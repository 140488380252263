import React from 'react';
import Select from "react-select";
import { Row, Col } from "react-bootstrap";
import { isValidPhoto, isValidVideo, isValidPPT, isValidDocument, isValidMusic } from './formFileValidation';
import Video from '../video';
import ImagePreview from '../ImagePreview';
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";

const TimeType = ({title, serialNo, questionsFiles, isRequired}) => {
    const multipleChoiceOpt = [
        { value: "AM", label: "AM" },
        { value: "PM", label: "PM" },
    ];
    return (
        <div className='mt-3 pt-3'>
            <h6 className='mb-3 font-medium'>{serialNo}. {title}
            {isRequired && (<span className="mandatoryFields" style={{ color: "red" }}>*</span>)}
            </h6>
            {questionsFiles.length > 0 ? (
                                        <>
                                          {isValidVideo(
                                            questionsFiles.length > 0 &&
                                            questionsFiles[0]?.filePath
                                          ) ? (
                                            <Video contentUrl={questionsFiles[0]?.filePath}
                                            />
                                          ) : isValidPhoto(
                                              questionsFiles.length > 0 &&
                                              questionsFiles[0]?.filePath
                                            ) ? (
                                            <ImagePreview
                                              contentUrl={
                                                questionsFiles[0].filePath
                                              }
                                            />
                                          ) : isValidPPT(
                                            questionsFiles.length > 0 &&
                                            questionsFiles[0]?.filePath
                                          ) ? (
                                            <Ppt
                                              contentUrl={
                                                questionsFiles[0].filePath
                                              }
                                            />
                                          ) : isValidDocument(
                                            questionsFiles.length > 0 &&
                                            questionsFiles[0]?.filePath
                                          ) ? (
                                            <Doc
                                              contentUrl={
                                                questionsFiles[0].filePath
                                              }
                                            />
                                          ) : isValidMusic(
                                            questionsFiles.length > 0 &&
                                            questionsFiles[0]?.filePath
                                          ) ? (
                                            <Audio
                                              contentUrl={
                                                questionsFiles[0].filePath
                                              }
                                            />
                                          ) : null}
                                        </>
                                      ) : null}
            <Row>
                <Col xs={6} md={4}>
                    <div className="input-group myInput">
                        <input type="text" value="08:00" />
                    </div>
                </Col>
                <Col xs={6} md={4}>
                    <div className='mySelect newMySelect'>
                        <Select
                        isSearchable={false}
                        menuPlacement="auto"
                            className="select"
                            options={multipleChoiceOpt}
                            defaultValue={multipleChoiceOpt[0]}
                            classNamePrefix="selectCustom"
                        />
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default TimeType
