import React from "react";
import { Modal, Button } from "react-bootstrap";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { convertToGB } from "../../../common";
const Downgrade2Modal = ({
  setLessFeaturesPlan,
  confirm,
  selectedPlanData,
}) => {
  console.log(selectedPlanData,'selectedPlanData');
  return (
    <>
      <Modal.Header closeButton onClick={() => setLessFeaturesPlan(false)}></Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <h3 className="mt-3 pe-4">
          We hear you! you will lose access to these key features when you
          downgrade.
        </h3>
        <div className="mt-32 text-gray-100">
          <ul className="checkList1">
            <li>Active Users reduce to <b>{selectedPlanData?.metaData?.max_users} Users</b></li>
            <li><b>{convertToGB(selectedPlanData?.metaData?.storage)}</b>/User Content Storage</li>
            <li>Custom Domain & Branding will change to {selectedPlanData?.metaData?.custom_branding === "Yes"? 'Basic' : 'No'}</li>
            <li>Could only create up to <b>{selectedPlanData?.metaData?.process === '-1' ? 'Unlimited' : selectedPlanData?.metaData?.process} Processes, {selectedPlanData?.metaData?.sequence === '-1' ? 'Unlimited' : selectedPlanData?.metaData?.sequence} Sequences & {selectedPlanData?.metaData?.modules === '-1' ? 'Unlimited' : selectedPlanData?.metaData?.modules} Modules</b></li>
            <li>Videos Community Knowledge Base</li>
            <li>Support Desk  turnaround on  will be <b>2 Business Day</b></li>
          </ul>
        </div>
        <div className="py-3"></div>
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter gap-2">
        <Button
          variant="white"
          size="lg"
          onClick={() => setLessFeaturesPlan(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          size="lg"
          className="border-0"
          onClick={confirm}
        >
          Submit
        </Button>
      </Modal.Footer>
    </>
  );
};

export default Downgrade2Modal;
