import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import {
    getSquenceAsync,
  } from "../../reducers/sequence-reducers";
  import {
    getModuleAsync,
    
  } from "../../reducers/module-reducers";
  import {
    getProcessesAsync,
    
  } from "../../reducers/processes-reducers";
  
const AuthoringNav = () => {
  const dispatch = useDispatch();

  const processes = useSelector((state) => state.process.processes);
  const modules = useSelector((state) => state.module.modules);
  const sequences = useSelector((state) => state.sequence.sequences);

  const [sortColumns, setSortColumns] = useState({
    column: "",
    sortDirection: "",
  });

  const getTabCount = () => {
    let reqBodys = {
      page: 1,
      pageSize: 50,
      orderBy: sortColumns.column.sortField,
      isOrderByDesc: sortColumns.sortDirection === "asc" ? false : true,
      searchString: "",
      status: [],
      tags: [],
    };
    dispatch(getProcessesAsync(reqBodys));
    dispatch(getSquenceAsync(reqBodys));
    dispatch(getModuleAsync(reqBodys));
  }

  useEffect(() => {
    getTabCount();
  }, []);
  
  return (
    <ul className='authoringNav'>
      <li className='me-4'>
        <NavLink exact className='h6 text-gray-100' to="/authoring">Processes ({processes.allRecordsCount ? processes.allRecordsCount : 0})</NavLink>
      </li>
      <li className='me-4'>
        <NavLink className='h6 text-gray-100' to="/authoring/sequences">Sequences ({sequences.allRecordsCount ? sequences.allRecordsCount: 0})</NavLink>
      </li>
      <li className='me-4'>
        <NavLink className='h6 text-gray-100' to="/authoring/modules">Modules ({modules.allRecordsCount ? modules.allRecordsCount: 0})</NavLink>
      </li>
    </ul>
  );
}

export default AuthoringNav;
