import React from 'react';
import { Modal, Button } from "react-bootstrap";
const ConfirmEditCardModal = ({ handleModal, confirm }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <h3 className='mt-3 pe-4'>Confirm Edit Payment Card</h3>
                <p className='text-gray-300 mb-0 mt-2 mb-5'>
                    Are you sure you want to proceed with the edit payment card?
                </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button variant="white" size="sm" onClick={() => handleModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" className='border-0' onClick={confirm}>
                    Confirm
                </Button>
            </Modal.Footer>
        </>
    );
}

export default ConfirmEditCardModal;
