import React, { useState, useEffect } from "react";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import {
  IconStar,
  IconTopSearch,
  IconEdit2,
  IconEye,
  IconTick,
  IconDuplicate,
  IconHamberger,
  IconArchive,
  IconDelete,
  IconEyeColored,
  FeedbackIconGrey,
} from "../../../icons/Icons";
import OwnerImg from "../../../assets/images/Account.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import NoContent from "../Processes/NoContent";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { getFormatDate, getVisibilityStatus } from "../../../common";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import ModalCreatePage from "./ModalCreatePage";
import ModalCreateForm from "./ModalCreateForm";
import { Status } from "../../../common";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteModuleAsync,
  getModuleDetailsAsync,
  getgetModulePageFormsAsync,
  getModulePreviewAsync,
} from "../../../reducers/module-reducers";
import Loader from "../../../components/Loader/Loader";
import EmployeeAssignementPopup from "../../../components/EmployeeAssignementPopup/EmployeeAssignementPopup";
import { AssignmentActionType } from "../../../services/DataConstants";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import { pageType } from "../../../helper/pageType";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import AudienceModal from "../Audience/AudienceModal";
import FeedbackView from "../Processes/FeedbackView";
import ModalMessageAlertPopup from "../../../components/ModalPopup/ModalMessageAlertPopup";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";

import {
  getUserProfileAsync,
  getMarkNotificationReadAsync,
} from "../../../reducers/user-reducers";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader"

const ModuleDetail = (id) => {
  const [formDataReady, setFormDataReady] = useState(false);
  const dispatch = useDispatch();
  const { state } = useLocation();
  const navigate = useHistory();
  const [moduleid, setmoduleId] = useState(null);
  const [moduleDetails, setAdditionalStorage] = useState(null);
  const [listData, setlistData] = useState(null);
  const [createPageModal, setCreatePageModal] = useState(false);
  const [createFormModal, setCreateFormModal] = useState(false);
  const [createPageId, setCreatePageId] = useState(null);
  const [createFormId, setCreateFormId] = useState(null);
  const [assignModal, setAssignModal] = useState(false);
  const [modulePreview, setModulePreview] = useState([]);
  const [contentType, setContentType] = useState(null);
  const [openAudienceModal, setOpenAudienceModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [audienceList, setAudienceList] = useState(null);
  const [pagesLists, setpagesLists] = useState([]);
  const [formsLists, setformsLists] = useState([]);

  console.log(state,'statestatestate');

  const [userProfiles, setUserProfiles] = useState(null);
  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    markNotificationRead();
  }, [state?.notificationId, userProfiles]);

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfiles(res.payload);
      }
    });
  };

  const markNotificationRead = async () => {
    const reqBody = {
      userId: userProfiles?.employeeId,
      notificationId: state?.notificationId,
    };

    const response = await dispatch(getMarkNotificationReadAsync(reqBody));
    if (response.payload) {
      console.log(response.payload,'markOtherNotificationRead');
    }
  };

  const setData = async () => {
    let response = await dispatch(getModuleDetailsAsync(state.id));
    dispatch(getgetModulePageFormsAsync(state.id));
    setAdditionalStorage(response.payload);
//console.log(response.payload,"response.payload")
    // const modulePreviewResponse = await dispatch(
    //   getModulePreviewAsync(state.id)
    // );
    // setModulePreview(modulePreviewResponse.payload[0]);
    //if(forms)
    const list = [];
    if (
      response.payload.formPages !== undefined &&
      response.payload.formPages.length > 0
    ) {
      response.payload.formPages.map((m) => {
        list.push({ ...m});
      });
    }
    // const list = [];
    // if (
    //   response.payload.pages !== undefined &&
    //   response.payload.pages.length > 0
    // ) {
    //   response.payload.pages.map((m) => {
    //     list.push({ ...m, contenttype: "pages" });
    //   });
    // }
    // if (
    //   response.payload.forms !== undefined &&
    //   response.payload.forms.length > 0
    // ) {
    //   response.payload.forms.map((m) => {
    //     list.push({ ...m, contenttype: "forms" });
    //   });
    // }
    setlistData(list ?? []);
    setFormDataReady(true);
    setAudienceList(response.payload.assignment)
  };

  let { forms } = modulePreview;
  const formsList = [];
  const dd =
    forms !== undefined &&
    forms.map((v, i) => {
      formsList.push(...v.questions);
    });

  useEffect(() => {
    if (state != null) {
      setmoduleId(state.id);
    }
    if (state.id != null) {
      setData();
    }
  }, [state, setModulePreview]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState("");
  const [showAlertMessagePopup, setShowAlertMessagePopup] = useState(false);

  const onConfirmDuplicate = async (confirmstatus) => {
    // Redirect to the desired URL
    // window.location.href = redirectUrl;
    // navigate.push('/authoring/modules/details');
    // navigate.push(redirectUrl);
    navigate.push(redirectUrl, {
      propsData: moduleDetails,
      operationType: "Duplicate",
      redirectTo: "/authoring/modules/details",
    });
    setDuplicateModal(false);
  };

  const onConfirm = async (confirmstatus) => {
    if (moduleid != null && moduleid != undefined) {
      const jsonData = {
        id: moduleid,
        status: confirmstatus,
      };
      const response = await dispatch(deleteModuleAsync(jsonData));

      if (confirmstatus === "Archive") {
        if (response.payload) {
          // ToasterAlertSucces(response, ErrrorMessage.Archive);
          ToasterAlertSucces(response, "Your module has been archived successfully.");
          navigate.push("/authoring/modules");
        } else {
          setArchiveModal(false);
          setShowAlertMessagePopup(true);
        }
      } else {
        // ToasterAlertSucces(response, ErrrorMessage.DELETE);
        ToasterAlertSucces(response, "Your module was deleted successfully.");
        navigate.push("/authoring/modules");
      }
    }
  };

  const OneditContent = (data) => {
    setCreatePageId(data.id);
    setCreatePageModal(true);
  };

  const OnEditFormContent = (data) => {
    setCreateFormId(data.id);
    setCreateFormModal(true);
  };
  const searchData = (e) => {
    // const list = [];
    console.log(moduleDetails,'moduledetail')
    const inputValue = e.target.value.trim().toLowerCase();

    const filteredList = moduleDetails?.formPages.filter((item) => {
      return item.title.trim().toLowerCase().includes(inputValue);
    });
  
    setlistData(filteredList);
    // moduleDetails.pages.filter((f) => {
    //   if (
    //     f.title
    //       .trim()
    //       .toLowerCase()
    //       .indexOf(e.target.value.trim().toLowerCase()) > -1
    //   ) {
    //     list.push({ ...f, contenttype: "pages" });
    //   }
    // });
    // moduleDetails.forms.filter((f) => {
    //   if (
    //     f.title
    //       .trim()
    //       .toLowerCase()
    //       .indexOf(e.target.value.trim().toLowerCase()) > -1
    //   ) {
    //     list.push({ ...f, contenttype: "forms" });
    //   }
    // });
    //setlistData(list);
  };
 
  const handleCloseAssignModal = () => {
    setAssignModal(false);
    setOpenAudienceModal(false);
    setOpenFeedbackModal(false);
  };

  useEffect(() => {
    const pagesList = listData && listData.filter(
      (item) => item.type === "Page"
    );
    const formsList = listData && listData.filter(
      (item) => item.type === "Form"
    );
    setpagesLists(pagesList);
    setformsLists(formsList);
  }, [listData]);

  console.log(listData, 'listdata');
  console.log(moduleDetails,'moduleDetails');
  return (
    <>
      {moduleDetails != undefined && formDataReady !== false && (
        <div className="pages">
          <RenewalHeader />
          <header className="pageHeader">
            <Container fluid>
              <Row className="align-items-center">
                <Col className="col-lg-4">
                  <div className="d-flex align-items-center gap-2 w-100">
                    <Link to="/authoring/modules">
                      <span className="arrow left"></span>
                    </Link>
                    <div className="mw-90">
                      <p className="h6 text-gray-200 mb-1">Modules</p>
                      <h3 className="text-truncate">{moduleDetails.title}</h3>
                    </div>
                  </div>
                </Col>
                <Col className="text-end col-lg-8">
                  <div className="d-flex align-items-center justify-content-end SupportIconBtn">
                    {/* {getVisibilityStatus(moduleDetails.status, "Duplicate") && ( */}
                    <Link className="m-0"
                      to={{
                        pathname: `/authoring/modules/create`,
                        state: {
                          propsData: moduleDetails,
                          opertionType: "Duplicate",
                          redirectTo: "/authoring/modules/details",
                        },
                      }}
                      // onClick={() => setDuplicateModal(true)}
                      onClick={(e) => {
                        e.preventDefault();
                        setDuplicateModal(true);
                        setRedirectUrl(e.currentTarget.pathname);
                      }}
                    >
                      <Button variant="" className="border-0 py-0 ps-0 pe-4">
                        <div className="d-flex gap-2 align-items-center">
                          <IconDuplicate />
                          <p className="h6 text-gray-300">Duplicate</p>
                        </div>
                      </Button>
                    </Link>
                    {/* )} */}
                    {/* {getVisibilityStatus(moduleDetails.status, "Delete") && (
                      moduleDetails.audience === 0 && (
                        <Button
                          variant=""
                          className="border-0 py-0 ps-0 pe-4"
                          onClick={() => setDeleteModal(true)}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <IconDelete />
                            <p className="h6 text-gray-300">Delete</p>
                          </div>
                        </Button>)
                    )} */}
                    {getVisibilityStatus(moduleDetails.status, "Delete") && (
                      moduleDetails.status !== "Archived" && moduleDetails?.audience === 0 && (
                        <Button
                          variant=""
                          className="border-0 py-0 ps-0 pe-4"
                          onClick={() => setDeleteModal(true)}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <IconDelete />
                            <p className="h6 text-gray-300">Delete</p>
                          </div>
                        </Button>)
                    )}
                    {getVisibilityStatus(moduleDetails.status, "Edit") && (
                      <Link className="m-0"
                        to={{
                          pathname: `/authoring/modules/create`,
                          state: {
                            propsData: moduleDetails,
                            opertionType: "Edit",
                            redirectTo: "/authoring/modules/details",
                          },
                        }}
                      >
                        <div className="d-flex gap-2 align-items-center pe-4">
                          <IconEdit2 />
                          <p className="h6 text-gray-300">Edit</p>
                        </div>
                      </Link>
                    )}

                    {/* {getVisibilityStatus(moduleDetails.status, "Unarchive") && (
                      <Button
                        variant=""
                        className="border-0 py-0"
                      // onClick={() => setArchiveModal(true)}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <IconArchive />
                          <p className="h6 text-gray-300">Un-Archive</p>
                        </div>
                      </Button>
                    )} */}

                    {getVisibilityStatus(moduleDetails.status, "Archive") && (
                      <Button
                        variant=""
                        className="border-0 py-0 m-0 ps-0 pe-4"
                        onClick={() => setArchiveModal(true)}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <IconArchive />
                          <p className="h6 text-gray-300">Archive</p>
                        </div>
                      </Button>
                    )}
                    {/* <Link
                      to={{
                        // pathname: `/authoring/module/feedback`,
                        state: {
                          propsData: moduleDetails,
                          opertionType: "Edit",
                          redirectTo: "/authoring/module/feedback",
                          authoringName: "Modules",
                        },
                      }}
                    >
                      <div className="d-flex gap-2 align-items-center justify-content-end">
                      
                        <Button variant="" className="border-0 p-0"
                          onClick={(()=> setOpenFeedbackModal(true))}>
                          <div className="d-flex gap-2 align-items-center">
                            <FeedbackIconGrey />
                            <p className="h6 text-gray-300">Feedback</p>
                          </div>
                        </Button>
                      </div>
                    </Link> */}
                    <div className="pipeSeparator pe-4">&nbsp;</div>
                    <Link className="ms-0"
                      // to={{
                      //   pathname: `/authoring/preview`,
                      //   state: {
                      //     propsData: modulePreview,
                      //     opertionType: "preview",
                      //     redirectTo: `/authoring/preview`,
                      //     headerFirstTitle: moduleDetails.title,
                      //     headerSecondTitle: "",
                      //   },
                      // }}
                      to={`/authoring/preview/${state.id}/module/sm/${moduleDetails.title}`}
                      target="_blank"
                    >
                      <Button variant="white"
                      // onClick={()=> 
                      //   localStorage.setItem('propsData', JSON.stringify({state: {
                      //     propsData: modulePreview,
                      //     opertionType: "preview",
                      //     moduleId:state.id,
                      //     redirectFrom:`/authoring/modules/details`,
                      //     redirectTo: `/authoring/preview`,
                      //     headerFirstTitle: moduleDetails.title,
                      //     headerSecondTitle: '',
                      //   }}))
                      //   }
                      >
                        <div className="d-flex align-items-center">
                          <IconEyeColored />
                          {/* TODO-16-1-Modal-Preview Button*/}
                          <span className="ps-10">Preview</span>
                        </div>
                      </Button></Link>
                    {getVisibilityStatus(moduleDetails.status, "Assign") && (
                      <Button
                        variant="secondary"
                        className="ms-0 me-4"
                        onClick={() => {
                          setAssignModal(true);
                        }}
                        // disabled={moduleDetails.isRestricted === true}
                      >
                        Assign 
                      </Button>)}
                    <ActivityLog />
                    {/* <div>
                      <a href="#" className="hamberger">
                        <IconHamberger />
                      </a>
                    </div> */}
                  </div>
                </Col>
              </Row>
            </Container>
          </header>
          <div className="pageOuter">
            <Container fluid>
              <div className="page detailModulePage d-flex flex-column h-100">
                <div className="p-32 pb-4 borderBottom w-100">
                  <div className="d-flex align-items-center mb-32">
                    <p className="h6 ">
                      <span className="text-gray-300">Created on</span>{" "}
                      {getFormatDate(moduleDetails.createdDate)}{" "}
                      {/* <DateTimeConverter utcDateTime={moduleDetails.createdDate} /> */}
                      {moduleDetails.modifiedBy && moduleDetails.modifiedBy !== null && (
                        <><span className="text-gray-300">
                          <span className="text-gray-400">|</span> Edited on
                        </span>{" "}
                        {getFormatDate(moduleDetails.modifiedDate ? moduleDetails.modifiedDate : moduleDetails.createdDate)}{" "}
                        {/* <DateTimeConverter utcDateTime={moduleDetails.modifiedDate ? moduleDetails.modifiedDate : moduleDetails.createdDate} /> */}
                        </>
                      )}
                      
                      <span className="text-gray-300">
                        <span className="text-gray-400">|</span> Created by
                      </span>{" "}
                      <span className="text-primary-100">
                        {moduleDetails?.createdUserDetail?.name}
                      </span>
                    </p>

                    <div className="ms-2">
                      <p className="text-md mb-0 d-flex align-items-center gap-2">
                        {/* {moduleDetails.status} */}
                        <span
                          className={`tableTag ${Status[moduleDetails.status]?.color
                            } text-md  `}
                        >
                          {" "}
                          <IconTick />
                          {moduleDetails.status}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div></div>

                  <div className="d-flex feedbackData">
                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Used in Processes</p>

                      <h3 className="font-medium">
                        {moduleDetails.usedInProcesses ?? 0}
                      </h3>
                    </div>

                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Used in Sequences</p>

                      <h3 className="font-medium">
                        {moduleDetails.usedInSequences ?? 0}
                      </h3>
                    </div>

                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Feedback Rating</p>

                      <Link
                        to={{
                          // pathname: `/authoring/module/feedback`,
                          state: {
                            propsData: moduleDetails,
                            opertionType: "Edit",
                            redirectTo: "/authoring/module/feedback",
                            authoringName: "Modules",
                          },
                        }}
                      >
                        <div className="">
                          <Button variant="" className="d-flex border-0 align-items-center p-0"
                            onClick={(() => setOpenFeedbackModal(true))}>
                            <IconStar />
                            <h3 className="ps-1 font-medium">
                              {moduleDetails.avgRating.toFixed(1)}
                            </h3>
                          </Button>
                          <Button variant="" className="d-flex border-0 align-items-center p-0 btn-sm"
                            onClick={(() => setOpenFeedbackModal(true))}>
                            <p className="text-primary-200 text-lg mt-2 mb-0">
                              View all ratings
                            </p>
                          </Button>
                        </div>
                      </Link>

                      {/* {moduleDetails.avgRating > 0 &&
                        moduleDetails.feedBackRating != null &&
                        moduleDetails.feedBackRating.length >
                        0(
                          <p className="h6 text-primary-200 font-bold">
                            View all ratings
                          </p>
                        )} */}
                    </div>

                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Audience</p>
                      <button onClick={() => setOpenAudienceModal(true)}>
                        <h3 className="font-medium">
                          {moduleDetails.audience}
                        </h3>
                      </button>
                    </div>

                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Module Owner</p>
                      <h3 className="font-medium">
                        {moduleDetails.moduleOwnerCount}
                      </h3>
                      {/* <div className="d-flex">
                        {moduleDetails.moduleOwner != undefined &&
                          moduleDetails.moduleOwner.length > 0 &&
                          moduleDetails.moduleOwner.map((c, index) => (
                            <div key={index} className="headImg moduleImg">
                              <img key={"img" + index} src={OwnerImg} alt="" />
                            </div>
                          ))}
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="p-32 bg-gray-600 flex-grow-1 overflow-auto">
                  <h5 className="mb-3">Content</h5>
                  <div className="input-group myInput myInputSearch mb-20">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by content title"
                      onChange={(e) => searchData(e)}
                    />
                    <IconTopSearch />
                  </div>
                  {listData != undefined && listData.length <= 0 ? (
                    <EmptyState message="No content found" />
                  ) : (
                    <div className="row">
                      <div className="col-lg-9">
                        {listData != undefined &&
                          listData.length > 0 &&
                          listData.map((p, index) => (
                            <div key={index} className="cardContent">
                              <div className="borderBottom2 p-12 d-flex gap-3 align-items-start">
                                <div className="d-flex gap-3 pt-4 px-4  borderLeft position-absolute end-0 top-0">
                                  <span
                                    className={`tableTag ${Status[p.statusstr].color
                                      } text-md  `}
                                  >     {p.statusstr}</span>
                                  {moduleDetails.status !== 'Active' && moduleDetails.status !== 'Archived' && (
                                    <Button
                                      variant=""
                                      className="border-0 py-0"
                                      onClick={() => {
                                        if (p.type === "Form") {
                                          OnEditFormContent(p);
                                        } else {
                                          OneditContent(p);
                                        }
                                      }}
                                    >
                                      <IconEdit2 />
                                    </Button>
                                  )}
                                  {/* <IconEye /> */}
                                </div>
                                <div className="count">
                                  <span className="text-xs">{index + 1}</span>
                                </div>
                                <div style={{width: "calc(100% - 13.375rem)"}}>
                                  <div className="d-flex align-items-center gap-2 mb-2">
                                    <p className="font-medium mb-0">
                                      {p.title}
                                    </p>
                                    <p className="tableTag orange text-md mb-0">
                                      {p.type === "Page" &&
                                        p.pageContentTypestr}
                                      {p.type === "Form" && "Form"}
                                    </p>
                                  </div>
                                  <p className="h6 text-wrap">
                                    <span className="text-gray-300">
                                      Created on
                                    </span>{" "}
                                    {getFormatDate(p.createdDate)}{" "}
                                    {/* <DateTimeConverter utcDateTime={p.createdDate} /> */}
                                    {p.modifiedDate && (<>
                                    <span className="text-gray-300">
                                      <span className="text-gray-400">|</span>{" "}
                                      Edited on
                                    </span>{" "}
                                    {getFormatDate(p.modifiedDate)}{" "}
                                    {/* <DateTimeConverter utcDateTime={p.modifiedDate} /> */}
                                    </>)}
                                    {console.log(p.modifiedDate,"777")}
                                    <span className="text-gray-300">
                                      <span className="text-gray-400">|</span>{" "}
                                      Created by
                                    </span>{" "}
                                    {p?.createdUserDetail?.name}
                                  </p>
                                </div>
                              </div>

                              <div className="p-4">
                                <div className="d-flex align-items-center gap-3 ms-5">
                                  <span className="issuesNo h6">
                                    {" "}
                                    {p?.reportIssueCount}
                                  </span>
                                  <h6 className="text-gray-300 text-uppercase">
                                    reported ISSUES
                                  </h6>
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        // onHide={() => setAssignModal(false)}
        show={assignModal}
      >
        <EmployeeAssignementPopup
          type={AssignmentActionType.MODULE}
          title={"Module"}
          contentIds={[moduleid]}
          getData={setData}
          setAssignModal={setAssignModal}
          moduletitle={moduleDetails}
          onClose={handleCloseAssignModal}
        />
      </Modal>
      <> {formDataReady === false && <Loader />}</>

      <Modal className="modalDialog" size="sm" centered show={duplicateModal}>
        <ModalDelete
          onCancel={setDuplicateModal}
          onConfirm={onConfirmDuplicate}
          headerLable="Are you sure you want to duplicate this module?"
          chldLable="The page and forms added to this module will be duplicated."
        />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal || archiveModal}
      >
        {deleteModal ? (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onConfirm}
            headerLable="Are you sure you want to delete this module?"
            chldLable="The pages and forms added to this module will be removed. "
          />
        ) : (
          <ModalDelete
            onCancel={setArchiveModal}
            onConfirm={onConfirm}
            requestType="Archive"
            headerLable="Are you sure you want to archive this module? "
            chldLable="The pages and forms added to this module will be archived."
          />
        )}
      </Modal>
      <Modal
        className="come-from-modal right createPage"
        show={createPageModal}
        onHide={() => setCreatePageModal(false)}
      >
        <ModalCreatePage
          openCreatePageModal={setCreatePageModal}
          // onSave={handlePagesForms}
          moduleData={moduleDetails}
          getModuleDataFunction={setData}
          id={createPageId}
          onClosed={setCreatePageModal}
          moduleFormRef={moduleDetails?.title}
          pagesList={pagesLists}
          formsList={formsLists}
        />
      </Modal>
      <Modal
        className="come-from-modal right createPage"
        show={createFormModal}
        onHide={() => setCreateFormModal(false)}
      >
        <ModalCreateForm
          openCreateFormModal={setCreateFormModal}
          // onSave={handlePagesForms}
          moduleData={moduleDetails}
          getModuleDataFunction={setData}
          id={createFormId}
          onClosed={setCreateFormModal}
          moduleFormRef={moduleDetails?.title}
          pagesList={pagesLists}
          formsList={formsLists}
        />
      </Modal>

      <Modal
        className="come-from-modal right"
        centered
        show={openAudienceModal}
      // onHide={() => setOpenAudienceModal(false)}
      >
        <AudienceModal
          openAudienceModal={setOpenAudienceModal}
          data={audienceList}
          title={"Module"}
          moduletitle={moduleDetails}
          onClose={setOpenAudienceModal}
        />
      </Modal>

      <Modal
        className="come-from-modal right"
        centered
        show={openFeedbackModal}
      // onHide={() => setOpenFeedbackModal(false)}
      >
        <FeedbackView id={state.d} onClose={handleCloseAssignModal} />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={showAlertMessagePopup}
      >
        <ModalMessageAlertPopup
          onCancel={setShowAlertMessagePopup}
          requestType="Archive"
          headerLable="You can not archive the content as this content is yet to be completed by the users"
          chldLable="You can only archive this once it is completed by all the users to whom this is assigned."
        />
      </Modal>
    </>
  );
};

export default ModuleDetail;
