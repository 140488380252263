import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import FormDocumentModalForm from "./FormDocumentModalForm";
import FormDocumentModalDocument from "./FormDocumentModalDocument";

const FormDocumentModal = ({
  handleModal,
  documentUserId,
  documentUserName,
  documentType,
}) => {
  const [modalType, setModalType] = useState("form");

  useEffect(() => {
    if (documentType) {
      setModalType(
        documentType === "FORMS"
          ? "form"
          : documentType === "DOCUMENTS"
          ? "documents"
          : "form"
      );
    }
  }, [documentType]);

  return (
    <>
      <Modal.Header
        className="px-32"
        closeButton
        // onClick={() => handleModal(false)}
      >
        <div className="row w-100">
          <div className="col-md-3">
            {/* <Modal.Title>{documentUserName.toUpperCase()}</Modal.Title> */}
            <Modal.Title className="singleLineEc" as="h2">
              {documentUserName}
            </Modal.Title>
          </div>
          <div className="col-md-9">
            <ul className="topNavHeader docNav d-flex m-0">
              <li className={`${modalType === "form" ? "active" : ""} me-3`}>
                <button onClick={() => setModalType("form")}>Form</button>
              </li>
              <li className={`${modalType === "documents" ? "active" : ""} ms-2`}>
                <button onClick={() => setModalType("documents")}>
                  Documents
                </button>
              </li>
            </ul>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        {modalType === "form" && <FormDocumentModalForm documentUserId={documentUserId} />}
        {modalType === "documents" && <FormDocumentModalDocument documentUserId={documentUserId} />}
      </Modal.Body>
    </>
  );
};

export default FormDocumentModal;
