export const isValidPhoto = (fileName) => {
    var allowed_extensions = new Array("jpg", "png", "jpeg");
    if (fileName === undefined) {
      return "Filename is undefined.";
    }
    var file_extension = fileName.split(".").pop().toLowerCase();
    for (var i = 0; i <= allowed_extensions.length; i++) {
      if (allowed_extensions[i] == file_extension) {
        return true;
      }
    }
    // return "The image file is invalid or the image type is not Allowed.";
    return " The file can not be uploaded. Please change the Page Type in order to upload any other file type.";
  }

  
  export const  isValidVideo = (filename) => {
    if (filename === undefined) {
      return "Filename is undefined.";
    }
    var ext =  filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'mp4':
      case 'mov':
     return true;
    }
    // return "The video file is invalid or the video type is not Allowed.";
    return " The file can not be uploaded. Please change the Page Type in order to upload any other file type.";
  }

  export const  isValidDocument = (filename) => {
    // console.log(filename,'filename');
    if (filename === undefined) {
      return "Filename is undefined.";
    }
    var ext =  filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case 'pdf':
      case 'doc':
      case 'docx':
     return true;
    }
    // return "The document file is invalid or the document type is not Allowed.";
    return " The file can not be uploaded. Please change the Page Type in order to upload any other file type.";
  }

  export const  isValidAudio = (filename) => {
    if (filename === undefined) {
      return "Filename is undefined.";
    }
    var ext =  filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case 'mp3':
     return true;
    }
    // return "The audio file is invalid or the audio type is not Allowed.";
    return " The file can not be uploaded. Please change the Page Type in order to upload any other file type.";
  }

  export const  isValidPPT = (filename) => {
    if (filename === undefined) {
      return "Filename is undefined.";
    }
    var ext =  filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case 'pptx':
     return true;
    }
    // return "The ppt file is invalid or the ppt type is not Allowed.";
    return " The file can not be uploaded. Please change the Page Type in order to upload any other file type.";
  }

  export const  isDownloadableFile = (filename) => {
    if (filename === undefined) {
      return "Filename is undefined.";
    }
    var ext =  filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case 'mp3':
      case 'jpg':
      case 'png':
      case 'jpeg':
      case 'm4v':
      case 'avi':
      case 'mpg':
      case 'mp4':
      case 'pdf':
      case 'doc':
      case 'docx':
      case 'pptx':
      case 'mov':
      
     return true;
    }
    return false;
  }
