import React, { useState, useEffect } from 'react'
import Search from "../../../components/Search/Search";
import { 
  PDFicon,
  IconDownload,
  IconLink,
  IconListDoc,
  IconPhoto,
  IconAudio,
  IconVideo,
  IconDocument, 
} from "../../../icons/Icons";
import {
  getEmployeeAllDocumentsAsync,
} from "../../../reducers/user-reducers";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, getFormatDate } from "../../../common";
import NoData from '../../../components/EmptyState/NoData';
import DownloadLink from "../../Authoring/ContentPreview/DownloadLink";
import { bulkGeneratePresignedUrl, generatePresignedUrl } from "../../../api/common-store-api";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";
import { getGUID, getFileExtension, getRemainingStringFileName } from "../../../common";
import Loader from "../../../components/Loader/Loader";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const FormDocumentModalDocument = ({
  documentUserId,
}) => {
  const dispatch = useDispatch();
  const [employeeId, setEmployeeId] = useState(documentUserId);
  const [employeeAllDocuments, setEmployeeAllDocuments] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const iconMap = {
    IconPhoto: IconPhoto,
    IconAudio: IconAudio,
    IconVideo: IconVideo,
    IconDocument: IconDocument
  };

  const onSearchStringChange = (value) => {
    setSearchString(value);
  };

  useEffect(() => {
    setEmployeeId(documentUserId);
    debouncedEmployeeAllDocuments();
  }, [documentUserId, searchString]);

  const getEmployeeAllDocuments = () => {
    setIsLoading(true);
    const reqBody = {
      searchString: searchString,
      EmployeeId: employeeId
    };
    dispatch(getEmployeeAllDocumentsAsync(reqBody)).then((res) => {
      // console.log(res.payload,'res.payloadres.payloadres.payload');
      if (res.payload) {
        setEmployeeAllDocuments(res.payload);
        setIsLoading(false);
      }
    });
  };

  const debouncedEmployeeAllDocuments = useDebounce(getEmployeeAllDocuments, 1000);

  const getBulkGeneratePresignedUrl = async (files) => {
    // console.log(files,'filesfilesfiles');

    let allDocuments = [];
    files.forEach(file => {
      const documents = file.document.split(',');
      documents.forEach(document => {
        allDocuments.push(document.trim());
      });
    });

    const processedFiles = allDocuments.map((file) => getGUID(file));
    let responseURLsDownloadable = await bulkGeneratePresignedUrl(processedFiles.map((value, i) => processedFiles[i]));
    if (responseURLsDownloadable?.data) {
        return  responseURLsDownloadable?.data;
    }

    // let responseURLsDownloadable = await bulkGeneratePresignedUrl(files.map((value) => value.document));
    // console.log(responseURLsDownloadable, 'responseURLsDownloadable');
    // if (responseURLsDownloadable.data) {
    //     return  responseURLsDownloadable.data;
    // }
  };

  const handleDownload = async () => {
    const urls = await getBulkGeneratePresignedUrl(employeeAllDocuments);
    if (urls && urls.length) {
        urls.forEach((url) => {
            const link = document.createElement('a');
            link.href = url;
            link.target = '_blank';
            link.download = ''; // Optional: Set the desired file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }
  };

  // console.log(employeeId, 'employeeId');
  // console.log(employeeAllDocuments, 'employeeAllDocuments');

  return (
    <>
    <div>
      <div className='SeacrhDownlaod d-flex justify-content-between py-4 px-32'>
        <div className=''><Search placeHolder='Search' searchString={searchString} onBlur={onSearchStringChange} /></div>
        {employeeAllDocuments?.length > 0 && (
          <a className='h6 font-normal text-secondary-500' onClick={handleDownload} role="button">
          {/* <a className='h6 font-normal text-secondary-500' href={getBulkGeneratePresignedUrl(employeeAllDocuments)} target="blank" download> */}
          {/* <button className="h6 font-normal text-secondary-500" onClick={() => getBulkGeneratePresignedUrl(employeeAllDocuments)} > */}
            <span className='me-2'><IconDownload /></span>Download all 
          {/* </button> */}
          </a>
        )}
      </div>

      <div className="tableComponent flex-grow-1 overflow-auto">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Submitted on</th>
              {/* <th>File size</th> */}
              <th>Form</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {isLoading ? (
            <div className="position-absolute" style={{height:"calc(100vh - 200px)", width:"100%"}}>
              <Loader type="activity" />
            </div>
            ):(
            employeeAllDocuments && employeeAllDocuments.map((document, index) => {
              const documentList = document?.document.split(',');
              return documentList.map((doc, docIndex) => {
                const fileExtension = getFileExtension(doc);
                const fileName = getRemainingStringFileName(doc);
                const IconComponent = iconMap[fileExtension] || IconDocument;
                return (
                  <tr key={`${index}-${docIndex}`}>
                    <td>
                    <div className='d-flex justify-content-start align-items-center'>
                    <IconComponent />
                    <span className='mx-2'>{fileName}</span>      
                    </div>  
                    </td>
                    <td>
                    {getFormatDate(document?.uploadedDate)}
                    {/* <DateTimeConverter utcDateTime={document?.uploadedDate} /> */}
                    </td>
                    {/* <td>3.3 MB</td> */}
                    <td>{document?.formTitle}</td>
                    <td>
                    <button className="h6 font-normal" >
                    
                        {/* <PDFicon /> */}
                        {/* <span className='mx-2'>{doc}</span> */}
                                        
                        {/* <IconDownload /> */}
                        <DownloadLink url={doc} />
                      </button>
                    </td>
                  </tr>
                )
              });
            })
          )}
          </tbody>
        </table>
      </div>
    </div>
    {employeeAllDocuments?.length === 0 && (
      <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
        <NoData msgicon="formModal" message="No documents to view, return later for<br/> updated information."/>
      </div>
    )}
    </>
  )
}

export default FormDocumentModalDocument
