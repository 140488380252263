import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Row, Col, Modal, Button } from "react-bootstrap";
import PaymentCard from "../../../components/PaymentCard/PaymentCard";
import MasterCard from "../../../assets/images/masterCard.png";
import VisaCard from "../../../assets/images/visaCard.png";
import { Link, useHistory  } from "react-router-dom";
import PrefrredModal from "./PrefrredModal";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAsync } from "../../../reducers/user-reducers";
import { 
  addSubscriptionAsync,
  getCardListByCustomerIdAsync,
  deleteCardAsync,
  getSubscriptionAsync,
  addCardAsync,
  getCardByCardIdAsync,
  updateCardAsync,
  getPlansAsync,
} from "../../../reducers/subscription-reducers";
import { Formik, Field, Form } from "formik";
import CheckBoxInput from "../../../components/FormikInputs/CheckBoxInput";
// import Select from "react-select";
import cardType from "../../../assets/images/master_card.png";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import { Constants } from "../../../services/Constants";
import ConfirmPlanModal from "./ConfirmPlanModal";
import Loader from "../../../components/Loader/Loader";
import Select from "react-select";
import {
  countryList,
  convertToGB,
} from "../../../common";
import { IconleftArrow, IconEdit3 } from "../../../icons/Icons";

const curYearMonthOpts = [];
const monthOpts = [];
const yearsOpts = [];
const noOfYear = 5;
const currentYear = parseInt(moment(new Date()).format("YYYY"));
const currentMonth = parseInt(moment(new Date()).format("MM"));
for (let i = currentMonth; i <= 12; i++) {
  curYearMonthOpts.push({ value: i, label: i.toString().padStart(2, "0") });
}
for (let i = 1; i <= 12; i++) {
  monthOpts.push({ value: i, label: i.toString().padStart(2, "0") });
}
for (let i = 0; i < noOfYear; i++) {
  yearsOpts.push({ value: currentYear + i, label: currentYear + i });
}

const PaymentCardModal = ({
  selectedPlanId,
  setSelectedPlanId,
  paymentCardModal,
  setPaymentCardModal,
  setIsMakePlan,
  selectedPlanDatas,
  viewPlan,
  changeBillingCycle,
  // modalTitle,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const cardListData = useSelector((state) => state.subscription);
  const cardData = useSelector((state) => state.subscription.cardData);

  const [planName, setPlanName] = useState('');
  const { plansData, isUpdateLoading } = useSelector(
    (state) => state.subscription
  );
  const subscriptionData = useSelector((state) => state.subscription);

  const [expiryDate, setExpiryDate] = useState(new Date());
  const [cardId, setCardId] = useState("");
  const [showPaymentMethod, setShowPaymentMethod] = useState(true);
  const [userProfile, setUserProfile] = useState(null);
  const [tokens, setTokens] = useState(null);
  const [redirectUrl, setRedirectUrl] = useState('');
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [isLoader, setIsLoader] = useState(false);
  const [countryOption, setCountryOption] = useState(true);

  const [selectedCountry, setSelectedCountry] = useState({});
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  

//   const optionsTeam = [
//     { value: '1', label: 'India' },
//     { value: '2', label: 'USA' },
//     { value: '3', label: 'England' }
// ]

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [otherPlanData, setOtherPlanData] = useState({});
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handlePreferred = (id) => {
    // console.log("handlePreferred", id);
    setCardId(id);
    setShowPaymentMethod(false);
    // setChangePreferredModal(true);
    dispatch(getCardByCardIdAsync(id));
    dispatch(getCardListByCustomerIdAsync());
  };

  useEffect(() => {
    let selPlanData = [];
    if (viewPlan === "year") {
      selPlanData = selectedPlanDatas?.planType === "Yearly" ? selectedPlanDatas : [];
    } else {
      selPlanData = selectedPlanDatas?.planType === "Monthly" ? selectedPlanDatas : [];
    }
    if (selPlanData) {
      setSelectedPlanData(selPlanData);
    }

    const filteredPlansData = plansData.filter(
      (plan) =>
        plan.planType === (viewPlan === "year" ? "Monthly" : "Yearly") &&
        plan.statementDescriptor === "base_plan" && 
        plan.planName === selectedPlanDatas?.planName
    );
    setOtherPlanData(filteredPlansData && filteredPlansData[0]);
  }, [viewPlan, selectedPlanDatas]);
  

  useEffect(() => {
    if (countryList) {
      const transformedList = countryList.map(country => ({
        value: country.code,
        label: country.name
      }));
      setCountryOption(transformedList);
      // console.log(cntryName,'cntryName');
    }
  }, [countryList]);

  useEffect(() => {
    getUserProfile();
    dispatch(getPlansAsync());
    dispatch(getSubscriptionAsync());
    dispatch(getCardListByCustomerIdAsync());
    if (confirmPayment === false) {
      localStorage.removeItem('makePaymentData');
    }
  }, []);

  const getUserProfile = async () => {
    await dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
      }
    });
  };
  const onCloseModal = () => {
    setCardId("");
    setSelectedPlanId("");
    setShowPaymentMethod(false);
    setPaymentCardModal(false);
  };

  const onhandleConfirmPlan = async () => {
    // onCloseModal();
    window.location.href = redirectUrl;
  };

  const cardValidation = async (values) => {
    const cardElement = elements.getElement(CardElement);
    const { error: cardError, token } = await stripe.createToken(cardElement);
    console.log(values,'values');

    let errors = {};

    if (cardError?.message) {
      errors.cardElement = cardError?.message;
    }
    if (!values.cardHolderName || values.cardHolderName.trim() === "") {
      errors.cardHolderName = "Card holder name is required!";
    }
    if (!values.country || values.country.trim() === "") {
      errors.country = "Country name is required!";
    }
    if (!values.addressLine1 || values.addressLine1.trim() === "") {
      errors.addressLine1 = "Address Line 1 is required!";
    }
    if (!values.city || values.city.trim() === "") {
      errors.city = "City name is required!";
    }
    if (!values.state || values.state.trim() === "") {
      errors.state = "State name is required!";
    }
    if (!values.postalCode || values.postalCode.trim() === "") {
      errors.postalCode = "Postal code is required!";
    }
    // else {
    //   errors = {};
    // }
    return errors;
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#232323",
        "::placeholder": {
          color: "#888888",
          textTransform: 'capitalize',
        },
        borderColor: "#4a154b",
        borderRadius: '0.375rem',
        padding: '0.75rem 1rem',
        fontSize: '15px',
        border: '0.0625rem solid #D9D9D9',
        fontFamily: '"Inter",sans-serif'
        
      },
      invalid: {
        lineHeight: "normal",
        fontSize: '15px',
        marginTop: '0.25rem',
        color: "red",
        fontWeight: "normal",
        borderRadius: '0.375rem',
        border: '0.0625rem solid #D9D9D9',
      },
    },
    hidePostalCode: true, // Optional, hides the postal code field
    classes: {
      focus: 'focused', // Optional, CSS class to apply when the element is in focus
    }
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  // console.log(cardListData,'cardListData');
  console.log(selectedPlanData,'selectedPlanData');
  console.log(selectedPlanDatas, 'selectedPlanDatas');
  console.log(otherPlanData,'otherPlanData');
  console.log(plansData,'plansDataplansDataplansDataplansData');

  return (
    <>
      {isLoader && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      {/*<div className="paymentMethod py-3">
      </div>*/}
      {showPaymentMethod && (
        <Formik
          initialValues={{
            // isCustomerExist: false,
            // planId: selectedPlanId,
            cardHolderName: "",
            country: "",
            addressLine1: "",
            addressLine2: "",
            city: "",
            state: "",
            postalCode: "",
            // expiryYear: "",
            // setAsPreferredCard: false,
          }}
          validate={cardValidation}
          onSubmit={async (values) => {
            setIsLoader(true);
            const cardElement = elements.getElement(CardElement);
            const { error, token } = await stripe.createToken(cardElement);
            // console.log(token,'token');
            setTokens(token);
            // tokens
            const request = {
              token: token?.id || "",
              // customerId: userProfile?.id || "",
              customerId: "cus_PHZcTbZGclpDJ2",
              amount: selectedPlanData?.price || "", 
              // subscriptionData?.subscriptionData[0]?.items[0]?.plan.amount || "",
              priceId: selectedPlanData?.priceId || "", 
              // subscriptionData?.subscriptionData[0]?.items[0]?.price.id || "",
              returnUrl: `${Constants?.clientRoot}/accountsetting/billingsubscription/makeplansuccess`,
              requestBody: {
                city: values?.city || "",
                state: values?.state || "",
                country: values?.country || "",
                address1: values?.addressLine1 || "",
                address2: values?.addressLine2 || "",
                postalCode: values?.postalCode || "",
              },
            };
            await dispatch(addCardAsync(request)).then((res) => {
              console.log(res,'addCardAsync');
              if (res?.payload?.result) {
                localStorage.setItem(
                  "makePaymentData",
                  JSON.stringify({
                    state: {
                      customerId: "cus_PHZcTbZGclpDJ2",
                      paymentMethodId: res?.payload?.paymentMethodId,
                      priceId: selectedPlanData?.priceId,
                      isNew: true,
                    },
                  })
                );
                localStorage.setItem(
                  "selectedPlanData",
                  JSON.stringify({
                    state: {
                      selectedPlanData: selectedPlanData,
                    },
                  })
                );
                setRedirectUrl(res?.payload?.result);
                setIsLoader(false);
                setConfirmPayment(true);
                // window.location.href = res?.payload?.result;
              }
              // ToasterAlertSucces(res, ErrrorMessage.CREATE);
              // dispatch(getCardListByCustomerIdAsync());
              
            });
            // setShowPaymentMethod(false);
          }}
        >
          {({
            errors,
            touched,
            setFieldValue,
            setFieldTouched,
            values,
            
          }) => {
            return (
              <Form
                className="d-flex flex-column flex-grow-1 overfllow-auto h-100"
                autoComplete="off"
              >
                <Modal.Header closeButton>
                  <div className="w-100 d-flex justify-content-start align-items-center">
                    <div className="">
                      <Modal.Title>Payment Details</Modal.Title>
                      <p className="mb-0 mt-1 text-lg text-gray-300 font-normal">
                        Complete your payment and fill in the information.
                      </p>
                    </div>
                  </div>
                </Modal.Header>
                <Modal.Body className="p-0">
                  <Row className="gx-0">
                    <Col sm={8}>
                      <div className="leftArea w-100 h-100 p-32">
                        <h6 className="pb-4">Payment Details</h6>
                        <div className="mb-3 ps-lg-0" style={{maxWidth: "27.5rem"}}>
                          <CardElement options={cardElementOptions} onChange={handleCardChange} />
                          {errors?.cardElement !== '' && (
                            <label className="text-sm" style={{ color: "red" }}>
                              {errors?.cardElement?.replace(/\./g, '!')}
                            </label>
                          )}
                        </div>
                        <div className="mb-2 pe-lg-0 input-group myInput">
                          <label className="">
                            Name on the card <span style={{ color: "red" }}>*</span>
                          </label>
                          <Field
                            name="cardHolderName"
                            placeholder="Name on the card"
                            aria-label="Name on the card"
                            type="text"
                            className="input"
                            onChange={(e) => {
                              setFieldValue("cardHolderName", e?.target?.value);
                            }}
                          />
                          {errors.cardHolderName && touched.cardHolderName ? (
                            <label className="text-sm  mb-0" style={{ color: "red" }}>
                              {errors.cardHolderName}
                            </label>
                          ) : null}
                        </div>
                        <h6 className="pt-32 pb-4">Billing Address</h6>
                        <div className="form-group mySelect mb-32">
                          <label>Country <span style={{ color: "red" }}>*</span></label>
                          <Select
                            isSearchable={true}
                            className="select"
                            // options={optionsTeam}
                            options={countryOption}
                            // classNamePrefix="recatMySel"
                            classNamePrefix="selectCustom"
                            name="country"
                            // isRequired={true}
                            // value={selectedCountry}
                            onChange={(e) => {
                              console.log(e,'country');
                              setFieldValue("country", e?.value);
                              // setSelectedCountry({
                              //   value: e?.target?.value,
                              //   label: e?.target?.label,
                              // });
                            }}
                          />
                          {errors.country ? (
                            <label className="text-sm  mb-0" style={{ color: "red" }}>
                              {errors.country}
                            </label>
                          ) : null}
                        </div>
                        <div className="input-group myInput mb-32">
                          <label>Address Line 1 <span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address  Line 1"
                            name="addressLine1"
                            onChange={(e) => {
                              setFieldValue("addressLine1", e?.target?.value);
                            }}
                            // value="4517 Washington Ave"
                            // isRequired={true}
                          />
                          {errors.addressLine1 ? (
                            <label className="text-sm  mb-0" style={{ color: "red" }}>
                              {errors.addressLine1}
                            </label>
                          ) : null}
                        </div>
                        <div className="input-group myInput mb-32">
                          <label>Address Line 2</label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Address  Line 2"
                            name="addressLine2"
                            onChange={(e) => {
                              setFieldValue("addressLine2", e?.target?.value);
                            }}
                            // value="Manchester, Kentucky "
                          />
                        </div>
                        <div className="myInput mb-32">
                          <div className="row gx-4">
                            <div className="col-lg-6">
                              <label>Postal Code <span style={{ color: "red" }}>*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Postal Code"
                                name="postalCode"
                                onChange={(e) => {
                                    setFieldValue("postalCode", e?.target?.value);
                                  }}
                                // value="39495"
                                // isRequired={true}
                              />
                              {errors.postalCode ? (
                                <label className="text-sm  mb-0" style={{ color: "red" }}>
                                  {errors.postalCode}
                                </label>
                              ) : null}
                            </div>
                            <div className="col-lg-6">
                              <label>City <span style={{ color: "red" }}>*</span></label>
                              <input
                                type="text"
                                className="form-control"
                                placeholder="City"
                                name="city"
                                onChange={(e) => {
                                    setFieldValue("city", e?.target?.value);
                                  }}
                                // value="Washington  D.C."
                                // isRequired={true}
                              />
                              {errors.city ?  (
                                <label className="text-sm  mb-0" style={{ color: "red" }}>
                                  {errors.city}
                                </label>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="input-group myInput">
                          <label>State <span style={{ color: "red" }}>*</span></label>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="State"
                            name="state"
                            onChange={(e) => {
                                setFieldValue("state", e?.target?.value);
                              }}
                            // value="Washington  D.C."
                            // isRequired={true}
                          />
                          {errors.state ? (
                            <label className="text-sm  mb-0" style={{ color: "red" }}>
                              {errors.state}
                            </label>
                          ) : null}
                        </div>
                        
                        <h6 className="text-gray-100 font-bold mt-32 mb-4">
                          Billing Cycle
                        </h6>
                        <div className="planBox">
                        
                          <div 
                            className={`singlePlan d-flex flex-column ${selectedPlanData?.planType === "Monthly" ? "active" : ""}`}
                            onClick={
                              () => changeBillingCycle && changeBillingCycle('month', selectedPlanData?.planName)
                            }
                          >
                            <div>
                              <p className="text-md text-uppercase text-base-200 opacity-75 font-normal mb-0">
                                Monthly plan
                              </p>
                            </div>
                            <h2 className="mt-auto text-base-200 font-normal d-flex justify-content-start align-items-center">
                              {selectedPlanData?.planType === "Yearly" ? (
                                `$${otherPlanData?.price}`
                              ) : (
                                `$${selectedPlanData?.price}`
                              )}                              
                              <span className="text-md opacity-75 ms-1 dottedBorderBottom">
                                Per month.
                              </span>
                            </h2>
                          </div>
                          <div 
                            className={`singlePlan d-flex flex-column ${selectedPlanData?.planType === "Yearly" ? "active" : ""}`}
                            onClick={
                              () => changeBillingCycle && changeBillingCycle('year', selectedPlanData?.planName)
                            }
                          >
                            <div>
                              <p className="text-md text-uppercase text-base-200 opacity-75 font-normal mb-0">
                                Annual Plan
                              </p>
                              <span className="py-1 px-2 text-xs rounded-pill text-base-100 bg-gray-1400 font-bold ">
                                12mo for the price of 10
                              </span>
                            </div>
                            <h2 className="mt-auto text-base-200 font-normal d-flex justify-content-start align-items-center">
                              {selectedPlanData?.planType === "Monthly" ? (
                                `$${otherPlanData?.price}`
                              ) : (
                                `$${selectedPlanData?.price}`
                              )}
                              <span className="text-md opacity-75 ms-1 dottedBorderBottom">
                                {/* Per month. Billed annually */}
                                Per year. Billed annually
                              </span>
                            </h2>
                          </div>
                        </div>
                        <div className="mt-4 d-flex justify-content-between align-items-center">
                          <div>
                            <p className="text-md text-base-200 opacity-75 text-uppercase font-medium mb-2">
                              Total Due
                            </p>
                            <h2 className="mt-auto text-base-200 font-bold d-flex justify-content-start align-items-center">
                              {`$${selectedPlanData?.price}`}
                              <span className="text-md opacity-75 ms-1 dottedBorderBottom font-normal">
                                Per {selectedPlanData?.planType === "Monthly" ? "month" : "year"}.
                              </span>
                            </h2>
                          </div>
                          <div className="text-end">
                            <p className="text-lg text-gray-300 font-medium mb-2">
                              Powered by
                            </p>
                            <h2 className="text-secondary-500 font-bold">stripe</h2>
                          </div>
                        </div>
                      </div>
                    </Col>
                    <Col sm={4}>
                      {/* <div className="payCard card p-4">
                        <div className="mb-4">   
                          <p class="text-xl font-extraBold mb-0">{selectedPlanData?.planName}</p>
                          <p class="text-sm text-gray-300 mb-12 pe-5">For more users charges will be added based on per active users beyond minimum count.</p>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p class="h4 font-extraBold mb-0">{selectedPlanData?.planName}
                            <span class="text-sm text-gray-300 mb-0 d-block font-normal">$60 Per member/month<br></br>
                            billed {selectedPlanData?.planType === "Yearly" ? " annually" : " monthly"}</span>
                          </p>
                          <p class="h4 font-extraBold text-end">${selectedPlanData?.price ?? 0}
                            <span class="text-sm text-gray-300 mb-0 d-block font-normal">{selectedPlanData?.planType === "Yearly" ? "Yearly" : "Monthly"}</span>
                          </p>
                        </div>
                        <div className="d-flex justify-content-between my-3">
                          <p class="h4 font-extraBold mb-0">Billed Now
                            <span class="text-sm text-gray-300 mb-0 d-block font-normal">${selectedPlanData?.planType === "Yearly" ? selectedPlanData?.price/12 : selectedPlanData?.price} X 12 month</span>
                          </p>
                          <p class="h4 font-extraBold text-end">${selectedPlanData?.planType === "Yearly" ? selectedPlanData?.price : selectedPlanData?.price*12 ?? 0}
                            <span class="text-sm text-gray-300 mb-0 d-block font-normal"></span>
                          </p>
                        </div>
                        <div className="d-flex gap-1 mt-3"></div>
                      </div> */}

                      <div className="rightArea w-100 h-100">
                        <div className="p-32 rounded-6 bg-base-100 filterShadow-100">
                          <h2 className="text-base-200 font-extraBold">{selectedPlanData?.planName}</h2>
                          <div className="text-md pt-32 text-base-200 pt-">
                            <span className="font-bold text-uppercase d-block">
                              SEATS
                            </span>
                            <span className="d-block font-normal">
                              {selectedPlanData?.metaData?.included_users} seats
                            </span>
                          </div>
                          <div className="text-md pt-20 text-base-200">
                            <span className="font-bold text-uppercase d-block">
                              STORAGE
                            </span>
                            <span className="d-block font-normal">
                              {convertToGB(selectedPlanData?.metaData?.storage)}{" "}
                              {/* 100 MB{" "} */}
                              <label className="text-gray-1200 opacity-75">
                                per seat
                              </label>
                            </span>
                          </div>
                          <div className="text-md pt-20 text-base-200">
                            <span className="font-bold text-uppercase d-block">
                              Domain & branding
                            </span>
                            <span className="d-block font-normal">
                              {selectedPlanData?.planName == 'Business' && selectedPlanData?.metaData?.custom_branding === "Yes" ? 'Pro' : selectedPlanData?.planName == 'Startup' && selectedPlanData?.metaData?.custom_branding === "Yes" ? "Basic" : 'No'}
                            </span>
                          </div>
                          <div className="text-md pt-20 text-base-200">
                            <span className="font-bold text-uppercase d-block">
                              Content
                            </span>
                            <span className="d-block font-normal">
                              {selectedPlanData && selectedPlanData?.metaData?.process === '-1' ? 'Unlimited' : selectedPlanData?.metaData?.process} Process &{" "}
                              {selectedPlanData && selectedPlanData?.metaData?.sequence === '-1' ? 'Unlimited' : selectedPlanData?.metaData?.sequence} Sequences &{" "}
                              {selectedPlanData && selectedPlanData?.metaData?.modules === '-1' ? 'Unlimited' : selectedPlanData?.metaData?.modules} Modules
                            </span>
                          </div>
                          <div className="text-md pt-20 text-base-200">
                            <span className="font-bold text-uppercase d-block">
                              HELP and support
                            </span>
                            <span className="d-block font-normal">
                              2 Business Day turnaround
                            </span>
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer className="justify-content-start btnFooter">
                  <Button 
                    className="m-0"
                    variant="secondary"
                    type="submit"
                    disabled={!stripe || isButtonDisabled === true || values?.cardHolderName === "" || values?.country === "" || values?.addressLine1 === "" || values?.postalCode === "" || values?.city === "" || values?.state === ""}
                  >
                    Confirm
                  </Button>
                </Modal.Footer>
                {/* <Modal.Footer className="d-flex justify-content-start align-ites-center">
                  <Button size="lg" variant="secondary" className="me-3" type="submit">
                    Make Payment
                  </Button>
                  <Button
                    size="lg"
                    variant="white"
                    onClick={() => onCloseModal()}
                    className="ms-1"
                  >
                    Cancel
                  </Button>
                </Modal.Footer> */}
              </Form>
            );
          }}
        </Formik>
      )}
      <Modal
        className="modalDialog confirmatinModalExtraBg"
        size="sm"
        centered
        show={confirmPayment}
        onHide={() => setConfirmPayment(false)}
      >
        <ConfirmPlanModal
          handleModal={setConfirmPayment}
          confirm={onhandleConfirmPlan}
        />
      </Modal>
    </>
  );
};

export default PaymentCardModal;
