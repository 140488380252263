import React from 'react';
import { Modal, Button } from "react-bootstrap";
const DisableAccountModal = ({ handleModal }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
             
                <h3 className='mt-3'>Are you sure you want to disable this account?</h3>
                <p className='text-gray-300 mb-0 mt-2 mb-5 pe-5'>
                The data will remain intact and you can re-enable the account anytime.
                </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button variant="white" size="sm" onClick={() => handleModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" className='border-0'>
                    Yes, I am sure
                </Button>
            </Modal.Footer>
        </>
    );
}

export default DisableAccountModal;
