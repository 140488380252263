
import React from 'react';
import { Modal, Button } from "react-bootstrap";
import {
    IconArrow,
    PDFicon,
    PDFdownload,
    IconleftArrow,
    IconrightArrow,
  } from "../../icons/Icons";
const CertificateCovidModal = ({ handleModal, onClosed }) => {
    return (
        <>
            <Modal.Header className="d-flex justify-content-start" closeButton onClick={() => onClosed(false)}>
            <Modal.Title>All Responses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=''>
                    <div className='row d-flex justify-content-between'>
                        <div className='col-6'> <h5 className='formQus font-normal text-gray-100'><span className='count'>2.</span>
                        Upload COVID Vaccination Certificate
                            <span className='d-block text-xs text-gray-300'>200 Responses</span>
                        </h5></div>
                       
                  <div className='col-6'>
                  <ul className='paginationModal'>
                            <li className='border-right me-3'>
                            <button className=" btn-sm pBtn me-2">
                            <PDFdownload /><span className='ps-2'>Download all</span>
                     </button>
                            </li>
                            <li className='text-sm pageCount'>
                            <button className="btn btn-sm pBtn me-2">
                     <IconleftArrow/>
                     </button>
                            Question<span>1</span>of 100
                     <button className="btn btn-sm pBtn ms-2">
                     <IconrightArrow/>
                     </button>
                            </li>
                        </ul>
                  </div>
                    </div>
                    <div className="responseList">
                                  <ul className="p-0 m-0">
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                              
                                  </ul>
                                </div>    
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-start btnFooter">
        <Button
          variant="secondary"
          size="sm"
          onClick={() => onClosed(false)}
        >
          Done
        </Button>
      </Modal.Footer>
        </>
    );
}

export default CertificateCovidModal;
