import { AuthProvider } from "./providers/authProvider";
import { PrivateRoute } from "./routes/privateRoute";
import { BrowserRouter } from "react-router-dom";
import { Callback } from "./components/Auth/callback";
import { Logout } from "./components/Auth/logout";
import { LogoutCallback } from "./components/Auth/logoutCallback";
import { SilentRenew } from "./components/Auth/silentRenew";
import React, { Component } from "react";
import SideNav from "./components/SideNav/SideNav";
import { Switch, Route } from "react-router-dom";
import Dashboard from "./pages/Dashboard/Dashboard";
import UsersIndex from "./pages/Users/UsersIndex";
import AuthoringIndex from "./pages/Authoring/AuthoringIndex";
import AccountSettingIndex from "./pages/AccountSetting/AccountSettingIndex";
import ReportedIssues from "./pages/ReportedIssues/ReportedIssues";
import CatalogDetails from "./pages/StartMyTrial/CatalogDetails";
import SubscriptionIndex from "./pages/Subscription/SubscriptionIndex";
import StartMyTrialIndex from "./pages/StartMyTrial/StartMyTrialIndex";
import EmployeeAccountIndex from "./pages/EmployeeAccount/EmployeeAccountIndex";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmployeeDashboard from "./pages/Dashboard/EmployeeDashboard";
import EmployeeAccount from "./pages/EmployeeAccount/EmployeeAccount";
import NotificationView from "./pages/EmployeeAccount/NotificationView";
import Preview from "./pages/EmployeeAccount/ContentPreview/Preview";
import ContentPreview from "./pages/EmployeeAccount/ContentPreview/ContentPreview";
import LearningResources from "./pages/EmployeeAccount/LearningResources";
import MyAccount from "./pages/EmployeeAccount/MyAccount";
import { Feedback } from "@mui/icons-material";
import FeedbackEmployee from "./pages/Feedback/FeedbackEmployee";
import Oops from "./pages/Oops/Oops";
import Restricted from "./pages/Restricted/Restricted";
import FormViewResponse from "./pages/StartMyTrial/FormViewResponse";
import FormViewIndividual from "./pages/StartMyTrial/FormViewIndividual";
import { logActionAsync } from "./reducers/user-reducers";
import { getSubscriptionAsync } from "./reducers/subscription-reducers";
import { connect } from "react-redux";
import Loader from "./components/Loader/Loader";
import AuthService from "./services/AuthService";
class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      nav: false,
      userRoleName: "",
      isLoading: true,
      isRestricted: false,
    };
    this.setNav = this.setNav.bind(this);
    this.userRole = this.userRole.bind(this);
    this.fetchApiData = this.fetchApiData.bind(this);
    this.fetchSubscriptionData = this.fetchSubscriptionData.bind(this);
    this.authService = new AuthService();
  }

  componentDidMount() {
    this.fetchApiData();
    this.fetchSubscriptionData();

    this.refreshInterval = setInterval(this.fetchApiData, 15 * 60 * 1000);
    setTimeout(() => {
      this.setState({ isLoading: false });
    }, 1000);
  }

  setNav(nav) {
    this.setState({ nav });
  }

  userRole(roleName) {
    console.log(roleName, "oo12332222");
    this.setState({
      userRoleName: roleName,
    });
    // console.log("roleName 603 = ", roleName);
  }

  async fetchApiData() {
    const { dispatch } = this.props;

    const response = await dispatch(logActionAsync());
    console.error("global data:", response);
    const data = await response;
    this.setState({ apiData: data });

    // const subscriptionData = await dispatch(getSubscriptionAsync());
    // console.log(subscriptionData, 'App Subscription Data');
    // if (
    //   subscriptionData?.payload !== undefined && subscriptionData?.payload?.subscriptions?.length > 0 &&
    //   subscriptionData?.payload?.subscriptions[0]?.items?.length > 0 && subscriptionData?.payload?.subscriptions[0]?.status === 'active'
    // ) {
    //   this.setState({ isRestricted: false });
    // } else {
    //   this.setState({ isRestricted: true });
    // }
  }
  async fetchSubscriptionData() {
    const { dispatch } = this.props;
    console.log(this.props, "this.props");
    await dispatch(getSubscriptionAsync()).then((res) => {
      console.log(res?.payload, "App Subscription Data");
      if (
        res?.payload !== undefined &&
        res?.payload?.subscriptions?.length > 0 &&
        res?.payload?.subscriptions[0]?.items?.length > 0 &&
        (res?.payload?.subscriptions[0]?.status === "active" ||
          res?.payload?.subscriptions[0]?.status === "trialing")
      ) {
        res?.payload?.subscriptions[0]?.items[0]?.plan?.active === true
          ? this.setState({ isRestricted: false })
          : this.setState({ isRestricted: true });
      } else {
        this.setState({ isRestricted: true });
      }
    });
    // const subscriptionData = await dispatch(getSubscriptionAsync());
    // console.log(subscriptionData, 'App Subscription Data');
    // if (
    //   subscriptionData?.payload !== undefined && subscriptionData?.payload?.subscriptions?.length > 0 &&
    //   subscriptionData?.payload?.subscriptions[0]?.items?.length > 0 && (subscriptionData?.payload?.subscriptions[0]?.status === 'active' || subscriptionData?.payload?.subscriptions[0]?.status === 'trialing')
    // ) {
    //   subscriptionData?.payload?.subscriptions[0]?.items[0]?.plan?.active === true ? this.setState({ isRestricted: false }) : this.setState({ isRestricted: true }) ;
    // } else {
    //   this.setState({ isRestricted: true });
    // }
  }

  render() {
    console.log(this?.state?.isRestricted, "this?.state?.isRestricted");
    return (
      <AuthProvider>
        {/* Needs refactoring */}
        {this.state.isLoading && (
          <div className="loader-container bg-white">
            <Loader />
          </div>
        )}
        {this.state.isLoading == false && (
          <BrowserRouter>
            <div className="App">
              <ToastContainer newestOnTop />
              <div className={this.state.nav ? "contect navOpen" : "contect"}>
                {this.authService.isAuthenticated() === true && (
                  <div>
                    <SideNav
                      nav={this.state.nav}
                      setNav={this.setNav}
                      checkUserRole={this.userRole}
                      isRestricted={this.state.isRestricted}
                    />
                  </div>
                )}

                <Switch>
                  <Route
                    exact={true}
                    path="/signin-oidc"
                    component={Callback}
                  />
                  <Route
                    exact={true}
                    path="/silentrenew"
                    component={SilentRenew}
                  />

                  {/* <Route path="/accountsetting" component={AccountSettingIndex} /> */}
                  <Route
                    path="/accountsetting"
                    render={(props) => (
                      <AccountSettingIndex
                        {...props}
                        isRestricted={this?.state?.isRestricted}
                      />
                    )}
                  />

                  {/* <PrivateRoute exact key='/' path='/' render={(props) => <Restricted {...props} userType={this?.state?.userRoleName}  />} /> */}
                  {this?.state?.isRestricted === true &&
                    (this.state.userRoleName === "" ||
                      this.state.userRoleName === "Super Admin" ||
                      this.state.userRoleName === "Admin" ||
                      this.state.userRoleName === "Employee") && (
                      <PrivateRoute exact path="/" component={Restricted} />
                    )}

                  {/* <Route path="/authoring" component={AuthoringIndex} /> */}
                  <Route path="/authoring">
                    {this?.state?.isRestricted === false ? (
                      <AuthoringIndex />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  {this?.state?.isRestricted === false &&
                    (this.state.userRoleName === "" ||
                      this.state.userRoleName === "Super Admin" ||
                      this.state.userRoleName === "Admin") && (
                      <PrivateRoute
                        exact
                        path="/"
                        component={Dashboard}
                        isRestricted={this?.state?.isRestricted}
                      />
                    )}
                  {this?.state?.isRestricted === false &&
                    process.env.REACT_APP_RESTRICT_EMP_LOGIN &&
                    this.state.userRoleName === "Employee" && (
                      <>
                        {/* <Route path="/" component={EmployeeAccountIndex} /> */}
                        <PrivateRoute
                          exact
                          path="/"
                          component={EmployeeDashboard}
                          isRestricted={this?.state?.isRestricted}
                        />
                        <PrivateRoute
                          exact
                          path="/feedback/:id/:previewType/:title"
                          component={FeedbackEmployee}
                          isRestricted={this?.state?.isRestricted}
                        />
                        <PrivateRoute
                          exact
                          path="/catalogue"
                          component={EmployeeAccount}
                          isRestricted={this?.state?.isRestricted}
                        />
                        <PrivateRoute
                          exact
                          path="/catalogue"
                          component={NotificationView}
                          isRestricted={this?.state?.isRestricted}
                        />
                        <PrivateRoute
                          exact
                          path="/catalogue/preview/:parentId/:assignmentId/:assignmentSegId/:id/:previewType/:contentType/:title"
                          component={ContentPreview}
                          isRestricted={this?.state?.isRestricted}
                        />
                      </>
                    )}

                  <Route path="/users">
                    {this?.state?.isRestricted === false ? (
                      <UsersIndex />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  <Route path="/subscription" component={SubscriptionIndex} />

                  <Route path="/reportedIssues">
                    {this?.state?.isRestricted === false ? (
                      <ReportedIssues />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  <Route path="/trial">
                    {this?.state?.isRestricted === false ? (
                      <StartMyTrialIndex />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  <Route path="/CatalogDetails">
                    {this?.state?.isRestricted === false ? (
                      <CatalogDetails />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  <Route path="/FormView">
                    {this?.state?.isRestricted === false ? (
                      <FormViewResponse />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  <Route path="/Individual">
                    {this?.state?.isRestricted === false ? (
                      <FormViewIndividual />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  <Route
                    path="/employeeaccount/myaccount"
                    component={MyAccount}
                  />

                  <Route exact path="/feedback">
                    {this?.state?.isRestricted === false ? (
                      <FeedbackEmployee />
                    ) : (
                      <Restricted userType={this.state.userRoleName} />
                    )}
                  </Route>

                  {[
                    {
                      key: "/oops",
                      component: {
                        heading: "Oops!",
                        summary: `You do not have an account because it has been deleted or\n deactivated. If you believe this was an error, please contact us\n through our help center`,
                      },
                    },
                    {
                      key: "/unauthorized",
                      component: {
                        heading: "Unauthorized",
                        summary: `You are not authorized to view the page you are trying to\n access either you don't have enough permission or your account is deactivated, please contact us\n through our help center`,
                      },
                    },
                    // {key:'', component: {heading: "404", summary: `The page you are looking for is either not available or\n the path has been changed, please contact us\n through our help center`}}
                  ].map((path) => (
                    <Route
                      key={path.key}
                      path={path.key ? path.key : undefined}
                      render={(props) => (
                        <Oops {...props} {...path.component} />
                      )}
                    />
                  ))}
                </Switch>
              </div>
            </div>
          </BrowserRouter>
        )}
      </AuthProvider>
    );
  }
}

export default connect()(App);
