import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Button, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  IconExit,
  IconSkip,
  IconBreadcrumArrow,
  IconUploadFile,
  IconReportIssue,
} from "../../../icons/Icons";
import { questionType } from "../../../helper/questionType";
import { Formik, Form } from "formik";
import TextInput from "../../../components/FormikInputs/TextInput";
import Loader from "../../../components/Loader/Loader";
import { useDispatch } from "react-redux";
import TextArea from "../../../components/FormikInputs/TaxtArea";
import CheckBoxMd from "../../../components/CheckBox/CheckBoxMd";
import MultipleChoice from "./FormType/MultipleChoice";
import ShortAnswer from "./FormType/ShortAnswer";
import Paragraph from "./FormType/Paragraph";
import Checkboxes from "./FormType/Checkboxes";
import UploadFile from "./FormType/UploadFile";
import DateType from "./FormType/DateType";
import TimeType from "./FormType/TimeType";
import Dropdown from "./FormType/Dropdown";
import { mapStatusToValue, getRemainingStringFileName } from "../../../common";
import { IconPPT } from "../../../icons/Icons";
import DownloadLink from "./DownloadLink";

const FormPreview = ({ data, setContent, actionReport, allData, userProfiles, currentState }) => {
  const [show, setShow] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [formDataReady, setFormDataReady] = useState(true);
  const formRef = useRef();
  const dispatch = useDispatch();
  const target = useRef(null);
  const arrayDownloadableFiles = data?.downloadableFile

  function isValidPhoto(fileName) {
    if (fileName !== undefined) {
      var allowed_extensions = new Array("jpg", "png", "jpeg");
      var file_extension = fileName.split(".").pop().toLowerCase();
      for (var i = 0; i <= allowed_extensions.length; i++) {
        if (allowed_extensions[i] == file_extension) {
          return true;
        }
      }
    }
    return false;
  }

  function isVideo(filename) {
    if (filename !== undefined) {
      var ext = filename.split(".").pop().toLowerCase();
      switch (ext.toLowerCase()) {
        case "m4v":
        case "mp4":
        case "mov":
          return true;
      }
    }
    return false;
  }
  console.log(data,"data")
console.log(data.index,"data.index")
  return (
    <>
      <div className='pages'>
        <div className='pageOuter p-0 bg-base-100'>
          <div className='d-flex flex-column h-100'>
            <div className='w-100 flex-grow-1 h-100 overflow-auto'>
              <Row className='g-0 d-flex h-100'>
                <Col className='col-lg-9 flex-grow-1 overflow-auto h-100'>
                  <div className='p-32 pb-4'>

                    <div className='rounded-6 border'>
                      <div className='p-32 bg-gray-600 '>
                        <div className='catlogHeading d-flex px-2'>
                          <div className='count'>
                            <span className='roundCount'>{currentState + 1}</span>
                          </div>
                          <div className='ms-3 ps-1'>
                            <h3>{data.title}</h3>
                            <p className='text-lg text-gray-200 pt-1 pb-1 mb-2 text-break'>{data.description}</p>
                            {/* <p className='text-12 m-0 text-gray-300'>Expiring on <span className='text-base-200'>Jul 26</span></p> */}
                          </div>
                        </div>
                      </div>

                      {/* <div className='p-32'>
                                        <div className='textList mb-4'>
                                            <h5>Analytics home</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>The module helps the users to understand their expenses deeply. It provides users with a visual representation of their expenses till date. It provides different and smart insights for better decision making of further expenses.</p>
                                        </div>
                                        <div className='textList mb-4'>
                                            <h5>Total Expenses</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>Total expense is a visual representation of all the expenses done in past months and till date. By default, it shows the expenses of all the categories of the current month. To view the expenses of other months, the user has to click on the month given on the bar graph.<br></br>
Expensive week/month/quarter: Within the total expenses, the user gets a detailed tour of their expenses. The chart shows a visual representation of the most expensive wee/month/quarter by selecting the filter. It also shows major contributors of the most expensive week/month/quarter. By clicking on the particular week/month/quarter, users can see the contributor categories of that period which helps them to analyze their expenses.</p>
                                        </div>
                                        <div className='textList mb-4'>
                                            <h5>Summary</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>The module helps the users to understand their expenses deeply. It provides users with a visual representation of their expenses till date. It provides different and smart insights for better decision making of further expenses.</p>
                                        </div>
                                    </div> */}

                      <div className=''>
                        {formDataReady && (
                          <Formik
                            initialValues={{}}
                            //   onSubmit={onSubmit}
                            //   validate={Validation}
                            innerRef={formRef}
                          >
                            {({
                              errors,
                              touched,
                              isValidating,
                              values,
                              setFieldValue,
                              setTouched,
                              validateField,
                            }) => {
                              return (
                                <div className="pages formView">
                                  <div className="pageOuter pb-0">
                                    <Row className="g-0 h-100">
                                      <Form style={{ display: "contents" }}>
                                        <Col className="col-lg-12 bg-base-100">
                                          <div className="pb-5 pt-3">
                                            <div>
                                              {data.questions.map((item, index) => (
                                                <div className="videoPreview fullCol">
                                                  {questionType.ShortAnswer ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput">
                                                          <ShortAnswer title={item.question} serialNo={index + 1} questionsFiles={item.questionsFiles} isRequired={item.isRequired} />
                                                        </div>
                                                      </div>
                                                    )}

                                                  {questionType.Paragraph ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput">
                                                          <Paragraph title={item.question} serialNo={index + 1} questionsFiles={item.questionsFiles} isRequired={item.isRequired} />
                                                        </div>
                                                      </div>
                                                    )}

                                                  {questionType.Checkboxes ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput">
                                                          <Checkboxes title={item.question} options={item.options} serialNo={index + 1} questionsFiles={item.questionsFiles} isRequired={item.isRequired} />
                                                        </div>
                                                      </div>
                                                    )}

                                                  {questionType.MultipleChoice ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput">
                                                          <MultipleChoice title={item.question} options={item.options} serialNo={index + 1} questionsFiles={item.questionsFiles} isRequired={item.isRequired} />
                                                        </div>
                                                      </div>
                                                    )}


                                                  {questionType.FileUpload ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput">
                                                          <UploadFile title={item.question} serialNo={index + 1} questionsFiles={item.questionsFiles} isRequired={item.isRequired} data={item} />
                                                        </div>
                                                      </div>
                                                    )}

                                                  {questionType.Date ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput calpr">
                                                          <DateType title={item.question} serialNo={index + 1} questionsFiles={item.questionsFiles} isRequired={item.isRequired} />
                                                        </div>
                                                      </div>
                                                    )}

                                                  {questionType.Time ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput timepr">
                                                          <TimeType title={item.question} serialNo={index + 1} questionsFiles={item.questionsFiles} isRequired={item.isRequired} />
                                                        </div>
                                                      </div>
                                                    )}

                                                  {questionType.Dropdown ===
                                                    item.questionType && (
                                                      <div className="col-7">
                                                        <div className="input-group myInput dropValue">
                                                          <Dropdown title={item.question} serialNo={index + 1} options={item.options} questionsFiles={item.questionsFiles} isRequired={item.isRequired} />
                                                        </div>
                                                      </div>
                                                    )}

                                                  {/* <Button variant="primary" className="border-0">
                                  Submit
                                </Button> */}
                                                </div>
                                              ))}
                                            </div>
                                          </div>
                                        </Col>
                                      </Form>
                                    </Row>
                                  </div>
                                </div>
                              );
                            }}
                          </Formik>
                        )}
                      </div>

                    </div>


                  </div>
                </Col>
                <Col className='col-lg-3 flex-grow-1 h-100 catalogSidebar'>
                  <div className='bg-gray-600 p-20 py-32 h-100 borderLeft2 overflow-auto'>
                    <h5 className='pb-2'>Next in queue</h5>
                    {allData && allData.length > 0 && allData.map((item, index) => (
                      <div key={item.id}>
                        {item.form ? (
                          <div className={`catlogHeading d-flex my-3 ${currentState === index ? 'active' : ''}`}>
                            <div className='count'>
                              <span className='roundCount'>{index + 1}</span>
                            </div>
                            <div className='ms-1 px-3 acContent'>
                            {/* <div className={`ms-1 px-3 acContent ${data.index === index + 1 ? 'active' : ''}`}> */}
                              {/* <span class="tableTag text-md mb-2">{mapStatusToValue(item.status)}</span> */}
                              <h4>{item.form=== null ? item.page.title: item.form.title}</h4>
                            </div>
                          </div>
                        ) : (
                          <Accordion defaultActiveKey={currentState=== index ? index : null} className='catlogAccordion'>
                            <Accordion.Item eventKey={index} disabled={true} className="mb-3" key={item.id}>
                              <Accordion.Header>
                              <div className={`catlogHeading d-flex my-3 ${currentState === index ? 'active' : ''}`}>
                                  <div className='count'>
                                    <span className='roundCount'>{index + 1}</span>
                                  </div>
                                  <div className='ms-1 px-3 acContent'>
                                    {/* <span class="tableTag text-md mb-2">{mapStatusToValue(item.status)}</span> */}
                                    <h4>{item.page === null ? item.form.title: item.page.title}</h4>
                                  </div>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                <div className="mb-4">
                                  <h6 className="mb-20">Resources</h6>
                                  {item?.downloadableFile !== undefined &&
                                    item?.downloadableFile !== null &&
                                    item?.downloadableFile.length > 0 &&
                                    item?.downloadableFile.map((value, index, array) => (
                                      <div className="d-flex justify-content-between align-items-center mb-20">
                                        <div className="d-flex gap-3 align-items-center w-100">
                                          <div>
                                            <IconPPT />
                                          </div>
                                          <div className="w-100 pe-4">
                                            <p className="singleLineEc mb-0 text-break text-sm">
                                              {/* {value.filePath} */}
                                              {getRemainingStringFileName(value.filePath)}
                                            </p>
                                            {/* <p className="mb-0 text-gray-300 text-12 pt-1">1.27 MB</p> */}
                                          </div>
                                        </div>
                                        <DownloadLink url={value.filePath} />
                                      </div>
                                    ))}

                                  {arrayDownloadableFiles === undefined ||
                                    arrayDownloadableFiles.length === 0 ? (
                                    <div className="p-20 bg-base-100 rounded-6">
                                      <p className="text-md text-gray-200">Looks like no resources have been added yet.</p>
                                    </div>
                                  ) : null}
                                </div>
                                <div className="mb-0">
                                  <h6 className="mb-20">Message from Author</h6>
                                  <div className="p-20 bg-base-100 rounded-6">
                                    <p className="text-md text-gray-200">
                                    {item?.page?.messages && item?.page?.messages[0]?.message ? item?.page?.messages[0]?.message : 'Looks like the author has not added any message yet.'}
                                    </p>
                                  </div>
                                </div>
                              </Accordion.Body>
                            </Accordion.Item>
                          </Accordion>
                        )}
                      </div>
                    ))}

                    {userProfiles && userProfiles.roleName === "Employee" && (
                      <div className="d-flex gap-2 align-items-center">
                        <div>
                          <IconReportIssue />
                        </div>

                        <button
                          className="text-primary-200 h6 font-bold"
                          onClick={() => {
                            setContent(data);
                            actionReport(true);
                          }}
                        >
                          Report an issue
                        </button>
                      </div>
                    )}
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <> {showLoader && <Loader />}</>
    </>
  );
};
export default FormPreview;
