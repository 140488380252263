import React, { useState, useEffect } from "react";
import { NavLink, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getEmpTeamListAsync,
  createTeamAsync,
  getTeamsAsync,
} from "../../../reducers/user-reducers";
import Search from "../../../components/Search/Search";
import { Button } from "react-bootstrap";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";

const ManageTeamsNav = ({
  setSelectedTeam,
  selectedTeam,
  isTeamUpdated,
  setIsTeamUpdated,
}) => {
  const dispatch = useDispatch();
  const entities = useSelector((state) => state.user.empTeamList);

  const [invalidTeam, setInvalidTeam] = useState(false);
  const [searchString, setSearchString] = useState("");
  
  const teams = [
    {
      key: "2d64c795-aaa2-4dd4-9450-a5731bb5691c",
      value: "Circle Team",
    },
    {
      key: "52e872f4-d228-471a-9029-b57803cf342d",
      value: "HR Department",
    },
  ];
  const onSearchStringChange = (value) => {
    setSearchString(value);
    setInvalidTeam(false);
  };

  const getTeams = () => {
    const reqBody = {
      searchString: searchString,
    };
    dispatch(getEmpTeamListAsync(reqBody)).then(() => {
      setIsTeamUpdated(false);
    });
  };

  const createTeam = () => {
    if (entities.find((item) => item.name === searchString)) {
      setInvalidTeam(true);
    } else {
      const reqBody = {
        name: searchString,
      };
      dispatch(createTeamAsync(reqBody)).then((res) => {
        ToasterAlertSucces(res, ErrrorMessage.CREATE);
        dispatch(getTeamsAsync()).then((response) => {
          const updatedTeams = response.payload;
          const newlyCreatedTeam = updatedTeams.find(
            (team) => team.value === searchString
          );
  
          if (newlyCreatedTeam) {
            setSelectedTeam({
              id: newlyCreatedTeam.key,
              name: newlyCreatedTeam.value,
            });
          }
  
          setIsTeamUpdated(true);
        });
        setTimeout(() => {
          setSearchString("");
        }, 1000);
      });
    }
  };
  
  useEffect(() => {
    if (isTeamUpdated) {
      getTeams();
    }
  }, [isTeamUpdated]);

  useEffect(() => {
    if (entities?.length === 0 || isTeamUpdated) {
      getTeams();
    }
    if (!selectedTeam && entities.length > 0) {
      setSelectedTeam(entities[0]);
    }
  }, [entities]);

  const handleEnterKeyPress = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      if (searchString.trim() !== "") {
        createTeam();
      }
    }
  };

  return (
    <div className="manageTeamsNav">
      <div className="text-md font-bold text-gray-300 mb-3">ALL TEAMS</div>
      <form
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="d-flex gap-3">
          <Search
            onBlur={onSearchStringChange}
            placeHolder="Enter team name"
            searchString={searchString}
            onKeyPress={handleEnterKeyPress}
          />
          {searchString && (
            <button
              type="button"
              onClick={createTeam}
              className="btn-secondary btn-sm"
            >
              Add
            </button>
          )}
        </div>
        <div className="mt-1">
          {invalidTeam && (
            <small className="text-md" style={{ color: "red" }}>
              Team already exists!
            </small>
          )}
        </div>
      </form>

      <div className="pt-3 manageTeamsNavLinks">
        {entities?.length > 0 &&
          entities
            ?.filter((item) =>
              item.name.toLowerCase().includes(searchString.toLowerCase())
            )
            .map((item) => {
              const isSelected =
                selectedTeam && item.id === selectedTeam.id;
              return (
                <Link
                  to="#"
                  exact
                  className={`h6 text-gray-300 ${isSelected ? "active" : ""}`}
                  onClick={() => setSelectedTeam(item)}
                  key={item.id}
                >
                  <span className="linkRing border-secondary-300"></span>
                  {item.name} ({item.membersCount})
                </Link>
              );
            })}
      </div>
    </div>
  );
};

export default ManageTeamsNav;
