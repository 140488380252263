import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllModule,
  createModule,
  updateModule,
  addUpdateQuestion,
  addUpdateForm,
  addUpdatePage,
  deleteModule,
  getModuleDetails,
  getModulePageDetails,
  getModuleFormDetails,
  getModulePageForms,
  getModulePreview,
  checkUniqueness,
  checkUniquenessTemp,
  bulkDeleteModules,
  bulkArchiveModules,
  getModuleFeedbackDetails,
  createModuleDraft,
  getModuleTemplates,
  deleteModulePage,
  deleteModuleForm,
  getSortedPageForms,
} from "../api/module-store-api";

const initialState = {
  modules: { records: [] },
  loading: false,
  page: 0,
  pageSize: 10,
  searchString: "",
  orderBy: "",
  isOrderByDesc: true,
  status: [],
  tags: [],
  modulesSave: null,
  moduleUpdate: null,
  moduleDelete: null,
  currentmodule: null,
};

export const getModuleAsync = createAsyncThunk(
  "module/getModuleAll",
  async (request) => {
    const response = await getAllModule(request);
    //console.log(response.data);
    return response.data;
  }
);

export const createModuleAsync = createAsyncThunk(
  "module/createmodule",
  async (request) => {
    const response = await createModule(request);
    return response.data;
  }
);

export const createModuleDraftAsync = createAsyncThunk(
  "module/createModuleDraft",
  async (request) => {
    const response = await createModuleDraft(request);
    return response.data;
  }
);

export const addUpdateQuestionAsync = createAsyncThunk(
  "module/addUpdateQuestion",
  async (request) => {
    const response = await addUpdateQuestion(request);
    return response.data;
  }
);

export const addUpdatePageAsync = createAsyncThunk(
  "module/addUpdateQuestion",
  async (request) => {
    const response = await addUpdatePage(request);
    return response.data;
  }
);


export const addUpdateFormAsync = createAsyncThunk(
  "module/addUpdateForm",
  async (request) => {
    const response = await addUpdateForm(request);
    return response.data;
  }
);

export const updateModuleAsync = createAsyncThunk(
  "module/updateModule",
  async (request) => {
    const response = await updateModule(request);
    return response.data;
  }
);

export const deleteModuleAsync = createAsyncThunk(
  "module/deleteModules",
  async (request) => {
    const response = await deleteModule(request);
    return response.data;
  }
);

export const deleteModulePageAsync = createAsyncThunk(
  "module/deleteModules",
  async (request) => {
    const response = await deleteModulePage(request);
    return response.data;
  }
);

export const deleteModuleFormAsync = createAsyncThunk(
  "module/deleteModules",
  async (request) => {
    const response = await deleteModuleForm(request);
    return response.data;
  }
);

export const bulkDeleteModulesAsync = createAsyncThunk(
  "module/bulkDeleteModulesAsync",
  async (request) => {
    const response = await bulkDeleteModules(request);
    return response.data;
  }
);

export const bulkArchiveModulesAsync = createAsyncThunk(
  "module/bulkArchiveModulesAsync",
  async (request) => {
    const response = await bulkArchiveModules(request);
    return response.data;
  }
);
export const getModuleDetailsAsync = createAsyncThunk(
  "module/getModuleDetailsAsync",
  async (id) => {
    const response = await getModuleDetails(id);
    return response.data;
  }
);

export const getModulePageDetailsAsync = createAsyncThunk(
  "module/getModulePageDetailsAsync",
  async (id) => {
    const response = await getModulePageDetails(id);
    return response.data;
  }
);

export const getModuleFormDetailsAsync = createAsyncThunk(
  "module/getModuleFormDetailsAsync",
  async (id) => {
    const response = await getModuleFormDetails(id);
    return response.data;
  }
);

export const getModuleFeedbackDetailsAsync = createAsyncThunk(
  "module/getModuleFeedbackDetailsAsync",
  async (id) => {
    const response = await getModuleFeedbackDetails(id);
    return response.data;
  }
);

export const getModuleTemplatesAsync = createAsyncThunk(
  "module/getModuleTemplates",
  async (request) => {
    const response = await getModuleTemplates(request)
    return response.data;
  }
);

export const getgetModulePageFormsAsync = createAsyncThunk(
  "module/getgetModulePageFormsAsync",
  async (id) => {
    const response = await getModulePageForms(id);
    return response.data;
  }
);
export const checkUniquenessAsync = createAsyncThunk(
  "module/checkUniqueness",
  async (request) => {
    const response = await checkUniqueness(request);
    return response.data;
  }
);

export const checkUniquenessTempAsync = createAsyncThunk(
  "module/checkUniquenessTemp",
  async (request) => {
    const response = await checkUniquenessTemp(request);
    return response.data;
  }
);

export const getModulePreviewAsync = createAsyncThunk(
  "processes/getModulePreviewAsync",
  async (id) => {
    const response = await getModulePreview(id);
    return response.data;
  }
);

export const getSortedPageFormsAsync = createAsyncThunk(
  "module/getSortedPageForms",
  async (id) => {
    const response = await getSortedPageForms(id);
    return response.data;
  }
);
const moduleSlice = createSlice({
  name: "module",
  initialState,
  reducers: {},
  // reducers:{
  //     getPageData: (state,action)=>{
  //         state.notifications = action;
  //     },
  // },
  extraReducers: {
    [getModuleAsync.pending]: () => {
      console.log("pending");
    },
    [getModuleAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, modules: payload };
    },
    [getModuleTemplatesAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, moduleTemplate: payload };
    },
    [getModuleAsync.rejected]: () => {
      console.log("rejected");
    },
    [getModuleDetailsAsync.pending]: () => {},
    [getModuleDetailsAsync.fulfilled]: (state, { payload }) => {
      return { ...state, currentmodule: payload };
    },
    [getgetModulePageFormsAsync.rejected]: () => {},
    [getgetModulePageFormsAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, pageFormsList: payload };
    },
    [getSortedPageFormsAsync.rejected]: () => {},
    [getSortedPageFormsAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, sortPageFormsList: payload };
    },
    // [createProcesses.pending]: () => {
    //   console.log("pending save processes");
    // },
    // [createProcesses.fulfilled]: (state, { payload }) => {
    //   console.log("full filled", payload);
    //   if (payload.status === 200) {
    //     let result = [
    //       "Processes save successfully!",
    //       new Date().toLocaleDateString(),
    //       payload.data,
    //     ];
    //     return { ...state, processesSave: result };
    //   } else {
    //     let result = [
    //       "Unable to save processes!",
    //       new Date().toLocaleDateString(),
    //       payload.data,
    //     ];
    //     return { ...state, processesSave: result };
    //   }
    // },
    // [createProcesses.rejected]: () => {
    //   console.log("rejected save processes");
    // },
  },
});

//export const { processesAdded, processesUpdated, processesDeleted } = processesSlice.actions;

export default moduleSlice.reducer;
