import React from "react";
import { useField } from "formik";

const TextArea = ({ label, isRequired, maxCharCount, ...props }) => {
  const [field, meta] = useField(props);

  const handleTextAreaChange = (event) => {
    const newValue = event.target.value;
    if (!maxCharCount || newValue.length <= maxCharCount) {
      field.onChange(event);
    }
  };

  const text = field.value || "";
  const charCount = text.length;
  const remainingChars = maxCharCount ? maxCharCount - charCount : null;

  return (
    <div>
      <label htmlFor={props.id || props.name}>
        {label} {isRequired && <span style={{ color: "red" }}>*</span>}
      </label>
      <textarea
        className="text-area"
        {...field}
        {...props}
        onChange={handleTextAreaChange}
      />
      {maxCharCount !== undefined && (
        <div className="char-count">
          Remaining characters: {remainingChars}/{maxCharCount}
        </div>
      )}
      {meta.touched && meta.error ? (
        <div className="error">{meta.error}</div>
      ) : null}
    </div>
  );
};

export default TextArea;
