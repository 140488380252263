import React, { useState, useEffect } from "react";
import { IconDownloadBlue } from "../../../icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { getCustomerInvoicesAsync } from "../../../reducers/subscription-reducers";
import { getInvoicesPdf } from "../../../api/subscription-api";
import moment from "moment";
import inVoiceEmptyPrimary from "../../../assets/images/inVoiceEmptyPrimary.svg";

const AllInvoices = ({ isTrialingPlan, trialingDays }) => {

  const dispatch = useDispatch();
  const { paymentData, pdfUrl } = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(getCustomerInvoicesAsync());
  }, []);

  // const handleDownloadInvoice = async (id) => {
  //   if (id) {
  //     let res = await getInvoicesPdf(id);
  //     console.log(res, 'getInvoicesPdf');
  //     if (res.data?.downloadUrl) {
  //       const response = await fetch(res.data?.downloadUrl);
  //       const blob = await response.blob();
  //       const url = URL.createObjectURL(blob);
  //       const link = document.createElement("a");
  //       link.href = url;
  //       link.download = "invoice.pdf";
  //       document.body.appendChild(link);
  //       link.click();
  //       document.body.removeChild(link);
  //       URL.revokeObjectURL(url);
  //     }
  //   }
  // };
  console.log(paymentData,'paymentData');

  return (
    <>
      {isTrialingPlan && (
        <div className="w-100 h-100 d-flex justify-content-center align-items-center">
            <div className="text-center">
                <img src={inVoiceEmptyPrimary} alt="" className="mb-1" />
                <p className="m-0 text-lg text-base-200 font-bold mt-2">No Invoices Available As Yet</p>
                <p className="m-0 text-lg text-gray-300 font-normal mt-1">You are on a {trialingDays}-day Trial. Your invoices are available once<br></br> you start your subscription after the trial.</p>
            </div>
        </div>
      )}
      {!isTrialingPlan && paymentData && paymentData.length > 0 && (
        <div className="allInvoices text-sm overflow-auto">
          <table className="table">
            <thead>
              <tr>
                <th>DATE</th>
                <th>PAYMENT METHOD</th>
                {/* <th>TRANSCATION DETAILS</th> */}
                <th>SERVICE PERIOD</th>
                <th>AMOUNT</th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {paymentData?.map((payment) => {
                return (
                  <tr>
                    <td>{moment(payment?.created).format("MMM D, YYYY")}</td>
                    <td>{payment?.status === 'paid' && payment?.total > 0 ? `**** **** **** ${payment?.paymentIntent?.paymentMethod?.card?.last4}` : '-'}</td>
                    {/* <td>
                      Monthly Charges - Start Up Plan
                    </td> */}
                    <td>
                      {payment?.lines?.length > 0 && (
                        <>
                          {moment(payment?.lines[0]?.period?.start).format(
                            "MMM D, YYYY "
                          )}{" "}
                          -{" "}
                          {moment(payment?.lines[0]?.period?.end).format(
                            "MMM D, YYYY"
                          )}
                        </>
                      )}
                    </td>
                    <td>
                      ${payment?.total / 100}
                    </td>
                    <td>
                      {payment.status === "open" ? (
                        <span className="bg-pastel-200 text-md rounded-pill text-secondary-600 px-10 py-1 font-medium">
                          Failed
                        </span>
                      ) : (
                        <span className="bg-pastel-500 text-md rounded-pill text-secondary-300 px-10 py-1 font-medium">
                          {payment?.status && payment.status.charAt(0).toUpperCase() + payment.status.slice(1)}
                        </span>
                      )}
                      {/* <span className="bg-pastel-500 text-md rounded-pill text-secondary-300 px-10 py-1 font-medium">Paid</span> */}
                    </td>
                    <td>
                      <a
                        // onClick={() => {
                        //   handleDownloadInvoice(payment?.id);
                        // }}
                        // href={payment?.invoicePdf}
                        href={payment?.hostedInvoiceUrl}
                        className="h6 text-secondary-500 font-bold d-flex gap-2"
                        target="_blank"
                      >
                        <IconDownloadBlue />
                        {" "}Invoice
                      </a>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
    </>
  );
};

{/* <tr>
  <td>12/1/24</td>
  <td>**** **** **** 9747</td>
  <td>Monthly Charges - Start Up Plan</td>
  <td>12/1 - 12/2, 2024</td>
  <td><span className="bg-pastel-500 text-md rounded-pill text-secondary-300 px-10 py-1 font-medium">Paid</span></td>
  <td>$899.99</td>
  <td>
    <button className="h6 text-secondary-500 font-bold d-flex gap-2">
      <IconDownloadBlue />
      Invoice
    </button>
  </td>
</tr>
<tr>
  <td>12/1/24</td>
  <td>**** **** **** 9747</td>
  <td>Monthly Charges - Start Up Plan</td>
  <td>12/1 - 12/2, 2024</td>
  <td><span className="bg-pastel-100 text-md rounded-pill text-primary-100 px-10 py-1 font-medium">Failed</span></td>
  <td>$899.99</td>
  <td>
    <button className="h6 text-secondary-500 font-bold d-flex gap-2">
      <IconDownloadBlue />
      Invoice
    </button>
  </td>
</tr> */}

export default AllInvoices;
