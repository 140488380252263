import React, { useEffect, useState, useRef } from "react";
import AuthoringNav from "../AuthoringNav";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import {
  IconHamberger,
  IconStar,
  IconDelete,
  IconArchive,
  IconAssign,
  IconleftArrow,
  IconrightArrow,
} from "../../../icons/Icons";
import AccountImg from "../../../assets/images/Account.png";
import Search from "../../../components/Search/Search";
import Filters from "../../../components/Filters/Filters";
import { Link, useLocation, useHistory } from "react-router-dom";
import CheckBox from "../../../components/CheckBox/CheckBox";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import {
  getFormatDate,
  Status,
  prepareSortFilterRequest,
  authoringSortby,
} from "../../../common";
import {
  getSquenceAsync,
  bulkDeleteSequenceAsync,
  bulkArchiveSequenceAsync,
} from "../../../reducers/sequence-reducers";
import { useDispatch, useSelector } from "react-redux";
import { getStatusEnumListAsync } from "../../../reducers/common-reducers";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../../components/ModalPopup/ModalResponsePopup";
import EmployeeAssignementPopup from "../../../components/EmployeeAssignementPopup/EmployeeAssignementPopup";
import { AssignmentActionType } from "../../../services/DataConstants";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import Loader from "../../../components/Loader/Loader";
import EmptyState from "../../../components/EmptyState/EmptyState";
import UserImage from "../../../components/UserImage/UserImage";
import TemplateList from "../TemplateList";
import getInitialsInName from "../../../helper/GetNameInitials";
import Pagination from "../../../components/Pagination/Pagination";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader";

import RestrictedUpgradeToBusinessModalPopup from "../../../components/ModalPopup/RestrictedUpgradeToBusinessModalPopup";

import { getSubscriptionAsync } from "../../../reducers/subscription-reducers";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const Sequences = () => {
  const location = useLocation();

  const [searchString, setSearchString] = useState("");
  const [selectedStatus, setStatusList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [totalPages, setTotalPages] = useState(1);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });

  const [responsePopup, setResponsePopup] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [formDataReady, setFormDataReady] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [assignModalAlert, setAssignModalAlert] = useState(false);
  const [selectedDataList, setSelectedDataList] = useState([]);
  const [templateModal, setTemplateModal] = useState(false);
  const [selectedSequencesForAssignList, setSelectedSequencesForAssignList] =
    useState([]);
  const [selectedSequenceForDelete, setSelectedSequenceForDelete] = useState(
    []
  );
  const [isDeleteButton, setIsDeleteButton] = useState(false);
  const [isAssignButton, setIsAssignButton] = useState(true);
  const [isArchieveButton, setIsArchieveButton] = useState(true);
  const [filterss, setFilterss] = useState(false);

  const [isBulkAssign, setIsBulkAssign] = useState(false);
  const [isBulkUpdate, setIsBulkUpdate] = useState(false);
  const [isRestrictedModalPopup, setIsRestrictedModalPopup] = useState(false);

  const tableRef = useRef(null);

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    setIsAssignButton(false);
    setSelectedItemList([]);
    setSelectedDataList([]);
    setSelectedSequencesForAssignList([]);
    scrollToTop();
  };

  const dispatch = useDispatch();
  const entities = useSelector((state) => state.sequence.sequences);
  const statusList = useSelector((state) => state.common.statusList);
  const subscriptionData = useSelector((state) => state.subscription);

  useEffect(() => {
    dispatch(getSubscriptionAsync());
  }, []);

  useEffect(() => {
    console.log(subscriptionData, "subscriptionData");
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      if (basePlan && basePlan?.length > 0) {
        setIsBulkAssign(
          basePlan[0]?.metaData?.bulk_assign_content === "Yes" ? true : false
        );
        setIsBulkUpdate(
          basePlan[0]?.metaData?.bulk_update_status === "Yes" ? true : false
        );
      }
    }
  }, [subscriptionData]);

  const getSequence = () => {
    setFormDataReady(false);
    let reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      searchString: searchString,
      status: selectedStatus,
      tags: [],
    };

    dispatch(getSquenceAsync(reqBody)).then((res) => {
      setTotalPages(entities?.totalPages);
      setFormDataReady(true);
      setSelectAll(false);
      // console.log(res,'selectAll');
      // if (selectAll) {
      //   const pageSelectedItems = res?.payload?.records.map((c) => c?.id);
      //   const pageSelectedDatas = res?.payload?.records.map((c) => c);
      //   const pageSelectedSequences=[];
      //   pageSelectedDatas.filter(
      //     (item) => {
      //       if(item.status === "Active" ||
      //       item.status === "Published"){
      //         pageSelectedSequences.push(item.id)
      //       }
      //     }
      //   );

      //   setSelectedItemList(pageSelectedItems);
      //   setSelectedDataList(pageSelectedDatas);
      //   setSelectedSequencesForAssignList(pageSelectedSequences);
      //   setSelectAll(pageSelectedItems.length === res?.payload?.records.length);
      // }
    });
  };
  const onFilterChange = (value, type) => {
    setFilterss(true);
    if (type == "sortBy") {
      setSortColumn(prepareSortFilterRequest(value));
    } else if (type === "status") {
      setStatusList(value);
    }
    setCurrentPage(1);
    scrollToTop();
  };

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
    getSequence();
  };

  const processBulkActResponse = (res, operation, totalSelected) => {
    const proceedData = res?.proceedData || 0;
    const failedData = totalSelected - proceedData;

    let prompt = proceedData === totalSelected ? "Success" : "Error";
    let message;

    if (proceedData === totalSelected) {
      message = (
        <div>
          <h5 className="mt-2 mb-1">{`Your sequence was deleted successfully. (${proceedData}/${totalSelected})`}</h5>
        </div>
      );
    } else {
      message = (
        <div className="text-gray-100">
          <h5 className="mt-2 mb-1 text-danger">{`${operation} Failed (${failedData}/${totalSelected})`}</h5>
          <h5 className="mt-2 mb-1">
          Some Sequence could not be deleted due to their current status.
          </h5>
          <h5 className="mt-2 mb-1">
            Successfully deleted {proceedData}
          </h5>
          <h5 className="mt-2 mb-1">
            Failed {failedData}
          </h5>
        </div>
      );
    }

    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records:
        res?.failexcutionResponse && res?.failexcutionResponse?.length > 0
          ? res?.failexcutionResponse
          : [],
      procesFailedError: res?.procesFailedError,
    });
  };
  const processBulkActResponses = (res, operation) => {
    let prompt =
      res?.proceedData &&
      res?.totalRequest &&
      res?.proceedData === res?.totalRequest
        ? "Success"
        : "Error";
    let successMessage =
      operation === "Archive"
        ? `Your sequence has been archived successfully. `
        : operation === "Assign"
        ? `The selected sequences have been successfully assigned to your chosen users! `
        : "";

    successMessage += `(${res?.proceedData}/${res?.totalRequest})`;

    let message =
      res?.proceedData === res?.totalRequest
        ? successMessage
        : `${operation} Failed (${res?.totalRequest - res?.proceedData}/${
            res?.totalRequest
          })`;

    setResponsePopup({
      show: true,
      prompt: prompt,
      message: message,
      records:
        res?.failexcutionResponse && res?.failexcutionResponse.length > 0
          ? res?.failexcutionResponse
          : [],
      procesFailedError: res?.procesFailedError,
    });
  };

  const onConfirm = (confirmstatus) => {
    // console.log(entities.records.map((c) => c.status), "selectedDataList")
    if (selectedItemList !== null && selectedItemList.length > 0) {
      if (deleteModal) {
        dispatch(bulkDeleteSequenceAsync(selectedSequenceForDelete)).then(
          (res) => {
            processBulkActResponse(
              res.payload,
              "Delete",
              selectedItemList.length
            );
          }
        );
        setDeleteModal(false);
      } else if (archiveModal) {
        dispatch(bulkArchiveSequenceAsync(selectedItemList)).then((res) => {
          processBulkActResponses(res.payload, "Archive");
        });
        setArchiveModal(false);
      }
      //else if (assignModal) {
      // dispatch(bulkAssigneSequenceAsync(selectedItemList)).then((res) => {
      //   processBulkActResponse(res.payload,"Delete");

      // });
      //  setAssignModal(false);
      //}
      setSelectedSequenceForDelete([]);
      setSelectedItemList([]);
      setSelectedSequencesForAssignList([]);
      setSelectedDataList([]);
      setSelectAll(false);

      // navigate.push("/authoring/");
    }
  };

  const ststusChange = (id, newStstus, selectedData) => {
    // setFilterss(true);
    let selectedItems = [...selectedItemList];
    let selectedDatas = [...selectedDataList];
    if (id == "All") {
      selectedItems = [];
      selectedDatas = [];
      if (newStstus) {
        selectedItems = entities.records.map((c) => c?.id);
        selectedDatas = entities.records.map((c) => c);
      }

      setSelectAll(!selectAll);
    } else {
      let index = -1;
      let indexData = -1;
      index = selectedItems.indexOf(id);
      indexData = selectedDatas.indexOf(selectedData);
      if (index !== -1) {
        selectedItems.splice(index, 1);
        selectedDatas.splice(indexData, 1);
      } else {
        selectedItems.push(id);
        selectedDatas.push(selectedData);
      }
    }

    const selectedSequences = [];
    selectedDatas.filter((item) => {
      // if ((item.status === "Active" || item.status === "Published") && item.isRestricted === false) {
      if (item.status === "Active" || item.status === "Published") {
        selectedSequences.push(item.id);
      }
    });
    const selectedSequences2 = [];
    selectedDatas.filter((item) => {
      // if ((item.status === "Active" || item.status === "Published") && item.isRestricted === false) {
      if (
        item.status === "Active" ||
        item.status === "Published" ||
        item.status === "Draft"
      ) {
        selectedSequences2.push(item.id);
      }
    });
    console.log(selectedDatas, "selectedDatas");
    const selectedSequencesDelete = [];
    selectedDatas.filter((item) => {
      if (item.audience === 0) {
        selectedSequencesDelete.push(item.id);
      }
    });

    setSelectedItemList(selectedItems);
    setSelectedDataList(selectedDatas);
    setSelectedSequenceForDelete(selectedSequencesDelete);
    setSelectedSequencesForAssignList(selectedSequences);
    setSelectAll(selectedItems.length === entities.records.length);

    // filter for the assign functionality --------------------------------------------
    // const dataAS = selectedDatas.filter((item) => item.status === "Pubslished" || item.status === "Active");
    // if (dataAS.length > 0) {
    //   setIsAssignButton(true);
    // } else {
    //   setIsAssignButton(false);
    // }
    console.log(selectedSequences, "selectedSequences");
    //filter for the Assign and Archieve functionality --------------------------------------------
    if (selectedSequences.length > 0) {
      setIsAssignButton(false);
    } else {
      setIsAssignButton(true);
    }

    if (selectedSequences2.length > 0) {
      setIsArchieveButton(false);
    } else {
      setIsArchieveButton(true);
    }
    //filter for the delete functionality --------------------------------------------
    const data = selectedDatas.filter((item) => {
      // if (item.audience === 0) {
      //   setIsDeleteButton(true);
      // } else if (item.audience > 0) {
      //   setIsDeleteButton(false);
      const dataDP = selectedDatas.filter(
        (item) => item.status === "Archived" || item.audience > 0
      );
      if (dataDP?.length === selectedDatas?.length) {
        setIsDeleteButton(false);
      } else {
        setIsDeleteButton(true);
      }
      // }
    });
  };

  const onAssignModelClose = (isOpen) => {
    setAssignModal(isOpen);
    setSelectedItemList([]);
    setSelectedDataList([]);
    setSelectedSequencesForAssignList([]);
  };

  useEffect(() => {
    if (searchString) {
      setFilterss(true);
      setCurrentPage(1);
    }
  }, [searchString]);

  useEffect(() => {
    if (
      location?.state !== undefined &&
      location?.state !== null &&
      location?.state?.backType !== undefined &&
      location?.state?.backType !== null
    ) {
      setTemplateModal(true);
    }
  }, [location]);

  const debouncedSequence = useDebounce(getSequence, 1000);

  useEffect(() => {
    //getSequence();
    debouncedSequence();
    if (statusList.length === 0) {
      dispatch(getStatusEnumListAsync());
    }
    scrollToTop();
  }, [
    dispatch,
    currentPage,
    pageSize,
    totalPages,
    sortColumn,
    searchString,
    selectedStatus,
  ]);
  const onClearAll = () => {
    // setSearchString("");
    setFilterss(true);
    setStatusList([]);
    setCurrentPage(1);
    setPageSize(50);
    // setCurrentPage(1);
    setSortColumn({
      column: "",
      sortDirection: "",
    });
    scrollToTop();
  };

  const onBulkAssignConfirmation = () => {
    const activeEmployeeUsers = selectedDataList.filter(
      (item) =>
        item.status == "Draft" ||
        item.status == "Expired" ||
        item.status == "Archived"
    );

    if (activeEmployeeUsers.length > 0) {
      setAssignModalAlert(true);
    } else {
      setAssignModal(true);
    }
  };
  const onResponseClose = () => {
    setAssignModalAlert(false);
  };

  const getTitle = entities.records.find((c) => c.id === selectedItemList[0]);
  const fetechTitle = getTitle ? getTitle.title : "Unknown";
  console.log(selectedItemList, "selectedItemList");
  // console.log(isAssignButton,'isAssignButton');
  const handleCloseModal = () => {
    setTemplateModal(false);
  };
  return (
    <>
      <div className="pages">
        <RenewalHeader />
        <header className="pageHeader mb-4">
          <Container fluid>
            <Row className="mx-0 align-items-center">
              <Col className="px-0">
                <h3>Authoring</h3>
              </Col>
              <Col className="px-0 d-flex align-items-center justify-content-end">
                <div className="d-flex justify-content-between userSearchin me-4">
                  <Search
                    onBlur={setSearchString}
                    placeHolder="Search by sequence name"
                  />
                </div>
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <AccountDropdown />
                  <ActivityLog />
                  {/* <div className="headImg">
                    <img src={AccountImg} alt="" />
                  </div>
                  <div>
                    <a href="#" className="hamberger">
                      {" "}
                      <IconHamberger />
                    </a>
                  </div> */}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="pageOuter">
          <Container fluid>
            {!formDataReady && <Loader />}
            <AuthoringNav />
            <div className="page employeesPage d-flex">
              <Filters
                sortBy={authoringSortby}
                onChange={onFilterChange}
                statusList={statusList}
                onClearAll={onClearAll}
              />
              <div className="filtersContent">
                <div className="pageActions p-32 py-3 p20">
                  <Row className="mx-0">
                    <Col className="px-0" sm={6}>
                      <div className="d-flex feedbackData users align-items-center">
                        <div className="dataItem userAllToatal d-flex align-items-center">
                          {/* <p className="h6 text-gray-200">Processes <span className="text-gray-100">1 to 50</span></p>
                        <div className="btnArrow ms-2">
                          <Button variant="white">
                            <IconleftArrow/>
                          </Button>
                          <Button variant="white" className="ms-3">
                            <IconrightArrow/>
                          </Button>
                        </div> */}
                          <Pagination
                            totalItems={entities?.allRecordsCount}
                            itemsPerPage={pageSize}
                            onPageChange={onPageChange}
                            setFilterss={setFilterss}
                            filterss={filterss}
                            pageTitle={"Sequences"}
                          />
                        </div>
                      </div>
                    </Col>
                    <Col className="px-0" sm={6}>
                      <div className="d-flex justify-content-end">
                        {selectedItemList && selectedItemList.length > 0 && (
                          <>
                            <div className="d-flex align-items-center SupportIconBtn me-2">
                              <Button
                                variant=""
                                className="border-0 p-0"
                                onClick={() => {
                                  !isBulkAssign && selectedItemList.length > 1
                                    ? setIsRestrictedModalPopup(true)
                                    : onBulkAssignConfirmation();
                                }}
                                disabled={isAssignButton}
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  {/* <IconAssign /> */}
                                  <p className="h6 text-primary-200 font-bold">
                                    Assign
                                  </p>
                                </div>
                              </Button>

                              <Button
                                variant=""
                                className="border-0 p-0"
                                onClick={() => {
                                  !isBulkUpdate && selectedItemList.length > 1
                                    ? setIsRestrictedModalPopup(true)
                                    : setArchiveModal(true);
                                }}
                                disabled={isArchieveButton}
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  {/* <IconArchive /> */}
                                  <p className="h6 text-primary-100 font-bold">
                                    Archive
                                  </p>
                                </div>
                              </Button>
                              <Button
                                variant=""
                                className="border-0 p-0"
                                onClick={() => {
                                  !isBulkUpdate && selectedItemList.length > 1
                                    ? setIsRestrictedModalPopup(true)
                                    : setDeleteModal(true);
                                }}
                                disabled={!isDeleteButton}
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  {/* <IconDelete /> */}
                                  <p className="h6 text-primary-100 font-bold">
                                    Delete
                                  </p>
                                </div>
                              </Button>
                            </div>
                          </>
                        )}
                        <button
                          className="btn-white btn-sm"
                          onClick={() => setTemplateModal(true)}
                        >
                          Use template
                        </button>
                        {/* <span className='mx-4 divider'></span> */}
                        <Link
                          className="btn-secondary btn-sm ms-12"
                          to={{
                            pathname: `/authoring/sequences/create`,
                            state: {
                              operationType: "Add",
                            },
                          }}
                        >
                          Create sequence
                        </Link>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div
                  className="tableComponent prefTable flex-grow-1 overflow-auto"
                  ref={tableRef}
                >
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <CheckBox
                            text=""
                            id={"All"}
                            ststusChange={ststusChange}
                            status={selectAll}
                          />
                        </th>
                        <th>SEQUENCE TITLE</th>
                        <th>CREATED ON</th>
                        <th>LAST EDITED</th>
                        <th>CREATED BY</th>
                        <th>AUDIENCE</th>
                        <th>STATUS</th>
                        <th>FEEDBACK RATING</th>
                      </tr>
                    </thead>
                    <tbody>
                      {entities?.records !== null &&
                        entities?.records.length > 0 &&
                        entities.records.map((c, index) => (
                          <tr key={c.id}>
                            <td align="middle">
                              <CheckBox
                                id={c.id}
                                ststusChange={ststusChange}
                                status={selectedItemList.includes(c.id)}
                                selectedData={c}
                              />
                            </td>
                            <td>
                              <OverlayTrigger
                                trigger={["hover", "focus"]}
                                rootClose
                                placement="auto"
                                overlay={
                                  <Popover
                                    id="popover-basic"
                                    style={{ position: "fixed" }}
                                  >
                                    <Popover.Body>{c.title}</Popover.Body>
                                  </Popover>
                                }
                                container={this}
                              >
                                <Link
                                  to={{
                                    pathname: `/authoring/sequences/details`,
                                    state: { id: c.id },
                                  }}
                                  className="text-gray-100 d-flex align-items-center gap-2 descrp"
                                >
                                  {c.title}
                                </Link>
                              </OverlayTrigger>
                            </td>
                            <td>
                              {getFormatDate(c.createdDate)}
                              {/* <DateTimeConverter utcDateTime={c.createdDate} /> */}
                            </td>
                            <td>
                              {getFormatDate(
                                c.modifiedDate ? c.modifiedDate : c.createdDate
                              )}
                              {/* <DateTimeConverter
                                utcDateTime={
                                  c.modifiedDate
                                    ? c.modifiedDate
                                    : c.createdDate
                                }
                              /> */}
                            </td>
                            <td>
                              {c.createdUserDetail !== undefined && (
                                <div className="d-flex align-items-center gap-2">
                                  <div className="tableImg">
                                    {c?.createdUserDetail?.profileImagePath ? (
                                      <UserImage
                                        imgKey={
                                          c?.createdUserDetail?.profileImagePath
                                        }
                                      />
                                    ) : (
                                      <img
                                        src={getInitialsInName(
                                          c?.createdUserDetail?.name ?? "N A",
                                          24
                                        )}
                                        alt={c?.createdUserDetail?.name}
                                      />
                                    )}
                                  </div>
                                  <p className="text-nowrap mb-0">
                                    {c.createdUserDetail?.name}
                                  </p>
                                </div>
                              )}
                            </td>
                            <td>
                              <span className="text-primary-200 font-bold">
                                {c.audience}
                              </span>{" "}
                            </td>
                            <td>
                              <span
                                className={`tableTag ${
                                  Status[c.status].color
                                } text-md  `}
                              >
                                {c.status}
                              </span>
                            </td>
                            <td>
                              <div className="d-flex gap-2 align-items-center">
                                <IconStar />
                                <span>{c.feedBackRating.toFixed(1)}</span>
                              </div>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                  {!entities?.records.length > 0 && formDataReady === true ? (
                    <EmptyState
                      message="No sequence found"
                      link=<Link
                        to={{
                          pathname: `/authoring/sequences/create`,
                          state: {
                            operationType: "Add",
                          },
                        }}
                        // className="btn-secondary btn-sm ms-3"
                      >
                        Create a sequence
                      </Link>
                    />
                  ) : null}
                </div>
                <div className="pageActions paginationMax">
                  <Row className="mx-0">
                    {/* <Pagination
                      totalItems={entities?.allRecordsCount}
                      itemsPerPage={pageSize}
                      onPageChange={onPageChange}
                      setFilterss={setFilterss}
                      filterss={filterss}
                    /> */}
                    {/* <Col className="px-0" sm={12}>
                      <div className="d-flex justify-content-end">
                        <button className="btn btn-sm pBtn"
                         disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
                        
                        >         
                        <IconleftArrow/>
                        </button>
                        {[...Array(totalPages)].map((c, index) => (
                          <Link className={`btn btn-sm pBtn ${currentPage === index+1 && "active"}`}  onClick={() => setCurrentPage(index+1)}>
                          {index+1}
                        </Link>
                        ))}
                        <button className="btn btn-sm pBtn" disabled={currentPage === totalPages}
          onClick={() => setCurrentPage(currentPage + 1)}>

                        <IconrightArrow/>
                      
                        </button>
                      </div>
                    </Col> */}
                  </Row>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        // onHide={() => setAssignModal(false)}
        show={assignModal}
      >
        <EmployeeAssignementPopup
          type={AssignmentActionType.SEQUENCE}
          title={"Sequence"}
          requestType={"bulk"}
          contentIds={selectedSequencesForAssignList}
          onClose={onAssignModelClose}
          moduletitle={getTitle}
          getData={getSequence}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal || archiveModal || responsePopup?.show}
      >
        {deleteModal ? (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onConfirm}
            headerLable="Are you sure you want to delete the selected sequences?"
            chldLable="You can't delete the sequence which has audience data available."
          />
        ) : (
          archiveModal && (
            <ModalDelete
              onCancel={setArchiveModal}
              onConfirm={onConfirm}
              requestType="Archive"
              headerLable="Are you sure you want to archive the selected sequences? "
              // chldLable="They will be moved to your archived list."
              chldLable={
                <div>
                  {/* They will be moved to your archived list.
                  <br /> */}
                  <p style={{ fontSize: "small" }}>
                    You will be able to archive only the content that are in
                    active or published state. And, please note that the
                    archived content can’t be retrieved back. In order to reuse
                    the archived content, you must duplicate the same.{" "}
                  </p>
                  <p>Click Yes if you still want to proceed ?</p>
                </div>
              }
            />
          )
        )}

        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={assignModalAlert}>
        <Modal.Body className="pt-4 pb-4">
          <h3 className="mt-3">
            You can only assign Published or Active status content to the users.
          </h3>
          <p className="text-gray-300 mb-0 mt-2 mb-5">
            Click on Yes, if you still want to proceed ?
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-end btnFooter gap-2">
          <Button
            variant="white"
            size="sm"
            onClick={() => onResponseClose(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setAssignModal(true);
              onResponseClose(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="come-from-modal right templateList"
        centered
        show={templateModal}
        // onHide={() => setTemplateModal(false)}
      >
        <TemplateList type={"Sequences"} onClose={handleCloseModal} />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isRestrictedModalPopup}
      >
        <RestrictedUpgradeToBusinessModalPopup
          onCancel={setIsRestrictedModalPopup}
          headerLable={""}
          chldLable={""}
        />
      </Modal>
    </>
  );
};

export default Sequences;
