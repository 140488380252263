import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const ModalDelete = ({
  onCancel,
  onConfirm,
  headerLable,
  chldLable,
  requestType = "Delete",
  isRestricted = false,
}) => {
  // const requestFor = requestType ? requestType : ;
  return (
    <>
      <Modal.Header closeButton onClick={() => onCancel(false)}></Modal.Header>
      <Modal.Body className="pb-4">
        <h3 className=""> {headerLable}</h3>
        <p className="text-gray-300 mb-0 mt-2 mb-5 h5">{chldLable}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
        {isRestricted === false && requestType !== "NotDelete" && (
          <Button variant="white" size="" className="m-0" onClick={() => onCancel(false)}>
            Cancel
          </Button>
        )}
        {isRestricted === true && (
          <Button variant="white" size="" className="m-0" onClick={() => onCancel(false)}>
            Cancel
          </Button>
        )}
        
        {isRestricted === true ? (
          <Link to="/accountsetting/billingsubscription">
            <Button
              variant="primary"
              size=""
              className="border-0 m-0"
              // onClick={() => requestType === "NotDelete" ? onCancel(false) : onConfirm(requestType)}
            >
              Go to Billing & Subscription
            </Button>
          </Link>
        ) : (
          <Button
            variant="primary"
            size=""
            className="border-0 m-0"
            onClick={() => requestType === "NotDelete" ? onCancel(false) : onConfirm(requestType)}
          >
            {requestType === "NotDelete" ? "OK" : "Yes, I am sure"}
          </Button>
        )}
      </Modal.Footer>
    </>
  );
};

export default ModalDelete;
