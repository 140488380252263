import React, { useState, useEffect, useRef } from "react";
import { IconUploadFile, IconCross, SpinIcon, TickFill } from "../../../../icons/Icons";
import { isValidPhoto, isValidVideo, isValidPPT, isValidDocument, isValidMusic } from './formFileValidation';
import Video from '../video';
import ImagePreview from '../ImagePreview';
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";
import {
  fileUpload,
  generatePresignedUrl,
} from "../../../../api/common-store-api";
import { getStorageAsync } from "../../../../reducers/subscription-reducers";
import ModalStorageLimitReachedPopup from "../../../../components/ModalPopup/ModalStorageLimitReachedPopup";
import {
  Modal,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllowedFileTypes } from "../../../../common";

const UploadFile = ({
  title,
  serialNo,
  questionsFiles,
  isRequired,
  data,
  onUploadFileChange,
  setIsFileUploaded,
  value,
  disabled,
}) => {
  console.log(questionsFiles,'questionsFiles');
  console.log(data,'datadatadatadatadata');
  const dispatch = useDispatch();
  const [file, setFile] = useState();
  const [files, setFiles] = useState([]);
  const [uploadedFiles, setUploadedFiles] = useState([]);
  const [uploadError, setUploadError] = useState("");
  const [removeImg, setRemoveImg] = useState(false);

  const { storageData } = useSelector((state) => state.subscription);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isFileUploadLoad, setIsFileUploadLoad] = useState(false);
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);

  // console.log(value,"up")
  // const uploadFile = async (e) => {
  //   const selectedFile = e.target.files[0];

  //   if (selectedFile) {
  //     setFile(selectedFile.name);

  //     setIsFileUploaded(true);

  //     const formData = new FormData();
  //     formData.append("file", selectedFile);

  //       const responseFile = await fileUpload(formData);
  //       console.log(responseFile, "responseFile");
  //       onUploadFileChange(responseFile.data);

  //   } else {
  //     setFile("");
  //     onUploadFileChange("");
  //     setIsFileUploaded(false);
  //   }
  // };

  const uploadFile = async (e) => {
    // debugger;
    // const selectedFile = e.target.files[0];
    e.preventDefault();
    if (isFileUpload) {
      setIsStorageLimitReached(true);
    } else {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      // fileInput.accept = 'file_extension|audio/*|video/*|image/*|media_type'; // You can specify accepted file types      
      fileInput.onchange = async (event) => {
        const selectedFile = event.target.files[0];
        if (selectedFile) {
          const fileName = selectedFile.name.toLowerCase();
          const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to megabytes
    
          const allowedFileTypes = data.specificAllowedFileTypes
            ? data.specificAllowedFileTypes
                .split(",")
                .map((type) => type.trim().toLowerCase())
            : [];
    
          const isFileTypeAllowed =
            !allowedFileTypes ||
            allowedFileTypes.length === 0 ||
            allowedFileTypes.some((allowedType) => {
              if (
                !allowedType ||
                (allowedType === "image" &&
                  (fileName.endsWith(".jpeg") ||
                    fileName.endsWith(".jpg") ||
                    fileName.endsWith(".png")))
              ) {
                return true;
              } else if (
                !allowedType ||
                (allowedType === "document" &&
                  (fileName.endsWith(".doc") || fileName.endsWith(".docx") || fileName.endsWith(".pdf")))
              ) {
                return true;
              } else if (
                !allowedType ||
                (allowedType === "audio" &&
                  (fileName.endsWith(".mp3") || fileName.endsWith(".wav")))
              ) {
                return true;
              } else if (
                !allowedType ||
                (allowedType === "ppt" &&
                  (fileName.endsWith(".ppt") || fileName.endsWith(".pptx")))
              ) {
                return true;
              } else if (
                !allowedType ||
                (allowedType === "pdf" && fileName.endsWith(".pdf"))
              ) {
                return true;
              } else if (
                !allowedType ||
                (allowedType === "video" &&
                  (fileName.endsWith(".mp4") ||
                    fileName.endsWith(".avi") ||
                    fileName.endsWith(".m4v") ||
                    fileName.endsWith(".mov") ||
                    fileName.endsWith(".mpg")))
              ) {
                return true;
              }
              return false;
            });
    
          if (isFileTypeAllowed) {
            if (data.maxFileSize && fileSizeInMB > data.maxFileSize) {
              // console.log("File size exceeds the maximum limit");
              setUploadError("File size exceeds the maximum limit");
              setFile("");
              setIsFileUploaded(false);
            } else {
              if (data.maxFileLimit && files.length >= data.maxFileLimit) {
                // console.log("Maximum file limit reached");
                setUploadError("Maximum file limit reached");
                setFile("");
                // setFiles([]);
                setIsFileUploaded(false);
              } else {
                const updatedFiles = [...files, selectedFile.name];
                // console.log(updatedFiles,'updatedFilesupdatedFilesupdatedFiles');
                setFiles(updatedFiles);
                setFile(selectedFile.name);
                setIsFileUploaded(true);
    
                const formData = new FormData();
                formData.append("file", selectedFile);
    
                setUploadError("");
    
                try {
                  setIsFileUploadLoad(true);
                  const responseFile = await fileUpload(formData);
                  console.log(responseFile, "responseFile");
                  if (responseFile.data) {
                    const updatedFiles = [
                      ...files,
                      // { name: selectedFile.name, data: responseFile.data },
                      { name: selectedFile.name, data: `${responseFile.data?.fileKey}${responseFile.data?.fileName}` },
                    ];
                    // console.log(updatedFiles,'updatedFiles');
                    setFiles(updatedFiles);
      
                    onUploadFileChange(
                      updatedFiles.map((file) => file.data).join(",")
                    );
                    //onUploadFileChange(responseFile.data);
                    setIsFileUploadLoad(false);
                  }
                } catch (error) {
                  // console.error("Error uploading file:", error);
                  setUploadError(`Error uploading file: ${error}`);
                  setFile("");
                  setIsFileUploaded(false);
                  setUploadedFiles(updatedFiles.join(","));
                }
              }
            }
          } else {
            // console.log("File type not allowed");
            // setUploadError("Invalid file type");
            setUploadError("File type not allowed");
            setFile("");
            setIsFileUploaded(false);
          }
        } else {
          setFile("");
          onUploadFileChange("");
          setIsFileUploaded(false);
        }
      };
      fileInput.click();
    }
    
    // if (selectedFile) {
    //   const fileName = selectedFile.name.toLowerCase();
    //   const fileSizeInMB = selectedFile.size / (1024 * 1024); // Convert bytes to megabytes

    //   const allowedFileTypes = data.specificAllowedFileTypes
    //     ? data.specificAllowedFileTypes
    //         .split(",")
    //         .map((type) => type.trim().toLowerCase())
    //     : [];

    //   const isFileTypeAllowed =
    //     !allowedFileTypes ||
    //     allowedFileTypes.length === 0 ||
    //     allowedFileTypes.some((allowedType) => {
    //       if (
    //         !allowedType ||
    //         (allowedType === "image" &&
    //           (fileName.endsWith(".jpeg") ||
    //             fileName.endsWith(".jpg") ||
    //             fileName.endsWith(".png")))
    //       ) {
    //         return true;
    //       } else if (
    //         !allowedType ||
    //         (allowedType === "document" &&
    //           (fileName.endsWith(".doc") || fileName.endsWith(".docx")))
    //       ) {
    //         return true;
    //       } else if (
    //         !allowedType ||
    //         (allowedType === "audio" &&
    //           (fileName.endsWith(".mp3") || fileName.endsWith(".wav")))
    //       ) {
    //         return true;
    //       } else if (
    //         !allowedType ||
    //         (allowedType === "ppt" &&
    //           (fileName.endsWith(".ppt") || fileName.endsWith(".pptx")))
    //       ) {
    //         return true;
    //       } else if (
    //         !allowedType ||
    //         (allowedType === "pdf" && fileName.endsWith(".pdf"))
    //       ) {
    //         return true;
    //       } else if (
    //         !allowedType ||
    //         (allowedType === "video" &&
    //           (fileName.endsWith(".mp4") ||
    //             fileName.endsWith(".avi") ||
    //             fileName.endsWith(".m4v") ||
    //             fileName.endsWith(".mpg")))
    //       ) {
    //         return true;
    //       }
    //       return false;
    //     });

    //   if (isFileTypeAllowed) {
    //     if (data.maxFileSize && fileSizeInMB > data.maxFileSize) {
    //       // console.log("File size exceeds the maximum limit");
    //       setUploadError("File size exceeds the maximum limit");
    //       setFile("");
    //       setIsFileUploaded(false);
    //     } else {
    //       if (data.maxFileLimit && files.length >= data.maxFileLimit) {
    //         // console.log("Maximum file limit reached");
    //         setUploadError("Maximum file limit reached");
    //         setFile("");
    //         // setFiles([]);
    //         setIsFileUploaded(false);
    //       } else {
    //         const updatedFiles = [...files, selectedFile.name];
    //         // console.log(updatedFiles,'updatedFilesupdatedFilesupdatedFiles');
    //         setFiles(updatedFiles);
    //         setFile(selectedFile.name);
    //         setIsFileUploaded(true);

    //         const formData = new FormData();
    //         formData.append("file", selectedFile);

    //         setUploadError("");

    //         try {
    //           const responseFile = await fileUpload(formData);
    //           // console.log(responseFile, "responseFile");
    //           const updatedFiles = [
    //             ...files,
    //             { name: selectedFile.name, data: responseFile.data },
    //           ];
    //           // console.log(updatedFiles,'updatedFiles');
    //           setFiles(updatedFiles);

    //           onUploadFileChange(
    //             updatedFiles.map((file) => file.data).join(",")
    //           );
    //           //onUploadFileChange(responseFile.data);
    //         } catch (error) {
    //           // console.error("Error uploading file:", error);
    //           setUploadError(`Error uploading file: ${error}`);
    //           setFile("");
    //           setIsFileUploaded(false);
    //           setUploadedFiles(updatedFiles.join(","));
    //         }
    //       }
    //     }
    //   } else {
    //     // console.log("File type not allowed");
    //     // setUploadError("Invalid file type");
    //     setUploadError("File type not allowed");
    //     setFile("");
    //     setIsFileUploaded(false);
    //   }
    // } else {
    //   setFile("");
    //   onUploadFileChange("");
    //   setIsFileUploaded(false);
    // }
  };

  const handleRemoveFile = (index) => {
    // Create a copy of the files array
    const updatedFiles = [...files];
    // Remove the file at the specified index
    updatedFiles.splice(index, 1);
    // Update the state with the new array
    setFiles(updatedFiles);
    onUploadFileChange(updatedFiles.map((file) => file.data).join(","));
    setRemoveImg(true);
  };

  useEffect(() => {
    console.log("Files:", files);
    console.log("Uploaded Files:", uploadedFiles);
  }, [files, uploadedFiles]);
  const idsString = value || "";

  const idsArray = idsString.split(",");

  const trimmedIds = idsArray.map((id) => id.trim().substring(0, 36));

  useEffect(() => {
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    if (storageData && storageData?.usedStorage >= storageData?.totalStorage) {
      setIsFileUpload(true);
    }
  }, [storageData]);

  return (
    <div className="mt-3 pt-3">
      <h6 className="mb-3 font-medium">
        {serialNo}. {title}
        {isRequired && (
          <span className="mandatoryFields" style={{ color: "red" }}>
            *
          </span>
        )}
      </h6>
      {questionsFiles?.length > 0 ? (
        <>
          {isValidVideo(
            questionsFiles.length > 0 && questionsFiles[0]?.filePath
          ) ? (
            <Video contentUrl={questionsFiles[0]?.filePath} />
          ) : isValidPhoto(
              questionsFiles.length > 0 && questionsFiles[0]?.filePath
            ) ? (
              <Video contentUrl={questionsFiles[0]?.filePath} />
            ) : isValidPhoto(
                questionsFiles.length > 0 && questionsFiles[0]?.filePath
              ) ? (
              <ImagePreview contentUrl={questionsFiles[0].filePath} />
            ) : isValidPPT(
              questionsFiles.length > 0 &&
              questionsFiles[0]?.filePath
            ) ? (
              <Ppt
                contentUrl={
                  questionsFiles[0].filePath
                }
              />
            ) : isValidDocument(
              questionsFiles.length > 0 &&
              questionsFiles[0]?.filePath
            ) ? (
              <Doc
                contentUrl={
                  questionsFiles[0].filePath
                }
              />
            ) : isValidMusic(
              questionsFiles.length > 0 &&
              questionsFiles[0]?.filePath
            ) ? (
              <Audio
                contentUrl={
                  questionsFiles[0].filePath
                }
              />
            ) : null}
          </>
        ) : null}
        {files.map((file, index) => (
          <div key={index} className="position-relative removeFile">
            <p>
              {file.name}
              {file.name && (
                <span className="text-end" onClick={() => handleRemoveFile(index)}><IconCross /></span>
              )}
            </p>
            {/* <p>Data: {file.data}</p> */}
          </div>
        ))}

        {removeImg === false && files?.length === 0 && idsArray.map((originalString, index) => (
          <div key={index}>
            {/* <div>{trimmedIds[index]}</div> */}
            <div>{originalString.substring(36)}</div>
          </div>
        ))}
        <label className="d-flex justify-content-center align-item-center p-32 text-center bg-base-100 rounded-6 borderAllDashed position-relative">
          <input
            type="file"
            className={`${!file ? "d-none" : ""}`}
            onClick={uploadFile}
            name="upload"
            disabled={disabled || isFileUploadLoad}
            // accept= ".jpeg, .jpg, .png, .mp4, .avi, .m4v, .mpg, .mp3, .doc, .docx, .pdf, .pptx"
          />

          {!file ? (
            <div>
              <div className="svgUploadicon">
                <IconUploadFile />
              </div>
              <p className="h6">
                <span className="font-bold text-primary-200">Add File</span>
              </p>
            </div>
          ) : null}
          <div></div>

          {isFileUploadLoad && (
            <div className="animateClass uploadFileSpin"><SpinIcon /></div>
          )}          
          {/*<div className="uploadPageTickFill"><TickFill/></div>*/}
        </label>

        {/* {data.maxFileLimit ? (
          <div
            style={{
              color: "red",
              fontSize: "12px",
              float: "left",
            }}
            className="pe-2"
          >
            Max file limit: {data.maxFileLimit}{" "}
          </div>
        ) : null} */}
        <div>
        {uploadError && (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              display: "inline-block",
              fontWeight: "500",
              lineHeight: "0.875rem",
              marginBottom: "6px"
            }}
            className=""
          >
            {uploadError}
          </span>
        )}
      </div>
      <div className="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-start align-items-center">
        {data.maxFileLimit ? (
          <div
            style={{
              color: "#888",
              fontSize: "11px",
              lineHeight: "0.875rem"
            }}
            className="pe-2 "
          >
            Max file limit: {data.maxFileLimit}{" "}
          </div>
        ) : null}

        {data.maxFileSize ? (
          <div
            style={{
              color: "#888",
              fontSize: "11px",
              lineHeight: "0.875rem"
            }}
            className="pe-2"
          >
            Max file size: {data.maxFileSize}MB{" "}
          </div>
        ) : null}

        {data.specificAllowedFileTypes ? (
          <span
            style={{
              color: "#888",
              fontSize: "11px",
              lineHeight: "0.875rem"
            }}
            className="pe-2"
          >
            {getAllowedFileTypes(data.specificAllowedFileTypes)}
            {/* Allowed file: {data.specificAllowedFileTypes}{" "} */}
          </span>
        ) : (
          <span
            style={{
              color: "#888",
              fontSize: "11px",
              lineHeight: "0.875rem"
            }}
            className="pe-2"
          >
            Allowed types: jpeg, jpg, png, mp4, avi, m4v, mpg, mp3, mov, doc, .docx, .pdf, .ppt & .pptx.
          </span>
        )}
      </div>
      
      </div>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isStorageLimitReached}
      >
        <ModalStorageLimitReachedPopup onClose={setIsStorageLimitReached} userType="Employee" />
      </Modal>
    </div>
  );
};

export default UploadFile;
