export const Constants = {
  clientId: "0b530e9a-6a05-4f94-ac2c-1fb3ac6f5e96",
  clientScope: "openid profile email b8259469-f9af-4384-aed0-d93e4ddc895b_api",
  loadStripe: "pk_test_51OFF9aSDUG9764DXrfQkoGAOT8yaaLmaI6rtY6drAkc4DU90dIlW9tU78KPqCQdTuYYu2rpZOKCnGE27lI8zJQF00070lgq0EF",

  stsAuthority: "https://echo-unity.cerebrent.io",
  clientRoot: "https://echo-ascend.cerebrent.io",
  apiRoot: "https://echo-unity-api.cerebrent.io",
  employeeApiRoot: "https://echo-employee-api.cerebrent.io",
  authApiRoot: "https://echo-auth-api.cerebrent.io",
  identityApiRoot: "https://echo-unity-api.cerebrent.io",
  blobServiceApiRoot: "https://echo-blob-api.cerebrent.io",
  emailVerification: "https://echo-unity-api.cerebrent.io/Manage/EmailVerification",
  chargebeeApiRoot: "https://echo-chargebee-api.cerebrent.io",
  stripeApiRoot: "https://echo-company-api.cerebrent.io",

  // stsAuthority: "https://localhost:44310",
  // clientRoot: "https://localhost:3000",
  // apiRoot: "http://localhost:44310",
  // employeeApiRoot: "https://localhost:5191",
  // authApiRoot: "https://localhost:5192",
};
