import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Search from "../Search/Search";
import Select from "react-select";
import { IconHamberger, IconStar } from "../../icons/Icons";
import Loader from "../Loader/Loader";
import { getFormatDate } from "../../common";
import CheckBox from "../CheckBox/CheckBox";
import AccountImg from "../../assets/images/Account.png";
import { AssignmentActionType } from "../../services/DataConstants";
import {
  getUsersAsync,
  GetUnassignedEmployeeListAsync,
  assignModulesAsync,
  assignProcessesAsync,
  assignSequencesAsync,
} from "../../reducers/user-reducers";
import { useSelector, useDispatch } from "react-redux";
import ModalDelete from "../ModalPopup/ModalDeletePopup";

import ModalResponse from "../ModalPopup/ModalResponsePopup";
import EmptyState from "../EmptyState/EmptyState";
import getInitialsInName from "../../helper/GetNameInitials";
import UserImage from "../../components/UserImage/UserImage";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import { IconleftArrow } from "../../icons/Icons";
import { IconrightArrow } from "../../icons/Icons";
import Pagination from "../../components/Pagination/Pagination";
import RestrictedUpgradeToBusinessModalPopup from "../../components/ModalPopup/RestrictedUpgradeToBusinessModalPopup";
import {
  getSubscriptionAsync,
  getPlansAsync,
} from "../../reducers/subscription-reducers";

const EmployeeAssignementPopup = ({ contentIds, title, type, getData, onClose, requestType,  moduletitle }) => {

  const subscriptionData = useSelector((state) => state.subscription);
  const data = useSelector((state) => state.user.users);
  const [responsePopup, setResponsePopup] = useState({});
  const [assignModals, setAssignModals] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [showLoader, setShowLoader] = useState(false);
  const [formDataReady, setFormDataReady] = useState(false);
  const [isOrderByDesc, setIsOrderByDesc] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [filterss, setFilterss] = useState(false);
  const [searchString, setSearchString] = useState("");

  const [isBulkAssign, setIsBulkAssign] = useState(false);
  const [isBulkUpdate, setIsBulkUpdate] = useState(false);
  const [isRestrictedModalPopup, setIsRestrictedModalPopup] = useState(false);
  const [userPlan, setUserPlan] = useState('');
  
  console.log(isOrderByDesc,'isOrderByDesc');

  const dispatch = useDispatch();

  const [timer, setTimer] = useState(null);

  const inputChanged = (e) => {
    console.log(e, 'setSearchString');
    setSearchString(e);
    clearTimeout(timer);    
    const newTimer = setTimeout(() => {
      // getUsers(e);
      setSelectAll(false);
      getUsers();
    }, 1000);

    setTimer(newTimer);
  };

  const optionSort = [
    { value: true, label: "Latest - Oldest" },
    { value: false, label: "Oldest - Latest" },
    // { value: "3", label: "Oldest-Latest 3" },
  ];

  const getUsers = () => {
    setShowLoader(true);
    setFormDataReady(false)
    let reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: "JoiningDate",
      isOrderByDesc: isOrderByDesc,
      assignmentId: requestType !== "bulk" ? contentIds[0] : null,
      assignmentType: type,
      searchString: searchString ? searchString : "",
    };
    dispatch(GetUnassignedEmployeeListAsync(reqBody)).then((res) => {
      setFormDataReady(true)
      setShowLoader(false);
    });
  };
  const onResponseClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
      type:requestType
    });
    onClose(false);
  };

  const processBulkActResponse = (res, operation) => {
    let prompt =
      res?.proceedData &&
      res?.totalRequest &&
      res?.proceedData === res?.totalRequest
        ? "Success"
        : "Error";

    let successMessage =
      operation === "Delete"
        ? `All selected records are deleted successfully! `
        : operation === "Archive"
        ? `All selected records are archived successfully! `
        : operation === "Assign"
        ? `The selected content has been assigned to the chosen users successfully! `
        : "";
    successMessage += `(${res?.proceedData}/${res?.totalRequest})`;

    let message =
      res?.proceedData === res?.totalRequest
        ? successMessage
        : `${operation === "Assign" && "Assignment"} Failed (${res?.totalRequest - res?.proceedData}/${
            res?.totalRequest
          })`;

    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      type:requestType,
      data:res,
      records:
        res?.failexcutionResponse && res?.failexcutionResponse?.length > 0
          ? res?.failexcutionResponse
          : [],
      procesFailedError: res?.procesFailedError,
    });
    if (res?.proceedData === res?.totalRequest) {
    }
  };

  const statusChange = (id, newStatus) => {
    var selectedItems = [...selectedItemList];
    if (id === "All") {
      if (newStatus) {
        selectedItems = data.users.map((c) => c?.id);
      } else {
        selectedItems = [];
      }
      setSelectAll(newStatus);
    } else {
      let index = selectedItems.indexOf(id);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      } else {
        selectedItems.push(id);
      }
      setSelectAll(selectedItems?.length === data.users?.length);
    }
    setSelectedItemList(selectedItems);
  };
  
  const onConfirm = () => {
    setShowLoader(true);
    if (selectedItemList !== null && selectedItemList?.length > 0) {
      const reqBody = {
        contentId: contentIds,
        employeeIds: selectedItemList,
      };
      if (type == AssignmentActionType.MODULE) {
        dispatch(assignModulesAsync(reqBody)).then((res) => {
          getUsers()
          getData()
          setShowLoader(false);
          processBulkActResponse(res.payload, "Assign");
        });
      }
      if (type == AssignmentActionType.SEQUENCE) {
        dispatch(assignSequencesAsync(reqBody)).then((res) => {
          getUsers()
          getData()
          setShowLoader(false);
          processBulkActResponse(res.payload, "Assign");
        });
      }
      if (type == AssignmentActionType.PROCESS) {
        dispatch(assignProcessesAsync(reqBody)).then((res) => {
          getUsers()
          getData()
          setShowLoader(false);
          processBulkActResponse(res.payload, "Assign");
        });
      }
      setAssignModals(false);
      setSelectedItemList([]);
      setSelectAll(false);
    }
  };
  useEffect(() => {
    setCurrentPage(1);
  }, [isOrderByDesc]);

  useEffect(() => {
    getUsers();
  }, [isOrderByDesc, currentPage]);

  // console.log(data,"EmployeeModalData");
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  useEffect(() => {
    // console.log(subscriptionData,'subscriptionData');
    const hasSubscriptions = subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems = hasSubscriptions && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      if (basePlan && basePlan?.length > 0) {
        setUserPlan(basePlan[0]?.planName);
        setIsBulkAssign(basePlan[0]?.metaData?.bulk_assign_content === "Yes" ? true : false);
        setIsBulkUpdate(basePlan[0]?.metaData?.bulk_update_status === "Yes" ? true : false);
      }
    }
  }, [
    subscriptionData,
  ]);

  return (
    <>
      <Modal.Header className="py-3" closeButton onClick={() => onClose(false)}>
        <div>
          <Modal.Title>Assign</Modal.Title>
          <ol className="breadcrumb h6 mt-1">
            <li>
              <Link to="/authoring">{title ? title : "Loading..."}</Link>
            </li>
            {/* <li>HR Onboarding</li> */}
            {/* <li>{moduletitle?.title }</li> */}
          </ol>
        </div>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-5 mb-3 pe-40">
        <div className="mb-4 d-flex justify-content-between">
          {/* <input value={inputValue} type="text" onChange={inputChanged} /> */}
            <div className="d-flex gap-4 largeSearch">
              <Search onBlur={inputChanged} placeHolder="Search by employee name or email"/>
              <div className="mySelect h6">
                  <Select
                    isSearchable={false}
                    className="select audSelect"
                    options={optionSort}
                    defaultValue={[optionSort[0]]}
                    classNamePrefix="recatMySel"
                    onChange={(e) => {
                      setFilterss(true);
                      setIsOrderByDesc(e.value);
                      setCurrentPage(1);
                    }}
                  />
                </div>
            </div>
          
            <div className="d-flex justify-content-end align-items-center">
            <Pagination
              totalItems={data?.allRecordsCount}
              itemsPerPage={pageSize}
              onPageChange={onPageChange}
              setFilterss={setFilterss}
              filterss={filterss}
              pageTitle={"Users"}
            />
            </div>
        </div>
        <div className="tableComponent prefTable tableOuter bg-base-100 d-flex flex-column h-100 overflow-auto">
        <table className="table">
            <thead>
              <tr>
                <th>
                  <CheckBox
                    //text="ALL"
                    id={"All"}
                    ststusChange={statusChange}
                    status={selectAll}
                  />
                </th>
                <th>NAME</th>
                <th>TEAM</th>
                <th>CONTACT DETAILS</th>
                <th>ONBOARDED ON</th>
                <th>STATUS</th>
              </tr>
            </thead>
            <tbody>
              {data?.users != undefined &&
                data?.users?.length > 0 &&
                data?.users.map((user) => (
                  <tr>
                  {/* {user.role !== "Admin" ?
                  <> */}
                    <td align="middle">
                      <CheckBox
                        status={selectedItemList.includes(user?.id)}
                        ststusChange={statusChange}
                        id={user.id}
                      />
                    </td>
                    <td className="w15rem">
                      <Link
                        to={{
                          pathname: `/users/${user?.id}`,
                          state: { user },
                          // `/authoring/modules/?id=${c.id}`
                        }}
                        className="text-gray-100 d-flex align-items-center gap-2"
                      >
                        <div className="d-flex align-items-center gap-2">
                          <div className="tableImg">
                            {user?.img && user?.img != "" ? (
                              <UserImage
                                key={user?.id}
                                imgKey={user.img}
                              />
                            ) : (
                              <img
                                src={
                                  user?.img && user?.img != ""
                                    ? user?.img
                                    : getInitialsInName(user?.name ?? "N A", 36)
                                    // : AccountImg
                                }
                                alt={user?.name}
                                key={user?.id}
                              />
                            )}
                          </div>
                          <p className="mb-0">{user?.name}</p>
                        </div>
                      </Link>
                    </td>
                    <td>{user?.team}</td>
                    <td className="w15rem">
                      <a
                        className="text-primary-200 font-medium"
                        href="mailto:mark.wilson@cerebrent.com"
                      >
                        {user?.companyEmail}
                      </a>
                      <br />
                      <a
                        className="text-primary-200 font-medium"
                        href="tel:+123 456 7890"
                      >
                        {user?.phoneNumber}
                      </a>
                    </td>
                    {/* </>: null } */}
                    <td>
                    {getFormatDate(user?.joiningDate)}
                    {/* <DateTimeConverter utcDateTime={user?.joiningDate} /> */}
                    </td>
                    <td>
                    <span class="tableTag text-md  null">{user?.status === "Active" ? "Active" : user.status === "Inactive" ? "Archived" : "NA"}</span>
                    </td> 
                  </tr>
                ))}
              {/* <tr>
                <td align="middle">
                  <CheckBox />
                </td>
                <td>
                  <Link
                    to={`/authoring/processes/${123}`}
                    className="text-gray-100 d-flex align-items-center gap-2"
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div className="tableImg">
                        <img src={AccountImg} alt="" />
                      </div>
                      <p className="text-nowrap mb-0">Ronald Richards</p>
                    </div>
                  </Link>
                </td>
                <td>Jul 20, 2022</td>
                <td>
                  <a
                    className="text-primary-200 font-medium"
                    href="mailto:mark.wilson@cerebrent.com"
                  >
                    mark.wilson@cerebrent.com
                  </a>
                  <br />
                  <a
                    className="text-primary-200 font-medium"
                    href="tel:+123 456 7890"
                  >
                    +123 456 7890
                  </a>
                </td>
                <td>Jul 20, 2022</td>
                <td>
                  <span className="tableTag text-md green">Completed</span>
                </td>
              </tr>
              <tr>
                <td align="middle">
                  <CheckBox />
                </td>
                <td>
                  <Link
                    to={`/authoring/processes/${123}`}
                    className="text-gray-100 d-flex align-items-center gap-2"
                  >
                    <div className="d-flex align-items-center gap-2">
                      <div className="tableName">RR</div>
                      <p className="text-nowrap mb-0">Ronald Richards</p>
                    </div>
                  </Link>
                </td>
                <td>Jul 20, 2022</td>
                <td>
                  <a
                    className="text-primary-200 font-medium"
                    href="mailto:mark.wilson@cerebrent.com"
                  >
                    mark.wilson@cerebrent.com
                  </a>
                  <br />
                  <a
                    className="text-primary-200 font-medium"
                    href="tel:+123 456 7890"
                  >
                    +123 456 7890
                  </a>
                </td>
                <td>Jul 20, 2022</td>
                <td>
                  <span className="tableTag text-md green">Completed</span>
                </td>
              </tr> */}
            </tbody>
          </table>
          {!data?.users?.length > 0 && formDataReady ? <EmptyState message="No user found"/> : null }
        </div>
        <> {showLoader && <Loader />}</>
      </Modal.Body>
      <Modal.Footer className="justify-content-between align-items-center btnFooter">
        <Button
          variant="secondary"
         className="m-0"
          onClick={() => {!isBulkAssign && selectedItemList.length > 1 ? setIsRestrictedModalPopup(true) : setAssignModals(true)}}
          disabled={!contentIds || selectedItemList.length < 1}
        >
          Assign
        </Button>
        {/* <div className="new-pagination">
            <div className="d-flex justify-content-end align-items-center text-gray-1200 h6 font-normal">
                Page <span className="ms-1 me-1 text-gray-100 font-bold text-size">1-15</span>
                <div className="btnArrow ms-2">
                  <Button variant="white p-1" disabled>
                    <IconleftArrow/>
                  </Button>
                  <Button variant="white p-1" className="ms-3">
                    <IconrightArrow/>
                  </Button>
                </div>
            </div>
        </div> */}
      </Modal.Footer>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={assignModals || responsePopup?.show}
      >
        {assignModals && (
          <ModalDelete
            onCancel={setAssignModals}
            onConfirm={onConfirm}
            requestType="Assign"
            headerLable={`Are you sure you want to assign the ${
              type == AssignmentActionType.MODULE
                ? "Module"
                : type == AssignmentActionType.SEQUENCE
                ? "Sequence"
                : "Process"
            } ?`}
            // headerLable={`Are you sure want to assigned to selected ${
            //   type == AssignmentActionType.MODULE
            //     ? "modules"
            //     : type == AssignmentActionType.SEQUENCE
            //     ? "sequences"
            //     : "process"
            // }.`}
            chldLable={`The selected ${
              type == AssignmentActionType.MODULE
                ? "Module"
                : type == AssignmentActionType.SEQUENCE
                ? "Sequence"
                : "Process"
            } will be assigned to the chosen users.`}
          />
        )}
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onResponseClose} />
        )}
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={isRestrictedModalPopup}>
        <RestrictedUpgradeToBusinessModalPopup
          onCancel={setIsRestrictedModalPopup}
          headerLable={""}
          chldLable={""}
        />
      </Modal>
    </>
  );
};

export default EmployeeAssignementPopup;
