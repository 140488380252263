import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { IconGo, IconTimer, IconFilter } from "../../icons/Icons";
import Loader from "../Loader/Loader";
import AccountImg from "../../assets/images/Account.png";
import Search from "../../components/Search/Search";
import NoData from "../../components/EmptyState/NoData";
import UserImage from "../../components/UserImage/UserImage";
import getInitialsInName from "../../helper/GetNameInitials";
import { Link } from "react-router-dom";

import { useDispatch } from "react-redux";
import {
  getUserProfileAsync,
  getNotificationsAsync,
  getMarkOtherNotificationReadAsync,
} from "../../reducers/user-reducers";
import { formatDate, getFormatDate, formatTime, getItemPath } from "../../common";

import { getEmployeeItemPath, calculateRemainingDays } from "../../common";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import { IconrightArrow } from "../../icons/Icons";
import ToasterAlertError from "../../helper/ToasterAlertError";
import { useDebounce } from "../../utils/hooks/useDebounce";

const ActivityLogModal = ({ handleModal }) => {
  const dispatch = useDispatch();
  const [userProfiles, setUserProfiles] = useState(null);
  const [activeTab, setActiveTab] = useState("All");
  const [alertNotification, setAlertNotification] = useState([]);
  const [searchString, setSearchString] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [showLoader, setShowLoader] = useState(false);
  const [userType, setUserType] = useState("Admin");
  const [showLoader1, setShowLoader1] = useState(false);
  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    // if (searchString) {
     // getNotification(1);
     debouncedNotification(1);
    // }
  }, [searchString, userType]);

  useEffect(() => {
    console.log(userProfiles, "userProfiles");
    if (userProfiles) {
      getNotification(1);
      markOtherNotificationRead();
    }
  }, [userProfiles]);

  useEffect(() => {
    const modalBody = document.querySelector(".modal-body");
    if (modalBody) {
      modalBody.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (modalBody) {
        modalBody.removeEventListener("scroll", handleScroll);
      }
    };
  }, [showLoader, currentPage, totalPages]);

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfiles(res.payload);
      }
    });
  };

  const markOtherNotificationRead = async () => {
    const reqBody = {
      userId: userProfiles?.employeeId,
      // userId: "AFCA5765-3AF9-4D35-C12B-08DBE83C92EC",
    };

    const response = await dispatch(getMarkOtherNotificationReadAsync(reqBody));
    if (response.payload) {
      // console.log(response.payload,'markOtherNotificationRead');
      getNotification(1);
    }
  };

  const getNotification = async (page) => {
    try {
      setShowLoader(true);
      setShowLoader1(true);
      const reqBody = {
        page: page || currentPage,
        pageSize: 50,
        orderBy: "",
        isOrderByDesc: true,
        searchString: searchString,
        userId: userProfiles?.employeeId,
        // userId: "AFCA5765-3AF9-4D35-C12B-08DBE83C92EC",
        apiType: activeTab,
      };
      if (userType) {
        reqBody.UserType = userType;
      }
      const response = await dispatch(getNotificationsAsync(reqBody));
  
      if (response.payload) {
        setTotalPages(response?.payload?.totalPages);
        if (page && page > 1) {
          // If loading more pages, append notifications
          setAlertNotification((prevNotifications) => [
            ...prevNotifications,
            ...response.payload.records,
          ]);
        } else {
          // If loading first page, replace existing notifications
          setAlertNotification(response.payload.records);
        }
      }
    } finally {
      setShowLoader(false);
      setShowLoader1(false);
    }
  };
  const debouncedNotification = useDebounce(getNotification, 1000);
  // const getNotification = () => {
  //   const reqBody = {
  //     page: 1,
  //     pageSize: 10,
  //     orderBy: "",
  //     isOrderByDesc: true,
  //     searchString: searchString,
  //     // userId: "AFCA5765-3AF9-4D35-C12B-08DBE83C92EC",
  //     userId: userProfiles?.id,
  //     apiType: activeTab
  //   };
  //   // dispatch(getUserProfileAsync(reqBody)).then((res) => {
  //   dispatch(getNotificationsAsync(reqBody)).then((res) => {
  //     if (res.payload) {
  //       setAlertNotification(res?.payload?.records);
  //     }
  //   });
  // };

  // Define a function to handle pagination changes
  const handlePaginationChange = (newPage) => {
    // Set the new current page
    setCurrentPage(newPage);

    // Call getNotification with the new page
    getNotification(newPage);
  };

  // handleScroll function to call handlePaginationChange when reaching the bottom
  const handleScroll = () => {
    const modalBody = document.querySelector(".modal-body");
    if (modalBody && modalBody.scrollHeight - modalBody.scrollTop === modalBody.clientHeight) {
      // User has scrolled to the bottom
      if (!showLoader && currentPage < totalPages) {
        // Load next page if not already loading and more pages available
        handlePaginationChange(currentPage + 1);
      }
    }
  };

  console.log(alertNotification,'alertNotification');
  const displayedDates = [];

 const handleUserTypeToggle = async () => {
    const newUserType = userType === "Admin" ? "Employee" : "Admin";
    setUserType(newUserType);
    await getNotification(1);
  };

  // Notification Modal start 
const [showNotification, setShowNotification] = useState([]);
useEffect(() => {

  if (activeTab === "All") {
    setShowNotification(alertNotification);
  } else if (activeTab === "Notification") {
    setShowNotification(
      alertNotification.filter((item) => item.notificationType === "Notification")
    );
  } else if (activeTab === "Alerts") {
    setShowNotification(
      alertNotification.filter((item) => item.notificationType === 'Alert')
    );
  }
 
}, [alertNotification, activeTab]);

const handleTabClick = (tabName) => {
  setActiveTab(tabName);
  setCurrentPage(1); 
  getNotification(1); 
};
 // Notification Modal end 
 const handleClick = (event) => {
  event.preventDefault();
  ToasterAlertError('In order to click and view the Notification, please switch the dashboard to "Teams" dashboard');
}

  return (
    <>
      <Modal.Header closeButton>
        <div className="w-100">
          <Modal.Title>
            <div className="d-flex align-items-center justify-content-between">
              <h3>
              Activity Log
              {/* {userType === "Employee" ? "Notification" : "Activity Log"} */}
               </h3>

               {userType === "Employee" && (
              <div className="d-flex gap-3 me-4">
                <div className="">
                  <Search
                    placeHolder="Search by Assignment name"
                    onBlur={setSearchString}
                  />
                </div>
              </div>
              )}
              {/* <div className='d-flex gap-3 me-4'>
                                <div className='mwidth-268'><Search placeHolder='Search by employee name'/></div>

                                <Button variant="white" size="sm" className='text-gray-100 font-normal px-2 py-1 bg-base-100' ><span className='d-inline-block me-1'><IconFilter /></span> Filters</Button>

                            </div> */}
            </div>
          </Modal.Title>
        </div>
      </Modal.Header>
      {userType==="Admin" ? (
      <Modal.Body className="py-32 pt-0">
      {(userProfiles?.roleName === "Admin" || userProfiles?.roleName === "Super Admin") && (
        <div className="d-flex justify-content-end align-items-center switchSticky-area pt-32">
          <div className="emp">Admin</div>
          <div className="form-check form-switch custom-switch customNew-switch p-0">
            <input
              name="switch"
              className="form-check-input"
              type="checkbox"
              onChange={handleUserTypeToggle}
              checked={userType === "Employee"}
            />
          </div>
          <div className="adm">Employee</div>
        </div>
        )}
        <div className="activityLog">
        {showLoader ? (<div style={{height:"calc(100vh - 9.438rem)"}} className="overflow-hidden d-flex align-items-center justify-content-center"><Loader type="activity" /></div>):(<>
            {alertNotification != undefined &&
              alertNotification?.length === 0 && (
                <div className="bg-base-100 d-flex align-items-center justify-content-center text-center" style={{height: "calc(100vh - 9.438rem)"}}>
                  <NoData
                    msgicon="resume"
                    message="There are no notifications & alerts."
                  />
                </div>
            )}
            
            
            {alertNotification != undefined &&
              alertNotification?.length > 0 &&
              alertNotification.map((item, index) => {
                const currentDate = item?.createdDate !== null && formatDate(item?.createdDate);
                return (
                  <>
                    {item?.createdDate !== null && !displayedDates.includes(currentDate) && (() => { 
                      displayedDates.push(currentDate); 
                      return (
                        <h6 className="text-uppercase mb-20">{currentDate}</h6>
                      );
                    })()}

                    <div className={item?.createdDate !== null && !displayedDates.includes(currentDate) ? "pb-40 borderBottom" : ""}>
                        <div className="activity mb-4">
                            {/* <div className="timeSection align-items-center">
                                <span className="tableName timerPipe">
                                <IconTimer />
                                </span>
                                <p className="text-gray-200 h6 time">{formatTime(item?.createdDate)}</p>
                            </div> */}

                            <div className="userDetails">
                                {/* <div class="tableImg">
                                    <img src={AccountImg} alt="" />
                                </div> */}
                                {item.img ? (
                                    <div className="tableImg" key={index}>
                                        <UserImage
                                            key={index}
                                            imgKey={item.img}
                                        />
                                    </div>
                                ) : (
                                    <div className="tableImg" key={index}>
                                        <img
                                        src={getInitialsInName(
                                            item.userName ?? "N A",
                                            26
                                        )}
                                        alt={item.userName}
                                        />
                                    </div>
                                )}
                                <p className="mb-0 font-medium d-flex gap-2">
                                {/* <span>{item?.userName}</span> */}
                                {/* <span className="text-gray-200 font-normal"> {item?.action} </span> */}
                                <Link
                                  to={getItemPath(item?.contentType, item?.contentId, item?.id)}
                                  className="logRedirect"
                                  onClick={() => handleModal(false)}
                                >
                                {/* <a href="#" className="logRedirect"> */}
                                    <span className={`logRedirectText ${item.isRead === false ? 'text-gray-100 font-bold' : 'text-gray-200 font-medium'}`}>
                                    {item?.content}
                                    </span>
                                    <span className="d-none iconGo">
                                    <IconGo />
                                    </span>
                                {/* </a> */}
                                </Link>
                                </p>
                            </div>
                        </div>
                    </div>
                  </>
                );
            })}
            </>
          )}
          {/* <div className="pb-40 borderBottom">
            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className="tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">11:42 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableImg">
                  <img src={AccountImg} alt="" />
                </div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Sansa HR</span>
                  <span className="text-gray-200 font-normal"> edited </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      Sequence: HR Onboarding
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">10:30 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableImg">
                  <img src={AccountImg} alt="" />
                </div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Sania Dsuza</span>
                  <span className="text-gray-200 font-normal"> assigned </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      Module: HR Onboarding to 16 employees
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">08:13 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableImg">
                  <img src={AccountImg} alt="" />
                </div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Mark Williams</span>
                  <span className="text-gray-200 font-normal">
                    {" "}
                    was started{" "}
                  </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      Process: Accounts Onboarding
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">02:03 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableName">KH</div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Kylie HR</span>
                  <span className="text-gray-200 font-normal"> edited </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      {" "}
                      Sequence: HR Onboarding
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">08:13 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableImg">
                  <img src={AccountImg} alt="" />
                </div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Sania Dsuza</span>
                  <span className="text-gray-200 font-normal">assigned</span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      Module: HR Onboarding to 16 employees
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div>

          <h6 className="text-uppercase mt-32 mb-20">JULY 21, 2022</h6>
          <div className="pb-40">
            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">08:30 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableImg">
                  <img src={AccountImg} alt="" />
                </div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Annie Brues</span>
                  <span className="text-gray-200 font-normal"> started </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      {" "}
                      Process: Accounts Onboarding
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">06:14 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableName">CH</div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Cristy HR</span>
                  <span className="text-gray-200 font-normal"> edited </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      {" "}
                      Sequence: HR Onboarding
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">05:10 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableImg">
                  <img src={AccountImg} alt="" />
                </div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Sansa HR</span>
                  <span className="text-gray-200 font-normal"> edited </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      {" "}
                      Sequence: HR Onboarding
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName timerPipe">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">12:03 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableName">KH</div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Sania Dsuza</span>
                  <span className="text-gray-200 font-normal"> assigned </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      {" "}
                      Module: HR Onboarding to 16 employees
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>

            <div className="activity mb-4">
              <div className="timeSection align-items-center">
                <span className=" tableName">
                  <IconTimer />
                </span>
                <p className="text-gray-200 h6 time">10:40 PM</p>
              </div>

              <div className="userDetails">
                <div class="tableName">KH</div>
                <p className="mb-0 font-medium d-flex gap-2">
                  <span>Mark Williams</span>
                  <span className="text-gray-200 font-normal">
                    {" "}
                    was started{" "}
                  </span>
                  <a href="#" className="logRedirect">
                    <span className="logRedirectText">
                      {" "}
                      Process: Accounts Onboarding
                    </span>
                    <span className="d-none iconGo">
                      <IconGo />
                    </span>
                  </a>
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </Modal.Body>
      ):(
        <Modal.Body className="pb-32 pt-0">
        <div className="page mb-4">
          <div className="">
            <ul class="authoringNav EmployeeNotification pb-4">
            {(userProfiles?.roleName === "Admin" || userProfiles?.roleName === "Super Admin") && (
              <div className="PreswitchSticky-areaEmployee">
                <div className="d-flex justify-content-end align-items-center switchSticky-area Employee pt-32">
                  <div className="emp">Admin</div>
                  <div className="form-check form-switch custom-switch customNew-switch p-0">
                    <input
                      name="AccountUpdatesE"
                      className="form-check-input"
                      type="checkbox"
                      onChange={handleUserTypeToggle}
                      checked={userType === "Employee"}
                    />
                  </div>
                  <div className="adm">Employee</div>
                </div>
              </div>
            )}
              <li class="me-4">
                <a
                  aria-current="page"
                  class={`h6 text-gray-100 pb-2 ${
                    activeTab === "All" ? "active" : ""
                  }`}
                  href="#"
                  onClick={() => handleTabClick("All")}
                >
                  All
                </a>
              </li>
              <li class="me-4">
                <a
                  aria-current="page"
                  class={`h6 text-gray-100 pb-2 ${
                    activeTab === "Notification" ? "active" : ""
                  }`}
                  href="#"
                  onClick={() => handleTabClick("Notification")}
                >
                  Notification
                </a>
              </li>
              <li class="me-4">
                <a
                  aria-current="page"
                  class={`h6 text-gray-100 pb-2 ${
                    activeTab === "Alerts" ? "active" : ""
                  }`}
                  href="#"
                  onClick={() => handleTabClick("Alerts")}
                >
                  Alerts
                </a>
              </li>
            </ul>
            {showLoader1 ? (<div style={{height:"calc(100vh - 9.438rem)"}} className="overflow-hidden d-flex align-items-center justify-content-center"><Loader type="activity" /></div>):(<>
            {activeTab === "All" &&
              showNotification != undefined &&
              showNotification?.length === 0 && (
                <div className="NotificationNodataHeight bg-base-100 d-flex align-items-center justify-content-center text-center">
                  <NoData
                    msgicon="resume"
                    message="There are no notifications & alerts."
                  />
                </div>
              )}
            {activeTab === "Notification" &&
              showNotification != undefined &&
              showNotification?.length === 0 && (
                <div className="NotificationNodataHeight bg-base-100 d-flex align-items-center justify-content-center text-center">
                  <NoData
                    msgicon="resume"
                    message="There are no notifications."
                  />
                </div>
              )}
            {activeTab === "Alerts" &&
              showNotification != undefined &&
              showNotification?.length === 0 && (
                <div className="NotificationNodataHeight bg-base-100 d-flex align-items-center justify-content-center text-center">
                  <NoData msgicon="resume" message="There are no alerts." />
                </div>
              )}
             
            {showNotification != undefined &&
              showNotification?.length > 0 &&
              showNotification.map((item, index) => {
                const currentDate = item?.createdDate !== null && formatDate(item?.createdDate);
                return (
                  <>
                    {item?.createdDate !== null && !displayedDates.includes(currentDate) && (() => { 
                      displayedDates.push(currentDate); 
                      return (
                        <p className="mb-4 text-gray-300 text-md font-bold">{currentDate}</p>
                      );
                    })()}
                    {/* <p className="mb-4 text-gray-300 text-md font-bold">LATEST</p> */}
                    
                    {item?.notificationType === 'Notification' && (
                      <div className={item?.createdDate !== null && !displayedDates.includes(currentDate) ? "borderBottom mb-20" : " mb-20"} >
                        <div className="d-flex gap-2 mb-20 align-items-center">
                          <div class="tableImg">
                            {/* <img src={AccountImg} alt="" /> */}
                            {item.img ? (
                                <div className="tableImg" key={index}>
                                    <UserImage
                                        key={index}
                                        imgKey={item.img}
                                    />
                                </div>
                            ) : (
                                <div className="tableImg" key={index}>
                                    <img
                                      src={getInitialsInName(
                                        item.userName ?? "N A",
                                        26
                                      )}
                                      alt={item.userName}
                                    />
                                </div>
                            )}
                          </div>
                          <p className="h6">
                            {/* {item?.userName}{" "} */}
                            {/* <Link
                              to={getEmployeeItemPath(item?.contentType, item?.assignmentSegId, item?.id)}
                              className="logRedirect"
                              onClick={() => handleModal(false)}
                            > */}
                            <Link
                              to=''
                              className="logRedirect"
                              onClick={handleClick}
                            > 
                              <span className={`logRedirectText ${item.isRead === false ? 'text-gray-100 font-bold' : 'text-gray-200 font-medium'}`}>
                                {item?.content}
                              </span>
                              <span className="d-none iconGo">
                                <IconGo />
                              </span>
                              
                            </Link>
                            {/* 📜Onboarding buddy */}
                          </p>
                        </div>

                        {/* <div className="d-flex gap-2 mb-20 align-items-center">
                          <div class="tableImg">
                            <img src={AccountImg} alt="" />
                          </div>
                          <p className="h6">
                            Mark Williams{" "}
                            <span className="text-gray-200">was started </span>
                            😍Process: Accounts Onboarding
                          </p>
                        </div> */}
                      </div>
                    )}

                    {/* <p className="mb-12 text-gray-300 text-md font-bold">
                      THURSDAY
                    </p> */}
                    {item?.notificationType === 'Alert' && (
                      <div className="d-flex gap-2 borderAll rounded-6 bg-gray-600 p-10 mb-4">
                        <div>
                          <svg
                            width="1.5rem"
                            height="1.5rem"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.76 15.92L15.36 4.4C14.5 2.85 13.31 2 12 2C10.69 2 9.49998 2.85 8.63998 4.4L2.23998 15.92C1.42998 17.39 1.33998 18.8 1.98998 19.91C2.63998 21.02 3.91998 21.63 5.59998 21.63H18.4C20.08 21.63 21.36 21.02 22.01 19.91C22.66 18.8 22.57 17.38 21.76 15.92ZM11.25 9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75C11.59 14.75 11.25 14.41 11.25 14V9ZM12.71 17.71C12.66 17.75 12.61 17.79 12.56 17.83C12.5 17.87 12.44 17.9 12.38 17.92C12.32 17.95 12.26 17.97 12.19 17.98C12.13 17.99 12.06 18 12 18C11.94 18 11.87 17.99 11.8 17.98C11.74 17.97 11.68 17.95 11.62 17.92C11.56 17.9 11.5 17.87 11.44 17.83C11.39 17.79 11.34 17.75 11.29 17.71C11.11 17.52 11 17.26 11 17C11 16.74 11.11 16.48 11.29 16.29C11.34 16.25 11.39 16.21 11.44 16.17C11.5 16.13 11.56 16.1 11.62 16.08C11.68 16.05 11.74 16.03 11.8 16.02C11.93 15.99 12.07 15.99 12.19 16.02C12.26 16.03 12.32 16.05 12.38 16.08C12.44 16.1 12.5 16.13 12.56 16.17C12.61 16.21 12.66 16.25 12.71 16.29C12.89 16.48 13 16.74 13 17C13 17.26 12.89 17.52 12.71 17.71Z"
                              fill="#ECB22E"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="text-gray-200 h6 mb-1">
                            {item?.action === "Delayed" && (
                              <>
                                Delayed
                              </>
                            )}
                            {item?.action === "Expired" && (
                              <>
                                Expired on {getFormatDate(item?.dueDate)}
                                {/* <DateTimeConverter utcDateTime={item?.dueDate} /> */}
                              </>
                            )}
                            {item?.action === "Expired Reminder" && (
                              <>
                                Expiring on {getFormatDate(item?.dueDate)}
                                {/* <DateTimeConverter utcDateTime={item?.dueDate} /> */}
                              </>
                            )}
                            {item?.action === "Delayed Reminder" && (
                              <>
                                {calculateRemainingDays(item?.dueDate) > 1 ? "days" : "Today"}  to complete
                              </>
                            )}
                          </p>
                          <p className={`h6 ${item.isRead === false ? 'text-gray-100 font-bold' : 'text-gray-200 font-medium'}`}>
                            {/* <span className="font-normal text-gray-200">
                              {item?.subCategory} :
                            </span>{" "}
                            Roles &amp; Responsibility of employees */}
                            {item?.content}
                          </p>
                        </div>
                        <div className="ms-auto d-flex align-items-center pe-4">
                          {/* <Link
                            to={getEmployeeItemPath(item?.contentType, item?.assignmentSegId, item?.id)}
                            className="text-gray-100 d-flex align-items-center gap-2"
                            onClick={() => handleModal(false)}
                          >*/}
                           <Link
                              to=''
                              className="logRedirect"
                              onClick={handleClick}
                            > 
                            <IconrightArrow />
                          </Link> 
                        </div>
                      </div>
                    )}

                    {/* <div className="d-flex gap-2 align-items-center">
                      <div class="tableImg">
                        <img src={AccountImg} alt="" />
                      </div>
                      <p className="h6">
                        📜JIRA module 1{" "}
                        <span className="text-gray-200">
                          has been assigned to you{" "}
                        </span>
                        by Annie Brues
                      </p>
                    </div> */}
                  </>
                );
              })}
              </>)}
          </div>
          {/* <> {showLoader && <Loader />}</> */}
        </div>
      </Modal.Body>
      )}
      {/* <Modal.Footer className="justify-content-start btnFooter">
                <Button variant="secondary" size="sm" onClick={() => handleModal(false)}>
                    Create page
                </Button>
                <Button variant="white" size="sm">
                    Preview
                </Button>
                <Button variant="white" size="sm" className='border-0'>
                    Save as draft
                </Button>
            </Modal.Footer> */}
    </>
  );
};

export default ActivityLogModal;
