import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  upgradeStorageAsync,
  getSubscriptionAsync,
  getStorageAsync,
  getProrationalInvoiceAsync,
  getCustomerInvoicesAsync,
} from "../../../reducers/subscription-reducers";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import ToasterAlertError from "../../../helper/ToasterAlertError";
import { convertMBToGB, convertKBToGBNumeric, convertGBToKB } from "../../../common";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import {
  getProratedData,
} from "../../../common";

const AddStorageModal = ({handleModal, basePlan}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData, isUpdateLoading, storageData } = useSelector(
    (state) => state.subscription
  );

  const [count, setCount] = useState(1);
  const [incrementCount, setIncrementCount] = useState(100);
  const [storageDatas, setStorageDatas] = useState({});
  const [calcStoragePrice, setCalcStoragePrice] = useState("");
  const [calcStoragePriceTotal, setCalcStoragePriceTotal] = useState("");
  const [isLoader, setIsLoader] = useState(false);
  const [makePayment, setMakePayment] = useState(true);
  const [planType, setPlanType] = useState("");
  const [dueNextCycle, setDueNextCycle] = useState("");
  const [totalStorage, setTotalStorage] = useState(0);
  const [basePlanPrice, setBasePlanPrice] = useState(0);

  const [proDays, setProDays] = useState('');
  const [proAmount, setProAmount] = useState(0);

  useEffect(() => {
    dispatch(getSubscriptionAsync());
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    // storageDatas?.price
    const seatPric = storageDatas ? (storageDatas?.price*count) : 0;
    const seatPricTot = storageDatas ? (storageDatas?.price*count)*12 : 0;

    setCalcStoragePrice(seatPric);
    setCalcStoragePriceTotal(seatPricTot);
    if (count && seatPric && seatPricTot) {
      getProrationalInvoice(count, seatPric, seatPricTot);
    }
  }, [storageDatas, count]);

  useEffect(() => {
    const prevStorage = convertKBToGBNumeric(storageData && storageData?.totalStorage);
    const totalSt = (incrementCount + prevStorage);
    const addGb = convertGBToKB(totalSt);
    console.log(prevStorage,'prevStorage');
    console.log(totalSt,'totalSt');
    console.log(addGb,'addGb');
    setTotalStorage(addGb);
  }, [incrementCount, storageData]);

  useEffect(() => {
    if (subscriptionData?.subscriptionData?.subscriptions?.length > 0) {
      setDueNextCycle(moment(subscriptionData?.subscriptionData?.subscriptions[0]?.currentPeriodEnd).format("MMM D, YYYY"));
    }
    setPlanType(basePlan && basePlan?.planType === "Monthly" ? "month" : "annum");
    // setPlanType("annum");
    setBasePlanPrice(basePlan && basePlan?.price);

    const stData = plansData.filter(
      (plan) =>
        plan.planType === "Monthly" &&
        plan.statementDescriptor === "add_on_storage" &&
        plan.planName === "Add-On Storage"
    );
    // console.log(stData, 'stData');
    setStorageDatas(stData && stData[0]);
  }, [subscriptionData, storageData]);

  const increaseCount = () => {
    setCount(prevCount => prevCount + 1);
    setIncrementCount(prevCount => prevCount + 100);
    setMakePayment(false);
  };

  const decreaseCount = () => {
    setCount(prevCount => prevCount === 1 ? 1 : prevCount - 1);
    setIncrementCount(prevCount => prevCount === 100 ? 100 : prevCount - 100);
    setMakePayment(false);
  };

  const getProrationalInvoice = async (quantity, seatPric, seatPricTot) => {
    // console.log(quantity,'quantity');
    // setIsLoader(true);
    const request = {
      quantity: quantity,
      seatQuantity: '',
    };
    await dispatch(getProrationalInvoiceAsync(request)).then((res) => {
      // console.log(res.payload,'getProrationalInvoiceAsync');
      if (res?.payload && res?.payload?.lines?.length > 0) {
        const endDate = res?.payload?.periodEnd;
        const startDate = res?.payload?.periodStart;
        const totalAmount = planType === 'month' ? seatPric : seatPricTot;
        // getProratedData(planType, totalAmount, startDate, endDate);
        const { timeRemaining, amount } = getProratedData(planType, totalAmount, startDate, endDate);
        setProAmount(amount);
        setProDays(timeRemaining);
      }
    });
  };

  // console.log(subscriptionData,'subscriptionData Add Storage');
  // console.log(plansData,'plansData Add Storage');
  const upgradeStorage = async () => {
      setMakePayment(true);  
      setIsLoader(true);
      const request = {
          quantity: count,
          seatQuantity: '',
      };
      await dispatch(upgradeStorageAsync(request)).then((res) => {
          console.log(res);
          setIsLoader(false);
          if (res.payload === "Success") {
              dispatch(getStorageAsync());
              dispatch(getCustomerInvoicesAsync());
              ToasterAlertSucces(res, 'Storage upgraded successfully!'); 
              handleModal(false);
          } else {
              console.log('additional storage error');
              // ToasterAlertError(res.payload?.value?.data);
              ToasterAlertError('Storage upgrade failed!');
          }
      });
  };

  return (
    <>
      {isLoader && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <Modal.Header closeButton>
        <h3 className="">Add Storage</h3>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <div className="d-flex align-items-start flex-column h-100">
          <div className="w-100 mb-5">
            <label className="d-inline-block text-uppercase text-gray-300 text-md font-medium mb-3 opacity-75">
              ADD-ON
            </label>
            <div className="border border-gray-400 rounded-6 p-4 pt-32 w-100">
              <p className="mb-1 text-base-200 text-md font-bold">STORAGE</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <div className="d-flex align-items-center">
                    <button
                      className="btn-white btn-xs bg-gray-600"
                      onClick={decreaseCount}
                    >
                      <svg
                        width="16"
                        height="2"
                        viewBox="0 0 16 2"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.48096 1H14.518"
                          stroke="#232323"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                    <h6 className="text-gray-200 mx-3">{incrementCount}</h6>
                    <button
                      className="btn-white btn-xs bg-gray-600"
                      onClick={increaseCount}
                    >
                      <svg
                        width="20"
                        height="20"
                        viewBox="0 0 22 22"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M4.48096 11H17.518"
                          stroke="#232323"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                        <path
                          d="M11 17.5185V4.48145"
                          stroke="#232323"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        ></path>
                      </svg>
                    </button>
                    <h6 className="text-gray-100 ms-2">GB</h6>
                  </div>
                  <p className="mb-0 text-lg text-gray-100 font-medium opacity-75 ms-4">
                    ${storageDatas?.price}/100 GB/{planType}
                  </p>
                </div>
                <p className="mb-0 text-lg text-gray-100 font-medium">${calcStoragePrice}</p>
              </div>
            </div>
          </div>
          <div className="mt-auto w-100">
            <div className="w-100">
              <label className="d-inline-block text-uppercase text-gray-300 text-md font-medium mb-3 opacity-75">
                Billing for Add ons
              </label>
              <div className="d-flex justify-content-between align-items-center w-100 pb-1 px-2">
                <p class="mb-0 text-lg text-uppercase text-gray-100 font-normal Total pt-1">
                  Total
                </p>
                <div class="text-lg text-gray-100 font-medium">
                $ {planType === 'month' ? calcStoragePrice : calcStoragePriceTotal} <span class="opacity-75 text-xs">/{planType}</span>
                </div>
              </div>
              {/* {!makePayment && ( */}
                <>
                  <div
                    class="d-flex justify-content-between align-items-center py-2 px-2 rounded-6 mt-3"
                    style={{ background: "rgba(74, 21, 75, 0.10)" }}
                  >
                    <p class="mb-0 text-gray-1200 font-bold text-lg ms-1 mt-1 mb-1">
                      Pro-rated for {proDays}
                    </p>
                    <h3 class="font-bold pe-1 mb-1 mt-1">$ {proAmount.toFixed(2)}</h3>
                  </div>
                  <p className="mb-0 text-gray-300 text-md mt-1">
                    You will be charged <b>${proAmount.toFixed(2)}</b> today for additional{" "}
                    <b>{incrementCount} GB</b>, bringing your total to <b>{convertMBToGB(totalStorage)}</b>.
                  </p>
                  {/* <p className="mb-0 text-gray-300 text-md mt-1">
                    {" "}
                    The payment of <b>${calcStoragePrice+basePlanPrice}</b> will be due next cycle,{" "}
                    <b>{dueNextCycle}.</b>
                  </p> */}
                </>
              {/* )} */}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start btnFooter gap-2 py-3">
        <Button
          variant="secondary" 
          size="large"
          // disabled={makePayment}
          onClick={() => upgradeStorage()}
        >
          Make Payment
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AddStorageModal;
