import React from "react";
import { Modal, Button } from "react-bootstrap";
import { CrossIconRounded } from "../../../icons/Icons";
import RadioButton from "../../../components/RadioButton/RadioButton";

const DowngradePlanModal = () => {
  return (
    <>
      <Modal.Header closeButton></Modal.Header>

      <Modal.Body className="pt-4 pb-4">
        <h3 className="text-gray-100">Downgrade my plan</h3>
        <p className="text-gray-300 h5 font-normal mb-0 mt-1">
          Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem ipsum Lorem
          ipsum
        </p>
        <div className="w-605 mt-4 border-top border-bottom border-gray-400">
          <div className="d-flex flex-col flex-grow usagePlanBox h-100 align-items-start">
            <div className="first pt-10 h-100 ps-2">
              <span className="text-base-100 text-lg font-bold py-1 px-12 rounded-pill mb-12 d-inline-block">
                Dummy for space manage
              </span>
              <p className="mb-12 text-gray-1200 font-medium text-lg">
                Active Users
              </p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">
                Storage Utilization
              </p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">
                Processes
              </p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">
                Sequences
              </p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">
                Modules
              </p>
            </div>
            <div className="second pt-10 h-100 text-center">
              <span className="text-base-100 text-lg font-medium bg-primary-200 py-1 px-12 rounded-pill mb-12 d-inline-block">
                Current Usage
              </span>
              <p className="mb-12 text-gray-1200 font-medium text-lg">30</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">2GB</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">3</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">5</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">54</p>
            </div>
            <div className="third pt-10 h-100 text-center">
              <span className="text-primary-200 text-lg font-bold py-1 px-12 rounded-pill mb-12 d-inline-block">
                New Plan Limits
              </span>
              <p className="mb-12 text-gray-1200 font-medium text-lg">20</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">100MB</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">0</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">3</p>
              <p className="mb-12 text-gray-1200 font-medium text-lg">20</p>
            </div>
          </div>
        </div>
        <div className="mt-40">
          <h5 className="font-bold text-gray-100 mb-12">
            You will lose access to
          </h5>
          <ul className="p-0">
            <li className="d-flex justify-content-start align-items-center gap-2 text-gray-1200 font-medium text-lg mb-12">
              <CrossIconRounded />
              <span>Adding more than 20 active users</span>
            </li>
            <li className="d-flex justify-content-start align-items-center gap-2 text-gray-1200 font-medium text-lg mb-12">
              <CrossIconRounded />
              <span>Content storage more than 100MB</span>
            </li>
            <li className="d-flex justify-content-start align-items-center gap-2 text-gray-1200 font-medium text-lg mb-12">
              <CrossIconRounded />
              <span>Creating more than 3 sequences & 20 modules</span>
            </li>
            <li className="d-flex justify-content-start align-items-center gap-2 text-gray-1200 font-medium text-lg mb-12">
              <CrossIconRounded />
              <span>Videos of community knowledge base</span>
            </li>
            <li className="d-flex justify-content-start align-items-center gap-2 text-gray-1200 font-medium text-lg mb-12">
              <CrossIconRounded />
              <span>
                Prompt support desk turnaround (it will take 2 business days)
              </span>
            </li>
          </ul>
        </div>
        <div className="mt-40">
          <h5 className="font-bold text-gray-100 mb-12">
            Let us know the issue you are facing with the current plan
          </h5>
          <ul className="p-0">
            <li className="d-flex justify-content-start align-items-center text-gray-1200 font-medium text-lg mb-12">
              <div className="mtm-32">
                <RadioButton />
              </div>
              <span>Plan is too expensive</span>
            </li>
            <li className="d-flex justify-content-start align-items-center text-gray-1200 font-medium text-lg mb-12">
              <div className="mtm-32">
                <RadioButton />
              </div>
              <span>Do not need some features</span>
            </li>
            <li className="d-flex justify-content-start align-items-center text-gray-1200 font-medium text-lg mb-12">
              <div className="mtm-32">
                <RadioButton />
              </div>
              <span>Organization Budget Cuts</span>
            </li>
            <li className="d-flex justify-content-start align-items-center text-gray-1200 font-medium text-lg mb-12">
              <div className="mtm-32">
                <RadioButton />
              </div>
              <span>Others</span>
            </li>
          </ul>
          <div className="mt-4">
            <h6 className="text-gray-1200 font-medium">
              Provide a detailed reason for downgrading{" "}
              <span className="text-primary-100">*</span>
            </h6>
            <div className="mt-3 w-472">
                <textarea rows="3" placeholder='Add description'></textarea>
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start gap-2">
        <Button variant="primary" size="lg" className="border-0 pe-">
          Cancel
        </Button>
        <Button variant="white" size="lg" onClick={function noRefCheck() {}}>
          Raise downgrade request
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DowngradePlanModal;
