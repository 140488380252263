import Reac, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import RadioButton from "../../../components/RadioButton/RadioButton";


const planReasons = [
  "Plan is too expensive",
  "Don’t need some features",
  "Organization Budget Cuts",
  "Others",
];

const DowngradeModal = ({
  downgradePlan,
  handleModal,
  confirm,
  request,
  setRequest,
}) => {
  const [showOther, setShowOther] = useState(false);
  const onhandlePlan = (e) => {
    if (e.target.value === planReasons[3]) {
      setShowOther(true);
      setRequest({ ...request, planReason: e.target.value });
    } else {
      setShowOther(false);
      setRequest({ planReason: e.target.value, other: "" });
    }
  };
  const onhandleOther = (e) => {
    setRequest({ ...request, other: e.target.value });
  };
  return (
    <>
      <Modal.Header closeButton>
        {/* <button className="close" onClick={() => handleModal(false)}>
          <span>&times;</span>
        </button> */}
      </Modal.Header>

      <Modal.Body className="pt-4 pb-4">
        <h3 className="mt-3">Looks like you are  downgrading your plan.</h3>
        <p className="text-gray-300 mb-0 mt-2 mb-5">
          Let us know whats is the issue you faced with current plan.
        </p>
        <div className="">
          {planReasons.map((planReasonData) => (
            <div className="mb-2 pb-1">
              <label className="rarioBox d-flex align-items-center">
                <input
                  type="radio"
                  name="plan"
                  onChange={(e) => onhandlePlan(e)}
                  value={planReasonData}
                  checked={request.planReason === planReasonData}
                />
                <span className="radioBoxCheck"> </span>
                {planReasonData && (
                  <span className="ps-1 text-sm font-medium">
                    {planReasonData}
                  </span>
                )}
              </label>
            </div>
          ))}
          {/* <div className='mb-3'>

const DowngradeModal = ({ handleModal }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <h3 className='mt-3'>It seems you're downgrading your plan.</h3>
                <p className='text-gray-300 mb-0 mt-2 mb-5'>
                 Please help us understand the issues you faced with your current plan. 

                </p>
                <div className=''>
                    <div className='mb-3'>

                        <RadioButton name="downgrad" text="Plan is too expensive" />
                    </div>
                    <div className='mb-3'>
                        <RadioButton name="downgrad" text="Don’t need some features" />
                    </div>
                    <div className='mb-3'>
                        <RadioButton name="downgrad" text="Organization Budget Cuts" />
                    </div>
                    <div className='mb-3'>
                        <RadioButton name="downgrad" text="Others" />
                    </div> */}
        </div>
        {showOther && (
          <div className="mb-3 pb-3">
            <textarea
              className="input bg-gray-600"
              rows={4}
              placeholder="Describe your issue"
              onChange={(e) => onhandleOther(e)}
            >
              {request.other}
            </textarea>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter gap-2">
        <Button
          variant="white"
          size="large"
          onClick={() => handleModal(false)}
        >
          Cancel
        </Button>
        <Button
          variant="primary"
          size="large"
          className="border-0"
          onClick={confirm}
          disabled={
            request.planReason
              ? request.planReason === planReasons[3]
                ? request.other
                  ? false
                  : true
                : false
              : true
          }
        >
          Submit
        </Button>
      </Modal.Footer>
    </>
  );
};

export default DowngradeModal;
