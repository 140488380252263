import React from 'react';
import { Row, Col } from 'react-bootstrap';
import { Link } from "react-router-dom";
import { 
  mapStatusToValue,
  getFormatDate,
  getShowNowUrl,
} from "../../../common";
import AccordionProgressBar from '../../../components/AccordionProgressBar/AccordionProgressBar';
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";

const EmpDashboardCardLarge = ({ assignment, JournyImg }) => {
  let dueText;
  let totalDays;

  if (assignment.recommendedTimeDurationType === 0) {
    if (assignment.recommendedTime < 1440) {
      dueText = '1 day';
      totalDays = 1 + parseInt(assignment.recommendedTime / 1440);
    } else {
      const days = Math.ceil(assignment.recommendedTime / 1440);
      dueText = `${days} days`;
      totalDays = days;
    }
  } else if (assignment.recommendedTimeDurationType === 1) {
    if (assignment.recommendedTime < 24) {
      dueText = '1 day';
      totalDays = 1 + parseInt(assignment.recommendedTime / 24);
    } else {
      const days = Math.ceil(assignment.recommendedTime / 24);
      dueText = `${days} days`;
      totalDays = days;
    }
  } else if (assignment.recommendedTimeDurationType === 2) {
    dueText = `${assignment.recommendedTime} days`;
    totalDays = parseInt(assignment.recommendedTime);
  } else if (assignment.recommendedTimeDurationType === 3) {
    const days = assignment.recommendedTime * 7;
    dueText = `${days} days`;
    totalDays = days;
  }

  const assignedOn = new Date(assignment.assignedOn); 

  const currentDate = new Date();
  const dueDate = new Date(assignedOn);
  dueDate.setDate(assignedOn.getDate() + totalDays);

  const timeDifference = dueDate - currentDate; 
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  console.log(daysRemaining,'daysRemaining')
  return (
    <div className="journyCardshadow">
      <Row className="g-0">
      {/* <Row className="g-0 align-items-center"> */}
        <Col lg={5}>
          <img src={JournyImg} alt="Jimg" className="w-100 max-298 aspect-ratio-1" />
        </Col>
        <Col lg={7} className="px-3">
          <div className="journyCard position-relative d-flex align-items-center">
            <div className="">
              <span className={`tableTag ${assignment.status === 2 ? 'orange' : 'blue'} text-md`}>
                {mapStatusToValue(assignment.status)}
              </span>
              <h3 className="mt-3 mb-2 pb-1 ec2line">{assignment.title}</h3>
              <p className="text-sm m-0 text-gray-200 ec4line">{assignment.description}</p>
              <div className="exp text-12 pt-2 pb-3 text-gray-300">
                <span>
                  <b>{assignment.moduleCount}</b> Modules
                </span>
                {/* <span>
                  Due on <b>
                  {dueText && <span>{dueText}</span>}
                  
                  </b>
                </span> */}
                {daysRemaining < 0 ? (
                    assignment.expiryDate !== null ? (
                        <span>Expiring on <b>
                        {getFormatDate(assignment.expiryDate)}
                        {/* <DateTimeConverter utcDateTime={assignment.expiryDate} /> */}
                        </b></span>
                    ) : null
                ) : (
                    <span>
                        Due on <b>
                        {daysRemaining === 1 ? `${daysRemaining} day` : `${daysRemaining} days`}
                        </b>
                    </span>
                )}
              </div>
              {assignment.type === "Sequence" && assignment.modules?.length > 0 && (
                <Link
                  to={{
                    pathname: getShowNowUrl(
                      assignment.id,
                      assignment.assignmentId,
                      assignment.modules[0].assignmentSegId,
                      assignment.type,
                      assignment.modules[0].id,
                      // assignment.modules[0].title,
                      assignment.title,
                      "Module/"
                    ),
                    state: { assignmentId: assignment.assignmentId }
                  }}
                  target="_blank"
                  className="btn-secondary btn-sm py-2"
                >
                  Resume
                </Link>
              )}

              {assignment.type === "Module" && (
                <Link
                  to={{
                    pathname: getShowNowUrl(
                      assignment.id,
                      assignment.assignmentId,
                      assignment.assignmentSegId,
                      assignment.type,
                      assignment.id,
                      assignment.title,
                    ),
                    state: { assignmentId: assignment.assignmentId }
                  }}
                  target="_blank"
                  className="btn-secondary btn-sm py-2"
                >
                  Resume
                </Link>
              )}
            </div>
            {/* <AccordionProgressBar value={50} /> */}
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default EmpDashboardCardLarge;
