import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import { Link , useLocation, useParams } from "react-router-dom";
import Header from "./Header";
import FormPreview from "./FormPreview";
import PagePreview from "./PagePreview";
import { useDispatch } from "react-redux";
import { getModulePreviewAsync } from "../../../reducers/module-reducers";
import { getSequencePreviewAsync } from "../../../reducers/sequence-reducers";
import { getUserProfileAsync} from "../../../reducers/user-reducers";

const Preview = ({ actionReport, setContent}) => {
  const location = useLocation()
  const target = useRef(null);
  const playerRef = useRef();
  const [contentData, setContentData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [userProfiles, setUserProfiles] = useState(null);
  const dispatch = useDispatch();
 
// Get ID from URL
const params = useParams();
const { id, previewType, contentType } = params;
const getContent =async()=>{
  if (previewType === "module") {
    // const previewResponse = await dispatch(
    //   getModulePreviewAsync(id)
    // );
    // console.log(previewResponse, "previewResponse")
    // if(previewResponse.payload){
    //   const previewData = [];
    //   let {forms, pages} = previewResponse.payload[0]
    //   setPreviewData(previewResponse.payload[0])
    //    previewData.push(...pages, ...forms);
    //    setContentData(previewData)
    // }
    const previewResponse = await dispatch(getModulePreviewAsync(id));
    console.log(previewResponse, "previewResponse");

    if (previewResponse.payload && previewResponse.payload.length > 0) {
      const { pagesForms } = previewResponse.payload[0];
      console.log(pagesForms,"pagesForms")
      setPreviewData(pagesForms);
      setContentData(pagesForms);
    }
  }

  else if(previewType === "process"){
    let previewData = {};
    if (contentType === "Module") {
      const modulePreviewResponse = await dispatch(getModulePreviewAsync(id));
      
      if(modulePreviewResponse.payload){
         previewData = modulePreviewResponse.payload[0];
      }
    } else if (contentType === "Sequence") {
      const previewResponse = await dispatch(getSequencePreviewAsync(id));
      // const forms = [];
      // const pages = [];
      // previewResponse.payload &&
      //   previewResponse.payload.map((v, i) => {
      //     forms.push(...v.forms);
      //     pages.push(...v.pages);
      //   });
      // previewData = {
      //   forms,
      //   pages,
      // };
      previewData = previewResponse.payload[0];
    }
    // const setPreviewDatas = [];
    // let {forms, pages} =previewData
    // setPreviewData(previewData)
    // setPreviewDatas.push(...pages, ...forms);
    // setContentData(setPreviewDatas)
    const { pagesForms } = previewData;
      console.log(pagesForms,"pagesForms")
      setPreviewData(pagesForms);
      setContentData(pagesForms);
  }

  else if(previewType === "sequence"){
    const modulePreviewResponse = await dispatch(getModulePreviewAsync(id));
    console.log(modulePreviewResponse, "previewResponse");
    // if(modulePreviewResponse.payload){
    //   const previewData = [];
    // let {forms, pages} = modulePreviewResponse.payload[0];
    // setPreviewData(modulePreviewResponse.payload[0])
    // previewData.push(...pages, ...forms);
    // setContentData(previewData)
    // }
    if (modulePreviewResponse.payload && modulePreviewResponse.payload.length > 0) {
      const { pagesForms } = modulePreviewResponse.payload[0];
      console.log(pagesForms,"pagesForms")
      setPreviewData(pagesForms);
      setContentData(pagesForms);
    }
  }
}

  // const previewData = [];
  // const question = [];
  // const setContentList = ()=>{
  //   alert("fgfg")
  //   console.log(contentData)
  //   let {forms, pages} = contentData
  // forms && forms.map((v,i)=>{
  //     v.questions.map((v,i) =>  question.push(v))
  //    })
  //    // previewData.push(...forms, ...pages);
  //  previewData.push(...pages, ...forms);

  // }


  const ImgRef = useRef();
  const [current, setCurrent] = useState(0);
  const length = contentData.length;
  
      function nextSlide() {
          setCurrent( current === length - 1 ? 0 : current + 1 );
      }
  
      function prevSlide() {
          setCurrent( current === 0 ? length - 1 : current - 1 );
      }

  useEffect(() => {
    getContent();
    getUserProfile();
  }, []);
 
  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfiles(res.payload);
      }
    });
  };
  console.log(contentData,"contentData")
  return (
    <div className="pages">
    {console.log(previewData,'previewData')}
      <Header nextSlide={nextSlide} prevSlide={prevSlide}  contentLenght={length}  currentState={current} contentData={previewData} userProfiles={userProfiles}/>
      <div className="position-relative" style={{ top: "-1px" }}>
        <div
          className="progress tableProgress bg-base-100 "
          style={{ height: "2px" }}
          ref={target}
          // onClick={handleSeekMouseUp}
        >
          <div
            className="progress-bar bg-primary-200"
            role="progressbar"
            style={{ width: "21%" }}
          ></div>
        </div>
      </div>

      <div className="pageOuter pb-0">
        <Row className="g-0 h-100">
          <Col className="col-lg-12 bg-base-100">
            <div>
              {contentData && contentData.length > 0 && contentData.map((slide, index) => {
                if(slide.form !== null){
                  return index === current && <FormPreview  actionReport={actionReport} data={slide.form} setContent={setContent} allData={contentData} userProfiles={userProfiles} currentState={current}/>
                } else {
                  return index === current && <PagePreview  actionReport={actionReport} data={slide.page} setContent={setContent} allData={contentData} userProfiles={userProfiles} currentState={current}/>
                }
              })}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Preview;
