import React, { useState, useEffect } from "react";
import { Row, Col, Container, Accordion, Modal, Button } from "react-bootstrap";
import {
  IconStar,
  IconTopSearch,
  IconTick,
  IconExclamation,
  IconDuplicate,
  IconEdit2,
  IconArchive,
  IconDelete,
  IconHamberger,
  IconEyeColored,
  FeedbackIconGrey,
  Expired
} from "../../../icons/Icons";
import OwnerImg from "../../../assets/images/Account.png";
import AssignProcessModal from "./AssignProcessModal";
import CheckBoxMd from "../../../components/CheckBox/CheckBoxMd";
import ProcessExpiredModal from "./ProcessExpiredModal";
import ProcessFeedbackModal from "./ProcessFeedbackModal";
import { Link, useLocation, useHistory, Switch } from "react-router-dom";
import NoContent from "./NoContent";
import ActivityLog from "../../../components/ActivityLog//ActivityLog";
import { getFormatDate, getVisibilityStatus, Status } from "../../../common";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import Loader from "../../../components/Loader/Loader";
import {
  deleteProcessesAsync,
  getProcessesDetailsAsync,
  getProcessesPreviewAsync,
} from "../../../reducers/processes-reducers";
import { useDispatch, useSelector } from "react-redux";
import EmployeeAssignementPopup from "../../../components/EmployeeAssignementPopup/EmployeeAssignementPopup";
import { AssignmentActionType } from "../../../services/DataConstants";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import AudienceModal from "../../Authoring/Audience/AudienceModal"
import FeedbackView from "./FeedbackView";
import { getSequencePreviewAsync } from "../../../reducers/sequence-reducers";
import { getModulePreviewAsync } from "../../../reducers/module-reducers";
import EmptyState from "../../../components/EmptyState/EmptyState";
import ModalMessageAlertPopup from "../../../components/ModalPopup/ModalMessageAlertPopup";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";

import {
  getUserProfileAsync,
  getMarkNotificationReadAsync,
} from "../../../reducers/user-reducers";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader"

const ProcessesDetail = () => {
  const dispatch = useDispatch();
  const deleteStatus = useSelector((state) => state.process.processesDelete);
  const currentProcess = useSelector((state) => state.process.currentProcess);
  const [modalAssignProcess, setModalAssignProcess] = useState(false);
  const [modalExpiredProcess, setModalExpiredProcess] = useState(false);
  const [modalFeedbackProcess, setModalFeedbackProcess] = useState(false);
  const { state } = useLocation();
  const [noContent, setnoContent] = useState(false);
  const [processId, setProcessId] = useState(null);
  const [ProcessesDetails, setAdditionalStorage] = useState(null);
  const [processesPreview, setProcessesPreview] = useState([]);
  const [selctedChekd, setSelctedChekd] = useState("All");
  const [contentList, setContentList] = useState([]);
  const navigate = useHistory();
  const [formDataReady, setFormDataReady] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [openAudienceModal, setOpenAudienceModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [audienceList, setAudienceList] = useState(null)
  const [showAlertMessagePopup, setShowAlertMessagePopup] = useState(false);

  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [listData, setlistData] = useState(null);

  const [duplicateModal, setDuplicateModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  console.log(state,'statestatestate');

  const [userProfiles, setUserProfiles] = useState(null);
  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    markNotificationRead();
  }, [state?.notificationId, userProfiles]);

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfiles(res.payload);
      }
    });
  };

  const markNotificationRead = async () => {
    const reqBody = {
      userId: userProfiles?.employeeId,
      notificationId: state?.notificationId,
    };

    const response = await dispatch(getMarkNotificationReadAsync(reqBody));
    if (response.payload) {
      console.log(response.payload,'markOtherNotificationRead');
    }
  };

  const setData = async () => {
        let response = await dispatch(getProcessesDetailsAsync(state.id));
        console.log(response,'responseresponseresponse');
        // const processesPreviewResponse = await dispatch(getProcessesPreviewAsync(state.id));
    // setProcessesPreview(processesPreviewResponse.payload[0]);
        setAdditionalStorage(response.payload);
        setContentList(response.payload?.content ?? []);
        setlistData(response.payload?.content ?? []);
        setAudienceList(response.payload?.assignment ?? []);
        setFormDataReady(true);
  };

  const getPreviewData = async (id, c) => {
    let previewData = {};
    if (c.type === "Module") {
      const modulePreviewResponse = await dispatch(getModulePreviewAsync(id));
      previewData = modulePreviewResponse.payload[0];
    } else if (c.type === "Sequence") {
      const previewResponse = await dispatch(getSequencePreviewAsync(id));
      const forms = [];
      const pages = [];
      previewResponse.payload &&
        previewResponse.payload.map((v, i) => {
          forms.push(...v.forms);
          pages.push(...v.pages);
        });
      previewData = {
        forms,
        pages,
      };
    }

    // previewData.push(...forms, ...pages);
    localStorage.setItem(
      "propsData",
      JSON.stringify({
        state: {
          propsData: previewData,
          opertionType: "preview",
          moduleId: state.id,
          redirectFrom: `/authoring/processes/details`,
          redirectTo: `/authoring/preview`,
          headerFirstTitle: ProcessesDetails.title,
          headerSecondTitle: c.title,
        },
      })
    );
  };

  useEffect(() => {
    if (state?.id !== null) {
      setProcessId(state.id);
    }
    if (state?.id !== null) {
      setData();
    }
  }, [state?.id]);

  const onConfirmDuplicate = async (confirmstatus) => {
    // Redirect to the desired URL
    // window.location.href = redirectUrl;
    // navigate.push('/authoring/modules/details');
    // navigate.push(redirectUrl);
    navigate.push(redirectUrl, { propsData: ProcessesDetails, operationType: 'Duplicate', redirectTo: "/authoring/processes/details" });
    setDuplicateModal(false);
  }

  const onConfirm = async (confirmstatus) => {
    if (processId !== null && processId !== undefined) {
      const jsonData = {
        id: processId,
        status: confirmstatus,
      };

      const response = await dispatch(deleteProcessesAsync(jsonData));
      if (confirmstatus === "Archive") {
        if (response.payload) {
          // ToasterAlertSucces(response, ErrrorMessage.Archive);
          ToasterAlertSucces(response, "Your process has been archived successfully.");
          navigate.push("/authoring/");
        } else {
          setArchiveModal(false);
          setShowAlertMessagePopup(true);
        }
      } else {
        // ToasterAlertSucces(response, ErrrorMessage.DELETE);
        ToasterAlertSucces(response, "Your process was deleted successfully.");
        navigate.push("/authoring/");
      }
    }
  };

  const onCheck = (value, type) => {
    setSelctedChekd(type);
    var data = contentList;
    switch (type) {
      case "All":
        return setContentList(ProcessesDetails.content);
      case "Sequences":
        return setContentList(
          ProcessesDetails.content.filter((f) => f.type === "Sequence")
        );
      case "IM":
        return setContentList(
          ProcessesDetails.content.filter((f) => f.type === "Module")
        );
      default:
        return setContentList(ProcessesDetails.content);
    }
  };

  const searchData = (e) => {
    const list = [];
    ProcessesDetails.content.filter((f) => {
      if (
        f.title
          .trim()
          .toLowerCase()
          .indexOf(e.target.value.trim().toLowerCase()) > -1
      ) {
        list.push({ ...f,});
      }
    });

    setlistData(list);
    switch (selctedChekd) {
      case "All":
        return setContentList(list ?? []);
      case "Sequences":
        return setContentList(
          list.filter((f) => f.type === "Sequence")
        );
      case "IM":
        return setContentList(
          list.filter((f) => f.type === "Module")
        );
      default:
        return setContentList(list ?? []);
    }
    // setContentList(list ?? []);
  };
  const handleCloseAssignModal = () => {
    setOpenAudienceModal(false);
    setOpenFeedbackModal(false);
  };

  console.log(contentList,'contentList');
  console.log(listData,'listData');
  console.log(ProcessesDetails,'ProcessesDetails');
  
  return (
    <>
      {ProcessesDetails !== undefined &&
        ProcessesDetails !== null &&
        formDataReady !== false && (
          <div className="pages">
            <RenewalHeader />
            {/* <button onClick={() => setModalExpiredProcess(true)}>setModalExpiredProcess</button>
            <br />
            <button onClick={() => setModalFeedbackProcess(true)}>setModalFeedbackProcess</button> */}
            <header className="pageHeader">
              <Container fluid>
                <Row className="align-items-center">
                  <Col className="col-lg-4">
                    <div className="d-flex align-items-center gap-2 w-100">
                      <Link to="/authoring">
                        <span className="arrow left"></span>
                      </Link>
                      <div className="mw-90">
                        <p className="h6 text-gray-200 mb-1">All Processes</p>
                        <h3 className="text-truncate">
                          {ProcessesDetails.title}
                        </h3>
                      </div>
                    </div>
                  </Col>
                  <Col className="text-end col-lg-8">
                    <div className="d-flex align-items-center justify-content-end SupportIconBtn gap-4">
                      {/* {getVisibilityStatus(
                        ProcessesDetails.status,
                        "Duplicate"
                      ) && ( */}
                      <Link
                        className="m-0"
                        to={{
                          pathname: `/authoring/processes/create`,
                          state: {
                            propsData: ProcessesDetails,
                            opertionType: "Duplicate",
                            redirectTo: "/authoring/processes/details",
                          },
                        }}
                        onClick={(e) => {
                          e.preventDefault();
                          setDuplicateModal(true);
                          setRedirectUrl(e.currentTarget.pathname);
                        }}
                      >
                        <Button variant="" className="border-0 p-0">
                          <div className="d-flex gap-2 align-items-center">
                            <IconDuplicate />
                            <p className="h6 text-gray-300">Duplicate</p>
                          </div>
                        </Button>
                      </Link>
                      {/* )} */}
                      {/* {getVisibilityStatus(
                        ProcessesDetails.status,
                        "Delete"
                      ) && (
                        ProcessesDetails.audience === 0 && (
                          <Button
                            variant=""
                            className="border-0 p-0 m-0"
                            onClick={() => setDeleteModal(true)}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <IconDelete />
                              <p className="h6 text-gray-300">Delete</p>
                            </div>
                          </Button>)
                      )} */}
                      {getVisibilityStatus(ProcessesDetails.status, "Delete") &&
                        ProcessesDetails.status !== "Archived" &&
                        ProcessesDetails?.audience === 0 && (
                          <Button
                            variant=""
                            className="border-0 p-0 m-0"
                            onClick={() => setDeleteModal(true)}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <IconDelete />
                              <p className="h6 text-gray-300">Delete</p>
                            </div>
                          </Button>
                        )}
                      {getVisibilityStatus(ProcessesDetails.status, "Edit") && (
                        <Link
                          className="m-0"
                          to={{
                            pathname: `/authoring/processes/edit`,
                            state: {
                              propsData: ProcessesDetails,
                              opertionType: "Edit",
                              redirectTo: "/authoring/processes/details",
                            },
                          }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <IconEdit2 />
                            <p className="h6 text-gray-300">Edit</p>
                          </div>
                        </Link>
                      )}
                      {/* {getVisibilityStatus(
                        ProcessesDetails.status,
                        "Unarchive"
                      ) && (
                          <Button
                            variant=""
                            className="border-0 p-0"
                          // onClick={() => setArchiveModal(true)}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <IconArchive />
                              <p className="h6 text-gray-300">Un-Archive</p>
                            </div>
                          </Button>
                        )} */}
                      {getVisibilityStatus(
                        ProcessesDetails.status,
                        "Archive"
                      ) && (
                        <Button
                          variant=""
                          className="border-0 p-0 m-0"
                          onClick={() => setArchiveModal(true)}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <IconArchive />
                            <p className="h6 text-gray-300">Archive</p>
                          </div>
                        </Button>
                      )}
                      {/* <Link
                        to={{
                          // pathname: `/authoring/process/feedback`,
                          state: {
                            propsData: ProcessesDetails,
                            opertionType: "Edit",
                            redirectTo: "/authoring/process/feedback",
                            authoringName: "Processes"
                          },
                        }}
                      >
                       
                       <Button variant="" className="border-0 p-0"
                          onClick={(()=> setOpenFeedbackModal(true))}>
                            <div className="d-flex gap-2 align-items-center">
                              <FeedbackIconGrey />
                              <p className="h6 text-gray-300">Feedback</p>
                            </div>
                          </Button>
                  
                      </Link> */}
                      <div className="pipeSeparator">&nbsp;</div>
                      {/* <Link
                        to={{
                          pathname: `/authoring/preview`,
                          state: {
                            propsData: processesPreview,
                            opertionType: "preview",
                            redirectTo: "/authoring/preview",
                          },
                        }}
                      >
                      <Button variant="white">
                        <div className="d-flex gap-2 align-items-center">
                          <IconEyeColored />
                          <span>Preview</span>
                        </div>
                      </Button></Link> */}
                      {getVisibilityStatus(
                        ProcessesDetails.status,
                        "Assign"
                      ) && (
                        <Button
                          className=""
                          variant="secondary"
                          onClick={() => setModalAssignProcess(true)}
                          // disabled={ProcessesDetails.isRestricted === true}
                        >
                          Assign
                        </Button>
                      )}
                      <ActivityLog />
                    </div>
                  </Col>
                </Row>
              </Container>
            </header>
            <div className="pageOuter">
              <Container fluid>
                <div className="page detailModulePage d-flex flex-column flex-grow-1">
                  <div className="p-32 pb-4 borderBottom w-100">
                    <div className="d-flex align-items-center mb-32">
                      <p className="h6 ">
                        <span className="text-gray-300">Created on</span>{" "}
                        {getFormatDate(ProcessesDetails.createdDate)}{" "}
                        {/* <DateTimeConverter utcDateTime={ProcessesDetails.createdDate} /> */}
                        {ProcessesDetails.modifiedBy &&
                          ProcessesDetails.modifiedBy !== null && (
                            <>
                              <span className="text-gray-300">
                                <span className="text-gray-400">|</span> Edited
                                on
                              </span>{" "}
                              {getFormatDate(
                                ProcessesDetails.modifiedDate
                                  ? ProcessesDetails.modifiedDate
                                  : ProcessesDetails.createdDate
                              )}{" "}
                              {/* <DateTimeConverter utcDateTime={ProcessesDetails.modifiedDate ? ProcessesDetails.modifiedDate : ProcessesDetails.createdDate} /> */}
                            </>
                          )}
                        <span className="text-gray-300">
                          <span className="text-gray-400">|</span> Created by
                        </span>{" "}
                        <span className="text-primary-100">
                          {ProcessesDetails?.createdUserDetail?.name}
                        </span>
                      </p>

                      <div className="ms-2">
                        <p className="text-md mb-0 d-flex align-items-center gap-2">
                          <span
                            className={`tableTag ${
                              Status[ProcessesDetails.status].color
                            } text-md  `}
                          >
                            {" "}
                            <IconTick />
                            {ProcessesDetails.status}
                          </span>
                          {/* {ProcessesDetails.status} */}
                        </p>
                      </div>
                    </div>

                    <div></div>

                    <div className="d-flex feedbackData">
                      <div className="dataItem">
                        <p className="h6 mb-3 text-gray-300">Total Sequences</p>

                        <h3 className="font-medium">
                          {ProcessesDetails.totalSequence}
                        </h3>
                      </div>

                      <div className="dataItem">
                        <p className="h6 mb-3 text-gray-300">Total Modules</p>

                        <h3 className="font-medium">
                          {ProcessesDetails.totalModule}
                        </h3>
                      </div>

                      {/* <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Feedback Rating</p>
                    
                      <Link
                      to={{
                        // pathname: `/authoring/module/feedback`,
                        state: {
                          propsData: ProcessesDetails,
                          opertionType: "Edit",
                          redirectTo: "/authoring/process/feedback",
                          authoringName: "Processes",
                        },
                      }}
                    >
                      <div className="">
                      
                        <Button variant="" className="d-flex border-0 align-items-center p-0"
                          onClick={(()=> setOpenFeedbackModal(true))}>
                         <IconStar />
                        <h3 className="ps-1 font-medium">
                          {ProcessesDetails.avgRating.toFixed(1)}
                        </h3>
                        </Button>
                        <Button variant="" className="d-flex border-0 align-items-center p-0 btn-sm"
                          onClick={(()=> setOpenFeedbackModal(true))}>
                          <p className="text-primary-200 text-md mt-2 mb-0">
                            View all ratings
                          </p>
                        </Button>
                      </div>
                    </Link>
                      </div> */}

                      <div className="dataItem">
                        <p className="h6 mb-3 text-gray-300">Audience</p>
                        <button onClick={() => setOpenAudienceModal(true)}>
                          <h3 className="font-medium">
                            {ProcessesDetails.audience}
                          </h3>
                        </button>
                      </div>

                      <div className="dataItem">
                        <p className="h6 mb-3 text-gray-300">Process Owner</p>
                        <h3 className="font-medium">
                          {ProcessesDetails.processOwnerCount}
                        </h3>
                        {/* <div className="d-flex">
                        {console.log(ProcessesDetails)}
                          {ProcessesDetails.processOwner !== undefined &&
                            ProcessesDetails.processOwner !== null &&
                            ProcessesDetails.processOwner.length > 0 &&
                            ProcessesDetails.processOwner.map((c, index) => (
                              <>
                                <div key={index} className="headImg moduleImg">
                                  <img
                                    key={"img" + index}
                                    src={OwnerImg}
                                    alt=""
                                  />
                                </div>
                              </>
                            ))}
                        </div> */}
                        {/* <div className="d-flex">
                        <div className="headImg moduleImg">
                          <img src={OwnerImg} alt="" />
                        </div>
                        <div className="headImg moduleImg">
                          <img src={OwnerImg} alt="" />
                        </div>
                        <div className="headImg moduleImg">
                          <img src={OwnerImg} alt="" />
                        </div>
                        <div className="headImg moduleImg imgCount">
                          <div className="text-xs total">+3</div>
                        </div>
                      </div> */}
                      </div>
                    </div>
                  </div>

                  <div className="p-32 bg-gray-600 flex-grow-1 overflow-auto">
                    <h5 className="mb-3">Content</h5>

                    <div className="d-flex align-items-center mb-20">
                      <div className="input-group myInput myInputSearch me-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Search by content title"
                          onChange={(e) => searchData(e)}
                        />
                        <IconTopSearch />
                      </div>
                      <p className="h6 text-gray-200">
                        <CheckBoxMd
                          text="All"
                          onChange={onCheck}
                          colName="All"
                          value={selctedChekd === "All"}
                        />
                      </p>
                      <p className="h6 text-gray-200 px-3">
                        <CheckBoxMd
                          text="Sequences"
                          onChange={onCheck}
                          colName="Sequences"
                          value={selctedChekd === "Sequences"}
                        />
                      </p>
                      <p className="h6 text-gray-200">
                        <CheckBoxMd
                          text="Independent Modules"
                          onChange={onCheck}
                          colName="IM"
                          value={selctedChekd === "IM"}
                        />
                      </p>
                    </div>
                    {listData !== undefined && listData.length <= 0 ? (
                      <EmptyState message="No content found" />
                    ) : (
                      <div className="row">
                        <div className="col-lg-9">
                          <Accordion defaultActiveKey="0">
                            {contentList !== undefined &&
                              contentList.length > 0 &&
                              contentList.map((c, index) => (
                                <Accordion.Item
                                  eventKey={index}
                                  className="mb-12 cardContent seqModule"
                                >
                                  <Accordion.Header>
                                    <div className="d-flex justify-content-between align-items-center p-12 pe-3 w-100">
                                      <div className="d-flex gap-3 align-items-start">
                                        <div className="count">
                                          <span className="text-xs">
                                            {/* {c.index} */}
                                            {index + 1}
                                          </span>
                                        </div>

                                        <div className="moduleTitle">
                                          <p className="text-primary-200 text-md mb-0">
                                            {c.type}
                                          </p>
                                          <p className="font-medium mb-0 text-gray-100">
                                            {/* TODO-16-4-Title */}
                                            {c.title}
                                            {/* {c?.status === "Expired" && (
                                              <IconExclamation />
                                            )} */}
                                          </p>
                                          <p className="font-medium mb-0 text-gray-100">
                                            {c.time}
                                            {/* <span className="d-inline-block ms-2">
                                            <IconExclamation />
                                          </span>{" "} */}
                                          </p>
                                        </div>
                                      </div>
                                      {c?.type === "Module" && (
                                        <Link
                                          // to={{
                                          //   pathname: `/authoring/preview`,
                                          //   state: {
                                          //     propsData: processesPreview,
                                          //     opertionType: "preview",
                                          //     redirectTo: `/authoring/preview`,
                                          //     headerFirstTitle: ProcessesDetails.title,
                                          //     headerSecondTitle: c.title,
                                          //   },
                                          // }}
                                          to={`/authoring/preview/${c.childId}/process/${c.type}/${c.title}`}
                                          target="_blank"
                                        >
                                          <Button
                                            variant="white"
                                            size="sm"
                                            onClick={() => {
                                              getPreviewData(c.childId, c);
                                            }}
                                          >
                                            <div className="d-flex align-items-center">
                                              <IconEyeColored />
                                              <span className="ps-10">
                                                Preview
                                              </span>
                                            </div>
                                          </Button>
                                        </Link>
                                      )}
                                    </div>
                                  </Accordion.Header>
                                  {c.type === "Sequence" && (
                                    <Accordion.Body>
                                      <Accordion defaultActiveKey="0">
                                        {c.content !== undefined &&
                                          c.content !== null &&
                                          c.content.length > 0 &&
                                          c.content.map((cc, index) => (
                                            <Accordion.Item
                                              eventKey={index}
                                              className="mb-12 cardContent seqModule"
                                            >
                                              <Accordion.Header>
                                                <div className="d-flex justify-content-between align-items-center p-12 w-100">
                                                  <div
                                                    className="d-flex gap-3 align-items-center justify-content-start"
                                                    style={{ width: "40%" }}
                                                  >
                                                    <div className="count text-xs text-gray-200 borderPurple">
                                                      {index + 1}
                                                    </div>
                                                    <div>
                                                      <p className="text-primary-200 text-md mb-0">
                                                        {cc.type}
                                                      </p>
                                                      <p className="font-medium mb-0 text-gray-100">
                                                        {cc.title}
                                                      </p>
                                                    </div>
                                                  </div>

                                                  <div>
                                                    <p className="text-md text-gray-300 mb-0">
                                                      <span
                                                        className={`text-${
                                                          cc.status ===
                                                            "Published" ||
                                                          cc.status === "Active"
                                                            ? "secondary-300"
                                                            : "secondary-600"
                                                        } text-md me-3 d-inline-block`}
                                                      >
                                                        {cc.status}
                                                        {/* {cc?.status === "Expired" && (
                                            <IconExclamation />
                                          )} */}
                                                      </span>
                                                    </p>
                                                  </div>
                                                  {cc.type === "Module" && (
                                                    <div>
                                                      <Link
                                                        // to={{
                                                        //   pathname: `/authoring/preview`,
                                                        //   state: {
                                                        //     propsData: processesPreview,
                                                        //     opertionType: "preview",
                                                        //     redirectTo: `/authoring/preview`,
                                                        //     headerFirstTitle: ProcessesDetails.title,
                                                        //     headerSecondTitle: c.title,
                                                        //   },
                                                        // }}
                                                        to={`/authoring/preview/${cc.id}/process/${cc.type}/${cc.title}`}
                                                        target="_blank"
                                                      >
                                                        <Button
                                                          variant="white"
                                                          size="sm"
                                                          onClick={() => {
                                                            getPreviewData(
                                                              cc.id,
                                                              cc
                                                            );
                                                          }}
                                                        >
                                                          <div className="d-flex align-items-center">
                                                            <IconEyeColored />
                                                            <span className="ps-10">
                                                              Preview
                                                            </span>
                                                          </div>
                                                        </Button>
                                                      </Link>
                                                    </div>
                                                  )}
                                                </div>
                                              </Accordion.Header>
                                              <Accordion.Body>
                                                {cc.pages !== undefined &&
                                                  cc.pages !== null &&
                                                  cc.pages.length > 0 &&
                                                  cc.pages.map((ccc, index) => (
                                                    <div className="d-flex justify-content-between align-items-center cardBody mb-20">
                                                      <div
                                                        className="d-flex gap-3 align-items-center justify-content-start"
                                                        style={{ width: "40%" }}
                                                      >
                                                        <div className="count text-xs text-gray-200 borderPurple">
                                                          {index + 1}
                                                        </div>
                                                        <div>
                                                          <p className="text-primary-200 text-md mb-0">
                                                            Pages
                                                          </p>
                                                          <p className="font-medium mb-0 text-gray-100">
                                                            {ccc.title}
                                                          </p>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  ))}
                                                {cc.forms !== undefined &&
                                                  cc.forms !== null &&
                                                  cc.forms.length > 0 &&
                                                  cc.forms.map((ccc, index) => (
                                                    <div className="d-flex justify-content-between align-items-center cardBody mb-20">
                                                      <div
                                                        className="d-flex gap-3 align-items-center justify-content-start"
                                                        style={{ width: "40%" }}
                                                      >
                                                        <div className="count text-xs text-gray-200 borderPurple">
                                                          {index + 1}
                                                        </div>
                                                        <div>
                                                          <p className="text-primary-200 text-md mb-0">
                                                            Forms
                                                          </p>
                                                          <p className="font-medium mb-0 text-gray-100">
                                                            {ccc.title}
                                                          </p>
                                                        </div>
                                                      </div>
                                                      <div>
                                                        <p className="text-md text-gray-300 mb-0">
                                                          <span
                                                            className={`text-${
                                                              ccc.status === 0 ||
                                                              ccc.status === "Active"
                                                                ? "secondary-300"
                                                                : "secondary-600"
                                                            } text-md me-3 d-inline-block`}
                                                          >
                                                            {ccc.status === 0 ? "Published" : ""}
                                                            {/* {cc?.status === "Expired" && (
                                                            <IconExclamation />
                                                          )} */}
                                                          </span>
                                                        </p>
                                                      </div>
                                                    </div>
                                                    
                                                  ))}
                                              </Accordion.Body>
                                            </Accordion.Item>
                                          ))}
                                      </Accordion>
                                    </Accordion.Body>
                                  )}
                                  {c.type === "Module" && (
                                    <Accordion.Body>
                                      {c.content !== undefined &&
                                        c.content !== null &&
                                        c.content.length > 0 &&
                                        c.content.map((cc, index) => (
                                          <div className="d-flex justify-content-between align-items-center cardBody mb-20">
                                            <div
                                              className="d-flex gap-3 align-items-center justify-content-start"
                                              style={{ width: "40%" }}
                                            >
                                              <div className="count text-xs text-gray-200 borderPurple">
                                                {index + 1}
                                              </div>
                                              <div>
                                                <p className="text-primary-200 text-md mb-0">
                                                  {cc.type}
                                                </p>
                                                <p className="font-medium mb-0 text-gray-100">
                                                  {cc.title}
                                                </p>
                                              </div>
                                            </div>

                                            <div>
                                              <p className="text-md text-gray-300 mb-0">
                                                <span
                                                  className={`text-${
                                                    cc.status === "Published" ||
                                                    cc.status === "Active"
                                                      ? "secondary-300"
                                                      : "secondary-600"
                                                  } text-md me-3 d-inline-block`}
                                                >
                                                  {cc.status}
                                                  {/* {cc?.status === "Expired" && (
                                                  <IconExclamation />
                                                )} */}
                                                </span>
                                              </p>
                                            </div>
                                            
                                          </div>
                                        ))}
                                    </Accordion.Body>
                                  )}
                                </Accordion.Item>
                              ))}
                            {contentList && contentList?.length <= 0 && (
                              <EmptyState message="No content found" />
                            )}
                            {/* <Accordion.Item
                            eventKey="1"
                            className="mb-4 cardContent"
                          >
                            <Accordion.Header>
                              <div className="d-flex justify-content-between align-items-center p-12 pe-3 w-100">
                                <div className="d-flex gap-3 align-items-start">
                                  <div className="count">
                                    <span className="text-xs noBgBorderPurple">
                                      2
                                    </span>
                                  </div>

                                  <div>
                                    <p className="text-primary-200 text-md mb-0">
                                      Sequence
                                    </p>
                                    <p className="font-medium mb-0 text-gray-100">
                                      Team roles &amp; responsibilities{" "}
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <p className="text-md text-gray-300 mb-0">
                                    40 mins
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex justify-content-between align-items-center cardBody mb-20">
                                <div className="d-flex gap-3 align-items-center justify-content-start">
                                  <div className="count text-xs text-gray-200 borderPurple">
                                    1
                                  </div>
                                  <div>
                                    <p className="text-primary-200 text-md mb-0">
                                      Module
                                    </p>
                                    <p className="font-medium mb-0 text-gray-100">
                                      Jira Tutorial
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  {" "}
                                  <p className="text-md text-gray-300 mb-0">
                                    20 mins
                                  </p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between align-items-center cardBody">
                                <div className="d-flex gap-3 align-items-center justify-content-start">
                                  <div className="count text-xs text-gray-200">
                                    2
                                  </div>
                                  <div>
                                    <p className="text-primary-200 text-md mb-0">
                                      Module
                                    </p>
                                    <p className="font-medium mb-0 text-gray-100">
                                      Jira Tutorial
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  {" "}
                                  <p className="text-md text-gray-300 mb-0">
                                    20 mins
                                  </p>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item
                            eventKey="2"
                            className="mb-4 cardContent"
                          >
                            <Accordion.Header>
                              <div className="d-flex justify-content-between align-items-center p-12 pe-3 w-100">
                                <div className="d-flex gap-3 align-items-start">
                                  <div className="count">
                                    <span className="text-xs noBgBorderGray">
                                      3
                                    </span>
                                  </div>

                                  <div>
                                    <p className="text-primary-200 text-md mb-0">
                                      Module
                                    </p>
                                    <p className="font-medium mb-0 text-gray-100">
                                      Jira Tutorial
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  <p className="text-md text-gray-300 mb-0">
                                    40 mins
                                  </p>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="d-flex justify-content-between align-items-center cardBody mb-20">
                                <div className="d-flex gap-3 align-items-center justify-content-start">
                                  <div className="count text-xs text-gray-200 borderPurple">
                                    1
                                  </div>
                                  <div>
                                    <p className="text-primary-200 text-md mb-0">
                                      Module
                                    </p>
                                    <p className="font-medium mb-0 text-gray-100">
                                      Jira Tutorial
                                    </p>
                                  </div>
                                </div>

                                <div>
                                  {" "}
                                  <p className="text-md text-gray-300 mb-0">
                                    20 mins
                                  </p>
                                </div>
                              </div>

                              <div className="d-flex justify-content-between align-items-center cardBody">
                                <div className="d-flex gap-3 align-items-center justify-content-start">
                                  <div className="count text-xs text-gray-200">
                                    2
                                  </div>
                                  <div>
                                    <p className="text-primary-200 text-md mb-0">
                                      Module
                                    </p>
                                    <p className="font-medium mb-0 text-gray-100">
                                      Jira Tutorial
                                    </p>
                                  </div>
                                </div>
                                <div>
                                  <p className="text-md text-gray-300 mb-0">
                                    20 mins
                                  </p>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item> */}
                          </Accordion>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </Container>
            </div>
          </div>
        )}

      <Modal className="modalDialog" size="sm" centered show={duplicateModal}>
        <ModalDelete
          onCancel={setDuplicateModal}
          onConfirm={onConfirmDuplicate}
          headerLable="Are you sure you want to duplicate this Process?"
          chldLable="All the content added in the process will be duplicated."
        />
      </Modal>

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        // onHide={() => setModalAssignProcess(false)}
        show={modalAssignProcess}
      >
        <EmployeeAssignementPopup
          type={AssignmentActionType.PROCESS}
          title={"Process"}
          contentIds={[processId]}
          onClose={setModalAssignProcess}
          getData={setData}
          setAssignModal={setModalAssignProcess}
          moduletitle={ProcessesDetails}
        />
      </Modal>

      <> {formDataReady === false && <Loader />}</>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={modalExpiredProcess}
        onHide={() => setModalExpiredProcess(false)}
      >
        <ProcessExpiredModal openModalExpiredProcess={setModalExpiredProcess} />
      </Modal>
      <Modal
        className="come-from-modal right"
        centered
        show={modalFeedbackProcess}
        onHide={() => setModalFeedbackProcess(false)}
      >
        <ProcessFeedbackModal
          openModalFeedbackProcess={setModalFeedbackProcess}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={modalExpiredProcess}
        onHide={() => setModalExpiredProcess(false)}
      >
        <ProcessExpiredModal openModalExpiredProcess={setModalExpiredProcess} />
      </Modal>
      <Modal
        className="come-from-modal right"
        centered
        show={modalFeedbackProcess}
        onHide={() => setModalFeedbackProcess(false)}
      >
        <ProcessFeedbackModal
          openModalFeedbackProcess={setModalFeedbackProcess}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal || archiveModal}
      >
        {deleteModal ? (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onConfirm}
            headerLable="Are you sure you want to delete this process?"
            chldLable="Be aware that the modules added to this sequence will be removed."
          />
        ) : (
          <ModalDelete
            onCancel={setArchiveModal}
            onConfirm={onConfirm}
            requestType="Archive"
            headerLable="Are you sure you want to archive this process?"
            chldLable="The process will be moved to your archive."
          />
        )}
      </Modal>

      <Modal
        className="come-from-modal right"
        centered
        show={openAudienceModal}
        // onHide={() => setOpenAudienceModal(false)}
      >
        <AudienceModal
          openAudienceModal={setOpenAudienceModal}
          data={audienceList}
          title={"Process"}
          moduletitle={ProcessesDetails}
          onClose={handleCloseAssignModal}
        />
      </Modal>

      <Modal
        className="come-from-modal right"
        centered
        show={openFeedbackModal}
        // onHide={() => setOpenFeedbackModal(false)}
      >
        <FeedbackView id={state.d} onClose={handleCloseAssignModal} />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={showAlertMessagePopup}
      >
        <ModalMessageAlertPopup
          onCancel={setShowAlertMessagePopup}
          requestType="Archive"
          headerLable="You can not archive the content as this content is yet to be completed by the users"
          chldLable="You can only archive this once it is completed by all the users to whom this is assigned."
        />
      </Modal>
    </>
  );
};

export default ProcessesDetail;
