import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { IconExit, IconSkip, IconBreadcrumArrow, IconUploadFile } from "../../../icons/Icons";

const Form = () => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    return (
        <div className='pages'>
            <header className='pageHeader mb-0'>
                <Container fluid>
                    <Row className='align-items-center'>
                        <Col className='col-lg-4'>
                            <div className='d-flex align-items-center gap-2'>
                                <Link to="/authoring">
                                    <span className='arrow left'></span>
                                </Link>

                                <p className='h6 text-gray-200'>Our Mission &amp; Vision</p>

                                <IconBreadcrumArrow />
                                <div>
                                    <p className='h6 text-primary-200'> Understanding the structure</p>
                                </div>
                            </div>
                        </Col>
                        <Col className='text-end col-lg-8'>
                            <div className="d-flex gap-2 align-items-center justify-content-end">

                                <Button variant="" className='border-0 p-0'>

                                    <div className='d-flex gap-2 align-items-center'><IconExit /><p className='h6 text-gray-300'>Exit</p></div>
                                </Button>

                                <Button variant="" className='border-0 p-0'>

                                    <div className='d-flex gap-2 align-items-center'><IconSkip /><p className='h6 text-gray-300'>Skip</p></div>
                                </Button>

                                <div className='pipeSeparator pe-4'>&nbsp;</div>
                                <Button variant="white">

                                    <div className='d-flex gap-2 align-items-center'>

                                        <span>Previous</span>
                                    </div>
                                </Button>
                                <Button variant="secondary">
                                    Next
                                </Button>



                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>

            <div className='position-relative' style={{ top: '-1px' }}>
                <div className="progress tableProgress bg-base-100 " style={{ height: '2px' }} ref={target} onMouseEnter={() => setShow(!show)}>
                    <div className="progress-bar bg-primary-200" role="progressbar" style={{ width: '68%' }}></div>
                </div>
            </div>



            <div className='pageOuter pb-0'>

                <Row className='g-0 h-100'>
                    <Col className='col-lg-9 bg-base-100'>
                        <div className="row">
                            <div className="col-lg-9">
                                <div className='videoPreview'>
                                    <h5>1. Please upload your vaccination certificate.</h5>
                                    <div className='bg-gray-600 my-4 d-flex justify-content-center align-item-center p-52 text-center rounded-6 borderAllDashed'>
                                        <div>
                                            <div className='mb-12'>
                                                <IconUploadFile />
                                            </div>
                                            <p className='h6'><span className='font-bold text-primary-200'>Choose file</span> or drag here</p>
                                        </div>
                                    </div>
                                    <Button variant="primary" className='border-0'>
                                        Submit
                                    </Button>
                                </div>
                            </div>
                        </div>

                    </Col>

                    <Col className='col-lg-3'>

                        <div className='bg-gray-600 p-32 px-4 h-100 borderLeft2 '>
                            <div>
                                <h3 className='mb-12'>COVID Vaccination</h3>
                                <p className='text-gray-200 mb-40 h6'>Mondatory for all employees</p>
                            </div>
                        </div>
                    </Col>
                </Row>

            </div>
        </div>
    );
}

export default Form;
