import { http } from "../httpService";

export function getAllSequence(reqBody) {
  return http.post(`/Sequence/All`, reqBody);
}

export function createSequence(reqBody) {
  return http.post(`/Sequence/Create`, reqBody);
}

export function createSequenceSaveAsDraft(reqBody) {
  return http.post(`/Sequence/SaveAsDraft`, reqBody);
}

export function updateSequence(reqBody) {
  return http.post(`/Sequence/Update`, reqBody);
}
export function deleteSequence(request) {
  return http.delete(
    `/Sequence/DELETE?id=${request.id}&status=${request.status}`
  );
}

export function bulkDeleteSequence(request) {
  return http.post(`/Sequence/BulkDelete`, request);
}

export function bulkArchiveSequence(request) {
  return http.post(`/Sequence/BulkArchive`, request);
}

export function getSequenceDetails(id) {
  return http.post(`/Sequence/Get?id=${id}`);
}

export function getDropDownList() {
  return http.get(`/Module/List`);
}

export function getModuleDropdownList() {
  return http.get(`/Module/ModuleList`);
}

export function getSequencePreview(id) {
  return http.post(`/Sequence/Preview/${id}`);
}

export function getSequenceFeedbackDetails(id) {
  return http.get(`/EmployeeFeedbackRating/SequenceFeedback/${id}`);
}

export function getSequenceTemplates(reqBody) {
  return http.post(`/Sequence/Templates`, reqBody);
}
