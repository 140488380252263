import React, { useState, useRef, useEffect } from "react";
import { IconCalendar, CrossIconXl } from "../../icons/Icons";
import CheckBoxMd from "../../components/CheckBox/CheckBoxMd";
import "react-phone-number-input/style.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import TagsInput from "../../components/TagsInput/TagsInput";
import { Formik, Form, Field } from "formik";
import TextArea from "../../components/FormikInputs/TaxtArea";
import TextInput from "../../components/FormikInputs/TextInput";
import { useLocation, useHistory } from "react-router-dom";
import Loader from "../../components/Loader/Loader";
import PhoneInput from "react-phone-number-input";
import { Modal, Button } from "react-bootstrap";
import {
  addUserAsync,
  getRolesAsync,
  getTimezoneAsync,
  getTeamsAsync,
  addAdminAsync,
  checkUniquenessAsync,
  checkUniquenessCompanyEmailAsync,
  checkUniquenessPersonalEmailAsync,
  checkUniquenessPhoneNumberAsync,
  updateUserAsync,
  validateHelpdeskNumberAsync,
  getUserUsageAsync,
  getUsersAsync,
} from "../../reducers/user-reducers";
import { useDispatch, useSelector } from "react-redux";
import { getUserDetails } from "../../api/users-store-api";
import ToasterAlertSucces from "../../helper/ToasterAlertSucces";
import ToasterAlertError from "../../helper/ToasterAlertError";
import { ErrrorMessage } from "../../helper/ErrorMessage";
import validator from "validator";
import CheckBox from "../../components/CheckBox/CheckBox";
import { IconExclamationLg } from "../../icons/Icons";

import RestrictedPlan from "../AccountSetting/Billing/RestrictedPlan";
import {
  getSubscriptionAsync,
  getPlansAsync,
  getStorageAsync,
} from "../../reducers/subscription-reducers";
import { useDebounce } from "../../utils/hooks/useDebounce";

const AddUserEmployeeModal = ({
  handleModal,
  action,
  user,
  getUsers,
  getUserList,
  onClose,
  reloadUser,
}) => {
  const dispatch = useDispatch();

  const [isRestricted, setIsRestricted] = useState(false);
  const [userUsage, setUserUsage] = useState({});
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData, storageData } = useSelector((state) => state.subscription);

  const [optionsTeam, setOptionsTeam] = useState([]);
  const [optionsTimezone, setOptionsTimezone] = useState([]);
  const [optionsRole, setOptionsRole] = useState([]);
  const [optionsUser, setOptionsUser] = useState([]);

  const [selectedRole, setSelectedRole] = useState(
    optionsRole[0]?.label ? optionsRole[0]?.label : ""
  );
  const [selectedRoleId, setSelectedRoleId] = useState(
    optionsRole[0] ? optionsRole[0] : ""
  );
  const [selectedTeam, setSelectedTeam] = useState(
    optionsTeam[0] ? optionsTeam[0] : ""
  );
  const [selectedTimeZone, setSelectedTimeZone] = useState(
    optionsTimezone[0] ? optionsTimezone[0] : ""
  );
  const [selectedUserList, setSelectedUserList] = useState(
    optionsUser[0] ? optionsUser[0] : ""
  );
  // console.log(selectedUserList, "selectedUserList")
  const defaultData = {
    id: "",
    userInformationId: "",
    role: selectedRole?.value ? selectedRole?.value : "",
    roleId: selectedRole?.value ? selectedRole?.value : "",
    profilePicture: "",
    name: "",
    title: "",
    contactNo: "",
    phoneNumber: "",
    personalEmail: "",
    tags: "",
    status: 0,
    companyEmail: "",
    teamId: selectedTeam?.value ? selectedTeam?.value : "",
    timeZoneId: selectedTimeZone?.value ? selectedTimeZone?.value : "",
    reportsTo: selectedUserList?.value ? selectedUserList?.value : null,
    password: "",
    joiningDate: null,
    changePasswordFlag: true,
    inviteDate: null,
    isHelpdeskNumber: false,
  };

  const [userData, setUserData] = useState(user ? user : defaultData);
  const navigate = useHistory();
  const [formDataReady, setFormDataReady] = useState(true);
  const location = useLocation();
  const [opertionType, setooo] = useState("");
  const formRef = useRef();
  const [isUniqueTitle, setIsUniqueTitle] = useState(true);
  const [isUniqueCompanyEmail, setIsUniqueCompanyEmail] = useState(true);
  const [isUniquePersonalEmail, setIsUniquePersonalEmail] = useState(true);
  const [isUniquePhoneNumber, setIsUniquePhoneNumber] = useState(true);
  const [titleFocused, setTitleFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const rolesList = useSelector((state) => state.user.roles);
  const [helpdeskNumber, setHelpdeskNumber] = useState([]);
  const teamsList = useSelector((state) => state.user.teams);
  const timezoneList = useSelector((state) => state.user.timezone);
  const data = useSelector((state) => state.user.users);
  const [pageSize, setPageSize] = useState(100000);
  const [showHelpDeskError, setShowHelpDeskError] = useState(false);
  const [isVisible, setIsVisible] = useState(true);
  
  useEffect(() => {
    // console.log(action,'action');
    if (action !== "Edit") {
      getUserUsage();
      dispatch(getSubscriptionAsync());
      dispatch(getStorageAsync());
      dispatch(getPlansAsync());
    }
  }, [action]);

  useEffect(() => {
    // console.log(userUsage,'userUsage');
    // console.log(subscriptionData,'subscriptionData');
    // console.log(storageData,'storageData');
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (action !== "Edit") {
      if (hasSubscriptions && hasItems) {
        let planUsers = 0;
        if (storageData?.seatQuantity) {
          console.log(storageData, "storageData");
          const isActive =
            subscriptionData?.subscriptionData?.subscriptions[0]?.status ===
            "active";
          const isTrialing =
            subscriptionData?.subscriptionData?.subscriptions[0]?.status ===
            "trialing";
          setIsRestricted(
            (isTrialing || isActive) &&
              userUsage?.usersCount >= storageData?.seatQuantity
          );
        }
      } else {
        setIsRestricted(true);
      }
    }
  }, [subscriptionData, userUsage, storageData, action]);

  const getUserUsage = () => {
    dispatch(getUserUsageAsync()).then((res) => {
      if (res.payload) {
        setUserUsage(res.payload);
      }
    });
  };

  const isRequiredToValiate = () => {
    return !(selectedRole?.label == "Admin");
  };

  const isFieldVisible = () => {
    return !(selectedRole?.label == "Admin");
  };
  function hasNumbersOrSpecialChars(value) {
    const regex = /[!@#$%^&*(),.?":{}|<>0-9]/g;
    return regex.test(value);
  }
  const userValidation = (values) => {
    let errors = {};
    if (!values.role || values.role.length === 0) {
      errors.role =
        "Please select a role for the new user. This field is required.";
    }
    if (!values.name || values.name.trim() === "") {
      errors.name = "Employee name is required!";
    } else if (hasNumbersOrSpecialChars(values.name)) {
      errors.name =
        "Employee name should not contain numbers or special characters!";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Designation is required!";
    }
    // if (
    //   !values.personalEmail ||
    //   values.personalEmail.trim() === ""
    //   // && isRequiredToValiate()
    // ) {
    //   errors.personalEmail = "Personal email is required!";
    // } else 
    // if (
    //   !/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.personalEmail)
    //   // &&
    //   // isRequiredToValiate()
    // ) {
    //   errors.personalEmail = "Invalid email address";
    // } else if (values.personalEmail && !isUniquePersonalEmail) {
    //   errors.personalEmail = "Personal email is already exist!";
    // } else if (values.personalEmail === values.companyEmail) {
    //   errors.personalEmail =
    //     "The Company and Personal email addresses can't be the same. Please provide a different Personal email address.";
    // }

    if (!values.companyEmail || values.companyEmail.trim() === "") {
      errors.companyEmail = "Company email is required!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.companyEmail)) {
      errors.companyEmail = "Invalid email address";
    } else if (values.companyEmail && !isUniqueCompanyEmail) {
      errors.companyEmail = "Company email is already exist!";
    }

    if (!values.phoneNumber || values.phoneNumber.trim() === "") {
      errors.phoneNumber = "A valid phone number is required!";
    } else if (
      !validator.isMobilePhone(values.phoneNumber, "any", { strictMode: false })
    ) {
      errors.phoneNumber = "Invalid phone number.";
    } else if (
      values.phoneNumber.length < 7 ||
      values.phoneNumber.length > 15
    ) {
      errors.phoneNumber = "Phone number should be between 7 and 15 digits.";
    } else if (values.phoneNumber && !isUniquePhoneNumber) {
      errors.phoneNumber = "Phone number is already exist!";
    }

    // else if (
    //   !/^[0-9]{10}$/.test(values.phoneNumber) &&
    //   isRequiredToValiate()
    // ) {
    //   errors.phoneNumber = "Invalid phone number";
    // }

    if (
      !values.teamId ||
      values.teamId?.trim() === ""
      // &&
      // isRequiredToValiate()
    ) {
      errors.teamId =
        "Please assign the new user to a team. This field is mandatory.";
    }
    if (
      !values.timeZoneId ||
      values.timeZoneId.trim() === ""
      // &&
      // isRequiredToValiate()
    ) {
      errors.timeZoneIde = "Please set the timezone. This field is mandatory.";
    }

    if (selectedRole.label === "Employee") {
      if (!values.reportsTo || values.reportsTo.trim() === "") {
        errors.timeZoneIde =
          "Please assign to reporting manager. This field is mandatory.";
      }
    }

    if (
      !values.tags ||
      values.tags.trim() === ""
      // && isRequiredToValiate()
    ) {
      errors.tags = "Tags are required for user categorization.";
    }

    // if ((!values.userName || values.userName.trim() === "") && isRequiredToValiate()) {
    //   errors.userName = "Username is required!";
    // }
    //  else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.userName)) {
    //   errors.userName = "Invalid username";
    // }
    // else if (values.userName && !isUniqueTitle) {
    //     errors.userName = "Username is already exist!";
    //   }

    if (!values.password) {
      errors.password = "Please enter a password to proceed.";
    } else if (values.password.length < 8) {
      errors.password =
        "A password is required and it must be at least 8 characters long.";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/.test(
        values.password
      )
    ) {
      errors.password =
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character";
    }

    if (values.inviteDate === "" || values.inviteDate === null) {
      errors.inviteDate =
        "Please specify when to send the invitation to the user.";
    }
    // else if (!values.inviteDate || values.inviteDate < new Date()) {
    //   errors.inviteDate = "Invite date should be in future!";
    // }

    if (values.joiningDate === "" || values.joiningDate === null) {
      errors.joiningDate = "User's joining date is required.";
    }
    // else
    // if (!values.joiningDate || values.joiningDate < new Date()) {
    //     errors.joiningDate = "Joining date should be in future!";
    // }
    // console.log(errors,'errors');
    // console.log(values.joiningDate,'values.joiningDate');
    return errors;
  };
  const onUserSubmit = (action) => {
    console.log(action, 'actionaction');
    const currentFormData = formRef?.current;
    // if (currentFormData.values.status == undefined) {
    //     navigate.push("/users");
    // }
    var errors = userValidation(currentFormData.values);
    if (
      Object.keys(currentFormData.errors).length === 0 &&
      Object.keys(errors).length === 0
    ) {
      if (userData["id"] && (action === undefined || action === "edit")) {
        const reqBody = {
          id: userData?.id,
          roleId: selectedRole.value,
          roleName: selectedRole.label,
          title: currentFormData?.values?.title,
          profilePicture: currentFormData?.values?.profilePicture ?? "",
          name: currentFormData?.values?.name,
          contactNo: currentFormData?.values?.phoneNumber,
          phoneNumber: currentFormData?.values?.phoneNumber,
          personalEmail: currentFormData?.values?.personalEmail,
          tags: currentFormData?.values?.tags,
          status: currentFormData?.values?.status,
          companyEmail: currentFormData?.values?.companyEmail,
          teamId: currentFormData?.values?.teamId,
          timeZoneId: currentFormData?.values?.timeZoneId,
          reportsTo:
            currentFormData?.values?.reportsTo === null
              ? null
              : currentFormData?.values?.reportsTo,
          // userName: currentFormData?.values?.userName,
          password: currentFormData?.values?.password,
          joiningDate: currentFormData?.values?.joiningDate,
          changePasswordFlag: currentFormData?.values?.changePasswordFlag,
          isHelpdeskNumber: currentFormData?.values?.isHelpdeskNumber,
          inviteDate: currentFormData?.values?.inviteDate,
          code: "",
          IdentityId: userData["identityId"],
        };
        setFormDataReady(false);
        try {
          const res = dispatch(updateUserAsync(reqBody));
          if (res) {
            setFormDataReady(true);
            getUsers && getUsers();
            ToasterAlertSucces(res, ErrrorMessage.UPDATE);
            getUserList();
            setSelectedRoleId(rolesList[0] ? rolesList[0] : {});
            setSelectedTeam(teamsList[0] ? teamsList[0] : {});
            setSelectedTimeZone(timezoneList[0] ? timezoneList[0] : {});
            setSelectedUserList(data.users[0] ? data.users[0] : {});
            formRef?.current?.resetForm();
            reloadUser && reloadUser(true);
            handleModal(action === "invite");
            navigate.push("/users", {reloadUsers: true});
          } else {
            ToasterAlertError(res);
          }
        } catch (error) {
          ToasterAlertError(
            "An unexpected error occurred. Please try again later."
          );
        }

        // dispatch(updateUserAsync(reqBody)).then((res) => {
        //   getUsers()
        //   ToasterAlertSucces(res, ErrrorMessage.UPDATE);
        //   setFormDataReady(true);
        //   getUserList()
        //   if (res?.error) {
        //     console.log(res);
        //   } else {
        //     setSelectedRoleId(rolesList[0] ? rolesList[0] : {});
        //     setSelectedTeam(teamsList[0] ? teamsList[0] : {});
        //     setSelectedTimeZone(timezoneList[0] ? timezoneList[0] : {});
        //     setSelectedUserList(data.users[0] ? data.users[0] : {})
        //     formRef?.current?.resetForm();
        //     handleModal(action == "invite");
        //     navigate.push("/users");
        //   }
        // });
      } else {
        const reqBody = {
          roleId: currentFormData?.values?.role,
          roleName: selectedRole.label,
          title: currentFormData?.values?.title,
          profilePicture: currentFormData?.values?.profilePicture ?? "",
          name: currentFormData?.values?.name,
          contactNo: currentFormData?.values?.contactNo,
          phoneNumber: currentFormData?.values?.phoneNumber,
          personalEmail: currentFormData?.values?.personalEmail,
          tags: currentFormData?.values?.tags,
          status: currentFormData?.values?.status,
          companyEmail: currentFormData?.values?.companyEmail,
          teamId: currentFormData?.values?.teamId,
          timeZoneId: currentFormData?.values?.timeZoneId,
          reportsTo:
            currentFormData?.values?.reportsTo === null
              ? null
              : currentFormData?.values?.reportsTo,
          // userName: currentFormData?.values?.userName,
          password: currentFormData?.values?.password,
          joiningDate: currentFormData?.values?.joiningDate,
          changePasswordFlag: currentFormData?.values?.changePasswordFlag,
          isHelpdeskNumber: currentFormData?.values?.isHelpdeskNumber,
          inviteDate: currentFormData?.values?.inviteDate,
          code: "",
        };
        setFormDataReady(false);
        if (selectedRole.label === "Admin") {
          // dispatch(addUserAsync(reqBody)).then((res) => {
          //   ToasterAlertSucces(res, ErrrorMessage.CREATE);
          //   getUsers()
          //   setFormDataReady(true);
          //   if (res?.error) {
          //     console.log(res);
          //   } else {
          //     setSelectedRoleId(rolesList[0] ? rolesList[0] : {});
          //     // setSelectedTeam(teamsList[0] ? teamsList[0] : {});
          //     // setSelectedTimeZone(timezoneList[0] ? timezoneList[0] : {});
          //     setSelectedTeam("");
          //     setSelectedTimeZone("");
          //     setSelectedUserList("");
          //     formRef?.current?.resetForm();
          //     handleModal(action == "invite");
          //     navigate.push("/users");
          //   }
          // });

          try {
            const res = dispatch(addUserAsync(reqBody));
            if (res) {
              ToasterAlertSucces(res, ErrrorMessage.CREATE);
              getUsers && getUsers();
              setFormDataReady(true);
              setSelectedRoleId(rolesList[0] ? rolesList[0] : {});
              // setSelectedTeam(teamsList[0] ? teamsList[0] : {});
              // setSelectedTimeZone(timezoneList[0] ? timezoneList[0] : {});
              setSelectedTeam("");
              setSelectedTimeZone("");
              setSelectedUserList("");
              formRef?.current?.resetForm();
              // reloadUser && reloadUser(true);
              handleModal(action === "invite");
              navigate.push("/users", {reloadUsers: true});
            } else {
              console.log(res);
              ToasterAlertError(res);
            }
          } catch (error) {
            ToasterAlertError(
              "An unexpected error occurred. Please try again later."
            );
          }
        } else {
          //  dispatch(addUserAsync(reqBody)).then((res) => {
          //     ToasterAlertSucces(res, ErrrorMessage.CREATE);
          //     getUsers()
          //     setFormDataReady(true);
          //     if (res?.error) {
          //       console.log(res);
          //     } else {
          //       setSelectedRoleId(rolesList[0] ? rolesList[0] : {});
          //       // setSelectedTeam(teamsList[0] ? teamsList[0] : {});
          //       // setSelectedTimeZone(timezoneList[0] ? timezoneList[0] : {});
          //       setSelectedTeam("");
          //       setSelectedTimeZone("");
          //       setSelectedUserList("");
          //       formRef?.current?.resetForm();
          //       handleModal(action == "invite");
          //       navigate.push("/users");
          //     }
          //   });

          try {
            const res = dispatch(addUserAsync(reqBody));
            if (res) {
              ToasterAlertSucces(res, ErrrorMessage.CREATE);
              getUsers && getUsers();
              setFormDataReady(true);
              setSelectedRoleId(rolesList[0] ? rolesList[0] : {});
              // setSelectedTeam(teamsList[0] ? teamsList[0] : {});
              // setSelectedTimeZone(timezoneList[0] ? timezoneList[0] : {});
              setSelectedTeam("");
              setSelectedTimeZone("");
              setSelectedUserList("");
              formRef?.current?.resetForm();
              // reloadUser && reloadUser(true);
              handleModal(action === "invite");
              navigate.push("/users", {reloadUsers: true});
            } else {
              console.log(res);
              ToasterAlertError(res);
            }
          } catch (error) {
            ToasterAlertError(
              "An unexpected error occurred. Please try again later."
            );
          }
        }
      }
    }
  };
  const onTagsUpdate = (
    updatedList,
    setFieldValue,
    setTouchedMethod,
    formTouchedData
  ) => {
    let stringdata = updatedList
      .map((m) => {
        return m.tag;
      })
      .toString();

    if (setTouchedMethod) {
      setTouchedMethod({ ...formTouchedData, ["tags"]: true });
    }
    setFieldValue("tags", stringdata);
  };
  const onSelectationChange = (e, colName, setMethod, localState) => {
    localState({
      value: e?.value,
      label: e?.label,
    });
    if (setMethod) {
      setMethod(colName, e.value);
    }
    if (colName === "teamId") {
      const reqBody = {
        id: userData?.id ? userData?.id : "",
        teamId: e?.value ? e?.value : "",
      };
      dispatch(validateHelpdeskNumberAsync(reqBody)).then((res) => {
        if (res.payload) {
          setHelpdeskNumber(res.payload);
          setIsVisible(true);
        }
      });
    }
  };
  const userId = user?.userInformationId;

  useEffect(() => {
    const roleReqBody = {
      name: "",
    };
    const teamsReqBody = {};
    const timezoneReqBody = {};
    const userReqBody = {
      pageSize: pageSize,
      filters: {},
    };

    if (rolesList.length === 0) {
      dispatch(getRolesAsync(roleReqBody)).then((res) => {
        if (!!res?.payload && res?.payload.totalRecords > 0) {
          const roles = res?.payload?.records.map((item) => {
            return { value: item.id, label: item.name };
          });
          setOptionsRole(roles);
        }
      });
    } else {
      if (!!rolesList && rolesList?.totalRecords > 0) {
        const roles = rolesList?.records.map((item) => {
          return { value: item.id, label: item.name };
        });
        setOptionsRole(roles);
      }
    }

    if (teamsList.length === 0) {
      dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload?.map((item) => {
            return { value: item.key, label: item.value };
          });
          setOptionsTeam(teams);
        }
      });
    } else {
      if (!!teamsList && teamsList?.length > 0) {
        const teams = teamsList?.map((item) => {
          return { value: item.key, label: item.value };
        });
        setOptionsTeam(teams);
      }
    }

    if (timezoneList.length === 0) {
      dispatch(getTimezoneAsync(timezoneReqBody)).then((res) => {
        if (!!res?.payload && res?.payload.length > 0) {
          const timeZone = res?.payload.map((item) => {
            return { value: item.key, label: item.value };
          });
          setOptionsTimezone(timeZone);
        }
      });
    } else {
      if (!!timezoneList && timezoneList?.length > 0) {
        const timeZone = timezoneList?.map((item) => {
          return { value: item.key, label: item.value };
        });
        setOptionsTimezone(timeZone);
      }
    }

    if (data.users?.length === 0) {
      dispatch(getUsersAsync(userReqBody)).then((res) => {
        if (!!res.payload.users && res.payload.users.length > 0) {
          // const users = res?.payload?.users.map((item) => {
          //   return { value: item.userInformationId, label: item.name };
          // });
          const users = res?.payload?.users
            .filter((item) => item.userInformationId !== userId)
            .map((item) => {
              return { value: item.userInformationId, label: item.name };
            });
          const optionsWithExtra = [
            { value: null, label: "Select...." },
            ...users,
          ];
          // console.log(users, "data1");
          setOptionsUser(optionsWithExtra);
        }
      });
    } else {
      if (!!data && data?.totalRecords > 0) {
        //   const users = data?.users.map((item) => {
        //     return { value: item.userInformationId, label: item.name };
        //   });
        //   console.log(data, "data");
        //  setOptionsUser(users);
        dispatch(getUsersAsync(userReqBody)).then((res) => {
          if (!!res.payload.users && res.payload.users.length > 0) {
            // const users = res?.payload?.users.map((item) => {
            //   return { value: item.userInformationId, label: item.name };
            // });
            const users = res?.payload?.users
              .filter((item) => item.userInformationId !== userId)
              .map((item) => {
                return { value: item.userInformationId, label: item.name };
              });
            const optionsWithExtra = [
              { value: null, label: "Select...." },
              ...users,
            ];
            //console.log(users, "data1");
            setOptionsUser(optionsWithExtra);
          }
        });
      }
    }

    if (action == "Edit") {
      setUserData(user);
      setSelectedTeam({
        value: user?.teamId,
        label: user?.team,
      });

      setSelectedRole({
        value: user?.roleId,
        label: user?.role,
      });

      setSelectedTimeZone({
        value: user?.timeZoneId,
        label: user?.timeZoneDisplayName,
      });

      setSelectedUserList({
        value: user?.reportsTo,
        label: user?.reportsToName,
      });
    }
  }, []);

  const validateCompanyEmail = async (
    companyEmail,
    validateField,
    touchedFields,
    setTouchedFun
  ) => {
    setTouchedFun({ ...touchedFields, ["companyEmail"]: true });
    validateField("companyEmail");
    if (companyEmail.trim() !== "") {
      // console.log(userData,'userDatauserDatauserDatauserDatauserData');
      const jsonData = userData["userInformationId"]
        ? {
            id: userData["userInformationId"] ?? null,
            identityId: userData["identityId"] ?? null,
            email: companyEmail,
          }
        : {
            email: companyEmail,
          };

      await dispatch(checkUniquenessCompanyEmailAsync(jsonData)).then((res) => {
        setIsUniqueCompanyEmail(res?.payload ? true : false);
        setTitleFocused(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["companyEmail"]: true });
          validateField("companyEmail");
        }, 1);
      });
    }
  };
  const debouncedValidateCompanyEmail = useDebounce(validateCompanyEmail, 1000);

  const validatePersonalEmail = async (
    personalEmail,
    validateField,
    touchedFields,
    setTouchedFun
  ) => {
    setTouchedFun({ ...touchedFields, ["personalEmail"]: true });
    validateField("personalEmail");
    if (personalEmail.trim() !== "") {
      const jsonData = userData["id"]
        ? {
            id: userData["id"] ?? null,
            identityId: userData["identityId"] ?? null,
            email: personalEmail,
          }
        : {
            email: personalEmail,
          };

      await dispatch(checkUniquenessPersonalEmailAsync(jsonData)).then(
        (res) => {
          setIsUniquePersonalEmail(res?.payload ? true : false);
          setTitleFocused(false);
          setTimeout(() => {
            setTouchedFun({ ...touchedFields, ["personalEmail"]: true });
            validateField("personalEmail");
          }, 1);
        }
      );
    }
  };
  const debouncedValidatePersonalEmail = useDebounce(validatePersonalEmail, 1000);

  const validatePhoneNumber = async (
    phoneNumber,
    validateField,
    touchedFields,
    setTouchedFun
  ) => {
    setTouchedFun({ ...touchedFields, ["phoneNumber"]: true });
    validateField("phoneNumber");
    // console.log(phoneNumber,'phoneNumber');

    // Remove spaces from the phone number
    const phoneNumberWithoutSpaces = phoneNumber.replace(/\s/g, "");

    if (phoneNumber && phoneNumber.trim() !== "") {
      const jsonData = userData["userInformationId"]
        ? {
            id: userData["userInformationId"] ?? null,
            identityId: userData["identityId"] ?? null,
            phoneNumber: phoneNumberWithoutSpaces,
          }
        : {
            phoneNumber: phoneNumberWithoutSpaces,
          };

      await dispatch(checkUniquenessPhoneNumberAsync(jsonData)).then((res) => {
        // console.log(res?.payload,'res?.payload');
        setIsUniquePhoneNumber(res?.payload ? true : false);
        setPhoneFocused(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["phoneNumber"]: true });
          validateField("phoneNumber");
        }, 1);
      });
    }
  };
  const debouncedValidatePhoneNumber = useDebounce(validatePhoneNumber, 1000);

  useEffect(() => {
    const reqBody = {
      id: userData?.id ? userData?.id : "",
      teamId: selectedTeam?.value ? selectedTeam?.value : "",
    };
    dispatch(validateHelpdeskNumberAsync(reqBody)).then((res) => {
      // console.log(res, 'res')
      if (res.payload) {
        setHelpdeskNumber(res.payload);
      }
    });
  }, []);

  useEffect(() => {
    console.log(action, "action");
    console.log(userData, "userData");
    console.log(selectedTeam, "selectedTeam");
    console.log(helpdeskNumber, "helpdeskNumber");
    if (
      action == "Edit" &&
      helpdeskNumber?.email !== userData?.companyEmail &&
      helpdeskNumber?.result === "Helpdesk with same team exist."
    ) {
      setShowHelpDeskError(true);
    } else if (
      action !== "Edit" &&
      selectedTeam?.value &&
      helpdeskNumber?.result === "Helpdesk with same team exist."
    ) {
      setShowHelpDeskError(true);
    } else {
      setShowHelpDeskError(false);
    }
  }, [userData, selectedTeam, helpdeskNumber, action]);

  const [selectedValues, setSelectedValues] = useState();
  const handleCheckboxChange = (newValues) => {
    setSelectedValues(newValues);
  };
  
  const handleClose = () => {
    setIsVisible(false);
  };
  // console.log(selectedTeam,'selectedTeam');
  // console.log(selectedTimeZone,'selectedTimeZone');
  // console.log(selectedRole.label, "userData");
  // console.log(userData, "userDatauserDatauserDatauserData");
  // console.log(helpdeskNumber,'helpdeskNumber');

  return (
    userData && (
      <>
        {!isRestricted && (
          <Formik
            initialValues={{
              // id: userData?.id,
              role: userData?.role ?? undefined,
              roleId: userData?.roleId ?? undefined,
              // profilePicture: userData?.profilePicture,
              name: userData?.name,
              title: userData?.title,
              phoneNumber: userData?.phoneNumber,
              personalEmail: userData?.personalEmail,
              tags: userData?.tags,
              status: userData?.status,
              companyEmail: userData?.companyEmail,
              teamId: userData?.teamId,
              timeZoneId: userData?.timeZoneId ?? 1,
              reportsTo: userData?.reportsTo,
              // userName: userData?.userName,
              password: userData?.password,
              joiningDate: userData?.joiningDate
                ? new Date(userData?.joiningDate)
                : new Date(),
              changePasswordFlag: userData?.changePasswordFlag,
              isHelpdeskNumber: userData?.isHelpdeskNumber,
              inviteDate: userData?.inviteDate
                ? new Date(userData?.inviteDate)
                : new Date(),
            }}
            onSubmit={onUserSubmit}
            validate={userValidation}
            innerRef={formRef}
          >
            {({
              formik,
              errors,
              touched,
              isValidating,
              values,
              validateField,
              handleBlur,
              setFieldValue,
              setTouched,
            }) => {
              // console.log(values, 'values');
              return (
                <>
                  <Modal.Header>
                    {/* closeButton */}
                    {/* onClick={() => onClose(false)} */}
                    <div className="w-100">
                      {action == "Edit" && (
                        <Modal.Title>Edit user details</Modal.Title>
                      )}
                      {action != "Edit" && (
                        <Modal.Title>Add User</Modal.Title>
                      )}
                    </div>
                    <button
                      onClick={() => {
                        reloadUser && reloadUser(true);
                        handleModal(false);                        
                      }}
                      type="button"
                      class="btn-close"
                      aria-label="Close"
                    ></button>
                  </Modal.Header>
                  <Modal.Body className="">
                    {formDataReady === false && <Loader />}
                    {formDataReady && (
                      <Form style={{ display: "contents" }}>
                        <div className="row">
                          <div className="col-6 col-md-6 col-lg-6">
                            {/* {action != "Edit" && ( */}
                            <div className="form-group mySelect mb-32">
                              <label>
                                Role
                                <span
                                  className="mandatoryFields"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </label>
                              {userData.role === "Admin" ? (
                                <Select
                                  name="roleId"
                                  isSearchable={false}
                                  className="select"
                                  // classNamePrefix="recatMySel"
                                  classNamePrefix="selectCustom"
                                  options={optionsRole}
                                  defaultValue={selectedRole}
                                  isDisabled={true}
                                  value={selectedRole}
                                />
                              ) : (
                                <Select
                                  name="roleId"
                                  isSearchable={false}
                                  className="select"
                                  // classNamePrefix="recatMySel"
                                  classNamePrefix="selectCustom"
                                  options={optionsRole}
                                  defaultValue={selectedRole}
                                  value={selectedRole}
                                  onChange={(e) => {
                                    // console.log(e, "eventt");
                                    // setSelectedRoleId(e.value);
                                    onSelectationChange(
                                      e,
                                      "role",
                                      setFieldValue,
                                      setSelectedRole
                                    );
                                  }}
                                />
                              )}
                            </div>
                            {/* )} */}

                            <div className="input-group myInput mb-32">
                              <TextInput
                                name="title"
                                label="Designation"
                                className="form-control"
                                placeholder={
                                  isFieldVisible()
                                    ? "Enter employee designation"
                                    : "Enter admin designation"
                                }
                                isRequired={true}
                              />
                            </div>

                            <div className="input-group myInput mb-32">
                              <TextInput
                                name="name"
                                label={
                                  isFieldVisible()
                                    ? "Employee name"
                                    : "Admin name"
                                }
                                className="form-control"
                                placeholder={
                                  isFieldVisible()
                                    ? "Enter employee name"
                                    : "Enter admin name"
                                }
                                isRequired={true}
                                // onChange={handleNameChange}
                                // value={name}
                              />
                              {/* <label>  Employee nam</label>
                                                  <input type="text" className="form-control" placeholder="Enter employee name" /> */}
                            </div>
                            <div className="input-group myInput mb-32">
                              <TextInput
                                name="companyEmail"
                                label="Company email address"
                                className="form-control"
                                placeholder="Enter email address"
                                isRequired={true}
                                // onBlur={(event) => {
                                //   validateCompanyEmail(
                                //     event?.target?.value,
                                //     validateField,
                                //     touched,
                                //     setTouched
                                //   );
                                // }}
                                onInput={(event) => {
                                  debouncedValidateCompanyEmail(
                                    event?.target?.value,
                                    validateField,
                                    touched,
                                    setTouched
                                  );
                                }}
                                disabled={action == "Edit"}
                              />
                            </div>
                            {/* {isFieldVisible() && (
                              <> */}
                            <div className="input-group myInput mb-32">
                              <TextInput
                                name="personalEmail"
                                label="Personal email address"
                                className="form-control"
                                placeholder="Enter email address"
                                isRequired={false}
                                // onBlur={(event) => {
                                //   validatePersonalEmail(
                                //     event?.target?.value,
                                //     validateField,
                                //     touched,
                                //     setTouched
                                //   );
                                // }}
                                onInput={(event) => {
                                  debouncedValidatePersonalEmail(
                                    event?.target?.value,
                                    validateField,
                                    touched,
                                    setTouched
                                  );
                                }}
                              />
                            </div>

                            <div className="myInput addUser phoneCountry ee mb-32">
                              {/* <TextInput
                                    name="phoneNumber"
                                    label="Phone number"
                                    className="form-control"
                                    placeholder="Enter phone number"
                                    isRequired={true}
                                    value={values.phoneNumber}
                                  /> */}
                              <label>
                                Phone number
                                <span
                                  className="mandatoryFields"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </label>
                              <PhoneInput
                                // className='addUser'
                                defaultCountry="IN"
                                international
                                addInternationalOption={false}
                                placeholder="Phone Number"
                                name="phoneNumber"
                                value={values.phoneNumber}
                                onChange={(e) =>
                                  setFieldValue("phoneNumber", e)
                                }
                                // // onBlur={handleBlur("phoneNumber")}
                                // onBlur={(event) => {
                                //   validatePhoneNumber(
                                //     event?.target?.value,
                                //     validateField,
                                //     touched,
                                //     setTouched
                                //   );
                                // }}
                                onInput={(event) => {
                                  debouncedValidatePhoneNumber(
                                    event?.target?.value,
                                    validateField,
                                    touched,
                                    setTouched
                                  );
                                }}
                                limitMaxLength={25}
                              />
                              {touched.phoneNumber && errors.phoneNumber && (
                                <label
                                  className="errorValidation"
                                  style={{ color: "red" }}
                                >
                                  {errors.phoneNumber}
                                </label>
                              )}
                            </div>
                            <div className="form-group mySelect mb-3">
                              <label>
                                Team
                                <span
                                  className="mandatoryFields"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </label>
                              <Select
                                name="teamId"
                                isSearchable={false}
                                className="select"
                                classNamePrefix="selectCustom"
                                // classNamePrefix="recatMySel"
                                options={optionsTeam}
                                defaultValue={selectedTeam}
                                value={selectedTeam}
                                onChange={(e) =>
                                  onSelectationChange(
                                    e,
                                    "teamId",
                                    setFieldValue,
                                    setSelectedTeam
                                  )
                                }
                              />

                              {userData?.isHelpdeskNumber === false &&
                              selectedTeam.value &&
                              helpdeskNumber?.result ===
                                "Helpdesk count exceeded." ? (
                                <p className="h6 text-secondary-600 pt-2">
                                  Helpdesk members limit has reached. Please
                                  remove an existing one to add new.
                                </p>
                              ) : null}
                            </div>
                            {/* {(userData.role === "Admin" || selectedRole.label === "Admin") && ( */}
                            <div className="cusCheck simpleCheck">
                              <p className="h6 text-gray-200 mwidth-440">
                                {/* <TextInput
                                      name="isHelpdeskNumber"
                                      label="Mark User as helpdesk member"
                                      type="checkbox"
                                      value={values.isHelpdeskNumber}
                                      disabled={
                                        values.status === "Inactive" || 
                                        (helpdeskNumber === "Helpdesk with the same team exist." ||
                                        helpdeskNumber === "Helpdesk count exceeded.") ||
                                        !((action === "Edit" && values.isHelpdeskNumber === true) || (helpdeskNumber === "Success"))
                                      }
                                      className="checkBoxCheck"
                                    /> */}

                                <CheckBox
                                  name="isHelpdeskNumber"
                                  text="Mark User as helpdesk member"
                                  checked={values.isHelpdeskNumber}
                                  onChange={() => {
                                    setFieldValue(
                                      "isHelpdeskNumber",
                                      !values.isHelpdeskNumber
                                    );
                                  }}
                                  disabled={
                                    values.status === "Inactive" ||
                                    helpdeskNumber?.result ===
                                      "Helpdesk with the same team exist." ||
                                    (userData?.isHelpdeskNumber === false &&
                                      helpdeskNumber?.result ===
                                        "Helpdesk with same team exist.") ||
                                    (userData?.isHelpdeskNumber === false &&
                                      helpdeskNumber?.result ===
                                        "Helpdesk count exceeded.") ||
                                    showHelpDeskError === true
                                    // ||
                                    // !((action === "Edit" && values.isHelpdeskNumber === true) || (helpdeskNumber === "Success"))
                                  }
                                  className="checkBoxCheck"
                                />
                              </p>
                            </div>
                            {/* )} */}
                            {/* {userData.role === "Admin" && selectedRole.label === "Admin" && ( */}
                            <div className="cusCheck simpleCheck pt-1">
              
                              <p className="h6 text-gray-200 mb-32 mwidth-440 mt-2">
                                {isVisible && userData?.status === "Inactive" ? (
                                  <>
                                    <div className="d-flex align-items-center justify-content-between m-0 radius-6" style={{background: "rgba(74, 21, 75, 0.10)", padding:"10px 15px"}} onClick={handleClose}>
                                      <p className="h6 text-primary-200 m-0 p-0" style={{width: "calc(100% - 30px)",marginRight: "12px"}}>{`Archived user cannot be added as a helpdesk member.`}</p>
                                      <span className="d-flex align-items-center justify-content-end p-0 btn border-0"><CrossIconXl/></span>
                                    </div>
                                  </>
                                ) : isVisible && showHelpDeskError === true ? (
                                  <>
                                    <div className="d-flex align-items-center justify-content-between m-0 mt-3 radius-6" style={{background: "rgba(74, 21, 75, 0.10)", padding:"10px 15px"}} onClick={handleClose}>
                                      <p className="h6 text-primary-200 m-0 p-0" style={{width: "calc(100% - 30px)",marginRight: "12px"}}>{`'${selectedTeam.label}' team already has a helpdesk member '${helpdeskNumber?.name}(${helpdeskNumber?.email})'. Kindly remove the current selection and try again.`}</p>
                                      <span className="d-flex align-items-center justify-content-end p-0 btn border-0"><CrossIconXl/></span>
                                    </div>
                                  </>
                                ) : null}
                              </p>
                            </div>
                            {/* )} */}
                            <div className="form-group mySelect mb-32">
                              <label>
                                Reporting to
                                {selectedRole.label === "Employee" && (
                                  <span
                                    className="mandatoryFields"
                                    style={{ color: "red" }}
                                  >
                                    *
                                  </span>
                                )}
                              </label>
                              <Select
                                name="reportsTo"
                                isSearchable={true}
                                className="select"
                                classNamePrefix="selectCustom"
                                // classNamePrefix="recatMySel"
                                options={optionsUser}
                                defaultValue={selectedUserList}
                                value={selectedUserList}
                                onChange={(e) => {
                                  onSelectationChange(
                                    e,
                                    "reportsTo",
                                    setFieldValue,
                                    setSelectedUserList
                                  );
                                }}
                              />
                            </div>
                            <div className="form-group mySelect">
                              <label>
                                Timezone
                                <span
                                  className="mandatoryFields"
                                  style={{ color: "red" }}
                                >
                                  *
                                </span>
                              </label>
                              <Select
                                name="timeZoneId"
                                isSearchable={true}
                                className="select"
                                classNamePrefix="selectCustom"
                                // classNamePrefix="recatMySel"
                                options={optionsTimezone}
                                defaultValue={selectedTimeZone}
                                value={selectedTimeZone}
                                onChange={(e) => {
                                  onSelectationChange(
                                    e,
                                    "timeZoneId",
                                    setFieldValue,
                                    setSelectedTimeZone
                                  );
                                }}
                              />
                            </div>
                            {/* </>
                            )} */}
                          </div>
                          <div className="col-6 col-md-6 col-lg-6">
                            {/* {isFieldVisible() && ( */}
                            <div className="mwidth-440">
                              <div className="mb-32">
                                <TagsInput
                                  inputTags={userData?.tags ?? ""}
                                  onChange={onTagsUpdate}
                                  setMethod={setFieldValue}
                                  touchedMethod={setTouched}
                                  touchedData={touched}
                                />
                                {touched.tags && errors.tags && (
                                  <label
                                    className="errorValidation"
                                    style={{ color: "red" }}
                                  >
                                    {errors.tags}
                                  </label>
                                )}
                              </div>

                              <div className="mb-32 pb-32 borderBottom">
                                <p className="h6 mb-2 font-medium">
                                  Joining date
                                  <span
                                    className="mandatoryFields"
                                    style={{ color: "red" }}
                                  >
                                    *
                                  </span>
                                </p>
                                <div className="input-group myInput dateCalendar iconbig dateCalendarbgico">
                                  {/* <IconCalendar /> */}
                                  <DatePicker
                                    name="joiningDate"
                                    selected={values.joiningDate}
                                    onChange={(date) => {
                                      setFieldValue("joiningDate", date);
                                    }}
                                    minDate={new Date()}
                                  />
                                  {errors.joiningDate && (
                                    <label
                                      className="errorValidation"
                                      style={{ color: "red" }}
                                    >
                                      {errors.joiningDate}
                                    </label>
                                  )}
                                </div>
                              </div>
                            </div>
                            {/* )} */}
                            {action != "Edit" && (
                              <>
                                <p className="font-bold mb-1">
                                  Login Credentials
                                </p>
                                <p className="h6 fw-normal text-gray-300 mb-4">
                                  Set credentials manually
                                </p>
                              </>
                            )}
                            {action != "Edit" && (
                              <>
                                <div className="input-group myInput mb-32">
                                  <TextInput
                                    disabled
                                    name="userName"
                                    label="Username"
                                    className="form-control"
                                    placeholder="Enter user name"
                                    value={values.companyEmail}
                                    // isRequired={true}
                                    // onFocus={() => {
                                    //   setTitleFocused(true);
                                    // }}
                                    // onBlur={(event) => {
                                    //   validateTitle(
                                    //     event?.target?.value,
                                    //     validateField,
                                    //     touched,
                                    //     setTouched
                                    //   );
                                    // }}
                                  />
                                </div>

                                <div className="input-group myInput mb-12">
                                  <TextInput
                                    name="password"
                                    label="Password"
                                    className="form-control"
                                    placeholder="Enter password"
                                    isRequired={true}
                                  />
                                </div>
                                <p className="h6 text-gray-200 mb-32 mwidth-440">
                                  <CheckBoxMd
                                    name="changePasswordFlag"
                                    text="Change password on first login"
                                    value={values.changePasswordFlag}
                                    onChange={() => {
                                      setFieldValue(
                                        "changePasswordFlag",
                                        !values.changePasswordFlag
                                      );
                                    }}
                                  />
                                </p>
                                {/* {isFieldVisible() && ( */}
                                <div className="mb-3">
                                  <p className="h6 mb-2 font-medium">
                                    Schedule to send invite
                                    <span
                                      className="mandatoryFields"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span>
                                  </p>
                                  {/* {isFieldVisible() && ( */}
                                  <div className="input-group myInput dateCalendar iconbig dateCalendarbgico">
                                    {/* <IconCalendar /> */}
                                    <DatePicker
                                      name="inviteDate"
                                      selected={values.inviteDate}
                                      onChange={(date) => {
                                        setFieldValue("inviteDate", date);
                                      }}
                                      minDate={new Date()}
                                    />
                                    {errors.inviteDate && (
                                      <label
                                        className="errorValidation"
                                        style={{ color: "red" }}
                                      >
                                        {errors.inviteDate}
                                      </label>
                                    )}
                                  </div>
                                  {/* )} */}
                                </div>
                                {/* )} */}
                              </>
                            )}

                            {/* {action == "Edit" && (
                              <>
                                <h1>Credentials are Configured.</h1>
                              </>
                            )} */}
                          </div>
                        </div>
                      </Form>
                    )}
                  </Modal.Body>
                  <Modal.Footer className="justify-content-start btnFooter">
                    {action != "Edit" && (
                      <>
                        <Button
                          variant="secondary"
                          className="m-0"
                          type="submit"
                          disabled={
                            Object.keys(userValidation(values)).length !== 0 ||
                            !formDataReady
                          }
                          onClick={() => onUserSubmit()}
                        >
                          Invite User
                        </Button>
                        <Button
                          variant="white"
                          className="m-0 mx-20"
                          onClick={() => {
                            onUserSubmit("invite");
                          }}
                          disabled={
                            Object.keys(userValidation(values)).length !== 0 ||
                            !formDataReady
                          }
                        >
                          Create & Invite Another
                        </Button>
                      </>
                    )}
                    {action == "Edit" && (
                      <Button
                        variant="secondary"
                        className="m-0"
                        type="submit"
                        disabled={
                          Object.keys(userValidation(values)).length !== 0 ||
                          !formDataReady
                        }
                        onClick={() => onUserSubmit("edit")}
                      >
                        Update User
                      </Button>
                    )}
                  </Modal.Footer>
                </>
              );
            }}
          </Formik>
        )}

        {isRestricted && (
          <>
            <Modal.Header>
              <div className="w-100">
                <Modal.Title>Add User</Modal.Title>
              </div>
              <button
                onClick={() => onClose(false)}
                type="button"
                class="btn-close"
                aria-label="Close"
              ></button>
            </Modal.Header>
            <Modal.Body className="">
              <RestrictedPlan
                title={"User Limit Reached!"}
                description={
                  "You have reached the maximum number of users allowed under your current plan. To add more users, upgrade your plan."
                }
              />
            </Modal.Body>
            <Modal.Footer className="justify-content-start btnFooter">
              <Button
                variant="secondary"
                className="m-0"
                type="submit"
                onClick={() => onClose(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </>
        )}
      </>
    )
  );
};

export default AddUserEmployeeModal;
