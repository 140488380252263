import React from "react";
import "./Loader.css";
const Loader = ({type}) => {
  return (
    <div className={`${type==="activity" ? "h-100 w-100 overflow-hidden d-flex align-items-center justify-content-center" :"loader-container"}`}>
      <div className="spinner"></div>
    </div>
  );
};
export default Loader;
