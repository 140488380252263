import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Search from "../../../components/Search/Search";
import Select from 'react-select';
import { IconHamberger, IconStar } from "../../../icons/Icons";
import CheckBox from "../../../components/CheckBox/CheckBox";
import AccountImg from "../../../assets/images/Account.png";
const ProcessExpiredModal = ({ openModalExpiredProcess }) => {
    const optionSort = [
        { value: '1', label: 'Oldest-Latest' },
        { value: '2', label: 'Oldest-Latest 2' },
        { value: '3', label: 'Oldest-Latest 3' }
    ]
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <circle cx="24" cy="24" r="24" fill="#F8F8F8" />
                    <path d="M24 20V26.6667" stroke="#E01E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M24.0001 36.5467H15.9201C11.2934 36.5467 9.36007 33.24 11.6001 29.2L15.7601 21.7067L19.6801 14.6667C22.0534 10.3867 25.9467 10.3867 28.3201 14.6667L32.2401 21.72L36.4001 29.2133C38.6401 33.2533 36.6934 36.56 32.0801 36.56H24.0001V36.5467Z" stroke="#E01E1E" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    <path d="M23.9927 30.6666H24.0047" stroke="#E01E1E" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                <h3 className='mt-3'>Process: HR Onboarding contains an expired module</h3>
                <p className='text-gray-300 mb-0 mt-2'>Lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum lorem ipsum.</p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button variant="white" size="sm" onClick={() => openModalExpiredProcess(false)}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" className='border-0'>
                    Edit Settings
                </Button>
            </Modal.Footer>
        </>
    );
}

export default ProcessExpiredModal;
