import React, { useState, useEffect } from "react";
import PageHeaderSection from "../../../components/PageHeader/PageHeaderSection";
import {
  Row,
  Col,
  Container,
  Modal,
  Tooltip,
  Button,
  OverlayTrigger,
} from "react-bootstrap";
import visaCard from "../../../assets/images/visaCard.png";
import MasterCard from "../../../assets/images/masterCard.png";
import { IconEdit3, IconHamberger, MinusBorder, TickNormal, TickFill } from "../../../icons/Icons";
import { Link, useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlansAsync,
  getSubscriptionAsync,
  getCardListByCustomerIdAsync,
  updateSubscriptionAsync,
  downgradeSubscriptionAsync,
  getStorageAsync,
} from "../../../reducers/subscription-reducers";
import { convertToGB, convertMBToGB, convertKBToGBNumeric, convertMBToKB, getProratedUpgradeAmount } from "../../../common";
import EditPaymentModal from "../../../components/PaymentCard/EditPaymentModal";
import UpgradeModal from "./UpgradeModal";
import DowngradeModal from "./DowngradeModal";
import Downgrade2Modal from "./Downgrade2Modal";
import SamePlanModal from "./SamePlanModal";
import RetainSamePlanModal from "./RetainSamePlanModal";
import DowngradeUnderProcessModal from "./DowngradeUnderProcessModal";
import ToasterAlertError from "../../../helper/ToasterAlertError";
import PlanComparisonModal from "./PlanComparisonModal";
import { getUserUsageAsync } from "../../../reducers/user-reducers";
import Loader from "../../../components/Loader/Loader";
import DowngradePlanModal from "./DowngradePlanModal";

const ManageSubscription = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();

  const planType = location.state.planType;
  const isTrialingPlan = location?.state?.isTrialingPlan;

  const { plansData, isUpdateLoading, isDowngradeLoading } = useSelector(
    (state) => state.subscription
  );
  const subscriptionData = useSelector((state) => state.subscription);
  const { storageData } = useSelector((state) => state.subscription);

  const [viewPlan, setViewPlan] = useState(
    planType && planType === "Monthly" ? "month" : "year"
  );
  const [isCouponUsed, setIsCouponUsed] = useState(false);
  const [subscribePlanId, setSubscribePlanId] = useState(-1);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState("");

  const [reversedPlansData, setReversedPlansData] = useState([]);
  const [enterprisePlansData, setEnterprisePlansData] = useState([]);
  const [startUpPlans, setStartUpPlans] = useState([]);
  const [businessPlans, setBusinessPlans] = useState([]);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [paymentCardModal, setPaymentCardModal] = useState(false);
  const [isMakePayment, setIsMakePayment] = useState(false);
  const [cardListData, setCardListData] = useState([]);
  const [cardId, setCardId] = useState("");
  const [editPayment, setEditPayment] = useState(false);
  const [upgrade, setUpgrade] = useState(false);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [makeUpgrade, setMakeUpgrade] = useState(false);
  const [isPlanChange, setIsPlanChange] = useState(false);

  const [downgrade, setDowngrade] = useState(false);
  const [isPlanDowngrade, setIsPlanDowngrade] = useState(false);
  const [makeDowngrade, setMakeDowngrade] = useState(false);
  const [downgrade2, setDowngrade2] = useState(false);
  const [donwgradePlanReason, setDonwgradePlanReason] = useState({
    planReason: "",
    other: "",
  });
  const [samePlan, setSamePlan] = useState(false);
  const [planComparison, setPlanComparison] = useState(false);
  const [retainSamePlan, setRetainSamePlan] = useState(false);
  const [afterDowngradePlanData, setAfterDowngradePlanData] = useState([])
  const [downgradeUnderProcess, setDowngradeUnderProcess] = useState(false);

  const [userUsage, setUserUsage] = useState({});
  const [metaData, setMetaData] = useState({});
  const [basePlanStorage, setBasePlanStorage] = useState({});
  const [downgradNotification, setDowngradNotification] = useState(false);
  const [userNotification, setUserNotification] = useState(false);
  const [moduleNotification, setModuleNotification] = useState(false);
  const [processNotification, setProcessNotification] = useState(false);
  const [sequenceNotification, setSequenceNotification] = useState(false);
  const [storageNotification, setStorageNotification] = useState(false); 
  const [isDowngrade, setIsDowngrade] = useState(false);
  const [formDataReady, setFormDataReady] = useState(false);
  const [proratedAmount, setProratedAmount] = useState(0);
  const [downgradePlan, setDowngradePlan] = useState(false);
  useEffect(() => {
    getUserUsage();
    dispatch(getStorageAsync());
  }, []);

  const getUserUsage = () => {
    dispatch(getUserUsageAsync()).then((res) => {
      if (res.payload) {
        setUserUsage(res.payload);
      }
    });
  };

  useEffect(() => {
    // console.log(isDowngradeLoading,'isDowngradeLoading');
    if (isPlanChange) {
      if (!isUpdateLoading) {
        // selectedPlanData
        if (isUpgrade) {
          history.push({
            pathname: "/accountsetting/billingsubscription/PlanRetained",
            state: { selectedPlanData: selectedPlanData },
          });
        } else {
          history.push({
            pathname: "/accountsetting/billingsubscription/upgrade",
            state: { selectedPlanData: selectedPlanData },
          });
        }
      }
    }
    if (isPlanDowngrade) {
      if (!isDowngradeLoading) {
        history.push({
          pathname: "/accountsetting/billingsubscription/downgrade",
          state: { selectedPlanData: selectedPlanData },
        });
      }
    }
  }, [
    isPlanChange,
    isUpdateLoading,
    isUpgrade,
    history,
    isPlanDowngrade,
    isDowngradeLoading,
  ]);

  useEffect(() => {
    // console.log(plansData,'plansDataplansData');
    if (plansData && plansData.length > 0) {
      const filteredPlansData = plansData.filter(
        (plan) =>
          plan.planType === (viewPlan === "year" ? "Yearly" : "Monthly") &&
          plan.statementDescriptor === "base_plan" &&
          plan.planName !== "Enterprise"
      );

      const filteredEnterprisePlans = plansData.filter(
        (plan) =>
          // plan.planType === (viewPlan === "year" ? "Yearly" : "Monthly") &&
          plan.statementDescriptor === "base_plan" &&
          plan.planName === "Enterprise"
      );
      setEnterprisePlansData(filteredEnterprisePlans);
      // console.log(filteredPlansData,'filteredPlansData');
      // const reversedData = [...filteredPlansData].reverse();
      let reversedData = [];
      if (viewPlan === "year") {
        reversedData = [...filteredPlansData].sort((a, b) => a.price - b.price);
        setReversedPlansData(reversedData);
      } else {
        reversedData = [...filteredPlansData];
        setReversedPlansData(reversedData);
      }

      const startUpDatas = reversedData.filter(
        (plan) => plan.planName === "Startup"
      );
      setStartUpPlans(startUpDatas && startUpDatas[0]);

      const businessDatas = reversedData.filter(
        (plan) => plan.planName === "Business"
      );
      setBusinessPlans(businessDatas && businessDatas[0]);
    }
  }, [plansData, viewPlan]);

  useEffect(() => {
    dispatch(getPlansAsync());
    dispatch(getSubscriptionAsync());
    setFormDataReady(true);
  }, []);

  useEffect(() => {
    getCardList();
  }, [editPayment]);

  useEffect(() => {
    setSubscribePlanId(-1);
    const monthlyTotal = plansData?.reduce((total, planData) => {
      const monthlyPrice =
        planData?.planType === "Monthly" ? planData?.price / 100 : 0;
      return total + (monthlyPrice || 0);
    }, 0);

    const yearlyTotal = plansData?.reduce((total, planData) => {
      const yearlyPrice =
        planData?.planType === "Yearly" ? planData?.price / 100 : 0;
      return total + (yearlyPrice || 0);
    }, 0);

    setDiscountedPrice(
      (((monthlyTotal * 12 - yearlyTotal) / (monthlyTotal * 12)) * 100).toFixed(
        2
      )
    );

    if ((subscriptionData?.subscriptionData === null || subscriptionData?.subscriptionData?.length === 0)) {
      setIsCouponUsed(subscriptionData?.subscriptionData?.isCouponUsed);
    }

    if (
      subscriptionData?.subscriptionData?.subscriptions?.length > 0 &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0
    ) {
      if (subscriptionData?.subscriptionData?.subscriptions[0]?.scheduleId !== null && subscriptionData?.subscriptionData?.subscriptions[0]?.scheduleId) {
        if (subscriptionData?.subscriptionData?.subscriptions[0]?.schedule?.phases[1]?.items[0]?.priceId) {
          const afterDowngradePlan = plansData.filter(
            (plan) =>
              plan.statementDescriptor === "base_plan" && plan.priceId === subscriptionData.subscriptionData.subscriptions[0].schedule.phases[1].items[0].priceId
          );
          setAfterDowngradePlanData(afterDowngradePlan);
        }
      }

      // const reversedPlans = [...plansData].reverse();
      const subscriptionItems =
        subscriptionData?.subscriptionData?.subscriptions[0]?.items;
      // console.log(plansData,'plansData');
      // console.log(reversedPlansData, "reversedPlans");
      // console.log(subscriptionItems, "subscriptionItems");
      for (let index = 0; index < reversedPlansData?.length; index++) {
        const plan = reversedPlansData[index];
        if (subscriptionItems[0].price?.id === plan?.priceId) {
          // setViewPlan(plan?.planType === "Yearly" ? "year" : "month");
          setSubscribePlanId(index);
          break;
        }
      }

      // console.log(enterprisePlansData,'enterprisePlansData');
      for (let index = 0; index < enterprisePlansData?.length; index++) {
        const entPlan = enterprisePlansData[index];
        if (subscriptionItems[0].price?.id === entPlan?.priceId) {
          // console.log(subscriptionItems[0].price?.id,'setIsEnterprise price');
          // console.log(entPlan?.priceId, 'setIsEnterprise');
          setIsEnterprise(true);
          break;
        }
      }
    }
  }, [plansData, subscriptionData, reversedPlansData]);

  const getCardList = async () => {
    const reqBody = {
      customerId: "cus_PHZcTbZGclpDJ2",
    };
    await dispatch(getCardListByCustomerIdAsync(reqBody)).then((res) => {
      if (res.payload) {
        // console.log(res.payload,'getCardListByCustomerIdAsync');
        setCardListData(res.payload);
      }
    });
  };

  // const handlePlan = (val) => {
  //   // console.log(val, "val");
  //   if (val == "Yearly") {
  //     setViewPlan("year");
  //   } else {
  //     setViewPlan("month");
  //   }
  //   setIsMakePayment(false);
  //   setSelectedPlanId("");
  // };

  const onhandleGetStarted = (planData) => {
    let selPlanData = [];
    if (viewPlan === "year") {
      selPlanData = planData?.planType === "Yearly" ? planData : [];
    } else {
      selPlanData = planData?.planType === "Monthly" ? planData : [];
    }
    if (selPlanData) {
      setSelectedPlanId(selPlanData?.priceId);
      setSelectedPlanData(selPlanData);
    }
    setIsMakePayment(true);
  };

  console.log(subscriptionData, 'subscriptionDataManageSubscription');

  const onhandleUpgrade = (planData) => {
    let selPlanData = [];
    // console.log(planData, 'planDataplanDataplanData');
    const currentPeriodEnd = subscriptionData?.subscriptionData?.subscriptions[0]?.currentPeriodEnd;
    const currentPeriodStart = subscriptionData?.subscriptionData?.subscriptions[0]?.currentPeriodStart;
    const currentAmount = subscriptionData?.subscriptionData?.subscriptions[0]?.items[0]?.plan?.amount;
    if (currentPeriodEnd && currentPeriodStart && currentAmount) {
      const { getProRatedAmount } = getProratedUpgradeAmount(currentAmount/100, currentPeriodStart, currentPeriodEnd);
      setProratedAmount(getProRatedAmount);
    }
    if (viewPlan === "year") {
      selPlanData = planData?.planType === "Yearly" ? planData : [];
    } else {
      selPlanData = planData?.planType === "Monthly" ? planData : [];
    }
    if (selPlanData) {
      setSelectedPlanId(selPlanData?.priceId);
      setSelectedItemId(selPlanData?.planId);
      setSelectedPlanData(selPlanData);
    }
    setIsMakePayment(true);
    setUpgrade(true);
    setDowngrade(false);
  };

  const handleUpgradePlan = () => {
    planChange("Upgrade");
    setUpgrade(false);
    setIsUpgrade(false);
  };

  const planChange = (reason) => {
    const request = {
      subscriptionId:
        subscriptionData?.subscriptionData?.subscriptions?.[0]?.id ?? "",
      newPriceId: selectedPlanId,
      // reasonForUpdate: reason,
      quantity: 1,
      itemId: selectedItemId,
    };
    const res = dispatch(updateSubscriptionAsync(request));
    setIsPlanChange(true);
  };

  const onhandleDowngrade = (planData) => {
    setIsDowngrade(true);
    const metaDatas = planData?.metaData;
    setMetaData(metaDatas);
    // console.log(metaDatas, 'metadata');
    // console.log(userUsage, "userUsage");
    // console.log(storageData, "storageData");

    const basePlanStorages = metaDatas && convertMBToKB(parseInt(metaDatas?.storage)*parseInt(metaDatas?.included_users));
    setBasePlanStorage(basePlanStorages);

    let selPlanData = [];
    let showDowngrade = false;
    if (userUsage?.usersCount > parseInt(metaDatas?.included_users)) {
      showDowngrade = true;
      setUserNotification(true);
      setDowngradNotification(true);
    }
    if (userUsage?.modulesCount > parseInt(metaDatas?.modules)) {
      showDowngrade = true;
      setModuleNotification(true);
      setDowngradNotification(true);
    }
    if (userUsage?.processCount > parseInt(metaDatas?.process)) {
      showDowngrade = true;
      setProcessNotification(true);
      setDowngradNotification(true);
    }
    if (userUsage?.sequenceCount > parseInt(metaDatas?.sequence)) {
      showDowngrade = true;
      setSequenceNotification(true);
      setDowngradNotification(true);
    }
    if (parseInt(storageData?.usedStorage) > basePlanStorages) {
      showDowngrade = true;
      setStorageNotification(true);
      setDowngradNotification(true);
    }
    if (showDowngrade === false) {
      if (afterDowngradePlanData?.length > 0) {
        setDowngradeUnderProcess(true);
      } else {
        setDowngradeUnderProcess(false);
        if (viewPlan === "year") {
          selPlanData = planData?.planType === "Yearly" ? planData : [];
        } else {
          selPlanData = planData?.planType === "Monthly" ? planData : [];
        }
        if (selPlanData) {
          setSelectedPlanId(selPlanData?.priceId);
          setSelectedPlanData(selPlanData);
        }
        setIsMakePayment(true);
        setUpgrade(false);
        setDowngrade(true);
      }
    }   
  };

  const handleDowngradePlan = () => {
    // setDowngrade(false);
    setMakeDowngrade(false);
    setDowngrade2(true);
    //setLessFeaturesPlan(true);
  };

  const handleComfirmDowngrade = () => {
    const reason =
      donwgradePlanReason.planReason === "Others"
        ? donwgradePlanReason.other
        : donwgradePlanReason.planReason;
    planChangeDowngrade(reason);
    setDowngrade2(false);
    setSamePlan(false);
  };

  const handleComfirmRetainSamePlan = () => {
    setSamePlan(false);
    setRetainSamePlan(true);
  };

  const planChangeDowngrade = (reason) => {
    const request = {
      subscriptionId:
        subscriptionData?.subscriptionData?.subscriptions?.length > 0
          ? subscriptionData?.subscriptionData?.subscriptions[0].id
          : "",
      newPriceId: selectedPlanId,
      // reasonForUpdate: reason,
      quantity: 1,
    };
    // const res = dispatch(downgradeSubscriptionAsync(request));
    setFormDataReady(false);
    dispatch(downgradeSubscriptionAsync(request)).then((res) => {
      if (res?.payload === "downgrade is already scheduled") {
        ToasterAlertError("Downgrade is already scheduled");
      } else {
        setDowngrade2(false);
        setIsPlanDowngrade(true);
      }
      setFormDataReady(true);
    });
    // console.log(res, "update");
    // setIsPlanDowngrade(true);
  };

  const makePayment = () => {
    if (downgrade) {
      setMakeDowngrade(true);
    } else if (upgrade) {
      setMakeUpgrade(true);
    }
  };

  const handleEdit = (id) => {
    //  console.log("handleEdit", id);
    setCardId(id);
    setEditPayment(true);
  };

  const handleLessFeaturesPlan = () => {
    setDowngrade2(false);
    setSamePlan(true);
  };

  console.log(startUpPlans, "startUpPlans");
  console.log(businessPlans, "businessPlans");

  // console.log(plansData, "plansData");
  // console.log(reversedPlansData, "reversedPlansData");
  // console.log(enterprisePlansData, "enterprisePlansData");
  // console.log(isEnterprise, "isEnterprise");
  // console.log(selectedPlanData, "selectedPlanData");

  return (
    <>
      <div className="pages">
        <PageHeaderSection
          redirectTo={"/accountsetting/billingsubscription/"}
          title="Manage Subscription"
        ></PageHeaderSection>
        <div className="pageOuter">
          <Container fluid>
            {!formDataReady && <Loader />}
            <div className="page manageSubscriptionsPage d-flex h-100">
              <div className="w-100 d-flex flex-column">
                <div className="flex-grow-1 overflow-auto p-32">
                  <div className="d-flex justify-content-between align-items-center">
                    <div>
                      <h1 className="text-gray-100">
                        Find a plan that is right for you
                      </h1>
                      <p className="text-gray-100 font-normal mb-0">
                        According to the information you provided, we have a
                        perfect plan that will fit your business needs.
                      </p>
                    </div>
                    {/* <div className="planToggleSwitch">
                      <div className="switches-container">
                      <input
                        type="radio"
                        id="switchYearly"
                        name="switchPlan"
                        value="Yearly"
                        checked={viewPlan === "year"}
                        onChange={(event) => handlePlan(event.target.value)}
                        // disabled={true}
                      />
                      <input
                        type="radio"
                        id="switchMonthly"
                        name="switchPlan"
                        value="Monthly"
                        checked={viewPlan === "month"}
                        onChange={(event) => handlePlan(event.target.value)}
                      />
                        <label for="switchYearly">Annually</label>
                        <label for="switchMonthly">Monthly</label>
                        <div className="switch-wrapper">
                          <div className="switch">
                            <div>
                              <span className="me-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="12"
                                  viewBox="0 0 15 12"
                                  fill="none"
                                >
                                  <path
                                    d="M12.9503 1.57617L5.92004 9.85453L2.4082 6.93829"
                                    stroke="white"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              Annually
                            </div>
                            <div>
                              <span className="me-1">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="15"
                                  height="12"
                                  viewBox="0 0 15 12"
                                  fill="none"
                                >
                                  <path
                                    d="M12.9503 1.57617L5.92004 9.85453L2.4082 6.93829"
                                    stroke="white"
                                    stroke-width="3"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </span>
                              Monthly
                            </div>
                          </div>
                        </div>
                      </div>
                    </div> */}
                  </div>
                  {/* <div className="row gx-0 selectArea align-items-end"> */}
                  <div className={`row ${planComparison === false ? 'gx-4' : 'gx-2'} selectPlan align-items-end planComparisonModal`}>
                    {planComparison === true && (
                      <div className="col-sm-3">
                        <div className="singlePlanBox left">
                          <h5 className="mt-4">Platform Features</h5>
                          <h6 className="mt-4">Process</h6>
                          <h6 className="mt-4">Sequence</h6>
                          <h6 className="mt-4">Modules</h6>
                          <h6 className="mt-4">Feedback from employees</h6>
                          <h6 className="mt-4">Pay by Invoice</h6>
                          <h6 className="mt-4">Export to CSV</h6>
                          <h6 className="mt-4">Create Content</h6>
                          <h5 className="mt-32 mb-32">Fundamental Features</h5>
                          <h6 className="">GDPR Compliant</h6>
                          <h6 className="mt-4">Bulk import employees</h6>
                          <h6 className="mt-4">Bulk assign content</h6>
                          <h6 className="mt-4">Bulk update status</h6>
                          <h6 className="mt-4">Multilingual</h6>
                          <h6 className="mt-4">Role based access</h6>
                          <h6 className="mt-4">SSO-Google</h6>
                          <h6 className="mt-4">SSO-Microsoft Azure AD</h6>
                          <h6 className="mt-4">Custom Domain</h6>
                          <h6 className="mt-4 lineExtra">
                            Uptime and support request response SLA
                          </h6>
                        </div>
                      </div>
                    )}

                    {reversedPlansData.map((planData, index) => {
                      return (
                        <div className={`${planComparison === false ? 'col-sm-4' : 'col-sm-3'}`}>
                          <div
                            className={`w-100 h-auto rounded-6 singlePlanBox ${
                              selectedPlanId === planData?.priceId
                                ? "current"
                                : ""
                            }`}
                          >
                            <h5 >Platform Features</h5>
                            <h3 className="font-medium text-gray-100">
                              {planData.planName}
                            </h3>
                            <h1 className="text-gray-100 font-bold mt-3 h-40">
                              {(planData.planName === "Business" ||
                                planData.planName === "Startup") &&
                              planData?.price ? (
                                `$${planData?.price}`
                              ) : (
                                <> Request pricing </>
                              )}
                              <span className="text-lg font-normal text-gray-300">
                                {planData.planName === "Business" ||
                                planData.planName === "Startup"
                                  ? ` /${viewPlan}`
                                  : "Custom pricing for your company"}
                              </span>
                            </h1>
                            <span className="text-gray-100 text-xs font-normal opacity-75">
                              {planData.planName === "Business" ||
                              planData.planName === "Startup"
                                ? `(5 Seats Minimum, billed annually)`
                                : "Custom pricing for your company"}
                            </span>

                            {index === 0 && (
                              <>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-32 text-md pb-3 mb-0">
                                  Up to{" "}
                                  <b>{planData?.metaData?.max_users} Active</b>{" "}
                                  Users
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  <b>
                                    {convertToGB(planData?.metaData?.storage)}
                                  </b>
                                  /User Content Storage
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  Custom Domain & Branding-{" "}
                                  <b>
                                    {planData?.metaData?.custom_branding ===
                                    "Yes"
                                      ? "Basic"
                                      : "No"}
                                  </b>
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0 h-60 d-flex align-items-center">
                                  <div>
                                    Create up to{" "}
                                    <b>
                                      {planData?.metaData?.sequence} sequences &{" "}
                                      {planData?.metaData?.modules === "-1"
                                        ? "unlimited"
                                        : planData?.metaData?.modules}{" "}
                                      Modules
                                    </b>
                                  </div>
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  Videos Community Knowledge Base
                                </p>
                                <p className="text-gray-100 font-normal text-md pt-3 pb-1 mb-1">
                                  <b>1 Business Day</b> turnaround on Support
                                  Desk
                                </p>
                              </>
                            )}

                            {index === 1 && (
                              <>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-32 text-md pb-3 mb-0">
                                  Up to{" "}
                                  <b>{planData?.metaData?.max_users} Active</b>{" "}
                                  Users
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  <b>
                                    {convertToGB(
                                      businessPlans?.metaData?.storage
                                    )}
                                  </b>
                                  /User Content Storage
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  Custom Domain & Branding-{" "}
                                  <b>
                                    {planData?.metaData?.custom_branding ===
                                    "Yes"
                                      ? "Pro"
                                      : "No"}
                                  </b>
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0 h-60 d-flex align-items-center">
                                  <div>
                                    Create up to{" "}
                                    <b>
                                      {planData?.metaData?.process === "-1"
                                        ? "Unlimited"
                                        : planData?.metaData?.process}{" "}
                                      Processes,{" "}
                                      {planData?.metaData?.sequence === "-1"
                                        ? "Unlimited"
                                        : planData?.metaData?.sequence}{" "}
                                      Sequences &{" "}
                                      {planData?.metaData?.modules === "-1"
                                        ? "Unlimited"
                                        : planData?.metaData?.modules}{" "}
                                      Modules
                                    </b>
                                  </div>
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  Videos Community Knowledge Base
                                </p>
                                <p className="text-gray-100 font-normal text-md pt-3 pb-1 mb-0">
                                  <b>2 Business Day</b> turnaround on Support
                                  Desk
                                </p>
                              </>
                            )}

                            {subscribePlanId === -1 ? (
                              <button
                                className={`m-0 mt-32 btn-sm w-100 text-center ${
                                  selectedPlanId === planData?.priceId
                                    ? "text-white current-btn btn btn-primary"
                                    : "btn btn-primary"
                                }`}
                                variant="primary"
                                disabled={isEnterprise === true || isTrialingPlan}
                                onClick={() => onhandleGetStarted(planData)}
                              >
                                {selectedPlanId === planData?.priceId
                                  ? "Selected Plan"
                                  : "Select Plan"}
                              </button>
                            ) : index === subscribePlanId ? (
                              <button
                                className={`m-0 mt-32 btn-sm w-100 text-center ${
                                  selectedPlanId === planData?.priceId
                                    ? "text-primary-200 current-btn"
                                    : "btn btn-primary"
                                }`}
                                variant="primary"
                                disabled={
                                  isTrialingPlan === true ? true :
                                  index === subscribePlanId
                                    ? true
                                    : isEnterprise === true
                                    ? true
                                    : false
                                }
                                onClick={() => onhandleGetStarted(planData)}
                              >
                                {selectedPlanId === planData?.priceId
                                  ? "Selected Plan"
                                  : "Current Plan"}
                              </button>
                            ) : index > subscribePlanId ? (
                              <button
                                className={`m-0 mt-32 btn-sm w-100 text-center ${
                                  selectedPlanId === planData?.priceId
                                    ? "text-white current-btn btn btn-primary"
                                    : "btn btn-primary"
                                }`}
                                variant="primary"
                                disabled={isEnterprise === true || isTrialingPlan}
                                onClick={() => onhandleUpgrade(planData)}
                              >
                                {selectedPlanId === planData?.priceId
                                  ? "Selected Plan"
                                  : "Upgrade Plan"}
                              </button>
                            ) : (
                              <button
                                className={`m-0 mt-32 btn-sm w-100 text-center ${
                                  selectedPlanId === planData?.priceId
                                    ? "text-white current-btn btn btn-primary"
                                    : "btn btn-primary"
                                }`}
                                variant="primary"
                                disabled={isEnterprise === true || isTrialingPlan}
                                onClick={() => onhandleDowngrade(planData)}
                              >
                                {selectedPlanId === planData?.priceId
                                  ? "Selected Plan"
                                  : "Downgrade Plan"}
                              </button>
                            )}

                            {planComparison === true && index === 0 && (
                              <div className="text-center">
                                  <h5 className="invisible mt-4">Platform Features</h5>
                                  <h6 className="mt-4">
                                    {planData && planData?.metaData?.process === '-1' ? 'Unlimited' : planData?.metaData?.process === '0' ? <MinusBorder /> : planData?.metaData?.process}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData && planData?.metaData?.sequence === '-1' ? 'Unlimited' : planData?.metaData?.sequence === '0' ? <MinusBorder /> : planData?.metaData?.sequence}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData && planData?.metaData?.modules === '-1' ? 'Unlimited' : planData?.metaData?.modules === '0' ? <MinusBorder /> : planData?.metaData?.modules}
                                  </h6>
                                  <h6 className="mt-4">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.export_csv === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h5 className="mt-32 mb-32 invisible">Fundamental Features</h5>
                                  <h6 className="">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.bulk_import_employees === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.bulk_assign_content === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.bulk_update_status === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h6 className="mt-4">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.custom_branding === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                  </h6>
                                  <h6 className="mt-4 lineExtra">
                                    <MinusBorder />
                                  </h6>
                              </div>
                            )}

                            {planComparison === true && index === 1 && (
                              <div className="text-center">
                                  <h5 className="invisible mt-4">Platform Features</h5>
                                  <h6 className="mt-4">
                                    {planData && planData?.metaData?.process === '-1' ? 'Unlimited' : planData?.metaData?.process === '0' ? <MinusBorder /> : planData?.metaData?.process}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData && planData?.metaData?.sequence === '-1' ? 'Unlimited' : planData?.metaData?.sequence === '0' ? <MinusBorder /> : planData?.metaData?.sequence}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData && planData?.metaData?.modules === '-1' ? 'Unlimited' : planData?.metaData?.modules === '0' ? <MinusBorder /> : planData?.metaData?.modules}
                                  </h6>
                                  <h6 className="mt-4">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.export_csv === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h5 className="mt-32 mb-32 invisible">Fundamental Features</h5>
                                  <h6 className="">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.bulk_import_employees === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.bulk_assign_content === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.bulk_update_status === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                                  </h6>
                                  <h6 className="mt-4">
                                    <MinusBorder />
                                  </h6>
                                  <h6 className="mt-4">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    <TickNormal />
                                  </h6>
                                  <h6 className="mt-4">
                                    {planData?.metaData?.custom_branding === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                                  </h6>
                                  <h6 className="mt-4 lineExtra">
                                    <MinusBorder />
                                  </h6>
                              </div>
                            )}
                          </div>
                        </div>
                      );
                    })}

                    {reversedPlansData?.length > 0 && (
                      <>
                        {enterprisePlansData.map((planData, index) => {
                          return (
                            <div className={`${planComparison === false ? 'col-sm-4' : 'col-sm-3'}`}>
                              <div className="w-100 h-auto rounded-6 singlePlanBox border-0">
                                <h3 className="font-medium text-gray-100">
                                  {planData.planName}
                                </h3>
                                <h3 class="font-medium text-gray-100 invisible">Business</h3>
                                <h4 className="text-gray-100 font-bold h-40 d-flex align-items-center mt-3">
                                  Request for price
                                </h4>
                                <span class="text-gray-100 text-xs font-normal opacity-75 invisible">(5 Seats Minimum, billed annually)</span>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-32 text-md pb-3 mb-0">
                                  {/* {planData?.metaData?.max_users} */}
                                  Unlimited Users
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  <b>
                                    {/* {convertToGB(planData?.metaData?.storage)} */}
                                    Unlimited
                                  </b>{" "}
                                  Content Storage
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  Custom Domain & Branding-
                                  <b>
                                    {planData?.metaData?.custom_branding ===
                                    "Yes"
                                      ? "Complete"
                                      : "No"}
                                  </b>
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0 h-60 d-flex align-items-center">
                                  <div>
                                    Create up to{" "}
                                    <b>
                                      {planData?.metaData?.process === "-1"
                                        ? "Unlimited"
                                        : planData?.metaData?.process}{" "}
                                      Processes,{" "}
                                      {planData?.metaData?.sequence === "-1"
                                        ? "Unlimited"
                                        : planData?.metaData?.sequence}{" "}
                                      Sequences &{" "}
                                      {planData?.metaData?.modules === "-1"
                                        ? "Unlimited"
                                        : planData?.metaData?.modules}{" "}
                                      Modules
                                    </b>
                                  </div>
                                </p>
                                <p className="text-gray-100 font-normal border-bottom border-gray-400 text-md py-3 mb-0">
                                  Videos Community Knowledge Base
                                </p>
                                <p className="text-gray-100 font-normal text-md pt-3 pb-1 mb-0">
                                  <b>2 Business Day</b> turnaround on Support
                                  Desk
                                </p>

                                <Button
                                  className="m-0 mt-32 btn-sm w-100 text-center"
                                  variant="primary"
                                  disabled={true}
                                >
                                  Request for price
                                </Button>

                                {planComparison === true && (
                                  <div className="text-center">
                                    <h5 className="invisible mt-4">Platform Features</h5>
                                    <h6 className="mt-4">{planData?.metaData?.process === "-1" ? "Unlimited" : planData?.metaData?.process}</h6>
                                    <h6 className="mt-4">{planData?.metaData?.sequence === "-1" ? "Unlimited" : planData?.metaData?.sequence}</h6>
                                    <h6 className="mt-4">{planData?.metaData?.modules === "-1" ? "Unlimited" : planData?.metaData?.modules}</h6>
                                    <h6 className="mt-4">
                                      <TickNormal />
                                    </h6>
                                    <h6 className="mt-4">
                                      <TickNormal />
                                    </h6>
                                    <h6 className="mt-4">
                                      {planData?.metaData?.export_csv === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                    </h6>
                                    <h6 className="mt-4">
                                      <TickNormal />
                                    </h6>
                                    <h5 className="mt-32 mb-32 invisible">Fundamental Features</h5>
                                    <h6 className="">
                                      <TickNormal />
                                    </h6>
                                    <h6 className="mt-4">
                                      {planData?.metaData?.bulk_import_employees === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                    </h6>
                                    <h6 className="mt-4">
                                      {planData?.metaData?.bulk_assign_content === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                    </h6>
                                    <h6 className="mt-4">
                                      {planData?.metaData?.bulk_update_status === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                    </h6>
                                    <h6 className="mt-4">
                                      <TickNormal />
                                    </h6>
                                    <h6 className="mt-4">
                                      <TickNormal />
                                    </h6>
                                    <h6 className="mt-4">
                                      <TickNormal />
                                    </h6>
                                    <h6 className="mt-4">
                                      <TickNormal />
                                    </h6>
                                    <h6 className="mt-4">
                                      {planData?.metaData?.custom_branding === "No" ? (<MinusBorder />) : (<TickNormal />)}
                                    </h6>
                                    <h6 className="mt-4 lineExtra h-40 d-flex align-items-center justify-content-center">
                                      <TickNormal />
                                    </h6>
                                  </div>
                                )}

                              </div>
                            </div>
                          );
                        })}
                      </>
                    )}
                  </div>
                  <div className="d-flex justify-content-center align-items-center mt-3">
                    {planComparison === false ? (
                      <Button variant="white" className="m-0" onClick={() => setPlanComparison(true)}>
                        Show Detailed Comparison
                      </Button>
                    ) : (
                      <Button variant="white" className="m-0" onClick={() => setPlanComparison(false)}>
                        Hide Detailed Comparison
                      </Button>
                    )}
                  </div>
                  {isMakePayment && !isDowngrade && (
                    <>
                      {cardListData && cardListData?.length > 0 && (
                        <div className="border border-gray-500 rounded-6 p-4 mt-32">
                          <h6 className="text-gray-100 font-bold mb-12">
                            Payment Method
                          </h6>
                          <div className="bg-pastel-900 rounded-6 py-20 px-2 mt-3 d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-start align-items-center">
                              {/* <img src={visaCard} alt="" className="ms-1 pe-1" /> */}
                              {cardListData[0]?.card?.brand === 1 && (
                                <img src={visaCard} className="ms-1" />
                              )}
                              {cardListData[0]?.card?.brand ===
                                "Mastercard" && (
                                <img
                                  src={MasterCard}
                                  alt="CardMaster"
                                  className="ms-1"
                                />
                              )}
                              {cardListData[0]?.card?.brand === "visa" && (
                                <img src={visaCard} className="ms-1" />
                              )}

                              <div className="h5 font-bold text-gray-100 mx-3">
                                Card Ending **-{cardListData[0]?.card?.last4}
                              </div>
                              <div className="text-lg text-gray-300 font-normal ps-4">
                                Expires{" "}
                                <span className="text-gray-100 font-medium">
                                  {`${cardListData[0]?.card?.expMonth}/${cardListData[0]?.card?.expYear}`}
                                </span>
                              </div>
                            </div>
                            <button
                              className="text-primary-200 d-flex align-items-center gap-2 text-lg font-bold me-3"
                              onClick={() => handleEdit(cardListData[0]?.id)}
                            >
                              <IconEdit3 />
                              <span>Edit</span>
                            </button>
                          </div>
                        </div>
                      )}
                      <div className="border border-gray-500 rounded-6 p-4 mt-3 d-flex justify-content-between align-items-center">
                        <h4 className="text-gray-100 font-bold">
                          Pro-rated cost to upgrade
                        </h4>
                        <h3 className="text-gray-100 font-bold">
                          ${(selectedPlanData?.price-proratedAmount).toFixed(2)}
                          {/* ${proratedAmount} */}
                        </h3>
                      </div>
                    </>
                  )}
                </div>
                <div className="d-flex gap-20 align-items-center btnFooter pe-4">
                  <Button
                    className="m-0"
                    variant="secondary"
                    disabled={!isMakePayment}
                    onClick={() => makePayment()}
                  >
                    Make Payment
                  </Button>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        className="come-from-modal right editPaymentcard"
        centered
        show={editPayment}
        onHide={() => setEditPayment(false)}
      >
        <EditPaymentModal
          handleModal={setEditPayment}
          editPayment={editPayment}
          cardId={cardId}
          setCardId={setCardId}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={makeUpgrade}
        onHide={() => setMakeUpgrade(false)}
      >
        {upgrade && (
          <UpgradeModal
            handleModal={setMakeUpgrade}
            selectedPlanData={selectedPlanData}
            confirm={handleUpgradePlan}
          />
        )}
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={makeDowngrade}
        onHide={() => setMakeDowngrade(false)}
      >
        {downgrade && (
          <DowngradeModal
            //handleModal={setDowngrade}
            handleModal={setMakeDowngrade}
            confirm={handleDowngradePlan}
            request={donwgradePlanReason}
            setRequest={setDonwgradePlanReason}
          />
        )}
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={downgrade2}
        onHide={() => setDowngrade2(false)}
      >
        <Downgrade2Modal
          // handleModal={setDowngrade2}
          setLessFeaturesPlan={setDowngrade2}
          confirm={isCouponUsed === false ? handleLessFeaturesPlan : handleComfirmDowngrade}
          // confirm={handleComfirmDowngrade}
          selectedPlanData={selectedPlanData}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={samePlan}
        onHide={() => setSamePlan(false)}
      >
        <SamePlanModal
          // handleModal={setSamePlan}
          setDiscountPlan={handleComfirmRetainSamePlan}
          confirm={handleComfirmDowngrade}
        />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={retainSamePlan}
        onHide={() => setRetainSamePlan(false)}
      >
        <RetainSamePlanModal
          setRetainSamePlan={setRetainSamePlan}
        />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={downgradeUnderProcess}
        onHide={() => setDowngradeUnderProcess(false)}
      >
        <DowngradeUnderProcessModal
          setDowngradeUnderProcess={setDowngradeUnderProcess}
        />
      </Modal>

      <Modal className="modalDialog downgradeNotPossibleModal" size="lg" centered show={downgradNotification}>
        <Modal.Header closeButton onClick={() => setDowngradNotification(false)}></Modal.Header>
        <Modal.Body className="">
          <h3>
            Downgrade Not Possible
          </h3>
          <p className="text-gray-300 mb-0 mt-2">
            We noticed that you are attempting to downgrade to the Startup plan. However, your current usage exceeds the limits.
            {/* allowed for the Startup Plan */}
          </p>
          <p className="text-gray-300 mb-0 mt-3 pb-3">
            To proceed with the downgrade, please ensure that:
          </p>
          {userNotification && (
            <p className="d-flex justify-content-start align-item-center gap-2 mt-3 mb-0">
              <TickFill />
              <span>The number of <b>Users(<span className="text-primary-100">{userUsage?.usersCount}</span><span className="text-lg font-normal">/{parseInt(metaData?.included_users)}</span>)</b> is reduced to meet limit.</span>
            </p>
          )}
          {moduleNotification && (
            <p className="d-flex justify-content-start align-item-center gap-2 mt-3 mb-0">
              <TickFill />
              <span>The number of <b>Modules(<span className="text-primary-100">{userUsage?.modulesCount}</span><span className="text-lg font-normal">/{parseInt(metaData?.modules)}</span>)</b> is reduced to meet limit.</span>
            </p>
          )}
          {processNotification && (
            <p className="d-flex justify-content-start align-item-center gap-2 mt-3 mb-0">
              <TickFill />
              <span>The number of <b>Processes(<span className="text-primary-100">{userUsage?.processCount}</span><span className="text-lg font-normal">/{parseInt(metaData?.process)}</span>)</b> is reduced to meet limit.</span>
            </p>
          )}
          {sequenceNotification && (
            <p className="d-flex justify-content-start align-item-center gap-2 mt-3 mb-0">
              <TickFill />
              <span>The number of <b>Sequences(<span className="text-primary-100">{userUsage?.sequenceCount}</span><span className="text-lg font-normal">/{parseInt(metaData?.sequence)}</span>)</b> is reduced to meet limit.</span>
            </p>
          )}
          {storageNotification && (
            <p className="d-flex justify-content-start align-item-center gap-2 mt-3 mb-0">
              <TickFill />
              <span>Your <b>Storage(<span className="text-primary-100">{convertKBToGBNumeric(parseInt(storageData?.usedStorage))}GB</span><span className="text-lg font-normal">/{convertKBToGBNumeric(basePlanStorage)}GB</span>)</b> usage is within allowed capacity.</span>
            </p>
          )}
          {/* <p className="text-lg text-gray-300 opacity-75 mt-2 pt-1 mb-1">Please adjust your usage accordingly and try again. If you need assistance, feel free to contact our support team.</p> */}
          <div className="text-primary-200 font-bold bg-gray-500 rounded-6 mt-4 py-2 px-3 mb-2">
            <p className="my-1">Your plan will be downgraded in the next billing cycle.</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end btnFooter">
          <Button
            variant="primary"
            size="large"
            onClick={() => setDowngradNotification(false)}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Modal
        className="come-from-modal right"
        centered
        show={planComparison}
        onHide={() => setPlanComparison(false)}
      >
        <PlanComparisonModal
          handleModal={setPlanComparison}
          reversedPlansData={reversedPlansData}
          enterprisePlansData={enterprisePlansData}
          // editPayment={planComparison}          
        />
      </Modal> */}
      <Modal
      className="modalDialog downgradePlanModal"
        centered
        size="lg"
        show={downgradePlan}
        onHide={() => setDowngradePlan(false)}
      >
        <DowngradePlanModal
          handleModal={setDowngradePlan}        
        />
      </Modal>
    </>
  );
};

export default ManageSubscription;
