import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import { Link, useLocation, useParams } from "react-router-dom";
import Header from "./Header";
import FormPreview from "./FormPreview";
import PagePreview from "./PagePreview";
import { useDispatch } from "react-redux";
import { getUserProfileAsync, getEmployeeModulePreviewAsync } from "../../../reducers/user-reducers";

const Preview = ({ actionReport, setContent }) => {
  const location = useLocation()
  const target = useRef(null);
  const playerRef = useRef();
  const [contentData, setContentData] = useState([]);
  const [previewData, setPreviewData] = useState([]);
  const [userProfiles, setUserProfiles] = useState(null);
  const [activeKey, setActiveKey] = useState(0);
  const [isTextareaFilled, setIsTextareaFilled] = useState(false);
  const [assignmentIds, setAssignmentIds] = useState();
  const [isValidDate, setIsValidDate] = useState(false);
  const [formValues, setFormValues] = useState([]);
  const [isFileUploaded, setIsFileUploaded] = useState(false);
  const [isValidTime, setIsValidTime] = useState(true);
  const [titles, setTitles] = useState("");
  const [assignmentStatus, setAssignmentStatus] = useState();
  const dispatch = useDispatch();

  // Get ID from URL
  const params = useParams();
  const { id, assignmentId, assignmentSegId, previewType, contentType } = params;
  // console.log(assignmentSegId, "assignmentSegId");
  const getContent = async () => {
    if (assignmentId && id) {
      const reqBody = {
        assignmentId: assignmentId,
        moduleId: id,
        assignmentSegId: assignmentSegId,
      };
    
    const previewResponse = await 
      dispatch(getEmployeeModulePreviewAsync(reqBody))
    
    console.log(previewResponse, "previewResponse")
    if (previewResponse.payload) {
      setAssignmentIds(previewResponse.payload.assignmentId);
      setTitles(previewResponse.payload.moduleTitle)
      setContentData(previewResponse.payload.assignmentDetails);
      setAssignmentStatus(previewResponse.payload.assignmentSegStatus);
      // const filteredData = previewResponse.payload.assignmentDetails;

      // const formData = filteredData.filter((item) => item.form);
      // const pageData = filteredData.filter((item) => item.page);

      // // setPreviewData(previewResponse.payload.assignmentDetails)
      // const combinedData = [...pageData, ...formData];
      // setContentData(combinedData)
      // setPreviewData(combinedData)
    }
  }

  }

  const ImgRef = useRef();
  const [current, setCurrent] = useState(0);
  const [mapPageForm, setMapPageForm] = useState();
  const length = contentData.length;

  function nextSlide() {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  function prevSlide() {
    setCurrent(current === 0 ? length - 1 : current - 1);
  }
  // console.log(current, 'current88')
  useEffect(() => {
    getContent();
    getUserProfile();
  }, []);
  

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfiles(res.payload);
      }
    });
  };

  const handleNextClick = () => {
    // debugger;
    const nextIndex = (activeKey + 1) % contentData.length;
    setActiveKey(nextIndex);
  };
  
  const findFirstIncompleteQuestionIndex = () => {
    for (let i = 0; i < contentData.length; i++) {
      const correspondingFormValue = contentData.find((item) => item.id === contentData[i].id);
      if (correspondingFormValue && correspondingFormValue.status !== 0) {
        return i;
      }
    }
  };
  const firstIncompleteIndex = findFirstIncompleteQuestionIndex();
  useEffect(() => {
    // console.log(firstIncompleteIndex,'firstIncompleteIndex');
    if (firstIncompleteIndex) {
      setCurrent(firstIncompleteIndex);
    }
    // else {
    //   setCurrent(0);
    // }
  }, [firstIncompleteIndex]);
  
  return (
    <div className="pages">
      <Header nextSlide={nextSlide} 
      prevSlide={prevSlide} 
      contentLenght={length} 
      currentState={current} 
      contentData={contentData} 
      userProfiles={userProfiles} 
      handleNextClick={handleNextClick} 
      formValues={formValues}
      getContent={getContent}
      isFileUploaded={isFileUploaded}
      isValidDate={isValidDate}
      isValidTime={isValidTime}
      moduleId={id}
      assignmentId={assignmentId}
      titles={titles}
      />
      <div className="position-relative" style={{ top: "-1px" }}>
        <div
          className="progress tableProgress bg-base-100 "
          style={{ height: "2px" }}
          ref={target}
        // onClick={handleSeekMouseUp}
        >
          <div
            className="progress-bar bg-primary-200"
            role="progressbar"
            style={{ width: "21%" }}
          ></div>
        </div>
      </div>

      <div className="pageOuter pb-0">
        <Row className="g-0 h-100">
          <Col className="col-lg-12 bg-base-100">
            <div>
              {contentData && contentData.length > 0 && contentData.map((slide, index) => {
                if (slide.form?.questions) {
                  return index === current && <FormPreview actionReport={actionReport} data={slide.form} setContent={setContent} allData={contentData} userProfiles={userProfiles} currents={current} setActiveKey={setActiveKey} activeKey={activeKey} setIsFileUploaded={setIsFileUploaded} formValues={formValues} setFormValues={setFormValues} setIsValidDate={setIsValidDate} setIsValidTime={setIsValidTime} assignmentStatus={assignmentStatus}/>
                } else {
                  return index === current && <PagePreview actionReport={actionReport} data={slide.page} setContent={setContent} allData={contentData} userProfiles={userProfiles} currents={current} setActiveKey={setActiveKey} activeKey={activeKey} />
                }
              })}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Preview;
