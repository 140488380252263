import React, { useState, useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import {
  IconDownload,
  IconLink,
  IconListDoc,
  IconPhoto,
  IconAudio,
  IconVideo,
  IconDocument,
} from "../../../icons/Icons";
import Attachments from "./Attachments";
import Search from "../../../components/Search/Search";
import {
  getAllEmployeeFormsAsync,
  // getSubmittedQuestionAnswersAsync,
} from "../../../reducers/user-reducers";
import { useDispatch, useSelector } from "react-redux";
import { formatDate, getFormatDate, getFileExtension, getRemainingStringFileName } from "../../../common";
import NoData from "../../../components/EmptyState/NoData";
import DownloadLink from "../../Authoring/ContentPreview/DownloadLink";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";
import Loader from "../../../components/Loader/Loader";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const FormDocumentModalForm = ({ documentUserId }) => {
  const dispatch = useDispatch();
  const [activeKey, setActiveKey] = useState(0);
  const [employeeAllForms, setEmployeeAllForms] = useState([]);
  // const [submittedQuestionAnswers, setSubmittedQuestionAnswers] = useState(null);
  const [employeeId, setEmployeeId] = useState(documentUserId);
  const [searchString, setSearchString] = useState("");
  const [formId, setFormId] = useState(null);
  const [allDocuments, setAllDocuments] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const iconMap = {
    IconPhoto: IconPhoto,
    IconAudio: IconAudio,
    IconVideo: IconVideo,
    IconDocument: IconDocument,
  };

  const onSearchStringChange = (value) => {
    setSearchString(value);
  };

  useEffect(() => {
    setEmployeeId(documentUserId);
    debouncedEmployeeAllForms();
  }, [documentUserId, searchString]);

  const getEmployeeAllForms = () => {
    setIsLoading(true);
    const reqBody = {
      searchString: searchString,
      employeeId: employeeId,
    };
    dispatch(getAllEmployeeFormsAsync(reqBody)).then((res) => {
      // console.log(res.payload,'res.payloadres.payloadres.payload');
      if (res?.payload) {
        // Assuming your data is stored in the variable 'data'
        const sortedData = [...res?.payload].sort(
          (a, b) => new Date(b.answerDateTime) - new Date(a.answerDateTime)
        );
        setEmployeeAllForms(sortedData);
        setIsLoading(false);
      }
    });
  };

  const debouncedEmployeeAllForms = useDebounce(getEmployeeAllForms, 1000);

  useEffect(() => {
    setAllDocuments(
      employeeAllForms[activeKey]?.documents?.length > 0
        ? employeeAllForms[activeKey]?.documents
        : []
    );
  }, [employeeAllForms, activeKey]);

  // console.log(employeeId, "employeeId");
  console.log(employeeAllForms, "employeeAllForms");
  // console.log(allDocuments, "allDocuments");

  const displayedDates = [];

  return (
    <div className="d-flex flex-grow-1 flex-column bg-gray-600 overflow-auto h-100">
      {isLoading ? (
        <div className="formLoader">
          <Loader type="activity" />
        </div>
      ) : (
        <Row className="mx-0 w-100 flex-grow-1 overflow-auto h-100">
          <Col
            md={4}
            className="px-0 bg-base-100 overflow-auto overflow-auto h-100"
          >
            <div className="ps-32">
              <div className="SeacrhDownlaod d-flex justify-content-between py-4">
                <div className="">
                  <Search
                    placeHolder="Search"
                    searchString={searchString}
                    onBlur={onSearchStringChange}
                  />
                </div>
              </div>

              {employeeAllForms.map((form, index) => {
                const currentDate =
                  form?.answerDateTime !== null &&
                  formatDate(form?.answerDateTime);

                return (
                  <div className="mb-4" key={index}>
                    {form?.answerDateTime !== null &&
                      !displayedDates.includes(currentDate) &&
                      (() => {
                        displayedDates.push(currentDate);
                        return (
                          <h6 className="text-gray-300 font-normal">
                            {currentDate}
                          </h6>
                        );
                      })()}
                    <div className="formActionList">
                      <button
                        className={`${activeKey === index ? "active" : ""}`}
                        onClick={() => setActiveKey(index)}
                      >
                        <IconListDoc />
                        <div>
                          <div className="ps-3 text-start formActionIn">
                            <h6>{form?.formName}</h6>
                            <span className="text-md d-block">
                              {form?.moduleName}
                            </span>
                          </div>
                          {form?.totalDocument > 0 && <IconLink />}
                        </div>
                      </button>
                    </div>
                  </div>
                );
              })}
            </div>
          </Col>
          <Col
            md={8}
            className="px-0 overflow-auto d-flex flex-column position-relative overflow-auto h-100"
          >
            <div className="formContentList h6">
              {employeeAllForms.map((quesAnswer, index) => (
                <div
                  className={`formContentListItm ${
                    activeKey === index ? "active" : ""
                  }`}
                >
                  <ol>
                    {quesAnswer?.questionAnswer.map((ques, qindex) => {
                      const documentList =
                        ques?.type === "FileUpload"
                          ? ques?.answer.split(",")
                          : [];

                      return (
                        <li>
                          <label>{ques?.question}</label>
                          {ques?.type !== "FileUpload" && (
                            <div>
                              {/* {ques?.type === "Date" ? getFormatDate(ques?.answer) : ques?.answer} */}
                                {/* <DateTimeConverter utcDateTime={ques?.answer} />  */}
                              {ques?.type === "Date" ? (
                                getFormatDate(ques?.answer)
                               
                              ) : (
                                ques?.answer
                              )}
                            </div>
                          )}

                          <div>
                            {ques?.type === "FileUpload" && (
                              <>
                                {documentList.map((doc, docIndex) => {
                                  const fileExtension = getFileExtension(doc);
                                  const fileName = getRemainingStringFileName(doc);
                                  const IconComponent =
                                    iconMap[fileExtension] || IconDocument;
                                  return (
                                    <div
                                      className="d-flex gap-5 ParentdownloadFile"
                                      key={`${index}-${docIndex}`}
                                    >
                                      <div className="">
                                        <button className="downloadFile">
                                          {/*<IconPhoto/>*/}
                                          {/*<IconAudio />*/}
                                          {/*<IconVideo/>*/}
                                          <IconComponent />
                                          {/* <span>{doc}</span> */}
                                          <span>{fileName}</span>
                                          <DownloadLink url={doc} />
                                          {/* <span>{ques?.answer}</span> */}
                                          {/* <IconDownload className='iconDownload'/> */}
                                          {/* <DownloadLink url={ques?.answer} /> */}
                                        </button>
                                      </div>
                                    </div>
                                  );
                                })}
                              </>
                            )}
                          </div>
                        </li>
                      );
                    })}
                  </ol>
                </div>
              ))}

              {employeeAllForms?.length === 0 && (
                <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                  <NoData
                    msgicon="formModal"
                    message="No forms to view, return later for<br/> updated information."
                  />
                </div>
              )}
            </div>
            {allDocuments?.length > 0 && (
              <Attachments allDocuments={allDocuments} />
            )}
          </Col>
        </Row>
      )}
    </div>
  );
};

export default FormDocumentModalForm;
