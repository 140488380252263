import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import { IconCalendar } from "../../icons/Icons";
import CheckBoxMd from "../../components/CheckBox/CheckBoxMd";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select'
import TagsInput from "../../components/TagsInput/TagsInput";

const AddUserModal = ({ handleModal }) => {

    const [startDate, setStartDate] = useState(new Date());
    const optionsTeam = [
        { value: 'Human Resources', label: 'Human Resources' },
        { value: 'Developers', label: 'Developers' },
        { value: 'Content Writers', label: 'Content Writers' }
    ]

    const optionsTimezone = [
        { value: '1', label: 'Toronto, Canada 06:20 PM' },
        { value: '2', label: 'Toronto, Canad 06:20 PM' },
        { value: '3', label: 'Toronto, Canada 06:20 PM' }
    ]

    const optionsRole = [
        { value: '1', label: 'Admin' },
        { value: '2', label: 'Employee' },
        { value: '3', label: 'Employer' }
    ]
    return (

        <>
            <Modal.Header closeButton>
                <div className='w-100'>
                    <Modal.Title>Add a User</Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className=''>

                <div className="row">

                    <div className="col-12 col-md-6 col-lg-6">
                        <div className="form-group mySelect mb-32">
                            <label>Role</label>

                            <Select isSearchable={false} className="select" options={optionsRole} defaultValue={[optionsRole[0]]} />
                        </div>

                        <div className="input-group myInput mb-32">
                            <label>  Employee name</label>
                            <input type="text" className="form-control" placeholder="Enter employee name" />
                        </div>

                        <div className="input-group myInput mb-32">
                            <label>Company email address</label>
                            <input type="email" className="form-control" placeholder="Enter email address" />
                        </div>




                    </div>

                    <div className="col-12 col-md-6 col-lg-6">




                        <p className='font-bold mb-1'>Login Credentials</p>
                        <p className="h6 fw-normal text-gray-300 mb-4">Set credentials manually</p>


                        <div className="input-group myInput mb-32">
                            <label>Username</label>
                            <input type="text" className="form-control" placeholder="Enter user name" />
                        </div>

                        <div className="input-group myInput mb-12">
                            <label>Password</label>
                            <input type="password" className="form-control" placeholder="Enter password" />
                        </div>
                        <p className='h6 text-gray-200 mb-32 mwidth-440'>
                            <CheckBoxMd text="Change password on first login" />
                        </p>






                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-start btnFooter">
                <Button variant="secondary" onClick={() => handleModal(false)}>
                    Invite User
                </Button>
                <Button variant="white" >
                    Create &amp; Invite Another
                </Button>
            </Modal.Footer>
        </>
    );
}

export default AddUserModal;

