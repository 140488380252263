import React, { useState, useRef, useEffect } from 'react';
import { Row, Col, Container, Button } from "react-bootstrap";
import { IconPPT, IconDownload, IconReportIssue } from "../../../icons/Icons";


import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import { generatePresignedUrl, bulkGeneratePresignedUrl } from "../../../api/common-store-api";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getGUID } from "../../../common";

const Audio = ({ actionReport, contentUrl, slideData, setContent }) => {
	const [show, setShow] = useState(false);
	const audioRef = useRef(null);
	const userRole = useSelector((state) => state.user.currentUser?.roles[0].name);
	const [seconds, setSeconds] = useState(89.374935);
	const [trackIndex, setTrackIndex] = useState(1);
	const [contentUrlData, setContentUrlData] = useState(null);
	const location = useLocation()
	const arrayDownloadableFiles = slideData?.downloadableFile
	const [downloadableFileURLs, setDownloadableFileURLs] = useState([])

	const getPreviewUrl = async () => {
		const getGuid = getGUID(contentUrl); 
		let previewUrl = await generatePresignedUrl(getGuid);
		if (previewUrl?.data) {
			setContentUrlData(previewUrl.data);
		}
	};

	const getBulkGeneratePresignedUrl = async () => {
		if (arrayDownloadableFiles !== undefined &&
			arrayDownloadableFiles !== null &&
			arrayDownloadableFiles.length > 0) {
			let responseURLsDownloadable = await bulkGeneratePresignedUrl(arrayDownloadableFiles.map((value) => value.filePath));
			console.log('responseURLsDownloadable 34 = ', responseURLsDownloadable)
			if (responseURLsDownloadable?.data) {
				setDownloadableFileURLs(responseURLsDownloadable.data)
			}
		}
	};

	useEffect(() => {
		getPreviewUrl();
		setTimeout(() => {
			setTrackIndex(0);
		}, 1000);
		getBulkGeneratePresignedUrl()
	}, []);

	const handleClickPrevious = () => {
		setTrackIndex((currentTrack) =>
			currentTrack === 0 ? musicTracks.length - 1 : currentTrack - 1
		);
	};

	const handleClickNext = () => {
		setTrackIndex((currentTrack) =>
			currentTrack < musicTracks.length - 1 ? currentTrack + 1 : 0
		);
	};

	// const AudioPlayer = window.ReactH5AudioPlayer.default;
	const musicTracks = [
		{
			name: "Under standing the structure",
			src: "https://www.bensound.com/bensound-music/bensound-creativeminds.mp3"
		},
		{
			name: "Acoustic Breeze",
			src: "https://www.bensound.com/bensound-music/bensound-acousticbreeze.mp3"
		},
		{
			name: "Sunny",
			src: "https://www.bensound.com/bensound-music/bensound-sunny.mp3"
		},
		{
			name: "Tenderness",
			src: "https://www.bensound.com/bensound-music/bensound-tenderness.mp3"
		},
		{
			name: "Once Again",
			src: "https://www.bensound.com/bensound-music/bensound-onceagain.mp3"
		},
		{
			name: "Sweet",
			src: "https://www.bensound.com/bensound-music/bensound-sweet.mp3"
		},
		{
			name: "Love",
			src: "https://www.bensound.com/bensound-music/bensound-love.mp3"
		},
		{
			name: "Piano Moment",
			src: "https://www.bensound.com/bensound-music/bensound-pianomoment.mp3"
		},
		{
			name: "E.R.F",
			src: "https://www.bensound.com/bensound-music/bensound-erf.mp3"
		},
		{
			name: "Dreams",
			src: "https://www.bensound.com/bensound-music/bensound-dreams.mp3"
		},
		{
			name: "A Day To Remember",
			src:
				"https://www.bensound.com/royalty-free-music/track/a-day-to-remember-wedding-music"
		},
		{
			name: "Adventure",
			src: "https://www.bensound.com/bensound-music/bensound-adventure.mp3"
		},
		{
			name: "Photo Album",
			src: "https://www.bensound.com/bensound-music/bensound-photoalbum.mp3"
		},
		{
			name: "November",
			src: "https://www.bensound.com/bensound-music/bensound-november.mp3"
		}
	];


	return (
    <div className="pages">
      {/* <Header />
            <div className='position-relative' style={{ top: '-1px' }}>
                <div className="progress tableProgress bg-base-100 " style={{ height: '2px' }} ref={audioRef} onMouseEnter={() => setShow(!show)}>
                    <div className="progress-bar bg-primary-200" role="progressbar" style={{ width: '21%' }}></div>
                </div>
            </div> */}
      <div className="pageOuter pb-0">
        <Row className="g-0 h-100">
          <Col className="col-lg-12 bg-base-100">
            <div className="videoPreview audioPlayer bg-gray-600 rounded-6 borderAll boxShadow2 overflow-hidden">
              <AudioPlayer
                ref={audioRef}
                // src={musicTracks[trackIndex].src}
                src={contentUrlData}
                onClickPrevious={handleClickPrevious}
                onClickNext={handleClickNext}
                onEnded={handleClickNext}
                progressUpdateInterval={20}
              />

              <div className="titleTrack">
                {" "}
                {` ${musicTracks[trackIndex].name}`}
              </div>
              {/* <div className="p-5">
                <h5 className="mb-12">Transcript</h5>
                <p className="h6 pb-5 mb-5">
                  Expensive week/month/quarter: Within the total expenses, the
                  user gets a detailed tour of their expenses. The chart shows a
                  visual representation Expensive week/month/quarter: Within the
                  total expenses, the user gets a detailed tour of their
                  expenses. The chart{" "}
                  <font>
                    shows a visual week/month/quarter: Within the total
                    expenses, the user gets a detailed tour of their expenses.
                    The chart shows a visual representationr.
                  </font>
                </p>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
}

export default Audio;
