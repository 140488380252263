import React from "react";
import { useField } from "formik";

const TextArea = ({ label, isRequired, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label htmlFor={props.id || props.name}>
        {label} {isRequired === true && <span style={{ color: "red" }}>*</span>}
      </label>
      <textarea className="text-area" {...field} {...props} />
      {meta.touched && meta.error ? (
        <div className="error errorValidation w-100" style={{ color: "red" }}>{meta.error}</div>
      ) : null}
    </>
  );
};

export default TextArea;
