import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {
  Row,
  Col,
  Container,
  Modal,
  Tooltip,
  Button,
  OverlayTrigger,
} from "react-bootstrap";
import {
  IconStarLg,
  IconStarGray,
  IconPlanUpgrade,
} from "../../../icons/Icons";
import Successful from "../../../assets/images/successful.svg";
import inVoiceEmptyPrimary from "../../../assets/images/inVoiceEmptyPrimary.svg";
import planBg from "../../../assets/images/plan_bg.png";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionAsync, addSubscriptionAsync } from "../../../reducers/subscription-reducers";
import { formatDate, getFormatDate, formatTime, getItemPath } from "../../../common";
import moment from "moment";
import { useHistory, useLocation } from "react-router-dom";

const RenewalPlanSuccess = () => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const subscriptionData = useSelector((state) => state.subscription);
    const [paymentStatus, setPaymentStatus] = useState(true);
    const [responcePayment, setResponcePayment] = useState({});

    const [downloadInvoice, setDownloadInvoice] = useState("");
    const [cardHolderName, setCardHolderName] = useState("");
    const [amountPaid, setAmountPaid] = useState("");
    const [renewalDate, setRenewalDate] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [basePlan, setBasePlan] = useState({});

    useEffect(() => {
      dispatch(getSubscriptionAsync());
    }, []);

    useEffect(() => {
      if (subscriptionData?.subscriptionData?.latestInvoice && subscriptionData?.subscriptionData?.subscriptions?.length > 0) {
        setDownloadInvoice(subscriptionData?.subscriptionData?.latestInvoice?.hostedInvoiceUrl);
        setCardHolderName(subscriptionData?.subscriptionData?.latestInvoice?.customerName);
        setAmountPaid(subscriptionData?.subscriptionData?.latestInvoice?.amountPaid);
        setRenewalDate(subscriptionData?.subscriptionData?.subscriptions[0].currentPeriodEnd);
        setCreatedDate(subscriptionData?.subscriptionData?.subscriptions[0].created);
        const basePlan = subscriptionData?.subscriptionData?.plans.filter(
          (plan) => plan.statementDescriptor === "base_plan"
        );
        setBasePlan(basePlan && basePlan?.length > 0 ? basePlan[0] : {});
      }
    }, [subscriptionData]);

    const goBack = () =>{
      dispatch(getSubscriptionAsync());
      setTimeout(() => {
        navigate.push("/");
      }, 1000);
    }
  
  console.log(subscriptionData,'subscriptionData');
  
  return (
    <div className="pages bg-gray-600">
      <PageHeader title="Account Settings" />
      {paymentStatus ? (
        <div className="h-100 w-100 d-flex justify-content-center paymentSuccessful">
          <div>
            <div className="text-center">
              <img src={Successful} alt="" />
              <h1 className="pt-20">Payment Successful</h1>
              <p className="text-gray-300 mt-2 mb-0">
                Lorem ipsum dolor sit amet. Et molestias ratione est architecto
                <br></br> numquam aut modi odit aut harum quod est laborum.
              </p>
            </div>
            <div className="subcriptionDetailsBox rounded-6 bg-base-100 filterShadow-100 mt-32">
              <h5 className="p-20 border-bottom border-gray-400">
                Your Subscription Details
              </h5>
              <div className="p-20">
                <div className="row">
                  <h6 className="col-6 font-normal">Subscription Plan</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {/* Start UP */}
                    {basePlan && basePlan?.planName.toUpperCase()}
                  </p>
                </div>
                <div className="row mt-4">
                  <h6 className="col-6 font-normal">Amount</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    $ {amountPaid}
                  </p>
                </div>
                <div className="row mt-4">
                  <h6 className="col-6 font-normal">Date</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {/* 1/1/2024 */}
                    {createdDate && moment(createdDate).format(
                      "MMM D, YYYY"
                    )}
                    {/* {responcePayment?.created && formatDate(responcePayment?.created)} */}
                  </p>
                </div>
                <div className="row mt-4">
                  <h6 className="col-6 font-normal">Renewal Date</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {/* 1/1/2025 */}
                    {renewalDate && moment(renewalDate).format(
                      "MMM D, YYYY"
                    )}
                    {/* {responcePayment?.currentPeriodEnd && formatDate(responcePayment?.currentPeriodEnd)} */}
                  </p>
                </div>
                {/* <div className="row mt-4">
                  <h6 className="col-6 font-normal">Card Type</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    Visa
                  </p>
                </div> */}
                <div className="row mt-4">
                  <h6 className="col-6 font-normal">Card Holder Name</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {cardHolderName}
                  </p>
                </div>
              </div>
              <div className="mt-4 bottomBtn">
                <div className="row gx-3">
                  <div className="col-6">
                    <a href="/">
                      <Button className="w-100 m-0" variant="secondary">
                      {/* onClick={() => goBack()} */}
                        Go to Dashboard
                      </Button>
                    </a>
                  </div>
                  <div className="col-6">
                    <a href={downloadInvoice} target="_blank">
                      <Button variant="white" className="m-0 w-100">
                        Download Receipt
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center paymentFailed">
          <div className="text-center">
            <img src={inVoiceEmptyPrimary} alt="" />
            <h1 className="pt-20">Payment Failed</h1>
            <p className="text-gray-300 mt-2 mb-0">
              Lorem ipsum dolor sit amet. Et molestias ratione est architecto
              <br></br> numquam aut modi odit aut harum quod est laborum.
            </p>
            <a href="/accountsetting/billingsubscription">
              <Button className="mt-4 m-0" variant="secondary">
                Go to Billing & Subscription
              </Button>
            </a>
          </div>
        </div>
      )}      
    </div>
  );
};

export default RenewalPlanSuccess;