import React, { useEffect, useState } from "react";

import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import {
  IconArrow,
  PDFicon,
  PDFdownload,
  MyLearing,
  Alerts,
  SideArrow,
} from "../../icons/Icons";
import AccountImg from "../../assets/images/Account.png";
import { Link, NavLink, useHistory } from "react-router-dom";
import Search from "../../components/Search/Search";
import OwnerImg from "../../assets/images/Account.png";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../components/ActivityLog/ActivityLog";

import ModalFormView from "./ModalFormView";
import CertificateCovidModal from "./CertificateCovidModal";
import FormFlow3Modal from "./FormFlow3Modal";
import FormFlow6Modal from "./FormFlow6Modal";

const FormViewResponse = () => {
  const [activityModal, setActivityModal] = useState(false);
  const [CovidModal, setCovidModal] = useState(false);
  const [Flow3Modal, setFlow3Modal] = useState(false);
  const [Flow6Modal, setFlow6Modal] = useState(false);
  return (
    <>
      <div className="pages">
        <header className="pageHeader mb-0">
          <Container fluid>
            <Row className="mx-0 align-items-center">
              <Col className="px-0">
                <div class="d-flex align-items-center gap-2">
                  <Link to="/authoring">
                    <span className="arrow left"></span>
                  </Link>
                  <h3>Form Responses</h3>
                </div>
              </Col>
              <Col className="px-0 text-end ">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <AccountDropdown />
                  <ActivityLog />
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="pageOuter p-0 bg-base-100">
          <div className="d-flex flex-column h-100 bg-gray-500">
            <div className="w-100 flex-grow-1 h-100 overflow-auto">
              <Row className="g-0 d-flex h-100">
                <Col className="col-lg-12 flex-grow-1 overflow-auto h-100">
                  <div className="p-32 pb-4">
                    <div className="rounded-6 border bg-gray-600">
                      <div className="p-32 pb-0 bg-base-100 ">
                        <div className="catlogHeading d-flex">
                          <div className="pb-4 mb-3">
                            <h4>Total Responses</h4>
                          </div>
                        </div>
                        <ul class="authoringNav">
                          <li class="me-4">
                            <a
                              aria-current="page"
                              class="h6 text-gray-100 active"
                              href="/FormView"
                            >
                              Summary
                            </a>
                          </li>
                          <li class="me-4">
                            <a class="h6 text-gray-100" href="/Individual">
                              Individual
                            </a>
                          </li>
                        </ul>
                      </div>

                      <div className="p-32">
                        <Row className=" h-100">
                          <Col className="col-lg-6">
                            <div className="rounded-6 border bg-base-100 py-4 d-flex">
                              <div className="cardCount">
                                <span className="bg-gray-500 p-1 font-medium h5">
                                  01
                                </span>
                              </div>
                              <div className="px-3 me-3 w-100">
                                <div className="d-flex justify-content-between align-items-center w-100 py-2 mb-3">
                                  <h5 className="font-medium">
                                    Enter your Full Name
                                  </h5>
                                  <div className="viewAll">
                                    <button className="text-primary-200 font-bold h6 text-start" onClick={() => setActivityModal(true)}>
                                      View All{" "}
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>

                                  </div>
                                </div>
                                <div className="responseList">
                                  <ul className="p-0 m-0">
                                    <li className="bg-gray-600 text-gray-200 h5">
                                      Jenny Wilson
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 h5">
                                      Robert Fox
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-lg-6">
                            <div className="rounded-6 border bg-base-100 py-4 d-flex">
                              <div className="cardCount">
                                <span className="bg-gray-500 p-1 font-medium h5">
                                  02
                                </span>
                              </div>
                              <div className="px-3 me-3 w-100">
                                <div className="d-flex justify-content-between align-items-center w-100 py-2 mb-3">
                                  <h5 className="font-medium">
                                    Upload COVID Vaccination Certificate
                                  </h5>
                                  <div className="viewAll">
                                    <button className="text-primary-200 font-bold h6 text-start" onClick={() => setCovidModal(true)}>
                                      View All{" "}
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="responseList">
                                  <ul className="p-0 m-0">
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 d-flex align-items-center">
                                      <PDFicon />
                                      <h5 className="font-medium ms-2">
                                        COVID vaccination.pdf
                                      </h5>
                                      <span className="ms-auto">
                                        <PDFdownload />
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className=" h-100 mt-4">
                          <Col className="col-lg-6">
                            <div className="rounded-6 border bg-base-100 py-4 d-flex">
                              <div className="cardCount">
                                <span className="bg-gray-500 p-1 font-medium h5">
                                  03
                                </span>
                              </div>
                              <div className="px-3 me-3 w-100">
                                <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                                  <h5 className="font-medium pe-lg-5">
                                    How well did the onboarding process
                                    introduce you to Millipixels culture and
                                    values?
                                  </h5>
                                  <div className="viewAll">
                                    <button className="text-primary-200 font-bold h6 text-start" onClick={() => setFlow3Modal(true)}>
                                      View All{" "}
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="responseList">
                                  <ul className="p-0 m-0 progressBar">
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p80">
                                      <h5 className="font-medium">
                                        Extremely well
                                      </h5>
                                      <span className="processValue ms-auto">
                                        150
                                      </span>
                                    </li>
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p20">
                                      <h5 className="font-medium">
                                        Fairly well
                                      </h5>
                                      <span className="processValue ms-auto">
                                        20
                                      </span>
                                    </li>
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p10">
                                      <h5 className="font-medium">
                                        Not Very well
                                      </h5>
                                      <span className="processValue ms-auto">
                                        10
                                      </span>
                                    </li>
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p10">
                                      <h5 className="font-medium">
                                        Extremely unwell
                                      </h5>
                                      <span className="processValue ms-auto">
                                        10
                                      </span>
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-lg-6">
                            <div className="rounded-6 border bg-base-100 py-4 d-flex">
                              <div className="cardCount">
                                <span className="bg-gray-500 p-1 font-medium h5">
                                  04
                                </span>
                              </div>
                              <div className="px-3 me-3 w-100">
                                <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                                  <h5 className="font-medium pe-lg-5">
                                    What would you have liked us to share with
                                    you before your first day?
                                  </h5>
                                  <div className="viewAll">
                                    <button className="text-primary-200 font-bold h6 text-start">
                                      View All{" "}
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="responseList">graph here</div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                        <Row className=" h-100 mt-4">
                          <Col className="col-lg-6">
                            <div className="rounded-6 border bg-base-100 py-4 d-flex">
                              <div className="cardCount">
                                <span className="bg-gray-500 p-1 font-medium h5">
                                  05
                                </span>
                              </div>
                              <div className="px-3 me-3 w-100">
                                <div className="d-flex justify-content-between align-items-center w-100 py-2 mb-4">
                                  <h5 className="font-medium">
                                    Enter Your Email Address
                                  </h5>
                                  <div className="viewAll">
                                    <button className="text-primary-200 font-bold h6 text-start">
                                      View All{" "}
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="responseList">
                                  <ul className="p-0 m-0">
                                    <li className="bg-gray-600 text-gray-200 h5">
                                      Jennywilson123@gmail.com
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 h5">
                                      Robertfox245@gmail.com
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col className="col-lg-6">
                            <div className="rounded-6 border bg-base-100 py-4 d-flex">
                              <div className="cardCount">
                                <span className="bg-gray-500 p-1 font-medium h5">
                                  06
                                </span>
                              </div>
                              <div className="px-3 me-3 w-100">
                                <div className="d-flex justify-content-between align-items-center w-100 mb-3">
                                  <h5 className="font-medium pe-lg-5">
                                    What improvements would you suggest to make
                                    the app experience better?
                                  </h5>
                                  <div className="viewAll">
                                    <button className="text-primary-200 font-bold h6 text-start" onClick={() => setFlow6Modal(true)}>
                                      View All{" "}
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>
                                  </div>
                                </div>
                                <div className="responseList">
                                  <ul className="p-0 m-0">
                                    <li className="bg-gray-600 text-gray-200 h5">
                                      Having consistency in UI design assures
                                      users.
                                    </li>
                                    <li className="bg-gray-600 text-gray-200 h5">
                                      The conventions in design to keep
                                      consistency will build trust.
                                    </li>
                                  </ul>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={activityModal}
      >
        <ModalFormView
          handleModal={setActivityModal}
          onClosed={setActivityModal}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={CovidModal}
      >
        <CertificateCovidModal
          handleModal={setCovidModal}
          onClosed={setCovidModal}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={Flow3Modal}
      >
        <FormFlow3Modal
          handleModal={setFlow3Modal}
          onClosed={setFlow3Modal}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={Flow6Modal}
      >
        <FormFlow6Modal
          handleModal={setFlow6Modal}
          onClosed={setFlow6Modal}
        />
      </Modal>
    </>
  );
};

export default FormViewResponse;
