import React, { useState } from 'react';
import { Modal } from "react-bootstrap";
import { Switch, Route } from "react-router-dom";
import ReportModal from "./ReportModal";
import Video from './video';
import Ppt from './ppt';
import Doc from './Doc';
import Audio from "./Audio";
import Form from './Form';
import Preview from './Preview'
const ContentPreview = () => {
    const [report, setReport] = useState(false);
    const [content, setContent] = useState(null);

    return (
        <>
            <Switch>
                <Route exact path="/authoring/preview/:id/:previewType/:contentType/:title">
                    {/* <Video actionReport={setReport} /> */}
                    {/* TODO-16-2 */}
                    <Preview actionReport={setReport}  setContent={setContent} content={content}/>
                </Route>
                <Route exact path="/authoring/preview/PPT">
                    <Ppt actionReport={setReport} />
                </Route>
                <Route exact path="/authoring/preview/doc">
                    <Doc actionReport={setReport} />
                </Route>
                <Route exact path="/authoring/preview/Excel">
                    <Doc actionReport={setReport} />
                </Route>
                <Route exact path="/authoring/preview/audio">
                    <Audio actionReport={setReport} />
                </Route>
                <Route exact path="/authoring/preview/form">
                    <Form actionReport={setReport} />
                </Route>
            </Switch>
            <Modal className="modalDialog modalDialogReport" size="sm" centered show={report} 
            // onHide={() => setReport(false)}
            >
                <ReportModal handleModal={setReport}  content={content} onClose={setReport}/>
            </Modal>
        </>
    );
}

export default ContentPreview;