export const isValidPhoto = (fileName) => {
    var allowed_extensions = new Array("jpg", "png", "jpeg");
    var file_extension = fileName.split(".").pop().toLowerCase();
    for (var i = 0; i <= allowed_extensions.length; i++) {
      if (allowed_extensions[i] == file_extension) {
        return true;
      }
    }
    return false;
  }

  export const  isValidVideo = (filename) => {
    var ext =  filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case 'm4v':
      case 'mp4':
      case 'mov':
     return true;
    }
    return false;
  }

  export const  isValidPPT = (filename) => {
    if (filename && typeof filename === "string") {
      var ext = filename.split(".").pop().toLowerCase();
      switch (ext.toLowerCase()) {
        case "pptx":
          return true;
      }
    }
    return false;
}

export const  isValidDocument = (filename) => {
  if (filename && typeof filename === "string") {
    var ext = filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case "pdf":
      case "doc":
      case "docx":
        return true;
    }
  }
  return false;
}

export const  isValidMusic = (filename) => {
  if (filename && typeof filename === "string") {
    var ext = filename.split(".").pop().toLowerCase();
    switch (ext.toLowerCase()) {
      case "mp3":
        return true;
    }
  }
  return false;
}