import React, { useState, useEffect } from "react";
import { Modal, Button, Row, Col, Container, Accordion } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import CheckBoxMd from "../../components/CheckBox/CheckBoxMd";
import { getFormatDate } from "../../common";
import EmptyState from "../../components/EmptyState/EmptyState";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import { Status } from "../../common";
import Loader from "../../components/Loader/Loader";
const ModalPreview = ({ setlistData, onClosed, detail, ProcessesDetails, SequencesDetails, type, contentList, setContentList, listData, moduleData, isLoading }) => {

    const dispatch = useDispatch();
    const [selctedChekd, setSelctedChekd] = useState("All");

    const onCheck = (value, type) => {
        setSelctedChekd(type);
        var data = contentList;
        switch (type) {
            case "All":
                return setContentList(ProcessesDetails.content);
            case "Sequences":
                return setContentList(
                    ProcessesDetails.content.filter((f) => f.type === "Sequence")
                );
            case "IM":
                return setContentList(
                    ProcessesDetails.content.filter((f) => f.type === "Module")
                );
            default:
                return setContentList(ProcessesDetails.content);
        }
    };

    const handleClick = (event) => {
        event.preventDefault();
        onClosed(false);
    };

    // console.log(contentList, "contentList")
    // console.log(listData, "listData");
    console.log(moduleData,'moduleDatamoduleData');

    return (
        <>
         {isLoading && <div className="formLoader">
          <Loader type="activity" />
        </div>}
            {!isLoading && type === "process" && (
                <>
                    <Modal.Header closeButton className="bg-base-100 px-44 py-3" onClick={() => onClosed(false)}>
                        <div>
                            <Modal.Title>Template Preview</Modal.Title>
                            <ol className="breadcrumb h6 mt-1">
                                <li>
                                    <Link to="" onClick={handleClick}>Templates</Link>
                                </li>
                                <li>{ProcessesDetails?.title}</li>
                            </ol>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <div className='px-44 py-32 bg-gray-600 h-100 d-flex'>
                            <div className='borderAll rounded-6 boxShadow h-100 d-flex overflow-hidden flex-column w-100'>

                                <div className='borderBottom p-32 pb-4 bg-base-100 '>
                                    <h2 className='mb-2 d-flex align-items-center justify-content-between'>{ProcessesDetails?.title}
                                        <Link
                                            to={{
                                                pathname: `/authoring/processes/edit`,
                                                state: {
                                                    propsData: ProcessesDetails,
                                                    opertionType: "Edit",

                                                },
                                            }}
                                        >
                                            <Button className="btn-sm btn-secondary">Use Template</Button>
                                        </Link>
                                    </h2>
                                    
                                    <p className='h6 mb-12'><span className='text-gray-300 me-1'>Created on</span> 
                                    {getFormatDate(ProcessesDetails?.createdDate)}
                                    {/* <DateTimeConverter utcDateTime={ProcessesDetails?.createdDate} /> */}
                                    </p>
                                    <p className='text-gray-300 mb-0'>{ProcessesDetails?.description}</p>
                                </div>

                                <div className='p-32 bg-base-400 overflow-auto h-100 flex-grow-1'>
                                    <div className='mb-4 d-flex justify-content-between align-items-center'>

                                        <h5>Content</h5>

                                        <div className='d-flex gap-3'>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd
                                                    text="All"
                                                    onChange={onCheck}
                                                    colName="All"
                                                    value={selctedChekd === "All"}
                                                /></p>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd
                                                    text="Sequences"
                                                    onChange={onCheck}
                                                    colName="Sequences"
                                                    value={selctedChekd === "Sequences"}
                                                /></p>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd
                                                    text="Independent Modules"
                                                    onChange={onCheck}
                                                    colName="IM"
                                                    value={selctedChekd === "IM"}
                                                /></p>
                                        </div>
                                    </div>

                                    <div className="mb-4 d-flex feedbackData">
                                        <div className='dataItem'>
                                            <p className='h6 mb-3 text-gray-300'>Total Sequences</p>

                                            <h3 className='font-medium'>{ProcessesDetails?.totalSequence}</h3>
                                        </div>

                                        <div className='dataItem'>
                                            <p className='h6 mb-3 text-gray-300'>Total Modules</p>

                                            <h3 className='font-medium'>{ProcessesDetails?.totalModule}</h3>
                                        </div>
                                    </div>

                                    <div>
                                        <Accordion defaultActiveKey="0">
                                            {contentList != undefined &&
                                                contentList.length > 0 &&
                                                contentList.map((c, index) => (
                                                    <>
                                                        <Accordion.Item eventKey={index} className="mb-4 cardContent">
                                                            <Accordion.Header>
                                                                <div className='d-flex justify-content-between align-items-center p-12 pe-3 w-100'>
                                                                    <div className='d-flex gap-3 align-items-start'>

                                                                        <div className='count'>
                                                                            <span className='text-xs'>{index + 1}</span>
                                                                        </div>

                                                                        <div>
                                                                            <p className='text-primary-200 text-md mb-0'>{c.type}</p>
                                                                            <p className='font-medium h5 mb-0 text-gray-100'>{c.title}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <p className='text-md text-gray-300 mb-0'>{c.time}</p>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                {c.content !== undefined &&
                                                                    c.content !== null &&
                                                                    c.content.length > 0 &&
                                                                    c.content.map((cc, index) => (
                                                                        <>
                                                                            <div className='d-flex justify-content-between align-items-center cardBody mb-20'>

                                                                                <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                                                    <div className="count text-xs text-gray-200 borderPurple">{index + 1}</div>
                                                                                    <div>
                                                                                        <p className='text-primary-200 text-md mb-0'>{cc.type}</p>
                                                                                        <p className='font-medium h5 mb-0 text-gray-100'>{cc.title}</p>
                                                                                    </div>
                                                                                </div>

                                                                                <div>  <p className='text-md text-gray-300 mb-0'><span className={`text-${cc.status === 'Published' || cc.status === 'Active' ? 'secondary-300' : 'secondary-600'} text-md me-3 d-inline-block`}>{cc.status} </span>
                                                                                    {/* 20 mins */}
                                                                                </p></div>
                                                                            </div>


                                                                        </>))}
                                                            </Accordion.Body>
                                                        </Accordion.Item>

                                                    </>))}
                                        </Accordion>
                                    </div>

                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                </>
            )}
            {!isLoading && type === "sequence" && (
                <>
                    <Modal.Header closeButton className="bg-base-100 ps-5" onClick={() => onClosed(false)}>
                        <div>
                            <Modal.Title>Template Preview</Modal.Title>
                            <ol className="breadcrumb h6 mt-1">
                                <li>
                                    <Link to="" onClick={handleClick}>Templates</Link>
                                </li>
                                <li>{SequencesDetails?.title}</li>
                            </ol>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <div className='px-44 py-32 bg-gray-600 h-100 d-flex'>
                            <div className='borderAll rounded-6 boxShadow h-100 d-flex overflow-hidden flex-column w-100'>

                                <div className='borderBottom p-32 pb-4 bg-base-100 '>
                                    <h2 className='mb-2 d-flex align-items-center justify-content-between'>{SequencesDetails?.title}
                                        <Link
                                            to={{
                                                pathname: `/authoring/sequences/edit`,
                                                state: {
                                                    propsData: SequencesDetails,
                                                    opertionType: "Edit",
                                                },
                                            }}
                                        >
                                            <Button className="btn-sm btn-secondary">Use Template</Button>
                                        </Link>
                                    </h2>
                                    <p className='h6 mb-12'><span className='text-gray-300 '>Created on</span>  
                                    {getFormatDate(SequencesDetails?.createdDate)} 
                                    {/* <DateTimeConverter utcDateTime={SequencesDetails?.createdDate} /> */}
                                    </p>
                                    {/* <p className='text-gray-300 mb-0'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p> */}
                                    <p className='text-gray-300 mb-0'>{SequencesDetails?.description}</p>
                                </div>

                                <div className='p-32 bg-base-400 overflow-auto h-100 flex-grow-1'>
                                    <div className='mb-4 d-flex justify-content-between align-items-center'>

                                        <h5>Content</h5>

                                        {/* <div className='d-flex gap-3'>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd text="All" />
                                                </p>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd text="Sequences" />
                                                </p>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd text="Independent Modules" />
                                                </p>
                                        </div> */}
                                    </div>

                                    <div className="mb-4 d-flex feedbackData">
                                        <div className='dataItem'>
                                            <p className='h6 mb-3 text-gray-300'>Used in Processes</p>

                                            <h3 className='font-medium'>{SequencesDetails?.usedInProcesses ?? 0}</h3>
                                        </div>

                                        <div className='dataItem'>
                                            <p className='h6 mb-3 text-gray-300'>Total Modules</p>

                                            <h3 className='font-medium'>{SequencesDetails?.totalModule}</h3>
                                        </div>
                                    </div>
                                    {listData != undefined && listData.length <= 0 ? (
                                        <EmptyState message="No content found" />
                                    ) : (
                                        <div>
                                            <Accordion defaultActiveKey="0">
                                                {listData != undefined &&
                                                    listData.length > 0 &&
                                                    listData.map((c, index) => (
                                                        <Accordion.Item eventKey={index} className="mb-4 cardContent">
                                                            <Accordion.Header>
                                                                <div className='d-flex justify-content-between align-items-center p-12 pe-3 w-100'>
                                                                    <div className='d-flex gap-3 align-items-start'>

                                                                        <div className='count'>
                                                                            <span className='text-xs'>{index + 1}</span>
                                                                        </div>

                                                                        <div>
                                                                            <p className='text-primary-200 text-md mb-0'> Module</p>
                                                                            <p className='font-medium mb-0 h5 text-gray-100'>{c.title}</p>
                                                                        </div>
                                                                    </div>

                                                                    <div>
                                                                        <p className='text-md text-gray-300 mb-0'>{c.time}</p>
                                                                    </div>
                                                                </div>
                                                            </Accordion.Header>
                                                            <Accordion.Body>
                                                                {/* {c.content != undefined &&
                                                                    c.content.length > 0 &&
                                                                    c.content.map((cc, index) => ( */}
                                                                {c.sortedContent != undefined &&
                                                                    c.sortedContent.length > 0 &&
                                                                    c.sortedContent.map((cc, index) => (
                                                                        <div className='d-flex justify-content-between align-items-center cardBody mb-20'>

                                                                            <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                                                <div className="count text-xs text-gray-200 borderPurple">{index + 1}</div>
                                                                                <div>
                                                                                    {cc?.form !== null && (
                                                                                        <><p className='text-primary-200 text-md mb-0'>{cc.form.type}</p>
                                                                                        <p className='font-medium mb-0 h5 text-gray-100'> {cc.form.title}</p></>
                                                                                    )}
                                                                                    {cc?.page !== null && (
                                                                                        <><p className='text-primary-200 text-md mb-0'>{cc.page.type}</p>
                                                                                        <p className='font-medium mb-0 h5 text-gray-100'> {cc.page.title}</p></>
                                                                                    )}
                                                                                </div>
                                                                            </div>

                                                                            <div>
                                                                                <p className='text-md text-gray-300 mb-0'>
                                                                                    {cc?.form !== null && (
                                                                                        <span className={`text-${cc.form.statusString === 'Published' || cc.form.statusString === 'Active' ? 'secondary-300' : 'secondary-600'} text-md me-3 d-inline-block`}>{cc.form.statusString}</span>
                                                                                    )}

                                                                                    {cc?.page !== null && (
                                                                                        <span className={`text-${cc.page.statusString === 'Published' || cc.page.statusString === 'Active' ? 'secondary-300' : 'secondary-600'} text-md me-3 d-inline-block`}>{cc.page.statusString}</span>
                                                                                    )}
                                                                                    {/* 20 mins */}
                                                                                </p>
                                                                            </div>
                                                                        </div>

                                                                    ))}
                                                            </Accordion.Body>
                                                        </Accordion.Item>
                                                    ))}

                                            </Accordion>
                                        </div>
                                    )}
                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                </>
            )}
            {!isLoading && type === "module" && (
                <>
                    <Modal.Header closeButton className="bg-base-100 ps-5" onClick={() => onClosed(false)}>
                        <div>
                            <Modal.Title>Template Preview</Modal.Title>
                            <ol className="breadcrumb h6 mt-1">
                                <li>
                                    <Link to="" onClick={handleClick}>Templates</Link>
                                </li>
                                <li>{detail?.title}</li>
                            </ol>
                        </div>
                    </Modal.Header>
                    <Modal.Body className='p-0'>
                        <div className='px-44 py-32 bg-gray-600 h-100 d-flex'>
                            <div className='borderAll rounded-6 boxShadow h-100 d-flex overflow-hidden flex-column w-100'>

                                <div className='borderBottom p-32 pb-4 bg-base-100 '>
                                    <h2 className='mb-2 d-flex align-items-center justify-content-between'>{detail?.title}
                                        <Link
                                            to={{
                                                pathname: `/authoring/modules/create`,
                                                state: {
                                                    propsData: detail,
                                                    opertionType: "Edit",
                                                },
                                            }}
                                        >
                                            <Button className="btn-sm btn-secondary">Use Template</Button>
                                        </Link>
                                    </h2>
                                    <p className='h6 mb-12'><span className='text-gray-300 '>Created on</span> 
                                    {getFormatDate(detail?.createdDate)} 
                                    {/* <DateTimeConverter utcDateTime={detail?.createdDate} /> */}
                                    </p>
                                    <p className='text-gray-300 mb-0'>{detail?.description}</p>
                                </div>

                                <div className='p-32 bg-base-400 overflow-auto h-100 flex-grow-1'>
                                    <div className='mb-4 d-flex justify-content-between align-items-center'>

                                        <h5>Content</h5>

                                        {/* <div className='d-flex gap-3'>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd text="All" /></p>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd text="Sequences" /></p>
                                            <p className='h6 text-gray-200'>
                                                <CheckBoxMd text="Independent Modules" /></p>
                                        </div> */}
                                    </div>

                                    <div className="mb-4 d-flex feedbackData">
                                        <div className='dataItem'>
                                            <p className='h6 mb-3 text-gray-300'>Used in Processes</p>

                                            <h3 className='font-medium'> {detail?.usedInProcesses ?? 0}</h3>
                                        </div>

                                        <div className='dataItem'>
                                            <p className='h6 mb-3 text-gray-300'>Used in Sequences</p>

                                            <h3 className='font-medium'>{detail?.usedInSequences ?? 0}</h3>
                                        </div>
                                    </div>

                                    {moduleData != undefined && moduleData.length <= 0 ? (
                                        <EmptyState message="No content found" />
                                    ) : (
                                        <div>
                                            {/* <Accordion defaultActiveKey="0">
                                            <Accordion.Item eventKey="0" className="mb-4 cardContent">
                                                <Accordion.Header>
                                                    <div className='d-flex justify-content-between align-items-center p-12 pe-3 w-100'>
                                                        <div className='d-flex gap-3 align-items-start'>

                                                            <div className='count'>
                                                                <span className='text-xs'>1</span>
                                                            </div>

                                                            <div>
                                                                <p className='text-primary-200 text-md mb-0'>Sequence</p>
                                                                <p className='font-medium mb-0 text-gray-100'>All About Cerebrent</p>
                                                            </div>
                                                        </div>

                                                        <div>
                                                            <p className='text-md text-gray-300 mb-0'>40 mins</p>
                                                        </div>
                                                    </div>
                                                </Accordion.Header>
                                                <Accordion.Body>
                                                    <div className='d-flex justify-content-between align-items-center cardBody mb-20'>

                                                        <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                            <div className="count text-xs text-gray-200 borderPurple">1</div>
                                                            <div>
                                                                <p className='text-primary-200 text-md mb-0'>Module</p>
                                                                <p className='font-medium mb-0 text-gray-100'>Jira Tutorial</p>
                                                            </div>
                                                        </div>

                                                        <div>  <p className='text-md text-gray-300 mb-0'><span className='text-secondary-600 text-md me-3 d-inline-block'>Expired</span>20 mins</p></div>
                                                    </div>

                                                    <div className='d-flex justify-content-between align-items-center cardBody'>

                                                        <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                            <div className="count text-xs text-gray-200">2</div>
                                                            <div>
                                                                <p className='text-primary-200 text-md mb-0'>Module</p>
                                                                <p className='font-medium mb-0 text-gray-100'>Jira Tutorial</p>
                                                            </div>
                                                        </div>

                                                        <div>  <p className='text-md text-gray-300 mb-0'>20 mins</p></div>
                                                    </div>
                                                </Accordion.Body>
                                            </Accordion.Item>
                                        </Accordion> */}
                                            {moduleData != undefined &&
                                                moduleData.length > 0 &&
                                                moduleData.map((p, index) => (
                                                    <div key={"pages" + index} className="cardContent">
                                                        <div className="borderBottom2 p-12 d-flex gap-3 align-items-center">
                                                            <div className="d-flex gap-3 pt-4 px-4  borderLeft position-absolute end-0 top-0">
                                                                <span
                                                                    className={`tableTag ${Status[p.statusstr].color
                                                                        } text-md  `}
                                                                >     {p.statusstr}</span>
                                                                {/* {detail.status !== 'Active' && detail.status !== 'Archived' && (
                                    <Button
                                      variant=""
                                      className="border-0 py-0"
                                      onClick={() => {
                                        if (p.contenttype === "forms") {
                                          OnEditFormContent(p);
                                        } else {
                                          OneditContent(p);
                                        }
                                      }}
                                    >
                                      <IconEdit2 />
                                    </Button>
                                  )} */}

                                                            </div>
                                                            <div className="count">
                                                                <span className="text-xs">{index + 1}</span>
                                                            </div>
                                                            <div>
                                                                <div className="d-flex align-items-center gap-2 mb-2">
                                                                    <p className="font-medium mb-0">
                                                                        {p.title}
                                                                    </p>
                                                                    <p className="tableTag orange text-md mb-0">
                                                                        {p.contenttype === "pages" &&
                                                                            p.pageContentTypestr}
                                                                        {p.contenttype === "forms" && "Form"}
                                                                    </p>
                                                                </div>
                                                                <p className="h6 ">
                                                                    <span className="text-gray-300">
                                                                        Created
                                                                    </span>{" "}
                                                                    {getFormatDate(p.createdDate)}{" "}
                                                                    {/* <DateTimeConverter utcDateTime={p.createdDate} /> */}
                                                                    <span className="text-gray-300">
                                                                        <span className="text-gray-400">|</span>{" "}
                                                                        Edited
                                                                    </span>{" "}
                                                                    {getFormatDate(p.modifiedDate)}{" "}
                                                                    {/* <DateTimeConverter utcDateTime={p.modifiedDate} /> */}
                                                                    <span className="text-gray-300">
                                                                        <span className="text-gray-400">|</span>{" "}
                                                                        Created by
                                                                    </span>{" "}
                                                                    {p?.createdUserDetail.name}
                                                                </p>
                                                            </div>
                                                        </div>

                                                        <div className="p-4">
                                                            <div className="d-flex align-items-center gap-3 ms-5">
                                                                <span className="issuesNo h6">
                                                                    {" "}
                                                                    {p?.reportIssueCount}
                                                                </span>
                                                                <h6 className="text-gray-300 text-uppercase">
                                                                    reported ISSUES
                                                                </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                        </div>
                                    )}
                                </div>
                            </div>



                        </div>
                    </Modal.Body>
                </>
            )}
        </>

    )

};

export default ModalPreview;
