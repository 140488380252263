import React, { useState, useRef, useEffect } from 'react';
import { Modal, Button } from "react-bootstrap";
import videoPoster from "../../assets/images/videoPoster.jpg";

const IntroductionModal = ({ handleModal }) => {
    const [isShown, setOpen] = useState(false);
    const [videoSize, setVideoSize] = useState({
        width: 0,
        height: 0,
    });
    const videoURL = "https://www.youtube.com/embed/jNQXAC9IVRw?loop=1&amp;modestbranding=1;mute=1&amp;autoplay=1";
    const ImgRef = useRef();
    useEffect(() => {
        setTimeout(() => {
            setVideoSize({
                width: ImgRef.current.width,
                height: ImgRef.current.height,
            });
        }, 300);
    }, []);
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='p-40 pt-4'>
                    <h3 className='mt-3 mb-2'>Welcome to Evantiv Circles</h3>
                    <p className='text-gray-300 mb-0 mb-4'>
                        Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa.
                    </p>

                    <div className='videoPreview m-0'>

                        {isShown ?
                            <iframe className='d-block' src={videoURL} width={videoSize.width ? videoSize.width : "100%"} height={videoSize.height ? videoSize.height : "100%"} frameborder="0" allowfullscreen allow="autoplay"></iframe>
                            :
                            <>
                                <img ref={ImgRef} src={videoPoster} alt="videoPoster" className='w-100 rounded-6' />
                                <button class="play-icon" video-modal="" onClick={() => setOpen(!isShown)}>
                                    <svg class="play-bg" width="74" height="74" viewBox="0 0 74 74" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <circle cx="37" cy="37" r="37" fill="white"></circle>
                                    </svg>
                                    <svg class="play-btn" viewBox="0 0 74 74">
                                        <path d="M28 36.6911V31.1911C28 26.7711 29.67 25.9813 33.5 28.1913L38.05 30.8911L42.5 33.6912C46.33 35.9012 45.83 37.4812 42 39.6912L38.05 42.4911L33.5 45.1912C29.67 47.4012 28 46.6112 28 42.1912V36.6911Z" fill="#1A1A1A"></path>
                                    </svg>
                                </button>
                            </>
                        }


                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button variant="primary" size="sm" className='border-0'>
                    Next
                </Button>
            </Modal.Footer>
        </>
    );
}

export default IntroductionModal;
