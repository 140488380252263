import React from "react";
import { Switch, Route } from "react-router-dom";

import Processes from "./Processes/Processes";
import CreateProcesses from "./Processes/CreateProcesses";
import ProcessesDetail from "./Processes/ProcessesDetail";
import ProcessesEdit from "./Processes/ProcessesEdit";

import Sequences from "./Sequences/Sequences";
import SequencesDetail from "./Sequences/SequencesDetail";
import SequencesCreate from "./Sequences/SequencesCreate";

import Modules from "./Modules/Modules";
import CreateModule from "./Modules/CreateModule";
import ModuleDetail from "./Modules/ModuleDetail";
import ModuleFeedback from "./Modules/ModuleFeedback";
import FtueDashboard from "./Modules/FtueDashboard";
import FeedbackEmployee from "../Feedback/FeedbackEmployee";

import ContentPreview from "./ContentPreview/ContentPreview";
import FeedbackView from "./Processes/FeedbackView";
const AuthoringIndex = () => {
  return (
    <>
      <Switch>
        <Route exact path="/authoring">
          <Processes />
        </Route>
        <Route exact path="/authoring/processes/create">
          <CreateProcesses />
        </Route>
        <Route exact path="/authoring/processes/edit">
          <CreateProcesses />
          {/* <ProcessesEdit /> */}
        </Route>
        <Route exact path="/authoring/processes/:id">
          <ProcessesDetail />
        </Route>
        <Route exact path="/authoring/sequences">
          <Sequences />
        </Route>
        <Route exact path="/authoring/sequences/create">
          <SequencesCreate />
        </Route>
        <Route exact path="/authoring/sequences/edit">
          <SequencesCreate />
        </Route>
        <Route exact path="/authoring/sequences/:id">
          <SequencesDetail />
        </Route>

        <Route exact path="/authoring/modules">
          <Modules />
        </Route>
        <Route exact path="/authoring/modules/create">
          <CreateModule />
        </Route>
        <Route exact path="/authoring/modules/feedback">
          <ModuleFeedback />
        </Route>
        <Route exact path="/authoring/modules/dashboard">
          <FtueDashboard />
        </Route>
        <Route exact path="/authoring/modules/:id">
          <ModuleDetail />
        </Route>
        <Route exact path="/authoring/:path/feedback">
          <FeedbackView />
        </Route>

        <Route path="/authoring/preview/:id/:previewType/:contentType">
          <ContentPreview />
        </Route>
      </Switch>
    </>
  );
};

export default AuthoringIndex;
