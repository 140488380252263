import React from "react";
import { Modal, Button } from "react-bootstrap";

const ModalMessageAlertPopup = ({
  onCancel,
  headerLable,
  chldLable
}) => {

  return (
    <>
      <Modal.Header></Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <h3 className="mt-3"> {headerLable}</h3>
        <p className="text-gray-300 mb-0 mt-2 mb-5">{chldLable}</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter gap-2 py-3 noShadow">
        <Button  variant="primary"
          className="border-0 me-0" size="" onClick={() => onCancel(false)}>
        Close
        </Button>
      </Modal.Footer>
    </>
  );
};

export default ModalMessageAlertPopup;
