import React, { useEffect, useState } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import {
  IconExit,
  IconSkip,
  IconBreadcrumArrow,
  IconZoom,
  IconPPT,
  IconDownload,
  IconReportIssue,
  IconUploadFile,
} from "../../../icons/Icons";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Subtitles } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import {setAssigmentCompleteAsync} from "../../../reducers/user-reducers";


const Header = ({ nextSlide, prevSlide, contentLenght, currentState, contentData, userProfiles }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();
  const assignmentId  = location?.state?.assignmentId;

  // Get ID from URL
  const params = useParams();
  const { title } = params;

  // let { forms, pages } = contentData;
  let { pages, forms } = contentData;

  const headerData = [];
  const headerSecTitle = [];
  const previewData = [];
  const question = [];
  forms &&
    forms.map((v, i) => {
      headerSecTitle.push(v);
      v.questions.map((v, i) => question.push(v));
    });
  // previewData.push(...forms, ...pages);
  // forms && previewData.push(...pages, ...forms);
  // // headerData.push(...headerSecTitle, ...pages);
  // pages && headerData.push(...pages, ...headerSecTitle);

  const headerFirstTitle = title
  // console.log(contentData,"contentData11")
  const headerSecondTitle = contentData[currentState]?.form === null ? contentData[currentState]?.page?.title
    : contentData[currentState]?.form?.title
   ;
  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);

  useEffect(() => {
    setIsNext(contentLenght == currentState + 1 ? true : false);
    setIsPrev(currentState === 0);
  }, [currentState, contentLenght]);

  console.log(assignmentId,'assignment')
 
  return (
    <header className="pageHeader mb-0 border-0">
      {/* TODO-17-5-Heading title */}
      {/* {console.log('location.state 31 = ', location.state)}
            {console.log('propsData 31 = ', propsData)}
            {console.log('headerFirstTitle 31 = ', headerFirstTitle)}
            {console.log('headerSecondTitle 31 = ', headerSecondTitle)} */}

      <Container fluid>
        <Row className="align-items-center">
          <Col className="col-lg-4">
            <div className="d-flex align-items-center gap-2">
              {userProfiles && ((userProfiles.roleName === "Super Admin" || userProfiles.roleName === "Admin") ? (
                <Link to="/authoring">
                  <span className="arrow left"></span>
                </Link>
              ) : (
                <Link to="/catalogue">
                  <span className="arrow left"></span>
                </Link>
              ))}
              <p className="h6 text-gray-200 headerFirstTitle">
                {headerFirstTitle}
              </p>

              {headerSecondTitle && headerSecondTitle !== "" && (
                <IconBreadcrumArrow />
              )}

              <div>
                <p className="h6 text-primary-200">{headerSecondTitle}</p>
              </div>
            </div>
          </Col>
          <Col className="text-end col-lg-8">
            <div className="d-flex align-items-center justify-content-end dd">
              {/* <Link to={{
                                  pathname: propData?.state.redirectFrom,
                                  state: { id: propData?.state.moduleId },
                                }}
                                className="text-gray-100 d-flex align-items-center gap-2 descrp"
                              >                          */}
              <Button
                variant=""
                className="border-0 py-0"
                onClick={() => window.close()}
              >
                <div className="d-flex gap-2 align-items-center">
                  <IconExit />
                  <p className="h6 text-gray-300">Exit</p>
                </div>
              </Button>
              {/* </Link> */}

              {/* <Button variant="" className='border-0 py-0' onClick={nextSlide}>
                <div className='d-flex gap-2 align-items-center'><IconSkip /><p className='h6 text-gray-300'>Skip</p></div>
              </Button> */}
              
              {/* {userProfiles?.roleName === "Employee" && previewData.some(slide => slide.allowSkip === true) ? (
                <div key={previewData[0].id}>
                  <Button variant="" className='border-0 py-0' onClick={nextSlide}>
                    <div className='d-flex gap-2 align-items-center'><IconSkip /><p className='h6 text-gray-300'>Skip</p></div>
                  </Button>
                </div>
              ) : ( */}
              {contentLenght > 1 && (
                <>
                <Button variant="" className='border-0 py-0' onClick={nextSlide}>
                  <div className='d-flex gap-2 align-items-center'><IconSkip /><p className='h6 text-gray-300'>Skip</p></div>
                </Button>
             

                  <div className="pipeSeparator pe-4">&nbsp;</div><Button variant="white" onClick={prevSlide} disabled={isPrev}>
                    <div className="d-flex gap-2 align-items-center">
                      <span>Previous</span>
                    </div>
                  </Button>

                  {/* <Button variant="secondary" onClick={nextSlide} disabled={isNext}> */}
                  <Button
                    variant="secondary"
                    className="ms-12"
                    onClick={nextSlide}
                    disabled={isNext}
                  >
                    {contentLenght === currentState + 1 ? "Submit" : "Next"}
                  </Button>

                  </>
              )}
            </div>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
