import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Search from "../../components/Search/Search";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../components/ActivityLog/ActivityLog";
import Filters from "../../components/Filters/Filters";
import { Link } from "react-router-dom";
import CheckBox from "../../components/CheckBox/CheckBox";
import TrialHeader from "./TrialHeader";
import RenewalHeader from "./../Authoring/ContentPreview/RenewalHeader"

const Modules = () => {
  return (
    <div className="pages">
      <RenewalHeader />
      <TrialHeader />
      <header className="pageHeader">
        <Container fluid>
          <Row className="mx-0 align-items-center">
            <Col className="px-0">
              <h3 className="mb-1">Authoring</h3>
              <p className="h6 text-primary-100">
                📝 How do I author my content?
              </p>
            </Col>
            <Col className="px-0 text-end ">
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <AccountDropdown />
                <ActivityLog />
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <div className="page d-flex">
        <Filters />
        <div className="filtersContent d-flex flex-column">
          <div className="pageActions">
            <Row className="mx-0">
              <Col className="px-0" sm={6}>
                <Search />
              </Col>
              <Col className="px-0" sm={6}>
                <div className="d-flex justify-content-end">
                  <button className="btn-white btn-sm">Use a template</button>
                  {/* <span className='mx-4 divider'></span> */}
                  <Link
                    to="/authoring/processes/create"
                    className="btn-secondary btn-sm ms-3"
                  >
                    Create new module
                  </Link>
                </div>
              </Col>
            </Row>
          </div>

          <table className="table">
            <thead>
              <tr>
                <th>
                  <CheckBox text="ALL" />
                </th>
                <th>MODULE TITLE</th>
                <th>CREATED ON</th>
                <th>LAST EDITED</th>
                <th>CREATED BY</th>
                <th>AUDIENCE</th>
                <th>STATUS</th>
                <th>Feedback Rating</th>
              </tr>
            </thead>
          </table>

          <div className="d-flex justify-content-center align-items-center flex-grow-1">
            <div className="text-center">
              <div className="mb-3">
                <svg
                  width="164"
                  height="164"
                  viewBox="0 0 164 164"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <circle cx="82" cy="82" r="82" fill="#F4F4F4" />
                  <path
                    d="M127.487 108.514H112.892C100.73 108.514 98.2974 103.503 98.2974 93.4811V78.473C98.2974 67.0405 102.36 63.9757 110.46 63.5135H129.919C138.019 63.9514 142.081 67.0405 142.081 78.473V93.9189"
                    fill="white"
                  />
                  <path
                    d="M108.027 81.7568H127.486"
                    stroke="#D9D9D9"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M108.027 91.4865H120.189"
                    stroke="#D9D9D9"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M142.081 93.9189L127.486 108.514V101.216C127.486 96.3513 129.919 93.9189 134.784 93.9189H142.081Z"
                    fill="#EEEEEE"
                  />
                  <path
                    d="M55.4866 108.514H40.892C28.7298 108.514 26.2974 103.503 26.2974 93.4811V78.473C26.2974 67.0405 30.3595 63.9757 38.4595 63.5135H57.919C66.019 63.9514 70.0811 67.0405 70.0811 78.473V93.9189"
                    fill="white"
                  />
                  <path
                    d="M36.0269 81.7568H55.4863"
                    stroke="#D9D9D9"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M36.0269 91.4865H48.189"
                    stroke="#D9D9D9"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M70.0809 93.9189L55.4863 108.514V101.216C55.4863 96.3513 57.9188 93.9189 62.7836 93.9189H70.0809Z"
                    fill="#EEEEEE"
                  />
                  <path
                    d="M94 118.333H74C57.3333 118.333 54 111.467 54 97.7333V77.1667C54 61.5 59.5667 57.3 70.6667 56.6667H97.3333C108.433 57.2667 114 61.5 114 77.1667V98.3333"
                    fill="white"
                  />
                  <path
                    d="M67.3335 81.6667H94.0002"
                    stroke="#D9D9D9"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M67.3335 95H84.0002"
                    stroke="#D9D9D9"
                    stroke-width="1.5"
                    stroke-miterlimit="10"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M114 98.3333L94 118.333V108.333C94 101.667 97.3333 98.3333 104 98.3333H114Z"
                    fill="#EEEEEE"
                  />
                  <path
                    d="M84.3332 112C101.822 112 116 97.8223 116 80.3333C116 62.8443 101.822 48.6667 84.3332 48.6667C66.8442 48.6667 52.6665 62.8443 52.6665 80.3333C52.6665 97.8223 66.8442 112 84.3332 112Z"
                    stroke="#D9D9D9"
                    stroke-width="4.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                  <path
                    d="M119.333 115.333L112.667 108.667"
                    stroke="#D9D9D9"
                    stroke-width="4.5"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  />
                </svg>
              </div>

              <p className="h6 text-gray-300">
                No modules to show.{" "}
                <a href="" className="text-primary-200 font-bold">
                  Create a module
                </a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Modules;
