import React, { useEffect, useState } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import {
  IconExit,
  IconSkip,
  IconBreadcrumArrow,
  IconZoom,
  IconPPT,
  IconDownload,
  IconReportIssue,
  IconUploadFile,
} from "../../../icons/Icons";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Subtitles } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {setAssigmentCompleteAsync} from "../../../reducers/user-reducers";
import {
  getPlansAsync,
  getSubscriptionAsync,
} from "../../../reducers/subscription-reducers";
import { isMoreThan30Minutes } from "../../../common"

const RenewalHeader = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const history = useHistory();

  const subscriptionData = useSelector((state) => state.subscription);
  const [activePlan, setIsActivePlan] = useState(false);
  const [subscriptionDt, setSubscriptionDt] = useState({});
  const [isWarning, setIsWarning] = useState(false);

  useEffect(() => {
    dispatch(getPlansAsync());
    // dispatch(getSubscriptionAsync());

    dispatch(getSubscriptionAsync()).then((res) => {
      if (res?.payload) {
        setSubscriptionDt(res?.payload);
        if (res?.payload?.subscriptions?.length > 0 && res?.payload?.subscriptions[0]?.items?.length > 0 && res?.payload?.subscriptions[0].status === 'active') {
          if (res?.payload?.subscriptions[0]?.items[0]?.plan?.active === true) {
            setIsActivePlan(false);
          }
        }
      }
    });
  }, []);

  useEffect(() => {
    console.log(subscriptionDt,'Renewal subscriptionData');
    if (subscriptionDt?.subscriptions?.length > 0 && subscriptionDt?.subscriptions[0].items?.length > 0 && subscriptionDt?.subscriptions[0].status === 'past_due') { // past_due
      if (subscriptionDt?.subscriptions[0]?.items[0]?.plan?.active === true) {
        const result = isMoreThan30Minutes(subscriptionDt?.subscriptions[0]?.currentPeriodStart);
        console.log(result,'resultresultresultresult');
        setIsActivePlan(true);
        if (result) {
          setIsWarning(false);
        } else {
          setIsWarning(true);
        }
      }
    }
  }, [
    subscriptionDt,
  ]);
  
  // console.log(assignmentId,'assignment')
 
  return (
    <>
      {activePlan === true && (
        <div className={` ${isWarning ? "bg-secondary-200" : "bg-primary-100"} py-2 px-2 d-flex align-items-center justify-content-start gap-3`}>
          {isWarning ? (
            <div className="bg-base-100 p-1 rounded-6 ms-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M8.7821 2.1049L4.62358 3.58289C3.6652 3.92094 2.88184 4.99012 2.88184 5.94924V11.7904C2.88184 12.7181 3.53187 13.9367 4.32357 14.4948L7.90706 17.0184C9.08212 17.8518 11.0155 17.8518 12.1906 17.0184L15.7741 14.4948C16.5658 13.9367 17.2158 12.7181 17.2158 11.7904V5.94924C17.2158 4.98226 16.4324 3.91308 15.4741 3.57503L11.3155 2.1049C10.6072 1.86119 9.4738 1.86119 8.7821 2.1049Z"
                  stroke="#ECB22E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.58203 9.68314L8.92376 10.9489L12.5073 7.56836"
                  stroke="#ECB22E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          ) : (
            <div className="bg-base-100 p-1 rounded-6 ms-1">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
              >
                <path
                  d="M8.7821 2.1049L4.62358 3.58289C3.6652 3.92094 2.88184 4.99012 2.88184 5.94924V11.7904C2.88184 12.7181 3.53187 13.9367 4.32357 14.4948L7.90706 17.0184C9.08212 17.8518 11.0155 17.8518 12.1906 17.0184L15.7741 14.4948C16.5658 13.9367 17.2158 12.7181 17.2158 11.7904V5.94924C17.2158 4.98226 16.4324 3.91308 15.4741 3.57503L11.3155 2.1049C10.6072 1.86119 9.4738 1.86119 8.7821 2.1049Z"
                  stroke="#E01E1E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M7.58203 9.68314L8.92376 10.9489L12.5073 7.56836"
                  stroke="#E01E1E"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
          )}
          <p className="mb-0 text-base-100 font-medium">
            {isWarning ? "Awaiting Confirmation on last payment. To know more visit" : "Last Payment Failed. To know more visit" }
            {/* Last Payment for Plan Renewal has Failed. Renew the plan to access. */}
          </p>
          <Link
            className={`btn-white btn-sm border-0 bg-base-100 ${isWarning ? "text-secondary-200" : "text-primary-100"}`}
            to="/accountsetting/billingsubscription"
          >
            Go to Billing & Subscription
          </Link>
          {/* <button className="btn-white btn-sm border-0 bg-base-100 text-primary-100">
            Go to Billing & Subscription
          </button> */}
        </div>
      )}
    </>
  );
};

export default RenewalHeader;
