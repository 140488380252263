import React, { useState } from 'react';
import RadioButton from "../../../../components/RadioButton/RadioButton";
import { isValidPhoto, isValidVideo, isValidPPT, isValidDocument, isValidMusic } from './formFileValidation';
import Video from '../video';
import ImagePreview from '../ImagePreview';
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";

const MultipleChoice = ({ title, options, serialNo, questionsFiles, isRequired, onValueChange, value, disabled }) => {
  const [selectedOption, setSelectedOption] = useState(value ? value : null);

  const handleChoiceChange = (newValue) => {
    setSelectedOption(newValue);
    onValueChange(newValue);
    console.log(newValue, "radio");
  };

  return (
    <div className='mt-3 pt-3'>
      <h6 className='mb-3 font-medium'>
        {serialNo}. {title}
        {isRequired && (<span className="mandatoryFields" style={{ color: "red" }}>*</span>)}
      </h6>
      {questionsFiles?.length > 0 ? (
        <>
          {isValidVideo(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Video contentUrl={questionsFiles[0]?.filePath} />
          ) : isValidPhoto(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <ImagePreview contentUrl={questionsFiles[0].filePath} />
          ) : isValidPPT(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Ppt
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : isValidDocument(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Doc
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : isValidMusic(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Audio
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : null}
        </>
      ) : null}
      <div>
        {options.map((item) => (
          <div className='mt-3 justify-content-between flex-column' key={item.optionValue}>
            <RadioButton
              text={item.optionValue}
              name="radio"
              checked={item.optionValue === selectedOption}
              onChange={() => {
                handleChoiceChange(item.optionValue);
              }}
              disabled={disabled}
              // value={value}
            />
            {isValidVideo(
              item.contentPath
              ) ? (
                <Video contentUrl={item.contentPath}
                />
              ) : isValidPhoto(
                item.contentPath
                ) ? (
                <ImagePreview
                  contentUrl={
                    item.contentPath
                  }
                />
              ) : isValidPPT(
                item.contentPath
              ) ? (
                <Ppt
                  contentUrl={
                    item.contentPath
                  }
                />
              ) : isValidDocument(
                item.contentPath
              ) ? (
                <Doc
                  contentUrl={
                    item.contentPath
                  }
                />
              ) : isValidMusic(
                item.contentPath
              ) ? (
                <Audio
                  contentUrl={
                    item.contentPath
                  }
                />
              ) : null}
          </div>
        ))}
      </div>
    </div>
  )
}

export default MultipleChoice;
