
import React from 'react';
import { Modal, Button } from "react-bootstrap";
import {
    IconleftArrow,
    IconrightArrow,
  } from "../../icons/Icons";
const FormFlow3Modal = ({ handleModal, onClosed }) => {
    return (
        <>
            <Modal.Header className="d-flex justify-content-start" closeButton onClick={() => onClosed(false)}>
            <Modal.Title>All Responses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=''>
                    <div className='d-flex justify-content-between'>
                        <h5 className='formQus font-normal text-gray-100'><span className='count'>3.</span>
                        How well did the onboarding process introduce you to Millipixels culture and values?
                            <span className='d-block text-xs text-gray-300'>200 Responses</span>
                        </h5>
                        <ul>
                            <li className='text-sm pageCount'>
                            <button className="btn btn-sm pBtn me-2">
                     <IconleftArrow/>
                     </button>
                            Question<span>3</span>of 100
                     <button className="btn btn-sm pBtn ms-2">
                     <IconrightArrow/>
                     </button>
                            </li>
                        </ul>
                    </div>
                    <div className="responseList">
                                  <ul className="p-0 m-0 progressBar">
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p80">
                                      <h5 className="font-medium">
                                        Extremely well
                                      </h5>
                                      <span className="processValue ms-auto">
                                        150
                                      </span>
                                    </li>
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p20">
                                      <h5 className="font-medium">
                                        Fairly well
                                      </h5>
                                      <span className="processValue ms-auto">
                                        20
                                      </span>
                                    </li>
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p10">
                                      <h5 className="font-medium">
                                        Not Very well
                                      </h5>
                                      <span className="processValue ms-auto">
                                        10
                                      </span>
                                    </li>
                                    <li className="bg-gray-1100 text-gray-200 h5 d-flex align-items-center p10">
                                      <h5 className="font-medium">
                                        Extremely unwell
                                      </h5>
                                      <span className="processValue ms-auto">
                                        10
                                      </span>
                                    </li>
                                  </ul>
                                </div>    
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-start btnFooter">
        <Button
          variant="secondary"
          size="sm"
          onClick={() => onClosed(false)}
        >
          Done
        </Button>
      </Modal.Footer>
        </>
    );
}

export default FormFlow3Modal;
