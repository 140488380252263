import React, { useEffect, useState } from "react";
import { Tooltip, OverlayTrigger } from "react-bootstrap";

const Progress = ({ assignMents }) => {
  const [completed, setCompleted] = useState(0);

  useEffect(() => {
    const totalAssignMents =
      assignMents?.pending + assignMents?.active + assignMents?.completed;
    if (totalAssignMents > 0) {
      const calcCompleted = (assignMents.completed / totalAssignMents) * 100;
      setCompleted(calcCompleted);
    } else {
      setCompleted(0);
    }
  }, [assignMents]);

  const renderTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props} className="tooltipProgressBar">
      <div>
        <p className="h6">Completed</p>
        <span className="mb-0 font-medium"> {assignMents.completed}</span>
      </div>
      <div>
        <p className="h6">Active</p>
        <span className="mb-0 font-medium">{assignMents.active}</span>
      </div>
      <div>
        <p className="h6">Pending</p>
        <span className="mb-0 font-medium">{assignMents.pending}</span>
      </div>
      <div>
        <p className="h6">Delayed</p>
        <span className="mb-0 font-medium">{assignMents.delayed}</span>
      </div>
    </Tooltip>
  );
  return (
    <>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        <div className="py-2">
          <div className="progress tableProgress">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${completed}%` }}
            ></div>
          </div>
        </div>
      </OverlayTrigger>
    </>
  );
};

export default Progress;
