import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Modal,
  Tooltip,
  Button,
  OverlayTrigger,
  Accordion,
} from "react-bootstrap";
import { MinusBorder, TickNormal } from "../../../icons/Icons";
import { Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlansAsync,
  getSubscriptionAsync,
  updateSubscriptionAsync,
  downgradeSubscriptionAsync,
} from "../../../reducers/subscription-reducers";
import { convertToGB } from "../../../common";

const ChangePlanModal = ({ onhandleGetStarted, cycleName }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { plansData, isUpdateLoading, isDowngradeLoading } = useSelector(
    (state) => state.subscription
  );
  const subscriptionData = useSelector((state) => state.subscription);

  const [viewPlan, setViewPlan] = useState("year");
  const [subscribePlanId, setSubscribePlanId] = useState(-1);
  const [discountPlan, setDiscountPlan] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [discountedPrice, setDiscountedPrice] = useState("");
  
  const [reversedPlansData, setReversedPlansData] = useState([]);
  const [enterprisePlansData, setEnterprisePlansData] = useState([]);
  const [startUpPlans, setStartUpPlans] = useState([]);
  const [businessPlans, setBusinessPlans] = useState([]);
  const [isEnterprise, setIsEnterprise] = useState(false);
  const [paymentCardModal, setPaymentCardModal] = useState(false);

  useEffect(() => {
    // alert(cycleName)
    setViewPlan(cycleName);
  }, [cycleName]);

  useEffect(() => {
    // console.log(plansData,'plansDataplansData');
    if (plansData && plansData.length > 0) {
      const filteredPlansData = plansData.filter(
        (plan) =>
          plan.planType === (viewPlan === "year" ? "Yearly" : "Monthly") &&
          plan.statementDescriptor === "base_plan" && 
          plan.planName !== "Enterprise"
      );

      const filteredEnterprisePlans = plansData.filter(
        (plan) =>
          // plan.planType === (viewPlan === "year" ? "Yearly" : "Monthly") &&
          plan.statementDescriptor === "base_plan" && 
          plan.planName === "Enterprise"
      );
      setEnterprisePlansData(filteredEnterprisePlans);
      // console.log(filteredPlansData,'filteredPlansData');
      // const reversedData = [...filteredPlansData].reverse();
      let reversedData = [];
      if (viewPlan === "year") {
        reversedData = [...filteredPlansData].sort((a, b) => a.price - b.price);
        setReversedPlansData(reversedData);
      } else {
        reversedData = [...filteredPlansData];
        setReversedPlansData(reversedData);
      }

      const startUpDatas = reversedData.filter(
        (plan) => plan.planName === "Startup"
      );
      setStartUpPlans(startUpDatas && startUpDatas[0]);

      const businessDatas = reversedData.filter(
        (plan) => plan.planName === "Business"
      );
      setBusinessPlans(businessDatas && businessDatas[0]);
    }
  }, [plansData, viewPlan]);

  useEffect(() => {
    dispatch(getPlansAsync());
    dispatch(getSubscriptionAsync());
  }, []);

  useEffect(() => {
    setSubscribePlanId(-1);
    const monthlyTotal = plansData?.reduce((total, planData) => {
      const monthlyPrice =
        planData?.planType === "Monthly" ? planData?.price / 100 : 0;
      return total + (monthlyPrice || 0);
    }, 0);

    const yearlyTotal = plansData?.reduce((total, planData) => {
      const yearlyPrice =
        planData?.planType === "Yearly" ? planData?.price / 100 : 0;
      return total + (yearlyPrice || 0);
    }, 0);

    setDiscountedPrice(
      (((monthlyTotal * 12 - yearlyTotal) / (monthlyTotal * 12)) * 100).toFixed(
        2
      )
    );

    if (
      subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0
    ) {
      // const reversedPlans = [...plansData].reverse();
      const subscriptionItems = subscriptionData?.subscriptionData?.subscriptions[0]?.items;
      // console.log(plansData,'plansData');
      // console.log(reversedPlansData,'reversedPlans');
      // console.log(subscriptionItems,'subscriptionItems');
      for (let index = 0; index < reversedPlansData?.length; index++) {
        const plan = reversedPlansData[index];
        if (subscriptionItems[0].price?.id === plan?.priceId) {
          setSubscribePlanId(index);
          break;
        }
      }

      for (let index = 0; index < enterprisePlansData?.length; index++) {
        const entPlan = enterprisePlansData[index];
        if (subscriptionItems[0].price?.id === entPlan?.priceId) {
          setIsEnterprise(true);
          break;
        }
      }
    }
  }, [
    plansData,
    subscriptionData,
    reversedPlansData,
  ]);

  const handlePlan = (val) => {
    // console.log(val, "val");
    if (val == "Yearly") {
      setViewPlan("year");
    } else {
      setViewPlan("month");
    }
  };

  // const onhandleGetStarted = (planData) => {
  //   let selPlanData = [];
  //   if (viewPlan === "year") {
  //     selPlanData = planData?.planType === "Yearly" ? planData : [];
  //   } else {
  //     selPlanData = planData?.planType === "Monthly" ? planData : [];
  //   }
  //   if (selPlanData) {
  //     setSelectedPlanId(selPlanData?.priceId);
  //     setSelectedPlanData(selPlanData);
  //   }
  //   setPaymentCardModal(true);
  // };

  console.log(startUpPlans, "startUpPlans");
  console.log(businessPlans, "businessPlans");

  console.log(plansData, "plansData");
  console.log(reversedPlansData, "reversedPlansData");
  console.log(enterprisePlansData, "enterprisePlansData");

  return (
    <div className="changePlanModal">
      <div className="p-32 pb-4">
        <div className="d-flex justify-content-between align-items-center ps-32">
          <div>
            <h1 className="text-gray-100 font-bold">
              Find a plan that is right for you
            </h1>
            <p className="text-gray-100 font-normal mb-0 mt-3">
              According to the information you provided, we have a perfect plan
              that will fit your business needs.
            </p>
          </div>
          <div className="planToggleSwitch white">
            <div className="switches-container">
              <input
                type="radio"
                id="switchYearly"
                name="switchPlan"
                value="Yearly"
                checked={viewPlan === "year"}
                onChange={(event) => handlePlan(event.target.value)}
                // disabled={true}
              />
              <input
                type="radio"
                id="switchMonthly"
                name="switchPlan"
                value="Monthly"
                checked={viewPlan === "month"}
                onChange={(event) => handlePlan(event.target.value)}
              />
              
              <label for="switchYearly">Annually</label>
              <label for="switchMonthly">Monthly</label>
              <div className="switch-wrapper">
                <div className="switch">
                  <div>
                    <span className="me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                      >
                        <path
                          d="M12.9503 1.57617L5.92004 9.85453L2.4082 6.93829"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Annually
                  </div>
                  <div>
                    <span className="me-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="15"
                        height="12"
                        viewBox="0 0 15 12"
                        fill="none"
                      >
                        <path
                          d="M12.9503 1.57617L5.92004 9.85453L2.4082 6.93829"
                          stroke="white"
                          stroke-width="3"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </span>
                    Monthly
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row gx-0 selectArea align-items-end">
          <div className="col-sm-3">
            <div className="leftSide">
              <h5>Platform Features</h5>
              <h6 className="mt-4">Process</h6>
              <h6 className="mt-4">Sequence</h6>
              <h6 className="mt-4">Modules</h6>
              <h6 className="mt-4">Feedback from employees</h6>
              <h6 className="mt-4">Pay by Invoice</h6>
              <h6 className="mt-4">Export to CSV</h6>
              <h6 className="mt-4">Create Content</h6>
              <h5 className="mt-32 mb-32">Fundamental Features</h5>
              <h6 className="">GDPR Compliant</h6>
              <h6 className="mt-4">Bulk import employees</h6>
              <h6 className="mt-4">Bulk assign content</h6>
              <h6 className="mt-4">Bulk update status</h6>
              <h6 className="mt-4">Multilingual</h6>
              <h6 className="mt-4">Role based access</h6>
              <h6 className="mt-4">SSO-Google</h6>
              <h6 className="mt-4">SSO-Microsoft Azure AD</h6>
              <h6 className="mt-4">Custom Domain</h6>
              <h6 className="mt-4 lineExtra">
                Uptime and support request response SLA
              </h6>
            </div>
          </div>
          
          {reversedPlansData.map((planData, index) => {
            // console.log(index, "index");
            // console.log(subscribePlanId, "subscribePlanId");
            return (
              <div className="col-sm-3">
                <div className="single-box">
                  <h4 className="font-extraBold text-gray-100 text-center">
                    {planData.planName}
                  </h4>
                  <h2 className="font-bold text-gray-100 mt-4 text-28">
                    {(planData.planName === "Business" || planData.planName === "Startup") && planData?.price ? (
                      `$${planData?.price}`
                    ) : (
                      <> Request pricing </>
                    )}
                    <span className="text-lg text-gray-300 font-normal">
                      {/* per seat/month */}
                      {planData.planName === "Business" ||
                      planData.planName === "Startup"
                        ? ` /${viewPlan}`
                        : "Custom pricing for your company"}
                    </span>
                  </h2>
                  <p className="text-xs text-gray-100 opacity-75 text-center">
                    {/* (5 Seats Minimum, billed annually) */}
                    {planData.planName === "Business" ||
                      planData.planName === "Startup"
                        ? `(5 Seats Minimum, billed annually)`
                        : "Custom pricing for your company"}
                  </p>

                  {/* <Button
                    className="m-0 mt-32 btn-sm w-100 text-center"
                    variant="primary"
                  >
                    Start 15-day FREE Trial
                  </Button> */}
                  <Button
                    type="submit"
                    className={`m-0 mt-32 btn-sm w-100 text-center ${index === 1 ? "btn-dark text-base-200" : ""}`}
                    variant={index === 0 ? "primary" : ""}
                    onClick={
                      () => onhandleGetStarted && onhandleGetStarted(planData, viewPlan)
                    }
                    // disabled={isEnterprise}
                  >
                    {planData.planName === "Business"
                      ? " Start 15-day FREE Trial"
                      : "Start 15-day FREE Trial"}
                  </Button>

                  {index === 0 && (
                    <>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-4 text-md pb-3">
                        Up to <b>{planData?.metaData?.max_users} Active</b> Users
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        <b>{convertToGB(planData?.metaData?.storage)}</b>/User Content Storage
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Custom Domain & Branding- <b>{planData?.metaData?.custom_branding === "Yes"? 'Basic' : 'No'}</b>
                      </p>                    
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Create up to <b>{planData?.metaData?.sequence} sequences & {planData?.metaData?.modules === "-1" ? "unlimited" : planData?.metaData?.modules} Modules</b>
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Videos Community Knowledge Base
                      </p>
                      <p className="text-gray-100 font-normal mt-3 text-md">
                        <b>2 Business Day</b> turnaround on Support Desk
                      </p>
                    
                      <div className="text-center">
                        <h5 className="invisible">Platform Features</h5>
                        <h6 className="mt-4">
                          {planData && planData?.metaData?.process === '-1' ? 'Unlimited' : planData?.metaData?.process === '0' ? <MinusBorder /> : planData?.metaData?.process}
                        </h6>
                        <h6 className="mt-4">
                          {planData && planData?.metaData?.sequence === '-1' ? 'Unlimited' : planData?.metaData?.sequence === '0' ? <MinusBorder /> : planData?.metaData?.sequence}
                        </h6>
                        <h6 className="mt-4">
                          {planData && planData?.metaData?.modules === '-1' ? 'Unlimited' : planData?.metaData?.modules === '0' ? <MinusBorder /> : planData?.metaData?.modules}
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.export_csv === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h5 className="mt-32 mb-32 invisible">Fundamental Features</h5>
                        <h6 className="">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_import_employees === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_assign_content === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_update_status === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.custom_branding === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4 lineExtra">
                          <MinusBorder />
                        </h6>
                      </div>
                    </>
                  )}

                  {index === 1 && (
                    <>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-4 text-md pb-3">
                        Up to <b>{planData?.metaData?.max_users} Active</b> Users
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        <b>{convertToGB(businessPlans?.metaData?.storage)}</b>/User Content Storage
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Custom Domain & Branding- <b>{planData?.metaData?.custom_branding === "Yes" ? 'Pro' : 'No'}</b>
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Create up to{" "}
                        <b>{planData?.metaData?.process === '-1' ? 'Unlimited' : planData?.metaData?.process} Processes, {planData?.metaData?.sequence === '-1' ? 'Unlimited' : planData?.metaData?.sequence} Sequences & {planData?.metaData?.modules === '-1' ? 'Unlimited' : planData?.metaData?.modules} Modules</b>
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Videos Community Knowledge Base
                      </p>
                      <p className="text-gray-100 font-normal mt-3 text-md">
                        <b>1 Business Day</b> turnaround on Support Desk
                      </p>
                      <div className="text-center">
                        <h5 className="invisible">Platform Features</h5>
                        <h6 className="mt-4">
                          {planData && planData?.metaData?.process === '-1' ? 'Unlimited' : planData?.metaData?.process === '0' ? <MinusBorder /> : planData?.metaData?.process}
                        </h6>
                        <h6 className="mt-4">
                          {planData && planData?.metaData?.sequence === '-1' ? 'Unlimited' : planData?.metaData?.sequence === '0' ? <MinusBorder /> : planData?.metaData?.sequence}
                        </h6>
                        <h6 className="mt-4">
                          {planData && planData?.metaData?.modules === '-1' ? 'Unlimited' : planData?.metaData?.modules === '0' ? <MinusBorder /> : planData?.metaData?.modules}
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.export_csv === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h5 className="mt-32 mb-32 invisible">Fundamental Features</h5>
                        <h6 className="">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_import_employees === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_assign_content === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_update_status === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                        </h6>
                        <h6 className="mt-4">
                          <MinusBorder />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.custom_branding === 'Yes' ? (<TickNormal />) : (<MinusBorder />)}
                        </h6>
                        <h6 className="mt-4 lineExtra">
                          <MinusBorder />
                        </h6>
                      </div>
                    </>
                  )}
                </div>
              </div>
            );
          })}

          {reversedPlansData?.length > 0 && (
            <>
              {enterprisePlansData.map((planData, index) => {
                return (
                  <div className="col-sm-3">
                    <div className="single-box">
                      <h4 className="font-extraBold text-gray-100 text-center">
                        {planData.planName}
                      </h4>
                      <h5 className="font-bold text-gray-100 mt-4">Request pricing</h5>
                      <p className="text-lg text-gray-100 opacity-75 pb-1">
                        Custom pricing for your company
                      </p>
                      <Button
                        className="m-0 mt-32 btn-sm w-100 text-center btn-dark text-base-200"
                        variant=""
                        disabled={true}
                      >
                        Book a demo
                      </Button>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-4 text-md pb-3">
                        {/* {planData?.metaData?.max_users} */}
                        Unlimited Users
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        <b>
                        {/* {convertToGB(planData?.metaData?.storage)} */}
                        Unlimited
                        </b>
                        Content Storage
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Custom Domain & Branding- 
                        <b>
                          {planData?.metaData?.custom_branding === "Yes"? 'Complete' : 'No'}
                        </b>
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Create up to <b>{planData?.metaData?.process === "-1" ? "Unlimited" : planData?.metaData?.process} Processes, {planData?.metaData?.sequence === "-1" ? "Unlimited" : planData?.metaData?.sequence} Sequences & {planData?.metaData?.modules === "-1" ? "Unlimited" : planData?.metaData?.modules} Modules</b>
                      </p>
                      <p className="text-gray-100 font-normal border-bottom border-gray-400 mt-3 text-md pb-3">
                        Videos Community Knowledge Base
                      </p>
                      <p className="text-gray-100 font-normal mt-3 text-md">
                        <b>2 Business Day</b> turnaround on Support Desk
                      </p>
                      <div className="text-center">
                        <h5 className="invisible">Platform Features</h5>
                        <h6 className="mt-4">{planData?.metaData?.process === "-1" ? "Unlimited" : planData?.metaData?.process}</h6>
                        <h6 className="mt-4">{planData?.metaData?.sequence === "-1" ? "Unlimited" : planData?.metaData?.sequence}</h6>
                        <h6 className="mt-4">{planData?.metaData?.modules === "-1" ? "Unlimited" : planData?.metaData?.modules}</h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.export_csv === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h5 className="mt-32 mb-32 invisible">Fundamental Features</h5>
                        <h6 className="">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_import_employees === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_assign_content === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.bulk_update_status === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          <TickNormal />
                        </h6>
                        <h6 className="mt-4">
                          {planData?.metaData?.custom_branding === "No" ? (<MinusBorder />) : (<TickNormal />)}
                        </h6>
                        <h6 className="mt-4 lineExtra">
                          <TickNormal />
                        </h6>
                      </div>
                    </div>
                  </div>
                )
              })}
            </>
          )}
        </div>
        <div className="px-32 pt-4 text-lg text-gray-1200">
          <span className="text-primary-100 me-1 d-iline-block">*</span>
          Conditions Apply
        </div>
      </div>
      <div className="bg-base-100 w-100 px-32 py-4">
        <div className="row">
          <div className="col-sm-4">
            <div className="w-93 px-32">
              <h1 className="mb-3 mt-4">
                Frequently
                <br /> asked questions
              </h1>

              <h6 className="fw-normal mb-5 text-gray-100">
                Everything you need to know about the product and billing. Get
                your answers here.
              </h6>
            </div>
          </div>
          <div className="col-sm-8">
            <Accordion defaultActiveKey="0" className="planAccordian mt-4 ms-3">
              <Accordion.Item eventKey="0" className="mb-4">
                <Accordion.Header>
                  <h3 className="text-gray-100 font-medium">
                    Is there free trial available?
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="h6 fw-normal text-gray-100">
                    Yes, you can try us for 30 days, If you want, we will provide
                    you with a free, personalized 30-minute onboarding call to
                    get you up running as soon as possible. The Free plan
                    enables you to experience the power of visual collaboration
                    firsthand.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mb-4">
                <Accordion.Header>
                  <h3 className="text-gray-100 font-medium">
                    Can I change my plan later?
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="h6 fw-normal text-gray-100">
                    Yes, you can try us for 30 days, If you want, we will provide
                    you with a free, personalized 30-minute onboarding call to
                    get you up running as soon as possible. The Free plan
                    enables you to experience the power of visual collaboration
                    firsthand.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mb-4">
                <Accordion.Header>
                  <h3 className="text-gray-100 font-medium">
                    What is your cancellation policy?
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="h6 fw-normal text-gray-100">
                    Yes, you can try us for 30 days, If you want, we will provide
                    you with a free, personalized 30-minute onboarding call to
                    get you up running as soon as possible. The Free plan
                    enables you to experience the power of visual collaboration
                    firsthand.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mb-4">
                <Accordion.Header>
                  <h3 className="text-gray-100 font-medium">
                    What are my payment options? Can I pay by credit card?
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="h6 fw-normal text-gray-100">
                    Yes, you can try us for 30 days, If you want, we will provide
                    you with a free, personalized 30-minute onboarding call to
                    get you up running as soon as possible. The Free plan
                    enables you to experience the power of visual collaboration
                    firsthand.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mb-4">
                <Accordion.Header>
                  <h3 className="text-gray-100 font-medium">
                    We want to add new users to our team. How will that be
                    billed ?
                  </h3>
                </Accordion.Header>
                <Accordion.Body>
                  <p className="h6 fw-normal text-gray-100">
                    Yes, you can try us for 30 days, If you want, we will provide
                    you with a free, personalized 30-minute onboarding call to
                    get you up running as soon as possible. The Free plan
                    enables you to experience the power of visual collaboration
                    firsthand.
                  </p>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangePlanModal;
