import React, { useState, useEffect, useRef } from "react";
import {
  Row,
  Col,
  Modal,
  Button,
  Tooltip,
  Overlay,
  Container,
} from "react-bootstrap";
import Select from "react-select";
import { IconCross, IconPageContent, IconPlus, SpinIcon, TickFill } from "../../../icons/Icons";
import TagsInput from "../../../components/TagsInput/TagsInput";
import uploadContent from "../../../assets/images/upload_content.png";
import TextArea from "../../../components/FormikInputs/TaxtArea";
import TextInput from "../../../components/FormikInputs/TextInput";
import {
  getModulePageDetailsAsync,
  checkUniquenessAsync,
  updateModuleAsync,
  addUpdatePageAsync,
  deleteModulePageAsync,
  deleteModuleFormAsync,
} from "../../../reducers/module-reducers";
import {
  fileUpload,
  generatePresignedUrl,
} from "../../../api/common-store-api";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormikContext, Formik, Form, Field } from "formik";

import { getPageTypeEnumListAsync } from "../../../reducers/common-reducers";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import { filesUpload } from "../../../api/common-store-api";
import Loader from "../../../components/Loader/Loader";
import { pageType } from "../../../helper/pageType";
import {
  isValidPhoto,
  isValidAudio,
  isValidVideo,
  isValidDocument,
  isDownloadableFile,
  isValidPPT,
} from "../../../helper/FileValidation";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../../components/ModalPopup/ModalResponsePopup";
import ToggleButton from "../../../components/ToggleButton/ToggleButton";

import { getStorageAsync } from "../../../reducers/subscription-reducers";
import ModalStorageLimitReachedPopup from "../../../components/ModalPopup/ModalStorageLimitReachedPopup";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import { getRemainingStringFileName } from "../../../common";

const ModalCreatePage = ({
  openCreatePageModal,
  selectedData,
  onSave,
  id,
  moduleData,
  getModuleDataFunction,
  pagesetMethod,
  setTouched,
  formTouchedData,
  pagesList,
  formsList,
  onClosed,
  moduleFormRef,
  draftCount,
  setDraftCount,
  hasUnpublishedContent
}) => {
  console.log(pagesList,'pagesList');
  console.log(moduleFormRef,'moduleFormRef');
  const [formDataReady, setFormDataReady] = useState(false);
  const formRef = useRef();
  const dispatch = useDispatch();
  const [ftue, setFtue] = useState(false);
  const [showTool, setShowTool] = useState(false);
  const [modulePageVali, setmodulePageVali] = useState([]);
  const target = useRef(null);
  const [addMessage, setAddMessage] = useState(false);
  const [pageData, setpageData] = useState(null);
  const [pagetype, setpagetype] = useState(null);
  const [isUniqueTitle, setIsUniqueTitle] = useState(true);
  const [titleFocused, setTitleFocused] = useState(false);
  const [fileUploadShow, setFileUploadShow] = useState(true);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePageContentFileModal, setDeletePageContentFileModal] =
    useState(false);
  const [responsePopup, setResponsePopup] = useState({});
  const [
    selectedValueForDeleteDownloadableFile,
    setSelectedValueForDeleteDownloadableFile,
  ] = useState({});
  const [deleteContentFile, setDeleteContentFile] = useState({});
  const [downloadableFileErrorMessage, setDownloadFileErrorMessage] =
    useState();

  const [file, setFile] = useState(null);
  const pageFormsList = useSelector(
    (state) => state.module ?? state.module.pageFormsList.pages
  );
  const refImageMultipleChoice = useRef();
  const [showLoader, setShowLoader] = useState(false);
  const [extensionErrorMessage, setExtensionErrorMessage] = useState(null);

  const { storageData } = useSelector((state) => state.subscription);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);
  const [pageContentPathFileName, setPageContentPathFileName] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [isUploadings, setIsUploadings] = useState(false);

  const onTagsUpdate = (
    updatedList,
    setFieldValue,
    setTouchedMethod,
    formTouchedData
  ) => {
    let stringdata = updatedList
      .map((m) => {
        return m.tag;
      })
      .toString();
    if (setTouchedMethod) {
      setTouchedMethod({ ...formTouchedData, ["customTags"]: true });
    }
    setFieldValue("customTags", stringdata);
  };
  const optionPageType = useSelector((state) => state.common.pageTypeList);
  const onSelectationChange = (e, colName, setMethod) => {
    setpagetype({
      value: e.value,
      label: e.label,
    });
    if (setMethod) {
      setMethod(colName, e.value);
      setMethod("pageContentPath", "");
    }
    if (e.label === "Image") {
      setExtensionErrorMessage("Allowed types: jpeg, jpg, png");
    } else if (e.label === "Video") {
      setExtensionErrorMessage("Allowed types: mp4, m4v, mov");
    } else if (e.label === "Audio") {
      setExtensionErrorMessage("Allowed types: mp3");
    } else if (e.label === "Document") {
      setExtensionErrorMessage("Allowed types:  doc, .docx, .pdf");
    } else if (e.label === "PPT") {
      setExtensionErrorMessage("Allowed types:  pptx");
    }
  };

  const onAddMessage = (event, setMethod) => {
    const currentFormData = formRef?.current;
    const inputValue = event.target.value;
    if (inputValue != null && inputValue != undefined && inputValue != "") {
      const existingValue = currentFormData.values?.messages ?? [];
      existingValue.push({ id: null, message: inputValue });
      setMethod("messages", existingValue);
      setAddMessage(false);
    }
  };

  const getHeaderTitle = (id) => {
    return id !== undefined && id !== "" ? "Edit page" : "Create a page";
  };

  const getSubmitTitle = (id) => {
    if (pageData?.status === 1) {
      return "Publish";
    } else {
      return id !== undefined && id !== "" && pageData?.status === 0
        ? "Update page"
        : "Create page";
    }
  };

  const oldPageRemove = (moduleData, index, newPageData) => {
    newPageData = [newPageData];
    let newList = [];
    moduleData?.pageFormsList?.pages.forEach(function (item) {
      if (item.id === newPageData[0].id) {
        newList.push(newPageData[0]);
      } else {
        newList.push({
          id: item.id,
          title: item.title,
          description: item.description,
          customTags: item.customTags,
          pageContentType: item.pageContentType,
          pageContentPath: item.pageContentPath,
          singlePage: item.singlePage,
          status: newPageData[0].status,
          downloadableFile: item.downloadableFile,
          messages: item.messages,
          index: item.index,
          contentType: item.contentType,
          allowSkip: item.allowSkip,
        });
      }
    });
    return newList;
  };
  
//  const onSubmit = async (status, index) => {
//     debugger;
//     setShowLoader(true);
//     let currentFormData = formRef?.current;
//     if (status == 1) {
//       currentFormData.values.status = status
//     }
//     if (!(typeof file === "string" || file instanceof String)) {
//       const formData_pageContentPath = new FormData();
//       formData_pageContentPath.append("file", file);
//       if(file !== null){
//         const responseFile = await fileUpload(formData_pageContentPath);
//         console.log(responseFile,'responseFile');
//         currentFormData.values.pageContentPath = responseFile?.data?.fileName;
//       }
//     } else {
//       currentFormData.values.pageContentPath = file?.name;
//     }

//     let existingValue = currentFormData.values?.downloadableFile ?? [];
//     let arrayPendingUploadFiles = [];
//     let arrayUploadedFiles = [];

//     existingValue.map((item, index) => {
//       if (
//         !(typeof item.filePath === "string" || item.filePath instanceof String)
//       ) {
//         arrayPendingUploadFiles.push(item);
//       } else {
//         arrayUploadedFiles.push(item);
//       }
//     });

//     if (arrayPendingUploadFiles.length > 0) {
//       let formData_downloadableFile = new FormData();
//       arrayPendingUploadFiles.map((value, index) =>
//         formData_downloadableFile.append("files", value.filePath)
//       );

//       const response_downloadableFile = await filesUpload(
//         formData_downloadableFile
//       );
//       console.log(response_downloadableFile,'12');
//       existingValue = [];
//       const arrayRes = response_downloadableFile.data;
//       arrayRes.map((value, index) =>
//         arrayUploadedFiles.push({ id: null, filePath: value })
//       );
//     }

//     // if (arrayUploadedFiles.length > 0) {
//     //   arrayUploadedFiles.map((value, index) =>
//     //   existingValue.push(value)
//     //   );
//     // }
//     currentFormData.values.downloadableFile = arrayUploadedFiles;

//     // if (!(typeof existingValue[0].filePath === 'string' || existingValue[0].filePath instanceof String)) {
//     //   let formData_downloadableFile = new FormData();
//     //   existingValue.map((value, index) => formData_downloadableFile.append("files", value.filePath));

//     //   const response_downloadableFile = await filesUpload(formData_downloadableFile)
//     //   existingValue = []
//     //   const arrayRes = response_downloadableFile.data
//     //   arrayRes.map((value, index) => existingValue.push({ id: null, filePath: value }));
//     //   currentFormData.values.downloadableFile = existingValue
//     // }

//     var errors = pageValidation(currentFormData.values);
//     if (
//       Object.keys(currentFormData.errors).length === 0 &&
//       Object.keys(errors).length === 0 || status === 1
//     ) {
//       if (id) {
//         const pageList = oldPageRemove(
//           pageFormsList,
//           id,
//           currentFormData.values
//         );
//         // console.log(pageList,'pageListpageListpageListpageList');
//         // const reqBody = {
//         //   id: moduleData.id,
//         //   title: moduleData.title,
//         //   description: moduleData.description,
//         //   customTags: moduleData.customTags,
//         //   recommendedTime: moduleData.recommendedTime,
//         //   recommendedTimeDurationType: moduleData.recommendedTimeDurationType,
//         //   skipAllowed: moduleData.skipAllowed,
//         //   isExpirable: moduleData.isExpirable,
//         //   expiryDate: moduleData.expiryDate,
//         //   estimatedTime: moduleData.estimatedTime,
//         //   estimatedTimeDurationType: moduleData.estimatedTimeDurationType,
//         //   status: currentFormData?.values?.status,
//         //   pages: pageList,
//         //   forms: moduleData.forms,
//         // };

//         const reqBody = {
//           moduleId: moduleData.id,
//           id: currentFormData.values.id,
//           title: currentFormData.values.title,
//           description: currentFormData.values.description,
//           customTags: currentFormData.values.customTags,
//           pageContentType: currentFormData.values.pageContentType,
//           pageContentPath: currentFormData.values.pageContentPath,
//           singlePage: currentFormData.values.singlePage,
//           messages: currentFormData.values.messages,
//           downloadableFile: currentFormData.values.downloadableFile,
//           allowSkip: currentFormData.values.allowSkip,
//           status:currentFormData.values.status,
//         };
//         // console.log(reqBody,'reqBodyreqBodyreqBody');
//         setFormDataReady(false);
//         dispatch(addUpdatePageAsync(reqBody)).then((res) => {
//           ToasterAlertSucces(res, ErrrorMessage.UPDATE);
//           getModuleDataFunction(moduleData.id);
//           openCreatePageModal(false);
//         });
//       }

//       if (onSave) {
//         if (moduleData.id && moduleData.status !== "Template") {
//           const reqBody = {
//             id: null,
//             moduleId: moduleData.id,
//             title: currentFormData.values.title,
//             description: currentFormData.values.description,
//             customTags: currentFormData.values.customTags,
//             pageContentType: currentFormData.values.pageContentType,
//             pageContentPath: currentFormData.values.pageContentPath,
//             singlePage: currentFormData.values.singlePage,
//             messages: currentFormData.values.messages,
//             downloadableFile: currentFormData.values.downloadableFile,
//             allowSkip: currentFormData.values.allowSkip,
//             status:currentFormData.values.status,
//           };
//           console.log(reqBody,'reqBodyreqBodyreqBody');
//           dispatch(addUpdatePageAsync(reqBody)).then((res) => {
//             ToasterAlertSucces(res, ErrrorMessage.CREATE);
//             getModuleDataFunction(moduleData.id);
//             openCreatePageModal(false);
//           });
//         } else {
//           onSave(
//             currentFormData.values,
//             "pages",
//             pageData?.index,
//             pagesetMethod,
//             setTouched,
//             formTouchedData
//           );
//         }
//       }
//       openCreatePageModal(false);
//     }
//     setShowLoader(false);
//   };

  const onSubmit = async (status, index) => {
    setShowLoader(true);
    let currentFormData = formRef?.current;
    // console.log(file,'filefilefilefilefilefile');
    if (!(typeof file === "string" || file instanceof String)) {
        const formData_pageContentPath = new FormData();
        formData_pageContentPath.append("file", file);
        //  setIsUploading(true);
        if (file !== null) {
            // const responseFile = await fileUpload(formData_pageContentPath);
            // console.log(responseFile, 'responseFile');
            // currentFormData.values.pageContentPath = responseFile?.data?.fileName;
            currentFormData.values.pageContentPath = pageContentPathFileName;
            // setIsUploading(false);
            // currentFormData.values.pageContentPath = `${responseFile.data?.fileKey}${responseFile.data?.fileName}`;
        }
    } else {
        console.log(file,'filessssss');
        // currentFormData.values.pageContentPath = file?.name;
        currentFormData.values.pageContentPath = file;
    }

    // let arrayUploadedFiles = [];
    // console.log(currentFormData.values.downloadableFile,'currentFormData.values.downloadableFile');
    // if (currentFormData.values.downloadableFile) {
    //   // arrayUploadedFiles.push({ id: null, filePath: currentFormData.values.pageContentPath });
    //   arrayUploadedFiles.push(currentFormData?.values?.downloadableFile);
    // }
    // console.log(arrayUploadedFiles,'page vanish');
    // if (arrayUploadedFiles.length > 0) {
        // currentFormData.values.downloadableFile = arrayUploadedFiles;
        var errors = pageValidation(currentFormData.values);

        if (
          Object.keys(currentFormData.errors).length === 0 &&
          Object.keys(errors).length === 0 || status === 1
        ) {
          if (id) {
            const pageList = oldPageRemove(
              pageFormsList,
              id,
              currentFormData.values
            );
            // console.log(pageList,'pageListpageListpageListpageList');
            // const reqBody = {
            //   id: moduleData.id,
            //   title: moduleData.title,
            //   description: moduleData.description,
            //   customTags: moduleData.customTags,
            //   recommendedTime: moduleData.recommendedTime,
            //   recommendedTimeDurationType: moduleData.recommendedTimeDurationType,
            //   skipAllowed: moduleData.skipAllowed,
            //   isExpirable: moduleData.isExpirable,
            //   expiryDate: moduleData.expiryDate,
            //   estimatedTime: moduleData.estimatedTime,
            //   estimatedTimeDurationType: moduleData.estimatedTimeDurationType,
            //   status: currentFormData?.values?.status,
            //   pages: pageList,
            //   forms: moduleData.forms,
            // };
    
            const reqBody = {
              moduleId: moduleData.id,
              id: currentFormData.values.id,
              title: currentFormData.values.title,
              description: currentFormData.values.description,
              customTags: currentFormData.values.customTags,
              pageContentType: currentFormData.values.pageContentType,
              pageContentPath: currentFormData.values.pageContentPath,
              singlePage: currentFormData.values.singlePage,
              messages: currentFormData.values.messages,
              downloadableFile: currentFormData.values.downloadableFile,
              allowSkip: currentFormData.values.allowSkip,
              status:currentFormData.values.status,
            };
            // console.log(reqBody,'reqBodyreqBodyreqBody');
            setFormDataReady(false);
            dispatch(addUpdatePageAsync(reqBody)).then((res) => {
              ToasterAlertSucces(res, ErrrorMessage.UPDATE);
              getModuleDataFunction(moduleData.id);
              openCreatePageModal(false);
            });
          }
    
          if (onSave) {
            if (moduleData.id && moduleData.status !== "Template") {
              const reqBody = {
                id: null,
                moduleId: moduleData.id,
                title: currentFormData.values.title,
                description: currentFormData.values.description,
                customTags: currentFormData.values.customTags,
                pageContentType: currentFormData.values.pageContentType,
                pageContentPath: currentFormData.values.pageContentPath,
                singlePage: currentFormData.values.singlePage,
                messages: currentFormData.values.messages,
                downloadableFile: currentFormData.values.downloadableFile,
                allowSkip: currentFormData.values.allowSkip,
                status:currentFormData.values.status,
              };
              console.log(reqBody,'reqBodyreqBodyreqBody');
              dispatch(addUpdatePageAsync(reqBody)).then((res) => {
                ToasterAlertSucces(res, ErrrorMessage.CREATE);
                getModuleDataFunction(moduleData.id);
                openCreatePageModal(false);
              });
            } else {
              onSave(
                currentFormData.values,
                "pages",
                pageData?.index,
                pagesetMethod,
                setTouched,
                formTouchedData
              );
            }
          }
          openCreatePageModal(false);
        }
        setShowLoader(false);
    // }
  };


  const setPageData = async () => {
    let response = await dispatch(getModulePageDetailsAsync(id));
    setpageData(response?.payload);
    if (response?.payload) {
      pageType.map((v) => {
        if (v.value === response.payload.pageContentType) {
          setpagetype(v);
        }
      });
    }
    setFormDataReady(true);
  };

  useEffect(() => {
    setFormDataReady(true);
    if (optionPageType.length === 0) {
      dispatch(getPageTypeEnumListAsync());
    } else {
      const defaultValue = optionPageType.filter(
        (f) => f.value == (selectedData?.pageContentType ?? null)
      );
      // setpagetype({
      //   value: defaultValue[0].value,
      //   label: defaultValue[0].label,
      // });
      // }
    }

    if (selectedData === null) {
      setpageData({ pageContentType: null, singlePage: false, allowSkip:true });
    } else if (id != null) {
      setFormDataReady(false);
      setPageData();
    } else {
      setpageData(selectedData);
    }
  }, []);

  useEffect(() => {
    console.log(pageData,'pageDatapageDatapageData');
    if (pageData?.pageContentPath) {
      setFile(pageData.pageContentPath);
      setFileUploadShow(false);
    }
  }, [pageData]);

  const pageValidation = (values) => {
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    }
    if (!values.description || values.description.trim() === "") {
      errors.description = "Description is required!";
    } 

    // console.log(values.pageContentPath,'values.pageContentPath');
    if (!values?.pageContentPath || values.pageContentPath === undefined || values.pageContentPath === null) {
      errors.pageContentPath = "The content field cannot be empty. Please add content for your page.";
    }

    // else if  (!values.pageContentType || values.pageContentType !== 0) {
    //   // console.log(values.pageContentType)
    //   // setFile(null);
    //   errors.pageContentPath = "Please select the type!";
    // }
    else if (values.pageContentPath) {
      const errorMessage =
        values.pageContentType == 0
          ? isValidPPT(values?.pageContentPath)
          : values.pageContentType === 1
          ? isValidDocument(values?.pageContentPath)
          : values.pageContentType === 2
          ? isValidVideo(values?.pageContentPath)
          : values.pageContentType === 3
          ? isValidAudio(values?.pageContentPath)
          : values.pageContentType === 4
          ? isValidPhoto(values?.pageContentPath)
          : null;
      if (errorMessage !== true) {
        values.pageContentPath = null;
        errors.pageContentPath = errorMessage;
      }
    }
    if (!values.pageContentType && values.pageContentType !== 0) {
      errors.pageContentType = "Page type is required!";
    }
    if (!values.customTags || values.customTags.trim() === "") {
      errors.customTags = "Please add tags for your page.";
    }
    // console.log(errors,'errorserrorserrors');
    return errors;
  };

  const pageDraftValidation = (values) => {

    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    }
    return errors;
  };

  const validateTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun
  ) => {
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");
    if (pageData?.title === title) {
      setIsUniqueTitle(true);
    } else if (pagesList && pagesList.find((form) => form.title === title)) {
      setIsUniqueTitle(false);
      setTimeout(() => {
        setTouchedFun({ ...touchedFields, ["title"]: true });
        validateField("title");
      }, 1);
    }  else if (moduleFormRef === title) {
      setIsUniqueTitle(false);
      setTimeout(() => {
        setTouchedFun({ ...touchedFields, ["title"]: true });
        validateField("title");
      }, 1);
    } else if (formsList && formsList.find((form) => form.title === title)) {
      setIsUniqueTitle(false);
      setTimeout(() => {
        setTouchedFun({ ...touchedFields, ["title"]: true });
        validateField("title");
      }, 1);
    } else {
      setIsUniqueTitle(true);
    }
    // else if (title.trim() !== "") {
    //   const jsonData =
    //     pageData && pageData["id"]
    //       ? {
    //           id: pageData["id"] ?? null,
    //           name: title,
    //           type: "Page",
    //         }
    //       : {
    //           name: title,
    //           type: "Page",
    //         };

    //   await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
    //     setTitleFocused(false);
    //     setIsUniqueTitle(res?.payload ? true : false);
    //     setTimeout(() => {
    //       setTouchedFun({ ...touchedFields, ["title"]: true });
    //       validateField("title");
    //     }, 1);
    //   });
    // }
  };
  const debouncedValidateTitle = useDebounce(validateTitle, 1000);
  
  const onChangeFile = async (e, setMethod) => {
    const files = e.target.files; 
    const errorMessage = isDownloadableFile(files[0].name);
    setDownloadFileErrorMessage(errorMessage);

    if (errorMessage) {
      const currentFormData = formRef?.current;
      let existingValue = currentFormData.values?.downloadableFile ?? [];
      const arrayFiles = Array.from(files);
      // console.log(arrayFiles,'arrayFiles');

      try {
        setIsUploadings(true);
        const responses = await Promise.all(arrayFiles.map(async (value, index) => {
          const formData = new FormData();
          formData.append("file", value);
          
          return fileUpload(formData);
        }));

        responses.forEach(responseFile => {
          if (responseFile.data) {
            const profileImg = `${responseFile.data?.fileKey}${responseFile.data?.fileName}`;
            existingValue.push({ id: null, filePath: profileImg });
          }
        });

        // console.log(existingValue,'existingValue');
        setDownloadFileErrorMessage(null);
        setMethod && setMethod("downloadableFile", existingValue);
      } catch (error) {
        // console.error("Error uploading files:", error);
        setDownloadFileErrorMessage("Error uploading files. Please try again.");
      }
      setIsUploadings(false);
    } else {
      setDownloadFileErrorMessage(
        "The file is invalid or the file type is not allowed"
      );
    }
  };

  const onClickAddADownloadableFile = (e) => {
    console.log(isFileUpload,'isFileUpload');
    if (isFileUpload === true) {
      setIsStorageLimitReached(true);
    } else {
      e.preventDefault();
      refImageMultipleChoice.current.click();
    }
  };

  const onClickCrossDownloadableFile = (e, index, setMethod) => {
    e.preventDefault();
    let currentFormData = formRef?.current;
    const existingValue = currentFormData.values?.downloadableFile ?? [];
    existingValue.splice(index, 1);
    currentFormData.values.downloadableFile = existingValue;
    setMethod && setMethod("downloadableFile", existingValue);
  };

  const onClickCrossContentFile = (e, setMethod) => {
    e.preventDefault();
    setMethod("pageContentPath", "");
  };

  const onConfirm = () => {
    if (deleteModal) {
      setDeleteModal(false);
      onClickCrossDownloadableFile(
        selectedValueForDeleteDownloadableFile.e,
        selectedValueForDeleteDownloadableFile.index,
        selectedValueForDeleteDownloadableFile.setMethod
      );
    }
  };

  const onConfirmContentFile = () => {
    if (deletePageContentFileModal) {
      setDeletePageContentFileModal(false);
      onClickCrossContentFile(
        deleteContentFile.e,
        deleteContentFile.setFieldValue
      );
    }
  };

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };

  useEffect(() => {
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    if (storageData && storageData?.usedStorage >= storageData?.totalStorage) {
      setIsFileUpload(true);
    }
  }, [storageData]);

  const onFileUpload = async (e, setFieldValue) => {
    e.preventDefault();
    if (isFileUpload) {
      setIsStorageLimitReached(true);
    } else {
      // const file = e.target.files[0];
      // console.log(e,'filefilefilefile');
      // setFieldValue && setFieldValue("pageContentPath", file?.name);
      // setFile(file);
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'file_extension|audio/*|video/*|image/*|media_type'; // You can specify accepted file types      
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        // setFieldValue && setFieldValue("pageContentPath", file?.name);
        // setFile(file);
        if (file) {
          const formData = new FormData();
          formData.append("file", file);
          setIsUploading(true);
          const responseFile = await fileUpload(formData);
          if(responseFile.data){
            const pgContpath = `${responseFile.data?.fileKey}${responseFile.data?.fileName}`;
            setPageContentPathFileName(pgContpath)
            setFieldValue && setFieldValue("pageContentPath", pgContpath);
            setFile(file);
            setIsUploading(false);
          }
        }
      };
      fileInput.click();
    }

    // const responseFile = await fileUpload(formData)
    // if(responseFile.data){
    //   const reqBody = []
    //   reqBody.push(responseFile.data)
    //   const responseFileUrl = await generatePresignedUrl(reqBody)

    // }
  };
 console.log(isUploading, "isUploading")
  const mess = pageData?.messages?.map((m) => m.message);
  return (
    <>
      <Modal.Header closeButton onClick={() => onClosed(false)}>
        <Modal.Title> {getHeaderTitle(id)}</Modal.Title>
      </Modal.Header>
      {ftue && <div className="ftueOver"></div>}
      {formDataReady && (
        <Formik
          initialValues={{
            id: pageData?.id ?? undefined,
            title: pageData?.title,
            description: pageData?.description,
            customTags: pageData?.customTags,
            pageContentType: pageData?.pageContentType,
            pageContentPath: pageData?.pageContentPath,
            singlePage: pageData?.singlePage ?? false,
            downloadableFile: pageData?.downloadableFile,
            status:pageData?.status,
            message: mess?.length > 0 ? mess[mess?.length - 1] : "",
            allowSkip: pageData?.allowSkip ?? false,
          }}
          onSubmit={onSubmit}
          validate={pageValidation}
          innerRef={formRef}
        >
          {({
            errors,
            touched,
            isValidating,
            values,
            setFieldValue,
            setTouched,
            validateField,
          }) => {
            return (
              <Form style={{ display: "contents" }}>
                <div className="flex-grow-1 overflow-auto">
                  <Row className="g-0 d-flex h-100">
                    <Col className="col-lg-6">
                      <div className="p-32 pb-4">
                        <div className="input-group myInput mb-32">
                          <TextInput
                            name="title"
                            label="Title"
                            className="form-control"
                            placeholder="E.g HR Operations"
                            isRequired={true}
                            // onFocus={() => {
                            //   setTitleFocused(true);
                            // }}
                            // onBlur={(event) => {
                            //   validateTitle(
                            //     event?.target?.value,
                            //     validateField,
                            //     touched,
                            //     setTouched
                            //   );
                            // }}
                            onInput={(event) => {
                              debouncedValidateTitle(
                                event?.target?.value,
                                validateField,
                                touched,
                                setTouched
                              );
                            }}
                            maxLength={40}
                          />
                        </div>
                        <div className="input-group myInput mb-32 labelFor">
                          <TextArea
                            label="Description"
                            name="description"
                            className="form-control"
                            rows="3"
                            placeholder="Add text..."
                            isRequired={true}
                            maxLength={250}
                          />
                        </div>
                        <div className="mb-32">
                          <TagsInput
                            name="customTags"
                            inputTags={values?.customTags ?? ""}
                            onChange={onTagsUpdate}
                            setMethod={setFieldValue}
                            touchedMethod={setTouched}
                            touchedData={touched}
                          />

                          {touched.customTags && errors.customTags && (
                            <label
                              className="errorValidation"
                              style={{ color: "red" }}
                            >
                              {errors.customTags}
                            </label>
                          )}
                        </div>
                        <div className="form-group mySelect mb-32">
                          <label>
                            Page type{" "}
                            <label
                              className="mandatoryFields"
                              style={{ color: "red" }}
                            >
                              *
                            </label>
                          </label>
                          <Select
                            isSearchable={false}
                            name="pageContentType"
                            className="select"
                            options={optionPageType}
                            defaultValue={pagetype}
                            value={pagetype}
                            classNamePrefix="selectCustom"
                            // classNamePrefix="recatMySel"
                            onChange={(e) =>
                              onSelectationChange(
                                e,
                                "pageContentType",
                                setFieldValue
                              )
                            }
                          />
                          {touched.pageContentType &&
                            errors.pageContentType && (
                              <label
                                className="errorValidation"
                                style={{ color: "red" }}
                              >
                                {errors.pageContentType}
                              </label>
                            )}
                        </div>
                        <div className="row g-0 mb-32">
                          <div className="col-lg-12 fileChoos position-relative">
                            <p className="mb-2 h6 font-medium">
                              Page Contents{" "}
                              <label
                                className="mandatoryFields"
                                style={{ color: "red" }}
                              >
                                *
                              </label>
                            </p>
                            {!values?.pageContentPath ? (
                              <div className="form-group mySelect mb-4">
                                <TextInput
                                  name="pageContentPath"
                                  className="form-control"
                                  type="file"
                                  accept="file_extension|audio/*|video/*|image/*|media_type"
                                  isRequired={true}
                                  disabled={
                                    !values?.pageContentType &&
                                    values?.pageContentType !== 0
                                  }
                                  // onChange={(e)=>{
                                  //   let file = onFileUpload(e)
                                  //   setFieldValue("pageContentPath",e.target.files[0].name)
                                  // }}
                                  onClick={(e) =>
                                    onFileUpload(e, setFieldValue)
                                  }
                                  // onBlur={(event) => {
                                  //   validateTitle(
                                  //     event?.target?.value,
                                  //     validateField,
                                  //     touched,
                                  //     setTouched
                                  //   );
                                  // }}
                                />
                                <span
                                  style={{
                                    color: "red",
                                    fontSize: ".75rem",
                                  }}
                                >
                                  {/* {touched.pageContentPath && errors?.pageContentPath && (
                                    <label className="errorValidation" style={{ color: "red" }}>
                                      {errors.pageContentPath}
                                    </label> 
                                  )} */}
                                  {extensionErrorMessage}
                                </span>
                                {isUploading && (
                                  <div className="animateClass pageSpin">
                                    <SpinIcon />
                                  </div>
                                )}
                              </div>
                            ) : null}
                            {ftue ? (
                              <div
                                className="bg-base-100 ftuePoint outline-0 d-flex align-items-center gap-3  borderAll p-12 rounded-6 position-relative"
                                ref={target}
                              >
                                <div>
                                  <IconPageContent />
                                </div>
                                <div>
                                  <p className=" h6 text-gray-200">
                                    Intro company.ppt
                                  </p>
                                </div>
                              </div>
                            ) : (
                              values?.pageContentPath !== "" &&
                              values?.pageContentPath !== null &&
                              values?.pageContentPath !== undefined && (
                                <>
                                  <div className="namePDF mySelect d-flex align-items-center bg-gray-600 borderAll p-12 rounded-6 position-relative">
                                    <div className="iconPDF">
                                      <IconPageContent />
                                    </div>
                                    <div className="fullNamePDF">
                                      <p className=" h6 text-gray-200">
                                        {getRemainingStringFileName(
                                          values?.pageContentPath
                                        )}
                                      </p>
                                    </div>

                                    <div className="crossBtn">
                                      <Button
                                        variant="white"
                                        size="sm"
                                        className="text-gray-100 font-normal px-2 py-1 bg-base-100"
                                        onClick={(e) => {
                                          setDeletePageContentFileModal(true);
                                          setDeleteContentFile({
                                            e,
                                            setFieldValue,
                                          });
                                        }}
                                      >
                                        <IconCross />
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="createPageTick">
                                    <TickFill />
                                  </div>
                                </>
                              )
                            )}
                            <Overlay
                              target={target.current}
                              show={showTool}
                              placement="right"
                            >
                              {(props) => (
                                <Tooltip
                                  {...props}
                                  className="tootltipCreateModule "
                                >
                                  {/* <h1>Test</h1> */}
                                  <button className="crossBtn">
                                    <svg
                                      width="10"
                                      height="10"
                                      viewBox="0 0 10 10"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M9.65561 1.40533C9.9485 1.11244 9.9485 0.637563 9.65561 0.34467C9.36271 0.0517766 8.88784 0.0517766 8.59495 0.34467L5.00008 3.93954L1.40521 0.34467C1.11231 0.0517766 0.637441 0.0517766 0.344548 0.34467C0.0516546 0.637563 0.0516546 1.11244 0.344548 1.40533L3.93942 5.0002L0.344548 8.59507C0.0516546 8.88796 0.0516546 9.36283 0.344548 9.65573C0.637441 9.94862 1.11231 9.94862 1.40521 9.65573L5.00008 6.06086L8.59495 9.65573C8.88784 9.94862 9.36271 9.94862 9.65561 9.65573C9.9485 9.36283 9.9485 8.88796 9.65561 8.59507L6.06074 5.0002L9.65561 1.40533Z"
                                        fill="#232323"
                                      />
                                    </svg>
                                  </button>
                                  <div className="borderBottom">
                                    <div className="d-flex gap-4 align-items-center p-32 mt-2">
                                      <img src={uploadContent} alt="" />

                                      <div className="text-start">
                                        <h3 className="mb-12">
                                          Upload Content to be added in a page
                                        </h3>
                                        <p className="text-gray-300 h5 mb-2">
                                          Lorem ipsum dolor sit amet,
                                          consectetuer adipiscing elit. Aenean
                                          commodo ligula.
                                        </p>
                                      </div>
                                    </div>

                                    <div
                                      className="progress tableProgress bg-base-100"
                                      style={{ height: "4px" }}
                                    >
                                      <div
                                        className="progress-bar"
                                        role="progressbar"
                                        style={{ width: "90%" }}
                                      ></div>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center p-32 py-3 bg-gray-600">
                                    <p className="mb-0 h5">
                                      Press
                                      <span className="text-gray-300 font-medium">
                                        enter
                                      </span>
                                      to autofill
                                    </p>

                                    <div className="d-flex gap-2">
                                      <Button
                                        variant=""
                                        size="sm"
                                        className="font-medium text-gray-300 border-0"
                                      >
                                        PREV
                                      </Button>
                                      <Button variant="primary" size="sm">
                                        FINISH
                                      </Button>
                                    </div>
                                  </div>
                                </Tooltip>
                              )}
                            </Overlay>
                          </div>
                        </div>
                        {/* <div>
                          <p className="mb-20 h6 font-medium">
                            Do you want all the slides in a single page?
                          </p>
                          <div style={{ display: "inline-flex" }}>
                            <label className="customRadio mb-2 me-5">
                              <Field
                                type="radio"
                                name="singlePage"
                                value={true}
                                onChange={() =>
                                  setFieldValue("singlePage", true)
                                }
                              />
                              <span className="customRadioCheck"></span>
                              <span className="customRadioText h5 text-gray-300">
                                Yes
                              </span>
                            </label>
                            <label className="customRadio mb-2 me-5">
                              <Field
                                type="radio"
                                name="singlePage"
                                value={false}
                                onChange={() =>
                                  setFieldValue("singlePage", false)
                                }
                              />
                              <span className="customRadioCheck"></span>
                              <span className="customRadioText h5 text-gray-300">
                                No, All slides in different pages
                              </span>
                            </label>
                          </div>
                        </div> */}
                      </div>
                    </Col>
                    <Col className="col-lg-6">
                      <div className="p-32 pb-4">
                        <p className="font-bold mb-20">Page Resources</p>
                        <div className="mb-32">
                          <p className="h6 mb-12">Downloadable file</p>
                          {values?.downloadableFile !== undefined &&
                            values?.downloadableFile?.length > 0 &&
                            values?.downloadableFile?.map((m, index) => (
                              <>
                                <div
                                  className="row mb-20 g-0 position-relative"
                                  key={index}
                                >
                                  <div className="namePDF mySelect d-flex align-items-center bg-gray-600 borderAll p-12 rounded-6 position-relative">
                                    <div className="iconPDF">
                                      <IconPageContent />
                                    </div>
                                    <div className="fullNamePDF">
                                      <p className=" h6 text-gray-200">
                                        {getRemainingStringFileName(m.filePath)}
                                      </p>
                                    </div>

                                    <div className="crossBtn">
                                      <Button
                                        variant=""
                                        onClick={(e) => {
                                          setDeleteModal(true);
                                          setSelectedValueForDeleteDownloadableFile(
                                            { e, index, setFieldValue }
                                          );
                                        }}
                                      >
                                        <IconCross />
                                      </Button>
                                    </div>
                                  </div>
                                  <div className="downloadTickFill">
                                    <TickFill />
                                  </div>
                                </div>
                              </>
                            ))}
                          {/* <div className="row mb-20 g-0">
                            <div className="col-lg-5">
                              <div className="d-flex align-items-center gap-3 bg-gray-600 borderAll p-12 rounded-6 position-relative">
                                <IconPageContent />
                                <div>
                                  <p className=" h6 text-gray-200">
                                    Intro company.ppt
                                  </p>
                                </div>

                                <div className="crossBtn">
                                  <IconCross />
                                </div>
                              </div>
                            </div>
                          </div> */}
                          <div className="iconGroupRadio position-relative">
                            <input
                              type="file"
                              multiple
                              accept="image/*, .doc, .docx, .ppt, .pptx, video/*, audio/*, .pdf, .mov"
                              onChange={(e) => onChangeFile(e, setFieldValue)}
                              style={{ display: "none" }}
                              ref={refImageMultipleChoice}
                            />
                            {/* <p className="text-primary-100 bold">
                              {downloadableFileErrorMessage}
                            </p> */}
                            <p
                              style={{
                                color: "red",
                                fontSize: "0.625rem",
                                float: "left",
                                lineHeight: "normal",
                              }}
                            >
                              {downloadableFileErrorMessage}
                            </p>
                            <Button
                              variant=""
                              className="btnLink"
                              onClick={(e) => onClickAddADownloadableFile(e)}
                            >
                              <IconPlus /> Add a downloadable file
                            </Button>
                            {isUploadings && (
                              <div className="animateClass download">
                                <SpinIcon />
                              </div>
                            )}
                            <div className="">
                              <span
                                style={{
                                  color: "red",
                                  fontSize: "0.625rem",
                                  lineHeight: "0.1",
                                }}
                              >
                                Allowed types: jpeg, jpg, png, mp4, avi, m4v,
                                mpg, mp3, mov, doc, .docx, .pdf & pptx.
                              </span>
                            </div>
                          </div>
                        </div>
                        <div className="mb-32">
                          {/* <div className="input-group myInput messageInput">
                            <label className="m-0">Message from Author</label>
                            {values?.messages != undefined &&
                              values?.messages?.length > 0 &&
                              values?.messages?.map((m, index) => (
                                <div
                                  className="input-group myInput mb-20"
                                  //key={index}
                                >
                                  <TextInput
                                    name="messages"
                                    className="form-control"
                                    isRequired={true}
                                    //defaultValue={m.message}
                                  />
                                  <label
                                    style={{ color: "red" }}
                                    className={"errPageMessage"}
                                  ></label>
                                </div>
                              ))}
                          </div> */}
                          {/* {addMessage == true && ( */}
                          <div className="input-group myInput messageInput mb-20">
                            <label className="m-0">Message from Author</label>
                            <TextArea
                              name="message"
                              className="form-control"
                              rows="3"
                              placeholder="Add a message"
                              onBlur={(e) => onAddMessage(e, setFieldValue)}
                            />
                          </div>
                          {/* )} */}
                          {/* <p
                            className="text-primary-200 font-bold h6"
                            onClick={() => setAddMessage(true)}
                          >
                            <IconPlus /> Add a message
                          </p> */}

                          {/* <Button
                            variant=""
                            className="btnLink"
                            onClick={() => setAddMessage(true)}
                          >
                            <IconPlus /> Add a message
                          </Button> */}
                          <div className="form-check form-switch custom-switch ps-0 pe-1 d-flex">
                            {/* <label className="m-0">Skip Allowed</label> */}
                            {/* <ToggleButton
                              name="allowSkip"
                              value={values.allowSkip}
                              onToggleChange={(value) =>
                                setFieldValue("allowSkip", value)
                              }
                            /> */}
                            {/* <TextInput
                              name="allowSkip"
                              label="Skip Allowed"
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                              defaultChecked={true}
                              checked={values.allowSkip}
                              onChange={(event) =>
                                setFieldValue("allowSkip", event.target.checked)
                              }
                            /> */}
                            <TextInput
                              name="allowSkip"
                              label="Skip Allowed"
                              className="form-check-input"
                              type="checkbox"
                            />
                          </div>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
                <div className="d-flex gap-20 align-items-center btnFooter pe-4">
                  <Button
                    disabled={Object.keys(pageValidation(values)).length !== 0}
                    type="submit"
                    variant="secondary"
                    onClick={() => setFieldValue("status", 0)}
                    className="m-0"
                  >
                    {getSubmitTitle(id)}
                  </Button>
                  {/* <Button variant="white">Preview</Button> */}
                  {/* {(pageData?.status !== 0 || moduleData?.status !=="Published") &&( */}
                  {(pageData?.status === null ||
                    pageData?.status === 1 ||
                    moduleData?.status !== "Published") && (
                    <Button
                      disabled={
                        Object.keys(pageDraftValidation(values)).length !== 0
                      }
                      type="submit"
                      variant="noBg"
                      className="m-0"
                      onClick={() => {
                        setFieldValue("status", 1);
                        onSubmit(1, pageData?.index);
                        if (
                          pageData?.status !== 0 ||
                          moduleData?.status !== "Published"
                        ) {
                          setDraftCount(draftCount + 1);
                          hasUnpublishedContent(true);
                        }
                      }}
                    >
                      Save as draft
                    </Button>
                  )}
                </div>
              </Form>
            );
          }}
        </Formik>
      )}

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal || responsePopup?.show}
      >
        {deleteModal && (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onConfirm}
            requestType="Delete"
            headerLable="The selected file has been deleted successfully."
            chldLable="   The selected file will be permanently deleted."
          />
        )}
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deletePageContentFileModal || responsePopup?.show}
      >
        {deletePageContentFileModal && (
          <ModalDelete
            onCancel={setDeletePageContentFileModal}
            onConfirm={onConfirmContentFile}
            requestType="Delete"
            headerLable="Are you sure you want to delete the file?"
            chldLable="This file will be deleted."
          />
        )}
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isStorageLimitReached}
      >
        <ModalStorageLimitReachedPopup onClose={setIsStorageLimitReached} />
      </Modal>
      <> {showLoader && <Loader />}</>
    </>
  );
};

export default ModalCreatePage;
