import React from 'react'

export function IconsGallery() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.50008 18.3337H12.5001C16.6667 18.3337 18.3334 16.667 18.3334 12.5003V7.50033C18.3334 3.33366 16.6667 1.66699 12.5001 1.66699H7.50008C3.33341 1.66699 1.66675 3.33366 1.66675 7.50033V12.5003C1.66675 16.667 3.33341 18.3337 7.50008 18.3337Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M7.49992 8.33333C8.42039 8.33333 9.16659 7.58714 9.16659 6.66667C9.16659 5.74619 8.42039 5 7.49992 5C6.57944 5 5.83325 5.74619 5.83325 6.66667C5.83325 7.58714 6.57944 8.33333 7.49992 8.33333Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2.22485 15.7918L6.33319 13.0335C6.99152 12.5918 7.94152 12.6418 8.53319 13.1501L8.80819 13.3918C9.45819 13.9501 10.5082 13.9501 11.1582 13.3918L14.6249 10.4168C15.2749 9.85846 16.3249 9.85846 16.9749 10.4168L18.3332 11.5835" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
export function IconsVideo() {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M10.4417 17.0167H5.17508C2.54175 17.0167 1.66675 15.2667 1.66675 13.5084V6.49173C1.66675 3.8584 2.54175 2.9834 5.17508 2.9834H10.4417C13.0751 2.9834 13.9501 3.8584 13.9501 6.49173V13.5084C13.9501 16.1417 13.0667 17.0167 10.4417 17.0167Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M16.2669 14.2501L13.9502 12.6251V7.36678L16.2669 5.74178C17.4002 4.95011 18.3335 5.43344 18.3335 6.82511V13.1751C18.3335 14.5668 17.4002 15.0501 16.2669 14.2501Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M9.58325 9.16699C10.2736 9.16699 10.8333 8.60735 10.8333 7.91699C10.8333 7.22664 10.2736 6.66699 9.58325 6.66699C8.8929 6.66699 8.33325 7.22664 8.33325 7.91699C8.33325 8.60735 8.8929 9.16699 9.58325 9.16699Z" stroke="#888888" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export function IconsShortAnswer() {
    return (
        <svg width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4H14" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 8.44434H8.31333" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
export function IconsParagraph() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M2 4H14" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 8.44434H14" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 12.8887H11" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
export function IconsMultipleChoice() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle cx="8" cy="8" r="5.5" fill="white" stroke="#888888" stroke-width="5" />
        </svg>
    )
}
export function IconsCheckboxes() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="3.5" fill="#888888" stroke="#888888" />
            <path d="M6.67398 11.3337C6.48722 11.3337 6.3098 11.2592 6.17907 11.129L3.53641 8.49678C3.26561 8.22704 3.26561 7.78058 3.53641 7.51085C3.80722 7.24111 4.25544 7.24111 4.52624 7.51085L6.67398 9.65013L11.4737 4.86929C11.7445 4.59956 12.1927 4.59956 12.4635 4.86929C12.7343 5.13903 12.7343 5.58549 12.4635 5.85523L7.1689 11.129C7.03817 11.2592 6.86074 11.3337 6.67398 11.3337Z" fill="white" />
        </svg>
    )
}
export function IconsDropdown() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="0.5" y="0.5" width="15" height="15" rx="7.5" fill="#888888" stroke="#888888" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M12.8746 5.61562C12.7072 5.46146 12.4359 5.46146 12.2685 5.61562L8.00012 9.54702L3.73174 5.61562C3.56437 5.46146 3.29301 5.46146 3.12565 5.61562C2.95828 5.76977 2.95828 6.0197 3.12565 6.17386L7.69708 10.3844C7.77745 10.4584 7.88646 10.5 8.00012 10.5C8.11379 10.5 8.22279 10.4584 8.30317 10.3844L12.8746 6.17386C13.042 6.0197 13.042 5.76977 12.8746 5.61562Z" fill="white" />
        </svg>
    )
}
export function IconsFileUpload() {
    return (
        <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M12.7154 13.0547C13.7957 13.0622 14.8356 12.6865 15.6338 12.0102C18.27 9.86103 16.8592 5.54772 13.3845 5.14193C12.143 -1.87659 1.28371 0.783539 3.85544 7.46391" stroke="#888888" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.19316 7.77166C4.76588 7.56877 4.29023 7.46356 3.81459 7.47108C0.0577751 7.71906 0.0658367 12.8139 3.81459 13.0619" stroke="#888888" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M12.0778 5.45751C12.497 5.26213 12.9484 5.15693 13.416 5.14941" stroke="#888888" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M5.73596 9.77148L8.99831 6.73063L12.2607 9.77148" stroke="#888888" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M8.99829 15.2471V6.81583" stroke="#888888" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}
export function IconsDate() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M5.33331 0.833008C5.60946 0.833008 5.83331 1.05687 5.83331 1.33301V3.33301C5.83331 3.60915 5.60946 3.83301 5.33331 3.83301C5.05717 3.83301 4.83331 3.60915 4.83331 3.33301V1.33301C4.83331 1.05687 5.05717 0.833008 5.33331 0.833008Z" fill="#888888" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M10.6667 0.833008C10.9428 0.833008 11.1667 1.05687 11.1667 1.33301V3.33301C11.1667 3.60915 10.9428 3.83301 10.6667 3.83301C10.3905 3.83301 10.1667 3.60915 10.1667 3.33301V1.33301C10.1667 1.05687 10.3905 0.833008 10.6667 0.833008Z" fill="#888888" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M1.83331 6.05957C1.83331 5.78343 2.05717 5.55957 2.33331 5.55957H13.6666C13.9428 5.55957 14.1666 5.78343 14.1666 6.05957C14.1666 6.33571 13.9428 6.55957 13.6666 6.55957H2.33331C2.05717 6.55957 1.83331 6.33571 1.83331 6.05957Z" fill="#888888" />
            <path fill-rule="evenodd" clip-rule="evenodd" d="M3.15806 3.5899C2.73091 4.04991 2.5 4.74299 2.5 5.66634V11.333C2.5 12.2564 2.73091 12.9494 3.15806 13.4094C3.57899 13.8628 4.26197 14.1663 5.33333 14.1663H10.6667C11.738 14.1663 12.421 13.8628 12.8419 13.4094C13.2691 12.9494 13.5 12.2564 13.5 11.333V5.66634C13.5 4.74299 13.2691 4.04991 12.8419 3.5899C12.421 3.1366 11.738 2.83301 10.6667 2.83301H5.33333C4.26197 2.83301 3.57899 3.1366 3.15806 3.5899ZM2.42527 2.90945C3.08768 2.19608 4.07137 1.83301 5.33333 1.83301H10.6667C11.9286 1.83301 12.9123 2.19608 13.5747 2.90945C14.2309 3.61611 14.5 4.58969 14.5 5.66634V11.333C14.5 12.4097 14.2309 13.3832 13.5747 14.0899C12.9123 14.8033 11.9286 15.1663 10.6667 15.1663H5.33333C4.07137 15.1663 3.08768 14.8033 2.42527 14.0899C1.76909 13.3832 1.5 12.4097 1.5 11.333V5.66634C1.5 4.58969 1.76909 3.61611 2.42527 2.90945Z" fill="#888888" />
        </svg>
    )
}
export function IconsTime() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.6666 7.99967C14.6666 11.6797 11.68 14.6663 7.99998 14.6663C4.31998 14.6663 1.33331 11.6797 1.33331 7.99967C1.33331 4.31967 4.31998 1.33301 7.99998 1.33301C11.68 1.33301 14.6666 4.31967 14.6666 7.99967Z" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M10.4734 10.1202L8.40669 8.88684C8.04669 8.6735 7.75336 8.16017 7.75336 7.74017V5.00684" stroke="#888888" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

export function IconsCross() {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M15.406 1.65533C15.6989 1.36244 15.6989 0.887563 15.406 0.59467C15.1131 0.301777 14.6382 0.301777 14.3453 0.59467L8.00033 6.93967L1.65533 0.59467C1.36244 0.301777 0.887563 0.301777 0.59467 0.59467C0.301777 0.887563 0.301777 1.36244 0.59467 1.65533L6.93967 8.00033L0.59467 14.3453C0.301777 14.6382 0.301777 15.1131 0.59467 15.406C0.887563 15.6989 1.36244 15.6989 1.65533 15.406L8.00033 9.06099L14.3453 15.406C14.6382 15.6989 15.1131 15.6989 15.406 15.406C15.6989 15.1131 15.6989 14.6382 15.406 14.3453L9.06099 8.00033L15.406 1.65533Z" fill="#888888" />
        </svg>
    )
}

