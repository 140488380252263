import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import AccountImg from "../../assets/images/Account.png";
import PageHeader from "../../components/PageHeader/PageHeader";
import Filters from "../../components/Filters/Filters";
import CheckBox from "../../components/CheckBox/CheckBox";
import Actions from "./Actions";
import Loader from "../../components/Loader/Loader";
import { getIssueStatusListAsync } from "../../reducers/common-reducers";
import {
  getReportedAsync,
  markAsResolvedReportedIssueAsync,
  markAsUnResolvedReportedIssueAsync,
  bulkMarkAsResolvedReportedIssueAsync,
  bulkMarkAsUnResolvedReportedIssueAsync,
  updateReportedAsync,
} from "../../reducers/reportedIssue-reducers";
import { getFormatDate, Status, prepareSortFilterRequest } from "../../common";
import {
  IconDelete,
  IconArchive,
  IconChecked,
  IconStatus,
  IconRequest,
} from "../../icons/Icons";
import ModalDelete from "../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../components/ModalPopup/ModalResponsePopup";

import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import EmptyState from "../../components/EmptyState/EmptyState";
import UserImage from "../../components/UserImage/UserImage";
import getInitialsInName from "../../helper/GetNameInitials";

import Pagination from "../../components/Pagination/Pagination";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import {
  getSubscriptionAsync,
  getPlansAsync,
} from "../../reducers/subscription-reducers";
import { useDebounce } from "../../utils/hooks/useDebounce";
import RestrictedUpgradeToBusinessModalPopup from "../../components/ModalPopup/RestrictedUpgradeToBusinessModalPopup";

const popover = (
  <Popover id="popover-basic" style={{ position: "fixed" }}>
    <Popover.Body>
      Lorem ipsum dolor sit amet consectetur <b>adipisicing </b>elit. Lorem
      ipsum dolor sit amet.
    </Popover.Body>
  </Popover>
);
const issueType = [
  { text: "Page Content not Visible", value: 'Page Content not Visible' },
  { text: "Sound Problem", value: 'Sound Problem' },
  { text: "Video not Playing", value: 'Video not Playing' },
  // { text: "Caption or Subtitle", value: 'Caption or Subtitle' },
  { text: "Others", value: 'Others' },
  // { text: "Page Content not Visible", value: "Page_content_not_visible" },
  // { text: "Sound Problem", value: "Sound_problem" },
  // { text: "Video not Playing", value: "Video_not_playing" },
  // { text: "Caption or Subtitle", value: "Caption_subtitle" },
];

const reportedIssueSortby = [
  { text: "Request sent : Latest", value: "CreatedDate_desc" },
  { text: "Request sent : Earliest", value: "CreatedDate_asc" },
  // { text: "Status ascending", value: "status_asc" },
  // { text: "Status descending", value: "status_dsc" },
  { text: "Sent by ascending", value: "CreatedByName_asc" },
  { text: "Sent by descending", value: "CreatedByName_dsc" },
];
const ReportedIssues = () => {
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData } = useSelector(
    (state) => state.subscription
  );
  const [userPlan, setUserPlan] = useState('');
  const [isAssignButtons, setIsAssignButton] = useState(true);

  const [searchString, setSearchString] = useState("");
  const [selectedStatus, setStatusList] = useState([]);
  const [selectedIssue, setIssueList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [requestOnDate, setRequestOnDate] = useState("");
  const [pageSize, setPageSize] = useState(50);
  const [startDate, setstartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [formDataReady, setFormDataReady] = useState(false);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });

  const [responsePopup, setResponsePopup] = useState({});
  const [resolvedModal, setResolvedModal] = useState(false);
  const [unResolvedModal, setUnResolvedModal] = useState(false);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [filters, setFilters] = useState({});
  const [isLoading, setIsloading] = useState(false);
  const [filterss, setFilterss] = useState(false);
  const [checkAll, setCheckAll] = useState(false);

  const [isBulkUpdate, setIsBulkUpdate] = useState(false);
  const [isRestrictedModalPopup, setIsRestrictedModalPopup] = useState(false);

  const tableRef = useRef(null);

  const scrollToTop = () => {
    if (tableRef.current) {
      tableRef.current.scrollTop = 0;
    }
  };
  

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
    scrollToTop();
  };

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubscriptionAsync());
}, [dispatch]);

  useEffect(() => {
    // console.log(subscriptionData,'subscriptionData');
    const hasSubscriptions = subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems = hasSubscriptions && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      console.log(basePlan,'basePlanbasePlanbasePlanbasePlan');
      if (basePlan && basePlan?.length > 0) {
        setUserPlan(basePlan[0]?.planName);
        setIsBulkUpdate(basePlan[0]?.metaData?.bulk_update_status === "Yes");
      }
    }
  }, [
    subscriptionData,
  ]);

  const onDateRangeChange = (start, end) => {
    setFilterss(true);
    setstartDate(start);
    setEndDate(end);
    setCurrentPage(1);
  };

  const getReportedIssue = () => {
    setIsloading(true);
    setFormDataReady(false);
    let reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      searchString: searchString,
      status: selectedStatus,
      issueType: selectedIssue,
      // issueType: selectedIssueType,
      // tags: [],
      requestOnStart: startDate || undefined,
      requestOnEnd: endDate || undefined,
    };
    dispatch(getReportedAsync(reqBody)).then((res) => {
      setFormDataReady(true);
      setSelectAll(false);
      setIsloading(false);
      // console.log(res,'selectAll');
      // if (selectAll) {
      //   const pageSelectedItems = res?.payload?.records.map((c) => c?.id);
  
      //   setSelectedItemList(pageSelectedItems);
      //   setSelectAll(pageSelectedItems.length === res?.payload?.records.length);
      // }
    });
  };

  useEffect(() => {
    if (searchString) {
      setFilterss(true);
      setCurrentPage(1);
    }
  }, [searchString]);

  const statusList = useSelector((state) => state.common.issueTypeList);
  const entities = useSelector((state) => state.reportedIssue.reportedIssues);

  useEffect(() => {
    // Filter the records where status is "Resolved"
    const resolvedRecords = entities?.records.filter(record => record.status === "Resolved");
    if (resolvedRecords.length === entities?.totalRecords) {
      setCheckAll(true);
    } else {
      setCheckAll(false);
    }
  }, [entities]); 

  const debouncedReportedIssue = useDebounce(getReportedIssue, 1000);
  useEffect(() => {
    // getReportedIssue();
    debouncedReportedIssue();
    if (statusList.length === 0) {
      setIsloading(true);
      dispatch(getIssueStatusListAsync()).then((res) => {
        setIsloading(false);
      });
    }
    scrollToTop();
  }, [
    dispatch,
    currentPage,
    pageSize,
    sortColumn,
    searchString,
    selectedStatus,
    selectedIssue,
    startDate,
    endDate,
  ]);

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
    getReportedIssue();
  };

  const processBulkActResponse = (res, operation) => {
    if (!res.payload) {
      setResponsePopup({
        show: true,
        propmt: "Error",
        message: res.error.message,
        records: [],
        procesFailedError: "",
      });
      return;
    }
    let prompt =
      res.payload?.proceedData &&
      res.payload?.totalRequest &&
      res.payload?.proceedData === res.payload?.totalRequest
        ? "Success"
        : "Error";

    let successMessage =
      operation === "Resolved"
        ? `All selected records are marked as resolved successfully! `
        : operation === "Unresolved"
        ? `All selected records are marked as unresolved successfully! `
        : "";
    successMessage += `(${res.payload?.proceedData}/${res.payload?.totalRequest})`;

    let message =
      res.payload?.proceedData === res.payload?.totalRequest
        ? successMessage
        : `${operation} Failed (${
            res.payload?.totalRequest - res.payload?.proceedData
          }/${res.payload?.totalRequest})`;

    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records:
        res.payload?.failexcutionResponse &&
        res.payload?.failexcutionResponse?.length > 0
          ? res.payload?.failexcutionResponse
          : [],
      procesFailedError: res.payload?.procesFailedError,
    });
  };

  const onConfirm = () => {
    if (selectedItemList !== null && selectedItemList.length > 0) {
      let reqBody = {
        ids: selectedItemList,
      };

      setIsloading(true);
      if (resolvedModal) {
        dispatch(bulkMarkAsResolvedReportedIssueAsync(reqBody)).then((res) => {
          setIsloading(false);

          if (res && res.payload) {
            processBulkActResponse(res, "Resolved");
          } else {
            processBulkActResponse(res, "Error");
          }
        });
        setResolvedModal(false);
      } else if (unResolvedModal) {
        dispatch(bulkMarkAsUnResolvedReportedIssueAsync(reqBody)).then(
          (res) => {
            if (res && res.payload) {
              processBulkActResponse(res, "Unresolved");
            } else {
              processBulkActResponse(res, "Error");
            }
          }
        );
        setUnResolvedModal(false);
      }
      setIsloading(false);
      setSelectedItemList([]);
      
      setSelectAll(false);
    }
  };
  const onResolveUnresolveClick = (id, status) => {
    if (id && id != "") {
      let reqBody = {
        id: id,
      };
      setIsloading(true);
      if (status) {
        dispatch(markAsResolvedReportedIssueAsync(reqBody)).then((res) => {
          setIsloading(false);
          if (res?.payload) {
            let t = { ...entities };
            let tempRecords = [];
            t.records?.map((item) => {
              let test = { ...item };
              if (item.id == id) {
                test.status = "Resolved";
              }
              tempRecords.push(test);
            });
            t.records = tempRecords;
            dispatch(updateReportedAsync(t));
          }
        });
      } else {
        dispatch(markAsUnResolvedReportedIssueAsync(reqBody)).then((res) => {
          setIsloading(false);
          if (res?.payload) {
            let t = { ...entities };
            let tempRecords = [];
            t.records?.map((item) => {
              let test = { ...item };
              if (item.id == id) {
                test.status = "Unresolved";
              }
              tempRecords.push(test);
            });
            t.records = tempRecords;
            dispatch(updateReportedAsync(t));
          }
        });
      }
    }
  };

  const ststusChange = (id, newStstus) => {
    // console.log(entities, "entities")
    setFilterss(true);
    let selectedItems = [...selectedItemList];
    if (id == "All") {
      selectedItems = [];
      if (newStstus) {
        // selectedItems = entities.records.map((c) => c?.id);
        selectedItems = entities.records.filter((record) => record.status === 'Unresolved').map((record) => record.id);
      }
      setSelectedItemList(selectedItems);
      setSelectAll(!selectAll);
    } else {
      let index = -1;
      index = selectedItems.indexOf(id);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      } else {
        selectedItems.push(id);
      }
      const unResolvedRecords = entities?.records.filter(record => record.status === "Resolved");
      const checkAllStatus = (unResolvedRecords?.length + selectedItems?.length);
      // console.log(checkAllStatus,'checkAllStatus');
      // console.log(unResolvedRecords,'unResolvedRecords');
      // console.log(selectedItems,'selectedItems');
      // console.log(entities,'entitiesentities');
      setSelectedItemList(selectedItems);
      setIsAssignButton(true);
      // setSelectAll(selectedItems.length === entities.records.length);
      setSelectAll(checkAllStatus === entities.records.length);
      
      if (userPlan === "Startup" && selectedItems.length === 1) {
        setIsAssignButton(false);
      } 
      // else{
      //   setIsAssignButton(false);
      // }

    }
  };
  const [selectedIssueType, setSelectedIssueType] = useState(null);
  const onFilterChange = (value, type) => {
    // console.log(value,'value');
    setFilterss(true);
    if (type === "sortBy") {
      setSortColumn(prepareSortFilterRequest(value));
    } else if (type === "status") {
      setStatusList(value);
    } else if (type === "issuetype") {
      const updatedArray = value.map((item) => {
        if (item === 'Page Content not Visible')
        {
          return 0;
        } 
        if (item === 'Sound Problem') {
          return 1;
        }
        if (item === 'Video not Playing') {
          return 2;
        }
        // if (item === 'Caption or Subtitle') {
          if (item === 'Others') {
          return 3;
        }
        // return item;
      });
      // console.log(updatedArray,'updatedArray');
      setIssueList(updatedArray);
    }
    setCurrentPage(1);
    scrollToTop();
  };
  const onClearAll = () => {
    // setSearchString("");
    setFilterss(false);
    setStatusList([]);
    setIssueList([]);
    setCurrentPage(1);
    setPageSize(50);
    setRequestOnDate("");
    setSortColumn({
      column: "",
      sortDirection: "",
    });
    setstartDate(undefined);
    setEndDate(undefined);
    scrollToTop();
  };
  const getTextByIssueTypeId = (issueTypeId) => {
    if (issueTypeId === 0)
    {
      return 'Page Content not Visible';
    } 
    if (issueTypeId === 1) {
      return 'Sound Problem';
    }
    if (issueTypeId === 2) {
      return 'Video not Playing';
    }
    if (issueTypeId === 3) {
      // return 'Caption or Subtitle';
      return 'Others';
    }
    const matchingIssue = issueType.find(
      (issue) => issue.value === issueTypeId
    );
    return matchingIssue ? matchingIssue.text : "Unknown";
  };

  console.log(entities,'entities?.totalRecords');
  return (
    <>
      <div className="pages">
        {/* <PageHeader title="Reported Issues"  setSearchString={setSearchString}/> */}
        <PageHeader title="Reported Issues" searchString={searchString} onBlur={setSearchString} placeHolder="Search by Page title" />
        <div className="pageOuter">
          <Container fluid>
            <div className="page d-flex">
              <Filters
                onChange={onFilterChange}
                statusList={statusList}
                issueType={issueType}
                // datepicker={{
                //   lable: "Request sent on",
                //   value: requestOnDate,
                //   onDateChange: { setRequestOnDate: setRequestOnDate },
                // }}
                dateRange={{
                  lable: "Request sent on",
                  start: startDate,
                  end: endDate,
                  onDateChange: { onDateRangeChange: onDateRangeChange },
                }}
                onClearAll={onClearAll}
                sortBy={reportedIssueSortby}
              />
              <div className="filtersContent">
                <div className="pageActions p-32 py-3 p20">
                <Row className="mx-0">
                  {/* <Col className="px-0" sm={6}>
                    <Actions
                      placeHolder="Search by Page title"
                      onBlur={setSearchString}
                    />
                  </Col> */}
                  <Col className="px-0" sm={6}>
                    <div className="">
                      <div className="dataItem userAllToatal d-flex align-items-center">
                        <Pagination
                          totalItems={entities?.allRecordsCount}
                          itemsPerPage={pageSize}
                          onPageChange={onPageChange}
                          setFilterss={setFilterss}
                          filterss={filterss}
                          pageTitle={"Reported issues"}
                        />
                      </div>
                    </div>
                  </Col>
                  <Col className="px-0 col-sm-6" sm={6}>
                    <div className="d-flex justify-content-end">
                      {/* {selectedItemList && selectedItemList.length > 0 && ( */}
                      {/* {selectedItemList && (
                        <> */}
                          <Button
                            variant=""
                            className="btn-secondary btn-sm ms-12"
                            onClick={() => {
                              !isBulkUpdate && selectedItemList.length > 1 ? setIsRestrictedModalPopup(true) : setResolvedModal(true);
                            }}
                            disabled={isAssignButtons && selectedItemList && selectedItemList.length === 0}
                          > Mark as resolved
                            {/* <div className="d-flex gap-2 align-items-center">
                              <IconStatus />
                              <p className="h6 text-gray-300">
                                Mark as resolved
                              </p>
                            </div> */}
                          </Button>
                          {/* <Button
                            variant=""
                            className="border-0 py-0"
                            onClick={() => setUnResolvedModal(true)}
                          >
                            <div className="d-flex gap-2 align-items-center">
                              <IconArchive />
                              <p className="h6 text-gray-300">
                                Mark as Unresolved
                              </p>
                            </div>
                          </Button> */}
                        {/* </>
                      )} */}
                    </div>
                  </Col>
                </Row>
                </div>
                <div className="tableComponent prefTable flex-grow-1 overflow-auto" ref={tableRef}>
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <CheckBox
                            text=""
                            id={"All"}
                            ststusChange={ststusChange}
                            status={selectAll}
                            disabled={checkAll}
                          />
                        </th>
                        <th>REQUEST TYPE</th>
                        <th>REQUEST SENT ON</th>
                        <th>SENT BY</th>
                        <th>ISSUE DESCRIPTION</th>
                        <th>CONTACT DETAILS</th>
                        <th>STATUS</th>
                        {/* <th></th> */}
                      </tr>
                    </thead>
                    <tbody>
                      {entities?.records !== null &&
                        entities?.records.length > 0 &&
                        entities.records
                          ?.filter((c) => {
                            const d = new Date(
                              new Date(requestOnDate).toDateString()
                            );
                            const createdDate = new Date(
                              new Date(c.createdDate).toDateString()
                            );
                            return (
                              d.getTime() == createdDate.getTime() ||
                              !requestOnDate
                            );
                          })
                          ?.map((c, index) => (
                            <tr key={c.id}>
                              <td align="middle">
                                {/* {c.status !== "Resolved" ? */}
                                <CheckBox
                                  id={c.id}
                                  ststusChange={ststusChange}
                                  status={selectedItemList.includes(c.id)}
                                  disabled={c.status === "Resolved" ? true : false}
                                />
                                {/* : null } */}
                              </td>
                              <td>
                                {/* {c.description ? ( */}
                                <OverlayTrigger
                                  trigger={["hover", "focus"]}
                                  rootClose
                                  placement="auto"
                                  overlay={
                                    <Popover
                                      id="popover-basic"
                                      className="cusWidthpop"
                                      style={{ position: "fixed" }}
                                    >
                                      <Popover.Body>
                                        {/* {c.description} */}
                                        {/* <span class="d-block font-medium text-primary-200 mt-1">{c.pageFromTitle}</span>
                                          {c.issueTypeDetailList && c.issueTypeDetailList.map((c, index) => (
                                          <div className="popMoresec">
                                            <span class="d-block font-medium text-gray-100">{c.issueTypeId}</span>
                                            <span class="d-block font-noraml text-md text-gray-300 pt-1">{c.issueTypeDescription
                                          }</span>
                                          </div>
                                          ))} */}
                                        <span className="d-block font-medium text-primary-200 mt-1">
                                          {c.pageFromTitle}
                                        </span>
                                        {c.issueTypeDetailList &&
                                          c.issueTypeDetailList.map(
                                            (item, index) => (
                                              <div
                                                key={index}
                                                className="popMoresec"
                                              >
                                                <span className="d-block font-medium text-gray-100">
                                                  {getTextByIssueTypeId(
                                                    item.issueTypeId
                                                  )}
                                                </span>
                                                <span className="d-block font-normal text-md text-gray-300 pt-1">
                                                  {item.issueTypeDescription}
                                                </span>
                                              </div>
                                            )
                                          )}
                                      </Popover.Body>
                                    </Popover>
                                  }
                                  container={this}
                                >
                                  <div>
                                    <div className="descrp">
                                      {c.pageFromTitle}
                                    </div>
                                    <span className="d-block font-medium text-primary-200 mt-1">
                                      {/* {c.issueTypeId_str} */}
                                      {c.issueTypeDetailList &&
                                        c.issueTypeDetailList[0] && (
                                          <>
                                            {getTextByIssueTypeId(
                                              c.issueTypeDetailList[0]
                                                .issueTypeId
                                            )}
                                          </>
                                        )}
                                    </span>
                                  </div>
                                </OverlayTrigger>
                                {/* ) : ( */}
                                {/* <div className="descrp">{c.description}</div> */}
                                {/* )} */}
                              </td>

                              <td>
                              {getFormatDate(c.createdDate)}
                              {/* <DateTimeConverter utcDateTime={c.createdDate} /> */}
                              </td>

                              <td>
                                {c.createdUserDetail &&
                                  c.createdUserDetail !== undefined && (
                                    <>
                                      <div className="d-flex align-items-center gap-2">
                                        <div className="tableImg">
                                          {c.createdUserDetail
                                            ?.profileImagePath ? (
                                            <UserImage
                                              imgKey={
                                                c?.createdUserDetail
                                                  ?.profileImagePath
                                              }
                                            />
                                          ) : (
                                            <img
                                              src={getInitialsInName(
                                                c?.createdUserDetail?.name ??
                                                  "N A",
                                                24
                                              )}
                                              alt={c?.createdUserDetail?.name}
                                            />
                                          )}
                                          {/* <img src={AccountImg} alt="" /> */}
                                        </div>
                                        <p className="text-nowrap mb-0">
                                          {c.createdUserDetail.name}
                                        </p>
                                      </div>
                                    </>
                                  )}
                              </td>

                              <td>
                                <>
                                  {c.description.length > 9 ? (
                                    <OverlayTrigger
                                      trigger={["hover", "focus"]}
                                      rootClose
                                      placement="top"
                                      overlay={
                                        <Popover
                                          id="popover-basic"
                                          style={{ position: "fixed" }}
                                        >
                                          {/* <Popover.Header>
                                              <h5>Issue Description</h5>
                                              {c.createdUserDetail && c.createdUserDetail !== undefined && (
                                                <>
                                                  <div className="d-flex align-items-center gap-2">
                                                    <div className="tableImg">
                                                      <img src={AccountImg} alt="" />
                                                    </div>
                                                    <p className="text-nowrap mb-0">
                                                      {c.createdUserDetail.name}
                                                    </p>
                                                  </div>
                                                </>
                                              )}
                                            </Popover.Header> */}

                                          <Popover.Body>
                                            {c.description}
                                          </Popover.Body>
                                        </Popover>
                                      }
                                      container={this}
                                    >
                                      <span className="font-medium text-primary-200 mt-1 textEclipsis">
                                        {c.description}
                                      </span>
                                    </OverlayTrigger>
                                  ) : (
                                    <span className="font-medium text-primary-200 mt-1">
                                      {c.description}
                                    </span>
                                  )}
                                </>
                              </td>

                              <td>
                                {c.createdUserDetail &&
                                  c.createdUserDetail !== undefined && (
                                    <>
                                      <a
                                        className="font-medium text-primary-200"
                                        href={
                                          "mailto:" +
                                          c.createdUserDetail?.companyEmail
                                        }
                                      >
                                        {c.createdUserDetail?.companyEmail}
                                      </a>
                                      <br />
                                      <a
                                        className="font-medium text-primary-200 mt-1"
                                        href={
                                          "tel:" +
                                          c.createdUserDetail?.phoneNumber
                                        }
                                      >
                                        {c.createdUserDetail?.phoneNumber}
                                      </a>
                                    </>
                                  )}
                              </td>

                              <td>
                                <span
                                  className={`tableTag ${
                                    Status[c.status].color
                                  } text-md  `}
                                >
                                  {c.status}
                                </span>
                              </td>
                              {/* <td>
                                <div className="reportIssuesAct">
                                {c.status !== "Resolved" ?
                                  <CheckBox
                                    text={
                                      c.status == "Resolved"
                                        ? "Mark as unresolved"
                                        : "Mark as resolved"
                                    }
                                    id={c.id}
                                    ststusChange={onResolveUnresolveClick}
                                    status={c.status == "Resolved"}
                                  /> : null }
                                </div>
                              </td> */}
                            </tr>
                          ))}
                    </tbody>
                  </table>
                  {!entities?.records?.length > 0 && formDataReady === true ? (
                    <EmptyState message={"No report found"} />
                  ) : null}
                </div>
                {/* <div className="pageActions paginationMax">
                  <Row className="mx-0">
                    <Pagination
                      totalItems={entities?.allRecordsCount}
                      itemsPerPage={pageSize}
                      onPageChange={onPageChange}
                      setFilterss={setFilterss}
                      filterss={filterss}
                    />
                  </Row>
                </div> */}
              </div>
            </div>
          </Container>
          <> {isLoading === true && <Loader />}</>
        </div>
      </div>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={resolvedModal || unResolvedModal || responsePopup?.show}
      >
        {resolvedModal ? (
          <ModalDelete
            onCancel={setResolvedModal}
            onConfirm={onConfirm}
            requestType="Resolved"
            headerLable="Are you sure want to mark as resolved selected issues."
            chldLable="The selected issues will be marked as resolved."
          />
        ) : (
          unResolvedModal && (
            <ModalDelete
              onCancel={setUnResolvedModal}
              onConfirm={onConfirm}
              requestType="Unresolved"
              headerLable="Are you sure want to mark as unresolved selected issues."
              chldLable="The selected issues will be marked as unresolved."
            />
          )
        )}
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={isRestrictedModalPopup}>
        <RestrictedUpgradeToBusinessModalPopup
          onCancel={setIsRestrictedModalPopup}
          headerLable={""}
          chldLable={""}
        />
      </Modal>
    </>
  );
};

export default ReportedIssues;
