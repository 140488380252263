import React, { useState, useEffect } from "react";
import AccountImg from "../../../assets/images/employeeDp.png";
import Loader from "../../../components/Loader/Loader";
import {
  IconTopSearch,
  IconEmployeeAccount,
  IconEmployeeCall,
  IconEmployeeMail,
  IconEmployeeDate,
} from "../../../icons/Icons";
import { useDispatch } from "react-redux";
import Search from "../../../components/Search/Search";
import AddUserEmployeeModal from "./../../Users/AddUserEmployeeModal";
import {
  getUserDetailsAsync,
  unassignContentToUsersAsync,
  getUserProfileAsync,
} from "../../../reducers/user-reducers";
import { getFormatDate, UserProfileTimeZone } from "../../../common";
import { Modal } from "react-bootstrap";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../../components/ModalPopup/ModalResponsePopup";
import AssignConenteeModal from "./../../Users/AssignConenteeModal";
import UserImage from "../../../components/UserImage/UserImage";
import getInitialsInName from "../../../helper/GetNameInitials";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";

const ModalEmployeeActivity = ({ handleModal, userDetails, onClosed }) => {
  const [addUserEmployee, setAddUserEmployee] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const [serachString, setSerachString] = useState("");
  const [assignModel, setAssignModel] = useState(false);
  const [unassignModel, setUnassignModel] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [responsePopup, setResponsePopup] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();

  const onInputChange = (value) => {
    setSerachString(value);
  };

  const getUserDetails = () => {
    setShowLoader(true);
    if (userDetails) {
      dispatch(getUserDetailsAsync(userDetails.id)).then((res) => {
        if (res) {
          setUserData(res?.payload);
          setShowLoader(false);
        }
      });
    }
  };
  const userBulkActResponse = (res, operation) => {
    let successMessage =
      operation === "Unassign"
        ? `The selected content has been unassigned successfully.`
        : "";
    successMessage += `(${res?.proceedData}/${res?.totalRequest})`;

    let prompt =
      res?.proceedData &&
      res?.totalRequest &&
      res?.proceedData === res?.totalRequest
        ? "Success"
        : "Error";
    // prompt = "Success";
    let message =
      res?.proceedData === res?.totalRequest
        ? successMessage
        : `${operation} Failed (${res?.totalRequest - res?.proceedData}/${
            res?.totalRequest
          })`;
    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records:
        res?.failexcutionResponse && res?.failexcutionResponse?.length > 0
          ? res?.failexcutionResponse
          : [],
      procesFailedError: res?.procesFailedError,
    });
    setUnassignModel(false);
    getUserDetails();
  };

  const onConfirm = () => {
    setUnassignModel(false);
    if (selectedItemList && selectedItemList.length > 0) {
      setShowLoader(true);
      const reqBody = {
        assignmentIds: selectedItemList,
      };
      dispatch(unassignContentToUsersAsync(reqBody)).then((res) => {
        userBulkActResponse(res?.payload, "Unassign");
        getUserDetails();
        setShowLoader(false);
      });
      setSelectedItemList([]);
    }
  };
  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };
  useEffect(() => {
    if (userDetails) {
      getUserDetails();
    }
  }, [userDetails]);

  const onAssignModelClose = (isOpen) => {
    setAssignModel(isOpen);
    setSelectedItemList([]);
  };

  
  return (
    userData && (
      <>
        <Modal.Header className="bg-base-100 ps-5 align-items-start">
          <div className="w-100">
            <Modal.Title>Employee Activity</Modal.Title>
            <button
            
          onClick={() => {
            handleModal(false);                        
          }}
          type="button"
          class="btn-close employeeActivityClose"
          aria-label="Close"
        ></button>
            <div className="employeesDetail">
              <div className="pt-3 mt-3 w-100">
                <div className="d-flex">
                  <div className="headImg me-3">
                    {userData.img ? (
                      <UserImage imgKey={userData.img} />
                    ) : (
                      <img
                        src={getInitialsInName(userData.name ?? "N A", 74)}
                        alt={userData.name}
                      />
                    )}
                    {/* <img src={AccountImg} alt="" /> */}
                  </div>
                  <div className="mt-2">
                    <h2 className="mb-1">{userData.name}</h2>
                    <p className="mb-0 h6 text-gray-200">
                    {userData?.timeZoneId.replace("/", ", ").replace("_", " ").split(', ').reverse().join(', ')} {UserProfileTimeZone(userData?.timeZoneId)}
                    </p>
                    <div className="d-flex gap-5 mt-4">
                      {showLoader && <Loader />}
                      <div className="d-flex gap-2">
                        <IconEmployeeAccount />
                        <p className="h6">{userData.team}</p>
                      </div>

                      <div className="d-flex gap-2">
                        <IconEmployeeCall />
                        <p className="h6">{userData.phoneNumber}</p>
                      </div>

                      <div className="d-flex gap-2">
                        <IconEmployeeMail />
                        <p className="h6">{userData.companyEmail}</p>
                      </div>

                      <div className="d-flex gap-2">
                        <IconEmployeeDate />
                        <p className="h6">
                          <span className="text-gray-300 me-1">Joined on</span>
                          {getFormatDate(userData.joiningDate)}
                          {/* <DateTimeConverter utcDateTime={userData.joiningDate} /> */}
                          {userData.lastactiveDate && (
                            <>
                          <span className="text-gray-400 px-1">|</span>
                          <span className="text-gray-300 me-1">
                            Last active on
                          </span>
                          {getFormatDate(userData.lastactiveDate)}
                          {/* <DateTimeConverter utcDateTime={userData.lastactiveDate} /> */}
                          </>
                        )}
                          {/* Sep 1, 2021  */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Header>
        <div className="bg-gray-600 p-32 pb-0 flex-grow-1 overflow-auto d-flex flex-column ps-5">
          <div className="d-flex justify-content-between w-100 align-items-center mb-4">
            <h5>Assignment Status</h5>

            <div className="d-flex gap-2">
              <div className="input-group myInput myInputSearch">
                <Search placeHolder="Search by Title" onBlur={onInputChange} />
                <IconTopSearch />
              </div>
            </div>
          </div>

          <div className="row flex-grow-1 col5c">

            <div className="col">
              <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                <p className="h6 p-3 mb-0">
                  Pending (
                  {
                    userData.assignMents.filter(
                      (f) =>
                        f.status === 1 &&
                        (f.assignmentTitle
                          .toLowerCase()
                          .includes(serachString?.toLowerCase()) ||
                          serachString == "")
                    ).length
                  }
                  )
                </p>
                {userData.assignMents
                  .filter(
                    (f) =>
                      f.status === 1 &&
                      (f.assignmentTitle
                        .toLowerCase()
                        .includes(serachString?.toLowerCase()) ||
                        serachString == "")
                  )
                  .map((m) => (
                    <div className="bg-base-100  p-3 rounded-6 assignStatus">
                      <p className="text-xs text-gray-300 mb-1 status">
                        {m.assignmentType}
                      </p>
                      <h5
                        className="mb-3 font-medium assignmentTitle"
                        onClick={() => {
                          setSelectedItemList([m.id]);
                          setUnassignModel(true);
                        }}
                      >
                        {m.assignmentTitle}
                      </h5>
                      <p className="mb-0  text-md">
                        <span className="text-gray-300 me-1">Assigned on</span>
                        {getFormatDate(m.assignOn)}
                        {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col">
              <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                <p className="h6 p-3 mb-0">
                  Active (
                  {
                    userData.assignMents.filter(
                      (f) =>
                        f.status === 3 &&
                        (f.assignmentTitle
                          .toLowerCase()
                          .includes(serachString?.toLowerCase()) ||
                          serachString == "")
                    ).length
                  }
                  )
                </p>
                {userData.assignMents
                  .filter(
                    (f) =>
                      f.status === 3 &&
                      (f.assignmentTitle
                        .toLowerCase()
                        .includes(serachString?.toLowerCase()) ||
                        serachString == "")
                  )
                  .map((m) => (
                    <div className="bg-base-100  p-3 rounded-6 assignStatus">
                      <p className="text-xs text-gray-300 mb-1 status">
                        {m.assignmentType}
                      </p>
                      <h5 className="mb-3 font-medium assignmentTitle">{m.assignmentTitle}</h5>
                      <p className="mb-0  text-md">
                        <span className="text-gray-300 me-1">Assigned on</span>
                        {getFormatDate(m.assignOn)}
                        {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col">
              <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                <p className="h6 p-3 mb-0">
                  Delayed (
                  {
                    userData.assignMents.filter(
                      (f) =>
                        f.status === 2 &&
                        (f.assignmentTitle
                          .toLowerCase()
                          .includes(serachString?.toLowerCase()) ||
                          serachString == "")
                    ).length
                  }
                  )
                </p>
                {userData.assignMents
                  .filter(
                    (f) =>
                      f.status === 2 &&
                      (f.assignmentTitle
                        .toLowerCase()
                        .includes(serachString?.toLowerCase()) ||
                        serachString == "")
                  )
                  .map((m) => (
                    <div className="bg-base-100  p-3 rounded-6 assignStatus">
                      <p className="text-xs text-gray-300 mb-1 status">
                        {m.assignmentType}
                      </p>
                      <h5 className="mb-3 font-medium assignmentTitle">{m.assignmentTitle}</h5>
                      <p className="mb-0  text-md">
                        <span className="text-gray-300 me-1">Assigned on</span>
                        {getFormatDate(m.assignOn)}
                        {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col">
              <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                <p className="h6 p-3 mb-0">
                  Expired (
                  {
                    userData.assignMents.filter(
                      (f) =>
                        f.status === 4 &&
                        (f.assignmentTitle
                          .toLowerCase()
                          .includes(serachString?.toLowerCase()) ||
                          serachString == "")
                    ).length
                  }
                  )
                </p>
                {userData.assignMents
                  .filter(
                    (f) =>
                      f.status === 4 &&
                      (f.assignmentTitle
                        .toLowerCase()
                        .includes(serachString?.toLowerCase()) ||
                        serachString == "")
                  )
                  .map((m) => (
                    <div className="bg-base-100  p-3 rounded-6 assignStatus">
                      <p className="text-xs text-gray-300 mb-1 status">
                        {m.assignmentType}
                      </p>
                      <h5 className="mb-3 font-medium assignmentTitle">{m.assignmentTitle}</h5>
                      <p className="mb-0  text-md">
                        <span className="text-gray-300 me-1">Assigned on</span>
                        {getFormatDate(m.assignOn)}
                        {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                      </p>
                    </div>
                  ))}
              </div>
            </div>

            <div className="col">
              <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                <p className="h6 p-3 mb-0">
                  Completed (
                  {
                    userData.assignMents.filter(
                      (f) =>
                        f.status === 0 &&
                        (f.assignmentTitle
                          .toLowerCase()
                          .includes(serachString?.toLowerCase()) ||
                          serachString == "")
                    ).length
                  }
                  )
                </p>
                {console.log(userData, "uuu")}
                {userData.assignMents
                  .filter(
                    (f) =>
                      f.status === 0 &&
                      (f.assignmentTitle
                        .toLowerCase()
                        .includes(serachString?.toLowerCase()) ||
                        serachString == "")
                  )
                  .map((m) => (
                    <div className="bg-base-100  p-3 rounded-6 assignStatus">
                      <p className="text-xs text-gray-300 mb-1 status">
                        {m.assignmentType}
                      </p>
                      <h5 className="mb-3 font-medium assignmentTitle">{m.assignmentTitle}</h5>
                      <p className="mb-0  text-md">
                        <span className="text-gray-300 me-1">Assigned on</span>
                        {getFormatDate(m.assignOn)}
                        {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                      </p>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>

        <Modal
          className="come-from-modal right manageTeamsActivityModal"
          centered
          show={addUserEmployee}
          onHide={() => setAddUserEmployee(false)}
        >
          <AddUserEmployeeModal
            handleModal={setAddUserEmployee}
            action={"Edit"}
            user={userData}
          />
        </Modal>
        <Modal
          className="modalDialog"
          size="sm"
          centered
          // show={unassignModel || responsePopup?.show}
          show={unassignModel || responsePopup?.show}
        >
          {unassignModel && (
            <ModalDelete
              onCancel={setUnassignModel}
              onConfirm={onConfirm}
              headerLable="Are you sure you want to unassign this content?"
              chldLable="This action cannot be undone."
            />
          )}

          {responsePopup?.show && (
            <ModalResponse data={responsePopup} onClose={onClose} />
          )}
        </Modal>

        <Modal
          className="come-from-modal right addEmployee assignContent"
          centered
          show={assignModel}
          onHide={() => setAssignModel(false)}
        >
          <AssignConenteeModal
            openAssignConenteeModal={onAssignModelClose}
            selectedUsers={[userData.id]}
          />
        </Modal>
      </>
    )
  );
};

export default ModalEmployeeActivity;
