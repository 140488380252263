import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { IconTopSearch, IconCrossSm, IconTick } from "../../icons/Icons";
import { Status } from "../../common";
import EmptyState from "../EmptyState/EmptyState";

export class ContentDropdown extends Component {
  state = {
    lisdata: [],
    filteredData: [],
  };
  constructor(props) {
    super(props);
    const filteredData =
    // props.selectedData != undefined
      props.selectedData === undefined
        ? props.list.filter(
            (f) => props.selectedData.map((m) => m.id).indexOf(f.id) == -1
          )
        : props.list;
    this.state = {
      lisdata: filteredData,
      filteredData: filteredData,
    };
  }

  componentDidMount() {}

  componentDidUpdate() {
    // if (this.state.lisdata.length != this.props.list.length) {
    //   this.setState({ lisdata: this.props.list });
    // }
  }
  onMoveEnd = (newList) => {
    console.log(newList);
  };
  // onAddClick = (data, setMethod) => {
  //   if (this.props) {
  //     this.props.onAdd(
  //       data,
  //       setMethod,
  //       this.props.touchedMethod,
  //       this.props.touchedData
  //     );
  //     const updatedLisData = this.state.lisdata.filter(
  //       (item) => item.id !== data.id
  //     );
  //     this.setState({ lisdata: updatedLisData });
  //   }
  // };
  onAddClick = (data, setMethod) => {
    if (this.props) {
      const { expiryDate } = data;
      this.props.onAdd(
        data,
        setMethod,
        this.props.touchedMethod,
        this.props.touchedData,
        expiryDate 
      );
  
      const updatedLisData = this.state.lisdata.filter(
        (item) => item.id !== data.id
      );
      this.setState({ lisdata: updatedLisData });
  
    }
  };
  
  onSearch = (value) => {
    let data = this.state.filteredData.filter(
      (item) => item.title.toLowerCase().indexOf(value.toLowerCase()) > -1
    );
    this.setState({ lisdata: data });
  };

  render() {
    return (
      <div className="mwidth-440">
        <label className="labelField">
          Content<span style={{ color: "red" }}> * </span>
        </label>
        <div className="input-group myInput">
          <input
            className="input form-control"
            list="brow"
            onChange={(e) => this.onSearch(e.target.value)}
            placeholder="Search by content title"
          />
          {/* <button onClick={() => this.onAddClick(null)}>
            <IconCrossSm />
          </button> */}
        </div>

        <ul className="customSelval">

          {this.props.getSelectedContentViewList}

          {this.state.lisdata.map((item, index) => (
            <li key={index + "_ContentDropDownListItem"}>
              <div className="align-items-center d-flex gap-3 bg-gray-600 borderAll p-12 rounded-6 mwidth-440 position-relative mb-12">
                {/* <div>
                  <p className="mb-0 text-sm">  </p>
                </div> */}
                <div className="fullDiv" style={{ marginRight: "20%" }}>
                  {item?.status != undefined &&(
                    <p className="mb-0 text-md text-primary-200" style={{ marginBottom: "1%" }}>
                      {item?.type ?? ""}
                      {/* <span
                        className={`tableTag ${
                          Status[item.status].color
                        } text-md  `}
                      >
                        <IconTick /> 
                        {item.status}
                      </span> */}
                      {item.status === "Draft" && (
                        <span className={`tableTag ${Status[item.status].color} text-md`}>
                          {item.status}
                        </span>
                      )}
                    </p>
                  )}
                  <p className="mb-0 mt-1 text-truncate">
                    <strong className="wordWrap"> {item?.title ?? ""} </strong>
                  </p>
                </div>

                <div className="crossBtn">
                  <button
                    className="text-base-100 btn-sm btn btn-secondary font-normal"
                    onClick={() => this.onAddClick(item, this.props.setMethod)}
                  >
                    Add
                  </button>
                </div>
              </div>
            </li>
          ))}
        </ul>
        {!this.state.lisdata.length > 0 && (
                  <EmptyState message={"No content found"} />
                )}
      </div>
    );
  }
}

export default ContentDropdown;
