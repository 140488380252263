import React from "react";
import OwnerImg from "../../../../../assets/images/Account.png";
import EmptyState from "../../../../../components/EmptyState/EmptyState";
import { IconEdit2, ClickShare } from "../../../../../icons/Icons";
import UserImage from "../../../../../components/UserImage/UserImage";
import getInitialsInName from "../../../../../helper/GetNameInitials";
const AdminTeam = ({ actionActivity, teamMember = [], setUserData }) => {
  return teamMember.length > 0 ? (
    teamMember.map((m) => (
      <>
        <button
          className="userMedia d-flex align-items-center"
          onClick={() => {
            actionActivity(true);
            setUserData(m);
          }}
        >
          <div className="userMediaImg1">
                                  {m.img ? (
                                    <div className="tableImg">
                                      {/* <img src={user.img} alt={user.name} /> */}
                                      <UserImage imgKey={m.img}/>
                                    </div>
                                  ) : (
                                    <div>
                                      <div className="tableImg">
                                        <img src={getInitialsInName(m.name ?? "N A", 36)} alt={m.name} />
                                      </div>
                                      {/* <div class="tableName text-primary-100 bg-pastel-100">
                                        {getInitialsInName(user.name)}
                                      </div> */}
                                    </div>
                                  )}
          </div>
          <div className="userMediaBody">
            <div className="ps-2">
              <h6 className="font-medium d-flex">
                <span className="w10rem">{m.name}</span>
               
              </h6>
            </div>
          </div>
          <span className="ps-2 ms-1">
                  <ClickShare />
                </span>
        </button>
      </>
    ))
  ) : (
    <EmptyState message={"No admin records found "} />
  );
};

export default AdminTeam;
