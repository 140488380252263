import React from 'react';
import { Modal, Button } from "react-bootstrap";
const SamePlanModal = ({ setDiscountPlan, confirm }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <h3 className='mt-3 pe-4'>Get 50% off on your next bill if you continue on the same plan 
                </h3>
                <p className='text-gray-300 mb-0 mt-2 mb-5'>
                We had really like for you to continue on your current plan.
                </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">

                <Button variant="white" size="sm" onClick={confirm}>
                    I still want to Downgrade

                {/* <Button variant="white" size="sm" onClick={() => handleModal(false)}>
                I still want to Downgrade */}

                </Button>
                <Button variant="primary" size="sm" className='border-0' onClick={() => setDiscountPlan()}>
                Continue with same plan
                </Button>
            </Modal.Footer>
        </>
    );
}

export default SamePlanModal;
