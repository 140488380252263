import React from "react";
import { Modal, Button, Row, Col, Container, Accordion } from "react-bootstrap";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import AccountImg from "../../../../../assets/images/Account.png";
import {
  IconSeqarrow,
  IconPlanUpgrade2,
  IconleftArrow,
} from "../../../../../icons/Icons";
import UserImage from "../../../../../components/UserImage/UserImage";
import getInitialsInName from "../../../../../helper/GetNameInitials";
import { useEffect } from "react";
import Loader from "../../../../../components/Loader/Loader";
import { Link } from "react-router-dom";
const AccountOnboarding = ({ onClose, teamStatus, formDataReady }) => {
  const currentMemberTabTitle = `Currently Onboarding (${teamStatus?.currentMembers?.length || 0})`;
  const pendingTabTitle = `Pending (${teamStatus?.pendingMembers?.length || 0 })`;
  const upcomingMembersTabTitle = `Upcoming (${teamStatus?.upcomingMembers?.length || 0})`;
  const completedMembers = `Completed (${teamStatus?.completedMembers?.length || 0})`;
  return (
    <>
      <Modal.Header closeButton className="bg-base-100 ps-5" onClick={() => onClose(false)}>
        <button className="d-flex justify-content-start align-items-center">
          <span className="arrow left"></span>
          <span className="text-lg text-gray-200 ms-1">Back</span>
        </button>
      </Modal.Header>
      <Modal.Body className="p-4 bg-gray-600">
        <div className='m-3 card cardMod bg-gray-600'>
        
          <Tabs defaultActiveKey="Onboarding" id="uncontrolled-tab-example" className="modalTab authoringNav pt-3 px-32 bg-base-100" >

            <Tab eventKey="Onboarding" title={currentMemberTabTitle}>
            {formDataReady === false && <Loader />}
              <div className="p-4">
                {teamStatus?.currentMembers.map((item) => (
                  <div className="d-flex gap-2 align-items-center mb-3 pb-1">
                    {item.img ? (
                      <div className="headImg">
                        {/*   <img src={AccountImg} alt="" /> */}
                        <UserImage imgKey={item.img} />
                      </div>
                    ) : (
                      <div>
                        <div className="headImg">
                          <img src={getInitialsInName(item.name ?? "N A", 36)} alt={item.name} />
                        </div>
                      </div>
                    )}
                    <div>
                      <h6 className="font-medium">{item.name} <span>({item.role})</span></h6>
                    </div>
                  </div>
                ))}
                {/* <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div> */}
              </div>
            </Tab>
            <Tab eventKey="Pending" title={pendingTabTitle}>
            {formDataReady === false && <Loader />}
              <div className="p-4">
                {teamStatus?.pendingMembers.map((item) => (
                  <div className="d-flex gap-2 align-items-center mb-3 pb-1">
                    {item.img ? (
                      <div className="headImg">
                        {/*   <img src={AccountImg} alt="" /> */}
                        <UserImage imgKey={item.img} />
                      </div>
                    ) : (
                      <div>
                        <div className="headImg">
                          <img src={getInitialsInName(item.name ?? "N A", 36)} alt={item.name} />
                        </div>
                      </div>
                    )}
                    <div>
                      <h6 className="font-medium">{item.name} <span>({item.role})</span></h6>
                    </div>
                  </div>
                ))}
              </div>

            </Tab>
            <Tab eventKey="Upcoming" title={upcomingMembersTabTitle}>
            {formDataReady === false && <Loader />}
              <div className="p-4">
                {teamStatus?.upcomingMembers.map((item) => (
                  <div className="d-flex gap-2 align-items-center mb-3 pb-1">
                    {item.img ? (
                      <div className="headImg">
                        {/*   <img src={AccountImg} alt="" /> */}
                        <UserImage imgKey={item.img} />
                      </div>
                    ) : (
                      <div>
                        <div className="headImg">
                          <img src={getInitialsInName(item.name ?? "N A", 36)} alt={item.name} />
                        </div>
                      </div>
                    )}
                    <div>
                      <h6 className="font-medium">{item.name} <span>({item.role})</span></h6>
                    </div>
                  </div>
                ))}
                {/* <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div> */}

              </div>

            </Tab>
            <Tab eventKey="Completed" title={completedMembers}>
            {formDataReady === false && <Loader />}
              <div className="p-4">
                {teamStatus?.completedMembers.map((item) => (
                  <div className="d-flex gap-2 align-items-center mb-3 pb-1">
                    {item.img ? (
                      <div className="headImg">
                        {/*   <img src={AccountImg} alt="" /> */}
                        <UserImage imgKey={item.img} />
                      </div>
                    ) : (
                      <div>
                        <div className="headImg">
                          <img src={getInitialsInName(item.name ?? "N A", 36)} alt={item.name} />
                        </div>
                      </div>
                    )}
                    <div>
                      <h6 className="font-medium">{item.name} <span>({item.role})</span></h6>
                    </div>
                  </div>
                ))}
                {/* <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div>
        <div className="d-flex gap-2 align-items-center mb-3 pb-1">
           <div className="headImg">
              <img src={AccountImg} alt="" />
            </div>
            <div>
              <h6 className="font-medium">John Smith</h6>
            </div>
        </div> */}
              </div>

            </Tab>
          </Tabs>

        </div>
      </Modal.Body>
    </>
  );

};

export default AccountOnboarding;
