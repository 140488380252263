import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import FileTypeCheckboxes from "./FileTypeCheckboxes";

const FileUpload = (props) => {
  const [selectedFileLimit, setSelectedFileLimit] = useState({
    label: 1,
    value: 1,
  });
  const [selectedFileSize, setSelectedFileSize] = useState({
    label: "10 MB",
    value: 10,
  });
  const [specificFileTypes1, setSpecificFileTypes1] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [selectedItemLi, setselectedItemLi] = useState([]);
  const [validationMsg, setValidationMsg] = useState("");

  useEffect(() => {
    // console.log(props?.specificFileTypes,'props?.specificFileTypes');
    setSpecificFileTypes1(props?.specificFileTypes?.length > 0 ?? true);
    setSelectedItemList(
      props?.specificFileTypes !== undefined &&
        props?.specificFileTypes !== null
        ? props?.specificFileTypes
            .map((item) => item)
            .filter((id) => id !== undefined)
        : []
    );
    //console.log(props?.specificFileTypes, "selectedItemList1")
    setSelectedFileLimit(
      props?.selectedFileLimit
        ? { label: props?.selectedFileLimit, value: props?.selectedFileLimit }
        : { label: 1, value: 1 }
    );
    setSelectedFileSize(
      props?.selectedFileSize
        ? { label: `${props?.selectedFileSize} MB`, value: props?.selectedSize }
        : { label: "10 MB", value: 10 }
    );
  }, [
    props?.selectedFileSize,
    props?.selectedFileLimit,
    props?.specificFileTypes,
  ]);

  const ststusChange = (id, newStatus) => {
    var selectedItems = [...selectedItemList];

    if (id === "All") {
      if (newStatus) {
        selectedItems = props.specificFileTypes.map((item) => item.id);
      } else {
        selectedItems = [];
      }
    } else {
      if (newStatus) {
        if (!selectedItems.includes(id)) {
          selectedItems.push(id);
        }
      } else {
        selectedItems = selectedItems.filter((item) => item !== id);
      }
    }

    setSelectedItemList(selectedItems);
    setselectedItemLi(selectedItems);
    props.setSpecificFileTypes(selectedItems);
    // if (specificFileTypes1 === true && selectedItems.length === 0) {
    //   setValidationMsg(
    //     "Please select the allowed file types below or Unselect allowing only specific file type"
    //   );
    // } else {
    //   setValidationMsg("");
    // }
  };

  return (
    <div className="mb-4">
      <div className="form-group mySelect mb-4">
        <label>Maximum number of files</label>
        <Select
          className="select"
          options={props?.numberFiles}
          //defaultValue={selectedFileLimit}
          value={selectedFileLimit}
          classNamePrefix="recatMySel"
          onChange={(e) => {
            console.log(e, "eee");
            setSelectedFileLimit(e);
            props?.setSelectedFileLimit(e.value);
          }}
        />
      </div>

      <div className="form-group mySelect mb-4">
        <label>Maximum file size</label>
        <Select
          className="select"
          options={props?.sizeFiles}
          //defaultValue={selectedFileSize}
          value={selectedFileSize}
          classNamePrefix="recatMySel"
          onChange={(e) => {
            console.log(e, "eee");
            setSelectedFileSize(e);
            props?.setSelectedFileSize(e.value);
          }}
        />
      </div>
      <div className="alowSpecf" style={{ maxWidth: "300px" }}>
        <div className="mb-4">
          <div className="ps-0 pe-1 d-flex justify-content-between align-items-center">
            <label className="form-check-label check-lab1 h6 font-medium">
              Allow only specific file types
            </label>
            {/* <label className="btnSwitch">
              <input
                className="form-check-input"
                type="checkbox"
                checked={specificFileTypes1}
                onChange={(e) => setSpecificFileTypes1(!specificFileTypes1)}
              />
              <span className="btnSwitchStyle"></span>
            </label> */}
          </div>
        </div>
      </div>
      <div className="fileCheckbox">
        {/* {specificFileTypes1 ? ( */}
          <FileTypeCheckboxes
            ststusChange={ststusChange}
            selectedItemList={selectedItemList}
            pagetype={props.pagetype}
            selectedItemLi={selectedItemLi}
            setselectedItemLi={setselectedItemLi}
          />
        {/* ) : null} */}
        {/* {specificFileTypes1 === true && (
          <div
            style={{
              color: "red",
              fontSize: "0.825rem",
              float: "left",
              lineHeight: "normal",
            }}
          >
            {validationMsg}
          </div>
        )} */}
      </div>
    </div>
  );
};

export default FileUpload;
