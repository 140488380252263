import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import {
  switchRoleAsync,
} from "../../reducers/user-reducers";

const SwitchDashboard = ({ userProfiles }) => {
  const [isTeamMode, setIsTeamMode] = useState(userProfiles.switchedRoleName === "Employee");
  // const [mode, setMode] = useState(userProfiles.switchedRoleName || 'Admin');
  const [mode, setMode] = useState(
    userProfiles.switchedRoleName === "Employee"
      ? "Employee"
      : userProfiles.switchedRoleName === "Super Admin"
      ? "Super Admin"
      : "Admin"
  );
  const dispatch = useDispatch();

  useEffect(() => {
    if (userProfiles.switchedRoleName === "Employee") {
      setMode("Employee");
      setIsTeamMode(true);
    } else if (userProfiles.switchedRoleName === "Super Admin") {
      setMode("Super Admin");
      setIsTeamMode(false);
    } else {
      setMode("Admin");
      setIsTeamMode(false);
    }
  }, [userProfiles]);
  
  // useEffect(() => {
  //   setMode(userProfiles.switchedRoleName || 'Admin');
  //   setIsTeamMode(userProfiles.switchedRoleName === 'Employee');
  // }, [userProfiles]);

  // const handleToggle = () => {
  //   const newMode = mode === 'Admin' ? 'Employee' : 'Admin';
  //   setMode(newMode);
  //   setIsTeamMode(newMode === 'Employee');
  //   dispatch(switchRoleAsync(newMode))
  //     .then(() => {
  //       window.location.reload();
  //     });
  // };
  const handleToggle = () => {
    let newMode;
    if (mode === 'Admin' || mode === 'Super Admin') {
      newMode = 'Employee';
    } else {
      newMode = 'Admin'; 
    }
    setMode(newMode);
    setIsTeamMode(newMode === 'Employee');
    dispatch(switchRoleAsync(newMode))
      .then(() => {
        window.location.reload();
      });
  };
  

  return (
    <div className="text-with-tggolebtn me-2">
      <label className="switch btn-color-mode-switch">
        <input
          type="checkbox"
          name="color_mode"
          id="color_mode"
          checked={isTeamMode}
          onChange={handleToggle}
        />
        <label
          htmlFor="color_mode"
          data-off="Admin"
          data-on="Team"
          className="btn-color-mode-switch-inner"
        ></label>
      </label>
    </div>
  );
};

export default SwitchDashboard;

