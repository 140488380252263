import React from 'react';
import Chart from "react-apexcharts";
import EmptyState from "../../components/EmptyState/EmptyState";

const BarChart = ({ teamwiseOnboarding }) => {
    if (!teamwiseOnboarding) {
        return <EmptyState message={"No data found"} />;
    }
    const seriesData = teamwiseOnboarding.map((item) => item.onboardingCount);

    const chartData = {
        series: [{
            data: seriesData.slice(0, 5),
        }],
        options: {
            chart: {
                type: 'bar',
                // height: 300,
                toolbar: {
                    tools: {
                        download: false,
                    },
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 0,
                    horizontal: true,
                    barHeight: '60%',
                    // distributed: true,
                    colors: {
                        ranges: [
                            {
                                from: 0,
                                to: 10,
                                color: "#D9D9D9",
                            },
                            {
                                from: 11,
                                to: 100,
                                color: "#E3828D",
                            },
                        ],
                        // backgroundBarColors: ["#fff"],
                        // backgroundBarOpacity: 1,
                        // backgroundBarRadius: 0,
                    },
                    dataLabels: {
                        position: 'top',
                        // position: 'middle',
                        // maxItems: 100,
                        hideOverflowingLabels: true,
                        // orientation: horizontal
                    },
                },
            },
            dataLabels: {
                // enabled: false,
                style: {
                    fontSize: '0.875rem',
                    colors: ['#000'],
                    fontWeight: 500,
                    fontFamily: 'Inter',
                },
                offsetX: 15,
                offsetY: 5,
                rotate: 0,
            },
            xaxis: {
                showAlways: true,
                showForNullSeries: true,
                labels: {
                    show: false,
                },
                categories: teamwiseOnboarding.map((item) => item.teamName).slice(0, 5),
                // axisBorder: {
                //     show: false
                // },

                // axisTicks: {
                //     show: true,
                //     borderType: 'dashed',
                //     color: '#78909C',
                //     width: -350,
                //     offsetX: 0,
                //     offsetY: 0
                // },
            },
            yaxis: {
                show: true,
                labels: {
                    style: {
                        fontSize: '0.875rem',
                        // width:"700px",
                        colors: ['#000'],
                        // fontWeight: 500,
                        fontWeight: 400,
                        // cssClass: 'me-5',
                        fontFamily: 'Inter',
                    },
                    offsetX: -5,
                    offsetY: 0,
                    rotate: 0,
                },
                axisBorder: {
                    // show: false
                },

            },
            tooltip: {
                enabled: false,
            },
            grid: {
                show: true,
                borderColor: '#D9D9D9',
                strokeDashArray: 5,
                position: 'back',
                xaxis: {
                    lines: {
                        show: true,
                    },
                },
                yaxis: {
                    lines: {
                        show: false,
                    },
                },
                // row: {
                //     colors: "#000",
                //     opacity: 0.5
                // },
                // column: {
                //     colors: undefined,
                //     opacity: 0.5
                // },
                // padding: {
                //     top: 0,
                //     right: 0,
                //     bottom: 0,
                //     left: 0
                // },
            },
        },
    };

    return (
        <div className='teamViseBarChart' style={{height:chartData.series[0].data.length * 56}}>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="bar"
                // height={chartData.options.chart.height}
                // height="300"
                // height="210"
                height="100%"
            />
        </div>
    );
}

export default BarChart;
