import { React, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Container, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import Search from "../../../components/Search/Search";
import Select from "react-select";
import {
  IconHamberger,
  IconStar,
  IconhalfStar,
  IconemptyStar,
  IconStarOne,
  IconStarTwo,
  IconStarThree,
  IconStarFour,
  IconStarSix,
  IconStarSeven,
  IconStarEight,
  IconStarNine,
} from "../../../icons/Icons";
import CheckBox from "../../../components/CheckBox/CheckBox";
import AccountImg from "../../../assets/images/Account.png";
import { useLocation } from "react-router-dom";
import { getModuleFeedbackDetailsAsync } from "../../../reducers/module-reducers";
import { getSequenceFeedbackDetailsAsync } from "../../../reducers/sequence-reducers";
import { getProcessesFeedbackDetailsAsync } from "../../../reducers/processes-reducers";
import { useDispatch } from "react-redux";
import EmptyState from "../../../components/EmptyState/EmptyState";

const FeedbackView = ({ onClose }) => {
  const { state } = useLocation();
  const dispatch = useDispatch();
  const { id } = state.propsData;
  const [moduleFeedbackDetails, setModuleFeedbackDetails] = useState(null);

  const getFeedbackDetails = async (id) => {
    // debugger
    if (state.redirectTo === "/authoring/module/feedback") {
      const data = await dispatch(getModuleFeedbackDetailsAsync(id));
      setModuleFeedbackDetails(data.payload);
    } else if (state.redirectTo === "/authoring/process/feedback") {
      const data = await dispatch(getProcessesFeedbackDetailsAsync(id));
      setModuleFeedbackDetails(data.payload);
    } else {
      const data = await dispatch(getSequenceFeedbackDetailsAsync(id));
      setModuleFeedbackDetails(data.payload);
    }
  };

  useEffect(() => {
    getFeedbackDetails(id);
  }, []);

  const optionsDays = [
    { value: "1", label: "Today" },
    { value: "2", label: "Tomorrow" },
    { value: "3", label: "Today" },
  ];
  const avgRating =
    moduleFeedbackDetails &&
    moduleFeedbackDetails.feedBackRatingSummray.avgRating;
  const roundedFractionalPart = Math.round((avgRating % 1) * 10) / 10;

  return (
    <>
      <Modal.Header
        className="d-flex justify-content-start py-3"
        closeButton
        onClick={() => onClose(false)}
      >
        <div className="ms-3">
          <Modal.Title>Feedback</Modal.Title>
          <ol className="breadcrumb h6 mt-1">
            <li>
            {state.authoringName === "Sequences" && (
              <Link to="/authoring/sequences">{state.authoringName}</Link>
              )}
              {state.authoringName === "Modules" && (
              <Link to="/authoring/modules">{state.authoringName}</Link>
              )}
            </li>
            {/* <li>HR Onboarding</li> */} 
            <li>{state.propsData.title}</li>
          </ol>
        </div>
      </Modal.Header>

      <Modal.Body className="pt-3 pb-4 pe-5 mt-3">
        <Row className="d-flex h-100 ms-2">
          {moduleFeedbackDetails &&
          moduleFeedbackDetails.feedBackRatingSummray ? (
            <Col className="col-lg-4">
              <h5 className="mb-2">Avg. Rating</h5>

              <div className="d-flex gap-2 align-items-center pb-3 mb-4 avgRat">
                <h1>
                  {moduleFeedbackDetails.feedBackRatingSummray.avgRating.toFixed(
                    1
                  )}
                </h1>
                <div className="d-flex gap-1">
                  {/* <IconStar />
                  <IconStar />
                  <IconStar />
                  <IconStar />
                  <IconStar /> */}

                  {moduleFeedbackDetails &&
                    moduleFeedbackDetails.feedBackRatingSummray && (
                      <>
                        {[...Array(Math.floor(avgRating))].map((_, index) => (
                          <IconStar key={index} />
                        ))}
                        {roundedFractionalPart === 0.1 && <IconStarOne />}
                        {roundedFractionalPart === 0.2 && <IconStarTwo />}
                        {roundedFractionalPart === 0.3 && <IconStarThree />}
                        {roundedFractionalPart === 0.4 && <IconStarFour />}
                        {roundedFractionalPart === 0.5 && <IconhalfStar />}
                        {roundedFractionalPart === 0.6 && <IconStarSix />}
                        {roundedFractionalPart === 0.7 && <IconStarSeven />}
                        {roundedFractionalPart === 0.8 && <IconStarEight />}
                        {roundedFractionalPart === 0.9 && <IconStarNine />}
                        {Array(Math.floor(5 - avgRating)).fill(
                          <IconemptyStar />
                        )}
                      </>
                    )}
                </div>
                <p className="text-sm text-gray-200 mb-0">
                  {moduleFeedbackDetails.feedBackRatingSummray.totalFeedback}{" "}
                  reviews
                </p>
              </div>

              <div className="mainProgressBar mb-5 pb-6">
                <div className="d-flex align-items-center gap-2 mb-14">
                  <p className="text-gray-300 font-medium text-md mb-0">5</p>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${moduleFeedbackDetails.feedBackRatingSummray.feedback5[0]}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <p className="font-bold text-md mb-0 percent">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback5[0]}%
                  </p>

                  <p className="text-gray-300 font-medium text-md mb-0">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback5[1]}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2 mb-14">
                  <p className="text-gray-300 font-medium text-md mb-0">4</p>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{
                          width: `${moduleFeedbackDetails.feedBackRatingSummray.feedback4[0]}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <p className="font-bold text-md mb-0 percent">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback4[0]}%
                  </p>

                  <p className="text-gray-300 font-medium text-md mb-0">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback4[1]}
                  </p>
                </div>

                <div className="d-flex align-items-center gap-2 mb-14">
                  <p className="text-gray-300 font-medium text-md mb-0">3</p>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar yellow"
                        role="progressbar"
                        style={{
                          width: `${moduleFeedbackDetails.feedBackRatingSummray.feedback3[0]}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <p className="font-bold text-md mb-0 percent">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback3[0]}%
                  </p>

                  <p className="text-gray-300 font-medium text-md mb-0">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback3[1]}
                  </p>
                </div>

                <div className="d-flex align-items-center gap-2 mb-14">
                  <p className="text-gray-300 font-medium text-md mb-0">2</p>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar yellow"
                        role="progressbar"
                        style={{
                          width: `${moduleFeedbackDetails.feedBackRatingSummray.feedback2[0]}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <p className="font-bold text-md mb-0 percent">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback2[0]}%
                  </p>

                  <p className="text-gray-300 font-medium text-md mb-0">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback2[1]}
                  </p>
                </div>
                <div className="d-flex align-items-center gap-2 mb-14">
                  <p className="text-gray-300 font-medium text-md mb-0">1</p>
                  <div className="col">
                    <div className="progress">
                      <div
                        className="progress-bar orange"
                        role="progressbar"
                        style={{
                          width: `${moduleFeedbackDetails.feedBackRatingSummray.feedback1[0]}%`,
                        }}
                      ></div>
                    </div>
                  </div>

                  <p className="font-bold text-md mb-0 percent">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback1[0]}%
                  </p>

                  <p className="text-gray-300 font-medium text-md mb-0">
                    {moduleFeedbackDetails.feedBackRatingSummray.feedback1[1]}
                  </p>
                </div>
              </div>
              <div className="d-flex gap-4">
                <div>
                  <p className="text-md mb-2 text-gray-200">Total Feedback</p>
                  <h3 className="font-medium">
                    {moduleFeedbackDetails.feedBackRatingSummray.totalFeedback}
                  </h3>
                </div>

                <div>
                  <p className="text-md mb-2 text-gray-200">Total Comments</p>
                  <h3 className="font-medium">
                    {moduleFeedbackDetails.feedBackRatingSummray.totalComments}
                  </h3>
                </div>

                <div>
                  <p className="text-md mb-2 text-gray-200">Reported Issues</p>
                  <h3 className="font-medium">
                    {moduleFeedbackDetails.reportedIssuesCount}
                  </h3>
                </div>
              </div>
            </Col>
          ) : null}

          <Col className="col-lg-8 d-flex h-100 flex-column">
            <div className="d-flex justify-content-between mb-20 align-items-center">
              <p className="font-bold mb-0">Recent Feedback</p>
              {/* <div className="mySelect topSelect ">
                                <Select isSearchable={false} className="select" options={optionsDays} defaultValue={[optionsDays[0]]} />
                            </div> */}
            </div>
            <div className="feedbackTable tableOuter flex-grow-1 overflow-auto h-100">
              <table className="table">
                <thead>
                  <tr>
                    {/* <th><CheckBox text="ALL" /></th> */}
                    <th className="W40">NAME</th>
                    <th className="W15">RATING</th>
                    <th className="W45">COMMENT</th>
                  </tr>
                </thead>
                <tbody>
                  {moduleFeedbackDetails &&
                    moduleFeedbackDetails.feedBackRatingDetails.length > 0 &&
                    moduleFeedbackDetails.feedBackRatingDetails.map((item) => (
                      <tr>
                        {/* <td align='middle'><CheckBox /></td> */}
                        <td>
                          {/* <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'> */}
                          <div className="d-flex align-items-center gap-2">
                            <div className="tableImg">
                              <img src={AccountImg} alt="" />
                            </div>
                            <div>
                              <p className="text-nowrap mb-0">
                                {item.employeeName}
                              </p>
                              <a
                                className="text-primary-200 font-medium"
                                href={`mailto:${item.employeeEmail}`}
                              >
                                {item.employeeEmail}
                              </a>
                              <br />
                              <a
                                className="text-primary-200 font-medium"
                                href={`tel:${item.phoneNumber}`}
                              >
                                {item.phoneNumber}
                              </a>
                            </div>
                          </div>
                          {/* </Link> */}
                        </td>
                        <td>
                          <div className="d-flex gap-2 align-items-center">
                            <IconStar />
                            <span>{item.rating}</span>
                          </div>
                        </td>
                        <td className="diComent">
                          <OverlayTrigger
                            placement="auto"
                            overlay={
                              <Popover
                                id="popover-basic"
                                style={{ position: 'fixed' }}
                              >
                                <Popover.Body>
                                  {item.comment ? item.comment : "-"}
                                </Popover.Body>
                              </Popover>
                            }
                            trigger={["hover", "focus"]}
                            container={this}
                          >
                            <p className="mb-0">
                              {item.comment ? item.comment : "-"}
                            </p>
                          </OverlayTrigger>
                          {/* <p className="mb-0">{item.comment}</p> */}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
              {moduleFeedbackDetails &&
              !moduleFeedbackDetails.feedBackRatingDetails.length > 0 ? (
                <EmptyState
                  message={
                    "Sorry, There is no feedback data available for now."
                  }
                />
              ) : null}
            </div>
          </Col>
        </Row>
      </Modal.Body>
    </>
  );
};

export default FeedbackView;
