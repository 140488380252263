import { http, httpBlobServiceApiRoot, httpBlobGetServiceApiRoot } from "../httpService";

export function getEnumList(value) {
  return http.get(`/sequence/Options?name=${value}`);
}

export function filesUpload(request) {
  return httpBlobServiceApiRoot.post(`/api/Bllob/Bulk/uploadfiles`, request);
}

export function fileUpload(request) {
  return httpBlobServiceApiRoot.post(`/api/Bllob/uploadfiles`, request);
}

export function generatePresignedUrl(reqBody) {
  console.log('reqBody 16 = ', reqBody);
  if (reqBody !== undefined) {
    return httpBlobGetServiceApiRoot.post(`/api/Bllob/GeneratePresignedUrl`, reqBody);
  }
}

export function bulkGeneratePresignedUrl(reqBody) {
  console.log('reqBody 20 = ', reqBody)
  return httpBlobGetServiceApiRoot.post(`/api/Bllob/Bulk/GeneratePresignedUrl`, reqBody);
}
