import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import AccountSettingNav from "../AccountSettingNav";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import { IconHamberger, IconStar } from "../../../icons/Icons";
import AccountImg from "../../../assets/images/Account.png";
import Search from "../../../components/Search/Search";
import CheckBoxMd from "../../../components/CheckBox/CheckBoxMd";
import Select from "react-select";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import ConfimrationPopup from "../../../components/ModalPopup/ConfimrationPopup";
import TextInput from "../../../components/FormikInputs/TextInput";
// import {
//   getPhonesettingEnumListAsync,
//   getEmailsettingEnumListAsync,
// } from "../../../reducers/common-reducers";
import {
  getUserPrefenceAsync,
  setUserPrefenceAsync,
} from "../../../reducers/user-reducers";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader";

const Preferences = ({ userProfile }) => {
  // const [accountemailNotificationType, setaccountemailNotificationType] =
  //   useState(null);
  // const [accountsphoneNotificationType, setaccountsphoneNotificationType] =
  //   useState(null);
  // const [
  //   newAssignmentsemailNotificationType,
  //   setnewAssignmentsemailNotificationType,
  // ] = useState(null);
  // const [
  //   newAssignmentsphoneNotificationType,
  //   setnewAssignmentsphoneNotificationType,
  // ] = useState(null);
  // const [
  //   reportedIssueemailNotificationType,
  //   setreportedIssueemailNotificationType,
  // ] = useState(null);
  // const [
  //   reportedIssuephoneNotificationType,
  //   setreportedIssuephoneNotificationType,
  // ] = useState(null);

  // const optionsEmail = useSelector((state) => state.common.emailsettingList);
  // const optionsPhone = useSelector((state) => state.common.phonesettingList);
  const [userPreferences, setUserPreferences] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const [formDataReady, setFormDataReady] = useState(true);
  const formRef = useRef();
  const dispatch = useDispatch();

  const onPreferencesSubmit = (values) => {
    const transformedData = userPreferences.map((notification, index) => ({
      preferenceId: notification.id,
      notificationsType: values?.emailNotifications[index]?.notificationsType,
      emailValue: values?.emailNotifications[index]?.emailValue,
      textValue: values?.emailNotifications[index]?.textValue,
      inAppValue: values?.emailNotifications[index]?.inAppValue,
    }));

    dispatch(setUserPrefenceAsync(transformedData)).then(() => {
      getUserPrefence();
      setShowPopup(true);
    });
  };

  const getUserPrefence = async () => {
    const response = await dispatch(getUserPrefenceAsync());
    setUserPreferences(response.payload);
    setFormDataReady(false);
  };

  useEffect(() => {
    setFormDataReady(true);
    if (!userPreferences) {
      getUserPrefence();
    }
    // if (optionsEmail.length === 0) {
    //   dispatch(getEmailsettingEnumListAsync());
    // } else if (userPreferences) {
    //   const accountdefaultValue = optionsEmail.filter(
    //     (f) =>
    //       f.value ==
    //       (userPreferences.emailNotifications[0]?.emailNotificationType ?? 0)
    //   );
    //   setaccountemailNotificationType({
    //     value: accountdefaultValue[0].value,
    //     label: accountdefaultValue[0].label,
    //   });

    //   const newAssignmentdefaultValue = optionsEmail.filter(
    //     (f) =>
    //       f.value ==
    //       (userPreferences.emailNotifications[1]?.emailNotificationType ?? 0)
    //   );
    //   setnewAssignmentsemailNotificationType({
    //     value: newAssignmentdefaultValue[0].value,
    //     label: newAssignmentdefaultValue[0].label,
    //   });

    //   const reportedIssuedefaultValue = optionsEmail.filter(
    //     (f) =>
    //       f.value ==
    //       (userPreferences.emailNotifications[2]?.emailNotificationType ?? 0)
    //   );
    //   setreportedIssueemailNotificationType({
    //     value: reportedIssuedefaultValue[0].value,
    //     label: reportedIssuedefaultValue[0].label,
    //   });
    // }
    // if (optionsPhone.length === 0) {
    //   dispatch(getPhonesettingEnumListAsync());
    // } else if (userPreferences) {
    //   const accountdefaultValue = optionsPhone.filter(
    //     (f) =>
    //       f.value ==
    //       (userPreferences.emailNotifications[0]?.phoneNotificationsType ?? 0)
    //   );
    //   setaccountsphoneNotificationType({
    //     value: accountdefaultValue[0].value,
    //     label: accountdefaultValue[0].label,
    //   });
    //   const newAssignmentdefaultValue = optionsPhone.filter(
    //     (f) =>
    //       f.value ==
    //       (userPreferences.emailNotifications[1]?.phoneNotificationsType ?? 0)
    //   );
    //   setnewAssignmentsphoneNotificationType({
    //     value: newAssignmentdefaultValue[0].value,
    //     label: newAssignmentdefaultValue[0].label,
    //   });
    //   const reportedIssuedefaultValue = optionsPhone.filter(
    //     (f) =>
    //       f.value ==
    //       (userPreferences.emailNotifications[2]?.phoneNotificationsType ?? 0)
    //   );
    //   setreportedIssuephoneNotificationType({
    //     value: reportedIssuedefaultValue[0].value,
    //     label: reportedIssuedefaultValue[0].label,
    //   });
    // }
  }, [userPreferences]);

  const onResponseClose = () => {
    setShowPopup(false);
  };

  // const onChangeDataSet = (setFieldValue, e) => {
  //   // console.log(e,'eeeeeeeeeeee');
  //   if (e.target.name === "AccountUpdatesE") {
  //     setFieldValue(
  //       "emailNotifications[0].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "AccountUpdatesP") {
  //     setFieldValue(
  //       "emailNotifications[0].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "AccountUpdatesApp") {
  //     setFieldValue(
  //       "emailNotifications[0].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "NewAssignmentsE") {
  //     setFieldValue(
  //       "emailNotifications[1].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "NewAssignmentsP") {
  //     setFieldValue(
  //       "emailNotifications[1].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "NewAssignmentsApp") {
  //     setFieldValue(
  //       "emailNotifications[1].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "ReportedIssueUpdateE") {
  //     setFieldValue(
  //       "emailNotifications[2].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "ReportedIssueUpdateP") {
  //     setFieldValue(
  //       "emailNotifications[2].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "ReportedIssueUpdateApp") {
  //     setFieldValue(
  //       "emailNotifications[2].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "InformOnNewlaunchesE") {
  //     setFieldValue(
  //       "emailNotifications[3].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "InformOnNewlaunchesP") {
  //     setFieldValue(
  //       "emailNotifications[3].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "InformOnNewlaunchesApp") {
  //     setFieldValue(
  //       "emailNotifications[3].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "authoringCreatedE") {
  //     setFieldValue(
  //       "emailNotifications[4].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "authoringCreatedP") {
  //     setFieldValue(
  //       "emailNotifications[4].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "authoringCreatedApp") {
  //     setFieldValue(
  //       "emailNotifications[4].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "authoringDraftE") {
  //     setFieldValue(
  //       "emailNotifications[5].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "authoringDraftP") {
  //     setFieldValue(
  //       "emailNotifications[5].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "authoringDraftApp") {
  //     setFieldValue(
  //       "emailNotifications[5].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "weeklyDigestE") {
  //     setFieldValue(
  //       "emailNotifications[6].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "weeklyDigestP") {
  //     setFieldValue(
  //       "emailNotifications[6].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "weeklyDigestApp") {
  //     setFieldValue(
  //       "emailNotifications[6].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "promotionalMessagesE") {
  //     setFieldValue(
  //       "emailNotifications[7].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "promotionalMessagesP") {
  //     setFieldValue(
  //       "emailNotifications[7].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "promotionalMessagesApp") {
  //     setFieldValue(
  //       "emailNotifications[7].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "supportMessagesE") {
  //     setFieldValue(
  //       "emailNotifications[8].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "supportMessagesP") {
  //     setFieldValue(
  //       "emailNotifications[8].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "supportMessagesApp") {
  //     setFieldValue(
  //       "emailNotifications[8].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  // };

  console.log(userPreferences, "userPreferences");
  // console.log(optionsEmail, "optionsEmail");
  // console.log(optionsPhone, "optionsPhone");
  return (
    <div className="pages">
      <RenewalHeader />
      <header className="pageHeader mb-4">
        <Container fluid>
          <Row className="mx-0 align-items-center">
            <Col className="px-0">
              <h3>Account Settings</h3>
            </Col>
            <Col className="px-0 text-end">
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <AccountDropdown />
                <ActivityLog />
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <div className="pageOuter">
        <Container fluid>
          <AccountSettingNav />
{(userProfile.roleName === "Super Admin" || userProfile.roleName === "Admin") && userProfile.switchedRoleName === "Admin" && (
          <div className="page profile d-flex flex-column">
            {!userPreferences && <Loader />}
            {formDataReady && userPreferences && (
              <Formik
                initialValues={{
                  emailNotifications: userPreferences || [],
                }}
                onSubmit={onPreferencesSubmit}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <Form style={{ display: "contents" }}>
                    <div className="tableComponent prefTable flex-grow-1 overflow-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-gray-100 text-sm">
                              Notifications
                            </th>
                            <th className="px-32">Email</th>
                            <th className="px-32">SMS</th>
                            <th className="w-113 px-32">In-App</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userPreferences.slice(0, 6).map(
                            (notification, index) =>
                              notification.isVisibleOnUI && (
                                <tr key={notification.id}>
                                  <td>
                                    {/* <b> */}
                                    {notification?.title} 
                                    {/* -</b>{" "}
                                    {notification?.subTitle} */}
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].emailValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          values.emailNotifications[index]
                                            ?.emailValue || false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `emailNotifications[${index}].emailValue`,
                                            e.target.checked
                                          );
                                        }}
                                        disabled={
                                          !notification.isEmailConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].textValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          values.emailNotifications[index]
                                            ?.textValue || false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `emailNotifications[${index}].textValue`,
                                            e.target.checked
                                          );
                                        }}
                                        disabled={
                                          !notification.isTextConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].inAppValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={values.emailNotifications[index]?.inAppValue || false}
                                        disabled={
                                          !notification.isInAppConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex gap-20 align-items-center btnFooter">
                      <Button
                        variant="secondary"
                        onClick={() => onPreferencesSubmit(values)}
                      >
                        Save Changes
                      </Button>
                      <Button
                        variant="white"
                        size=""
                        onClick={() => resetForm()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
        )}
          {(userProfile.roleName === "Super Admin" || userProfile.roleName === "Admin") && userProfile.switchedRoleName === "Employee" && (
            <div className="page profile d-flex flex-column">
            {!userPreferences && <Loader />}
            {formDataReady && userPreferences && (
              <Formik
                initialValues={{
                  emailNotifications: userPreferences || [],
                }}
                onSubmit={onPreferencesSubmit}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <Form style={{ display: "contents" }}>
                    <div className="tableComponent prefTable flex-grow-1 overflow-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-gray-100 text-sm">
                              Notifications
                            </th>
                            <th className="px-32">Email</th>
                            <th className="px-32">SMS</th>
                            <th className="w-113 px-32">In-App</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userPreferences.map(
                            (notification, index) =>
                              notification.isVisibleOnUI && (
                                <tr key={notification.id}>
                                  <td>
                                    <b>
                                    {notification?.title} 
                                    -</b>{" "}
                                    {notification?.subTitle}
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].emailValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          values.emailNotifications[index]
                                            ?.emailValue || false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `emailNotifications[${index}].emailValue`,
                                            e.target.checked
                                          );
                                        }}
                                        disabled={
                                          !notification.isEmailConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].textValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          values.emailNotifications[index]
                                            ?.textValue || false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `emailNotifications[${index}].textValue`,
                                            e.target.checked
                                          );
                                        }}
                                        disabled={
                                          !notification.isTextConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].inAppValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={values.emailNotifications[index]?.inAppValue || false}
                                        disabled={
                                          !notification.isInAppConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex gap-20 align-items-center btnFooter">
                      <Button
                        variant="secondary"
                        onClick={() => onPreferencesSubmit(values)}
                      >
                        Save Changes
                      </Button>
                      <Button
                        variant="white"
                        size=""
                        onClick={() => resetForm()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
              </Formik>
            )}
          </div>
          )}
          <Modal className="modalDialog" size="sm" centered show={showPopup}>
            <ConfimrationPopup
              message="Your details have been saved successfully"
              onClose={onResponseClose}
            />
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default Preferences;
