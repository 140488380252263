import React from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button } from "react-bootstrap";

const PaymentError = () => {
    return (
        <div className='pages'>
            <PageHeader title="" />
            <div className='d-flex justify-content-center align-items-center h-100 flex-grow-1 overflow-auto'>

                <div className='text-center mx-auto mwidth-540'>
                    <div className='mb-32'>
                        <svg width="100" height="100" viewBox="0 0 100 100" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="50" cy="50" r="50" fill="#EFEFEF" />
                            <path d="M82.6665 31.9999V38.4532C82.6665 42.6666 79.9998 45.3333 75.7865 45.3333H66.6665V26.6932C66.6665 23.7332 69.0932 21.3333 72.0532 21.3333C74.9598 21.3599 77.6265 22.5332 79.5465 24.4532C81.4665 26.3999 82.6665 29.0666 82.6665 31.9999Z" fill="white" stroke="#888888" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M29.3335 34.6666V71.9999C29.3335 74.2133 31.8402 75.4666 33.6002 74.1333L38.1602 70.7199C39.2268 69.9199 40.7202 70.0266 41.6802 70.9866L46.1068 75.4399C47.1468 76.4799 48.8535 76.4799 49.8935 75.4399L54.3735 70.9599C55.3068 70.0266 56.8002 69.9199 57.8402 70.7199L62.4002 74.1333C64.1602 75.4399 66.6668 74.1866 66.6668 71.9999V26.6666C66.6668 23.7333 69.0668 21.3333 72.0002 21.3333H42.6668H40.0002C32.0002 21.3333 29.3335 26.1066 29.3335 31.9999V34.6666Z" fill="white" stroke="#888888" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M48 60.6934H56" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M48 50.0254H56" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9883 60.666H40.0122H39.9883Z" fill="white" />
                            <path d="M39.9883 60.666H40.0122" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M39.9883 50H40.0122H39.9883Z" fill="white" />
                            <path d="M39.9883 50H40.0122" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M53.0653 42.8182V24.6364H54.2301V42.8182H53.0653ZM56.1548 30.6378C56.098 30.0649 55.8542 29.6198 55.4233 29.3026C54.9924 28.9853 54.4077 28.8267 53.669 28.8267C53.1671 28.8267 52.7434 28.8977 52.3977 29.0398C52.0521 29.1771 51.7869 29.3688 51.6023 29.6151C51.4223 29.8613 51.3324 30.1406 51.3324 30.4531C51.3229 30.7135 51.3774 30.9408 51.4957 31.1349C51.6188 31.3291 51.7869 31.4972 52 31.6392C52.2131 31.7765 52.4593 31.8973 52.7386 32.0014C53.018 32.1009 53.3163 32.1861 53.6335 32.2571L54.9403 32.5696C55.5748 32.7116 56.1572 32.901 56.6875 33.1378C57.2178 33.3745 57.6771 33.6657 58.0653 34.0114C58.4536 34.357 58.7543 34.7642 58.9673 35.233C59.1851 35.7017 59.2964 36.2391 59.3011 36.8452C59.2964 37.7353 59.0691 38.5071 58.6193 39.1605C58.1742 39.8092 57.5303 40.3134 56.6875 40.6733C55.8494 41.0284 54.8385 41.206 53.6548 41.206C52.4806 41.206 51.4579 41.026 50.5866 40.6662C49.7202 40.3063 49.0431 39.7737 48.5554 39.0682C48.0724 38.358 47.8191 37.4796 47.7955 36.4332H50.7713C50.8045 36.9209 50.9441 37.3281 51.1903 37.6548C51.4413 37.9768 51.7751 38.2206 52.1918 38.3864C52.6132 38.5473 53.089 38.6278 53.6193 38.6278C54.1402 38.6278 54.5923 38.5521 54.9759 38.4006C55.3641 38.2491 55.6648 38.0384 55.8778 37.7685C56.0909 37.4986 56.1974 37.1884 56.1974 36.8381C56.1974 36.5114 56.1004 36.2367 55.9062 36.0142C55.7169 35.7917 55.4375 35.6023 55.0682 35.446C54.7036 35.2898 54.2562 35.1477 53.7259 35.0199L52.142 34.6222C50.9157 34.3239 49.9474 33.8575 49.2372 33.223C48.527 32.5885 48.1742 31.7339 48.179 30.6591C48.1742 29.7784 48.4086 29.009 48.8821 28.3509C49.3603 27.6927 50.0161 27.179 50.8494 26.8097C51.6828 26.4403 52.6297 26.2557 53.6903 26.2557C54.7699 26.2557 55.7121 26.4403 56.517 26.8097C57.3267 27.179 57.9564 27.6927 58.4062 28.3509C58.8561 29.009 59.0881 29.7713 59.1023 30.6378H56.1548Z" fill="#4A154B" />
                            <path d="M21.66 54.2576H38.325C43.665 54.2576 45 55.5776 45 60.8426V73.1576C45 78.4226 43.665 79.7426 38.34 79.7426H21.66C16.335 79.7576 15 78.4376 15 73.1726V60.8426C15 55.5776 16.335 54.2576 21.66 54.2576Z" fill="#D9D9D9" stroke="#D9D9D9" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M16 61.7598H43.5" stroke="#4A154B" stroke-width="4" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round" />
                            <path d="M15 75L33 75" stroke="white" stroke-width="3" stroke-miterlimit="10" stroke-linecap="square" stroke-linejoin="round" />
                            <path d="M17.25 69.0398C17.036 69.0398 16.8537 68.9815 16.7031 68.8651C16.5526 68.7476 16.4375 68.5777 16.358 68.3551C16.2784 68.1316 16.2386 67.8617 16.2386 67.5455C16.2386 67.2311 16.2784 66.9626 16.358 66.7401C16.4384 66.5166 16.554 66.3461 16.7045 66.2287C16.8561 66.1103 17.0379 66.0511 17.25 66.0511C17.4621 66.0511 17.6435 66.1103 17.794 66.2287C17.9455 66.3461 18.0611 66.5166 18.1406 66.7401C18.2211 66.9626 18.2614 67.2311 18.2614 67.5455C18.2614 67.8617 18.2216 68.1316 18.142 68.3551C18.0625 68.5777 17.9474 68.7476 17.7969 68.8651C17.6463 68.9815 17.464 69.0398 17.25 69.0398ZM17.25 68.7273C17.4621 68.7273 17.6269 68.625 17.7443 68.4205C17.8617 68.2159 17.9205 67.9242 17.9205 67.5455C17.9205 67.2936 17.8935 67.0791 17.8395 66.902C17.7865 66.7249 17.7098 66.59 17.6094 66.4972C17.5099 66.4044 17.3902 66.358 17.25 66.358C17.0398 66.358 16.8755 66.4616 16.7571 66.669C16.6387 66.8755 16.5795 67.1676 16.5795 67.5455C16.5795 67.7973 16.6061 68.0114 16.6591 68.1875C16.7121 68.3636 16.7884 68.4976 16.8878 68.5895C16.9882 68.6813 17.1089 68.7273 17.25 68.7273Z" fill="#232323" />
                            <path d="M20.25 69.0398C20.036 69.0398 19.8537 68.9815 19.7031 68.8651C19.5526 68.7476 19.4375 68.5777 19.358 68.3551C19.2784 68.1316 19.2386 67.8617 19.2386 67.5455C19.2386 67.2311 19.2784 66.9626 19.358 66.7401C19.4384 66.5166 19.554 66.3461 19.7045 66.2287C19.8561 66.1103 20.0379 66.0511 20.25 66.0511C20.4621 66.0511 20.6435 66.1103 20.794 66.2287C20.9455 66.3461 21.0611 66.5166 21.1406 66.7401C21.2211 66.9626 21.2614 67.2311 21.2614 67.5455C21.2614 67.8617 21.2216 68.1316 21.142 68.3551C21.0625 68.5777 20.9474 68.7476 20.7969 68.8651C20.6463 68.9815 20.464 69.0398 20.25 69.0398ZM20.25 68.7273C20.4621 68.7273 20.6269 68.625 20.7443 68.4205C20.8617 68.2159 20.9205 67.9242 20.9205 67.5455C20.9205 67.2936 20.8935 67.0791 20.8395 66.902C20.7865 66.7249 20.7098 66.59 20.6094 66.4972C20.5099 66.4044 20.3902 66.358 20.25 66.358C20.0398 66.358 19.8755 66.4616 19.7571 66.669C19.6387 66.8755 19.5795 67.1676 19.5795 67.5455C19.5795 67.7973 19.6061 68.0114 19.6591 68.1875C19.7121 68.3636 19.7884 68.4976 19.8878 68.5895C19.9882 68.6813 20.1089 68.7273 20.25 68.7273Z" fill="#232323" />
                            <path d="M23.25 69.0398C23.036 69.0398 22.8537 68.9815 22.7031 68.8651C22.5526 68.7476 22.4375 68.5777 22.358 68.3551C22.2784 68.1316 22.2386 67.8617 22.2386 67.5455C22.2386 67.2311 22.2784 66.9626 22.358 66.7401C22.4384 66.5166 22.554 66.3461 22.7045 66.2287C22.8561 66.1103 23.0379 66.0511 23.25 66.0511C23.4621 66.0511 23.6435 66.1103 23.794 66.2287C23.9455 66.3461 24.0611 66.5166 24.1406 66.7401C24.2211 66.9626 24.2614 67.2311 24.2614 67.5455C24.2614 67.8617 24.2216 68.1316 24.142 68.3551C24.0625 68.5777 23.9474 68.7476 23.7969 68.8651C23.6463 68.9815 23.464 69.0398 23.25 69.0398ZM23.25 68.7273C23.4621 68.7273 23.6269 68.625 23.7443 68.4205C23.8617 68.2159 23.9205 67.9242 23.9205 67.5455C23.9205 67.2936 23.8935 67.0791 23.8395 66.902C23.7865 66.7249 23.7098 66.59 23.6094 66.4972C23.5099 66.4044 23.3902 66.358 23.25 66.358C23.0398 66.358 22.8755 66.4616 22.7571 66.669C22.6387 66.8755 22.5795 67.1676 22.5795 67.5455C22.5795 67.7973 22.6061 68.0114 22.6591 68.1875C22.7121 68.3636 22.7884 68.4976 22.8878 68.5895C22.9882 68.6813 23.1089 68.7273 23.25 68.7273Z" fill="#232323" />
                            <path d="M26.25 69.0398C26.036 69.0398 25.8537 68.9815 25.7031 68.8651C25.5526 68.7476 25.4375 68.5777 25.358 68.3551C25.2784 68.1316 25.2386 67.8617 25.2386 67.5455C25.2386 67.2311 25.2784 66.9626 25.358 66.7401C25.4384 66.5166 25.554 66.3461 25.7045 66.2287C25.8561 66.1103 26.0379 66.0511 26.25 66.0511C26.4621 66.0511 26.6435 66.1103 26.794 66.2287C26.9455 66.3461 27.0611 66.5166 27.1406 66.7401C27.2211 66.9626 27.2614 67.2311 27.2614 67.5455C27.2614 67.8617 27.2216 68.1316 27.142 68.3551C27.0625 68.5777 26.9474 68.7476 26.7969 68.8651C26.6463 68.9815 26.464 69.0398 26.25 69.0398ZM26.25 68.7273C26.4621 68.7273 26.6269 68.625 26.7443 68.4205C26.8617 68.2159 26.9205 67.9242 26.9205 67.5455C26.9205 67.2936 26.8935 67.0791 26.8395 66.902C26.7865 66.7249 26.7098 66.59 26.6094 66.4972C26.5099 66.4044 26.3902 66.358 26.25 66.358C26.0398 66.358 25.8755 66.4616 25.7571 66.669C25.6387 66.8755 25.5795 67.1676 25.5795 67.5455C25.5795 67.7973 25.6061 68.0114 25.6591 68.1875C25.7121 68.3636 25.7884 68.4976 25.8878 68.5895C25.9882 68.6813 26.1089 68.7273 26.25 68.7273Z" fill="#232323" />
                            <circle cx="69" cy="25" r="10" fill="#F25E4A" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M64.9782 20.9783C65.1491 20.8075 65.4261 20.8075 65.597 20.9783L73.0216 28.4029C73.1924 28.5738 73.1924 28.8508 73.0216 29.0216C72.8507 29.1925 72.5737 29.1925 72.4029 29.0216L64.9782 21.597C64.8074 21.4262 64.8074 21.1492 64.9782 20.9783Z" fill="white" />
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M73.0218 20.9783C73.1926 21.1492 73.1926 21.4262 73.0218 21.597L65.5971 29.0216C65.4263 29.1925 65.1493 29.1925 64.9784 29.0216C64.8076 28.8508 64.8076 28.5738 64.9784 28.4029L72.403 20.9783C72.5739 20.8075 72.8509 20.8075 73.0218 20.9783Z" fill="white" />
                        </svg>

                    </div>


                    <h1 className='mb-3'>Payment Failed</h1>
                    <p className='mb-32 text-gray-300'>Lorem ipsum dolor sit amet. Et molestias ratione est architecto numquam aut modi odit aut harum quod est laborum.</p>



                    <div className='justify-content-center d-flex gap-3 pb-5'>
                        <Button variant="secondary" >
                            Retry Payment
                        </Button>
                        <Button variant="white" >
                            Cancel Payment
                        </Button>
                    </div>

                </div>




            </div>
        </div>
    );
}

export default PaymentError;
