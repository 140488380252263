import React from 'react';

const CheckBoxMd = ({ text,value,colName,onChange }) => {

    const handleChange =(value,colName)=> {
        if (onChange) {
            onChange(value,colName);
        }
      }
    return (
        <label className='checkBox md'>
            <input type="checkBox" checked={value} onChange={e => handleChange(e.target.checked,colName)}/>
            <span className='checkBoxCheck'></span>
            {text && <span className='ms-2'>{text}</span>}
        </label>
    );
}

export default CheckBoxMd;
