

import React from 'react';

import { Row, Col, Container, Button, Accordion } from "react-bootstrap";


import { IconPPT, IconDownload, IconReportIssue }from "../../icons/Icons";

import AccountImg from '../../assets/images/Account.png';
import { Link, useLocation, useHistory } from "react-router-dom";
import Search from "../../components/Search/Search";
import OwnerImg from '../../assets/images/Account.png';

const CatalogDetails = () => {
    return (
        <div className='pages'>
            <header className='pageHeader mb-0'>
                <Container fluid>
                    <Row className='mx-0 align-items-center'>
                        <Col className='px-0'>
                        <div class="d-flex align-items-center gap-2">
                        <Link to="/authoring">
                            <span className="arrow left"></span>
                        </Link>
                            <div><p class="h6 text-gray-200 mb-1">3 Modules</p><h3>Mission and Vision</h3></div></div>
                    
                        </Col>
                        <Col className='px-0 text-end '>
                            <div className="d-flex gap-3 align-items-center justify-content-end">
                   
                                <Button variant="white">
                                    <div className="d-flex gap-2 align-items-center">
                                    <span>Previous</span>
                                    </div>
                                </Button>

                                <Button variant="secondary">
                                    Next
                                </Button>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <div className='pageOuter p-0 bg-base-100'>
                    <div className='d-flex flex-column h-100'>
                        <div className='w-100 flex-grow-1 h-100 overflow-auto'>
                            <Row className='g-0 d-flex h-100'>
                                <Col className='col-lg-9 flex-grow-1 overflow-auto h-100'>
                                    <div className='p-32 pb-4'>
                                    
                               <div className='rounded-6 border'>
                               <div className='p-32 bg-gray-600 '>
                                       <div className='catlogHeading d-flex'>
                                            <div className='count'>
                                                <span className='roundCount'>1</span>
                                            </div>
                                            <div className='ms-3 ps-1'>
                                                <h4>Introduction to Our Compliance Policies</h4>
                                                <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam malesuada imperdiet enim sed mollis. <br></br>Phasellus vestibulum tincidunt neque.</p>
                                                <p className='text-12 m-0 text-gray-300'>Expiring on <span className='text-base-200'>Jul 26</span></p>
                                            </div>
                                       </div>
                                    </div>

                                    <div>
                                        bottom section video audio
                                    </div>

                                    <div className='p-32'>
                                        <div className='textList mb-4'>
                                            <h5>Analytics home</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>The module helps the users to understand their expenses deeply. It provides users with a visual representation of their expenses till date. It provides different and smart insights for better decision making of further expenses.</p>
                                        </div>
                                        <div className='textList mb-4'>
                                            <h5>Total Expenses</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>Total expense is a visual representation of all the expenses done in past months and till date. By default, it shows the expenses of all the categories of the current month. To view the expenses of other months, the user has to click on the month given on the bar graph.<br></br>
Expensive week/month/quarter: Within the total expenses, the user gets a detailed tour of their expenses. The chart shows a visual representation of the most expensive wee/month/quarter by selecting the filter. It also shows major contributors of the most expensive week/month/quarter. By clicking on the particular week/month/quarter, users can see the contributor categories of that period which helps them to analyze their expenses.</p>
                                        </div>
                                        <div className='textList mb-4'>
                                            <h5>Summary</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>The module helps the users to understand their expenses deeply. It provides users with a visual representation of their expenses till date. It provides different and smart insights for better decision making of further expenses.</p>
                                        </div>
                                    </div>

                                    <div className='p-32'>
                                       Qustion answar
                                    </div>

                               </div>


                                    </div>
                                </Col>
                                <Col className='col-lg-3 flex-grow-1 h-100 catalogSidebar'>
                                    <div className='bg-gray-600 p-20 py-32 h-100 borderLeft2 overflow-auto'>
                                    <h5 className='pb-2'>Next in queue</h5>
                                 
                                <Accordion defaultActiveKey="0" className='catlogAccordion'>
                                <Accordion.Item eventKey="1"  className="mb-3">
                                        <Accordion.Header>
                                        <div className='catlogHeading d-flex my-3'>
                                            <div className='count'>
                                                <span className='roundCount'>1</span>
                                            </div>
                                            <div className='ms-1 px-3 acContent'>
                                                <span class="tableTag text-md mb-2">Active</span>
                                                <h4>Introduction to Our Compliance Policies</h4>
                                              
                                            </div>
                                       </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <h6 className='mb-20'>Resources</h6>
                                                <div className="d-flex justify-content-between align-items-center mb-20">
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <div>
                                                        <IconPPT />
                                                        </div>
                                                        <div>
                                                    <h6 className='font-medium'>Intro company.ppt</h6>
                                                        <p className="mb-0 text-gray-300 text-12">1.27 MB</p>
                                                        </div>
                                                    </div>
                                                    <a href="" target="blank" download>
                                                        <IconDownload />
                                                    </a>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-20">
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <div>
                                                        <IconPPT />
                                                        </div>
                                                        <div>
                                                    <h6 className='font-medium'>Intro company.ppt</h6>
                                                        <p className="mb-0 text-gray-300 text-12">1.27 MB</p>
                                                        </div>
                                                    </div>
                                                    <a href="" target="blank" download>
                                                        <IconDownload />
                                                    </a>
                                                </div>
                                                <div className="mb-4 pt-2">
                                                    <h6 className="mb-20">Message from Author</h6>
                                                    <div className="p-20 bg-base-100 rounded-6">
                                                    <p className="text-md text-gray-200">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                    </p>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                        <Accordion.Item eventKey="2"  className="mb-3">
                                        <Accordion.Header>
                                        <div className='catlogHeading d-flex my-3'>
                                            <div className='count'>
                                                <span className='roundCount'>2</span>
                                            </div>
                                            <div className='ms-1 px-3 acContent'>
                                                <span class="tableTag text-md mb-2">Active</span>
                                                <h4>Understanding Quality assurance</h4>
                                              
                                            </div>
                                       </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <h6 className='mb-20'>Resources</h6>
                                                <div className="d-flex justify-content-between align-items-center mb-20">
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <div>
                                                        <IconPPT />
                                                        </div>
                                                        <div>
                                                    <h6 className='font-medium'>Intro company.ppt</h6>
                                                        <p className="mb-0 text-gray-300 text-12">1.27 MB</p>
                                                        </div>
                                                    </div>
                                                    <a href="" target="blank" download>
                                                        <IconDownload />
                                                    </a>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-20">
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <div>
                                                        <IconPPT />
                                                        </div>
                                                        <div>
                                                    <h6 className='font-medium'>Intro company.ppt</h6>
                                                        <p className="mb-0 text-gray-300 text-12">1.27 MB</p>
                                                        </div>
                                                    </div>
                                                    <a href="" target="blank" download>
                                                        <IconDownload />
                                                    </a>
                                                </div>
                                                <div className="mb-4 pt-2">
                                                    <h6 className="mb-20">Message from Author</h6>
                                                    <div className="p-20 bg-base-100 rounded-6">
                                                    <p className="text-md text-gray-200">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                    </p>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                        <Accordion.Item eventKey="3"  className="mb-3">
                                        <Accordion.Header>
                                        <div className='catlogHeading d-flex my-3'>
                                            <div className='count'>
                                                <span className='roundCount'>3</span>
                                            </div>
                                            <div className='ms-1 px-3 acContent'>
                                            <span class="tableTag text-md mb-2">Active</span>
                                                <h4>Introduction to Business Ethics</h4>
                                                
                                            </div>
                                       </div>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <h6 className='mb-20'>Resources</h6>
                                                <div className="d-flex justify-content-between align-items-center mb-20">
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <div>
                                                        <IconPPT />
                                                        </div>
                                                        <div>
                                                    <h6 className='font-medium'>Intro company.ppt</h6>
                                                        <p className="mb-0 text-gray-300 text-12">1.27 MB</p>
                                                        </div>
                                                    </div>
                                                    <a href="" target="blank" download>
                                                        <IconDownload />
                                                    </a>
                                                </div>
                                                <div className="d-flex justify-content-between align-items-center mb-20">
                                                    <div className="d-flex gap-3 align-items-center">
                                                        <div>
                                                        <IconPPT />
                                                        </div>
                                                        <div>
                                                    <h6 className='font-medium'>Intro company.ppt</h6>
                                                        <p className="mb-0 text-gray-300 text-12">1.27 MB</p>
                                                        </div>
                                                    </div>
                                                    <a href="" target="blank" download>
                                                        <IconDownload />
                                                    </a>
                                                </div>
                                                <div className="mb-4 pt-2">
                                                    <h6 className="mb-20">Message from Author</h6>
                                                    <div className="p-20 bg-base-100 rounded-6">
                                                    <p className="text-md text-gray-200">
                                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.
                                                    </p>
                                                    </div>
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                </Accordion>

                   
                                     
                                    

                                       <div className="d-flex gap-2 align-items-center">
                                            <div>
                                                <IconReportIssue />
                                            </div>
                                            <button className="text-primary-200 h6 font-bold">
                                                Report an issue
                                            </button>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </div>      
            </div >
        </div >
    );
}

export default CatalogDetails;

