import React, { useEffect, useState } from "react";
import { IconDownload } from "../../../icons/Icons";
import { generatePresignedUrl } from "../../../api/common-store-api";
import { getGUID } from "../../../common";

const DownloadLink = ({ url }) => {
  const [content, setContentUrlData] = useState(null);

  const getPreviewUrl = async () => {
    const getGuid = getGUID(url);
    let previewUrl = await generatePresignedUrl(getGuid);
    if (previewUrl?.data) {
      setContentUrlData(previewUrl.data);
    }
  };

  useEffect(() => {
    getPreviewUrl();
  }, []);

  return (
    <>
      <a href={content} target="blank" download>
        <IconDownload />
      </a>
    </>
  );
};

export default DownloadLink;
