import React, { useState, useEffect } from "react";
import { IconSort } from "../../icons/Icons";

import { Accordion } from "react-bootstrap";

const FilterTemplate = ({ templateDetail, type, setSelectedData }) => {
  const [viewFilter, setViewFilter] = useState(false);
  const [checked, setChecked] = useState([]);
  const [selectedTemplates, setSelectedTemplates] = useState([]);
  

  useEffect(() => {
    if (checked.length === 0) {
      setSelectedData(templateDetail);
    } else {
      const updatedSelectedData = templateDetail.filter((item) =>
        checked.includes(item.title)
      );
      setSelectedData(updatedSelectedData);
    }
  }, [checked, templateDetail]);

  const handleCheckboxClick = (title) => {
    const isChecked = checked.includes(title);
    const updatedChecked = isChecked
      ? checked.filter((item) => item !== title)
      : [...checked, title];

    setChecked(updatedChecked);
  };

  // const handleSelectAll = () => {
  //   if (checked.length === 0) {
  //     const allTemplateTitles = templateDetail.map((item) => item.title);
  //     setChecked(allTemplateTitles);
  //     setSelectedTemplates(allTemplateTitles);
  //   } else {
  //     setChecked([]);
  //     setSelectedTemplates([]);
  //   }
  // };

  return (
    <div
      className={
        viewFilter
          ? "filters sideFilterall bg-base-100"
          : "filters sideFilterall bg-base-100"
      }
    >
      <div className="borderBottom py-2">
        <h6 className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center py-3">
          Frequently used
        </h6>
        {templateDetail
          .filter((c) => c.templateusedCount >= 3)
          .map((c, index) => (
            <ul className="m-0 p-0" key={index}>
              {c.type === "Process" || c.type === "Sequence" || c.type === "Module" ? (
                <li className="mb-2">
                  <label className="checkBox">
                    <input
                      type="checkbox"
                      value={c.title}
                      checked={checked.includes(c.title)}
                      onChange={() => handleCheckboxClick(c.title)}
                    />
                    <span className="checkBoxCheck"></span>
                    <label
                      className="form-check-label text-md text-gray-200 mt-1"
                      htmlFor="flexCheckDefault"
                    >
                      {c.title}
                    </label>
                  </label>
                </li>
              ) : null}
            </ul>
          ))}
      </div>

      <div className="py-2">
        <ul className="m-0 p-0 pt-1">
          {templateDetail != null &&
            templateDetail.map((c, index) => (
              <React.Fragment key={index}>
                {c.type === "Process" || c.type === "Sequence" || c.type === "Module" ? (
                  <ul className="m-0 p-0">
                    <li className="mb-2">
                      <label className="checkBox">
                        <input
                          type="checkbox"
                          value={c.title}
                          checked={checked.includes(c.title)}
                          onChange={() => handleCheckboxClick(c.title)}
                        />
                        <span className="checkBoxCheck"></span>
                        <label
                          className="form-check-label text-md text-gray-200 mt-1"
                          htmlFor="flexCheckDefault"
                        >
                          {c.title}
                        </label>
                      </label>
                    </li>
                  </ul>
                ):(null)}
              </React.Fragment>
            ))}
        </ul>
      </div>
    </div>
  );
};

export default FilterTemplate;
