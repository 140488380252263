import React from "react";
import { Modal, Button } from "react-bootstrap";
import { Link } from "react-router-dom";

const RestrictedUpgradeToBusinessModalPopup = ({
  onCancel,
  headerLable,
  chldLable,
}) => {
  // const requestFor = requestType ? requestType : ;
  return (
    <>
      <Modal.Header closeButton onClick={() => onCancel(false)} />
      <Modal.Body className="pb-4">
        <h3 className=""> {"Upgrade to access this feature"}</h3>
        <p className="text-gray-300 mb-0 mt-2 mb-5 h5">
          The current subscription does not allow the bulk actions. Switch to <a href="#">business plan</a> to keep using feature like this and many more.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
        <Button variant="white" size="" className="m-0" onClick={() => onCancel(false)}>
          Cancel
        </Button>
        
        <Link to="/accountsetting/billingsubscription">
          <Button
            variant="primary"
            size=""
            className="border-0 m-0"
            // onClick={() => requestType === "NotDelete" ? onCancel(false) : onConfirm(requestType)}
          >
            Upgrade to Business plan
          </Button>
        </Link>
      </Modal.Footer>
    </>
  );
};

export default RestrictedUpgradeToBusinessModalPopup;
