import React, { useState } from "react";
import {
  IconTopSearchSmall,
  IconCalendarSm,
  IconCalendar,
  IconTeams,
  IconRole,
  IconStatus,
  IconSequence,
  IconModules,
  IconFiltersMenu,
  IconFiltersBack,
  IconAssignment,
  IconJoinDate,
  IconSort,
  IssueType,
  RequestSent,
  CreateProcess,
} from "../../icons/Icons";
import DatePicker from "react-datepicker";
// import {
//   IconStatus,
//   IconFiltersMenu,
//   IconFiltersBack,
// } from "../../icons/Icons";
import { Button, Accordion } from "react-bootstrap";
import CheckBox from "../../components/CheckBox/CheckBox";
import RadioButton from "../CheckBox/RadioButton";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
import FilterList from "./FilterList";
const Filters = ({
  type,
  onChange,
  statusList,
  issueType,
  sortBy,
  onClearAll,
  datepicker = null,
  dateRange = null,
  filters = null,
}) => {
  const [viewFilter, setViewFilter] = useState(false);
  const [checked, setChecked] = useState([]);
  const [checkedIssue, setCheckedIssue] = useState([]);
  const [selectAll, setselectAll] = useState(false);
  const [selectAllIssue, setselectAllIssue] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const [sortByName, setsortBy] = useState("");

  // console.log(issueType,'issueType');
  // console.log(statusList,'statusList');

  const onSortByChange = (event) => {
    setsortBy(event.target.value);
    if (onChange) {
      onChange(event.target.value, "sortBy");
    }
  };

  const hadnleSelectAll = (event) => {
    let selectList = [];
    if (event.target.checked) {
      selectList = statusList?.map((m) => {
        return m.name;
      });
      setChecked(selectList);
    } else {
      setChecked(selectList);
    }
    if (selectList.length === statusList.length) {
      setselectAll(true);
    } else {
      setselectAll(false);
    }
    if (onChange) {
      onChange(selectList, "status");
    }
  };
  const handleStatusClick = (event) => {
    setselectAll(false);
    var updatedList = [...checked];
    if (event.target.checked) {
      updatedList = [...checked, event.target.value];
    } else {
      updatedList.splice(checked.indexOf(event.target.value), 1);
    }
    if (updatedList.length === statusList.length) {
      setselectAll(true);
    } else {
      setselectAll(false);
    }
    setChecked(updatedList);
    if (onChange) {
      onChange(updatedList, "status");
    }
  };

  const hadnleSelectAllIssue = (event) => {
    let selectList = [];
    if (event.target.checked) {
      selectList = issueType?.map((m) => {
        return m.value;
      });
      setCheckedIssue(selectList);
    } else {
      setCheckedIssue(selectList);
    }
    if (selectList.length === issueType.length) {
      setselectAllIssue(true);
    } else {
      setselectAllIssue(false);
    }
    if (onChange) {
      onChange(selectList, "issuetype");
    }
  };
  const onIssueChange = (event) => {
    setselectAllIssue(false);
    var updatedList = [...checkedIssue];
    if (event.target.checked) {
      updatedList = [...checkedIssue, event.target.value];
    } else {
      updatedList.splice(checkedIssue.indexOf(event.target.value), 1);
    }
    if (updatedList.length === issueType.length) {
      setselectAllIssue(true);
    } else {
      setselectAllIssue(false);
    }
    setCheckedIssue(updatedList);
    if (onChange) {
      onChange(updatedList, "issuetype");
    }
  };

  const dateChange = (value) => {
    if (datepicker.onDateChange) {
      datepicker.onDateChange.setRequestOnDate(value.toString());
    }
  };
  const dateRangeChange = (start, end) => {
    if (dateRange?.onDateChange) {
      dateRange?.onDateChange?.onDateRangeChange(start, end);
    }
  };
  const onClearAllFilter = () => {
    // console.log(datepicker,'datepicker');
    setChecked([]);
    setCheckedIssue([]);
    setErrorMessage("");
    setsortBy("");
    setselectAll(false);
    setselectAllIssue(false);
    if (onClearAll) {
      onClearAll();
    }
    dateRange?.onDateChange?.onDateRangeChange("", "");
    datepicker?.onDateChange?.setRequestOnDate("");
    // dateRange.onDateChange.onDateRangeChange(null, null);
    // datepicker.onDateChange.setRequestOnDate(null);
    // console.log(onClearAll,'onClearAll');
  };
  //console.log(filters, "filters");
  const getIconComponent = (header) => {
    switch (header) {
      case "Teams":
        return IconTeams;
      case "Role":
        return IconRole;
      case "Status":
        return IconStatus;
      case "Processes":
        return CreateProcess;
      case "Sequence":
        return IconSequence;
      case "Module":
        return IconModules;
      case "Assignment":
        return IconAssignment;
      default:
        return;
    }
  };

  return (
    <div
      className={
        viewFilter
          ? "filters sideFilterall"
          : "filters sideFilterall hideContent"
      }
    >
      <div>
        <div className="filtersTop ">
          <div className=" filter-clear mt-1">
            <div className="text-md font-bold text-gray-200">FILTERS</div>
            <div className="text-gray-400 text-xs">|</div>
            <button
              className="text-md text-primary-100 text-nowrap"
              onClick={() => onClearAllFilter()}
            >
              Clear all
            </button>
          </div>
          <button
            className="p-0 bg-gray-600 border-0 shadow-none icon-menu closefilbtn"
            onClick={() => setViewFilter(!viewFilter)}
          >
            <span className="iconfilmenu">
              <IconFiltersMenu />
            </span>
            <span className="iconfilarrow">
              <IconFiltersBack />
            </span>
          </button>
        </div>

        <Accordion className="filtersAccordian mt-3">
          {filters &&
            filters.length > 0 &&
            filters.map((f, index) => {
              const IconComponent = getIconComponent(f.header);
              return (
                <Accordion.Item
                  eventKey={index + 11}
                  className="mb-3"
                  key={index}
                >
                  <Accordion.Header className="mb-3">
                    <div className="d-flex justify-content-between align-items-center">
                      <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                        {IconComponent && <IconComponent />}
                        <span className="head">{f.header}</span>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <FilterList f={f} selectAlls={selectAll} />
                  </Accordion.Body>
                </Accordion.Item>
              );
            })}
          {statusList && statusList.length > 0 && (
            <Accordion.Item eventKey="7" className="mb-3">
              <Accordion.Header className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                    <IconStatus />
                    <span className="head">Status</span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  key={"SelectAll_Status"}
                  className="text-md text-gray-200 pb-1 mb-2"
                >
                  <label className="checkBox">
                    <input
                      value="Select_All"
                      type="checkbox"
                      onChange={(e) => hadnleSelectAll(e)}
                      checked={selectAll}
                    />
                    <span className="checkBoxCheck"></span>
                    <span className="">All</span>
                  </label>
                </div>
                {statusList.map((item, index) => {
                  return (
                    <>
                      <div
                        key={index + "_Status"}
                        className="text-md text-gray-200 pb-1 mb-2"
                      >
                        <label className="checkBox">
                          {/* {console.log(
                            checked.indexOf(item.name),
                            "checkedddddd"
                          )} */}
                          <input
                            value={item.name}
                            type="checkbox"
                            onChange={handleStatusClick}
                            checked={checked.indexOf(item.name) > -1}
                          />
                          <span className="checkBoxCheck"></span>
                          {item.name && <span className="">{item.name}</span>}
                        </label>
                      </div>
                    </>
                  );
                })}
              </Accordion.Body>
            </Accordion.Item>
          )}
          {issueType && issueType.length > 0 && (
            <Accordion.Item eventKey="8" className="mb-3">
              <Accordion.Header className="mb-3">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                    <IssueType />
                    <span className="head">Issue Type</span>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div
                  key={"SelectAll_Issue"}
                  className="text-md text-gray-200 pb-1 mb-2"
                >
                  <label className="checkBox">
                    <input
                      value="Select_All"
                      type="checkbox"
                      onChange={(e) => hadnleSelectAllIssue(e)}
                      checked={selectAllIssue}
                    />
                    <span className="checkBoxCheck"></span>
                    <span className="">All</span>
                  </label>
                </div>
                {issueType.map((item, index) => (
                  <div
                    key={index + "_Issue"}
                    className="text-md text-gray-200 pb-1 mb-2"
                  >
                    <label className="checkBox">
                      {/* {console.log(cheked.indexOf(item.text), "checkedddddd")} */}
                      <input
                        value={item.value}
                        type="checkbox"
                        onChange={onIssueChange}
                        checked={checkedIssue.includes(item.value)}
                      />
                      <span className="checkBoxCheck"></span>
                      {item.text && <span className="">{item.text}</span>}
                    </label>
                  </div>
                ))}
              </Accordion.Body>
            </Accordion.Item>
          )}

          {sortBy && sortBy.length > 0 && (
            <>
              <Accordion.Item eventKey="9" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconSort />
                      <span className="head">Sort By</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div onChange={onSortByChange}>
                    {sortBy.map((m) => (
                      <div className="text-md text-gray-200 pb-1 mb-2">
                        <RadioButton
                          parentName="sortBy"
                          text={m.text}
                          value={m.value}
                          defauluValue={sortByName}
                        />
                      </div>
                    ))}
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
          {datepicker && (
            <>
              <Accordion.Item eventKey="10" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <RequestSent />
                      <span className="head">{datepicker.lable}</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-md text-gray-200 pb-1 mb-2">
                      <div className="input-group myInput dateCalendar dateCalendarbgico">
                        {/* <IconCalendar /> */}
                        <DatePicker
                          selected={
                            datepicker.value ? new Date(datepicker.value) : ""
                          }
                          onChange={(value) => dateChange(value)}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
          {dateRange && (
            <>
              <Accordion.Item eventKey="11" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <RequestSent />
                      <span className="head">{dateRange.lable}</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div>
                    <div className="text-md text-gray-200 pb-1 mb-2">
                      <div className="input-group myInput dateCalendar dateCalendarbgico">
                        {/* <IconCalendar /> */}
                        <DateRangePicker
                          start={
                            dateRange.start ? new Date(dateRange.start) : ""
                          }
                          end={dateRange.end ? new Date(dateRange.end) : ""}
                          onChange={dateRangeChange}
                          setErrorMessage={setErrorMessage}
                          errorMessage={errorMessage}
                        />
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
          {type === "Users" && (
            <>
              <Accordion.Item eventKey="0" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconTeams />

                      <span className="head">Teams</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <form className="input-group myInput myInputSearch mb-3">
                    <input
                      type="text"
                      className="form-control py-1 px-2"
                      placeholder="Search by Team"
                    />
                    <IconTopSearchSmall />
                  </form>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="All" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Design" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Front end" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Backend" />
                  </div>

                  <a href="#" className="text-primary-200 text-sm">
                    +9 more
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="1" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconRole />

                      <span className="head">Role</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <form className="input-group myInput myInputSearch mb-3">
                    <input
                      type="text"
                      className="form-control py-1 px-2"
                      placeholder="Search..."
                    />
                    <IconTopSearchSmall />
                  </form>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="All" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Design" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Front end" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Backend" />
                  </div>

                  <a href="#" className="text-primary-200 text-sm">
                    +9 more
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="2" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconStatus />

                      <span className="head">Status</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <form className="input-group myInput myInputSearch mb-3">
                    <input
                      type="text"
                      className="form-control py-1 px-2"
                      placeholder="Search..."
                    />
                    <IconTopSearchSmall />
                  </form>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="All" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Design" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Front end" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Backend" />
                  </div>

                  <a href="#" className="text-primary-200 text-sm">
                    +9 more
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="3" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconSequence />

                      <span className="head">Sequences</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <form className="input-group myInput myInputSearch mb-3">
                    <input
                      type="text"
                      className="form-control py-1 px-2"
                      placeholder="Search by Sequence"
                    />
                    <IconTopSearchSmall />
                  </form>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="All" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Design" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Front end" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Backend" />
                  </div>

                  <a href="#" className="text-primary-200 text-sm">
                    +9 more
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="4" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconModules /> <span className="head">Modules</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <form className="input-group myInput myInputSearch mb-3">
                    <input
                      type="text"
                      className="form-control py-1 px-2"
                      placeholder="Search by Modules"
                    />
                    <IconTopSearchSmall />
                  </form>
                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text=" Design Onboarding" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Company core values" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Frontend Onboarding" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Backend Onboarding" />
                  </div>

                  <a href="#" className="text-primary-200 text-sm">
                    +5 more
                  </a>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="5" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconAssignment /> <span className="head">Assigment</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <form className="input-group myInput myInputSearch mb-3">
                    <input
                      type="text"
                      className="form-control py-1 px-2"
                      placeholder="Search by Assigments"
                    />
                    <IconTopSearchSmall />
                  </form>
                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Completed" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Pending" />
                  </div>

                  <div className="text-md text-gray-200 pb-1 mb-2">
                    <CheckBox text="Delayed" />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
              <Accordion.Item eventKey="6" className="mb-3">
                <Accordion.Header className="mb-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="text-md font-medium text-gray-100 d-flex gap-2 align-items-center">
                      <IconJoinDate />
                      <span className="head">Joining Date</span>
                    </div>
                  </div>
                </Accordion.Header>
                <Accordion.Body>
                  <div className="form-group calendar">
                    <input
                      type="text"
                      value="24/10/2022"
                      className="form-control py-1 px-2 text-sm"
                    />
                    <IconCalendarSm />
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </>
          )}
        </Accordion>
      </div>
    </div>
  );
};

export default Filters;
