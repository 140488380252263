import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllSequence,
  createSequence,
  updateSequence,
  deleteSequence,
  getSequenceDetails,
  getDropDownList,
  getModuleDropdownList,
  bulkDeleteSequence,
  bulkArchiveSequence,
  getSequencePreview,
  getSequenceFeedbackDetails,
  createSequenceSaveAsDraft,
  getSequenceTemplates,
} from "../api/sequence-store-api";

const initialState = {
  sequences: { records: [] },
  loading: false,
  page: 0,
  pageSize: 10,
  searchString: "",
  orderBy: "",
  isOrderByDesc: true,
  status: [],
  tags: [],
  sequenceSave: null,
  sequenceUpdate: null,
  sequenceDelete: null,
  currentSequence: null,
  dropdownList: [],
};

export const getSquenceAsync = createAsyncThunk(
  "sequence/getSquenceAll",
  async (request) => {
    const response = await getAllSequence(request);
    //console.log(response.data);
    return response.data;
  }
);

export const createSequenceAsync = createAsyncThunk(
  "sequence/createSequence",
  async (request) => {
    const response = await createSequence(request);
    return response.data;
  }
);

export const createSequenceSaveAsDraftAsync = createAsyncThunk(
  "sequence/createSequenceSaveAsDraft",
  async (request) => {
    const response = await createSequenceSaveAsDraft(request);
    return response.data;
  }
);

export const updateSequenceAsync = createAsyncThunk(
  "sequence/updateSequence",
  async (request) => {
    const response = await updateSequence(request);
    return response.data;
  }
);

export const deleteSequenceAsync = createAsyncThunk(
  "sequence/deletesequenceAsync",
  async (request) => {
    const response = await deleteSequence(request);
    return response.data;
  }
);

export const bulkDeleteSequenceAsync = createAsyncThunk(
  "sequence/bulkDeleteSequenceAsync",
  async (request) => {
    const response = await bulkDeleteSequence(request);
    return response.data;
  }
);

export const bulkArchiveSequenceAsync = createAsyncThunk(
  "sequence/bulkArchiveSequenceAsync",
  async (request) => {
    const response = await bulkArchiveSequence(request);
    return response.data;
  }
);

export const getSequenceDetailsAsync = createAsyncThunk(
  "sequence/getSequenceDetailsAsync",
  async (id) => {
    const response = await getSequenceDetails(id);
    return response.data;
  }
);

export const getSequencePreviewAsync = createAsyncThunk(
  "sequence/getSequencePreviewAsync",
  async (id) => {
    const response = await getSequencePreview(id);
    return response.data;
  }
);

export const getSequenceFeedbackDetailsAsync = createAsyncThunk(
  "sequence/getSequenceFeedbackDetailsAsync",
  async (id) => {
    const response = await getSequenceFeedbackDetails(id);
    return response.data;
  }
);

export const getModuleDropdownListAsync = createAsyncThunk(
  "sequence/getDropdownListAsync",
  async () => {
    const response = await getDropDownList();
    return response.data;
  }
);

export const getModuleDropdownListForSequenceAsync = createAsyncThunk(
  "sequence/getDropdownListAsync",
  async () => {
    const response = await getModuleDropdownList();
    return response.data;
  }
);

export const getSequenceTemplatesAsync = createAsyncThunk(
  "module/getSequenceTemplates",
  async (request) => {
    const response = await getSequenceTemplates(request)
    return response.data;
  }
);

const sequencesSlice = createSlice({
  name: "sequence",
  initialState,
  reducers: {},
  // reducers:{
  //     getPageData: (state,action)=>{
  //         state.notifications = action;
  //     },
  // },
  extraReducers: {
    [getSquenceAsync.pending]: () => {
      console.log("pending");
    },
    [getSquenceAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, sequences: payload };
    },
    [getSquenceAsync.rejected]: () => {
      console.log("rejected");
    },
    [getSequenceDetailsAsync.pending]: () => {},
    [getSequenceDetailsAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, currentSequence: payload };
    },

    [getSequenceTemplatesAsync.fulfilled]: (state, { payload }) => {
      return { ...state, sequenceTemplate: payload };
    },

    [getModuleDropdownListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, dropdownList: payload };
    },
    [getSequenceDetailsAsync.rejected]: () => {},
    // [createProcesses.pending]: () => {
    //   console.log("pending save processes");
    // },
    // [createProcesses.fulfilled]: (state, { payload }) => {
    //   console.log("full filled", payload);
    //   if (payload.status === 200) {
    //     let result = [
    //       "Processes save successfully!",
    //       new Date().toLocaleDateString(),
    //       payload.data,
    //     ];
    //     return { ...state, processesSave: result };
    //   } else {
    //     let result = [
    //       "Unable to save processes!",
    //       new Date().toLocaleDateString(),
    //       payload.data,
    //     ];
    //     return { ...state, processesSave: result };
    //   }
    // },
    // [createProcesses.rejected]: () => {
    //   console.log("rejected save processes");
    // },
  },
});

//export const { processesAdded, processesUpdated, processesDeleted } = processesSlice.actions;

export default sequencesSlice.reducer;
