export const pageType = [
  {
    value: 0,
    label: "PPT",
  },
  {
    value: 1,
    label: "Document",
  },
  {
    value: 2,
    label: "Video",
  },
  {
    value: 3,
    label: "Audio",
  },
  {
    value: 4,
    label: "Image",
  },
];
