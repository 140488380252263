import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button, Link } from "react-bootstrap";
import {
  IconStarLg,
  IconStarGray,
  IconPlanUpgrade,
} from "../../../icons/Icons";
import planBg from "../../../assets/images/plan_bg.png";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionAsync } from "../../../reducers/subscription-reducers";

const PlanRetained = () => {

    const dispatch = useDispatch();
    const [planName, setPlanName] = useState('');
    const { plansData, isUpdateLoading } = useSelector(
      (state) => state.subscription
    );
    const subscriptionData = useSelector((state) => state.subscription);
    useEffect(() => {
      dispatch(getSubscriptionAsync());
    }, []);

    useEffect(() => {
      const reversedPlans = [...plansData].reverse();
      const subscriptionItems = subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0 ? subscriptionData?.subscriptionData?.subscriptions[0]?.items[0] : [];
      for (let index = 0; index < reversedPlans.length; index++) {
        const plan = reversedPlans[index];
        // for (const price of plan) {
          // if (subscriptionItems.some(item => item?.price?.id.includes(plan?.priceId))) {
          if (subscriptionItems?.price?.id === plan?.priceId) {
            setPlanName(plan?.planName);
            break;
          }
        // }
      }
    }, [subscriptionData]);

    const hasSubscriptions = subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;

    console.log(subscriptionData,'subscriptionData');

  return (
    <div className="pages bg-gray-600">
      <PageHeader title="Account Settings" />
      <div className="d-flex align-items-center justify-content-center h-100 row g-0">
        <div className="text-center col-lg-6">
          <div className="mb-20">
            <IconPlanUpgrade />
          </div>

          <div className=" d-flex justify-content-center">
            <h1 className="mb-32">Plan will be cancelled!</h1>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-8 rounded-6 borderAll bg-base-100 text-start p-4 mb-32 position-relative">
              <p className="mb-1 font-medium">
              {/* Business */}
              {planName}
              </p>
              <p className="text-gray-1000 mb-20 text-md w-75">
                Live 60 minute training on using Additional Training and Support
                $500/mo as an addon
              </p>

              <h3>
              {/* $200 */}$
              {hasSubscriptions && (subscriptionData?.subscriptionData?.subscriptions[0]?.items[0]?.plan?.amount / 100)}
              </h3>

              <p className="text-gray-1000 mb-0 text-md">
                {/* Per member/month billed annually */}
                {hasSubscriptions && subscriptionData?.subscriptionData?.subscriptions[0]?.items[0]?.plan.nickname === "Yearly" ? " annually" : " monthly"}
              </p>
              <div className="position-absolute end-0 bottom-0">
                <img src={planBg} alt="" />
              </div>
            </div>
          </div>

          <div className="text-center">
            <a href="/accountsetting/billingsubscription/change">
              <button class="btn-secondary">View plans</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanRetained;
