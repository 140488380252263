import React, { useEffect, useRef, useState } from "react";
import {
  Row,
  Col,
  Container,
  Modal,
  Button,
  Dropdown,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import Search from "../../components/Search/Search";
import {
  IconSeqarrow,
  IconleftArrow,
  IconDots,
  IconrightArrow,
  IconDisableuser,
} from "../../icons/Icons";
import ModalResponse from "../../components/ModalPopup/ModalResponsePopup";
import AddUserEmployeeModal from "./AddUserEmployeeModal";
import AddUserModal from "./AddUserModal";
import FormDocumentModal from "./Modules/FormDocumentModal";

import DisableAccountModal from "./DisableAccountModal";
import DeleteAccountModal from "./DeleteAccountModal";
import DeletePersonalDataModal from "./DeletePersonalDataModal";

import AssignConenteeModal from "./AssignConenteeModal";

import Filters from "../../components/Filters/Filters";
import CheckBox from "../../components/CheckBox/CheckBox";
import OwnerImg from "../../assets/images/Account.png";
import AccountImg from "../../assets/images/Account.png";
import PageHeader from "../../components/PageHeader/PageHeader";

import { Link, useHistory, useLocation } from "react-router-dom";
import Progress from "./Progress";
import { NavLink } from "react-router-dom";
import FormModal from "./Modules/FormModal";
import {
  getUsersAsync,
  updateUsersAsync,
  setDisableAccountAsync,
  setdeleteAccountAsync,
  setdeletePersonalDataAsync,
  setBulkDisableAccountAsync,
  setBulkdeleteAccountAsync,
  setBulkdeletePersonalDataAsync,
  getTeamsAsync,
  getRolesAsync,
  setBulkEnableAccountAsync,
  setEnableAccountAsync,
  getUserUsageAsync,
} from "../../reducers/user-reducers";
import {
  getUserStatusListAsync,
  getAssignmentStatusListAsync,
} from "../../reducers/common-reducers";
import {
  getSequenceListAsync,
  getprocessesDropdownListAsync,
} from "../../reducers/processes-reducers";
import { getModuleDropdownListAsync } from "../../reducers/sequence-reducers";
import { useDispatch, useSelector } from "react-redux";
import ModalDelete from "../../components/ModalPopup/ModalDeletePopup";
import {
  IconHamberger,
  IconStar,
  IconArchive,
  IconDelete,
} from "../../icons/Icons";
import Loader from "../../components/Loader/Loader";
import NoResults from "../../helper/NoResults";
import getInitialsInName from "../../helper/GetNameInitials";
import UserDetail from "./UserDetail";
import EmptyState from "../../components/EmptyState/EmptyState";
import UserImage from "../../components/UserImage/UserImage";

import Pagination from "../../components/Pagination/Pagination";
import {
  getSubscriptionAsync,
  getPlansAsync,
  getStorageAsync,
} from "../../reducers/subscription-reducers";

import RestrictedUpgradeToBusinessModalPopup from "../../components/ModalPopup/RestrictedUpgradeToBusinessModalPopup";
import accountSettingsPopupUserImg from "../../assets/images/accountSettingsPopupUser.svg";
import { useDebounce } from "../../utils/hooks/useDebounce";

const Users = () => {
  const [isRestricted, setIsRestricted] = useState(false);
  const [userUsage, setUserUsage] = useState({});
  const [formDataReady, setFormDataReady] = useState(false);
  const [responsePopup, setResponsePopup] = useState({});
  const [searchString, setSearchString] = useState("");
  const [selectedStatus, setStatusList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [filters, setfiltersvalue] = useState({
    teamIds: [],
    statusIds: [],
    roleIds: [],
    sequenceIds: [],
    moduleIds: [],
    processeIds: [],
    assignmentStatuIds: [],
  });
  const [addUserEmployee, setAddUserEmployee] = useState(false);
  const [addUser, setAddUser] = useState(false);
  const [reloadUser, setReloadUser] = useState(false);

  const [assignConentee, setAssignConentee] = useState(false);
  const [isAssignButton, setIsAssignButton] = useState(false);
  const [disableAccount, setDisableAccount] = useState(false);
  const [enableAccount, setEnableAccount] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePersonalDataModal, setDeletePersonalDataModal] = useState(false);
  const [assignModalAlert, setAssignModalAlert] = useState(false);
  const [disableHelpdesk, setDisableHelpdesk] = useState(false);
  const [accountSettingsModalAlert, setAccountSettingsModalAlert] =
    useState(false);

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const [selectAll, setSelectAll] = useState(false);
  const dispatch = useDispatch();
  const entities = useSelector((state) => state.user.users);
  const statusList = useSelector((state) => state.common.userstatusList);
  const teamList = useSelector((state) => state.user.teams);
  const roleList = useSelector((state) => state.user.roles);
  const sequenceList = useSelector((state) => state.process.sequenceList);
  const moduleList = useSelector((state) => state.sequence.dropdownList);
  const assignmentList = useSelector(
    (state) => state.common.assignmentStatusList
  );
  const processesList = useSelector(
    (state) => state.process.processesdropdownList
  );

  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData, storageData } = useSelector((state) => state.subscription);
  const [userPlan, setUserPlan] = useState("");

  // console.log(processesList,'processesList');
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [selectedDataList, setSelectedDataList] = useState([]);
  const [activeSelectedItems, setActiveSelectedItems] = useState([]);
  const [startDate, setstartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [requestId, setRequestId] = useState(null);
  const [userDetailsShow, setUserDetailsShow] = useState(false);
  const [userId, setUserId] = useState(null);
  const [tabCount, setTabCount] = useState(null);

  const history = useHistory();
  const location = useLocation();
  const userIndex = location?.state && location?.state?.userIndex;
  const [filterss, setFilterss] = useState(false);
  const reloadUsers = location?.state && location?.state?.reloadUsers;
  console.log(reloadUsers, "reloadUsers");
  //console.log(userIndex,'userIndex');

  const [formDocumentModalShow, setFormDocumentModalShow] = useState(false);
  const [documentUserId, setDocumentUserId] = useState(null);
  const [documentUserName, setDocumentUserName] = useState(null);
  const [documentType, setDocumentType] = useState("");
  const [activeTab, setActiveTab] = useState("All");
  const [userDetails, setUserDetails] = useState([]);
  const [allUserDetail, setAllUserDetail] = useState([]);
  const [userCount, setUserCount] = useState();
  const [isActiveButton, setIsActiveButton] = useState(false);
  const [isDeleteButton, setIsDeleteButton] = useState(false);
  const [isArchiveButton, setIsArchiveButton] = useState(false);
  const [cantAssignArchived, setCantAssignArchived] = useState(false);

  const [isBulkAssign, setIsBulkAssign] = useState(false);
  const [isBulkUpdate, setIsBulkUpdate] = useState(false);
  const [isRestrictedModalPopup, setIsRestrictedModalPopup] = useState(false);

  // console.log(statusList,'statusList');
  const updatedStatusList = statusList.map((item) => {
    if (item.name === "Inactive") {
      return { ...item, name: "Archived" };
    } else if (item.name === "Active") {
      return { ...item, name: "Active" };
    }
    return item;
  });

  
  useEffect(() => {
    if (reloadUsers === true) {
      setReloadUser(true);
    }
  }, [reloadUsers]);

  // useEffect(() => {
  //   debouncedUsers();
  //  }, []);

  useEffect(() => {
    getUserUsage();
    dispatch(getStorageAsync());
    dispatch(getSubscriptionAsync());
  }, []);

  const getUserUsage = () => {
    dispatch(getUserUsageAsync()).then((res) => {
      if (res.payload) {
        setUserUsage(res.payload);
      }
    });
  };

  const onFilterchange = (type, selectoptions) => {
    setFilterss(true);
    setfiltersvalue({ ...filters, [type]: selectoptions });
    setCurrentPage(1);
  };
  const onDateRangeChange = (start, end) => {
    setFilterss(true);
    setstartDate(start);
    setEndDate(end);
    setCurrentPage(1);
  };
  const filtersRecord = [
    {
      header: "Teams",
      options: teamList.map((m) => {
        return { id: m.key, name: m.value };
      }),
      selectedoptions: filters && filters["teamIds"] ? filters["teamIds"] : [],
      onFilterChange: onFilterchange,
      filterColumnName: "teamIds",
      showAll: false,
      isSearchble: true,
    },
    // {
    //   header: "Role",
    //   options: roleList?.records?.map((m) => {
    //     return { id: m.id, name: m.name };
    //   }),
    //   selectedoptions: filters && filters["roleIds"] ? filters["roleIds"] : [],
    //   onFilterChange: onFilterchange,
    //   filterColumnName: "roleIds",
    // },
    {
      header: "Status",
      // options: statusList,
      options: updatedStatusList,
      selectedoptions:
        filters && filters["statusIds"] ? filters["statusIds"] : [],
      onFilterChange: onFilterchange,
      filterColumnName: "statusIds",
    },
    {
      header: "Processes",
      options: processesList.map((m) => {
        return { id: m.id, name: m.title };
      }),
      selectedoptions:
        filters && filters["processeIds"] ? filters["processeIds"] : [],
      onFilterChange: onFilterchange,
      filterColumnName: "processeIds",
      showAll: false,
      isSearchble: true,
    },
    {
      header: "Sequence",
      options: sequenceList.map((m) => {
        return { id: m.id, name: m.title };
      }),
      selectedoptions:
        filters && filters["sequenceIds"] ? filters["sequenceIds"] : [],
      onFilterChange: onFilterchange,
      filterColumnName: "sequenceIds",
      showAll: false,
      isSearchble: true,
    },
    {
      header: "Module",
      options: moduleList.map((m) => {
        return { id: m.id, name: m.title };
      }),
      selectedoptions:
        filters && filters["moduleIds"] ? filters["moduleIds"] : [],
      onFilterChange: onFilterchange,
      filterColumnName: "moduleIds",
      showAll: false,
      isSearchble: true,
    },
    {
      header: "Assignment",
      options: assignmentList,
      selectedoptions:
        filters && filters["assignmentStatuIds"]
          ? filters["assignmentStatuIds"]
          : [],
      onFilterChange: onFilterchange,
      filterColumnName: "assignmentStatuIds",
    },
  ];

  const getUsers = () => {
    setFormDataReady(false);
    let reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: sortColumn.column.sortField,
      isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
      searchString: searchString,
      filters,
      startDate: startDate || undefined,
      endDate: endDate || undefined,
    };

    // console.log("reqBody = ", reqBody);
    // debugger;
    dispatch(getUsersAsync(reqBody)).then((res) => {
      if (res.payload) {
        setTabCount(res?.payload?.userCounts);
        setUserCount(res.payload.allRecordsCount);
        setUserDetails(res.payload.users);
        setAllUserDetail(res.payload.users);

        if (activeTab === "All") {
          setUserDetails(res?.payload?.users);
        } else if (activeTab === "Admin") {
          setUserDetails(
            res?.payload?.users?.filter((item) => item.role === "Admin")
          );
        } else if (activeTab === "Employee") {
          setUserDetails(
            res?.payload?.users?.filter((item) => item.role === "Employee")
          );
        }
      }
      // console.log(res,"res")
      setFormDataReady(true);
      setSelectAll(false);
    });
  };

  const onFilterChange = (value, type) => {
    setFilterss(true);
    if (type == "sortBy") {
      setSortColumn(prepareSortFilterRequest(value));
    } else if (type === "status") {
      setStatusList(value);
    }
    setCurrentPage(1);
  };
  const prepareSortFilterRequest = (val) => {
    if (val) {
      let sortColumnDetails = val.split("_");
      let sortColumnName = sortColumnDetails[0];
      return {
        column: { sortField: sortColumnName },
        sortDirection: sortColumnDetails[1] == "asc" ? "asc" : "desc",
      };
    }
  };
  const onClearAll = () => {
    setFilterss(false);
    setStatusList([]);
    setCurrentPage(1);
    setPageSize(50);
    setSortColumn({
      column: "",
      sortDirection: "",
    });
    setfiltersvalue({
      teamIds: [],
      statusIds: [],
      roleIds: [],
      sequenceIds: [],
      moduleIds: [],
      processeIds: [],
      assignmentStatuIds: [],
    });
    setstartDate(undefined);
    setEndDate(undefined);
  };

  const ststusChange = (id, newStstus, selectedData) => {
    setFilterss(true);
    var selectedItems = [...selectedItemList];
    var selectedDatas = [...selectedDataList];
    let showValidationPopup = false;

    if (id == "All") {
      selectedItems = [];
      if (newStstus) {
        selectedItems = entities.users.map((c) => c?.id);
        selectedDatas = entities.users.map((c) => c);
      }
      setSelectAll(!selectAll);
      setIsActiveButton(false);
      setIsArchiveButton(false);
      // setIsDeleteButton(true);
      const caaData = selectedDatas.filter(
        (item) => item.status === "Inactive"
      );
      if (caaData?.length > 0) {
        setCantAssignArchived(true);
      } else {
        setCantAssignArchived(false);
      }
    } else {
      let index = -1;
      let indexData = -1;
      index = selectedItems.indexOf(id);
      indexData = selectedDatas.indexOf(selectedData);
      if (index !== -1) {
        selectedItems.splice(index, 1);
        selectedDatas.splice(indexData, 1);
      } else {
        selectedItems.push(id);
        selectedDatas.push(selectedData);
      }
    }
    // setSelectedItemList(newStstus === true ? selectedItems : []);
    // setSelectAll(newStstus === true ? selectedItems.length === entities.users.length : false);
    //remove admin and Inactive user
    const selectedActiveUserIds = [];
    selectedDatas.filter((user) => {
      //  if (user.role === "Employee" && user.status === "Active") {
      if (user.status === "Active") {
        selectedActiveUserIds.push(user.id);
      }
    });
    setSelectedItemList(selectedItems);
    setSelectedDataList(selectedDatas);
    setActiveSelectedItems(selectedActiveUserIds);

    const data = selectedDatas.filter((item) => {
      // if(item.role === "Admin" || item.status ==="Inactive") {
      //   setIsAssignButton(true)
      // } else if (item.role === "Employee" || item.status ==="Active"){
      //   setIsAssignButton(false)
      // }
      // if (item.role === "Employee" && item.status === "Active") {
        if (item.status === "Active") {
        setIsAssignButton(false);
      }
      // if (item.status === "Active") {
      //   setIsActiveButton(true);
      //   const iadata = selectedDatas.filter(
      //     (item) => item.status === "Inactive"
      //   );
      //   if (iadata.length > 0) {
      //     setIsActiveButton(false);
      //   }
      // }
      // else if (item.role === "Admin" && item.status === "Active") {
      //   setIsAssignButton(true);
      //   const data = selectedDatas.filter(
      //     (item) => item.role === "Employee" && item.status === "Active"
      //   );
      //   if (data.length > 0) {
      //     setIsAssignButton(false);
      //   }
      // }
      else if (item.status === "Inactive") {
        setIsAssignButton(true);
        const data = selectedDatas.filter(
          // (item) => item.role === "Employee" && item.status === "Active"
          (item) => item.status === "Active"
        );
        if (data.length > 0) {
          setIsAssignButton(false);
        }
      }

      // console.log(userPlan,'userPlanuserPlan');
    });
    // if(data.length > 0){
    //   setIsAssignButton(true)
    // } else {
    //   setIsAssignButton(false)
    // }
    // const ActiveEmployeeUser = selectedDatas.filter((item)=> item.role === "Employee" || item.status ==="Active")
    // if(ActiveEmployeeUser.length > 0){
    //   setIsAssignButton(false)
    // }

    setIsActiveButton(
      selectedItems?.length === 1 && selectedData?.status === "Active"
    );
    setIsArchiveButton(
      selectedItems?.length === 1 && selectedData?.status === "Inactive"
    );

    const caaData = selectedDatas.filter(
      (item) => item.status === "Inactive"
    );
    if (caaData?.length > 0) {
      setCantAssignArchived(true);
    } else {
      setCantAssignArchived(false);
    }
    // setCantAssignArchived(selectedData?.status === "Inactive");
    setSelectAll(selectedItems.length === entities.users.length);
  };

  const userBulkActResponse = (res, operation) => {
    let successMessage =
      operation === "Disable"
        ? `The selected user accounts have been archived successfully. `
        : operation === "Delete"
        ? `Success! The selected user accounts have been deleted permanently. `
        : operation === "DeletePersonalData"
        ? `All selected users personal data delete successfully! `
        : operation === "Enable"
        ? `The selected user accounts have been Active successfully.`
        : "";
    successMessage += `(${res?.proceedData}/${res?.totalRequest})`;

    let prompt =
      res?.proceedData &&
      res?.totalRequest &&
      res?.proceedData === res?.totalRequest
        ? "Success"
        : "Error";
    // prompt = "Success";
    let message =
      res?.proceedData === res?.totalRequest
        ? successMessage
        : `${operation} Failed (${res?.totalRequest - res?.proceedData}/${
            res?.totalRequest
          })`;
    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      // data: res,
      // records:
      //   res?.failexcutionResponse && res?.failexcutionResponse?.length > 0
      //     ? res?.failexcutionResponse
      //     : [],
      // procesFailedError: res?.procesFailedError,
    });
  };

  const onConfirm = () => {
    // debugger;

    if (selectedItemList !== null && selectedItemList.length > 0) {
      //console.log(requestId, "Disable");
      if ((disableHelpdesk || disableAccount) && requestId === null) {
        dispatch(setBulkDisableAccountAsync(selectedItemList)).then((res) => {
          userBulkActResponse(res.payload, "Disable");
        });
        const hasHelpdeskUser = selectedItemList.some((selectedId) =>
          userDetails.some(
            (user) => user.id === selectedId && user.isHelpdeskNumber === true
          )
        );
        if (hasHelpdeskUser) {
          setDisableHelpdesk(false);
        } else {
          setDisableAccount(false);
        }
        //setDisableAccount(false);
      } else if (enableAccount && requestId === null) {
        dispatch(setBulkEnableAccountAsync(selectedItemList)).then((res) => {
          userBulkActResponse(res.payload, "Enable");
        });
        setEnableAccount(false);
      } else if (deleteModal && requestId === null) {
        dispatch(setBulkdeleteAccountAsync(selectedItemList)).then((res) => {
          userBulkActResponse(res.payload, "Delete");
        });
        setDeleteModal(false);
      } else if (deletePersonalDataModal && requestId === null) {
        dispatch(setBulkdeletePersonalDataAsync(selectedItemList)).then(
          (res) => {
            userBulkActResponse(res.payload, "DeletePersonalData");
          }
        );
        setDeletePersonalDataModal(false);
      }
      setSelectedItemList([]);
      setSelectedDataList([]);
      setActiveSelectedItems([]);
      setRequestId(null);
      setSelectAll(false);
    }

    if (requestId != null) {
      if (disableAccount || disableHelpdesk) {
        dispatch(setDisableAccountAsync(requestId)).then((res) => {
          userBulkActResponse(res.payload, "Disable");
          getUsers();
        });
        //setDisableAccount(false);
        const hasHelpdeskUser = selectedItemList.some((selectedId) =>
          userDetails.some(
            (user) => user.id === selectedId && user.isHelpdeskNumber === true
          )
        );
        if (hasHelpdeskUser) {
          setDisableHelpdesk(false);
        } else {
          setDisableAccount(false);
        }
      } else if (enableAccount) {
        dispatch(setEnableAccountAsync(requestId)).then((res) => {
          userBulkActResponse(res.payload, "Enable");
          getUsers();
        });
        setEnableAccount(false);
      } else if (deleteModal) {
        dispatch(setdeleteAccountAsync(requestId)).then((res) => {
          userBulkActResponse(res.payload, "Delete");
          getUsers();
        });
        setDeleteModal(false);
      } else if (deletePersonalDataModal) {
        dispatch(setdeletePersonalDataAsync(requestId)).then((res) => {
          userBulkActResponse(res.payload, "DeletePersonalData");
          getUsers();
        });
        setDeletePersonalDataModal(false);
      }
      setRequestId(null);
    }
  };

  const onAssignModelClose = (isOpen) => {
    setAssignConentee(isOpen);
    setSelectAll(false);
    setSelectedItemList([]);
    setSelectedDataList([]);
    setActiveSelectedItems([]);
  };

  useEffect(() => {
    if (searchString) {
      setFilterss(true);
      setCurrentPage(1);
    }
  }, [searchString]);

  const debouncedUsers = useDebounce(getUsers, 1000);

  useEffect(() => {
    // console.log(userUsage, "userUsage");
    // console.log(storageData, "storageData");
    // console.log(reloadUsers, 'reloadUsers');
    // console.log(reloadUser, 'reloadUser');
    // if (Users.length === 0) {
      // getUsers();
      debouncedUsers();
    // }

    setIsRestricted(userUsage?.usersCount >= storageData?.seatQuantity);
    if (teamList.length === 0) {
      dispatch(getTeamsAsync());
    }
    if (statusList.length === 0) {
      dispatch(getUserStatusListAsync());
    }
    if (sequenceList.length === 0) {
      dispatch(getSequenceListAsync());
    }
    if (moduleList.length === 0) {
      dispatch(getModuleDropdownListAsync());
    }
    if (roleList.length === 0) {
      dispatch(getRolesAsync({ name: "" }));
    }
    if (assignmentList.length === 0) {
      dispatch(getAssignmentStatusListAsync());
    }
    if (processesList.length === 0) {
      dispatch(getprocessesDropdownListAsync());
    }
  }, [
    currentPage,
    pageSize,
    sortColumn,
    searchString,
    selectedStatus,
    filters,
    startDate,
    endDate,
    addUserEmployee,
    userUsage,
    // storageData,
    reloadUser,
  ]);

  useEffect(() => {
    // console.log(subscriptionData,'subscriptionData');
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      if (basePlan && basePlan?.length > 0) {
        setUserPlan(basePlan[0]?.planName);
        setIsBulkAssign(
          basePlan[0]?.metaData?.bulk_assign_content === "Yes" ? true : false
        );
        setIsBulkUpdate(
          basePlan[0]?.metaData?.bulk_update_status === "Yes" ? true : false
        );
      }
    }
  }, [subscriptionData, plansData]);

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
    getUsers();
  };
  // if (formDataReady === false) {
  //   return <Loader />;
  // }
  const onUserDetailsShow = (userId) => {
    setUserDetailsShow(true);
    setUserId(userId);
    history.push("/users", { userIndex: false });
  };

  const onUserFormDocumentShow = (userId, userName, type) => {
    setFormDocumentModalShow(true);
    setDocumentUserId(userId);
    setDocumentUserName(userName);
    setDocumentType(type);
  };

  useEffect(() => {
    if (userIndex === true) {
      setUserDetailsShow(false);
      setUserId(null);
      getUsers();
      history.push("/users", { userIndex: true });
    }
  }, [userIndex]);

  const handleCloseUserModal = () => {
    setAddUserEmployee(false);
    setAssignConentee(false);
  };

  const onBulkAssignConfirmation = () => {
    // console.log(selectedDataList)
    const activeEmployeeUsers = selectedDataList.filter(
      (item) => item.status == "Inactive" || item.role == "Admin"
    );
    if (activeEmployeeUsers.length > 0) {
      setAssignModalAlert(true);
    } else {
      setAssignConentee(true);
    }
  };

  const onResponseClose = () => {
    setAssignModalAlert(false);
  };

  const calculateUserCounts = () => {
    // const allCount = allUserDetail.length;
    // const adminCount = allUserDetail.filter(item => item.role === 'Admin').length;
    // const employeeCount = allUserDetail.filter(item => item.role === 'Employee').length;

    const allCount = tabCount?.allUser ? tabCount?.allUser : 0;
    const adminCount = tabCount?.admins ? tabCount?.admins : 0;
    const employeeCount = allCount - adminCount;

    return { All: allCount, Admin: adminCount, Employee: employeeCount };
  };

  const handleTabClick = (tabName) => {
    // console.log(tabName,'tabName');
    setActiveTab(tabName);
    setCurrentPage(1);
    setSelectAll(false); 
    setSelectedItemList([]);
    // setSelectedDataList([]);
    if (tabName === "All") {
      setUserDetails(allUserDetail);
    } else if (tabName === "Admin") {
      setUserDetails(allUserDetail.filter((item) => item.role === "Admin"));
    } else if (tabName === "Employee") {
      setUserDetails(allUserDetail.filter((item) => item.role === "Employee"));
    }
  };
  //  console.log(userDetails,"userDetails")
  //  console.log(selectedItemList,"selectedItemList")
  // console.log(currentPage,"currentPage")
  console.log(isActiveButton, "isActiveButton");
  console.log(userDetails, "userDetails");
  console.log(entities, "entities");

  return (
    <>
      {userDetailsShow ? (
        <UserDetail
          id={userId}
          setUserDetailsShow={setUserDetailsShow}
          getUserList={getUsers}
        />
      ) : (
        <div className="pages">
          <PageHeader
            title="Users"
            searchString={searchString}
            onBlur={setSearchString}
            placeHolder="Search by name and email address"
          />
          <div className="pageOuter">
            <Container fluid>
              <ul className="authoringNav">
                <li className="me-4">
                  {/* <NavLink exact className='h6 text-gray-100 active' to="/"> All (56)</NavLink> */}
                  <Button
                    variant=""
                    className={`border-0 h6 text-gray-100 pt-0 ${
                      activeTab === "All" ? "active" : ""
                    }`}
                    // aria-current="page"
                    // class={`h6 text-gray-100 ${activeTab === "All" ? "active" : ""
                    //   }`}
                    onClick={() => handleTabClick("All")}
                    // href="#"
                  >
                    All ({userCount ? userCount : 0})
                  </Button>
                </li>
                <li className="me-4">
                  {/* <NavLink className='h6 text-gray-100' to="//"> Admin(14)</NavLink> */}
                  <Button
                    variant=""
                    className={`border-0 h6 text-gray-100 pt-0 ${
                      activeTab === "Admin" ? "active" : ""
                    }`}
                    // aria-current="page"
                    // class={`h6 text-gray-100 ${activeTab === "Admin" ? "active" : ""
                    //   }`}
                    onClick={() => handleTabClick("Admin")}
                    // href="#"
                  >
                    Admin ({calculateUserCounts().Admin || 0})
                  </Button>
                </li>
                <li className="me-4">
                  {/* <NavLink className='h6 text-gray-100' to="//"> Employee(42)</NavLink> */}
                  <Button
                    variant=""
                    className={`border-0 h6 text-gray-100 pt-0 ${
                      activeTab === "Employee" ? "active" : ""
                    }`}
                    // aria-current="page"
                    // class={`h6 text-gray-100 ${activeTab === "Employee" ? "active" : ""
                    //   }`}
                    onClick={() => handleTabClick("Employee")}
                    // href="#"
                  >
                    Employee ({calculateUserCounts().Employee || 0})
                  </Button>
                </li>
              </ul>
              {formDataReady === false && <Loader />}
              <div className="page d-flex">
                <Filters
                  onChange={onFilterChange}
                  // statusList={statusList}
                  onClearAll={onClearAll}
                  //  type="Users"
                  filters={filtersRecord}
                  dateRange={{
                    lable: "Joining Date",
                    start: startDate,
                    end: endDate,
                    onDateChange: { onDateRangeChange: onDateRangeChange },
                  }}
                />
                <div className="filtersContent">
                  <div className="p-32 py-3 p20">
                    <div className="detailModulePage d-flex justify-content-between align-items-center">
                      <div className="d-flex feedbackData users align-items-center">
                        <div className="dataItem userAllToatal d-flex align-items-center">
                          <Pagination
                            // totalItems={entities?.allRecordsCount}
                            totalItems={
                              activeTab === "All"
                                ? calculateUserCounts().All
                                : activeTab === "Admin"
                                ? calculateUserCounts().Admin
                                : activeTab === "Employee"
                                ? calculateUserCounts().Employee
                                : 0
                            }
                            itemsPerPage={pageSize}
                            onPageChange={onPageChange}
                            setFilterss={setFilterss}
                            filterss={filterss}
                            pageTitle={"Users"}
                          />
                          {/* <p className="h6 text-gray-200">Processes <span className="text-gray-100">1 to 50</span></p>
                          <div className="btnArrow ms-2">
                          <Button variant="white">
                            <IconleftArrow/>
                          </Button>
                          <Button variant="white" className="ms-3">
                            <IconrightArrow/>
                          </Button>
                          </div> */}
                          {/* <p className="h6 mb-3 text-gray-300">Users</p> */}
                          {/* <div className="d-flex">
                            {entities?.userCounts?.imgCount === 1 ? (
                              <div className="headImg moduleImg">
                                {/* <img src={OwnerImg} alt="" /> }
                                {entities?.users[0].img ? (
                                  <UserImage imgKey={entities?.users[0].img} />
                                ) : (
                                  <img
                                    src={getInitialsInName(
                                      entities?.users[0].name ?? "N A",
                                      24
                                    )}
                                    alt={entities?.users[0].name}
                                  />
                                )}
                              </div>
                            ) : entities?.userCounts?.imgCount === 2 ? (
                              <>
                                <div className="headImg moduleImg">
                                  {/* <img src={OwnerImg} alt="" /> }
                                  {entities?.users[0].img ? (
                                    <UserImage
                                      imgKey={entities?.users[0].img}
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        entities?.users[0].name ?? "N A",
                                        24
                                      )}
                                      alt={entities?.users[0].name}
                                    />
                                  )}
                                </div>
                                <div className="headImg moduleImg">
                                  {/* <img src={OwnerImg} alt="" /> }
                                  {entities?.users[1].img ? (
                                    <UserImage
                                      imgKey={entities?.users[1].img}
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        entities?.users[1].name ?? "N A",
                                        24
                                      )}
                                      alt={entities?.users[1].name}
                                    />
                                  )}
                                </div>
                              </>
                            ) : entities?.userCounts?.imgCount > 2 ? (
                              <>
                                <div className="headImg moduleImg">
                                  {/* <img src={OwnerImg} alt="" /> }
                                  {entities?.users[0].img ? (
                                    <UserImage
                                      imgKey={entities?.users[0].img}
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        entities?.users[0].name ?? "N A",
                                        24
                                      )}
                                      alt={entities?.users[0].name}
                                    />
                                  )}
                                </div>
                                <div className="headImg moduleImg">
                                  {/* <img src={OwnerImg} alt="" /> }
                                  {entities?.users[1].img ? (
                                    <UserImage
                                      imgKey={entities?.users[1].img}
                                    />
                                  ) : (
                                    <img
                                      src={getInitialsInName(
                                        entities?.users[1].name ?? "N A",
                                        24
                                      )}
                                      alt={entities?.users[1].name}
                                    />
                                  )}
                                </div>
                              </>
                            ) : null}
                            {entities?.userCounts?.imgCount ? (
                              <div className="headImg moduleImg imgCount">
                                <div className="text-xs total">
                                  {entities?.userCounts?.imgCount > 2
                                    ? `${"+"} ${
                                        entities.userCounts?.imgCount - 2
                                      }`
                                    : +entities.userCounts?.imgCount}
                                </div>
                              </div>
                            ) : (
                              <div className="headImg moduleImg imgCount">
                                <div className="text-xs total">0</div>
                              </div>
                            )}
                          </div> */}
                        </div>

                        {/* <div className="dataItem d-flex">
                          <div className="me-4">
                            <p className="h6 mb-3 text-gray-300 text-nowrap">
                              {/* Unarchived  }Active
                            </p>

                            <h3 className="font-medium">
                              {entities?.userCounts?.activeUsers}
                            </h3>
                          </div>

                          <div className="me-4">
                            <p className="h6 mb-3 text-gray-300">Admin</p>

                            <h3 className="font-medium">
                              {entities?.userCounts?.admins}
                            </h3>
                          </div>

                          <div className="me-4">
                            <p className="h6 mb-3 text-gray-300">
                            {/* Inactive }Archived
                            </p>

                            <h3 className="font-medium text-primary-100">
                              {entities?.userCounts?.inActive}
                            </h3>
                          </div>
                        </div> */}
                        {/* <div className="d-flex justify-content-between userSearchin">
                      <Search
                        onBlur={setSearchString}
                        placeHolder="Search by Employee name & Id"
                      />

                       <div className="d-flex gap-2 align-items-center">
                        <div>
                          {" "}
                          <IconPlanUpgrade2 />
                        </div>
                        <Link
                          to="/accountsetting/billingsubscription/change"
                          className="text-gray-100 h6"
                        >
                          <span className="text-underline text-secondary-300 font-medium">
                            Upgrade your plan
                          </span>{" "}
                          now to add more users.
                        </Link>
                      </div> 
                    </div> */}
                      </div>

                      <div className="d-flex gap-3 align-items-center">
                        <Button variant="" className="border-0 py-0 px-0">
                          <div className="d-flex gap-3 align-items-center">
                            {selectedItemList &&
                              selectedItemList.length > 0 && (
                                <>
                                  <Button
                                    className="p-0 border-0"
                                    onClick={() => {
                                      !isBulkAssign &&
                                      selectedItemList.length > 1
                                        ? setIsRestrictedModalPopup(true)
                                        : onBulkAssignConfirmation();
                                    }}
                                    variant=""
                                    size="sm"
                                    disabled={
                                      selectedItemList.length < 1 ||
                                      isAssignButton
                                    }
                                  >
                                    <div className="d-flex gap-2 align-items-center">
                                      {/* <IconDisableuser /> */}
                                      <p className="h6 text-primary-200 font-bold">
                                        Assign Content
                                        {/* Disable{" "} */}
                                      </p>
                                    </div>
                                  </Button>
                                  <Button
                                    variant=""
                                    className="border-0 py-0 p-0"
                                    disabled={isActiveButton}
                                    onClick={() => {
                                      !isBulkUpdate &&
                                      selectedItemList.length > 1
                                        ? setIsRestrictedModalPopup(true)
                                        : setEnableAccount(true);
                                    }}
                                  >
                                    <div className="d-flex gap-2 align-items-center">
                                      {/* <IconEnableuser /> */}
                                      <p className="h6 text-primary-200 font-bold">
                                        {selectedItemList.length > 1 ? "Activate" : "Active"}
                                        {/* Active */}
                                        {/* Unarchive */}
                                        {/* Enable{" "} */}
                                      </p>
                                    </div>
                                  </Button>
                                  <Button
                                    variant=""
                                    className="border-0 py-0 p-0"
                                    disabled={isArchiveButton}
                                    onClick={() => {
                                      const hasHelpdeskUser =
                                        selectedItemList.some((selectedId) =>
                                          userDetails.some(
                                            (user) =>
                                              user.id === selectedId &&
                                              user.isHelpdeskNumber === true
                                          )
                                        );
                                      if (
                                        !isBulkUpdate &&
                                        selectedItemList.length > 1
                                      ) {
                                        setIsRestrictedModalPopup(true);
                                      } else if (hasHelpdeskUser) {
                                        setDisableHelpdesk(true);
                                      } else {
                                        setDisableAccount(true);
                                      }
                                    }}
                                  >
                                    <div className="d-flex gap-2 align-items-center">
                                      {/* <IconDisableuser /> */}
                                      <p className="h6 text-primary-100 font-bold">
                                        Archive
                                        {/* Disable{" "} */}
                                      </p>
                                    </div>
                                  </Button>
                                 
                                  <Button
                                    variant=""
                                    className="border-0 py-0 p-0"
                                    onClick={() => {
                                      !isBulkUpdate &&
                                      selectedItemList.length > 1
                                        ? setIsRestrictedModalPopup(true)
                                        : setDeleteModal(true);
                                    }}
                                    disabled={isDeleteButton}
                                  >
                                    <div className="d-flex gap-2 align-items-center">
                                      {/* <IconDelete /> */}
                                      <p className="h6 text-primary-100 font-bold">
                                        Delete
                                      </p>
                                    </div>
                                  </Button>

                                  {/* <Button
                                variant=""
                                className="border-0 py-0"
                                onClick={() => setDeletePersonalDataModal(true)}
                                disabled={true}
                              >
                                <div className="d-flex gap-2 align-items-center">
                                  <IconArchive />
                                  <p className="h6 text-gray-300">
                                    Personal Data Delete Request
                                  </p>
                                </div>
                              </Button> */}
                                </>
                              )}
                            {/* <IconBulkActions />
                          <p className="h6 text-gray-300">Bulk Actions</p> */}
                          </div>
                        </Button>
                        {/* {selectedItemList && selectedItemList.length > 0 && (
                          <>
                            <div className="pipeSeparator pe-4 ps-2">&nbsp;</div>
                            { <button className='button button--mimas'>
                                                <span>Add a new User</span>
                                            </button> }{" "}
                          </>
                        )} */}
                        <Button
                          onClick={() => {
                            teamList.length === 0
                              ? setAccountSettingsModalAlert(true)
                              : setAddUserEmployee(true);
                          }}
                          variant="secondary"
                          size="sm"
                          className=""
                        >
                          Add User
                        </Button>
                      </div>
                    </div>
                  </div>

                  <div className="tableComponent prefTable flex-grow-1 overflow-auto">
                    <table className="table">
                      <thead>
                        <tr>
                          <th>
                            <CheckBox
                              text=""
                              id={"All"}
                              ststusChange={ststusChange}
                              status={selectAll}
                            />
                          </th>
                          <th className="width:9rem">NAME</th>
                          <th>TEAM</th>
                          <th>EMAIL ADDRESS</th>
                          <th>PHONE</th>
                          <th>STATUS</th>
                          <th className="thProgress">ASSIGNMENTS</th>
                          <th>FORMS SUBMITTED</th>
                          <th>DOCUMENTS</th>
                          <th>&nbsp;</th>
                        </tr>
                      </thead>

                      {formDataReady === true && entities?.users && (
                        <tbody>
                          {userDetails.map((user, index) => (
                            <tr className={user.isChecked ? "checkedRow" : ""}>
                              <td>
                                <CheckBox
                                  status={selectedItemList.includes(user.id)}
                                  ststusChange={ststusChange}
                                  selectedData={user}
                                  id={user.id}
                                />
                              </td>
                              <td>
                                <Link
                                  onClick={() => {
                                    onUserDetailsShow(user.id);
                                  }}
                                  // to={{
                                  //   pathname: `users/${user.id}`,
                                  //   state: { user },
                                  //   // `/authoring/modules/?id=${c.id}`
                                  // }}
                                  className="d-flex align-items-center gap-2"
                                >
                                  <div className="text-gray-100 d-flex align-items-center gap-2">
                                    {user.img ? (
                                      <div className="tableImg" key={index}>
                                        {/* <img src={user.img} alt={user.name} /> */}
                                        <UserImage
                                          key={index}
                                          imgKey={user.img}
                                        />
                                      </div>
                                    ) : (
                                      <div>
                                        <div className="tableImg" key={index}>
                                          <img
                                            src={getInitialsInName(
                                              user.name ?? "N A",
                                              26
                                            )}
                                            alt={user.name}
                                          />
                                        </div>
                                        {/* <div class="tableName text-primary-100 bg-pastel-100">
                                        {getInitialsInName(user.name)}
                                      </div> */}
                                      </div>
                                    )}
                                    <div>
                                      <p className="text-nowrap mb-0 max9rem singleLineEc">
                                        {user.name}
                                      </p>
                                      {/* <p className="text-gray-300 text-md mb-0">
                                        {user.role}
                                      </p> */}
                                    </div>
                                  </div>
                                </Link>
                              </td>
                              <td>
                                <div className="singleLineEc max9rem">
                                  {user.team == "" ? "NA" : user.team}
                                </div>
                              </td>
                              <td>
                                <div className="blockdiv">
                                  {user.companyEmail && (
                                    <a
                                      href={"mailto:" + user.companyEmail}
                                      className="text-primary-200 text-truncate"
                                    >
                                      {user.companyEmail}
                                    </a>
                                  )}
                                </div>
                              </td>
                              <td>
                                <div className="blockdiv">
                                  {user.phoneNumber && (
                                    <a
                                      href={"tel:" + user.phoneNumber}
                                      className="text-primary-200"
                                    >
                                      {user.phoneNumber}
                                    </a>
                                  )}
                                </div>
                              </td>
                              <td>
                                <span
                                  class={`${"tableTag text-md"}  ${
                                    user.status === "Inactive" ? "orange" : null
                                  }`}
                                >
                                  {!user.status || user.status == ""
                                    ? "NA"
                                    : user.status === "Active"
                                    ? "Active"
                                    : user.status === "Inactive"
                                    ? "Archived"
                                    : "NA"}
                                </span>
                              </td>
                              <td>
                                <Progress
                                  key={index}
                                  assignMents={user.assignmentCount}
                                />
                              </td>
                              <td>
                                <span className="text-gray-300">
                                  {user?.totalFormCompleted}
                                </span>
                                /
                                <span className="font-medium">
                                  {user?.totalFormCount}
                                </span>{" "}
                                <Button
                                  className="fSubmitMod"
                                  variant="white"
                                  size="sm"
                                  onClick={() => {
                                    onUserFormDocumentShow(
                                      user.id,
                                      user.name,
                                      "FORMS"
                                    );
                                  }}
                                >
                                  <IconSeqarrow />
                                </Button>
                              </td>
                              <td>
                                {/* <b>{user?.totalDocumentCount}</b>{" "} */}
                                <b>{user?.totalFileUploadedCount}</b>{" "}                               
                                <Button
                                  className="fSubmitMod"
                                  variant="white"
                                  size="sm"
                                  onClick={() => {
                                    onUserFormDocumentShow(
                                      user.id,
                                      user.name,
                                      "DOCUMENTS"
                                    );
                                  }}
                                >
                                  <IconSeqarrow />
                                </Button>
                              </td>
                              <td>
                                <Dropdown className="tableDropdown">
                                  <Dropdown.Toggle>
                                    <IconDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu className="py-0">
                                    {user.status === "Active" ? (
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          setRequestId(user?.id);
                                          // setDisableAccount(true);
                                          if (
                                            user.id === selectedItemList &&
                                            user.isHelpdeskNumber === true
                                          ) {
                                            setDisableHelpdesk(true);
                                          } else {
                                            setDisableAccount(true);
                                          }
                                        }}
                                      >
                                        {/* Disable Login */}Archive Login
                                      </button>
                                    ) : (
                                      <button
                                        className="dropdown-item"
                                        onClick={() => {
                                          setRequestId(user?.id);
                                          setEnableAccount(true);
                                        }}
                                      >
                                        {/* Enable Login */}Active Login
                                      </button>
                                    )}

                                    <button
                                      className="dropdown-item text-primary-100"
                                      onClick={() => {
                                        setRequestId(user.id);
                                        setDeleteModal(true);
                                      }}
                                    >
                                      Delete Account
                                    </button>
                                    {/* <button
                                    className="dropdown-item"
                                    onClick={() =>
                                      setDeletePersonalDataModal(true)
                                    }
                                  >
                                    Request Personal Data Delete
                                  </button> */}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      )}
                    </table>
                    {!entities?.users?.length > 0 ||
                    !userDetails?.length > 0 ? ( //  && formDataReady === true
                      <EmptyState message={"No users to show."} />
                    ) : null}
                  </div>
                  {/* <div className="pageActions paginationMax">
                    <Row className="mx-0">
                      <Pagination
                        totalItems={entities?.allRecordsCount}
                        itemsPerPage={pageSize}
                        onPageChange={onPageChange}
                        setFilterss={setFilterss}
                        filterss={filterss}
                      />
                    </Row>
                  </div> */}
                </div>
              </div>
            </Container>
          </div>
        </div>
      )}

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={addUserEmployee}
        // onHide={() => setAddUserEmployee(false)}
      >
        <AddUserEmployeeModal
          getUsers={getUsers}
          handleModal={setAddUserEmployee}
          onClose={handleCloseUserModal}
          reloadUser={setReloadUser}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={addUser}
        // onHide={() => setAddUser(false)}
      >
        <AddUserModal Modal handleModal={setAddUser} />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={disableAccount}
        // onHide={() => setDisableAccount(false)}
      >
        <ModalDelete
          onCancel={setDisableAccount}
          onConfirm={onConfirm}
          headerLable="Are you sure you want to archive this account?"
          chldLable="The data will remain intact and you can Active the account anytime."
        />
      </Modal>
      <Modal className="modalDialog" size="sm" centered show={disableHelpdesk}>
        <ModalDelete
          onCancel={setDisableHelpdesk}
          onConfirm={onConfirm}
          headerLable="One or more of the selected users have helpdesk member role assigned."
          chldLable="Archiving the users will result in helpdesk role disposal for the selected user(s). Are you sure you want to archive the selected user(s)?"
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={enableAccount}
        // onHide={() => setEnableAccount(false)}
      >
        <ModalDelete
          onCancel={setEnableAccount}
          onConfirm={onConfirm}
          headerLable={
            isRestricted
              ? "Action can not be performed since user limit has reached!"
              : "Are you sure you want to Active this account? "
          }
          chldLable={
            isRestricted
              ? "Kindly purchase add on seat or upgrade your plan in order to continue with this action. "
              : "Remember, you can archive the account anytime if needed."
          }
          requestType={isRestricted ? "NotDelete" : ""}
          isRestricted={isRestricted}
        />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal}
        // onHide={() => setDeleteModal(false)}
      >
        {/* <DeleteAccountModal handleModal={setDeleteModal} /> */}
        <ModalDelete
          onCancel={setDeleteModal}
          onConfirm={onConfirm}
          headerLable="Are you sure you want to delete the selected accounts?"
          chldLable="Please note, the data of selected accounts will be permanently deleted."
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deletePersonalDataModal}
        // onHide={() => setDeletePersonalDataModal(false)}
      >
        <ModalDelete
          onCancel={setDeleteModal}
          onConfirm={onConfirm}
          headerLable="Request personal data delete"
          chldLable=" Content to be added"
        />
        {/* <DeletePersonalDataModal handleModal={setDeletePersonalDataModal} /> */}
      </Modal>
      <Modal
        className="come-from-modal right addEmployee assignContent"
        centered
        show={assignConentee}
        // onHide={() => setAssignConentee(false)}
      >
        <AssignConenteeModal
          openAssignConenteeModal={onAssignModelClose}
          selectedUsers={activeSelectedItems}
          type="bulk"
          getUserList={getUsers}
          onClose={handleCloseUserModal}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={responsePopup?.show}
      >
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={assignModalAlert}>
        <Modal.Body className="pt-4 pb-4">
          <h3 className="mt-3">
            You can assign the content to Active users only.
          </h3>
          {cantAssignArchived && (
            <p className="text-gray-300 mb-0 mt-2 mb-5">
              You can not assign the content to archived users.
            </p>
          )}
          <p className="text-gray-300 mb-0 mt-2">
            Click Yes, in order to proceed.
          </p>
        </Modal.Body>
        <Modal.Footer className="justify-content-end btnFooter gap-2">
          <Button
            variant="white"
            size="sm"
            onClick={() => onResponseClose(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
            size="sm"
            onClick={() => {
              setAssignConentee(true);
              onResponseClose(false);
            }}
          >
            Yes
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={formDocumentModalShow}
        onHide={() => setFormDocumentModalShow(false)}
      >
        <FormDocumentModal
          Modal
          handleModal={setFormDocumentModalShow}
          documentUserId={documentUserId}
          documentUserName={documentUserName}
          documentType={documentType}
        />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isRestrictedModalPopup}
      >
        <RestrictedUpgradeToBusinessModalPopup
          onCancel={setIsRestrictedModalPopup}
          headerLable={""}
          chldLable={""}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={accountSettingsModalAlert}
      >
        <Modal.Body className="pt-40 pb-4 d-flex justify-content-center">
          <div class="text-center">
            <div>
              <img src={accountSettingsPopupUserImg} alt="" />
            </div>
            <div className="mt-32">
              <h3 className="text-gray-100">
                Hey Wait! You dont have any teams to add a user{" "}
              </h3>
              <p className="text-gray-300 mt-1 mb-3">
                Create a team first, in Account Settings &gt; Manage Team and{" "}
                <br />
                then start adding the users here.
              </p>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-end btnFooter gap-2 py-3">
          <Link to="/accountsetting/manageteams">
            <Button className="my-1" variant="primary" size="sm">
              Take me to Account settings
            </Button>
          </Link>
        </Modal.Footer>
      </Modal>

      {/* <FormModal /> */}
    </>
  );
};

export default Users;
