import axios from "axios";
import { Constants } from "./services/Constants";
import AuthService from "./services/AuthService";

export const http = axios.create({
  // baseURL: Constants.employeeApiRoot,
  baseURL: Constants.employeeApiRoot,
  headers: {
    Authorization: `Bearer ${new AuthService().getToken()}`,
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

export const httpAuth = axios.create({
  // baseURL: Constants.employeeApiRoot,
  baseURL: Constants.authApiRoot,
  headers: {
    Authorization: `Bearer ${new AuthService().getToken()}`,
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

export const httpIdentityApiRoot = axios.create({
  baseURL: Constants.identityApiRoot,
  headers: {
    Authorization: `Bearer ${new AuthService().getToken()}`,
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

export const httpBlobServiceApiRoot = axios.create({
  baseURL: Constants.blobServiceApiRoot,
  headers: {
    Authorization: `Bearer ${new AuthService().getToken()}`,
    accept: "*/*",
    "Content-Type": "multipart/form-data",
  },
});

export const httpBlobGetServiceApiRoot = axios.create({
  baseURL: Constants.blobServiceApiRoot,
  headers: {
    Authorization: `Bearer ${new AuthService().getToken()}`,
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

export const httpChargebeeApiRoot = axios.create({
  baseURL: Constants.chargebeeApiRoot,
  headers: {
    Authorization: `Bearer ${new AuthService().getToken()}`,
    accept: "*/*",
    "Content-Type": "application/json",
  },
});

export const httpStripeApiRoot = axios.create({
  baseURL: Constants.stripeApiRoot,
  headers: {
    Authorization: `Bearer ${new AuthService().getToken()}`,
    accept: "*/*",
    "Content-Type": "application/json",
  },
});