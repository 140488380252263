import React from "react";

const RadioButton = ({ text, name, checked, onChange, disabled }) => {
  return (
    <label className="radioButton">
      <input
        type="radio"
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <span className="radioCheck"></span>
      {text && <span className="">{text}</span>}
    </label>
  );
};

export default RadioButton;
