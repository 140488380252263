import React from 'react';
import { Container } from "react-bootstrap";
import { Link } from "react-router-dom";
const TrialHeader = () => {
    return (
        <div className='trialHeader'>
            <Container fluid>
                <div className='text-gray-200 d-flex align-items-center gap-3'>
                    <div>
                        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <rect width="40" height="40" rx="8" fill="white" />
                            <path d="M18.49 10.2294L13.5 12.1094C12.35 12.5393 11.41 13.8993 11.41 15.1192V22.5489C11.41 23.7288 12.19 25.2788 13.14 25.9887L17.44 29.1986C18.85 30.2585 21.17 30.2585 22.58 29.1986L26.88 25.9887C27.83 25.2788 28.61 23.7288 28.61 22.5489V15.1192C28.61 13.8893 27.67 12.5293 26.52 12.0994L21.53 10.2294C20.68 9.91945 19.32 9.91945 18.49 10.2294Z" stroke="#F15845" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                            <path d="M17.0499 19.8685L18.6599 21.4784L22.9599 17.1786" stroke="#F15845" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                        </svg>
                    </div>
                    <div>
                        Hey! Your trial version expires on 10th August 2022. <Link to="/accountsetting/billingsubscription/change" className='font-bold text-primary-200'>Upgrade now to unlock all features.</Link>
                    </div>
                </div>
            </Container>
        </div>
    );
}

export default TrialHeader;
