import React, { useState } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Search from "../../../components/Search/Search";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import ModuleCreatedModal from "./ModuleCreatedModal";
import TrialHeader from "../../StartMyTrial/TrialHeader";
const FtueDashboard = () => {
  const [moduleCreated, setModuleCreated] = useState(true);
  return (
    <>
      <div className="pages">
        <div className="position-relative z-index-1051">
          <TrialHeader />
        </div>
        <header className="pageHeader">
          <Container fluid>
            <Row className="mx-0 align-items-center">
              <Col className="px-0">
                <h3>Dashboard</h3>
                <div className="h6 mt-2">
                  👋 Welcome to your Dashboard, Harjeet!
                </div>
              </Col>
              <Col className="px-0 text-end ">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <AccountDropdown />
                  <ActivityLog />
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="pageOuter">
          <div className="d-flex align-items-center justify-content-center flex-grow-1">
            <div className="text-center">
              <div className="row g-0">
                <div className="col-lg-6 m-auto">
                  <h1 className="mb-12">Welcome HarryHR!</h1>
                  <p className="mb-40 text-gray-300">
                    Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                    Aenean commodo ligula eget dolor. Aenean massa. Cum sociis
                    natoque penatibus et magnis dis parturient montes, nascetur
                    ridiculus mus.
                  </p>
                  <p className="font-medium mb-4">
                    Let’s start authoring content
                  </p>
                </div>
                <div className="col-lg-7 m-auto">
                  <div className="row">
                    <div className="col-lg-6">
                      <div className="bg-base-100 rounded-6 boxShadow p-32  d-flex flex-column authorContent">
                        <div className="mb-32 flex-grow-1 h-100">
                          <svg
                            width="120"
                            height="129"
                            viewBox="0 0 120 129"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M119.498 125.441H23.651V7.30973C23.651 3.5039 26.7056 0.449219 30.5115 0.449219H112.637C116.443 0.449219 119.498 3.5039 119.498 7.30973V125.441Z"
                              fill="white"
                            />
                            <path
                              d="M113.238 0.449219H29.9105C26.4553 0.449219 23.651 3.25351 23.651 6.70881V13.2689H119.498V6.70881C119.498 3.25351 116.693 0.449219 113.238 0.449219Z"
                              fill="#F3D1CB"
                            />
                            <path
                              d="M35.1157 7.61152C35.1157 8.46283 34.4146 9.11382 33.6134 9.11382C32.8122 9.11382 32.1111 8.41275 32.1111 7.61152C32.1111 6.76022 32.8122 6.10922 33.6134 6.10922C34.4146 6.10922 35.1157 6.76022 35.1157 7.61152Z"
                              fill="white"
                            />
                            <path
                              d="M41.18 7.61152C41.18 8.46283 40.479 9.11382 39.6777 9.11382C38.8264 9.11382 38.1754 8.41275 38.1754 7.61152C38.1754 6.76022 38.8765 6.10922 39.6777 6.10922C40.5291 6.10922 41.18 6.76022 41.18 7.61152Z"
                              fill="white"
                            />
                            <path
                              d="M47.2893 7.61152C47.2893 8.46283 46.5882 9.11382 45.787 9.11382C44.9357 9.11382 44.2847 8.41275 44.2847 7.61152C44.2847 6.76022 44.9858 6.10922 45.787 6.10922C46.6383 6.10922 47.2893 6.76022 47.2893 7.61152Z"
                              fill="white"
                            />
                            <path
                              d="M96.4657 71.1099H45.1371C38.1764 71.1099 32.5678 65.4513 32.5678 58.5407V34.3036C32.5678 27.3429 38.2265 21.7343 45.1371 21.7343H96.4657C103.426 21.7343 109.035 27.393 109.035 34.3036V58.5407C109.035 65.5014 103.426 71.1099 96.4657 71.1099Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M99.6188 13.9206L23.6525 13.6702C23.4021 13.6702 23.2018 13.4699 23.2018 13.2195C23.2018 12.9691 23.4021 12.7688 23.6525 12.7688L99.6188 13.0192C99.8692 13.0192 100.07 13.2195 100.07 13.4699C100.07 13.7203 99.8692 13.9206 99.6188 13.9206Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M119.499 125.893C119.249 125.893 119.049 125.693 119.049 125.442V6.86051C119.049 3.60552 116.394 0.901382 113.089 0.901382H30.0622C26.8073 0.901382 24.1031 3.55544 24.1031 6.86051V125.442C24.1031 125.693 23.9028 125.893 23.6524 125.893C23.402 125.893 23.2017 125.693 23.2017 125.442V6.86051C23.2017 3.05468 26.3065 0 30.0622 0H113.14C116.945 0 120 3.10475 120 6.86051V125.442C119.95 125.693 119.75 125.893 119.499 125.893Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M92.6083 27.5427H38.3252V21.7839C38.3252 18.6792 40.829 16.1753 43.9338 16.1753H92.6584C95.7632 16.1753 98.267 18.6792 98.267 21.7839V21.9341C98.2169 25.0389 95.7131 27.5427 92.6083 27.5427Z"
                              fill="#F3D1CB"
                            />
                            <path
                              d="M94.1599 19.331H43.1317V21.0336H94.1599V19.331Z"
                              fill="white"
                            />
                            <path
                              d="M68.6708 22.9846H43.1317V24.6872H68.6708V22.9846Z"
                              fill="white"
                            />
                            <path
                              d="M97.0746 59.5905H79.7337C77.0725 59.5905 74.8834 57.4014 74.8834 54.7402V37.3994C74.8834 34.7381 77.0725 32.5491 79.7337 32.5491H97.0746C99.7358 32.5491 101.925 34.7381 101.925 37.3994V54.7402C101.925 57.4444 99.7358 59.5905 97.0746 59.5905Z"
                              fill="white"
                            />
                            <path d="M77 47H80V60H77V47Z" fill="#EFEFEF" />
                            <path d="M82 39H85V60H82V39Z" fill="#F3D1CB" />
                            <path d="M87 46H90V60H87V46Z" fill="#EFEFEF" />
                            <path d="M92 56H95V60H92V56Z" fill="#EFEFEF" />
                            <path
                              d="M54.4909 41.3137C54.4909 41.7637 54.8659 42.0637 55.2409 42.0637C55.6909 42.0637 55.9909 41.6887 55.9909 41.3137C55.9909 40.9387 55.6159 40.5637 55.2409 40.5637C54.8659 40.5637 54.4909 40.8637 54.4909 41.3137Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M51.7143 43.2857C52 43.5714 52.5 43.5714 52.7857 43.2857C53.0714 43 53.0714 42.5 52.7857 42.2143C52.5 41.9286 52 41.9286 51.7143 42.2143C51.4286 42.5714 51.4286 43 51.7143 43.2857Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M50.3343 47.0687C50.7814 47.0687 51.0795 46.6937 51.0795 46.3187C51.0795 45.8687 50.7069 45.5687 50.3343 45.5687C49.8872 45.5687 49.5891 45.9437 49.5891 46.3187C49.5145 46.6937 49.8872 47.0687 50.3343 47.0687Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M52.3177 50.3096C52.6034 50.0239 52.6034 49.5239 52.3177 49.2382C52.032 48.9524 51.532 48.9524 51.2463 49.2382C50.9605 49.5239 50.9605 50.0239 51.2463 50.3096C51.6034 50.5953 52.032 50.5953 52.3177 50.3096Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M56.0433 51.2263C56.0433 50.7763 55.6683 50.4763 55.2933 50.4763C54.8433 50.4763 54.5433 50.8513 54.5433 51.2263C54.5433 51.6763 54.9183 51.9763 55.2933 51.9763C55.7433 51.9763 56.0433 51.6763 56.0433 51.2263Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M59.3329 49.1895C59.0471 48.9038 58.5471 48.9038 58.2614 49.1895C57.9757 49.4752 57.9757 49.9752 58.2614 50.2609C58.5471 50.5466 59.0471 50.5466 59.3329 50.2609C59.6186 49.9752 59.6186 49.4752 59.3329 49.1895Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M58.2614 42.178C57.9757 42.4637 57.9757 42.9637 58.2614 43.2495C58.5471 43.5352 59.0471 43.5352 59.3329 43.2495C59.6186 42.9637 59.6186 42.4637 59.3329 42.178C58.9757 41.8923 58.5471 41.8923 58.2614 42.178Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M59.9985 46.7733H69.0123C69.2626 46.7733 69.463 46.573 69.463 46.3226C69.463 46.0722 69.2626 45.8719 69.0123 45.8719H59.9985C59.7481 45.8719 59.5478 46.0722 59.5478 46.3226C59.4977 46.5229 59.698 46.7733 59.9985 46.7733Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M67.265 93.7941L77.0299 105.462C77.4305 105.963 78.1316 106.013 78.6324 105.662C81.0861 103.91 87.6963 97.5499 92.9543 78.1702L105.023 82.2264C100.917 113.925 88.5976 123.89 88.5976 123.89L67.6656 124.04L54.1449 106.814L67.265 93.7941Z"
                              fill="white"
                            />
                            <path
                              d="M67.4672 124.542L53.5459 106.765L67.317 93.1443L77.4325 105.163C77.6829 105.463 78.1336 105.513 78.4341 105.263C80.9379 103.46 87.3978 97.1505 92.5557 78.0212C92.6058 77.7708 92.8561 77.6206 93.1065 77.6706C93.3569 77.7207 93.5071 77.9711 93.4571 78.2215C88.199 97.6012 81.5889 104.061 78.9849 105.964C78.2838 106.465 77.2823 106.365 76.7314 105.664L67.2669 94.3963L54.7478 106.865L67.8679 123.591L88.3993 123.441C89.6512 122.289 100.718 111.623 104.524 82.1775C104.574 81.9272 104.774 81.7268 105.025 81.7769C105.275 81.827 105.475 82.0273 105.425 82.2777C101.319 113.776 89.3508 123.841 88.85 124.242L88.6998 124.342L67.4672 124.542Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M90.7031 85.5323L103.773 90.0893C104.074 88.6371 104.324 87.0847 104.574 85.5323L92.0552 81.3259C91.6045 82.8282 91.1538 84.2303 90.7031 85.5323Z"
                              fill="#414042"
                            />
                            <path
                              d="M69.3225 96.2484L56.1523 77.9704C53.5984 73.5637 49.3419 70.509 42.4313 69.3572C34.1186 68.0051 20.9484 67.9551 14.3383 69.858C5.57484 72.3618 -0.133909 79.8733 0.567165 88.0358L1.01785 128.197H57.1538C56.603 121.838 55.0005 117.431 56.8033 106.063C60.3087 101.707 66.4681 96.8493 69.3225 96.2484Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M57.7034 128.697H0.56592L0.0651511 88.0847C-0.686 79.7219 5.1229 72.0602 14.1367 69.4562C20.9471 67.5032 34.2675 67.6034 42.43 68.9554C49.0902 70.0571 53.6472 72.9115 56.4515 77.7189L59.7065 82.2258C59.8567 82.4261 59.8067 82.7265 59.6064 82.8768C59.4061 83.027 59.1056 82.9769 58.9554 82.7766L55.7004 78.2196C53.0463 73.6627 48.6396 70.9084 42.3299 69.8568C34.2675 68.5047 21.1474 68.4046 14.4372 70.3075C5.82397 72.7613 0.315534 80.0725 1.01661 88.0347L1.4673 127.796H56.6518C56.5517 126.494 56.3514 125.192 56.2011 123.99C55.5501 119.082 54.8491 114.024 56.7019 103.909L57.5031 98.701C57.5532 98.4506 57.8036 98.2503 58.054 98.3004C58.3044 98.3505 58.5047 98.6008 58.4546 98.8512L57.6534 104.059C55.8005 114.075 56.5016 119.032 57.1526 123.89C57.3529 125.292 57.5532 126.744 57.6534 128.246L57.7034 128.697Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M20.1471 68.5036L23.4021 72.6099C27.8088 78.1684 36.7225 75.6646 37.5738 68.6539L36.0214 52.0785H22.8012L20.1471 68.5036Z"
                              fill="white"
                            />
                            <path
                              d="M29.6131 76.1168C27.1092 76.1168 24.7056 74.965 23.053 72.9119L19.6979 68.6554L22.4521 51.6293H36.4736L38.076 68.7054V68.7555C37.6754 72.2108 35.3218 74.9149 31.9667 75.8664C31.1655 76.0166 30.3642 76.1168 29.6131 76.1168ZM20.6493 68.405L23.7541 72.361C25.657 74.7647 28.7117 75.7662 31.6662 74.965C34.6208 74.1137 36.6739 71.7601 37.0745 68.7054L35.5722 52.5807H23.2033L20.6493 68.405Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M106.027 73.2124C105.526 72.8619 104.875 72.6115 104.224 72.6115C103.623 72.5614 103.373 72.5614 103.022 72.1107C102.722 71.7101 102.221 71.4097 101.67 71.5098C101.47 71.5599 100.969 71.8103 100.819 71.7602C100.769 71.7602 100.769 71.7101 100.769 71.7101C100.368 71.1092 99.9173 70.4582 99.066 70.6084C98.7155 70.6585 98.4651 70.8588 98.2648 71.1593H98.2147L98.9659 65.2002C99.066 64.449 99.1662 63.6478 99.3164 62.8966C99.4166 62.0954 99.5668 61.2942 99.5167 60.4929C99.5167 60.0422 99.4666 59.5915 99.2162 59.241C98.9659 58.8905 98.5152 58.59 98.1146 58.6902C97.6138 58.7903 97.3133 59.3412 97.1631 59.8419C96.3619 62.496 96.0614 65.2001 95.4605 67.9043C94.7093 71.3596 93.9081 74.8149 92.9567 78.2201L105.025 82.2763L107.229 76.7679C107.679 75.6161 107.178 74.264 106.277 73.4628C106.177 73.3627 106.127 73.2625 106.027 73.2124Z"
                              fill="white"
                            />
                            <path
                              d="M92.9603 78.6194C92.9102 78.6194 92.8601 78.6194 92.8601 78.6194C92.6097 78.5693 92.4595 78.3189 92.5096 78.0686C93.5111 74.3128 94.3624 70.6071 95.0134 67.7528C95.2137 66.7512 95.414 65.7497 95.6143 64.7482C95.9148 63.0956 96.2152 61.3429 96.716 59.6403C96.9664 58.8391 97.4171 58.2883 98.018 58.1881C98.6189 58.088 99.2699 58.3884 99.6205 58.9393C99.9209 59.44 99.971 59.9909 100.021 60.4416C100.071 61.2929 99.9209 62.0941 99.8208 62.8953L99.4702 65.1989L98.7191 71.158C98.669 71.4084 98.4687 71.6087 98.2183 71.5586C97.9679 71.5085 97.7676 71.3082 97.8177 71.0578L98.5688 65.0987L98.9194 62.7952C99.0195 62.044 99.1197 61.2428 99.1197 60.4916C99.1197 60.0409 99.0195 59.6904 98.8693 59.44C98.7191 59.1897 98.4687 59.0394 98.2684 59.0895C97.9178 59.1396 97.7176 59.6904 97.6675 59.8907C97.1667 61.5433 96.8662 63.2459 96.5658 64.8984C96.3655 65.8999 96.2152 66.9015 95.9649 67.9531C95.3139 70.8074 94.4626 74.5632 93.461 78.3189C93.3609 78.5193 93.1606 78.6194 92.9603 78.6194Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M105.029 82.7272C104.979 82.7272 104.929 82.7272 104.879 82.6771C104.629 82.577 104.529 82.3266 104.629 82.0762L106.832 76.5678C107.183 75.6664 106.882 74.5146 106.031 73.7635C105.981 73.7134 105.881 73.6633 105.831 73.5632C105.38 73.2627 104.829 73.0624 104.278 73.0123C104.028 73.0123 103.828 72.762 103.828 72.5116C103.828 72.2612 104.078 72.0609 104.328 72.0609C105.029 72.111 105.781 72.3613 106.381 72.762C106.482 72.812 106.582 72.9122 106.682 73.0123C107.784 74.0139 108.234 75.5663 107.734 76.8182L105.53 82.3266C105.38 82.6271 105.23 82.7272 105.029 82.7272Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M107.382 67.4535C107.332 67.0029 106.781 66.7024 106.33 66.6523C105.329 66.5522 104.428 67.103 103.576 67.6538C100.972 69.3565 98.3682 71.0591 95.7642 72.7617L99.6702 79.8726C100.572 79.3217 101.173 78.4203 101.473 77.3687C101.773 76.3171 101.723 75.2655 101.423 74.264C101.373 74.1137 101.323 73.9635 101.323 73.7632C101.323 73.4127 101.573 73.1623 101.824 72.962C103.576 71.5598 105.429 70.1076 106.931 68.4551C107.182 68.2047 107.432 67.8542 107.382 67.4535Z"
                              fill="white"
                            />
                            <path
                              d="M6.77273 109.267L29.9082 128.246H16.9884H1.56476L6.77273 109.267Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M23.1532 98.4999L29.1124 109.467C29.5631 110.368 30.5145 110.969 31.516 111.069C42.0822 112.021 52.7986 113.673 63.4149 113.974L65.6684 114.224L86.6505 121.585C88.8038 122.336 90.5565 123.989 91.4579 126.092L91.7583 126.843C91.9085 127.244 91.6081 127.645 91.2075 127.645L68.8232 126.994L62.5135 128.145C62.5135 128.145 62.5135 128.145 62.4634 128.145H62.4134C60.3602 128.145 26.3081 128.145 26.3081 128.145C21.2503 128.145 17.6448 125.642 13.7388 121.034C13.7388 121.034 5.72653 111.119 6.92838 107.013C8.23037 102.656 18.6964 97.2981 23.1532 98.4999Z"
                              fill="white"
                            />
                            <path
                              d="M61.9122 128.798V128.697C57.205 128.697 26.3077 128.697 26.3077 128.697C21.5003 128.697 17.7446 126.594 13.3879 121.436L13.3378 121.386L3.82325 105.812C3.67302 105.612 3.77317 105.312 3.97348 105.161C4.17378 105.011 4.47425 105.111 4.62448 105.312L14.139 120.885C18.2954 125.793 21.8509 127.796 26.3077 127.796C26.3077 127.796 60.3599 127.796 62.413 127.796L68.7727 126.644L91.2071 127.295C91.2572 127.295 91.3073 127.295 91.3073 127.245C91.3073 127.245 91.3573 127.195 91.3073 127.145L91.0068 126.394C90.1555 124.391 88.503 122.838 86.4498 122.137L65.5177 114.776L63.2142 114.526C56.1534 114.325 48.9424 113.524 41.9817 112.723C38.5264 112.322 34.9209 111.922 31.4155 111.621C30.2137 111.521 29.2121 110.82 28.6613 109.768L22.8023 98.9018C20.2484 98.3509 15.3409 99.9534 11.485 102.658C11.2847 102.808 10.9842 102.758 10.834 102.557C10.6838 102.357 10.7338 102.057 10.9341 101.906C14.5397 99.4026 20.0982 97.1992 23.253 98.0505L23.4533 98.1006L29.4625 109.268C29.8631 110.069 30.6143 110.57 31.5157 110.62C35.021 110.92 38.6266 111.321 42.1319 111.721C49.0926 112.523 56.3537 113.324 63.4145 113.524H63.9153V113.574L65.8683 113.775L86.8504 121.136C89.154 121.937 91.0068 123.69 91.9582 125.943L92.2587 126.694C92.4089 127.045 92.3589 127.395 92.1586 127.696C91.9582 127.996 91.6077 128.147 91.2572 128.147L68.923 127.496L63.0139 128.547V128.597H62.5632L61.9122 128.798Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M80.9926 125.542C81.8439 125.642 82.6953 125.692 83.4464 126.043C84.0473 126.343 84.6482 126.794 84.8986 127.445C84.9487 127.545 84.9988 127.595 85.0989 127.545C85.1991 127.545 85.2492 127.395 85.1991 127.345C84.8986 126.644 84.2977 126.093 83.6467 125.742C82.7954 125.342 81.894 125.241 80.9426 125.191C80.7923 125.191 80.7923 125.542 80.9926 125.542Z"
                              fill="#F9696A"
                            />
                            <path
                              d="M20.246 50.3278C20.5966 52.1306 19.4448 53.8332 17.642 54.1837C15.8393 54.5343 14.1367 53.3825 13.7861 51.5797C13.4356 49.777 14.5874 48.0744 16.3901 47.7238C18.1428 47.4234 19.8955 48.5751 20.246 50.3278Z"
                              fill="white"
                            />
                            <path
                              d="M16.9904 54.7327C15.2377 54.7327 13.6352 53.4808 13.2847 51.678C12.8841 49.6249 14.2362 47.6719 16.2392 47.2713C18.2924 46.8706 20.2454 48.2227 20.646 50.2258C21.0466 52.2789 19.6945 54.2319 17.6915 54.6325C17.4912 54.6826 17.2408 54.7327 16.9904 54.7327ZM16.9904 48.1726C16.7901 48.1726 16.6399 48.1726 16.4395 48.2227C14.9372 48.5232 13.9357 50.0255 14.2362 51.5278C14.5366 53.0301 16.0389 54.0316 17.5412 53.7311C19.0435 53.4307 20.0451 51.9284 19.7446 50.4261C19.4942 49.074 18.3425 48.1726 16.9904 48.1726Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M38.6772 41.6125C38.6772 41.6125 47.04 65.8496 32.3174 66.3504C17.5448 66.8512 15.8422 45.6187 15.8422 45.6187C15.8422 45.6187 21.9515 25.6881 38.6772 41.6125Z"
                              fill="white"
                            />
                            <path
                              d="M31.7635 66.801C23.3507 66.801 19.1943 59.8904 17.1912 53.9814C17.0911 53.731 17.2413 53.4806 17.4917 53.3805C17.7421 53.2803 17.9925 53.4306 18.0926 53.6809C20.0456 59.3897 24.0517 66.1 32.3144 65.8496C35.2188 65.7494 37.3721 64.6978 38.7242 62.6948C43.181 56.1848 38.3236 41.8628 38.2735 41.7126C38.1734 41.4622 38.3236 41.2118 38.574 41.1117C38.8244 41.0115 39.0747 41.1618 39.1749 41.4122C39.3752 42.0131 44.2326 56.335 39.5254 63.1955C37.9731 65.449 35.5694 66.6508 32.3645 66.751C32.1141 66.801 31.9639 66.801 31.7635 66.801Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M19.593 47.3247C20.0437 48.9272 20.3942 51.431 19.6431 52.7831C19.2925 53.384 18.5414 53.7345 17.9405 53.4341C16.989 52.8832 16.7386 51.4811 16.338 50.5797C15.8373 49.5281 15.1362 48.6768 14.2348 47.8756C12.4821 46.3733 10.1786 45.2716 9.77797 42.8178C9.52758 41.3656 10.3288 38.8117 11.9813 38.5112C12.2818 38.4611 12.5823 38.4611 12.8326 38.2608C12.9829 38.1106 13.033 37.8101 13.083 37.6098C13.5838 34.5552 17.7402 31.4003 20.1438 29.8479C23.7994 27.4943 28.3064 25.992 32.7632 26.7432C36.6692 27.3942 40.5751 30.2986 41.8771 34.1045C42.9288 37.1091 42.1275 40.8648 39.5235 42.7176C36.0182 45.2716 30.1091 44.3702 26.5036 42.4172C25.9027 42.1167 23.3988 40.4642 23.1985 39.9134C23.6993 41.3155 23.7994 42.8178 23.0984 44.1699C22.4474 45.3717 20.895 46.5736 19.4928 46.8239C19.4928 46.874 19.5429 47.0743 19.593 47.3247Z"
                              fill="#EF9E8F"
                            />
                            <path
                              d="M34.5652 46.8712C34.415 46.8712 34.2648 46.8211 34.1646 46.6709C34.0144 46.4706 34.0645 46.1701 34.3149 46.0199C34.415 45.9698 36.3179 44.7179 38.4712 45.9698C38.6715 46.12 38.7717 46.3704 38.6715 46.6208C38.5213 46.8211 38.2709 46.9213 38.0205 46.8211C36.368 45.8696 34.9158 46.8211 34.8657 46.8712C34.7656 46.8712 34.6654 46.8712 34.5652 46.8712Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M37.2191 49.2762C37.4194 49.7269 37.2692 50.2778 36.9186 50.428C36.518 50.6283 36.0673 50.3779 35.817 49.9272C35.6166 49.4765 35.7669 48.9257 36.1174 48.7755C36.518 48.5752 36.9687 48.8255 37.2191 49.2762Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M25.1571 49.6254C25.0569 49.6254 24.9568 49.6254 24.9067 49.5252C24.7064 49.375 24.6062 49.0746 24.7564 48.8743C24.8065 48.7741 26.0584 46.9213 28.5122 47.1717C28.7626 47.2217 28.9629 47.422 28.9128 47.6724C28.8627 47.9228 28.6624 48.1231 28.412 48.073C26.5091 47.8727 25.5577 49.3249 25.5076 49.375C25.4575 49.5253 25.3073 49.6254 25.1571 49.6254Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M28.8114 50.9303C29.0117 51.381 28.8614 51.9319 28.5109 52.0821C28.1103 52.2824 27.6596 52.032 27.4092 51.5813C27.2089 51.1306 27.3591 50.5798 27.7097 50.4296C28.1103 50.2293 28.6111 50.4296 28.8114 50.9303Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M33.6684 56.8875C33.4181 56.8875 33.2178 56.6872 33.2178 56.4869C33.2178 56.2365 33.368 55.9861 33.6184 55.9861L35.0205 55.8359L33.1677 51.329C33.0675 51.0786 33.1677 50.8282 33.4181 50.728C33.6684 50.6279 33.9188 50.728 34.019 50.9784L35.972 55.8359C36.0721 56.0362 36.0221 56.2365 35.9219 56.4368C35.8217 56.6371 35.6214 56.7373 35.4211 56.7873L33.6684 56.8875C33.7185 56.8875 33.6684 56.8875 33.6684 56.8875Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M32.6651 60.2943C30.8123 60.2943 30.2614 58.792 30.2114 58.742C30.1112 58.4916 30.2614 58.2412 30.4617 58.141C30.7121 58.0409 30.9625 58.1911 31.0627 58.3914C31.0627 58.4415 31.4633 59.3429 32.615 59.3429H32.6651C32.9155 59.3429 33.1158 59.5432 33.1659 59.7936C33.1659 60.0439 32.9656 60.2443 32.7152 60.2943C32.7653 60.2943 32.7152 60.2943 32.6651 60.2943Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M60.3557 102.759C60.2555 102.759 60.1053 102.709 60.0051 102.608C59.8048 102.408 59.8549 102.108 60.0051 101.957C63.0097 99.1031 66.3148 96.7995 68.368 96.0484L68.418 95.9983C68.418 95.9983 68.418 95.9482 68.418 95.8982L53.7456 75.5169C53.5953 75.3166 53.6454 75.0162 53.8457 74.8659C54.046 74.7157 54.3465 74.7658 54.4967 74.9661L69.2193 95.3473C69.4196 95.5977 69.4696 95.9482 69.3695 96.2487C69.2693 96.5492 69.069 96.7995 68.7686 96.8997C66.8156 97.6008 63.6107 99.8542 60.7062 102.608C60.6061 102.709 60.5059 102.759 60.3557 102.759Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M100.021 80.6225C99.8703 80.6225 99.67 80.5224 99.62 80.3721C99.5198 80.1217 99.5699 79.8714 99.8203 79.7211C102.775 78.1688 101.122 74.1626 101.122 74.1125L100.972 73.8121L105.93 69.4053C106.531 68.8545 106.781 68.0032 106.531 67.252L106.43 66.9015C106.43 66.8514 106.38 66.8514 106.38 66.8514C106.38 66.8514 106.33 66.8514 106.28 66.8514L96.365 73.3614C96.1647 73.5116 95.8642 73.4615 95.714 73.2112C95.5637 73.0109 95.6138 72.7104 95.8642 72.5602L105.779 66.0502C106.03 65.8499 106.38 65.8499 106.681 65.95C106.981 66.0502 107.232 66.3006 107.282 66.601L107.382 66.9516C107.682 68.0533 107.332 69.3052 106.48 70.0563L102.024 74.0124C102.675 75.7651 102.975 79.0201 100.221 80.4723C100.171 80.5724 100.071 80.6225 100.021 80.6225Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M104.126 90.7385L90.1042 85.831L90.2544 85.3803C90.7051 84.0282 91.1558 82.6261 91.6065 81.1739L91.7567 80.7232L105.077 85.2301L105.027 85.6307C104.777 87.1831 104.526 88.7354 104.226 90.2377L104.126 90.7385ZM91.306 85.2301L103.425 89.4866C103.625 88.2848 103.875 87.0829 104.076 85.831L92.4077 81.8749C92.0071 83.0267 91.6566 84.1785 91.306 85.2301Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M99.1647 85.4331C99.1647 86.1842 98.5637 86.7851 97.8126 86.7851C97.0614 86.7851 96.4605 86.1842 96.4605 85.4331C96.4605 84.6819 97.0614 84.081 97.8126 84.081C98.5637 84.081 99.1647 84.6819 99.1647 85.4331Z"
                              fill="#FAAF3D"
                            />
                            <path
                              d="M76.7318 109.57C76.6317 109.57 76.4814 109.52 76.3813 109.42C76.181 109.219 76.181 108.919 76.3813 108.769C77.533 107.617 77.3327 105.964 77.3327 105.964C77.2827 105.714 77.483 105.464 77.7333 105.464C77.9837 105.414 78.2341 105.614 78.2341 105.864C78.2341 105.964 78.4845 107.968 77.0323 109.47C76.9321 109.52 76.832 109.57 76.7318 109.57Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M28.6086 114.524C28.4583 114.524 28.3081 114.474 28.2079 114.324C28.0577 114.123 28.1078 113.823 28.3081 113.673C29.6602 112.671 29.6101 111.219 29.6101 111.119C29.6101 110.869 29.8104 110.618 30.0608 110.618C30.3112 110.618 30.5615 110.768 30.5615 111.069C30.5615 111.169 30.7118 113.122 28.909 114.424C28.7588 114.474 28.6586 114.524 28.6086 114.524Z"
                              fill="#4A154B"
                            />
                          </svg>
                        </div>
                        <h4 className="mb-2">Start from scratch</h4>
                        <p className="h6 text-gray-300">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Aenean commodo{" "}
                        </p>
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="bg-base-100 rounded-6 boxShadow p-32 d-flex flex-column authorContent">
                        <div className="mb-32 flex-grow-1 h-100">
                          <svg
                            width="179"
                            height="118"
                            viewBox="0 0 179 118"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M161.212 74.9356H66.169C62.0027 74.9356 58.878 71.471 59.555 67.6161L68.4604 16.5255C69.0853 12.7681 72.5746 10.0355 76.5846 10.0355H171.088C175.255 10.0355 178.971 13.5001 178.294 17.355L169.336 68.4456C168.711 72.1542 165.274 74.9356 161.212 74.9356Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M65.5856 69.0396C65.2212 69.0396 65.013 68.8932 64.9089 68.7468C64.8048 68.6004 64.6487 68.3564 64.7007 68.0636L73.6014 16.9708C73.8096 15.8484 74.7985 15.0676 75.9957 15.0676H169.166C170.728 15.0676 171.925 16.3852 171.665 17.8004L163.024 67.1364C162.816 68.2588 161.827 69.0396 160.63 69.0396H65.5856Z"
                              fill="white"
                            />
                            <path
                              d="M169.166 15.0675H75.9949C74.7978 15.0675 73.8088 15.8483 73.6006 16.9707L72.7157 21.9482H170.935L171.664 17.8003C171.924 16.3851 170.727 15.0675 169.166 15.0675Z"
                              fill="#EEEEEE"
                            />
                            <path
                              d="M170.59 62.5685H62.895C62.0597 62.5685 61.2245 62.4219 60.4936 62.1775L59.554 67.6034C58.8753 71.465 62.0597 74.9356 66.1838 74.9356H161.507C165.579 74.9356 169.024 72.1983 169.651 68.4344L170.695 62.5197C170.642 62.5685 170.59 62.5685 170.59 62.5685Z"
                              fill="#EEEEEE"
                            />
                            <path
                              d="M171.667 10.0355H170.37C174.521 10.0355 177.634 13.5001 176.959 17.355L168.139 68.4456C167.516 72.203 164.04 74.9356 160.045 74.9356H161.342C165.389 74.9356 168.814 72.203 169.436 68.4456L178.308 17.355C178.931 13.5489 175.766 10.0355 171.667 10.0355Z"
                              fill="#D9D9D9"
                            />
                            <path
                              d="M130.7 85.3389H109.516L106.289 74.7983H127.473L130.7 85.3389Z"
                              fill="#D1D3D4"
                            />
                            <path
                              d="M128.202 77.0919H107.017L106.289 74.7983H127.473L128.202 77.0919Z"
                              fill="#888888"
                            />
                            <path
                              d="M130.961 74.7981H127.474L130.701 85.3387H101.605C100.668 85.3387 99.8873 86.0707 99.8873 86.9491C99.8873 87.8275 100.668 88.5595 101.605 88.5595H135.386L130.961 74.7981Z"
                              fill="white"
                            />
                            <path
                              d="M93.2762 41.9069H75.0065C73.9134 41.9069 73.0806 40.9797 73.2888 40.0037L75.6311 26.5839C75.7872 25.6079 76.7241 24.8759 77.7651 24.8759H96.0349C97.128 24.8759 97.9608 25.8031 97.7526 26.7791L95.4103 40.1989C95.2021 41.2237 94.3172 41.9069 93.2762 41.9069Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M163.856 29.6097H115.345C115.137 29.6097 114.928 29.4145 114.98 29.2193L115.501 26.389C115.553 26.1938 115.709 26.0474 115.969 26.0474H164.481C164.689 26.0474 164.897 26.2426 164.845 26.4378L164.324 29.2681C164.272 29.4633 164.064 29.6097 163.856 29.6097Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M136.06 32.8795H114.355C114.251 32.8795 114.147 32.7819 114.199 32.6843L114.407 31.4155C114.407 31.3179 114.511 31.2691 114.616 31.2691H136.321C136.425 31.2691 136.529 31.3667 136.477 31.4643L136.269 32.7331C136.269 32.8307 136.165 32.8795 136.06 32.8795Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M93.5377 46.5921H72.5092C72.4051 46.5921 72.301 46.4945 72.353 46.3969L72.5612 45.1281C72.5612 45.0305 72.6654 44.9817 72.7695 44.9817H93.7979C93.902 44.9817 94.0061 45.0793 93.9541 45.1769L93.7459 46.4457C93.7459 46.5433 93.6418 46.5921 93.5377 46.5921Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M84.0651 50.5287H71.985C71.9252 50.5287 71.8654 50.4279 71.8953 50.327L72.0149 49.0159C72.0149 48.9151 72.0747 48.8646 72.1345 48.8646H84.2146C84.2744 48.8646 84.3342 48.9655 84.3043 49.0663L84.1847 50.3774C84.1548 50.4783 84.1249 50.5287 84.0651 50.5287Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M161.72 41.2238H113.209C113.001 41.2238 112.793 41.0286 112.845 40.8334L113.365 38.003C113.417 37.8078 113.574 37.6614 113.834 37.6614H162.345C162.553 37.6614 162.761 37.8566 162.709 38.0518L162.189 40.8822C162.137 41.0774 161.981 41.2238 161.72 41.2238Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M133.979 44.4936H112.274C112.17 44.4936 112.066 44.396 112.118 44.2984L112.326 43.0296C112.326 42.932 112.43 42.8832 112.534 42.8832H134.239C134.344 42.8832 134.448 42.9808 134.396 43.0784L134.187 44.3472C134.135 44.396 134.083 44.4936 133.979 44.4936Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M139.9 52.6846H111.303C111.18 52.6846 111.057 52.4832 111.088 52.2818L111.395 49.3614C111.426 49.16 111.518 49.0089 111.671 49.0089H140.268C140.391 49.0089 140.513 49.2104 140.483 49.4118L140.176 52.3321C140.145 52.5335 140.022 52.6846 139.9 52.6846Z"
                              fill="#E3828D"
                            />
                            <path
                              d="M109.983 33.1719H101.655C101.135 33.1719 100.77 32.7327 100.875 32.2936L101.968 26.096C102.072 25.6568 102.488 25.3152 102.957 25.3152H111.285C111.805 25.3152 112.17 25.7544 112.065 26.1936L110.972 32.3911C110.868 32.8303 110.452 33.1719 109.983 33.1719Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M108.266 44.1517H99.9382C99.4176 44.1517 99.0533 43.7125 99.1574 43.2733L100.25 37.0758C100.355 36.6366 100.771 36.295 101.239 36.295H109.568C110.088 36.295 110.452 36.7342 110.348 37.1734L109.255 43.3709C109.151 43.8101 108.735 44.1517 108.266 44.1517Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M106.549 55.1314H98.2209C97.7004 55.1314 97.3361 54.6922 97.4402 54.253L98.5332 48.0555C98.6374 47.6163 99.0538 47.2747 99.5222 47.2747H107.85C108.371 47.2747 108.735 47.7139 108.631 48.1531L107.538 54.3506C107.434 54.8386 107.017 55.1314 106.549 55.1314Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M79.7942 18.776C79.6901 19.264 79.2737 19.6056 78.9094 19.5568C78.545 19.508 78.3368 19.02 78.4409 18.532C78.545 18.044 78.9614 17.7024 79.3258 17.7512C79.6901 17.8 79.8983 18.2392 79.7942 18.776Z"
                              fill="white"
                            />
                            <path
                              d="M83.335 18.776C83.2309 19.264 82.8145 19.6056 82.4501 19.5568C82.0857 19.508 81.8775 19.02 81.9816 18.532C82.0857 18.044 82.5022 17.7024 82.8665 17.7512C83.2309 17.8 83.4391 18.2392 83.335 18.776Z"
                              fill="white"
                            />
                            <path
                              d="M86.8734 18.776C86.7693 19.264 86.3529 19.6056 85.9885 19.5568C85.6242 19.508 85.416 19.02 85.5201 18.532C85.6242 18.044 86.0406 17.7024 86.4049 17.7512C86.7693 17.8 86.9775 18.2392 86.8734 18.776Z"
                              fill="white"
                            />
                            <path
                              d="M113.362 58.5403C113.822 57.7858 115.201 56.8696 115.949 54.121C116.351 52.7737 114.397 50.9952 113.017 52.7198L109.453 56.7079C109.51 56.7618 111.752 61.2889 113.362 58.5403Z"
                              fill="white"
                            />
                            <path
                              d="M112.959 53.7974C113.303 52.989 114.511 51.8572 114.855 48.9469C115.028 47.4918 112.729 45.9828 111.694 47.9768L108.762 52.5578C108.705 52.5039 111.694 56.8154 112.959 53.7974Z"
                              fill="white"
                            />
                            <path
                              d="M112.556 62.2586C113.131 61.6658 114.511 61.1807 115.833 58.8633C116.465 57.7315 115.143 55.7914 113.591 57.0848L109.567 59.9412C109.567 59.8873 110.487 64.3066 112.556 62.2586Z"
                              fill="white"
                            />
                            <path
                              d="M40.7015 103.379L24.8932 104.996L11.3269 106.613C5.57842 107.314 0.462303 103.056 0.577272 97.6126C0.634757 94.918 0.864696 92.2233 1.2096 89.5286L4.5437 65.007L26.5603 67.6478L26.9627 88.5585L40.7015 103.379Z"
                              fill="white"
                            />
                            <path
                              d="M10.0617 107.206C7.58992 107.206 5.17557 106.344 3.33607 104.781C1.15165 102.948 -0.0555214 100.361 0.00196308 97.6129C0.0594476 94.9182 0.289387 92.1696 0.634294 89.4749L4.02588 64.4683L27.0197 67.2168L27.4221 88.3971L41.7357 103.703L24.9502 105.481L11.3839 107.098C10.924 107.152 10.4641 107.206 10.0617 107.206ZM4.94563 65.6L1.66901 89.5827C1.32411 92.2235 1.09417 94.9182 1.03669 97.6129C0.979201 100.038 2.0714 102.356 4.02588 103.972C5.98035 105.589 8.56715 106.398 11.2114 106.074L24.7778 104.457L39.6088 102.948L26.3873 88.7743L25.985 68.0791L4.94563 65.6Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M30.8713 46.6833L35.1826 45.4976C39.8389 50.9948 48.8064 52.9888 49.7262 46.0366L50.4735 45.3898L48.4615 25.1797H34.6078L30.8713 46.6833Z"
                              fill="white"
                            />
                            <path
                              d="M44.725 50.9949C41.5058 50.9949 37.6544 49.1086 35.0101 46.0905L30.2389 47.384L34.1478 24.6408H49.0363L51.1057 45.5516L50.2435 46.2522C49.8986 48.6235 48.5189 50.1864 46.4495 50.7254C45.9321 50.941 45.3573 50.9949 44.725 50.9949ZM35.4125 44.9049L35.6424 45.1743C38.5166 48.5696 43.1154 50.6176 46.2196 49.8092C47.9441 49.3781 48.9788 48.0307 49.2662 45.9827V45.8211L50.0135 45.1743L48.1166 25.6648H35.0101L31.5035 45.9827L35.4125 44.9049Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M50.818 33.2639C44.9546 33.1561 39.3786 29.2757 33.5727 29.2218C31.8481 3.73012 22.3632 8.1494 51.9677 8.31108C58.3485 8.25719 57.1413 15.5328 57.1413 19.5749C59.2108 24.5331 57.7162 32.9405 50.818 33.2639Z"
                              fill="white"
                            />
                            <path
                              d="M24.9505 79.5047C21.0991 76.4327 9.31474 75.3009 2.87647 76.8638L5.69321 55.8453C6.61297 48.8391 12.5339 43.2881 20.0069 42.4258L29.9517 42.4797L24.9505 79.5047Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M72.3741 108.553H18.7986L17.3615 86.8881C17.0166 81.7143 16.2693 76.5944 15.0621 71.5284L12.1304 59.2945C12.0729 59.0251 12.0154 58.7556 12.0154 58.4861C11.2681 50.2943 19.9483 43.0725 28.8584 42.4797C29.6057 42.4258 30.2955 42.5336 30.9853 42.6953L35.1817 43.7732C35.3541 43.827 35.5841 43.8809 35.7565 43.8809C38.4583 44.3121 41.2175 44.4199 43.9193 44.0965L58.4054 42.4797C58.7503 42.4258 59.0952 42.4258 59.4401 42.4258C68.4077 42.7492 75.3059 50.0787 74.3861 58.4861C74.3861 58.5939 74.3861 58.7556 74.3861 58.8634L72.3741 108.553Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M104.395 69.2645L90.2536 63.6596L87.3794 74.115L83.3554 67.5399L64.0406 77.9953L77.4345 97.6665C83.068 105.912 96.5194 103.703 98.7613 94.1095L104.395 69.2645Z"
                              fill="white"
                            />
                            <path
                              d="M104.221 69.4266L112.326 62.636C114.338 60.4802 111.349 54.0668 111.867 51.2644C112.614 47.2224 114.281 39.6233 113.534 37.1442C112.786 34.5573 111.292 34.719 110.717 36.6053L108.59 44.2043C108.13 45.875 107.498 47.5457 106.75 49.1086L106.003 50.6716C105.601 51.3183 105.083 51.8572 104.394 52.1806C104.049 52.3423 103.704 52.5039 103.359 52.5578C102.267 52.7734 101.002 52.2884 101.002 51.2105L101.807 45.9289C101.749 42.5875 99.7373 41.5096 98.6451 44.3121L95.541 51.2644C94.8511 52.7734 93.7589 54.3902 93.7589 54.3902C92.2643 56.9771 91.4021 60.1568 91.4021 60.1568C91.4021 60.1568 90.5973 61.8814 90.2524 63.5522L104.221 69.4266Z"
                              fill="white"
                            />
                            <path
                              d="M87.0907 73.6845C78.8129 73.2533 70.7651 78.4271 67.8334 84.1399L52.485 59.241L71.7423 48.7856L87.0907 73.6845Z"
                              fill="#F6EFE8"
                            />
                            <path
                              d="M32.3076 38.3298C32.3076 38.3298 41.5626 43.1264 49.8979 39.246L56.0487 42.7491C56.0487 42.7491 40.6429 46.5756 30.0082 42.5336L32.3076 38.3298Z"
                              fill="white"
                            />
                            <path
                              d="M42.0231 44.8509C37.9417 44.8509 33.6304 44.3659 29.8364 42.9108L29.2616 42.6952L32.0783 37.5753L32.5382 37.8448C32.6532 37.8986 41.6782 42.4257 49.6686 38.761L49.956 38.6532L57.429 42.9108L56.2218 43.2341C55.7619 43.3419 49.3812 44.8509 42.0231 44.8509ZM30.7562 42.2101C39.3214 45.1743 50.8757 43.288 54.6697 42.5335L49.8985 39.8388C42.483 43.1263 34.6076 39.9466 32.5382 39.0304L30.7562 42.2101Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M35.3548 61.4502C35.3548 61.7735 35.3548 62.043 35.3548 62.3664C35.0099 62.3664 34.7225 62.3664 34.3776 62.3664C34.0327 62.3664 34.0327 62.8514 34.3776 62.8514C34.7225 62.8514 35.0099 62.8514 35.3548 62.8514C35.3548 63.1748 35.3548 63.4442 35.3548 63.7676C35.3548 64.091 35.8722 64.091 35.8722 63.7676C35.8722 63.4442 35.8722 63.1748 35.8722 62.8514C36.2171 62.8514 36.5045 62.8514 36.8494 62.8514C37.1943 62.8514 37.1943 62.3664 36.8494 62.3664C36.5045 62.3664 36.2171 62.3664 35.8722 62.3664C35.8722 62.043 35.8722 61.7735 35.8722 61.4502C35.8722 61.1807 35.3548 61.1807 35.3548 61.4502Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M30.7568 104.781C30.7568 104.458 30.7568 104.188 30.7568 103.865C31.1018 103.865 31.3892 103.865 31.7341 103.865C32.079 103.865 32.079 103.38 31.7341 103.38C31.3892 103.38 31.1018 103.38 30.7568 103.38C30.7568 103.057 30.7568 102.787 30.7568 102.464C30.7568 102.14 30.2395 102.14 30.2395 102.464C30.2395 102.787 30.2395 103.057 30.2395 103.38C29.8946 103.38 29.6072 103.38 29.2623 103.38C28.9173 103.38 28.9173 103.865 29.2623 103.865C29.6072 103.865 29.8946 103.865 30.2395 103.865C30.2395 104.188 30.2395 104.458 30.2395 104.781C30.2395 105.051 30.7568 105.051 30.7568 104.781Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M36.5627 96.5353C36.5627 96.2119 36.5627 95.9424 36.5627 95.6191C36.9076 95.6191 37.1951 95.6191 37.54 95.6191C37.8849 95.6191 37.8849 95.134 37.54 95.134C37.1951 95.134 36.9076 95.134 36.5627 95.134C36.5627 94.8107 36.5627 94.5412 36.5627 94.2178C36.5627 93.8945 36.0454 93.8945 36.0454 94.2178C36.0454 94.5412 36.0454 94.8107 36.0454 95.134C35.7005 95.134 35.4131 95.134 35.0681 95.134C34.7232 95.134 34.7232 95.6191 35.0681 95.6191C35.4131 95.6191 35.7005 95.6191 36.0454 95.6191C36.0454 95.9424 36.0454 96.2119 36.0454 96.5353C36.0454 96.8586 36.5627 96.8586 36.5627 96.5353Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M34.7233 79.2355C35.0682 79.2355 35.3557 79.2355 35.7006 79.2355C35.7006 79.5589 35.7006 79.8283 35.7006 80.1517C35.7006 80.475 36.2179 80.475 36.2179 80.1517C36.2179 79.8283 36.2179 79.5589 36.2179 79.2355C36.5628 79.2355 36.8503 79.2355 37.1952 79.2355C37.5401 79.2355 37.5401 78.7504 37.1952 78.7504C36.8503 78.7504 36.5628 78.7504 36.2179 78.7504C36.2179 78.4271 36.2179 78.1576 36.2179 77.8343C36.2179 77.5109 35.7006 77.5109 35.7006 77.8343C35.7006 78.1576 35.7006 78.4271 35.7006 78.7504C35.3557 78.7504 35.0682 78.7504 34.7233 78.7504C34.3784 78.7504 34.3784 79.2355 34.7233 79.2355Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M28.9163 87.481C29.2612 87.481 29.5486 87.481 29.8935 87.481C29.8935 87.8044 29.8935 88.0739 29.8935 88.3972C29.8935 88.7206 30.4109 88.7206 30.4109 88.3972C30.4109 88.0739 30.4109 87.8044 30.4109 87.481C30.7558 87.481 31.0432 87.481 31.3881 87.481C31.733 87.481 31.733 86.996 31.3881 86.996C31.0432 86.996 30.7558 86.996 30.4109 86.996C30.4109 86.6726 30.4109 86.4032 30.4109 86.0798C30.4109 85.7564 29.8935 85.7564 29.8935 86.0798C29.8935 86.4032 29.8935 86.6726 29.8935 86.996C29.5486 86.996 29.2612 86.996 28.9163 86.996C28.6289 86.996 28.6289 87.481 28.9163 87.481Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M29.606 69.6958C29.606 70.0191 29.606 70.2886 29.606 70.612C29.2611 70.612 28.9737 70.612 28.6288 70.612C28.2839 70.612 28.2839 71.097 28.6288 71.097C28.9737 71.097 29.2611 71.097 29.606 71.097C29.606 71.4204 29.606 71.6898 29.606 72.0132C29.606 72.3366 30.1234 72.3366 30.1234 72.0132C30.1234 71.6898 30.1234 71.4204 30.1234 71.097C30.4683 71.097 30.7557 71.097 31.1006 71.097C31.4455 71.097 31.4455 70.612 31.1006 70.612C30.7557 70.612 30.4683 70.612 30.1234 70.612C30.1234 70.2886 30.1234 70.0191 30.1234 69.6958C30.1234 69.3724 29.606 69.3724 29.606 69.6958Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M70.4204 80.1517C70.4204 79.8283 70.4204 79.5589 70.4204 79.2355C70.7653 79.2355 71.0527 79.2355 71.3976 79.2355C71.7425 79.2355 71.7425 78.7504 71.3976 78.7504C71.0527 78.7504 70.7653 78.7504 70.4204 78.7504C70.4204 78.4271 70.4204 78.1576 70.4204 77.8343C70.4204 77.5109 69.903 77.5109 69.903 77.8343C69.903 78.1576 69.903 78.4271 69.903 78.7504C69.5581 78.7504 69.2707 78.7504 68.9258 78.7504C68.5809 78.7504 68.5809 79.2355 68.9258 79.2355C69.2707 79.2355 69.5581 79.2355 69.903 79.2355C69.903 79.5589 69.903 79.8283 69.903 80.1517C69.9605 80.475 70.4204 80.475 70.4204 80.1517Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M57.8313 45.0667C57.8313 45.39 57.8313 45.6595 57.8313 45.9829C57.4864 45.9829 57.199 45.9829 56.8541 45.9829C56.5092 45.9829 56.5092 46.4679 56.8541 46.4679C57.199 46.4679 57.4864 46.4679 57.8313 46.4679C57.8313 46.7913 57.8313 47.0607 57.8313 47.3841C57.8313 47.7075 58.3487 47.7075 58.3487 47.3841C58.3487 47.0607 58.3487 46.7913 58.3487 46.4679C58.6936 46.4679 58.981 46.4679 59.3259 46.4679C59.6709 46.4679 59.6709 45.9829 59.3259 45.9829C58.981 45.9829 58.6936 45.9829 58.3487 45.9829C58.3487 45.6595 58.3487 45.39 58.3487 45.0667C58.3487 44.7972 57.8313 44.7972 57.8313 45.0667Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M23.5696 45.0667C23.5696 45.39 23.5696 45.6595 23.5696 45.9829C23.2247 45.9829 22.9373 45.9829 22.5924 45.9829C22.2475 45.9829 22.2475 46.4679 22.5924 46.4679C22.9373 46.4679 23.2247 46.4679 23.5696 46.4679C23.5696 46.7913 23.5696 47.0607 23.5696 47.3841C23.5696 47.7075 24.087 47.7075 24.087 47.3841C24.087 47.0607 24.087 46.7913 24.087 46.4679C24.4319 46.4679 24.7193 46.4679 25.0642 46.4679C25.4091 46.4679 25.4091 45.9829 25.0642 45.9829C24.7193 45.9829 24.4319 45.9829 24.087 45.9829C24.087 45.6595 24.087 45.39 24.087 45.0667C24.087 44.7972 23.5696 44.7972 23.5696 45.0667Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M25.1223 96.5353C25.1223 96.2119 25.1223 95.9424 25.1223 95.6191C25.4672 95.6191 25.7546 95.6191 26.0995 95.6191C26.4444 95.6191 26.4444 95.134 26.0995 95.134C25.7546 95.134 25.4672 95.134 25.1223 95.134C25.1223 94.8107 25.1223 94.5412 25.1223 94.2178C25.1223 93.8945 24.6049 93.8945 24.6049 94.2178C24.6049 94.5412 24.6049 94.8107 24.6049 95.134C24.26 95.134 23.9726 95.134 23.6277 95.134C23.2827 95.134 23.2827 95.6191 23.6277 95.6191C23.9726 95.6191 24.26 95.6191 24.6049 95.6191C24.6049 95.9424 24.6049 96.2119 24.6049 96.5353C24.6049 96.8586 25.1223 96.8586 25.1223 96.5353Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M22.9381 62.8514C23.283 62.8514 23.5704 62.8514 23.9153 62.8514C23.9153 63.1748 23.9153 63.4443 23.9153 63.7676C23.9153 64.091 24.4327 64.091 24.4327 63.7676C24.4327 63.4443 24.4327 63.1748 24.4327 62.8514C24.7776 62.8514 25.065 62.8514 25.4099 62.8514C25.7548 62.8514 25.7548 62.3664 25.4099 62.3664C25.065 62.3664 24.7776 62.3664 24.4327 62.3664C24.4327 62.043 24.4327 61.7736 24.4327 61.4502C24.4327 61.1269 23.9153 61.1269 23.9153 61.4502C23.9153 61.7736 23.9153 62.043 23.9153 62.3664C23.5704 62.3664 23.283 62.3664 22.9381 62.3664C22.5932 62.3664 22.5932 62.8514 22.9381 62.8514Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M23.2829 79.2355C23.6278 79.2355 23.9152 79.2355 24.2601 79.2355C24.2601 79.5589 24.2601 79.8283 24.2601 80.1517C24.2601 80.475 24.7775 80.475 24.7775 80.1517C24.7775 79.8283 24.7775 79.5589 24.7775 79.2355C25.1224 79.2355 25.4098 79.2355 25.7547 79.2355C26.0996 79.2355 26.0996 78.7504 25.7547 78.7504C25.4098 78.7504 25.1224 78.7504 24.7775 78.7504C24.7775 78.4271 24.7775 78.1576 24.7775 77.8343C24.7775 77.5109 24.2601 77.5109 24.2601 77.8343C24.2601 78.1576 24.2601 78.4271 24.2601 78.7504C23.9152 78.7504 23.6278 78.7504 23.2829 78.7504C22.938 78.7504 22.938 79.2355 23.2829 79.2355Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M16.8449 54.714C17.1898 54.714 17.4772 54.714 17.8221 54.714C17.8221 55.0374 17.8221 55.3069 17.8221 55.6302C17.8221 55.9536 18.3395 55.9536 18.3395 55.6302C18.3395 55.3069 18.3395 55.0374 18.3395 54.714C18.6844 54.714 18.9718 54.714 19.3167 54.714C19.6616 54.714 19.6616 54.229 19.3167 54.229C18.9718 54.229 18.6844 54.229 18.3395 54.229C18.3395 53.9056 18.3395 53.6362 18.3395 53.3128C18.3395 52.9894 17.8221 52.9894 17.8221 53.3128C17.8221 53.6362 17.8221 53.9056 17.8221 54.229C17.4772 54.229 17.1898 54.229 16.8449 54.229C16.5 54.229 16.5 54.714 16.8449 54.714Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M12.1878 45.9829C11.9003 45.9829 11.6704 45.9829 11.383 45.9829C11.268 46.0907 11.0956 46.1985 10.9806 46.3063C11.0381 46.3602 11.0956 46.414 11.2105 46.414C11.5554 46.414 11.8429 46.414 12.1878 46.414C12.1878 46.7374 12.1878 47.0069 12.1878 47.3302C12.1878 47.6536 12.7051 47.6536 12.7051 47.3302C12.7051 47.0069 12.7051 46.7374 12.7051 46.414C13.05 46.414 13.3375 46.414 13.6824 46.414C14.0273 46.414 14.0273 45.929 13.6824 45.929C13.3375 45.929 13.05 45.929 12.7051 45.929C12.7051 45.6056 12.7051 45.3362 12.7051 45.0128C12.5327 45.1206 12.3602 45.2284 12.1878 45.3362C12.1878 45.6056 12.1878 45.8212 12.1878 45.9829Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M18.6852 72.0132C18.6852 71.6898 18.6852 71.4204 18.6852 71.097C19.0301 71.097 19.3175 71.097 19.6624 71.097C20.0074 71.097 20.0074 70.612 19.6624 70.612C19.3175 70.612 19.0301 70.612 18.6852 70.612C18.6852 70.2886 18.6852 70.0191 18.6852 69.6958C18.6852 69.3724 18.1678 69.3724 18.1678 69.6958C18.1678 70.0191 18.1678 70.2886 18.1678 70.612C17.8229 70.612 17.5355 70.612 17.1906 70.612C16.8457 70.612 16.8457 71.097 17.1906 71.097C17.5355 71.097 17.8229 71.097 18.1678 71.097C18.1678 71.4204 18.1678 71.6898 18.1678 72.0132C18.1678 72.2827 18.6852 72.2827 18.6852 72.0132Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M19.374 104.781C19.374 104.458 19.374 104.188 19.374 103.865C19.7189 103.865 20.0064 103.865 20.3513 103.865C20.6962 103.865 20.6962 103.38 20.3513 103.38C20.0064 103.38 19.7189 103.38 19.374 103.38C19.374 103.057 19.374 102.787 19.374 102.464C19.374 102.14 18.8567 102.14 18.8567 102.464C18.8567 102.787 18.8567 103.057 18.8567 103.38C18.7417 103.38 18.5692 103.38 18.4543 103.38L18.5118 103.865C18.6267 103.865 18.7417 103.865 18.8567 103.865C18.8567 104.188 18.8567 104.458 18.8567 104.781C18.8567 105.051 19.374 105.051 19.374 104.781Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M14.0278 62.3664C13.6829 62.3664 13.3955 62.3664 13.0506 62.3664C13.0506 62.043 13.0506 61.7736 13.0506 61.4502C13.0506 61.1269 12.5332 61.1269 12.5332 61.4502C12.5332 61.7736 12.5332 62.043 12.5332 62.3664C12.1883 62.3664 11.9009 62.3664 11.556 62.3664C11.2111 62.3664 11.2111 62.8514 11.556 62.8514C11.9009 62.8514 12.1883 62.8514 12.5332 62.8514C12.5332 63.1748 12.5332 63.4443 12.5332 63.7676C12.5332 64.091 13.0506 64.091 13.0506 63.7676C13.0506 63.4443 13.0506 63.1748 13.0506 62.8514C13.3955 62.8514 13.6829 62.8514 14.0278 62.8514C14.3728 62.8514 14.3728 62.3664 14.0278 62.3664Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M28.284 54.714C28.6289 54.714 28.9164 54.714 29.2613 54.714C29.2613 55.0374 29.2613 55.3069 29.2613 55.6302C29.2613 55.9536 29.7786 55.9536 29.7786 55.6302C29.7786 55.3069 29.7786 55.0374 29.7786 54.714C30.1235 54.714 30.411 54.714 30.7559 54.714C31.1008 54.714 31.1008 54.229 30.7559 54.229C30.411 54.229 30.1235 54.229 29.7786 54.229C29.7786 53.9056 29.7786 53.6362 29.7786 53.3128C29.7786 52.9894 29.2613 52.9894 29.2613 53.3128C29.2613 53.6362 29.2613 53.9056 29.2613 54.229C28.9164 54.229 28.6289 54.229 28.284 54.229C27.9391 54.229 27.9391 54.714 28.284 54.714Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M40.3558 87.4811C40.7007 87.4811 40.9881 87.4811 41.333 87.4811C41.333 87.8044 41.333 88.0739 41.333 88.3973C41.333 88.7206 41.8504 88.7206 41.8504 88.3973C41.8504 88.0739 41.8504 87.8044 41.8504 87.4811C42.1953 87.4811 42.4827 87.4811 42.8276 87.4811C43.1725 87.4811 43.1725 86.996 42.8276 86.996C42.4827 86.996 42.1953 86.996 41.8504 86.996C41.8504 86.6727 41.8504 86.4032 41.8504 86.0798C41.8504 85.7565 41.333 85.7565 41.333 86.0798C41.333 86.4032 41.333 86.6727 41.333 86.996C40.9881 86.996 40.7007 86.996 40.3558 86.996C40.0109 86.996 40.0109 87.4811 40.3558 87.4811Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M42.195 104.781C42.195 104.458 42.195 104.188 42.195 103.865C42.54 103.865 42.8274 103.865 43.1723 103.865C43.5172 103.865 43.5172 103.38 43.1723 103.38C42.8274 103.38 42.54 103.38 42.195 103.38C42.195 103.057 42.195 102.787 42.195 102.464C42.195 102.14 41.6777 102.14 41.6777 102.464C41.6777 102.787 41.6777 103.057 41.6777 103.38C41.3328 103.38 41.0454 103.38 40.7005 103.38C40.3555 103.38 40.3555 103.865 40.7005 103.865C41.0454 103.865 41.3328 103.865 41.6777 103.865C41.6777 104.188 41.6777 104.458 41.6777 104.781C41.6777 105.051 42.195 105.051 42.195 104.781Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M18.5125 87.481C18.5125 87.8044 18.5125 88.0739 18.5125 88.3972C18.5125 88.7206 19.0299 88.7206 19.0299 88.3972C19.0299 88.0739 19.0299 87.8044 19.0299 87.481C19.3748 87.481 19.6622 87.481 20.0071 87.481C20.352 87.481 20.352 86.996 20.0071 86.996C19.6622 86.996 19.3748 86.996 19.0299 86.996C19.0299 86.6726 19.0299 86.4032 19.0299 86.0798C19.0299 85.7564 18.5125 85.7564 18.5125 86.0798C18.5125 86.4032 18.5125 86.6726 18.5125 86.996C18.1676 86.996 17.8802 86.996 17.5353 86.996C17.4778 86.996 17.4203 86.996 17.3628 87.0499V87.481C17.4203 87.481 17.4203 87.5349 17.4778 87.5349C17.8802 87.481 18.1676 87.481 18.5125 87.481Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M35.0101 45.0667C35.0101 45.39 35.0101 45.6595 35.0101 45.9829C34.6651 45.9829 34.3777 45.9829 34.0328 45.9829C33.6879 45.9829 33.6879 46.4679 34.0328 46.4679C34.3777 46.4679 34.6651 46.4679 35.0101 46.4679C35.0101 46.7913 35.0101 47.0607 35.0101 47.3841C35.0101 47.7075 35.5274 47.7075 35.5274 47.3841C35.5274 47.0607 35.5274 46.7913 35.5274 46.4679C35.8723 46.4679 36.1597 46.4679 36.5046 46.4679C36.8496 46.4679 36.8496 45.9829 36.5046 45.9829C36.1597 45.9829 35.8723 45.9829 35.5274 45.9829C35.5274 45.6595 35.5274 45.39 35.5274 45.0667C35.5274 44.7972 35.0101 44.7972 35.0101 45.0667Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M40.6434 53.3128C40.6434 53.6362 40.6434 53.9056 40.6434 54.229C40.2985 54.229 40.011 54.229 39.6661 54.229C39.3212 54.229 39.3212 54.714 39.6661 54.714C40.011 54.714 40.2985 54.714 40.6434 54.714C40.6434 55.0374 40.6434 55.3069 40.6434 55.6302C40.6434 55.9536 41.1607 55.9536 41.1607 55.6302C41.1607 55.3069 41.1607 55.0374 41.1607 54.714C41.5056 54.714 41.7931 54.714 42.138 54.714C42.4829 54.714 42.4829 54.229 42.138 54.229C41.7931 54.229 41.5056 54.229 41.1607 54.229C41.1607 53.9056 41.1607 53.6362 41.1607 53.3128C41.1607 52.9894 40.6434 52.9894 40.6434 53.3128Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M40.9881 69.6958C40.9881 70.0191 40.9881 70.2886 40.9881 70.612C40.6432 70.612 40.3558 70.612 40.0109 70.612C39.666 70.612 39.666 71.097 40.0109 71.097C40.3558 71.097 40.6432 71.097 40.9881 71.097C40.9881 71.4204 40.9881 71.6898 40.9881 72.0132C40.9881 72.3366 41.5055 72.3366 41.5055 72.0132C41.5055 71.6898 41.5055 71.4204 41.5055 71.097C41.8504 71.097 42.1378 71.097 42.4827 71.097C42.8276 71.097 42.8276 70.612 42.4827 70.612C42.1378 70.612 41.8504 70.612 41.5055 70.612C41.5055 70.2886 41.5055 70.0191 41.5055 69.6958C41.5055 69.3724 40.9881 69.3724 40.9881 69.6958Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M45.415 46.468C45.7599 46.468 46.0473 46.468 46.3923 46.468C46.3923 46.7913 46.3923 47.0608 46.3923 47.3842C46.3923 47.7075 46.9096 47.7075 46.9096 47.3842C46.9096 47.0608 46.9096 46.7913 46.9096 46.468C47.2545 46.468 47.5419 46.468 47.8868 46.468C48.2318 46.468 48.2318 45.9829 47.8868 45.9829C47.5419 45.9829 47.2545 45.9829 46.9096 45.9829C46.9096 45.6596 46.9096 45.3901 46.9096 45.0667C46.9096 44.7434 46.3923 44.7434 46.3923 45.0667C46.3923 45.3901 46.3923 45.6596 46.3923 45.9829C46.0473 45.9829 45.7599 45.9829 45.415 45.9829C45.1276 45.9829 45.1276 46.468 45.415 46.468Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M46.1045 79.2355C46.4494 79.2355 46.7368 79.2355 47.0817 79.2355C47.0817 79.5589 47.0817 79.8283 47.0817 80.1517C47.0817 80.475 47.5991 80.475 47.5991 80.1517C47.5991 79.8283 47.5991 79.5589 47.5991 79.2355C47.944 79.2355 48.2314 79.2355 48.5763 79.2355C48.9212 79.2355 48.9212 78.7504 48.5763 78.7504C48.2314 78.7504 47.944 78.7504 47.5991 78.7504C47.5991 78.4271 47.5991 78.1576 47.5991 77.8343C47.5991 77.5109 47.0817 77.5109 47.0817 77.8343C47.0817 78.1576 47.0817 78.4271 47.0817 78.7504C46.7368 78.7504 46.4494 78.7504 46.1045 78.7504C45.7596 78.7504 45.7596 79.2355 46.1045 79.2355Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M71.7995 95.134C71.4546 95.134 71.1672 95.134 70.8223 95.134C70.8223 94.8107 70.8223 94.5412 70.8223 94.2178C70.8223 93.8945 70.3049 93.8945 70.3049 94.2178C70.3049 94.5412 70.3049 94.8107 70.3049 95.134C69.96 95.134 69.6726 95.134 69.3276 95.134C68.9827 95.134 68.9827 95.6191 69.3276 95.6191C69.6726 95.6191 69.96 95.6191 70.3049 95.6191C70.3049 95.9424 70.3049 96.2119 70.3049 96.5353C70.3049 96.8586 70.8223 96.8586 70.8223 96.5353C70.8223 96.2119 70.8223 95.9424 70.8223 95.6191C71.1672 95.6191 71.4546 95.6191 71.7995 95.6191C72.0869 95.6191 72.0869 95.134 71.7995 95.134Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M46.7369 61.4502C46.7369 61.7735 46.7369 62.043 46.7369 62.3664C46.392 62.3664 46.1046 62.3664 45.7597 62.3664C45.4148 62.3664 45.4148 62.8514 45.7597 62.8514C46.1046 62.8514 46.392 62.8514 46.7369 62.8514C46.7369 63.1748 46.7369 63.4442 46.7369 63.7676C46.7369 64.091 47.2543 64.091 47.2543 63.7676C47.2543 63.4442 47.2543 63.1748 47.2543 62.8514C47.5992 62.8514 47.8866 62.8514 48.2315 62.8514C48.5764 62.8514 48.5764 62.3664 48.2315 62.3664C47.8866 62.3664 47.5992 62.3664 47.2543 62.3664C47.2543 62.043 47.2543 61.7735 47.2543 61.4502C47.2543 61.1807 46.7369 61.1807 46.7369 61.4502Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M47.9439 96.5353C47.9439 96.2119 47.9439 95.9424 47.9439 95.6191C48.2888 95.6191 48.5762 95.6191 48.9211 95.6191C49.266 95.6191 49.266 95.134 48.9211 95.134C48.5762 95.134 48.2888 95.134 47.9439 95.134C47.9439 94.8107 47.9439 94.5412 47.9439 94.2178C47.9439 93.8945 47.4265 93.8945 47.4265 94.2178C47.4265 94.5412 47.4265 94.8107 47.4265 95.134C47.0816 95.134 46.7942 95.134 46.4493 95.134C46.1044 95.134 46.1044 95.6191 46.4493 95.6191C46.7942 95.6191 47.0816 95.6191 47.4265 95.6191C47.4265 95.9424 47.4265 96.2119 47.4265 96.5353C47.4265 96.8586 47.9439 96.8586 47.9439 96.5353Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M51.7949 87.481C52.1398 87.481 52.4272 87.481 52.7721 87.481C52.7721 87.8044 52.7721 88.0739 52.7721 88.3972C52.7721 88.7206 53.2895 88.7206 53.2895 88.3972C53.2895 88.0739 53.2895 87.8044 53.2895 87.481C53.6344 87.481 53.9218 87.481 54.2667 87.481C54.6116 87.481 54.6116 86.996 54.2667 86.996C53.9218 86.996 53.6344 86.996 53.2895 86.996C53.2895 86.6726 53.2895 86.4032 53.2895 86.0798C53.2895 85.7564 52.7721 85.7564 52.7721 86.0798C52.7721 86.4032 52.7721 86.6726 52.7721 86.996C52.4272 86.996 52.1398 86.996 51.7949 86.996C51.45 86.996 51.45 87.481 51.7949 87.481Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M73.9269 54.7138C74.2718 54.7138 74.5593 54.7138 74.9042 54.7138C74.9042 55.0371 74.9042 55.3066 74.9042 55.63C74.9042 55.9533 75.4215 55.9533 75.4215 55.63C75.4215 55.3066 75.4215 55.0371 75.4215 54.7138L74.9042 53.8515C74.9042 53.9593 74.9042 54.067 74.9042 54.1748C74.5593 54.1748 74.2718 54.1748 73.9269 54.1748C73.582 54.2287 73.582 54.7138 73.9269 54.7138Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M80.0205 62.8518C80.1355 62.8518 80.3079 62.8518 80.4229 62.8518L80.1355 62.3668C80.078 62.3668 80.078 62.3668 80.0205 62.3668C79.6756 62.3668 79.6756 62.8518 80.0205 62.8518Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M8.27907 70.612C7.93416 70.612 7.64674 70.612 7.30183 70.612C7.30183 70.2886 7.30183 70.0191 7.30183 69.6958C7.30183 69.3724 6.78447 69.3724 6.78447 69.6958C6.78447 70.0191 6.78447 70.2886 6.78447 70.612C6.43956 70.612 6.15214 70.612 5.80723 70.612C5.46233 70.612 5.46233 71.097 5.80723 71.097C6.15214 71.097 6.43956 71.097 6.78447 71.097C6.78447 71.4204 6.78447 71.6898 6.78447 72.0132C6.78447 72.3366 7.30183 72.3366 7.30183 72.0132C7.30183 71.6898 7.30183 71.4204 7.30183 71.097C7.64674 71.097 7.93416 71.097 8.27907 71.097C8.56649 71.097 8.56649 70.612 8.27907 70.612Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M6.38263 54.714C6.38263 55.0374 6.38263 55.3069 6.38263 55.6302C6.38263 55.9536 6.89999 55.9536 6.89999 55.6302C6.89999 55.3069 6.89999 55.0374 6.89999 54.714C7.24489 54.714 7.53232 54.714 7.87722 54.714C8.22213 54.714 8.22213 54.229 7.87722 54.229C7.53232 54.229 7.24489 54.229 6.89999 54.229C6.89999 53.9056 6.89999 53.6362 6.89999 53.3128C6.89999 52.9894 6.38263 52.9894 6.38263 53.3128C6.38263 53.6362 6.38263 53.9056 6.38263 54.229C6.26766 54.229 6.15269 54.229 5.98023 54.229C5.92275 54.3907 5.92275 54.5524 5.86526 54.714C6.0952 54.714 6.26766 54.714 6.38263 54.714Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M80.9975 63.7678C80.9975 63.8755 81.055 63.9294 81.1125 63.9833L80.9975 63.7678Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M69.6166 61.4502C69.6166 61.7735 69.6166 62.043 69.6166 62.3664C69.2717 62.3664 68.9843 62.3664 68.6394 62.3664C68.2945 62.3664 68.2945 62.8514 68.6394 62.8514C68.9843 62.8514 69.2717 62.8514 69.6166 62.8514C69.6166 63.1748 69.6166 63.4442 69.6166 63.7676C69.6166 64.091 70.134 64.091 70.134 63.7676C70.134 63.4442 70.134 63.1748 70.134 62.8514C70.4789 62.8514 70.7663 62.8514 71.1112 62.8514C71.4561 62.8514 71.4561 62.3664 71.1112 62.3664C70.7663 62.3664 70.4789 62.3664 70.134 62.3664C70.134 62.043 70.134 61.7735 70.134 61.4502C70.134 61.1807 69.6166 61.1807 69.6166 61.4502Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M75.7663 72.0132C75.7663 71.6898 75.7663 71.4204 75.7663 71.097C76.1112 71.097 76.3986 71.097 76.7435 71.097C77.0885 71.097 77.0885 70.612 76.7435 70.612C76.3986 70.612 76.1112 70.612 75.7663 70.612C75.7663 70.2886 75.7663 70.0191 75.7663 69.6958C75.7663 69.3724 75.2489 69.3724 75.2489 69.6958C75.2489 70.0191 75.2489 70.2886 75.2489 70.612C74.904 70.612 74.6166 70.612 74.2717 70.612C73.9268 70.612 73.9268 71.097 74.2717 71.097C74.6166 71.097 74.904 71.097 75.2489 71.097C75.2489 71.4204 75.2489 71.6898 75.2489 72.0132C75.2489 72.2827 75.7663 72.2827 75.7663 72.0132Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M62.8325 71.097C63.1774 71.097 63.4649 71.097 63.8098 71.097C63.8098 71.4204 63.8098 71.6898 63.8098 72.0132C63.8098 72.3366 64.3271 72.3366 64.3271 72.0132C64.3271 71.6898 64.3271 71.4204 64.3271 71.097C64.672 71.097 64.9595 71.097 65.3044 71.097C65.6493 71.097 65.6493 70.612 65.3044 70.612C64.9595 70.612 64.672 70.612 64.3271 70.612C64.3271 70.2886 64.3271 70.0191 64.3271 69.6958C64.3271 69.3724 63.8098 69.3724 63.8098 69.6958C63.8098 70.0191 63.8098 70.2886 63.8098 70.612C63.4649 70.612 63.1774 70.612 62.8325 70.612C62.4876 70.612 62.4876 71.097 62.8325 71.097Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M63.465 53.3128C63.465 53.6362 63.465 53.9056 63.465 54.229C63.1201 54.229 62.8326 54.229 62.4877 54.229C62.1428 54.229 62.1428 54.714 62.4877 54.714C62.8326 54.714 63.1201 54.714 63.465 54.714C63.465 55.0374 63.465 55.3069 63.465 55.6302C63.465 55.9536 63.9823 55.9536 63.9823 55.6302C63.9823 55.3069 63.9823 55.0374 63.9823 54.714C64.3272 54.714 64.6147 54.714 64.9596 54.714C65.3045 54.714 65.3045 54.229 64.9596 54.229C64.6147 54.229 64.3272 54.229 63.9823 54.229C63.9823 53.9056 63.9823 53.6362 63.9823 53.3128C63.9823 52.9894 63.465 52.9894 63.465 53.3128Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M51.4501 71.097C51.795 71.097 52.0825 71.097 52.4274 71.097C52.4274 71.4204 52.4274 71.6898 52.4274 72.0132C52.4274 72.3366 52.9447 72.3366 52.9447 72.0132C52.9447 71.6898 52.9447 71.4204 52.9447 71.097C53.2896 71.097 53.5771 71.097 53.922 71.097C54.2669 71.097 54.2669 70.612 53.922 70.612C53.5771 70.612 53.2896 70.612 52.9447 70.612C52.9447 70.2886 52.9447 70.0191 52.9447 69.6958C52.9447 69.3724 52.4274 69.3724 52.4274 69.6958C52.4274 70.0191 52.4274 70.2886 52.4274 70.612C52.0825 70.612 51.795 70.612 51.4501 70.612C51.1052 70.612 51.1052 71.097 51.4501 71.097Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M63.1783 87.481C63.5232 87.481 63.8106 87.481 64.1555 87.481C64.1555 87.8044 64.1555 88.0739 64.1555 88.3972C64.1555 88.7206 64.6729 88.7206 64.6729 88.3972C64.6729 88.0739 64.6729 87.8044 64.6729 87.481C65.0178 87.481 65.3052 87.481 65.6501 87.481C65.995 87.481 65.995 86.996 65.6501 86.996C65.3052 86.996 65.0178 86.996 64.6729 86.996C64.6729 86.6726 64.6729 86.4032 64.6729 86.0798C64.6729 85.7564 64.1555 85.7564 64.1555 86.0798C64.1555 86.4032 64.1555 86.6726 64.1555 86.996C63.8106 86.996 63.5232 86.996 63.1783 86.996C62.8334 86.996 62.8334 87.481 63.1783 87.481Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M68.2936 45.9827C67.9487 45.9827 67.9487 46.4678 68.2936 46.4678C68.6385 46.4678 68.9259 46.4678 69.2709 46.4678C69.2709 46.7911 69.2709 47.0606 69.2709 47.384C69.2709 47.7073 69.7882 47.7073 69.7882 47.384C69.7882 47.1145 69.7882 46.845 69.7882 46.5756C69.5583 46.36 69.3283 46.1444 69.0984 45.9827C68.811 45.9827 68.5235 45.9827 68.2936 45.9827Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M65.0176 104.781C65.0176 104.458 65.0176 104.188 65.0176 103.865C65.3626 103.865 65.65 103.865 65.9949 103.865C66.3398 103.865 66.3398 103.38 65.9949 103.38C65.65 103.38 65.3626 103.38 65.0176 103.38C65.0176 103.057 65.0176 102.787 65.0176 102.464C65.0176 102.14 64.5003 102.14 64.5003 102.464C64.5003 102.787 64.5003 103.057 64.5003 103.38C64.1554 103.38 63.868 103.38 63.5231 103.38C63.1781 103.38 63.1781 103.865 63.5231 103.865C63.868 103.865 64.1554 103.865 64.5003 103.865C64.5003 104.188 64.5003 104.458 64.5003 104.781C64.5003 105.051 65.0176 105.051 65.0176 104.781Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M53.6343 104.781C53.6343 104.458 53.6343 104.188 53.6343 103.865C53.9792 103.865 54.2666 103.865 54.6115 103.865C54.9564 103.865 54.9564 103.38 54.6115 103.38C54.2666 103.38 53.9792 103.38 53.6343 103.38C53.6343 103.057 53.6343 102.787 53.6343 102.464C53.6343 102.14 53.1169 102.14 53.1169 102.464C53.1169 102.787 53.1169 103.057 53.1169 103.38C52.772 103.38 52.4846 103.38 52.1397 103.38C51.7948 103.38 51.7948 103.865 52.1397 103.865C52.4846 103.865 52.772 103.865 53.1169 103.865C53.1169 104.188 53.1169 104.458 53.1169 104.781C53.1169 105.051 53.6343 105.051 53.6343 104.781Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M52.0838 53.3128C52.0838 53.6362 52.0838 53.9056 52.0838 54.229C51.7389 54.229 51.4515 54.229 51.1066 54.229C50.7617 54.229 50.7617 54.714 51.1066 54.714C51.4515 54.714 51.7389 54.714 52.0838 54.714C52.0838 55.0374 52.0838 55.3069 52.0838 55.6302C52.0838 55.9536 52.6012 55.9536 52.6012 55.6302C52.6012 55.3069 52.6012 55.0374 52.6012 54.714C52.9461 54.714 53.2335 54.714 53.5784 54.714C53.9234 54.714 53.9234 54.229 53.5784 54.229C53.2335 54.229 52.9461 54.229 52.6012 54.229C52.6012 53.9056 52.6012 53.6362 52.6012 53.3128C52.6012 52.9894 52.0838 52.9894 52.0838 53.3128Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M58.1761 61.4502C58.1761 61.7735 58.1761 62.043 58.1761 62.3664C57.8312 62.3664 57.5438 62.3664 57.1989 62.3664C56.854 62.3664 56.854 62.8514 57.1989 62.8514C57.5438 62.8514 57.8312 62.8514 58.1761 62.8514C58.1761 63.1748 58.1761 63.4442 58.1761 63.7676C58.1761 64.091 58.6935 64.091 58.6935 63.7676C58.6935 63.4442 58.6935 63.1748 58.6935 62.8514C59.0384 62.8514 59.3258 62.8514 59.6707 62.8514C60.0156 62.8514 60.0156 62.3664 59.6707 62.3664C59.3258 62.3664 59.0384 62.3664 58.6935 62.3664C58.6935 62.043 58.6935 61.7735 58.6935 61.4502C58.6935 61.1807 58.1761 61.1807 58.1761 61.4502Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M57.5437 79.2355C57.8886 79.2355 58.176 79.2355 58.5209 79.2355C58.5209 79.5589 58.5209 79.8283 58.5209 80.1517C58.5209 80.475 59.0383 80.475 59.0383 80.1517C59.0383 79.8283 59.0383 79.5589 59.0383 79.2355C59.3832 79.2355 59.6706 79.2355 60.0155 79.2355C60.3604 79.2355 60.3604 78.7504 60.0155 78.7504C59.6706 78.7504 59.3832 78.7504 59.0383 78.7504C59.0383 78.4271 59.0383 78.1576 59.0383 77.8343C59.0383 77.5109 58.5209 77.5109 58.5209 77.8343C58.5209 78.1576 58.5209 78.4271 58.5209 78.7504C58.176 78.7504 57.8886 78.7504 57.5437 78.7504C57.1987 78.7504 57.1987 79.2355 57.5437 79.2355Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M59.383 96.5353C59.383 96.2119 59.383 95.9424 59.383 95.6191C59.728 95.6191 60.0154 95.6191 60.3603 95.6191C60.7052 95.6191 60.7052 95.134 60.3603 95.134C60.0154 95.134 59.728 95.134 59.383 95.134C59.383 94.8107 59.383 94.5412 59.383 94.2178C59.383 93.8945 58.8657 93.8945 58.8657 94.2178C58.8657 94.5412 58.8657 94.8107 58.8657 95.134C58.5208 95.134 58.2334 95.134 57.8885 95.134C57.5435 95.134 57.5435 95.6191 57.8885 95.6191C58.2334 95.6191 58.5208 95.6191 58.8657 95.6191C58.8657 95.9424 58.8657 96.2119 58.8657 96.5353C58.8657 96.8586 59.383 96.8586 59.383 96.5353Z"
                              fill="#FAC53D"
                            />
                            <path
                              d="M60.0168 72.9834L67.8347 108.499H72.4334L73.2957 92.2234C73.2382 92.2773 64.7305 77.6183 60.0168 72.9834Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M59.5554 22.3774C56.6236 18.9282 56.5087 16.5569 56.5087 16.5569L56.2213 23.8865L58.9805 23.6709C59.6128 23.617 59.9577 22.8625 59.5554 22.3774Z"
                              fill="white"
                            />
                            <path
                              d="M50.8188 33.8025H50.5889C40.4716 33.5331 32.1364 26.4191 30.7568 16.9338L30.1819 12.8379C30.0095 11.6522 30.4119 10.4127 31.2741 9.55039C32.1364 8.6342 33.3436 8.09526 34.6082 8.09526L51.9111 7.87969C53.4056 7.7719 54.7853 8.25694 55.8775 9.33482C57.4296 10.9516 58.1769 13.7541 57.717 16.1254C57.1996 18.4967 57.8894 19.5746 59.9589 22.1076C60.4188 22.7543 60.2463 23.2394 60.0739 23.5089C59.7864 23.94 59.2116 24.2095 58.6367 24.2634C58.5793 26.6886 57.6595 29.8144 55.5901 31.8624C54.2679 33.1019 52.6009 33.8025 50.8188 33.8025ZM52.3709 8.79588C52.256 8.79588 52.141 8.79588 51.9685 8.79588L34.6082 9.01145C33.631 9.01145 32.7112 9.4426 32.0789 10.1432C31.4466 10.8438 31.1592 11.76 31.2741 12.6762L31.849 16.7721C33.1711 25.8263 41.0465 32.563 50.6464 32.7786H50.8188C52.3134 32.7786 53.6356 32.2396 54.7853 31.1079C56.7972 29.1138 57.6595 25.988 57.5445 23.7783V23.1855L58.1769 23.2394C58.6367 23.2933 59.0966 23.1316 59.2116 22.9699C59.2691 22.916 59.2116 22.7543 59.0966 22.7005C57.0847 20.2752 56.0499 18.8201 56.6823 15.9637C57.0272 14.1853 56.6248 11.5445 55.1302 10.0354C54.3254 9.17314 53.4056 8.79588 52.3709 8.79588Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M18.7997 108.553L19.0296 117.23H109.855L101.232 112.272C97.036 109.847 92.2648 108.607 87.3212 108.607H18.7997V108.553Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M111.752 117.661H18.5117L18.2243 108.014H87.3206C92.3218 108.014 97.2654 109.308 101.519 111.787L111.752 117.661ZM19.5464 116.691H107.958L100.944 112.649C96.863 110.278 92.1493 109.038 87.3206 109.038H19.3739L19.5464 116.691Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M53.3467 1.30488C49.7826 1.25099 46.2186 0.388686 42.4246 0.981517C37.4235 1.73603 31.8475 3.46063 28.6283 7.34097C24.5469 12.2453 26.7313 19.7365 28.6858 25.072C29.2032 26.4732 29.778 27.8206 30.4678 29.1679C31.1576 30.5153 32.0199 31.9704 33.5145 32.5632C36.6187 33.9106 43.4593 31.8626 45.2413 29.0601C45.9312 27.9823 45.8162 26.6888 45.7587 25.4492C45.6437 24.048 45.7587 22.6468 46.0461 21.2455C46.1036 21.0839 46.1036 20.9222 46.2761 20.8144C46.621 20.6527 47.2533 20.8144 47.5982 20.9761C48.1731 21.1917 48.6329 21.5689 48.9778 22.054C49.2078 22.3234 49.2653 22.6468 49.3802 22.9701C49.4377 23.1318 49.4952 23.2935 49.6676 23.3474C50.07 23.563 51.5072 23.5091 51.7946 23.1318C52.1395 22.7007 51.6796 21.6767 51.6221 21.1917C51.5646 20.5449 51.5072 19.8982 51.5072 19.2515C51.5072 18.0119 51.6796 16.5568 52.6568 15.6406C53.1742 15.1556 53.8065 14.9939 54.5538 14.8861C55.9909 14.6166 57.543 14.1855 58.8652 13.4849C60.1873 12.7303 61.1071 11.5447 62.0268 10.4129C63.2915 8.7961 63.0041 6.64035 62.6017 4.75408C62.4867 4.32293 60.8196 -0.25804 60.2448 0.0114286C57.773 1.0893 55.5311 1.35878 53.3467 1.30488Z"
                              fill="#EF9E8F"
                            />
                            <path
                              d="M48.204 24.8508C49.78 24.0169 50.0963 21.7439 48.9104 19.7738C47.7245 17.8037 45.4855 16.8826 43.9095 17.7165C42.3335 18.5504 42.0172 20.8234 43.2032 22.7935C44.3891 24.7636 46.628 25.6847 48.204 24.8508Z"
                              fill="white"
                            />
                            <path
                              d="M67.8906 85.1099L58.6356 72.4988C58.4632 72.2832 58.5207 71.9598 58.7506 71.7982C58.9805 71.6365 59.3255 71.6904 59.4979 71.906L67.7182 83.1158C70.305 78.8582 75.3061 75.2474 80.8246 73.7922C81.1121 73.7383 81.3995 73.9 81.457 74.1156C81.5145 74.3851 81.342 74.6545 81.1121 74.7084C75.5936 76.1636 70.5349 79.8822 68.2356 84.2476L67.8906 85.1099Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M87.0918 74.2231C86.9193 74.2231 86.7469 74.1153 86.6319 73.9536L71.2835 49.0547C71.1111 48.8392 71.226 48.5158 71.456 48.3541C71.6859 48.1924 72.0308 48.3002 72.2033 48.5158L87.5516 73.4147C87.7241 73.6303 87.6091 73.9536 87.3792 74.1153C87.2642 74.1692 87.2067 74.2231 87.0918 74.2231Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M72.8923 109.038H18.2821L16.7875 86.8882C16.4426 81.7683 15.6953 76.5945 14.4881 71.5824C14.4306 71.3129 14.603 71.0434 14.8905 70.9895C15.1779 70.9356 15.4653 71.0973 15.5228 71.3668C16.73 76.4328 17.5348 81.6605 17.8797 86.8343L19.2593 108.068H71.8001L72.4324 92.2776C72.4324 92.0081 72.7199 91.7925 73.0073 91.7925C73.2947 91.7925 73.5247 92.062 73.5247 92.3314L72.8923 109.038Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M71.7429 49.3242C71.5704 49.3242 71.398 49.2703 71.283 49.1086C68.5813 45.3899 64.1549 43.1264 59.3837 42.9647C59.0963 42.9647 58.7514 42.9647 58.464 43.0186L43.9779 44.6354C41.2186 44.9588 38.4019 44.851 35.6426 44.4198C35.4127 44.366 35.2402 44.3121 35.0103 44.3121L30.8139 43.2342C30.1241 43.0725 29.4918 43.0186 28.8595 43.0186C28.572 43.0186 28.2846 42.8569 28.2846 42.5336C28.2846 42.2641 28.4571 41.9946 28.802 41.9946C29.5493 41.9407 30.3541 41.9946 31.1588 42.2102L35.3552 43.2881C35.5277 43.342 35.7001 43.3959 35.8726 43.3959C38.5169 43.827 41.2186 43.9348 43.8629 43.6114L58.349 41.9946C58.6939 41.9407 59.0963 41.9407 59.4412 41.9407C64.5573 42.1024 69.3285 44.5815 72.2028 48.5158C72.3752 48.7313 72.3177 49.0547 72.0878 49.2164C71.9153 49.2703 71.8004 49.3242 71.7429 49.3242Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M87.3207 74.4923C87.0907 74.4923 86.8608 74.3846 86.7458 74.169L82.8369 67.8095C82.6644 67.594 82.7794 67.2706 83.0093 67.1089C83.2393 66.9472 83.5842 67.055 83.7566 67.2706L87.1482 72.7678L89.6776 63.498C89.735 63.2286 90.0225 63.0669 90.3099 63.1208C90.5973 63.1747 90.7698 63.4441 90.7123 63.7136L87.8955 73.9534C87.838 74.169 87.6081 74.3846 87.3782 74.3846C87.3782 74.4924 87.3782 74.4923 87.3207 74.4923Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M87.2052 103.218C83.0664 103.218 79.2724 101.278 76.973 97.8825L63.7516 79.4509C63.5791 79.2353 63.6366 78.912 63.924 78.7503C64.154 78.5886 64.4989 78.6425 64.6713 78.912L77.8928 97.3436C80.3646 101.008 84.7334 102.787 89.2747 102.032C93.816 101.278 97.265 98.2059 98.2423 93.9483L103.876 69.1033C103.933 68.8338 104.221 68.6722 104.508 68.7261C104.795 68.7799 104.968 69.0494 104.91 69.3189L99.277 94.1639C98.1848 98.7987 94.3908 102.194 89.4471 103.002C88.6998 103.164 87.9525 103.218 87.2052 103.218Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M87.0914 78.9118C86.9764 78.9118 86.9189 78.9118 86.8039 78.8579C86.574 78.6962 86.459 78.4268 86.6315 78.1573C87.9536 76.2171 86.9189 74.3848 86.8614 74.3309C86.7464 74.0614 86.8039 73.7919 87.0914 73.6302C87.3788 73.5224 87.6662 73.5763 87.8386 73.8458C87.8961 73.9536 89.2183 76.2171 87.5512 78.6963C87.4363 78.8579 87.2638 78.9118 87.0914 78.9118Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M113.706 54.0667C113.591 54.0667 113.534 54.0667 113.419 54.0128C113.189 53.8511 113.074 53.5278 113.247 53.3122C113.419 53.0427 113.764 51.9649 114.051 50.9948C114.224 50.348 114.454 49.7013 114.684 49.1085C114.856 48.6234 114.569 48.1384 114.051 47.9228L112.499 47.3839C112.212 47.2761 112.097 47.0066 112.154 46.7372C112.269 46.4677 112.557 46.3599 112.844 46.4138L114.396 46.9527C115.431 47.33 116.006 48.354 115.661 49.378C115.431 49.9708 115.258 50.6714 115.086 51.2642C114.684 52.6116 114.396 53.4739 114.166 53.8511C114.051 53.9589 113.879 54.0667 113.706 54.0667Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M114.396 57.5697C114.281 57.5697 114.109 57.5158 113.994 57.4081C113.764 57.1925 113.764 56.8691 113.994 56.7074C114.511 56.2224 115.029 55.2523 115.489 53.905C115.604 53.4738 115.431 53.0427 115.086 52.8271L114.109 52.1804C113.879 52.0187 113.822 51.6953 113.994 51.4798C114.166 51.2642 114.511 51.2103 114.741 51.372L115.719 52.0187C116.466 52.5037 116.811 53.4199 116.523 54.2283C116.178 55.3062 115.604 56.7074 114.799 57.4619C114.684 57.5158 114.511 57.5697 114.396 57.5697Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M113.905 35.5117C115.285 34.131 115.285 31.8924 113.905 30.5117C112.524 29.131 110.285 29.131 108.905 30.5117C107.524 31.8924 107.524 34.131 108.905 35.5117C110.285 36.8924 112.524 36.8924 113.905 35.5117Z"
                              fill="#E3828D"
                            />
                            <path
                              d="M110.936 34.4631L109.702 33.2292L110.414 32.5173L110.936 33.0394L112.407 31.5919L113.095 32.28L110.936 34.4631Z"
                              fill="white"
                            />
                            <path
                              d="M90.2528 64.1451C90.1953 64.1451 90.1953 64.1451 90.1378 64.1451C89.8504 64.0912 89.6779 63.8217 89.7354 63.5522C90.0803 61.9354 90.7701 60.3186 90.8851 60.0491C91.0001 59.6719 91.8623 56.7077 93.2994 54.1747C93.2994 54.1208 94.3917 52.504 95.024 51.0489L98.1281 44.0966C98.8754 42.2103 99.9102 42.1025 100.37 42.2103C101.52 42.372 102.267 43.8271 102.325 45.8751V45.929L101.52 51.2106C101.52 51.4261 101.635 51.6417 101.865 51.8034C102.21 52.0729 102.727 52.1268 103.244 52.019C103.532 51.9651 103.819 51.8573 104.107 51.6956C104.681 51.4261 105.141 50.9411 105.486 50.4022L106.233 48.8392C106.981 47.2763 107.556 45.6595 108.015 43.9888L110.142 36.3898C110.487 35.258 111.177 34.6113 111.924 34.6113C112.442 34.6113 113.419 34.9347 113.994 36.9287C114.569 38.9228 113.534 43.6115 112.614 47.7614C112.327 49.0548 112.039 50.2944 111.867 51.2644C111.809 51.5339 111.522 51.6956 111.235 51.6956C110.947 51.6417 110.775 51.3722 110.775 51.1028C110.947 50.0788 111.235 48.8392 111.522 47.5458C112.384 43.7193 113.419 38.9767 112.902 37.1982C112.557 35.9586 112.039 35.6353 111.867 35.6353C111.637 35.6353 111.292 36.0125 111.12 36.6593L109.05 44.2583C108.59 45.9829 107.958 47.6536 107.211 49.2704L106.463 50.8872C106.004 51.6417 105.371 52.2345 104.624 52.6118C104.222 52.8274 103.877 52.9352 103.474 53.0429C102.669 53.2046 101.75 53.0429 101.175 52.6118C100.715 52.2884 100.428 51.7495 100.428 51.2106V51.1567V51.1028L101.175 45.8751C101.117 44.1505 100.542 43.2343 100.14 43.1804C99.8527 43.1265 99.4503 43.5038 99.0479 44.4739L95.9437 51.4261C95.2539 52.9352 94.1617 54.552 94.1617 54.6598C92.7246 57.1389 91.8623 60.2647 91.8623 60.3186V60.3725C91.8623 60.3725 91.115 62.0432 90.7701 63.66C90.7126 63.9834 90.4827 64.1451 90.2528 64.1451Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M104.394 69.7497C104.222 69.7497 104.107 69.6958 103.992 69.588C103.819 69.3724 103.819 69.0491 104.049 68.8874L111.924 62.3123L115.201 58.8631C115.546 58.5398 115.546 58.0009 115.316 57.6236L114.856 56.923C114.684 56.7074 114.741 56.384 115.029 56.2224C115.259 56.0607 115.603 56.1146 115.776 56.384L116.236 57.0847C116.753 57.8392 116.696 58.8632 116.063 59.5099L112.729 63.013L104.796 69.6419C104.681 69.6958 104.509 69.7497 104.394 69.7497Z"
                              fill="#4A154B"
                            />
                            <path
                              d="M2.24384 77.5645L5.17555 55.7914C6.15279 48.5158 12.2461 42.8569 19.9491 41.9407H20.0066H20.064L28.9741 41.9946C29.2616 41.9946 29.4915 42.2102 29.4915 42.4797C29.4915 42.7491 29.2616 42.9647 28.9741 42.9647L20.1215 42.9108C12.8785 43.7731 7.18751 49.1086 6.26776 55.8992L3.5085 76.2171C6.15279 75.6782 9.60186 75.5165 13.1659 75.786C13.4533 75.786 13.6833 76.0554 13.6833 76.3249C13.6833 76.5944 13.3958 76.81 13.1084 76.81C9.31444 76.5405 5.63543 76.7561 3.04863 77.4028L2.24384 77.5645Z"
                              fill="#4A154B"
                            />
                          </svg>
                        </div>
                        <h4 className="mb-2">Select a template</h4>
                        <p className="h6 text-gray-300">
                          Lorem ipsum dolor sit amet, consectetuer adipiscing
                          elit. Aenean commodo{" "}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="modalDialog"
        size="sm"
        backdrop="static"
        keyboard={false}
        centered
        show={moduleCreated}
        onHide={() => setModuleCreated(false)}
      >
        <ModuleCreatedModal handleModal={setModuleCreated} />
      </Modal>
    </>
  );
};

export default FtueDashboard;
