import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  upgradeStorageAsync,
  getSubscriptionAsync,
  getStorageAsync,
  getProrationalInvoiceAsync,
  getCustomerInvoicesAsync,
} from "../../../reducers/subscription-reducers";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import ToasterAlertError from "../../../helper/ToasterAlertError";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";
import {
  getProratedData,
} from "../../../common";

const AddSeatsModal = ({handleModal, basePlan}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData, isUpdateLoading, storageData } = useSelector(
    (state) => state.subscription
  );

  const [isLoader, setIsLoader] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [planType, setPlanType] = useState("");
  const [dueNextCycle, setDueNextCycle] = useState("");
  const [storageDatas, setStorageDatas] = useState({});
  const [calcSeatsPrice, setCalcSeatsPrice] = useState("");
  const [calcSeatsPriceTotal, setCalcSeatsPriceTotal] = useState("");
  const [totalSeats, setTotalSeats] = useState(0);
  const [seatsValue, setSeatsValue] = useState(1);
  const [proDays, setProDays] = useState('');
  const [proAmount, setProAmount] = useState(0);

  useEffect(() => {
    dispatch(getSubscriptionAsync());
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    const prevSeats = (storageData && storageData?.seatQuantity);
    // console.log(prevSeats,'prevSeats');
    console.log(seatsValue,'seatsValue');
    const totalSt = (parseInt(seatsValue)+parseInt(prevSeats));
    setTotalSeats(totalSt);

  }, [seatsValue, storageData]);

  useEffect(() => {
    const seatPric = storageDatas ? (storageDatas?.price*seatsValue) : 0;
    const seatPricTot = storageDatas ? (storageDatas?.price*seatsValue)*12 : 0

    setCalcSeatsPrice(seatPric);
    setCalcSeatsPriceTotal(seatPricTot)
    if (seatsValue && seatPric && seatPricTot) {
      getProrationalInvoice(seatsValue, seatPric, seatPricTot);
    }
  }, [storageDatas, seatsValue]);

  useEffect(() => {
    if (subscriptionData?.subscriptionData?.subscriptions?.length > 0) {
      setDueNextCycle(moment(subscriptionData?.subscriptionData?.subscriptions[0]?.currentPeriodEnd).format("MMM D, YYYY"));
    }
    setPlanType(basePlan && basePlan?.planType === "Monthly" ? "month" : "annum");
    // setPlanType("annum");

    const stData = plansData.filter(
      (plan) =>
        plan.planType === basePlan?.planType &&
        plan.statementDescriptor === "per_seat" &&
        plan.planName === (basePlan?.planName === "Startup" ? "Startup Per User Plan" : "Business Per User Plan")
    );
    console.log(stData, 'stData');
    setStorageDatas(stData && stData[0]);
  }, [subscriptionData, storageData]);

  const getProrationalInvoice = async (quantity, seatPric, seatPricTot) => {
    const request = {
      quantity: '',
      seatQuantity: quantity,
    };
    await dispatch(getProrationalInvoiceAsync(request)).then((res) => {
      // console.log(res.payload,'getProrationalInvoiceAsync');
      if (res?.payload && res?.payload?.lines?.length > 0) {
        const endDate = res?.payload?.periodEnd;
        const startDate = res?.payload?.periodStart;
        const totalAmount = planType === 'month' ? seatPric : seatPricTot;
        // getProratedData(planType, totalAmount, startDate, endDate);
        const { timeRemaining, amount } = getProratedData(planType, totalAmount, startDate, endDate);
        setProAmount(amount);
        setProDays(timeRemaining);
      }
    });
  };

  const upgradeStorage = async () => {
    setIsLoader(true);
    const request = {
        quantity: '',
        seatQuantity: seatsValue,
    };
    await dispatch(upgradeStorageAsync(request)).then((res) => {
        console.log(res);
        setIsLoader(false);
        if (res.payload === "Success") {
            dispatch(getStorageAsync());
            dispatch(getCustomerInvoicesAsync());
            ToasterAlertSucces(res, 'Seats upgraded successfully!');
            handleModal(false);
        } else {
            console.log('additional seats error');
            // ToasterAlertError(res.payload?.value?.data);
            ToasterAlertError('Seats upgrade failed!');
        }
    });
  };

  const handleChangeSeats = (val) => {
    const newValue = Math.max(1, parseInt(val, 10)); // Ensure minimum is 1
    setSeatsValue(newValue === "" ? 1 : newValue);
    setMakePayment(!val ? true : false);
  };

  return (
    <>
      {isLoader && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <Modal.Header closeButton>
        <h3 className="">Add Seats</h3>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <div className="d-flex align-items-start flex-column h-100">
          <div className="w-100 mb-5">
            <label className="d-inline-block text-uppercase text-gray-300 text-md font-medium mb-3 opacity-75">
              ADD-ON
            </label>
            <div className="border border-gray-400 rounded-6 p-4 pt-32 w-100">
              <p className="mb-1 text-base-200 text-md font-bold">SEATS</p>
              <div className="d-flex justify-content-between align-items-center">
                <div className="d-flex justify-content-start align-items-center">
                  <input
                    className="input form-control w-120"
                    placeholder=""
                    type="number"
                    value={seatsValue}
                    min={1}
                    onChange={(event) => handleChangeSeats(event.target.value)}
                  />
                  <p class="mb-0 text-lg text-gray-100 font-medium opacity-75 ms-4">
                    ${storageDatas?.price}/seat/{planType}
                  </p>
                </div>
                <p class="mb-0 text-lg text-gray-100 font-medium">${calcSeatsPrice}</p>
              </div>
            </div>
          </div>
          <div className="mt-auto w-100">
            <div className="w-100">
              <label className="d-inline-block text-uppercase text-gray-300 text-md font-medium mb-3 opacity-75">
                Billing for Add ons
              </label>
              <div className="d-flex justify-content-between align-items-center w-100 pb-1 px-2">
                <p class="mb-0 text-lg text-uppercase text-gray-100 font-normal Total pt-1">
                  Total
                </p>
                <div class="text-lg text-gray-100 font-medium">
                  $ {planType === 'month' ? calcSeatsPrice : calcSeatsPriceTotal} <span class="opacity-75 text-xs">/{planType}</span>
                </div>
              </div>
              {!makePayment && (
                <>
                  <div class="d-flex justify-content-between align-items-center py-2 px-2 rounded-6 mt-3"
                  style={{background: "rgba(74, 21, 75, 0.10)"}}
                  >
                    <p class="mb-0 text-gray-1200 font-bold text-lg ms-1 mt-1 mb-1">
                      Pro-rated for {proDays}
                    </p>
                    <h3 class="font-bold pe-1 mb-1 mt-1">$ {proAmount.toFixed(2)}</h3>
                  </div>
                  <p className="mb-0 text-gray-300 text-md mt-1">You will be charged <b>${proAmount.toFixed(2)}</b> today for additional <b>{seatsValue} seats</b>, bringing your total to <b>{totalSeats} seats</b>.</p>
                  {/* <p className="mb-0 text-gray-300 text-md mt-1"> The payment of <b>$29.97</b> will be due next cycle, <b>{dueNextCycle}.</b></p> */}
                </>
              )}
            </div>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start btnFooter gap-2 py-3">
        <Button
          variant="secondary"
          size="large"
          disabled={makePayment}
          onClick={() => upgradeStorage()}
        >
          Make Payment
        </Button>
      </Modal.Footer>
    </>
  );
};

export default AddSeatsModal;
