import React, { useEffect, useState } from "react";
import { Modal, Button, Container, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import AccountImg from "../../assets/images/Account.png";
import {
  IconTopSearch,
  IconEditPencil,
  IconHamberger,
  IconEmployeeAccount,
  IconEmployeeCall,
  IconEmployeeMail,
  IconEmployeeDate,
} from "../../icons/Icons";
import { getFormatDate } from "../../common";
import getInitialsInName from "../../helper/GetNameInitials";
import UserImage from "../../components/UserImage/UserImage";
import Search from "../../components/Search/Search";
import EmptyState from "../../components/EmptyState/EmptyState";
import { Link, useHistory, useLocation } from "react-router-dom";
import { getEmployeeCountAsync } from "../../reducers/dashboard-reducers";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import { IconrightArrow } from "../../icons/Icons";
import { IconleftArrow } from "../../icons/Icons";
import Pagination from "../../components/Pagination/Pagination";
import { getTeamsAsync } from "../../reducers/user-reducers";
import Loader from "../../components/Loader/Loader";
import { useDebounce } from "../../utils/hooks/useDebounce";

const NewJoiningModal = ({
  employeeDetail,
  designOption,
  selectedDesign,
  selectedDate,
}) => {
  const dispatch = useDispatch();
  const size = 50;
  const [listNewJoining, setListNewJoining] = useState([]);
  const [newJoiningCounts, setNewJoiningCounts] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [formDataReady, setFormDataReady] = useState(false);
  const [designOptions, setDesignOptions] = useState(designOption ?? []);
  // const [selectedDesigns, setSelectedDesigns] = useState(selectedDesign);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  // const [newJoiningEmp, setNewJoiningEmp] = useState(employeeDetail?.users?.slice(page, size) ?? []);
  const [filterss, setFilterss] = useState(false);
  const [optionsTeam, setOptionsTeam] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(
    optionsTeam[0] ? optionsTeam[0] : ""
  );
  const teamsList = useSelector((state) => state.user.teams);
  const [filteredNewJoining, setFilteredNewJoining] = useState([]);

  useEffect(() => {
    const teamsReqBody = {};
    if (teamsList.length === 0) {
      dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload?.map((item) => {
            return { value: item.key, label: item.value };
          });

          const allOption = {
            value: "all",
            label: "All Teams",
          };
          const optionsWithAll = [allOption, ...teams];
          setOptionsTeam(optionsWithAll);
          setSelectedTeam(optionsWithAll[0]);
        }
      });
    } else {
      if (!!teamsList && teamsList?.length > 0) {
        const teams = teamsList?.map((item) => {
          return { value: item.key, label: item.value };
        });
        const allOption = {
          value: "all",
          label: "All Teams",
        };
        const optionsWithAll = [allOption, ...teams];
        setOptionsTeam(optionsWithAll);
        setSelectedTeam(optionsWithAll[0]);
      }
    }
  }, []);

  const getNewJoining = () => {
    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedYear = selectedDate.getFullYear();
    setFormDataReady(false);
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: "",
      isOrderByDesc: true,
      searchString: searchString,
      month: selectedMonth,
      year: selectedYear,
      teamId:
        selectedTeam?.value && selectedTeam?.value !== "all"
          ? selectedTeam?.value
          : null,
    };

    dispatch(getEmployeeCountAsync(reqBody)).then((res) => {
      if (res.payload) {
        // setNewJoiningCounts(res.payload?.employeeTotalCount);
        setNewJoiningCounts(res.payload?.allRecordsCount);
        // setNewJoiningEmp(res.payload?.users);
        setFilteredNewJoining(res.payload?.users);
        setFormDataReady(true);
        //setSelectedDesigns(selectedOption);
      }
    });
  };
  const debouncedNewJoining = useDebounce(getNewJoining, 1000);
  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTeam, searchString]);

  useEffect(() => {
    // getNewJoining();
    debouncedNewJoining();
  }, [searchString, selectedTeam, currentPage]);

  useEffect(() => {
    const newJoiningEmployees = filteredNewJoining.filter((item) => {
      const nameMatch = item.name
        .toLowerCase()
        .includes(searchString.toLowerCase());
      const companyEmail = item.companyEmail
        .toLowerCase()
        .includes(searchString.toLowerCase());
      return nameMatch || companyEmail;
    });
    setListNewJoining(newJoiningEmployees);
    // setNewJoiningCounts(employeeDetail?.users?.length);
  }, [filteredNewJoining, searchString]);

  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  return (
    <>
      <Modal.Header closeButton className="ps-8">
        <div className="w-100">
          <Modal.Title>
            New Joining<span className="font-normal ps-2">({newJoiningCounts})</span>
          </Modal.Title>
        </div>
        {/*<Container className="w-25">

        <Row xs="auto" >
          <Col><Button id="prev" sx={{mr: 10}} onClick={handlePageChange}>Prev</Button> </Col>
          <Col><Button id="next" onClick={handlePageChange}>Next</Button> </Col>
        </Row>
        </Container>*/}
      </Modal.Header>
      {!formDataReady && <Loader />}
      <Modal.Body className="px-8 pt-0 mt-0">
        <div className="row pb-3 joinPopInfo newJoiningJoinPopupInfo mb-3">
          <div className="col-4">
            <Search onBlur={setSearchString} placeHolder="Search User" />
          </div>
          <div className="col-2">
            <div className="mb-4 justify-content-end selectBox">
              <Select
                name="designs"
                isSearchable={false}
                className="select"
                classNamePrefix="recatMySel"
                placeHolder="Design"
                options={designOptions}
                // defaultValue={designOptions[0]}
                // value={selectedDesigns}
                // onChange={(selectedOption) => {
                //   getNewJoining(selectedOption);
                // }}
                value={selectedTeam}
                onChange={(selectedOption) => {
                  setFilterss(true);
                  setSelectedTeam(selectedOption);
                }}
              />
            </div>
          </div>

          <div className="col-6 d-flex justify-content-start align-items-center">
            <Pagination
              totalItems={newJoiningCounts}
              itemsPerPage={pageSize}
              onPageChange={onPageChange}
              setFilterss={setFilterss}
              filterss={filterss}
              pageTitle={"Users"}
            />

          </div>
        </div>
        <div className="row">
          {/* Just rapeat col-3 div */}
          {formDataReady === true && listNewJoining?.map((employee, index) => (
            <div className="col-3">
              <div className="newJoining">
                <div className="d-flex align-items-center mb-3">
                  <div className="headImg">
                    {/* <img src={AccountImg} alt="" /> */}
                    {employee.img ? (
                      <UserImage imgKey={employee.img} alt={employee.name} />
                    ) : (
                      <div>
                        <img
                          src={getInitialsInName(employee.name ?? "N A", 40)}
                          alt={employee.name}
                        />
                      </div>
                    )}
                  </div>

                  <div className="w-100">
                    <h5 className="singleLineEc font-medium mb-1">
                      {employee.name}
                    </h5>
                    <p className="singleLineEc text-md mb-0 text-gray-300">
                      {employee.title}
                    </p>
                  </div>
                </div>

                <ul className="p-0 m-0 newJoinInfo">
                  <li className="w-100 singleLineEc pe-0">
                    <IconEmployeeMail />
                    <a
                      href={`mailto:${employee.companyEmail}`}
                      className="text-gray-100 text-truncate text-sm"
                    >
                      {employee.companyEmail}
                    </a>
                  </li>
                  <li>
                    <IconEmployeeCall />
                    <a
                      // href={`callto:${employee.phoneNumber}`}
                      className="text-gray-100 text-truncate text-sm"
                    >
                      {employee.phoneNumber}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <IconEmployeeDate />
                    <span className="text-gray-300 text-12">Joining date</span>
                    <span className="text-gray-100 text-sm ms-1">
                      {getFormatDate(employee.joiningDate)}
                      {/* <DateTimeConverter utcDateTime={employee.joiningDate} /> */}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ))}
          {listNewJoining.length === 0 && (
            <div style={{height: "calc(100vh - 16.563rem)"}}><EmptyState message={"No records found"} /></div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-end border-0 pe-5">
        {/* <div className="new-pagination">
          <div className="d-flex justify-content-end align-items-center text-gray-1200 h6 font-normal">
            Page{" "}
            <span className="ms-1 me-1 text-gray-100 font-bold text-size">
              1-15
            </span>
            <div className="btnArrow ms-2">
              <Button variant="white p-1" disabled>
                <IconleftArrow />
              </Button>
              <Button variant="white p-1" className="ms-3">
                <IconrightArrow />
              </Button>
            </div>
          </div>
        </div> */}
      </Modal.Footer>

    </>
  );
};

export default NewJoiningModal;
