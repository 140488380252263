//import { IDENTITY_CONFIG } from "../utils/authConst";
import { UserManager, WebStorageStateStore, Log } from "oidc-client";
import { Constants } from "./Constants";

export default class AuthService {
  UserManager;

  constructor() {
    const settings = {
      authority: Constants.stsAuthority,
      client_id: Constants.clientId,
      redirect_uri: Constants.clientRoot + "/signin-oidc",
      silent_redirect_uri: Constants.clientRoot + "/silentrenew",
      post_logout_redirect_uri: `${Constants.clientRoot}/`,
      response_type: "code",
      scope: Constants.clientScope,
    };
    this.UserManager = new UserManager({
      ...settings,
      userStore: new WebStorageStateStore({ store: window.localStorage }),
    });
    // Logger
    Log.logger = console;
    Log.level = Log.DEBUG;
    this.UserManager.events.addUserLoaded((user) => {
      this.UserManager.storeUser(user);
      if (window.location.href.indexOf("signin-oidc") !== -1) {
        this.navigateToScreen();
      }
    });
    this.UserManager.events.addSilentRenewError((e) => {
      console.log("silent renew error", e.message);
    });

    this.UserManager.events.addAccessTokenExpired(() => {
      this.UserManager.signoutPopup();
      this.UserManager.clearStaleState();
      window.location.replace("/");
    });
  }

  signinRedirectCallback = () => {
    this.UserManager.signinRedirectCallback()
      .then((res) => {
        this.UserManager.storeUser(res);
        this.navigateToScreen();
      })
      .catch(function (e) {
        console.error(e);
      });
  };

  getUser = async () => {
    const user = await this.UserManager.getUser();
    if (!user) {
      return await this.UserManager.signinRedirectCallback();
    }
    return user;
  };

  parseJwt = (token) => {
    const base64Url = token.split(".")[1];
    const base64 = base64Url.replace("-", "+").replace("_", "/");
    return JSON.parse(window.atob(base64));
  };

  getUserProfile = () => {
    return JSON.parse(
      localStorage.getItem(
        `oidc.user:${Constants.stsAuthority}:${Constants.clientId}`
      )
    )?.profile;
  };

  signinRedirect = () => {
    localStorage.setItem("redirectUri", window.location.pathname);
    this.UserManager.signinRedirect({});
  };

  navigateToScreen = () => {
    window.location.replace(localStorage.getItem("redirectUri"));
  };

  isAuthenticated = () => {
    const oidcStorage = JSON.parse(
      localStorage.getItem(
        `oidc.user:${Constants.stsAuthority}:${Constants.clientId}`
      )
    );
    return !!oidcStorage && !!oidcStorage.access_token;
  };

  getToken = () => {
    if (this.isAuthenticated()) {
      return JSON.parse(
        localStorage.getItem(
          `oidc.user:${Constants.stsAuthority}:${Constants.clientId}`
        )
      ).access_token;
    } else {
      return "";
    }
  };
  signinSilent = () => {
    this.UserManager.signinSilent()
      .then((user) => {
        console.log("signed in", user);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  signinSilentCallback = () => {
    this.UserManager.signinSilentCallback();
  };

  createSigninRequest = () => {
    return this.UserManager.createSigninRequest();
  };

  logout = () => {
    this.UserManager.signoutRedirect({
      id_token_hint: localStorage.getItem("access_token"),
    });
    this.signoutRedirectCallback();
  };

  signoutRedirectCallback = () => {
    this.UserManager.signoutRedirectCallback().then(() => {
      localStorage.clear();
      this.UserManager.clearStaleState();
    });
  };
}
