import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import RenewalHeader from "../../pages/Authoring/ContentPreview/RenewalHeader"

const PageHeaderSection = ({ redirectTo, title }) => {
  return (
    <>
    <RenewalHeader />
    <header className="pageHeader">
      <Container fluid>
        <Row className="align-items-center">
          <Col>
            <div className="d-flex align-items-center gap-2">
              <Link to={redirectTo} className="mb-1">
                <span className="arrow left"></span>
              </Link>
              <h3>{title}</h3>
            </div>
          </Col>
          <Col className="text-end"></Col>
        </Row>
      </Container>
    </header>
    </>
  );
};

export default PageHeaderSection;
