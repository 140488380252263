import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import EmptyState from "../../components/EmptyState/EmptyState";
import { Modal, Button } from "react-bootstrap";
import ModalPreview from "./ModalPreview";
import useTemp from "../../assets/images/useTemp.png";
import { getModuleDetailsAsync } from "../../reducers/module-reducers";
import { getSequenceDetailsAsync } from "../../reducers/sequence-reducers";
import { getProcessesDetailsAsync } from "../../reducers/processes-reducers";
import { useDispatch, useSelector } from "react-redux";
import { IconTempDelete } from "../../icons/Icons";

const ListPreview = ({ templateDetail, formDataReady, type, selectedData, handleDeleteClick, deleteId }) => {
  const [previewModal, setPreviewModal] = useState(false);
  const [moduleDetails, setAdditionalStorage] = useState(null);
  const [SequencesDetails, setSequencesDetails] = useState(null);
  const [ProcessesDetails, setProcessesDetails] = useState(null);
  const [listData, setlistData] = useState(null);
  const [moduleData, setModuletData] = useState(null);
  const [contentList, setContentList] = useState([]);
  const [userData, setUserData] = useState(deleteId);
  const dispatch = useDispatch();
  const navigate = useHistory();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    console.log(userData, "tester123");
    if (userData) {
      getDetails();
    }
  }, [userData]);

  const getDetails = async () => {
  setIsLoading(true);
    if (!userData) {
      return;
    }
    if (type === "module") {
      const data = await dispatch(getModuleDetailsAsync(userData));
      setAdditionalStorage(data.payload);
      const list = [];
      if (
        data.payload.pages !== undefined &&
        data.payload.pages.length > 0
      ) {
        data.payload.pages.map((m) => {
          list.push({ ...m, contenttype: "pages" });
        });
      }
      if (
        data.payload.forms !== undefined &&
        data.payload.forms.length > 0
      ) {
        data.payload.forms.map((m) => {
          list.push({ ...m, contenttype: "forms" });
        });
      }
      setModuletData(list ?? []);
    } else if (type === "process") {
      const data = await dispatch(getProcessesDetailsAsync(userData));
      setProcessesDetails(data.payload);
      setContentList(data.payload?.content ?? []);
    } else {
      const data = await dispatch(getSequenceDetailsAsync(userData));
      setSequencesDetails(data.payload);
      setlistData(data.payload?.modules ?? []);
    }
    setIsLoading(false);
  };
  
  
  const OnEditFormContent = async (id) => {
    // if (!userData) {
    //   return;
    // }
  
    let route = "";
    let routeData = null;
  
    if (type === "process") {
      const data = await dispatch(getProcessesDetailsAsync(id));
      setProcessesDetails(data.payload);
      setContentList(data.payload?.content ?? []);
      route = `/authoring/processes/edit`;
      routeData = { propsData: data?.payload, operationType: "Edit" };
    } else if (type === "sequence") {
      const data = await dispatch(getSequenceDetailsAsync(id));
      setSequencesDetails(data.payload);
      setlistData(data.payload?.modules ?? []);
      route = `/authoring/sequences/edit`;
      routeData = { propsData: data?.payload, operationType: "Edit" };
    } else if (type === "module") {
      const data = await dispatch(getModuleDetailsAsync(id));
      setAdditionalStorage(data.payload);
      const list = [];
      if (
        data.payload.pages !== undefined &&
        data.payload.pages.length > 0
      ) {
        data.payload.pages.map((m) => {
          list.push({ ...m, contenttype: "pages" });
        });
      }
      if (
        data.payload.forms !== undefined &&
        data.payload.forms.length > 0
      ) {
        data.payload.forms.map((m) => {
          list.push({ ...m, contenttype: "forms" });
        });
      }
      setModuletData(list ?? []);
      route = `/authoring/modules/create`;
      routeData = { propsData: data.payload ?? [], operationType: "Edit" };
    }
  
    navigate.push(route, routeData);
  };
  
  const handleUseClick = (id) => {
    // debugger
    // if (userData) {
      OnEditFormContent(id);
    // } else {
    //   setUserData(id);
    // }
  };
 
  return (
    <>
      <div className="p-32">
        {/* <div className="row">
          <div className="col-md-12 pb-4">
            <h5>For you</h5> 
          </div>
        </div> */}
        
        <div className="row">
          {selectedData != null &&
            selectedData?.length > 0 &&
            selectedData?.map((c, index) => (
              <>
                {c.type === "Process" && (
                  <div className="col-md-4 mb-2" key={c.id}>
                    <div className="card p-2 mb-4 mx-1 mb-4">
                      {/* <p className="card-title text-secondary-400 text-12 m-0 px-1">{c.type}</p> */}
                      <div className="temBtn d-flex align-items-start gap-3">
                        <Button className="btn-sm btn-secondary" onClick={() => handleUseClick(c.id)}>Use</Button>
                        
                        <Button variant="white" className="btn-sm btn-white" onClick={() => {
                          setUserData(c.id);
                          setPreviewModal(true);
                        }}>Preview</Button></div>
                      <img className="card-img-top" src={useTemp} alt="" />
                      <div className="d-flex justify-content-between py-2 mt-1 pe-2"> <h5 className="card-text text-gray-100 font-medium px-1 singleLineEc pe-5">{c.title}</h5><Button className="p-0 m-0 btn btn-Deleteico" onClick={() => handleDeleteClick("Process", c.id)}><IconTempDelete/></Button></div>
                    </div>
                   
                  </div>
                )}
                {c.type === "Sequence" && (
                  <div className="col-md-4 mb-2" key={c.id}>
                    <div className="card p-2 mb-4 mx-1">
                      {/* <p className="card-title text-secondary-400 text-12 m-0 px-1">{c.type}</p> */}
                      <div className="temBtn d-flex align-items-start gap-3">
                        <Button className="btn-sm btn-secondary"  onClick={() => handleUseClick(c.id)}>Use</Button>
                           
                        <Button className="btn-sm btn-white" onClick={() => {
                          setPreviewModal(true);
                          setUserData(c.id);
                        }}>Preview</Button></div>
                      <img className="card-img-top" src={useTemp} alt="" />
                      <div className="d-flex justify-content-between py-2 mt-1 pe-2"> <h5 className="card-text text-gray-100 font-medium px-1 singleLineEc pe-5">{c.title}</h5><Button className="btn p-0 m-0 btn-Deleteico" onClick={() => handleDeleteClick("Sequence", c.id)}><IconTempDelete/></Button></div>
                    </div>
                   
                  </div>
                )}
                {c.type === "Module" && (
                  <div className="col-md-4 mb-2" key={c.id}>
                    <div className="card p-2 mb-4 mx-1 mb-4">
                      {/* <p className="card-title text-secondary-400 text-12 m-0 px-1">{c.type}</p> */}
                      <div className="temBtn d-flex align-items-start gap-3">
                        <Button className="btn-sm btn-secondary" onClick={() => handleUseClick(c.id)}>Use</Button>
                       
                        <Button className="btn-sm btn-white" onClick={() => {
                          setPreviewModal(true);
                          setUserData(c.id);
                        }}>Preview</Button></div>
                      <img className="card-img-top" src={useTemp} alt="" />
                     <div className="d-flex justify-content-between py-2 mt-1 pe-2"> <h5 className="card-text text-gray-100 font-medium px-1 singleLineEc pe-5">{c.title}</h5><Button className="p-0 m-0 btn btn-Deleteico" onClick={() => handleDeleteClick("Module", c.id)}><IconTempDelete/></Button></div>
                    </div>
                    {/* )}  */}
                  </div>
                )}
              </>
            ))}
        </div>

        {!selectedData?.length > 0 && formDataReady === true? <div style={{height: "calc(100vh - 150px)"}}><EmptyState message="No template found" /></div> : null}
      </div>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={previewModal}
      // onHide={() => setActivityModal(false)}
      >
        <ModalPreview
          handleModal={setPreviewModal}
          type={type}
          detail={moduleDetails}
          onClosed={setPreviewModal}
          SequencesDetails={SequencesDetails}
          ProcessesDetails={ProcessesDetails}
          contentList={contentList}
          setContentList={setContentList}
          setlistData={setlistData}
          listData={listData}
          moduleData={moduleData}
          isLoading={isLoading}
        />
      </Modal>
    </>
  );
}

export default ListPreview;
