import React, {useState, useEffect} from 'react'
import { isValidPhoto, isValidVideo, isValidPPT, isValidDocument, isValidMusic } from './formFileValidation';
import Video from '../video';
import ImagePreview from '../ImagePreview';
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";

const ShortAnswer = ({ title, serialNo, questionsFiles, isRequired, onShortAnswerChange, value, disabled  }) => {
  const [shortAnswer, setShortAnswer] = useState('');

  const handleShortAnswerChange = (event) => {
    const answer = event.target.value;
    setShortAnswer(answer);
    onShortAnswerChange(answer);
    console.log(answer,"answer")
    
  };

  useEffect(() => {
    setShortAnswer(value || '');
  }, [value]);
  
  return (
<div className='mt-3 pt-3'>
      <h6 className='mb-3 font-medium'>
        {serialNo}. {title}
        {isRequired && <span className="mandatoryFields" style={{ color: "red" }}>*</span>}
      </h6>
      {questionsFiles?.length > 0 ? (
        <>
          {isValidVideo(questionsFiles.length > 0 && questionsFiles[0]?.filePath) ? (
            <Video contentUrl={questionsFiles[0]?.filePath} />
          ) : isValidPhoto(questionsFiles.length > 0 && questionsFiles[0]?.filePath) ? (
            <ImagePreview contentUrl={questionsFiles[0].filePath} />
          ) : isValidPPT(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Ppt
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : isValidDocument(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Doc
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : isValidMusic(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Audio
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : null}
        </>
      ) : null}
      <textarea rows="3"  name= "shortanswer" placeholder='Your answer' required={isRequired} value={shortAnswer} disabled={disabled}
        onChange={handleShortAnswerChange}></textarea>
    </div>
  );
};

export default ShortAnswer;

