import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { IconEdit2, IconDelete, ClickShare } from "../../../../icons/Icons";
import { Row, Col, Accordion, Button } from "react-bootstrap";
import OwnerImg from "../../../../assets/images/Account.png";
import TeamsNav from "./TeamsNav";
import Onboarding from "./Status/Onboarding";
import TeamsProcess from "./Status/AccountOnboarding";
import TeamsModule from "./Status/TeamsModule";
import { useSelector, useDispatch } from "react-redux";
import {
  updateTeamAsync,
  deleteTeamAsync,
  getTeamDetailsAsync,
  getTeamsAsync,
  getEmpTeamListAsync
} from "../../../../reducers/user-reducers";
import TextInput from "../../../../components/FormikInputs/TextInput";
import ModalDelete from "../../../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../../../components/ModalPopup/ModalResponsePopup";
import { Modal } from "react-bootstrap";
import EmptyState from "../../../../components/EmptyState/EmptyState";
import { getFormatDate } from "../../../../common";
import ToasterAlertSucces from "../../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../../helper/ErrorMessage";
import AdminTeam from "./Status/AdminTeam";
import UserImage from "../../../../components/UserImage/UserImage";
import getInitialsInName from "../../../../helper/GetNameInitials";
import DateTimeConverter from "../../../../components/DateTimeConverter/DateTimeConverter";
import { IconleftArrow } from "../../../../icons/Icons";
import { IconrightArrow } from "../../../../icons/Icons";
import Loader from "../../../../components/Loader/Loader";
import ToasterAlertError from "../../../../helper/ToasterAlertError";

const TeamDetails = ({
  setSelectedTeam,
  actionActivity,
  teamId,
  teamName,
  setIsTeamUpdated,
  setUserData,
}) => {
  const [isNameEditable, setIsNameEditable] = useState(false);
  const [updateTeamName, setUpdateTeamName] = useState(teamName);
  const [responsePopup, setResponsePopup] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [teamDetail, setteamDetail] = useState(null);
  const [activeTab, setActiveTab] = useState("Currently Onboarding");
  const dispatch = useDispatch();
  const [showProcessModal, setShowProcessModal] = useState(false);
  const [showSequenceModal, setShowSequenceModal] = useState(false);
  const [showModuleModal, setShowModuleModal] = useState(false);
  const [showModuleState, setShowModuleState] = useState();
  const [searchString, setSearchString] = useState("");
  const [loading, setLoading] = useState(true);
  // pagination code start ============================================
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const [totalPages, setTotalPages] = useState(0);
  const [teamMember, setTeamMember] = useState([]);
  const indexOfLastEmployee = currentPage * itemsPerPage;
  // pagination code end ============================================
  
  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };

  const getTeams = () => {
    const reqBody = {
      searchString: searchString,
    };
    dispatch(getEmpTeamListAsync(reqBody)).then((res) => {
      //console.log(res, "res")
      setSelectedTeam(res?.payload[0] ? res?.payload[0] : undefined);
    });
    setIsTeamUpdated(false);
  };
  
  const processResponse = (res, operation) => {
    let prompt = res.payload === "success" ? "Success" : "Error";
    let successMessage =
      operation === "Delete" && res.payload === "success"
        && `Team has been deleted successfully.`
        // : "You can’t delete this team as it has some user data."; 
    let message = res?.payload ? successMessage : `${operation} Failed!!`;

    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records: [],
      // procesFailedError: "Delete failed",
    });
  };
  
  const onConfirm = () => {
    const reqBody = {
      id: teamId,
    };

    if (deleteModal) {
      dispatch(deleteTeamAsync(reqBody)).then((res) => {
        // console.log(res);
        processResponse(res, "Delete");
        if (res.payload === "success") {
          getTeams();
          setIsTeamUpdated(true);
          //setSelectedTeam(null);
        }
      });
      setDeleteModal(false);
    }
  };
  const handleNameUpdate = () => {
    if (updateTeamName) {
      const reqBody = {
        id: teamId,
        name: updateTeamName,
      };
      dispatch(updateTeamAsync(reqBody)).then((res) => {
        if (res.payload) {
          ToasterAlertSucces(res, ErrrorMessage.UPDATE);
          dispatch(getTeamsAsync());
          setIsTeamUpdated(true);
          setSelectedTeam({ key: teamId, value: updateTeamName });
          setUpdateTeamName(updateTeamName);
        } else {
          setUpdateTeamName(teamName);
        }
      });
    }
    setIsNameEditable(false);
  };

  const getTeamDetails = async (teamId) => {
    setLoading(true);
    await dispatch(getTeamDetailsAsync(teamId)).then((res) => {
      setteamDetail(res.payload);
      setLoading(false);
    });
  };

  useEffect(() => {
    setTotalPages(0);
    setTeamMember([]);
    // Slice the employees array based on the current page and items per page
    const indexOfFirstEmployee = indexOfLastEmployee - itemsPerPage;
    let totalPagesCount = [];
    let teamMembers = [];
    if (teamDetail?.currentMembers?.employees?.length > 0 && activeTab === "Currently Onboarding") {
      totalPagesCount = Math.ceil(teamDetail?.currentMembers?.employees?.length / itemsPerPage);
      setTotalPages(totalPagesCount);
      teamMembers = teamDetail?.currentMembers?.employees?.slice(indexOfFirstEmployee, indexOfLastEmployee);
      setTeamMember(teamMembers);
    } 
    // else if (teamDetail?.pendingMembers?.employees?.length > 0 && activeTab === "Pending") {
    //   totalPagesCount = Math.ceil(teamDetail?.pendingMembers?.employees?.length / itemsPerPage);
    //   setTotalPages(totalPagesCount);
    //   teamMembers = teamDetail?.pendingMembers?.employees?.slice(indexOfFirstEmployee, indexOfLastEmployee);
    //   setTeamMember(teamMembers);
    // } 
    else if ((teamDetail?.pendingMembers?.employees?.length > 0 || teamDetail?.pendingMembers?.admins?.length > 0) && activeTab === "Pending") {
      const combinedPendingMembers = [
        ...(teamDetail?.pendingMembers?.employees || []),
        ...(teamDetail?.pendingMembers?.admins || [])
      ];
      totalPagesCount = Math.ceil(combinedPendingMembers.length / itemsPerPage);
      setTotalPages(totalPagesCount);
      teamMembers = combinedPendingMembers.slice(indexOfFirstEmployee, indexOfLastEmployee);
      setTeamMember(teamMembers);
    }
    else if (teamDetail?.upcomingMembers?.employees?.length > 0 && activeTab === "Upcoming") {
      totalPagesCount = Math.ceil(teamDetail?.upcomingMembers?.employees?.length / itemsPerPage);
      setTotalPages(totalPagesCount);
      teamMembers = teamDetail?.upcomingMembers?.employees?.slice(indexOfFirstEmployee, indexOfLastEmployee);
      setTeamMember(teamMembers);
    } else if (teamDetail?.completedMembers?.employees?.length > 0 && activeTab === "Completed") {
      totalPagesCount = Math.ceil(teamDetail?.completedMembers?.employees?.length / itemsPerPage);
      setTotalPages(totalPagesCount);
      teamMembers = teamDetail?.completedMembers?.employees?.slice(indexOfFirstEmployee, indexOfLastEmployee);
      setTeamMember(teamMembers);
    }
  }, [teamId, currentPage, teamDetail, setSelectedTeam, activeTab]);

  useEffect(() => {
    setCurrentPage(1);
  }, [teamId, teamDetail, setSelectedTeam, activeTab]);

  useEffect(() => {
    if (teamId) {
      getTeamDetails(teamId);
    }
    setUpdateTeamName(teamName);
  }, [teamId, teamName, setSelectedTeam]);

  const onCloseUpdateModal = () => {
    setIsNameEditable(false);
    setShowProcessModal(false);
    setShowSequenceModal(false);
    setShowModuleModal(false);
    setUpdateTeamName(teamName);
  };
  const handleTeamsModule = (event) => {
    setShowModuleModal(true)
    setShowModuleState(event)
  }

  // Function to handle "Next" button click
  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage((prevPage) => prevPage + 1);
    }
  };

  // Function to handle "Previous" button click
  const handlePrevClick = () => {
    if (currentPage > 1) {
      setCurrentPage((prevPage) => prevPage - 1);
    }
  };
  
  const handleDeleteClick = () => {
    if (teamDetail?.headerDetails?.imgCount > 0) {
      ToasterAlertError('Team can not be deleted as it has users available.');
    } else {
      setDeleteModal(true);
    }
  };
  return (
    <>
    {/* {!teamDetail && <Loader />} */}
      {teamDetail && (
        <div className="manageTeamsCard d-flex flex-column flex-grow-1">
          <div className="manageTeamsCardTop">
            <h2>
              {updateTeamName}{" "} <span className="fw-normal">({teamDetail?.headerDetails?.imgCount})</span>
              {!isNameEditable && (
                <Link onClick={() => setIsNameEditable(true)} className="ms-3">
                  <IconEdit2 />
                </Link>
              )}
            </h2>
            {/* {isNameEditable && (
              <>
                <label htmlFor="teamName">
                  Team Name
                </label>
                <div class="d-flex p-2">
                <div>
                <input
                  type="text"
                  placeholder="Enter team name..."
                  value={updateTeamName}
                  onChange={(event) => {
                    setUpdateTeamName(event.target.value);
                  }}
                  onBlur={() => handleNameUpdate()}
                />
              </>
            )} */}
            <p className="h6 mt-2mb-0 text-gray-300">
              Created on{" "}
              <span className="text-gray-100">
                {getFormatDate(teamDetail?.headerDetails.createdDate)}
                {/* <DateTimeConverter utcDateTime={teamDetail?.headerDetails.createdDate} /> */}
              </span>{" "}
              {getFormatDate(teamDetail?.headerDetails.modifiedDate) && (
                <>
                  | Last edited on{" "}
                  <span className="text-gray-100">
                    {" "}
                    {getFormatDate(teamDetail?.headerDetails.modifiedDate)}
                    {/* <DateTimeConverter utcDateTime={teamDetail?.headerDetails.modifiedDate} /> */}
                  </span>{" "}
                </>
              )}
              | Created by{" "}
              <span className="text-primary-400">
                {teamDetail?.headerDetails.createdBy}
              </span>
            </p>
            <button
              // onClick={() => setDeleteModal(true)}
              onClick={handleDeleteClick}
              className="h6 d-flex gap-2 manageTeamsCardDelete"
            >
              <IconDelete /> Delete
            </button>
            <div className="mt-3 pt-3">
              <div className="d-flex gap-5">
                <div>
                  <div className="h6 text-gray-300 mb-3">Members</div>

                  {/* <div className="d-flex">
                    <div className="headImg moduleImg">
                      <img src={OwnerImg} alt="OwnerImg" />
                    </div>
                    <div className="headImg moduleImg">
                      <img src={OwnerImg} alt="OwnerImg" />
                    </div>
                    <div className="headImg moduleImg">
                      <img src={OwnerImg} alt="OwnerImg" />
                    </div>
                    <div className="headImg moduleImg imgCount">
                      <div className="text-xs total">
                        {teamDetail?.headerDetails.imgCount > 3 ? "+" : null}
                        {teamDetail?.headerDetails.imgCount}
                      </div>
                    </div>
                  </div> */}

                  <div className="d-flex">
                    {teamDetail?.headerDetails?.imgCount === 1 ? (
                      <div className="headImg moduleImg">
                        {/* <img src={OwnerImg} alt="" /> */}
                        {teamDetail?.allMembers[0].img ? (
                          <UserImage imgKey={teamDetail?.allMembers[0].img} />
                        ) : (
                          <img
                            src={getInitialsInName(
                              teamDetail?.allMembers[0].name ?? "N A",
                              36
                            )}
                            alt={teamDetail?.allMembers[0].name}
                          />
                        )}
                      </div>
                    ) : teamDetail?.headerDetails?.imgCount === 2 ? (
                      <>
                        <div className="headImg moduleImg">
                          {/* <img src={OwnerImg} alt="" /> */}
                          {teamDetail?.allMembers[0].img ? (
                            <UserImage imgKey={teamDetail?.allMembers[0].img} />
                          ) : (
                            <img
                              src={getInitialsInName(
                                teamDetail?.allMembers[0].name ?? "N A",
                                24
                              )}
                              alt={teamDetail?.allMembers[0].name}
                            />
                          )}
                        </div>
                        <div className="headImg moduleImg">
                          {/* <img src={OwnerImg} alt="" /> */}
                          {teamDetail?.allMembers[1].img ? (
                            <UserImage imgKey={teamDetail?.allMembers[1].img} />
                          ) : (
                            <img
                              src={getInitialsInName(
                                teamDetail?.allMembers[1].name ?? "N A",
                                24
                              )}
                              alt={teamDetail?.allMembers[1].name}
                            />
                          )}
                        </div>
                      </>
                    ) : teamDetail?.headerDetails?.imgCount > 2 ? (
                      <>
                        <div className="headImg moduleImg">
                          {/* <img src={OwnerImg} alt="" /> */}
                          {teamDetail?.allMembers[0].img ? (
                            <UserImage imgKey={teamDetail?.allMembers[0].img} />
                          ) : (
                            <img
                              src={getInitialsInName(
                                teamDetail?.allMembers[0].name ?? "N A",
                                24
                              )}
                              alt={teamDetail?.allMembers[0].name}
                            />
                          )}
                        </div>
                        <div className="headImg moduleImg">
                          {/* <img src={OwnerImg} alt="" /> */}
                          {teamDetail?.allMembers[1].img ? (
                            <UserImage imgKey={teamDetail?.allMembers[1].img} />
                          ) : (
                            <img
                              src={getInitialsInName(
                                teamDetail?.allMembers[1].name ?? "N A",
                                24
                              )}
                              alt={teamDetail?.allMembers[1].name}
                            />
                          )}
                        </div>
                      </>
                    ) : null}
                    {/* {teamDetail?.headerDetails?.imgCount ? (
                      <div className="headImg moduleImg imgCount">
                        <div className="text-xs total">
                          {teamDetail?.headerDetails?.imgCount > 2
                            ? `${"+"} ${teamDetail.headerDetails?.imgCount - 2}`
                            : +teamDetail.headerDetails?.imgCount}
                        </div>
                      </div>
                    ) : (
                      <div className="headImg moduleImg imgCount">
                        <div className="text-xs total">0</div>
                      </div>
                    )} */}
                    {teamDetail?.headerDetails?.imgCount > 2 ? (
                      <div className="headImg moduleImg imgCount">
                        <div className="text-xs total">
                          {`+ ${teamDetail.headerDetails?.imgCount - 2}`}
                        </div>
                      </div>
                    ) : teamDetail?.headerDetails?.imgCount === 0 ? (
                      <div className="headImg moduleImg imgCount">
                        <div className="text-xs total">0</div>
                      </div>
                    ) : null}

                  </div>
                </div>
                <div>
                  <div className="h6 text-gray-300 mb-3">
                    Average Onboarding Time
                  </div>
                  <div className="pt-2">
                    <h3 className="font-medium text-center">
                      {/* {teamDetail?.headerDetails.avgOnBorading.toFixed(1)} days */}
                      {teamDetail?.headerDetails.avgOnBorading === 0
                        ? "0 days"
                        : `${teamDetail?.headerDetails.avgOnBorading.toFixed(1)} days`}
                    </h3>
                  </div>
                </div>

                <div className="border-start"></div>
                <div>
                  <div className="h6 text-gray-300 mb-3">Processes</div>
                  <div className="pt-2 d-flex justify-content-center">
                    <Button
                      variant=""
                      className="d-flex border-0 align-items-center p-0"
                      onClick={() => handleTeamsModule('Processes')}

                    >
                      <h3 className="font-medium text-center">
                        {teamDetail?.headerDetails.processes}
                      </h3>
                    </Button>
                  </div>
                </div>
                <div>
                  <div className="h6 text-gray-300 mb-3">Sequences</div>
                  <div className="pt-2 d-flex justify-content-center">
                    <Button
                      variant=""
                      className="d-flex border-0 align-items-center p-0"
                      // onClick={() => setShowSequenceModal(true)}
                      onClick={() => handleTeamsModule('Sequences')}
                    >
                      <h3 className="font-medium text-center">
                        {teamDetail?.headerDetails.sequences}
                      </h3>
                    </Button>
                  </div>
                </div>
                <div>
                  <div className="h6 text-gray-300 mb-3">Modules</div>
                  <div className="pt-2 d-flex justify-content-center">
                    <Button
                      variant=""
                      className="d-flex border-0 align-items-center p-0"
                      // onClick={() => setShowModuleModal(true)}
                      onClick={() => handleTeamsModule('Modules')}
                    >
                      <h3 className="font-medium text-center">
                        {teamDetail?.headerDetails.modules}
                      </h3>
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="manageTeamsCardBody flex-grow-1 overflow-auto">
            <Row>
              <Col sm={12}>
                <b>Team</b>
                <div className="mt-2 pt-1 navTeam">
                  <TeamsNav
                    teamMember={teamDetail}
                    setActiveTab={setActiveTab}
                    activeTab={activeTab}
                  />
                </div>
              </Col>
            </Row>
            <Row>
              <Col sm={8}>
              <div className="manageTeamsCardBodyContent rounded-6 borderAll">
                  <div className="d-flex justify-content-between align-items-center pe-4 mb-2 new-pagination">
                    <div>{/* <h6 className="font-medium mb-4">Employees</h6> */}</div>
                    <div className="d-flex justify-content-end align-items-center text-gray-1200 h6 font-normal">
                        Page <span className="ms-1 me-1 text-gray-100 font-bold text-size">{totalPages > 0 ? currentPage : 0}-{totalPages}</span>
                        <div className="btnArrow ms-2">
                          <Button variant="white p-1" onClick={handlePrevClick} disabled={currentPage === 1}>
                            <IconleftArrow/>
                          </Button>
                          <Button variant="white p-1" className="ms-3" onClick={handleNextClick} disabled={totalPages === 0 ? true : currentPage === totalPages}>
                            <IconrightArrow/>
                          </Button>
                        </div>
                    </div>
                  </div>
                  {loading ? (
                    <div className="loader-container">
                    <Loader />
                  </div>
                  ) : (
                  activeTab === "Currently Onboarding" ? (
                    <Onboarding
                      actionActivity={actionActivity}
                      teamMember={teamMember}
                      setUserData={setUserData}
                    />
                  ) : activeTab === "Pending" ? (
                    <Onboarding
                      actionActivity={actionActivity}
                      teamMember={teamMember}
                      setUserData={setUserData}
                    />
                  ) : activeTab === "Upcoming" ? (
                    <Onboarding
                      actionActivity={actionActivity}
                      teamMember={teamMember}
                      setUserData={setUserData}
                    />
                  ) : activeTab === "Completed" ? (
                    <Onboarding
                      actionActivity={actionActivity}
                      teamMember={teamMember}
                      setUserData={setUserData}
                    />
                  ) : null
                  )}
                </div>
              </Col>
              <Col sm={4}>
                <div className="manageTeamsCardBodyContent rounded-6 borderAll">
                  <h6 className="font-medium">Admin Team</h6>
                  <div className="mt-4">
                 {activeTab === "Currently Onboarding" ? (
                  
                    <AdminTeam
                      actionActivity={actionActivity}
                      teamMember={teamDetail?.currentMembers?.admins}
                      setUserData={setUserData}
                    />
                  ) : activeTab === "Pending" ? (
                    <AdminTeam
                      actionActivity={actionActivity}
                      teamMember={teamDetail?.pendingMembers?.admins}
                      setUserData={setUserData}
                    />
                  ) : activeTab === "Upcoming" ? (
                    <AdminTeam
                      actionActivity={actionActivity}
                      teamMember={teamDetail?.upcomingMembers?.admins}
                      setUserData={setUserData}
                    />
                  ) : activeTab === "Completed" ? (
                    <AdminTeam
                      actionActivity={actionActivity}
                      teamMember={teamDetail?.completedMembers?.admins}
                      setUserData={setUserData}
                    />
                    
                  ) : null}
                  </div>
                </div>
                {/* <div className="manageTeamsAccordion">
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Processes Allocated33</Accordion.Header>
                      <Accordion.Body>
                        <ul className="text-sm text-gray-200">
                          <li>
                            <span>UI Design Tool</span>
                            <span className="text-md text-gray-300">
                              14 Jun
                            </span>
                          </li>
                          <li>
                            <span>Introduction to Jira 1</span>
                            <span className="text-md text-gray-300">
                              23 Apr
                            </span>
                          </li>
                          <li>
                            <span>UX Design Tool</span>
                            <span className="text-md text-gray-300">
                              11 Apr
                            </span>
                          </li>
                          <li>
                            <span>Understanding Accounts</span>
                            <span className="text-md text-gray-300">9 Apr</span>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Sequences Allocated</Accordion.Header>
                      <Accordion.Body>
                        <ul className="text-sm text-gray-200">
                          <li>
                            <span>UI Design Tool</span>
                            <span className="text-md text-gray-300">
                              14 Jun
                            </span>
                          </li>
                          <li>
                            <span>Introduction to Jira 1</span>
                            <span className="text-md text-gray-300">
                              23 Apr
                            </span>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>Modules Allocated</Accordion.Header>
                      <Accordion.Body>
                        <ul className="text-sm text-gray-200">
                          <li>
                            <span>UI Design Tool</span>
                            <span className="text-md text-gray-300">
                              14 Jun
                            </span>
                          </li>
                          <li>
                            <span>Introduction to Jira 1</span>
                            <span className="text-md text-gray-300">
                              23 Apr
                            </span>
                          </li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </div> */}
              </Col>
            </Row>
          </div>
        </div>
      )}
      <Modal
        className="modalDialog"
        size="sm"
        centered
        // show={unassignModel || responsePopup?.show}
        show={deleteModal || responsePopup?.show}
      >
        {deleteModal && (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onConfirm}
            headerLable="Are you sure you want to delete this team? "
            chldLable="This action will delete the team permanently and cannot be undone."
            // chldLable="This action will remove all employees added to this team and cannot be undone."
          />
        )}

        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={isNameEditable}>
        <Modal.Header></Modal.Header>
        <Modal.Body className="mb-3">
          <h2 className="mb-4 pb-2">Update team</h2>
          <>
            <label className="pb-2" htmlFor="teamName">
              Team name
              {<span style={{ color: "red" }}>*</span>}
            </label>
            <input
              type="text"
              placeholder="Enter team name..."
              value={updateTeamName}
              onChange={(event) => {
                setUpdateTeamName(event.target.value);
              }}
            />
            {/* {updateTeamName?.trim() === "" ? (
              <label className="errorValidation" style={{ color: "red" }}>
                Team name is required!
              </label>
            ) : null} */}
          </>
        </Modal.Body>
        <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
      
          <Button
            variant="white"
      
            className="m-0"
            onClick={() => onCloseUpdateModal(false)}
          >
            Cancel
          </Button>
          <Button
            variant="primary"
      
            className="m-0"
            onClick={() => handleNameUpdate()}
            disabled={updateTeamName?.trim() === "" ? true : false}
          >
            Update
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal className="come-from-modal right" centered show={showModuleModal}>
        <Modal.Header closeButton className="bg-base-100 ps-5" onClick={() => onCloseUpdateModal(false)}></Modal.Header>
        <TeamsModule onClose={() => setShowModuleModal(false)} showModuleState={showModuleState} teamDetail={teamDetail} teamId={teamId}/>
      </Modal>
    </>
  );
};

export default TeamDetails;
