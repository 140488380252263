
export const MaxFileUploadSizes = [{ value: 5, label: "5 MB" },
{ value: 10, label: "10 MB" },
{ value: 20, label: "20 MB" },
{ value: 50, label: "50 MB" },
{ value: 100, label: "100 MB" },
{ value: 500, label: "500 MB" }];

export const MaxFileUploadLimits = [
    { value: 1, label: "1" },
    { value: 2, label: "2" },
    { value: 3, label: "3" },
    { value: 4, label: "4" },
    { value: 5, label: "5" },
    { value: 6, label: "6" },
    { value: 7, label: "7" },
    { value: 8, label: "8" },
    { value: 9, label: "9" },
    { value: 10, label: "10" }

    
    

];
export const  AssignmentActionType={
MODULE:"Module",
SEQUENCE:"Sequence",
PROCESS:"Process"
}