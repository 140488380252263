// ** Reactstrap Imports
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { toast } from "react-toastify";
import { Fragment } from "react";
import { Check } from "react-feather";

const ToasterAlertSucces = (response, message) => {
  let errorMessage = "";
  if (response.error) {
    errorMessage = response.payload.split(":")[1];
    errorMessage = errorMessage.split("\n")[0];
  }
  if (!response.error) {
    return toast.success(
      message,
      { autoClose: 3000 },
      <Fragment>
        <div className="toastify-header">
          <div></div>
          <div className="title-wrapper d-flex">
            <CheckCircleIcon
              size="sm"
              color="success"
              icon={<Check size={12} />}
            />
            &nbsp;&nbsp;
            <h6 className="toast-title">{message}</h6>
          </div>
        </div>
      </Fragment>,
      { icon: false, hideProgressBar: false }
    );
  } else {
    return toast.error(
      <Fragment>
        <div className="toastify-header">
          <div className="title-wrapper">
            <h6 className="toast-title">{errorMessage ? errorMessage : "Something went wrong"} </h6>
          </div>
        </div>
      </Fragment>,
      { icon: true, hideProgressBar: false }
    );
  }
};
export default ToasterAlertSucces;
