import React, { useState, useEffect } from 'react';
import { IconHamberger } from "../../icons/Icons";
import { Modal } from 'react-bootstrap';
import NotificationModal from "./NotificationModal";
import { useDispatch } from "react-redux";
import {
  getUserProfileAsync,
  getNotificationsAsync,
} from "../../reducers/user-reducers";
import { useDebounce } from "../../utils/hooks/useDebounce";

const NotificationLog = () => {
    const dispatch = useDispatch();
    const [userProfiles, setUserProfiles] = useState(null);
    const [activeTab, setActiveTab] = useState("All");
    const [readNotification, setReadNotification] = useState(true);
    const [modalActivityLog, setModalActivityLog] = useState(false);

    useEffect(() => {
        getUserProfile();
    }, []);

    useEffect(() => {
        //getNotification();
        debouncedNotification();
    }, [userProfiles, modalActivityLog]);

    const getUserProfile = () => {
        dispatch(getUserProfileAsync()).then((res) => {
          if (res.payload) {
            setUserProfiles(res.payload);
          }
        });
    };

    const getNotification = async () => {
        const reqBody = {
            page: 1,
            pageSize: 10,
            orderBy: "",
            isOrderByDesc: true,
            searchString: '',
            userId: userProfiles?.employeeId,
            // userId: "AFCA5765-3AF9-4D35-C12B-08DBE83C92EC",
            apiType: activeTab,
        };
        const response = await dispatch(getNotificationsAsync(reqBody));
        if (response.payload) {
            setReadNotification(response?.payload?.totalUnreadRecords > 0 ? true : false);
        }
    };
    const debouncedNotification = useDebounce(getNotification, 1000);
    return (
        <>
            <div>
                <button onClick={() => setModalActivityLog(true)} className="hamberger">
                    <IconHamberger />
                    {readNotification === true && (
                        <span className='dot'></span>
                    )}
                </button>
            </div>
            <Modal className="come-from-modal right" centered show={modalActivityLog} onHide={() => setModalActivityLog(false)}>
                <NotificationModal handleModal={setModalActivityLog} />
            </Modal>
        </>
    );
}

export default NotificationLog;
