import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import Select from "react-select";
import { QueTypeIcon } from "./questionTypeIcon";
import FileUpload from "./FormType/FileUpload";
import Loader from "../../../components/Loader/Loader";
import {
  IconPlus,
  IconModule,
  IconUploadFile,
  IconCrossSm,
  IconCross,
  IconDelete,
  IconDuplicate,
  ImageUpload,
  VideoUpload,
  ImageUploadPreview,
  VideoUploadPreview,
  PDFUploadPreview,
  FileUploadPreview,TickFill
} from "../../../icons/Icons";
import {
  addUpdateQuestionAsync,
  checkUniquenessAsync,
  getModuleFormDetailsAsync,
  updateModuleAsync,
  addUpdateFormAsync,
} from "../../../reducers/module-reducers";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import TextInput from "../../../components/FormikInputs/TextInput";
import TextArea from "../../../components/FormikInputs/TaxtArea";
import CheckBoxInput from "../../../components/FormikInputs/CheckBoxInput";
import FormType from "./FormType/FormType";
import MultipleChoice from "./FormType/MultipleChoice";
import Dropdown from "./FormType/Dropdown";
import Checkboxes from "./FormType/Checkboxes";
import CommonOption from "./FormType/CommonOption";
import {
  getPageTypeEnumListAsync,
  getQuestionTypeEnumListAsync,
} from "../../../reducers/common-reducers";
import { MaxFileUploadLimits } from "../../../services/DataConstants";
import { MaxFileUploadSizes } from "../../../services/DataConstants";
import { filesUpload } from "../../../api/common-store-api";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../../components/ModalPopup/ModalResponsePopup";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import {
  isValidPhoto,
  isValidVideo,
} from "../ContentPreview/FormType/formFileValidation";

import { getStorageAsync } from "../../../reducers/subscription-reducers";
import ModalStorageLimitReachedPopup from "../../../components/ModalPopup/ModalStorageLimitReachedPopup";
import { useDebounce } from "../../../utils/hooks/useDebounce";
import { getRemainingStringFileName } from "../../../common";

const ModalCreateForm = ({
  openCreateFormModal,
  selectedData,
  onSave,
  formsList,
  pagesList,
  id,
  moduleData,
  moduleId,
  getModuleDataFunction,
  formSetMethod,
  setTouched,
  formTouchedData,
  moduleFormRef,
  onClosed,
  draftCount,
  setDraftCount,
  hasUnpublishedContent
}) => {
  // console.log(moduleFormRef, "moduleFormRef");
  
  const dispatch = useDispatch();

  const { storageData } = useSelector((state) => state.subscription);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);
  const [upload, setUpload] = useState(false);

  useEffect(() => {
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    if (storageData && storageData?.usedStorage >= storageData?.totalStorage) {
      setIsFileUpload(true);
    }
  }, [storageData]);

  const [optionPageType, setoptionPageType] = useState(
    useSelector(
      (state) =>
        state.common.questionTypeList?.map((m) => {
          return {
            value: m.id,
            label: <QueTypeIcon typeId={m.id} typeName={m.name} />,
          };
        }) ?? []
    )
  );
  const defaultData = {
    id: null,
    title: "",
    description: "",
    status: null,
    questions: [],
    allowSkip: true,
  };

  const [selectedFiles, setSelectedFiles] = useState([]);
  const refInput = useRef();
  const refInputVideoAudio = useRef();

  // States for checking the errors
  const [formData, setFormData] = useState(defaultData);
  const [requestedIndex, setRequestedIndex] = useState(null);
  const [addQuestion, setAddQuestion] = useState(false);
  const [pagetype, setpagetype] = useState(null);
  const [isUniqueTitle, setIsUniqueTitle] = useState(true);
  const [titleFocused, setTitleFocused] = useState(false);
  const [selectedFileLimit, setSelectedFileLimit] = useState();
  const [selectedFileSize, setSelectedFileSize] = useState();
  const [specificFileTypes1, setSpecificFileTypes1] = useState(null);
  const [showLoader, setShowLoader] = useState(false);
  const [pageData, setpageData] = useState(null);
  const [fileError, setFileError] = useState(null);
  const [formValid, setFormValid] = useState(true);
  const pageFormsList = useSelector(
    (state) => state.module ?? state.module.pageFormsList.forms
  );

  const setSelectation = (id) => {
    const defaultValue = optionPageType?.filter((f) => f.value === id);
    // console.log(defaultValue,'defaultValuedefaultValuedefaultValue');
    setpagetype({
      value: defaultValue[0].value,
      label: defaultValue[0].label,
    });
  };

  const [isUpdateQuestion, setIsUpdateQuestion] = useState(false);
  const [responsePopup, setResponsePopup] = useState({});
  const [deleteModal, setDeleteModal] = useState(false);
  const [selectedValueForDeleteQuestion, setSelectedValueForDeleteQuestion] =
    useState({});

  const [formDataReady, setFormDataReady] = useState(false);
  const formRef = useRef();
  const formValidation = (values) => {
    let errors = {};
    if (values) {
      if (titleFocused) {
        errors.title = "";
      }
      if (!values.title || values.title.trim() === "") {
        errors.title = "Title is required!";
      } else if (values.title && !isUniqueTitle) {
        errors.title = "Title already exists!";
      }
      if (!values.description || values.description.trim() === "") {
        errors.description = "Description is required!";
      } 
      if (!values.questions || values.questions.length === 0) {
        errors.questions = "Questions are required!";
      }
      if (addQuestion === true) {
        let questionError = {};
        if (
          !values.question.question ||
          values.question.question.trim() === ""
        ) {
          questionError.question = "Question Title is required";
          errors.question = questionError;
        }
      //   if (!values.question.questionType || values.question.questionType.value === "") {
      //     questionError.questionType = "Question Type is required";
      // }
      }
    }
    // console.log(errors,'errors');
    return errors;
  };
  const formQuestionValidated = (values) => {
    if (addQuestion === true) {
      let questionError = {};
      if (values) {
        if (
          !values.question.question ||
          values.question.question.trim() === ""
        ) {
          questionError.question = "Question Title is required";
        }

        //   if( !values.question.questionsFiles ||
        //       !values.question.questionsFiles.length > 0 ) {
        //   if (
        //     !values.question.questionsFiles ||
        //     values.question.questionsFiles.trim() === ""
        //   ) {
        //     questionError.questionsFiles = "Question file is required";
        //   }
        // }
      }
      return questionError;
    }
  };
  const validateTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun
  ) => {
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");

    if (formData?.title === title) {
      setIsUniqueTitle(true);
    } else if (formsList && formsList.find((form) => form.title === title)) {
      setIsUniqueTitle(false);
      setTimeout(() => {
        setTouchedFun({ ...touchedFields, ["title"]: true });
        validateField("title");
      }, 1);
    } else if (moduleFormRef === title) {
      setIsUniqueTitle(false);
      setTimeout(() => {
        setTouchedFun({ ...touchedFields, ["title"]: true });
        validateField("title");
      }, 1);
    } else if (pagesList && pagesList.find((page) => page.title === title)) {
      setIsUniqueTitle(false);
      setTimeout(() => {
        setTouchedFun({ ...touchedFields, ["title"]: true });
        validateField("title");
      }, 1);
    } else {
      setIsUniqueTitle(true);
    }
    
    // else if (title.trim() !== "") {
    //   const jsonData =
    //     formData && formData["id"]
    //       ? {
    //           id: formData["id"] ?? null,
    //           name: title,
    //           type: "Form",
    //         }
    //       : {
    //           name: title,
    //           type: "Form",
    //         };

    //   await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
    //     setTitleFocused(false);
    //     setIsUniqueTitle(res?.payload ? true : false);
    //     setTimeout(() => {
    //       setTouchedFun({ ...touchedFields, ["title"]: true });
    //       validateField("title");
    //     }, 1);
    //   });
    // }
  };
  const debouncedValidateTitle = useDebounce(validateTitle, 1000);

  const formDraftValidation = (values) => {
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    }
    return errors;
  };

  const OnSubmitQuestion = async (values, setMethod, touchedMethod, touchedData) => {
    // debugger;
    let errors = formValidation(values);
    if (Object.keys(errors?.question ?? {}).length === 0) {
      let oldData = values.questions ?? [];
      values.question.maxFileSize = values.question.maxFileSize || 10;
      values.question.maxFileLimit = values.question.maxFileLimit || 1;
  
      if (requestedIndex != null) {
        oldData[requestedIndex] = values.question;
      } else {
        oldData.push(values.question);
      }
  
      if (setMethod) {
        touchedMethod({ ...touchedData, ["questions"]: true });
        setMethod("questions", oldData);
        setAddQuestion(false);
      }
    }
  
    const arrayFiles = selectedFiles ? [...selectedFiles] : [];
    if (arrayFiles.length > 0) {
      let formData = new FormData();
  
      arrayFiles.map((file, i) => formData.append("files", file));
      setShowLoader(true);
      // console.log(arrayFiles,'arrayFilesarrayFilesarrayFiles');
      await filesUpload(formData)
        .then(async (res) => {
          // console.log(res?.data,'resresresres');
          //`${responseFile.data?.fileKey}${responseFile.data?.fileName}`;
          const fileKeys = Object.keys(res?.data?.fileKeys);
          const fileValues = Object.values(res?.data?.fileKeys);
          let responseFile = `${fileKeys[0]}${fileValues[0]}`;
          // let responseFile = res.data[0];
          if (arrayFiles?.isUpdate === false) {
            responseFile = arrayFiles[0].name;
          }
          if (res.data.length > 0) {
            responseFile = `${fileKeys[0]}${fileValues[0]}`;
            // responseFile = res.data[0];
          }
          // console.log(responseFile,'responseFile');
          // console.log(fileValues,'fileValues');
          const questionsFiles = [
            {
              fileType: 0,
              filePath: responseFile,
            },
          ];
          values.question.questionsFiles = questionsFiles;
          values.question.specificFileTypes = specificFileTypes1
            ? specificFileTypes1
            : [0];
 

          setSelectedFiles([]);
          setShowLoader(false);
        })
        .catch((err) => {
          console.log("err = ", err);
        });
    } else {
      values.question.questionsFiles = [];
      values.question.formId = values.id;
    }
  
    // if (id) {
    //   values.question.formId = values.id;
    //   // if(specificFileTypes)
    //   // values.question.specificFileTypes = specificFileTypes1 ? specificFileTypes1.map((item)=> {
    //   //   if(item.id || item.id==0){
    //   //     return  item.id
    //   //   } else {
    //   //     return  item
    //   //   }
    //   // }) :[0]
    //   values.question.specificFileTypes = specificFileTypes1
    //   ? specificFileTypes1.map(item => item)
    //   : [0];
    
    //   setShowLoader(true);
    //   dispatch(addUpdateQuestionAsync(values.question)).then((res) => {
    //     getFormData();
    //     getModuleDataFunction();
    //     setShowLoader(false);
    //     ToasterAlertSucces(res, ErrrorMessage.UPDATE);
    //   });
    // }
  };
  

  const assignRemoveData = (setMethod, index, data, formValues) => {
    // console.log("setMethod = ", setMethod);
    // console.log("index = ", index);
    // console.log("data = ", data);
    // console.log("formValues = ", formValues);
    if (index != undefined && index >= 0 && formValues.questions.length > 0) {
      setMethod("question", formValues.questions.splice(index, 1));
    }
  };
  const duplicateQuestion = (setMethod, index, data, formValues) => {
    if (index != undefined && index >= 0 && formValues.questions.length > 0) {
      setMethod(
        "question",
        formValues.questions.splice(index, 0, formValues.questions[index])
      );
    }
  };

  const assignAddupdateData = (setMethod, index, data, action) => {
    console.log(index, 'index');
    console.log(data, 'datadatadatadata');
    console.log(formData, 'formData');
    setUpload(false);
    setSelectedFiles([]);
    if (index !== undefined) {
      if (selectedData === null) {
        setSelectation(data?.questionType);
        setSelectedFileSize(data?.maxFileSize);
        setSelectedFileLimit(data?.maxFileLimit);
        setSpecificFileTypes1(data?.specificFileTypes);
      } else if (!formData?.questions[index]) {
        setSelectation(data?.questionType);
        setSelectedFileSize(data?.maxFileSize);
        setSelectedFileLimit(data?.maxFileLimit);
        setSpecificFileTypes1(data?.specificFileTypes);
      } else {
        setSelectation(formData.questions[index]?.questionType);
        setSelectedFileSize(formData.questions[index]?.maxFileSize);
        setSelectedFileLimit(formData.questions[index]?.maxFileLimit);
        setSpecificFileTypes1(formData.questions[index]?.specificFileTypes);
      }
      if (action !== undefined && action === "duplicate") {
        setIsUpdateQuestion(false);
        setRequestedIndex(null);
      } else {
        setRequestedIndex(index);
      }
    } else {
      setRequestedIndex(null);
    }
    if (!data) {
      setpagetype({
        value: optionPageType[0].value,
        label: optionPageType[0].label,
      });
      setSelectedFileSize();
      setSelectedFileLimit();
    } else {
      const matchingOption = optionPageType.find(option => option.value === data?.questionType);
      if (matchingOption) {
        setpagetype({
          value: data?.questionType,
          label: matchingOption?.label,
        });
      }
    }

    if (action === "duplicate") {
      data.id = null;
    }
    let questioData = data ?? {
      id: null,
      question: "",
      questionType: 1,
      isRequired: false,
      options: [],
    };
    if (data?.questionsFiles.length > 0) {
      // console.log(data?.questionsFiles);
      const Files = [
        { name: data.questionsFiles[0]?.filePath, isUpdate: false },
      ];
      setSelectedFiles(Files);
    }
    setMethod("question", questioData);
    setAddQuestion(true);
  };

  const oldFormRemove = (moduleData, index, newFromData) => {
    newFromData = [newFromData];
    let newList = [];
    if (moduleData.pageFormsList && moduleData.pageFormsList.forms) {
    moduleData.pageFormsList.forms.forEach(function (item) {
      if (item.id === newFromData[0].id) {
        let questions = [];
        newFromData[0].questions.forEach(function (item) {
          if (
            item.specificFileTypes &&
            item.specificFileTypes.length > 0
          ) {
            let specificFileTypess = [];
            item.specificFileTypes.map((item, i) => {
              specificFileTypess.push(item.id);
            });
            item.specificFileTypess = specificFileTypes1;
            questions.push(item);
          } else {
            questions.push(item);
          }
        });
        newList.push({
          id: newFromData[0].id,
          title: newFromData[0].title,
          description: newFromData[0].description,
          questions: questions,
        });
      } else {
        newList.push({
          id: item.id,
          title: item.title,
          description: item.description,
          questions: item.questions.forEach(function (item) {
            if (item.specificFileTypes.length > 0) {
              let specificFileTypess = [];
              item.specificFileTypes.map((item, i) => {
                specificFileTypess.push(item.id);
              });

              return item;
            }
          }),
        });
      }
    });
  }
    return newList;
  };

  const onSubmit = (status, index) => {
    // debugger;
    setShowLoader(true);
    const currentFormData = formRef?.current;
    currentFormData.values.status = status
    if (currentFormData.values.status == undefined) {
      // navigate.push("/authoring/sequences");
    }
    var errors = formValidation(currentFormData.values);
    /// setpageData({ ...pageData, ["status"]: status });
    // console.log(Object.keys(currentFormData.errors).length,'sdfsdbvbcvbcvbvcffsddf');
    // console.log(Object.keys(errors).length,'sdfsd');
    // if (
    //   Object.keys(currentFormData.errors).length === 0 &&
    //   Object.keys(errors).length === 0 && !addQuestion
    // ) {
    if (Object.keys(errors).length === 0 && !addQuestion || status === 1) {
      if (id) {
        const formList = oldFormRemove(
          pageFormsList,
          id,
          currentFormData.values
        );

        const reqBody = {
          id: currentFormData.values.id,
          moduleId: moduleData.id,
          title: currentFormData.values.title,
          description: currentFormData.values.description,
          status: status,
          questions: currentFormData.values.questions,
          allowSkip: currentFormData.values.allowSkip,
          index:currentFormData.values.index,
        };

        setFormDataReady(false);
        dispatch(addUpdateFormAsync(reqBody)).then((res) => {
          ToasterAlertSucces(res, ErrrorMessage.UPDATE);
          getModuleDataFunction();
          getFormData();
          openCreateFormModal(false);
        });
      }
      if (onSave) {
        if (moduleData.id && moduleData.status !== "Template") {
          const reqBody = {
            id: null,
            moduleId: moduleData.id,
            title: currentFormData.values.title,
            description: currentFormData.values.description,
            status: status,
            questions: currentFormData.values.questions,
            allowSkip: currentFormData.values.allowSkip,
            index:currentFormData.values.index,
          };
          // openCreateFormModal(false);
          dispatch(addUpdateFormAsync(reqBody)).then((res) => {
            ToasterAlertSucces(res, ErrrorMessage.CREATE);
            getModuleDataFunction(moduleData.id);
            getFormData();
            openCreateFormModal(false);
          });
        } else {
          onSave(
            currentFormData.values,
            "forms",
            index,
            formSetMethod,
            setTouched,
            formTouchedData
          );
          //onSave(currentFormData.values, "forms", index,);
        }
      }
      openCreateFormModal(false);
    }
    setShowLoader(false);
  };

  const onSelectationChange = (e, colName, setMethod) => {
    // console.log(e,'eeeeeeeeee');
    setpagetype({
      value: e.value,
      label: e.label,
    });
    if (setMethod) {
      setMethod(colName, e.value);
    }
    // addUpdateQuestionData(e.value, colName);
  };

  useEffect(() => {
    setUpload(false);
    if (selectedData !== null) {
      setFormData(selectedData);
    }
    // console.log(optionPageType,'optionPageType?.length');
    if (optionPageType?.length === 0) {
      dispatch(getQuestionTypeEnumListAsync()).then((res) => {
        const pagetypes = res.payload.map((m) => {
          return { value: m.id, label: m.name };
        });

        setoptionPageType(
          pagetypes?.map((m) => {
            return {
              value: m.value,
              label: <QueTypeIcon typeId={m.value} typeName={m.label} />,
            };
          }) ?? []
        );

        const defaultData = {
          value: res.payload[0].id,
          label: res.payload[0].name,
        };
        setpagetype(defaultData);
        setFormDataReady(true);
      });
    } else {
      setpagetype(optionPageType[0]);
      setFormDataReady(true);
    }
  }, []);
  
  const addQuestionOption = (value, setMethod, index) => {
    // console.log(value,'question.options');
    // console.log(index,'question.index');
    if (index >= 0 && index < value.length) {
      value[index].contentPath = "";
    }
    setMethod("question.options", value);
  };

  const onChangeFile = (e, type) => {
    const files = e.target.files;
    const selectedFilesArray = Array.from(files);

    if (pagetype.value === 6) {
      const filesArray = selectedFilesArray.map((file) => {
        return URL.createObjectURL(file);
      });
      setUpload(true);
      setSelectedFiles([...selectedFiles, ...selectedFilesArray]);
    }

    if (type === "image") {
      setFileError(null);
      let isImage = isValidPhoto(files[0].name);
      setSelectedFiles(selectedFilesArray);
      setUpload(true);
      if (!isImage) {
        setUpload(false);
        setSelectedFiles([]);
        setFileError(
          "This file is not supported. Supported files - jpeg, jpg, png."
        );
      }
    } else if (type === "video") {
      setFileError(null);
      let isVideo = isValidVideo(files[0].name);
      setSelectedFiles(selectedFilesArray);
      setUpload(true);
      if (!isVideo) {
        setUpload(false);
        setSelectedFiles([]);
        setFileError(
          "This file is not supported. Supported files - mp4, m4v, mov."
        );
      }
    }
  };

  const onClickSelectFile = () => {
    // console.log(isFileUpload,'isFileUpload');
    if (isFileUpload === true) {
      setIsStorageLimitReached(true);
    } else {
      refInput.current.click();
    }
  };

  const onClickSelectVideoAudio = () => {
    // console.log(isFileUpload,'isFileUpload');
    if (isFileUpload === true) {
      setIsStorageLimitReached(true);
    } else {
      refInputVideoAudio.current.click();
    }
  };

  const onConfirm = () => {
    if (deleteModal) {
      // assignRemoveData(setFieldValue, index, m, values)
      assignRemoveData(
        selectedValueForDeleteQuestion.setFieldValue,
        selectedValueForDeleteQuestion.index,
        selectedValueForDeleteQuestion.m,
        selectedValueForDeleteQuestion.values
      );
      setDeleteModal(false);
      setIsUpdateQuestion(false);
      setAddQuestion(false);
      setSelectedValueForDeleteQuestion({});
    }
  };

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };

  const getHeaderTitle = (id) => {
    return id !== undefined && id !== "" ? "Edit form" : "Create a form";
  };

  const getSubmitTitle = (id) => {
    if (formData?.status === 1) {
      return "Publish";
    } else {
      return id !== undefined && id !== "" ? "Update form" : "Create form";
    }
  };

  const getFormData = async () => {
    setShowLoader(true);
    let response = await dispatch(getModuleFormDetailsAsync(id));
    setFormData(response?.payload);
    // console.log(response, "res");
    if (response?.payload) {
      setFormData(response?.payload);
      setShowLoader(false);
      // pageType.map((v) => {
      //   if (v.value === response.payload.pageContentType) {
      //     setpagetype(v);
      //   }
      // });
    }
    setFormDataReady(true);
  };

  useEffect(() => {
    setFormDataReady(true);
    if (optionPageType.length === 0) {
      dispatch(getPageTypeEnumListAsync());
    } else {
      const defaultValue = optionPageType.filter(
        (f) => f.value === (selectedData?.pageContentType ?? 0)
      );
      // setpagetype({
      //   value: defaultValue[0].value,
      //   label: defaultValue[0].label,
      // });
    // }
    }

    if (selectedData === null) {
      setFormData(defaultData);
    } else if (id != null) {
      setFormDataReady(false);
      getFormData();
    } else {
      setFormData(selectedData);
    }
  }, []);

  // {
  // console.log(formData, "formData");
  // }

  return (
    <>
      <Modal.Header closeButton  onClick={() => onClosed(false)}>
        <Modal.Title> {getHeaderTitle(id)}</Modal.Title>
      </Modal.Header>

      {formDataReady && (
        <Formik
          enableReinitialize
          initialValues={{
            id: formData?.id ?? undefined,
            title: formData?.title,
            description: formData?.description,
            questions: formData?.questions ?? [],
            question: {
              id: null,
              question: null,
              questionType: 1,
              isRequired: false,
              options: [],
              maxFileLimit: 0,
              maxFileSize: 0,
              specificFileTypes: [0],
              questionsFiles: [
                {
                  fileType: 0,
                  filePath: "",
                },
              ],
            },
            allowSkip: formData?.allowSkip,
            index:formData?.index,
          }}
          onSubmit={onSubmit}
          validate={formValidation}
          innerRef={formRef}
        >
          {({
            errors,
            values,
            setFieldValue,
            touched,
            setTouched,
            validateField,
          }) => {
            {/* console.log(values,"test")
            console.log(optionPageType,'optionPageTypeoptionPageTypeoptionPageType');
            console.log(pagetype,'pagetypepagetypepagetypepagetypepagetype'); */}
            return (
              <Form style={{ display: "contents" }}>
                <div className="py-0 pe-0 modal-body">
                  <Row className="g-0 d-flex h-100">
                    <Col className="col-lg-7 pt-32 px-0 pb-0 col">
                      <div className="pb-0">
                        <div className="input-group myInput mb-32">
                          <TextInput
                            name="title"
                            label="Title"
                            className="form-control"
                            placeholder="E.g HR Operations"
                            isRequired={true}
                            // onFocus={() => {
                            //   setTitleFocused(true);
                            // }}
                            // onBlur={(event) => {
                            //   validateTitle(
                            //     event?.target?.value,
                            //     validateField,
                            //     touched,
                            //     setTouched
                            //   );
                            // }}
                            onInput={(event) => {
                              debouncedValidateTitle(
                                event?.target?.value,
                                validateField,
                                touched,
                                setTouched
                              );
                            }}
                            maxLength={40}
                          />
                        </div>
                        <div className="input-group myInput mb-32 labelFor">
                          <TextArea
                            label="Description"
                            name="description"
                            className="form-control"
                            rows="3"
                            placeholder="Add text..."
                            isRequired={true}
                            maxLength={250}
                          />
                        </div>

                      <div className="d-flex align-items-start justify-content-between myInputbox">
                        <p className="font-bold">
                          Form Questions ({values.questions.length ?? 0}){" "}
                          <span
                            className="mandatoryFields"
                            style={{ color: "red" }}
                          >
                            *
                          </span>
                        </p>
                        <div className="form-check form-switch custom-switch ps-0 pe-1 d-flex">
                          <TextInput
                              name="allowSkip"
                              label="Skip Allowed"
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked"
                             // defaultChecked={true}
                              checked={values.allowSkip}
                              
                              onChange={(event) =>
                                setFieldValue("allowSkip", event.target.checked ? true : false)
                              }
                            />
                           {/* {console.log(values.allowSkip,'checked')} */}
                                {/* <p className="h6 text-gray-200">
                                    <CheckBoxInput
                                      name="skipAllowed"
                                      label="Skip allowed"
                                      value={values.skipAllowed ?? false}
                                    />
                                  </p> */}
                          {/* <TextInput
                            name="allowSkip"
                            label="Skip Allowed"
                            className="form-check-input"
                            type="checkbox"
                          /> */}
                        </div>
                        </div>
                      </div>

                      <div className=" py-0 pe-0">
                        {values?.questions != undefined &&
                          values?.questions.length > 0 &&
                          values?.questions.map((m, index) => (
                            <div
                              key={index}
                              className="quesMod d-flex bg-gray-600 borderAll p-12 rounded-6 mwidth-440 position-relative mb-12 align-items-center"
                            >
                              <IconModule />
                              <div
                                className="w-100 ms-3"
                                onClick={() => {
                                  setIsUpdateQuestion(true);
                                  assignAddupdateData(setFieldValue, index, m);
                                }}
                              >
                                <p className="mb-0 w-100">
                                  <Button variant="" className="border-0 p-0">
                                    <div className="d-flex gap-2 align-items-center">
                                      <p className="mb-0">
                                        {m.question}
                                      </p>
                                    </div>
                                  </Button>
                                </p>
                              </div>
                              <div className="crossBtn">
                                <Button
                                  variant=""
                                  className="border-0 py-0"
                                  onClick={() =>
                                    assignAddupdateData(
                                      setFieldValue,
                                      index,
                                      m,
                                      "duplicate"
                                    )
                                  }
                                >
                                  <IconDuplicate />
                                </Button>
                                <Button
                                  variant=""
                                  className="border-0 py-0 ps-0"
                                  onClick={() => {
                                    setDeleteModal(true);
                                    setFileError(null);
                                    // assignRemoveData(setFieldValue, index, m, values)
                                    setSelectedValueForDeleteQuestion({
                                      setFieldValue,
                                      index,
                                      m,
                                      values,
                                    });
                                  }}
                                >
                                  <IconDelete />
                                </Button>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className=" pt-0">
                        <Button
                          variant=""
                          className="btnLink mb-3 flexDiv"
                          onClick={(e) => {
                            setIsUpdateQuestion(false);
                            assignAddupdateData(setFieldValue);
                          }}
                        >
                          <IconPlus /> Add Question
                        </Button>
                       
                        {touched.questions && errors.questions && (
                          <label className="errorValidation mb-3" style={{ color: "red" }}>
                            {errors.questions}
                          </label>
                        )}
                      </div>
                    </Col>
                    <Col className="col-lg-5">
                      {addQuestion == true && (
                        <div className="bg-gray-600 p-32 px-4 h-100">
                          <div className="row">
                            <div className="col-lg-12">
                              {/* <FormType /> */}

                              <div className="row gx-0">
                                <div className="col-lg-9">
                                  <div className="input-group myInput mb-32">
                                    <TextInput
                                      name="question.question"
                                      label="Questions"
                                      className="form-control"
                                      placeholder="E.g HR Operations"
                                      isRequired={true}
                                      maxLength={40}
                                    />
                                  </div>
                                </div>
                                <div className="col-lg-3">
                                  <div
                                    className="d-flex flex-row ms-3 me-3 formInputAdd"
                                    style={{
                                      height: "2rem",
                                      marginTop: "2.3rem",
                                    }}
                                  >
                                    <input
                                      type="file"
                                      name="question.questionsFiles"
                                      // multiple= {pagetype.value=== 6 ?? "multiple"}
                                      accept="image/*"
                                      onChange={(e) => {
                                        const type = "image";
                                        onChangeFile(e, type);
                                        setFieldValue(
                                          "question.questionsFiles",
                                          e.target.files[0].name
                                        );
                                        e.target.value = null;
                                      }}
                                      style={{ display: "none" }}
                                      ref={refInput}
                                    />
                                    <button onClick={onClickSelectFile}>
                                      <h4>
                                        <ImageUpload />
                                      </h4>
                                    </button>
                                    {/* <label className="errorValidation" style={{ color: "red" }}>*</label> */}

                                    <div
                                      className="vr mx-2 mt-2"
                                      style={{ height: "1.2rem" }}
                                    />

                                    <input
                                      type="file"
                                      name="question.questionsFiles"
                                      // multiple= {pagetype.value=== 6 ?? "multiple"}
                                      accept="video/mp4, video/*"
                                      onChange={(e) => {
                                        const type = "video";
                                        onChangeFile(e, type);
                                        setFieldValue(
                                          "question.questionsFiles",
                                          e.target.files[0].name
                                        );
                                        e.target.value = null;
                                      }}
                                      style={{ display: "none" }}
                                      ref={refInputVideoAudio}
                                    />
                                    <button onClick={onClickSelectVideoAudio}>
                                      <h4>
                                        <VideoUpload />
                                      </h4>
                                    </button>
                                    {/* <label className="errorValidation" style={{ color: "red" }}>*</label> */}
                                  </div>
                                </div>
                              </div>
                              <div className="position-relative"
                                style={{
                                  // marginTop: "-1.4rem",
                                }}
                              >
                                <h6 style={{ color: "red" }}>{fileError}</h6>
                                {selectedFiles &&
                                  selectedFiles.map((file, index) => {
                                    return (
                                      <div
                                        key={index}
                                        className="d-flex gap-3 bg-gray-600 borderAll p-12 rounded-6 mwidth-440 position-relative mb-32 flex-start" style={{marginTop: "-1rem"}}
                                      >
                                        <FileUploadPreview />
                                        <div>
                                          <p className="mb-0">
                                            <Button
                                              variant=""
                                              className="border-0 py-0"
                                            >
                                              <div className="d-flex gap-2 align-items-center">
                                                <p className="mb-0 font-bold">
                                                  {upload === false ? (
                                                    getRemainingStringFileName(file.name)
                                                  ) : (
                                                    <>
                                                      {file.name.length < 20
                                                      ? file.name
                                                      : `${file.name.substring(
                                                          0,
                                                          35
                                                        )}...`}
                                                    </>
                                                  )}
                                                </p>
                                              </div>
                                            </Button>
                                          </p>
                                        </div>
                                        <div className="crossBtn">
                                          <Button
                                            variant=""
                                            className="border-0 py-0"
                                            onClick={() => {
                                              setSelectedFiles(
                                                selectedFiles.filter(
                                                  (e) => e !== file
                                                )
                                              );
                                            }}
                                          >
                                            <IconCross /> 
                                          </Button>
                                        </div>
                                      </div>
                                      );
                                  })}
                                  {selectedFiles?.length > 0 && (
                                    <div className="tickfill"><TickFill/></div>
                                  )}
                              </div>

                              <div className="row">
                                <div className="col-lg-9">
                                  <div className="fullSelect input-group mySelect w-100 mb-32">
                                    <label>Type
                                    <span
                                      className="mandatoryFields"
                                      style={{ color: "red" }}
                                    >
                                      *
                                    </span></label>
                                    <Select
                                      isSearchable={false}
                                      name="question.questionType"
                                      className="select"
                                      options={optionPageType}
                                      defaultValue={pagetype}
                                      value={pagetype}
                                      // classNamePrefix="recatMySel"
                                       classNamePrefix="selectCustom"
                                      onChange={(e) =>
                                        onSelectationChange(
                                          e,
                                          "question.questionType",
                                          setFieldValue
                                        )
                                      }
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-9 uploadSizearea">
                                  {(pagetype.value === 3 ||
                                    pagetype.value === 5 ||
                                    pagetype.value === 4) && (
                                    <CommonOption
                                      type={pagetype.value}
                                      options={values?.question?.options}
                                      onAdd={addQuestionOption}
                                      setMethod={setFieldValue}
                                      touchedMethod={setTouched}
                                      touchedData={touched}
                                      setFormValid={setFormValid}
                                    />
                                  )}

                                  {pagetype.value === 6 && (
                                    <FileUpload
                                      numberFiles={MaxFileUploadLimits}
                                      sizeFiles={MaxFileUploadSizes}
                                      selectedFileSize={selectedFileSize}
                                      selectedFileLimit={selectedFileLimit}
                                      specificFileTypes={specificFileTypes1}
                                      setSpecificFileTypes={(value) => {
                                        //setSpecificFileTypes1(value);
                                        setFieldValue(
                                          "question.specificFileTypes",
                                          value
                                        );
                                      }}
                                      pagetype={pagetype}
                                      setSelectedFileSize={(value) => {
                                        setSelectedFileSize(value);
                                        setFieldValue(
                                          "question.maxFileSize",
                                          value
                                        );
                                      }}
                                      setSelectedFileLimit={(value) => {
                                        setSelectedFileLimit(value);
                                        setFieldValue(
                                          "question.maxFileLimit",
                                          value
                                        );
                                      }}
                                    />
                                  )}

                                  <div className="uploadSizef">
                                    <p className="font-bold mb-3">Settings</p>
                                    <div className="mb-4 pb-1">
                                      <div className="form-check form-switch custom-switch ps-0 pe-1 d-flex justify-content-between align-items-center">
                                        <TextInput
                                          name="question.isRequired"
                                          label="Required"
                                          className="form-check-input"
                                          type="checkbox"
                                        />
                                      </div>
                                    </div>
                                  </div>

                                  {/* <div className="float-start">
                                <p className="h6 font-medium mb-20">
                                  Image / Video
                                </p>
                                <div className="d-flex justify-content-center align-item-center p-32 text-center bg-base-100 rounded-6 borderAllDashed">
                                  <div>
                                    <div className="mb-12">
                                      <IconUploadFile />
                                    </div>
                                    <p className="h6">
                                      <span className="font-bold text-primary-200">
                                        Choose file
                                      </span>
                                      or drag here
                                    </p>
                                  </div>
                                </div>
                              </div> */}
                                  <div
                                    style={{ display: "flex", width: "100%", paddingTop: "24px", }}
                                  >
                                    <div
                                      className="mb-4 me-1"
                                   
                                    >
                                      <Button
                                        variant="secondary"
                                        disabled={
                                          Object.keys(
                                            formQuestionValidated(values)
                                          ).length !== 0 || (formValid === false)
                                        }
                                        onClick={() => {
                                          setIsUpdateQuestion(false);
                                          OnSubmitQuestion(
                                            values,
                                            setFieldValue,
                                            setTouched,
                                            touched
                                          );
                                        }}
                                      >
                                        {isUpdateQuestion == true
                                          ? "Update Question"
                                          : "Add Question"}
                                      </Button>
                                    </div>
                                    <div
                                      className="mb-4 ms-3"

                                    >
                                      <Button
                                        variant="white"
                                        onClick={() => {
                                          setIsUpdateQuestion(false);
                                          setAddQuestion(false);
                                        }}
                                      >
                                        Cancel
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                    </Col>
                  </Row>
                </div>
                <div className="d-flex gap-20 align-items-center btnFooter">
                  <Button
                    variant="secondary"
                    disabled={
                      addQuestion ||
                      Object.keys(formValidation(values)).length !== 0
                    }
                    onClick={() => {
                      setFieldValue("status", 0)
                      onSubmit(0, formData?.index)}
                    }
                  >
                    {getSubmitTitle(id)}
                  </Button>
                  {/* <Button
                    disabled={Object.keys(formValidation(values)).length !== 0}
                    variant="white"
                  >
                    Preview
                  </Button> */}
                  {((formData?.status === null || formData?.status === 1) && moduleData?.status !=="Published") && (
                    <Button
                    variant="white"
                    disabled={
                      addQuestion ||
                      Object.keys(formDraftValidation(values)).length !== 0
                    }
                    className="border-0"
                    onClick={() =>{
                      setFieldValue("status", 1)
                      onSubmit(1, formData?.index)
                      if (formData?.status === null || formData?.status === 1) {
                        setDraftCount(draftCount + 1);
                        hasUnpublishedContent(true);
                      }
                      }
                    }
                  >
                    Save as draft
                  </Button>
                  )}
                  
                </div>
              </Form>
            );
          }}
        </Formik>
      )}

      {/* <Modal.Footer className="justify-content-start btnFooter">
        <Button
          variant="secondary"
          disabled={
            Object.keys(formValidation(formRef?.current?.values)).length !== 0
          }
          onClick={() => onSubmit(0, formData?.frontendIndex)}
        >
          Create form
        </Button>
        <Button
          disabled={
            Object.keys(formValidation(formRef?.current?.values)).length !== 0
          }
          variant="white"
        >
          Preview
        </Button>
        <Button
          variant="white"
          disabled={
            Object.keys(formValidation(formRef?.current?.values)).length !== 0
          }
          className="border-0"
          onClick={() => onSubmit(1, formData?.frontendIndex)}
        >
          Save as draft
        </Button>
      </Modal.Footer> */}

      <Modal
        className="modalDialog delModal"
        size="sm"
        centered
        show={deleteModal || responsePopup?.show}
      >
        {deleteModal && (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onConfirm}
            requestType="Delete"
            headerLable="Are you sure you want to delete selected question?"
            chldLable="The selected question will be deleted."
          />
        )}
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isStorageLimitReached}
      >
        <ModalStorageLimitReachedPopup onClose={setIsStorageLimitReached} />
      </Modal>
      <> {showLoader && <Loader />}</>
    </>
  );
};

export default ModalCreateForm;
