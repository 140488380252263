import { configureStore } from "@reduxjs/toolkit";
import processesReducers from "./reducers/processes-reducers";
import sequenceReducers from "./reducers/sequence-reducers";
import moduleReducers from "./reducers/module-reducers";
import commonReducers from "./reducers/common-reducers";
import reportedIssue from "./reducers/reportedIssue-reducers";
import userReducers from "./reducers/user-reducers";
import subscriptionReducers from "./reducers/subscription-reducers";
export const store = configureStore({
  reducer: {
    process: processesReducers,
    sequence: sequenceReducers,
    module: moduleReducers,
    reportedIssue: reportedIssue,
    common: commonReducers,
    user:userReducers,
    subscription:subscriptionReducers
  },
});
