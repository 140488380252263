import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import Search from "../../components/Search/Search";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../components/ActivityLog/ActivityLog";
import MyAccountNav from "./MyAccountNav";
import PersonalDetails from "./PersonalDetails";
import ProfessionalDetails from "./ProfessionalDetails";
import { Switch, Route } from "react-router-dom";

const MyAccount = () => {
  return (
    <div className="pages">
      <header className="pageHeader">
        <Container fluid>
          <Row className="mx-0 align-items-center">
            <Col className="px-0">
              <h3>My Account</h3>
              <div class="h6 mt-2 text-gray-200">
                Please follow the following sequence to complete the onboarding
                process.
              </div>
            </Col>
            <Col className="px-0 text-end ">
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <AccountDropdown />
                <ActivityLog />
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <div className="pageOuter">
        <Container fluid>
          <MyAccountNav />
          <Switch>
            <Route path="/employeeaccount/myaccount/professional">
              <ProfessionalDetails />
            </Route>
            <Route path="/employeeaccount/myaccount">
              <PersonalDetails />
            </Route>
          </Switch>
        </Container>
      </div>
    </div>
  );
};

export default MyAccount;
