import React, { useState, useRef, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Search from "../../../components/Search/Search";
import Select from "react-select";
import { IconHamberger, IconStar } from "../../../icons/Icons";
import CheckBox from "../../../components/CheckBox/CheckBox";
import AccountImg from "../../../assets/images/Account.png";
import {
  getFormatDate,
  Status
} from "../../../common";
import EmptyState from "../../../components/EmptyState/EmptyState";
import { useLocation } from "react-router-dom";
import getInitialsInName from "../../../helper/GetNameInitials";
import UserImage from "../../../components/UserImage/UserImage";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";

const AudienceModal = ({ openModalAssignProcess, data, title, moduletitle, onClose }) => {
  // console.log(data,'datadatadatadata');
  const optionSort = [
    { value: 1, label: "Latest-Oldest" },
    { value: 2, label: "Oldest-Latest" },
  ];
  const [processModalData, setprocessModalData] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [searchResult, setSearchResult] = useState([]);
  

  const handleSearch = (event) => {
    const { value } = event.target;

    setSearchString(value);
    // Perform the search operation here
    const filteredArray = processModalData.filter((item) =>
      item.employeeName.toLowerCase().includes(value.toLowerCase())
    );
    setSearchResult(filteredArray);
  };

  const handleSort = (e) => {
    let data = [];
    searchResult.forEach((value) => {
      data.push({
        employeeName: value.employeeName,
        teamName: value.teamName,
        officalMail: value.officalMail,
        officalContact: value.officalContact,
        assignOn: new Date(value.assignOn),
        assignstatus: value.assignstatus,
        img: value.img,
      });
      return value;
    });
    if (e.value === 1) {
      setprocessModalData(data.sort((a, b) => b.assignOn - a.assignOn));
      setSearchResult(data.sort((a, b) => b.assignOn - a.assignOn));
    } else if (e.value === 2) {
      setprocessModalData(data.sort((a, b) => a.assignOn - b.assignOn));
      setSearchResult(data.sort((a, b) => a.assignOn - b.assignOn));
    }
  };

  const getData = () => {
    let setData = [];
    data.forEach((value) => {
      setData.push({
        employeeName: value.employeeName,
        teamName: value.teamName,
        officalMail: value.officalMail,
        officalContact: value.officalContact,
        assignOn: new Date(value.assignOn),
        assignstatus: value.assignstatus,
        img: value.img,
      });
      return value;
    });
    setprocessModalData(setData.sort((a, b) => b.assignOn - a.assignOn));
    setSearchResult(setData.sort((a, b) => b.assignOn - a.assignOn));
  }
  useEffect(() => {
    if (data != null) {
      getData()
    }
  }, []);

  console.log(searchResult,'searchResult');
  return (
    <>
      <Modal.Header className="ps-8 py-3" closeButton onClick={() => onClose(false)}>
        <div>
          <Modal.Title>Audience</Modal.Title>
          <ol className="breadcrumb h6 mt-1">
            <li>
            {title === "Process" && (
              <Link to="/authoring">{title ? title : "Loading..."}</Link>
              )}
              {title === "Sequence" && (
              <Link to="/authoring/sequences">{title ? title : "Loading..."}</Link>
              )}
              {title === "Module" && (
              <Link to="/authoring/modules">{title ? title : "Loading..."}</Link>
              )}
            </li>
            {/* <li>HR Onboarding</li> */}
            <li>{moduletitle?.title}</li>
          </ol>
        </div>
      </Modal.Header>
      <Modal.Body className="ps-8 pt-4 pe-5">
        <div className="mb-4 d-flex gap-4">
          <Search
            onBlur={setSearchString}
            handleSearch={handleSearch}
            placeHolder="Search by audience...."
          />

          <div className="mySelect h6">
            <Select
              isSearchable={false}
              className="select audSelect"
              options={optionSort}
              defaultValue={[optionSort[0]]}
              classNamePrefix="recatMySel"
              onChange={(e) => {
                handleSort(e);
              }}
            />
          </div>
        </div>
        <div className='tableOuter flex-grow-1 overflow-auto h-100'>
          <table className="table">
            <thead>
              <tr>
                {/* <th>
                  <CheckBox text="ALL" />
                </th> */}
                <th className="ps-20">NAME</th>
                <th>TEAM</th>
                <th>CONTACT DETAILS</th>
                <th>ASSIGNED ON</th>
                <th>STATUS OF ASSIGNMENT</th>
              </tr>
            </thead>
            <tbody>
              {searchResult != undefined &&
                searchResult.length > 0 &&
                searchResult.map((f) => (
                  <tr>
                    {/* <td align="middle">
                      <CheckBox />
                    </td> */}
                    <td className="ps-20">
                      {/* <Link
                        to={`/user/processes/${123}`}
                        className="text-gray-100 d-flex align-items-center gap-2"
                      > */}
                      <div className="d-flex align-items-center gap-2">
                        <div className="tableImg">
                          {f.img && f.img != "" ? (
                            <UserImage
                              key={f?.img}
                              imgKey={f.img}
                            />
                          ) : (
                            <img 
                              src={
                                f?.img && f?.img != ""
                                  ? f?.img
                                  : getInitialsInName(f?.employeeName ?? "N A", 36)
                                  // : AccountImg
                              }
                              alt={f?.employeeName}
                            />
                          )}
                        </div>
                        <p className="text-nowrap mb-0 tdName">{f.employeeName}</p>
                      </div>
                      {/* </Link> */}
                    </td>
                    <td>{f.teamName}</td>
                    <td>
                      <a
                        className="text-primary-200 font-medium tdMail"
                        href={`mailto:${f.officalMail}`}
                      >
                        {f.officalMail}
                      </a>
                      <br />
                      <a
                        className="text-primary-200 font-medium"
                        href={`tel:${f.officalContact}`}
                      >
                        {f.officalContact}
                      </a>
                    </td>
                    <td> 
                    {getFormatDate(f.assignOn)}
                    {console.log(f.assignOn, 'f.assignOn')}
                    {/* <DateTimeConverter utcDateTime={f.assignOn} /> */}
                    </td>
                    <td>
                      <span
                        className={`tableTag ${
                          Status[f.assignstatus]?.color
                        } text-md  `}
                      >
                        {f.assignstatus}
                      </span>
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
          {!searchResult.length > 0 && (
            <EmptyState message={"Audience no found"} />
          )}
        </div>
      </Modal.Body>
      {/* <Modal.Footer className="justify-content-start btnFooter">
                <Button variant="secondary" size="sm" onClick={() => openModalAssignProcess(false)}>
                    Create page
                </Button>
                <Button variant="white" size="sm">
                    Preview
                </Button>
                <Button variant="white" size="sm" className='border-0'>
                    Save as draft
                </Button>
            </Modal.Footer> */}
    </>
  );
};

export default AudienceModal;
