import React, { useState, useEffect } from 'react';
import Select from 'react-select';
import { Row, Col } from 'react-bootstrap';
import { isValidPhoto, isValidVideo, isValidPPT, isValidDocument, isValidMusic } from './formFileValidation';
import Video from '../video';
import ImagePreview from '../ImagePreview';
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";

const TimeType = ({ title, serialNo, questionsFiles, isRequired, onTimeChange, setIsValidTime, value, disabled }) => {
  const multipleChoiceOpt = [
    { value: 'AM', label: 'AM' },
    { value: 'PM', label: 'PM' },
  ];

  const [timeValue, setTimeValue] = useState('');
  const [selectedAMPM, setSelectedAMPM] = useState(multipleChoiceOpt[0]);

  useEffect(() => {
    const [time, ampm] = (value || '').split(',');

    const selectedOption = ampm
      ? multipleChoiceOpt.find((opt) => opt.value.toLowerCase() === ampm.toLowerCase())
      : multipleChoiceOpt[0];

    setTimeValue(time || '');
    setSelectedAMPM(selectedOption || multipleChoiceOpt[0]); 
  }, [value]);


  const handleTimeChange = (time, isRequireds) => {
    const validTime = time.replace(/[^0-9:@]/g, '');
    setTimeValue(validTime);

    if (isRequireds && !validTime) {
      setIsValidTime(false);
    } else {
      onTimeChange(`${validTime},${selectedAMPM.value}`);
      if (isRequireds && validTime) {
        setIsValidTime(true);
      }
    }
  };

  const handleAMPMChange = (selectedOption) => {
    setSelectedAMPM(selectedOption);

    setTimeValue((prevTimeValue) => {
      const validTime = prevTimeValue.replace(/[^0-9:@]/g, '');

      if (isRequired && !validTime) {
        // setIsValidTime(false);
      } else {
        const ampmValue = selectedOption ? selectedOption.value : '';
        // console.log(ampmValue,"ampmValue")
        onTimeChange(`${validTime},${ampmValue}`);
        // setIsValidTime(true);
      }

      return prevTimeValue;
    });
  };

  return (
    <div className='mt-3 pt-3'>
      <h6 className='mb-3 font-medium'>
        {serialNo}. {title}
        {isRequired && <span className='mandatoryFields' style={{ color: 'red' }}>*</span>}
      </h6>
      {questionsFiles?.length > 0 ? (
        <>
          {isValidVideo(questionsFiles.length > 0 && questionsFiles[0]?.filePath) ? (
            <Video contentUrl={questionsFiles[0]?.filePath} />
          ) : isValidPhoto(questionsFiles.length > 0 && questionsFiles[0]?.filePath) ? (
            <ImagePreview contentUrl={questionsFiles[0].filePath} />
          ) : isValidPPT(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Ppt
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : isValidDocument(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Doc
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : isValidMusic(
            questionsFiles.length > 0 &&
            questionsFiles[0]?.filePath
          ) ? (
            <Audio
              contentUrl={
                questionsFiles[0].filePath
              }
            />
          ) : null}
        </>
      ) : null}
      <Row>
        <Col xs={6} md={4}>
          <div className='input-group myInput'>
            <input type='text' placeholder='Enter Time' name='time' value={timeValue} onChange={(e) => handleTimeChange(e.target.value, isRequired)} required={isRequired} disabled={disabled}/>
          </div>
        </Col>
        <Col xs={6} md={4}>
          <div className='mySelect newMySelect'>
            <Select
            isSearchable={false}
              menuPlacement='auto'
              className='select'
              options={multipleChoiceOpt}
              value={selectedAMPM}
              classNamePrefix='selectCustom'
              name='am_pm'
              onChange={handleAMPMChange}
              isDisabled={disabled}
            />
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default TimeType;
