import React from "react";
import { Modal, Button } from "react-bootstrap";
import {
  IconResetcheck
} from "../../icons/Icons";
class ModalResponse extends React.Component {

   
  render() {
     return (
    <>
    
      <Modal.Body className="pt-4 pb-4">
        
        {this.props.data?.propmt === "Success" &&(
            <>
           
            <h3 className="mt-3 text-success-100 f24">Success!</h3>
            <p className="mt-2 mb-4">{this.props.data?.message}</p>
            {this.props.data?.data ?
            <>
            {this.props.data.type === "bulk" ?
               <>
            <h5 className="mt-2 mb-1">Total request: {this.props.data?.data?.totalRequest} </h5>
            {this.props.data?.data.alreadyAssignedUserCount > 0 ?
            <h5 className="mt-2 mb-1">Some of the content was already assigned to {this.props.data?.data.alreadyAssignedUserCount} of the user.</h5> :null}
            <h5 className="mt-2 mb-1">Successfully assigned {this.props.data?.data.proceedData}.</h5>
            <h5 className="mt-2 mb-1">Failed assignment {this.props.data?.data.failureData}</h5>
            </>:null}
          </>:null}
            </>
        )}
        {this.props.data?.propmt === "Error" &&(
          <>
          <h2 className="mt-5 text-center text-danger"> Error </h2>
          {this.props.data?.data?.totalRequest !== 0 ?
          <h4 className="text-danger mt-2 mb-1">{this.props.data?.message}</h4>:null}

          {this.props.data?.data ?
            <>
            {this.props.data.type === "bulk" ?
               <>
            <h5 className="mt-2 mb-1">Total request: {this.props.data?.data?.totalRequest} </h5>
            {this.props.data?.data.alreadyAssignedUserCount > 0 ?
            <h5 className="mt-2 mb-1">Some of the content was already assigned to {this.props.data?.data.alreadyAssignedUserCount} of the user.</h5> :null}
            <h5 className="mt-2 mb-1">Successfully assigned {this.props.data?.data.proceedData}.</h5>
            <h5 className="mt-2 mb-1">Failed assignment {this.props.data?.data.failureData}</h5>
            </>:null}
          </>:null}
          {/* <h4>{this.props.data?.data.procesFailedError}</h4> */}

          {/* {(!this.props.data?.procesFailedError || this.props.data?.procesFailedError?.length <= 0) && this.props.data?.records && this.props.data.records.map((c, index) => {
           return <>
           <p className="text-danger mt-1">{index +1}. Failed : {c?.id}. Error: {c?.errorMessage}</p>
           </>
          })} */}
            {this.props.data?.procesFailedError && this.props.data?.procesFailedError?.length>0
           && (<p className="text-danger  mt-2">Error : {this.props.data?.procesFailedError}</p>)
        }
          </>
        )}


        {this.props.data?.propmt === "Warning" &&(
          <>
           <h3 className="mt-5 text-warning"> Warning </h3>
            <p className="text-success-300 mb-0 mt-3 mb-5">{this.props.data?.message}</p>
            </>
        )}
      
        
       
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
        <Button className="m-0" variant="primary" size="" onClick={() => this.props?.onClose()}>
          Close
        </Button>
      </Modal.Footer>
    </>
  );
  }
}

export default ModalResponse;
