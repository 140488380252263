import React, { useState, useRef, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Modal,
  Button,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import {
  IconCreateForm,
  IconCreatePage,
  IconPipe,
  IconRestrict,
} from "../../../icons/Icons";
import OwnerImg from "../../../assets/images/Account.png";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import ModalCreatePage from "./ModalCreatePage";
import ModalCreateForm from "./ModalCreateForm";
import TagsInput from "../../../components/TagsInput/TagsInput";
import ContentDraggable from "../../../components/Draggable/ContentDraggable";
import { useLocation, useHistory } from "react-router-dom";
import createForm from "../../../assets/images/create_form.png";
import Loader from "../../../components/Loader/Loader";
import { optionsTime } from "../../../common";
import PageHeaderSection from "../../../components/PageHeader/PageHeaderSection";
import {
  createModuleAsync,
  updateModuleAsync,
  createModuleDraftAsync,
  getSortedPageFormsAsync,
  getgetModulePageFormsAsync,
  getModuleDetailsAsync,
  checkUniquenessAsync,
  checkUniquenessTempAsync,
  deleteModulePageAsync,
  deleteModuleFormAsync,
} from "../../../reducers/module-reducers";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field } from "formik";
import TextArea from "../../../components/FormikInputs/TaxtArea";
import TextInput from "../../../components/FormikInputs/TextInput";
import CheckBoxInput from "../../../components/FormikInputs/CheckBoxInput";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import OwnerImgCount from "../OwnerImgCount";
import MultiSelectDropdown from "../../../components/MultiSelect/MultiSelect";
import {
  getTeamsAsync,
  getUsersAsync,
  getRolesAsync,
} from "../../../reducers/user-reducers";
import * as moment from "moment";
import Draggable from "../../../components/Draggable/Draggable";
import Draggable2 from "../../../components/Draggable/Draggable2";
import RestrictedModalPopup from "../../../components/ModalPopup/RestrictedModalPopup";
import { getUserUsageAsync } from "../../../reducers/user-reducers";
import {
  getSubscriptionAsync,
  getPlansAsync,
} from "../../../reducers/subscription-reducers";
import RestrictedPlan from "../../AccountSetting/Billing/RestrictedPlan";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const CreateModule = () => {
  const dispatch = useDispatch();
  const [ftue, setFtue] = useState(false);
  const [showTool, setShowTool] = useState(false);
  const target = useRef(null);
  const [createPageModal, setCreatePageModal] = useState(false);
  const [createFormModal, setCreateFormModal] = useState(false);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [isUniqueTitle, setIsUniqueTitle] = useState(true);
  const [titleFocused, setTitleFocused] = useState(false);
  const [isSubmittted, setIsSubmittted] = useState(false);

  const [isCheckFirstTitleDuplicate, setIsCheckFirstTitleDuplicate] =
    useState(true);

  const [recommendedTimeDurationType, setRecommendedTimeDurationType] =
    useState(optionsTime[0]);
  const [
    reminderRecommendedTimeDurationType,
    setReminderRecommendedTimeDurationType,
  ] = useState(optionsTime[0]);
  const [estimatedTimeDurationType, setestimatedTimeDurationType] = useState(
    optionsTime[0]
  );
  const navigate = useHistory();
  const [list, setList] = useState([]);
  const [pagesList, setpagesList] = useState([]);
  const [formsList, setformsList] = useState([]);
  const [optionsTeam, setOptionsTeam] = useState([]);

  const [restrictModal, setRestrictModal] = useState(false);
  const teamsList = useSelector((state) => state.user.teams);
  const roleList = useSelector((state) => state.user.roles);
  const [optionsUser, setOptionsUser] = useState([]);
  const [roleId, setRoleId] = useState(null);
  const [pageSize, setPageSize] = useState(100000);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [restrictedTeam, setRestrictedTeam] = useState([]);
  const [restrictedEmployee, setRestrictEmployee] = useState(false);
  const [lisdata, setlistData] = useState([]);
  const [hasDraftContent, setHasDraftContent] = useState(0);
  const defaultData = {
    id: "",
    title: "",
    description: "",
    customTags: "",
    recommendedTime: null,
    recommendedTimeDurationType: 0,
    // reminderRecommendedTime: null,
    // reminderRecommendedTimeDurationType: 1,
    skipAllowed: false,
    isExpirable: false,
    expiryDate: null,
    //estimatedTime: "",
    estimatedTimeDurationType: 1,
    status: "",
    restrictedTeamIds: selectedTeam?.value ? selectedTeam?.value : "",
    pages: [],
    forms: [],
  };

  const [moduleData, SetModuleData] = useState(defaultData);
  const [requestedIndex, setRequestedIndex] = useState(null);
  const location = useLocation();
  const { operationType, redirectTo } = location.state;

  const [requestPagedData, setrequestPagedData] = useState(null);
  const [requestFormData, setrequestFormData] = useState(null);
  const [userUsage, setUserUsage] = useState({});
  const [isRestricted, setIsRestricted] = useState(false);
  const [restrictedPopUp, setRestrictedPopUp] = useState(false);
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData } = useSelector((state) => state.subscription);
  const [loadTeamsList, setLoadTeamsList] = useState(true);

  useEffect(() => {
    if (restrictedEmployee === true) {
      // console.log(restrictedTeam,'restrictedTeamrestrictedTeamrestrictedTeam');
      // Get the last object in the array
      const lastObject =
        restrictedTeam && restrictedTeam[restrictedTeam?.length - 1];
      // console.log(lastObject,'lastObject');
      if (lastObject) {
        const selectedUser = optionsUser
          ? optionsUser.filter((user) => user.teamId === lastObject.value)
          : [];
        // console.log(selectedUser,'selectedUser');
        // console.log(selectedUsers,'selectedUsers');

        // Filter out objects from selectedUsers that already exist in selectedUser
        const filteredSelectedUsers = selectedUsers.filter(
          (user) => !selectedUser.some((u) => u.value === user.value)
        );

        // Append the filteredSelectedUsers to selectedUser
        setSelectedUsers([...selectedUser, ...filteredSelectedUsers]);
      }
    }
  }, [restrictedEmployee]);

  const getUsers = () => {
    roleList?.records?.map((m) => {
      if (m.name === "Admin") {
        setRoleId(m.id);
      }
    });

    let reqBody = {
      pageSize: pageSize,
      // filters: { roleIds: ["c6ec043a-50f9-4bd8-b360-2bfe5de477f7"] },
      filters: {},
    };
    dispatch(getUsersAsync(reqBody)).then((res) => {
      if (res.payload.users && res.payload.users.length > 0) {
        const users = res.payload.users.map((user) => ({
          value: user.id,
          label: user.name,
          teamId: user.teamId,
        }));
        console.log(users);
        setOptionsUser(users);
      }
    });
  };

  useEffect(() => {
    getUserUsage();
    if (roleList.length === 0) {
      dispatch(getRolesAsync({ name: "" }));
    }
    getUsers();
  }, [roleList, setRoleId, roleId]);

  // useEffect(() => {
  //   const teamsReqBody = {};
  //   if (teamsList.length === 0) {
  //     dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
  //       if (!!res?.payload && res?.payload?.length > 0) {
  //         const teams = res?.payload?.map((item) => {
  //           return { value: item.key, label: item.value };
  //         });
  //         setOptionsTeam(teams);
  //         //console.log(setOptionsTeam);
  //       }
  //     });
  //   } else {
  //     if (!!teamsList && teamsList?.length > 0) {
  //       const teams = teamsList?.map((item) => {
  //         return { value: item.key, label: item.value };
  //       });
  //       setOptionsTeam(teams);
  //       //console.log(setOptionsTeam);
  //     }
  //   }
  // }, []);

  const fetchData = async () => {
    const teamsReqBody = {};
    if (loadTeamsList && teamsList.length === 0) {
      setLoadTeamsList(false);
      try {
        const res = await dispatch(getTeamsAsync(teamsReqBody));
        if (res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload
            ?.filter((team) => {
              return optionsUser.some((user) => user.teamId === team.key);
            })
            .map((item) => ({ value: item.key, label: item.value }));
          setOptionsTeam(teams); // Filter out undefined values
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    } else {
      const teams = teamsList
        ?.filter((team) => {
          return optionsUser.some((user) => user.teamId === team.key);
        })
        .map((item) => ({ value: item.key, label: item.value }));
      setOptionsTeam(teams); // Filter out undefined values
    }
  };

  useEffect(() => {
    // console.log("teamsList:", teamsList);
    // console.log("optionsUser:", optionsUser);
    fetchData();
  }, [optionsUser, teamsList]);

  const validateTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun,
    callback
  ) => {
    // console.log(operationType,'validate operationType');
    setIsCheckFirstTitleDuplicate(false);
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");
    console.log("i am here");
    if (title.trim() !== "") {
      setIsSubmittted(true);
      const jsonData =
        moduleData["id"] &&
        (operationType === undefined || operationType !== "Duplicate")
          ? {
              id: moduleData["id"] ?? null,
              name: title,
              type: "Module",
            }
          : {
              name: title,
              type: "Module",
            };

      await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
        setIsUniqueTitle(res?.payload ? true : false);
        setIsSubmittted(false);
        console.log("I am here");
        setTitleFocused(false);
        setIsCheckFirstTitleDuplicate(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["title"]: true });
          validateField("title");
        }, 1);

        if (res?.payload) {
          callback && callback();
        }
      });
    }
    return true;
  };
  const debouncedValidateTitle = useDebounce(validateTitle, 1000);

  const validateTempTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun,
    callback
  ) => {
    setIsCheckFirstTitleDuplicate(false);
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");
    if (title.trim() !== "") {
      setIsSubmittted(true);
      const jsonData =
        moduleData["id"] &&
        (operationType === undefined || operationType !== "Duplicate")
          ? {
              id: moduleData["id"] ?? null,
              name: title,
              type: "Module",
            }
          : {
              name: title,
              type: "Module",
            };

      await dispatch(checkUniquenessTempAsync(jsonData)).then((res) => {
        setIsUniqueTitle(res?.payload ? true : false);
        setIsSubmittted(false);
        console.log("I am here");
        setTitleFocused(false);
        setIsCheckFirstTitleDuplicate(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["title"]: true });
          validateField("title");
        }, 1);

        if (res?.payload) {
          callback && callback();
        }
      });
    }
    return true;
  };

  const debouncedValidateTempTitle = useDebounce(validateTempTitle, 1000);

  const handleActionClick = async (
    actionType,
    validateField,
    touched,
    setTouched,
    handleSubmit,
    setFieldValue,
    values
  ) => {
    console.log(actionType, "actionType");
    console.log(moduleData, "moduleData");
    console.log(values, "values");
    switch (actionType) {
      case "save_as_template":
        if (moduleData?.id && moduleData?.status !== "Template") {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templateSave(7);
              // handleSubmit();
            }
          );
          // if(!validation){
          //   // formikProps.handleSubmit();
          // }
          return;
        }
        break;
      case "update":
        // if (operationType === "Duplicate") {
        //   let validation = await validateTempTitle(
        //     values?.title,
        //     validateField,
        //     touched,
        //     setTouched,
        //     () => {
        //       templateSave(7);
        //       // handleSubmit();
        //     }
        //   );
        //   return;
        // }
        if (moduleData?.status === "Template") {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              setFieldValue("status", 7);
              handleSubmit();
            }
          );
          return;
        } else {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templateSave(7);
              // handleSubmit();
            }
          );
          return;
        }
        break;
      case "update_publish": // case action duplicate, create, draft, edit, 
        if ((operationType === "Duplicate" || moduleData?.status === "Draft") && getRestrictedPopUp() === true) {
          setRestrictedPopUp(true);
          return;
        } else if (moduleData?.status !== "Template") {
          let validation = await debouncedValidateTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              setFieldValue("status", 0);
              handleSubmit();
            }
          );
          if (!validation) {
            // formikProps.handleSubmit();
          }
          return;
        }
        break;
      case "publish": // case template publish
        if (getRestrictedPopUp() === true) {
          // console.log("Test Publish");
          setRestrictedPopUp(true);
          return;
        } else if (moduleData?.status === "Template") {
          let validation = await debouncedValidateTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templatePublish(0);
              handleSubmit();
            }
          );
          return;
        }
        break;
    }
  };

  const draggableclick = (pageContentType, index) => {
    let requestData = list[index];
    setRequestedIndex(index);
    if (requestData) {
      if (requestData.contentType == "pages") {
        setrequestPagedData(requestData);
        setCreatePageModal(true);
      } else {
        setrequestFormData(requestData);
        setCreateFormModal(true);
      }
    }
  };

  const childCreateRequest = (type) => {
    setRequestedIndex(null);
    if (type == "pages") {
      setrequestPagedData(null);
      setCreatePageModal(true);
    } else {
      setrequestFormData(null);
      setCreateFormModal(true);
    }
  };

  // const draggableclickForRemove = async (type, index, contentIndex, setMethod) => {
  //   let updatedList = [...list];
  //   let updatedPagesList = [...pagesList];
  //   let updatedFormsList = [...formsList];

  //   for (let l = 0; l < updatedList.length; l++) {
  //     if (updatedList[l].id === index) {
  //       updatedList.splice(l, 1); // Remove the object at index
  //       break; // Break the loop once the item is found
  //     }
  //   }

  //   if (contentIndex === "pages") {
  //     for (let p = 0; p < updatedPagesList.length; p++) {
  //       if (updatedPagesList[p].id === index) {
  //         const pJsonData = {
  //           id: index,
  //           // status: 1,
  //         };
  //         if (moduleData?.status !== "Template") {
  //           const pResponse = await dispatch(deleteModulePageAsync(pJsonData));
  //           if (pResponse.payload) {
  //             updatedPagesList.splice(p, 1); // Remove the object at index
  //             break; // Break the loop once the item is found
  //           }
  //         }
  //         else{
  //           updatedPagesList.splice(p, 1); // Remove the object at index
  //             break; // Break the loop once the item is found
  //         }
  //       }
  //     }
  //     setpagesList(updatedPagesList);
  //   } else {
  //     for (let f = 0; f < updatedFormsList.length; f++) {
  //       if (updatedFormsList[f].id === index) {
  //         const fJsonData = {
  //           id: index,
  //           // status: 1,
  //         };
  //         if (moduleData?.status !== "Template") {
  //           const fResponse = await dispatch(deleteModuleFormAsync(fJsonData));
  //           if (fResponse.payload) {
  //             updatedFormsList.splice(f, 1); // Remove the object at index
  //             break; // Break the loop once the item is found
  //           }
  //         }
  //         else{
  //           updatedFormsList.splice(f, 1); // Remove the object at index
  //           break; // Break the loop once the item is found
  //         }
  //       }
  //     }
  //     setformsList(updatedFormsList);
  //   }

  //   setList(updatedList);
  //   setMethod("content", updatedList);
  // };

  const draggableclickForRemove = async (
    type,
    index,
    id,
    contentIndex,
    setMethod
  ) => {
    let updatedList = [...list];
    let updatedPagesList = [...pagesList];
    let updatedFormsList = [...formsList];

    if (index === undefined) {
      for (let ls = 0; ls < updatedList.length; ls++) {
        if (
          updatedList[ls].contentType === contentIndex &&
          updatedList[ls].index === id
        ) {
          updatedList.splice(ls, 1);
          break;
        }
      }

      if (contentIndex === "pages") {
        for (let ps = 0; ps < updatedPagesList.length; ps++) {
          if (updatedPagesList[ps].index === id) {
            updatedPagesList.splice(ps, 1);
            break;
          }
        }
        setpagesList(updatedPagesList);
      } else {
        for (let fs = 0; fs < updatedFormsList.length; fs++) {
          if (updatedFormsList[fs].index === id) {
            updatedFormsList.splice(fs, 1);
            break;
          }
        }
        setformsList(updatedFormsList);
      }

      setList(updatedList);
      setMethod("content", updatedList);
      return;
    } else {
      for (let l = 0; l < updatedList.length; l++) {
        if (updatedList[l].id === index) {
          updatedList.splice(l, 1);
          break;
        }
      }

      if (contentIndex === "pages") {
        for (let p = 0; p < updatedPagesList.length; p++) {
          if (updatedPagesList[p].id === index) {
            const pJsonData = {
              id: index,
              // status: 1,
            };
            if (moduleData?.status !== "Template") {
              const pResponse = await dispatch(
                deleteModulePageAsync(pJsonData)
              );
              if (pResponse.payload) {
                updatedPagesList.splice(p, 1);
                break;
              }
            } else {
              updatedPagesList.splice(p, 1);
              break;
            }
          }
        }
        setpagesList(updatedPagesList);
      } else {
        for (let f = 0; f < updatedFormsList.length; f++) {
          if (updatedFormsList[f].id === index) {
            const fJsonData = {
              id: index,
              // status: 1,
            };
            if (moduleData?.status !== "Template") {
              const fResponse = await dispatch(
                deleteModuleFormAsync(fJsonData)
              );
              if (fResponse.payload) {
                updatedFormsList.splice(f, 1);
                break;
              }
            } else {
              updatedFormsList.splice(f, 1);
              break;
            }
          }
        }
        setformsList(updatedFormsList);
      }
    }

    setList(updatedList);
    setMethod("content", updatedList);
  };

  const onCancelClick = () => {
    const state = location.state;
    let propdata = { ...state.propsData };
    // console.log(operationType,'operationType');
    if (
      moduleData["id"] &&
      state?.operationType !== undefined &&
      state?.operationType === "Duplicate"
    ) {
      navigate.push("/authoring/modules/details", { id: propdata.id });
    } else if (
      state?.opertionType !== undefined &&
      state?.opertionType === "Edit" &&
      propdata.id
    ) {
      navigate.push("/authoring/modules/details", { id: propdata.id });
    } else if (
      state?.operationType !== undefined &&
      state?.operationType === "Add" &&
      !state?.redirectTo
    ) {
      navigate.push("/authoring/modules");
    } else if (
      state?.operationType !== undefined &&
      state?.operationType === "Add" &&
      state?.redirectTo
    ) {
      navigate.push(redirectTo);
    } else if (moduleData?.status === "Template") {
      navigate.push("/authoring/modules", {
        id: location?.state?.propsData?.id,
        backType: "Template",
      });
    } else {
      navigate.push("/authoring/modules");
    }
  };

  const onModuleSubmit = (status) => {
    if (status == undefined) {
      navigate.push("/authoring/modules");
    }
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/modules");
    }
    var errors = moduleValidation(currentFormData.values);

    if (
      (Object.keys(currentFormData.errors).length === 0 &&
        Object.keys(errors).length === 0 &&
        status) ||
      status === 1
    ) {
      let page = lisdata
        ?.filter((item) => item.contentType === "pages")
        .map((m) => {
          return { ...m };
        });
      let form = lisdata
        ?.filter((item) => item.contentType === "forms")
        .map((m) => {
          return { ...m };
        });

      if (
        currentFormData?.values?.id &&
        (operationType === undefined || operationType !== "Duplicate")
      ) {
        const reqBody = {
          //id: currentFormData?.values?.id,
          title: currentFormData?.values?.title,
          description: currentFormData?.values?.description,
          customTags: currentFormData?.values?.customTags,
          recommendedTime: currentFormData?.values?.recommendedTime
            ? currentFormData?.values?.recommendedTime
            : null,
          //  parseInt(moduleData["recommendedTime"]),
          recommendedTimeDurationType:
            currentFormData?.values?.recommendedTimeDurationType,
          // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
          // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
          skipAllowed: currentFormData?.values?.skipAllowed,
          isExpirable: currentFormData?.values?.isExpirable,
          expiryDate: currentFormData?.values?.isExpirable
            ? currentFormData?.values?.expiryDate
            : null,
          // estimatedTime: currentFormData?.values?.estimatedTime,
          estimatedTimeDurationType:
            currentFormData?.values?.estimatedTimeDurationType,
          status: currentFormData?.values?.status,
          restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
          restrictedIds: currentFormData?.values?.restrictedIds || [],
          // pages: pagesList,
          //Rakesh--------------changes>
          pages: page,
          forms: form,
        };
        if (operationType !== "Edit") {
          reqBody.id = currentFormData.values.id;
        }
        setFormDataReady(false);
        dispatch(
          status === 1
            ? createModuleDraftAsync(reqBody)
            : updateModuleAsync(reqBody)
        ).then((res) => {
          // ToasterAlertSucces(res, ErrrorMessage.UPDATE);
          ToasterAlertSucces(
            res,
            status === 1
              ? "Success! Your module has been saved as a draft."
              : "Your module was updated successfully."
          );
          if (redirectTo) {
            navigate.push(redirectTo, { id: moduleData.id });
          } else {
            navigate.push("/authoring/modules");
          }
        });
      } else {
        const reqBody = {
          title: currentFormData?.values?.title,
          description: currentFormData?.values?.description,
          customTags: currentFormData?.values?.customTags,
          recommendedTime: currentFormData?.values?.recommendedTime
            ? currentFormData?.values?.recommendedTime
            : null,
          recommendedTimeDurationType:
            currentFormData?.values?.recommendedTimeDurationType,
          // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
          // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
          skipAllowed: currentFormData?.values?.skipAllowed,
          isExpirable: currentFormData?.values?.isExpirable,
          expiryDate: currentFormData?.values?.isExpirable
            ? currentFormData?.values?.expiryDate
            : null,
          // estimatedTime: currentFormData?.values?.estimatedTime,
          estimatedTimeDurationType:
            currentFormData?.values?.estimatedTimeDurationType,
          status: currentFormData?.values?.status,
          restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
          restrictedIds: currentFormData?.values?.restrictedIds || [],
          pages: page ? page : null,
          forms: form ? form : null,
          // pages: pagesList,
          // forms: formsList,
        };
        setFormDataReady(false);
        dispatch(
          status === 1
            ? createModuleDraftAsync(reqBody)
            : createModuleAsync(reqBody)
        ).then((res) => {
          // const message = status === 1 ? ErrrorMessage.DRAFT : ErrrorMessage.CREATE;
          const message =
            status === 1
              ? "Success! Your module has been saved as a draft."
              : operationType === "Duplicate"
              ? "Module duplicated successfully!"
              : "Success! Your module has been created and published.";
          ToasterAlertSucces(res, message);
          if (
            redirectTo &&
            (operationType === undefined || operationType !== "Duplicate")
          ) {
            navigate.push(redirectTo, { state: { id: moduleData.id } });
          } else {
            navigate.push("/authoring/modules");
          }
        });
      }
    }
  };

  const templatePublish = (status) => {
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/modules");
    }
    var errors = moduleValidation(currentFormData.values);
    console.log(errors, "status");

    let page = lisdata
      ?.filter((item) => item.contentType === "pages")
      .map((m) => {
        return { ...m };
      });
    let form = lisdata
      ?.filter((item) => item.contentType === "forms")
      .map((m) => {
        return { ...m };
      });
    const reqBody = {
      title: currentFormData?.values?.title,
      description: currentFormData?.values?.description,
      customTags: currentFormData?.values?.customTags,
      recommendedTime: currentFormData?.values?.recommendedTime
        ? currentFormData?.values?.recommendedTime
        : null,
      recommendedTimeDurationType:
        currentFormData?.values?.recommendedTimeDurationType,
      // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
      // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
      skipAllowed: currentFormData?.values?.skipAllowed,
      isExpirable: currentFormData?.values?.isExpirable,
      expiryDate: currentFormData?.values?.isExpirable
        ? currentFormData?.values?.expiryDate
        : null,
      // estimatedTime: currentFormData?.values?.estimatedTime,
      estimatedTimeDurationType:
        currentFormData?.values?.estimatedTimeDurationType,
      status: 0,
      restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
      restrictedIds: currentFormData?.values?.restrictedIds || [],
      templateId: currentFormData?.values?.id,
      pages: page ? page : null,
      forms: form ? form : null,
      // pages: pagesList,
      // forms: formsList,
    };
    setFormDataReady(false);
    dispatch(createModuleAsync(reqBody)).then((res) => {
      // const message = ErrrorMessage.CREATE;
      // const message = "Success! Your module has been saved as a template.";
      const message = "Your Module has been created Successfully.";
      ToasterAlertSucces(res, message);
      if (
        redirectTo &&
        (operationType === undefined || operationType !== "Duplicate")
      ) {
        navigate.push(redirectTo, { state: { id: moduleData.id } });
      } else {
        navigate.push("/authoring/modules");
      }
    });
  };
  const templateSave = (status) => {
    // debugger;
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/modules");
    }
    var errors = moduleValidation(currentFormData.values);
    // console.log(currentFormData.values.status,'status')

    console.log(errors, "errorserrors");

    let page = lisdata
      ?.filter((item) => item.contentType === "pages")
      .map((m) => {
        return { ...m };
      });
    let form = lisdata
      ?.filter((item) => item.contentType === "forms")
      .map((m) => {
        return { ...m };
      });
    const reqBody = {
      title: currentFormData?.values?.title,
      description: currentFormData?.values?.description,
      customTags: currentFormData?.values?.customTags,
      recommendedTime: currentFormData?.values?.recommendedTime
        ? currentFormData?.values?.recommendedTime
        : null,
      recommendedTimeDurationType:
        currentFormData?.values?.recommendedTimeDurationType,
      // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
      // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
      skipAllowed: currentFormData?.values?.skipAllowed,
      isExpirable: currentFormData?.values?.isExpirable,
      expiryDate: currentFormData?.values?.isExpirable
        ? currentFormData?.values?.expiryDate
        : null,
      // estimatedTime: currentFormData?.values?.estimatedTime,
      estimatedTimeDurationType:
        currentFormData?.values?.estimatedTimeDurationType,
      status: 7,
      restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
      restrictedIds: currentFormData?.values?.restrictedIds || [],
      pages: page ? page : null,
      forms: form ? form : null,
      // pages: pagesList,
      // forms: formsList,
    };
    setFormDataReady(false);
    dispatch(createModuleAsync(reqBody)).then((res) => {
      // const message = ErrrorMessage.CREATE;
      // const message = "Success! Your module has been saved as a template.";
      const message = "Your Module has been created Successfully.";
      ToasterAlertSucces(res, message);
      navigate.push("/authoring/modules");
    });
  };
  // const setPageFormData = async (id) => {
  //   let response = await dispatch(getgetModulePageFormsAsync(id));
  //   console.log(response, 'responseresponseresponseresponse');
  //   if (response.payload) {
  //     let apiData = [];
  //     let apilistData = [];

  //     if (response.payload?.pages.length > 0) {
  //       response.payload.pages.map((m, index) => {
  //         apiData.push({
  //           ...m,
  //           index: index,
  //           contentType: "pages",
  //         });
  //         apilistData.push({
  //           ...m,
  //           index: index,
  //           contentType: "pages",
  //         });
  //       });
  //       setpagesList(apiData);
  //     }
  //     if (response.payload?.forms.length > 0) {
  //         apiData = [];
  //       response.payload.forms.map((m, index) => {
  //         apiData.push({
  //           ...m,
  //           index: index,
  //           contentType: "forms",
  //         });
  //             apilistData.push({
  //               ...m,
  //               index: index,
  //               contentType: "forms",
  //             });
  //           });

  //         let formData = [];
  //         apiData &&
  //           apiData.forEach((question) => {
  //             let questions = [];
  //             question.questions.forEach((specificFiles) => {
  //               let specificFileTypes = [];
  //               specificFiles.specificAllowedFiles.map((item, i) => {
  //                 specificFileTypes.push(item.id);
  //               });
  //               questions.push({ ...specificFiles, specificFileTypes });
  //             });

  //             formData.push({ ...question, questions });
  //           });
  //         if (apiData && apiData) {
  //           setformsList(formData);
  //         }
  //       }

  //     setList(apilistData);
  //       console.log(list, "list")
  //     }
  //   };

  const setPageFormData = async (id) => {
    let response = await dispatch(getSortedPageFormsAsync(id));
    console.log(response, "response");

    if (response.payload) {
      const apiData = [];
      const apilistData = [];

      if (response.payload.content.length > 0) {
        response.payload.content.forEach((m, index) => {
          if (m.page) {
            apiData.push({
              ...m.page,
              index: index,
              form: null,
              contentType: "pages",
            });
            apilistData.push({
              ...m.page,
              index: index,
              form: null,
              contentType: "pages",
            });
          } else if (m.form) {
            apiData.push({
              ...m.form,
              index: index,
              page: null,
              contentType: "forms",
            });
            apilistData.push({
              ...m.form,
              index: index,
              page: null,
              contentType: "forms",
            });
          }
        });

        const pagesList = apiData.filter(
          (item) => item.contentType === "pages"
        );
        const formsList = apiData.filter(
          (item) => item.contentType === "forms"
        );

        setpagesList(pagesList);
        setformsList(formsList);
      }

      setList(apilistData);
      console.log(list, "list");
    }
  };

  const handlePagesForms = (
    value,
    type,
    contentIndex,
    setMethod,
    setTouched,
    formTouchedData
  ) => {
    value["index"] = list.length + 1;
    value["contentType"] = type;
    // if (type == "pages") {
    //   if (contentIndex !== undefined) {
    //     pagesList[contentIndex] = value;
    //     setpagesList([...pagesList]);
    //   } else {
    //     const nextPageCount = pagesList.length == 0 ? 0 : pagesList.length + 1;
    //     value["index"] = nextPageCount;
    //     setpagesList([...pagesList, value]);
    //   }
    // } else {
    //   if (contentIndex !== undefined) {
    //     formsList[contentIndex] = value;
    //     setformsList([...formsList]);
    //   } else {
    //     const nextFormCount = formsList.length == 0 ? 0 : formsList.length + 1;
    //     value["index"] = nextFormCount;
    //     setformsList([...formsList, value]);
    //   }
    // }
    if (requestedIndex != null) {
      list[requestedIndex] = value;
    } else {
      list.push({ ...value, contentType: type });
    }
    setList([...list]);
    setformsList([...list]);
    setpagesList([...list]);
    console.log(list, "list2");
    setTouched({ ...formTouchedData, ["content"]: true });
    setMethod("content", list);
    console.log(formsList, "list update");
  };

  const [formDataReady, setFormDataReady] = useState(false);
  const formRef = useRef();

  useEffect(() => {
    if (operationType === "Add") {
      getUserUsage();
      dispatch(getSubscriptionAsync());
      dispatch(getPlansAsync());
    }
  }, [operationType]);

  useEffect(() => {
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (operationType === "Add") {
      if (hasSubscriptions && hasItems) {
        const basePlan = subscriptionData?.subscriptionData?.plans.filter(
          (plan) => plan.statementDescriptor === "base_plan"
        );
        const planModules =
          basePlan && basePlan?.length > 0
            ? parseInt(basePlan[0]?.metaData?.modules)
            : 0;
        console.log(planModules, "planModules");
        console.log(userUsage, "userUsage");
        setIsRestricted(
          planModules === -1
            ? false
            : userUsage?.modulesCount >= planModules
            ? true
            : false
        );
      } else {
        setIsRestricted(true);
      }
    }
  }, [subscriptionData, plansData, userUsage, operationType]);

  useEffect(() => {
    console.log();
    if (operationType === "Duplicate") {
      setIsUniqueTitle(false);
    }

    const state = location.state;
    if (state != undefined && state.propsData != undefined) {
      let propdata = { ...state.propsData };
      let moduleId = propdata.id;
      if (operationType == "Duplicate") {
        propdata.id = undefined;
      }
      SetModuleData(propdata);
      const recommendedTimeDurationType = optionsTime.filter(
        (f) => f.value == propdata.recommendedTimeDurationType
      );
      setRecommendedTimeDurationType({
        value: recommendedTimeDurationType[0]?.value,
        label: recommendedTimeDurationType[0]?.label,
      });

      // const reminderRecommendedTimeDurationType = optionsTime.filter(
      //   (f) => f.value == propdata.reminderRecommendedTimeDurationType
      // );
      // setReminderRecommendedTimeDurationType({
      //   value: reminderRecommendedTimeDurationType[0]?.value,
      //   label: reminderRecommendedTimeDurationType[0]?.label,
      // });

      const estimatedTimeDurationType = optionsTime.filter(
        (f) => f.value == propdata.estimatedTimeDurationType
      );
      setestimatedTimeDurationType({
        value: estimatedTimeDurationType[0]?.value,
        label: estimatedTimeDurationType[0]?.label,
      });
      if (list.length == 0 && propdata.id != "") {
        setPageFormData(moduleId);
      }

      if (state?.propsData?.restrictedTeamIds) {
        const restrictedTeamIds = state?.propsData?.restrictedTeamIds.map(
          (value) => ({ value: value.id, label: value.name })
        );
        setSelectedTeam(restrictedTeamIds);
      }
      if (state?.propsData?.restrictedIds) {
        const restrictedIds = state?.propsData?.restrictedIds.map((value) => ({
          value: value.employeeId,
          label: value.employeeName,
          teamId: value.teamId,
        }));
        setSelectedUsers(restrictedIds);
        if (restrictedIds) {
          setShowMultiSelect(true);
        }
      }
      console.log(location, "location");
    } else {
      SetModuleData(defaultData);
    }
    setFormDataReady(true);
  }, []);

  const getHeaderTitle = (id, operationType) => {
    return id !== undefined && id !== ""
      ? "Edit module"
      : operationType !== "Duplicate"
      ? "Create a module"
      : "Create a module";
  };

  const getSubmitTitle = (id, opType) => {
    if (moduleData?.status === "Draft") {
      return "Publish";
    } else if (moduleData?.status === "Template") {
      return "Update and Publish";
    } else {
      return id !== undefined && id !== ""
        ? "Update"
        : opType !== "Duplicate"
        ? "Publish"
        : "Publish";
    }
  };

  // const getTemplateTitle = (id, opType) => {
  //     return id !== undefined && id !== ""
  //       ? "Update the template"
  //       : "Save as template";
  //   };
  const getTemplateTitle = (id, opType) => {
    if (moduleData?.status === "Template") {
      return id !== undefined && id !== "" ? "Update the template" : "";
    } else {
      return id !== undefined && id !== ""
        ? "Save as template"
        : "Save as template";
    }
  };

  const getHeaderTitleUrl = (redirectToUrl) => {
    const state = location.state;
    let propdata = { ...state.propsData };
    return redirectToUrl !== undefined
      ? {
          pathname: redirectToUrl,
          state: { id: propdata.id },
        }
      : moduleData?.status === "Template"
      ? {
          pathname: "/authoring/modules",
          state: { backType: "Template" },
        }
      : "/authoring/modules";
  };
  const moduleValidation = (values) => {
    const state = location.state;
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    } else if (
      pagesList &&
      pagesList.find((form) => form.title === values.title)
    ) {
      errors.title = "Title already exists!";
    } else if (
      formsList &&
      formsList.find((form) => form.title === values.title)
    ) {
      errors.title = "Title already exists!";
    }

    if (!values.description || values.description.trim() === "") {
      errors.description = "Description is required!";
    } else {
      // Remove multiple consecutive white spaces and line breaks, and count a single space as a character
      const cleanedDescription = values.description.replace(/[\s\n]+/g, " ");

      if (cleanedDescription.length < 116) {
        errors.description =
          "The minimum requirement for the description should be 116 characters.";
      }
    }

    if (!values.customTags || values.customTags.trim() === "") {
      errors.customTags = "Please add tags for your module.";
    }
    if (
      (!values.content || values.content.length === 0) &&
      (!list || list.length === 0)
    ) {
      errors.content =
        "The content field cannot be empty. Please add content for your module.";
    }

    if (!values.expiryDate && values.isExpirable === true) {
      errors.expiryDate = "Date is required and should be in future!";
    } else if (
      values.isExpirable === true &&
      values.expiryDate < new Date()
      // && (values.id === undefined || values.id === "")
    ) {
      errors.expiryDate = "Date is required and should be in future!";
    }
    // if (!values.estimatedTime && values.estimatedTime !== 0) {
    //   errors.estimatedTime = "Estimated time is required!";
    // } else if (values.estimatedTime <= 0) {
    //   errors.estimatedTime = "Estimated time should be grater then 0";
    // }

    // if (!values.reminderRecommendedTime && values.reminderRecommendedTime !== 0) {
    //   errors.reminderRecommendedTime = "Reminder time is required!";
    // } else if (values.reminderRecommendedTime <= 0) {
    //   errors.reminderRecommendedTime = "Reminder time should be grater then 0";
    // }

    if (!values.recommendedTime && values.recommendedTime !== 0) {
      errors.recommendedTime = "Recommended time is required!";
    } else if (values.recommendedTime <= 0) {
      errors.recommendedTime = "Recommended time should be greater then 0";
    }
    // console.log(errors,'errors');
    return errors;
  };

  const moduleDraftValidation = (values) => {
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    } else if (
      pagesList &&
      pagesList.find((form) => form.title === values.title)
    ) {
      errors.title = "Title already exists!";
    } else if (
      formsList &&
      formsList.find((form) => form.title === values.title)
    ) {
      errors.title = "Title already exists!";
    }
    return errors;
  };
  const onSelectationChange = (e, colName, setMethod, localState) => {
    localState({
      value: e.value,
      label: e.label,
    });
    if (setMethod) {
      setMethod(colName, e.value);
    }
  };
  const onSelectationChanges = (
    selectedValues,
    colName,
    setMethod,
    localState,
    prevSelectedValues // Add the previous selected values as a parameter
  ) => {
    // setRestrictModal(true);
    setRestrictEmployee(false);
    const selectedLocalTeamIds = selectedValues.map((value) => ({
      value: value.value,
      label: value.label,
    }));
    const selectedMethodTeamIds = selectedValues.map((value) => value.value);

    // console.log(selectedValues,'selectedValues');
    // console.log(selectedLocalTeamIds,'selectedLocalTeamIds');
    // console.log(selectedMethodTeamIds,'selectedMethodTeamIds');

    if (selectedMethodTeamIds.length > 0) {
      localState(selectedLocalTeamIds);
      setMethod(colName, selectedMethodTeamIds);

      // Check for removed items
      // console.log(selectedValues, 'selectedValues');
      // console.log(prevSelectedValues, 'prevSelectedValues');
      const removedItems = prevSelectedValues.filter(
        (prevItem) =>
          !selectedValues.some((newItem) => prevItem.value === newItem.value)
      );

      if (removedItems.length > 0) {
        // Extract the teamId values from removedItem
        const removedTeamIds = removedItems.map((item) => item.value);

        // Remove objects from selectedUser where teamId matches removedTeamIds
        const updatedSelectedUser = selectedUsers.filter(
          (user) => !removedTeamIds.includes(user.teamId)
        );

        // Append the updatedSelectedUser to the state
        setSelectedUsers(updatedSelectedUser);
        setMethod(
          "restrictedIds",
          updatedSelectedUser.map((user) => user.value)
        );
        setRestrictModal(false); // Example: Close or hide the modal when an item is removed
      } else {
        setRestrictedTeam(selectedLocalTeamIds);
        setRestrictModal(true);
      }
    } else {
      // localState(selectedLocalTeamIds);
      setSelectedUsers([]);
      setSelectedTeam([]);
      setMethod(colName, []);
      setMethod("restrictedIds", []);
      setShowMultiSelect(false);
      setRestrictModal(false);
    }
  };

  const onSelectationUserChanges = (
    selectedValues,
    colName,
    setMethod,
    localState
  ) => {
    // console.log(selectedValues,'selectedValues');
    // setSelectedUsers(selectedValues);
    if (setMethod) {
      setMethod(
        colName,
        selectedValues.map((user) => user.value)
      );
    }
    if (localState) {
      localState(
        selectedValues.map((user) => ({
          value: user.value,
          label: user.label,
          teamId: user.teamId,
        }))
      );
    }
  };
  const onTagsUpdate = (
    updatedList,
    setFieldValue,
    setTouchedMethod,
    formTouchedData
  ) => {
    let stringdata = updatedList
      .map((m) => {
        return m.tag;
      })
      .toString();

    if (setTouchedMethod) {
      setTouchedMethod({ ...formTouchedData, ["customTags"]: true });
    }
    setFieldValue("customTags", stringdata);
  };

  // let hasUnpublishedContent = pagesList.some(
  //   (m) => m.status === 1
  // ) || formsList.some(
  //   (m) => m.status === 1
  // );
  const [hasUnpublishedContent, setHasUnpublishedContent] = useState(
    pagesList.some((m) => m.status === 1) ||
      formsList.some((m) => m.status === 1)
  );
  const handleRemoveItem = () => {
    setDraftCount(draftCount - 1);
  };

  const getUserUsage = () => {
    dispatch(getUserUsageAsync()).then((res) => {
      if (res.payload) {
        setUserUsage(res.payload);
      }
    });
  };

  const getRestrictedPopUp = () => {
    console.log(operationType, "operationTypeoperationType");
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      const planModules =
        basePlan && basePlan?.length > 0
          ? parseInt(basePlan[0]?.metaData?.modules)
          : 0;
      return planModules === -1 ? false : userUsage?.modulesCount >= planModules ? true : false;
    } else {
      return true;
    }
  };

  console.log(subscriptionData, "subscriptionData");
  console.log(plansData, "plansData");
  console.log(userUsage, "userUsage");

  const [draftCount, setDraftCount] = useState(0);
  let listItem = list.some((m) => m.status === 1);
  // useEffect(() => {},[draftCount]);
  // console.log(draftCount, "draftCount");
  // console.log(hasUnpublishedContent, "hasUnpublishedContent");
  // console.log(list, "list");
  return (
    <>
      <div className={ftue ? "ftue pages" : "pages"}>
        {ftue && <div className="ftueOver"></div>}
        <PageHeaderSection
          redirectTo={getHeaderTitleUrl(redirectTo)}
          title={getHeaderTitle(moduleData?.id, operationType)}
        ></PageHeaderSection>

        <div className="pageOuter">
          <Container fluid>
            {!isRestricted && (
              <div className="page createModulePage d-flex h-100">
                <div className="w-100 d-flex flex-column">
                  {isSubmittted === true && <Loader />}
                  {formDataReady && (
                    <Formik
                      initialValues={{
                        id: moduleData?.id ?? undefined,
                        title: moduleData?.title,
                        description: moduleData?.description,
                        customTags: moduleData?.customTags,
                        content: moduleData.content,
                        recommendedTime: moduleData?.recommendedTime ?? 1,
                        recommendedTimeDurationType:
                          moduleData?.recommendedTimeDurationType ?? 0,
                        // reminderRecommendedTime: moduleData?.reminderRecommendedTime ?? 1,
                        // reminderRecommendedTimeDurationType: moduleData?.reminderRecommendedTimeDurationType ?? 1,
                        // estimatedTime: moduleData?.estimatedTime ?? 1,
                        skipAllowed: moduleData?.skipAllowed ?? false,
                        status: undefined,
                        isExpirable: moduleData?.isExpirable ?? false,
                        expiryDate: moduleData?.isExpirable
                          ? new Date(moduleData?.expiryDate)
                          : null,
                        isCompletedTimeLine:
                          moduleData?.isCompletedTimeLine ?? false,
                        completedTimeLine: moduleData?.completedTimeLine
                          ? new Date(moduleData?.completedTimeLine)
                          : new Date(),
                      }}
                      onSubmit={onModuleSubmit}
                      validate={moduleValidation}
                      innerRef={formRef}
                    >
                      {({
                        errors,
                        touched,
                        isValidating,
                        values,
                        validateField,
                        setFieldValue,
                        setTouched,
                        handleSubmit,
                      }) => {
                        return (
                          <Form style={{ display: "contents" }}>
                            <div className="flex-grow-1 overflow-auto">
                              <Row className="g-0 d-flex h-100">
                                <Col className="col-lg-8 flex-grow-1 overflow-auto h-100">
                                  <div className="p-32 pb-4">
                                    <div className="input-group myInput mb-32">
                                      <TextInput
                                        name="title"
                                        label="Title"
                                        className="form-control"
                                        placeholder="E.g HR Operations"
                                        isRequired={true}
                                        // onFocus={() => {
                                        //   if(operationType === "Duplicate")
                                        //   setTitleFocused(true);
                                        // }}
                                        // onBlur={(event) => {
                                        //   validateTitle(
                                        //     event?.target?.value,
                                        //     validateField,
                                        //     touched,
                                        //     setTouched
                                        //   );
                                        // }}
                                        onInput={(event) => {
                                          debouncedValidateTitle(
                                            event?.target?.value,
                                            validateField,
                                            touched,
                                            setTouched
                                          );
                                        }}
                                        maxLength={40}
                                      />
                                      {operationType === "Duplicate" &&
                                        isCheckFirstTitleDuplicate === true &&
                                        errors.title && (
                                          <label
                                            className="errorValidation"
                                            style={{ color: "red" }}
                                          >
                                            {errors.title}
                                          </label>
                                        )}
                                    </div>
                                    <div className="input-group myInput mb-32 labelFor">
                                      <TextArea
                                        label="Description"
                                        name="description"
                                        className="form-control"
                                        rows="3"
                                        placeholder="Add text..."
                                        isRequired={true}
                                        maxLength={250}
                                      />
                                    </div>

                                    <div className="mb-32">
                                      <TagsInput
                                        name="customTags"
                                        inputTags={moduleData?.customTags ?? ""}
                                        onChange={onTagsUpdate}
                                        setMethod={setFieldValue}
                                        touchedMethod={setTouched}
                                        touchedData={touched}
                                      />
                                      {touched.customTags &&
                                        errors.customTags && (
                                          <label
                                            className="errorValidation"
                                            style={{ color: "red" }}
                                          >
                                            {errors.customTags}
                                          </label>
                                        )}
                                    </div>
                                    <div>
                                      <p className="h6 font-medium pb-2 mb-1">
                                        Content
                                        <span
                                          className="mandatoryFields"
                                          style={{ color: "red" }}
                                        >
                                          *
                                        </span>
                                      </p>
                                      {listItem && (
                                        <p style={{ color: "red" }}>
                                          Your module cannot be published or
                                          saved as a template as the added
                                          content is in the draft state.
                                        </p>
                                      )}
                                      <ContentDraggable
                                        list={list}
                                        setList={setList}
                                        onClick={draggableclick}
                                        onRemove={draggableclickForRemove}
                                        setMethod={setFieldValue}
                                        setlistData={setlistData}
                                        hasUnpublishedContent={
                                          setHasUnpublishedContent
                                        }
                                        removeItemCallback={handleRemoveItem}
                                        moduleId={moduleData?.id}
                                        operationType={operationType}
                                      />

                                      {ftue ? (
                                        <button
                                          className="d-flex gap-3 align-items-center ftuePoint bg-base-100 rounded-1 px-2"
                                          ref={target}
                                        >
                                          <Button
                                            variant=""
                                            className="d-flex gap-2 btnLink"
                                          >
                                            <IconCreatePage />
                                            <span>Create a page</span>
                                          </Button>
                                          <div>
                                            <IconPipe />
                                          </div>
                                          <Button
                                            variant=""
                                            className="d-flex gap-2 btnLink"
                                          >
                                            <IconCreateForm />{" "}
                                            <span>Create a form</span>
                                          </Button>
                                        </button>
                                      ) : (
                                        <>
                                          <div className="d-flex gap-3 align-items-center btnLink">
                                            <Button
                                              variant=""
                                              className="d-flex gap-2 btnLink"
                                              onClick={() =>
                                                childCreateRequest("pages")
                                              }
                                            >
                                              <IconCreatePage />
                                              <span>Create a page</span>
                                            </Button>

                                            <div>
                                              <IconPipe />
                                            </div>
                                            <Button
                                              variant=""
                                              className="d-flex gap-2 btnLink"
                                              onClick={() =>
                                                childCreateRequest("forms")
                                              }
                                            >
                                              <IconCreateForm />
                                              <span>Create a form</span>
                                            </Button>
                                            {/* <button
                                              onClick={() =>
                                                childCreateRequest("forms")
                                              }
                                              className="d-flex gap-2 text-primary-200"
                                            >
                                              <IconCreateForm />
                                              <h6>Create a form</h6>
                                            </button> */}
                                          </div>

                                          <div className="mt-2">
                                            {touched.content &&
                                              errors.content && (
                                                <label
                                                  className="errorValidation"
                                                  style={{ color: "red" }}
                                                >
                                                  {errors.content}
                                                </label>
                                              )}
                                          </div>
                                        </>
                                      )}

                                      <Overlay
                                        target={target.current}
                                        show={showTool}
                                        placement="right"
                                      >
                                        {(props) => (
                                          <Tooltip
                                            {...props}
                                            className="tootltipCreateModule "
                                          >
                                            <button className="crossBtn">
                                              <svg
                                                width="10"
                                                height="10"
                                                viewBox="0 0 10 10"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  fillRule="evenodd"
                                                  clipRule="evenodd"
                                                  d="M9.65561 1.40533C9.9485 1.11244 9.9485 0.637563 9.65561 0.34467C9.36271 0.0517766 8.88784 0.0517766 8.59495 0.34467L5.00008 3.93954L1.40521 0.34467C1.11231 0.0517766 0.637441 0.0517766 0.344548 0.34467C0.0516546 0.637563 0.0516546 1.11244 0.344548 1.40533L3.93942 5.0002L0.344548 8.59507C0.0516546 8.88796 0.0516546 9.36283 0.344548 9.65573C0.637441 9.94862 1.11231 9.94862 1.40521 9.65573L5.00008 6.06086L8.59495 9.65573C8.88784 9.94862 9.36271 9.94862 9.65561 9.65573C9.9485 9.36283 9.9485 8.88796 9.65561 8.59507L6.06074 5.0002L9.65561 1.40533Z"
                                                  fill="#232323"
                                                />
                                              </svg>
                                            </button>
                                            <div className="borderBottom">
                                              <div className="d-flex gap-4 align-items-center p-32 mt-2">
                                                <img src={createForm} alt="" />

                                                <div className="text-start">
                                                  <h3 className="mb-12">
                                                    Create a Page / Form
                                                  </h3>
                                                  <p className="text-gray-300 h5 mb-2">
                                                    Lorem ipsum dolor sit amet,
                                                    consectetuer adipiscing
                                                    elit. Aenean commodo ligula.
                                                  </p>
                                                </div>
                                              </div>

                                              <div
                                                className="progress tableProgress bg-base-100"
                                                style={{ height: "4px" }}
                                              >
                                                <div
                                                  className="progress-bar"
                                                  role="progressbar"
                                                  style={{ width: "60%" }}
                                                ></div>
                                              </div>
                                            </div>

                                            <div className="d-flex justify-content-between align-items-center p-32 py-3 bg-gray-600">
                                              <p className="mb-0 h5">
                                                {" "}
                                                <span className="text-gray-300 font-medium">
                                                  Click on
                                                </span>{" "}
                                                the guide{" "}
                                                <span className="text-gray-300 font-medium">
                                                  or Press
                                                </span>{" "}
                                                enter
                                              </p>

                                              <div className="d-flex gap-2">
                                                <Button
                                                  variant=""
                                                  size="sm"
                                                  className="font-medium text-gray-300 border-0"
                                                >
                                                  PREV
                                                </Button>
                                                <Button
                                                  variant="primary"
                                                  size="sm"
                                                >
                                                  NEXT
                                                </Button>
                                              </div>
                                            </div>
                                          </Tooltip>
                                        )}
                                      </Overlay>
                                    </div>
                                  </div>
                                </Col>

                                <Col className="col-lg-4 bg-gray-600 flex-grow-1 overflow-auto h-100">
                                  <div className="bg-gray-600 p-32 px-4 h-100">
                                    <div className="mb-32">
                                      {/* <p className="font-medium h6 mb-3">
                                        Module Owners
                                      </p> */}
                                      {/* <div className="d-flex">
                                        <div className="headImg moduleImg">
                                          <img src={OwnerImg} alt="" />
                                        </div>
                                        <div className="headImg moduleImg">
                                          <img src={OwnerImg} alt="" />
                                        </div>
                                        <div className="headImg moduleImg">
                                          <img src={OwnerImg} alt="" />
                                        </div>
                                      </div> */}
                                      <OwnerImgCount title="Module Owners" />
                                    </div>

                                    {/* <div className="mb-3">
                                        <p className="h6 font-medium mb-12">
                                          Reminder time to alert
                                          <span
                                            className="mandatoryFields"
                                            style={{ color: "red" }}
                                          >
                                            *
                                          </span>
                                        </p>
                                        <div className="row">
                                          <div className="col-6">
                                            <div className="input-group myInput">
                                              <TextInput
                                                type="number"
                                                name="reminderRecommendedTime"
                                                className="form-control"
                                                min={0}
                                                step={1}
                                              />
                                            </div>
                                          </div>
                                          <div className="col-6">
                                            <div className="form-group mySelect">
                                              <Select
                                                isSearchable={false}
                                                name="reminderRecommendedTimeDurationType"
                                                className="select"
                                                classNamePrefix="selectCustom"
                                                options={optionsTime}
                                                // defaultValue={
                                                //   reminderRecommendedTimeDurationType
                                                // }
                                                value={reminderRecommendedTimeDurationType}
                                                onChange={(e) =>
                                                  onSelectationChange(
                                                    e,
                                                    "reminderRecommendedTimeDurationType",
                                                    setFieldValue,
                                                    setReminderRecommendedTimeDurationType
                                                  )
                                                }
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div> */}

                                    <div className="mb-3">
                                      <p className="h6 font-medium mb-12">
                                        Recommended time to complete
                                        <span
                                          className="mandatoryFields"
                                          style={{ color: "red" }}
                                        >
                                          *
                                        </span>
                                      </p>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="input-group myInput">
                                            <TextInput
                                              type="number"
                                              name="recommendedTime"
                                              className="form-control"
                                              min={1}
                                              step={1}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="form-group mySelect">
                                            <Select
                                              isSearchable={false}
                                              name="recommendedTimeDurationType"
                                              className="select"
                                              classNamePrefix="selectCustom"
                                              options={optionsTime}
                                              // defaultValue={
                                              //   recommendedTimeDurationType
                                              // }
                                              value={
                                                recommendedTimeDurationType
                                              }
                                              onChange={(e) =>
                                                onSelectationChange(
                                                  e,
                                                  "recommendedTimeDurationType",
                                                  setFieldValue,
                                                  setRecommendedTimeDurationType
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="mb-32">
                                      <p className="h6 font-medium mb-20">
                                        Other Settings
                                      </p>
                                      {/* <p className="h6 text-gray-200 mb-20">
                                        <CheckBoxInput
                                          name="skipAllowed"
                                          label="Skip allowed"
                                          value={values?.skipAllowed ?? false}
                                        />
                                      </p> */}

                                      <p className="h6 text-gray-200 mb-20">
                                        <CheckBoxInput
                                          name="isExpirable"
                                          label="Set Expiry date"
                                          value={values?.isExpirable ?? false}
                                          onChange={(isChecked) => {
                                            setFieldValue(
                                              "isExpirable",
                                              isChecked?.target?.checked
                                            );
                                            if (!isChecked?.target?.checked) {
                                              setFieldValue("expiryDate", null);
                                            }
                                          }}
                                        />
                                      </p>
                                      {values?.isExpirable == true && (
                                        <div className="input-group myInput mb-32 ms-2 ps-4 yAxisCalendar dateCalendar iconbig dateCalendarbgico">
                                          {/* <IconCalendar /> */}
                                          <DatePicker
                                            selected={
                                              values?.expiryDate
                                                ? new Date(values?.expiryDate)
                                                : null
                                            }
                                            className="form-control"
                                            onChange={(date) =>
                                              setFieldValue(
                                                "expiryDate",
                                                date
                                                  ? moment(
                                                      new Date(date)
                                                    ).format(
                                                      "YYYY-MM-DDThh:mm:ssZ"
                                                    )
                                                  : ""
                                              )
                                            }
                                            name="expiryDate"
                                            minDate={new Date()}
                                            autoComplete="off"
                                          />
                                          {errors.expiryDate && (
                                            <label
                                              className="errorValidation"
                                              style={{ color: "red" }}
                                            >
                                              {errors.expiryDate}
                                            </label>
                                          )}
                                        </div>
                                      )}
                                    </div>
                                    {/* {getHeaderTitle(moduleData?.id, operationType) !== "Edit module" && ( */}
                                    <>
                                      <div className="mb-12 d-flex justify-content-between align-items-center">
                                        <p className="h6 font-medium ">
                                          Access Restrictions
                                        </p>
                                        {/* <p className="h6 font-bold text-primary-200">
                                      Advanced
                                    </p> */}
                                      </div>

                                      <div
                                        className="multiSelectMenuTop"
                                        style={{ marginBottom: "1.25rem" }}
                                      >
                                        <MultiSelectDropdown
                                          name="restrictedTeamIds"
                                          isSearchable={false}
                                          autofocus={true}
                                          // menuPlacement="auto"
                                          className="select"
                                          classNamePrefix="selectCustom"
                                          options={optionsTeam}
                                          selectedValues={selectedTeam}
                                          placeholder="Search by Team"
                                          onSelect={(selectedTeams) =>
                                            onSelectationChanges(
                                              selectedTeams,
                                              "restrictedTeamIds",
                                              setFieldValue,
                                              setSelectedTeam,
                                              selectedTeam // Pass the current selectedTeam as prevSelectedValues
                                            )
                                          }
                                          // onSelect={(selectedTeam) =>
                                          //   onSelectationChanges(
                                          //     selectedTeam,
                                          //     "restrictedTeamIds",
                                          //     setFieldValue,
                                          //     setSelectedTeam
                                          //   )
                                          // }
                                          setMethod={setFieldValue}
                                        />
                                      </div>
                                      {showMultiSelect && (
                                        <div className="multiSelectMenuTop">
                                          <MultiSelectDropdown
                                            name="restrictedIds"
                                            isSearchable={false}
                                            className="select"
                                            classNamePrefix="selectCustom"
                                            options={
                                              optionsUser
                                                ? optionsUser.filter(
                                                    (user) =>
                                                      // selectedTeam.includes(user.teamId) || selectedUsers.includes(user.value)
                                                      selectedTeam.some(
                                                        (team) =>
                                                          team.value ===
                                                          user.teamId
                                                      ) ||
                                                      selectedUsers.some(
                                                        (team) =>
                                                          team.teamId ===
                                                          user.teamId
                                                      )
                                                  )
                                                : []
                                            }
                                            selectedValues={selectedUsers}
                                            placeholder="Search by Users"
                                            onSelect={(selectedUsers) =>
                                              onSelectationUserChanges(
                                                selectedUsers,
                                                "restrictedIds",
                                                setFieldValue,
                                                setSelectedUsers
                                              )
                                            }
                                            setMethod={setFieldValue}
                                            // onRemove={(selectedUsers) =>
                                            //   onSelectationUserChanges(
                                            //     selectedUsers,
                                            //     "restrictedIds",
                                            //     setFieldValue,
                                            //     setSelectedUsers
                                            //   )
                                            // }
                                          />
                                        </div>
                                      )}
                                    </>
                                    {/* )} */}
                                    {/* <div className="mb-32">
                                      <p className="h6 font-medium mb-12">
                                        Estimated time
                                        <span className="mandatoryFields" style={{ color: "red" }}>*</span>
                                      </p>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="input-group myInput">
                                            <TextInput
                                              type="number"
                                              name="estimatedTime"
                                              className="form-control"
                                              min={0}
                                              step={1}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="form-group mySelect">
                                            <Select
                                              isSearchable={false}
                                              name="estimatedTimeDurationType"
                                              className="select"
                                              classNamePrefix="selectCustom"
                                              options={optionsTime}
                                              defaultValue={
                                                estimatedTimeDurationType
                                              }
                                              value={estimatedTimeDurationType}
                                              onChange={(e) =>
                                                onSelectationChange(
                                                  e,
                                                  "recommendedTimeDurationType",
                                                  setFieldValue,
                                                  setestimatedTimeDurationType
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div> */}
                                  </div>
                                </Col>
                              </Row>
                            </div>
                            <div className="d-flex gap-20 align-items-center btnFooter pe-4">
                              <Button
                                type="button"
                                variant="secondary"
                                disabled={
                                  Object.keys(moduleValidation(values))
                                    .length !== 0 || listItem
                                }
                                // onClick={() => onModuleSubmit(0)}
                                // onClick={() => setFieldValue("status", 0)}
                                onClick={() =>
                                  handleActionClick(
                                    moduleData?.status === "Template"
                                      ? "publish"
                                      : "update_publish",
                                    validateField,
                                    touched,
                                    setTouched,
                                    handleSubmit,
                                    setFieldValue,
                                    values
                                  )
                                }
                                // Hiden to check validation
                                //   onClick={() => {
                                //   if (moduleData?.status === "Template") {
                                //     templatePublish(0);
                                //   }else{
                                //   setFieldValue("status", 0);
                                //   }
                                // }}
                              >
                                {getSubmitTitle(moduleData?.id, operationType)}
                              </Button>
                              <Button
                                disabled={
                                  Object.keys(moduleValidation(values))
                                    .length !== 0 || listItem
                                }
                                type="button"
                                variant="secondary2"
                                //onClick={() =>setFieldValue("status", 7)}
                                onClick={() =>
                                  handleActionClick(
                                    moduleData?.id &&
                                      moduleData?.status !== "Template"
                                      ? "save_as_template"
                                      : "update",
                                    validateField,
                                    touched,
                                    setTouched,
                                    handleSubmit,
                                    setFieldValue,
                                    values
                                  )
                                }
                                //   onClick={() => {
                                //   if (moduleData?.id && moduleData?.status !== "Template") {
                                //     templateSave(7);
                                //   }else{
                                //   setFieldValue("status", 7);
                                //   }
                                // }}
                              >
                                {getTemplateTitle(
                                  moduleData?.id,
                                  operationType
                                )}
                                {/* Save as Template */}
                              </Button>
                              {(operationType === "Duplicate" || moduleData?.status === "Template" ||
                                !moduleData?.status ||
                                (moduleData?.status === "Draft" &&
                                  moduleData?.status !== "Published")) && (
                                <Button
                                  type="submit"
                                  variant="noBg"
                                  disabled={
                                    isButtonDisabled ||
                                    Object.keys(moduleDraftValidation(values))
                                      .length !== 0 ||
                                    titleFocused
                                  }
                                  onClick={() => {
                                    setButtonDisabled(true);
                                    onModuleSubmit(1);
                                  }}
                                  // onClick={() => setFieldValue("status", 1)}
                                >
                                  Save as draft
                                </Button>
                              )}
                              <Button
                                className="ml-auro"
                                variant="white"
                                onClick={() => onCancelClick()}
                              >
                                Cancel
                              </Button>
                            </div>
                            <Modal
                              className="come-from-modal right createPage"
                              show={createPageModal}
                              // onHide={() => setCreatePageModal(false)}
                            >
                              <ModalCreatePage
                                openCreatePageModal={setCreatePageModal}
                                selectedData={requestPagedData ?? null}
                                moduleData={moduleData}
                                getModuleDataFunction={setPageFormData}
                                onSave={handlePagesForms}
                                pagesetMethod={setFieldValue}
                                setTouched={setTouched}
                                touched={touched}
                                pagesList={pagesList}
                                formsList={formsList}
                                onClosed={setCreatePageModal}
                                moduleFormRef={formRef?.current?.values?.title}
                                setDraftCount={setDraftCount}
                                draftCount={draftCount}
                                hasUnpublishedContent={setHasUnpublishedContent}
                              />
                            </Modal>
                            <Modal
                              className="come-from-modal right createForm"
                              show={createFormModal}
                              // onHide={() => setCreateFormModal(false)}
                            >
                              <ModalCreateForm
                                openCreateFormModal={setCreateFormModal}
                                selectedData={requestFormData ?? null}
                                getModuleDataFunction={setPageFormData}
                                moduleData={moduleData}
                                formsList={formsList}
                                pagesList={pagesList}
                                onSave={handlePagesForms}
                                formSetMethod={setFieldValue}
                                setTouched={setTouched}
                                touched={touched}
                                moduleFormRef={formRef?.current?.values?.title}
                                onClosed={setCreateFormModal}
                                setDraftCount={setDraftCount}
                                draftCount={draftCount}
                                hasUnpublishedContent={setHasUnpublishedContent}
                              />
                            </Modal>
                            <Modal
                              className="modalDialog"
                              size="sm"
                              centered
                              show={restrictModal}
                              // onHide={() => setRestrictModal(false)}
                            >
                              <Modal.Header
                                closeButton
                                onClick={() => setRestrictModal(false)}
                              >
                                <Modal.Title></Modal.Title>
                              </Modal.Header>
                              <Modal.Body className="pb-0">
                                <span className="iconRestrict">
                                  <IconRestrict />
                                </span>
                                <h3 className="pt-1 mt-2">
                                  {" "}
                                  Restrict Whole Team?
                                </h3>
                                <p className="text-gray-300 mb-0 mt-2 mb-5">
                                  All the team members of the selected team will
                                  be restricted.
                                </p>
                              </Modal.Body>

                              <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
                                <Button
                                  variant="white"
                                  size=""
                                  className="m-0"
                                  onClick={() => {
                                    setRestrictModal(false);
                                    setShowMultiSelect(true);
                                  }}
                                >
                                  No, Restrict Individually
                                </Button>
                                <Button
                                  variant="primary"
                                  size=""
                                  className="border-0 m-0 ms-2"
                                  onClick={() => {
                                    // Perform the action you want on confirm
                                    // For example, call onConfirm or any other function
                                    // onConfirm(requestType);

                                    // Close the modal
                                    setRestrictModal(false);
                                    setRestrictEmployee(true);
                                  }}
                                >
                                  Yes
                                </Button>
                              </Modal.Footer>
                            </Modal>
                          </Form>
                        );
                      }}
                    </Formik>
                  )}
                  {formDataReady === false && <Loader />}
                </div>
              </div>
            )}

            {isRestricted && (
              <RestrictedPlan
                title={"Module Creation Limit Reached!"}
                description={
                  "You have reached the creation limit for modules. Upgrade plan to add more."
                }
              />
            )}
          </Container>
        </div>
      </div>
      <Modal className="modalDialog" size="sm" centered show={restrictedPopUp}>
        <RestrictedModalPopup
          onCancel={setRestrictedPopUp}
          headerLable={
            "Action can not be performed since modules limit has reached!"
          }
          chldLable={
            "Kindly purchase or upgrade your plan in order to continue with this action."
          }
        />
      </Modal>
    </>
  );
};

export default CreateModule;
