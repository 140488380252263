import React from "react";

const CheckBox = ({ text, status, ststusChange, id, disabled,  selectedData, checked, onChange, name}) => {
  return (
    <label className="checkBox">
      {ststusChange ? (
        <input
          type="checkBox"
          checked={status ? status : ""}
          onChange={() => ststusChange(id, !status, selectedData)}
          disabled={disabled}
        />
      ) : (
        <input type="checkbox" disabled={disabled} name={name}
        checked={checked}
        onChange={onChange} />
      )}
      <span className="checkBoxCheck"></span>
      {text && <span className="">{text}</span>}
    </label>
  );
};

export default CheckBox;
