import React, { useState, useEffect } from 'react';
import DatePicker from "react-datepicker";
import { IconCalendar } from "../../../../icons/Icons";
import { isValidPhoto, isValidVideo, isValidPPT, isValidDocument, isValidMusic } from './formFileValidation';
import Video from '../video';
import ImagePreview from '../ImagePreview';
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";
import { getCalendarFormatDate } from "../../../../common";

const DateType = ({title, serialNo, questionsFiles, isRequired, onDateChange,setIsValidDate, value, disabled }) => {
    const [startDate, setStartDate] = useState();

  const handleDateChange = (date) => {
    if(date){
    setStartDate(date);
    onDateChange(date); 
    setIsValidDate(true);
    }else{
    setStartDate("");
    onDateChange(""); 
    setIsValidDate(false);
   // console.log(date,"date")
    }
  };
 
    return (
        <div className='mt-3 pt-3'>
            <h6 className='mb-3 font-medium'>{serialNo}. {title}
            {isRequired && (<span className="mandatoryFields" style={{ color: "red" }}>*</span>)}
            </h6>
            {questionsFiles?.length > 0 ? (
                                        <>
                                          {isValidVideo(
                                            questionsFiles.length > 0 &&
                                            questionsFiles[0]?.filePath
                                          ) ? (
                                            <Video contentUrl={questionsFiles[0]?.filePath}
                                            />
                                          ) : isValidPhoto(
                                              questionsFiles.length > 0 &&
                                              questionsFiles[0]?.filePath
                                            ) ? (
                                            <ImagePreview
                                              contentUrl={
                                                questionsFiles[0].filePath
                                              }
                                            />
                                          ) : isValidPPT(
                                              questionsFiles.length > 0 &&
                                              questionsFiles[0]?.filePath
                                            ) ? (
                                              <Ppt
                                                contentUrl={
                                                  questionsFiles[0].filePath
                                                }
                                              />
                                            ) : isValidDocument(
                                              questionsFiles.length > 0 &&
                                              questionsFiles[0]?.filePath
                                            ) ? (
                                              <Doc
                                                contentUrl={
                                                  questionsFiles[0].filePath
                                                }
                                              />
                                            ) : isValidMusic(
                                              questionsFiles.length > 0 &&
                                              questionsFiles[0]?.filePath
                                            ) ? (
                                              <Audio
                                                contentUrl={
                                                  questionsFiles[0].filePath
                                                }
                                              />
                                            ) : null}
                                        </>
                                      ) : null}
            <div className="input-group myInput yAxisCalendar">
                <IconCalendar />
                <DatePicker
                    className="form-control"
                    onChange={(date) => handleDateChange(date)}
                    selected={startDate}
                    // placeholderText='Month, day, year'
                    placeholderText="Select the date"
                    name="date"
                    value={value ? getCalendarFormatDate(value): startDate}
                    disabled={disabled}
                    showMonthYearPickerdateFormat="MMMM d, yyyy"
                    showMonthDropdown
                    showYearDropdown
                    dropdownMode="select"
                />
            </div>
        </div>
    )
}

export default DateType
