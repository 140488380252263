import React, { useState, useEffect } from "react";
import { Row, Col, Container } from "react-bootstrap";
import AccountImg from "../../assets/images/employeeDp.png";
import Loader from "../../components/Loader/Loader";
import {
  IconTopSearch,
  IconEditPencil,
  IconHamberger,
  IconEmployeeAccount,
  IconEmployeeCall,
  IconEmployeeMail,
  IconEmployeeDate,
} from "../../icons/Icons";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import Search from "../../components/Search/Search";
import ActivityLog from "../../components/ActivityLog/ActivityLog";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import AddUserEmployeeModal from "./AddUserEmployeeModal";
import {
  getUserDetailsAsync,
  unassignContentToUsersAsync,
} from "../../reducers/user-reducers";
import { getFormatDate, UserProfileTimeZone } from "../../common";
import { Modal, Button } from "react-bootstrap";
import ModalDelete from "../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../components/ModalPopup/ModalResponsePopup";
import AssignConenteeModal from "./AssignConenteeModal";
import getInitialsInName from "../../helper/GetNameInitials";
import UserImage from "../../components/UserImage/UserImage";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import RenewalHeader from "../Authoring/ContentPreview/RenewalHeader"
import TimeZoneConverter from "../../components/TimeZoneConverter/TimeZoneConverter";

const UserDetail = ({ id, setUserDetailsShow, openEditEmployeeModal, getUserList}) => {
  const { state } = useLocation();
  // Get ID from URL
  const params = useParams();
  if (params.id) {
    id = params.id;
  }
  const [addUserEmployee, setAddUserEmployee] = useState(false);
  const [userData, setUserData] = useState(undefined);
  const [serachString, setSerachString] = useState("");
  const [assignModel, setAssignModel] = useState(false);
  const [unassignModel, setUnassignModel] = useState(false);
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [responsePopup, setResponsePopup] = useState({});
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  //    {
  //     id: "12133213",
  //     role: "Admin",
  //     profilePicture: "abc",
  //     name: "Test User",
  //     contactNo: "9936634662",
  //     phoneNumber: "9936634662",
  //     personalEmail: "Abc@email.com",
  //     tags: "tag2",
  //     status: 0,
  //     companyEmail: "Abc@email.com",
  //     teamId: "",
  //     timeZoneId: "",
  //     userName: "Abc@gmail.com",
  //     password: "122323",
  //     joiningDate: new Date("03/29/2023"),
  //     changePasswordFlag: false,
  //     inviteDate: new Date(),
  //   };

  const onInputChange = (value) => {
    setSerachString(value);
  };

  const getUserDetails = () => {
    setShowLoader(true);
    if (id) {
      dispatch(getUserDetailsAsync(id)).then((res) => {
        if (res) {
          setUserData(res?.payload);
          setShowLoader(false);
        }
      });
    }
  };
  const userBulkActResponse = (res, operation) => {
    let successMessage =
      operation === "Unassign"
        ? `Success! Content has been unassigned from the employee's activities. `
        : "";
    successMessage += `(${res?.proceedData}/${res?.totalRequest})`;

    let prompt =
      res?.proceedData &&
      res?.totalRequest &&
      res?.proceedData === res?.totalRequest
        ? "Success"
        : "Error";
    // prompt = "Success";
    let message =
      res?.proceedData === res?.totalRequest
        ? successMessage
        : `${operation} Failed (${res?.totalRequest - res?.proceedData}/${
            res?.totalRequest
          })`;
    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records:
        res?.failexcutionResponse && res?.failexcutionResponse?.length > 0
          ? res?.failexcutionResponse
          : [],
      procesFailedError: res?.procesFailedError,
    });
    setUnassignModel(false);
    getUserDetails();
  };

  const onConfirm = () => {
    setUnassignModel(false);
    if (selectedItemList && selectedItemList.length > 0) {
      setShowLoader(true);
      const reqBody = {
        assignmentIds: selectedItemList,
      };
      dispatch(unassignContentToUsersAsync(reqBody)).then((res) => {
        userBulkActResponse(res?.payload, "Unassign");
        getUserDetails();
        setShowLoader(false);
      });
      setSelectedItemList([]);
    }
  };
  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };
  useEffect(() => {
    if (id && id != "") {
    }
    if (id) {
      getUserDetails();
    }
  }, [state]);

  // const openEditEmployeeModal = (isOpen)=>{

  // };

  const onAssignModelClose = (isOpen) => {
    setAssignModel(isOpen);
    getUserDetails();
    setSelectedItemList([]);
  };

  const handleCloseUserModal = () => {
    setAddUserEmployee(false);
  };
  return (
    !userData ? <Loader /> :(
      <>
        <div className="pages">
          <RenewalHeader />
          <header className="pageHeader">
            <Container fluid>
              <Row className="align-items-center">
                <Col>
                  <div className="d-flex align-items-center gap-2">
                    <Link to="/users" className="d-flex" onClick={(()=>setUserDetailsShow(false))}>
                      <span className="arrow left"></span>
                    </Link>
                    <h3>User Details</h3>
                  </div>
                </Col>
                <Col className="text-end">
                  <div className="d-flex gap-3 align-items-center justify-content-end">
                    <AccountDropdown />
                    <ActivityLog />
                  </div>
                </Col>
              </Row>
            </Container>
          </header>
          <div className="pageOuter">
            <Container fluid>
              <div className="page employeesDetail d-flex flex-column">
                <div className="px-32 pb-24 pt-32 w-100 borderBottom">
                  <div className=" d-flex justify-content-between">
                    <div className="d-flex align-items-center">
                      <div className="headImg me-3">
                        {userData.img ? (
                          <UserImage
                            imgKey={userData.img}
                            // alt={getInitialsInName(userData.name)}
                            alt={userData.name}
                          />
                        ) : (
                          <div>
                            <img
                              src={getInitialsInName(
                                userData.name ?? "N A",
                                40
                              )}
                              alt={userData.name}
                            />
                            {/* <span class="tableName text-primary-100 bg-pastel-100">
                              {getInitialsInName(userData.name)}
                            </span> */}
                          </div>
                        )}
                      </div>
                      <div>
                        <h2 className="mb-1">{userData.name}</h2>
                        <p className="mb-0 h6 text-gray-200">
                        {userData?.timeZoneId.replace("/", ", ").replace("_", " ").split(', ').reverse().join(', ')} <TimeZoneConverter timezone={userData?.timeZoneId} />
                        {/* {userData?.timeZoneId.replace("/", ", ").replace("_", " ").split(', ').reverse().join(', ')} {UserProfileTimeZone(userData?.timeZoneId)} */}
                        </p>
                      </div>
                    </div>

                    {showLoader && <Loader />}
                    {/* <Link
                          to={{
                            pathname: `/users/edit`,
                            state: {
                              propsData: {},
                              opertionType: "Edit",
                              redirectTo: "/users",
                            },
                          }}
                        >
                          <div className="d-flex gap-2 align-items-center">
                          <IconEditPencil />
                          <p className='mb-0 text-gray-300 h6 font-medium'>Edit details</p>
                                
                         <p className="h6 text-gray-300">Edit</p> 
                          </div>
                        </Link> */}

                    <button
                      onClick={() => setAddUserEmployee(true)}
                      className="d-flex align-items-center gap-2"
                    >
                      <IconEditPencil />
                      <p className="mb-0 text-gray-300 h6 font-medium">
                        Edit details
                      </p>
                    </button>
                  </div>

                  <div className="d-flex userDetails justify-content-between userDetailBox">
                    <div className="d-flex gap-2">
                      <IconEmployeeAccount />
                      <p className="h6">{userData.team}</p>
                    </div>

                    <div className="d-flex gap-2">
                      <IconEmployeeCall />
                      <p className="h6">{userData.phoneNumber}</p>
                    </div>

                    <div className="d-flex gap-2">
                      <IconEmployeeMail />
                      <p className="h6 word-break">{userData.companyEmail}</p>
                    </div>

                    <div className="d-flex gap-2">
                      <IconEmployeeDate />
                      <p className="h6">
                        <div className="m-0">
                          <span className="text-gray-300 pe-1">Joined on</span>
                          {/* {getFormatDate(userData.joiningDate)} */}
                          {/*<DateTimeConverter utcDateTime={userData.joiningDate} />*/}
                          {getFormatDate(userData.joiningDate)}
                        </div>
                        {/*<span className="text-gray-400">|</span>{" "}*/}
                        <div className="m-0">
                          {userData.lastactiveDate && (
                            <>
                              <span className="text-gray-300 pe-1">Last active on</span>
                              {/* {getFormatDate(userData.lastactiveDate)} */}
                              {/*<DateTimeConverter utcDateTime={userData.lastactiveDate} />*/}
                              {getFormatDate(userData.lastactiveDate)}
                            </>
                          )}
                        </div>

                      </p>
                    </div>
                  </div>
                </div>

                <div className="bg-gray-600 px-32 pt-24 pb-0 flex-grow-1 overflow-auto d-flex flex-column ">
                  <div className="d-flex justify-content-between w-100 align-items-center mb-4">
                    <h5>Assignment Status</h5>

                    <div className="d-flex">
                      <div className="input-group myInput myInputSearch me-1">
                        {/* <input
                          type="text"
                          className="form-control"
                          placeholder="Search..."
                        /> */}

                        <Search onBlur={onInputChange} placeHolder="Search by Title" />
                        <IconTopSearch />
                      </div>
                      {/* userData.role !== "Admin" && */}
                      {userData.status !== "Inactive" ? (
                        <button
                          className="btn-secondary btn-sm ms-2"
                          type="button"
                          onClick={() => setAssignModel(true)}
                        >
                          Assign
                        </button>
                      ) : null}
                    </div>
                  </div>

                  <div className="row flex-grow-1 col5c">
                    
                    <div className="col">
                      <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                        <p className="h6 p-3 mb-1">
                          Pending (
                          {
                            userData.assignMents.filter(
                              (f) =>
                                f.status === 1 &&
                                (f.assignmentTitle
                                  .toLowerCase()
                                  .includes(serachString?.toLowerCase()) ||
                                  serachString == "")
                            ).length
                          }
                          )
                        </p>
                        {userData.assignMents
                          .filter(
                            (f) =>
                              f.status === 1 &&
                              (f.assignmentTitle
                                .toLowerCase()
                                .includes(serachString?.toLowerCase()) ||
                                serachString == "")
                          )
                          .map((m) => (
                            <div className="bg-base-100  p-3 rounded-6 assignStatus">
                              <p
                                className={`text-xs text-gray-300 mb-1 status ${
                                  m.assignmentType === "MODULE"
                                    ? "blue"
                                    : m.assignmentType === "SEQUENCE"
                                    ? "yellow"
                                    : ""
                                }`}
                              >
                                {m.assignmentType}
                              </p>
                              <h5
                                className="mb-3 font-medium assignmentTitle"
                                onClick={() => {
                                  setSelectedItemList([m.id]);
                                  //setUnassignModel(true);
                                }}
                              >
                                {m.assignmentTitle}
                              </h5>
                              <p className="mb-0  text-md">
                                <span className="text-gray-300 me-1">
                                  Assigned on
                                </span>
                                {getFormatDate(m.assignOn)}
                                {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                              </p>
                              <a
                                href="#"
                                className="text-primary-200 font-bold h6 text-end"
                                onClick={() => {
                                  setSelectedItemList([m.id]);
                                  setUnassignModel(true);
                                }}
                              >
                                Unassign{" "}
                              </a>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col">
                      <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                        <p className="h6 p-3 mb-1">
                          Active (
                          {
                            userData.assignMents.filter(
                              (f) =>
                                f.status === 3 &&
                                (f.assignmentTitle
                                  .toLowerCase()
                                  .includes(serachString?.toLowerCase()) ||
                                  serachString == "")
                            ).length
                          }
                          )
                        </p>
                        {userData.assignMents
                          .filter(
                            (f) =>
                              f.status === 3 &&
                              (f.assignmentTitle
                                .toLowerCase()
                                .includes(serachString?.toLowerCase()) ||
                                serachString == "")
                          )
                          .map((m) => (
                            <div className="bg-base-100  p-3 rounded-6 assignStatus">
                              <p
                                className={`text-xs text-gray-300 mb-1 status ${
                                  m.assignmentType === "MODULE"
                                    ? "blue"
                                    : m.assignmentType === "SEQUENCE"
                                    ? "yellow"
                                    : ""
                                }`}
                              >
                                {m.assignmentType}
                              </p>
                              <h5 className="mb-3 font-medium assignmentTitle">
                                {m.assignmentTitle}
                              </h5>
                              <p className="mb-0  text-md">
                                <span className="text-gray-300 me-1">
                                  Assigned on
                                </span>
                                {getFormatDate(m.assignOn)}
                                {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col">
                      <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                        <p className="h6 p-3 mb-1">
                          Delayed (
                          {
                            userData.assignMents.filter(
                              (f) =>
                                f.status === 2 &&
                                (f.assignmentTitle
                                  .toLowerCase()
                                  .includes(serachString?.toLowerCase()) ||
                                  serachString == "")
                            ).length
                          }
                          )
                        </p>
                        {userData.assignMents
                          .filter(
                            (f) =>
                              f.status === 2 &&
                              (f.assignmentTitle
                                .toLowerCase()
                                .includes(serachString?.toLowerCase()) ||
                                serachString == "")
                          )
                          .map((m) => (
                            <div className="bg-base-100  p-3 rounded-6 assignStatus">
                              <p
                                className={`text-xs text-gray-300 mb-1 status ${
                                  m.assignmentType === "MODULE"
                                    ? "blue"
                                    : m.assignmentType === "SEQUENCE"
                                    ? "yellow"
                                    : ""
                                }`}
                              >
                                {m.assignmentType}
                              </p>
                              <h5 className="mb-3 font-medium assignmentTitle">
                                {m.assignmentTitle}
                              </h5>
                              <p className="mb-0  text-md">
                                <span className="text-gray-300 me-1">
                                  Assigned on
                                </span>
                                {getFormatDate(m.assignOn)}
                                {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col">
                      <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                        <p className="h6 p-3 mb-1">
                        Expired  (
                          {
                            userData.assignMents.filter(
                              (f) =>
                                f.status === 4 &&
                                (f.assignmentTitle
                                  .toLowerCase()
                                  .includes(serachString?.toLowerCase()) ||
                                  serachString == "")
                            ).length
                          }
                          )
                        </p>
                        {userData.assignMents
                          .filter(
                            (f) =>
                              f.status === 4 &&
                              (f.assignmentTitle
                                .toLowerCase()
                                .includes(serachString?.toLowerCase()) ||
                                serachString == "")
                          )
                          .map((m) => (
                            <div className="bg-base-100  p-3 rounded-6 assignStatus">
                              <p
                                className={`text-xs text-gray-300 mb-1 status ${
                                  m.assignmentType === "MODULE"
                                    ? "blue"
                                    : m.assignmentType === "SEQUENCE"
                                    ? "yellow"
                                    : ""
                                }`}
                              >
                                {m.assignmentType}
                              </p>
                              <h5 className="mb-3 font-medium assignmentTitle">
                                {m.assignmentTitle}
                              </h5>
                              <p className="mb-0  text-md">
                                <span className="text-gray-300 me-1">
                                  Assigned on
                                </span>
                                {getFormatDate(m.assignOn)}
                                {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>

                    <div className="col">
                      <div className="bg-gray-500 rounded-top borderAll border-bottom-0 boxInnerShadow h-100 pb-32">
                        <p className="h6 p-3 mb-1">
                          Completed (
                          {
                            userData.assignMents.filter(
                              (f) =>
                                f.status === 0 &&
                                (f.assignmentTitle
                                  .toLowerCase()
                                  .includes(serachString?.toLowerCase()) ||
                                  serachString == "")
                            ).length
                          }
                          )
                        </p>
                        {console.log(userData, "uuu")}
                        {userData.assignMents
                          .filter(
                            (f) =>
                              f.status === 0 &&
                              (f.assignmentTitle
                                .toLowerCase()
                                .includes(serachString?.toLowerCase()) ||
                                serachString == "")
                          )
                          .map((m) => (
                            <div className="bg-base-100  p-3 rounded-6 assignStatus">
                              <p
                                className={`text-xs text-gray-300 mb-1 status ${
                                  m.assignmentType === "MODULE"
                                    ? "blue"
                                    : m.assignmentType === "SEQUENCE"
                                    ? "yellow"
                                    : ""
                                }`}
                              >
                                {m.assignmentType}
                              </p>
                              <h5 className="mb-3 font-medium assignmentTitle">
                                {m.assignmentTitle}
                              </h5>
                              <p className="mb-0  text-md">
                                <span className="text-gray-300 me-1">
                                  Assigned on{" "}
                                </span>
                                {getFormatDate(m.assignOn)}
                                {/* <DateTimeConverter utcDateTime={m.assignOn} /> */}
                              </p>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </Container>
          </div>
        </div>

        <Modal
          className="come-from-modal right manageTeamsActivityModal"
          centered
          show={addUserEmployee}
          // onHide={() => setAddUserEmployee(false)}
        >
          <AddUserEmployeeModal
            handleModal={setAddUserEmployee}
            action={"Edit"}
            user={userData}
            getUsers={getUserDetails}
            getUserList={getUserList}
            onClose={handleCloseUserModal}
          />
        </Modal>
        <Modal
          className="modalDialog"
          size="sm"
          centered
          // show={unassignModel || responsePopup?.show}
          show={unassignModel || responsePopup?.show}
        >
          {unassignModel && (
            <ModalDelete
              onCancel={setUnassignModel}
              onConfirm={onConfirm}
              headerLable="Are you sure you want to unassign this content?"
              chldLable="This action cannot be undone."
            />
          )}

          {responsePopup?.show && (
            <ModalResponse data={responsePopup} onClose={onClose} />
          )}
        </Modal>

        <Modal
          className="come-from-modal right addEmployee assignContent"
          centered
          show={assignModel}
          // onHide={() => setAssignModel(false)}
        >
          <AssignConenteeModal
            openAssignConenteeModal={onAssignModelClose}
            selectedUsers={[userData.id]}
            singleUser={true}
            type="single"
            onClose={onAssignModelClose}
          />
        </Modal>
      </>
    )
  );
};

export default UserDetail;
