import React from 'react';
import { Switch, Route } from "react-router-dom";
import StartMyTrial from './StartMyTrial';
import Modules from "./Modules";
import EnterPaymentDetails from "./EnterPaymentDetails";
import PaymentSuccess from "./PaymentSuccess";
import PaymentError from './PaymentError';

const StartMyTrialIndex = () => {
    return (
        <>
            <Switch>
                <Route exact path="/trial">
                    <StartMyTrial />
                </Route>
                <Route exact path="/trial/modules">
                    <Modules />
                </Route>
                <Route exact path="/trial/payment">
                    <EnterPaymentDetails />
                </Route>
                <Route exact path="/trial/paymentsuccess">
                    <PaymentSuccess />
                </Route>
                <Route exact path="/trial/paymenterror">
                    <PaymentError />
                </Route>
            </Switch>
        </>
    );
}

export default StartMyTrialIndex;
