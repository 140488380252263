import React from "react";
import { Modal, Button } from "react-bootstrap";

class ConfimrationPopup extends React.Component {
  render() {
    return (
      <>
        <Modal.Header></Modal.Header>
        <Modal.Body className="pt-4 pb-4">
          <p className="text-gray-300 mb-0 mt-2 mb-5">{this.props?.message}</p>
        </Modal.Body>
        <Modal.Footer className="justify-content-end btnFooter gap-2">
          <Button
            variant="primary"
            size="md"
            onClick={() => this.props?.onClose()}
          >
            Close
          </Button>
        </Modal.Footer>
      </>
    );
  }
}

export default ConfimrationPopup;
