import React from 'react';
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import ScratchImg from "../../assets/images/ScratchImg.png";
const ScratchModal = ({ handleModal }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body>
                <div className='text-center'>
                    <img src={ScratchImg} alt="ScratchImg" />
                    <div className='p-40 pt-4 pb-0'>
                        <h3 className='mt-3 mb-2'>Take a moment to get comfortable</h3>
                        <p className='text-gray-300 mb-0 mb-0'>
                            We had love to show you around- it will be fast, promise. You will see where useful featues are, so you get off to a flying start.
                        </p>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Link to="/trial/modules" className='btn-white btn-sm' onClick={() => handleModal(false)}>
                    No, Thanks
                </Link>
                <Link className="btn-primary btn-sm text-base-100" onClick={() => handleModal(false)}>
                    Show me
                </Link>
            </Modal.Footer>
        </>
    );
}

export default ScratchModal;
