import React, { useEffect, useState } from "react";
import { Row, Col, Container, Accordion, Button, Modal } from "react-bootstrap";
import AccountImg from "../../assets/images/employeeDp.png";
import Catalogmod1 from "../../assets/images/Catalog-mod-1.png";
import Catalogmod2 from "../../assets/images/Catalog-mod-2.png";
import { Link } from "react-router-dom";
import Search from "../../components/Search/Search";
import ActivityLog from "../../components/ActivityLog/ActivityLog";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import { FeedbackIconGrey } from "../../icons/Icons";
import Loader from "../../components/Loader/Loader";
import { IconArrow, IconDelayed, IconExpired } from "../../icons/Icons";
import ModalResponse from "../../components/ModalPopup/ModalResponsePopup";
import UpdatePassword from "../Dashboard/UpdatePassword";
import {
  getEmployeeAssignmentsAsync,
  getUserProfileAsync,
  setAssigmentStartAsync,
  setAssigmentCompleteAsync,
} from "../../reducers/user-reducers";
import { useDispatch } from "react-redux";
import {
  employeeStatus,
  assignmentStatus,
  getFormatDate,
  getShowNowUrl,
  countLearning,
  getSequencesFirstActive,
  getProcessFirstActive,
  calculateDueTextAndDaysRemaining,
} from "../../common";
import NotificationLog from "../../components/ActivityLog/NotificationLog";
import { useHistory, useLocation } from "react-router-dom";
import EmptyState from "../../components/EmptyState/EmptyState";

import AssignedContentModal from "./AssignedContentModal";
import AccordionProgressBar from "../../components/AccordionProgressBar/AccordionProgressBar";
import NoData from "../../components/EmptyState/NoData";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import NotificationView from "./NotificationView";

const EmployeeAccount = ({ openEditEmployeeModal }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { state } = useLocation();
  const [updatePassword, setUpdatePassword] = useState(false);
  const [responsePopup, setResponsePopup] = useState({});
  const [userProfiles, setUserProfiles] = useState(null);
  const [employeeAssignment, setEmployeeAssignment] = useState([]);
  const [employeeAllAssignment, setEmployeeAllAssignment] = useState([]);
  const [employeeNewAssignment, setEmployeeNewAssignment] = useState([]);
  const [ftue, setFtue] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState("All");
  const [searchString, setSearchString] = useState("");
  const [filteredNewEmployees, setFilteredNewEmployees] = useState([]);
  const [filteredAllNewEmployees, setFilteredAllNewEmployees] = useState([]);
  const [assignedContentModal, setAssignedContentModal] = useState(false);
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const [filteredAllEmployees, setFilteredAllEmployees] = useState([]);
  const [delayedCount, setDelayedCount] = useState(0);
  const [expiredCount, setExpiredCount] = useState(0);
  let countSrNo = 1;

  useEffect(() => {
    const filteredNewEmployees = employeeNewAssignment?.filter((employee) => {
      const nameMatch = employee.title
        .toLowerCase()
        .includes(searchString.toLowerCase());
      return nameMatch;
    });
    filteredNewEmployees.sort((a, b) => {
      const dateA = new Date(a.assignedOn);
      const dateB = new Date(b.assignedOn);
      return dateB - dateA; // Note the reversal here
    });
    setFilteredNewEmployees(filteredNewEmployees.slice(0, 3));
    setFilteredAllNewEmployees(filteredNewEmployees);

    const filteredEmployees = employeeAssignment?.filter((employee) => {
      const nameMatch = employee.title
        .toLowerCase()
        .includes(searchString.toLowerCase());
      return nameMatch;
    });
    if (activeTab === "Delayed") {
      filteredEmployees.sort((a, b) => {
        const dateA = new Date(a.modifiedDate);
        const dateB = new Date(b.modifiedDate);
        return dateB - dateA; // Note the reversal here
      });
    } else if (activeTab === "Expired") {
      filteredEmployees.sort((a, b) => {
        const dateA = new Date(a.expiryDate);
        const dateB = new Date(b.expiryDate);
        return dateB - dateA; // Note the reversal here
      });
    } else {
      filteredEmployees.sort((a, b) => {
        const dateA = new Date(a.assignedOn);
        const dateB = new Date(b.assignedOn);
        return dateB - dateA; // Note the reversal here
      });
    }
    setFilteredEmployees(filteredEmployees);

    const filteredAllEmployees = employeeAllAssignment?.filter((employee) => {
      const nameMatch = employee.title
        .toLowerCase()
        .includes(searchString.toLowerCase());
      return nameMatch;
    });

    setFilteredAllEmployees(filteredAllEmployees);
  }, [
    searchString,
    employeeNewAssignment,
    employeeAssignment,
    employeeAllAssignment,
  ]);

  useEffect(() => {
    if (activeTab === "Delayed" || activeTab === "Expired") {
      setDelayedCount(
        filteredEmployees.filter((item) => item.status === 2)?.length
      );
      setExpiredCount(
        filteredEmployees.filter((item) => item.status === 4)?.length
      );
    }
  }, [filteredEmployees]);

  useEffect(() => {
    console.log("useEffect 18 = ");
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  useEffect(() => {
    getUserProfile();
    getEmployeeAssignment();
  }, []);

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUpdatePassword(res.payload?.onFirstLogin);
        setUserProfiles(res.payload);
      }
    });
  };

  // const getEmployeeAssignment = () => {
  //   dispatch(getEmployeeAssignmentsAsync()).then((res) => {
  //     if (res.payload) {
  //       setEmployeeAllAssignment(res.payload.assignments);
  //       setEmployeeAssignment(res.payload.assignments);
  //       console.log(res.payload,"res.payload.assignments")
  //       console.log(
  //         res?.payload?.assignments.filter((item) => item.status === 0)
  //       );
  //       setEmployeeNewAssignment(
  //         res?.payload?.assignments.filter((item) => item.status === 1)
  //       );
  //     }
  //   });
  // };
  const getEmployeeAssignment = () => {
    dispatch(getEmployeeAssignmentsAsync()).then((res) => {
      if (res.payload) {
        const assignments = res.payload.assignments;

        if (assignments) {
          setEmployeeAllAssignment(assignments);
          setEmployeeAssignment(assignments);
          setEmployeeNewAssignment(
            assignments.filter((item) => item.status === 1)
          );
        }
      }
    });
  };

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };

  const onClosed = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "All") {
      setEmployeeAssignment(employeeAllAssignment);
    } else if (tabName === "New") {
      setEmployeeAssignment(
        employeeAllAssignment.filter((item) => item.status === 1)
      );
    } else if (tabName === "Active") {
      setEmployeeAssignment(
        employeeAllAssignment.filter((item) => item.status === 3)
      );
    } else if (tabName === "Completed") {
      setEmployeeAssignment(
        employeeAllAssignment.filter((item) => item.status === 0)
      );
    } else if (tabName === "Delayed" || tabName === "Expired") {
      setEmployeeAssignment(
        employeeAllAssignment.filter(
          (item) => item.status === 2 || item.status === 4
        )
      );
    }
  };

  const onStartNow = (assignmentId, moduleId, assignmentSegId) => {
    // console.log(item,'itemitemitem');
    if (assignmentId && moduleId) {
      const reqBody = {
        assignmentId: assignmentId,
        moduleId: moduleId,
        assignmentSegId: assignmentSegId,
      };
      dispatch(setAssigmentStartAsync(reqBody)).then((res) => {
        // ToasterAlertSucces(res, ErrrorMessage.UPDATE);
        getEmployeeAssignment();
      });
    }
  };

  const onAllStartNow = (assignmentId, moduleId, assignmentSegId) => {
    // console.log(item,'itemitemitem');
    if (assignmentId && moduleId) {
      const reqBody = {
        assignmentId: assignmentId,
        moduleId: moduleId,
        assignmentSegId: assignmentSegId,
      };
      dispatch(setAssigmentStartAsync(reqBody)).then((res) => {
        // ToasterAlertSucces(res, ErrrorMessage.UPDATE);
        getEmployeeAssignment();
      });
    }
  };

  console.log(filteredEmployees, "filteredEmployees");
  console.log(employeeAssignment, "employeeAssignment");
  console.log(filteredNewEmployees, "filteredNewEmployees");

  return (
    <>
      <div className="pages">
        {isLoading && (
          <div className="loader-container bg-white">
            <Loader />
          </div>
        )}
        <header className="pageHeader mb-4">
          <Container fluid>
            <Row className="align-items-center">
              <Col>
                <h3>Catalogue</h3>
                <h6 className="font-normal text-gray-200 pt-1">
                  👋 Welcome to your Catalogue, {userProfiles?.name}!
                </h6>
              </Col>

              {/* <Col className="text-center">
                <Link
                  to={{
                    pathname: "/feedback",
                  }}
                >
                  <Button variant="" className="border-0 p-0">
                    <div className="d-flex gap-2 align-items-center">
                      <FeedbackIconGrey />
                      <p className="h6 text-gray-300">Feedback</p>
                    </div>
                  </Button>
                </Link>
              </Col> */}

              <Col className="text-end">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <div className="">
                    <Search placeHolder="Search..." onBlur={setSearchString} />
                  </div>
                  <AccountDropdown />
                  <NotificationLog />
                  {/* <ActivityLog /> */}
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        {!ftue && (
          <div className="pageOuter">
            <Container fluid>
              <Row>
                <Col lg={8} className="d-flex">
                  <div className="page bg-gray-600 flex-grow-1">
                    <div className="p-20 bg-base-100 pb-0 borderBottom mb-20">
                      <ul class="authoringNav">
                        <li class="me-4">
                          <a
                            aria-current="page"
                            class="h6 text-gray-100 active"
                            href=""
                          >
                            To Do
                          </a>
                        </li>
                        <li class="me-4">
                          <a class="h6 text-gray-100" href="">
                            Completed
                          </a>
                        </li>
                        <li class="me-4">
                          <a class="h6 text-gray-100" href="">
                            Expired
                          </a>
                        </li>
                      </ul>
                    </div>

                    <div className="p-20">
                      <Accordion
                        defaultActiveKey="11"
                        className="employeeDashboardAccordianMain"
                      >
                        <Accordion.Item
                          eventKey="11"
                          className="mb-4 cardContent"
                        >
                          <Accordion.Header>
                            <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                              <div className="d-flex align-items-center gap-2">
                                <p className="mb-0">
                                  <span className="linkRing orange me-2"></span>
                                  <span className="text-primary-100 text-md">
                                    Process
                                  </span>
                                </p>
                                <p className="font-medium mb-0 text-gray-100">
                                  Onboarding
                                </p>
                              </div>

                              <Button variant="secondary" size="sm">
                                Start Now
                              </Button>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Accordion
                              defaultActiveKey="0"
                              className="employeeDashboardAccordian"
                            >
                              <Accordion.Item
                                eventKey="0"
                                className="mb-4 cardContent"
                              >
                                <Accordion.Header>
                                  <div className="d-flex justify-content-between align-items-center p-1 pe-3 w-100">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs noBgBorderGray">
                                          1
                                        </span>
                                      </div>

                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Sequence
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          All About Cerebrent4
                                        </p>
                                      </div>
                                    </div>

                                    <span className="timeTaken text-md text-gray-300">
                                      40 mins
                                    </span>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="d-flex justify-content-between align-items-center cardBody mb-20 pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200 ">
                                        1
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <span className="timeTaken text-md text-gray-300">
                                      40 mins
                                    </span>
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center cardBody pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        2
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <span className="text-md text-gray-300 timeTaken">
                                      20 mins
                                    </span>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                eventKey="1"
                                className="mb-4 cardContent"
                              >
                                <Accordion.Header>
                                  <div className="d-flex justify-content-between align-items-center p-1 pe-3 w-100">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs noBgBorderGray">
                                          2
                                        </span>
                                      </div>

                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Sequence
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Team roles &amp; responsibilities{" "}
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      <p className="text-md text-gray-300 mb-0">
                                        40 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="d-flex justify-content-between align-items-center cardBody mb-20 pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        1
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center cardBody pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        2
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                eventKey="2"
                                className="mb-4 cardContent"
                              >
                                <Accordion.Header>
                                  <div className="d-flex justify-content-between align-items-center p-1 pe-3 w-100">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs noBgBorderGray">
                                          3
                                        </span>
                                      </div>

                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      <p className="text-md text-gray-300 mb-0">
                                        40 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="d-flex justify-content-between align-items-center cardBody mb-20 pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        1
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center cardBody pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        2
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item
                          eventKey="12"
                          className="mb-4 cardContent hide"
                        >
                          <Accordion.Header>
                            <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                              <div className="d-flex align-items-center gap-2">
                                <p className="mb-0">
                                  <span className="linkRing blue me-2"></span>
                                  <span className="text-secondary-500 text-md">
                                    Module
                                  </span>
                                </p>
                                <p className="font-medium mb-0 text-gray-100">
                                  Cyber security Regulations
                                </p>
                              </div>

                              <Button variant="secondary" size="sm">
                                Start Now
                              </Button>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body>
                            <Accordion
                              defaultActiveKey="0"
                              className="employeeDashboardAccordian"
                            >
                              <Accordion.Item
                                eventKey="0"
                                className="mb-4 cardContent"
                              >
                                <Accordion.Header>
                                  <div className="d-flex justify-content-between align-items-center p-12 pe-3 w-100">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs noBgBorderGray">
                                          1
                                        </span>
                                      </div>

                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Sequence
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          All About Cerebrent
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      <p className="text-md text-gray-300 mb-0">
                                        40 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="d-flex justify-content-between align-items-center cardBody mb-20 pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200 ">
                                        1
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center cardBody pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        2
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                eventKey="1"
                                className="mb-4 cardContent"
                              >
                                <Accordion.Header>
                                  <div className="d-flex justify-content-between align-items-center p-12 pe-3 w-100">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs noBgBorderGray">
                                          2
                                        </span>
                                      </div>

                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Sequence
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Team roles &amp; responsibilities{" "}
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      <p className="text-md text-gray-300 mb-0">
                                        40 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="d-flex justify-content-between align-items-center cardBody mb-20 pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        1
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center cardBody pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        2
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                              <Accordion.Item
                                eventKey="2"
                                className="mb-4 cardContent"
                              >
                                <Accordion.Header>
                                  <div className="d-flex justify-content-between align-items-center p-12 pe-3 w-100">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs noBgBorderGray">
                                          3
                                        </span>
                                      </div>

                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      <p className="text-md text-gray-300 mb-0">
                                        40 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body>
                                  <div className="d-flex justify-content-between align-items-center cardBody mb-20 pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        1
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>

                                  <div className="d-flex justify-content-between align-items-center cardBody pe-0">
                                    <div className="d-flex gap-3 align-items-center justify-content-start">
                                      <div className="count text-xs text-gray-200">
                                        2
                                      </div>
                                      <div>
                                        <p className="text-gray-300 text-md mb-0">
                                          Module
                                        </p>
                                        <p className="font-medium mb-0 text-gray-200">
                                          Jira Tutorial
                                        </p>
                                      </div>
                                    </div>

                                    <div>
                                      {" "}
                                      <p className="text-md text-gray-300 mb-0">
                                        20 mins
                                      </p>
                                    </div>
                                  </div>
                                </Accordion.Body>
                              </Accordion.Item>
                            </Accordion>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </Col>

                <Col lg={4}>
                  <div className="page mb-4">
                    <div className="p-20">
                      <h5 className="mb-4">Notifications</h5>
                      <p className="mb-4 text-gray-300 text-md font-bold">
                        LATEST
                      </p>

                      <div className="borderBottom mb-20">
                        <div className="d-flex gap-2 mb-20">
                          <div class="tableImg">
                            <img src={AccountImg} alt="" />
                          </div>
                          <p className="h6">
                            Sara william{" "}
                            <span className="text-gray-200">
                              has been assigned as your
                            </span>
                            📜Onboarding buddy
                          </p>
                        </div>

                        <div className="d-flex gap-2 mb-20">
                          <div class="tableImg">
                            <img src={AccountImg} alt="" />
                          </div>
                          <p className="h6">
                            Mark Williams{" "}
                            <span className="text-gray-200">was started </span>
                            😍Process: Accounts Onboarding
                          </p>
                        </div>
                      </div>

                      <p className="mb-12 text-gray-300 text-md font-bold">
                        THURSDAY
                      </p>

                      <div className="d-flex gap-2 borderAll rounded-6 bg-gray-600 p-10 mb-4">
                        <div>
                          <svg
                            width="1.5rem"
                            height="1.5rem"
                            viewBox="0 0 24 24"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M21.76 15.92L15.36 4.4C14.5 2.85 13.31 2 12 2C10.69 2 9.49998 2.85 8.63998 4.4L2.23998 15.92C1.42998 17.39 1.33998 18.8 1.98998 19.91C2.63998 21.02 3.91998 21.63 5.59998 21.63H18.4C20.08 21.63 21.36 21.02 22.01 19.91C22.66 18.8 22.57 17.38 21.76 15.92ZM11.25 9C11.25 8.59 11.59 8.25 12 8.25C12.41 8.25 12.75 8.59 12.75 9V14C12.75 14.41 12.41 14.75 12 14.75C11.59 14.75 11.25 14.41 11.25 14V9ZM12.71 17.71C12.66 17.75 12.61 17.79 12.56 17.83C12.5 17.87 12.44 17.9 12.38 17.92C12.32 17.95 12.26 17.97 12.19 17.98C12.13 17.99 12.06 18 12 18C11.94 18 11.87 17.99 11.8 17.98C11.74 17.97 11.68 17.95 11.62 17.92C11.56 17.9 11.5 17.87 11.44 17.83C11.39 17.79 11.34 17.75 11.29 17.71C11.11 17.52 11 17.26 11 17C11 16.74 11.11 16.48 11.29 16.29C11.34 16.25 11.39 16.21 11.44 16.17C11.5 16.13 11.56 16.1 11.62 16.08C11.68 16.05 11.74 16.03 11.8 16.02C11.93 15.99 12.07 15.99 12.19 16.02C12.26 16.03 12.32 16.05 12.38 16.08C12.44 16.1 12.5 16.13 12.56 16.17C12.61 16.21 12.66 16.25 12.71 16.29C12.89 16.48 13 16.74 13 17C13 17.26 12.89 17.52 12.71 17.71Z"
                              fill="#ECB22E"
                            />
                          </svg>
                        </div>
                        <div>
                          <p className="text-gray-200 h6 mb-1">
                            5 days to complete
                          </p>
                          <p className="h6 font-medium">
                            <span className="font-normal text-gray-200">
                              Training 1 :
                            </span>{" "}
                            Roles &amp; Responsibility of employees
                          </p>
                        </div>
                      </div>

                      <div className="d-flex gap-2">
                        <div class="tableImg">
                          <img src={AccountImg} alt="" />
                        </div>
                        <p className="h6">
                          📜JIRA module 1{" "}
                          <span className="text-gray-200">
                            has been assigned to you{" "}
                          </span>
                          by Annie Brues
                        </p>
                      </div>
                    </div>
                  </div>

                  <div className="page">
                    <div className="p-20">
                      <h5 className="mb-4">Helpdesk</h5>

                      <div className="d-flex justify-content-between borderBottom pb-4 mb-20">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="headImg">
                            <img src={AccountImg} alt="" />
                          </div>
                          <div>
                            <h6 className="font-medium">Ema Watson</h6>
                            <p className="text-md text-gray-300 mb-0">
                              HR Department
                            </p>
                          </div>
                        </div>

                        <div className="borderLeft2 ps-4">
                          <div className="d-flex gap-1 mb-1">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.333 13.6667H4.66634C2.66634 13.6667 1.33301 12.6667 1.33301 10.3333V5.66668C1.33301 3.33334 2.66634 2.33334 4.66634 2.33334H11.333C13.333 2.33334 14.6663 3.33334 14.6663 5.66668V10.3333C14.6663 12.6667 13.333 13.6667 11.333 13.6667Z"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.3337 6L9.24699 7.66667C8.56032 8.21333 7.43366 8.21333 6.74699 7.66667L4.66699 6"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <p className="text-sm mb-0">ema@example.com</p>
                          </div>

                          <div className="d-flex gap-1">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.6463 12.22C14.6463 12.46 14.593 12.7067 14.4797 12.9467C14.3663 13.1867 14.2197 13.4133 14.0263 13.6267C13.6997 13.9867 13.3397 14.2467 12.933 14.4133C12.533 14.58 12.0997 14.6667 11.633 14.6667C10.953 14.6667 10.2263 14.5067 9.45967 14.18C8.69301 13.8533 7.92634 13.4133 7.16634 12.86C6.39967 12.3 5.67301 11.68 4.97967 10.9933C4.29301 10.3 3.67301 9.57334 3.11967 8.81334C2.57301 8.05334 2.13301 7.29334 1.81301 6.54001C1.49301 5.78001 1.33301 5.05334 1.33301 4.36001C1.33301 3.90668 1.41301 3.47334 1.57301 3.07334C1.73301 2.66668 1.98634 2.29334 2.33967 1.96001C2.76634 1.54001 3.23301 1.33334 3.72634 1.33334C3.91301 1.33334 4.09967 1.37334 4.26634 1.45334C4.43967 1.53334 4.59301 1.65334 4.71301 1.82668L6.25967 4.00668C6.37967 4.17334 6.46634 4.32668 6.52634 4.47334C6.58634 4.61334 6.61967 4.75334 6.61967 4.88001C6.61967 5.04001 6.57301 5.20001 6.47967 5.35334C6.39301 5.50668 6.26634 5.66668 6.10634 5.82668L5.59967 6.35334C5.52634 6.42668 5.49301 6.51334 5.49301 6.62001C5.49301 6.67334 5.49967 6.72001 5.51301 6.77334C5.53301 6.82668 5.55301 6.86668 5.56634 6.90668C5.68634 7.12668 5.89301 7.41334 6.18634 7.76001C6.48634 8.10668 6.80634 8.46001 7.15301 8.81334C7.51301 9.16668 7.85967 9.49334 8.21301 9.79334C8.55967 10.0867 8.84634 10.2867 9.07301 10.4067C9.10634 10.42 9.14634 10.44 9.19301 10.46C9.24634 10.48 9.29967 10.4867 9.35967 10.4867C9.47301 10.4867 9.55967 10.4467 9.63301 10.3733L10.1397 9.87334C10.3063 9.70668 10.4663 9.58001 10.6197 9.50001C10.773 9.40668 10.9263 9.36001 11.093 9.36001C11.2197 9.36001 11.353 9.38668 11.4997 9.44668C11.6463 9.50668 11.7997 9.59334 11.9663 9.70668L14.173 11.2733C14.3463 11.3933 14.4663 11.5333 14.5397 11.7C14.6063 11.8667 14.6463 12.0333 14.6463 12.22Z"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                              />
                              <path
                                d="M12.3333 6.00002C12.3333 5.60002 12.02 4.98669 11.5533 4.48669C11.1267 4.02669 10.56 3.66669 10 3.66669"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.6667 6.00001C14.6667 3.42001 12.58 1.33334 10 1.33334"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <p className="text-sm mb-0">(+1) 123 456 7890</p>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-between pb-4 mb-20">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="headImg">
                            <img src={AccountImg} alt="" />
                          </div>
                          <div>
                            <h6 className="font-medium">John Smith</h6>
                            <p className="text-md text-gray-300 mb-0">
                              Tech lead
                            </p>
                          </div>
                        </div>

                        <div className="borderLeft2 ps-4">
                          <div className="d-flex gap-1 mb-1">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M11.333 13.6667H4.66634C2.66634 13.6667 1.33301 12.6667 1.33301 10.3333V5.66668C1.33301 3.33334 2.66634 2.33334 4.66634 2.33334H11.333C13.333 2.33334 14.6663 3.33334 14.6663 5.66668V10.3333C14.6663 12.6667 13.333 13.6667 11.333 13.6667Z"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M11.3337 6L9.24699 7.66667C8.56032 8.21333 7.43366 8.21333 6.74699 7.66667L4.66699 6"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                            <p className="text-sm mb-0">john@example.com</p>
                          </div>

                          <div className="d-flex gap-1">
                            <svg
                              width="16"
                              height="16"
                              viewBox="0 0 16 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.6463 12.22C14.6463 12.46 14.593 12.7067 14.4797 12.9467C14.3663 13.1867 14.2197 13.4133 14.0263 13.6267C13.6997 13.9867 13.3397 14.2467 12.933 14.4133C12.533 14.58 12.0997 14.6667 11.633 14.6667C10.953 14.6667 10.2263 14.5067 9.45967 14.18C8.69301 13.8533 7.92634 13.4133 7.16634 12.86C6.39967 12.3 5.67301 11.68 4.97967 10.9933C4.29301 10.3 3.67301 9.57334 3.11967 8.81334C2.57301 8.05334 2.13301 7.29334 1.81301 6.54001C1.49301 5.78001 1.33301 5.05334 1.33301 4.36001C1.33301 3.90668 1.41301 3.47334 1.57301 3.07334C1.73301 2.66668 1.98634 2.29334 2.33967 1.96001C2.76634 1.54001 3.23301 1.33334 3.72634 1.33334C3.91301 1.33334 4.09967 1.37334 4.26634 1.45334C4.43967 1.53334 4.59301 1.65334 4.71301 1.82668L6.25967 4.00668C6.37967 4.17334 6.46634 4.32668 6.52634 4.47334C6.58634 4.61334 6.61967 4.75334 6.61967 4.88001C6.61967 5.04001 6.57301 5.20001 6.47967 5.35334C6.39301 5.50668 6.26634 5.66668 6.10634 5.82668L5.59967 6.35334C5.52634 6.42668 5.49301 6.51334 5.49301 6.62001C5.49301 6.67334 5.49967 6.72001 5.51301 6.77334C5.53301 6.82668 5.55301 6.86668 5.56634 6.90668C5.68634 7.12668 5.89301 7.41334 6.18634 7.76001C6.48634 8.10668 6.80634 8.46001 7.15301 8.81334C7.51301 9.16668 7.85967 9.49334 8.21301 9.79334C8.55967 10.0867 8.84634 10.2867 9.07301 10.4067C9.10634 10.42 9.14634 10.44 9.19301 10.46C9.24634 10.48 9.29967 10.4867 9.35967 10.4867C9.47301 10.4867 9.55967 10.4467 9.63301 10.3733L10.1397 9.87334C10.3063 9.70668 10.4663 9.58001 10.6197 9.50001C10.773 9.40668 10.9263 9.36001 11.093 9.36001C11.2197 9.36001 11.353 9.38668 11.4997 9.44668C11.6463 9.50668 11.7997 9.59334 11.9663 9.70668L14.173 11.2733C14.3463 11.3933 14.4663 11.5333 14.5397 11.7C14.6063 11.8667 14.6463 12.0333 14.6463 12.22Z"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-miterlimit="10"
                              />
                              <path
                                d="M12.3333 6.00002C12.3333 5.60002 12.02 4.98669 11.5533 4.48669C11.1267 4.02669 10.56 3.66669 10 3.66669"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M14.6667 6.00001C14.6667 3.42001 12.58 1.33334 10 1.33334"
                                stroke="#888888"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>

                            <p className="text-sm mb-0">(+1) 123 456 7890</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        )}

        {state && state.setNotification === true ? (
          <div className="pageOuter">
            <NotificationView />
          </div>
        ) : (
          ftue && (
            <div className="pageOuter">
              <Container fluid>
                <ul class="authoringNav empAnav">
                  <li class="">
                    <a
                      aria-current="page"
                      class={`h6 text-gray-100 ${
                        activeTab === "All" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("All")}
                      href="#"
                    >
                      All (
                      {filteredAllEmployees ? filteredAllEmployees?.length : 0})
                    </a>
                  </li>
                  <li class="">
                    <a
                      aria-current="page"
                      class={`h6 text-gray-100 ${
                        activeTab === "Active" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Active")}
                      href="#"
                    >
                      Active
                    </a>
                  </li>
                  <li class="">
                    <a
                      aria-current="page"
                      class={`h6 text-gray-100 ${
                        activeTab === "New" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("New")}
                      href="#"
                    >
                      New
                    </a>
                  </li>
                  <li class="">
                    <a
                      aria-current="page"
                      class={`h6 text-gray-100 ${
                        activeTab === "Delayed"
                          ? "active"
                          : activeTab === "Expired"
                          ? "active"
                          : ""
                      }`}
                      onClick={() => handleTabClick("Delayed")}
                      href="#"
                    >
                      Delayed
                    </a>
                  </li>
                  <li class="">
                    <a
                      aria-current="page"
                      class={`h6 text-gray-100 ${
                        activeTab === "Completed" ? "active" : ""
                      }`}
                      onClick={() => handleTabClick("Completed")}
                      href="#"
                    >
                      Completed
                    </a>
                  </li>
                </ul>
                <div className="d-flex flex-column flex-grow-1 overflow-auto">
                  {activeTab === "All" &&
                    employeeNewAssignment?.length > 0 &&
                    !searchString && (
                      <div className="border bg-base-100 w-100 cardContent p-32 pb-4 mb-3">
                        <div className="d-flex justify-content-between align-items-center mb-4 pb-2">
                          <p className="mb-0">
                            You have <b>{employeeNewAssignment?.length} New</b>{" "}
                            contents assigned for you.
                          </p>
                          {employeeNewAssignment?.length > 3 && (
                            <div className="viewBtn">
                              <button
                                className="text-primary-200 font-bold h6 text-end"
                                onClick={() => setAssignedContentModal(true)}
                              >
                                View All
                                <span className="ms-1 d-inline-block">
                                  <IconArrow />
                                </span>
                              </button>
                            </div>
                          )}
                        </div>

                        <Row className="justify-conten-end row">
                          {filteredNewEmployees.map(
                            (parent_item, parent_index) => (
                              <Col lg={4} className="">
                                <div class="catlogHeading d-flex">
                                  <div class="count countRound">
                                    <span class="roundCount">
                                      {parent_index + 1}
                                    </span>
                                  </div>
                                  <div class="ms-1 px-3">
                                    <h5>{parent_item.title}</h5>
                                    <p class="text-12 text-gray-200 my-1 ec2line word-break">
                                      {parent_item.description}
                                    </p>

                                    <div className="expdate text-12 pt-2 pb-3 text-gray-300">
                                      <span>
                                        {countLearning(
                                          parent_item.type,
                                          parent_item
                                        )}
                                      </span>

                                      {/* <span>
                                      Expiring on {" "}
                                      {getFormatDate(
                                        parent_item.expiryDate
                                      )}
                                    </span> */}

                                      <>
                                        {calculateDueTextAndDaysRemaining(
                                          parent_item
                                        ).daysRemaining < 0 ? (
                                          parent_item.expiryDate ? (
                                            <span>
                                              Expiring on{" "}
                                              <b>
                                                {getFormatDate(
                                                  parent_item.expiryDate
                                                )}
                                                {/* <DateTimeConverter utcDateTime={parent_item.expiryDate} /> */}
                                              </b>
                                            </span>
                                          ) : null
                                        ) : (
                                          <span>
                                            Due on{" "}
                                            <b>
                                              {calculateDueTextAndDaysRemaining(
                                                parent_item
                                              ).dueText && (
                                                <span>
                                                  {calculateDueTextAndDaysRemaining(
                                                    parent_item
                                                  ).daysRemaining > 0
                                                    ? calculateDueTextAndDaysRemaining(
                                                        parent_item
                                                      ).daysRemaining
                                                    : ""}{" "}
                                                  {calculateDueTextAndDaysRemaining(
                                                    parent_item
                                                  ).daysRemaining === 1
                                                    ? "day"
                                                    : calculateDueTextAndDaysRemaining(
                                                        parent_item
                                                      ).daysRemaining === 0
                                                    ? "Today"
                                                    : "days"}
                                                </span>
                                              )}
                                            </b>
                                          </span>
                                        )}
                                      </>
                                    </div>

                                    {parent_item.type === "Sequence" &&
                                      parent_item.modules?.length > 0 && (
                                        <Link
                                          to={{
                                            pathname: getShowNowUrl(
                                              parent_item.id,
                                              parent_item.assignmentId,
                                              parent_item?.modules[0]
                                                ?.assignmentSegId,
                                              parent_item.type,
                                              parent_item?.modules[0]?.id,
                                              parent_item.title,
                                              // parent_item.modules[0].title,
                                              "Module/"
                                            ),
                                            state: {
                                              assignmentId:
                                                parent_item.assignmentId,
                                              parentId: parent_item.id,
                                            },
                                          }}
                                          target="_blank"
                                        >
                                          <Button
                                            variant="secondary"
                                            size="sm"
                                            className="py-2"
                                            onClick={() =>
                                              onStartNow(
                                                parent_item.assignmentId,
                                                parent_item.modules[0].id,
                                                parent_item.modules[0]
                                                  ?.assignmentSegId
                                              )
                                            }
                                          >
                                            Start Now
                                          </Button>
                                        </Link>
                                      )}

                                    {parent_item.type === "Module" && (
                                      <Link
                                        to={{
                                          pathname: getShowNowUrl(
                                            parent_item.id,
                                            parent_item.assignmentId,
                                            parent_item.assignmentSegId,
                                            parent_item.type,
                                            parent_item.id,
                                            parent_item.title
                                          ),
                                          state: {
                                            assignmentId:
                                              parent_item.assignmentId,
                                            parentId: parent_item.id,
                                          },
                                        }}
                                        target="_blank"
                                      >
                                        <Button
                                          variant="secondary"
                                          size="sm"
                                          className="py-2"
                                          onClick={() =>
                                            onStartNow(
                                              parent_item.assignmentId,
                                              parent_item.id,
                                              parent_item?.assignmentSegId
                                            )
                                          }
                                        >
                                          Start Now
                                        </Button>
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </Col>
                            )
                          )}
                          {/* {filteredNewEmployees.length <= 0 ? <EmptyState message={"No results found"} /> : null} */}
                        </Row>
                      </div>
                    )}
                  <div className="page overflow-visible d-flex flex-column">
                    {/* <div className="page h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                          <NoData msgicon="resume" message="Modules assigned to you will be listed here" />
                      </div> */}
                    <div className="p-20 px-32 d-flex flex-column flex-grow-1 overflow-auto">
                      <Row className="pt-2 mb-4">
                        {(activeTab === "Delayed" ||
                          activeTab === "Expired") && (
                          <Col lg={12} className="mb-3">
                            <ul class="authoringNav subAutNav align-items-center">
                              <li class="me-3 pe-3 border-right">
                                <a
                                  aria-current="page"
                                  class={`h6 text-gray-100 bIcon ${
                                    activeTab === "Delayed" ? "active" : ""
                                  }`}
                                  onClick={() => handleTabClick("Delayed")}
                                  href="#0"
                                >
                                  <span className="me-1">
                                    <IconDelayed />
                                  </span>
                                  Delayed ({delayedCount})
                                </a>
                              </li>
                              <li class="me-4">
                                <a
                                  aria-current="page"
                                  class={`h6 text-gray-100 bIcon ${
                                    activeTab === "Expired" ? "active" : ""
                                  }`}
                                  onClick={() => handleTabClick("Expired")}
                                  href="#"
                                >
                                  <span className="me-1">
                                    <IconExpired />
                                  </span>
                                  Expired ({expiredCount})
                                </a>
                              </li>
                            </ul>
                          </Col>
                        )}
                        {filteredEmployees?.length > 0 && (
                          <Col lg={12}>
                            <h4>
                              Resume your learning journey
                              <span className="ms-1 font-normal">
                                (
                                {activeTab === "Delayed"
                                  ? delayedCount
                                  : activeTab === "Expired"
                                  ? expiredCount
                                  : filteredEmployees
                                  ? filteredEmployees?.length
                                  : 0}
                                )
                              </span>
                            </h4>
                          </Col>
                        )}
                      </Row>

                      {activeTab === "All" &&
                        filteredEmployees != undefined &&
                        filteredEmployees?.length === 0 && (
                          <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                            <NoData
                              msgicon="resume"
                              message="Modules assigned to you will be listed here"
                            />
                          </div>
                        )}
                      {activeTab === "Delayed" &&
                        delayedCount !== undefined &&
                        delayedCount === 0 && (
                          <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                            <NoData
                              msgicon="resume"
                              message="None of the content assigned to you is Delayed"
                            />
                          </div>
                        )}
                      {activeTab === "Expired" &&
                        expiredCount !== undefined &&
                        expiredCount === 0 && (
                          <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                            <NoData
                              msgicon="resume"
                              message="None of the content assigned to you is Expired yet."
                            />
                          </div>
                        )}
                      {activeTab === "Completed" &&
                        filteredEmployees !== undefined &&
                        filteredEmployees?.length === 0 && (
                          <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                            <NoData
                              msgicon="resume"
                              message="You have not completed any modules yet."
                            />
                          </div>
                        )}
                      {activeTab === "New" &&
                        filteredEmployees !== undefined &&
                        filteredEmployees?.length === 0 && (
                          <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                            <NoData
                              msgicon="resume"
                              message="New modules assigned to you will be listed here"
                            />
                          </div>
                        )}
                      {activeTab === "Active" &&
                        filteredEmployees !== undefined &&
                        filteredEmployees?.length === 0 && (
                          <div className="h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                            <NoData
                              msgicon="resume"
                              message="You have not started any modules yet."
                            />
                          </div>
                        )}
                      <Accordion
                        defaultActiveKey="1"
                        className="employeeDashboardAccordianMain"
                      >
                        {filteredEmployees !== undefined &&
                          filteredEmployees?.length > 0 &&
                          filteredEmployees.map((c, index) => {
                            if (activeTab === "Delayed" && c.status === 2) {
                              return (
                                <>
                                  {c.type === "Sequence" && (
                                    <Accordion.Item
                                      eventKey={`s_${index}`}
                                      className="mb-4 cardContent"
                                    >
                                      <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                          <div className="d-flex gap-3 align-items-start">
                                            <div className="count">
                                              <span className="text-xs">
                                                {countSrNo++}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                                {c.title}
                                                <span
                                                  className={`tableTag ${
                                                    employeeStatus[c.status]
                                                      .color
                                                  } text-md ms-2 `}
                                                >
                                                  {
                                                    assignmentStatus[c.status]
                                                      .status
                                                  }
                                                </span>
                                              </p>
                                              <p className="text-gray-300 text-md mb-0">
                                                <span className="text-gray-300 pe-1">
                                                  {countLearning(c.type, c)}
                                                </span>
                                                {c.expiryDate && (
                                                  <span>
                                                    Expiring on{" "}
                                                    {getFormatDate(
                                                      c?.expiryDate
                                                    )}
                                                    {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                  </span>
                                                )}
                                                {/* {calculateDueTextAndDaysRemaining(c)
                                                .daysRemaining < 0 ? (
                                                c.expiryDate ? (
                                                  <span>
                                                    Expiring on{" "}
                                                    <b>
                                                      <DateTimeConverter utcDateTime={c.expiryDate} />
                                                    </b>
                                                  </span>
                                                ) : null
                                              ) : (
                                                <span>
                                                  Due on{" "}
                                                  <b>
                                                    {calculateDueTextAndDaysRemaining(
                                                      c
                                                    ).dueText && (
                                                      <span>
                                                        {
                                                          calculateDueTextAndDaysRemaining(
                                                            c
                                                          ).daysRemaining > 0 ? calculateDueTextAndDaysRemaining(
                                                            c
                                                          ).daysRemaining : ""
                                                        }{" "}
                                                        {calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining === 1 ? "day" : calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining === 0 ? "Today" : "days"}
                                                      </span>
                                                    )}
                                                  </b>
                                                </span>
                                              )} */}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body className="ps-5">
                                        <Accordion
                                          defaultActiveKey="SM_0"
                                          className="employeeDashboardAccordian"
                                        >
                                          {c.modules.map((mc, mindex) => (
                                            <Accordion.Item
                                              eventKey={`SM_${mindex}`}
                                              className="mb-4 cardContent borderPurple hide"
                                            >
                                              <div class="accordion-header">
                                                <div className="d-flex justify-content-between align-items-center p-1 pe-3 w-100">
                                                  <div className="d-flex gap-3 justify-content-start flex-grow-1">
                                                    <div className="count">
                                                      <span className="text-xs bgPurple">
                                                        {mindex + 1}
                                                      </span>
                                                    </div>
                                                    <div className="d-flex pe-3 flex-grow-1">
                                                      <img
                                                        src={Catalogmod2}
                                                        alt="Catalogmod2"
                                                        style={{
                                                          width: "5.1875rem",
                                                          height: "5.1875rem",
                                                        }}
                                                      />
                                                      <div className="ms-3 flex-grow-1">
                                                        <h5 className="text-gray-100 d-flex align-items-center">
                                                          {mc.title}
                                                          {mc.status === 2 && (
                                                            <span
                                                              className={`tableTag ${
                                                                employeeStatus[
                                                                  mc.status
                                                                ].color
                                                              } text-md ms-2 `}
                                                            >
                                                              {
                                                                assignmentStatus[
                                                                  mc.status
                                                                ].status
                                                              }
                                                            </span>
                                                          )}
                                                        </h5>
                                                        <p className="text-gray-200 h6 font-normal mb-0 word-break mx19rem">
                                                          {mc.description}
                                                        </p>
                                                        <div className="expdate text-12 pt-2 pb-3 text-gray-300">
                                                          {mc.expiryDate && (
                                                            <span>
                                                              Expiring on{" "}
                                                              <b>
                                                                {getFormatDate(
                                                                  mc?.expiryDate
                                                                )}
                                                                {/* <DateTimeConverter utcDateTime={mc.expiryDate} /> */}
                                                              </b>
                                                            </span>
                                                          )}
                                                          {/* {calculateDueTextAndDaysRemaining(
                                                          mc
                                                        ).daysRemaining < 0 ? (
                                                          mc.expiryDate ? (
                                                            <span>
                                                              Expiring on{" "}
                                                              <b>
                                                                <DateTimeConverter utcDateTime={mc.expiryDate} />
                                                              </b>
                                                            </span>
                                                          ) : null
                                                        ) : (
                                                          <span>
                                                            Due on{" "}
                                                            <b>
                                                              {calculateDueTextAndDaysRemaining(
                                                                mc
                                                              ).dueText && (
                                                                <span>
                                                                  {
                                                                    calculateDueTextAndDaysRemaining(
                                                                      mc
                                                                    ).daysRemaining > 0 ? calculateDueTextAndDaysRemaining(
                                                                      mc
                                                                    ).daysRemaining : ""
                                                                  }{" "}
                                                                  {calculateDueTextAndDaysRemaining(
                                                                    mc
                                                                  ).daysRemaining === 1 ? "day" : calculateDueTextAndDaysRemaining(
                                                                    mc
                                                                  ).daysRemaining === 0 ? "Today" : "days"}
                                                                </span>
                                                              )}
                                                            </b>
                                                          </span>
                                                        )} */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="d-flex align-items-center">
                                                    {mc.status === 1 && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        <Button
                                                          variant="secondary"
                                                          size="sm"
                                                          onClick={() =>
                                                            onStartNow(
                                                              c.assignmentId,
                                                              mc.id,
                                                              mc?.assignmentSegId
                                                            )
                                                          }
                                                          // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                        >
                                                          Start Now
                                                        </Button>
                                                      </Link>
                                                    )}
                                                    {(mc.status === 3 ||
                                                      mc.status === 2) && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        <Button
                                                          variant="secondary"
                                                          size="sm"
                                                          onClick={() =>
                                                            onStartNow(
                                                              c.assignmentId,
                                                              mc.id,
                                                              mc?.assignmentSegId
                                                            )
                                                          }
                                                        >
                                                          Resume
                                                        </Button>
                                                        {/* <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                      >
                                                        Resume
                                                      </Button> */}
                                                      </Link>
                                                    )}
                                                    {mc.status !== 1 &&
                                                      mc.status !== 3 &&
                                                      mc.status !== 2 && (
                                                        <Link
                                                          // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                          to={{
                                                            pathname:
                                                              getShowNowUrl(
                                                                c.id,
                                                                c.assignmentId,
                                                                mc.assignmentSegId,
                                                                c.type,
                                                                mc.id,
                                                                c.title,
                                                                // mc.title,
                                                                "Module/"
                                                              ),
                                                            state: {
                                                              assignmentId:
                                                                c.assignmentId,
                                                              parentId: c.id,
                                                            },
                                                          }}
                                                          // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                          target="_blank"
                                                        >
                                                          <span
                                                            className={`tableTag ${
                                                              employeeStatus[
                                                                mc.status
                                                              ].color
                                                            } text-md ms-2 `}
                                                          >
                                                            {
                                                              assignmentStatus[
                                                                mc.status
                                                              ].status
                                                            }
                                                          </span>
                                                        </Link>
                                                      )}
                                                  </div>
                                                </div>
                                                {/* <AccordionProgressBar value={80} /> */}
                                              </div>
                                            </Accordion.Item>
                                          ))}
                                        </Accordion>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )}

                                  {c.type === "Module" && (
                                    <Accordion.Item
                                      eventKey={`M_${index}`}
                                      className="mb-4 cardContent hide"
                                    >
                                      <div class="accordion-header">
                                        <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                          <div className="d-flex gap-3 align-items-start">
                                            <div className="count">
                                              <span className="text-xs">
                                                {countSrNo++}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                                {c.title}
                                                {c.status === 0 ? (
                                                  <Link
                                                    to={{
                                                      pathname: getShowNowUrl(
                                                        c.id,
                                                        c.assignmentId,
                                                        c.assignmentSegId,
                                                        c.type,
                                                        c.id,
                                                        c.title
                                                      ),
                                                      state: {
                                                        assignmentId:
                                                          c.assignmentId,
                                                        parentId: c.id,
                                                      },
                                                    }}
                                                    target="_blank"
                                                  >
                                                    <span
                                                      className={`tableTag ${
                                                        employeeStatus[c.status]
                                                          .color
                                                      } text-md ms-2 `}
                                                    >
                                                      {
                                                        assignmentStatus[
                                                          c.status
                                                        ].status
                                                      }
                                                    </span>
                                                  </Link>
                                                ) : (
                                                  <span
                                                    className={`tableTag ${
                                                      employeeStatus[c.status]
                                                        .color
                                                    } text-md ms-2 `}
                                                  >
                                                    {
                                                      assignmentStatus[c.status]
                                                        .status
                                                    }
                                                  </span>
                                                )}
                                                {/* <span
                                                className={`tableTag ${employeeStatus[c.status].color
                                                  } text-md ms-2 `}
                                              >
                                                {
                                                  assignmentStatus[c.status]
                                                    .status
                                                }
                                              </span> */}
                                              </p>
                                              <p className="text-gray-300 text-md mb-0">
                                                <span className="text-gray-300 pe-1">
                                                  {countLearning(c.type, c)}
                                                </span>

                                                {c.expiryDate && (
                                                  <span>
                                                    Expiring on{" "}
                                                    <b>
                                                      {getFormatDate(
                                                        c?.expiryDate
                                                      )}
                                                      {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                    </b>
                                                  </span>
                                                )}
                                                {/* {calculateDueTextAndDaysRemaining(c)
                                                .daysRemaining < 0 ? (
                                                c.expiryDate ? (
                                                  <span>
                                                    Expiring on{" "}
                                                    <b>
                                                      <DateTimeConverter utcDateTime={c.expiryDate} />
                                                    </b>
                                                  </span>
                                                ) : null
                                              ) : (
                                                <span>
                                                  Due on{" "}
                                                  <b>
                                                    {calculateDueTextAndDaysRemaining(
                                                      c
                                                    ).dueText && (
                                                      <span>
                                                        {
                                                          calculateDueTextAndDaysRemaining(
                                                            c
                                                          ).daysRemaining > 0 ? calculateDueTextAndDaysRemaining(
                                                            c
                                                          ).daysRemaining : ""
                                                        }{" "}
                                                        {calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining === 1 ? "day" : calculateDueTextAndDaysRemaining(
                                                            c
                                                          ).daysRemaining === 0 ? "Today" : "days"}
                                                      </span>
                                                    )}
                                                  </b>
                                                </span>
                                              )} */}
                                              </p>
                                            </div>
                                          </div>
                                          {c.status === 1 && (
                                            <Link
                                              to={{
                                                pathname: getShowNowUrl(
                                                  c.id,
                                                  c.assignmentId,
                                                  c.assignmentSegId,
                                                  c.type,
                                                  c.id,
                                                  c.title
                                                ),
                                                state: {
                                                  assignmentId: c.assignmentId,
                                                  parentId: c.id,
                                                },
                                              }}
                                              target="_blank"
                                            >
                                              <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() =>
                                                  onStartNow(
                                                    c.assignmentId,
                                                    c.id,
                                                    c?.assignmentSegId
                                                  )
                                                }
                                              >
                                                Start Now
                                              </Button>
                                            </Link>
                                          )}
                                          {(c.status === 3 ||
                                            c.status === 2) && (
                                            <Link
                                              to={{
                                                pathname: getShowNowUrl(
                                                  c.id,
                                                  c.assignmentId,
                                                  c.assignmentSegId,
                                                  c.type,
                                                  c.id,
                                                  c.title
                                                ),
                                                state: {
                                                  assignmentId: c.assignmentId,
                                                  parentId: c.id,
                                                },
                                              }}
                                              target="_blank"
                                            >
                                              <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() =>
                                                  onStartNow(
                                                    c.assignmentId,
                                                    c.id,
                                                    c?.assignmentSegId
                                                  )
                                                }
                                              >
                                                Resume
                                              </Button>
                                              {/* <Button
                                              variant="secondary"
                                              size="sm"
                                            >
                                              Resume
                                            </Button> */}
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </Accordion.Item>
                                  )}
                                </>
                              );
                            } else if (
                              activeTab === "Expired" &&
                              c.status === 4
                            ) {
                              return (
                                <>
                                  {c.type === "Sequence" && (
                                    <Accordion.Item
                                      eventKey={`s_${index}`}
                                      className="mb-4 cardContent"
                                    >
                                      <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                          <div className="d-flex gap-3 align-items-start">
                                            <div className="count">
                                              <span className="text-xs">
                                                {countSrNo++}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                                {c.title}
                                                <span
                                                  className={`tableTag ${
                                                    employeeStatus[c.status]
                                                      .color
                                                  } text-md ms-2 `}
                                                >
                                                  {
                                                    assignmentStatus[c.status]
                                                      .status
                                                  }
                                                </span>
                                              </p>
                                              <p className="text-gray-300 text-md mb-0">
                                                <span className="text-gray-300 pe-1">
                                                  {countLearning(c.type, c)}
                                                </span>
                                                {c.expiryDate && (
                                                  <span>
                                                    Expired on{" "}
                                                    <b>
                                                      {getFormatDate(
                                                        c?.expiryDate
                                                      )}
                                                      {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                    </b>
                                                  </span>
                                                )}
                                                {/* {calculateDueTextAndDaysRemaining(c)
                                              .daysRemaining < 0 ? (
                                              c.expiryDate ? (
                                                <span>
                                                  Expired on{" "}
                                                  <b>
                                                    <DateTimeConverter utcDateTime={c.expiryDate} />
                                                  </b>
                                                </span>
                                              ) : null
                                            ) : (
                                              <span>
                                                Due on{" "}
                                                <b>
                                                  {calculateDueTextAndDaysRemaining(
                                                    c
                                                  ).dueText && (
                                                    <span>
                                                      {
                                                        calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining > 0 ? calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining : ""
                                                      }{" "}
                                                      {calculateDueTextAndDaysRemaining(
                                                        c
                                                      ).daysRemaining === 1 ? "day" : calculateDueTextAndDaysRemaining(
                                                        c
                                                      ).daysRemaining === 0 ? "Today" : "days"}
                                                    </span>
                                                  )}
                                                </b>
                                              </span>
                                            )} */}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body className="ps-5">
                                        <Accordion
                                          defaultActiveKey="SM_0"
                                          className="employeeDashboardAccordian"
                                        >
                                          {c.modules.map((mc, mindex) => (
                                            <Accordion.Item
                                              eventKey={`SM_${mindex}`}
                                              className="mb-4 cardContent borderPurple hide"
                                            >
                                              <div class="accordion-header">
                                                <div className="d-flex justify-content-between align-items-center p-1 pe-3 w-100">
                                                  <div className="d-flex gap-3 justify-content-start flex-grow-1">
                                                    <div className="count">
                                                      <span className="text-xs bgPurple">
                                                        {mindex + 1}
                                                      </span>
                                                    </div>
                                                    <div className="d-flex pe-3 flex-grow-1">
                                                      <img
                                                        src={Catalogmod2}
                                                        alt="Catalogmod2"
                                                        style={{
                                                          width: "5.1875rem",
                                                          height: "5.1875rem",
                                                        }}
                                                      />
                                                      <div className="ms-3 flex-grow-1">
                                                        <h5 className="text-gray-100 d-flex align-items-center">
                                                          {mc.title}
                                                          {mc.status === 2 && (
                                                            <span
                                                              className={`tableTag ${
                                                                employeeStatus[
                                                                  mc.status
                                                                ].color
                                                              } text-md ms-2 `}
                                                            >
                                                              {
                                                                assignmentStatus[
                                                                  mc.status
                                                                ].status
                                                              }
                                                            </span>
                                                          )}
                                                        </h5>
                                                        <p className="text-gray-200 h6 font-normal mb-0 word-break mx19rem">
                                                          {mc.description}
                                                        </p>
                                                        <div className="expdate text-12 pt-2 pb-3 text-gray-300">
                                                          {mc.expiryDate && (
                                                            <span>
                                                              Expired on{" "}
                                                              <b>
                                                                {getFormatDate(
                                                                  mc?.expiryDate
                                                                )}
                                                                {/* <DateTimeConverter utcDateTime={mc.expiryDate} /> */}
                                                              </b>
                                                            </span>
                                                          )}
                                                          {/* {calculateDueTextAndDaysRemaining(
                                                        mc
                                                      ).daysRemaining < 0 ? (
                                                        mc.expiryDate ? (
                                                          <span>
                                                            Expired on{" "}
                                                            <b>
                                                              <DateTimeConverter utcDateTime={mc.expiryDate} />
                                                            </b>
                                                          </span>
                                                        ) : null
                                                      ) : (
                                                        <span>
                                                          Due on{" "}
                                                          <b>
                                                            {calculateDueTextAndDaysRemaining(
                                                              mc
                                                            ).dueText && (
                                                              <span>
                                                                {
                                                                  calculateDueTextAndDaysRemaining(
                                                                    mc
                                                                  ).daysRemaining > 0 ? calculateDueTextAndDaysRemaining(
                                                                    mc
                                                                  ).daysRemaining : ""
                                                                }{" "}
                                                                {calculateDueTextAndDaysRemaining(
                                                                  mc
                                                                ).daysRemaining === 1 ? "day" : calculateDueTextAndDaysRemaining(
                                                                  mc
                                                                ).daysRemaining === 0 ? "Today" : "days"}
                                                              </span>
                                                            )}
                                                          </b>
                                                        </span>
                                                      )} */}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="d-flex align-items-center">
                                                    {mc.status === 1 && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        <Button
                                                          variant="secondary"
                                                          size="sm"
                                                          onClick={() =>
                                                            onStartNow(
                                                              c.assignmentId,
                                                              mc.id,
                                                              mc?.assignmentSegId
                                                            )
                                                          }
                                                          // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                        >
                                                          Start Now
                                                        </Button>
                                                      </Link>
                                                    )}
                                                    {(mc.status === 3 ||
                                                      mc.status === 2) && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        {/* <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        onClick={() =>
                                                          onStartNow(
                                                            c.assignmentId,
                                                            mc.id,
                                                            mc?.assignmentSegId
                                                          )
                                                        }
                                                      >
                                                        Resume
                                                      </Button> */}
                                                        <Button
                                                          variant="secondary"
                                                          size="sm"
                                                          // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                        >
                                                          Resume
                                                        </Button>
                                                      </Link>
                                                    )}
                                                    {mc.status === 0 && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        <span
                                                          className={`tableTag ${
                                                            employeeStatus[
                                                              mc.status
                                                            ].color
                                                          } text-md ms-2 `}
                                                        >
                                                          {
                                                            assignmentStatus[
                                                              mc.status
                                                            ].status
                                                          }
                                                        </span>
                                                      </Link>
                                                    )}
                                                    {mc.status === 4 && (
                                                      <span
                                                        className={`tableTag ${
                                                          employeeStatus[
                                                            mc.status
                                                          ].color
                                                        } text-md ms-2 `}
                                                      >
                                                        {
                                                          assignmentStatus[
                                                            mc.status
                                                          ].status
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                                {/* <AccordionProgressBar value={80} /> */}
                                              </div>
                                            </Accordion.Item>
                                          ))}
                                        </Accordion>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )}

                                  {c.type === "Module" && (
                                    <Accordion.Item
                                      eventKey={`M_${index}`}
                                      className="mb-4 cardContent hide"
                                    >
                                      <div class="accordion-header">
                                        <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                          <div className="d-flex gap-3 align-items-start">
                                            <div className="count">
                                              <span className="text-xs">
                                                {countSrNo++}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                                {c.title}
                                                {c.status === 0 ? (
                                                  <Link
                                                    to={{
                                                      pathname: getShowNowUrl(
                                                        c.id,
                                                        c.assignmentId,
                                                        c.assignmentSegId,
                                                        c.type,
                                                        c.id,
                                                        c.title
                                                      ),
                                                      state: {
                                                        assignmentId:
                                                          c.assignmentId,
                                                        parentId: c.id,
                                                      },
                                                    }}
                                                    target="_blank"
                                                  >
                                                    <span
                                                      className={`tableTag ${
                                                        employeeStatus[c.status]
                                                          .color
                                                      } text-md ms-2 `}
                                                    >
                                                      {
                                                        assignmentStatus[
                                                          c.status
                                                        ].status
                                                      }
                                                    </span>
                                                  </Link>
                                                ) : (
                                                  <span
                                                    className={`tableTag ${
                                                      employeeStatus[c.status]
                                                        .color
                                                    } text-md ms-2 `}
                                                  >
                                                    {
                                                      assignmentStatus[c.status]
                                                        .status
                                                    }
                                                  </span>
                                                )}
                                                {/* <span
                                              className={`tableTag ${employeeStatus[c.status].color
                                                } text-md ms-2 `}
                                            >
                                              {
                                                assignmentStatus[c.status]
                                                  .status
                                              }
                                            </span> */}
                                              </p>
                                              <p className="text-gray-300 text-md mb-0">
                                                <span className="text-gray-300 pe-1">
                                                  {countLearning(c.type, c)}
                                                </span>

                                                {c.expiryDate && (
                                                  <span>
                                                    Expired on{" "}
                                                    <b>
                                                      {getFormatDate(
                                                        c?.expiryDate
                                                      )}
                                                      {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                    </b>
                                                  </span>
                                                )}
                                                {/* {calculateDueTextAndDaysRemaining(c)
                                              .daysRemaining < 0 ? (
                                              c.expiryDate ? (
                                                <span>
                                                  Expired on{" "}
                                                  <b>
                                                    <DateTimeConverter utcDateTime={c.expiryDate} />
                                                  </b>
                                                </span>
                                              ) : null
                                            ) : (
                                              <span>
                                                Due on{" "}
                                                <b>
                                                  {calculateDueTextAndDaysRemaining(
                                                    c
                                                  ).dueText && (
                                                    <span>
                                                      {
                                                        calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining > 0 ? calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining : ""
                                                      }{" "}
                                                      {calculateDueTextAndDaysRemaining(
                                                        c
                                                      ).daysRemaining === 1 ? "day" : calculateDueTextAndDaysRemaining(
                                                        c
                                                      ).daysRemaining === 0 ? "Today" : "days"}
                                                    </span>
                                                  )}
                                                </b>
                                              </span>
                                            )} */}
                                              </p>
                                            </div>
                                          </div>
                                          {c.status === 1 && (
                                            <Link
                                              to={{
                                                pathname: getShowNowUrl(
                                                  c.id,
                                                  c.assignmentId,
                                                  c.assignmentSegId,
                                                  c.type,
                                                  c.id,
                                                  c.title
                                                ),
                                                state: {
                                                  assignmentId: c.assignmentId,
                                                  parentId: c.id,
                                                },
                                              }}
                                              target="_blank"
                                            >
                                              <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() =>
                                                  onStartNow(
                                                    c.assignmentId,
                                                    c.id,
                                                    c?.assignmentSegId
                                                  )
                                                }
                                              >
                                                Start Now
                                              </Button>
                                            </Link>
                                          )}
                                          {(c.status === 3 ||
                                            c.status === 2) && (
                                            <Link
                                              to={{
                                                pathname: getShowNowUrl(
                                                  c.id,
                                                  c.assignmentId,
                                                  c.assignmentSegId,
                                                  c.type,
                                                  c.id,
                                                  c.title
                                                ),
                                                state: {
                                                  assignmentId: c.assignmentId,
                                                  parentId: c.id,
                                                },
                                              }}
                                              target="_blank"
                                            >
                                              <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() =>
                                                  onStartNow(
                                                    c.assignmentId,
                                                    c.id,
                                                    c?.assignmentSegId
                                                  )
                                                }
                                              >
                                                Resume
                                              </Button>
                                              {/* <Button
                                              variant="secondary"
                                              size="sm"
                                            >
                                              Resume
                                            </Button> */}
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </Accordion.Item>
                                  )}
                                </>
                              );
                            }

                            if (
                              activeTab === "All" ||
                              activeTab === "New" ||
                              activeTab === "Active" ||
                              activeTab === "Completed"
                            ) {
                              return (
                                <>
                                  {c.type === "Sequence" && (
                                    <Accordion.Item
                                      eventKey={`s_${index}`}
                                      className="mb-4 cardContent"
                                    >
                                      <Accordion.Header>
                                        <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                          <div className="d-flex gap-3 align-items-start">
                                            <div className="count">
                                              <span className="text-xs">
                                                {countSrNo++}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                                {c.title}
                                                <span
                                                  className={`tableTag ${
                                                    employeeStatus[c.status]
                                                      .color
                                                  } text-md ms-2 `}
                                                >
                                                  {
                                                    assignmentStatus[c.status]
                                                      .status
                                                  }
                                                </span>
                                              </p>
                                              <p className="text-gray-300 text-md mb-0">
                                                <span className="text-gray-300 pe-1">
                                                  {countLearning(c.type, c)}
                                                </span>
                                                {/* {c.expiryDate && (
                                              <span>
                                                | Expiring on {" "}
                                                {getFormatDate(
                                                  c.expiryDate
                                                )}
                                              </span>
                                            )} */}
                                                {c.status !== 0 && (
                                                  <>
                                                    {c.status === 2 &&
                                                      c.expiryDate && (
                                                        <span>
                                                          Expiring on{" "}
                                                          <b>
                                                            {getFormatDate(
                                                              c?.expiryDate
                                                            )}
                                                            {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                          </b>
                                                        </span>
                                                      )}
                                                    {c.status === 4 &&
                                                      c.expiryDate && (
                                                        <span>
                                                          Expired on{" "}
                                                          <b>
                                                            {getFormatDate(
                                                              c?.expiryDate
                                                            )}
                                                            {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                          </b>
                                                        </span>
                                                      )}

                                                    {(c.status === 1 ||
                                                      c.status === 3) && (
                                                      <>
                                                        {calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining < 0 ? (
                                                          c.expiryDate ? (
                                                            <span>
                                                              Expiring on{" "}
                                                              <b>
                                                                {getFormatDate(
                                                                  c?.expiryDate
                                                                )}
                                                                {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                              </b>
                                                            </span>
                                                          ) : null
                                                        ) : (
                                                          <span>
                                                            Due on{" "}
                                                            <b>
                                                              {calculateDueTextAndDaysRemaining(
                                                                c
                                                              ).dueText && (
                                                                <span>
                                                                  {calculateDueTextAndDaysRemaining(
                                                                    c
                                                                  )
                                                                    .daysRemaining >
                                                                  0
                                                                    ? calculateDueTextAndDaysRemaining(
                                                                        c
                                                                      )
                                                                        .daysRemaining
                                                                    : ""}{" "}
                                                                  {calculateDueTextAndDaysRemaining(
                                                                    c
                                                                  )
                                                                    .daysRemaining ===
                                                                  1
                                                                    ? "day"
                                                                    : calculateDueTextAndDaysRemaining(
                                                                        c
                                                                      )
                                                                        .daysRemaining ===
                                                                      0
                                                                    ? "Today"
                                                                    : "days"}
                                                                </span>
                                                              )}
                                                            </b>
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Header>
                                      <Accordion.Body className="ps-5">
                                        <Accordion
                                          defaultActiveKey="SM_0"
                                          className="employeeDashboardAccordian"
                                        >
                                          {c.modules.map((mc, mindex) => (
                                            <Accordion.Item
                                              eventKey={`SM_${mindex}`}
                                              className="mb-4 cardContent borderPurple hide"
                                            >
                                              <div class="accordion-header">
                                                <div className="d-flex justify-content-between align-items-center p-1 pe-3 w-100">
                                                  <div className="d-flex gap-3 justify-content-start flex-grow-1">
                                                    <div className="count">
                                                      <span className="text-xs bgPurple">
                                                        {mindex + 1}
                                                      </span>
                                                    </div>
                                                    <div className="d-flex pe-3 flex-grow-1">
                                                      <img
                                                        src={Catalogmod2}
                                                        alt="Catalogmod2"
                                                        style={{
                                                          width: "5.1875rem",
                                                          height: "5.1875rem",
                                                        }}
                                                      />
                                                      <div className="ms-3 flex-grow-1">
                                                        <h5 className="text-gray-100 d-flex align-items-center">
                                                          {mc.title}
                                                          {mc.status === 2 && (
                                                            <span
                                                              className={`tableTag ${
                                                                employeeStatus[
                                                                  mc.status
                                                                ].color
                                                              } text-md ms-2 `}
                                                            >
                                                              {
                                                                assignmentStatus[
                                                                  mc.status
                                                                ].status
                                                              }
                                                            </span>
                                                          )}
                                                        </h5>
                                                        <p className="text-gray-200 h6 font-normal mb-0 word-break mx19rem">
                                                          {mc.description}
                                                        </p>
                                                        <div className="expdate text-12 pt-2 pb-3 text-gray-300">
                                                          {/* {mc.expiryDate && (
                                                        <span>
                                                          Expiring on {" "}
                                                          {getFormatDate(
                                                            mc.expiryDate
                                                          )}
                                                        </span>
                                                      )} */}

                                                          {mc.status !== 0 && (
                                                            <>
                                                              {mc.status ===
                                                                2 &&
                                                                mc.expiryDate && (
                                                                  <span>
                                                                    Expiring on{" "}
                                                                    <b>
                                                                      {getFormatDate(
                                                                        mc?.expiryDate
                                                                      )}
                                                                      {/* <DateTimeConverter utcDateTime={mc.expiryDate} /> */}
                                                                    </b>
                                                                  </span>
                                                                )}
                                                              {mc.status ===
                                                                4 &&
                                                                mc.expiryDate && (
                                                                  <span>
                                                                    Expired on{" "}
                                                                    <b>
                                                                      {getFormatDate(
                                                                        mc?.expiryDate
                                                                      )}
                                                                      {/* <DateTimeConverter utcDateTime={mc.expiryDate} /> */}
                                                                    </b>
                                                                  </span>
                                                                )}

                                                              {(mc.status ===
                                                                1 ||
                                                                mc.status ===
                                                                  3) && (
                                                                <>
                                                                  {calculateDueTextAndDaysRemaining(
                                                                    mc
                                                                  )
                                                                    .daysRemaining <
                                                                  0 ? (
                                                                    mc.expiryDate ? (
                                                                      <span>
                                                                        Expiring
                                                                        on{" "}
                                                                        <b>
                                                                          {getFormatDate(
                                                                            mc?.expiryDate
                                                                          )}
                                                                          {/* <DateTimeConverter utcDateTime={mc.expiryDate} /> */}
                                                                        </b>
                                                                      </span>
                                                                    ) : null
                                                                  ) : (
                                                                    <span>
                                                                      Due on{" "}
                                                                      <b>
                                                                        {calculateDueTextAndDaysRemaining(
                                                                          mc
                                                                        )
                                                                          .dueText && (
                                                                          <span>
                                                                            {calculateDueTextAndDaysRemaining(
                                                                              mc
                                                                            )
                                                                              .daysRemaining >
                                                                            0
                                                                              ? calculateDueTextAndDaysRemaining(
                                                                                  mc
                                                                                )
                                                                                  .daysRemaining
                                                                              : ""}{" "}
                                                                            {calculateDueTextAndDaysRemaining(
                                                                              mc
                                                                            )
                                                                              .daysRemaining ===
                                                                            1
                                                                              ? "day"
                                                                              : calculateDueTextAndDaysRemaining(
                                                                                  mc
                                                                                )
                                                                                  .daysRemaining ===
                                                                                0
                                                                              ? "Today"
                                                                              : "days"}
                                                                          </span>
                                                                        )}
                                                                      </b>
                                                                    </span>
                                                                  )}
                                                                </>
                                                              )}
                                                            </>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                  <div className="d-flex align-items-center">
                                                    {mc.status === 1 && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        <Button
                                                          variant="secondary"
                                                          size="sm"
                                                          onClick={() =>
                                                            onStartNow(
                                                              c.assignmentId,
                                                              mc.id,
                                                              mc?.assignmentSegId
                                                            )
                                                          }
                                                          // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                        >
                                                          Start Now
                                                        </Button>
                                                      </Link>
                                                    )}
                                                    {(mc.status === 3 ||
                                                      mc.status === 2) && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        <Button
                                                          variant="secondary"
                                                          size="sm"
                                                          onClick={() =>
                                                            onStartNow(
                                                              c.assignmentId,
                                                              mc.id,
                                                              mc?.assignmentSegId
                                                            )
                                                          }
                                                        >
                                                          Resume
                                                        </Button>
                                                        {/* <Button
                                                        variant="secondary"
                                                        size="sm"
                                                        // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                      >
                                                        Resume
                                                      </Button> */}
                                                      </Link>
                                                    )}
                                                    {mc.status === 0 && (
                                                      <Link
                                                        // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                        to={{
                                                          pathname:
                                                            getShowNowUrl(
                                                              c.id,
                                                              c.assignmentId,
                                                              mc.assignmentSegId,
                                                              c.type,
                                                              mc.id,
                                                              c.title,
                                                              // mc.title,
                                                              "Module/"
                                                            ),
                                                          state: {
                                                            assignmentId:
                                                              c.assignmentId,
                                                            parentId: c.id,
                                                          },
                                                        }}
                                                        // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                        target="_blank"
                                                      >
                                                        <span
                                                          className={`tableTag ${
                                                            employeeStatus[
                                                              mc.status
                                                            ].color
                                                          } text-md ms-2 `}
                                                        >
                                                          {
                                                            assignmentStatus[
                                                              mc.status
                                                            ].status
                                                          }
                                                        </span>
                                                      </Link>
                                                    )}
                                                    {mc.status === 4 && (
                                                      <span
                                                        className={`tableTag ${
                                                          employeeStatus[
                                                            mc.status
                                                          ].color
                                                        } text-md ms-2 `}
                                                      >
                                                        {
                                                          assignmentStatus[
                                                            mc.status
                                                          ].status
                                                        }
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                                {/* <AccordionProgressBar value={80} /> */}
                                              </div>
                                            </Accordion.Item>
                                          ))}
                                        </Accordion>
                                      </Accordion.Body>
                                    </Accordion.Item>
                                  )}

                                  {c.type === "Module" && (
                                    <Accordion.Item
                                      eventKey={`M_${index}`}
                                      className="mb-4 cardContent hide"
                                    >
                                      <div class="accordion-header">
                                        <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                          <div className="d-flex gap-3 align-items-start">
                                            <div className="count">
                                              <span className="text-xs">
                                                {countSrNo++}
                                              </span>
                                            </div>
                                            <div>
                                              <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                                {c.title}
                                                {c.status === 0 ? (
                                                  <Link
                                                    to={{
                                                      pathname: getShowNowUrl(
                                                        c.id,
                                                        c.assignmentId,
                                                        c.assignmentSegId,
                                                        c.type,
                                                        c.id,
                                                        c.title
                                                      ),
                                                      state: {
                                                        assignmentId:
                                                          c.assignmentId,
                                                        parentId: c.id,
                                                      },
                                                    }}
                                                    target="_blank"
                                                  >
                                                    <span
                                                      className={`tableTag ${
                                                        employeeStatus[c.status]
                                                          .color
                                                      } text-md ms-2 `}
                                                    >
                                                      {
                                                        assignmentStatus[
                                                          c.status
                                                        ].status
                                                      }
                                                    </span>
                                                  </Link>
                                                ) : (
                                                  <span
                                                    className={`tableTag ${
                                                      employeeStatus[c.status]
                                                        .color
                                                    } text-md ms-2 `}
                                                  >
                                                    {
                                                      assignmentStatus[c.status]
                                                        .status
                                                    }
                                                  </span>
                                                )}
                                                {/* <span
                                              className={`tableTag ${employeeStatus[c.status].color
                                                } text-md ms-2 `}
                                            >
                                              {
                                                assignmentStatus[c.status]
                                                  .status
                                              }
                                            </span> */}
                                              </p>
                                              <p className="text-gray-300 text-md mb-0">
                                                <span className="text-gray-300 pe-1">
                                                  {countLearning(c.type, c)}
                                                </span>

                                                {/* {c.expiryDate && (
                                              <span>
                                                | Expiring on {" "}
                                                {getFormatDate(
                                                  c.expiryDate
                                                )}
                                              </span>
                                            )} */}
                                                {c.status !== 0 && (
                                                  <>
                                                    {c.status === 2 &&
                                                      c.expiryDate && (
                                                        <span>
                                                          Expiring on{" "}
                                                          <b>
                                                            {getFormatDate(
                                                              c?.expiryDate
                                                            )}
                                                            {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                          </b>
                                                        </span>
                                                      )}
                                                    {c.status === 4 &&
                                                      c.expiryDate && (
                                                        <span>
                                                          Expired on{" "}
                                                          <b>
                                                            {getFormatDate(
                                                              c?.expiryDate
                                                            )}
                                                            {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                          </b>
                                                        </span>
                                                      )}

                                                    {(c.status === 1 ||
                                                      c.status === 3) && (
                                                      <>
                                                        {calculateDueTextAndDaysRemaining(
                                                          c
                                                        ).daysRemaining < 0 ? (
                                                          c.expiryDate ? (
                                                            <span>
                                                              Expiring on{" "}
                                                              <b>
                                                                {getFormatDate(
                                                                  c?.expiryDate
                                                                )}
                                                                {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                              </b>
                                                            </span>
                                                          ) : null
                                                        ) : (
                                                          <span>
                                                            Due on{" "}
                                                            <b>
                                                              {calculateDueTextAndDaysRemaining(
                                                                c
                                                              ).dueText && (
                                                                <span>
                                                                  {calculateDueTextAndDaysRemaining(
                                                                    c
                                                                  )
                                                                    .daysRemaining >
                                                                  0
                                                                    ? calculateDueTextAndDaysRemaining(
                                                                        c
                                                                      )
                                                                        .daysRemaining
                                                                    : ""}{" "}
                                                                  {calculateDueTextAndDaysRemaining(
                                                                    c
                                                                  )
                                                                    .daysRemaining ===
                                                                  1
                                                                    ? "day"
                                                                    : calculateDueTextAndDaysRemaining(
                                                                        c
                                                                      )
                                                                        .daysRemaining ===
                                                                      0
                                                                    ? "Today"
                                                                    : "days"}
                                                                </span>
                                                              )}
                                                            </b>
                                                          </span>
                                                        )}
                                                      </>
                                                    )}
                                                  </>
                                                )}
                                              </p>
                                            </div>
                                          </div>
                                          {c.status === 1 && (
                                            <Link
                                              to={{
                                                pathname: getShowNowUrl(
                                                  c.id,
                                                  c.assignmentId,
                                                  c.assignmentSegId,
                                                  c.type,
                                                  c.id,
                                                  c.title
                                                ),
                                                state: {
                                                  assignmentId: c.assignmentId,
                                                  parentId: c.id,
                                                },
                                              }}
                                              target="_blank"
                                            >
                                              <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() =>
                                                  onStartNow(
                                                    c.assignmentId,
                                                    c.id,
                                                    c?.assignmentSegId
                                                  )
                                                }
                                              >
                                                Start Now
                                              </Button>
                                            </Link>
                                          )}
                                          {(c.status === 3 ||
                                            c.status === 2) && (
                                            <Link
                                              to={{
                                                pathname: getShowNowUrl(
                                                  c.id,
                                                  c.assignmentId,
                                                  c.assignmentSegId,
                                                  c.type,
                                                  c.id,
                                                  c.title
                                                ),
                                                state: {
                                                  assignmentId: c.assignmentId,
                                                  parentId: c.id,
                                                },
                                              }}
                                              target="_blank"
                                            >
                                              <Button
                                                variant="secondary"
                                                size="sm"
                                                onClick={() =>
                                                  onStartNow(
                                                    c.assignmentId,
                                                    c.id,
                                                    c?.assignmentSegId
                                                  )
                                                }
                                              >
                                                Resume
                                              </Button>
                                              {/* <Button
                                              variant="secondary"
                                              size="sm"
                                            >
                                              Resume
                                            </Button> */}
                                            </Link>
                                          )}
                                        </div>
                                      </div>
                                    </Accordion.Item>
                                  )}
                                </>
                              );
                            }
                          })}
                      </Accordion>
                    </div>
                  </div>
                </div>

                {/* empty state */}
                {/* <div className="page h-100 bg-base-100 d-flex align-items-center justify-content-center text-center">
                <NoData msgicon="resume" message="Modules assigned to you will be listed here" />
              </div> */}
                {/* end empty state */}
                {/* <Row>
                <Col lg={12} className="d-flex">
                </Col>
              </Row> */}
              </Container>
            </div>
          )
        )}
      </div>

      {/* <Modal
        className="uPass"
        size="md"
        centered
        show={responsePopup?.show || updatePassword}
      >
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
        {updatePassword && (
          <UpdatePassword
            onClose={setUpdatePassword}
            oldPasswords={userProfiles?.password}

          />
        )}
      </Modal> */}

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={assignedContentModal}
        onHide={() => setAssignedContentModal(false)}
      >
        <AssignedContentModal
          handleModal={onAllStartNow}
          allAssignedContent={filteredAllNewEmployees}
          onClosed={setAssignedContentModal}
        />
      </Modal>
    </>
  );
};

export default EmployeeAccount;
