import React from "react";
import { IconTopSearch, IconCross, IconModule, IconTick } from "../../icons/Icons";
import { Status } from "../../common";

const ContentView = ({
  index,
  id,
  type,
  status,
  title,
  setFieldValue,
  onRemove,
  touchedMethod,
  tuchedData,
  list,
  drop,
  dragStart,
  dragEnter,
}) => {
  
  return (
    <div 
       onDragStart={(e) => dragStart(e, index)}
        onDragEnter={(e) => dragEnter(e, index)}
        onDragEnd={(e) => drop(list, setFieldValue)}
        key={index}
        draggable
    className="d-flex gap-3 bg-gray-600 borderAll p-12 rounded-6 mwidth-440 position-relative mb-12">
      <IconModule />
      <div className="fullDiv">
        <p className="mb-0 text-md text-primary-200">
          {type}
          {/* <span className={`tableTag ${Status[status].color} text-md`}>
          <IconTick />{status}
          </span> */}
          {status === "Draft" && (
            <span className={`tableTag ${Status[status].color} text-md`}>
              {status}
            </span>
          )}
        </p>
        <p className="mb-0 mt-1 font-medium" style={{ marginRight: "20%" }}>
          {title}
        </p>
      </div>
      <div
        className="crossBtn"
        onClick={() => onRemove(id, setFieldValue, touchedMethod, tuchedData)}
      >
        <IconCross />
      </div>
    </div>
  );
};

export default ContentView;
