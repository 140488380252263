import React from "react";
import { useState, useEffect } from "react";
import { getUsersAsync, getRolesAsync } from "../../reducers/user-reducers";
import { useDispatch, useSelector } from "react-redux";
import getInitialsInName from "../../helper/GetNameInitials";
import UserImage from "../../components/UserImage/UserImage";

const OwnerImgCount = ({title}) => {
  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState(null);
  const [pageSize, setPageSize] = useState(1000);
  const [entities, setEntities] = useState(null);
  // const entities = useSelector((state) => state.user.users);
  // const roleList = useSelector((state) => state.user.roles);

  useEffect(() => {
    getRoles();
  }, [title]);

  useEffect(() => {
    if (roleId !== null) {
      getUsers();
    }
  }, [roleId]);

  const getRoles = () => {
    let reqBody = {
      name: "Admin"
    };
    dispatch(getRolesAsync(reqBody)).then((res) => {
      if (res.payload.records && res.payload.records.length > 0) {
        res.payload.records.map((role) => {
          if (role.name === "Admin") {
            setRoleId(role.id);
          }
        });
      }
    });
  };

  const getUsers = () => {
    let reqBody = {
      pageSize: pageSize,
      filters: { 
        roleIds: [roleId != null && roleId],
        statusIds:[1] 
      },
    };
    dispatch(getUsersAsync(reqBody)).then((res) => {
      if (res.payload) {
        setEntities(res.payload);
      }
    });
  };

  // console.log(roleId, 'rolesrolesrolesrolesroles');
  // console.log(entities, 'entitiesentitiesentitiesentitiesentities');

  return (
    <div className="dataItem">
          <p className="font-medium h6 mb-3">{title}</p>
      <div className="d-flex">
        {entities?.userCounts?.imgCount === 1 ? (
          <div className="headImg moduleImg">
            {entities?.users[0].img ? (
              <UserImage imgKey={entities?.users[0].img}/>
            ) : (
              <img
                src={getInitialsInName(entities?.users[0].name ?? "N A", 24)}
                alt={entities?.users[0].name}
              />
            )}
          </div>
        ) : entities?.userCounts?.imgCount === 2 ? (
          <>
            <div className="headImg moduleImg">
              {entities?.users[0].img ? (
                <UserImage imgKey={entities?.users[0].img}/>
              ) : (
                <img
                  src={getInitialsInName(entities?.users[0].name ?? "N A", 24)}
                  alt={entities?.users[0].name}
                />
              )}
            </div>
            <div className="headImg moduleImg">
              {entities?.users[1].img ? (
                <UserImage imgKey={entities?.users[1].img}/>
              ) : (
                <img
                  src={getInitialsInName(entities?.users[1].name ?? "N A", 24)}
                  alt={entities?.users[1].name}
                />
              )}
            </div>
          </>
        ) : entities?.userCounts?.imgCount > 2 ? (
          <>
            <div className="headImg moduleImg">
              {entities?.users[0].img ? (
                <UserImage imgKey={entities?.users[0].img}/>
              ) : (
                <img
                  src={getInitialsInName(entities?.users[0].name ?? "N A", 24)}
                  alt={entities?.users[0].name}
                />
              )}
            </div>
            <div className="headImg moduleImg">
              {entities?.users[1].img ? (
                <UserImage imgKey={entities?.users[1].img}/>
              ) : (
                <img
                  src={getInitialsInName(entities?.users[1].name ?? "N A", 24)}
                  alt={entities?.users[1].name}
                />
              )}
            </div>
          </>
        ) : null}

        {entities?.userCounts?.imgCount > 2 ?
          <div className="headImg moduleImg imgCount">
            <div className="text-xs total">
              {entities?.userCounts?.imgCount > 2
                ? `${"+"} ${entities?.userCounts?.imgCount - 2}`
                : +entities?.userCounts?.imgCount}
            </div>
          </div> : entities?.userCounts?.imgCount === 0 ? <div className="headImg moduleImg imgCount">
            <div className="text-xs total">
              0
            </div>
          </div> : ''}
      </div>
    </div>
  );
};

export default OwnerImgCount;
