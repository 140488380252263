import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Container,
  Button,
  Accordion,
  Collapse,
  Modal,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import UpgradeModal from "./UpgradeModal";
import DowngradeModal from "./DowngradeModal";
import Downgrade2Modal from "./Downgrade2Modal";
import SamePlanModal from "./SamePlanModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getPlansAsync,
  getSubscriptionAsync,
  updateSubscriptionAsync,
  downgradeSubscriptionAsync,
} from "../../../reducers/subscription-reducers";
import PaymentCardModal from "./PaymentCardModal";
import { convertToGB } from "../../../common";
import ToasterAlertError from "../../../helper/ToasterAlertError";

// import { Elements } from '@stripe/react-stripe-js';
// import { loadStripe } from '@stripe/stripe-js';

const ChangePlan = () => {
  const history = useHistory();
  const { plansData, isUpdateLoading, isDowngradeLoading } = useSelector(
    (state) => state.subscription
  );
  const subscriptionData = useSelector((state) => state.subscription);
  // const [viewDetail, setViewDetail] = useState(false);
  const [viewPlan, setViewPlan] = useState("month");
  const [open, setOpen] = useState(true);
  const [discountedPrice, setDiscountedPrice] = useState("");
  const [upgrade, setUpgrade] = useState(false);
  const [planTitle, setPlanTitle] = useState("Add Subscription");
  const [downgrade, setDowngrade] = useState(false);
  const [downgrade2, setDowngrade2] = useState(false);
  const [samePlan, setSamePlan] = useState(false);
  const [subscribePlanId, setSubscribePlanId] = useState(-1);
  const [discountPlan, setDiscountPlan] = useState(false);
  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [paymentCardModal, setPaymentCardModal] = useState(false);
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [isUpgrade, setIsUpgrade] = useState(false);
  const [isPlanChange, setIsPlanChange] = useState(false);
  const [isPlanDowngrade, setIsPlanDowngrade] = useState(false);
  const [subscriptionId, setSubscriptionId] = useState("");
  const [isEnterprise, setIsEnterprise] = useState(false);

  const [isMakePlan, setIsMakePlan] = useState(false);
  const [donwgradePlanReason, setDonwgradePlanReason] = useState({
    planReason: "",
    other: "",
  });
  const dispatch = useDispatch();

  // const stripePromise = loadStripe('pk_test_51OFF9aSDUG9764DXrfQkoGAOT8yaaLmaI6rtY6drAkc4DU90dIlW9tU78KPqCQdTuYYu2rpZOKCnGE27lI8zJQF00070lgq0EF');

  const [reversedPlansData, setReversedPlansData] = useState([]);
  const [enterprisePlansData, setEnterprisePlansData] = useState([]);
  const [startUpPlans, setStartUpPlans] = useState([]);
  const [businessPlans, setBusinessPlans] = useState([]);
  useEffect(() => {
    // console.log(plansData,'plansDataplansData');
    if (plansData && plansData.length > 0) {
      const filteredPlansData = plansData.filter(
        (plan) =>
          plan.planType === (viewPlan === "year" ? "Yearly" : "Monthly") &&
          plan.statementDescriptor === "base_plan" && 
          plan.planName !== "Enterprise"
      );

      const filteredEnterprisePlans = plansData.filter(
        (plan) =>
          plan.planType === (viewPlan === "year" ? "Yearly" : "Monthly") &&
          plan.statementDescriptor === "base_plan" && 
          plan.planName === "Enterprise"
      );
      setEnterprisePlansData(filteredEnterprisePlans);
      // console.log(filteredPlansData,'filteredPlansData');
      // const reversedData = [...filteredPlansData].reverse();
      const reversedData = [...filteredPlansData];
      setReversedPlansData(reversedData);

      const startUpDatas = reversedData.filter(
        (plan) => plan.planName === "Startup"
      );
      setStartUpPlans(startUpDatas && startUpDatas[0]);
      const businessDatas = reversedData.filter(
        (plan) => plan.planName === "Business"
      );
      setBusinessPlans(businessDatas && businessDatas[0]);
    }
  }, [plansData, viewPlan]);

  console.log(startUpPlans, "startUpPlans");
  console.log(businessPlans, "businessPlans");
  // if (plansData && plansData.length > 0) {
  //   reversedPlansData = [...plansData].reverse();
  // }

  // const handlePlan = (val) => {
  //   // console.log(val, "val");
  //   if (val == true) {
  //     setViewPlan("year");
  //   } else {
  //     setViewPlan("month");
  //   }
  // };
  useEffect(() => {
    dispatch(getPlansAsync());
  }, []);

  useEffect(() => {
    dispatch(getSubscriptionAsync());
  }, [paymentCardModal, discountPlan]);

  useEffect(() => {
    if (isPlanChange) {
      if (!isUpdateLoading) {
        // selectedPlanData
        if (isUpgrade) {
          history.push({
            pathname: "/accountsetting/billingsubscription/PlanRetained",
            state: { selectedPlanData: selectedPlanData },
          });
        } else {
          history.push({
            pathname: "/accountsetting/billingsubscription/upgrade",
            state: { selectedPlanData: selectedPlanData },
          });
        }
      }
    }
    if (isPlanDowngrade) {
      if (!isDowngradeLoading) {
        history.push({
          pathname: "/accountsetting/billingsubscription/downgrade",
          state: { selectedPlanData: selectedPlanData },
        });
      }
    }
  }, [
    isPlanChange,
    isUpdateLoading,
    isUpgrade,
    history,
    isPlanDowngrade,
    isDowngradeLoading,
  ]);

  useEffect(() => {
    if (isMakePlan) {
      // navigate.push("/accountsetting/billingsubscription/makeplansuccess");
      history.push({
        pathname: "/accountsetting/billingsubscription/makeplansuccess",
        state: { selectedPlanData: selectedPlanData },
      });
    }
  }, [isMakePlan]);

  // console.log(subscriptionData, "subscriptionData");
  useEffect(() => {
    setSubscribePlanId(-1);
    const monthlyTotal = plansData?.reduce((total, planData) => {
      const monthlyPrice =
        planData?.planType === "Monthly" ? planData?.price / 100 : 0;
      return total + (monthlyPrice || 0);
    }, 0);

    const yearlyTotal = plansData?.reduce((total, planData) => {
      const yearlyPrice =
        planData?.planType === "Yearly" ? planData?.price / 100 : 0;
      return total + (yearlyPrice || 0);
    }, 0);

    setDiscountedPrice(
      (((monthlyTotal * 12 - yearlyTotal) / (monthlyTotal * 12)) * 100).toFixed(
        2
      )
    );

    if (
      subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0
    ) {
      // const reversedPlans = [...plansData].reverse();
      const { items: subscriptionItems } = subscriptionData?.subscriptionData?.subscriptions[0] ?? {};
      // const subscriptionItems = subscriptionData?.subscriptionData?.subscriptions[0]?.items;
      // console.log(plansData,'plansData');
      // console.log(reversedPlansData,'reversedPlans');
      // console.log(subscriptionItems,'subscriptionItems');
      for (let index = 0; index < reversedPlansData?.length; index++) {
        const plan = reversedPlansData[index];
        if (subscriptionItems[0].price?.id === plan?.priceId) {
          setSubscribePlanId(index);
          break;
        }
      }

      
      for (let index = 0; index < enterprisePlansData?.length; index++) {
        const entPlan = enterprisePlansData[index];
        if (subscriptionItems[0].price?.id === entPlan?.priceId) {
          setIsEnterprise(true);
          break;
        }
      }
    }
  }, [
    plansData,
    discountPlan,
    subscriptionData,
    paymentCardModal,
    reversedPlansData,
  ]);

  const onhandleGetStarted = (planData) => {
    let selPlanData = [];
    if (viewPlan === "year") {
      selPlanData = planData?.planType === "Yearly" ? planData : [];
    } else {
      selPlanData = planData?.planType === "Monthly" ? planData : [];
    }
    if (selPlanData) {
      setSelectedPlanId(selPlanData?.priceId);
      setSelectedPlanData(selPlanData);
    }
    setPaymentCardModal(true);
  };

  const onhandleDowngrade = (planData) => {
    let selPlanData = [];
    if (viewPlan === "year") {
      selPlanData = planData?.planType === "Yearly" ? planData : [];
    } else {
      selPlanData = planData?.planType === "Monthly" ? planData : [];
    }
    if (selPlanData) {
      setSelectedPlanId(selPlanData?.priceId);
      setSelectedPlanData(selPlanData);
    }
    setDowngrade(true);
  };

  const onhandleUpgrade = (planData) => {
    let selPlanData = [];
    if (viewPlan === "year") {
      selPlanData = planData?.planType === "Yearly" ? planData : [];
    } else {
      selPlanData = planData?.planType === "Monthly" ? planData : [];
    }
    if (selPlanData) {
      setSelectedPlanId(selPlanData?.priceId);
      setSelectedItemId(selPlanData?.planId);
      setSelectedPlanData(selPlanData);
    }
    setUpgrade(true);
  };

  const handleDowngradePlan = () => {
    setDowngrade(false);
    setDowngrade2(true);
    //setLessFeaturesPlan(true);
  };
  const handleUpgradePlan = () => {
    planChange("Upgrade");
    setUpgrade(false);
    setIsUpgrade(false);
  };

  const planChange = (reason) => {
    const request = {
      subscriptionId: subscriptionData?.subscriptionData?.subscriptions?.[0]?.id ?? "",
      newPriceId: selectedPlanId,
      // reasonForUpdate: reason,
      quantity: 1,
      itemId: selectedItemId,
    };
    const res = dispatch(updateSubscriptionAsync(request));
    setIsPlanChange(true);
  };

  const handleComfirmDowngrade = () => {
    const reason =
      donwgradePlanReason.planReason === "Others"
        ? donwgradePlanReason.other
        : donwgradePlanReason.planReason;
    planChangeDowngrade(reason);
    // setDowngrade2(false);
    // setSamePlan(false);
  };

  const planChangeDowngrade = (reason) => {
    // debugger;
    // console.log(selectedPlanData,'selectedPlanDataselectedPlanData');
    // console.log(subscriptionData,'subscriptionData');
    const request = {
      subscriptionId: subscriptionData?.subscriptionData?.subscriptions?.length > 0
      ? subscriptionData?.subscriptionData?.subscriptions[0].id
        : "",
      newPriceId: selectedPlanId,
      // reasonForUpdate: reason,
      quantity: 1,
    };
    // const res = dispatch(downgradeSubscriptionAsync(request));
    dispatch(downgradeSubscriptionAsync(request)).then((res) => {
      if (res?.payload === 'downgrade is already scheduled') {
        ToasterAlertError('Downgrade is already scheduled');
      } else {
        setDowngrade2(false);
        setIsPlanDowngrade(true);
      }
    });
    // console.log(res, "update");
    // setIsPlanDowngrade(true);
  };

  const handleLessFeaturesPlan = () => {
    setDowngrade2(false);
    setSamePlan(true);
  };
  console.log(plansData, "plansData");
  console.log(reversedPlansData, "reversedPlansData");
  console.log(enterprisePlansData, "enterprisePlansData");

  return (
    <>
      <div className="pages">
        <header className="pageHeader">
          <Container fluid>
            <Row className="align-items-center">
              <Col>
                <div className="d-flex align-items-center gap-2">
                  <Link to="/accountsetting/billingsubscription">
                    <span className="arrow left"></span>
                  </Link>
                  <h3>Change Plan</h3>
                </div>
              </Col>
              <Col className="text-end"></Col>
            </Row>
          </Container>
        </header>
        <div className="pageOuter">
          <Container fluid>
            <div className="page changePlan d-flex flex-column h-100">
              <div className="px-5 pt-5 mb-32">
                <div className="row">
                  <div className="col-lg-4">
                    <div className="w-75">
                      <h1 className="mb-3 mt-4">
                        Discover the plan that best suits your needs.
                      </h1>

                      <h6 className="fw-normal mb-5">
                        Based on your inputs, we have a plan that will perfectly
                        fit your business requirements.
                      </h6>
                    </div>
                    {/* <div className="d-flex gap-1 mb-3">
                      <div className="form-check form-switch custom-switch ps-0 pe-1">
                        <label
                          className={
                            viewPlan
                              ? "form-check-label check-lab1 text-gray-200 h5 fw-normal mb-0 text-nowrap "
                              : " active form-check-label check-lab1 text-gray-200 h5 fw-normal mb-0 text-nowrap "
                          }
                        >
                          Pay Monthly
                        </label>
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id="flexSwitchCheckChecked"
                          defaultChecked={true}
                          onChange={(event) => handlePlan(event.target.checked)}
                        />

                        <label
                          className={
                            viewPlan
                              ? "active form-check-label check-lab1  fw-normal h5 text-nowrap "
                              : "form-check-label check-lab1  fw-normal h5 text-nowrap "
                          }
                        >
                          Pay yearly
                        </label>
                      </div>
                      <div className="d-flex align-items-start">
                        <span className="disc text-sm">
                          {discountedPrice}%
                        </span>
                      </div>
                    </div>
                    <div className="d-flex ">
                      <div className="borderAll2 bg-gray-600 d-flex align-items-center gap-2 rounded-1 px-2">
                        <div>
                          <svg
                            width="16"
                            height="16"
                            viewBox="0 0 16 16"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M2.65926 9.77395L1.64594 8.7606C1.2326 8.34727 1.2326 7.66726 1.64594 7.25393L2.65926 6.24058C2.8326 6.06725 2.9726 5.72725 2.9726 5.48725V4.0539C2.9726 3.46723 3.4526 2.98725 4.03927 2.98725H5.4726C5.7126 2.98725 6.0526 2.84727 6.22593 2.67393L7.23926 1.66059C7.65259 1.24725 8.3326 1.24725 8.74593 1.66059L9.75928 2.67393C9.93261 2.84727 10.2726 2.98725 10.5126 2.98725H11.9459C12.5326 2.98725 13.0126 3.46723 13.0126 4.0539V5.48725C13.0126 5.72725 13.1526 6.06725 13.3259 6.24058L14.3393 7.25393C14.7526 7.66726 14.7526 8.34727 14.3393 8.7606L13.3259 9.77395C13.1526 9.94728 13.0126 10.2873 13.0126 10.5273V11.9606C13.0126 12.5472 12.5326 13.0273 11.9459 13.0273H10.5126C10.2726 13.0273 9.93261 13.1673 9.75928 13.3406L8.74593 14.3539C8.3326 14.7673 7.65259 14.7673 7.23926 14.3539L6.22593 13.3406C6.0526 13.1673 5.7126 13.0273 5.4726 13.0273H4.03927C3.4526 13.0273 2.9726 12.5472 2.9726 11.9606V10.5273C2.9726 10.2806 2.8326 9.94061 2.65926 9.77395Z"
                              stroke="#2EB67D"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6 10L10 6"
                              stroke="#2EB67D"
                              stroke-width="1.5"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M9.66292 9.66634H9.66891"
                              stroke="#2EB67D"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                            <path
                              d="M6.32967 6.33333H6.33566"
                              stroke="#2EB67D"
                              stroke-width="2"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            />
                          </svg>
                        </div>
                        <p className="text-md mb-0">
                       Enjoy greater savings with our yearly plans.
                        </p>
                      </div>
                    </div> */}
                  </div>

                  <div className="col-lg-8">
                    <div className="planDashboard row">
                      {reversedPlansData.map((planData, index) => {
                        // console.log(index, "index");
                        // console.log(subscribePlanId, "subscribePlanId");
                        return (
                          <>
                            <div
                              className={`col-lg-4 card ${
                                index === subscribePlanId ? "active" : ""
                              }`}
                            >
                              {/* <div className="col-lg-4 card"> */}

                              <button
                                type="button"
                                className="btn-primary btn-sm text-md font-medium posBtn text-base-300 py-1"
                              >
                                Recommended for you
                                <svg
                                  className="ms-2"
                                  width="19"
                                  height="16"
                                  viewBox="0 0 19 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    d="M9.43167 14.6668C13.4503 14.6668 16.7383 11.6668 16.7383 8.00016C16.7383 4.3335 13.4503 1.3335 9.43167 1.3335C5.413 1.3335 2.125 4.3335 2.125 8.00016C2.125 11.6668 5.413 14.6668 9.43167 14.6668Z"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.42969 5.3335V8.66683"
                                    stroke="white"
                                    stroke-width="1.5"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                  <path
                                    d="M9.42578 10.6665H9.43234"
                                    stroke="white"
                                    stroke-width="2"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                  />
                                </svg>
                              </button>

                              <div className="py-4 px-2">
                                <p
                                  className={`text-xl font-extraBold mb-12 ${
                                    index === 0 && `active`
                                  }`}
                                >
                                  {planData.planName}
                                </p>

                                {planData.planName === "Business" ? (
                                  <p className="text-sm text-gray-300 mb-12">
                                    Live 60 minute training on using Additional
                                    Training and Support $500/mo as an addon
                                  </p>
                                ) : (
                                  <p className="text-sm text-gray-300 mb-12">
                                    For more users charges will be added based
                                    on per active users beyond minimum count.
                                  </p>
                                )}
                                <div className="price-info">
                                  <p className="text-xl font-bold mb-1">
                                    {/* {viewPlan ? "$100" : "$60"} */}
                                    {planData?.price ? (
                                      `$${planData?.price}`
                                    ) : (
                                      <> Request pricing </>
                                    )}
                                  </p>
                                </div>
                                <p className="text-sm text-gray-300 mb-0 w-75">
                                  {planData.planName === "Business" ||
                                  planData.planName === "Startup"
                                    ? `Per member/${viewPlan} billed annually`
                                    : "Custom pricing for your company"}
                                </p>
                                <div className="my-4 py-2">
                                  {subscribePlanId === -1 ? (
                                    <button
                                      type="submit"
                                      className="btn-secondary btn-sm"
                                      onClick={
                                        () => onhandleGetStarted(planData)
                                        // onhandleDowngrade(planData)
                                      }
                                      // disabled={planData.planName === "Enterprise" ? true : false}
                                      disabled={isEnterprise}
                                    >
                                      {planData.planName === "Business"
                                        ? " Start 15-day FREE Trial"
                                        : "Start 15-day FREE Trial"}
                                      {/* Get started */}
                                    </button>
                                  ) : index === subscribePlanId ? (
                                    <button
                                      type="submit"
                                      className="btn-secondary btn-sm"
                                      disabled={
                                        index === subscribePlanId ? (
                                          true
                                        ) : (
                                          isEnterprise === true ? true : false
                                        )
                                      }
                                      onClick={() =>
                                        onhandleGetStarted(planData)
                                      }
                                    >
                                      {planData.planName === "Business"
                                        ? " Start 15-day FREE Trial"
                                        : "Start 15-day FREE Trial"}
                                    </button>
                                  ) : index > subscribePlanId ? (
                                    <button
                                      type="submit"
                                      className="btn-secondary btn-sm"
                                      onClick={() => onhandleUpgrade(planData)}
                                      // disabled={planData.planName === "Enterprise" ? true : false}
                                      disabled={isEnterprise}
                                    >
                                      Upgrade
                                    </button>
                                  ) : (
                                    <button
                                      type="submit"
                                      className="btn-secondary btn-sm"
                                      onClick={() =>
                                        onhandleDowngrade(planData)
                                      }
                                      // disabled={planData.planName === "Enterprise" ? true : false}
                                      disabled={isEnterprise}
                                    >
                                      Downgrade
                                    </button>
                                  )}
                                </div>
                                {index === 0 && (
                                  <div className="w-75">
                                    <p className="text-sm mb-20 textDecoration">
                                      Up to {planData?.metaData?.max_users}{" "}
                                      Active Users
                                    </p>
                                    {planData?.metaData?.custom_branding ===
                                      "Yes" && (
                                      <p className="text-sm mb-20 textDecoration">
                                        Custom Domain &amp; Branding- Basic
                                      </p>
                                    )}
                                    <p className="text-sm mb-20 textDecoration">
                                      {planData?.metaData?.storage}MB/User
                                      Content Storage on yearly contracts
                                    </p>
                                    <p className="text-sm mb-20">
                                      Create upto {planData?.metaData?.sequence}{" "}
                                      sequence
                                    </p>
                                    <p className="text-sm mb-20">
                                      Dedicated Customer Success Specialist
                                    </p>
                                  </div>
                                )}

                                {index === 1 && (
                                  <div className="w-75">
                                    <p className="text-sm mb-20 textDecoration">
                                      Up to {planData?.metaData?.max_users}{" "}
                                      Active Users
                                    </p>
                                    {planData?.metaData?.custom_branding ===
                                      "Yes" && (
                                      <p className="text-sm mb-20 textDecoration">
                                        Custom Domain &amp; Branding-Pro
                                      </p>
                                    )}
                                    <p className="text-sm mb-20 ">
                                      8 business hour turnaround support system
                                      available via email, chat.
                                    </p>
                                    <p className="text-sm mb-20">
                                      Create upto {planData?.metaData?.process}{" "}
                                      processes
                                    </p>
                                    <p className="text-sm mb-20">
                                      Create{" "}
                                      {planData?.metaData?.modules === "-1"
                                        ? "unlimited"
                                        : planData?.metaData?.modules}{" "}
                                      modules
                                    </p>
                                  </div>
                                )}

                                {/* {index === 2 && (
                                  <div className="w-75">
                                    <p className="text-sm mb-20 textDecoration">
                                      Single Sign on support
                                    </p>
                                    <p className="text-sm mb-20 textDecoration">
                                      Create Content features is available
                                    </p>
                                    <p className="text-sm mb-20 ">
                                      Unlimited Content Storage on yearly
                                      contracts
                                    </p>
                                    <p className="text-sm mb-20">
                                      Multilingual features available
                                    </p>
                                    <p className="text-sm mb-20">
                                      Unlimited processes
                                    </p>
                                  </div>
                                )} */}
                              </div>
                            </div>
                            {/* </div> */}
                          </>
                        );
                      })}

                      {reversedPlansData?.length > 0 && (
                        <>
                          <div className={`col-lg-4 card`}>
                            <div className="py-4 px-2">
                              <p className={`text-xl font-extraBold mb-12`}>
                                Enterprise
                              </p>

                              <p className="text-sm text-gray-300 mb-12">
                                30 day customer success training dedicated CSM,
                                additional training and support $500/mo as an
                                addon
                              </p>
                              <div className="price-info">
                                <p className="text-xl font-bold mb-1">
                                  Request pricing
                                </p>
                              </div>
                              <p className="text-sm text-gray-300 mb-0 w-75">
                                Custom pricing for your company
                              </p>
                              <div className="my-4 py-2">
                                <button
                                  type="submit"
                                  className="btn-secondary btn-sm"
                                  // onClick={() =>
                                  //   onhandleDowngrade(planData)
                                  // }
                                  disabled={true}
                                >
                                  Book a demo
                                </button>
                              </div>

                              <div className="w-75">
                                <p className="text-sm mb-20 textDecoration">
                                  Single Sign on support
                                </p>
                                <p className="text-sm mb-20 textDecoration">
                                  Create Content features is available
                                </p>
                                <p className="text-sm mb-20 ">
                                  Unlimited Content Storage on yearly contracts
                                </p>
                                <p className="text-sm mb-20">
                                  Multilingual features available
                                </p>
                                <p className="text-sm mb-20">
                                  Unlimited processes
                                </p>
                              </div>
                            </div>
                          </div>
                          {/* </div> */}
                        </>
                      )}

                      <div
                        className="offset-lg-1 col-lg-8 planDashboard"
                        style={{ display: "none" }}
                      >
                        <div className="col-lg-4 card">
                          <button
                            type="button"
                            className="btn-primary btn-sm text-md font-medium posBtn text-base-300 py-1"
                          >
                            Recommended for you
                            <svg
                              className="ms-2"
                              width="19"
                              height="16"
                              viewBox="0 0 19 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.43167 14.6668C13.4503 14.6668 16.7383 11.6668 16.7383 8.00016C16.7383 4.3335 13.4503 1.3335 9.43167 1.3335C5.413 1.3335 2.125 4.3335 2.125 8.00016C2.125 11.6668 5.413 14.6668 9.43167 14.6668Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.42969 5.3335V8.66683"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.42578 10.6665H9.43234"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <div className="py-4 px-2">
                            <p className="text-xl font-extraBold mb-12">
                              Startup
                            </p>

                            <p className="text-sm text-gray-300 mb-12">
                              For more users charges will be added based on per
                              active users beyond minimum count.
                            </p>
                            <div className="price-info">
                              <p className="text-xl font-bold mb-1">
                                {viewPlan ? "$100" : "$60"}
                              </p>
                            </div>
                            <p className="text-sm text-gray-300 mb-0 w-75">
                              Per member/month billed annually
                            </p>
                            <div className="my-4 py-2">
                              <button
                                type="submit"
                                className="btn-secondary btn-sm"
                                onClick={() => setUpgrade(true)}
                              >
                                Start 15-day FREE Trial
                              </button>
                            </div>
                            <div className="w-75">
                              <p className="text-sm mb-20 textDecoration">
                                Up to 20 Active Users
                              </p>
                              <p className="text-sm mb-20 textDecoration">
                                Custom Domain &amp; Branding- Basic
                              </p>
                              <p className="text-sm mb-20 textDecoration">
                                100MB/User Content Storage on yearly contracts
                              </p>
                              <p className="text-sm mb-20">
                                Create upto 3 sequence
                              </p>
                              <p className="text-sm mb-20">
                                Dedicated Customer Success Specialist
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-4 card active">
                          <button
                            type="button"
                            className="btn-primary btn-sm text-md font-medium posBtn text-base-300 py-1"
                          >
                            Recommended for you
                            <svg
                              className="ms-2"
                              width="19"
                              height="16"
                              viewBox="0 0 19 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.43167 14.6668C13.4503 14.6668 16.7383 11.6668 16.7383 8.00016C16.7383 4.3335 13.4503 1.3335 9.43167 1.3335C5.413 1.3335 2.125 4.3335 2.125 8.00016C2.125 11.6668 5.413 14.6668 9.43167 14.6668Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.42969 5.3335V8.66683"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.42578 10.6665H9.43234"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <div className="py-4 px-2">
                            <p className="text-xl font-extraBold mb-12">
                              Business
                            </p>

                            <p className="text-sm text-gray-300 mb-12">
                              Live 60 minute training on using Additional
                              Training and Support $500/mo as an addon
                            </p>
                            <div className="price-info">
                              <p className="text-xl font-bold mb-1">
                                {viewPlan ? "$300" : "$200"}
                              </p>
                            </div>

                            <p className="text-sm text-gray-300 mb-0 w-75">
                              Per member/month billed annually
                            </p>
                            <div className="my-4 py-2">
                              <button
                                type="submit"
                                className="btn-secondary btn-sm"
                                disabled
                              >
                                Start 15-day FREE Trial
                              </button>
                            </div>
                            <div className="w-75">
                              <p className="text-sm mb-20 textDecoration">
                                Up to 100 Active Users
                              </p>
                              <p className="text-sm mb-20 textDecoration">
                                Custom Domain &amp; Branding-Pro
                              </p>
                              <p className="text-sm mb-20 ">
                                8 business hour turnaround support system
                                available via email, chat.
                              </p>
                              <p className="text-sm mb-20">
                                Create upto 3 processes
                              </p>
                              <p className="text-sm mb-20">
                                Create unlimited modules
                              </p>
                            </div>
                          </div>
                        </div>

                        <div className="col-lg-4 card">
                          <button
                            type="button"
                            className="btn-primary btn-sm text-md font-medium posBtn text-base-300 py-1"
                          >
                            Recommended for you
                            <svg
                              className="ms-2"
                              width="19"
                              height="16"
                              viewBox="0 0 19 16"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M9.43167 14.6668C13.4503 14.6668 16.7383 11.6668 16.7383 8.00016C16.7383 4.3335 13.4503 1.3335 9.43167 1.3335C5.413 1.3335 2.125 4.3335 2.125 8.00016C2.125 11.6668 5.413 14.6668 9.43167 14.6668Z"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.42969 5.3335V8.66683"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                              <path
                                d="M9.42578 10.6665H9.43234"
                                stroke="white"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </button>
                          <div className=" py-4 px-2">
                            <p className="text-xl font-extraBold mb-12">
                              Enterprise
                            </p>

                            <p className="text-sm text-gray-300 mb-12">
                              30 day customer success training dedicated CSM,
                              additional training and support $500/mo as an
                              addon
                            </p>

                            <p className="font-bold mb-12">Request pricing</p>

                            <p className="text-sm text-gray-300 mb-0 w-75">
                              Custom pricing for your company{" "}
                            </p>
                            <div className="my-4 py-2">
                              <button
                                type="submit"
                                className="btn-secondary btn-sm"
                                onClick={() => setDowngrade(true)}
                              >
                                Book a demo
                              </button>
                            </div>
                            <div className="w-75">
                              <p className="text-sm mb-20 textDecoration">
                                Single Sign on support
                              </p>
                              <p className="text-sm mb-20 textDecoration">
                                Create Content features is available
                              </p>
                              <p className="text-sm mb-20 ">
                                Unlimited Content Storage on yearly contracts
                              </p>
                              <p className="text-sm mb-20">
                                Multilingual features available
                              </p>
                              <p className="text-sm mb-20">
                                Unlimited processes
                              </p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="py-5 text-center bg-base-100">
                <Button
                  variant="white"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                  data-target="#example-collapse-text"
                >
                  {open
                    ? "Hide Detailed Comparison"
                    : "View Detailed Comparison"}
                </Button>
              </div>

              <Collapse in={open}>
                <div id="example-collapse-text">
                  <div>
                    <div className="px-5 bg-base-100 ">
                      <table className="table planTable">
                        <thead>
                          <tr>
                            <th scope="col">
                              <p> Key Features</p>
                            </th>
                            <th scope="col">
                              <p>Startup</p>
                            </th>
                            <th scope="col">
                              <p>Business</p>

                              <button
                                type="button"
                                className="btn-primary btn-sm text-md font-medium posBtn rounded-5 py-1"
                              >
                                Recommended
                              </button>
                            </th>
                            <th scope="col">
                              <p>Enterprise</p>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Maximum number of active users
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Payment timeline options
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Custom Branding
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Storage
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                Training and support
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Pro trial Access
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Support
                              </p>
                            </td>
                            <td>
                              <div>
                                <p className="text-sm">
                                  {" "}
                                  {startUpPlans?.metaData?.max_users}
                                </p>

                                <p className="text-sm">Monthly</p>
                                <p className="text-sm">
                                  {" "}
                                  {startUpPlans?.metaData?.custom_branding}
                                </p>

                                <p className="text-sm">
                                  {convertToGB(startUpPlans?.metaData?.storage)}
                                  /User
                                </p>
                                <p className="text-sm">
                                  {" "}
                                  Videos Community Knowledge Base
                                </p>

                                <p className="text-sm">
                                  Email 24 hour turnaround
                                </p>
                                <p className="text-sm">15 days</p>
                              </div>
                            </td>
                            <td>
                              <div>
                                <p className="text-sm">
                                  {" "}
                                  {businessPlans?.metaData?.max_users}
                                </p>

                                <p className="text-sm">Monthly</p>

                                <p className="text-sm">
                                  {businessPlans?.metaData?.custom_branding ===
                                  "Yes"
                                    ? "Yes-Pro"
                                    : "No"}
                                </p>
                                <p className="text-sm">
                                  {" "}
                                  {convertToGB(
                                    businessPlans?.metaData?.storage
                                  )}
                                  /User
                                </p>

                                <p className="text-sm">
                                  Live 60 minute training on using Additional
                                  Training and Support $500/mo as an addon
                                </p>
                                <p className="text-sm">
                                  {" "}
                                  Email, Chat 8 business hour turnaround
                                </p>

                                <p className="text-sm"> 15 days</p>
                              </div>
                            </td>
                            <td>
                              <div>
                                <p className="text-sm"> Unlimited</p>

                                <p className="text-sm">Monthly customised</p>

                                <p className="text-sm">Yes-Complete</p>
                                <p className="text-sm">Unlimited</p>

                                <p className="text-sm">
                                  30 day customer success training dedicated
                                  CSM, additional training and support $500/mo
                                  as an addon
                                </p>
                                <p className="text-sm">
                                  {" "}
                                  Customised email chat phone
                                </p>

                                <p className="text-sm"> Specific for account</p>
                              </div>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="font-bold head">
                                Platform Features
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Process
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Sequence
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                Modules
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Feedback from employees
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                Pay by Invoice
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Export to CSV
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Create Content
                              </p>
                            </td>

                            <td>
                              <p className="font-bold">&nbsp;</p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans &&
                                  startUpPlans?.metaData?.process === "0"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.process === "0" ? (
                                  <>&nbsp;</>
                                ) : (
                                  startUpPlans?.metaData?.process
                                )}
                              </p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans?.metaData?.sequence === "0"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.sequence === "0" ? (
                                  <>&nbsp;</>
                                ) : (
                                  startUpPlans?.metaData?.sequence
                                )}
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans?.metaData?.modules === "0"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.modules === "0" ? (
                                  <>&nbsp;</>
                                ) : (
                                  startUpPlans?.metaData?.modules
                                )}
                              </p>

                              <p className="text-sm text-gray-200">
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans?.metaData?.export_csv === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.export_csv === "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                            </td>

                            <td>
                              <p className="font-bold">&nbsp;</p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData?.process === "0"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.process === "0" ? (
                                  <>&nbsp;</>
                                ) : (
                                  businessPlans?.metaData?.process
                                )}
                              </p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData?.sequence === "0"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.sequence === "0" ? (
                                  <>&nbsp;</>
                                ) : (
                                  businessPlans?.metaData?.sequence
                                )}
                              </p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData?.modules === "0"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.modules === "0" ? (
                                  <>&nbsp;</>
                                ) : businessPlans?.metaData?.modules ===
                                  "-1" ? (
                                  "Unlimited"
                                ) : (
                                  ""
                                )}
                              </p>

                              <p className="text-sm text-gray-200">
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans?.metaData?.export_csv === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.export_csv ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                            </td>

                            <td>
                              <p className="font-bold">&nbsp;</p>

                              <p className="text-sm text-gray-200">Unlimited</p>

                              <p className="text-sm text-gray-200">Unlimited</p>

                              <p className="text-sm text-gray-200">Unlimited</p>

                              <p className="text-sm text-gray-200">
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>

                              <p className="text-sm text-gray-200">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                            </td>
                          </tr>
                          <tr>
                            <td>
                              <p className="font-bold head">
                                Fundamental Features
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Run Entire Process
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                GDPR Compliant
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                Bulk import employees
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                Bulk assign content
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                Bulk update status
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                SSO-Okta
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                White labelling
                              </p>

                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Multilingual
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Role based access
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                SSO-Google
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                SSO-Microsoft
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Custom Domain
                              </p>
                              <p className="text-sm text-gray-200 textDecoration">
                                {" "}
                                Uptime and support request response SLA
                              </p>
                            </td>

                            <td>
                              <p className="font-bold">&nbsp;</p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans &&
                                  startUpPlans?.metaData?.run_entire_process ===
                                    "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.run_entire_process ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>

                              <p className="text-sm text-gray-200">
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans &&
                                  startUpPlans?.metaData
                                    ?.bulk_import_employees === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData
                                  ?.bulk_import_employees === "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans &&
                                  startUpPlans?.metaData
                                    ?.bulk_assign_content === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.bulk_assign_content ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans &&
                                  startUpPlans?.metaData?.bulk_update_status ===
                                    "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.bulk_update_status ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>

                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>

                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  startUpPlans &&
                                  startUpPlans?.metaData?.custom_branding ===
                                    "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {startUpPlans?.metaData?.custom_branding ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                            </td>

                            <td>
                              <p className="font-bold">&nbsp;</p>

                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData
                                    ?.run_entire_process === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.run_entire_process ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>

                              <p className="text-sm text-gray-200">
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData
                                    ?.bulk_import_employees === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.bulk_import_employees ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData
                                    ?.bulk_assign_content === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.bulk_assign_content ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData
                                    ?.bulk_update_status === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.bulk_update_status ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>

                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>

                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                              <p
                                className={`text-sm text-gray-200 ${
                                  businessPlans &&
                                  businessPlans?.metaData
                                    ?.custom_branding === "No"
                                    ? "dash"
                                    : ""
                                }`}
                              >
                                {businessPlans?.metaData?.custom_branding ===
                                "No" ? (
                                  <>&nbsp;</>
                                ) : (
                                  <svg
                                    width="18"
                                    height="14"
                                    viewBox="0 0 18 14"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <path
                                      fill-rule="evenodd"
                                      clip-rule="evenodd"
                                      d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                      fill="#F15845"
                                    />
                                  </svg>
                                )}
                              </p>
                              <p className="text-sm text-gray-200 dash">
                                &nbsp;
                              </p>
                            </td>

                            <td>
                              <p className="font-bold">&nbsp;</p>

                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>
                              <p className="text-sm text-gray-200 ">
                                {" "}
                                <svg
                                  width="18"
                                  height="14"
                                  viewBox="0 0 18 14"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path
                                    fill-rule="evenodd"
                                    clip-rule="evenodd"
                                    d="M17.7463 0.482931C18.0734 0.804403 18.0859 1.33848 17.7741 1.67583L6.85862 13.4883C6.70412 13.6555 6.48991 13.7501 6.26595 13.75C6.04199 13.7499 5.82786 13.6551 5.6735 13.4878L0.225375 7.58154C-0.0860663 7.24391 -0.0731303 6.70984 0.254268 6.38867C0.581667 6.06749 1.09955 6.08083 1.41099 6.41846L6.26595 10L16.5895 0.511676C16.9012 0.174329 17.4191 0.161459 17.7463 0.482931Z"
                                    fill="#F15845"
                                  />
                                </svg>
                              </p>

                              <p className="text-sm text-gray-200">
                                Cheque/ Wire
                              </p>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>

                  <div className="px-5 bg-base-100 py-60">
                    <div className="row">
                      <div className="col-lg-3">
                        <div className="w-93">
                          <h1 className="mb-3 mt-4">
                            Frequently
                            <br /> asked questions
                          </h1>

                          <h6 className="fw-normal mb-5">
                            Everything you need to know about the product and
                            billing. Get your answers here.
                          </h6>
                        </div>
                      </div>
                      <div className="col-lg-9">
                        <Accordion
                          defaultActiveKey="0"
                          className="planAccordian mt-4 ms-3"
                        >
                          <Accordion.Item eventKey="0" className="mb-4">
                            <Accordion.Header>
                              <h3 className="text-gray-800 font-medium">
                                Is there free trial available?
                              </h3>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="h6 fw-normal text-gray-200">
                                Yes, you can try us for 30 days, If you want,
                                we will provide you with a free, personalized
                                30-minute onboarding call to get you up running
                                as soon as possible. The Free plan enables you
                                to experience the power of visual collaboration
                                firsthand.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1" className="mb-4">
                            <Accordion.Header>
                              <h3 className="text-gray-800 font-medium">
                                Can I change my plan later?
                              </h3>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="h6 fw-normal text-gray-200">
                                Yes, you can try us for 30 days, If you want,
                                we will provide you with a free, personalized
                                30-minute onboarding call to get you up running
                                as soon as possible. The Free plan enables you
                                to experience the power of visual collaboration
                                firsthand.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2" className="mb-4">
                            <Accordion.Header>
                              <h3 className="text-gray-800 font-medium">
                                What is your cancellation policy?
                              </h3>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="h6 fw-normal text-gray-200">
                                Yes, you can try us for 30 days, If you want,
                                we will provide you with a free, personalized
                                30-minute onboarding call to get you up running
                                as soon as possible. The Free plan enables you
                                to experience the power of visual collaboration
                                firsthand.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3" className="mb-4">
                            <Accordion.Header>
                              <h3 className="text-gray-800 font-medium">
                                What are my payment options? Can I pay by credit
                                card?
                              </h3>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="h6 fw-normal text-gray-200">
                                Yes, you can try us for 30 days, If you want,
                                we will provide you with a free, personalized
                                30-minute onboarding call to get you up running
                                as soon as possible. The Free plan enables you
                                to experience the power of visual collaboration
                                firsthand.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4" className="mb-4">
                            <Accordion.Header>
                              <h3 className="text-gray-800 font-medium">
                                We want to add new users to our team. How will
                                that be billed ?
                              </h3>
                            </Accordion.Header>
                            <Accordion.Body>
                              <p className="h6 fw-normal text-gray-200">
                                Yes, you can try us for 30 days, If you want,
                                we will provide you with a free, personalized
                                30-minute onboarding call to get you up running
                                as soon as possible. The Free plan enables you
                                to experience the power of visual collaboration
                                firsthand.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </div>
                  </div>
                </div>
              </Collapse>
            </div>
          </Container>
        </div>
      </div>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={upgrade}
        onHide={() => setUpgrade(false)}
      >
        {upgrade && (
          <UpgradeModal
            handleModal={setUpgrade}
            selectedPlanData={selectedPlanData}
            confirm={handleUpgradePlan}
          />
        )}
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={downgrade}
        onHide={() => setDowngrade(false)}
      >
        {downgrade && (
          <DowngradeModal
            //handleModal={setDowngrade}
            handleModal={setDowngrade}
            confirm={handleDowngradePlan}
            request={donwgradePlanReason}
            setRequest={setDonwgradePlanReason}
          />
        )}
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={downgrade2}
        onHide={() => setDowngrade2(false)}
      >
        <Downgrade2Modal
          // handleModal={setDowngrade2}
          setLessFeaturesPlan={setDowngrade2}
          // confirm={handleLessFeaturesPlan}
          confirm={handleComfirmDowngrade}
          selectedPlanData={selectedPlanData}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={samePlan}
        onHide={() => setSamePlan(false)}
      >
        <SamePlanModal
          // handleModal={setSamePlan}
          setDiscountPlan={setSamePlan}
          confirm={handleComfirmDowngrade}
        />
      </Modal>
      <Modal
        className="modalDialogxl"
        size="xl"
        centered
        show={paymentCardModal}
        onHide={() => setPaymentCardModal(false)}
      >
        {/* <Elements stripe={stripePromise}> */}
        <PaymentCardModal
          selectedPlanId={selectedPlanId}
          setSelectedPlanId={setSelectedPlanId}
          paymentCardModal={paymentCardModal}
          setPaymentCardModal={setPaymentCardModal}
          setIsMakePlan={setIsMakePlan}
          selectedPlanData={selectedPlanData}
          modalTitle={planTitle}
        />
        {/* </Elements> */}
      </Modal>
    </>
  );
};

export default ChangePlan;
