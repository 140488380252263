import React, { useState, useEffect } from "react";
import { IconCrossSm } from "../../icons/Icons";
import { useDispatch } from "react-redux";
import { createTagAsync, getTagAsync } from "../../reducers/user-reducers";

const TagsInput = ({
  inputTags,
  onChange,
  setMethod,
  touchedMethod,
  touchedData,
  ...props
}) => {
  const [tagInput, setTagInput] = useState("");
  const [tags, setTags] = useState([]);
  const [options, setOptions] = useState([]);
  const [showOptions, setShowOptions] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const dispatch = useDispatch();

  const onTabOrEnterClick = (e) => {
    setShowOptions(true)
    if (e.keyCode === 9 || e.keyCode === 13) {
      e.preventDefault();
      handleTagSelection(tagInput);
    }
  };

  useEffect(() => {
    let seletedtags = tags.map((m) => m.tag).toString();
    if (
      inputTags !== undefined &&
      inputTags !== "" &&
      seletedtags !== inputTags
    ) {
      const newTags = inputTags.split(",").map((m, i) => ({ id: i, tag: m }));
      setTags(newTags);
      setSearchQuery("")
    }
  }, [inputTags]);

  useEffect(() => {
    async function fetchOptions() {
      const response = await dispatch(getTagAsync());
      if (response.payload && Array.isArray(response.payload.result)) {
        const allOptions = response.payload.result;

        const filteredOptions = allOptions.filter(
          (tag) => !tags.some((selectedTag) => selectedTag.id === tag.id)
        );
        setOptions(filteredOptions);
      }
    }
    fetchOptions();
  }, [tags]);

  const handleTagSelection = (selectedTag) => {
    setSearchQuery("")
    if (selectedTag.trim() === "") {
      return;
    }
    const isExistInTags = tags.some((tag) => tag.tag === selectedTag);

    if (!isExistInTags) {
      const isExistInAllTags = options.some((tag) => tag.tag === selectedTag);

      if (isExistInAllTags) {
        const existingTag = options.find((tag) => tag.tag === selectedTag);
        setTags([...tags, existingTag]);
        setSearchQuery("");
        if (onChange) {
          onChange(
            [...tags, existingTag],
            setMethod,
            touchedMethod,
            touchedData
          );
        }
      } else {
        const reqBody = {
          tag: selectedTag,
        };

        dispatch(createTagAsync(reqBody)).then((createTagResponse) => {
          if (createTagResponse.payload && createTagResponse.payload.result) {
            const newTag = {
              id: createTagResponse.payload.result.id,
              tag: selectedTag,
            };

            setTags([...tags, newTag]);
            setSearchQuery("");
            if (onChange) {
              onChange(
                [...tags, newTag],
                setMethod,
                touchedMethod,
                touchedData
              );
            }
          }
        });
      }
    }

    setTagInput("");
    setShowOptions(false); // Hide the options
  };

  const removeTag = (id) => {
    const tagsNew = tags.filter((i) => i.id !== id);
    setTags(tagsNew);
    setSearchQuery("");
    if (onChange) {
      onChange(tagsNew, setMethod, touchedMethod, touchedData);
    }
  };
  const filteredOptionsItems = options.length > 0 && options.filter((item) =>
  item.tag.toLowerCase().includes(searchQuery.toLowerCase())
);
  return (
    <div className="myInput">
      <label>
        Tags
        <span className="mandatoryFields" style={{ color: "red" }}>
          *
        </span>
      </label>
      <div className="tagsInput input">
        <div className="tagsList h6">
          {tags.map((i) => (
            <span className="tagsListItm" key={i.id}>
              {i.tag}{" "}
              <button type="button" onClick={() => removeTag(i.id)}>
                <IconCrossSm />
              </button>
            </span>
          ))}
        </div>
        <div className="w-100">
          <input
            type="text"
            value={tagInput}
            id="tag"
            autocomplete="off"
            className={`form-control ${
              showOptions ? "taginputShow" : "taginputHide"
            }`}
            placeholder="Type here to add tags…"
            onChange={(e) => {
              setSearchQuery(e.target.value);
              setTagInput(e.target.value);
            }}
            onFocus={() => setShowOptions(true)}
            // onBlur={() => setShowOptions(false)}
            onKeyDown={(e) => onTabOrEnterClick(e)}
            validate={props.validate}
          />
 
        </div>
      </div>
      {searchQuery.length > 0 && filteredOptionsItems.length > 0 && (
      <>
          <ul
            id="tag_search_list"
            className={`custom-dropdown-list ${
              showOptions ? "taginputShow" : "taginputHide"
            }`}
          ><div className="d-none" style={{float:"right"}}><button type="button" class="btn-close" aria-label="Close" onClick={() =>{setSearchQuery('')
          setTagInput('');
          }}></button></div>
            {filteredOptionsItems.length > 0 &&  filteredOptionsItems.map((option) => (
              <li
                key={option.id}
                className="custom-dropdown-option"
                onClick={() => handleTagSelection(option.tag)}
              >
                {option.tag}
              </li>
            ))}
          </ul></>)}
    </div>
  );
};

export default TagsInput;
