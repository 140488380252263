import React, { useEffect, useState } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import {
  IconStarLg,
  IconStarGray,
  IconPlanUpgrade,
} from "../../../icons/Icons";
import planBg from "../../../assets/images/plan_bg.png";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionAsync } from "../../../reducers/subscription-reducers";

const PlanUpgade = () => {
  const navigate = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const [selectedPlanData, setSelectedPlanData] = useState(null);

  const goBack = () =>{
    navigate.push("/");
  }

  useEffect(() => {
    // Accessing the state passed from the previous component
    const selectedPlans = location.state.selectedPlanData;
    setSelectedPlanData(selectedPlans);
    console.log(selectedPlans,'selectedPlanData');
  }, [location]);

  return (
    <div className="pages bg-gray-600">
      <PageHeader title="Account Settings" />
      <div className="d-flex align-items-center justify-content-center h-100 row g-0">
        <div className="text-center col-lg-6">
          <div className="mb-20">
            <IconPlanUpgrade />
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-10">
              <h1 className="mb-3">
                {/* Your plan will be downgraded from the next billing cycle. */}
                Thank you! Your plan has been upgraded successfully.
              </h1>
              <p className='text-gray-300 mb-32'>You will continue to have access to your current plan features until the end of this billing cycle. Your plan will renew at $199/month from your next billing cycle.</p>
                    {/* <div className="row d-flex justify-content-center">
                        <div className="col-lg-10">
                            <h1 className='mb-3'> Your plan will be downgraded from the next billing cycle.</h1>
                        </div>
                    </div>
                

                    <p className='text-gray-300 mb-32'>You will continue to have access to your current plan features until the end of this billing cycle. Your plan will renew at $199/month from your next billing cycle.</p>
                    <div className="row d-flex justify-content-center">

                        <div className='col-lg-8 rounded-6 borderAll bg-base-100 text-start p-4 mb-32 position-relative'>
                            <p className='mb-1 font-medium'>Business</p>
                            <p className='text-gray-1000 mb-20 text-md w-75'>Live 60 minute training on using AdditionalTraining and Support $500/mo as an addon
                            </p>

                            <h3>$200</h3>

                            <p className='text-gray-1000 mb-0 text-md'>Per member/month billed annually </p>
                            <div className='position-absolute end-0 bottom-0'>
                                <img src={planBg} alt="" />
                            </div>
                        </div>
                    </div>

                    <div className='text-center'>
                        <Button variant="secondary" >
                            Go to  Dashboard
                        </Button>
                    </div>


                </div> */}

            </div>
          </div>

          {/* <p className="text-gray-300 mb-32">
            You will continue to have access to your current plan until the end
            of this billng cycle. Your plan will renew at $199/month from the
            next billing cycle
          </p> */}
          <div className="row d-flex justify-content-center">
            <div className="col-lg-8 rounded-6 borderAll bg-base-100 text-start p-4 mb-32 position-relative">
              <p className="mb-1 font-medium">{selectedPlanData?.planName}</p>
              <p className="text-gray-1000 mb-20 text-md w-75">
                Live 60 minute training on using Additional Training and Support
                $500/mo as an addon
              </p>

              <h3>
              {/* $200 */}
              ${selectedPlanData?.price}
              </h3>

              <p className="text-gray-1000 mb-0 text-md">
                Per member/billed 
                {selectedPlanData?.planType === "Yearly" ? " annually" : " monthly"}
              </p>
              <div className="position-absolute end-0 bottom-0">
                <img src={planBg} alt="" />
              </div>
            </div>
          </div>

          <div className="text-center">
            <Button variant="secondary" onClick={() => goBack()}>Go to Dashboard</Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanUpgade;
