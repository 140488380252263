import React from 'react';
import { Modal, Button, Row, Col } from "react-bootstrap";
import moduleFrame from '../../assets/images/moduleFrame.png';
import moduleFrame2 from '../../assets/images/moduleFrame2.png';
const TemplateLibraryModal = ({ handleModal, handlePreview }) => {
    return (
        <>
            <Modal.Header closeButton className='ps-5'>
                <div className='w-100'>
                    <Modal.Title>Template Library </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className='p-0'>

                <Row className='h-100 g-0 d-flex'>

                    <Col className='col-lg-2 borderRight flex-grow-1 overflow-auto h-100'>

                        <div className='px-20 py-32'>
                            <div className='pb-3 mb-3 borderBottom'>
                                <div className='d-flex justify-content-between align-items-center mb-10'>
                                    <h6 className='font-medium mb-0'>For you</h6>
                                    <div><div className='arrow right gray-100'></div></div>
                                </div>
                                <p className='h6 text-gray-300 mb-10'>Recent</p>
                                <p className='h6 text-gray-300'>Popular</p>
                            </div>
                            <div className='pb-3 mb-3 borderBottom'>
                                <div className='font-bold mb-10 text-gray-300 text-md'>Process</div>
                                <p className='h6 text-gray-300 mb-10'>HR Onboarding</p>
                                <p className='h6 text-gray-300 mb-10'>Accounts Onboarding</p>
                                <p className='h6 text-gray-300 mb-10'>Design Onboarding</p>
                                <p className='h6 text-gray-300 mb-10'>DevOPS Onboarding</p>
                            </div>
                            <div className='pb-3 mb-3 borderBottom'>
                                <div className='font-bold mb-10 text-gray-300 text-md'>Sequence</div>
                                <p className='h6 text-gray-300 mb-10'>Policy Intro</p>
                                <p className='h6 text-gray-300 mb-10'>Women empowerment</p>
                                <p className='h6 text-gray-300 mb-10'>COVID Guidelines</p>
                            </div>

                            <div className='pb-3 mb-3'>
                                <div className='font-bold mb-10 text-gray-300 text-md'>Modules</div>
                                <p className='h6 text-gray-300 mb-10'>Policy Intro</p>
                                <p className='h6 text-gray-300 mb-10'>Women empowerment</p>
                                <p className='h6 text-gray-300 mb-10'>COVID Guidelines</p>
                            </div>

                        </div>
                    </Col>

                    <Col className='col-lg-10 flex-grow-1 overflow-auto h-100'>

                        <div className='bg-gray-600 h-100 p-32'>

                            <h5 className='mb-4'>For you</h5>

                            <Row>

                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>
                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>
                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame2} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>

                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>
                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>
                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame2} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>

                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame2} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>
                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg'>
                                            <div className='mb-3'>
                                                <img src={moduleFrame} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>
                                <Col className='col-lg-4'>

                                    <div className='bg-base-100 rounded-12 boxShadow2 p-12 borderAll2 forYouCard'>

                                        <p className='text-secondary-400 mb-1 text-md'>PROCESS</p>
                                        <div className='forYouImg '>
                                            <div className='mb-3'>
                                                <img src={moduleFrame} alt="" className='w-100' />
                                            </div>


                                            <div className='viewBtns d-flex gap-2 '>
                                                <Button variant="secondary" size="sm">
                                                    Use
                                                </Button>
                                                <Button variant="white" size="sm" className='bg-base-100' onClick={() => handlePreview(true)}>
                                                    Preview
                                                </Button>
                                            </div>
                                        </div>

                                        <p className='font-medium mb-2'>All About Cerebrent</p>

                                    </div>
                                </Col>
                            </Row>

                        </div>
                    </Col>
                </Row>


            </Modal.Body>

        </>
    );
}

export default TemplateLibraryModal;
