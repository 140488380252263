import React from "react";
const EmptyState = ({message, link}) => {
  return (
    <div className="EmptyStateSec">
      <div className="EmptyGraphics">
      <svg width="164" height="164" viewBox="0 0 164 164" fill="none" xmlns="http://www.w3.org/2000/svg">
<circle cx="82" cy="82" r="82" fill="#F4F4F4"/>
<path d="M127.486 108.514H112.891C100.729 108.514 98.2969 103.503 98.2969 93.4812V78.4731C98.2969 67.0407 102.359 63.9758 110.459 63.5137H129.918C138.018 63.9515 142.081 67.0407 142.081 78.4731V93.9191" fill="white"/>
<path d="M108.027 81.7559H127.487" stroke="#D9D9D9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M108.027 91.4863H120.19" stroke="#D9D9D9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M142.081 93.918L127.486 108.513V101.215C127.486 96.3504 129.919 93.918 134.784 93.918H142.081Z" fill="#EEEEEE"/>
<path d="M55.4861 108.514H40.8915C28.7293 108.514 26.2969 103.503 26.2969 93.4812V78.4731C26.2969 67.0407 30.359 63.9758 38.459 63.5137H57.9185C66.0185 63.9515 70.0807 67.0407 70.0807 78.4731V93.9191" fill="white"/>
<path d="M36.0273 81.7559H55.4868" stroke="#D9D9D9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M36.0273 91.4863H48.1895" stroke="#D9D9D9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M70.0809 93.918L55.4863 108.513V101.215C55.4863 96.3504 57.9188 93.918 62.7836 93.918H70.0809Z" fill="#EEEEEE"/>
<path d="M94 118.333H74C57.3333 118.333 54 111.466 54 97.7327V77.166C54 61.4993 59.5667 57.2993 70.6667 56.666H97.3333C108.433 57.266 114 61.4993 114 77.166V98.3327" fill="white"/>
<path d="M67.334 81.666H94.0007" stroke="#D9D9D9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M67.334 95H84.0007" stroke="#D9D9D9" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M114 98.334L94 118.334V108.334C94 101.667 97.3333 98.334 104 98.334H114Z" fill="#EEEEEE"/>
<path d="M84.3327 111.999C101.822 111.999 115.999 97.8217 115.999 80.3327C115.999 62.8437 101.822 48.666 84.3327 48.666C66.8437 48.666 52.666 62.8437 52.666 80.3327C52.666 97.8217 66.8437 111.999 84.3327 111.999Z" stroke="#D9D9D9" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/>
<path d="M119.333 115.333L112.666 108.666" stroke="#D9D9D9" stroke-width="4.5" stroke-linecap="round" stroke-linejoin="round"/>
</svg>

       <div className="d-flex pt-3">
       <span className="text-sm text-gray-300">{message? message : "No content added"}</span>
        <span className="text-sm text-primary-200 font-bold ms-1">{link}</span>
       </div>
      </div>
    </div>
  );
};
export default EmptyState;
