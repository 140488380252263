import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  downgradeStorageAsync,
  getSubscriptionAsync,
  getStorageAsync,
  getCustomerInvoicesAsync,
} from "../../../reducers/subscription-reducers";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import ToasterAlertError from "../../../helper/ToasterAlertError";
import { convertMBToGB, convertKBToGBNumeric, convertMBToKB } from "../../../common";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";

const RemoveStorageModal = ({handleModal, basePlan}) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData, isUpdateLoading, storageData } = useSelector(
    (state) => state.subscription
  );

  const [count, setCount] = useState(1);
  const [incrementCount, setIncrementCount] = useState(100);
  const [isLoader, setIsLoader] = useState(false);
  const [makePayment, setMakePayment] = useState(true);
  const [dueNextCycle, setDueNextCycle] = useState("");
  const [isRemoved, setIsRemoved] = useState(false);
  const [storageBar, setStorageBar] = useState(0);

  useEffect(() => {
    dispatch(getSubscriptionAsync());
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    if (storageData?.totalStorage) {
      const basePlanStorage = basePlan && convertMBToKB(parseInt(basePlan?.metaData?.storage)*parseInt(basePlan?.metaData?.included_users));
      const addonStorage = (parseInt(storageData.totalStorage) - parseInt(basePlanStorage));
      const addonUsedStorage = storageData.usedStorage;
      const usedAddonStorage = addonUsedStorage > basePlanStorage ? (addonUsedStorage - basePlanStorage) : 0 ;
      const unused = (addonStorage-usedAddonStorage);
      const usedStorage = convertKBToGBNumeric(unused);
      // const usedStorage = convertKBToGBNumeric(addonStorage);
      const minimumCount = Math.ceil(usedStorage / 100); // Round up to the nearest integer
      console.log(minimumCount, 'minimumCount');
      console.log(usedStorage, 'usedStorage');
      if (count > minimumCount) {
        setIsRemoved(true);
      } else {
        setIsRemoved(false);
      }
    }    
  }, [storageData, count, basePlan]);

  useEffect(() => {
    if (subscriptionData?.subscriptionData?.subscriptions?.length > 0) {
      setDueNextCycle(moment(subscriptionData?.subscriptionData?.subscriptions[0]?.currentPeriodEnd).format("MMM D, YYYY"));
    }

    const calculatedStorageBar = storageData && storageData?.usedStorage > 0 ? ((storageData?.usedStorage / storageData?.totalStorage) * 100).toFixed(2) : 0;
    const stBar = parseFloat(calculatedStorageBar);
    setStorageBar(stBar);
  }, [subscriptionData, storageData]);

  const increaseCount = () => {
    setCount(prevCount => prevCount + 1);
    setIncrementCount(prevCount => prevCount + 100);
    setMakePayment(false);
  };

  const decreaseCount = () => {
    setCount(prevCount => prevCount === 1 ? 1 : prevCount - 1);
    setIncrementCount(prevCount => prevCount === 100 ? 100 : prevCount - 100);
    setMakePayment(false);
  };

  console.log(subscriptionData,'subscriptionData Remove Storage');
  // console.log(plansData,'plansData Remove Storage');
  console.log(storageData,'storageData Remove Storage');

  const removeStorage = async () => {
    if (isRemoved === false) {
      setMakePayment(true);
      setIsLoader(true);
      const request = {
          quantityToReduce: count,
      };
      // await dispatch(downgradeStorageAsync(request)).then((res) => {
      //     // console.log(res.payload,'Storage removed successfully!');
      //     setIsLoader(false);
      //     if (res.payload === "Success") {
      //       dispatch(getStorageAsync());
      //       dispatch(getCustomerInvoicesAsync());
      //       ToasterAlertSucces(res, 'Action performed successfully. Your storage will be removed in the next billing cycle.');
      //       handleModal(false);
      //     } else {
      //         console.log('removed storage error');
      //         // ToasterAlertError(res.payload);
      //     }
      // });

      try {
        const res = await dispatch(downgradeStorageAsync(request));
        setIsLoader(false);
        if (res.payload === "Success") {
          dispatch(getStorageAsync());
          dispatch(getCustomerInvoicesAsync());
          ToasterAlertSucces(res, 'Action performed successfully. Your storage will be removed in the next billing cycle.');
          handleModal(false);
        } else {
          console.log('removed storage error');
          ToasterAlertError(res.payload);
        }
      } catch (error) {
        setIsLoader(false);
        console.error('Error during storage removal:', error);
        ToasterAlertError('An unexpected error occurred. Please try again later.');
      }
    }
  };


  return (
    <>
      {isLoader && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <Modal.Header closeButton>
        <h3 className="">Remove Storage</h3>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <div className="d-flex align-items-start flex-column h-100">
          <div className="w-100 mb-5">
            <label className="d-inline-block text-uppercase text-gray-300 text-md font-medium mb-3 opacity-75">
              ADD-ON
            </label>
            <div className="border border-gray-400 rounded-6 p-4 pt-32 w-100">
              <p className="mb-1 text-gray-300 text-md font-medium text-uppercase opacity-75">
                Storage usage
              </p>
              <div className="d-flex justify-content-between align-items-center mt-2 px-2">
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <p className="mb-0 text-gray-100 text-lg pe-1">{convertMBToGB(storageData?.usedStorage)}</p>
                  <span className="text-secondary-300 text-lg font-bold ms-2">
                    {storageBar}%
                  </span>
                </div>
                <p className="mb-0 text-base-200 text-lg pe-1 opacity-75">
                  {convertMBToGB(storageData?.totalStorage)}
                </p>
              </div>
              <div
                class="progress rounded-0 mt-2"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ height: "0.5rem" }}
              >
                <div
                  class="progress-bar bg-gray-300"
                  style={{ width: `${storageBar}%` }}
                ></div>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2 pt-32">
              <label className="pe-1 text-gray-100 font-bold text-lg">
                Remove
              </label>
              <div className="d-flex justify-content-start align-items-center">
                <div className="d-flex align-items-center">
                  <button
                    className="btn-white btn-xs bg-gray-600"
                    onClick={decreaseCount}
                  >
                    <svg
                      width="16"
                      height="2"
                      viewBox="0 0 16 2"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.48096 1H14.518"
                        stroke="#232323"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <h6 className="text-gray-200 mx-3">{incrementCount}</h6>
                  <button
                    className="btn-white btn-xs bg-gray-600"
                    onClick={increaseCount}
                    disabled={isRemoved}
                  >
                    <svg
                      width="20"
                      height="20"
                      viewBox="0 0 22 22"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.48096 11H17.518"
                        stroke="#232323"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                      <path
                        d="M11 17.5185V4.48145"
                        stroke="#232323"
                        stroke-width="1.5"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                      ></path>
                    </svg>
                  </button>
                  <h6 className="text-gray-100 ms-2">GB</h6>
                </div>
              </div>
              <label className="ps-1 text-gray-100 font-bold text-lg">
                GB Storage
              </label>
            </div>
            {/* {isRemoved === true && (
              <label class="errorValidation" style={{ color: "red" }}>Storage can be removed in multiples of 100GBs only.</label>
            )} */}
          </div>
          <div className="mt-auto w-100">
            <p className="mb-0 text-gray-300 text-md">
              Your changes will take effect in next billing cycle,{" "}
              <b>{dueNextCycle}</b>
            </p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start btnFooter gap-2 py-3">
        <Button
          variant="secondary"
          size="large"
          // disabled={makePayment || isRemoved}
          disabled={isRemoved}
          onClick={() => removeStorage()}
        >
          Confirm
        </Button>
      </Modal.Footer>
    </>
  );
};

export default RemoveStorageModal;
