import React, { useState } from 'react';
import { Modal, Button } from "react-bootstrap";
import BarChartAll from "./BarChartAll";
import AccountImg from "../../assets/images/Account.png";
import {
    IconTopSearch,
    IconEditPencil,
    IconHamberger,
    IconEmployeeAccount,
    IconEmployeeCall,
    IconEmployeeMail,
    IconEmployeeDate,
  } from "../../icons/Icons";
const OnboardingsStatusModal = ({ handleModal, totalOnboardingCount, teamwiseOnboarding }) => {

    return (

        <>
            <Modal.Header className='ps-8' closeButton>
                <div className='w-100'>
                    <Modal.Title>Team Wise Onboarding Status<span className="font-normal ps-2">({totalOnboardingCount})</span></Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className='ps-8'>
                            
      
            <div className="row pt-3">
                                    {/* Just rapeat col-3 div */}
                <div className="col-12">  
                <BarChartAll teamwiseOnboarding={teamwiseOnboarding}/>
                    </div>

                </div>
            </Modal.Body>
           
        </>
    );
}

export default OnboardingsStatusModal;

