import React, { useState } from "react";
import DatePicker from "react-datepicker";
import * as moment from 'moment';

function DateRangePicker({ start, end, onChange, errorMessage, setErrorMessage }) {

  const [startDate, setStartDate] = useState(start);
  const [endDate, setEndDate] = useState(end);
  const [minDate, setMinDate] = useState(null);

  const handleStartDateChange = (event) => {
    const newStartDate = moment(event).format('YYYY-MM-DDThh:mm:ssZ');
    setStartDate(newStartDate);
    onChange(newStartDate, null);
    setMinDate(newStartDate);
    //setErrorMessage("End date is required")
    if(!end)
    setErrorMessage("End date is required")
    // else
    // setErrorMessage(null)

    // if (newStartDate < endDate) {
    //   setStartDate(newStartDate);
    //   onChange(newStartDate, null);
    // } else {
    //   setStartDate(newStartDate);
    //   setEndDate(newStartDate);
    //   onChange(newStartDate, null);
    // }
  };

  const handleEndDateChange = (event) => {
    const newEndDate = moment(event).format('YYYY-MM-DDThh:mm:ssZ');

    if (!start) {
      setErrorMessage("Start date is required");
    } else {
      setEndDate(newEndDate);
      onChange(start, newEndDate);
    }
    if (start) {
      setErrorMessage(null);
    }
    // if (newEndDate > startDate) {
    //   setEndDate(newEndDate);
    //   onChange(null, newEndDate);
    // } else {
    //   setEndDate(newEndDate);
    //   setStartDate(newEndDate);
    //   onChange(newEndDate, null);
    // }
  };

  const handleStartDateKeyDown = (event) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      setStartDate(null);
      onChange(null, endDate);
      setMinDate(null);
      setErrorMessage("Start date is required");
    }
  };

  const handleEndDateKeyDown = (event) => {
    if (event.key === "Backspace" || event.key === "Delete") {
      setEndDate(null);
      onChange(startDate, null);
    }
  };

  return (
    <div>
      <label htmlFor="start-date">Start date:</label>
      <DatePicker
        selected={start ? new Date(startDate) : null}
        onChange={handleStartDateChange}
        onKeyDown={handleStartDateKeyDown}
      />
      <br />
      <label htmlFor="end-date">End date:</label>
      <DatePicker
        selected={end ? new Date(endDate) : null}
        onChange={handleEndDateChange}
        onKeyDown={handleEndDateKeyDown}
        minDate={new Date(minDate)}
      />
      <p className="text-primary-100 bold">{errorMessage}</p>
    </div>
  );
}

export default DateRangePicker;
