import React, { useState, useEffect, useRef } from "react";
import { Formik, Form, Field } from "formik";
import Loader from "../../../components/Loader/Loader";
import { useDispatch, useSelector } from "react-redux";
import AccountSettingNav from "../AccountSettingNav";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import { IconHamberger, IconStar } from "../../../icons/Icons";
import AccountImg from "../../../assets/images/Account.png";
import Search from "../../../components/Search/Search";
import CheckBoxMd from "../../../components/CheckBox/CheckBoxMd";
import Select from "react-select";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import ConfimrationPopup from "../../../components/ModalPopup/ConfimrationPopup";
import TextInput from "../../../components/FormikInputs/TextInput";
import {
  getUserPrefenceAsync,
  setUserPrefenceAsync,
} from "../../../reducers/user-reducers";
const PreferencesEmp = () => {
  const [userPreferences, setUserPreferences] = useState(null);
  const [showPopup, setshowPopup] = useState(false);
  const [formDataReady, setFormDataReady] = useState(true);
  const formRef = useRef();
  const dispatch = useDispatch();

  // const onPreferencesSubmit = () => {
  //   const currentFormData = formRef?.current;
  //   dispatch(setUserPrefenceAsync(currentFormData.values)).then((res) => {
  //     getUserPrefence();
  //     setshowPopup(true);
  //   });
  // };

  const onPreferencesSubmit = (values) => {
    const transformedData = userPreferences.map((notification, index) => ({
      preferenceId: notification.id,
      notificationsType: values?.emailNotifications[index]?.notificationsType,
      emailValue: values?.emailNotifications[index]?.emailValue,
      textValue: values?.emailNotifications[index]?.textValue,
      inAppValue: values?.emailNotifications[index]?.inAppValue,
    }));

    dispatch(setUserPrefenceAsync(transformedData)).then(() => {
      getUserPrefence();
      setshowPopup(true);
    });
  };

  const getUserPrefence = async () => {
    const responseData = await dispatch(getUserPrefenceAsync());
    setUserPreferences(responseData.payload);
    setFormDataReady(false);
  };

  useEffect(() => {
    setFormDataReady(true);
    if (!userPreferences) {
      getUserPrefence();
    }
  }, [userPreferences]);

  const onResponseClose = () => {
    setshowPopup(false);
  };

  // const onChangeDataSet = (setFieldValue, e) => {
  //   // console.log(e,'eeeeeeeeeeee');
  //   if (e.target.name === "invitationAlertE") {
  //     setFieldValue(
  //       "emailNotifications[0].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "invitationAlertP") {
  //     setFieldValue(
  //       "emailNotifications[0].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "invitationAlertApp") {
  //     setFieldValue(
  //       "emailNotifications[0].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "onboardingFirstTimeE") {
  //     setFieldValue(
  //       "emailNotifications[1].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "onboardingFirstTimeP") {
  //     setFieldValue(
  //       "emailNotifications[1].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "onboardingFirstTimeApp") {
  //     setFieldValue(
  //       "emailNotifications[1].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "assignmentUpdatesNTAE") {
  //     setFieldValue(
  //       "emailNotifications[2].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNTAP") {
  //     setFieldValue(
  //       "emailNotifications[2].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNTAApp") {
  //     setFieldValue(
  //       "emailNotifications[2].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "assignmentUpdatesNDAE") {
  //     setFieldValue(
  //       "emailNotifications[3].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNDAP") {
  //     setFieldValue(
  //       "emailNotifications[3].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNDAApp") {
  //     setFieldValue(
  //       "emailNotifications[3].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "assignmentUpdatesNonDAE") {
  //     setFieldValue(
  //       "emailNotifications[4].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNonDAP") {
  //     setFieldValue(
  //       "emailNotifications[4].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNonDAApp") {
  //     setFieldValue(
  //       "emailNotifications[4].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "assignmentUpdatesNEAE") {
  //     setFieldValue(
  //       "emailNotifications[5].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNEAP") {
  //     setFieldValue(
  //       "emailNotifications[5].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNEAApp") {
  //     setFieldValue(
  //       "emailNotifications[5].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "assignmentUpdatesNonEAE") {
  //     setFieldValue(
  //       "emailNotifications[6].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNonEAP") {
  //     setFieldValue(
  //       "emailNotifications[6].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "assignmentUpdatesNonEAApp") {
  //     setFieldValue(
  //       "emailNotifications[6].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }

  //   if (e.target.name === "reportedIssuesUpdatesE") {
  //     setFieldValue(
  //       "emailNotifications[7].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "reportedIssuesUpdatesP") {
  //     setFieldValue(
  //       "emailNotifications[7].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "reportedIssuesUpdatesApp") {
  //     setFieldValue(
  //       "emailNotifications[7].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "verificationNotificationE") {
  //     setFieldValue(
  //       "emailNotifications[8].emailNotificationType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "verificationNotificationP") {
  //     setFieldValue(
  //       "emailNotifications[8].phoneNotificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  //   if (e.target.name === "verificationNotificationApp") {
  //     setFieldValue(
  //       "emailNotifications[8].notificationsType",
  //       e.target.checked ? true : false
  //     );
  //   }
  // };

  console.log(userPreferences, "userPreferences");

  return (
    <div className="pages">
      <header className="pageHeader mb-4">
        <Container fluid>
          <Row className="mx-0 align-items-center">
            <Col className="px-0">
              <h3>Account Settings</h3>
            </Col>
            <Col className="px-0 text-end ">
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <AccountDropdown />
                <ActivityLog />
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <div className="pageOuter">
        <Container fluid>
          <AccountSettingNav />
          <div className="page profile  d-flex flex-column">
            {!formDataReady && !userPreferences && <Loader />}
            {formDataReady && userPreferences && (
              <>
                <Formik
                  initialValues={{
                    emailNotifications: userPreferences || [],
                    // id: null,
                    // emailNotifications: userPreferences?.emailNotifications,
                    // informOnNewlaunches: userPreferences?.informOnNewlaunches,
                    // supportInformation: userPreferences?.supportInformation,
                    // informFOrOtherThings: userPreferences?.informFOrOtherThings,
                    // thirdPartyIntegration:
                    //   userPreferences?.thirdPartyIntegration,
                  }}
                  onSubmit={onPreferencesSubmit}
                  innerRef={formRef}
                >
                   {({ values, setFieldValue, resetForm }) => (
                  <Form style={{ display: "contents" }}>
                    <div className="tableComponent prefTable flex-grow-1 overflow-auto">
                      <table className="table">
                        <thead>
                          <tr>
                            <th className="text-gray-100 text-sm">
                              Notifications
                            </th>
                            <th className="px-32">Email</th>
                            <th className="px-32">SMS</th>
                            <th className="w-113 px-32">In-App</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userPreferences.map(
                            (notification, index) =>
                              notification.isVisibleOnUI && (
                                <tr key={notification.id}>
                                  <td>
                                    <b>{notification?.title} -</b>{" "}
                                    {notification?.subTitle}
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].emailValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          values.emailNotifications[index]
                                            ?.emailValue || false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `emailNotifications[${index}].emailValue`,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].textValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={
                                          values.emailNotifications[index]
                                            ?.textValue || false
                                        }
                                        onChange={(e) => {
                                          setFieldValue(
                                            `emailNotifications[${index}].textValue`,
                                            e.target.checked
                                          );
                                        }}
                                        disabled={
                                          !notification.isTextConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                  <td>
                                    <div className="form-check form-switch custom-switch p-0">
                                      <TextInput
                                        name={`emailNotifications[${index}].inAppValue`}
                                        label=""
                                        className="form-check-input"
                                        type="checkbox"
                                        checked={notification.inAppValue}
                                        disabled={
                                          !notification.isInAppConfigurable
                                        }
                                      />
                                    </div>
                                  </td>
                                </tr>
                              )
                          )}
                        </tbody>
                      </table>
                    </div>
                    <div className="d-flex gap-20 align-items-center btnFooter">
                      <Button
                        variant="secondary"
                        onClick={() => onPreferencesSubmit(values)}
                      >
                        Save Changes
                      </Button>
                      <Button
                        variant="white"
                        size=""
                        onClick={() => resetForm()}
                      >
                        Cancel
                      </Button>
                    </div>
                  </Form>
                )}
                </Formik>
              </>
            )}
          </div>
          <Modal className="modalDialog" size="sm" centered show={showPopup}>
            <ConfimrationPopup
              message="Your details have been saved successfully"
              onClose={onResponseClose}
            />
          </Modal>
        </Container>
      </div>
    </div>
  );
};

export default PreferencesEmp;
