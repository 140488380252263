import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Modal, Accordion, Button } from "react-bootstrap";
import ReportModal from "./ReportModal";
import { Link, useLocation } from "react-router-dom";
import Video from "./video";
import Ppt from "./ppt";
import Doc from "./Doc";
import Audio from "./Audio";
import ImagePreview from "./ImagePreview";
import { useSelector } from "react-redux";
import { bulkGeneratePresignedUrl, generatePresignedUrl } from "../../../api/common-store-api";
import { IconPPT, IconDownload, IconReportIssue } from "../../../icons/Icons";
import DownloadLink from "./DownloadLink";
import { mapStatusToValue, getRemainingStringFileName } from "../../../common";

const PagePreview = ({ actionReport, data, contentUrl, setContent, allData, userProfiles, currentState }) => {

  const [show, setShow] = useState(false);
  const location = useLocation();
  const ImgRef = useRef();
  const [seconds, setSeconds] = useState(0);
  const [current, setCurrent] = useState(0);
  const [contentUrlData, setContentUrlData] = useState(null);
  const userRole = useSelector((state) => state.user.currentUser?.roles[0].name);
  const arrayDownloadableFiles = data?.downloadableFile
  // const [downloadableFileURLs, setDownloadableFileURLs] = useState([])


  const getPreviewUrl = async () => {
    let previewUrl = await generatePresignedUrl(contentUrl);
    if (previewUrl?.data) {
      setContentUrlData(previewUrl.data);
    }
  };

  const getDownloadUrl = async (data) => {
    let previewUrl = await generatePresignedUrl(data);
    if (previewUrl?.data) {
      console.log(previewUrl.data)
      return previewUrl.data;
    }
  };

  let downloadableFileURLs = []
  const getBulkGeneratePresignedUrl = async (files) => {
    // if (arrayDownloadableFiles !== undefined &&
    //   arrayDownloadableFiles !== null &&
    //   arrayDownloadableFiles.length > 0) {
    //   let responseURLsDownloadable = await bulkGeneratePresignedUrl(arrayDownloadableFiles.map((value) => value.filePath));
    //   console.log('responseURLsDownloadable 34 = ', responseURLsDownloadable)
    //   if (responseURLsDownloadable.data) {
    //     setDownloadableFileURLs(responseURLsDownloadable.data)
    //   }
    // }
    let responseURLsDownloadable = await bulkGeneratePresignedUrl(files.map((value) => value.filePath));
    if (responseURLsDownloadable?.data) {
      return responseURLsDownloadable.data;
    }
  };

  useEffect(() => {
    getPreviewUrl();
    // getBulkGeneratePresignedUrl()
  }, []);

  function isValidPhoto(fileName) {
    // if (fileName !== undefined) {
    if (fileName && typeof fileName === "string") {
      var allowed_extensions = new Array("jpg", "png", "jpeg");
      var file_extension = fileName.split(".").pop().toLowerCase();
      for (var i = 0; i <= allowed_extensions.length; i++) {
        if (allowed_extensions[i] == file_extension) {
          return true;
        }
      }
    }
    return false;
  }

  function isVideo(filename) {
    // console.log(filename,'filenamefilename');
    // if (filename !== undefined) {
    if (filename && typeof filename === "string") {
      var ext = filename.split(".").pop().toLowerCase();
      switch (ext.toLowerCase()) {
        case "m4v":
        case "mp4":
        case "mov":
          return true;
      }
    }
    return false;
  }

  const isValidPPT = (filename) => {
    if (filename && typeof filename === "string") {
      var ext = filename.split(".").pop().toLowerCase();
      switch (ext.toLowerCase()) {
        case "pptx":
          return true;
      }
    }
    return false;
  };

  const isValidDocument = (filename) => {
    // if (filename !== undefined) {
    if (filename && typeof filename === "string") {
      var ext = filename.split(".").pop().toLowerCase();
      switch (ext.toLowerCase()) {
        case "pdf":
        case "doc":
        case "docx":
          return true;
      }
    }
    return false;
  };

  const isValidMusic = (filename) => {
    // if (filename !== undefined) {
    if (filename && typeof filename === "string") {
      var ext = filename.split(".").pop().toLowerCase();
      switch (ext.toLowerCase()) {
        case "mp3":
          return true;
      }
    }
    return false;
  };
  console.log(allData,"allData")
  console.log(data,"data")
  console.log(currentState,"currentState")
 
  return (
    <div className='pages'>
      <div className='pageOuter p-0 bg-base-100'>
        <div className='d-flex flex-column h-100'>
          <div className='w-100 flex-grow-1 h-100 overflow-auto'>
            <Row className='g-0 d-flex h-100'>
              <Col className='col-lg-9 flex-grow-1 overflow-auto h-100'>
                <div className='p-32 pb-4'>

                  <div className='rounded-6 border'>
                    <div className='p-32 bg-gray-600 '>
                      <div className='catlogHeading d-flex'>
                        <div className='count'>
                          <span className='roundCount'>{currentState + 1}</span>
                        </div>
                        <div className='ms-3 ps-1'>
                          <h4>{data.title}</h4>
                          <p className='text-sm text-gray-200 pt-1 pb-1 mb-2 text-break'>{data.description}</p>
                          {/* <p className='text-12 m-0 text-gray-300'>Expiring on <span className='text-base-200'>Jul 26</span></p> */}
                        </div>
                      </div>
                    </div>

                    <div>
                      {isVideo(data.pageContentPath) ? (
                        <Video
                          actionReport={actionReport}
                          contentUrl={data.pageContentPath}
                          slideData={data}
                          setContent={setContent}
                        />
                      ) : isValidPhoto(data.pageContentPath) ? (
                        <ImagePreview
                          actionReport={actionReport}
                          contentUrl={data.pageContentPath}
                          slideData={data}
                          setContent={setContent}
                        />
                      ) : isValidPPT(data.pageContentPath) ? (
                        <Ppt actionReport={actionReport}
                          contentUrl={data.pageContentPath}
                          slideData={data}
                          setContent={setContent}
                        />
                      ) : isValidDocument(data.pageContentPath) ? (
                        <Doc
                          actionReport={actionReport}
                          contentUrl={data.pageContentPath}
                          slideData={data}
                          setContent={setContent}
                        />
                      ) : isValidMusic(data.pageContentPath) ? (
                        <Audio
                          actionReport={actionReport}
                          contentUrl={data.pageContentPath}
                          slideData={data}
                          setContent={setContent}
                        />
                      ) : null}
                    </div>

                    {/* <div className='p-32'>
                                        <div className='textList mb-4'>
                                            <h5>Analytics home</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>The module helps the users to understand their expenses deeply. It provides users with a visual representation of their expenses till date. It provides different and smart insights for better decision making of further expenses.</p>
                                        </div>
                                        <div className='textList mb-4'>
                                            <h5>Total Expenses</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>Total expense is a visual representation of all the expenses done in past months and till date. By default, it shows the expenses of all the categories of the current month. To view the expenses of other months, the user has to click on the month given on the bar graph.<br></br>
Expensive week/month/quarter: Within the total expenses, the user gets a detailed tour of their expenses. The chart shows a visual representation of the most expensive wee/month/quarter by selecting the filter. It also shows major contributors of the most expensive week/month/quarter. By clicking on the particular week/month/quarter, users can see the contributor categories of that period which helps them to analyze their expenses.</p>
                                        </div>
                                        <div className='textList mb-4'>
                                            <h5>Summary</h5>
                                            <p className='text-sm text-gray-200 pt-1 pb-1 mb-2'>The module helps the users to understand their expenses deeply. It provides users with a visual representation of their expenses till date. It provides different and smart insights for better decision making of further expenses.</p>
                                        </div>
                                    </div>

                                    <div className='p-32'>
                                       Qustion answar
                                    </div> */}

                  </div>
                </div>
              </Col>
              <Col className='col-lg-3 flex-grow-1 h-100 catalogSidebar'>
                <div className='bg-gray-600 p-20 py-32 h-100 borderLeft2 overflow-auto'>
                  <h5 className='pb-2'>Next in queue</h5>
                  {allData && allData.length > 0 && allData.map((item, index) => (
                    <div key={item.id}>
                      {item.page ? (
                        <Accordion defaultActiveKey={currentState=== index ? index : null} className='catlogAccordion'>
                          <Accordion.Item eventKey={index} disabled={true} className="mb-3" key={item.id}>
                            <Accordion.Header>
                              <div className={`catlogHeading d-flex my-3 ${currentState === index ? 'active' : ''}`}>
                                <div className='count'>
                                  <span className='roundCount'>{index + 1}</span>
                                </div>
                                <div className='ms-1 px-3 acContent'>
                                  {/* <span class="tableTag text-md mb-2">{mapStatusToValue(item.status)}</span> */}
                                  <h4>{item.page === null ? item.form.title: item.page.title}</h4>
                                </div>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="mb-4">
                                <h6 className="mb-20">Resources</h6>
                                {item?.page?.downloadableFile !== undefined &&
                                  item?.page?.downloadableFile !== null &&
                                  item?.page?.downloadableFile.length > 0 &&
                                  item?.page?.downloadableFile.map((value, index, array) => (
                                    <div className="d-flex justify-content-between align-items-center mb-20">
                                      <div className="d-flex gap-3 align-items-center w-100">
                                        <div>
                                          <IconPPT />
                                        </div>
                                        <div className="w-100 pe-4">
                                          <p className="singleLineEc mb-0 text-break text-sm">{getRemainingStringFileName(value.filePath)}</p>
                                          {/* <p className="mb-0 text-gray-300 text-12 pt-1">1.27 MB</p> */}
                                        </div>
                                      </div>
                                      <DownloadLink url={value.filePath} />
                                    </div>
                                  ))}

                                {arrayDownloadableFiles === undefined ||
                                  arrayDownloadableFiles.length === 0 ? (
                                  <div className="p-20 bg-base-100 rounded-6">
                                    <p className="text-md text-gray-200">Looks like no resources have been added yet.</p>
                                  </div>
                                ) : null}
                              </div>
                              <div className="mb-0">
                                <h6 className="mb-20">Message from Author</h6>
                                <div className="p-20 bg-base-100 rounded-6">
                                  <p className="text-md text-gray-200">
                                    {item?.page?.messages && item?.page?.messages[0]?.message ? item?.page?.messages[0]?.message : 'Looks like the author has not added any message yet.'}
                                  </p>
                                </div>
                                
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      ) : (                       
                        <div className={`catlogHeading d-flex my-3 ${currentState === index ? 'active' : ''}`}>
                          <div className='count'>
                            <span className='roundCount'>{index + 1}</span>
                          </div>
                          <div className='ms-1 px-3 acContent'>
                          {/* <div className={`ms-1 px-3 acContent ${data.index === index + 1 ? 'active' : ''}`}> */}
                            {/* <span class="tableTag text-md mb-2">{mapStatusToValue(item.status)}</span> */}
                            <h4>{item.form=== null ? item.page.title: item.form.title}</h4>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}

                  {userProfiles && userProfiles.roleName === "Employee" && (
                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        <IconReportIssue />
                      </div>

                      <button
                        className="text-primary-200 h6 font-bold"
                        onClick={() => {
                          setContent(data)
                          actionReport(true)
                        }}
                      >
                        Report an issue
                      </button>
                    </div>
                  )}
                </div>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PagePreview;
