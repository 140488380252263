import React from 'react'
import logoOops from '../../assets/images/logo.svg'
import chatBtnImg from '../../assets/images/chat_btn.svg'
import { useHistory } from "react-router-dom";
import AuthService from '../../services/AuthService';

const Oops = (props) => {
  const{ heading, summary } = props;
  const history = useHistory();
  const navigateBack = () => {
    const authService = new AuthService();
    authService.logout();
    history.push("/");
  }
  return (
    <div className='oopsScreen'>
        <div className='d-flex justify-content-center pt-3'>
            <img src={logoOops} alt="logoOops" />
        </div>
        <div className='d-flex justify-content-center align-items-center' style={{height: "calc(100vh - 9rem)"}}>
            <div className='text-center'>
                <h1 className='text-gray-100 fw-bold mb-1'>{heading}</h1>
                <p className='mb-0 mt-2 text-gray-300 h4 fw-normal'>{summary.split('\n').map((line, index) => (
                    <React.Fragment key={index}>
                        {line}
                        <br />
                    </React.Fragment>
                    ))}
                </p>
                <button className='btn-white btn-sm px-4 bg-base-100 mt-4' onClick={navigateBack}>Go Back</button>
            </div>
        </div>
        <div className='d-flex justify-content-center align-items-center text-center'>
            <p className='h6 m-0 text-gray-100'>Your data is secure. Know more about our <a className='border-bottom border-primary-200 text-primary-200' href='#'>Privacy and Data Security Policies.</a></p>
        </div>
        <button className='chat-button'>
            <img src={chatBtnImg} alt="chatBtnImg" />
        </button>
    </div>
  )
}

export default Oops