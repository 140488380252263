import { httpChargebeeApiRoot, httpStripeApiRoot } from "../httpService";

export function getSubScription() {
  // return httpChargebeeApiRoot.get(`/api/Subscription`);
  return httpStripeApiRoot.get(`/Stripe/GetSubscription`);
}

export function getCustomerInvoices() {
  // return httpChargebeeApiRoot.get(`/GetCustomerInvoices`);
  return httpStripeApiRoot.get(`/Stripe/GetAllInvoices`);
}

export function getInvoicesPdf(id) {
  // return httpChargebeeApiRoot.get(`/GetInvoicePdf/${id}`);
  return httpStripeApiRoot.get(`/Stripe/GetInvoicePdf/${id}`);
}

export function addSubScription(reqBody) {
  // return httpChargebeeApiRoot.post(`/api/Subscription`, reqBody);
  // ?intentId=asdasd&customerId=asdfsdf&paymentMethodId=sdfsdf&priceId=sdfsdf'
  // return httpStripeApiRoot.get(
  //   `/Stripe/ConfirmPayment?intentId=${reqBody.intentId}&&customerId=${reqBody.customerId}&&paymentMethodId=${reqBody?.paymentMethodId}&&priceId=${reqBody?.priceId}`
  // );
  return httpStripeApiRoot.post(
    `/Stripe/ConfirmPayment?intentId=${reqBody.intentId}&&paymentMethodId=${reqBody?.paymentMethodId}&&priceId=${reqBody?.priceId}&&isNew=${reqBody.isNew}`
  );
}

export function updateSubScription(reqBody) {
  // return httpChargebeeApiRoot.put(
  //   `/api/Subscription?subscriptionId=${reqBody.subscriptionId}&planId=${reqBody.planId}&reasonForUpdate=${reqBody?.reasonForUpdate}&quantity=${reqBody?.quantity}`
  // );
  return httpStripeApiRoot.get(
    `/Stripe/UpdateSubscription?subscriptionId=${reqBody.subscriptionId}&&newPriceId=${reqBody.newPriceId}&&quantity=${reqBody?.quantity}&&itemId=${reqBody?.itemId}`
  );
}

export function payPendingInvoice(reqBody) {
  return httpStripeApiRoot.post(
    `/Stripe/PayPendingInvoice?invoiceId=${reqBody.invoiceId}`
  );
}

export function downgradeSubscription(reqBody) {
  // return httpChargebeeApiRoot.put(
  //   `/api/Subscription?subscriptionId=${reqBody.subscriptionId}&planId=${reqBody.planId}&reasonForUpdate=${reqBody?.reasonForUpdate}&quantity=${reqBody?.quantity}`
  // );
  return httpStripeApiRoot.get(
    `/Stripe/DowngradeSubscription?subscriptionId=${reqBody.subscriptionId}&&newPriceId=${reqBody.newPriceId}&&quantity=${reqBody?.quantity}`
  );
}

export function getPlan() {
  // return httpChargebeeApiRoot.get(`/api/plan`);
  return httpStripeApiRoot.get(`/Stripe/GetAllPlans`);
}

export function getCardListByCustomerId(reqBody){
    // return httpChargebeeApiRoot.get(`/api/Card/GetAllCards`);
    // return httpStripeApiRoot.get(`/Stripe/GetAllCards?customerId=${reqBody?.customerId}`);
    return httpStripeApiRoot.get(`/Stripe/GetAllCards`);
}

export function getCustomerDetails(reqBody){
  return httpStripeApiRoot.get(`/Stripe/GetCustomerDetails`);
}

export function getStorage(){
  return httpStripeApiRoot.get(`/Stripe/GetStorage`);
}

export function getDiscountOnCurrentPlan(){
  return httpStripeApiRoot.post(`/Stripe/getDiscountOnCurrentPlan`);
}

export function addCard(reqBody){
    // return httpChargebeeApiRoot.post(`/api/Card`, reqBody);
    // return httpStripeApiRoot.post(`/InitiateSubscription`, reqBody);
    // https://companyservice.millipixels.in/InitiateSubscription?token=ajncjkan%60&customerId=nnjkancna&amount=9999&priceId=jnlknmklm&returnUrl=lmlkmklm
    // return httpStripeApiRoot.post(`/Stripe/InitiateSubscription?token=${reqBody?.token}&&customerId=${reqBody?.customerId}&&amount=${reqBody?.amount}&&priceId=${reqBody?.priceId}&&returnUrl=${reqBody?.returnUrl}`);
    return httpStripeApiRoot.post(`/Stripe/InitiateSubscription?token=${reqBody?.token}&&amount=${reqBody?.amount}&&priceId=${reqBody?.priceId}&&returnUrl=${reqBody?.returnUrl}`, reqBody?.requestBody);
}

export function updateCustomer(reqBody){
  return httpStripeApiRoot.post(`/Stripe/UpdateCustomer`, reqBody);
}

export function getCardByCardId(cardId){
  // return httpChargebeeApiRoot.get(`/api/Card/${cardId}`);
  return httpStripeApiRoot.get(`/Stripe/GetCard/?paymentMethodId=${cardId}`);
}

export function updateCard(reqBody){
  // return httpChargebeeApiRoot.put(`/api/Card`, reqBody);
  // return httpStripeApiRoot.post(`/Stripe/UpdateCard?paymentMethodId=${reqBody?.paymentMethodId}&&cvc=${reqBody?.cvc}&&expiryMonth=${reqBody?.expiryMonth}&&expiryYear=${reqBody?.expiryYear}&&number=${reqBody?.number}`);
  return httpStripeApiRoot.post(`/Stripe/UpdateCard?id=${reqBody?.id}&&token=${reqBody?.token}&&returnUrl=${reqBody?.returnUrl}`);
}

export function deleteCard(cardId){
  // return httpChargebeeApiRoot.delete(`/api/Card/${cardId}`);
  return httpStripeApiRoot.delete(`/api/Card/${cardId}`);
}

export function deleteSubScription(subscriptionId){
  // return httpChargebeeApiRoot.delete(`/api/Subscription?subscriptionId=${subscriptionId}`);
  return httpStripeApiRoot.get(`/Stripe/CancelSubscription`);
}

export function cancelUnderReviewSubScription(subscriptionId){
  return httpStripeApiRoot.post(`/Stripe/CancelSubscriptionSchedule`);
}

export function saveReason(reqBody) {
  return httpStripeApiRoot.post(
    `/Company/SaveReason?reason=${reqBody.reason}&&action=${reqBody.action}&&companyId=${reqBody?.companyId}`
  );
}

export function upgradeStorage(reqBody) {
  return httpStripeApiRoot.post(
    `/Stripe/UpgradeStorage?quantity=${reqBody?.quantity}&&seatQuantity=${reqBody?.seatQuantity}`
  );
  // return httpStripeApiRoot.post(
  //   `/Stripe/UpgradeStorage?subscriptionId=${reqBody.subscriptionId}&&quantity=${reqBody.quantity}`
  // );  
}

export function downgradeStorage(reqBody) {
  return httpStripeApiRoot.get(
    `/Stripe/DowngradeStorage?storageToRemove=${reqBody?.quantityToReduce}`
  ); 
}

export function downgradeSeats(reqBody) {
  return httpStripeApiRoot.get(
    `/Stripe/DowngradeSeat?seatToRemove=${reqBody?.quantityToReduce}`
  ); 
}

export function getProrationalInvoice(reqBody) {
  return httpStripeApiRoot.post(
    `/Stripe/getProrationalInvoice?quantity=${reqBody?.quantity}&&seatQuantity=${reqBody?.seatQuantity}`
  );
}