import React, { useState, useEffect, useRef } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import {
  Row,
  Col,
  Container,
  Modal,
  Tooltip,
  Button,
  OverlayTrigger,
} from "react-bootstrap";
import {
  IconStarLg,
  IconStarGray,
  IconPlanUpgrade,
} from "../../../icons/Icons";
import Successful from "../../../assets/images/successful.svg";
import inVoiceEmptyPrimary from "../../../assets/images/inVoiceEmptyPrimary.svg";
import planBg from "../../../assets/images/plan_bg.png";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionAsync, addSubscriptionAsync } from "../../../reducers/subscription-reducers";
import { formatDate, getFormatDate, formatTime, getItemPath } from "../../../common";
import moment from "moment";
import ToasterAlertError from "../../../helper/ToasterAlertError";
// import { useParams } from 'react-router-dom';
import { useHistory, useLocation } from "react-router-dom";
import Loader from "../../../components/Loader/Loader";

const MakePlanSuccess = () => {
    const navigate = useHistory();
    const dispatch = useDispatch();
    const subscriptionData = useSelector((state) => state.subscription);
    // const { setup_intent, setup_intent_client_secret, source_type } = useParams();
    const params = new URLSearchParams(window.location.search);
    const [selectedPlanData, setSelectedPlanData] = useState({});
    const [paymentStatus, setPaymentStatus] = useState(true);
    const [responcePayment, setResponcePayment] = useState({});
    const [downloadInvoice, setDownloadInvoice] = useState("");
    const [cardHolderName, setCardHolderName] = useState("");
    const [amountPaid, setAmountPaid] = useState("");
    const [renewalDate, setRenewalDate] = useState("");
    const [createdDate, setCreatedDate] = useState("");
    const [formDataReady, setFormDataReady] = useState(false);

    const setup_intent = params?.get('setup_intent');
    const dataLoaded = useRef(false);
    // const setup_intent_client_secret = params?.get('setup_intent_client_secret');
    // const source_type = params?.get('source_type');

    useEffect(() => {
      dispatch(getSubscriptionAsync());
    }, [paymentStatus]);
    
    useEffect(() => {
      dispatch(getSubscriptionAsync());
      if (!dataLoaded.current) {
        console.log("Add Subscriptoins")
        dataLoaded.current = true;
        addSubscription();
      }
    }, [setup_intent]);


    useEffect(() => {
      if (subscriptionData?.subscriptionData?.latestInvoice && subscriptionData?.subscriptionData?.subscriptions?.length > 0) {
        setDownloadInvoice(subscriptionData?.subscriptionData?.latestInvoice?.hostedInvoiceUrl);
        setCardHolderName(subscriptionData?.subscriptionData?.latestInvoice?.customerName);
        setAmountPaid(subscriptionData?.subscriptionData?.latestInvoice?.amountPaid/100)
        setRenewalDate(subscriptionData?.subscriptionData?.subscriptions[0].currentPeriodEnd);
        setCreatedDate(subscriptionData?.subscriptionData?.subscriptions[0].created);
      }
    }, [subscriptionData]);

    const addSubscription = async () => {
      const makePaymentDataString = localStorage.getItem('makePaymentData');
      const makePaymentData = JSON.parse(makePaymentDataString);

      const selectedPlanDataString = localStorage.getItem('selectedPlanData');
      const selectedPlan = JSON.parse(selectedPlanDataString);
      setSelectedPlanData(selectedPlan);

      if (setup_intent && makePaymentData?.state?.customerId && makePaymentData?.state?.paymentMethodId && makePaymentData?.state?.priceId) {
        const request = {
          intentId: setup_intent,
          customerId: makePaymentData?.state?.customerId,
          paymentMethodId: makePaymentData?.state?.paymentMethodId,
          priceId: makePaymentData?.state?.priceId,
          isNew: makePaymentData?.state?.isNew,
        }
        setFormDataReady(false);
        try {
          const res = await dispatch(addSubscriptionAsync(request));
          setResponcePayment(res?.payload);
          if (res?.payload?.result === "Success") {
            // dispatch(getSubscriptionAsync());
            await dispatch(getSubscriptionAsync()).then((res) => {
              if (res.payload) {
                setFormDataReady(true);
                localStorage.removeItem('makePaymentData');
                setPaymentStatus(true); 
              }
            });
          } else {
            setPaymentStatus(false);
            setFormDataReady(true);
          }
        } catch (error) {
          console.error('Error during plan purchase:', error);
          ToasterAlertError('An unexpected error occurred. Please try again later.');
          setPaymentStatus(false);
          setFormDataReady(true);
        }

        // dispatch(addSubscriptionAsync(request)).then((res) => {
        //   // console.log(res.payload,'payment responce');
        //   setResponcePayment(res?.payload);
        //   if (res?.payload?.result === "Success") {
        //     localStorage.removeItem('makePaymentData');
        //     setPaymentStatus(true);
        //   } else {
        //     setPaymentStatus(false);
        //   }
        // });
      }
    };

    const goBack = () =>{
      dispatch(getSubscriptionAsync());
      setTimeout(() => {
        navigate.push("/");
      }, 1000);
    }
  
  console.log(subscriptionData,'subscriptionData Payment Successfull');
  console.log(responcePayment,'responcePayment Payment Successfull');
  
  return (
    <div className="pages bg-gray-600">
      <PageHeader title="Account Settings" />
      <div className="pageOuter">
      <Container fluid>
      {!formDataReady && <Loader />}
      {paymentStatus ? (
        <div className="h-100 w-100 d-flex justify-content-center paymentSuccessful">
          <div>
            <div className="text-center">
              <div className="m-auto" style={{width: "20rem"}}>
              <img src={Successful} alt="" />
              <h1 className="pt-20">Payment Successful</h1>
              </div>
              <p className="text-gray-300 mt-2 mb-0">
                Lorem ipsum dolor sit amet. Et molestias ratione est architecto
                <br></br> numquam aut modi odit aut harum quod est laborum.
              </p>
            </div>
            <div className="subcriptionDetailsBox rounded-6 bg-base-100 filterShadow-100 mt-32">
              <h5 className="p-20 border-bottom border-gray-400">
                Your Subscription Details
              </h5>
              <div className="p-20">
                <div className="row">
                  <h6 className="col-6 font-normal text-gray-200">Subscription Plan</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {selectedPlanData?.state?.selectedPlanData?.planName}
                  </p>
                </div>
                <div className="row mt-4">
                  <h6 className="col-6 font-normal text-gray-200">Amount</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {/* $ {selectedPlanData?.state?.selectedPlanData?.price} */}
                    $ {amountPaid}
                  </p>
                </div>
                <div className="row mt-4">
                  <h6 className="col-6 font-normal text-gray-200">Date</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {/* 1/1/2024 */}
                    {createdDate && moment(createdDate).format(
                      "MMM D, YYYY"
                    )}
                    {/* {responcePayment?.created && formatDate(responcePayment?.created)} */}
                  </p>
                </div>
                <div className="row mt-4">
                  <h6 className="col-6 font-normal text-gray-200">Renewal Date</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {/* 1/1/2025 */}
                    {renewalDate && moment(renewalDate).format(
                      "MMM D, YYYY"
                    )}
                    {/* {responcePayment?.currentPeriodEnd && formatDate(responcePayment?.currentPeriodEnd)} */}
                  </p>
                </div>
                {/* <div className="row mt-4">
                  <h6 className="col-6 font-normal">Card Type</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    Visa
                  </p>
                </div> */}
                <div className="row mt-4">
                  <h6 className="col-6 font-normal text-gray-200">Card Holder Name</h6>
                  <p className="col-6 text-lg text-gray-100 m-0 font-medium">
                    {cardHolderName}
                  </p>
                </div>
                {/* <div className="row d-flex justify-content-center">
                  <div className="col-lg-8 rounded-6 borderAll bg-base-100 text-start p-4 mb-32 position-relative">
                    <p className="text-gray-1000 mb-0 text-md">
                      {selectedPlanData?.planType === "Yearly" ? "annually" : "monthly"}
                    </p>
                    <div className="position-absolute end-0 bottom-0">
                      <img src={planBg} alt="" />
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
            <div className="mt-4 bottomBtn">
                <div className="row gx-3">
                  <div className="col-6">
                    <a href="/">
                      <Button className="w-100 m-0" variant="secondary">
                        {/* onClick={() => goBack()} */}
                        Go to Dashboard
                      </Button>
                    </a>
                  </div>
                  <div className="col-6">
                    <a href={downloadInvoice} target="_blank">
                      <Button variant="white" className="m-0 w-100">
                        Download Receipt
                      </Button>
                    </a>
                  </div>
                </div>
              </div>
          </div>
        </div>
      ) : (
        <div className="h-100 w-100 d-flex justify-content-center align-items-center paymentFailed">
          <div className="text-center">
            <img src={inVoiceEmptyPrimary} alt="" />
            <h1 className="pt-20">Payment Failed</h1>
            <p className="text-gray-300 mt-2 mb-0">
              Lorem ipsum dolor sit amet. Et molestias ratione est architecto
              <br></br> numquam aut modi odit aut harum quod est laborum.
            </p>
            <a href="/accountsetting/billingsubscription">
              <Button className="mt-4 m-0" variant="secondary">
                Go to Billing & Subscription
              </Button>
            </a>
          </div>
        </div>
      )}
      </Container>
      </div>      
    </div>
  );
};

export default MakePlanSuccess;