import React from 'react';
import { Switch, Route } from "react-router-dom";
import Users from "./Users";
import UserDetail from "./UserDetail";
const UsersIndex = () => {
    return (
        <>
            <Switch>
                <Route exact path="/users">
                    <Users />
                </Route>
                <Route exact path="/users/edit">
                    <UserDetail />
                </Route>
                <Route exact path="/users/:id">
                    <UserDetail />
                </Route>
            </Switch>
        </>
    );
}

export default UsersIndex;
