import React, { useContext, useState, useEffect } from "react";

import { Row, Col, Container, Modal } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Search from "../../components/Search/Search";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../components/ActivityLog/ActivityLog";
import EmptyState4 from "../../assets/images/EmptyState4.svg";
import EmptyState3 from "../../assets/images/EmptyState3.svg";
import {
  IconArrow,
  IconAddEmployee,
  IconModule2,
  IconSequence2,
  CreateProcess,
  IconReportIssue2,
  IconDashboardMostPopular1,
  IconDashboardMostPopular2,
  IconDashboardMostPopular3,
  IconDashboardMostPopular4,
  IconDashboardMostPopular5,
  IconDashboardMostPopular6,
  MyLearing,
  Alerts,
  SideArrow,
} from "../../icons/Icons";
import Select from "react-select";
import { getFormatDate } from "../../common";
import RadialBar from "./RadialBar";
import BarChart from "./BarChart";
//import { AuthContext } from "../providers/AuthProvider";
import { AuthContext } from "../../providers/authProvider";
import NewJoiningModal from "./NewJoiningModal";
import MostPopularContentModal from "./MostPopularContentModal";
import UnassignedDashboadModal from "./UnassignedDashboadModal";
import OnboardingsStatusModal from "./OnboardingsStatusModal";
import { useDispatch, useSelector } from "react-redux";
import {
  getUserProfileAsync,
  getTeamsAsync,
} from "../../reducers/user-reducers";
import AddUserEmployeeModal from "../Users/AddUserEmployeeModal";
import ModalResponse from "../../components/ModalPopup/ModalResponsePopup";
import UpdatePassword from "./UpdatePassword";
import {
  getEmployeeCountAsync,
  getUnassignedEmployeeCountAsync,
  getAssignmentsStatusCountAsync,
  getMostPopularContentAsync,
  avgOnboardingTimeAsync,
  getTeamWiseOnboardingAsync
} from "../../reducers/dashboard-reducers";
import { getSubscriptionAsync } from "../../reducers/subscription-reducers";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import getInitialsInName from "../../helper/GetNameInitials";
import UserImage from "../../components/UserImage/UserImage";

import EmptyState from "../../components/EmptyState/EmptyState";

import FirstTimePlan from "../../pages/AccountSetting/Billing/FirstTimePlan";
import Loader from "../../components/Loader/Loader";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import SwitchDashboard from "./SwitchDashboard";
import RenewalHeader from "../Authoring/ContentPreview/RenewalHeader"

const Dashboard = () => {
  const authService = useContext(AuthContext);
  const dispatch = useDispatch();
  const userProfile = authService.getUserProfile();
  const [userProfiles, setUserProfiles] = useState(null);
  const [addUserEmployee, setAddUserEmployee] = useState(false);
  const [updatePassword, setUpdatePassword] = useState(false);
  const [responsePopup, setResponsePopup] = useState({});
  const [optionsTeam, setOptionsTeam] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(
    optionsTeam[0] ? optionsTeam[0] : ""
  );
  const teamsList = useSelector((state) => state.user.teams);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [count, setCount] = useState(null);
  const [employeeDetail, setEmployeeDetail] = useState([]);
  const [unassignedCount, setUnassignedCount] = useState(null);
  const [unassignedEmployee, setUnassignedEmployee] = useState([]);
  const [newUserModal, setNewUserModal] = useState(false);
  const [mostPopularContentModal, setMostPopularContentModal] = useState(false);
  const [unassignedDashboadModal, setUnassignedDashboadModal] = useState(false);
  const [onboardingsStatusModal, setOnboardingsStatusModal] = useState(false);
  const [assignmentCount, setAssignmentCount] = useState(null);
  const [mostPopularContent, setMostPopularContent] = useState([]);
  const [avgTime, setAvgTime] = useState();
  const [teamwiseOnboarding, setTeamwiseOnboarding] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);

  const subscriptionData = useSelector((state) => state.subscription);
  useEffect(() => {
    dispatch(getSubscriptionAsync());
  }, []);

  useEffect(() => {
    getUserProfile();
    const teamsReqBody = {};
    if (teamsList.length === 0) {
      dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload?.map((item) => {
            return { value: item.key, label: item.value };
          });

          const allOption = {
            value: 'all',
            label: 'All Teams',
          };
          const optionsWithAll = [allOption, ...teams];
          setOptionsTeam(optionsWithAll);
          setSelectedTeam(optionsWithAll[0]);
        }
      });
    } else {
      if (!!teamsList && teamsList?.length > 0) {
        const teams = teamsList?.map((item) => {
          return { value: item.key, label: item.value };
        });
        const allOption = {
          value: 'all',
          label: 'All Teams',
        };
        const optionsWithAll = [allOption, ...teams];
        setOptionsTeam(optionsWithAll);
        setSelectedTeam(optionsWithAll[0]);
      }
    }
  }, []);

  // console.log(optionsTeam,'optionsTeam');

  useEffect(() => {
    // console.log(currentPage, '123')
    getEmployeeCount();
  }, [selectedDate, selectedTeam, currentPage,pageSize]);

  useEffect(() => {
    getEmployeeCounts();
  }, [selectedDate, setSelectedTeam]);
  const totalOnboardingCount = teamwiseOnboarding.reduce((total, item) => total + item.onboardingCount, 0);


  const getEmployeeCount = () => {
    if (selectedDate === null) {
      return;
    }

    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedYear = selectedDate.getFullYear();

    const reqBody = {
      page:currentPage,
      pageSize:pageSize,
      orderBy: "",
      isOrderByDesc: true,
      searchString: '',
      month: selectedMonth,
      year: selectedYear,
      teamId: selectedTeam?.value && selectedTeam?.value !== 'all' ? selectedTeam?.value : null,
    };

    dispatch(getEmployeeCountAsync(reqBody)).then((res) => {
      if (res.payload) {
        // setCount(res.payload?.employeeTotalCount);
        setCount(res.payload?.allRecordsCount);
        setEmployeeDetail(res.payload);
      }
    });

    dispatch(getUnassignedEmployeeCountAsync(reqBody)).then((res) => {
      if (res.payload) {
        // setUnassignedCount(res.payload?.employeeTotalCount);
        setUnassignedCount(res.payload?.allRecordsCount);
        setUnassignedEmployee(res.payload);
      }
    });

  };
  // console.log(employeeDetail,'000')
  const getEmployeeCounts = () => {
    if (selectedDate === null) {
      return;
    }

    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedYear = selectedDate.getFullYear();

    const reqBody = {
      month: selectedMonth,
      year: selectedYear,
      teamId: selectedTeam?.value && selectedTeam?.value !== 'all' ? selectedTeam?.value : "",
    };

    dispatch(getAssignmentsStatusCountAsync(reqBody)).then((res) => {
      if (res.payload) {
        setAssignmentCount(res.payload);
      }
    });

    dispatch(getMostPopularContentAsync(reqBody)).then((res) => {
      if (res.payload) {
        setMostPopularContent(res.payload);
      }
    });

    dispatch(avgOnboardingTimeAsync(reqBody)).then((res) => {
      if (res.payload) {
        setAvgTime(res.payload);
      }
    });

    dispatch(getTeamWiseOnboardingAsync(reqBody)).then((res) => {
      // console.log(res, "res");
      if (res.payload) {
        setTeamwiseOnboarding(res.payload);
      }
    });
  };

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUpdatePassword(res.payload?.onFirstLogin);
        setUserProfiles(res.payload);
      }
    });
  };

  console.log(subscriptionData, "Dashboard subscriptionData");
  console.log(userProfiles, "userProfiles");

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
  });

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };
  const pendingPercentage = assignmentCount?.pending;
  const completePercentage = assignmentCount?.completed;
  const assignPercentage = assignmentCount?.assigned;

  const availableIcons = [
    <IconDashboardMostPopular1 />,
    <IconDashboardMostPopular2 />,
    <IconDashboardMostPopular3 />,
    <IconDashboardMostPopular4 />,
  ];

  return (
    <>
      <div className="pages">
        {isLoading ? (
          <div className="loader-container bg-white">
            <Loader />
          </div>
        ) : (
          <>
            <RenewalHeader />
            <header className="pageHeader mb-22">
              <Container fluid>
                <Row className="mx-0 align-items-center">
                  <Col className="px-0">
                    <h3>{subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0 ? 'Dashboard' : 'Account Settings'}</h3>
                    {subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0 && (
                      <h6 className="font-normal text-gray-200 pt-1">
                        👋 Welcome to your Dashboard, {userProfiles?.name}!
                      </h6>
                    )}
                  </Col>
                  <Col className="px-0 text-end ">
                    <div className="d-flex gap-3 align-items-center justify-content-end">
                    {userProfiles && (userProfiles?.roleName === "Admin"  || userProfiles?.roleName === "Super Admin") && (
                    <SwitchDashboard userProfiles={userProfiles}/>
                    )}
                      <AccountDropdown />
                      <ActivityLog />
                    </div>
                  </Col>
                </Row>
              </Container>
            </header>
            <div className="pageOuter pageDashboard">
              <Container fluid>
                {subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0 ? (
                  <>
                    <Row className="mb-14 justify-content-end">
                      {/* selectBox */}
                      <Col lg={4} className="" style={{ maxWidth: "22rem" }}>
                        <div className="d-flex dashboadSel mySelect">
                          {/* <Select
                          isSearchable={false}
                          className="select float-end"
                          options={optionsJoinings}
                          defaultValue={[optionsJoinings[0]]}
                          classNamePrefix="selectCustom"
                        /> */}
                          <Select
                            name="teamId"
                            isSearchable={false}
                            className="select"
                            // classNamePrefix="recatMySel"
                            classNamePrefix="selectCustom"
                            options={optionsTeam}
                            // defaultValue={optionsTeam[0]}
                            value={selectedTeam}
                            onChange={(selectedOption) => {
                              setSelectedTeam(selectedOption);
                            }}
                          />
                          {/* <Select
                  isSearchable={false}
                  className="select float-end me-4"
                  options={optionsJoinings}
                  defaultValue={[optionsJoinings[0]]}
                  classNamePrefix="selectCustom"
                /> */}
                          <DatePicker
                            selected={selectedDate}
                            onChange={(date) => setSelectedDate(date)}
                            dateFormat="MMM yyyy"
                            showMonthYearPicker
                            className="calendar-dropdown"
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row className="mb-22">
                      <Col lg={6} className="d-flex">
                        <div className="page flex-grow-1 d-flex column">
                          <Row className="g-0 w-100">
                            <Col lg={5}>
                              <div className="p-20">
                                <h5 className="mb-12">New Joinings</h5>
                                <div className="form-group mySelect topSelect mb-32">
                                  {/* <Select
                          isSearchable={false}
                          className="select  float-start"
                          options={optionsJoinings}
                          defaultValue={[optionsJoinings[0]]}
                        /> */}
                                </div>
                                <div>
                                  <div className="mb-12">
                                    {count !== null ? (
                                      <RadialBar count={count} />
                                    ) : null}
                                  </div>

                                  <p className="h6 text-gray-200 text-center mb-10">
                                    {/* Employees */}
                                    Users
                                  </p>

                                  <div className="text-center">
                                    {/* <span className="mostPopularCount decreaseCount"> */}
                                    <span className={`mostPopularCount m-0 ${employeeDetail?.percentageIncDec && employeeDetail?.percentageIncDec < 0 ? 'decreaseCount' : ''}`}>
                                      <svg
                                        className="me-2"
                                        width="0.625rem"
                                        height="0.625rem"
                                        viewBox="0 0 10 10"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M1 9L9 1"
                                          stroke="#2EB67D"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                        <path
                                          d="M8.99993 5.29339V1H4.70654"
                                          stroke="#2EB67D"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                        />
                                      </svg>
                                      <span className="text-md mb-0 text-secondary-300 font-medium">
                                        {/* 10% */}
                                        {/* {employeeDetail?.percentageIncDec > 0 ? employeeDetail?.percentageIncDec?.toFixed(2) : '0.00' ||
                                  "0.00"} */}
                                        {employeeDetail?.percentageIncDec?.toFixed(2) ||
                                          "0.00"}
                                        {'%'}
                                      </span>
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Col>

                            <Col lg={7} className="d-flex flex-column">
                              <div className="d-flex flex-column flex-grow-1 p-32 bg-gray-600 borderLeft2">
                                {/* <div className='d-flex justify-content-between'> */}
                                <div className="flex-grow-1">
                                  <Row className="h-100">
                                    {employeeDetail?.users
                                      ?.slice(0, 3)
                                      .map((employee, index) => (
                                        <>
                                          <Col lg={12} key={index}>
                                            <div className="d-flex gap-2 align-items-center mb-22">
                                              <div className="headImg">
                                                {/* <img src={AccountImg} alt="" /> */}
                                                {employee.img ? (
                                                  <UserImage
                                                    imgKey={employee.img}
                                                    alt={employee.name}
                                                  />
                                                ) : (
                                                  <div className="">
                                                    <img
                                                      src={getInitialsInName(
                                                        employee.name ?? "N A",
                                                        40
                                                      )}
                                                      alt={employee.name}
                                                    />
                                                  </div>
                                                )}
                                              </div>

                                              <div className="w-100">
                                                <h6 className="font-medium singleLineEc">
                                                  {employee.name}
                                                </h6>
                                                <p className="text-md mb-0 text-gray-300">
                                                  {employee.title}
                                                </p>
                                              </div>
                                            </div>
                                          </Col>
                                        </>
                                      ))}
                                    {employeeDetail?.users?.length === 0 && (

                                      <div className="emptyDivs">
                                        <img src={EmptyState4} alt="EmptyState1" />
                                        <span className="h6 font-normal text-gray-300 p-0 m-0">
                                          New Joinings will be listed here
                                        </span>
                                      </div>
                                    )}
                                  </Row>
                                </div>

                                {/* <div>
                                                    <div className='d-flex gap-2 align-items-center mb-32'>
                                                        <div className='headImg'>
                                                            <img src={AccountImg} alt="" />
                                                        </div>

                                                        <div>
                                                            <h6 className='font-medium'>John Smith</h6>
                                                            <p className='text-md mb-0 text-gray-300'>UI Designer</p>
                                                        </div>

                                                    </div>
                                                    <div className='d-flex gap-2 align-items-center mb-32'>
                                                        <div className='headImg'>
                                                            <img src={AccountImg} alt="" />
                                                        </div>

                                                        <div>
                                                            <h6 className='font-medium'>Robert Wills</h6>
                                                            <p className='text-md mb-0 text-gray-300'>Marketing Head</p>
                                                        </div>

                                                    </div>
                                                    <div className='d-flex gap-2 align-items-center mb-32'>
                                                        <div className='headImg'>
                                                            <img src={AccountImg} alt="" />
                                                        </div>

                                                        <div>
                                                            <h6 className='font-medium'>Philson Roy</h6>
                                                            <p className='text-md mb-0 text-gray-300'>iOS Developer</p>
                                                        </div>

                                                    </div>

                                                </div>
                                            </div> */}
                                {employeeDetail && employeeDetail?.users?.length > 0 && (
                                  <button
                                    onClick={() => setNewUserModal(true)}
                                    className="text-primary-200 font-bold h6 text-start"
                                  >
                                    View All
                                    <span className="ms-1 d-inline-block">
                                      <IconArrow />
                                    </span>
                                  </button>
                                )}
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </Col>

                      <Col lg={4} className="d-flex">
                        <div className="page flex-grow-1 d-flex flex-column">
                          <div className="borderBottom p-18">
                            <h5 className="mb-0">Assignment Status</h5>
                            <div className="form-group mySelect topSelect mb-0 ">
                              {/* <Select
                      isSearchable={false}
                      className="select float-start"
                      options={optionsAssignment}
                      defaultValue={[optionsAssignment[0]]}
                    /> */}
                            </div>
                          </div>

                          <div className="bg-gray-600 p-20 flex-grow-1">
                            <div className="pt-3">
                              <div className="w-100">
                                {/* <div style={{ width: "55%" }}> */}
                                <div className="assignStatusbar d-flex" >
                                  <p className="assignStatusp text-gray-200 text-md">
                                    Completed
                                  </p>
                                  <span className="assignBar bg-gray-400" style={{ width: `${assignmentCount?.completed}%` }}>&nbsp;</span>

                                  <h3 className="font-medium">{assignmentCount?.completed}</h3>

                                </div>
                                <div className="assignStatusbar d-flex" >
                                  <p className="assignStatusp text-gray-200 text-md">
                                    In Progress
                                  </p>
                                  <span className="assignBar bg-secondary-800" style={{ width: `${assignmentCount?.active}%` }}>&nbsp;</span>

                                  <h3 className="font-medium">{assignmentCount?.active}</h3>
                                </div>



                                <div className="assignStatusbar d-flex" >

                                  <p className="assignStatusp text-gray-200 text-md">
                                    Delayed
                                  </p>
                                  <span className="assignBar bg-secondary-400" style={{ width: `${assignmentCount?.delayed}%` }}>
                                    &nbsp;
                                  </span>

                                  <h3 className="font-medium">{assignmentCount?.delayed}</h3>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </Col>

                      <Col lg={2} className="d-flex">
                        <div className="page flex-grow-1">
                          <div className="p-20">
                            <h5 className="mb-19">Quick Links</h5>
                            <div className="mb-19 pb-1 borderBottom d-flex gap-2">
                              <IconAddEmployee />
                              <p className="font-medium text-md mb-0 text-gray-200">
                                <Link
                                  to="/"
                                  className="font-medium text-md mb-0 text-gray-200"
                                  onClick={() => setAddUserEmployee(true)}
                                >
                                  Add user
                                </Link>
                              </p>
                            </div>
                            <div className="mb-19 pb-1 borderBottom d-flex gap-2">
                              <IconModule2 />
                              <p className="font-medium text-md mb-0 text-gray-200">
                                <Link
                                  to={{
                                    pathname: `/authoring/modules/create`,
                                    state: {
                                      operationType: "Add",
                                      redirectTo: "/",
                                    },
                                  }}
                                  className="font-medium text-md mb-0 text-gray-200"
                                >
                                  Create module
                                </Link>
                              </p>
                            </div>
                            <div className="mb-19 pb-1 borderBottom d-flex gap-2">
                              <IconSequence2 />
                              <p className="font-medium text-md mb-0 text-gray-200">
                                <Link
                                  to={{
                                    pathname: `/authoring/sequences/create`,
                                    state: {
                                      operationType: "Add",
                                      redirectTo: "/",
                                    },
                                  }}
                                  className="font-medium text-md mb-0 text-gray-200"
                                >
                                  Create sequence
                                </Link>
                              </p>
                            </div>
                            <div className="mb-19 pb-1 borderBottom d-flex gap-2">
                              <CreateProcess />
                              <p className="font-medium text-md mb-0 text-gray-200">
                                <Link
                                  to={{
                                    pathname: `/authoring/processes/create`,
                                    state: {
                                      operationType: "Add",
                                      redirectTo: "/",
                                    },
                                  }}
                                  className="font-medium text-md mb-0 text-gray-200"
                                >
                                  Create process
                                </Link>
                              </p>
                            </div>
                            <div className="mb-0 d-flex gap-2">
                              <IconReportIssue2 />
                              <p className="font-medium text-md mb-0 text-gray-200">
                                <Link
                                  to={{
                                    pathname: `/reportedIssues`,
                                  }}
                                  className="font-medium text-md mb-0 text-gray-200"
                                >
                                  Reported issues
                                </Link>
                              </p>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={4} className="d-flex">
                        <div className="page flex-grow-1 d-flex column">
                          <div className="p-20 px-0 w-100 d-flex flex-column">
                            <div className="mb-4 px-20 d-flex justify-content-between align-items-center">
                              <h5 className="">
                                Most Popular Content{" "}
                                <span className="font-normal">({mostPopularContent?.length})</span>
                              </h5>
                              <div className="">
                              {/*<button
                                  onClick={() => setMostPopularContentModal(true)}
                                  className="text-primary-200 font-bold h6 text-end"
                                >
                                  View All
                                  <span className="ms-1 d-inline-block">
                                    <IconArrow />
                                  </span>
                                </button>*/}
                              </div>
                            </div>
                            {mostPopularContent?.length > 0 && (
                              <div className=" px-20 mb-12">
                                <div className="d-flex justify-content-start align-items-center pb-3 gap-3">
                                  <div className="d-flex gap-3 conteHeadingh">
                                    <p className="text-md mb-0 text-gray-300">
                                      CONTENT <br></br>TITLE
                                    </p>
                                  </div>
                                  <div className="d-flex justify-content-between align-items-center conteHeadinghNext gap-3">
                                    <p className="text-md mb-0 text-gray-300">
                                    NUMBER <br></br>OF VIEWS
                                    </p>
                                    <p className="text-md mb-0 text-gray-300 text-end">
                                      AVERAGE DAYS <br></br>TO COMPLETE
                                    </p>
                                  </div>
                                </div>
                              </div>
                            )}
                            {mostPopularContent
                              ?.slice(0, 5)
                              .map((content, index) => {
                                const randomIcon = availableIcons[Math.floor(Math.random() * availableIcons.length)];
                                return (
                                  <div key={index} className="borderBottom px-20 mb-12">
                                    <div className="d-flex justify-content-start align-items-center pb-3 gap-3">
                                      <div className="d-flex gap-3 conteHeading">
                                        {randomIcon}
                                        <div className="w-100">
                                          <h6 className="font-medium singleLineEc">
                                            {content.title}
                                          </h6>
                                          <p className="text-gray-300 text-md mb-0">{content.type}</p>
                                        </div>
                                      </div>
                                      <div className="d-flex justify-content-between align-items-center conteHeadingNext gap-3">
                                        <div className="mostPopularCount">{content.noOfViews}</div>

                                        <p className="text-md mb-0 text-end">&lt;
                                          {/* {content.avgDaysToComplete} days */}
                                          {content.avgDaysToComplete.toFixed(1)} {content.avgDaysToComplete.toFixed(1) > 1 ? "days" : "day"}
                                        </p>
                                      </div>

                                    </div>
                                  </div>
                                );
                              })}
                            {mostPopularContent?.length === 0 && (

                              <div className="emptyDivs">
                                <img src={EmptyState3} alt="EmptyState1" />
                                <span className="h6 font-normal text-gray-300 p-0 m-0">
                                  Most Popular Content <br></br>will be listed here
                                </span>
                              </div>
                            )}
                            {/* <div className="borderBottom px-20 mb-12">
                      <div className="d-flex justify-content-between align-items-center pb-3 ">
                        <div className="d-flex gap-3 conteHeading">
                          <IconDashboardMostPopular2 />
                          <div className="w-100">
                            <h6 className="font-medium singleLineEc">UI Design Tool</h6>
                            <p className="text-gray-300 text-md mb-0">
                              Sequence
                            </p>
                          </div>
                        </div>

                        <div className="mostPopularCount">34</div>
                        <p className="text-md mb-0">&lt; 15 days</p>
                      </div>
                    </div>
                    <div className="borderBottom px-20 mb-12">
                      <div className="d-flex justify-content-between align-items-center pb-3 ">
                        <div className="d-flex gap-3 conteHeading">
                          <IconDashboardMostPopular3 />
                          <div className="w-100">
                            <h6 className="font-medium singleLineEc">
                              Understanding React Native
                            </h6>
                            <p className="text-gray-300 text-md mb-0">Module</p>
                          </div>
                        </div>

                        <div className="mostPopularCount">87</div>
                        <p className="text-md mb-0">&lt; 15 days</p>
                      </div>
                    </div>
                    <div className=" px-20">
                      <div className="d-flex justify-content-between align-items-center pb-3 ">
                        <div className="d-flex gap-3 conteHeading">
                          <IconDashboardMostPopular4 />
                          <div className="w-100">
                            <h6 className="font-medium singleLineEc">
                              Introduction to Jira 1
                            </h6>
                            <p className="text-gray-300 text-md mb-0">
                              Sequence
                            </p>
                          </div>
                        </div>

                        <div className="mostPopularCount">159</div>
                        <p className="text-md mb-0">&lt; 15 days</p>
                      </div>
                    </div> */}
                            {/* {mostPopularContent && mostPopularContent?.length > 0 && (
                              <div className="px-20">
                              <button
                                  onClick={() => setMostPopularContentModal(true)}
                                  className="text-primary-200 font-bold h6 text-end mt-2"
                                >
                                  View All
                                  <span className="ms-1 d-inline-block">
                                    <IconArrow />
                                  </span>
                                </button> */}
                                {mostPopularContent && mostPopularContent?.length > 0 && (
                                <div className="px-20">
                                  {mostPopularContent.length < 5 ? (
                                    <button
                                      onClick={() => setMostPopularContentModal(true)}
                                      className="text-primary-200 font-bold h6 text-end mt-2"
                                    >
                                      View Details
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>
                                  ) : (
                                    <button
                                      onClick={() => setMostPopularContentModal(true)}
                                      className="text-primary-200 font-bold h6 text-end mt-2"
                                    >
                                      View All
                                      <span className="ms-1 d-inline-block">
                                        <IconArrow />
                                      </span>
                                    </button>
                                  )}
                              

                                {/*<button
                                  onClick={() => setMostPopularContentModal(true)}
                                  className="text-primary-200 font-bold h6 text-end mt-2"
                                >
                                  View Details
                                  <span className="ms-1 d-inline-block">
                                    <IconArrow />
                                  </span>
                                </button>*/}
                              </div>
                            )}
                          </div>
                        </div>
                      </Col>

                      <Col lg={4} className="d-flex">
                        <div className="page flex-grow-1 d-flex column">
                          <div className="py-20 w-100 d-flex flex-column">
                            <div className="d-flex justify-content-between align-items-center mb-4 px-20">
                              <h5 className="pb-0">
                              Employees Without Assignment{" "}
                                <span className="font-normal">({unassignedCount})</span>
                              </h5>
                              <div className="">
                                {/*<button
                                  onClick={() => setUnassignedDashboadModal(true)}
                                  className="text-primary-200 font-bold h6 text-end"
                                >
                                  View All
                                  <span className="ms-1 d-inline-block">
                                    <IconArrow />
                                  </span>
                                </button>*/}
                              </div>
                            </div>
                            {unassignedEmployee && unassignedEmployee?.users?.length > 0 && (
                              <div className="d-flex justify-content-between mt-0 my-3 pb-3 px-20">
                                <p className="text-md mb-0 text-gray-300">NAME</p>
                                <p className="text-md mb-0 text-gray-300"> JOINING DATE</p>
                              </div>
                            )}

                            {unassignedEmployee?.users
                              ?.filter(emp => emp.role === "Employee" || emp.role === "Admin")
                              .slice(0, 5)
                              .map(emp => (
                                <div key={emp.id} className="borderBottom mb-12 px-20">
                                  <div className="d-flex justify-content-between align-items-center pb-3">
                                    <div className="d-flex me-2 align-items-center min-100">
                                      <div className="headImg me-2">
                                        {emp.img ? (
                                          <UserImage imgKey={emp.img} alt={emp.name} />
                                        ) : (
                                          <div>
                                            <img
                                              src={getInitialsInName(emp.name ?? "N A", 40)}
                                              alt={emp.name}
                                            />
                                          </div>
                                        )}
                                      </div>
                                      <div className="w-100">
                                        <h6 className="singleLineEc font-medium singleLineEc onEmpName">{emp.name}</h6>
                                        <p className="text-md mb-0 text-gray-300 onEmpName singleLineEc">{emp.title}</p>
                                      </div>
                                    </div>
                                    <div className="">
                                      <p className="text-md mb-0">
                                        {getFormatDate(emp.joiningDate)}
                                        {/* <DateTimeConverter utcDateTime={emp.joiningDate} /> */}
                                      </p>
                                    </div>
                                  </div>

                                </div>
                              ))}

                            {/* <div className="borderBottom mb-12">
                      <div className=" px-20 d-flex justify-content-between align-items-center pb-3">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="headImg">
                            <img src={AccountImg} alt="" />
                          </div>

                          <div>
                            <h6 className="font-medium">John Smith</h6>
                            <p className="text-md mb-0 text-gray-300">
                              UI Designer
                            </p>
                          </div>
                        </div>

                        <div>
                          <p className="text-md mb-0">Jul 20, 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="borderBottom mb-12">
                      <div className=" px-20 d-flex justify-content-between align-items-center pb-3">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="headImg">
                            <img src={AccountImg} alt="" />
                          </div>

                          <div>
                            <h6 className="font-medium">John Smith</h6>
                            <p className="text-md mb-0 text-gray-300">
                              UI Designer
                            </p>
                          </div>
                        </div>

                        <div>
                          <p className="text-md mb-0">Jul 20, 2023</p>
                        </div>
                      </div>
                    </div>
                    <div className="mb-1">
                      <div className=" px-20 d-flex justify-content-between align-items-center pb-3">
                        <div className="d-flex gap-2 align-items-center">
                          <div className="headImg">
                            <img src={AccountImg} alt="" />
                          </div>

                          <div>
                            <h6 className="font-medium">John Smith</h6>
                            <p className="text-md mb-0 text-gray-300">
                              UI Designer
                            </p>
                          </div>
                        </div>

                        <div>
                          <p className="text-md mb-0">Jul 20, 2023</p>
                        </div>
                      </div>
                    </div> */}
                    {/* {unassignedEmployee && unassignedEmployee?.users?.length > 0 ? (
                                <div className="px-20">
                                <button
                                  onClick={() => setUnassignedDashboadModal(true)}
                                  className="text-primary-200 font-bold h6 text-end mt-2"
                                >
                                  View All
                                  <span className="ms-1 d-inline-block">
                                    <IconArrow />
                                  </span>
                                </button> */}
                                {/*<button
                                  onClick={() => setUnassignedDashboadModal(true)}
                                  className="text-primary-200 font-bold h6 text-end"
                                >
                                  View Details
                                  <span className="ms-1 d-inline-block">
                                    <IconArrow />
                                  </span>
                                </button>*/}
                              {/* </div>
                            ) : (

                              <div className="emptyDivs">
                                <img src={EmptyState3} alt="EmptyState1" />
                                <span className="h6 font-normal text-gray-300 p-0 m-0">
                                  Not assigned with any content will be listed here
                                </span>
                              </div>

                            )} */}
                       
                            {unassignedEmployee && unassignedEmployee?.users?.length > 0 ? (
                                  <div className="px-20">
                                    {unassignedEmployee.users.length < 5 ? (
                                      <button
                                        onClick={() => setUnassignedDashboadModal(true)}
                                        className="text-primary-200 font-bold h6 text-end mt-2"
                                      >
                                        View Details
                                        <span className="ms-1 d-inline-block">
                                          <IconArrow />
                                        </span>
                                      </button>
                                    ) : (
                                      <button
                                        onClick={() => setUnassignedDashboadModal(true)}
                                        className="text-primary-200 font-bold h6 text-end mt-2"
                                      >
                                        View All
                                        <span className="ms-1 d-inline-block">
                                          <IconArrow />
                                        </span>
                                      </button>
                                    )}
                                  </div>
                                  ) :  <div className="emptyDivs">
                                  <img src={EmptyState3} alt="EmptyState1" />
                                  <span className="h6 font-normal text-gray-300 p-0 m-0">
                                  Employee without  any assignment will be listed here
                                  </span>
                                </div>
                              }
                          </div>
                        </div>
                      </Col>

                      <Col lg={4} className="d-flex flex-column">
                        {/*<div className="mb-4">
                  <div className="rounded-6 boxShadow2 p-20 bg-gradient-100 text-base-100 dashboardBg">
                    <div className="d-flex justify-content-between align-items-center mb-20">
                      <h1 className="pipeSepration">
                      {typeof avgTime === 'number' ? avgTime.toFixed(1) + ' days' : 'N/A'}
                      </h1>
                      <div>
                        <svg
                          width="40"
                          height="40"
                          viewBox="0 0 40 40"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <circle cx="20" cy="20" r="20" fill="#E3828D" />
                          <path
                            d="M27.5 28.9583H12.5C12.1583 28.9583 11.875 28.675 11.875 28.3333C11.875 27.9917 12.1583 27.7083 12.5 27.7083H27.5C27.8417 27.7083 28.125 27.9917 28.125 28.3333C28.125 28.675 27.8417 28.9583 27.5 28.9583Z"
                            fill="white"
                          />
                          <path
                            d="M14.6666 26.4583H13.3333C12.5333 26.4583 11.875 25.8 11.875 25V17.8167C11.875 17.0167 12.5333 16.3583 13.3333 16.3583H14.6666C15.4666 16.3583 16.125 17.0167 16.125 17.8167V25C16.125 25.8 15.4666 26.4583 14.6666 26.4583ZM13.3333 17.6C13.2167 17.6 13.125 17.6917 13.125 17.8083V25C13.125 25.1167 13.2167 25.2083 13.3333 25.2083H14.6666C14.7833 25.2083 14.875 25.1167 14.875 25V17.8167C14.875 17.7 14.7833 17.6083 14.6666 17.6083H13.3333V17.6Z"
                            fill="white"
                          />
                          <path
                            d="M20.6668 26.4583H19.3335C18.5335 26.4583 17.8752 25.8 17.8752 25V15.1583C17.8752 14.3583 18.5335 13.7 19.3335 13.7H20.6668C21.4668 13.7 22.1251 14.3583 22.1251 15.1583V25C22.1251 25.8 21.4668 26.4583 20.6668 26.4583ZM19.3335 14.95C19.2168 14.95 19.1252 15.0417 19.1252 15.1583V25C19.1252 25.1167 19.2168 25.2083 19.3335 25.2083H20.6668C20.7835 25.2083 20.8751 25.1167 20.8751 25V15.1583C20.8751 15.0417 20.7835 14.95 20.6668 14.95H19.3335Z"
                            fill="white"
                          />
                          <path
                            d="M26.6666 26.4583H25.3333C24.5333 26.4583 23.8749 25.8 23.8749 25V12.5C23.8749 11.7 24.5333 11.0417 25.3333 11.0417H26.6666C27.4666 11.0417 28.1249 11.7 28.1249 12.5V25C28.1249 25.8 27.4666 26.4583 26.6666 26.4583ZM25.3333 12.2917C25.2166 12.2917 25.1249 12.3833 25.1249 12.5V25C25.1249 25.1167 25.2166 25.2083 25.3333 25.2083H26.6666C26.7832 25.2083 26.8749 25.1167 26.8749 25V12.5C26.8749 12.3833 26.7832 12.2917 26.6666 12.2917H25.3333Z"
                            fill="white"
                          />
                        </svg>
                      </div>
                    </div>
                    <p className="h6 w-75">
                      Average time taken to Complete Onboarding
                    </p>
                  </div>
                    </div>*/}
                        {typeof avgTime === 'number' ? (
                          <div className="mb-4">
                            <div className="rounded-6 boxShadow2 p-20 bg-gradient-100 text-base-100 dashboardBg">
                              <div className="d-flex justify-content-between align-items-center mb-20">
                                <h1 className="pipeSepration">
                                  {/* {avgTime.toFixed(1)} days */}
                                  {typeof avgTime === 'number' ? avgTime.toFixed(1) + ' days' : 'N/A'}
                                </h1>
                                <div>
                                  <svg
                                    width="40"
                                    height="40"
                                    viewBox="0 0 40 40"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                  >
                                    <circle cx="20" cy="20" r="20" fill="#E3828D" />
                                    <path
                                      d="M27.5 28.9583H12.5C12.1583 28.9583 11.875 28.675 11.875 28.3333C11.875 27.9917 12.1583 27.7083 12.5 27.7083H27.5C27.8417 27.7083 28.125 27.9917 28.125 28.3333C28.125 28.675 27.8417 28.9583 27.5 28.9583Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M14.6666 26.4583H13.3333C12.5333 26.4583 11.875 25.8 11.875 25V17.8167C11.875 17.0167 12.5333 16.3583 13.3333 16.3583H14.6666C15.4666 16.3583 16.125 17.0167 16.125 17.8167V25C16.125 25.8 15.4666 26.4583 14.6666 26.4583ZM13.3333 17.6C13.2167 17.6 13.125 17.6917 13.125 17.8083V25C13.125 25.1167 13.2167 25.2083 13.3333 25.2083H14.6666C14.7833 25.2083 14.875 25.1167 14.875 25V17.8167C14.875 17.7 14.7833 17.6083 14.6666 17.6083H13.3333V17.6Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M20.6668 26.4583H19.3335C18.5335 26.4583 17.8752 25.8 17.8752 25V15.1583C17.8752 14.3583 18.5335 13.7 19.3335 13.7H20.6668C21.4668 13.7 22.1251 14.3583 22.1251 15.1583V25C22.1251 25.8 21.4668 26.4583 20.6668 26.4583ZM19.3335 14.95C19.2168 14.95 19.1252 15.0417 19.1252 15.1583V25C19.1252 25.1167 19.2168 25.2083 19.3335 25.2083H20.6668C20.7835 25.2083 20.8751 25.1167 20.8751 25V15.1583C20.8751 15.0417 20.7835 14.95 20.6668 14.95H19.3335Z"
                                      fill="white"
                                    />
                                    <path
                                      d="M26.6666 26.4583H25.3333C24.5333 26.4583 23.8749 25.8 23.8749 25V12.5C23.8749 11.7 24.5333 11.0417 25.3333 11.0417H26.6666C27.4666 11.0417 28.1249 11.7 28.1249 12.5V25C28.1249 25.8 27.4666 26.4583 26.6666 26.4583ZM25.3333 12.2917C25.2166 12.2917 25.1249 12.3833 25.1249 12.5V25C25.1249 25.1167 25.2166 25.2083 25.3333 25.2083H26.6666C26.7832 25.2083 26.8749 25.1167 26.8749 25V12.5C26.8749 12.3833 26.7832 12.2917 26.6666 12.2917H25.3333Z"
                                      fill="white"
                                    />
                                  </svg>
                                </div>
                              </div>
                              <p className="h6 w-75">
                                Average time taken to Complete Onboarding
                              </p>
                            </div>
                          </div>
                        ) : (
                          <div className="mb-4">
                            <div className="rounded-6 boxShadow2 border border-gray-400 p-20 bg-pastel-800 text-base-100 dashboardBg">
                              <div className="d-flex justify-content-between align-items-center mb-20">
                                <h1 className="pipeSepration text-gray-300 averageTime">
                                  0 days
                                </h1>
                                <div className="bg-base-100 d-flex justify-content-center align-items-center" style={{height: "2.5rem", width: "2.5rem", borderRadius: "50%"}}>
                                  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <g id="Icons/Stroke/Chart">
                                      <path id="Vector" d="M17.5 18.958H2.5C2.15833 18.958 1.875 18.6747 1.875 18.333C1.875 17.9913 2.15833 17.708 2.5 17.708H17.5C17.8417 17.708 18.125 17.9913 18.125 18.333C18.125 18.6747 17.8417 18.958 17.5 18.958Z" fill="#D9D9D9" />
                                      <path id="Vector_2" d="M4.66665 16.4584H3.33333C2.53333 16.4584 1.875 15.8001 1.875 15.0001V7.81673C1.875 7.01673 2.53333 6.3584 3.33333 6.3584H4.66665C5.46665 6.3584 6.12498 7.01673 6.12498 7.81673V15.0001C6.12498 15.8001 5.46665 16.4584 4.66665 16.4584ZM3.33333 7.60006C3.21667 7.60006 3.125 7.69172 3.125 7.80839V15.0001C3.125 15.1167 3.21667 15.2084 3.33333 15.2084H4.66665C4.78331 15.2084 4.87498 15.1167 4.87498 15.0001V7.81673C4.87498 7.70007 4.78331 7.6084 4.66665 7.6084H3.33333V7.60006Z" fill="#D9D9D9" />
                                      <path id="Vector_3" d="M10.6666 16.4585H9.33333C8.53333 16.4585 7.875 15.8002 7.875 15.0002V5.15853C7.875 4.35853 8.53333 3.7002 9.33333 3.7002H10.6666C11.4666 3.7002 12.125 4.35853 12.125 5.15853V15.0002C12.125 15.8002 11.4666 16.4585 10.6666 16.4585ZM9.33333 4.9502C9.21667 4.9502 9.125 5.04186 9.125 5.15853V15.0002C9.125 15.1169 9.21667 15.2085 9.33333 15.2085H10.6666C10.7833 15.2085 10.875 15.1169 10.875 15.0002V5.15853C10.875 5.04186 10.7833 4.9502 10.6666 4.9502H9.33333Z" fill="#D9D9D9" />
                                      <path id="Vector_4" d="M16.6666 16.4587H15.3333C14.5333 16.4587 13.875 15.8003 13.875 15.0003V2.50033C13.875 1.70033 14.5333 1.04199 15.3333 1.04199H16.6666C17.4666 1.04199 18.125 1.70033 18.125 2.50033V15.0003C18.125 15.8003 17.4666 16.4587 16.6666 16.4587ZM15.3333 2.29199C15.2167 2.29199 15.125 2.38366 15.125 2.50033V15.0003C15.125 15.117 15.2167 15.2087 15.3333 15.2087H16.6666C16.7833 15.2087 16.875 15.117 16.875 15.0003V2.50033C16.875 2.38366 16.7833 2.29199 16.6666 2.29199H15.3333Z" fill="#D9D9D9" />
                                    </g>
                                  </svg>

                                </div>
                              </div>
                              <p className="h6 w-75 text-gray-300">
                                Average time taken to Complete Onboarding
                              </p>
                            </div>
                          </div>
                        )}
                        <div className="page ">
                          <div className="p-20">
                            <div className="d-flex justify-content-between align-items-center">
                              <h5 className="mb-0">
                              Team Wise Onboarding Status{" "}
                                <span className="font-normal">({teamwiseOnboarding?.length})</span>
                              </h5>
                              {teamwiseOnboarding?.length > 0 && (
                                <div className="viewBtn">
                                  <button
                                    onClick={() => setOnboardingsStatusModal(true)}
                                    className="text-primary-200 font-bold h6 text-end"
                                  >
                                    View All
                                    <span className="ms-1 d-inline-block">
                                      <IconArrow />
                                    </span>
                                  </button>
                                </div>
                              )}
                            </div>

                            {teamwiseOnboarding?.length === 0 ? (

                              <div className="emptyDivs mt-5">
                                <img src={EmptyState3} alt="EmptyState1" />
                                <span className="h6 font-normal text-gray-300 p-0 m-0">
                                  Team wise onboarding status will be listed here
                                  {/* Most Popular Content <br></br>will be listed here */}
                                </span>
                              </div>
                            ) : (
                              <BarChart teamwiseOnboarding={teamwiseOnboarding} />
                            )}
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </>
                ) : (
                  <>
                    {userProfiles?.roleName !== "Admin" && (
                      <FirstTimePlan />
                    )}
                  </>
                )}
              </Container>
            </div>
          </>
        )}
      </div>

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={newUserModal}
        onHide={() => setNewUserModal(false)}
      >
        <NewJoiningModal
          handleModal={setNewUserModal}
          employeeDetail={employeeDetail}
          designOption={optionsTeam}
          selectedDesign={selectedTeam}
          selectedDate={selectedDate}
          setCurrentPage={setCurrentPage}
          currentPage={currentPage}
          pageSize={pageSize}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={mostPopularContentModal}
        onHide={() => setMostPopularContentModal(false)}
      >
        <MostPopularContentModal handleModal={setMostPopularContentModal} mostPopularContent={mostPopularContent} />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={unassignedDashboadModal}
        onHide={() => setUnassignedDashboadModal(false)}
      >
        <UnassignedDashboadModal
          handleModal={setUnassignedDashboadModal}
          unassignedEmployee={unassignedEmployee}
          unassignedCount={unassignedCount}
          designOption={optionsTeam}
          selectedDesign={selectedTeam}
          selectedDate={selectedDate}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={onboardingsStatusModal}
        onHide={() => setOnboardingsStatusModal(false)}
      >
        <OnboardingsStatusModal handleModal={setOnboardingsStatusModal} teamwiseOnboarding={teamwiseOnboarding} totalOnboardingCount={teamwiseOnboarding?.length} />
      </Modal>

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={addUserEmployee}
      >
        <AddUserEmployeeModal
          handleModal={setAddUserEmployee}
          onClose={() => setAddUserEmployee(false)}
        />
      </Modal>

      <Modal
        className="uPass"
        size="md"
        centered
        show={updatePassword}
      >
        {updatePassword && (
          <UpdatePassword
            onClose={setUpdatePassword}
            isPasswordNotSet={userProfile?.isPasswordNotSet}
            oldPasswords={userProfiles?.password}
          />
        )}
      </Modal>
    </>
  );
};

export default Dashboard;
