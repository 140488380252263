import React, { useState, useRef, useEffect } from "react";
import { IconleftArrow, IconEdit3 } from "../../../icons/Icons";
import visaCard from "../../../assets/images/visaCard.png";
import MasterCard from "../../../assets/images/masterCard.png";
import {
  Row,
  Col,
  Container,
  Modal,
  Tooltip,
  Button,
  OverlayTrigger,
} from "react-bootstrap";

import { Switch, Route, Redirect, Link, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  countryList,
  convertToGB,
} from "../../../common";

import {
  getCardListByCustomerIdAsync,
  getCustomerDetailsAsync,
  getPlansAsync,
  getSubscriptionAsync,
  updateSubscriptionAsync,
  payPendingInvoiceAsync,
} from "../../../reducers/subscription-reducers";
import EditAddressModal from "../../../components/PaymentCard/EditAddressModal";
import EditPaymentModal from "../../../components/PaymentCard/EditPaymentModal";
import RenewalModal from "./RenewalModal";

const PaymentDetailsModal = ({ handleModal }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { plansData, isRenewalLoading, isDowngradeLoading } = useSelector(
    (state) => state.subscription
  );
  const subscriptionData = useSelector((state) => state.subscription);

  const [customerDetails, setCustomerDetails] = useState([]);
  const [cardId, setCardId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [cardListData, setCardListData] = useState([]);

  const [editAddress, setEditAddress] = useState(false);
  const [editPayment, setEditPayment] = useState(false);
  const [fullAddress, setFullAddress] = useState("");
  const [renewalPlanData, setRenewalPlanData] = useState({});
  const [isConfirmed, setIsConfirmed] = useState(false);
  const [isPlanRenewal, setIsPlanRenewal] = useState(false);

  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedItemId, setSelectedItemId] = useState("");
  const [invoiceId, setInvoiceId] = useState("");

  useEffect(() => {
    dispatch(getPlansAsync());
    dispatch(getSubscriptionAsync());
    getCardList();
    getCustomerDetails();
  }, []);

  useEffect(() => {
    getCustomerDetails();
  }, [editAddress]);

  useEffect(() => {
    console.log(subscriptionData,'subscriptionData Make Payment');
    if (subscriptionData?.subscriptionData?.plans?.length > 0) {
      setSelectedPlanId(subscriptionData?.subscriptionData?.plans[0]?.priceId);
      setSelectedItemId(subscriptionData?.subscriptionData?.plans[0]?.planId);
      setRenewalPlanData(subscriptionData?.subscriptionData?.plans[0]);
      setInvoiceId(subscriptionData?.subscriptionData?.latestInvoice?.id);
    }
  }, [subscriptionData]);


  let fullAddresss = '';
  useEffect(() => {
    // console.log(countryList,'countryList');
    let cntryName = '';
    if (customerDetails?.address?.country) {
      cntryName = countryList.find(
        (country) => country.code === customerDetails?.address?.country?.toUpperCase()
      );
      // setCountryName(cntryName && cntryName?.name);
      // console.log(cntryName,'cntryName');
    }
    fullAddresss = `${customerDetails?.address?.line1 || ''}, ${customerDetails?.address?.line2 || ''}, ${customerDetails?.address?.city || ''}, ${customerDetails?.address?.postalCode || ''}, ${customerDetails?.address?.state || ''}, ${customerDetails?.address?.country || ''}, ${cntryName?.name || ''}`;
    // console.log(fullAddresss,'fullAddress');
    setFullAddress(fullAddresss);
  }, [customerDetails, countryList]);

  useEffect(() => {
    if (isPlanRenewal) {
      if (!isRenewalLoading) {
        history.push({
          pathname: "/accountsetting/billingsubscription/renewalplansuccess",
          // state: { selectedPlanData: selectedPlanData },
        });
      }
    }
  }, [
    isPlanRenewal,
    isRenewalLoading,
    history,
  ]);

  const getCustomerDetails = async () => {
    const reqBody = {
      customerId: "cus_PHZcTbZGclpDJ2",
    };
    await dispatch(getCustomerDetailsAsync(reqBody)).then((res) => {
      if (res.payload) {
        setCustomerDetails(res.payload);
      }
    });
  };

  const getCardList = async () => {
    const reqBody = {
      customerId: "cus_PHZcTbZGclpDJ2",
    };
    await dispatch(getCardListByCustomerIdAsync(reqBody)).then((res) => {
      if (res.payload) {
        // console.log(res.payload,'getCardListByCustomerIdAsync');
        setCardListData(res.payload);
      }
    });
  };

  const handleEdit = (id) => {
    //  console.log("handleEdit", id);
    setCardId(id);
    setEditPayment(true);
  };

  const handleEditAddress = () => {
    setEditAddress(true);
  };

  const handleRenewalPlan = async () => {
    const request = {
      invoiceId: invoiceId ?? "",
    };

    await dispatch(payPendingInvoiceAsync(request)).then((res) => {
      if (res.payload) {
        console.log(res.payload,'payPendingInvoiceAsync');
        setIsPlanRenewal(true);
        setIsConfirmed(false);
      }
    });
  };

  console.log(renewalPlanData,'renewalPlanDataaaa');

  return (
    <>
      <Modal.Header closeButton>
        <div className="w-100 d-flex justify-content-start align-items-center">
          <button>
            <IconleftArrow />
          </button>
          <div className="ms-2">
            <Modal.Title>Payment Details</Modal.Title>
            <p className="mb-0 mt-1 text-lg text-gray-300 font-normal">
              Complete your payment and fill in the information.
            </p>
          </div>
        </div>
      </Modal.Header>
      <Modal.Body className="p-0">
        <Row className="gx-0">
          <Col sm={8}>
            <div className="leftArea w-100 h-100">

            {cardListData && cardListData?.length > 0 && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-gray-100 font-bold">Payment Method</h6>
                  <button 
                    className="text-primary-200 d-flex align-items-center gap-2 text-lg font-bold"
                    onClick={() =>  handleEdit(cardListData[0]?.id)}
                  >
                    <IconEdit3 />
                    <span>Edit</span>
                  </button>
                </div>
                <div className="bg-pastel-900 rounded-6 py-20 px-20 mt-12 d-flex justify-content-start align-items-center">
                  {cardListData[0]?.card?.brand === 1 && <img src={visaCard} className="ms-1" />}
                  {cardListData[0]?.card?.brand === "Mastercard" && (
                    <img src={MasterCard} alt="CardMaster" className="ms-1" />
                  )}
                  {cardListData[0]?.card?.brand === "visa" && <img src={visaCard} className="ms-1" />}
                  <div className="ms-3">
                    <h5 className="font-bold text-gray-100">
                      Card Ending **-{cardListData[0]?.card?.last4}
                    </h5>
                    <p className="text-lg text-gray-300 font-normal mb-0 mt-1">
                      Expires{" "}
                      <span className="text-gray-100 font-medium">
                        {`${cardListData[0]?.card?.expMonth}/${cardListData[0]?.card?.expYear}`}
                      </span>
                    </p>
                  </div>
                </div>
              </>
            )}

            {customerDetails && customerDetails?.address && (
              <>
                <div className="d-flex justify-content-between align-items-center">
                  <h6 className="text-gray-100 font-bold mt-4 mb-12">
                    Billing Address
                  </h6>
                  <button 
                    className="text-primary-200 d-flex align-items-center gap-2 text-lg font-bold"
                    onClick={() =>  handleEditAddress()}
                  >
                    <IconEdit3 />
                    <span>Edit</span>
                  </button>
                </div>
                <div className="input-group myInput w-100 mw-100">
                  <label> Address Line 1</label>
                  <textarea
                    rows="4"
                    className="bg-gray-600 w-100"
                    placeholder="Address line 1"
                    value={fullAddress}
                    disabled
                  ></textarea>
                </div>
              </>
            )}
              {/* <div className="input-group myInput w-100 mw-100 mt-12">
                <div className="col-sm-6">
                  <label>Contact Number</label>
                  <input
                    className="input form-control bg-gray-600"
                    placeholder="Contact number"
                    value="+91 74104 10123"
                    disabled
                  />
                </div>
              </div> */}

              <h6 className="text-gray-100 font-bold mt-4 mb-12">
                Billing Cycle
              </h6>
              <div className="planBox">
                <div className="singlePlan d-flex flex-column active">
                  <div>
                    <p className="text-md text-uppercase text-base-200 opacity-75 font-normal mb-0">
                    {renewalPlanData?.planType} plan
                    </p>
                  </div>
                  <h2 className="mt-auto text-base-200 font-normal d-flex justify-content-start align-items-center">
                    ${renewalPlanData?.price}
                    <span className="text-md opacity-75 ms-1 dottedBorderBottom">
                      Per {renewalPlanData?.planType === "Yearly" ? "year " : "month"}.
                    </span>
                  </h2>
                </div>
                {/* <div className="singlePlan d-flex flex-column">
                  <div>
                    <p className="text-md text-uppercase text-base-200 opacity-75 font-normal mb-0">
                      Annual Plan
                    </p>
                    <span className="py-1 px-2 text-xs rounded-pill text-base-100 bg-gray-1400 font-bold ">
                      12mo for the price of 10
                    </span>
                  </div>
                  <h2 className="mt-auto text-base-200 font-normal d-flex justify-content-start align-items-center">
                    $759.99
                    <span className="text-md opacity-75 ms-1 dottedBorderBottom">
                      Per month. Billed annually
                    </span>
                  </h2>
                </div> */}
              </div>
              <div className="mt-4 d-flex justify-content-between align-items-center">
                <div>
                  <p className="text-md text-base-200 opacity-75 text-uppercase font-medium mb-2">
                    Total Due
                  </p>
                  <h2 className="mt-auto text-base-200 font-bold d-flex justify-content-start align-items-center">
                    $ {renewalPlanData?.price}
                    <span className="text-md opacity-75 ms-1 dottedBorderBottom font-normal">
                      Per {renewalPlanData?.planType === "Yearly" ? "year " : "month"}..
                    </span>
                  </h2>
                </div>
                <div className="text-end">
                  <p className="text-lg text-gray-300 font-medium mb-2">
                    Powered by
                  </p>
                  <h2 className="text-secondary-500 font-bold">stripe</h2>
                </div>
              </div>
              
            </div>
          </Col>
          <Col sm={4}>
            <div className="rightArea w-100 h-100">
              <div className="p-32 rounded-6 bg-base-100 filterShadow-100">
                <h2 className="text-base-200 font-extraBold">{renewalPlanData?.planName}</h2>
                <div className="text-md pt-32 text-base-200 pt-">
                  <span className="font-bold text-uppercase d-block">
                    SEATS
                  </span>
                  <span className="d-block font-normal">5 seats</span>
                </div>
                <div className="text-md pt-20 text-base-200">
                  <span className="font-bold text-uppercase d-block">
                    STORAGE
                  </span>
                  <span className="d-block font-normal">
                    {convertToGB(renewalPlanData?.metaData?.storage)}{" "}
                    <label className="text-gray-1200 opacity-75">
                      per seat
                    </label>
                  </span>
                </div>
                <div className="text-md pt-20 text-base-200">
                  <span className="font-bold text-uppercase d-block">
                    Domain & branding
                  </span>
                  <span className="d-block font-normal">
                    {renewalPlanData?.planName === "Business" ? (
                      <>
                        {renewalPlanData?.metaData?.custom_branding === "Yes" ? 'Pro' : 'No'}
                      </>
                    ) : (
                      <>
                        {renewalPlanData?.metaData?.custom_branding === "Yes" ? 'Basic' : 'No'}
                      </>
                    )}
                  </span>
                </div>
                <div className="text-md pt-20 text-base-200">
                  <span className="font-bold text-uppercase d-block">
                    Content
                  </span>
                  <span className="d-block font-normal">
                    {renewalPlanData?.metaData?.process === "-1" ? "Unlimited" : renewalPlanData?.metaData?.process} Processes, {renewalPlanData?.metaData?.sequence === "-1" ? "Unlimited" : renewalPlanData?.metaData?.sequence} Sequences & {renewalPlanData?.metaData?.modules === "-1" ? "Unlimited" : renewalPlanData?.metaData?.modules} Modules
                  </span>
                </div>
                <div className="text-md pt-20 text-base-200">
                  <span className="font-bold text-uppercase d-block">
                    HELP and support
                  </span>
                  <span className="d-block font-normal">
                    2 Business Day turnaround
                  </span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer className="justify-content-start btnFooter">
        <Button
          className="m-0"
          variant="secondary"
          onClick={() =>  setIsConfirmed(true)}
        >
          Confirm
        </Button>
      </Modal.Footer>
      <Modal
        className="come-from-modal right editPaymentcard"
        centered
        show={editAddress}
        onHide={() => setEditAddress(false)}
      >
        <EditAddressModal
          handleModal={setEditAddress}
          editAddress={editAddress}
        />
      </Modal>
      <Modal
        className="come-from-modal right editPaymentcard"
        centered
        show={editPayment}
        onHide={() => setEditPayment(false)}
      >
        <EditPaymentModal
          handleModal={setEditPayment}
          editPayment={editPayment}
          cardId={cardId}
          setCardId={setCardId}
        />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isConfirmed}
        onHide={() => setIsConfirmed(false)}
      >
        <RenewalModal
          handleModal={setIsConfirmed}
          confirm={handleRenewalPlan}
        />
      </Modal>
    </>
  );
};

export default PaymentDetailsModal;
