import { http } from "../httpService";

export function createReportedIssue(reqBody) {
  return http.post(`/ReportIssue`, reqBody);
}

export function getReportedIssue(reqBody) {
  return http.post(`/ReportedIssue/All`, reqBody);
}

export function markAsResolvedReportedIssue(reqBody) {
  return http.post(`/ReportedIssue/Resolved?id=${reqBody.id}`, {});
}

export function markAsUnResolvedReportedIssue(reqBody) {
  return http.post(`/ReportedIssue/UnResolved?id=${reqBody.id}`, {});
}

export function bulkMarkAsResolvedReportedIssue(reqBody) {
  return http.post(`/ReportedIssue/BulkResolved`, reqBody.ids);
}

export function bulkMarkAsUnResolvedReportedIssue(reqBody) {
  return http.post(`/ReportedIssue/BulkUnResolved`, reqBody.ids);
}

export function optionsReportedIssue(reqBody) {
  return http.post(`/ReportedIssue/Options?name=${reqBody.name}`, reqBody);
}
