import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import { IconCross, IconModule } from "../../icons/Icons";
import ModalDelete from "../../components/ModalPopup/ModalDeletePopup";

const ContentDraggable = ({ list, setList, onClick, onRemove, setMethod,setlistData, hasUnpublishedContent, removeItemCallback, moduleId, operationType }) => {
  // const [lisdata, setlistData] = useState([]);
  const [deleteModal, setDeleteModal] = useState(false);
  const [responsePopup, setResponsePopup] = useState({});
  const [deleteData, setDeleteData] = useState({});
// console.log(lisdata,'listdata')
  const dragItem = useRef();
  const dragOverItem = useRef();

  const dragStart = (e, position) => {
    dragItem.current = position;
  };
 
  const dragEnter = (e, position) => {
    dragOverItem.current = position;
  };
 
  const drop = (e) => {
    const copyListItems = [...list];
    const dragItemContent = copyListItems[dragItem.current];
    copyListItems.splice(dragItem.current, 1);
    copyListItems.splice(dragOverItem.current, 0, dragItemContent);
    dragItem.current = null;
    dragOverItem.current = null;
    setList(copyListItems.map((item, index) => ({...item, index:index})));
  };


  useEffect(() => {
    console.log(list,'listlistlistlistlist');
    setlistData([...list]);
  }, [list]);

  const onConfirm = (requestType) => {
    console.log(deleteData,"deleteData")
    console.log(list,"list55")
    if (requestType === "Delete") {

      setDeleteModal(false);
      const deletedItem = deleteData.index;
      
      if (hasUnpublishedContent && removeItemCallback) {
        removeItemCallback(deletedItem); 
        hasUnpublishedContent(true);
      }
      
      if(operationType === "Duplicate" || operationType === undefined){
        deleteData.id = undefined;
        onRemove(deleteData.type, deleteData.id, deleteData.index, deleteData.contentType, setMethod);
      }else{
            onRemove(deleteData.type, deleteData.id, deleteData.index, deleteData.contentType, setMethod);
      }
     //       setDeleteModal(false)
//       onRemove(deleteData.type, deleteData.id, deleteData.contentType, setMethod)

    }
  };

  return (
    <>
      {
        list &&
    list.map((item, index) => (
      <div
        onDragStart={(e) => dragStart(e, index)}
        onDragEnter={(e) => dragEnter(e, index)}
        onDragEnd={drop}
        key={index}
        draggable
        className="d-flex align-items-center gap-3 bg-gray-600 borderAll p-12 rounded-6 mwidth-440 position-relative mb-12"
        >
          <IconModule />
          <div className="w-87">
            <p className="mb-0 text-md text-primary-200">{item.type}</p>
            <p className="mb-0 crmodCon mw-90">
              {/* <Button
                variant=""
                className="border-0 py-0"
                onClick={() => onClick(item.contentType, index)}
              > */}
                <div className="d-block gap-2 align-items-center text-truncate">
                  {index + 1} {item.title}
                </div>
              {/* </Button> */}
            </p>
          </div>
          {/* {item.contentType} */}
          {/* {item.id} */}
          <div className="crossBtn">
            <Button
              variant=""
              className="border-0 py-0"
              onClick={() => {

                setDeleteModal(true);
                setDeleteData({
                  type: true,
                  id: item.id,
                  index: item.index,
                  status:item.status,
                  contentType: item.contentType,
                });

//                 setDeleteModal(true) 
//                 setDeleteData(Object({type:true, id:item.id, contentType:item.contentType}))

              }}
            >
              <IconCross />
            </Button>
          </div>
        </div>
      ))}
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal || responsePopup?.show}
      >
        <ModalDelete
          onCancel={() => setDeleteModal(false)}
          onConfirm={onConfirm}
          // requestType={moduleId !== undefined && operationType !== undefined || operationType !== "Duplicate" && list && list?.length === 1 ? "NotDelete" : "Delete"}
          // headerLable={moduleId !== undefined && operationType !== undefined || operationType !== "Duplicate" && list && list?.length === 1 ? "Cannot Save the Changes !" : "Are you sure you want to delete the content?"}
          // chldLable={moduleId !== undefined && operationType !== undefined || operationType !== "Duplicate" && list && list?.length === 1 ? "At least 1 content should be present in a module all the time." : "This content will be deleted."}
          requestType={operationType === "Duplicate" || operationType === "Add" || (moduleId !== undefined && operationType === undefined && list && list.length > 1)? "Delete": "NotDelete"}
          headerLable={operationType === "Duplicate" || operationType === "Add" ||(moduleId !== undefined && operationType === undefined && list && list.length > 1)? "Are you sure you want to delete the content?": "Cannot Save the Changes !"}
          chldLable={operationType === "Duplicate" || operationType === "Add" ||(moduleId !== undefined && operationType === undefined && list && list.length > 1)? "This content will be deleted.": "At least 1 content should be present in a module all the time."}

        />
      </Modal>
     
    </>
  );
};

export default ContentDraggable;
