import React, { useEffect, useState } from 'react';
import Chart from "react-apexcharts";

const RadialBar = ({count}) => {
    // console.log(count,"countttttt")
    const [chartData, setChartData] = useState(
        {
            series: [count],
            options: {
                chart: {
                    type: 'radialBar',
                    offsetY: -10,
                    sparkline: {
                        enabled: true
                    }
                },
                plotOptions: {
                    radialBar: {
                        startAngle: -90,
                        endAngle: 90,
                        track: {
                            background: "#D9D9D9",
                            strokeWidth: '97%',
                            margin: 5, // margin is in pixels
                            /*
                            dropShadow: {
                                enabled: true,
                                top: 2,
                                left: 0,
                                color: '#999',
                                opacity: 1,
                                blur: 2
                            }
                            */
                        },
                        dataLabels: {
                            name: {
                                show: false
                            },
                            value: {
                                offsetY: -2,
                                fontSize: '32px',
                                fontWeight: '700',
                                fontFamily: 'Inter',
                                formatter: function (val) {
                                    return val + ''
                                }
                            }
                        }
                    }
                },
                grid: {
                    padding: {
                        top: -10
                    }
                },
                fill: {
                    colors: ['#E3828D'],
                    // type: 'gradient',
                    gradient: {
                        // shade: 'light',
                        // shadeIntensity: 0.4,
                        // inverseColors: false,
                        // opacityFrom: 1,
                        // opacityTo: 1,
                        // stops: [0, 50, 53, 91]
                    },
                },
                labels: ['Average Results'],
            },
        }
    );
    useEffect(() => {
        setChartData(
            {
                series: [count],
                options: {
                    chart: {
                        type: 'radialBar',
                        offsetY: -10,
                        sparkline: {
                            enabled: true
                        }
                    },
                    plotOptions: {
                        radialBar: {
                            startAngle: -90,
                            endAngle: 90,
                            track: {
                                background: "#D9D9D9",
                                strokeWidth: '97%',
                                margin: 5, // margin is in pixels
                                /*
                                dropShadow: {
                                    enabled: true,
                                    top: 2,
                                    left: 0,
                                    color: '#999',
                                    opacity: 1,
                                    blur: 2
                                }
                                */
                            },
                            dataLabels: {
                                name: {
                                    show: false
                                },
                                value: {
                                    offsetY: -2,
                                    fontSize: '2rem',
                                    fontWeight: '700',
                                    fontFamily: 'Inter',
                                    formatter: function (val) {
                                        return val + ''
                                    }
                                }
                            }
                        }
                    },
                    grid: {
                        padding: {
                            top: -10
                        }
                    },
                    fill: {
                        colors: ['#E3828D'],
                        // type: 'gradient',
                        gradient: {
                            // shade: 'light',
                            // shadeIntensity: 0.4,
                            // inverseColors: false,
                            // opacityFrom: 1,
                            // opacityTo: 1,
                            // stops: [0, 50, 53, 91]
                        },
                    },
                    labels: ['Average Results'],
                },
            }
        );
    },[count]);

    return (
        <div>
            <Chart
                options={chartData.options}
                series={chartData.series}
                type="radialBar"
                // width="200"
                // height="250"
            />
        </div>
    );
}

export default RadialBar;
