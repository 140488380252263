import React, { useState, useEffect } from "react";
import AccountSettingNav from "../AccountSettingNav";
import {
  Row,
  Col,
  Container,
  Modal,
  Tooltip,
  Button,
  OverlayTrigger,
  Dropdown,
} from "react-bootstrap";
import { IconEdit3, IconHamberger, IconDots } from "../../../icons/Icons";
import AccountImg from "../../../assets/images/Account.png";
import Search from "../../../components/Search/Search";
import BillingNav from "./BillingNav";
import { Switch, Route, Redirect, Link, useHistory } from "react-router-dom";
import AllInvoices from "./AllInvoices";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import AdditionalStorageModal from "./AdditionalStorageModal";
import planBg from "../../../assets/images/plan_bg.png";
import visaCard from "../../../assets/images/visaCard.png";
import MasterCard from "../../../assets/images/masterCard.png";
import PaymentDetailsModal from "./PaymentDetailsModal";
import PaymentSuccessful from "./PaymentSuccessful";
import PaymentFailed from "./PaymentFailed";
import EditAddressModal from "../../../components/PaymentCard/EditAddressModal";
import EditPaymentModal from "../../../components/PaymentCard/EditPaymentModal";
import ManageAddonsModal from "./ManageAddonsModal";
import ManageSubscription from "./ManageSubscription";
import ChangePlanModal from "./ChangePlanModal";
import InvoiceEmpty from "../../../assets/images/invoiceEmpty.svg";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";

import { useDispatch, useSelector } from "react-redux";
import {
  getPlansAsync,
  getSubscriptionAsync,
  updateSubscriptionAsync,
  downgradeSubscriptionAsync,
  getCardListByCustomerIdAsync,
  getCustomerDetailsAsync,
  getStorageAsync,
  getDiscountOnCurrentPlanAsync,
  addSubscriptionAsync,
} from "../../../reducers/subscription-reducers";
import {
  countryList,
  getDateComparison,
  getRemainingDays,
  convertToGB,
  convertMBToGB,
  convertMBToKB,
} from "../../../common";
import PaymentCardModal from "./PaymentCardModal";
import CancelPlanUnderReviewModal from "./CancelPlanUnderReviewModal";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader"
import moment from "moment";

import AddSeatsModal from "./AddSeatsModal";
import RemoveSeatsModal from "./RemoveSeatsModal";
import AddStorageModal from "./AddStorageModal";
import RemoveStorageModal from "./RemoveStorageModal";

const Billing = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [isUpdatedPaymentMethod, setIsUpdatedPaymentMethod] = useState(false);
  const params = new URLSearchParams(window.location.search);
  const setup_intent = params?.get('setup_intent');
  // const setup_intent_client_secret = params?.get('setup_intent_client_secret');
  // const source_type = params?.get('source_type');
  // console.log(setup_intent,'setup_intent');

  const { plansData, isUpdateLoading, isDowngradeLoading, storageData, discountData } = useSelector(
    (state) => state.subscription
  );
  const subscriptionData = useSelector((state) => state.subscription);

  const [paymentDetails, setPaymentDetails] = useState(false);
  const [manageSubscriptionModal, setManageSubscriptionModal] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editPayment, setEditPayment] = useState(false);
  const [manageAddons, setManageAddons] = useState(false);
  const [changePlan, setChangePlan] = useState(false);
  const [activePlan, setIsActivePlan] = useState(true);

  const [addSeats, setAddSeats] = useState(false);
  const [removeSeats, setRemoveSeats] = useState(false);
  const [addStorage, setAddStorage] = useState(false);
  const [removeStorage, setRemoveStorage] = useState(false);

  const [paymentCardModal, setPaymentCardModal] = useState(false);

  const [selectedPlanId, setSelectedPlanId] = useState("");
  const [selectedPlanData, setSelectedPlanData] = useState(null);
  const [cardListData, setCardListData] = useState([]);
  const [customerDetails, setCustomerDetails] = useState([]);
  const [cardId, setCardId] = useState("");
  const [countryName, setCountryName] = useState("");
  const [isMakePlan, setIsMakePlan] = useState(false);
  const [viewPlan, setViewPlan] = useState("year");
  const [cancelPlan, setCancelPlan] = useState(false);
  const [isRenewalPlan, setIsRenewalPlan] = useState(false);
  const [isTrialingPlan, setIsTrialingPlan] = useState(false);

  const [basePlan, setBasePlan] = useState({});
  const [latestInvoice, setLatestInvoice] = useState({});
  const [trialingDays, setTrialingDays] = useState(0);
  const [totalStorage, setTotalStorage] = useState(0);
  const [seatQuantity, setSeatQuantity] = useState(0);
  const [isCouponUsed, setIsCouponUsed] = useState(false);
  const [isAction, setIsAction] = useState(true);
  const [afterDowngradePlanData, setAfterDowngradePlanData] = useState([])

  useEffect(() => {
    dispatch(getPlansAsync());
    dispatch(getSubscriptionAsync());
    dispatch(getStorageAsync());
    // dispatch(getDiscountOnCurrentPlanAsync());
    getCardList();
    getCustomerDetails();
  }, []);

  useEffect(() => {
    getCustomerDetails();
  }, [editAddress]);

  useEffect(() => {
    if (setup_intent !== "" && isUpdatedPaymentMethod === false) {
      onUpdatePaymentMethod(setup_intent);
    }
  }, [setup_intent]);

  useEffect(() => {
    // dispatch(getSubscriptionAsync());
    dispatch(getSubscriptionAsync()).then((res) => {
      if (res?.payload) {
        if (res?.payload?.subscriptions !== null && res?.payload?.subscriptions?.length > 0 && res?.payload?.subscriptions[0]?.status === "active") {
          setIsAction(false);
          if (res?.payload?.subscriptions[0]?.scheduleId === null || !res?.payload?.subscriptions[0]?.scheduleId) {
            setAfterDowngradePlanData([]);
          }
        }
      }
    });
  }, [paymentCardModal, cancelPlan]);

  useEffect(() => {
    console.log(subscriptionData,'Billing subscriptionData');
    if (subscriptionData?.subscriptionData?.subscriptions !== null && subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0]?.status === "active") {
      setIsAction(false);
      if (subscriptionData?.subscriptionData?.subscriptions[0]?.scheduleId !== null && subscriptionData?.subscriptionData?.subscriptions[0]?.scheduleId) {
        if (subscriptionData?.subscriptionData?.subscriptions[0]?.schedule?.phases[1]?.items[0]?.priceId) {
          const afterDowngradePlan = plansData.filter(
            (plan) =>
              plan.statementDescriptor === "base_plan" && plan.priceId === subscriptionData.subscriptionData.subscriptions[0].schedule.phases[1].items[0].priceId
          );
          setAfterDowngradePlanData(afterDowngradePlan)
        }
      }
    }
    if ((subscriptionData?.subscriptionData?.subscriptions === null || subscriptionData?.subscriptionData?.subscriptions?.length === 0)) {
      setChangePlan(true);
      setIsCouponUsed(subscriptionData?.subscriptionData?.isCouponUsed);
    }
    if (subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0].items?.length > 0) {
      // setChangePlan(false);
      if (subscriptionData?.subscriptionData?.subscriptions[0]?.items[0]?.plan?.active === true) {
        setIsActivePlan(false);
      }
      const trailDays = getDateComparison(subscriptionData?.subscriptionData?.subscriptions[0]?.trialStart, subscriptionData?.subscriptionData?.subscriptions[0]?.trialEnd);
      setTrialingDays(trailDays);
    }
    if (subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0].items?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0].status === 'past_due') {
      if (subscriptionData?.subscriptionData?.subscriptions[0]?.items[0]?.plan?.active === true) {
        setIsRenewalPlan(true);
      }
    }
    if (subscriptionData?.subscriptionData?.subscriptions?.length > 0 && subscriptionData?.subscriptionData?.subscriptions[0].status === 'trialing') {
      setIsTrialingPlan(true);
    }
    if (subscriptionData?.subscriptionData?.latestInvoice && subscriptionData?.subscriptionData?.upcomingInvoice && subscriptionData?.subscriptionData?.plans?.length > 0) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      console.log(basePlan[0],'basePlan');
      // console.log(subscriptionData?.subscriptionData?.latestInvoice, 'latestInvoice');
      setBasePlan(basePlan && basePlan?.length > 0 ? basePlan[0] : {});
      setLatestInvoice(subscriptionData?.subscriptionData?.latestInvoice);
      // setTopLeftSeats();
      // setTopLeftSeats();
    }
    // 
  }, [
    subscriptionData,
  ]);

  // console.log(afterDowngradePlanData,'afterDowngradePlanData');

  useEffect(() => {
    // console.log();
    const basePlanIncludedUsers = parseFloat(basePlan && basePlan?.metaData?.included_users);
    const basePlanStorage = convertMBToKB(basePlan && (basePlan?.metaData?.storage * basePlan?.metaData?.included_users));
    const addonStorage = storageData?.totalStorage;
    // console.log(basePlanIncludedUsers,'basePlanIncludedUsers');
    // console.log(basePlanStorage,'basePlanStorage');
    // console.log(addonStorage,'addonStorage');
    const totalSt = (addonStorage > basePlanStorage ? addonStorage - basePlanStorage : 0);
    // console.log(totalSt,'totalSt');
    setTotalStorage(totalSt);
    setSeatQuantity(storageData && (storageData?.seatQuantity > basePlanIncludedUsers ? storageData?.seatQuantity-basePlanIncludedUsers : 0));
  }, [storageData, basePlan]);

  useEffect(() => {
    // console.log(countryList,'countryList');
    if (customerDetails?.address?.country) {
      const cntryName = countryList.find(
        (country) => country.code === customerDetails?.address?.country?.toUpperCase()
      );
      setCountryName(cntryName && cntryName?.name);
      // console.log(cntryName,'cntryName');
    }
  }, [customerDetails, countryList]);

  useEffect(() => {
    if (isMakePlan) {
      // navigate.push("/accountsetting/billingsubscription/makeplansuccess");
      history.push({
        pathname: "/accountsetting/billingsubscription/makeplansuccess",
        state: { selectedPlanData: selectedPlanData },
      });
    }
  }, [isMakePlan]);

  useEffect(() => {
    if (manageSubscriptionModal) {
      // navigate.push("/accountsetting/billingsubscription/makeplansuccess");
      history.push({
        pathname: "/accountsetting/billingsubscription/managesubscription",
        state: { planType: basePlan?.planType, isTrialingPlan: isTrialingPlan },
      });
    }
  }, [manageSubscriptionModal]);  

  const onhandleGetStarted = (planData, viewPlan) => {
    let selPlanData = [];
    if (viewPlan === "year") {
      selPlanData = planData?.planType === "Yearly" ? planData : [];
    } else {
      selPlanData = planData?.planType === "Monthly" ? planData : [];
    }
    if (selPlanData) {
      setSelectedPlanId(selPlanData?.priceId);
      setSelectedPlanData(planData);
      setViewPlan(viewPlan);
    }
    setPaymentCardModal(true);
    // setPaymentDetails(true);
  };

  const onUpdatePaymentMethod = (setup_intent) => {
    const updatePaymentCardString = localStorage.getItem('updatePaymentCard');
    const updatePaymentCard = JSON.parse(updatePaymentCardString);
    if (setup_intent && updatePaymentCard?.state?.paymentMethodId) {
      const request = {
        intentId: setup_intent,
        // customerId: updatePaymentCard?.state?.customerId,
        paymentMethodId: updatePaymentCard?.state?.paymentMethodId,
        priceId: null, //updatePaymentCard?.state?.priceId,
        isNew: updatePaymentCard?.state?.isNew,
      }
      dispatch(addSubscriptionAsync(request)).then((res) => {
        if (res.payload && isUpdatedPaymentMethod === false) {
          setIsUpdatedPaymentMethod(true);
          // console.log(res.payload);
          localStorage.removeItem('updatePaymentCard');
          ToasterAlertSucces(res, "Payment details updated successfully");
        }
      });
    }
  };

  const changeBillingCycle = (cycle, plName) => {
    // console.log(cycle,'cycle');
    // console.log(plName,'plName');
    const filteredPlansData = plansData.filter(
      (plan) =>
        plan.planType === (cycle === "year" ? "Yearly" : "Monthly") &&
        plan.statementDescriptor === "base_plan" && 
        plan.planName === plName
    );
    // console.log(filteredPlansData,'changeBillingCycle');
    setSelectedPlanId(filteredPlansData && filteredPlansData[0]?.priceId);
    setSelectedPlanData(filteredPlansData && filteredPlansData[0]);
    setViewPlan(cycle);
  }

  const getCardList = async () => {
    const reqBody = {
      customerId: "cus_PHZcTbZGclpDJ2",
    };
    await dispatch(getCardListByCustomerIdAsync(reqBody)).then((res) => {
      if (res.payload) {
        // console.log(res.payload,'getCardListByCustomerIdAsync');
        setCardListData(res.payload);
      }
    });
  };

  const getCustomerDetails = async () => {
    const reqBody = {
      customerId: "cus_PHZcTbZGclpDJ2",
    };
    await dispatch(getCustomerDetailsAsync(reqBody)).then((res) => {
      if (res.payload) {
        setCustomerDetails(res.payload);
      }
    });
  };

  const handleEdit = (id) => {
    //  console.log("handleEdit", id);
    setCardId(id);
    setEditPayment(true);
  };

  const handleEditAddress = () => {
    //  console.log("handleEdit", id);
    // setCardId(id);
    setEditAddress(true);
  };

  // console.log(discountData,'discountData');

  return (
    <>
      <div className="pages">
        <RenewalHeader />        
        <header className="pageHeader mb-4">
          <Container fluid>
            <Row className="mx-0 align-items-center">
              <Col className="px-0">
                <h3>Account Settings</h3>
              </Col>
              <Col className="px-0 text-end ">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <AccountDropdown />
                  <ActivityLog />
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="pageOuter">
          <Container fluid>
            <AccountSettingNav />
            {activePlan === false && (
              <>
                {isRenewalPlan && (
                  <div className="px-20 py-3 bg-pastel-100 border border-primary-100 rounded-6 mb-3 d-flex align-items-center justify-content-start">
                    <p className="mb-0 text-primary-100 font-bold pe-1">
                      Your Last Payment for Plan Renewal has Failed
                    </p>
                    <button
                      onClick={() => setPaymentDetails(true)}
                      className="btn-white btn-sm ms-4 border-0 bg-base-100 text-primary-100"
                    >
                      Make Payment
                    </button>
                  </div>
                )}
                {afterDowngradePlanData?.length > 0 && (
                  <div className="px-20 py-3 bg-gray-900 border border-gray-400 rounded-6 d-flex align-items-center justify-content-between">
                    <p className="mb-0 text-primary-200 font-normal text-lg">
                      {/* Your request for <b>Plan Downgrade</b> in under review */}
                      Your plan will be downgraded when the current billing cycle ends.
                    </p>
                    <button
                      className="text-primary-100 font-bold text-lg"
                      onClick={() => setCancelPlan(true)}
                      disabled={isAction}
                    >
                      Cancel Request
                    </button>
                  </div>
                )}
              </>
            )}

            <Row className={`flex-grow-1 trialPlan h-100 ${activePlan === false ? "" : "activePlan"}`}>
              {activePlan === false && (
                <>
                  <Col sm={4}>
                    {isTrialingPlan && (
                      <>
                        <div className="p-20 rounded-6 bg-base-100 filterShadow-100 trialPeriod position-relative">
                          <p className="text-md text-primary-100 font-normal text-uppercase m-0">
                            {basePlan && basePlan?.planType.toUpperCase()}
                            <span className="mx-2 text-gray-1300 opacity-75">|</span>
                            <span className="text-base-200 opacity-75 font-bold">
                              {basePlan && basePlan?.planName.toUpperCase()} PLAN
                            </span>
                          </p>
                          <h2 className="font-extraBold text-base-200 mt-6">
                            {subscriptionData?.subscriptionData?.subscriptions?.length > 0 && (
                              <>
                                {trialingDays}-day Trial Period
                              </>
                            )}
                          </h2>
                          <p className="m-0 text-md text-uppercase text-base-200 opacity-40 font-normal">
                            {subscriptionData?.subscriptionData?.subscriptions?.length > 0 && (
                              <>
                                {getRemainingDays(subscriptionData?.subscriptionData?.subscriptions[0]?.trialEnd)} Days remaining
                              </>
                            )}
                          </p>
                          <div className="text-md mt-12 text-base-200">
                            <span className="font-bold text-uppercase d-block">
                              SEATS
                            </span>
                            <span className="d-block opacity-75 font-normal">
                              {basePlan && basePlan?.metaData?.included_users} seats
                            </span>
                          </div>
                          <div className="text-md mt-2 text-base-200">
                            <span className="font-bold text-uppercase d-block">
                              STORAGE
                            </span>
                            <span className="d-block font-normal">
                              {convertToGB(basePlan && basePlan?.metaData?.storage)}{" "}
                              {/* {basePlan && basePlan?.metaData?.included_users} MB{" "} */}
                              <label className="text-gray-1200 opacity-75">
                                per seat
                              </label>
                            </span>
                          </div>
                          <div className="position-absolute end-0 bottom-0">
                            <img src={planBg} alt="" />
                          </div>
                        </div>
                        <div className="p-20 rounded-6 bg-base-100 filterShadow-100 mt-4">
                          <p className="text-md text-base-200 font-normal text-uppercase m-0 opacity-75">
                            Total Due after trial
                          </p>
                          <h2 className="text-base-200 font-extraBold mt-3">${basePlan && basePlan?.price}</h2>
                          <span className="d-block text-base-200 text-md opacity-75">
                            Per {basePlan && basePlan?.planType === "Monthly" ? "Month" : "Year"}.
                          </span>
                          <p className="d-block text-base-200 text-md mt-12 mb-1">
                            Next Bill Date{" "}
                            <span className="font-bold">
                              {subscriptionData?.subscriptionData?.subscriptions?.length > 0 && (
                                <>
                                  {moment(subscriptionData?.subscriptionData?.subscriptions[0]?.trialEnd).format("MMM D, YYYY")}
                                </>
                              )}
                            </span>
                          </p>
                          {/* <button
                            className="btn-secondary btn-sm w-100 mt-3 text-center"
                            onClick={() => setPaymentDetails(true)}
                          >
                            Start Subscription Now
                          </button> */}
                          <Button
                            className="btn-secondary btn-sm w-100 mt-3 text-center"
                            variant="white"
                            onClick={() => setManageSubscriptionModal(true)}
                            // disabled={isAction}
                          >
                            Manage Subscription
                          </Button>
                        </div>
                      </>
                    )}

                    {!isTrialingPlan && (
                      <>
                        <div className="p-20 rounded-6 bg-base-100 filterShadow-100 trialPeriod position-relative">
                          <p className="text-md text-primary-100 font-normal text-uppercase m-0">
                            {basePlan && basePlan?.planType.toUpperCase()}
                          </p>
                          <h2 className="font-extraBold text-base-200 mt-6">
                            {basePlan && basePlan?.planName} Plan
                          </h2>
                          <div className="text-md mt-4 text-base-200">
                            <span className="font-bold text-uppercase d-block">
                              SEATS
                            </span>
                            <span className="d-block opacity-75 font-normal">
                              {basePlan && basePlan?.metaData?.included_users} seats
                            </span>
                          </div>
                          <div className="text-md mt-2 text-base-200 pb-1">
                            <span className="font-bold text-uppercase d-block">
                              STORAGE
                            </span>
                            <span className="d-block font-normal">
                              {convertToGB(basePlan && basePlan?.metaData?.storage)}{" "}
                              <label className="text-gray-1200 opacity-75">
                                per seat
                              </label>
                            </span>
                          </div>
                          <div className="border-bottom border-gray-400 mt-3 pb-14">
                            <Button
                              className="m-0 btn-sm text-pastel-1000 font-normal manageBtn"
                              variant="white"
                              onClick={() => setManageSubscriptionModal(true)}
                              disabled={isAction}
                            >
                              Manage Subscription
                            </Button>
                          </div>
                          {/* <div className="mt-14">
                            <p className="text-uppercase text-md text-base-200 opacity-75 mb-14">
                              Get more out of the {basePlan && basePlan?.planName} plan.<br></br> Try Some addons
                            </p>
                            <Button
                              className="m-0 btn-sm text-pastel-1000 font-normal"
                              variant="white"
                              onClick={() => setManageAddons(true)}
                            >
                              Manage Addons
                            </Button>
                          </div> */}
                          <div className="d-flex justify-content-between align-tems-center mt-4">
                            <div className="text-md text-base-200">
                              <span className="font-bold text-uppercase d-block">
                                Add On SEATS
                              </span>
                              <span className="d-block font-normal">
                                {seatQuantity}{" "}Seats
                              </span>
                            </div>
                            <div className="relative z-1">
                              <Dropdown className="billingDropdown" drop="start">
                                <Dropdown.Toggle>
                                  <IconDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => setAddSeats(true)}
                                    disabled={isAction}
                                  >
                                  Add more seats
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => setRemoveSeats(true)}
                                    disabled={isAction}
                                  >
                                  Remove seats
                                  </button>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="d-flex justify-content-between align-tems-center mt-4">
                            <div className="text-md text-base-200">
                              <span className="font-bold text-uppercase d-block">
                                Add On Storage
                              </span>
                              <span className="d-block font-normal">
                                {convertMBToGB(totalStorage)}
                              </span>
                            </div>
                            <div className="relative z-1">
                              <Dropdown className="billingDropdown" drop="start" align="end">
                                <Dropdown.Toggle>
                                  <IconDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => setAddStorage(true)}
                                    disabled={isAction}
                                  >
                                  Add more storage
                                  </button>
                                  <button
                                    className="dropdown-item"
                                    onClick={() => setRemoveStorage(true)}
                                    disabled={isAction}
                                  >
                                  Remove storage
                                  </button>
                                </Dropdown.Menu>
                              </Dropdown>
                            </div>
                          </div>
                          <div className="position-absolute end-0 bottom-0">
                            <img src={planBg} alt="" />
                          </div>
                        </div>
                        <div className="p-20 pb-32 rounded-6 bg-base-100 filterShadow-100 mt-4">
                          <p className="text-md text-base-200 font-normal text-uppercase m-0 opacity-75">
                            Billing
                          </p>
                          <div className="pt-14 pb-4 border-bottom border-gray-400 d-flex justify-content-between align-items-center">
                            <p className="mb-0 text-lg text-gray-1200 font-normal">
                              Subscription Plan
                            </p>
                            <p className="mb-0 text-lg text-gray-100 font-medium">
                              ${basePlan && basePlan?.price}<br></br>
                              {/* <span className="text-xs opacity-75">Per {basePlan && basePlan?.planType === "Monthly" ? "Month" : "Year"}</span> */}
                            </p>
                          </div>
                          <h2 className="text-base-200 font-extraBold mt-3">
                            ${basePlan && basePlan?.price}
                            {/* {subscriptionData?.subscriptionData?.latestInvoice && (
                              <>
                                ${subscriptionData?.subscriptionData?.latestInvoice?.total/100}
                              </>
                            )} */}
                          </h2>
                          <span className="d-block text-base-200 text-md opacity-75">
                            Per {basePlan && basePlan?.planType === "Monthly" ? "month" : "annum"}.
                          </span>
                          {subscriptionData?.subscriptionData?.subscriptions?.length > 0 && (
                            <p className={`d-block ${subscriptionData?.subscriptionData?.subscriptions[0]?.status === "past_due" ? "text-primary-100" : "text-base-200"} text-md mt-12 mb-1`}>
                              {subscriptionData?.subscriptionData?.subscriptions[0]?.status === "past_due" ? "Payment Failed on" : "Next Bill Date"}{" "}
                              <span className="font-bold">
                                {moment(subscriptionData?.subscriptionData?.subscriptions[0]?.currentPeriodEnd).format("MMM D, YYYY")}
                              </span>
                            </p>
                          )}
                          {/*<p className="d-block text-primary-100 text-md mt-12 mb-1">
                            Payment Failed on{" "}
                            <span className="font-bold">12th April</span>
                          </p>*/}
                        </div>
                      </>
                    )}
                    
                  </Col>
                  <Col sm={8} className="d-flex flex-column h-100">
                    <div className="row">
                      <div className="col-sm-6 h-100">
                        <div className="p-20 rounded-6 bg-base-100 filterShadow-100 h-100">
                          {cardListData && cardListData?.length > 0 && (
                            <>
                              <div className="d-flex align-items-center justify-content-between">
                                <h6 className="text-gray-100 font-bold">
                                  Payment Method
                                </h6>
                                <button 
                                  className="text-primary-200 d-flex align-items-center gap-2 text-lg font-bold" 
                                  // onClick={() => setEditPayment(true)}
                                  onClick={() =>  handleEdit(cardListData[0]?.id)}
                                >
                                  <IconEdit3 />
                                  <span>Edit</span>
                                </button>
                              </div>

                              <div className="bg-pastel-900 rounded-6 py-20 px-2 mt-3 d-flex justify-content-start align-items-center">
                                {cardListData[0]?.card?.brand === 1 && <img src={visaCard} className="ms-1" />}
                                {cardListData[0]?.card?.brand === "Mastercard" && (
                                  <img src={MasterCard} alt="CardMaster" className="ms-1" />
                                )}
                                {cardListData[0]?.card?.brand === "visa" && <img src={visaCard} className="ms-1" />}

                                {/* <img src={visaCard} alt="" className="ms-1" /> */}
                                <div className="ms-3 me-1">
                                  <div className="h5 font-bold text-gray-100">
                                    Card Ending **-{cardListData[0]?.card?.last4}
                                  </div>
                                  <div className="text-lg text-gray-300 font-normal">
                                    Expires{" "}
                                    <span className="text-gray-100 font-medium">
                                      {`${cardListData[0]?.card?.expMonth}/${cardListData[0]?.card?.expYear}`}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}

                          {/*Payment Method Empty */}
                          {!cardListData && cardListData?.length === 0 && (
                            <div className="allInvoiceEmpty">
                              <div>
                                <img src={InvoiceEmpty} />
                                <p className="text-lg text-gray-300 mt-3">No payment method added</p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-sm-6 h-100">
                        <div className="p-20 rounded-6 bg-base-100 filterShadow-100 h-100">
                          {customerDetails && customerDetails?.address && (
                            <><div className="d-flex align-items-center justify-content-between">
                              <h6 className="text-gray-100 font-bold">
                                Billing Address
                              </h6>
                              <button className="text-primary-200 d-flex align-items-center gap-2 text-lg font-bold" 
                                // onClick={() => setEditAddress(true)}
                                onClick={() =>  handleEditAddress()}
                              >
                                <IconEdit3 />
                                <span>Edit</span>
                              </button>
                            </div>
                            <div className="pt-20">
                              <p className="mb-0 text-gray-100" style={{overflowWrap: "Break-word"}}>
                                {customerDetails?.address?.line1 && `${customerDetails?.address?.line1}`}
                                {customerDetails?.address?.line2 && `, ${customerDetails?.address?.line2}`}
                                {customerDetails?.address?.city && `, ${customerDetails?.address?.city}`}
                                {customerDetails?.address?.postalCode && `, ${customerDetails?.address?.postalCode}`}
                                {customerDetails?.address?.state && `, ${customerDetails?.address?.state}`}
                                {customerDetails?.address?.country && `, ${countryName}`}
                                {/* 4517 Washington Ave. Manchester, Kentucky 39495,
                                Washington D.C. United States */}
                              </p>
                              {/* <p className="mb-0 mt-1 text-gray-100">+91 74104 10123</p> */}
                            </div></>
                          )}
                          {/*Billing Address Empty */}
                          {!customerDetails && !customerDetails?.address && (
                            <div className="allInvoiceEmpty">
                              <div>
                                <img src={InvoiceEmpty} />
                                <p className="text-lg text-gray-300 mt-3">No billing address added</p>
                              </div>
                            </div>
                          )}                
                        </div>
                      </div>
                    </div>
                    <div className="rounded-6 bg-base-100 filterShadow-100 invoiceBlock mt-4">
                      <h6 className="px-20 pt-20 pb-3">All Invoices</h6>
                      <AllInvoices isTrialingPlan={isTrialingPlan} trialingDays={trialingDays} />
                    </div>
                  </Col>
                </>
              )}
            </Row>
          </Container>
        </div>
      </div>

      <Modal
        className="come-from-modal right paymentDetailsModal"
        centered
        show={paymentCardModal}
        onHide={() => setPaymentCardModal(false)}
      >
        {/* <Elements stripe={stripePromise}> */}
        <PaymentCardModal
          selectedPlanId={selectedPlanId}
          setSelectedPlanId={setSelectedPlanId}
          paymentCardModal={paymentCardModal}
          setPaymentCardModal={setPaymentCardModal}
          setIsMakePlan={setIsMakePlan}
          selectedPlanDatas={selectedPlanData}
          viewPlan={viewPlan}
          changeBillingCycle={changeBillingCycle}
          // modalTitle={planTitle}
        />
        {/* </Elements> */}
      </Modal>

      <Modal
        className="come-from-modal right paymentDetailsModal"
        centered
        show={paymentDetails}
        onHide={() => setPaymentDetails(false)}
      >
        <PaymentDetailsModal handleModal={PaymentDetailsModal} />
      </Modal>
      
      <Modal
        className="come-from-modal right editPaymentcard"
        centered
        show={editAddress}
        onHide={() => setEditAddress(false)}
      >
        <EditAddressModal
          handleModal={setEditAddress}
          editAddress={editAddress}
        />
      </Modal>
      <Modal
        className="come-from-modal right editPaymentcard"
        centered
        show={editPayment}
        onHide={() => setEditPayment(false)}
      >
        <EditPaymentModal
          handleModal={setEditPayment}
          editPayment={editPayment}
          cardId={cardId}
          setCardId={setCardId}
        />
      </Modal>
      {/* <Modal
        className="come-from-modal right manageAddons"
        centered
        show={manageAddons}
        onHide={() => setManageAddons(false)}
      >
        <ManageAddonsModal
          handleModal={setManageAddons}
          editPayment={editPayment}
        />
      </Modal> */}
      {changePlan && (
        <Modal
          className="come-from-modal right"
          centered
          show={changePlan}
          onHide={() => setChangePlan(false)}
        >
          <ChangePlanModal
            handleModal={setChangePlan}
            onhandleGetStarted={onhandleGetStarted}
            cycleName={viewPlan}
            // editPayment={editPayment}
          />
        </Modal>
      )}
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={cancelPlan}
        onHide={() => setCancelPlan(false)}
      >
        <CancelPlanUnderReviewModal handleModal={setCancelPlan} />
      </Modal>

      <Modal
        className="come-from-modal right manageAddons"
        centered
        show={addSeats}
        onHide={() => setAddSeats(false)}
      >
        <AddSeatsModal
          handleModal={setAddSeats}
          basePlan={basePlan}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageAddons"
        centered
        show={removeSeats}
        onHide={() => setRemoveSeats(false)}
      >
        <RemoveSeatsModal
          handleModal={setRemoveSeats}
          basePlan={basePlan}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageAddons"
        centered
        show={addStorage}
        onHide={() => setAddStorage(false)}
      >
        <AddStorageModal
          handleModal={setAddStorage}
          basePlan={basePlan}
        />
      </Modal>
      <Modal
        className="come-from-modal right manageAddons"
        centered
        show={removeStorage}
        onHide={() => setRemoveStorage(false)}
      >
        <RemoveStorageModal
          handleModal={setRemoveStorage}
          basePlan={basePlan}
        />
      </Modal>
    </>
  );
};

export default Billing;
