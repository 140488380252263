import React from "react";
import { NavLink } from "react-router-dom";
import {
  IconSideNavDashboardLight,
  IconSideNavEmployeesLight,
  IconSideNavAuthoringLight,
  IconSideNavReportedLight,
} from "../../icons/Icons";

const SideNavAdmin = ({ isRestricted }) => {
  return (
    <>
      <li>
        <NavLink exact to="/">
          <strong className="svgLight">
            <IconSideNavDashboardLight />
          </strong>

          <span>Dashboard</span>
        </NavLink>
      </li>

      <li>
        <NavLink 
          // to="/users"
          to={{
            pathname: "/users",
            state: { userIndex: true }
          }}
        >
          <strong className="svgLight">
            <IconSideNavEmployeesLight />
          </strong>

          <span>Users</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/authoring">
          <strong className="svgLight">
            <IconSideNavAuthoringLight />
          </strong>

          <span>Authoring</span>
        </NavLink>
      </li>

      <li>
        <NavLink to="/reportedIssues">
          <strong className="svgLight">
            <IconSideNavReportedLight />
          </strong>

          <span>Reported Issues</span>
        </NavLink>
      </li>
    </>
  );
};

export default SideNavAdmin;
