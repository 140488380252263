import React, { useState, useEffect } from 'react';

const OTPTimer = ({ expiryTime, onTimerExpired }) => {
  const [timeLeft, setTimeLeft] = useState(expiryTime);

  useEffect(() => {
    const interval = setInterval(() => {
      if (timeLeft > 0) {
        setTimeLeft(timeLeft - 1);
      } else {
        clearInterval(interval);
        onTimerExpired();
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [timeLeft, onTimerExpired]);

  // Format the remaining time as minutes and seconds
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;

  return (
    <div>
      <p> {minutes < 10 ? `0${minutes}` : minutes}:{seconds < 10 ? `0${seconds}` : seconds}</p>
    </div>
  );
};

export default OTPTimer;
