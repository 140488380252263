import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { onVerifyRequestAsync } from "../../../reducers/user-reducers";

import TextInput from "../../../components/FormikInputs/TextInput";
import OtpInput from "../../../components/OtpInput/OtpInput";
import { Formik, Form, ErrorMessage } from 'formik';

import ModalResponse from "../../../components/ModalPopup/ModalResponsePopup";
import ToasterAlertError from "../../../helper/ToasterAlertError";
import OTPTimer from "./OTPTimer";

const OtpSent = ({ onClose, email, type, isOTP, onSendVerificationRequest, getUserProfile, onOtpSubmitProfile }) => {
  const dispatch = useDispatch();

  const formRef = useRef();
  console.log(formRef,'formRef');

  const [responsePopup, setResponsePopup] = useState({});
  const [otpTimerExpired, setOTPTimerExpired] = useState(false);
  const [showOtpTimer, setShowOtpTimer] = useState(isOTP);
  const [maskedNumber, setMaskedNumber] = useState('**********');
  const [otp, setOtp] = useState('');
  const [verifySave, setVerifySave] = useState(false);

  const handleOtpComplete = (otps, setFieldValue) => {
    setFieldValue("otp", otps)
    setOtp(otps);
  };

  const handleTimerExpired = () => {
    setOTPTimerExpired(true);
    // Handle timer expiration here (e.g., resend OTP or show an error message)
  };

  useEffect(() => {
    if (email) {
        // Remove all non-digit characters from the phone number
        //const cleanedPhoneNumber = email.replace(/\D/g, '');
        const cleanedPhoneNumber = email;

        // Extract the first two and last two digits
        const firstTwoDigits = cleanedPhoneNumber.substring(0, 3);
        const lastTwoDigits = cleanedPhoneNumber.substring(cleanedPhoneNumber.length - 2);

        // Create the masked number
        setMaskedNumber(`${firstTwoDigits}******${lastTwoDigits}`);
    }
  }, [email]);

  const onResponseClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
    onClose(false);
  };

  const processResponse = (res) => {
  let errorMessage = "";
  if (res.error) {
    errorMessage = res.payload.split(":")[1];
    errorMessage = errorMessage.split("\n")[0];
  }
    let prompt =!res?.error ? "Success": "Error";
    // prompt = "Success";
    let message = !res?.error ? `Your ${type=== "Mobile" ? "mobile number": "email" } has been successfully verified` : errorMessage;

    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records: [],
      procesFailedError: res?.errors?.message,
    });
    if (!res?.error) {
      onOtpSubmitProfile && onOtpSubmitProfile(res, type);
    }
    
  };

  const otpValidation = (values) => {
    let errors = {};
    if (!values.otp || values.otp.trim() === "") {
      errors.otp = "Otp is required";
    } else if(values.otp.toString().length!==6){
      errors.otp = "Otp is required";
    }

    return errors;
  };

  const onOtpSubmit = () => {
    const currentFormData = formRef?.current;
    var errors = otpValidation(currentFormData.values);
    if (
      Object.keys(currentFormData.errors).length === 0 &&
      Object.keys(errors).length === 0
    ) {
      const reqBody = {
        email: encodeURIComponent(email),
        type:  type,
        code: otp
      };
      setVerifySave(true);
      dispatch(onVerifyRequestAsync(reqBody)).then(res => {
        if(res.payload?.value?.status === 200){
          getUserProfile();
          processResponse(res);
        }else{
          ToasterAlertError(res.payload?.value?.data);
        }
        setVerifySave(false);
      });
    }
  };

  const initialValues = {
    otp: ""
  };

  return (
    <>
      <Modal.Header className="sendCode" closeButton onClick={() => onClose(false)}></Modal.Header>
        <Formik
          initialValues={initialValues}
          onSubmit={onOtpSubmit}
          validate={otpValidation}
          innerRef={formRef}
        >
          {({values, setFieldValue}) => {
            return (
              <>
                <Modal.Body className="pt-4 pb-4">
                  {email && (
                    <>
                      <h3 className="mt-3 mb-1">
                        {" "}
                        Verify your{" "}
                        {type === "Mobile" ? "phone number" : "email"}
                      </h3>
                      <p className="text-300 mt-2 mb-4 pb-3">
                        We have sent a code on your{" "}
                        {type === "Mobile" ? "phone number" : "email"}
                        <b> {maskedNumber}</b>. Enter the code here to verify{" "}
                        {type === "Mobile" ? "phone number" : "email"}
                      </p>
                      <div className="mb-12 otpInputs">
                        <OtpInput
                          length={6}
                          onComplete={handleOtpComplete}
                          setFieldValue={setFieldValue}
                        />
                        <p className="text-300 text-center mt-2 mb-5">
                          {`Didn't receive the code?`}
                          {showOtpTimer && !otpTimerExpired ? (
                            <b className="text-success"> {"OTP sent ✔"}</b>
                          ) : (
                            <a
                              className="text-primary-200"
                              onClick={() => {
                                setShowOtpTimer(true);
                                setOTPTimerExpired(false);
                                onSendVerificationRequest(type);
                              }}
                            >
                              <b className="ms-1 mt-1">Resend code</b>
                            </a>
                          )}
                          {showOtpTimer ? (
                            <div>
                              {otpTimerExpired ? (
                                <p></p>
                              ) : (
                                <OTPTimer
                                  expiryTime={60}
                                  onTimerExpired={handleTimerExpired}
                                />
                              )}
                            </div>
                          ) : null}
                        </p>
                      </div>
                    </>
                  )}
                </Modal.Body>

                <Modal.Footer className="justify-content-end btnFooter gap-2 bg-gray-600">
                  <Button
                    variant="white"
                    size="md"
                    onClick={() => onClose(false)}
                  >
                    Close
                  </Button>
                  <Button
                    variant="secondary"
                    size="md"
                    disabled={Object.keys(otpValidation(values)).length !== 0 || verifySave}
                    onClick={() => onOtpSubmit()}
                  >
                    Verify & Save
                  </Button>
                </Modal.Footer>
              </>
            );
          }}
        </Formik>

        <Modal
          className="modalDialog"
          size="sm"
          centered
          show={responsePopup?.show}
        >
          {responsePopup?.show && (
            <ModalResponse data={responsePopup} onClose={onResponseClose} />
          )}
        </Modal>
    </>
  );
};

export default OtpSent;
