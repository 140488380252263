// ** Reactstrap Imports
import { toast } from "react-toastify";
import { Fragment } from "react";

const ToasterAlertError = (message) => {
  if (message) {
    return toast.error(
      <Fragment>
        <div className="toastify-header">
          <div className="title-wrapper">
            <h6 className="toast-title">{ message } </h6>
          </div>
        </div>
      </Fragment>,
      { icon: true, hideProgressBar: false }
    );
  }
};
export default ToasterAlertError;
