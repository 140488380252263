import React from 'react';
import { Switch, Route } from "react-router-dom";
import Modules from './Modules';
import Subscription from "./Subscription";

const SubscriptionIndex = () => {
    return (
        <>
            <Switch>
                <Route exact path="/subscription/modules">
                    <Modules />
                </Route>
                <Route exact path="/subscription">
                    <Subscription />
                </Route>
            </Switch>
        </>
    );
}

export default SubscriptionIndex;
