import React, { useState, useEffect } from "react";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { Modal, Button, Row, Col, Container, Accordion } from "react-bootstrap";
import AccountOnboarding from "../Status/AccountOnboarding";
import { Link } from "react-router-dom";
import { IconSeqarrow, IconPlanUpgrade2 } from "../../../../../icons/Icons";
import { useSelector, useDispatch } from "react-redux";
import { getAssigneeAsync } from "../../../../../reducers/user-reducers";
import EmptyState from "../../../../../components/EmptyState/EmptyState";

const TeamsModule = ({ onClose, showModuleState, teamDetail, teamId }) => {
  const dispatch = useDispatch();
  const [accountOnboard, setAccountOnboard] = useState(false);
  const [tabData, setTabData] = useState(showModuleState);
  const [formDataReady, setFormDataReady] = useState(false);
  const [processes, setProcesses] = useState([]);
  const [sequences, setSequences] = useState([]);
  const [modules, setModules] = useState([]);
  const [teamStatus, setTeamStatus] = useState(null);

  const getTabData = (tabKey) => {
    // console.log(tabKey,'tabKey');
    // console.log(teamDetail,'teamDetail');
    setProcesses(
      teamDetail?.allContents &&
        teamDetail?.allContents.filter((item) => item.type === "Process")
    );
    setSequences(
      teamDetail?.allContents &&
        teamDetail?.allContents.filter((item) => item.type === "Sequence")
    );
    setModules(
      teamDetail?.allContents &&
        teamDetail?.allContents.filter((item) => item.type === "Module")
    );
    // if (tabKey === "Processes") {

    // }
    // if (tabKey === "Sequences") {

    // }
    // if (tabKey === "Modules") {

    // }
  };

  useEffect(() => {
    if (tabData) {
      getTabData(tabData);
    }
  }, [tabData]);

  // console.log(processes,'processes');
  // console.log(sequences,'sequences');
  // console.log(modules,'modules');
  const handleButtonClick = (contentId, type) => {
    setFormDataReady(false);
    setAccountOnboard(true);
    const reqBody = {
      teamId: teamId,
      contentId: contentId,
      contentType: type,
    };

    dispatch(getAssigneeAsync(reqBody)).then((res) => {
      setTeamStatus(res?.payload);
      setFormDataReady(true);
    });
  };
  console.log(teamStatus, "teamStatus");
  return (
    <>
      <Modal.Body className="p-4 bg-gray-600">
        <div className="m-3 card cardMod bg-gray-600 fullHeight">
          <Tabs
            defaultActiveKey={showModuleState}
            id="uncontrolled-tab-example"
            className="modalTab authoringNav pt-3 px-32 bg-base-100 z-1"
          >
            <Tab
              className="h-100"
              eventKey="Processes"
              title="Processes"
              onClick={() => setTabData("Processes")}
            >
              <Accordion className="h-100 p-32" defaultActiveKey="0">
                {processes?.length > 0 ? (
                  <>
                    {processes &&
                      processes.map((pr, pindex) => (
                        <Accordion.Item
                          eventKey={pindex}
                          className="mb-4 cardContent"
                        >
                          <Accordion.Header>
                            <div className="row justify-content-between align-items-center p-12 pe-3 w-100">
                              <div className="col-4 align-items-start">
                                <div className="">
                                  <p className="text-primary-200 text-md mb-0">
                                    Process
                                  </p>
                                  <p className="font-bold mb-0 text-gray-100">
                                    {pr?.title}
                                  </p>
                                </div>
                              </div>

                              <div className="col-5">
                                {/* <div
                                  className="text-center"
                                  style={{ width: "max-content" }}
                                >
                                  <p className="text-primary-200 text-md mb-0">
                                    Assignees
                                  </p>
                                  <Button
                                    className="fSubmitMod ms-2"
                                    variant="white"
                                    size="sm"
                                    
                                    onClick={() =>
                                      handleButtonClick(pr.contentId, pr.type)
                                    }
                                  >
                                    <p className="font-bold mb-0 text-gray-100">
                                      {pr?.assignees}
                                      
                                    </p>
                                  </Button>
                                </div> */}
                              </div>

                              {/* <div className="col-3">
                          <p className="text-md text-end text-gray-300 mb-0">
                            40 mins
                          </p>
                        </div> */}
                            </div>
                          </Accordion.Header>
                          <div
                            className="text-center position-absolute"
                            style={{ width: "max-content", left: "60%", top:"0.813rem", zIndex:"20" }}
                          >
                            <p className="text-primary-200 text-md mb-0">
                              Assignees
                            </p>
                            <Button
                              className="fSubmitMod"
                              variant="white"
                              size="sm"
                              // onClick={() => setAccountOnboard(true)}
                              onClick={() =>
                                handleButtonClick(pr.contentId, pr.type)
                              }
                            >
                              <p className="font-bold mb-0 text-gray-100">
                                {pr?.assignees}
                                {/* <IconSeqarrow /> */}
                              </p>
                            </Button>
                          </div>
                          <Accordion.Body>
                            {pr?.subContents &&
                              pr?.subContents.map((pri, prindex) => (
                                <>
                                  {pri?.type === "Sequence" && (
                                    <>
                                      <div className="d-flex justify-content-between align-items-center pb-3 w-100">
                                        <div className="align-items-start">
                                          <div className="">
                                            <p className="text-primary-200 text-md mb-0">
                                              Sequence
                                            </p>
                                            <p className="font-medium mb-0 text-gray-100">
                                              {pri?.title}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                      {pri?.subContents &&
                                        pri?.subContents.map(
                                          (prs, prsindex) => (
                                            <div className="d-flex justify-content-between align-items-center cardBody mb-20 ps-0">
                                              <div className="d-flex gap-3 align-items-center justify-content-start">
                                                <div className="count text-xs text-gray-200 borderPurple">
                                                  {prsindex + 1}
                                                </div>
                                                <div>
                                                  <p className="text-primary-200 text-md mb-0">
                                                    Module
                                                  </p>
                                                  <p className="font-medium mb-0 text-gray-100">
                                                    {prs?.title}
                                                  </p>
                                                </div>
                                              </div>

                                              {/* <div>
                                  {" "}
                                  <p className="text-md text-gray-300 mb-0">20 mins</p>
                                </div> */}
                                            </div>
                                          )
                                        )}
                                    </>
                                  )}

                                  {pri?.type === "Module" && (
                                    <div className="d-flex justify-content-between align-items-center pb-3 w-100">
                                      <div className="align-items-start">
                                        <div className="">
                                          <p className="text-primary-200 text-md mb-0">
                                            Module
                                          </p>
                                          <p className="font-medium mb-0 text-gray-100">
                                            {pri?.title}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              ))}

                            {/* <div className="d-flex justify-content-between align-items-center cardBody noPoint mb-4  ps-0">
                        <div className="d-flex gap-3 align-items-center justify-content-start">
                          <div className="count text-xs text-gray-200">2</div>
                          <div>
                            <p className="text-primary-200 text-md mb-0">
                              Module
                            </p>
                            <p className="font-medium mb-0 text-gray-100">
                              Jira Tutorial
                            </p>
                          </div>
                        </div>
 
                        <div>
                          {" "}
                          <p className="text-md text-gray-300 mb-0">20 mins</p>
                        </div>
                      </div> */}
                            {/* <div className="d-flex justify-content-between align-items-center pb-3 w-100">
                        <div className="align-items-start">
                          <div className="">
                            <p className="text-primary-200 text-md mb-0">
                              Sequence
                            </p>
                            <p className="font-medium mb-0 text-gray-100">
                              Team roles & responsibilities
                            </p>
                          </div>
                        </div>
                      </div> */}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </>
                ) : (
                  <EmptyState message={"No process found "} />
                )}
                {/* <Accordion.Item eventKey="2" className="mb-4 cardContent">
                  <Accordion.Header>
                    <div className="row justify-content-between align-items-center p-12 pe-3 w-100">
                      <div className="col-4 align-items-start">
                        <div className="">
                          <p className="text-primary-200 text-md mb-0">
                            Process
                          </p>
                          <p className="font-bold mb-0 text-gray-100">
                            Account Onboarding
                          </p>
                        </div>
                      </div>
 
                      <div className="col-5">
                        <p className="text-primary-200 text-md mb-0">
                          Assignees
                        </p>
                        <p className="font-bold mb-0 text-gray-100">
                          234
                          <Button
                            className="fSubmitMod ms-2"
                            variant="white"
                            size="sm"
                            onClick={() => setAccountOnboard(true)}
                          >
                            <IconSeqarrow />
                          </Button>
                        </p>
                      </div>
 
                      <div className="col-3">
                        <p className="text-md text-end text-gray-300 mb-0">
                          40 mins
                        </p>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex justify-content-between align-items-center pb-3 w-100">
                      <div className="align-items-start">
                        <div className="">
                          <p className="text-primary-200 text-md mb-0">
                            Sequence
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            All About Cerebrent
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center cardBody mb-20 ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200 borderPurple">
                          1
                        </div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
 
                      <div>
                        {" "}
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
 
                    <div className="d-flex justify-content-between align-items-center cardBody noPoint mb-4  ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200">2</div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
 
                      <div>
                        {" "}
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-3 w-100">
                      <div className="align-items-start">
                        <div className="">
                          <p className="text-primary-200 text-md mb-0">
                            Sequence
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Team roles & responsibilities
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center pb-3 w-100">
                      <div className="align-items-start">
                        <div className="">
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </Tab>

            <Tab
              eventKey="Sequences"
              className="h-100"
              title="Sequence"
              onClick={() => setTabData("Sequences")}
            >
              <Accordion className="p-32 h-100" defaultActiveKey="0">
                {sequences?.length > 0 ? (
                  <>
                    {sequences &&
                      sequences.map((sc, sindex) => (
                        <Accordion.Item
                          eventKey={sindex}
                          className="mb-4 cardContent"
                        >
                          <Accordion.Header>
                            <div className="row justify-content-between align-items-center p-12 pe-3 w-100">
                              <div className="col-4 align-items-start">
                                <div className="">
                                  <p className="text-primary-200 text-md mb-0">
                                    Sequence
                                  </p>
                                  <p className="font-bold mb-0 text-gray-100">
                                    {sc?.title}
                                  </p>
                                </div>
                              </div>

                              <div className="col-5">
                                {/* <div
                                  className="text-center"
                                  style={{ width: "max-content" }}
                                >
                                  <p className="text-primary-200 text-md mb-0">
                                    Assignees
                                  </p>
                                  <Button
                                    className="fSubmitMod ms-2"
                                    variant="white"
                                    size="sm"
                                   
                                    onClick={() =>
                                      handleButtonClick(sc.contentId, sc.type)
                                    }
                                  >
                                    <p className="font-bold mb-0 text-gray-100">
                                      {sc?.assignees}

                                    </p>
                                  </Button>
                                </div> */}
                              </div>

                              {/* <div className="col-3">
                        <p className="text-md text-end text-gray-300 mb-0">
                          40 mins
                        </p>
                      </div> */}
                            </div>
                          </Accordion.Header>
                          <div
                            className="text-center position-absolute"
                            style={{ width: "max-content", left: "60%", top:"0.813rem", zIndex:"20" }}
                          >
                            <p className="text-primary-200 text-md mb-0">
                              Assignees
                            </p>
                            <Button
                              className="fSubmitMod"
                              variant="white"
                              size="sm"
                              // onClick={() => setAccountOnboard(true)}
                              onClick={() =>
                                handleButtonClick(sc.contentId, sc.type)
                              }
                            >
                              <p className="font-bold mb-0 text-gray-100">
                                {sc?.assignees}

                                {/* <IconSeqarrow /> */}
                              </p>
                            </Button>
                          </div>
                          <Accordion.Body>
                            {sc?.subContents &&
                              sc?.subContents.map((scm, scmindex) => (
                                <div className="d-flex justify-content-between align-items-center cardBody mb-20 ps-0">
                                  <div className="d-flex gap-3 align-items-center justify-content-start">
                                    <div className="count text-xs text-gray-200 borderPurple">
                                      {scmindex + 1}
                                    </div>
                                    <div>
                                      <p className="text-primary-200 text-md mb-0">
                                        Module
                                      </p>
                                      <p className="font-medium mb-0 text-gray-100">
                                        {scm?.title}
                                      </p>
                                    </div>
                                  </div>

                                  {/* <div>
                          {" "}
                          <p className="text-md text-gray-300 mb-0">20 mins</p>
                        </div> */}
                                </div>
                              ))}

                            {/* <div className="d-flex justify-content-between align-items-center cardBody ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200">2</div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
 
                      <div>
                        {" "}
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div> */}
                          </Accordion.Body>
                        </Accordion.Item>
                      ))}
                  </>
                ) : (
                  <EmptyState message={"No sequence found "} />
                )}
                {/* <Accordion.Item eventKey="2" className="mb-4 cardContent">
                  <Accordion.Header>
                    <div className="row justify-content-between align-items-center p-12 pe-3 w-100">
                      <div className="col-4 align-items-start">
                        <div className="">
                          <p className="text-primary-200 text-md mb-0">
                            Sequence
                          </p>
                          <p className="font-bold mb-0 text-gray-100">
                            Team roles & responsibilities
                          </p>
                        </div>
                      </div>
 
                      <div className="col-5">
                        <p className="text-primary-200 text-md mb-0">
                          Assignees
                        </p>
                        <p className="font-bold mb-0 text-gray-100">
                          234
                          <Button
                            className="fSubmitMod ms-2"
                            variant="white"
                            size="sm"
                            onClick={() => setAccountOnboard(true)}
                          >
                            <IconSeqarrow />
                          </Button>
                        </p>
                      </div>
 
                      <div className="col-3">
                        <p className="text-md text-end text-gray-300 mb-0">
                          40 mins
                        </p>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex justify-content-between align-items-center cardBody mb-20 ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200 borderPurple">
                          1
                        </div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
 
                      <div>
                        {" "}
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
 
                    <div className="d-flex justify-content-between align-items-center cardBody ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200">2</div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </Tab>

            <Tab
              className="h-100"
              eventKey="Modules"
              title="Module"
              onClick={() => setTabData("Modules")}
            >
              <Accordion className="p-32 h-100" defaultActiveKey="0">
                {modules?.length > 0 ? (
                  <>
                    {modules &&
                      modules.map((mc, mindex) => (
                        <Accordion.Item
                          eventKey={mindex}
                          className="mb-4 cardContent hide"
                        >
                          <div class="accordion-header">
                            <div className="row justify-content-between align-items-center p-12 pe-3 w-100">
                              <div className="col-4 align-items-start">
                                <div className="">
                                  <p className="text-primary-200 text-md mb-0">
                                    Module
                                  </p>
                                  <p className="font-bold mb-0 text-gray-100">
                                    {mc?.title}
                                  </p>
                                </div>
                              </div>

                              <div className="col-5">
                                {/* <div
                                  className="text-center"
                                  style={{ width: "max-content" }}
                                >
                                  <p className="text-primary-200 text-md mb-0">
                                    Assignees
                                  </p>
                                  <Button
                                    className="fSubmitMod ms-2"
                                    variant="white"
                                    size="sm"
                                    
                                    onClick={() =>
                                      handleButtonClick(mc.contentId, mc.type)
                                    }
                                  >
                                    <p className="font-bold mb-0 text-gray-100">
                                      {mc?.assignees}
                                    </p>
                                    
                                  </Button>
                                </div> */}
                              </div>

                              {/* <div className="col-3">
                        <p className="text-md text-end text-gray-300 mb-0">
                          40 mins
                        </p>
                      </div> */}
                            </div>
                          </div>
                          <div
                                  className="text-center position-absolute"
                                  style={{ width: "max-content", left: "60%", top:"0.813rem", zIndex:"20" }}
                                >
                                  <p className="text-primary-200 text-md mb-0">
                                    Assignees
                                  </p>
                                  <Button
                                    className="fSubmitMod"
                                    variant="white"
                                    size="sm"
                                    // onClick={() => setAccountOnboard(true)}
                                    onClick={() =>
                                      handleButtonClick(mc.contentId, mc.type)
                                    }
                                  >
                                    <p className="font-bold mb-0 text-gray-100">
                                      {mc?.assignees}
                                    </p>
                                    {/* <IconSeqarrow /> */}
                                  </Button>
                                </div>
                          {/* <Accordion.Body>
                    <div className="d-flex justify-content-between align-items-center cardBody mb-20 ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200 borderPurple">
                          1
                        </div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
 
                      <div>
                        {" "}
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
 
                    <div className="d-flex justify-content-between align-items-center cardBody ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200">2</div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
 
                      <div>
                        {" "}
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
                  </Accordion.Body> */}
                        </Accordion.Item>
                        
                      ))}
                  </>
                ) : (
                  <EmptyState message={"No module found "} />
                )}
                {/* <Accordion.Item eventKey="2" className="mb-4 cardContent">
                  <Accordion.Header>
                    <div className="row justify-content-between align-items-center p-12 pe-3 w-100">
                      <div className="col-4 align-items-start">
                        <div className="">
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-bold mb-0 text-gray-100">
                            Team roles & responsibilities
                          </p>
                        </div>
                      </div>
 
                      <div className="col-5">
                        <p className="text-primary-200 text-md mb-0">
                          Assignees
                        </p>
                        <p className="font-bold mb-0 text-gray-100">
                          234
                          <Button
                            className="fSubmitMod ms-2"
                            variant="white"
                            size="sm"
                            onClick={() => setAccountOnboard(true)}
                          >
                            <IconSeqarrow />
                          </Button>
                        </p>
                      </div>
 
                      <div className="col-3">
                        <p className="text-md text-end text-gray-300 mb-0">
                          40 mins
                        </p>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="d-flex justify-content-between align-items-center cardBody mb-20 ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200 borderPurple">
                          1
                        </div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
 
                      <div>
                        {" "}
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
 
                    <div className="d-flex justify-content-between align-items-center cardBody ps-0">
                      <div className="d-flex gap-3 align-items-center justify-content-start">
                        <div className="count text-xs text-gray-200">2</div>
                        <div>
                          <p className="text-primary-200 text-md mb-0">
                            Module
                          </p>
                          <p className="font-medium mb-0 text-gray-100">
                            Jira Tutorial
                          </p>
                        </div>
                      </div>
                      <div>
                        <p className="text-md text-gray-300 mb-0">20 mins</p>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item> */}
              </Accordion>
            </Tab>
          </Tabs>
        </div>
      </Modal.Body>

      <Modal className="come-from-modal right" centered show={accountOnboard}>
        <AccountOnboarding
          onClose={() => setAccountOnboard(false)}
          teamStatus={teamStatus}
          formDataReady={formDataReady}
        />
      </Modal>
    </>
  );
};

export default TeamsModule;
