import React, { useEffect } from 'react'
import { Link } from "react-router-dom";
import { 
    mapStatusToValue,
    getFormatDate,
    getShowNowUrl,
} from "../../../common";
import AccordionProgressBar from '../../../components/AccordionProgressBar/AccordionProgressBar';
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";

const EmpDashboardCard = ({ assignment, image }) => {

    let dueText;
    let totalDays;
  
    if (assignment.recommendedTimeDurationType === 0) {
      if (assignment.recommendedTime < 1440) {
        dueText = '1 day';
        totalDays = 1 + parseInt(assignment.recommendedTime / 1440);
      } else {
        const days = Math.ceil(assignment.recommendedTime / 1440);
        dueText = `${days} days`;
        totalDays = days;
      }
    } else if (assignment.recommendedTimeDurationType === 1) {
      if (assignment.recommendedTime < 24) {
        dueText = '1 day';
        totalDays = 1 + parseInt(assignment.recommendedTime / 24);
      } else {
        const days = Math.ceil(assignment.recommendedTime / 24);
        dueText = `${days} days`;
        totalDays = days;
      }
    } else if (assignment.recommendedTimeDurationType === 2) {
      dueText = `${assignment.recommendedTime} days`;
      totalDays = parseInt(assignment.recommendedTime);
    } else if (assignment.recommendedTimeDurationType === 3) {
      const days = assignment.recommendedTime * 7;
      dueText = `${days} days`;
      totalDays = days;
    }
  
    const assignedOn = new Date(assignment.assignedOn); 
  
    const currentDate = new Date();
    const dueDate = new Date(assignedOn);
    dueDate.setDate(assignedOn.getDate() + totalDays);
  
    const timeDifference = dueDate - currentDate; 
    const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));

    return (
        <div className="journyCard journyCardshadow flex-grow-1 d-flex flex-column">
            <img src={image} alt="Jimg" className="w-100" style={{height: "7.5rem", objectFit: "cover"}}/>
            <div className="journyCardDetail px-3 flex-grow-1">
                <span className={`tableTag ${assignment.status === 2 ? 'orange' : 'blue'} text-md`}>{mapStatusToValue(assignment.status)}</span>
                <h5 className="singleLineEc pe-0">{assignment.title}</h5>
                <p className="text-12 my-2 mb-10 ec2line">
                    {assignment.description}
                </p>
                <div className="exp text-12 text-gray-300">
                    <span>
                        <b className='p-0 m-0'>{assignment.moduleCount}</b> {3 > 1 ? 'Modules' : 'Module'}
                    </span>
                    {/* <span>Expiring on <b>{getFormatDate(assignment.expiryDate)}</b></span> */}
                    {/* <span> Due on <b>
                        {dueText && <span>{dueText}</span>}</b> */}
                    {daysRemaining < 0 ? (
                        assignment.expiryDate !== null ? (
                            <span>Expiring on <b>
                            {getFormatDate(assignment.expiryDate)}
                            {/* <DateTimeConverter utcDateTime={assignment.expiryDate} /> */}
                            </b></span>
                        ) : null
                    ) : (
                        <span>
                            Due on <b>
                            {daysRemaining === 1 ? `${daysRemaining} day` : `${daysRemaining} days`}
                            </b>
                        </span>
                    )}


                </div>

                {assignment.type === "Sequence" && assignment.modules?.length > 0 && (
                    <Link
                    to={{
                        pathname: getShowNowUrl(
                            assignment.id,
                            assignment.assignmentId,
                            assignment.modules[0].assignmentSegId,
                            assignment.type,
                            assignment.modules[0].id,
                            // assignment.modules[0].title,
                            assignment.title,
                            "Module/"
                        ),
                        state: { assignmentId: assignment.assignmentId }
                    }}
                    target="_blank"
                    className="btn-secondary btn-sm"
                    >
                    Resume
                    </Link>
                )}

                {assignment.type === "Module" && (
                    <Link
                    to={{
                        pathname: getShowNowUrl(
                            assignment.id,
                            assignment.assignmentId,
                            assignment.assignmentSegId,
                            assignment.type,
                            assignment.id,
                            assignment.title,
                        ),
                        state: { assignmentId: assignment.assignmentId }
                    }}
                    target="_blank"
                    className="btn-secondary btn-sm"
                    >
                    Resume
                    </Link>
                )}
            </div>
            {/* <div className='mx-3 position-relative'>
                <AccordionProgressBar value={30} />
            </div> */}
        </div>
    )
}

export default EmpDashboardCard
