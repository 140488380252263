import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import { IconPPT, IconDownload, IconReportIssue } from "../../../icons/Icons";
import { Link, useLocation, useHistory } from "react-router-dom";
import { pageType } from "../../../helper/pageType"
import DocViewer, { DocViewerRenderers } from "react-doc-viewer";
import Header from './Header';
import { generatePresignedUrl, bulkGeneratePresignedUrl } from '../../../api/common-store-api';
import { useSelector } from 'react-redux';
import { getGUID } from "../../../common";


const Ppt = ({ actionReport, contentUrl, slideData, setContent }) => {
    const location = useLocation()
    let history = useHistory();
    const [contentUrlData, setContentUrlData] = useState(null);
    const userRole = useSelector((state) => state.user.currentUser?.roles[0].name);

    const arrayDownloadableFiles = slideData?.downloadableFile
    const [downloadableFileURLs, setDownloadableFileURLs] = useState([])

    const [current, setCurrent] = useState(0);
    const length = ImageData.length;


    const [show, setShow] = useState(false);
    const target = useRef(null);

    const getPreviewUrl = async () => {
      const getGuid = getGUID(contentUrl);
        let previewUrl = await generatePresignedUrl(getGuid);
        if (previewUrl?.data) {
            setContentUrlData(previewUrl.data)
        }
    }

    useEffect(() => {
        getPreviewUrl();
        getBulkGeneratePresignedUrl()
    }, []);

    const linkToPPTFile = contentUrlData
    // /   "https://scholar.harvard.edu/files/torman_personal/files/samplepptx.pptx";

    const pptFile = [
      { uri: contentUrlData,
        fileType:contentUrl.split(".").pop()
     }
    ];

    const getBulkGeneratePresignedUrl = async () => {
        if (arrayDownloadableFiles !== undefined &&
            arrayDownloadableFiles !== null &&
            arrayDownloadableFiles.length > 0) {
            let responseURLsDownloadable = await bulkGeneratePresignedUrl(arrayDownloadableFiles.map((value) => value.filePath));
            if (responseURLsDownloadable?.data) {
                setDownloadableFileURLs(responseURLsDownloadable?.data)
            }
        }
    };

    return (
      <div className="pages">
        {/* <Header nextSlide={nextSlide} prevSlide={prevSlide}/>
            <div className='position-relative' style={{ top: '-1px' }}>
                <div className="progress tableProgress bg-base-100 " style={{ height: '2px' }} ref={target} onMouseEnter={() => setShow(!show)}>
                    <div className="progress-bar bg-primary-200" role="progressbar" style={{ width: '21%' }}></div>
                </div>
            </div> */}
        <div className="pageOuter pb-0">
          <Row className="g-0 h-100">
            <Col className="col-lg-12">
              <div className="videoPreview">
                <DocViewer
                  pluginRenderers={DocViewerRenderers}
                  documents={pptFile}
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: true,
                      retainURLParams: false,
                    },
                  }}
                  style={{ height: 500 }}
                />
                {/* <div className="mt-3">
                  <i className="text-gray-200 h6">
                    Use arrow keys to move through the presentation
                  </i>
                </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
}

export default Ppt;
