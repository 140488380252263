import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import CheckBoxMd from "../../components/CheckBox/CheckBoxMd";
import { useDispatch, useSelector } from "react-redux";
import {
  getContentAsync,
  assignContentToUsersAsync,
} from "../../reducers/user-reducers";
import CheckBox from "../../components/CheckBox/CheckBox";
import Search from "../../components/Search/Search";
import { useEffect } from "react";
import ModalDelete from "../../components/ModalPopup/ModalDeletePopup";
import ModalResponse from "../../components/ModalPopup/ModalResponsePopup";
import Loader from "../../components/Loader/Loader";
import EmptyState from "../../components/EmptyState/EmptyState";
import RestrictedUpgradeToBusinessModalPopup from "../../components/ModalPopup/RestrictedUpgradeToBusinessModalPopup";

import {
  getSubscriptionAsync,
  getPlansAsync,
} from "../../reducers/subscription-reducers";

const AssignConenteeModal = ({ openAssignConenteeModal, selectedUsers, singleUser, type, getUserList, onClose }) => {
  const entities = useSelector((state) => state.user.content);
  const [selectedItemList, setSelectedItemList] = useState([]);
  const [responsePopup, setResponsePopup] = useState({});
  const [assignModal, setAssignModal] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [formDataReady, setFormDataReady] = useState(false);

  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData } = useSelector(
    (state) => state.subscription
  );
  const [userPlan, setUserPlan] = useState('');
  const [isAssignButtons, setIsAssignButton] = useState(false);

  const [isBulkAssign, setIsBulkAssign] = useState(false);
  const [isBulkUpdate, setIsBulkUpdate] = useState(false);
  const [isRestrictedModalPopup, setIsRestrictedModalPopup] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSubscriptionAsync());
  }, [dispatch]);

  useEffect(() => {
    // console.log(subscriptionData,'subscriptionData');
    const hasSubscriptions = subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems = hasSubscriptions && subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      if (basePlan && basePlan?.length > 0) {
        setUserPlan(basePlan[0]?.planName);
        setIsBulkAssign(basePlan[0]?.metaData?.bulk_assign_content === "Yes" ? true : false);
        setIsBulkUpdate(basePlan[0]?.metaData?.bulk_update_status === "Yes" ? true : false);
      }
    }
  }, [
    subscriptionData,
  ]);

  const getContent = async (searchString) => {
    setShowLoader(true)
    setFormDataReady(false)
    const employeeId = selectedUsers[0]

    const params = {
      searchString: searchString ? searchString : "",
      employeeId: singleUser ? employeeId : "",
    };
    await dispatch(getContentAsync(params)).then((res) => {
      setShowLoader(false)
      setFormDataReady(true)
    });
  };

  const [timer, setTimer] = useState(null);

  const inputChanged = (e) => {
    clearTimeout(timer);

    const newTimer = setTimeout(() => {
      getContent(e);
    }, 1000);

    setTimer(newTimer);
  };

  const onResponseClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
      type:type
    });
    openAssignConenteeModal(false);
  };
  const processBulkActResponse = (res, operation) => {

    let prompt =
      res?.proceedData &&
      res?.totalRequest &&
      res?.proceedData === res?.totalRequest
        ? "Success"
        : "Error";

    let successMessage =
      operation === "Delete"
        ? `All selected records are deleted successfully! `
        : operation === "Archive"
        ? `All selected records are archived successfully! `
        : operation === "Assign"
        ? `The selected content has been assigned to the chosen users successfully! `
        : "";
    successMessage += `(${res?.proceedData}/${res?.totalRequest})`;

    let message =
      res?.proceedData === res?.totalRequest
        ? successMessage
        : `${operation} Failed (${res?.totalRequest - res?.proceedData}/${
            res?.totalRequest
          })`;

    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      type:type,
      data:res,
      records:
        res?.failexcutionResponse && res?.failexcutionResponse?.length > 0
          ? res?.failexcutionResponse
          : [],
      procesFailedError: res?.procesFailedError,
    });
  };

  const onConfirm = () => {
    if (
      selectedItemList !== null &&
      selectedItemList.length > 0 &&
      selectedUsers &&
      selectedUsers.length > 0
    ) {
      const contentData = entities.filter((ele) => {
        return selectedItemList.includes(ele.id);
      });

      const reqBody = {
        employeeId: selectedUsers,
        contentData: contentData,
      };
      dispatch(assignContentToUsersAsync(reqBody)).then((res) => {
        processBulkActResponse(res?.payload, "Assign");
        getContent()
        getUserList()
      });
      setAssignModal(false);
    }

    setSelectedItemList([]);
  };

  // const handleAssign = ()=>{

  //      const contentData = entities.filter((ele)=>{
  //         return selectedItemList.includes(ele.id);
  //      });

  //     const reqBody = {
  //           employeeId: selectedUsers,
  //           contentData: contentData
  //     };
  //     dispatch(assignContentToUsersAsync(reqBody)).then(res=>{
  //         processBulkActResponse(res);
  //     });
  // };
  const ststusChange = (newStstus, id) => {
    var selectedItems = [...selectedItemList];
    console.log(selectedItems);
    if (id == "All") {
      if (newStstus) {
        selectedItems = entities.map((c) => c?.id);
      }
      //   setSelectAll(newStstus);
    } else {
      let index = -1;
      index = selectedItems.indexOf(id);
      if (index !== -1) {
        selectedItems.splice(index, 1);
      } else {
        selectedItems.push(id);
      }
    }

    setSelectedItemList(selectedItems);
    // setSelectAll(selectedItems.length === entities.users.length);
  };
  useEffect(() => {
    if(singleUser){
      getContent("")
    }
    getContent("");
  }, []);

  return (
    <>
         <> {showLoader && <Loader />}</>
      <Modal.Header closeButton className="" onClick={() => onClose(false)}>
        <Modal.Title>Assign Content</Modal.Title>
      </Modal.Header>
      <Modal.Body className="">
        <div className="d-flex h-100 flex-column assignCont">
          <div className="input-group myInput mb-3 mw-100">
            <Search onBlur={inputChanged} placeHolder="Search by Title"  />
            {/* <input type="text" className="form-control" placeholder="Search.." /> */}
          </div>
          <div className="bg-base-100 d-flex flex-column h-100 overflow-auto">
            <div className="boxShadow2 borderAll2 rounded-6 assignCheck dropDownCheckBox overflow-auto mb-3">
              {entities &&
                entities.map((content) => {
                  return (
                    <div className="dlist">
                      <div className="h6">
                        <CheckBoxMd
                          text={content.title}
                          value={selectedItemList.includes(content.id)}
                          colName={content.id}
                          onChange={ststusChange}
                        />
                      </div>
                      <p className="text-xs text-gray-300 mb-0 ms-32">
                        {content.type}
                      </p>
                    </div>
                  );
                })}
            </div>
            {!entities.length > 0 && formDataReady === true ? <EmptyState message={"No content found"}/> : null }
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start btnFooter">
        <Button
          variant="secondary"
          onClick={() => {!isBulkAssign && selectedItemList.length > 1 ? setIsRestrictedModalPopup(true) : setAssignModal(true)}}
          disabled={selectedItemList.length < 1}
          className="ms-0 me-3"
        >
          Assign
        </Button>
        <Button variant="white" onClick={() => openAssignConenteeModal(false)} className="ms-1">
          Cancel
        </Button>
      </Modal.Footer>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={assignModal || responsePopup?.show}
      >
        {assignModal && (
          <ModalDelete
            onCancel={setAssignModal}
            onConfirm={onConfirm}
            requestType="Assign"
            headerLable={`Are you sure you want to assign the chosen content?`}
            chldLable={`This content will be assigned to the selected users.`}
          />
        )}
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onResponseClose} />
        )}
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={isRestrictedModalPopup}>
        <RestrictedUpgradeToBusinessModalPopup
          onCancel={setIsRestrictedModalPopup}
          headerLable={""}
          chldLable={""}
        />
      </Modal>
    </>
  );
};

export default AssignConenteeModal;
