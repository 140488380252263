import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { getReportedIssue,bulkMarkAsResolvedReportedIssue,bulkMarkAsUnResolvedReportedIssue,markAsResolvedReportedIssue,markAsUnResolvedReportedIssue, createReportedIssue } from "../api/reportedIssue-store-api";

const initialState = {
  reportedIssues: { records: [] },
};

export const createReportedAsync = createAsyncThunk(
  "reportedIssue",
  async (request) => {
    const response = await createReportedIssue(request);
    return response.data;
  }
);

export const updateReportedAsync = createAsyncThunk(
  "reportedIssue/All",
  async (data) => {
    return data;
  }
);

export const getReportedAsync = createAsyncThunk(
  "reportedIssue/All",
  async (request) => {
    const response = await getReportedIssue(request);
    return response.data;
  }
);
export const markAsResolvedReportedIssueAsync = createAsyncThunk(
  "reportedIssue/Resolved",
  async (request) => {
    const response = await markAsResolvedReportedIssue(request);
    //console.log(response.data);
    return response.data;
  }
);
export const markAsUnResolvedReportedIssueAsync = createAsyncThunk(
  "reportedIssue/UnResolved",
  async (request) => {
    const response = await markAsUnResolvedReportedIssue(request);
    //console.log(response.data);
    return response.data;
  }
);
export const bulkMarkAsResolvedReportedIssueAsync = createAsyncThunk(
  "reportedIssue/BulkResolved",
  async (request) => {
    const response = await bulkMarkAsResolvedReportedIssue(request);
    //console.log(response.data);
    return response.data;
  }
);
export const bulkMarkAsUnResolvedReportedIssueAsync = createAsyncThunk(
  "reportedIssue/BulkUnResolved",
  async (request) => {
    const response = await bulkMarkAsUnResolvedReportedIssue(request);
    //console.log(response.data);
    return response.data;
  }
);


const reportedIssueSlice = createSlice({
  name: "sequence",
  initialState,
  reducers: {},
  extraReducers: {
    [getReportedAsync.pending]: () => {
      console.log("pending");
    },
    [getReportedAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, reportedIssues: payload };
    },
    [getReportedAsync.rejected]: () => {
      console.log("rejected");
    },
  },
});

export default reportedIssueSlice.reducer;
