import React, { useState, useEffect, useRef } from "react";
import { IconUploadFile } from "../../../../icons/Icons";
import {
  isValidPhoto,
  isValidVideo,
  isValidPPT,
  isValidDocument,
  isValidMusic,
} from "./formFileValidation";
import Video from "../video";
import ImagePreview from "../ImagePreview";
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";
import { getStorageAsync } from "../../../../reducers/subscription-reducers";
import ModalStorageLimitReachedPopup from "../../../../components/ModalPopup/ModalStorageLimitReachedPopup";
import { Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllowedFileTypes } from "../../../../common";

const UploadFile = ({ title, serialNo, questionsFiles, isRequired, data }) => {
  const dispatch = useDispatch();
  const [file, setFile] = useState();

  const { storageData } = useSelector((state) => state.subscription);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);

  const uploadFile = async (e) => {
    e.preventDefault();
    if (isFileUpload) {
      setIsStorageLimitReached(true);
    } else {
      const fileInput = document.createElement("input");
      fileInput.type = "file";
      fileInput.onchange = async (event) => {
        const file = event.target.files[0];
        var src = URL.createObjectURL(file);
        setFile(file.name);
      };
      // const file = e.target.files[0];
      // var src = URL.createObjectURL(file);
      // setFile(file.name);
    }
    // const file = e.target.files[0];
    // var src = URL.createObjectURL(file);
    // setFile(file.name);
  };

  useEffect(() => {
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    if (storageData && storageData?.usedStorage >= storageData?.totalStorage) {
      setIsFileUpload(true);
    }
  }, [storageData]);

  return (
    <div className="mt-3 pt-3">
      <h6 className="mb-3 font-medium">
        {serialNo}. {title}
        {isRequired && (
          <span className="mandatoryFields" style={{ color: "red" }}>
            *
          </span>
        )}
      </h6>
      {questionsFiles.length > 0 ? (
        <>
          {isValidVideo(
            questionsFiles.length > 0 && questionsFiles[0]?.filePath
          ) ? (
            <Video contentUrl={questionsFiles[0]?.filePath} />
          ) : isValidPhoto(
              questionsFiles.length > 0 && questionsFiles[0]?.filePath
            ) ? (
            <ImagePreview contentUrl={questionsFiles[0].filePath} />
          ) : isValidPPT(
              questionsFiles.length > 0 && questionsFiles[0]?.filePath
            ) ? (
            <Ppt contentUrl={questionsFiles[0].filePath} />
          ) : isValidDocument(
              questionsFiles.length > 0 && questionsFiles[0]?.filePath
            ) ? (
            <Doc contentUrl={questionsFiles[0].filePath} />
          ) : isValidMusic(
              questionsFiles.length > 0 && questionsFiles[0]?.filePath
            ) ? (
            <Audio contentUrl={questionsFiles[0].filePath} />
          ) : null}
        </>
      ) : null}
      <label className="d-flex justify-content-center align-item-center p-32 text-center bg-base-100 rounded-6 borderAllDashed">
        <input
          type="file"
          className={`${!file ? "d-none" : ""}`}
          onClick={uploadFile}
          name="upload"
          accept=".jpeg, .jpg, .png, .mp4, .avi, .m4v, .mpg, .mp3, .doc, .docx, .pdf, .pptx"
        />
        {!file ? (
          <div>
            <div className="svgUploadicon">
              <IconUploadFile />
            </div>
            <p className="h6">
              <span className="font-bold text-primary-200">Add File</span>
            </p>
          </div>
        ) : null}
      </label>
      <div className="d-flex justify-content-start align-items-center">
        {data.maxFileLimit ? (
          <div
            style={{
              color: "red",
              fontSize: "11px",
              lineHeight: "0.875rem",
            }}
            className="pe-2"
          >
            Max file limit: {data.maxFileLimit}{" "}
          </div>
        ) : null}
        {data.maxFileSize ? (
          <div
            style={{
              color: "red",
              fontSize: "11px",
              lineHeight: "0.875rem",
            }}
            className="pe-2"
          >
            Max file size: {data.maxFileSize} MB
          </div>
        ) : null}
        {data.specificAllowedFileTypes ? (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              lineHeight: "0.875rem",
            }}
            className="pe-2"
          >
            {getAllowedFileTypes(data.specificAllowedFileTypes)}
            {/* Allowed file: {data.specificAllowedFileTypes}{" "} */}
          </span>
        ) : (
          <span
            style={{
              color: "red",
              fontSize: "11px",
              lineHeight: "0.875rem"
            }}
            className="pe-2"
          >
            Allowed types: jpeg, jpg, png, mp4, avi, m4v, mpg, mp3, mov, doc, .docx, .pdf, .ppt & .pptx.
          </span>
        )}
      </div>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isStorageLimitReached}
      >
        <ModalStorageLimitReachedPopup onClose={setIsStorageLimitReached} />
      </Modal>
    </div>
  );
};

export default UploadFile;
