import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import AccountImg from "../../assets/images/Account.png";
import {
  IconTopSearch,
  IconEditPencil,
  IconHamberger,
  IconEmployeeAccount,
  IconEmployeeCall,
  IconEmployeeMail,
  IconEmployeeDate,
} from "../../icons/Icons";
import { getFormatDate } from "../../common";
import getInitialsInName from "../../helper/GetNameInitials";
import UserImage from "../../components/UserImage/UserImage";
import Search from "../../components/Search/Search";
import EmptyState from "../../components/EmptyState/EmptyState";
import {
  getUnassignedEmployeeCountAsync,
} from "../../reducers/dashboard-reducers";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";
import { IconleftArrow } from "../../icons/Icons";
import { IconrightArrow } from "../../icons/Icons";
import Pagination from "../../components/Pagination/Pagination";
import {getTeamsAsync} from "../../reducers/user-reducers";
import Loader from "../../components/Loader/Loader";
import { useDebounce } from "../../utils/hooks/useDebounce";

const UnassignedDashboadModal = ({
  handleModal,
  unassignedEmployee,
  unassignedCount,
  designOption,
  selectedDesign,
  selectedDate,
}) => {
  const dispatch = useDispatch();

  const [newUnassignedEmp, setNewUnassignedEmp] = useState(
    (unassignedEmployee?.users ?? []).filter(user => user.role === "Employee" || user.role === "Admin")
  );

  const [listUnassignedEmp, setListUnassignedEmp] = useState([]);
//   const [newUnassignedEmp, setNewUnassignedEmp] = useState(unassignedEmployee?.users ?? []);

  const [unassignedCounts, setUnassignedCounts] = useState([]);
  const [searchString, setSearchString] = useState("");
  const [formDataReady, setFormDataReady] = useState(false);
  const [designOptions, setDesignOptions] = useState(designOption ?? []);
  //const [selectedDesigns, setSelectedDesigns] = useState(selectedDesign);
  const [pageSize, setPageSize] = useState(50);
  const [currentPage, setCurrentPage] = useState(1);
  const [filterss, setFilterss] = useState(false);
  const [optionsTeam, setOptionsTeam] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(
    optionsTeam[0] ? optionsTeam[0] : ""
  );
  const teamsList = useSelector((state) => state.user.teams);
 

  useEffect(() => {
    const teamsReqBody = {};
    if (teamsList.length === 0) {
      dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload?.map((item) => {
            return { value: item.key, label: item.value };
          });

          const allOption = {
            value: "all",
            label: "All Teams",
          };
          const optionsWithAll = [allOption, ...teams];
          setOptionsTeam(optionsWithAll);
          setSelectedTeam(optionsWithAll[0]);
        }
      });
    } else {
      if (!!teamsList && teamsList?.length > 0) {
        const teams = teamsList?.map((item) => {
          return { value: item.key, label: item.value };
        });
        const allOption = {
          value: "all",
          label: "All Teams",
        };
        const optionsWithAll = [allOption, ...teams];
        setOptionsTeam(optionsWithAll);
        setSelectedTeam(optionsWithAll[0]);
      }
    }
  }, []);

  const getUnAssignEmployee = () => {
    const selectedMonth = selectedDate.getMonth() + 1;
    const selectedYear = selectedDate.getFullYear();
    setFormDataReady(false);
    const reqBody = {
      page: currentPage,
      pageSize: pageSize,
      orderBy: "",
      isOrderByDesc: true,
      searchString: searchString,
      month: selectedMonth,
      year: selectedYear,
      //teamId: selectedOption?.value && selectedOption?.value !== 'all' ? selectedOption?.value : "",
      teamId:
      selectedTeam?.value && selectedTeam?.value !== "all"
        ? selectedTeam?.value
        : null,
  
    };

    dispatch(getUnassignedEmployeeCountAsync(reqBody)).then((res) => {
      if (res.payload) {
        // setUnassignedCounts(res.payload?.employeeTotalCount);
        setUnassignedCounts(res.payload?.allRecordsCount);
        setNewUnassignedEmp(res.payload?.users);
        setFormDataReady(true);
        //setSelectedDesigns(selectedOption);
      }
    });
  };
  const debouncedUnAssign = useDebounce(getUnAssignEmployee, 1000);

  useEffect(() => {
    setCurrentPage(1);
  }, [selectedTeam, searchString]);

  useEffect(() => {
    debouncedUnAssign();
    //getUnAssignEmployee();
  }, [searchString, selectedTeam, currentPage]);
  
  useEffect(() => {
      const newUnassignedEmployees = newUnassignedEmp.filter((item) => {
        // item.name.toLowerCase().includes(searchString.toLowerCase())
        const adminEmp = item.role.toLowerCase().includes("employee") || item.role.toLowerCase().includes("admin");
        const nameMatch = item.name.toLowerCase().includes(searchString.toLowerCase());
        const companyEmail = item.companyEmail.toLowerCase().includes(searchString.toLowerCase());
        return adminEmp && (nameMatch || companyEmail);
      });
      setListUnassignedEmp(newUnassignedEmployees);
     // setUnassignedCounts(newUnassignedEmployees?.length);
  }, [newUnassignedEmp,searchString]);
  
  const onPageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  return (
    <>
      <Modal.Header className="ps-8" closeButton>
        <div className="w-100">
          <Modal.Title>
          Employee Without Assignment<span className="font-normal ps-2">({unassignedCounts})</span>
          </Modal.Title>
        </div>
      </Modal.Header>

      {!formDataReady && <Loader />}
      <Modal.Body className="ps-8 pt-0 mt-0">

        <div className="row pb-3 joinPopInfo unassignedJoinPopupInfo mb-3">
          <div className="col-4">
            <Search
              onBlur={setSearchString}
              placeHolder="Search Employee"
            />
          </div>
          <div className="col-2">
            <div className="mb-4 justify-content-end selectBox">
              <Select
                name="designs"
                isSearchable={false}
                className="select"
                classNamePrefix="recatMySel"
                placeHolder="Design"
                options={designOptions}
                // defaultValue={designOptions[0]}
                // value={selectedDesigns}
                // onChange={(selectedOption) => {
                //   getUnAssignEmployee(selectedOption);
                // }}
                value={selectedTeam}
                onChange={(selectedOption) => {
                  setFilterss(true);
                  setSelectedTeam(selectedOption);
                }}
              />
            </div>
          </div>

          <div className="col-6 d-flex justify-content-start align-items-center">
          <Pagination
            totalItems={unassignedCounts}
            itemsPerPage={pageSize}
            onPageChange={onPageChange}
            setFilterss={setFilterss}
            filterss={filterss}
            pageTitle={"Users"}
          />

          </div>
        </div>
        <div className="row">
          {/* Just rapeat col-3 div */}
          {formDataReady === true && listUnassignedEmp?.map((detail, index) => (
            <div className="col-3">
              <div className="newJoining">
                <div className="d-flex align-items-center mb-3">
                  <div className="headImg">
                    {/* <img src={AccountImg} alt="" /> */}
                    {detail.img ? (
                      <UserImage imgKey={detail.img} alt={detail.name} />
                    ) : (
                      <div>
                        <img
                          src={getInitialsInName(detail.name ?? "N A", 40)}
                          alt={detail.name}
                        />
                      </div>
                    )}
                  </div>

                  <div className="w-100">
                    <h5 className="font-medium mb-1 singleLineEc">{detail.name}</h5>
                    <p className="text-md mb-0 text-gray-300 singleLineEc">{detail.title}</p>
                  </div>
                </div>
                <ul className="p-0 m-0 newJoinInfo">
                <li className="w-100 singleLineEc pe-0">
                    <IconEmployeeMail />
                    <a
                      href={`mailto:${detail.companyEmail}`}
                      className="text-gray-100 text-truncate text-sm"
                    >
                      {detail.companyEmail}
                    </a>
                  </li>
                  <li>
                    <IconEmployeeCall />
                    <a
                      // href={`callto:${detail.phoneNumber}`}
                      className="text-gray-100 text-truncate text-sm"
                    >
                      {detail.phoneNumber}
                    </a>
                  </li>
                  <li>
                    {" "}
                    <IconEmployeeDate />
                    <span className="text-gray-300 text-12">Joining date</span>
                    <span className="text-gray-100 text-sm ms-1">
                      {getFormatDate(detail.joiningDate)}
                      {/* <DateTimeConverter utcDateTime={detail.joiningDate} /> */}
                    </span>
                  </li>
                </ul>
              </div>
            </div>
          ))}
          {listUnassignedEmp.length === 0 && (
            <div style={{height: "calc(100vh - 16.563rem)"}}>
              <EmptyState message={"No records found"}/>
            </div>
          )}
        </div>
      </Modal.Body>

      <Modal.Footer className="justify-content-end border-0 pe-5">
        {/* <div className="new-pagination">
            <div className="d-flex justify-content-end align-items-center text-gray-1200 h6 font-normal">
                Page <span className="ms-1 me-1 text-gray-100 font-bold text-size">1-15</span>
                <div className="btnArrow ms-2">
                  <Button variant="white p-1" disabled>
                    <IconleftArrow/>
                  </Button>
                  <Button variant="white p-1" className="ms-3">
                    <IconrightArrow/>
                  </Button>
                </div>
            </div>
        </div> */}
      </Modal.Footer>

    </>
  );
};

export default UnassignedDashboadModal;
