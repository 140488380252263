import React from "react";
import { NavLink } from "react-router-dom";
import { ResourcesEmployee, HomeEmployeeGrey } from "../../icons/Icons";

const SideNavEmployee = ({ isRestricted }) => {
  return (
    <>
      <li>
        <NavLink exact to="/">
          <strong className="svgLight">
            <HomeEmployeeGrey />
          </strong>

          <span>Dashboard</span>
        </NavLink>
      </li>
      <li>
        <NavLink exact to="/catalogue">
          <strong className="svgLight">
            <ResourcesEmployee />
          </strong>

          <span>Catalogue</span>
        </NavLink>
      </li>
    </>
  );
};

export default SideNavEmployee;
