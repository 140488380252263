import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import RadioButton from "../../../components/RadioButton/RadioButton";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PlanRetained from './PlanRetained';
import {
  getSubscriptionAsync,
  resetIsgetSubscription,
  cancelUnderReviewSubScriptionAsync
} from "../../../reducers/subscription-reducers";
import ToasterAlertError from "../../../helper/ToasterAlertError";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";

const CancelPlanUnderReviewModal = ({ handleModal }) => {
  const dispatch = useDispatch();  
  const navigate = useHistory();

  const subscriptionData = useSelector((state) => state.subscription);
  useEffect(() => {
    dispatch(resetIsgetSubscription());
    dispatch(getSubscriptionAsync());
  }, []);
  // console.log(subscriptionData, "subscriptionData");

  const confirm = () => {
    // dispatch(cancelUnderReviewSubScriptionAsync(subscriptionData?.subscriptionData?.subscriptions[0].id));
    dispatch(cancelUnderReviewSubScriptionAsync(subscriptionData?.subscriptionData?.subscriptions[0]?.id)).then((res) => {
      console.log(res?.payload,'dgdfg dgdgdg');
      if (res?.payload === 'Success') {
        ToasterAlertSucces(res, 'Successfully canceled under review plan.');
        dispatch(getSubscriptionAsync());
        handleModal(false);
      } else {
        ToasterAlertError(res?.payload);
      }
    });
  };

  return (
    <>
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="pt-4 pb-4 mb-2">
        <h3 className="mt-3">Are you sure you want to cancel the request?</h3>
        <p className="text-gray-300 mb-0 mt-2 mb-5">
          If you cancel the request to downgrade, you will be charged according to your current plan.
        </p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter gap-2">
        <Button variant="white" size="sm" onClick={() => handleModal(false)}>
          No, retain my plan
        </Button>
        <Button variant="primary" size="sm" className="border-0" onClick={confirm} >
          Yes, I am sure
        </Button>
      </Modal.Footer>
    </>
  );
};

export default CancelPlanUnderReviewModal;
