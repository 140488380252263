import { httpAuth, http, httpIdentityApiRoot } from "../httpService";

export function getAllUsers(reqBody) {
  return httpAuth.post(`getUserList`, reqBody);
}

export function getRoles(reqBody) {
  return httpAuth.post(`getRole`, reqBody);
}

export function getTeams(reqBody) {
  return httpAuth.post(`getTeams`, reqBody);
}

export function getTimezone(reqBody) {
  return httpAuth.post(`getTimezones`, reqBody);
}

export function getUserPrefence() {
  return http.get(`api/Preferences/Get`);
}

export function setUserPrefence(reqBody) {
  return http.post(`api/Preferences/Update`, reqBody);
}

export function addUser(reqBody) {
  return httpAuth.post(`/AddUser`, reqBody);
}

export function updateUser(reqBody) {
  return httpAuth.post(`/UpdateUser`, reqBody);
}

export function addAdmin(reqBody) {
  return httpAuth.post(`/User/Admin/AddUser`, reqBody);
}

export function getUserDetails(id) {
  return httpAuth.post(`${id}/GetUser`);
}

export function setDisableAccount(userId) {
  return httpAuth.post(`DisbleUser?userId=${userId}`);
}

export function setEnableAccount(userId) {
  return httpAuth.post(`ActiveUser?userId=${userId}`);
}

export function setDeleteAccount(id) {
  return httpAuth.post(`DeleteUser?userId=${id}`);
}

export function setDeletePersonalAccount(id) {
  return httpAuth.post(`DeleteUserPersoanlData?userId=${id}`);
}

export function setBulkdeletePersonal(id) {
  return httpAuth.post(`/Bulk/DeleteUserPersoanlData?userId=${id}`);
}

export function bulkDisableAccount(request) {
  return httpAuth.post(`/Bulk/DisbleUser`, request);
}
export function bulkEnableAccount(request) {
  return httpAuth.post(`/Bulk/ActiveUser`, request);
}

export function bulkDeleteAccount(request) {
  return httpAuth.post(`/Bulk/DeleteUser`, request);
}

export function checkUniqueness(reqBody) {
  return httpAuth.post(`/isUniqueness`, reqBody);
}

export function checkUniquenessCompanyEmail(reqBody) {
  return httpAuth.post(`/isUniqueness/CompanyEmail`, reqBody);
}

export function checkUniquenessPersonalEmail(reqBody) {
  return httpAuth.post(`/isUniqueness/PersonalEmail`, reqBody);
}

export function checkUniquenessPhoneNumber(reqBody) {
  return httpAuth.post(`/isUniqueness/PhoneNumber`, reqBody);
}

export function assignModules(reqBody) {
  return http.post(`EmployeeAssignMent/Bulk/ModuleAssigment`, reqBody);
}

export function assignProcesses(reqBody) {
  return http.post(`EmployeeAssignMent/Bulk/ProcessAssigment`, reqBody);
}

export function assignSequences(reqBody) {
  return http.post(`EmployeeAssignMent/Bulk/SequenceAssigment`, reqBody);
}

export function getContent(params) {
  return http.get(`EmployeeAssignMent/GetContent?searchString=${params.searchString}&employeeId=${params.employeeId}`);
}

export function GetUnassignedEmployeeList(reqBody) {
  return httpAuth.post(`/GetUnassignedEmployeeList`, reqBody);
}

export function assignContentToUsers(reqBody) {
  return http.post(`EmployeeAssignMent/Bulk/EmployeeContentAssigment`, reqBody);
}

export function unassignContentToUsers(reqBody) {
  return http.post(`EmployeeAssignMent/EmployeeContentUnAssigment`, reqBody);
}

export function getEmpTeamList(reqBody) {
  return http.post(`api/Teams/GetTeamList?searchText=`, reqBody);
}

export function createTeam(reqBody) {
  return http.post(`api/Teams/Create?name=${reqBody.name}`, reqBody);
}

export function updateTeam(reqBody) {
  return http.post(`api/Teams/${reqBody.id}/Update?name=${reqBody.name}`, {});
}

export function getTeamDetails(reqBody) {
  return http.post(`api/Teams/${reqBody}/Details`, {});
}

export function deleteTeam(reqBody) {
  return http.delete(`api/Teams/${reqBody.id}/Delete`, {});
}

export function getUserProfile(reqBody) {
  return http.get(`User/getUserProfile`, {});
}

export function getUserUsage() {
  return http.get(`User/UserUsage`);
}

export function updateUserProfile(reqBody) {
  return http.post(`User/updateUserProfile`, reqBody);
}

export function updatePassword(reqBody) {
  return http.post(`User/updatePassword`, reqBody);
}

export function createTag(reqBody) {
  return http.post(`Tag`, reqBody);
}

export function getTag(reqBody) {
  return http.get(`GetAll`, reqBody);
}

export function validateHelpdeskNumber(reqBody) {
  return http.get(`User/ValidateHelpdeskNumber?Id=${reqBody.id}&TeamId=${reqBody.teamId}`, {});
}

export function GetAllTemplates(reqBody) {
  return http.get(`User/GetAllTemplates?searchString=${reqBody.searchString}`);
}

export function getAssignee(reqBody) {
  return http.post(`api/Teams/GetAssignee?teamId=${reqBody.teamId}&contentId=${reqBody.contentId}&contentType=${reqBody.contentType}`, {});
}

export function createFeedback(reqBody) {
  let feedbackEndPoint
  const newObject = { ...reqBody };
  delete newObject.moduleType;

  if(reqBody.moduleType==="module"){
    feedbackEndPoint = "EmployeeFeedbackRating/ModuleFeedback";
  } else if (reqBody.moduleType === "process") {
    feedbackEndPoint = "EmployeeFeedbackRating/ProcessesFeedback";
  } else {
    feedbackEndPoint = "EmployeeFeedbackRating/SequenceFeedback";
  }
  return http.post(`${feedbackEndPoint}`, newObject);
}

export function getUsersRole(id) {
  return httpIdentityApiRoot.get(`/api/Users/${id}/Roles`);
}

export function onSendVerificationRequest(email) {
  return httpAuth.post(`/sendVerificationRequest?Email=${email}`);
}

export function onVerifyRequest(reqBody) {
  return httpAuth.post(`verifyRequest?Email=${reqBody.email}&Code=${reqBody.code}&Type=${reqBody.type}`);
}

export function getEmployeeAssignments(reqBody) {
  return http.post(`EmployeeAssignMent/GetEmployeeAssignments`, reqBody);
}

export function getEmployeeSingleAssignments(reqBody) {
  return http.post(`EmployeeAssignMent/GetSingleAssignment?assignmentSegId=${reqBody.assignmentSegId}`);
}

export function setAssigmentStart(reqBody) {
  return http.post(`EmployeeAssignMent/AssigmentStart?assignmentId=${reqBody.assignmentId}&moduleId=${reqBody.moduleId}&assignmentSegId=${reqBody.assignmentSegId}`);
}

export function setAssigmentComplete(assignmentId) {
  return http.post(`EmployeeAssignMent/AssigmentComplete?assignmentId=${assignmentId}`);
}

export function getAllEmployeeForms(reqBody) {
  // return httpAuth.post(`getAllEmployeeForms?EmployeeId=${reqBody.employeeId}`);
  return httpAuth.post(`getEmployeeModuleForms?EmployeeId=${reqBody.employeeId}&searchString=${reqBody.searchString}`);
}

export function getSubmittedQuestionAnswers(reqBody) {
  return httpAuth.post(`SubmittedQuestionAnswers?EmployeeId=${reqBody.EmployeeId}&FormId=${reqBody.FormId}`);
}

export function getEmployeeAllDocuments(reqBody) {
  // return httpAuth.get(`getEmployeeDocuments?EmployeeId=${reqBody.EmployeeId}`);
  return httpAuth.get(`getEmployeeDocuments?EmployeeId=${reqBody.EmployeeId}&searchString=${reqBody.searchString}`);
}

export function getEmployeeModulePreview(reqBody) {
  return http.post(`EmployeeAssignMent/EmployeePreviewModule?moduleId=${reqBody.moduleId}&assignmentId=${reqBody.assignmentId}&assignmentSegId=${reqBody.assignmentSegId}`);
}

export function saveAssignment(reqBody) {
  return http.post(`EmployeeAssignMent/SaveAssignment`, reqBody);
}

export function logAction(reqBody) {
  return httpAuth.post(`LogActions`, reqBody);
}

export function getNotifications(reqBody) {
  let apiCall
  const newObject = { ...reqBody };
  delete newObject.userId;
  delete newObject.apiType;
  delete newObject.UserType;
  // if(reqBody.apiType==="Notification"){
  //   apiCall = `Notification/GetNotifications?userId=${reqBody.userId}`;
  // } else if (reqBody.apiType === "Alerts") {
  //   apiCall = `Notification/GetAlerts?userId=${reqBody.userId}`;
  // } else {
  //   apiCall = `Notification/GetNotifications?userId=${reqBody.userId}`;
  // }
  // return http.post(`${apiCall}`, newObject);
  if(reqBody.UserType){
  return http.post(`Notification/GetAll?userId=${reqBody.userId}&UserType=${reqBody.UserType}`, newObject);
  }else{
    return http.post(`Notification/GetAll?userId=${reqBody.userId}`, newObject);
  }
}

export function markOtherNotificationRead(reqBody) {
  return http.get(`Notification/MarkOtherNotificationRead?userId=${reqBody.userId}`);
}

export function markNotificationRead(reqBody) {
  return http.get(`Notification/MarkNotificationRead?userId=${reqBody.userId}&notificationId=${reqBody.notificationId}`);
}

export function switchRole(role) {
  return http.post(`User/SwitchRole?role=${role}`);
}

export function sendWelcomeNotification(reqBody) {
  return httpAuth.post(`/SendWelcomeNotification`);
  // return http.post(`/isUniqueness/PhoneNumber`, reqBody);
}