import React, { useState, useEffect, useRef } from "react";
import { Modal, Button } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  updateCustomerAsync,
  getCustomerDetailsAsync,
} from "../../reducers/subscription-reducers";
import moment from "moment";
import ToasterAlertSucces from "../../helper/ToasterAlertSucces";
import Loader from "../../components/Loader/Loader";
import TextInput from "../../components/FormikInputs/TextInput";
import {
  countryList,
} from "../../common";

const EditAddressModal = ({ handleModal, editAddress }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const formRef = useRef();
  const [userProfile, setUserProfile] = useState(null);
  const [isLoader, setIsLoader] = useState(false);
  const [countryOption, setCountryOption] = useState(true);
  const [selectedCountry, setSelectedCountry] = useState({});
  const [customerDetails, setCustomerDetails] = useState([]);

  const [addressLine1, setAddressLine1] = useState("");
  const [addressLine2, setAddressLine2] = useState("");
  const [postalCode, setPostalCode] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");



  useEffect(() => {
    getCustomerDetails();
  }, []);

  useEffect(() => {
    // console.log(customerDetails,'customerDetails');
    // console.log(countryList,'countryList');
    if (countryList) {
      const transformedList = countryList.map(country => ({
        value: country.code,
        label: country.name
      }));
      setCountryOption(transformedList);
      if (customerDetails && customerDetails?.address?.country) {
        const cntryName = countryList.find(
          (country) => country.code === customerDetails?.address?.country?.toUpperCase()
        );
        // console.log(cntryName,'cntryName');
        setSelectedCountry({
          value: cntryName?.code,
          label: cntryName?.name
        })
      }
      if (customerDetails && customerDetails?.address) {
        setAddressLine1(customerDetails && customerDetails?.address?.line1);
        setAddressLine2(customerDetails && customerDetails?.address?.line2);
        setPostalCode(customerDetails && customerDetails?.address?.postalCode);
        setCity(customerDetails && customerDetails?.address?.city);
        setState(customerDetails && customerDetails?.address?.state);
      }
    }
  }, [countryList, customerDetails]);

  const getCustomerDetails = async () => {
    const reqBody = {
      customerId: "cus_PHZcTbZGclpDJ2",
    };
    await dispatch(getCustomerDetailsAsync(reqBody)).then((res) => {
      if (res.payload) {
        setCustomerDetails(res.payload);
      }
    });
  };

  const addressValidation = async (values) => {
    // console.log(values,'values');
    console.log(selectedCountry,'selectedCountry');
    let errors = {};
    if (!selectedCountry || selectedCountry?.value.trim() === "") {
      errors.country = "Country name is required!";
    }
    if (!addressLine1 || addressLine1.trim() === "") {
      errors.addressLine1 = "Address Line 1 is required!";
    }
    if (!postalCode || postalCode.trim() === "") {
      errors.postalCode = "Postal code is required!";
    }
    if (!city || city.trim() === "") {
      errors.city = "City name is required!";
    }
    if (!state || state.trim() === "") {
      errors.state = "State name is required!";
    }
    return errors;
  };
  
  // console.log(cardId, "cardId");
  // console.log(formRef?.current,'formRef?.current');

  return (
    <>
      {isLoader && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <Formik
        initialValues={{
          country: customerDetails && customerDetails?.address?.country || "",
          addressLine1: customerDetails && customerDetails?.address?.line1 || "",
          addressLine2: customerDetails && customerDetails?.address?.line2 || "",
          city: customerDetails && customerDetails?.address?.city || "",
          state: customerDetails && customerDetails?.address?.state || "",
          postalCode: customerDetails && customerDetails?.address?.postalCode || "",
        }}
        validate={addressValidation}
        onSubmit={async (values) => {
          setIsLoader(true);
          const requestBody = {
            city: city || "",
            state: state || "",
            country: selectedCountry?.value || "",
            address1: addressLine1 || "",
            address2: addressLine2 || "",
            postalCode: postalCode || "",
          };
          await dispatch(updateCustomerAsync(requestBody)).then((res) => {
            if (res?.payload) {
              setIsLoader(false);
              ToasterAlertSucces(res, 'Billing address updated successfully!');
              handleModal(false);
              // getCustomerDetails();
            }
          });
        }}
        innerRef={formRef}
      >
      {({
        errors,
        touched,
        setFieldValue,
        setFieldTouched,
        values,
        
      }) => {
        return (
          <Form
            className="d-flex flex-column flex-grow-1 overfllow-auto h-100"
            autoComplete="off"
          >
            <Modal.Header closeButton>
              <Modal.Title>
                {/* {cardId ? "Edit Payment Method" : "Add Payment Method"} */}
                Edit Billing Address
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className="pt-0 pb-0">
              <div className="row mt-32">
                <div className="col-12 col-md-6 col-lg-6">
                <div className="form-group mySelect mb-32">
                  <label>Country <span style={{ color: "red" }}>*</span></label>
                  <Select
                      isSearchable={true}
                      className="select"
                      options={countryOption}
                      classNamePrefix="selectCustom"
                      name="country"
                      // isRequired={true}
                      defaultValue={selectedCountry}
                      value={selectedCountry}
                      onChange={(e) => {
                        // setFieldValue("country", e?.value);
                        setSelectedCountry({
                          value: e?.value,
                          label: e?.label,
                        });
                      }}
                    />
                    {errors.country ? (
                      <label className="text-sm  mb-0" style={{ color: "red" }}>
                        {errors.country}
                      </label>
                    ) : null}
                </div>
                  <div className="input-group myInput mb-32">
                    {/* <label>Address Line 1</label> */}
                    <TextInput
                      // type="text"
                      label="Address  Line 1"
                      className="form-control"
                      placeholder="Address  Line 1"
                      name="addressLine1"
                      onInput={(e) => {
                        setAddressLine1(e?.target?.value);
                        // setFieldValue("addressLine1", e?.target?.value);
                      }}
                      // value={customerDetails && customerDetails?.address?.line1 || ""}
                      value={addressLine1}
                      isRequired={true}
                    />
                    {/* {errors.addressLine1 && touched.addressLine1 ? (
                      <label className="text-sm  mb-0" style={{ color: "red" }}>
                        {errors.addressLine1}
                      </label>
                    ) : null} */}
                  </div>
                  <div className="input-group myInput mb-32">
                    {/* <label>Address Line 2</label> */}
                    <TextInput
                      // type="text"
                      className="form-control"
                      label="Address  Line 2"
                      placeholder="Address  Line 2"
                      name="addressLine2"
                      onInput={(e) => {
                        setAddressLine2(e?.target?.value);
                        // setFieldValue("addressLine2", e?.target?.value);
                      }}
                      // value={customerDetails && customerDetails?.address?.line2 || ""}
                      value={addressLine2}
                    />
                  </div>
                  <div className="myInput mb-32">
                    <div className="row gx-4">
                      <div className="col-lg-6">
                        {/* <label>Postal Code</label> */}
                        <TextInput
                          // type="text"
                          label="Postal Code"
                          className="form-control"
                          placeholder="Postal Code"
                          name="postalCode"
                          defaultValue={customerDetails && customerDetails?.address?.postalCode}
                          onInput={(e) => {
                              setPostalCode(e?.target?.value);
                              // setFieldValue("postalCode", e?.target?.value);
                            }}
                          // value={customerDetails && customerDetails?.address?.postalCode || ""}
                          value={postalCode}
                          isRequired={true}
                        />
                        {/* {errors.postalCode && touched.postalCode ? (
                          <label className="text-sm  mb-0" style={{ color: "red" }}>
                            {errors.postalCode}
                          </label>
                        ) : null} */}
                      </div>
                      <div className="col-lg-6">
                        {/* <label>City</label> */}
                        <TextInput
                          // type="text"
                          label="City"
                          className="form-control"
                          placeholder="City"
                          name="city"
                          onInput={(e) => {
                              setCity(e?.target?.value);
                              // setFieldValue("city", e?.target?.value);
                            }}
                          // value={customerDetails && customerDetails?.address?.city || ""}
                          value={city}
                          isRequired={true}
                        />
                        {/* {errors.city && touched.city ?  (
                          <label className="text-sm  mb-0" style={{ color: "red" }}>
                            {errors.city}
                          </label>
                        ) : null} */}
                      </div>
                    </div>
                  </div>
                  <div className="input-group myInput mb-32">
                      {/* <label>State</label> */}
                      <TextInput
                        // type="text"
                        label="State"
                        className="form-control"
                        placeholder="State"
                        name="state"
                        onInput={(e) => {
                            setState(e?.target?.value);
                            // setFieldValue("state", e?.target?.value);
                          }}
                        // value={customerDetails && customerDetails?.address?.state || ""}
                        value={state}
                        isRequired={true}
                      />
                      {/* {errors.state && touched.state ? (
                        <label className="text-sm  mb-0" style={{ color: "red" }}>
                          {errors.state}
                        </label>
                      ) : null} */}
                    </div>
                  {/* <div className="input-group myInput mb-32">
                    <label>State</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="State"
                      name="cardHolderName"
                      value="Washington"
                    />
                  </div>
                  <div className="input-group myInput mb-32">
                    <label>Contact Number</label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Number"
                      name="cardHolderName"
                      value="+91 74104 10123"
                    />
                  </div> */}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-start btnFooter">
              <Button
                variant="secondary"
                className="m-0"
                size=""
                type="submit"
                disabled={!selectedCountry || !addressLine1 || !postalCode || !city || !state}
              >
                {/*{cardId ? "Save changes" : "Save"}*/}
                {/* This is a comment */}
                Save changes
              </Button>

              <Button
                variant="white"
                size=""
                className="m-0 ms-3"
                onClick={() => handleModal(false)}
              >
                Cancel
              </Button>
            </Modal.Footer>
          </Form>
        );
      }}
      </Formik>
    </>
  );
};

export default EditAddressModal;
