import React from "react";
import { Row, Col, Container } from "react-bootstrap";
import { IconHamberger, IconTopSearch, IconPlanUpgrade1 } from "../../icons/Icons";
import AccountImg from "../../assets/images/Account.png";
import Search from "../../components/Search/Search";
import ActivityLog from "../ActivityLog/ActivityLog";
import AccountDropdown from "../AccountDropdown/AccountDropdown";
import { Link, useHistory, useLocation } from "react-router-dom";
import RenewalHeader from "../../pages/Authoring/ContentPreview/RenewalHeader"

const PageHeader = ({ title, onBlur, placeHolder, searchString}) => {
  return (
   <div>
   {/* {title==="Users" &&(
     <div className="bg-primary-100 d-flex justify-content-center align-items-center py-2 ">
    <div>
      {" "}
      <IconPlanUpgrade1 />
    </div>
    <p className="ps-2 h6 text-base-100 font-medium">
      Your have reach limit for users. Upgrade for unlimited users.
      <span className="ps-2 text-base-100 font-bold">
      Upgrade now to unlock all features.
      </span>{" "}
    </p>
  </div>
  )} */}
    <RenewalHeader />
    <header className="pageHeader">
      <Container fluid>
        <Row className="align-items-center">
          <Col>
          <div className="d-flex">
          {title && <h3>{title}</h3>}
          {/* {title==="Users" &&(
          <ul className="topNavHeader d-flex m-0">
            <li>
              <Link to="/accountsetting/billingsubscription/change" className="">All (56)</Link>
            </li>
            <li>
            Admin(14)
            </li>
            <li className="active">
            Employee(42)
            </li>
          </ul>
          )} */}
          </div>
          </Col>
          <Col className="text-end">
            <div className="d-flex align-items-center justify-content-end">
            <div className="userSearchin">
            {/* {title==="Users" ?( */}
                      <Search
                        onBlur={onBlur}
                        placeHolder={placeHolder ? placeHolder : "Search by Employee"}
                        searchString={searchString ? searchString : ""}
                      />
                       {/* ):(
                        <Search
                        onBlur={onBlur && onBlur}
                        placeHolder={placeHolder ? placeHolder : "Search by Employee"}
                        searchString={searchString ? searchString : ""}
                      />
                       )} */}
                    </div>
           
              <div className="ms-4 ps-3 me-3"><AccountDropdown /></div>
              <ActivityLog />
            </div>
          </Col>
        </Row>
      </Container>
    </header>
   </div>
  );
};

export default PageHeader;
