import React, { useEffect, useState } from "react";
import { Row, Col, Container, Accordion, Button, Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import JournyImg3 from "../../assets/images/journyImg3.png";
import {
  employeeStatus,
  assignmentStatus,
  getFormatDate,
  getShowNowUrl,
  countLearning,
  calculateDueTextAndDaysRemaining,
} from "../../common";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";

const AssignedContentModal = ({ 
  handleModal,
  onClosed,
  allAssignedContent,
}) => {
  
  console.log(allAssignedContent,'allAssignedContent');

  return (
    <>
      <Modal.Header className="ps-8" closeButton onClick={() => onClosed(false)}>
        <div className="w-100">
          <Modal.Title>New Content Assigned</Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className="newSeqAss ps-8">
        <Row>
        <p className="mb-0">
          You have{" "}
          <b>{allAssignedContent?.length} New</b>{" "}
          contents assigned for you.
        </p>
        {allAssignedContent.map((parent_item, parent_index) => (
          <Col xl={4} lg={6} md={12}>
            <div className="journyCardshadow employeejournyCardshadow">
              <div className='catlogHeading d-flex'>
                <div className='count'>
                  <span className='roundCount bg-gray-500 text-sm'>{parent_index + 1}</span>
                </div>
                <div className='ms-3 ps-1'>
                  <h5>{parent_item.title}</h5>
                  <p class="text-12 text-gray-200 my-1 ec2line word-break">
                    {parent_item.description}
                  </p>

                  <div className="expdate text-12 pt-2 pb-3 text-gray-300">
                    <span>
                      {countLearning(
                        parent_item.type,
                        parent_item
                      )}
                    </span>
                    {calculateDueTextAndDaysRemaining(
                      parent_item
                    ).daysRemaining < 0 ? (
                      parent_item.expiryDate ? (
                        <span>
                          Expiring on{" "}
                          <b>
                            {getFormatDate(
                              parent_item.expiryDate
                            )}
                            {/* <DateTimeConverter utcDateTime={parent_item.expiryDate} /> */}
                          </b>
                        </span>
                      ) : null
                    ) : (
                      <span>
                        Due on{" "}
                        <b>
                          {calculateDueTextAndDaysRemaining(
                            parent_item
                          ).dueText && (
                            <span>
                              {
                                calculateDueTextAndDaysRemaining(
                                  parent_item
                                ).daysRemaining > 0 ? calculateDueTextAndDaysRemaining(
                                  parent_item
                                ).daysRemaining : ""
                              }{" "}
                              {calculateDueTextAndDaysRemaining(
                                parent_item
                              ).daysRemaining === 1
                                ? "day"
                                : calculateDueTextAndDaysRemaining(
                                parent_item
                              ).daysRemaining === 0 ? "Today" : "days"}
                            </span>
                          )}
                        </b>
                      </span>
                    )}
                    
                    {/* {parent_item.expiryDate && (
                      <span>Due {" "}
                        <DateTimeConverter utcDateTime={parent_item.expiryDate} />
                      </span>
                    )} */}
                    
                  </div>
                  {/* <Button variant="secondary" size="sm">Start Now</Button> */}

                  {parent_item.type === "Sequence" && parent_item.modules?.length > 0 && (
                    <Link
                      to={
                        {pathname: getShowNowUrl(
                          parent_item.id,
                          parent_item.assignmentId,
                          parent_item.modules[0].assignmentSegId,
                          parent_item.type,
                          parent_item.modules[0].id,
                          // parent_item.modules[0].title,
                          parent_item.title,
                          "Module/"
                        ),
                        state: { assignmentId: parent_item.assignmentId }}
                      }
                      target="_blank"
                    >
                      <Button
                        variant="secondary"
                        size="sm"
                        className="py-2"
                        onClick={() =>
                          handleModal(parent_item.assignmentId, parent_item.modules[0].id, parent_item.modules[0].assignmentSegId)
                        }
                      >
                        Start Now
                      </Button>
                    </Link>
                  )}

                  {parent_item.type === "Module" && (
                    <Link
                      to={
                        {pathname: getShowNowUrl(
                          parent_item.id,
                          parent_item.assignmentId,
                          parent_item.assignmentSegId,
                          parent_item.type,
                          parent_item.id,
                          parent_item.title
                        ),
                        state: { assignmentId: parent_item.assignmentId }}
                      }
                      target="_blank"
                    >
                      <Button
                        variant="secondary"
                        size="sm"
                        className="py-2"
                        onClick={() =>
                          handleModal(parent_item.assignmentId, parent_item.id, parent_item.assignmentSegId)
                        }
                      >
                        Start Now
                      </Button>
                    </Link>
                  )}
                </div>
              </div>                    
              </div>
          </Col>
          )
        )}
        </Row>
      </Modal.Body>
    </>
  );
};

export default AssignedContentModal;
