import React from "react";
import { Modal, Button } from "react-bootstrap";
import {
  IconResetcheck
} from "../../icons/Icons";
class ModalStorageLimitReachedPopup extends React.Component {
  render() {
    return (
        <>    
        <Modal.Body className="pt-4 pb-4">
            {/* <h2 className="mt-5 text-center text-danger">Storage Limit Reached!</h2>
            <h4 className="text-danger mt-2 mb-1">You've reached the max storage limit. Upgrade the plan to upload more files.</h4> */}
            <div className=" d-flex justify-content-center">
                <h1 className="mb-32">Storage Limit Reached!</h1>
            </div>
            <div className="text-center">
                <p className="text-gray-1000 mb-20 text-md">
                    {this.props?.userType === "Employee" ? (
                        "Maximum storage limit reached. Please upgrade your plan to upload more files. Contact administrator for assistance."
                    ) : (
                        "You've reached the max storage limit. Upgrade the plan to upload more files."
                    )}
                </p>
            </div>
            {this.props?.userType !== "Employee" && (
                <div className="text-center">
                    <a href="/accountsetting/billingsubscription/change">
                        <button class="btn-secondary">View plans</button>
                    </a>
                </div>
            )}            
        </Modal.Body>
        <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
            <Button className="m-0" variant="primary" size="" onClick={() => this.props?.onClose(false)}>
                Close
            </Button>
        </Modal.Footer>
        </>
    );
  }
}

export default ModalStorageLimitReachedPopup;
