import React, { useEffect, useRef, useState } from "react";
import AccountImg from "../../assets/images/Account.png";
import { Dropdown } from "react-bootstrap";
import { NavLink, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { generatePresignedUrl } from "../../api/common-store-api";
import { getUserProfileAsync } from "../../reducers/user-reducers";
import DefaultUserImage from "../../assets/images/defaultUserImg.png";
import UserImage from "../UserImage/UserImage";
import getInitialsInName from "../../helper/GetNameInitials";
import AuthService from "../../services/AuthService";
import {
  switchRoleAsync,
} from "../../reducers/user-reducers";

const AccountDropdown = ({ formDataReady }) => {
  const dispatch = useDispatch();
  let imgRef = useRef();

  const history = useHistory();
  const [userProfile, setUserProfile] = useState(null);
  const [newMode, setNewMode] = useState('');

  const handleLogout = () => {
    const authService = new AuthService();
    // const newMode = userProfile?.roleName;
    // authService.logout();
    // history.push("/");
    const res = dispatch(switchRoleAsync(newMode));
    if (res) {
      authService.logout();
      history.push("/");
    }
  };

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
        // getPreviewUrl(res.payload.profileImagePath)
      }
    });
  };

  // const getPreviewUrl = async (profileImagePath) => {
  //   let previewUrl = await generatePresignedUrl(profileImagePath);
  //   if (previewUrl.data) {
  //         if(imgRef){
  //             imgRef.current.src = previewUrl.data
  //         }
  //   }
  // };

  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    getUserProfile();
  }, [formDataReady]);

  useEffect(() => {
    if (userProfile?.roleName) {
      setNewMode(userProfile?.roleName);
    }
  }, [userProfile]);
  // console.log(userProfile, "userProfile");
  return (
    <>
      <div className="headImg">
        <Dropdown className="accountDropdown">
          <Dropdown.Toggle>
            {/* <img src={AccountImg} alt="" /> */}
            {/* <img src={DefaultUserImage} alt="ProfileImg" ref={imgRef} /> */}
            {/* {userProfile ? <UserImage imgKey={userProfile.profileImagePath} /> : <img src={DefaultUserImage} alt="ProfileImg" ref={imgRef} />} */}
            {userProfile?.profileImagePath ? (
              <UserImage imgKey={userProfile.profileImagePath} />
            ) : (
              <img
                src={getInitialsInName(userProfile?.name ?? "N A", 24)}
                alt={userProfile?.name}
              />
            )}
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <NavLink className="dropdown-item" to="/accountsetting">
              Account Settings
            </NavLink>
            <button className="dropdown-item" to="/" onClick={handleLogout}>
              Logout
            </button>
          </Dropdown.Menu>
        </Dropdown>
      </div>
    </>
  );
};

export default AccountDropdown;
