import { http } from "../httpService";

export function getEmployeeCount(reqBody) {
  return http.post(
    `/Dashboard/GetNewEmployeeCount`, reqBody
  );
}

export function getUnassignedEmployeeCount(reqBody) {
  return http.post(
    `/Dashboard/GetUnassignedEmployeeCount`, reqBody
  );
}
export function getAssignmentsStatusCount(reqBody) {
  return http.post(
    `/Dashboard/GetAssignmentsStatusCount?month=${reqBody.month}&year=${reqBody.year}&teamId=${reqBody.teamId}`
  );
}

export function getMostPopularContent(reqBody) {
  return http.post(
    `/Dashboard/GetMostPopularContent?month=${reqBody.month}&year=${reqBody.year}&teamId=${reqBody.teamId}`
  );
}

export function getHelpdeskNumbers(reqBody) {
  return http.post(
    `/Dashboard/GetHelpDeskNumbers`, reqBody);
}

export function avgOnboardingTime(reqBody) {
  return http.post(
    `/Dashboard/AvgOnboardingTime?month=${reqBody.month}&year=${reqBody.year}&teamId=${reqBody.teamId}`
  );
}

export function getTeamWiseOnboarding(reqBody) {
  return http.post(
    `/Dashboard/GetTeamWiseOnboarding?month=${reqBody.month}&year=${reqBody.year}`
  );
}