import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { Modal, Button } from "react-bootstrap";
import {
  getSubscriptionAsync,
  getStorageAsync,
  getCustomerInvoicesAsync,
  downgradeSeatsAsync,
} from "../../../reducers/subscription-reducers";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import ToasterAlertError from "../../../helper/ToasterAlertError";
import moment from "moment";
import Loader from "../../../components/Loader/Loader";

const RemoveSeatsModal = ({ handleModal, basePlan }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData, isUpdateLoading, storageData } = useSelector(
    (state) => state.subscription
  );

  const [isLoader, setIsLoader] = useState(false);
  const [makePayment, setMakePayment] = useState(false);
  const [dueNextCycle, setDueNextCycle] = useState("");
  const [totalSeats, setTotalSeats] = useState(0);
  const [isRemoved, setIsRemoved] = useState(false);
  const [count, setCount] = useState(1);
  const [storageBar, setStorageBar] = useState(0);

  useEffect(() => {
    dispatch(getSubscriptionAsync());
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    if (storageData?.seatQuantity) {
      const basePlanSeats = basePlan && parseInt(basePlan?.metaData?.included_users);
      const addonSeatQuantity = (storageData.seatQuantity - basePlanSeats);
      const usedSeatQuantity = storageData.usedSeatQuantity;
      const usedAddonSeat = usedSeatQuantity > basePlanSeats ? (usedSeatQuantity - basePlanSeats) : 0 ;
      const unused = (addonSeatQuantity-usedAddonSeat);
      console.log(unused, 'unused');
      if (count > unused) {
        setIsRemoved(true);
      } else {
        setIsRemoved(false);
      }
    }    
  }, [storageData, count, basePlan]);

  useEffect(() => {
    if (subscriptionData?.subscriptionData?.subscriptions?.length > 0) {
      setDueNextCycle(moment(subscriptionData?.subscriptionData?.subscriptions[0]?.currentPeriodEnd).format("MMM D, YYYY"));
    }

    const calculatedStorageBar = storageData && storageData?.usedSeatQuantity > 0 ? ((storageData?.usedSeatQuantity / storageData?.seatQuantity) * 100).toFixed(2) : 0;
    const stBar = parseFloat(calculatedStorageBar);
    setStorageBar(stBar);
    // console.log(storageBar, 'storageBar');
  }, [subscriptionData, storageData]);

  const removeSeats = async () => {
    if (isRemoved === false) {
      setMakePayment(true);
      setIsLoader(true);
      const request = {
        quantityToReduce: count,
      };
      
      // await dispatch(downgradeSeatsAsync(request)).then((res) => {
      //     console.log(res.payload,'Seats removed successfully!');
      //     setIsLoader(false);
      //     if (res.payload === "Success") {
      //       dispatch(getStorageAsync());
      //       dispatch(getCustomerInvoicesAsync());
      //       ToasterAlertSucces(res, 'Action performed successfully. Your seats will be removed in the next billing cycle.');
      //       handleModal(false);
      //     } else {
      //         console.log('removed seats error');
      //         // ToasterAlertError(res.payload);
      //     }
      // });

      try {
        const res = await dispatch(downgradeSeatsAsync(request));
        setIsLoader(false);
        if (res.payload === "Success") {
          dispatch(getStorageAsync());
          dispatch(getCustomerInvoicesAsync());
          ToasterAlertSucces(res, 'Action performed successfully. Your seats will be removed in the next billing cycle.');
          handleModal(false);
        } else {
          console.log('removed seats error');
          ToasterAlertError(res.payload);
        }
      } catch (error) {
        setIsLoader(false);
        console.error('Error during seats removal:', error);
        ToasterAlertError('An unexpected error occurred. Please try again later.');
      }
    }
  };

  const handleChangeSeats = (val) => {
    const newValue = Math.max(1, parseInt(val, 10)); // Ensure minimum is 1
    setCount(newValue === "" ? 1 : newValue);
    setMakePayment(!val ? true : false);
  };

  return (
    <>
      {isLoader && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <Modal.Header closeButton>
        <h3 className="">Remove Seats</h3>
      </Modal.Header>
      <Modal.Body className="pt-4 pb-4">
        <div className="d-flex align-items-start flex-column h-100">
          <div className="w-100 mb-5">
            <label className="d-inline-block text-uppercase text-gray-300 text-md font-medium mb-3 opacity-75">
              ADD-ON
            </label>
            <div className="border border-gray-400 rounded-6 p-4 pt-32 w-100">
              <p className="mb-1 text-gray-300 text-md font-medium text-uppercase opacity-75">
                Seat usage
              </p>
              <div className="d-flex justify-content-between align-items-center mt-2 px-2">
                <div className="d-flex justify-content-start align-items-center mb-2">
                  <p className="mb-0 text-gray-100 text-lg pe-1">{storageData?.usedSeatQuantity} used</p>
                  <span className="text-secondary-300 text-lg font-bold ms-2">
                    {storageBar}%
                  </span>
                </div>
                <p className="mb-0 text-base-200 text-lg pe-1 opacity-75">
                  {storageData?.seatQuantity} seats
                </p>
              </div>
              <div
                class="progress rounded-0 mt-2"
                role="progressbar"
                aria-label="Basic example"
                aria-valuenow="25"
                aria-valuemin="0"
                aria-valuemax="100"
                style={{ height: "0.5rem" }}
              >
                <div
                  class="progress-bar bg-gray-300"
                  style={{ width: `${storageBar}%` }}
                ></div>
              </div>
            </div>
            <div className="d-flex justify-content-start align-items-center gap-2 pt-32">
              <label className="pe-1 text-gray-100 font-bold text-lg">
                Remove
              </label>
              <div className="w-96">
                <input
                  className="input form-control"
                  placeholder=""
                  value={count}
                  min={1}
                  type="number"
                  onChange={(event) => handleChangeSeats(event.target.value)}
                />
              </div>
              <label className="ps-1 text-gray-100 font-bold text-lg">
                seats
              </label>
            </div>
          </div>
          <div className="mt-auto w-100">
          <p className="mb-0 text-gray-300 text-md">Your changes will take effect in next billing cycle, <b>{dueNextCycle}</b></p>
          </div>
        </div>
      </Modal.Body>
      <Modal.Footer className="justify-content-start btnFooter gap-2 py-3">
        <Button
          variant="secondary"
          size="large"
          disabled={isRemoved}
          onClick={() => removeSeats()}
        >
          Confirm
        </Button>
        {/*<Button variant="secondary" size="large">
            Make Payment
        </Button>*/}
      </Modal.Footer>
    </>
  );
};

export default RemoveSeatsModal;
