export const countryList = [ 
  {name: 'Afghanistan', code: 'AF'}, 
  {name: 'Åland Islands', code: 'AX'}, 
  {name: 'Albania', code: 'AL'}, 
  {name: 'Algeria', code: 'DZ'}, 
  {name: 'American Samoa', code: 'AS'}, 
  {name: 'AndorrA', code: 'AD'}, 
  {name: 'Angola', code: 'AO'}, 
  {name: 'Anguilla', code: 'AI'}, 
  {name: 'Antarctica', code: 'AQ'}, 
  {name: 'Antigua and Barbuda', code: 'AG'}, 
  {name: 'Argentina', code: 'AR'}, 
  {name: 'Armenia', code: 'AM'}, 
  {name: 'Aruba', code: 'AW'}, 
  {name: 'Australia', code: 'AU'}, 
  {name: 'Austria', code: 'AT'}, 
  {name: 'Azerbaijan', code: 'AZ'}, 
  {name: 'Bahamas', code: 'BS'}, 
  {name: 'Bahrain', code: 'BH'}, 
  {name: 'Bangladesh', code: 'BD'}, 
  {name: 'Barbados', code: 'BB'}, 
  {name: 'Belarus', code: 'BY'}, 
  {name: 'Belgium', code: 'BE'}, 
  {name: 'Belize', code: 'BZ'}, 
  {name: 'Benin', code: 'BJ'}, 
  {name: 'Bermuda', code: 'BM'}, 
  {name: 'Bhutan', code: 'BT'}, 
  {name: 'Bolivia', code: 'BO'}, 
  {name: 'Bosnia and Herzegovina', code: 'BA'}, 
  {name: 'Botswana', code: 'BW'}, 
  {name: 'Bouvet Island', code: 'BV'}, 
  {name: 'Brazil', code: 'BR'}, 
  {name: 'British Indian Ocean Territory', code: 'IO'}, 
  {name: 'Brunei Darussalam', code: 'BN'}, 
  {name: 'Bulgaria', code: 'BG'}, 
  {name: 'Burkina Faso', code: 'BF'}, 
  {name: 'Burundi', code: 'BI'}, 
  {name: 'Cambodia', code: 'KH'}, 
  {name: 'Cameroon', code: 'CM'}, 
  {name: 'Canada', code: 'CA'}, 
  {name: 'Cape Verde', code: 'CV'}, 
  {name: 'Cayman Islands', code: 'KY'}, 
  {name: 'Central African Republic', code: 'CF'}, 
  {name: 'Chad', code: 'TD'}, 
  {name: 'Chile', code: 'CL'}, 
  {name: 'China', code: 'CN'}, 
  {name: 'Christmas Island', code: 'CX'}, 
  {name: 'Cocos (Keeling) Islands', code: 'CC'}, 
  {name: 'Colombia', code: 'CO'}, 
  {name: 'Comoros', code: 'KM'}, 
  {name: 'Congo', code: 'CG'}, 
  {name: 'Congo, The Democratic Republic of the', code: 'CD'}, 
  {name: 'Cook Islands', code: 'CK'}, 
  {name: 'Costa Rica', code: 'CR'}, 
  {name: 'Cote D\'Ivoire', code: 'CI'}, 
  {name: 'Croatia', code: 'HR'}, 
  {name: 'Cuba', code: 'CU'}, 
  {name: 'Cyprus', code: 'CY'}, 
  {name: 'Czech Republic', code: 'CZ'}, 
  {name: 'Denmark', code: 'DK'}, 
  {name: 'Djibouti', code: 'DJ'}, 
  {name: 'Dominica', code: 'DM'}, 
  {name: 'Dominican Republic', code: 'DO'}, 
  {name: 'Ecuador', code: 'EC'}, 
  {name: 'Egypt', code: 'EG'}, 
  {name: 'El Salvador', code: 'SV'}, 
  {name: 'Equatorial Guinea', code: 'GQ'}, 
  {name: 'Eritrea', code: 'ER'}, 
  {name: 'Estonia', code: 'EE'}, 
  {name: 'Ethiopia', code: 'ET'}, 
  {name: 'Falkland Islands (Malvinas)', code: 'FK'}, 
  {name: 'Faroe Islands', code: 'FO'}, 
  {name: 'Fiji', code: 'FJ'}, 
  {name: 'Finland', code: 'FI'}, 
  {name: 'France', code: 'FR'}, 
  {name: 'French Guiana', code: 'GF'}, 
  {name: 'French Polynesia', code: 'PF'}, 
  {name: 'French Southern Territories', code: 'TF'}, 
  {name: 'Gabon', code: 'GA'}, 
  {name: 'Gambia', code: 'GM'}, 
  {name: 'Georgia', code: 'GE'}, 
  {name: 'Germany', code: 'DE'}, 
  {name: 'Ghana', code: 'GH'}, 
  {name: 'Gibraltar', code: 'GI'}, 
  {name: 'Greece', code: 'GR'}, 
  {name: 'Greenland', code: 'GL'}, 
  {name: 'Grenada', code: 'GD'}, 
  {name: 'Guadeloupe', code: 'GP'}, 
  {name: 'Guam', code: 'GU'}, 
  {name: 'Guatemala', code: 'GT'}, 
  {name: 'Guernsey', code: 'GG'}, 
  {name: 'Guinea', code: 'GN'}, 
  {name: 'Guinea-Bissau', code: 'GW'}, 
  {name: 'Guyana', code: 'GY'}, 
  {name: 'Haiti', code: 'HT'}, 
  {name: 'Heard Island and Mcdonald Islands', code: 'HM'}, 
  {name: 'Holy See (Vatican City State)', code: 'VA'}, 
  {name: 'Honduras', code: 'HN'}, 
  {name: 'Hong Kong', code: 'HK'}, 
  {name: 'Hungary', code: 'HU'}, 
  {name: 'Iceland', code: 'IS'}, 
  {name: 'India', code: 'IN'}, 
  {name: 'Indonesia', code: 'ID'}, 
  {name: 'Iran, Islamic Republic Of', code: 'IR'}, 
  {name: 'Iraq', code: 'IQ'}, 
  {name: 'Ireland', code: 'IE'}, 
  {name: 'Isle of Man', code: 'IM'}, 
  {name: 'Israel', code: 'IL'}, 
  {name: 'Italy', code: 'IT'}, 
  {name: 'Jamaica', code: 'JM'}, 
  {name: 'Japan', code: 'JP'}, 
  {name: 'Jersey', code: 'JE'}, 
  {name: 'Jordan', code: 'JO'}, 
  {name: 'Kazakhstan', code: 'KZ'}, 
  {name: 'Kenya', code: 'KE'}, 
  {name: 'Kiribati', code: 'KI'}, 
  {name: 'Korea, Democratic People\'S Republic of', code: 'KP'}, 
  {name: 'Korea, Republic of', code: 'KR'}, 
  {name: 'Kuwait', code: 'KW'}, 
  {name: 'Kyrgyzstan', code: 'KG'}, 
  {name: 'Lao People\'S Democratic Republic', code: 'LA'}, 
  {name: 'Latvia', code: 'LV'}, 
  {name: 'Lebanon', code: 'LB'}, 
  {name: 'Lesotho', code: 'LS'}, 
  {name: 'Liberia', code: 'LR'}, 
  {name: 'Libyan Arab Jamahiriya', code: 'LY'}, 
  {name: 'Liechtenstein', code: 'LI'}, 
  {name: 'Lithuania', code: 'LT'}, 
  {name: 'Luxembourg', code: 'LU'}, 
  {name: 'Macao', code: 'MO'}, 
  {name: 'Macedonia, The Former Yugoslav Republic of', code: 'MK'}, 
  {name: 'Madagascar', code: 'MG'}, 
  {name: 'Malawi', code: 'MW'}, 
  {name: 'Malaysia', code: 'MY'}, 
  {name: 'Maldives', code: 'MV'}, 
  {name: 'Mali', code: 'ML'}, 
  {name: 'Malta', code: 'MT'}, 
  {name: 'Marshall Islands', code: 'MH'}, 
  {name: 'Martinique', code: 'MQ'}, 
  {name: 'Mauritania', code: 'MR'}, 
  {name: 'Mauritius', code: 'MU'}, 
  {name: 'Mayotte', code: 'YT'}, 
  {name: 'Mexico', code: 'MX'}, 
  {name: 'Micronesia, Federated States of', code: 'FM'}, 
  {name: 'Moldova, Republic of', code: 'MD'}, 
  {name: 'Monaco', code: 'MC'}, 
  {name: 'Mongolia', code: 'MN'}, 
  {name: 'Montserrat', code: 'MS'}, 
  {name: 'Morocco', code: 'MA'}, 
  {name: 'Mozambique', code: 'MZ'}, 
  {name: 'Myanmar', code: 'MM'}, 
  {name: 'Namibia', code: 'NA'}, 
  {name: 'Nauru', code: 'NR'}, 
  {name: 'Nepal', code: 'NP'}, 
  {name: 'Netherlands', code: 'NL'}, 
  {name: 'Netherlands Antilles', code: 'AN'}, 
  {name: 'New Caledonia', code: 'NC'}, 
  {name: 'New Zealand', code: 'NZ'}, 
  {name: 'Nicaragua', code: 'NI'}, 
  {name: 'Niger', code: 'NE'}, 
  {name: 'Nigeria', code: 'NG'}, 
  {name: 'Niue', code: 'NU'}, 
  {name: 'Norfolk Island', code: 'NF'}, 
  {name: 'Northern Mariana Islands', code: 'MP'}, 
  {name: 'Norway', code: 'NO'}, 
  {name: 'Oman', code: 'OM'}, 
  {name: 'Pakistan', code: 'PK'}, 
  {name: 'Palau', code: 'PW'}, 
  {name: 'Palestinian Territory, Occupied', code: 'PS'}, 
  {name: 'Panama', code: 'PA'}, 
  {name: 'Papua New Guinea', code: 'PG'}, 
  {name: 'Paraguay', code: 'PY'}, 
  {name: 'Peru', code: 'PE'}, 
  {name: 'Philippines', code: 'PH'}, 
  {name: 'Pitcairn', code: 'PN'}, 
  {name: 'Poland', code: 'PL'}, 
  {name: 'Portugal', code: 'PT'}, 
  {name: 'Puerto Rico', code: 'PR'}, 
  {name: 'Qatar', code: 'QA'}, 
  {name: 'Reunion', code: 'RE'}, 
  {name: 'Romania', code: 'RO'}, 
  {name: 'Russian Federation', code: 'RU'}, 
  {name: 'RWANDA', code: 'RW'}, 
  {name: 'Saint Helena', code: 'SH'}, 
  {name: 'Saint Kitts and Nevis', code: 'KN'}, 
  {name: 'Saint Lucia', code: 'LC'}, 
  {name: 'Saint Pierre and Miquelon', code: 'PM'}, 
  {name: 'Saint Vincent and the Grenadines', code: 'VC'}, 
  {name: 'Samoa', code: 'WS'}, 
  {name: 'San Marino', code: 'SM'}, 
  {name: 'Sao Tome and Principe', code: 'ST'}, 
  {name: 'Saudi Arabia', code: 'SA'}, 
  {name: 'Senegal', code: 'SN'}, 
  {name: 'Serbia and Montenegro', code: 'CS'}, 
  {name: 'Seychelles', code: 'SC'}, 
  {name: 'Sierra Leone', code: 'SL'}, 
  {name: 'Singapore', code: 'SG'}, 
  {name: 'Slovakia', code: 'SK'}, 
  {name: 'Slovenia', code: 'SI'}, 
  {name: 'Solomon Islands', code: 'SB'}, 
  {name: 'Somalia', code: 'SO'}, 
  {name: 'South Africa', code: 'ZA'}, 
  {name: 'South Georgia and the South Sandwich Islands', code: 'GS'}, 
  {name: 'Spain', code: 'ES'}, 
  {name: 'Sri Lanka', code: 'LK'}, 
  {name: 'Sudan', code: 'SD'}, 
  {name: 'Suriname', code: 'SR'}, 
  {name: 'Svalbard and Jan Mayen', code: 'SJ'}, 
  {name: 'Swaziland', code: 'SZ'}, 
  {name: 'Sweden', code: 'SE'}, 
  {name: 'Switzerland', code: 'CH'}, 
  {name: 'Syrian Arab Republic', code: 'SY'}, 
  {name: 'Taiwan, Province of China', code: 'TW'}, 
  {name: 'Tajikistan', code: 'TJ'}, 
  {name: 'Tanzania, United Republic of', code: 'TZ'}, 
  {name: 'Thailand', code: 'TH'}, 
  {name: 'Timor-Leste', code: 'TL'}, 
  {name: 'Togo', code: 'TG'}, 
  {name: 'Tokelau', code: 'TK'}, 
  {name: 'Tonga', code: 'TO'}, 
  {name: 'Trinidad and Tobago', code: 'TT'}, 
  {name: 'Tunisia', code: 'TN'}, 
  {name: 'Turkey', code: 'TR'}, 
  {name: 'Turkmenistan', code: 'TM'}, 
  {name: 'Turks and Caicos Islands', code: 'TC'}, 
  {name: 'Tuvalu', code: 'TV'}, 
  {name: 'Uganda', code: 'UG'}, 
  {name: 'Ukraine', code: 'UA'}, 
  {name: 'United Arab Emirates', code: 'AE'}, 
  {name: 'United Kingdom', code: 'GB'}, 
  {name: 'United States', code: 'US'}, 
  {name: 'United States Minor Outlying Islands', code: 'UM'}, 
  {name: 'Uruguay', code: 'UY'}, 
  {name: 'Uzbekistan', code: 'UZ'}, 
  {name: 'Vanuatu', code: 'VU'}, 
  {name: 'Venezuela', code: 'VE'}, 
  {name: 'Viet Nam', code: 'VN'}, 
  {name: 'Virgin Islands, British', code: 'VG'}, 
  {name: 'Virgin Islands, U.S.', code: 'VI'}, 
  {name: 'Wallis and Futuna', code: 'WF'}, 
  {name: 'Western Sahara', code: 'EH'}, 
  {name: 'Yemen', code: 'YE'}, 
  {name: 'Zambia', code: 'ZM'}, 
  {name: 'Zimbabwe', code: 'ZW'} 
];


export const monthNames = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];

export const Status = {
  Published: {
    color: "green",
  },
  Completed: {
    color: "green",
  },
  Pending: {
    color: "orange",
  },
  Draft: {
    color: "gray",
  },
  Archived: {
    color: "purple",
  },
  Active: {
    color: "orange",
  },
  Delete: {
    color: "red",
  },
  Resolved: {
    color: "blue",
  },
  Unresolved: {
    color: "orange",
  },
  Expired: {
    color: "orange",
  },
  Delayed: {
    color: "orange",
  },
};

export const employeeStatus = {
  0: {
    color: "green",
  },
  1: {
    color: "orange",
  },
  2: {
    color: "red",
  },
  3: {
    color: "blue",
  },
  4: {
    color: "purple",
  },
};


export const assignmentStatus = {
    0: {
      status: "Completed",
    },
    1: {
      status: "New",
    },
    2: {
      status: "Delayed",
    },
    3: {
      status: "Active",
    },
    4: {
      status: "Expired",
    }
  };

export function getFormatDate(date) {
  if (date != undefined && date != null) {
    let newDate = new Date(date);
    let monthName = monthNames[newDate.getMonth()];
    return monthName + " " + newDate.getDate() + ", " + newDate.getFullYear();
  }
  return "";
}

export function getCalendarFormatDate(date) {
 const dateObject = new Date(date);
 if (isNaN(dateObject.getTime())) {
  return "Invalid Date";
}
  const year = dateObject.getFullYear();
  const month = dateObject.getMonth() + 1; 
  const day = dateObject.getDate();

  const formattedDate = `${month}/${day}/${year}`;
  return formattedDate;
}



export function formatDate(dateString) {
  if (!dateString) return ''; // Return an empty string for undefined or null dates
  
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  // Set time components to midnight
  today.setHours(0, 0, 0, 0);
  yesterday.setHours(0, 0, 0, 0);
  date.setHours(0, 0, 0, 0);

  // Check if it's today
  if (date.getTime() === today.getTime()) {
    return 'Today';
  }

  // Check if it's yesterday
  if (date.getTime() === yesterday.getTime()) {
    return 'Yesterday';
  }

  // Check if it's the latest date
  const latest = new Date(Math.max(today.getTime(), yesterday.getTime(), date.getTime()));
  if (date.getTime() === latest.getTime()) {
    return 'Latest';
  }

  // Format as "Mon DD, YYYY"
  const options = { year: 'numeric', month: 'short', day: 'numeric' };
  return date.toLocaleDateString(undefined, options);
}

export function formatTime(dateString) {
  if (!dateString) return ''; // Return an empty string for undefined or null dates
  
  const formattedTime = new Date(dateString).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  const ampm = new Date(dateString).toLocaleTimeString([], { hour12: true }).slice(-2);
  return (
    <>{formattedTime} {ampm}</>
  );
}

export const optionsTime = [
  // { value: 0, label: "Minutes" },
  // { value: 1, label: "Hours" },
  { value: 0, label: "Days" },
  { value: 1, label: "Weeks" },
  // { value: 4, label: "Months" },
  // { value: 5, label: "Years" },
];

export function getVisibilityStatus(status, menuHeader) {
  switch (status) {
    case "Published":
      return menuHeader !== "Unarchive";
    case "Draft":
      return (
        menuHeader === "Delete" ||
        menuHeader === "Edit" ||
        menuHeader === "Archive"
      );
    case "Archived":
      //return menuHeader === "Unarchive";
      return (
        menuHeader === "Delete" ||
        menuHeader === "Unarchive" 
      );
    case "Active":
      return (      
        menuHeader !== "Edit" &&
        menuHeader !== "Delete" &&
        menuHeader !== "Unarchive"
        //  &&
        // menuHeader === "Archive"
      );
      // return menuHeader === "Archive";
    case "Expired":
      // return menuHeader === "Archive";
      return (
        menuHeader === "Delete" ||
        menuHeader === "Unarchive" 
      );
    default:
      return false;
  }
}

export function prepareSortFilterRequest(val) {
  if (val) {
    let sortColumnDetails = val.split("_");
    let sortColumnName = sortColumnDetails[0];
    return {
      column: { sortField: sortColumnName },
      sortDirection: sortColumnDetails[1] == "asc" ? "asc" : "desc",
    };
  }
}

export const authoringSortby = [
  { text: "Highest rated", value: "Rating_desc" },
  { text: "Lowest rated", value: "Rating_asc" },
  { text: "Date created: Latest", value: "CreatedDate_desc" },
  { text: "Date created: Earliest", value: "CreatedDate_asc" },
  { text: "Last edited", value: "ModifiedDate_desc" },
];

export const authoringSortbyProcess = [
  // { text: "Highest rated", value: "Rating_desc" },
  // { text: "Lowest rated", value: "Rating_asc" },
  { text: "Date created: Latest", value: "CreatedDate_desc" },
  { text: "Date created: Earliest", value: "CreatedDate_asc" },
  { text: "Last edited", value: "ModifiedDate_desc" },
];

export function mapStatusToValue(status) {
  switch (status) {
    case 0:
      return 'Completed';
    case 1:
      return 'Pending';
    case 2:
      return 'Delayed'
    case 3:
      return 'Active';
    case 4:
      return 'Expired';
    case 5:
      return 'Skipped';
    default:
      return -1; 
  }
}

export function getShowNowUrl(parentId, assignmentId, segId, type, id, title, child) {
  switch (type) {
    case "Process":
      return (`/catalogue/preview/${parentId}/${assignmentId}/${segId}/${id}/process/${child}${title}`);
    case "Sequence":
      return (`/catalogue/preview/${parentId}/${assignmentId}/${segId}/${id}/sequence/${child}${title}`);
    case "Module":
      return (`/catalogue/preview/${parentId}/${assignmentId}/${segId}/${id}/module/sm/${title}`);
    default:
      return false;
  }
}

export function countLearning(type, item) {
  switch (type) {
    case "Process":
      // console.log(item,'item');
      // Calculate total formPages in modules
      // const totalFormPagesInModules = item?.modules?.reduce((count, module) => {
      //   return count + module?.formPages?.length;
      // }, 0);

      // // Calculate total formPages in sequences
      // const totalFormPagesInSequences = item?.sequences?.reduce((count, sequence) => {
      //   return count + sequence?.modules?.reduce((moduleCount, module) => {
      //     return moduleCount + module?.formPages?.length;
      //   }, 0);
      // }, 0);
      // return (totalFormPagesInModules+totalFormPagesInSequences);
      const totalModules = item?.moduleCount;
      const totalSequences = item?.sequenceCount;
      return (
        <>
          {`${totalModules} Modules | ${totalSequences} Sequences`}
        </>
      );
    case "Sequence":
      // Calculate total formPages in modules
      // const totalFormPagesInSeqModules = item?.modules?.reduce((count, module) => {
      //   return count + module?.formPages?.length;
      // }, 0);
      const totalFormPagesInSeqModules = item?.modules?.length;
      return (
        <>
          {`${totalFormPagesInSeqModules} `} {totalFormPagesInSeqModules > 1 ? "Modules" : "Module"}
        </>
      );
    case "Module":
      // console.log(item,'item');
      // Calculate total formPages in modules
      // const totalFormPagesInModModules = item?.formPages?.length ? item?.formPages?.length : 0 ;
      const totalForms = item?.formsCount;
      const totalPages = item?.pagesCount;
      return (
        <>
          {`${totalForms} `} {totalForms > 1 ? "Forms | " : "Form | "} {`${totalPages} `} {totalPages > 1 ? "Pages" : "Page"}
        </>
      );
    default:
      return '';
  }
}
export function calculateDueTextAndDaysRemaining(item) {
  let dueText;
  let totalDays;

  // if (item.recommendedTimeDurationType === 0) {
  //   if (item.recommendedTime < 1440) {
  //     dueText = '1 day';
  //     totalDays = 1 + parseInt(item.recommendedTime / 1440);
  //   } else {
  //     const days = Math.ceil(item.recommendedTime / 1440);
  //     dueText = `${days} days`;
  //     totalDays = days;
  //   }
  // } else if (item.recommendedTimeDurationType === 1) {
  //   if (item.recommendedTime < 24) {
  //     dueText = '1 day';
  //     totalDays = 1 + parseInt(item.recommendedTime / 24);
  //   } else {
  //     const days = Math.ceil(item.recommendedTime / 24);
  //     dueText = `${days} days`;
  //     totalDays = days;
  //   }
  // } else if (item.recommendedTimeDurationType === 2) {
  //   dueText = `${item.recommendedTime} days`;
  //   totalDays = parseInt(item.recommendedTime);
  // } else if (item.recommendedTimeDurationType === 3) {
  //   const days = item.recommendedTime * 7;
  //   dueText = `${days} days`;
  //   totalDays = days;
  // }
  if (item.recommendedTimeDurationType === 0) {
    dueText = `${item.recommendedTime} days`;
    totalDays = parseInt(item.recommendedTime);
  } else if (item.recommendedTimeDurationType === 1) {
    const days = item.recommendedTime * 7;
    dueText = `${days} days`;
    totalDays = days;
  }
  const assignedOn = new Date(item.assignedOn); 

  const currentDate = new Date();
  const dueDate = new Date(assignedOn);
  dueDate.setDate(assignedOn.getDate() + totalDays);

  const timeDifference = dueDate - currentDate; 
  const daysRemaining = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  // const daysRemaining = daysRemain === 1 ? '1 day' : daysRemain === 0 ? 'Today' : `${daysRemain} days`;
  //console.log(daysRemaining,"daysRemaining")

  return {
    dueText,
    totalDays,
    dueDate,
    daysRemaining
  };
}


export function getSequencesFirstActive(modules) {
  const firstStatus1Or3Index = modules.findIndex(
    (module) => module.status === 1 || module.status === 3
  );
  return firstStatus1Or3Index;
}

export function getProcessFirstActive(modules) {
  // Find the first module with status 1 or 3
  // const firstStatus1Or3Module = modules.find(
  //   (module) => module.status === 1 || module.status === 3
  // );
  return true;
}

export function  getItemPath(contentType, contentId, notificationId) {
  const defaultPath = ''; // Default path if contentType doesn't match

  const contentTypeToPath = {
    Module: '/authoring/modules/details',
    Process: '/authoring/processes/details',
    Sequence: '/authoring/sequences/details',
    Issue: '/reportedIssues',
  };

  const path = contentTypeToPath[contentType] || defaultPath;

  if (path !== '') {
    return {
      pathname: path,
      state: {
        id: contentId,
        notificationId: notificationId
      },
    };
  } else {
    return {
      pathname: path
    };
  }
};

export function  getEmployeeItemPath(contentType, assignmentSegId, notificationId) {
  const defaultPath = ''; // Default path if contentType doesn't match

  const contentTypeToPath = {
    Module: '/catalogue',
    Process: '/catalogue',
    Sequence: '/catalogue',
    Issue: '',
  };

  const path = contentTypeToPath[contentType] || defaultPath;

  if (path !== '') {
    return {
      pathname: path,
      state: {
        assignmentSegId: assignmentSegId,
        assignmentType: contentType,
        notificationId: notificationId,
        setNotification: true,
      },
    };
  } else {
    return {
      pathname: path
    };
  }
};

export function UserProfileTimeZone(timeZone) {
  if (!timeZone) return ''; // Return an empty string for undefined or null time zones

  const dateObject = new Date();
  
  try {
    const options = {
      // year: "numeric",
      // month: "short",
      // day: "numeric",
      hour: "numeric",
      minute: "numeric",
      hour12: true,
    };
    const localTime = dateObject.toLocaleString("en-US", options);
    return localTime;
  } catch (error) {
    
    return "Invalid Time Zone";
  }
}

export function calculateRemainingDays(pastDate) {
  const currentDate = new Date();
  const parsedPastDate = new Date(pastDate);
  
  // Calculate the difference in milliseconds
  const timeDifference = parsedPastDate - currentDate;

  // Convert the difference to days
  const remainingDays = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

  return remainingDays;
};

export function convertToGB(data) {
  const mb = parseFloat(data);
  if (mb >= 1024) {
    const gb = mb / 1024;
    // return gb.toFixed(2)+'GB';
    return Math.floor(mb / 1024)+'GB';
  } else {
    return mb+'MB';
  }
};

export function convertMBToKB(data) {
  const mb = parseFloat(data);
  const kb = (mb * 1024); // 1 MB = 1024 KB
  return kb;
};

export function convertMBToGB(data) {
  const kb = parseFloat(data);
  // console.log(kb, 'convertMBToGB');
  // const gb = (kb / (1024 * 1024)).toFixed(2);
  // const gb = Math.floor(kb / (1024 * 1024));
  const gb = Math.floor((kb / (1024 * 1024)) * 100) / 100;
  // console.log(gb,'convertMBToGB output');
  return gb + 'GB';
};

export function convertKBToGBNumeric(data) {
  const kb = parseFloat(data);
  // console.log(kb,'kbkbkbkbkbkb');
  // const gb = kb / (1024 * 1024);
  const gb = Math.floor((kb / (1024 * 1024)) * 100) / 100;
  return gb;
};

export function convertGBToKB(data) {
  const gb = parseFloat(data);
  // console.log(kb,'kbkbkbkbkbkb');
  const kb = (gb * 1048576);
  return kb;
};

export function getGUID(string) {
  let first36Characters = '';
  if (string) {
    first36Characters = string.slice(0, 36);
  }
  return first36Characters;
};

export function getRemainingStringFileName(string) {
  let remainingString = '';
  if (string) {
    remainingString = string.slice(36);
  }
  return remainingString;
};

export function getDateComparison(periodStart, periodEnd) {
  const startDate = new Date(periodStart);
  const endDate = new Date(periodEnd);

  // Convert both dates to UTC
  const utcStartDate = Date.UTC(startDate.getFullYear(), startDate.getMonth(), startDate.getDate());
  const utcEndDate = Date.UTC(endDate.getFullYear(), endDate.getMonth(), endDate.getDate());

  // Calculate the difference in days
  const daysDifference = Math.floor((utcEndDate - utcStartDate) / (1000 * 60 * 60 * 24));
  return daysDifference;
}

export function getRemainingDays(futureDate) {
  const currentDate = new Date();
  const targetDate = new Date(futureDate);
  
  const differenceInTime = targetDate.getTime() - currentDate.getTime();
  const remainingDays = Math.ceil(differenceInTime / (1000 * 3600 * 24));
  return remainingDays;
}

export function getAllowedFileTypes(specificAllowedFileTypes) {
  const fileTypeMapping = {
    Document: ["doc", "docx", "pdf"],
    PPT: ["ppt", "pptx"],
    PDF: ["pdf"],
    Image: ["jpeg", "jpg", "png"],
    Video: ["mp4", "avi", "m4v", "mpg", "mov"],
    Audio: ["mp3"]
  };
  const allowedCategories = specificAllowedFileTypes.split(',').map(type => type.trim());
  const allowedFileTypes = allowedCategories.flatMap(type => fileTypeMapping[type] || []);

  return `Allowed types: ${[...new Set(allowedFileTypes)].join(', ')}.`;
};

export function getProratedData(planType, totalAmount, startDate, endDate) {
  let amount;
  let differenceInDaysCurr;

  const currentDate = new Date();
  const startD = new Date(startDate);
  const endD = new Date(endDate);
  
  const differenceInTime = endD - startD; // Difference in milliseconds
  const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert to days

  const differenceInTimeCurr = endD - currentDate; // Difference in milliseconds
  differenceInDaysCurr = differenceInTimeCurr / (1000 * 3600 * 24); // Convert to days
  const oneDayAmount = totalAmount/parseInt(differenceInDays);
  amount = oneDayAmount*parseInt(differenceInDaysCurr);

  function isLeapYear(year) {
    if (year % 4 === 0) {
      if (year % 100 === 0) {
        if (year % 400 === 0) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

  const startYear = startD.getFullYear();
  const endYear = endD.getFullYear();
  let daysInYear = 365;
  for (let year = startYear; year <= endYear; year++) {
    if (isLeapYear(year)) {
      daysInYear = 366;
      break;
    }
  }

  const averageDaysPerMonth = daysInYear / 12; // More precise average days per month
  let timeRemaining;
  if (differenceInDaysCurr >= averageDaysPerMonth) {
    const months = Math.floor(differenceInDaysCurr / averageDaysPerMonth); // Approximate months
    const days = Math.floor(differenceInDaysCurr % averageDaysPerMonth); // Remaining days
    timeRemaining = `${months} Month(s) and ${days} Day(s)`;
  } else {
    timeRemaining = `${Math.floor(differenceInDaysCurr)} Day(s)`;
  }

  // dueText = `${item.recommendedTime} days`;  
  return {
    timeRemaining,
    amount,
  };
}

export function getFileExtension(doc) {
  if (typeof doc !== 'string') {
    return "IconDocument";
  }

  const parts = doc.split('.');
  if (parts.length < 2) {
    return "IconDocument";
  }

  const fileExtension = parts.pop().toLowerCase();
  switch (fileExtension) {
    case 'pdf':
      return "IconDocument";
    case 'png':
    case 'jpg':
    case 'jpeg':
      return "IconPhoto";
    case 'mp3':
    case 'wav':
      return "IconAudio";
    case 'mp4':
    case 'avi':
    case 'm4v':
    case 'mpg':
    case 'mov':
      return "IconVideo";
    default:
      return "IconDocument";
  }
}

export function getProratedUpgradeAmount(totalAmount, startDate, endDate) {
  const currentDate = new Date();
  const startD = new Date(startDate);
  const endD = new Date(endDate);

  // If the start date is today, return the full amount
  if (startD.toDateString() === currentDate.toDateString()) {
    return {
      getProratedAmount: totalAmount,
    };
  }

  const differenceInTime = endD - startD; // Difference in milliseconds
  const differenceInDays = differenceInTime / (1000 * 3600 * 24); // Convert to days
  const oneDayAmount = totalAmount / differenceInDays; // Get one day amount

  const differenceInTimeCurr = endD - currentDate; // Difference in milliseconds
  const differenceInDaysCurr = differenceInTimeCurr / (1000 * 3600 * 24); // Convert to days
  const getProratedAmount = oneDayAmount * differenceInDaysCurr;

  return {
    getProratedAmount
  };
}

export function isMoreThan30Minutes(currentPeriodStart) {
  const currentTime = new Date();
  const periodStartTime = new Date(currentPeriodStart);
  const timeDifference = currentTime - periodStartTime; // Difference in milliseconds
  return timeDifference > 30 * 60 * 1000; // 30 minutes in milliseconds
};