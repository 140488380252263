import React, { useState, useEffect, useRef } from "react";
import RadioButton from "../../../../components/RadioButton/RadioButton";
import { IconsGallery, IconsCross } from "./Icons";
import TextInput from "../../../../components/FormikInputs/TextInput";
import { IconPlus, FileUploadPreview, IconCross, SpinIcon, TickFill } from "../../../../icons/Icons";
import { Modal, Button } from "react-bootstrap";
import CheckBox from "../../../../components/CheckBox/CheckBox";
import { fileUpload } from "../../../../api/common-store-api";
import { useDispatch, useSelector } from "react-redux";
import { getStorageAsync } from "../../../../reducers/subscription-reducers";
import ModalStorageLimitReachedPopup from "../../../../components/ModalPopup/ModalStorageLimitReachedPopup";
import { getRemainingStringFileName } from "../../../../common";

const CommonOption = ({ type, options, onAdd, setMethod, setFormValid }) => {
  const dispatch = useDispatch();
  const { storageData } = useSelector((state) => state.subscription);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);
  
  const [optionValue, setoptionName] = useState(null);
  const [showError, setshowError] = useState({ show: false, error: "" });
  const refImageMultipleChoice = useRef();
  const refImageCheckbox = useRef()
  let currentSelectedIndex = 0

  // const onChangeFile = async (e) => {
  //   console.log(isFileUpload,'file change isFileUpload');
  //   const files = e.target.files;
  //   let formData = new FormData();
  //    formData.append("file", files[0]);
  //    setIsUploading(true);
  //   const responseFile = await fileUpload(formData);
  //   console.log(responseFile,'responseFile');
  //   options[currentSelectedIndex].uploadfile = files[0];
  //   options[currentSelectedIndex].contentType = 0;
  //   // options[currentSelectedIndex].contentPath = responseFile.data;
  //   options[currentSelectedIndex].contentPath = `${responseFile?.data?.fileKey}${responseFile?.data?.fileName}`;
  //   onAdd(options, setMethod);
  //   setIsUploading(false);
  // };
  const onChangeFile = async (e) => {
    const files = e.target.files;
    console.log(files,'filesfilesfilesfiles');
    let formData = new FormData();
    formData.append("file", files[0]);
    
    const updatedOptions = [...options];
    updatedOptions[currentSelectedIndex].isUploading = true;
    onAdd(updatedOptions, setMethod);

    const responseFile = await fileUpload(formData);
    updatedOptions[currentSelectedIndex].uploadfile = files[0];
    updatedOptions[currentSelectedIndex].contentType = 0;
    updatedOptions[currentSelectedIndex].contentPath = `${responseFile?.data?.fileKey}${responseFile?.data?.fileName}`;
    updatedOptions[currentSelectedIndex].isUploading = false;

    onAdd(updatedOptions, setMethod);
  };
  const onClickSelectFileMCQ = (e, m, index) => {
    console.log(isFileUpload,'isFileUpload');
    // console.log(refImageMultipleChoice,'refImageMultipleChoice');
    if (isFileUpload === true) {
      setIsStorageLimitReached(true);
    } else {
      currentSelectedIndex = index;
      e.preventDefault();
      // Reset the input value to allow selecting the same file again
      refImageMultipleChoice.current.value = '';
      refImageMultipleChoice.current.click();
    }
  };

  const onClickSelectFileCheckbox = (e, m, index) => {
    console.log(isFileUpload,'isFileUpload');
    if (isFileUpload === true) {
      setIsStorageLimitReached(true);
    } else {
      currentSelectedIndex = index;
      e.preventDefault();
      // Reset the input value to allow selecting the same file again
      refImageCheckbox.current.value = '';
      refImageCheckbox.current.click();
    }    
  };


  const onAddOption = () => {
    if (type === 4 && options.length >= 10) {
      setshowError({ show: true, error: "Maximum of 10 options can be added" });
      setFormValid(true);
      return;
    }
    if (type === 5 && options.length >= 20) {
      setshowError({ show: true, error: "Maximum of 20 options can be added" });
      setFormValid(true);
      return;
    }
    if (optionValue && optionValue.trim() !== "") {
      const isExists =
        options?.filter((f) => {
          return f.optionValue === optionValue;
        }) ?? [];
      if (isExists.length === 0 && onAdd) {
        options.push({ "optionValue": optionValue, "contentPath":"" });
        setoptionName("");
        setFormValid(true);
        onAdd(options, setMethod);
      } else {
        setshowError({ show: true, error: "Option value is already exist" });
        setFormValid(false);
      }
    } else {
      setshowError({ show: true, error: "Option value is reqired" });
      setFormValid(false); 
    }
  };
  const onChange = (value) => {
    setoptionName(value);
    if (showError.show && value) {
      setshowError({ show: false, error: "" });
    }
  };
  const onRemoveClick = (e, requestValue) => {
    e.preventDefault();
    const newoptionsList = options.filter(
      (f) => f.optionValue !== requestValue
    );
    // console.log(newoptionsList,"newoptionsList")
    const isFormValid = newoptionsList?.length > 0;
    setshowError({ show: true, error: "Option value is required" });
    setFormValid(isFormValid);
    onAdd(newoptionsList, setMethod);
  };

  useEffect(() => {
    console.log(options, "options")
    if (type === 5 || type === 4 || type === 3) {
      if (options?.length === 0 ) {
        setshowError({ show: true, error: "Option value is required" });
        setFormValid(false);
      } else {
        setshowError({ show: false, error: "" });
        setFormValid(true);
      }
    }
  }, [type]);

  useEffect(() => {
    dispatch(getStorageAsync());
  }, []);

  useEffect(() => {
    if (storageData && storageData?.usedStorage >= storageData?.totalStorage) {
      setIsFileUpload(true);
    }
  }, [storageData]);
 
  return (
    <div className="mb-4">
      <h6 className="font-medium">Options</h6>
      <div className="h6 font-medium formTypeComMultipleChoice">
        {options &&
          options.length > 0 &&
          options.map((m, index) => {
            return type === 5 ? (
              <div className="mt-3 d-flex justify-content-between">
                <span>
                  {index + 1} {". "}
                  {m.optionValue}
                </span>
                <div className="iconGroupRadio">
                  <button onClick={(e) => onRemoveClick(e, m.optionValue)}>
                    <IconsCross />
                  </button>
                </div>
              </div>
            ) : type === 3 ? (
              <>
                <div className="mt-3 d-flex justify-content-between me-4 align-items-center">
                  <RadioButton
                    disabled={true}
                    name="MultipleChoice"
                    text={m.optionValue}
                  />
                  <div className="iconGroupRadio">
                    <input
                      type="file"
                      accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                      onChange={(e) => onChangeFile(e)}
                      style={{ display: "none" }}
                      ref={refImageMultipleChoice}
                      disabled={isFileUpload}
                    />
                    <button onClick={(e) => onClickSelectFileMCQ(e, m, index)}>
                      <IconsGallery />
                    </button>
                    <button onClick={(e) => onRemoveClick(e, m.optionValue)}>
                      <IconsCross />
                    </button>
                    {m.isUploading && (
                      <div className="spinArea">
                        <div className="animateClass"><SpinIcon /></div>
                      </div>
                    )}
                  </div>
                </div>
                {/* {m.uploadfile && (m.uploadfile.name !== undefined) && ( */}
                {m.contentPath && (m.contentPath !== undefined) && (
                  // <p>{m.uploadfile.name}</p>
                  <div className="d-flex position-relative flex-row align-items-center justify-content-between bg-gray-600 borderAll p-1 rounded-6 me-3 mt-2">
                    <div className="d-flex flex-row w-90">
                      <p className="my-auto"><FileUploadPreview /></p>
                      <p className="my-auto mx-1 font-bold text-truncate">
                        {/* {m.uploadfile.name} */}
                        {/* {m.contentPath} */}
                        {getRemainingStringFileName(m.contentPath)}
                      </p>
                    </div>

                    <Button variant=""
                      onClick={() => {
                        options[index].uploadfile = {}
                        onAdd(options, setMethod, index);
                      }}
                    >
                      <IconCross /> 
                    </Button>
                   <div className="position-absolute" style={{right:"-2rem", top:"50%", transform:"translateY(-50%)"}}><TickFill/></div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="mt-3 d-flex justify-content-between me-4">
                  <CheckBox disabled={true} text={m.optionValue} />
                  <div className="iconGroupRadio">
                    <input
                      type="file"
                      accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .txt, .pdf"
                      onChange={(e) => onChangeFile(e)}
                      style={{ display: "none" }}
                      ref={refImageCheckbox}
                      disabled={isFileUpload}
                    />
                    <button onClick={(e) => onClickSelectFileCheckbox(e, m, index)}>
                      <IconsGallery />
                    </button>
                    <button onClick={(e) => onRemoveClick(e, m.optionValue)}>
                      <IconsCross />
                    </button>
                    {m.isUploading && (
                      <div className="">
                        <div className="animateClass"><SpinIcon /></div>
                      </div>
                    )} 
                  </div>
                </div>

                {/* {m.uploadfile && (m.uploadfile.name !== undefined) && ( */}
                {m.contentPath && (m.contentPath !== undefined) && (
                  <div className="d-flex flex-row align-items-center justify-content-between bg-gray-600 borderAll p-1 rounded-6 me-3 mt-2">
                    <div className="d-flex flex-row w-90">
                      <p className="my-auto"><FileUploadPreview /></p>
                      <p className="my-auto mx-1 font-bold text-truncate">
                        {/* {m.uploadfile.name} */}
                        {/* {m.contentPath} */}
                        {getRemainingStringFileName(m.contentPath)}
                      </p>
                    </div>

                    <Button variant=""
                      onClick={() => {
                        options[index].uploadfile = {}
                        onAdd(options, setMethod, index);
                      }}
                    >
                      <IconCross /> <TickFill/>
                    </Button>
                  </div>
                )}
              </>
            );
          })}
        <>
        <div className="row mt-3">
          <div className="col-md-6">
            <input
              type="text"
              value={optionValue}
              className="form-control text-input"
              onChange={(e) => onChange(e.target.value)}
              maxLength={(type === 3 || type === 5 ) && 80}
            />
            {showError.show && showError.error && (
              <label className="errorValidation" style={{ color: "red" }}>{showError.error}</label>
            )}
          </div>
          <div className="col-md-6">
            <Button
              variant=""
              className="btnLink flexDiv"
              style={{paddingTop:10}}
              onClick={(e) => onAddOption()}
            >
              <IconPlus /> Add Option
            </Button>
          </div>
        </div>

        {/* <div className="mt-3">
          <input
            type="text"
            value={optionValue}
            className="form-control text-input"
            style={{ width: 50 + "%" }}
            onChange={(e) => onChange(e.target.value)}
          />
          {showError.show && showError.error && (
            <label className="errorValidation" style={{ color: "red" }}>{showError.error}</label>
          )}
        </div> */}
        </>

      </div>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isStorageLimitReached}
      >
        <ModalStorageLimitReachedPopup onClose={setIsStorageLimitReached} />
      </Modal>
    </div>
  );
};

export default CommonOption;
