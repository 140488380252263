import React, { useState, useEffect, useRef } from "react";
import Search from "../../components/Search/Search";
const FilterList = ({ f, selectAlls }) => {
  const formRef = useRef();
  
  const [showAll, setshowAll] = useState(f.showAll);
  const [list, setlist] = useState([]);
  const [selectAll, setselectAll] = useState(false);
  const [placeHolder, setPlaceHolder] = useState("Search...");

  const onSearch = (value) => {
    setlist(
      f.options.filter(
        (f) =>
          f?.name?.toLowerCase().trim().indexOf(value.trim().toLowerCase()) > -1
      )
    );
  };

  const hadnleSelectAll = (event) => {
    if (event.target.checked) {
      setselectAll(true);
      f.options?.map((item) => {
        let index = f.selectedoptions.indexOf(item.id);
        f.selectedoptions.splice(index, 1);
      });
      f.options?.map((m) => {
        f.selectedoptions.push(m.id);
      });
      var latestData = f.selectedoptions;
      f.onFilterChange(f.filterColumnName, latestData);
    } else {
      f.options?.map((item) => {
        let index = f.selectedoptions.indexOf(item.id);
        f.selectedoptions.splice(index, 1);
      });
      f.onFilterChange(f.filterColumnName, f.selectedoptions);
      setselectAll(false);
    }

    if (f.options.length === f.selectedoptions.length) {
      setselectAll(true);
    } else {
      setselectAll(false);
    }
  };

  useEffect(() => {
    if (f.options && list.length === 0) {
      setlist(f.options);
    }
  }, [f?.options]);

  useEffect(() => {
    if (f.header && f.header === "Teams") {
      setPlaceHolder("Search by Team");
    }
    if (f.header && f.header === "Processes") {
      setPlaceHolder("Search by Processes");
    }
    if (f.header && f.header === "Sequence") {
      setPlaceHolder("Search by Sequence");
    }
    if (f.header && f.header === "Module") {
      setPlaceHolder("Search by Modules");
    }
    // console.log(f.header,'f.header');
  }, [f?.header]);

  useEffect(() => {
    if (f.selectedoptions.length === 0) {
      setselectAll(selectAlls);
    }
  }, [f]);
  // console.log(f,'selectedoptions');

  return (
    <>
      {f.isSearchble === true && <Search onBlur={onSearch} placeHolder={placeHolder} title="filter" />}
      <div key={"selectAll"} className="text-md text-gray-200 pt-1 pb-1">
        <label className="checkBox">
          <input
            value="selectAll"
            type="checkbox"
            onChange={(e) => hadnleSelectAll(e)}
            checked={selectAll}
          />
          <span className="checkBoxCheck"></span>
          <span className="">All</span>
        </label>
      </div>
      {list &&
        list.length > 0 &&
        list.map((item, index) => {
          return (
            <>
              {showAll === true ? (
                <div
                  key={index + "_" + item.name}
                  className="text-md text-gray-200 pt-1 pb-1"
                >
                  <label className="checkBox">
                    <input
                      value={item.name}
                      type="checkbox"
                      onChange={(e) => {
                        if (e.target.checked) {
                          f.selectedoptions.push(item.id);
                          var latestData = f.selectedoptions;
                          f.onFilterChange(f.filterColumnName, latestData);
                          if (list.length === f.selectedoptions.length) {
                            setselectAll(true);
                          } else {
                            setselectAll(false);
                          }
                        } else {
                          let index = f.selectedoptions.indexOf(item.id);
                          f.selectedoptions.splice(index, 1);
                          f.onFilterChange(
                            f.filterColumnName,
                            f.selectedoptions
                          );
                          setselectAll(false);
                        }
                      }}
                      checked={f.selectedoptions?.indexOf(item.id) > -1}
                    />
                    <span className="checkBoxCheck"></span>
                    {item.name && <span className="">{item.name}</span>}
                  </label>
                </div>
              ) : (
                index < 4 && (
                  <div
                    key={index + "_" + item.name}
                    className="text-md text-gray-200 pt-1 pb-1"
                  >
                    <label className="checkBox">
                      <input
                        value={item.name}
                        type="checkbox"
                        onChange={(e) => {
                          if (e.target.checked) {
                            f.selectedoptions.push(item.id);
                            var latestData = f.selectedoptions;
                            f.onFilterChange(f.filterColumnName, latestData);
                            if (list.length === f.selectedoptions.length) {
                              setselectAll(true);
                            } else {
                              setselectAll(false);
                            }
                          } else {
                            let index = f.selectedoptions.indexOf(item.id);
                            f.selectedoptions.splice(index, 1);
                            f.onFilterChange(
                              f.filterColumnName,
                              f.selectedoptions
                            );
                            setselectAll(false);
                          }
                        }}
                        checked={f.selectedoptions?.indexOf(item.id) > -1}
                      />
                      <span className="checkBoxCheck"></span>
                      {item.name && <span className="">{item.name}</span>}
                    </label>
                  </div>
                )
              )}
            </>
          );
        })}

      {showAll === false && list.length > 4 && (
        <span className="text-primary-200 text-sm cp"
          onClick={() => {
            setshowAll(!showAll);
          }}
        >
          + {list.length - 4} more
        </span>
      )}

      {showAll === true && (
        <span className="text-primary-200 text-sm cp"
          onClick={() => {
            setshowAll(!showAll);
          }}
        >
         - Less
        </span>
      )}
    </>
  );
};
export default FilterList;
