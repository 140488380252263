import React, { useState, useEffect } from "react";
import { Row, Col, Container, Accordion, Modal, Button } from "react-bootstrap";
import {
  IconStar,
  IconTopSearch,
  IconTick,
  IconHamberger,
  IconDuplicate,
  IconEdit2,
  IconDelete,
  IconArchive,
  IconEyeColored,
  FeedbackIconGrey,
  IconExclamation,
} from "../../../icons/Icons";
import Loader from "../../../components/Loader/Loader";
import OwnerImg from "../../../assets/images/Account.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import NoContent from "../Processes/NoContent";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import { getFormatDate, getVisibilityStatus, Status } from "../../../common";
import {
  deleteSequenceAsync,
  getSequenceDetailsAsync,
  getSequencePreviewAsync,
} from "../../../reducers/sequence-reducers";
import { useDispatch, useSelector } from "react-redux";
import EmployeeAssignementPopup from "../../../components/EmployeeAssignementPopup/EmployeeAssignementPopup";
import { AssignmentActionType } from "../../../services/DataConstants";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import AudienceModal from "../Audience/AudienceModal";
import FeedbackView from "../Processes/FeedbackView";
import { getModulePreviewAsync } from "../../../reducers/module-reducers";
import EmptyState from "../../../components/EmptyState/EmptyState";
import ModalMessageAlertPopup from "../../../components/ModalPopup/ModalMessageAlertPopup";
import DateTimeConverter from "../../../components/DateTimeConverter/DateTimeConverter";
//import AssignProcessModal from "../Processes/AssignProcessModal";

// import ProcessExpiredModal from "./ProcessExpiredModal";
// import ProcessFeedbackModal from "./ProcessFeedbackModal";

import {
  getUserProfileAsync,
  getMarkNotificationReadAsync,
} from "../../../reducers/user-reducers";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader"

const SequencesDetail = ({ id }) => {
  const [modalAssignProcess, setModalAssignProcess] = useState(false);
  const [sequencePreview, setSequencePreview] = useState([]);
  // const [modalExpiredProcess, setModalExpiredProcess] = useState(false);
  // const [modalFeedbackProcess, setModalFeedbackProcess] = useState(false);

  const [formDataReady, setFormDataReady] = useState(false);
  const dispatch = useDispatch();
  const currentSequence = useSelector(
    (state) => state.sequence.currentSequence
  );
  const { state } = useLocation();
  const navigate = useHistory();
  const [sequenceId, setSequenceId] = useState(null);
  const [SequencesDetails, setAdditionalStorage] = useState(null);
  const [listData, setlistData] = useState(null);
  const [AudienceList, setAudienceList] = useState(null);
  const [showAlertMessagePopup, setShowAlertMessagePopup] = useState(false);

  console.log(state,'statestatestate');

  const [userProfiles, setUserProfiles] = useState(null);
  useEffect(() => {
    getUserProfile();
  }, []);

  useEffect(() => {
    markNotificationRead();
  }, [state?.notificationId, userProfiles]);

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfiles(res.payload);
      }
    });
  };

  const markNotificationRead = async () => {
    const reqBody = {
      userId: userProfiles?.employeeId,
      notificationId: state?.notificationId,
    };

    const response = await dispatch(getMarkNotificationReadAsync(reqBody));
    if (response.payload) {
      console.log(response.payload,'markOtherNotificationRead');
    }
  };

  const setData = async () => {
    // const sequensePreviewResponse = await dispatch(getSequencePreviewAsync(state.id));
    // setSequencePreview(sequensePreviewResponse.payload[0]);

    let response = await dispatch(getSequenceDetailsAsync(state?.id));
    console.log(response,"responseresponseresponseresponse");
    setAdditionalStorage(response?.payload);
    setlistData(response?.payload?.modules ?? []);
    setAudienceList(response?.payload?.assignment)
    setFormDataReady(true);
  };

  // const getPreviewData = async (id, c) => {
  //   let previewData = {};
  //   const modulePreviewResponse = await dispatch(getModulePreviewAsync(id));
  //   previewData = modulePreviewResponse.payload[0];
  //   localStorage.setItem(
  //     "propsData",
  //     JSON.stringify({
  //       state: {
  //         propsData: previewData,
  //         opertionType: "preview",
  //         moduleId: state.id,
  //         redirectFrom: `/authoring/sequences/details`,
  //         redirectTo: `/authoring/preview`,
  //         headerFirstTitle: SequencesDetails.title,
  //         headerSecondTitle: c.title,
  //       },
  //     })
  //   );
  // };


  // console.log('state 338 = ', state)

  useEffect(() => {
    console.log(state,'statestate');
    if (state !== null) {
      setSequenceId(state?.id);
    }
    if (state?.id !== null) {
      setData();
    }
  }, [state?.id]);
  
  const [deleteModal, setDeleteModal] = useState(false);
  const [archiveModal, setArchiveModal] = useState(false);
  const [assignModal, setAssignModal] = useState(false);
  const [openAudienceModal, setOpenAudienceModal] = useState(false);
  const [openFeedbackModal, setOpenFeedbackModal] = useState(false);
  const [duplicateModal, setDuplicateModal] = useState(false);
  const [redirectUrl, setRedirectUrl] = useState('');

  const onConfirmDuplicate = async (confirmstatus) => {
    // Redirect to the desired URL
    // window.location.href = redirectUrl;
    // navigate.push('/authoring/modules/details');
    // navigate.push(redirectUrl);
    navigate.push(redirectUrl, { propsData: SequencesDetails, operationType: 'Duplicate', redirectTo: "/authoring/sequences/details" });
    setDuplicateModal(false);
  }

  const onConfirm = async (confirmstatus) => {
    if (sequenceId != null && sequenceId != undefined) {
      const jsonData = {
        id: sequenceId,
        status: confirmstatus,
      };
      const response = await dispatch(deleteSequenceAsync(jsonData));
          if (confirmstatus === "Archive") {
        if (response.payload) {
          ToasterAlertSucces(response, ErrrorMessage.Archive);
          navigate.push("/authoring/sequences");
        } else {
          setArchiveModal(false);
          setShowAlertMessagePopup(true);
        }
      } else {
        ToasterAlertSucces(response, ErrrorMessage.DELETE);
        navigate.push("/authoring/sequences");
      }
    }
  };
  const searchData = (e) => {
    const list = [];
    SequencesDetails.modules.filter((f) => {
      if (
        f.title
          .trim()
          .toLowerCase()
          .indexOf(e.target.value.trim().toLowerCase()) > -1
      ) {
        list.push({ ...f, contenttype: "pages" });
      }
    });

    setlistData(list);
  };
  const handleCloseAssignModal = () => {
    setAssignModal(false);
    setOpenAudienceModal(false);
    setOpenFeedbackModal(false);
  };

  console.log(listData,'listData');
  console.log(SequencesDetails,'SequencesDetails');

  return (
    <>
      {/* <div>
        ProcessesDetail
        <br />
        <button onClick={() => setModalAssignProcess(true)}>
          AssignProcessModal
        </button>
        <br />
      <button onClick={() => setModalExpiredProcess(true)}>
          setModalExpiredProcess
        </button>
        <br />
        <button onClick={() => setModalFeedbackProcess(true)}>
          setModalFeedbackProcess
        </button> 
      </div>*/}
      {SequencesDetails !== undefined && formDataReady !== false && (
        <div className="pages">
          <RenewalHeader />
          <header className="pageHeader">
            <Container fluid>
              <Row className="align-items-center">
                <Col className="col-lg-4">
                  <div className="d-flex align-items-center gap-2 w-100">
                    <Link to="/authoring/sequences">
                      <span className="arrow left"></span>
                    </Link>
                    <div className="mw-90">
                      <p className="h6 text-gray-200 mb-1">Sequences</p>
                      <h3 className="text-truncate">{SequencesDetails.title}</h3>
                    </div>
                  </div>
                </Col>
                <Col className="text-end col-lg-8">
                  <div className="d-flex align-items-center justify-content-end SupportIconBtn gap-4">
                    {/* {getVisibilityStatus(
                      SequencesDetails.status,
                      "Duplicate"
                    ) && ( */}
                        <Link className="m-0"
                          to={{
                            pathname: `/authoring/sequences/create`,
                            state: {
                              propsData: SequencesDetails,
                              opertionType: "Duplicate",
                              redirectTo: "/authoring/sequences/details",
                            },
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            setDuplicateModal(true);
                            setRedirectUrl(e.currentTarget.pathname);
                          }}
                        >
                          <Button variant="" className="border-0 p-0">
                            <div className="d-flex gap-2 align-items-center">
                              <IconDuplicate />
                              <p className="h6 text-gray-300">Duplicate</p>
                            </div>
                          </Button>
                        </Link>
                      {/* )} */}
                    {/* {getVisibilityStatus(SequencesDetails.status, "Delete") && (
                      SequencesDetails.audience === 0 && (
                      <Button
                        variant=""
                        className="border-0 p-0 m-0"
                        onClick={() => setDeleteModal(true)}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <IconDelete />
                          <p className="h6 text-gray-300">Delete</p>
                        </div>
                      </Button>)
                    )} */}
                    {getVisibilityStatus(SequencesDetails.status, "Delete") && (
                      SequencesDetails.status !== "Archived" && SequencesDetails?.audience === 0 && (
                      <Button
                        variant=""
                        className="border-0 p-0 m-0"
                        onClick={() => setDeleteModal(true)}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <IconDelete />
                          <p className="h6 text-gray-300">Delete</p>
                        </div>
                      </Button>)
                    )}
                    {getVisibilityStatus(SequencesDetails.status, "Edit") && (
                      <Link className="m-0"
                        to={{
                          pathname: `/authoring/sequences/edit`,
                          state: {
                            propsData: SequencesDetails,
                            opertionType: "Edit",
                            redirectTo: "/authoring/sequences/details",
                          },
                        }}
                      >
                        <div className="d-flex gap-2 align-items-center">
                          <IconEdit2 />
                          <p className="h6 text-gray-300">Edit</p>
                        </div>
                      </Link>
                    )}

                    {/* {getVisibilityStatus(
                      SequencesDetails.status,
                      "Unarchive"
                    ) && (
                        <Button
                          variant=""
                          className="border-0 p-0"
                        // onClick={() => setArchiveModal(true)}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <IconArchive />
                            <p className="h6 text-gray-300">Un-Archive</p>
                          </div>
                        </Button>
                      )} */}

                    {getVisibilityStatus(
                      SequencesDetails.status,
                      "Archive"
                    ) && (
                        <Button
                          variant=""
                          className="border-0 p-0 m-0"
                          onClick={() => setArchiveModal(true)}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <IconArchive />
                            <p className="h6 text-gray-300">Archive</p>
                          </div>
                        </Button>
                      )}
                      <div class="pipeSeparator">&nbsp;</div>
                    {/* <Link
                      to={{
                        // pathname: `/authoring/sequence/feedback`,
                        state: {
                          propsData: SequencesDetails,
                          opertionType: "Edit",
                          redirectTo: "/authoring/sequence/feedback",
                          authoringName: "Sequences"
                        },
                      }}
                    >
                      
                      <Button variant="" className="border-0 p-0"
                          onClick={(()=> setOpenFeedbackModal(true))}>
                          <div className="d-flex gap-2 align-items-center">
                            <FeedbackIconGrey />
                            <p className="h6 text-gray-300">Feedback</p>
                          </div>
                        </Button>
                     
                    </Link> */}
                    {/* <div className="pipeSeparator pe-4">&nbsp;</div> */}
                    {/* <Link
                        to={{
                          pathname: `/authoring/preview`,
                          state: {
                            propsData: SequencesDetails,
                            opertionType: "preview",
                            redirectTo: "/authoring/preview",
                          },
                        }}
                      >
                      <Button variant="white">
                        <div className="d-flex gap-2 align-items-center">
                          <IconEyeColored />
                          <span>Preview</span>
                        </div>
                      </Button></Link> */}
                      {getVisibilityStatus(SequencesDetails.status, "Assign") && (
                    <Button className="" variant="secondary"
                      onClick={() => { setAssignModal(true) }}
                      // disabled={SequencesDetails.isRestricted === true}
                    >Assign </Button>)}
                                       <ActivityLog />
                  </div>
                </Col>
              </Row>
            </Container>
          </header>

          <div className="pageOuter">
            <Container fluid>
              <div className="page detailModulePage d-flex flex-column flex-grow-1">
                <div className="p-32 pb-4 borderBottom w-100">
                  <div className="d-flex align-items-center mb-32">
                    <p className="h6 ">
                      <span className="text-gray-300">Created on</span>{" "}
                      {getFormatDate(SequencesDetails.createdDate)}{" "}
                      {/* <DateTimeConverter utcDateTime={SequencesDetails.createdDate} /> */}
                      {SequencesDetails.modifiedBy && SequencesDetails.modifiedBy !== null && (
                        <><span className="text-gray-300">
                          <span className="text-gray-400">|</span> Edited on
                        </span>{" "}
                        {getFormatDate(SequencesDetails.modifiedDate ? SequencesDetails.modifiedDate : SequencesDetails.createdDate)}{" "}
                        {/* <DateTimeConverter utcDateTime={SequencesDetails.modifiedDate ? SequencesDetails.modifiedDate : SequencesDetails.createdDate} /> */}
                        </>
                      )}
                      
                      <span className="text-gray-300">
                        <span className="text-gray-400">|</span> Created by
                      </span>{" "}
                      <span className="text-primary-100">
                        {SequencesDetails?.createdUserDetail?.name}
                      </span>
                    </p>
                    <div className="ms-2">
                      <p className="text-md mb-0 d-flex align-items-center gap-2">
                       
                        <span
                          className={`tableTag ${Status[SequencesDetails.status].color
                            } text-md  `}
                        > <IconTick />
                          {SequencesDetails.status}
                        </span>
                      </p>
                    </div>
                  </div>

                  <div></div>

                  <div className="d-flex feedbackData">
                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Used in Processes</p>

                      <h3 className="font-medium">
                        {SequencesDetails.usedInProcesses ?? 0}
                      </h3>
                    </div>

                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Total Modules</p>

                      <h3 className="font-medium">
                        {SequencesDetails.totalModule}
                      </h3>
                    </div>
                    <div className="dataItem">
                    <p className="h6 mb-3 text-gray-300">Feedback Rating</p>
                    <Link
                      to={{
                        // pathname: `/authoring/sequence/feedback`,
                        state: {
                          propsData: SequencesDetails,
                          opertionType: "Edit",
                          redirectTo: "/authoring/sequence/feedback",
                          authoringName: "Sequences"
                        },
                      }}
                    >
                      
                      <Button variant="" className="d-flex border-0 align-items-center p-0"
                          onClick={(()=> setOpenFeedbackModal(true))}>
                         <IconStar />
                        <h3 className="ps-1 font-medium">
                          {SequencesDetails.avgRating.toFixed(1)}
                        </h3>
                      </Button>
                      <Button variant="" className="d-flex border-0 align-items-center p-0 btn-sm"
                          onClick={(()=> setOpenFeedbackModal(true))}>
                          <p className="text-primary-200 text-md mt-2 mb-0">
                            View all ratings
                          </p>
                      </Button>
                    </Link>
                    </div>

                    <div className="dataItem">
                        <p className="h6 mb-3 text-gray-300">Audience</p>
                         <button onClick={(()=> setOpenAudienceModal(true))}>
                        <h3 className="font-medium">
                          {SequencesDetails.audience}
                        </h3></button>
                      </div>
                    <div className="dataItem">
                      <p className="h6 mb-3 text-gray-300">Sequence Owner</p>
                      <h3 className="font-medium">
                          {SequencesDetails.sequenceOwnerCount}
                        </h3>
                      {/* <div className="d-flex">
                        {SequencesDetails.sequenceOwner != undefined &&
                          SequencesDetails.sequenceOwner.length > 0 &&
                          SequencesDetails.sequenceOwner.map((c, index) => (
                            <>
                              <div key={index} className="headImg moduleImg">
                                <img
                                  key={"img" + index}
                                  src={OwnerImg}
                                  alt=""
                                />
                              </div>
                            </>
                          ))}
                      </div> */}
                    </div>
                  </div>
                </div>

                <div className="p-32 bg-gray-600 flex-grow-1 overflow-auto">
                  <h5 className="mb-3">Content</h5>

                  <div className="input-group myInput myInputSearch mb-20">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Search by content title"
                      onChange={(e) => searchData(e)}
                    />
                    <IconTopSearch />
                  </div>
                  
                  {listData !== undefined && listData.length <= 0 ? (
                    <EmptyState message="No content found" />
                  ) : (
                  <div className="row">
                    <div className="col-lg-9">
                      <Accordion>
                        {listData !== undefined &&
                          listData.length > 0 &&
                          listData.map((c, index) => (
                            <Accordion.Item
                              eventKey={index}
                              className="mb-12 cardContent seqModule"
                            >
                              <Accordion.Header>
                                <div className="d-flex justify-content-between align-items-center p-12 pe-3 w-100">
                                  <div className="d-flex gap-3 align-items-start">
                                    <div className="count">
                                      <span className="text-xs">
                                        {index + 1}
                                      </span>
                                    </div>
                                    <div className="moduleTitle">
                                      <p className="text-primary-200 text-md mb-0">
                                        Module
                                      </p>
                                      <p className="font-medium mb-0 text-gray-100">
                                        {c.title}
                                        {/* {c?.status === "Expired" && (
                                          <IconExclamation />
                                        )} */}
                                      </p>
                                    </div>
                                  </div>

                                  <div>
                                    <p className="text-md text-gray-300 mb-0">
                                      {c.time}
                                    </p>
                                  </div>
                                  <Link
                                    // to={{
                                    //   pathname: `/authoring/preview`,
                                    //   state: {
                                    //     propsData: sequencePreview,
                                    //     opertionType: "preview",
                                    //     redirectTo: `/authoring/preview`,
                                    //     headerFirstTitle: SequencesDetails.title,
                                    //     headerSecondTitle: c.title,
                                    //   },
                                    // }}
                                    to={`/authoring/preview/${c.moduleId}/sequence/${c.type}/${c.title}`}
                                    target="_blank"
                                  >
                                    <Button variant="white" size="sm" 
                                    // onClick={()=>  {
                                    //   getPreviewData(c.moduleId, c)}}
                                      >
                                      <div className="d-flex gap-2 align-items-center">
                                        <IconEyeColored />
                                        <span className="ps-10">Preview</span>
                                      </div>
                                    </Button>
                                  </Link>
                                </div>
                              </Accordion.Header>
                              <Accordion.Body>
                                {c.sortedContent != undefined &&
                                  c.sortedContent.length > 0 &&
                                  c.sortedContent.map((cc, index) => (
                                    <div className="d-flex justify-content-between align-items-center cardBody mb-20">
                                      <div className="d-flex gap-3 align-items-center justify-content-start">
                                        <div className="count text-xs text-gray-200 borderPurple">
                                          {index + 1}
                                        </div>
                                        <div>
                                          <p className="text-primary-200 text-md mb-0">
                                            {cc?.form=== null ? cc?.page?.type: cc?.form?.type}
                                          </p>
                                          <p className="font-medium mb-0 text-gray-100">
                                            {cc?.form=== null ? cc?.page?.title: cc?.form?.title}
                                          </p>
                                        </div>
                                      </div>

                                      <div>
                                        <p className="text-md text-gray-300 mb-0">
                                          <span className={`text-${(cc?.form=== null ? cc?.page?.statusString: cc?.form?.statusString) === 'Published' || (cc?.form=== null ? cc?.page?.statusString: cc?.form?.statusString) === 'Active' ? 'secondary-300' : 'secondary-600'} text-md me-3 d-inline-block`}>
                                            {cc?.form=== null ? cc?.page?.statusString: cc?.form?.statusString}
                                            {/* {cc?.status === "Expired" && (
                                              <IconExclamation />
                                            )} */}
                                          </span>
                                          {/* 20 mins */}
                                        </p>
                                      </div>
                                    </div>
                                  ))}
                              </Accordion.Body>
                            </Accordion.Item>
                          ))}
                      </Accordion>
                    </div>
                  </div>
                  )}
                </div>
              </div>

              {/* <Modal
                className="come-from-modal right"
                centered
                show={modalAssignProcess}
                onHide={() => setModalAssignProcess(false)}
              >
                <AssignProcessModal
                  openModalFeedbackProcess={setModalAssignProcess}
                />
              </Modal> */}

              {/* <Modal
                className="modalDialog"
                size="sm"
                centered
                show={modalExpiredProcess}
                onHide={() => setModalExpiredProcess(false)}
              >
                <ProcessExpiredModal
                  openModalExpiredProcess={setModalExpiredProcess}
                />
              </Modal>
              <Modal
                className="come-from-modal right"
                centered
                show={modalFeedbackProcess}
                onHide={() => setModalFeedbackProcess(false)}
              >
                <ProcessFeedbackModal
                  openModalFeedbackProcess={setModalFeedbackProcess}
                />
              </Modal>
              <Modal
                className="modalDialog"
                size="sm"
                centered
                show={modalExpiredProcess}
                onHide={() => setModalExpiredProcess(false)}
              >
                <ProcessExpiredModal
                  openModalExpiredProcess={setModalExpiredProcess}
                />
              </Modal>
              <Modal
                className="come-from-modal right"
                centered
                show={modalFeedbackProcess}
                onHide={() => setModalFeedbackProcess(false)}
              >
                <ProcessFeedbackModal
                  openModalFeedbackProcess={setModalFeedbackProcess}
                />
              </Modal> */}
            </Container>
          </div>
        </div>
      )}

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={duplicateModal}
      >
        <ModalDelete
          onCancel={setDuplicateModal}
          onConfirm={onConfirmDuplicate}
          headerLable="Are you sure you want to duplicate this Sequence?"
          chldLable="All the content added in the sequence will be duplicated."
        />
      </Modal>

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        // onHide={() => setAssignModal(false)}
        show={assignModal}
      >

        <EmployeeAssignementPopup
          type={AssignmentActionType.SEQUENCE}
          title={"Sequence"}
          contentIds={[sequenceId]}
          getData={setData}
          setAssignModal={setAssignModal}
          moduletitle={SequencesDetails}
          onClose={handleCloseAssignModal}
        />
      </Modal>

      <> {formDataReady === false && <Loader />}</>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal || archiveModal}
      >
        {deleteModal ? (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onConfirm}
            headerLable="Are you sure you want to delete this sequence?" 
            chldLable="All modules related to this sequence will be removed."
          />
        ) : (
          <ModalDelete
            onCancel={setArchiveModal}
            onConfirm={onConfirm}
            requestType="Archive"
            headerLable="Are you sure you want to archive this sequence?" 
            chldLable="The sequence will be moved to the archived list."
          />
        )}
      </Modal>

      <Modal
                className="come-from-modal right"
                centered
                show={openAudienceModal}
                // onHide={() => setOpenAudienceModal(false)}
              >
                <AudienceModal
                  openAudienceModal={setOpenAudienceModal}
                  data={AudienceList}
                  title={"Sequence"}
                  moduletitle={SequencesDetails}
                  onClose={handleCloseAssignModal}
                />
              </Modal>

              <Modal
                className="come-from-modal right"
                centered
                show={openFeedbackModal}
                // onHide={() => setOpenFeedbackModal(false)}
              >
                 <FeedbackView id={state.d} onClose={handleCloseAssignModal}/>
              </Modal>

                            <Modal
        className="modalDialog"
        size="sm"
        centered
        show={showAlertMessagePopup}
      >
            <ModalMessageAlertPopup
            onCancel={setShowAlertMessagePopup}
            requestType="Archive"
            headerLable="You can’t archive the content as this content is yet to be completed by the users"
            chldLable="You can only archive this once it is completed by all the users to whom this is assigned."
            />
          </Modal>       
    </>
  );
};

export default SequencesDetail;
