import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getEnumList } from "../api/common-store-api";

const initialState = {
  statusList: [],
  pageTypeList: [],
  specificFileTypes: [],
  questionTypeList: [],
  issueTypeList: [],
  userstatusList: [],
  assignmentStatusList: [],
  emailsettingList: [],
  phonesettingList: [],
};

export const getStatusEnumListAsync = createAsyncThunk(
  "sequence/Options/status",
  async () => {
    const response = await getEnumList("FilterStatus");
    return response.data;
  }
);
export const getIssueStatusListAsync = createAsyncThunk(
  "sequence/Options/IssueStatus",
  async () => {
    const response = await getEnumList("IssueStatus");
    return response.data;
  }
);
export const getUserStatusListAsync = createAsyncThunk(
  "sequence/Options/UserStatus",
  async () => {
    const response = await getEnumList("UserStatus");
    return response.data;
  }
);
export const getAssignmentStatusListAsync = createAsyncThunk(
  "sequence/Options/getAssignmentStatusListAsync",
  async () => {
    const response = await getEnumList("AssignmentStatus");
    return response.data;
  }
);

export const getPageTypeEnumListAsync = createAsyncThunk(
  "sequence/Options/PageType",
  async () => {
    const response = await getEnumList("PageType");
    return response.data;
  }
);

export const getSpecificFileTypesEnumListAsync = createAsyncThunk(
  "sequence/Options/SpecificFileTypes",
  async () => {
    const response = await getEnumList("SpecificFileTypes");
    return response.data;
  }
);

export const getEmailsettingEnumListAsync = createAsyncThunk(
  "sequence/Options/getEmailsetting",
  async () => {
    const response = await getEnumList("EmailNotificationsType");
    return response.data;
  }
);
export const getPhonesettingEnumListAsync = createAsyncThunk(
  "sequence/Options/PhoneNotificationsType",
  async () => {
    const response = await getEnumList("PhoneNotificationsType");
    return response.data;
  }
);
export const getQuestionTypeEnumListAsync = createAsyncThunk(
  "sequence/Options/QuestionType",
  async () => {
    const response = await getEnumList("QuestionType");
    return response.data;
  }
);
const commonSlice = createSlice({
  name: "common",
  initialState,
  reducers: {},
  extraReducers: {
    [getStatusEnumListAsync.pending]: () => {
      console.log("pending");
    },
    [getStatusEnumListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      const finalPayload = 
      payload.filter((val) => val.name != 'Un Archived')
      return { ...state, statusList: finalPayload };
    },
    [getStatusEnumListAsync.rejected]: () => {
      console.log("rejected");
    },
    [getIssueStatusListAsync.pending]: () => {
      console.log("pending");
    },
    [getIssueStatusListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, issueTypeList: payload };
    },
    [getAssignmentStatusListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, assignmentStatusList: payload };
    },
    [getIssueStatusListAsync.rejected]: () => {
      console.log("rejected");
    },
    [getPageTypeEnumListAsync.pending]: () => {
      console.log("pending");
    },
    [getUserStatusListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, userstatusList: payload };
    },
    [getPageTypeEnumListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return {
        ...state,
        pageTypeList: payload.map((m) => {
          return { value: m.id, label: m.name };
        }),
      };
    },
    [getSpecificFileTypesEnumListAsync.fulfilled]: (state, { payload }) => {
      return {
        ...state,
        specificFileTypes: payload
      };
    },
    [getPhonesettingEnumListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return {
        ...state,
        phonesettingList: payload.map((m) => {
          return { value: m.id, label: m.name };
        }),
      };
    },
    [getEmailsettingEnumListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return {
        ...state,
        emailsettingList: payload.map((m) => {
          return { value: m.id, label: m.name };
        }),
      };
    },
    [getPageTypeEnumListAsync.rejected]: () => {
      console.log("rejected");
    },

    [getQuestionTypeEnumListAsync.pending]: () => {
      console.log("pending");
    },
    [getQuestionTypeEnumListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, questionTypeList: payload };
    },
    [getQuestionTypeEnumListAsync.rejected]: () => {
      console.log("rejected");
    },
  },
});

//export const { processesAdded, processesUpdated, processesDeleted } = processesSlice.actions;

export default commonSlice.reducer;
