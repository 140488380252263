import React, { useEffect, useState } from "react";
import { Row, Col, Container, Accordion, Button, Modal } from "react-bootstrap";
import AccountImg from "../../assets/images/employeeDp.png";
import Catalogmod1 from "../../assets/images/Catalog-mod-1.png";
import Catalogmod2 from "../../assets/images/Catalog-mod-2.png";
import Search from "../../components/Search/Search";
import ActivityLog from "../../components/ActivityLog/ActivityLog";
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import { FeedbackIconGrey } from "../../icons/Icons";
import Loader from "../../components/Loader/Loader";
import { IconArrow, IconDelayed, IconExpired } from "../../icons/Icons";
import UpdatePassword from "../Dashboard/UpdatePassword";
import {
  getEmployeeSingleAssignmentsAsync,
  getUserProfileAsync,
  setAssigmentStartAsync,
  setAssigmentCompleteAsync,
  getMarkNotificationReadAsync,
} from "../../reducers/user-reducers";
import { useDispatch } from "react-redux";
import {
  employeeStatus,
  assignmentStatus,
  getFormatDate,
  getShowNowUrl,
  countLearning,
  getSequencesFirstActive,
  getProcessFirstActive,
  calculateDueTextAndDaysRemaining,
} from "../../common";
import NotificationLog from "../../components/ActivityLog/NotificationLog";
import { useHistory, useLocation, Link } from "react-router-dom";
import EmptyState from "../../components/EmptyState/EmptyState";
import AccordionProgressBar from "../../components/AccordionProgressBar/AccordionProgressBar";
import NoData from "../../components/EmptyState/NoData";
import DateTimeConverter from "../../components/DateTimeConverter/DateTimeConverter";

const NotificationView = ({ openEditEmployeeModal }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const { state } = useLocation();
  const [updatePassword, setUpdatePassword] = useState(false);
  const [userProfiles, setUserProfiles] = useState(null);
  const [assignment, setAssignment] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchString, setSearchString] = useState("");
  let countSrNo = 1;

  useEffect(() => {
    // console.log(state, "statestatestatestatestate");
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  });

  useEffect(() => {
    getUserProfile();
    getEmployeeSignleAssignment(state?.assignmentSegId);
  }, [state]);

  useEffect(() => {
    markNotificationRead();
  }, [userProfiles]);

  const markNotificationRead = async () => {
    const reqBody = {
      userId: userProfiles?.employeeId,
      notificationId: state?.notificationId,
    };

    const response = await dispatch(getMarkNotificationReadAsync(reqBody));
    if (response.payload) {
      // console.log(response.payload,'markOtherNotificationRead');
    }
  };

  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUpdatePassword(res.payload?.onFirstLogin);
        setUserProfiles(res.payload);
      }
    });
  };

  
  const getEmployeeSignleAssignment = (assignmentSegId) => {
    if (assignmentSegId) {
      const reqBody = {
        assignmentSegId: assignmentSegId,
      };
      dispatch(getEmployeeSingleAssignmentsAsync(reqBody)).then((res) => {
        if (res.payload) {
          const assignments = res.payload.assignments;
  
          if (assignments) {
            setAssignment(assignments);
          }
        }
      });
    }
  };

  const onStartNow = (assignmentId, moduleId, assignmentSegId) => {
    // console.log(item,'itemitemitem');
    if (assignmentId && moduleId) {
      const reqBody = {
        assignmentId: assignmentId,
        moduleId: moduleId,
        assignmentSegId: assignmentSegId,
      };
      dispatch(setAssigmentStartAsync(reqBody)).then((res) => {
        // ToasterAlertSucces(res, ErrrorMessage.UPDATE);
        getEmployeeSignleAssignment(state?.assignmentSegId);
      });
    }
  };

  // console.log(assignment, "assignmentassignmentassignmentassignment");

  return (
    <>
      {/* <div className="pages">
        {isLoading && (
          <div className="loader-container bg-white">
            <Loader />
          </div>
        )} */}
        {/* <header className="pageHeader mb-4">
          <Container fluid>
            <Row className="align-items-center">
              <Col>
                <h3>Notification</h3>
                <h6 className="font-normal text-gray-200 pt-1">
                  👋 Welcome to your Notification, {userProfiles?.name}!
                </h6>
              </Col>
              <Col className="text-end">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <div className="">
                    <Search placeHolder="Search..." onBlur={setSearchString} />
                  </div>
                  <AccountDropdown />
                  <NotificationLog />
                  <ActivityLog />
                </div>
              </Col>
            </Row>
          </Container>
        </header> */}
       
        {/* <div className="pageOuter"> */}
            <Container fluid>
              <div className="d-flex flex-column flex-grow-1 overflow-auto">
                <div className="page overflow-visible d-flex flex-column">
                  <div className="p-20 px-32 d-flex flex-column flex-grow-1 overflow-auto">
                    <Accordion
                      defaultActiveKey="0"
                      className="employeeDashboardAccordianMain"
                    >
                      {assignment != undefined &&
                        assignment?.length > 0 &&
                        assignment.map((c, index) => (
                          <>
                            {c.type === "Sequence" && (
                              <Accordion.Item
                                eventKey={`${index}`}
                                className="mb-4 cardContent borderPurple"
                              >
                                <Accordion.Header>
                                  <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs">
                                          {countSrNo++}
                                        </span>
                                      </div>
                                      <div>
                                        <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                          {c.title}
                                          <span
                                            className={`tableTag ${
                                              employeeStatus[c.status].color
                                            } text-md ms-2 `}
                                          >
                                            {assignmentStatus[c.status].status}
                                          </span>
                                        </p>
                                        <p className="text-gray-300 text-md mb-0">
                                          <span className="text-gray-300 pe-1">
                                            {countLearning(c.type, c)}
                                          </span>
                                          {calculateDueTextAndDaysRemaining(c)
                                            .daysRemaining < 0 ? (
                                            c.expiryDate ? (
                                              <span>
                                                Expiring on{" "}
                                                <b>
                                                  {getFormatDate(c.expiryDate)}
                                                  {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                </b>
                                              </span>
                                            ) : null
                                          ) : (
                                            <span>
                                              Due on{" "}
                                              <b>
                                                {calculateDueTextAndDaysRemaining(
                                                  c
                                                ).dueText && (
                                                  <span>
                                                    {
                                                      calculateDueTextAndDaysRemaining(
                                                        c
                                                      ).daysRemaining
                                                    }{" "}
                                                    {calculateDueTextAndDaysRemaining(
                                                      c
                                                    ).daysRemaining === 1
                                                      ? "day"
                                                      : "days"}
                                                  </span>
                                                )}
                                              </b>
                                            </span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </Accordion.Header>
                                <Accordion.Body className="ps-5">
                                  <Accordion
                                    defaultActiveKey="SM_0"
                                    className="employeeDashboardAccordian"
                                  >
                                    {c.modules.map((mc, mindex) => (
                                      <Accordion.Item
                                        eventKey={`SM_${mindex}`}
                                        className="mb-4 cardContent borderPurple hide"
                                      >
                                        <div class="accordion-header">
                                          <div className="d-flex justify-content-between align-items-center p-1 pe-3 w-100">
                                            <div className="d-flex gap-3 justify-content-start flex-grow-1">
                                              <div className="count">
                                                <span className="text-xs bgPurple">
                                                  {mindex + 1}
                                                </span>
                                              </div>
                                              <div className="d-flex pe-3 flex-grow-1">
                                                <img
                                                  src={Catalogmod2}
                                                  alt="Catalogmod2"
                                                  style={{
                                                    width: "5.1875rem",
                                                    height: "5.1875rem",
                                                  }}
                                                />
                                                <div className="ms-3 flex-grow-1">
                                                  <h5 className="text-gray-100">
                                                    {mc.title}
                                                  </h5>
                                                  <p className="text-gray-200 h6 font-normal mb-0 word-break mx19rem">
                                                    {mc.description}
                                                  </p>
                                                  <div className="expdate text-12 pt-2 pb-3 text-gray-300">
                                                    {calculateDueTextAndDaysRemaining(
                                                      mc
                                                    ).daysRemaining < 0 ? (
                                                      mc.expiryDate ? (
                                                        <span>
                                                          Expiring on{" "}
                                                          <b>
                                                            {getFormatDate(
                                                              mc.expiryDate
                                                            )}
                                                            {/* <DateTimeConverter utcDateTime={mc.expiryDate} /> */}
                                                          </b>
                                                        </span>
                                                      ) : null
                                                    ) : (
                                                      <span>
                                                        Due on{" "}
                                                        <b>
                                                          {calculateDueTextAndDaysRemaining(
                                                            mc
                                                          ).dueText && (
                                                            <span>
                                                              {
                                                                calculateDueTextAndDaysRemaining(
                                                                  mc
                                                                ).daysRemaining
                                                              }{" "}
                                                              {calculateDueTextAndDaysRemaining(
                                                                mc
                                                              )
                                                                .daysRemaining ===
                                                              1
                                                                ? "day"
                                                                : "days"}
                                                            </span>
                                                          )}
                                                        </b>
                                                      </span>
                                                    )}
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                            <div className="d-flex align-items-center">
                                              {mc.status === 1 && (
                                                <Link
                                                  // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                  to={{
                                                    pathname: getShowNowUrl(
                                                      c.id,
                                                      c.assignmentId,
                                                      mc.assignmentSegId,
                                                      c.type,
                                                      mc.id,
                                                      c.title,
                                                      // mc.title,
                                                      "Module/"
                                                    ),
                                                    state: {
                                                      assignmentId: c.assignmentId,
                                                      parentId: c.id,
                                                    },
                                                  }}
                                                  // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                  target="_blank"
                                                >
                                                  <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    onClick={() =>
                                                      onStartNow(
                                                        c.assignmentId,
                                                        mc.id,
                                                        mc?.assignmentSegId
                                                      )
                                                    }
                                                    // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                  >
                                                    Start Now
                                                  </Button>
                                                </Link>
                                              )}
                                              {mc.status === 3 && (
                                                <Link
                                                  // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                  to={{
                                                    pathname: getShowNowUrl(
                                                      c.id,
                                                      c.assignmentId,
                                                      mc.assignmentSegId,
                                                      c.type,
                                                      mc.id,
                                                      c.title,
                                                      // mc.title,
                                                      "Module/"
                                                    ),
                                                    state: {
                                                      assignmentId: c.assignmentId,
                                                      parentId: c.id,
                                                    },
                                                  }}
                                                  // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                  target="_blank"
                                                >
                                                  <Button
                                                    variant="secondary"
                                                    size="sm"
                                                    // disabled={mindex !== getSequencesFirstActive(c.modules) ? true : false}
                                                  >
                                                    Resume
                                                  </Button>
                                                </Link>
                                              )}
                                              {mc.status !== 1 &&
                                                mc.status !== 3 && (
                                                  <Link
                                                    // to={mindex === getSequencesFirstActive(c.modules) && ({
                                                    to={{
                                                      pathname: getShowNowUrl(
                                                        c.id,
                                                        c.assignmentId,
                                                        mc.assignmentSegId,
                                                        c.type,
                                                        mc.id,
                                                        c.title,
                                                        // mc.title,
                                                        "Module/"
                                                      ),
                                                      state: {
                                                        assignmentId: c.assignmentId,
                                                        parentId: c.id,
                                                      },
                                                    }}
                                                    // target={mindex === getSequencesFirstActive(c.modules) ? "_blank" : ""}
                                                    target="_blank"
                                                  >
                                                    <span
                                                      className={`tableTag ${
                                                        employeeStatus[
                                                          mc.status
                                                        ].color
                                                      } text-md ms-2 `}
                                                    >
                                                      {
                                                        assignmentStatus[
                                                          mc.status
                                                        ].status
                                                      }
                                                    </span>
                                                  </Link>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      </Accordion.Item>
                                    ))}
                                  </Accordion>
                                </Accordion.Body>
                              </Accordion.Item>
                            )}

                            {c.type === "Module" && (
                              <Accordion.Item
                                eventKey={`${index}`}
                                className="mb-4 cardContent hide"
                              >
                                <div class="accordion-header">
                                  <div className="d-flex justify-content-between align-items-center p-20 bg-base-100 w-100 pe-6">
                                    <div className="d-flex gap-3 align-items-start">
                                      <div className="count">
                                        <span className="text-xs">
                                          {countSrNo++}
                                        </span>
                                      </div>
                                      <div>
                                        <p className="font-medium mb-0 text-gray-100 d-flex align-items-center">
                                          {c.title}
                                          {c.status === 0 ? (
                                            <Link
                                              to={{
                                                pathname: getShowNowUrl(
                                                  c.id,
                                                  c.assignmentId,
                                                  c.assignmentSegId,
                                                  c.type,
                                                  c.id,
                                                  c.title
                                                ),
                                                state: {
                                                  assignmentId: c.assignmentId,
                                                  parentId: c.id,
                                                },
                                              }}
                                              target="_blank"
                                            >
                                              <span
                                                className={`tableTag ${
                                                  employeeStatus[c.status].color
                                                } text-md ms-2 `}
                                              >
                                                {
                                                  assignmentStatus[c.status]
                                                    .status
                                                }
                                              </span>
                                            </Link>
                                          ) : (
                                            <span
                                              className={`tableTag ${
                                                employeeStatus[c.status].color
                                              } text-md ms-2 `}
                                            >
                                              {
                                                assignmentStatus[c.status]
                                                  .status
                                              }
                                            </span>
                                          )}
                                        </p>
                                        <p className="text-gray-300 text-md mb-0">
                                          <span className="text-gray-300 pe-1">
                                            {countLearning(c.type, c)}
                                          </span>
                                          {calculateDueTextAndDaysRemaining(c)
                                            .daysRemaining < 0 ? (
                                            c.expiryDate ? (
                                              <span>
                                                Expiring on{" "}
                                                <b>
                                                  {getFormatDate(c.expiryDate)}
                                                  {/* <DateTimeConverter utcDateTime={c.expiryDate} /> */}
                                                </b>
                                              </span>
                                            ) : null
                                          ) : (
                                            <span>
                                              Due on{" "}
                                              <b>
                                                {calculateDueTextAndDaysRemaining(
                                                  c
                                                ).dueText && (
                                                  <span>
                                                    {
                                                      calculateDueTextAndDaysRemaining(
                                                        c
                                                      ).daysRemaining
                                                    }{" "}
                                                    {calculateDueTextAndDaysRemaining(
                                                      c
                                                    ).daysRemaining === 1
                                                      ? "day"
                                                      : "days"}
                                                  </span>
                                                )}
                                              </b>
                                            </span>
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    {c.status === 1 && (
                                      <Link
                                        to={{
                                          pathname: getShowNowUrl(
                                            c.id,
                                            c.assignmentId,
                                            c.assignmentSegId,
                                            c.type,
                                            c.id,
                                            c.title
                                          ),
                                          state: {
                                            assignmentId: c.assignmentId,
                                            parentId: c.id,
                                          },
                                        }}
                                        target="_blank"
                                      >
                                        <Button
                                          variant="secondary"
                                          size="sm"
                                          onClick={() =>
                                            onStartNow(
                                              c.assignmentId,
                                              c.id,
                                              c?.assignmentSegId
                                            )
                                          }
                                        >
                                          Start Now
                                        </Button>
                                      </Link>
                                    )}
                                    {c.status === 3 && (
                                      <Link
                                        to={{
                                          pathname: getShowNowUrl(
                                            c.id,
                                            c.assignmentId,
                                            c.assignmentSegId,
                                            c.type,
                                            c.id,
                                            c.title
                                          ),
                                          state: {
                                            assignmentId: c.assignmentId,
                                            parentId: c.id,
                                          },
                                        }}
                                        target="_blank"
                                      >
                                        <Button variant="secondary" size="sm">
                                          Resume
                                        </Button>
                                      </Link>
                                    )}
                                  </div>
                                </div>
                              </Accordion.Item>
                            )}
                          </>
                      ))}
                    </Accordion>
                  </div>
                </div>
              </div>
            </Container>
        {/* </div> */}
      {/* </div> */}
    </>
  );
};

export default NotificationView;
