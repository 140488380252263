import React, { useRef, useState } from "react";
import AccountSettingNav from "../AccountSettingNav";
import {
  Row,
  Col,
  Container,
  Modal,
  Button,
  Dropdown,
  Tooltip,
  Overlay,
} from "react-bootstrap";
import Search from "../../../components/Search/Search";
import {
  IconBulkActions,
  IconPlanUpgrade2,
  IconDots,
} from "../../../icons/Icons";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";

import AddUserEmployeeModal from "./AddUserEmployeeModal";
import AddUserModal from "./AddUserModal";

import DisableAccountModal from "./DisableAccountModal";
import DeleteAccountModal from "./DeleteAccountModal";
import DeletePersonalDataModal from "./DeletePersonalDataModal";
import Filters from "../../../components/Filters/Filters";
import CheckBox from "../../../components/CheckBox/CheckBox";
import OwnerImg from "../../../assets/images/Account.png";
import AccountImg from "../../../assets/images/Account.png";

import { NavLink } from "react-router-dom";

const Users = () => {
  const [show, setShow] = useState(false);
  const target = useRef(null);
  const [addUserEmployee, setAddUserEmployee] = useState(false);
  const [addUser, setAddUser] = useState(false);

  const [disableAccount, setDisableAccount] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [deletePersonalDataModal, setDeletePersonalDataModal] = useState(false);
  return (
    <>
      <div className="pages">
        <header className="pageHeader">
          <Container fluid>
            <Row className="mx-0 align-items-center">
              <Col className="px-0">
                <h3>Account Settings</h3>
              </Col>
              <Col className="px-0 text-end ">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <AccountDropdown />
                  <ActivityLog />
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="pageOuter">
          <Container fluid>
            <AccountSettingNav />

            <div className="page d-flex">
              <Filters />

              <div className="filtersContent">
                <div className="p-32 pb-4 borderBottom">
                  <div className="detailModulePage d-flex justify-content-between align-items-start mb-4">
                    <div className="d-flex feedbackData">
                      <div className="dataItem">
                        <p className="h6 mb-3 text-gray-300">Employees</p>

                        <div className="d-flex">
                          <div className="headImg moduleImg">
                            <img src={OwnerImg} alt="" />
                          </div>
                          <div className="headImg moduleImg">
                            <img src={OwnerImg} alt="" />
                          </div>
                          <div className="headImg moduleImg">
                            <img src={OwnerImg} alt="" />
                          </div>
                          <div className="headImg moduleImg imgCount">
                            <div className="text-xs total">+3</div>
                          </div>
                        </div>
                      </div>

                      <div className="dataItem d-flex">
                        <div className="me-4">
                          <p className="h6 mb-3 text-gray-300">Active users</p>

                          <h3 className="font-medium">10</h3>
                        </div>

                        <div className="me-4">
                          <p className="h6 mb-3 text-gray-300">Admin</p>

                          <h3 className="font-medium">4</h3>
                        </div>

                        <div className="me-4">
                          <p className="h6 mb-3 text-gray-300">Inactive</p>

                          <h3 className="font-medium text-primary-100">34</h3>
                        </div>
                      </div>
                    </div>

                    <div className="d-flex gap-2 align-items-center">
                      <Button variant="" className="border-0 py-0">
                        <div className="d-flex gap-2 align-items-center">
                          <IconBulkActions />
                          <p className="h6 text-gray-300">Bulk Actions</p>
                        </div>
                      </Button>

                      <div className="pipeSeparator pe-4">&nbsp;</div>
                      <Button variant="white" size="sm">
                        Add a new user
                      </Button>
                      <Button variant="secondary" size="sm">
                        Assign Content
                      </Button>
                    </div>
                  </div>

                  <div className="d-flex justify-content-between">
                    <Search />

                    <div className="d-flex gap-2 align-items-center">
                      <div>
                        {" "}
                        <IconPlanUpgrade2 />
                      </div>
                      <p className="h6 ">
                        <span className="text-underline text-secondary-300 font-medium">
                          Upgrade your plan
                        </span>{" "}
                        now to add more users.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="tableComponent flex-grow-1 overflow-auto">
                  <table className="table">
                    <thead>
                      <tr>
                        <th>
                          <CheckBox text="ALL" />
                        </th>
                        <th>NAME</th>
                        <th>TEAM</th>
                        <th>CONTACT DETAILS</th>
                        <th>STATUS</th>
                        <th>ASSIGNMENTS</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>

                    <tbody>
                      <tr>
                        <td>
                          <CheckBox />
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <div className="text-gray-100 d-flex align-items-center gap-2">
                              <div className="tableImg">
                                <img src={AccountImg} alt="" />
                              </div>
                              <div>
                                <p className="text-nowrap mb-0">
                                  Ronald Richards
                                </p>
                                <p className="text-gray-300 text-md mb-0">
                                  Admin
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>UX Design</td>
                        <td>
                          <a
                            href="mailto:ronald.r@cerebrent.com"
                            className="text-primary-200"
                          >
                            ronald.r@cerebrent.com
                          </a>
                          <br />
                          <a
                            href="tel:+123 456 7890"
                            className="text-primary-200"
                          >
                            +123 456 7890
                          </a>
                        </td>
                        <td>
                          <span class="tableTag text-md">Active</span>
                        </td>
                        <td>
                          <div
                            className="progress tableProgress"
                            ref={target}
                            onMouseEnter={() => setShow(!show)}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "50%" }}
                            ></div>
                          </div>

                          <Overlay
                            target={target.current}
                            show={show}
                            placement="bottom"
                          >
                            {(props) => (
                              <Tooltip
                                {...props}
                                className="tooltipProgressBar"
                              >
                                <div className="active">
                                  <p className="h6">Completed</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                                <div>
                                  <p className="h6">Active</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                                <div>
                                  <p className="h6">Pending</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                                <div>
                                  <p className="h6">Delayed</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                              </Tooltip>
                            )}
                          </Overlay>
                        </td>

                        <td>
                          <Dropdown className="tableDropdown">
                            <Dropdown.Toggle>
                              <IconDots />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <button
                                className="dropdown-item"
                                onClick={() => setDisableAccount(true)}
                              >
                                Disable Login
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={() => setDeleteModal(true)}
                              >
                                Delete Account
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={() => setDeletePersonalDataModal(true)}
                              >
                                Request Personal Data Delete
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>

                      <tr>
                        <td>
                          <CheckBox />
                        </td>
                        <td>
                          <div className="d-flex align-items-center gap-2">
                            <div className="text-gray-100 d-flex align-items-center gap-2">
                              <div class="tableName text-primary-100 bg-pastel-100">
                                WW
                              </div>
                              <div>
                                <p className="text-nowrap mb-0">
                                  Ronald Richards
                                </p>
                                <p className="text-gray-300 text-md mb-0">
                                  Admin
                                </p>
                              </div>
                            </div>
                          </div>
                        </td>
                        <td>UX Design</td>
                        <td>
                          <a
                            href="mailto:ronald.r@cerebrent.com"
                            className="text-primary-200"
                          >
                            ronald.r@cerebrent.com
                          </a>
                          <br />
                          <a
                            href="tel:+123 456 7890"
                            className="text-primary-200"
                          >
                            +123 456 7890
                          </a>
                        </td>
                        <td>
                          <span class="tableTag text-md">Active</span>
                        </td>
                        <td>
                          <div
                            className="progress tableProgress"
                            ref={target}
                            onMouseEnter={() => setShow(!show)}
                          >
                            <div
                              className="progress-bar"
                              role="progressbar"
                              style={{ width: "50%" }}
                            ></div>
                          </div>

                          <Overlay
                            target={target.current}
                            show={show}
                            placement="bottom"
                          >
                            {(props) => (
                              <Tooltip
                                {...props}
                                className="tooltipProgressBar"
                              >
                                <div>
                                  <p className="h6">Completed</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                                <div className="active">
                                  <p className="h6">Active</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                                <div>
                                  <p className="h6">Pending</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                                <div>
                                  <p className="h6">Delayed</p>
                                  <span className="mb-0 font-medium"> 10</span>
                                </div>
                              </Tooltip>
                            )}
                          </Overlay>
                        </td>

                        <td>
                          <Dropdown className="tableDropdown">
                            <Dropdown.Toggle>
                              <IconDots />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                              <button
                                className="dropdown-item"
                                onClick={() => setDisableAccount(true)}
                              >
                                Disable Login
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={() => setDeleteModal(true)}
                              >
                                Delete Account
                              </button>
                              <button
                                className="dropdown-item"
                                onClick={() => setDeletePersonalDataModal(true)}
                              >
                                Request Personal Data Delete
                              </button>
                            </Dropdown.Menu>
                          </Dropdown>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </Container>
        </div>
      </div>

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={addUserEmployee}
        onHide={() => setAddUserEmployee(false)}
      >
        <AddUserEmployeeModal handleModal={setAddUserEmployee} />
      </Modal>
      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={addUser}
        onHide={() => setAddUser(false)}
      >
        <AddUserModal Modal handleModal={setAddUser} />
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={disableAccount}
        onHide={() => setDisableAccount(false)}
      >
        <DisableAccountModal handleModal={setDisableAccount} />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deleteModal}
        onHide={() => setDeleteModal(false)}
      >
        <DeleteAccountModal handleModal={setDeleteModal} />
      </Modal>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={deletePersonalDataModal}
        onHide={() => setDeletePersonalDataModal(false)}
      >
        <DeletePersonalDataModal handleModal={setDeletePersonalDataModal} />
      </Modal>
    </>
  );
};

export default Users;
