import React, { Component } from "react";
import { Button } from "react-bootstrap";
import { IconCross, IconModule } from "../../icons/Icons";

export class Draggable2 extends Component {
  state = {
    lisdata: [],
  };

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.state.lisdata.length != this.props.list.length) {
      this.setState({ lisdata: this.props.list });
    }
  }
  onMoveEnd = (newList) => {
    console.log(newList);
  };
  render() {
    return (
      // <DraggableList
      //   width={300}
      //   height={75}
      //   rowSize={1}
      //   onMoveEnd={this.onMoveEnd}
      // >
      <div>
        {this.props.list.map((item, index) => (
          <div
            key={index}
            className="d-flex gap-3 bg-gray-600 borderAll p-12 rounded-6 mwidth-440 position-relative mb-12"
          >
            <IconModule />
            <div>
              <p className="mb-0 text-md text-primary-200">
                {item?.type ?? ""}
              </p>
              <p className="mb-0">
                <Button
                  variant=""
                  className="border-0 py-0"
                  onClick={() => this.props.onClick(item.contenttype, index)}
                >
                  <div className="d-flex gap-2 align-items-center">
                    {item?.title ?? ""}
                  </div>
                </Button>
              </p>
            </div>
            <div className="crossBtn">
              <Button
                variant=""
                className="border-0 py-0"
                onClick={() =>
                  this.props.onRemove(
                    item.contenttype,
                    index,
                    item.index,
                    this.props.setMethod
                  )
                }
              >
                <IconCross />
              </Button>
            </div>
          </div>
        ))}
      </div>
      // </DraggableList>
    );
  }
}

export default Draggable2;
