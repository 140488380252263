import React, { useState, useEffect } from 'react';
import moment from 'moment-timezone';

const TimeZoneConverter = ({ timezone }) => {
    const [currentTime, setCurrentTime] = useState('');
  
    useEffect(() => {
      const interval = setInterval(() => {
        const time = moment().tz(timezone).format('HH:mm');
        setCurrentTime(time);
      });
      
      return () => clearInterval(interval);
    }, [timezone]);
  
    return (
        <>
        {currentTime}
        </>
    );
  };
export default TimeZoneConverter;
