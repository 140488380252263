import React, { useState, useEffect } from "react";
import { Row, Col, Container, Tooltip, Modal, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import EmptyState from "../../components/EmptyState/EmptyState";
import {
  getModuleTemplatesAsync,
  getModuleDetailsAsync,
  deleteModuleAsync,
} from "../../reducers/module-reducers";
import Loader from "../../components/Loader/Loader";
import FilterTemplate from "../../components/Filters/FilterTemplate";
// import { getProcessesTemplatesAsync } from "../../reducers/processes-reducers";
// import { getSequenceTemplatesAsync } from "../../reducers/sequence-reducers";
import {
  deleteProcessesAsync,
  getProcessesTemplatesAsync,
} from "../../reducers/processes-reducers";
import {
  deleteSequenceAsync,
} from "../../reducers/sequence-reducers";

import useTemp from "../../assets/images/useTemp.png";
import { Link, useLocation, useHistory } from "react-router-dom";
import ModalPreview from "./ModalPreview";
import { getAllTemplatesAsync } from "../../reducers/user-reducers";
import Search from "../../components/Search/Search";
import ListPreview from "./ListPreview";
import ModalDelete from "../../components/ModalPopup/ModalDeletePopup";
import ToasterAlertSucces from "../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../helper/ErrorMessage";
import { useDebounce } from "../../utils/hooks/useDebounce";

const TemplateList = ({ type, onClose }) => {
  const dispatch = useDispatch();
  const [searchString, setSearchString] = useState("");
  const [selectedStatus, setStatusList] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(1000);
  const [sortColumn, setSortColumn] = useState({
    column: "",
    sortDirection: "",
  });
  const [deleteModal, setDeleteModal] = useState(false);
  const [deleteType, setDeleteType] = useState(null);
  const [deleteId, setDeleteId] = useState(null);

  const [formDataReady, setFormDataReady] = useState(false);
  const [moduleDetails, setAdditionalStorage] = useState(null);
  const { state } = useLocation();
  const [activeTab, setActiveTab] = useState(type ?? "Processes");
  const [previewModal, setPreviewModal] = useState(false);
  // const [userData, setUserData] = useState(null);
  const [templateDetail, setTemplateDetail] = useState([]);
  const [allTemplate, setAllTemplate] = useState([]);
  const [selectedData, setSelectedData] = useState([]);

  // const entities = useSelector((state) =>
  //   type === "module"
  //     ? state.module.moduleTemplate
  //     : type === "process"
  //       ? state.process.processesTemplate
  //       : state.sequence.sequenceTemplate
  // );

  // const getTemplates = () => {
  //   setFormDataReady(false);
  //   let reqBody = {
  //     page: currentPage,
  //     pageSize: pageSize,
  //     orderBy: sortColumn.column.sortField,
  //     isOrderByDesc: sortColumn.sortDirection === "asc" ? false : true,
  //     searchString: searchString,
  //     status: selectedStatus,
  //     tags: [],
  //   };
  //   dispatch(
  //     type === "module"
  //       ? getModuleTemplatesAsync(reqBody)
  //       : type === "process"
  //         ? getProcessesTemplatesAsync(reqBody)
  //         : getSequenceTemplatesAsync(reqBody)
  //   ).then((res) => {
  //     setFormDataReady(true);
  //   });
  // };
 
  useEffect(() => {
    // getAllTemplates();
    debouncedTemplate();
  }, [searchString, activeTab]);

  useEffect(() => {
    // getAllTemplates();
    if (activeTab === "Processes") {
      setTemplateDetail(allTemplate.filter((item) => item.type === "Process"));
    } else if (activeTab === "Sequences") {
      setTemplateDetail(allTemplate.filter((item) => item.type === "Sequence"));
    } else if (activeTab === "Modules") {
      setTemplateDetail(allTemplate.filter((item) => item.type === "Module"));
    }
  }, [allTemplate]);

  // useEffect(() => {
  //   setActiveTab(type);
  //   getAllTemplates();
  // }, [type]);

  const getAllTemplates = async () => {
    setFormDataReady(false);
    let reqBody = {
      searchString: searchString,
    };
    await dispatch(getAllTemplatesAsync(reqBody)).then((res) => {
      if (res.payload) {
        setFormDataReady(true);
        setAllTemplate(res.payload);
        // setTemplateDetail(res.payload);
      }
      //console.log(templateDetail, 'res1')
    });
  };
  const debouncedTemplate = useDebounce(getAllTemplates, 1000);
  
  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
    if (tabName === "Processes") {
      setTemplateDetail(allTemplate.filter((item) => item.type === "Process"));
    } else if (tabName === "Sequences") {
      setTemplateDetail(allTemplate.filter((item) => item.type === "Sequence"));
    } else if (tabName === "Modules") {
      setTemplateDetail(allTemplate.filter((item) => item.type === "Module"));
    }
  };

  const onDeleteConfirm = async () => {
    if (deleteType !== null && deleteId !== null) {
      const jsonData = {
        id: deleteId,
        status: 'Delete',
      };
      if (deleteType === 'Process') {
        const response = await dispatch(deleteProcessesAsync(jsonData));
        if (response.payload) {
          ToasterAlertSucces(response, ErrrorMessage.DELETE);
          setDeleteModal(false);
          setDeleteType(null);
          setDeleteId(null);
          getAllTemplates();
        }
      } else if (deleteType === 'Sequence') {
        const response = await dispatch(deleteSequenceAsync(jsonData));
        if (response.payload) {
          ToasterAlertSucces(response, ErrrorMessage.DELETE);
          setDeleteModal(false);
          setDeleteType(null);
          setDeleteId(null);
          getAllTemplates();
        }
      } else if (deleteType === 'Module') {
        const response = await dispatch(deleteModuleAsync(jsonData));
        if (response.payload) {
          ToasterAlertSucces(response, ErrrorMessage.DELETE);
          setDeleteModal(false);
          setDeleteType(null);
          setDeleteId(null);
          getAllTemplates();
        }
      }
    }
  };

  const handleDeleteClick = (type, id) => {
    setDeleteType(type);
    setDeleteId(id);
    setDeleteModal(true);
  };

  // console.log(templateDetail, 'templateDetail')
  // console.log(moduleDetails, 'moduleDetails')
  return (
    <>
      <div className="pages h-100">
        <header className="pageHeader m-0 pb-0">
          <Container fluid>
            <Row className="mx-0 align-items-center">
              <Col className="px-0">
                <h3>Template Library</h3>
              </Col>
              <Col>
                <div className="">
                  <div className="w-100">
                    <ul
                      class="modalTab modalTab authoringNav pt-4 bg-base-100 nav mt-3"
                      role="tablist"
                    >
                      <li className="nav-item">
                        <Link
                          className={`h6 text-gray-100 ${activeTab === "Processes" ? "active" : ""
                            }`}
                          onClick={() => handleTabClick("Processes")}
                        >
                          Processes
                        </Link>
                      </li>
                      <li className="nav-item">
                        <Link
                          className={`h6 text-gray-100 ${activeTab === "Sequences" ? "active" : ""
                            }`}
                          onClick={() => handleTabClick("Sequences")}
                        >
                          Sequences
                        </Link>
                      </li>

                      <li className="nav-item">
                        <Link
                          className={`h6 text-gray-100 ${activeTab === "Modules" ? "active" : ""
                            }`}
                          onClick={() => handleTabClick("Modules")}
                        >
                          Modules
                        </Link>
                      </li>
                      {/* <li class="nav-item" role="presentation">Processes</li>
                <li class="nav-item" role="presentation">Sequences</li>
                <li class="nav-item" role="presentation">Modules</li> */}
                    </ul>
                  </div>
                </div>
              </Col>
              <Col className="px-0 text-end">
                <div className="d-flex justify-content-end align-items-center">
                  <div className="mwidth-268 me-5">

                    <Search placeHolder="Search template" onBlur={setSearchString} />
                  </div>
                  <button
                    type="button"
                    class="btn-close"
                    aria-label="Close"
                    onClick={() => onClose(false)}
                  ></button>
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="h-100 h-head bg-base-100">
          {!formDataReady && <Loader />}
          {/* <AuthoringNav /> */}
          <div className="page employeesPage d-flex h-100">
            {activeTab === "Processes" ? (
              <FilterTemplate
                templateDetail={templateDetail.filter(
                  (item) => item.type === "Process"
                )}
                type="process"
                // selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            ) : activeTab === "Sequences" ? (
              <FilterTemplate
                templateDetail={templateDetail.filter(
                  (item) => item.type === "Sequence"
                )}
                type="sequence"
                // selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            ) : activeTab === "Modules" ? (
              <FilterTemplate
                templateDetail={templateDetail.filter(
                  (item) => item.type === "Module"
                )}
                type="module"
                // selectedData={selectedData}
                setSelectedData={setSelectedData}
              />
            ) : null}

            <div className="filtersContent">

              {activeTab === "Processes" ? (
                <ListPreview
                  templateDetail={templateDetail.filter(
                    (item) => item.type === "Process"
                  )}
                  // setUserData={setUserData}
                  type="process"
                  selectedData={selectedData}
                  handleDeleteClick={handleDeleteClick}
                  formDataReady={formDataReady}
                />
              ) : activeTab === "Sequences" ? (
                <ListPreview
                  templateDetail={templateDetail.filter(
                    (item) => item.type === "Sequence"
                  )}
                  // setUserData={setUserData}
                  type="sequence"
                  selectedData={selectedData}
                  handleDeleteClick={handleDeleteClick}
                  formDataReady={formDataReady}
                />
              ) : activeTab === "Modules" ? (
                <ListPreview
                  templateDetail={templateDetail.filter(
                    (item) => item.type === "Module"
                  )}
                  // setUserData={setUserData}
                  type="module"
                  selectedData={selectedData}
                  handleDeleteClick={handleDeleteClick}
                  formDataReady={formDataReady}
                />
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <Modal
        className="modalDialog modDeltemp"
        size="sm"
        centered
        show={deleteModal}
      >
        {deleteModal && (
          <ModalDelete
            onCancel={setDeleteModal}
            onConfirm={onDeleteConfirm}
            headerLable="Are you sure you want to delete the selected template?"
            chldLable="Please note, the selected template will be permanently deleted."
          />
        )}
      </Modal>

    </>
  );
};

export default TemplateList;
