import React, { useEffect, useState, useRef } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import {
  IconExit,
  IconSkip,
  IconBreadcrumArrow,
  IconZoom,
  IconPPT,
  IconDownload,
  IconReportIssue,
  IconUploadFile,
} from "../../../icons/Icons";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { Subtitles } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { setAssigmentCompleteAsync, saveAssignmentAsync } from "../../../reducers/user-reducers";
import { Formik, Form, useFormik, Field, ErrorMessage } from "formik";
import Loader from "../../../components/Loader/Loader";

const Header = ({ nextSlide, prevSlide,titles, contentLenght, currentState, contentData, assignmentId, moduleId, handleNextClick, formValues, getContent, isFileUploaded,isValidDate, isValidTime }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useHistory();
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [isFormReady, setIsFormReady] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);

  const formRef = useRef();
  // Get ID from URL
  const params = useParams();
  const { parentId, previewType, title, assignmentSegId } = params;

  //  let { form, page } = contentData;
  // let { pageData, formData } = contentData;
  const form = contentData.filter((item) => item.form);
  const page = contentData.filter((item) => item.page);
  console.log(form, 'form')
  console.log(page, 'page')
  // const combinedData = [...pageData, ...formData];

  const headerData = [];
  const headerSecTitle = [];
  const previewData = [];
  const question = [];
  form.form &&
    form.form.map((v, i) => {
      headerSecTitle.push(v);
      v.questions.map((v, i) => question.push(v));
    });
  // previewData.push(...forms, ...pages);
  form.form && previewData.push(...page, ...form);
  // headerData.push(...headerSecTitle, ...pages);
  page.page && headerData.push(...page, ...headerSecTitle);

  const headerFirstTitle = titles

  const headerSecondTitle = (() => {
    if (contentData[currentState] && contentData[currentState].form && contentData[currentState].form.title) {
      return contentData[currentState].form.title;
    } else if (contentData[currentState] && contentData[currentState].page && contentData[currentState].page.title) {
      return contentData[currentState].page.title;
    }
    return null;
  })();

  //  console.log(contentData, 'contentData')

  // console.log(previewData, 'previewDatah')

  const [isNext, setIsNext] = useState(false);
  const [isPrev, setIsPrev] = useState(false);

  useEffect(() => {
    // debugger
    setIsNext(contentLenght === currentState + 1 ? true : false);
    setIsPrev(currentState === 0);
    setIsSubmitted(false);
    setIsFormReady(true);
  }, [currentState, contentLenght]);

  // const onComplete = () => {
  //   dispatch(setAssigmentCompleteAsync(assignmentId)).then((res) => {
  //     console.log(res, "res");
  //   });
  // };

  const onNext = () => {
    handleNextClick();
    const currentFormData = formRef?.current;

    const currentAssignmentDetailId = contentData[currentState]?.id;
    const currentIndex = contentData.findIndex((item) => item.id === currentAssignmentDetailId);
    const nextIndex = currentIndex + 1;
    const nextItem = contentData[nextIndex];


    const currentAssignment = contentData.find((item) => item.id === currentAssignmentDetailId);
    setIsSubmitted(true);
    setIsFormReady(false);
    if (currentAssignment.form) {
      const reqBody = {
        assignmentId:assignmentId,
        assignmentSegId:assignmentSegId,
        moduleId:moduleId,
        assignmentDetailId: currentAssignmentDetailId,
        nextAssignmentDetailId: nextItem ? nextItem.id : null,
        isSkipped: currentFormData.values.isSkipped,
        formQuestions: formValues
        // formQuestions: [
        //   {
        //     questionId,
        //     answer: formValues,
        //   }
        // ]
      };
      dispatch(saveAssignmentAsync(reqBody)).then((res) => {
        // debugger;
        getContent();
        // console.log(res, 'FormCpmpletedddddddddddd');
        if(res.payload && contentLenght === currentState + 1){
          // navigate.push("/catalogue");
          setIsSubmitted(false);
          setIsFormReady(true);
          if (res.payload === 'Completed') {
            navigate.push(`/feedback/${parentId}/${previewType}/${title}`);
          } else {
            navigate.push("/catalogue");
          }          
        }
        
      });
    } else {
      const reqBody = {
        moduleId:moduleId,
        assignmentSegId:assignmentSegId,
        assignmentDetailId: currentAssignmentDetailId,
        nextAssignmentDetailId: nextItem ? nextItem.id : null,
        isSkipped: currentFormData.values.isSkipped,
      };
      dispatch(saveAssignmentAsync(reqBody)).then((res) => {
        // debugger;
        getContent();
        // console.log(res, 'PageCpmpletedddddddddddd');
        if(res.payload && contentLenght === currentState + 1){
          // navigate.push("/catalogue");
          setIsSubmitted(false);
          setIsFormReady(true);
          if (res.payload === 'Completed') {
            navigate.push(`/feedback/${parentId}/${previewType}/${title}`);
          } else {
            navigate.push("/catalogue");
          }        
        }        
      });
    }
  };

  const onSkip = () => {
    const currentFormData = formRef?.current;
    const currentAssignmentDetailId = contentData[currentState]?.id;
    const currentIndex = contentData.findIndex((item) => item.id === currentAssignmentDetailId);
    const nextIndex = currentIndex + 1;
    const nextItem = contentData[nextIndex];

    const currentAssignment = contentData.find((item) => item.id === currentAssignmentDetailId);


    const allowsSkip = currentAssignment.form
      ? currentAssignment.form.allowSkip
      : currentAssignment.page.allowSkip;

    if (allowsSkip) {
      const reqBody = {
        moduleId:moduleId,
        assignmentSegId:assignmentSegId,
        assignmentDetailId: currentAssignmentDetailId,
        nextAssignmentDetailId: nextItem ? nextItem.id : null,
        isSkipped: true,
      };
      dispatch(saveAssignmentAsync(reqBody)).then((res) => {
        // console.log(res, 'res');
        getContent();
        if(contentLenght === currentState + 1){
          navigate.push("/catalogue");
        }
      });
    }
  };


  useEffect(() => {
    const currentForm = contentData[currentState]?.form;
    if (currentForm) {
      const areAllRequiredFieldsFilled = currentForm.questions.every((question) => {
        if (question.isRequired === true) {
          if (formValues?.length > 0) {
            const questionValue = formValues.find((item) => item.questionId === question.id);
            // return questionValue && questionValue.answer !== '';
            return questionValue && questionValue.answer !== null && questionValue.answer !== '';
          } else if (question.answer === null || question.answer === '') {
            return false;
          }
        }
        return true;
      });
      console.log(areAllRequiredFieldsFilled,'areAllRequiredFieldsFilled');
      console.log(isFileUploaded,'isFileUploaded');
      console.log(isValidDate,'isValidDate');
      // setIsFormReady(areAllRequiredFieldsFilled);
      // setIsFormReady(areAllRequiredFieldsFilled || (isFileUploaded && isValidDate));
      setIsFormReady(areAllRequiredFieldsFilled);
    } else {
      setIsFormReady(true);
    }
  }, [currentState, contentData, formValues, isFileUploaded, isValidDate]);
  
  console.log(isFormReady,'isFormReady');
  console.log(isValidTime,'isValidTime');
  console.log(isSubmitted,'isSubmitted');
 
  // console.log(page[currentState].page.allowSkip,'index2')
  return (
    <header className="pageHeader mb-0 h-83">
      <Container fluid className="px-32">
        {isSubmitted && <Loader />}
        <Row className="align-items-center">
          <Col className="col-lg-4">
            <div className="d-flex align-items-center gap-2">
              <Link to="/catalogue">
                <span className="arrow left"></span>
              </Link>
              <p className="h6 text-gray-200 headerFirstTitle">
                {headerFirstTitle}
              </p>

              {headerSecondTitle && headerSecondTitle !== "" && (
                <IconBreadcrumArrow />
              )}

              <div>
                <p className="h6 text-primary-200">{headerSecondTitle}</p>
              </div>
            </div>
          </Col>
          <Col className="text-end col-lg-8">
            <Formik
              initialValues={{
                isSkipped: false,
              }}
              onSubmit={onNext}
              innerRef={formRef}
            >
              {({ setFieldValue }) => (
                <Form>
                  <div className="d-flex align-items-center justify-content-end">

                    <Button
                      variant=""
                      className="border-0 py-0"
                      onClick={() => window.close()}
                    >
                      <div className="d-flex gap-2 align-items-center">
                        <IconExit />
                        <p className="h6 text-gray-300">Exit</p>
                      </div>
                    </Button>


                    {/* <Button variant="" className='border-0 py-0' onClick={() => {
                            nextSlide;
                            onNext();   
                          }}>
                <div className='d-flex gap-2 align-items-center'><IconSkip /><p className='h6 text-gray-300'>Skip</p></div>
              </Button> */}

                    {/* {contentData.map((item) => (item.pageId === null ? item.form.allowSkip === true : item.page.allowSkip === true)) && ( */}
                      {contentLenght !== currentState + 1 && (
                      <div key={contentData[currentState]?.id}>
                        <Button variant="" className='border-0 py-0' name="isSkipped" 
                        disabled={
                          contentData[currentState]?.form?.allowSkip === false ||
                          contentData[currentState]?.page?.allowSkip === false
                        }
                        onClick={() => {
                          onSkip();
                          contentLenght !== currentState + 1 && nextSlide();
                          // nextSlide();
                        }}>
                          <div className='d-flex gap-2 align-items-center'><IconSkip />
                            <p className='h6 text-gray-300' name="isSkipped">Skip</p>

                          </div>
                        </Button>
                      </div>
                    )}
                    {/* )} */}

                    {contentLenght > 0 && (
                      <>
                        <div className="pipeSeparator pe-4 ps-2">&nbsp;</div>
                        <Button variant="white" onClick={prevSlide} 
                        disabled={isPrev}
                        >
                          <div className="d-flex gap-2 align-items-center">
                            <span>Previous</span>
                          </div>
                        </Button>

                        {/* <Button variant="secondary" onClick={nextSlide} disabled={isNext}> */}

                        <Button variant="secondary"
                          //disabled={isButtonDisabled}
                          className="ms-12"
                          disabled={!isFormReady || (!isValidTime)}
                          onClick={() => {
                            contentLenght !== currentState + 1 && nextSlide();
                            onNext();
                          }}>
                          {/* Next */}
                          {contentLenght === currentState + 1 ? "Submit" : "Next"}
                        </Button>

                      </>
                    )}
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </Row>
      </Container>
    </header>
  );
};

export default Header;
