import React, { useEffect, useState } from "react";
import AccountSettingNav from "../AccountSettingNav";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Search from "../../../components/Search/Search";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";

import ModalDelete from "./ModalDelete";
import ModalEmployeeActivity from "./ModalEmployeeActivity";
import ManageTeamsNav from "./ManageTeamsNav";
import TeamDetails from "./Teams/TeamDetails";
import Loader from "../../../components/Loader/Loader";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader"

const ManageTeams = (props) => {
  const [deleteModal, setDeleteModal] = useState(false);
  const [activityModal, setActivityModal] = useState(false);
  const [userData, setUserData] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(undefined);
  const [isTeamUpdated, setIsTeamUpdated] = useState(false);
  // console.log(selectedTeam, "selectedTeam")
  return (
    
    <>
      <div className="pages">
        <RenewalHeader />
        <header className="pageHeader mb-4">
          <Container fluid>
            <Row className="mx-0 align-items-center">
              <Col className="px-0">
                <h3>Account Settings</h3>
              </Col>
              <Col className="px-0 text-end ">
                <div className="d-flex gap-3 align-items-center justify-content-end">
                  <AccountDropdown />
                  <ActivityLog />
                </div>
              </Col>
            </Row>
          </Container>
        </header>
        <div className="pageOuter">
          <Container fluid>
          {/* {!selectedTeam && <Loader />} */}
            <AccountSettingNav />
            <div className="page d-flex">
              <ManageTeamsNav
                setSelectedTeam={(value) => {
                  setSelectedTeam(value);
                }}
                selectedTeam={selectedTeam}
                isTeamUpdated={isTeamUpdated}
                setIsTeamUpdated={setIsTeamUpdated}
              />
              <div className="manageTeamsNavContent d-flex">
                <TeamDetails
                  actionDelete={setDeleteModal}
                  actionActivity={setActivityModal}
                  teamId={selectedTeam?.id}
                  teamName={selectedTeam?.name}
                  setIsTeamUpdated={setIsTeamUpdated}
                  setSelectedTeam={(value) => {
                    setSelectedTeam(value);
                  }}
                  setUserData ={setUserData}
                />{" "}
              </div>
            </div>
          </Container>
        </div>
      </div>

      <Modal
        className="come-from-modal right manageTeamsActivityModal"
        centered
        show={activityModal}
        //onHide={() => setActivityModal(false)}
      >
        <ModalEmployeeActivity handleModal={setActivityModal} userDetails={userData} />
      </Modal>
    </>
  );
};

export default ManageTeams;
