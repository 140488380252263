

import React from 'react';

import { Row, Col, Container, Button } from "react-bootstrap";
import {
    IconArrow,
    PDFicon,
    PDFdownload,
    MyLearing,
    Alerts,
    SideArrow,
  } from "../../icons/Icons";
import AccountImg from '../../assets/images/Account.png';
import { Link, NavLink, useHistory } from "react-router-dom";
import Search from "../../components/Search/Search";
import OwnerImg from '../../assets/images/Account.png';
import AccountDropdown from "../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../components/ActivityLog/ActivityLog";

const FormViewIndividual = () => {
    return (
        <div className='pages'>
            <header className='pageHeader mb-0'>
                <Container fluid>
                    <Row className='mx-0 align-items-center'>
                        <Col className='px-0'>
                        <div class="d-flex align-items-center gap-2">
                        <Link to="/authoring">
                            <span className="arrow left"></span>
                        </Link>
                            <h3>Form Responses</h3></div>
                    
                        </Col>
                        <Col className='px-0 text-end '>
                            <div className="d-flex gap-3 align-items-center justify-content-end">
                                <AccountDropdown />
                                <ActivityLog />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <div className='pageOuter p-0 bg-base-100'>
                    <div className='d-flex flex-column h-100 bg-gray-500'>
                        <div className='w-100 flex-grow-1 h-100 overflow-auto'>
                            <Row className='g-0 d-flex h-100'>
                                <Col className='col-lg-12 flex-grow-1 overflow-auto h-100'>
                                    <div className='p-32 pb-4'>
                                        <div className='rounded-6 border bg-gray-600'>
                                                <div className='p-32 pb-0 bg-base-100 '>
                                                    <div className='catlogHeading d-flex'>
                                                
                                                            <div className='pb-4 mb-3'>
                                                                <h4>Total Responses</h4>
                                                            
                                                            </div>
                                                    </div>
                                                    <ul class="authoringNav">
                                                            <li class="me-4"><a aria-current="page" class="h6 text-gray-100 " href="/FormView">Summary</a></li>
                                                            <li class="me-4"><a class="h6 text-gray-100 active" href="/Individual">Individual</a></li>
                                                    </ul>
                                                </div>
                                                <div className='p-32'>
                                                    <Row className='h-100'>
                                                        <Col className='col-lg-12'>
                                                            <ul className='m-0 p-0 idividualList'>
                                                                <li className='d-flex'>
                                                                    <span className='listCount h6 text-gray-300 me-1'>1.</span>
                                                                    <h6 className='text-gray-300 font-normal'>Enter your full name<span className='text-gray-100 d-block mt-2'>Jenny Wilson</span></h6>
                                                                </li>
                                                                <li className='d-flex'>
                                                                    <span className='listCount h6 text-gray-300 me-1'>2.</span>
                                                                    <h6 className='text-gray-300 font-normal'>Upload your COVID Vaccination Certificate.<div className='d-flex text-gray-100 mt-2'><PDFicon /><span className='font-normal ms-2'>COVID vaccination.pdf</span><span className='ms-1'><PDFdownload /></span></div></h6>
                                                                </li>
                                                                <li className='d-flex'>
                                                                    <span className='listCount h6 text-gray-300 me-1'>3.</span>
                                                                    <h6 className='text-gray-300 font-normal'>How well did the onboarding process introduce you to Millipixels culture and Values?<span className='text-gray-100 d-block mt-2'>Option 2</span></h6>
                                                                </li>
                                                                <li className='d-flex'>
                                                                    <span className='listCount h6 text-gray-300 me-1'>4.</span>
                                                                    <h6 className='text-gray-300 font-normal'>What Improvements would you suggest to make the app experience better?<span className='text-gray-100 d-block mt-2'>Learn about company infrastructure that’s at your disposal as a team member</span></h6>
                                                                </li>
                                                                <li className='d-flex'>
                                                                    <span className='listCount h6 text-gray-300 me-1'>5.</span>
                                                                    <h6 className='text-gray-300 font-normal'>Enter your Email Address<span className='text-gray-100 d-block mt-2'>Abc123@gmail.com</span></h6>
                                                                </li>
                                                            </ul>
                                                        </Col>
                                                    </Row>
                                                </div>

                                        </div>
                                    </div>
                                </Col>
                               
                            </Row>
                        </div>
                    </div>      
            </div >
        </div >
    );
}

export default FormViewIndividual;

