import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button, Link } from "react-bootstrap";
import {
  IconStarLg,
  IconStarGray,
  IconPlanUpgrade,
} from "../../../icons/Icons";
import planBg from "../../../assets/images/plan_bg.png";
import { useDispatch, useSelector } from "react-redux";
import { getSubscriptionAsync, addSubscriptionAsync } from "../../../reducers/subscription-reducers";
// import { useParams } from 'react-router-dom';

const MakePlanSuccess = () => {

    const dispatch = useDispatch();
    const subscriptionData = useSelector((state) => state.subscription);
    // const { setup_intent, setup_intent_client_secret, source_type } = useParams();
    const params = new URLSearchParams(window.location.search);
    // const [selectedPlanData, setSelectedPlanData] = useState({});

    const setup_intent = params?.get('setup_intent');
    // const setup_intent_client_secret = params?.get('setup_intent_client_secret');
    // const source_type = params?.get('source_type');

    useEffect(() => {
      // alert('Update Card Success');
      dispatch(getSubscriptionAsync());
      const updatePaymentCardString = localStorage.getItem('updatePaymentCard');
      const updatePaymentCard = JSON.parse(updatePaymentCardString);

    //   const selectedPlanDataString = localStorage.getItem('selectedPlanData');
    //   const selectedPlan = JSON.parse(selectedPlanDataString);
    //   // console.log(selectedPlan,'selectedPlan');
    //   setSelectedPlanData(selectedPlan);

      // console.log(setup_intent,'setup_intent');
      // console.log(updatePaymentCard,'updatePaymentCard');
      if (setup_intent && updatePaymentCard?.state?.paymentMethodId) {
        const request = {
          intentId: setup_intent,
          // customerId: updatePaymentCard?.state?.customerId,
          paymentMethodId: updatePaymentCard?.state?.paymentMethodId,
          priceId: null, //updatePaymentCard?.state?.priceId,
          isNew: updatePaymentCard?.state?.isNew,
        }
        dispatch(addSubscriptionAsync(request)).then((res) => {
          if (res.payload) {
            console.log(res.payload);
            localStorage.removeItem('updatePaymentCard');
          }
        });
      }
    }, [setup_intent]);
  // console.log(subscriptionData,'subscriptionData')
  return (
    <div className="pages bg-gray-600">
      <PageHeader title="Account Settings" />
      <div className="d-flex align-items-center justify-content-center h-100 row g-0">
        <div className="text-center col-lg-6">
          <div className="mb-20">
            <IconPlanUpgrade />
          </div>

          <div className=" d-flex justify-content-center">
            <h1 className="mb-32">Payment method updated successfully!</h1>
          </div>

          {/* <div className="row d-flex justify-content-center">
            <div className="col-lg-8 rounded-6 borderAll bg-base-100 text-start p-4 mb-32 position-relative">
              <p className="mb-1 font-medium">
              {selectedPlanData?.state?.selectedPlanData?.planName}
              </p>
              <p className="text-gray-1000 mb-20 text-md w-75">
                Live 60 minute training on using Additional Training and Support
                $500/mo as an addon
              </p>

              <h3>
                ${selectedPlanData?.state?.selectedPlanData?.price}
              </h3>

              <p className="text-gray-1000 mb-0 text-md">
                {selectedPlanData?.planType === "Yearly" ? "annually" : "monthly"}
              </p>
              <div className="position-absolute end-0 bottom-0">
                <img src={planBg} alt="" />
              </div>
            </div>
          </div> */}

          <div className="text-center">
            <a href="/accountsetting/billingsubscription">
              <button class="btn-secondary">View card</button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakePlanSuccess;
