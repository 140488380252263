import React, { useState, useEffect } from "react";
import AccountImg from "../../assets/images/Account.png";
import DefaultUserImage from "../../assets/images/defaultUserImg.png";
import { generatePresignedUrl } from "../../api/common-store-api";
import LoaderUserImage from "../../assets/images/loaderUserImg.jpg";
import { getGUID } from "../../common";

const UserImage = ({ imgKey }) => {
  const [imgUrl, setImgUrl] = useState(DefaultUserImage);
  const [formDataReady, setFormDataReady] = useState(false);

  useEffect(() => {
    getImgUrl();
  }, [imgKey]);

  const getImgUrl = () => {
    // console.log(imgKey,'imgKey');
    if (imgKey && imgKey !== "") {
      const reqBody = getGUID(imgKey);
      generatePresignedUrl(reqBody).then((res) => {
        if (res?.data) setImgUrl(res.data);
        setFormDataReady(true);
      });
    }
  };

  if (!formDataReady) {
    return null;
  }

  return <img src={imgUrl} alt="photo" />;
};

export default UserImage;
