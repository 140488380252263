import React, { useContext, useState, useEffect } from "react";
import logoOops from '../../assets/images/logo.svg'
import chatBtnImg from '../../assets/images/chat_btn.svg'
import { useHistory, Link } from "react-router-dom";
import { AuthContext } from "../../providers/authProvider";

const Restricted = (props) => {
  const authService = useContext(AuthContext);
  const userProfile = authService.getUserProfile();
  const{ userType } = props;
  // console.log(userProfile,'userProfileuserProfileuserProfileuserProfile');
  return (
    <div className='oopsScreen'>
        <div className='d-flex justify-content-center pt-3'>
            <img src={logoOops} alt="logoOops" />
        </div>
        <div className='d-flex justify-content-center align-items-center' style={{height: "calc(100vh - 9rem)"}}>
            <div className='text-center col-lg-6'>
                <div className="row d-flex justify-content-center">
                    <h1 className='text-gray-100 fw-bold mb-1'>Restricted!</h1>
                    <p className='mb-1 mt-2 text-gray-300 h4 fw-normal'>
                        Upgrade your plan to access enhanced features and benefits.<br/> For assistance, please contact the administrator.
                    </p>
                </div>

                {userProfile?.role !== "Employee" && (
                    <div className="text-center">
                        <Link
                            className="btn-secondary mb-1 mt-2"
                            to="/accountsetting/billingsubscription"
                        >
                            View plans
                            {/* <a href="/accountsetting/billingsubscription/change">
                                <button class="btn-secondary">View plans</button>
                            </a> */}
                        </Link>
                    </div>
                )}                
            </div>
        </div>
        <div className='d-flex justify-content-center align-items-center text-center'>
            <p className='h6 m-0 text-gray-100'>Your data is secure. Know more about our <a className='border-bottom border-primary-200 text-primary-200' href='#'>Privacy and Data Security Policies.</a></p>
        </div>
        <button className='chat-button'>
            <img src={chatBtnImg} alt="chatBtnImg" />
        </button>
    </div>
  )
}

export default Restricted