import React, { useState, useEffect } from "react";
import MultiSelect from "multiselect-react-dropdown";

const MultiSelectDropdown = ({ options, onSelect, placeholder, selectedValues, setMethod, name,   autofocus,
  menuPlacement }) => {
  const handleSelect = (selectedList) => {
    // console.log(selectedList,'selectedList');
    // setSelectedValues([]);
    onSelect(selectedList);

  };
  const handleRemove = (selectedList) => {
    // Remove the item from the selected values instead of clearing it entirely
    const updatedValues = selectedValues.filter((value) =>
      selectedList.includes(value)
    );
    // setSelectedValues(updatedValues);
    onSelect(updatedValues);
  };

  useEffect(() => {
    if (selectedValues && name === 'restrictedTeamIds') {
      setMethod('restrictedTeamIds', selectedValues.map((team) => team.value))
    }
    if (selectedValues && name === 'restrictedIds') {
      setMethod('restrictedIds', selectedValues.map((usr) => usr.value))
    }
  }, [setMethod, name]);
  
  return (
    <MultiSelect
      options={options}
      selectedValues={selectedValues}
      onSelect={handleSelect}
      onRemove={handleRemove}
      autofocus={autofocus} 
      menuPlacement={menuPlacement}
      displayValue="label"
      //showCheckbox={true} 
      closeOnSelect={true}
      className="mulSelCustom"
      placeholder={placeholder}
      avoidHighlightFirstOption
    />
  );
};

export default MultiSelectDropdown;

