import { React, useState, useRef, useEffect } from "react";
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button } from "react-bootstrap";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";
import { createFeedbackAsync } from "../../reducers/user-reducers";
import { useDispatch } from "react-redux";
import { Formik, Form, useFormik, Field, ErrorMessage } from "formik";
import TextArea from "../../components/FormikInputs/TaxtArea";
import Box from "@mui/material/Box";
import Rating from "@mui/material/Rating";
import ToasterAlertSucces from "../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../helper/ErrorMessage";

const FeedbackEmployee = () => {
  // const location = useLocation();
  const navigate = useHistory();
  const params = useParams();
  const { id, previewType, title } = params;

  const dispatch = useDispatch();
  const [requestData, setRequestData] = useState(null);
  const [value, setValue] = useState(0);
  const formRef = useRef();
  // const { state, redirectTo } = location.state;

  const onFeedbackSubmit = () => {
    const currentFormData = formRef?.current;
    // let moduleType = "";
    // if (redirectTo === "/authoring/module/feedback") {
    //     moduleType = "module";
    // } else if (redirectTo === "/authoring/process/feedback") {
    //     moduleType = "process";
    // } else {
    //     moduleType = "sequence";
    // }
    const reqBody = {
      contentId: id,
      rating: currentFormData.values?.rating,
      comment: currentFormData.values?.comment,
      moduleType: previewType,
    };
    dispatch(createFeedbackAsync(reqBody)).then((res) => {
      ToasterAlertSucces(res, ErrrorMessage.FeedBackCreate);
      navigate.push("/catalogue");
    });
  };
  const feedbackValidation = (values) => {
    let errors = {};
  
    if (!values.rating || String(values.rating).trim() === "") {
      errors.rating = "Rating is required!";
    }
  
    return errors;
  };
  
  // useEffect(() => {
  //     const state = location.state;
  //     if (state != undefined && state.propsData != undefined) {
  //         let propdata = state.propsData;
  //         setRequestData(propdata);
  //     }
  // }, [location]);

  return (
    <div className="pages">
      <PageHeader title="Feedback" />
      <div className="d-flex align-items-center justify-content-center h-100">
        <div className="text-center mwidth-440">
          <div className="mb-32">
            <svg
              width="133"
              height="109"
              viewBox="0 0 133 109"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M66 88C83.6 88 98 73.6 98 56C98 38.4 83.6 24 66 24C48.4 24 34 38.4 34 56C34 73.6 48.4 88 66 88Z"
                fill="#2EB67D"
              />
              <path
                d="M52.4014 56.0003L61.4574 65.0563L79.6014 46.9443"
                fill="#2EB67D"
              />
              <path
                d="M52.4014 56.0003L61.4574 65.0563L79.6014 46.9443"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M107.403 30.1836C107.403 30.1836 105.62 21.6905 109.869 19.3341C114.205 16.9301 118.826 20.8421 123.007 18.161C127.337 15.384 125.828 4.87513 125.828 4.87513"
                stroke="#F15845"
                stroke-width="4"
                stroke-linecap="round"
              />
              <circle cx="113.5" cy="45.5" r="5.5" fill="#0080FF" />
              <circle cx="24.5" cy="35.5" r="5.5" fill="#ECB22E" />
              <path
                d="M111 67.9998C111 67.9998 118.799 61.591 123.857 65C127.758 67.629 131 74.9998 131 74.9998"
                stroke="#ECB22E"
                stroke-width="4"
                stroke-linecap="round"
              />
              <path
                d="M2 63.0012C2 63.0012 9.79858 69.4099 14.8571 66.0009C18.7583 63.3719 22 56.0012 22 56.0012"
                stroke="#F15845"
                stroke-width="4"
                stroke-linecap="round"
              />
              <circle
                cx="101.528"
                cy="100.742"
                r="5.5"
                transform="rotate(-43.1563 101.528 100.742)"
                fill="#0080FF"
              />
              <circle cx="25.5" cy="85.5" r="5.5" fill="#0080FF" />
              <circle cx="71.5" cy="5.5" r="5.5" fill="#0080FF" />
              <circle
                cx="85.9377"
                cy="92.1433"
                r="3"
                transform="rotate(-43.1563 85.9377 92.1433)"
                fill="#ECB22E"
              />
              <circle cx="80" cy="14" r="3" fill="#ECB22E" />
            </svg>
          </div>

          {/* <p className="mb-1 text-gray-300">{previewType.charAt(0).toUpperCase() + previewType.slice(1)} Complete</p> */}
          <p className="mb-1 text-gray-300">Assignment Complete</p>
          <h1 className="mb-32 pb-32 borderBottom">{title}</h1>

          <Formik
            initialValues={{
              rating: 0,
              comment: "",
            }}
            onSubmit={onFeedbackSubmit}
            validate={feedbackValidation}
            innerRef={formRef}
          >
            {({ setFieldValue, values }) => (
              <Form>
                <div className="text-start">
                  <p className="h6 mb-12">Please rate the Assignment
                  {/* {previewType} */}
                  </p>
                  <div className="d-flex gap-2 mb-4">
                    {/* <IconStarLg />
                      <IconStarLg />
                      <IconStarLg />
                      <IconStarLg />
                      <IconStarGray /> */}
                    <Box
                      sx={{
                        "& > legend": { mt: 2 },
                      }}
                    >
                      <Rating
                        name="simple-controlled"
                        value={value}
                        onChange={(event, newValue) => {
                          setValue(newValue);
                          setFieldValue("rating", newValue);
                        }}
                      />
                    </Box>
                  </div>

                  <p className="h6 mb-12">
                    Comments <span className="text-gray-300">(Optional)</span>
                  </p>
                  <div className="input-group myInput mb-32">
                    <TextArea
                      className="form-control"
                      rows="4"
                      type="text"
                      name="comment"
                      placeholder="Add text"
                    />
                  </div>
                  <div className="text-center">
                    <Button
                      variant="secondary"
                      disabled={Object.keys(feedbackValidation(values)).length !== 0}
                      onClick={() => onFeedbackSubmit()}
                    >
                      Submit
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default FeedbackEmployee;
