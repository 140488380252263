import { useState, useEffect } from "react";
import CheckBox from "../../../../components/CheckBox/CheckBox";
import { useDispatch, useSelector } from "react-redux";
import { getSpecificFileTypesEnumListAsync } from "../../../../reducers/common-reducers";

const FileTypeCheckboxes = ({
  ststusChange,
  selectedItemList,
  selectedItemLi,
  setselectedItemLi,
}) => {
  // console.log(selectedItemList,'selectedItemList');
  const dispatch = useDispatch();
  const optionPageType = useSelector((state) => state.common.specificFileTypes);

  // console.log(optionPageType,'optionPageType');

  const getTypeEnumList = async () => {
    await dispatch(getSpecificFileTypesEnumListAsync());
  };
  useEffect(() => {
    if (optionPageType.length === 0) {
      getTypeEnumList();
    }
  }, []);

  useEffect(() => {
    if (selectedItemList?.length > 0) {
      setselectedItemLi(selectedItemList);
    }
  }, [selectedItemList]);

  return (
    <div class="container">
      <div class="row">
        {optionPageType.map((obj, index) => (
          <div key={index} class="col-6">
            <CheckBox
              text={obj.name}
              status={selectedItemLi.includes(obj?.id)}
              ststusChange={ststusChange}
              id={obj.id}
            />
          </div>
        ))}
      </div>
    </div>
  );
};
export default FileTypeCheckboxes;
