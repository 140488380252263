import React, { useState, useEffect } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button, Link } from "react-bootstrap";
import {
  IconStarLg,
  IconStarGray,
  IconPlanUpgrade,
} from "../../../icons/Icons";
import planBg from "../../../assets/images/plan_bg.png";
import { useDispatch, useSelector } from "react-redux";
// import { useParams } from 'react-router-dom';
import { useHistory } from "react-router-dom";

const RestrictedPlan = ({ title, description }) => {
  const [restrictedTitle, setRestrictedTitle] = useState("");
  const history = useHistory();
  const subscriptionData = useSelector((state) => state.subscription);
  const [basePlan, setBasePlan] = useState({});

  useEffect(() => {
    setRestrictedTitle(title);
  }, [title]);

  useEffect(() => {
    if (
      subscriptionData?.subscriptionData?.latestInvoice &&
      subscriptionData?.subscriptionData?.upcomingInvoice &&
      subscriptionData?.subscriptionData?.plans?.length > 0
    ) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      setBasePlan(basePlan && basePlan?.length > 0 ? basePlan[0] : {});
    }
  }, [subscriptionData]);

  // console.log(basePlan, "basePlan");
  const handleClick = () => {
    history.push({
      pathname: "/accountsetting/billingsubscription/managesubscription",
      state: { planType: basePlan?.planType },
    });
  };

  // console.log(subscriptionData, "subscriptionData");
  return (
    <div className="page">
      <div className="d-flex align-items-center justify-content-center h-100 row g-0">
        <div className="text-center col-lg-6">
          <div className="mb-20">
            <IconPlanUpgrade />
          </div>

          <div className=" d-flex justify-content-center">
            <h1 className="mb-32">{restrictedTitle}</h1>
          </div>

          <div className="row d-flex justify-content-center">
            <div className="col-lg-8 rounded-6 borderAll bg-base-100 text-start p-4 mb-32 position-relative">
              <p className="text-gray-1000 mb-20 text-md w-75">{description}</p>

              <div className="position-absolute end-0 bottom-0">
                <img src={planBg} alt="" />
              </div>
            </div>
          </div>

          <div className="text-center">
            {/* <a href="/accountsetting/billingsubscription/change"> */}
            <button class="btn-secondary" onClick={handleClick}>
              View plans
            </button>
            {/* </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestrictedPlan;
