import React from "react";
import { Modal, Button } from "react-bootstrap";

const ReportSuccessModal = ({ handleModal, onClose }) => {
  return (
    <>
      <Modal.Header closeButton onClick={() => {handleModal(false);}}>
       
      </Modal.Header>
      <Modal.Body className="text-center">
      <svg width="105" height="93" viewBox="0 0 105 93" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M78.1112 86.5328H5.65625V34.9428H78.1112V86.5328Z" fill="#4A154B"/>
<path d="M85.1667 16.7048H71.7617V4.4694C71.7617 2.00202 73.7626 0.000225067 76.2309 0.000225067C77.465 0.000225067 78.5838 0.501961 79.3911 1.30922C80.1889 2.10701 80.6906 3.21204 80.7009 4.42895V4.4694L80.6975 12.2356C80.6975 12.2735 80.6975 12.3139 80.7009 12.3518V12.5867L80.7138 12.5798C80.8877 14.888 82.8146 16.7048 85.1667 16.7048Z" fill="white"/>
<path d="M89.6331 9.53596V12.2391C89.6331 13.4734 89.1358 14.5914 88.3242 15.3957C87.5197 16.207 86.4016 16.7048 85.1673 16.7048C82.815 16.7048 80.8889 14.888 80.7148 12.5801L89.6331 9.53596Z" fill="white"/>
<path d="M76.2294 0.0018692C76.1335 0.0018692 76.0416 0.00509644 75.9497 0.012352C75.8885 0.0155716 75.8305 0.0187912 75.7733 0.0260468C75.7072 0.0324936 75.646 0.0397491 75.5815 0.0494156C75.5444 0.0534439 75.5066 0.0598831 75.4695 0.0671387C75.422 0.0735855 75.3736 0.0840607 75.3293 0.0937347C75.2616 0.10743 75.1963 0.121132 75.1287 0.138054C75.0328 0.162224 74.9377 0.189621 74.8418 0.223465C74.7942 0.23716 74.7467 0.254089 74.7024 0.271011C74.6581 0.284706 74.6097 0.305664 74.5662 0.322586C74.5017 0.345947 74.4397 0.373344 74.3776 0.403961C74.3237 0.428139 74.2697 0.452301 74.2149 0.482925C74.2012 0.489372 74.1834 0.496628 74.1673 0.506294C74.119 0.530472 74.0714 0.557869 74.0239 0.585266C73.837 0.690826 73.6557 0.810081 73.4889 0.943039C73.4405 0.98011 73.3897 1.02119 73.3454 1.05907C73.2914 1.10339 73.2431 1.14771 73.1923 1.19525C73.1407 1.2428 73.0932 1.29034 73.0449 1.33868C72.9119 1.47486 72.7862 1.61749 72.6702 1.77139C72.6186 1.83989 72.5719 1.90435 72.5235 1.97203C72.3736 2.20088 72.2439 2.4394 72.1311 2.69161C72.1045 2.75285 72.0811 2.81812 72.0529 2.88258C71.982 3.07678 71.9208 3.27823 71.8724 3.4829C71.8587 3.55462 71.8458 3.62231 71.8321 3.6908C71.808 3.8302 71.7878 3.97363 71.7773 4.11706V66.8083H12.0273V6.97038C12.0273 3.1211 15.1465 0.0018692 18.9956 0.0018692H76.2294Z" fill="#EFEFEF"/>
<path d="M64.4339 8.77698H19.0938V6.39046H64.4339V8.77698Z" fill="white"/>
<path d="M60.9214 15.7614H19.0938V13.3752H60.9214V15.7614Z" fill="white"/>
<path d="M58.7156 22.7535H19.0938V20.3673H58.7156V22.7535Z" fill="white"/>
<path d="M64.4339 29.7399H19.0938V27.3533H64.4339V29.7399Z" fill="white"/>
<path d="M61.1943 36.816H36.6133V34.4294H61.1943V36.816Z" fill="white"/>
<path d="M41.5628 47.8278C41.2911 47.8278 41.0417 47.7354 40.8704 47.4994C40.66 47.2081 40.6746 46.8097 40.6844 46.5461L40.9164 40.379L41.2563 40.3918L41.025 46.5581C41.0146 46.8454 41.016 47.1185 41.1462 47.2992C41.436 47.699 42.1333 47.3531 42.2698 47.2796C42.956 46.9115 43.4492 46.2184 43.9257 45.5482C44.4558 44.8038 45.004 44.0332 45.8323 43.6712C46.3247 43.4561 46.6863 43.7716 46.9768 44.0238C47.1419 44.1667 47.2979 44.3016 47.463 44.3508C47.8963 44.4782 48.3282 44.3663 48.7844 44.247C49.1878 44.1418 49.6064 44.0312 50.0355 44.098C50.4277 44.1579 50.7447 44.3143 51.0526 44.466C51.5047 44.6892 51.8968 44.8828 52.4632 44.7452C53.2434 44.5571 53.9692 44.1128 54.67 43.6833C54.8741 43.5586 55.0768 43.4338 55.2809 43.3152C56.0702 42.8574 57.2544 42.2796 58.4337 42.5499L58.3578 42.8823C57.2941 42.6389 56.1907 43.1803 55.4523 43.6098C55.251 43.7278 55.0496 43.8505 54.849 43.9739C54.1246 44.4175 53.3757 44.876 52.5433 45.0769C51.8557 45.2421 51.3702 45.0034 50.9007 44.7715C50.6172 44.6313 50.3246 44.4869 49.9847 44.435C49.6238 44.3804 49.2581 44.4755 48.8708 44.5767C48.3888 44.7028 47.8921 44.8329 47.3655 44.6771C47.1307 44.6077 46.9391 44.4418 46.7538 44.2813C46.4494 44.0177 46.2495 43.8627 45.9688 43.9833C45.2269 44.3076 44.7295 45.0061 44.2036 45.7464C43.7041 46.4476 43.1873 47.1737 42.4308 47.5796C42.1619 47.7246 41.8498 47.8278 41.5628 47.8278Z" fill="#4A154B"/>
<path d="M32.8978 56.3311L32.4002 56.8832L9.91416 81.8166L5.65625 86.5348V34.9428L32.8978 56.3311Z" fill="#4A154B"/>
<path d="M78.1096 34.9428L50.8672 56.3303L78.1096 86.5328V34.9428Z" fill="#4A154B"/>
<path d="M32.4002 56.8843L9.91416 81.8177L5.65625 82.7555V73.5881C16.8958 65.5325 29.5058 56.1919 32.3867 55.991L32.4002 56.8843Z" fill="#4A154B"/>
<path d="M51.3672 56.8825L73.8536 81.8159L78.1111 82.7538V73.5864C66.8719 65.5308 54.2622 56.1901 51.3819 55.9892L51.3672 56.8825Z" fill="#4A154B"/>
<path d="M78.1115 86.5328H5.65625L5.66958 79.7462L30.6368 57.0963C31.9469 55.9084 33.6519 55.2497 35.4207 55.2497H48.1485C49.8912 55.2497 51.5733 55.8888 52.8768 57.0452L78.1827 79.5116L78.1115 86.5328Z" fill="#4A154B"/>
<path d="M78.1197 85.5641L78.1811 79.513L78.1094 79.4503V73.588V62.6143C82.5916 64.4967 85.7379 68.9263 85.7379 74.0916C85.7379 79.2528 82.5959 83.6797 78.1197 85.5641Z" fill="#888888"/>
<path d="M78.1137 73.5875C73.8717 70.5475 69.4344 67.3233 65.3984 64.4775C67.5472 62.7097 70.2983 61.6477 73.2988 61.6477C75.0062 61.6477 76.6326 61.9916 78.1137 62.6139V73.5875Z" fill="#4A154B"/>
<path d="M78.111 79.4489L63.4727 66.4526C64.0391 65.7244 64.6839 65.0623 65.3958 64.4764C69.4318 67.3223 73.8691 70.5464 78.111 73.5865V79.4489Z" fill="#4A154B"/>
<path d="M73.2947 86.5328C66.4222 86.5328 60.8516 80.9618 60.8516 74.0901C60.8516 71.2112 61.8296 68.5608 63.4709 66.4525L78.1098 79.4488L78.1809 79.5116L78.1196 85.5626C76.6353 86.1877 75.0055 86.5328 73.2947 86.5328Z" fill="#4A154B"/>
<path d="M104.602 72.9999C104.602 84.045 95.6468 92.9996 84.6001 92.9996C73.5564 92.9996 64.6016 84.045 64.6016 72.9999C64.6016 61.9539 73.5564 52.9992 84.6001 52.9992C95.6468 52.9992 104.602 61.9539 104.602 72.9999Z" fill="#2EB67D"/>
<path d="M81.3796 83.356C80.4635 83.1105 79.6872 82.4907 79.2452 81.6286L74.4337 72.2513C74.0183 71.4433 74.3362 70.4525 75.1459 70.0378C75.9526 69.6235 76.9441 69.9419 77.3583 70.7496L82.1695 80.1279C82.1801 80.1463 82.1985 80.1783 82.2536 80.184C82.31 80.1902 82.332 80.1612 82.3463 80.1449L94.191 65.3679C94.7578 64.6593 95.79 64.5454 96.5009 65.1129C97.2102 65.681 97.324 66.7156 96.7559 67.4239L84.9112 82.2009C84.1893 83.1025 83.0717 83.5716 81.9241 83.4548C81.7378 83.4362 81.5554 83.4031 81.3796 83.356Z" fill="white"/>
<path d="M88.7326 86.6385H0V86.4785H88.7326V86.6385Z" fill="#D9D9D9"/>
</svg>
<h5>Report Submitted</h5>
        <p>Your issue has been successfully submitted.</p>
      </Modal.Body>
      <Modal.Footer className="justify-content-end btnFooter gap-2">
        <Button variant="primary" className="border-0" onClick={() => {handleModal(false);}}>
        Okay
        </Button>
      </Modal.Footer>
    </>
  );
};
 
export default ReportSuccessModal;

 