import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { Constants } from "../services/Constants";
import {
  getAllUsers,
  getUserDetails,
  addUser,
  getRoles,
  getTimezone,
  getTeams,
  addAdmin,
  setDisableAccount,
  setDeleteAccount,
  setDeletePersonalAccount,
  bulkDisableAccount,
  bulkDeleteAccount,
  setBulkdeletePersonal,
  bulkEnableAccount,
  setEnableAccount,
  checkUniqueness,
  updateUser,
  assignModules,
  assignSequences,
  assignProcesses,
  assignContentToUsers,
  getContent,
  unassignContentToUsers,
  getEmpTeamList,
  createTeam,
  updateTeam,
  deleteTeam,
  getUserProfile,
  updateUserProfile,
  updatePassword,
  getUserPrefence,
  setUserPrefence,
  getTeamDetails,
  createFeedback,
  getUsersRole,
  checkUniquenessCompanyEmail,
  checkUniquenessPersonalEmail,
  checkUniquenessPhoneNumber,
  GetUnassignedEmployeeList,
  onSendVerificationRequest,
  onVerifyRequest,
  createTag,
  getTag,
  getEmployeeAssignments,
  setAssigmentStart,
  setAssigmentComplete,
  validateHelpdeskNumber,
  GetAllTemplates,
  getAllEmployeeForms,
  getSubmittedQuestionAnswers,
  getEmployeeAllDocuments,
  getEmployeeModulePreview,
  saveAssignment,
  getAssignee,
  getNotifications,
  logAction,
  getEmployeeSingleAssignments,
  markOtherNotificationRead,
  markNotificationRead,
  switchRole,
  getUserUsage,
  sendWelcomeNotification,
} from "../api/users-store-api";

const initialState = {
  users: { records: [] },
  loading: false,
  page: 0,
  pageSize: 10,
  searchString: "",
  orderBy: "",
  isOrderByDesc: true,
  status: [],
  tags: [],
  userSave: null,
  userUpdate: null,
  userDelete: null,
  currentUser: null,
  dropdownList: [],
  roles: [],
  teams: [],
  timezone: [],
  content: [],
  empTeamList: [],
  userProfile:[],
  userUsage:[],
  helpdesk:"",
  allTemplates:[],
  allEmployeeForms:[],
  submittedQuestionAnswer:[],
  employeeAllDocuments:[],
  logs:[]
};

export const updateUsersAsync = createAsyncThunk(
  "getAllUsers",
  async (data) => {
    return data;
  }
);

export const getUsersAsync = createAsyncThunk(
  "getAllUsers",
  async (request) => {
    const response = await getAllUsers(request);

    return response.data;
  }
);
export const getRolesAsync = createAsyncThunk("getRoles", async (request) => {
  const response = await getRoles(request);

  return response.data;
});

export const getTeamsAsync = createAsyncThunk("getTeams", async (request) => {
  const response = await getTeams(request);

  return response.data;
});
export const getTimezoneAsync = createAsyncThunk(
  "getTimezones",
  async (request) => {
    const response = await getTimezone(request);

    return response.data;
  }
);

export const getUserPrefenceAsync = createAsyncThunk(
  "getUserPrefence",
  async () => {
    const response = await getUserPrefence();

    return response.data;
  }
);
export const setUserPrefenceAsync = createAsyncThunk(
  "setUserPrefence",
  async (request) => {
    const response = await setUserPrefence(request);

    return response.data;
  }
);

export const addUserAsync = createAsyncThunk(
  "User/Admin/AddUser",
  async (request) => {
    const response = await addUser(request);

    return response.data;
  }
);

export const updateUserAsync = createAsyncThunk(
  "User/Admin/UpdateUser",
  async (request) => {
    const response = await updateUser(request);

    return response.data;
  }
);

export const addAdminAsync = createAsyncThunk(
  "addAdminAsync",
  async (request) => {
    const response = await addAdmin(request);

    return response.data;
  }
);

export const getUserDetailsAsync = createAsyncThunk(
  "getUserDetails",
  async (id) => {
    const response = await getUserDetails(id);
    return response.data;
  }
);

export const setDisableAccountAsync = createAsyncThunk(
  "disbleUser",
  async (id) => {
    const response = await setDisableAccount(id);
    return response.data;
  }
);
export const setEnableAccountAsync = createAsyncThunk(
  "enableUser",
  async (id) => {
    const response = await setEnableAccount(id);
    return response.data;
  }
);
export const setBulkDisableAccountAsync = createAsyncThunk(
  "bulkDisbleUser",
  async (request) => {
    const response = await bulkDisableAccount(request);
    return response.data;
  }
);
export const setBulkEnableAccountAsync = createAsyncThunk(
  "bulkEnableUser",
  async (request) => {
    const response = await bulkEnableAccount(request);
    return response.data;
  }
);

export const setdeleteAccountAsync = createAsyncThunk(
  "deleteUser",
  async (id) => {
    const response = await setDeleteAccount(id);
    return response.data;
  }
);
export const setBulkdeleteAccountAsync = createAsyncThunk(
  "bulkDisbleUser",
  async (request) => {
    const response = await bulkDeleteAccount(request);
    return response.data;
  }
);
export const setBulkdeletePersonalDataAsync = createAsyncThunk(
  "bulkDisbleUser",
  async (request) => {
    const response = await setBulkdeletePersonal(request);
    return response.data;
  }
);
export const setdeletePersonalDataAsync = createAsyncThunk(
  "deletePersonalData",
  async (id) => {
    const response = await setDeletePersonalAccount(id);
    return response.data;
  }
);
export const checkUniquenessAsync = createAsyncThunk(
  "checkUniqueness",
  async (request) => {
    const response = await checkUniqueness(request);
    return response.data;
  }
);

export const checkUniquenessCompanyEmailAsync = createAsyncThunk(
  "heckUniquenessCompanyEmail",
  async (request) => {
    const response = await checkUniquenessCompanyEmail(request);
    return response.data;
  }
);

export const checkUniquenessPersonalEmailAsync = createAsyncThunk(
  "heckUniquenessCompanyEmail",
  async (request) => {
    const response = await checkUniquenessPersonalEmail(request);
    return response.data;
  }
);

export const checkUniquenessPhoneNumberAsync = createAsyncThunk(
  "checkUniquenessPhoneNumber",
  async (request) => {
    const response = await checkUniquenessPhoneNumber(request);
    return response.data;
  }
);

export const assignModulesAsync = createAsyncThunk(
  "assignMoules",
  async (reqBody) => {
    console.log(reqBody);
    const response = await assignModules(reqBody);
    return response.data;
  }
);
export const assignProcessesAsync = createAsyncThunk(
  "assignProcess",
  async (reqBody) => {
    const response = await assignProcesses(reqBody);
    return response.data;
  }
);
export const assignSequencesAsync = createAsyncThunk(
  "assignSequences",
  async (reqBody) => {
    const response = await assignSequences(reqBody);
    return response.data;
  }
);

export const getContentAsync = createAsyncThunk(
  "EmployeeAssignMent/GetContent",
  async (params) => {
    const response = await getContent(params);
    return response.data;
  }
);

export const GetUnassignedEmployeeListAsync = createAsyncThunk(
  "GetUnassignedEmployeeList",
  async (reqBody) => {
    const response = await GetUnassignedEmployeeList(reqBody);
    return response.data;
  }
);

export const assignContentToUsersAsync = createAsyncThunk(
  "assignContentToUsersAsync",
  async (reqBody) => {
    const response = await assignContentToUsers(reqBody);
    return response.data;
  }
);

export const unassignContentToUsersAsync = createAsyncThunk(
  "unassignContentToUsersAsync",
  async (reqBody) => {
    const response = await unassignContentToUsers(reqBody);
    return response.data;
  }
);

export const getEmpTeamListAsync = createAsyncThunk(
  "getEmpTeamList",
  async (reqBody) => {
    const response = await getEmpTeamList(reqBody);
    return response.data;
  }
);

export const createTeamAsync = createAsyncThunk(
  "createTeam",
  async (reqBody) => {
    const response = await createTeam(reqBody);
    return response.data;
  }
);

export const createTagAsync = createAsyncThunk(
  "createTag",
  async (reqBody) => {
    const response = await createTag(reqBody);
    return response.data;
  }
);

export const getTagAsync = createAsyncThunk("getTag", async (request) => {
  const response = await getTag(request);

  return response.data;
});

export const updateTeamAsync = createAsyncThunk(
  "updateTeam",
  async (reqBody,  { rejectWithValue }) => {
    try {
      const response = await updateTeam(reqBody);
      return response.data;
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
);
export const getTeamDetailsAsync = createAsyncThunk(
  "getTeamDetails",
  async (reqBody) => {
    const response = await getTeamDetails(reqBody);
    return response.data;
  }
);
export const deleteTeamAsync = createAsyncThunk(
  "deleteTeam",
  async (reqBody) => {
    const response = await deleteTeam(reqBody);
    return response.data;
  }
);
export const getAssigneeAsync = createAsyncThunk(
  "getAssignee",
  async (reqBody) => {
    const response = await getAssignee(reqBody);
    return response.data;
  }
);

export const getUserProfileAsync = createAsyncThunk(
  "getUserProfile",
  async (reqBody) => {
    const response = await getUserProfile(reqBody);
    return response.data;
  }
);

export const getUserUsageAsync = createAsyncThunk(
  "getUserUsage",
  async () => {
    const response = await getUserUsage();
    return response.data;
  }
);

export const updateUserProfileAsync = createAsyncThunk(
  "updateUserProfile",
  async (reqBody) => {
    const response = await updateUserProfile(reqBody);
    return response.data;
  }
);
export const updatePasswordAsync = createAsyncThunk(
  "updatePassword",
  async (reqBody,  { rejectWithValue }) => {
    try {
    const response = await updatePassword(reqBody);
    return response.data;
  } catch (err) {
    if (!err.response) {
      throw err
    }
    return rejectWithValue(err.response.data)
  }
 }
);

export const createFeedbackAsync = createAsyncThunk(
  "createFeedback",
  async (reqBody, { rejectWithValue }) => {
    try {
      const response = await createFeedback(reqBody);
      return response.data
    } catch (err) {
      if (!err.response) {
        throw err
      }
      return rejectWithValue(err.response.data)
    }
  }
)

export const getUsersRoleAsync = createAsyncThunk(
  "getUsersRole",
  async (id) => {
    const response = await getUsersRole(id);
    console.log('response.data 1216= ', response.data)
    return response.data;
  }
)

export const onSendVerificationRequestAsync = createAsyncThunk(
  "sendVerificationRequestAsync",
  async (email) => {
    const response = await onSendVerificationRequest(email);
    return response.data;
  }
);

export const onVerifyRequestAsync = createAsyncThunk(
  "verifyRequest",
  async (reqBody) => {
    const response = await onVerifyRequest(reqBody);
    return response.data;
  }
);

export const getEmployeeAssignmentsAsync = createAsyncThunk(
  "getEmployeeAssignments",
  async (reqBody) => {
    const response = await getEmployeeAssignments(reqBody);
    return response.data;
  }
);

export const getEmployeeSingleAssignmentsAsync = createAsyncThunk(
  "getEmployeeSingleAssignments",
  async (reqBody) => {
    const response = await getEmployeeSingleAssignments(reqBody);
    return response.data;
  }
);

export const setAssigmentStartAsync = createAsyncThunk(
  "setAssigmentStart",
  async (reqBody) => {
    const response = await setAssigmentStart(reqBody);
    return response.data;
  }
);

export const setAssigmentCompleteAsync = createAsyncThunk(
  "setAssigmentComplete",
  async (assignmentId) => {
    const response = await setAssigmentComplete(assignmentId);
    return response.data;
  }
);

export const validateHelpdeskNumberAsync = createAsyncThunk(
  "validateHelpdeskNumber",
  async (reqBody) => {
    const response = await validateHelpdeskNumber(reqBody);
    return response.data;
  }
);

export const getAllTemplatesAsync = createAsyncThunk(
  "GetAllTemplates",
  async (reqBody) => {
    const response = await GetAllTemplates(reqBody);
    return response.data;
  }
);

export const getAllEmployeeFormsAsync = createAsyncThunk(
  "getAllEmployeeForms",
  async (reqBody) => {
    const response = await getAllEmployeeForms(reqBody);
    return response.data;
  }
);

export const getEmployeeModulePreviewAsync = createAsyncThunk(
  "processes/getEmployeeModulePreviewAsync",
  async (reqBody) => {
    const response = await getEmployeeModulePreview(reqBody);
    return response.data;
  }
);

export const getSubmittedQuestionAnswersAsync = createAsyncThunk(
  "getSubmittedQuestionAnswers",
  async (reqBody) => {
    const response = await getSubmittedQuestionAnswers(reqBody);
    return response.data;
  }
);

export const getEmployeeAllDocumentsAsync = createAsyncThunk(
  "getEmployeeAllDocuments",
  async (reqBody) => {
    const response = await getEmployeeAllDocuments(reqBody);
    return response.data;
  }
);

export const saveAssignmentAsync = createAsyncThunk(
  "saveAssignment",
  async (reqBody) => {
    const response = await saveAssignment(reqBody);
    return response.data;
  }
);

export const getNotificationsAsync = createAsyncThunk(
  "getNotifications",
  async (reqBody) => {
      const response = await getNotifications(reqBody);
      return response.data
  }
)

export const getMarkOtherNotificationReadAsync = createAsyncThunk(
  "markOtherNotificationRead",
  async (reqBody) => {
    const response = await markOtherNotificationRead(reqBody);
    return response.data
  }
)

export const getMarkNotificationReadAsync = createAsyncThunk(
  "markNotificationRead",
  async (reqBody) => {
    const response = await markNotificationRead(reqBody);
    return response.data
  }
)

export const logActionAsync = createAsyncThunk(
  "logAction",
  async (reqBody) => {
    const response = await logAction(reqBody);
    return response.data;
  }
);

export const switchRoleAsync = createAsyncThunk(
  "switchRole",
  async (role) => {
    const response = await switchRole(role);
    return response.data;
  }
);

export const sendWelcomeNotificationAsync = createAsyncThunk(
  "sendWelcomeNotification",
  async (role) => {
    const response = await sendWelcomeNotification(role);
    return response.data;
  }
);

const usersSlice = createSlice({
  name: "user",
  initialState,
  reducers: {},
  extraReducers: {
    [getUsersAsync.pending]: () => {
      console.log("pending");
    },
    [getUsersAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, users: payload };
    },
    [GetUnassignedEmployeeListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, users: payload };
    },
    [getUsersAsync.rejected]: () => {
      console.log("rejected");
    },

    [getUserProfileAsync.pending]: () => {
      console.log("pending");
    },
    [getUserProfileAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, userProfile: payload };
    },
    [getUserProfileAsync.rejected]: () => {
      console.log("rejected");
    },

    
    [getUserUsageAsync.pending]: () => {
      console.log("pending");
    },
    [getUserUsageAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, userUsage: payload };
    },
    [getUserUsageAsync.rejected]: () => {
      console.log("rejected");
    },

    [getUserDetailsAsync.pending]: () => { },

    [getUserDetailsAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, currentUser: payload };
    },
    [getUserDetailsAsync.rejected]: () => { },

    [getRolesAsync.pending]: () => {
      console.log("pending");
    },
    [getRolesAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, roles: payload };
    },
    [getRolesAsync.rejected]: () => {
      console.log("rejected");
    },
    [getTeamsAsync.pending]: () => {
      console.log("pending");
    },
    [getTeamsAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, teams: payload };
    },
    [getTeamsAsync.rejected]: () => {
      console.log("rejected");
    },
    [getTimezoneAsync.pending]: () => {
      console.log("pending");
    },
    [getTimezoneAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, timezone: payload };
    },
    [getTimezoneAsync.rejected]: () => {
      console.log("rejected");
    },
    [getContentAsync.pending]: () => {
      console.log("pending");
    },
    [getContentAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, content: payload };
    },
    [getContentAsync.rejected]: () => {
      console.log("rejected");
    },
    [getEmpTeamListAsync.pending]: () => {
      console.log("pending");
    },
    [getEmpTeamListAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, empTeamList: payload };
    },
    [getEmpTeamListAsync.rejected]: () => {
      console.log("rejected");
    },

    [getUsersRoleAsync.pending]: () => { },

    [getUsersRoleAsync.fulfilled]: (state, { payload }) => {
      // console.log("payload 1216 = ", payload);
      return { ...state, currentUser: payload };
    },
    [getUsersRoleAsync.rejected]: () => { },

    [getTagAsync.pending]: () => {
      console.log("pending");
    },
    [getTagAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, tags: payload };
    },
    [getTagAsync.rejected]: () => {
      console.log("rejected");
    },

    [validateHelpdeskNumberAsync.pending]: () => {
      console.log("pending");
    },
    [validateHelpdeskNumberAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, helpdesk: payload };
    },
    [validateHelpdeskNumberAsync.rejected]: () => {
      console.log("rejected");
    },
    
    [getAllTemplatesAsync.pending]: () => {
      console.log("pending");
    },
    [getAllTemplatesAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, allTemplates: payload };
    },
    [getAllTemplatesAsync.rejected]: () => {
      console.log("rejected");
    },

    [getAllEmployeeFormsAsync.pending]: () => {
      console.log("pending");
    },
    [getAllEmployeeFormsAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, allEmployeeForms: payload };
    },
    [getAllEmployeeFormsAsync.rejected]: () => {
      console.log("rejected");
    },

    [getSubmittedQuestionAnswersAsync.pending]: () => {
      console.log("pending");
    },
    [getSubmittedQuestionAnswersAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, submittedQuestionAnswer: payload };
    },
    [getSubmittedQuestionAnswersAsync.rejected]: () => {
      console.log("rejected");
    },

    [getEmployeeAllDocumentsAsync.pending]: () => {
      console.log("pending");
    },
    [getEmployeeAllDocumentsAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, employeeAllDocuments: payload };
    },
    [getEmployeeAllDocumentsAsync.rejected]: () => {
      console.log("rejected");
    },
    
    [logActionAsync.pending]: () => {
      console.log("pending");
    },
    [logActionAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, logs: payload };
    },
    [logActionAsync.rejected]: () => {
      console.log("rejected");
    },
  },
});

//export const { processesAdded, processesUpdated, processesDeleted } = processesSlice.actions;

export default usersSlice.reducer;
