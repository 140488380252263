import React, {useState, useEffect} from 'react';
import Select from "react-select";
import { isValidPhoto, isValidVideo, isValidPPT, isValidDocument, isValidMusic } from './formFileValidation';
import Video from '../video';
import ImagePreview from '../ImagePreview';
import Ppt from "../ppt";
import Doc from "../Doc";
import Audio from "../Audio";

const Dropdown = ({title, serialNo, options, questionsFiles, isRequired, onDropdownChange, value, disabled }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const multipleChoiceOpt = options.map((obj) => ({
    label: obj.optionValue,
    value: obj.optionValue,
  }));
  const handleDropdownChange = (selectedOption) => {
    console.log(selectedOption,"selectedOption")
    setSelectedOption(selectedOption);
    onDropdownChange(selectedOption);
  };
  console.log(multipleChoiceOpt,"multipleChoiceOpt")
  useEffect(() => {
    // Set the selected option when the 'value' prop changes
    setSelectedOption(multipleChoiceOpt.find((opt) => opt.value === value) || null);
  }, [value]);
    return (
        <div className='mt-3 pt-3'>
            <h6 className='mb-3 font-medium'>{serialNo}. {title}
            {isRequired && (<span className="mandatoryFields" style={{ color: "red" }}>*</span>)}
            </h6>
            {questionsFiles?.length > 0 ? (
              <>
                {isValidVideo(
                  questionsFiles.length > 0 &&
                  questionsFiles[0]?.filePath
                ) ? (
                  <Video contentUrl={questionsFiles[0]?.filePath}
                  />
                ) : isValidPhoto(
                    questionsFiles.length > 0 &&
                    questionsFiles[0]?.filePath
                  ) ? (
                  <ImagePreview
                    contentUrl={
                      questionsFiles[0].filePath
                    }
                  />
                ) : isValidPPT(
                  questionsFiles.length > 0 &&
                  questionsFiles[0]?.filePath
                ) ? (
                  <Ppt
                    contentUrl={
                      questionsFiles[0].filePath
                    }
                  />
                ) : isValidDocument(
                  questionsFiles.length > 0 &&
                  questionsFiles[0]?.filePath
                ) ? (
                  <Doc
                    contentUrl={
                      questionsFiles[0].filePath
                    }
                  />
                ) : isValidMusic(
                  questionsFiles.length > 0 &&
                  questionsFiles[0]?.filePath
                ) ? (
                  <Audio
                    contentUrl={
                      questionsFiles[0].filePath
                    }
                  />
                ) : null}
              </>
            ) : null}
            <div className='mySelect newMySelect'>
                <Select
                isSearchable={false}
                    className="select"
                    classNamePrefix="selectCustom"
                    options={multipleChoiceOpt}
                    name="dropdown"
                    onChange={handleDropdownChange}
                    // value={multipleChoiceOpt.find(opt => opt.value === value)} 
                    value={selectedOption}
                    isDisabled={disabled}
                />
            </div>
        </div>
    )
}

export default Dropdown
