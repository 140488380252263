import React from 'react';
import { Modal, Button, Row, Col, Container, Accordion } from "react-bootstrap";
import { Link } from "react-router-dom";
import CheckBoxMd from "../../components/CheckBox/CheckBoxMd";
const TemplatePreviewModal = ({ handleModal }) => {
    return (
        <>
            <Modal.Header closeButton className='ps-5'>
                <div className='w-100'>
                    <Modal.Title>



                        <div className='d-flex align-items-center gap-2'>
                            <Link to="/authoring">
                                <span className='arrow left gray-200'></span>
                            </Link>
                            <div>
                                <h3 className='mb-1'> Template Preview</h3>
                                <div className='d-flex gap-2'>
                                    <p className='h6 text-gray-200 mb-0'>Templates</p>

                                    <p className='h6 text-primary-200 mb-0'> <span className='arrow right me-2'></span> HR Onboarding</p>
                                </div>


                            </div>
                        </div>


                    </Modal.Title>
                </div>
            </Modal.Header>
            <Modal.Body className='p-0'>
                <div className='p-32 bg-gray-600 h-100 d-flex'>
                    <div className='borderAll rounded-6 boxShadow h-100 d-flex overflow-hidden flex-column'>

                        <div className='borderBottom p-32 pb-4 bg-base-100 '>
                            <h2 className='mb-2'>HR Onboarding</h2>
                            <p className='h6 mb-12'><span className='text-gray-300 me-1'>Created on</span> Jul 14, 2020 </p>
                            <p className='text-gray-300 mb-0'>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo ligula eget dolor. Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis enim.</p>
                        </div>

                        <div className='p-32 bg-base-400 overflow-auto h-100 flex-grow-1'>
                            <div className='mb-4 d-flex justify-content-between align-items-center'>

                                <h5>Content</h5>

                                <div className='d-flex gap-3'>
                                    <p className='h6 text-gray-200'>
                                        <CheckBoxMd text="All" /></p>
                                    <p className='h6 text-gray-200'>
                                        <CheckBoxMd text="Sequences" /></p>
                                    <p className='h6 text-gray-200'>
                                        <CheckBoxMd text="Independent Modules" /></p>
                                </div>
                            </div>

                            <div className="d-flex  mb-4">
                                <div className='dataItem'>
                                    <p className='h6 mb-3 text-gray-300'>Total Sequences</p>

                                    <h3 className='font-medium'>8</h3>
                                </div>

                                <div className='dataItem'>
                                    <p className='h6 mb-3 text-gray-300'>Total Modules</p>

                                    <h3 className='font-medium'>15</h3>
                                </div>
                            </div>

                            <div>
                                <Accordion defaultActiveKey="0">
                                    <Accordion.Item eventKey="0" className="mb-4 cardContent">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between align-items-center p-12 pe-3 w-100'>
                                                <div className='d-flex gap-3 align-items-start'>

                                                    <div className='count'>
                                                        <span className='text-xs'>1</span>
                                                    </div>

                                                    <div>
                                                        <p className='text-primary-200 text-md mb-0'>Sequence</p>
                                                        <p className='font-medium mb-0 text-gray-100'>All About Cerebrent</p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <p className='text-md text-gray-300 mb-0'>40 mins</p>
                                                </div>
                                            </div>
                                        </Accordion.Header>
                                        <Accordion.Body>
                                            <div className='d-flex justify-content-between align-items-center cardBody mb-20'>

                                                <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                    <div className="count text-xs text-gray-200 borderPurple">1</div>
                                                    <div>
                                                        <p className='text-primary-200 text-md mb-0'>Module</p>
                                                        <p className='font-medium mb-0 text-gray-100'>Jira Tutorial</p>
                                                    </div>
                                                </div>

                                                <div>  <p className='text-md text-gray-300 mb-0'><span className='text-secondary-600 text-md me-3 d-inline-block'>Expired</span>20 mins</p></div>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center cardBody'>

                                                <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                    <div className="count text-xs text-gray-200">2</div>
                                                    <div>
                                                        <p className='text-primary-200 text-md mb-0'>Module</p>
                                                        <p className='font-medium mb-0 text-gray-100'>Jira Tutorial</p>
                                                    </div>
                                                </div>

                                                <div>  <p className='text-md text-gray-300 mb-0'>20 mins</p></div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item eventKey="1" className="mb-4 cardContent">
                                        <Accordion.Header>
                                            <div className='d-flex justify-content-between align-items-center p-12 pe-3 w-100'>
                                                <div className='d-flex gap-3 align-items-start'>

                                                    <div className='count'>
                                                        <span className='text-xs noBgBorderPurple'>2</span>
                                                    </div>

                                                    <div>
                                                        <p className='text-primary-200 text-md mb-0'>Sequence</p>
                                                        <p className='font-medium mb-0 text-gray-100'>Team roles &amp; responsibilities </p>
                                                    </div>
                                                </div>

                                                <div>
                                                    <p className='text-md text-gray-300 mb-0'>40 mins</p>
                                                </div>
                                            </div></Accordion.Header>
                                        <Accordion.Body>
                                            <div className='d-flex justify-content-between align-items-center cardBody mb-20'>

                                                <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                    <div className="count text-xs text-gray-200 borderPurple">1</div>
                                                    <div>
                                                        <p className='text-primary-200 text-md mb-0'>Module</p>
                                                        <p className='font-medium mb-0 text-gray-100'>Jira Tutorial</p>
                                                    </div>
                                                </div>

                                                <div>  <p className='text-md text-gray-300 mb-0'>20 mins</p></div>
                                            </div>

                                            <div className='d-flex justify-content-between align-items-center cardBody'>

                                                <div className='d-flex gap-3 align-items-center justify-content-start'>

                                                    <div className="count text-xs text-gray-200">2</div>
                                                    <div>
                                                        <p className='text-primary-200 text-md mb-0'>Module</p>
                                                        <p className='font-medium mb-0 text-gray-100'>Jira Tutorial</p>
                                                    </div>
                                                </div>

                                                <div>  <p className='text-md text-gray-300 mb-0'>20 mins</p></div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>

                                </Accordion>
                            </div>

                        </div>
                    </div>



                </div>
            </Modal.Body>

        </>
    );
}

export default TemplatePreviewModal;
