import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  IconEyeDefault,
  IconEyeSlash
} from "../../../icons/Icons";


import { useDispatch } from "react-redux";
import { updatePasswordAsync } from "../../../reducers/user-reducers";

import TextInput from "../../../components/FormikInputs/TextInput";
import { Formik } from "formik";

import ModalResponse from "../../../components/ModalPopup/ModalResponsePopup";
import AuthService from "../../../services/AuthService";
import { toast } from "react-toastify";
const UpdatePassword = ({ onClose, isPasswordNotSet, oldPasswords }) => {
  const dispatch = useDispatch();


  const formRef = useRef();

  const [responsePopup, setResponsePopup] = useState({});
  const [showPassword, setShowPassword] = useState();

  const authService = new AuthService();
  const onResponseClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
    onClose(false);

  };

  const processResponse = (res) => {
  let errorMessage = "";
  if (res.error) {
    errorMessage = res.payload.split(":")[1];
    errorMessage = errorMessage.split("\n")[0];
  }
    let prompt =!res?.error ? "Success": "Error";
    // prompt = "Success";
    // let message = !res?.error ? "Your password has been saved successfully" : errorMessage;
    let message = !res?.error ? authService.logout() : errorMessage;
    
    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records: [],
      procesFailedError: res?.errors?.message,
    });
  };
  const passwordValidation = (values) => {
    let errors = {};
  
    if (!values.password && (!isPasswordNotSet)) {
      errors.password = "Old password is required";
    } 
    else if(values.password != oldPasswords){
      errors.password = "A valid old password is required!";
    }
     if (values.password === values.newPassword) {
      errors.newPassword = "Old and New Password cannot be the same";
    }
  
    if (!values.newPassword) {
      errors.newPassword = "New password is required";
    } else if (values.newPassword.length < 8) {
      errors.newPassword = "New password must be at least 8 characters long";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]/.test(
        values.newPassword
      ) && values.newPassword !== "password"
    ) {
      errors.newPassword =
        "New password must contain at least one uppercase letter, one lowercase letter, one number, one special character";
    }
  
    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = "Password does not match";
    }
  
    return errors;
  };
  

  const onPasswordSubmit = () => {
    const currentFormData = formRef?.current;
    var errors = passwordValidation(currentFormData.values);
    if (
      Object.keys(currentFormData.errors).length === 0 &&
      Object.keys(errors).length === 0
    ) {

      const reqBody = {
        password: currentFormData.values?.password,
        newPassword:  currentFormData.values?.newPassword 
      };

      dispatch(updatePasswordAsync(reqBody)).then(res => {
        //processResponse(res);
        toast.success('Password updated successfully');
        onClose();
      });
    }
  };
// console.log(formRef?.current, "form");
  return (
    <>
  
      <Modal.Header className="bg-white border-0 pt-4 mt-3 pb-0" closeButton onClick={() => onClose(false)}>
                <Modal.Title className="h4 font-bold">Update Password</Modal.Title>
            </Modal.Header>
       
        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
            oldPassword: ""

          }}
          onSubmit={onPasswordSubmit}
          validate={passwordValidation}
          innerRef={formRef}
        >
          {({
            values
           }) => {
            return (
              <>
              <Modal.Body className="updatePassword">
                {!isPasswordNotSet && (
                  <div className="input-group myInput mb-20 d-flex">
                    <TextInput
                      isRequired={true}
                      type={showPassword == 1 ? "text" : "password"}
                      name="password"
                      placeholder="Old Password"
                      className="form-control"
                      label={"Old Password"}

                    />
                    {showPassword != 1 &&
                      <span style={{ cursor: "pointer" }} onClick={() => { console.log(showPassword); setShowPassword(1) }}>
                        <IconEyeDefault />
                      </span>

                    }
                    {showPassword == 1 &&
                      <span  style={{ cursor: "pointer" }} onClick={() => { console.log(showPassword); setShowPassword() }}>
                        <IconEyeSlash />
                      </span>
                    }
                  </div>)}

                <div className="input-group myInput mb-20 d-flex">
                  <TextInput
                    isRequired={true}
                    type={showPassword == 2 ? "text" : "password"}
                    name="newPassword"
                    placeholder="New Password"
                    className="form-control"
                    label={"New Password"}

                  />
                  {showPassword != 2 &&
                    <span  style={{ cursor: "pointer" }} onClick={() => { console.log(showPassword); setShowPassword(2) }}>
                      <IconEyeDefault />
                    </span>

                  }
                  {showPassword == 2 &&
                    <span  style={{ cursor: "pointer" }}  onClick={() => { console.log(showPassword); setShowPassword() }}>
                      <IconEyeSlash />
                    </span>
                  }

                </div>
                <div className="input-group myInput mb-12 d-flex">
                  <TextInput
                    isRequired={true}
                    type={showPassword == 3 ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm Password"
                    className="form-control "
                    label={"Confirm Password"}

                  />
                  {showPassword != 3 &&
                    <span  style={{ cursor: "pointer" }}  onClick={() => { console.log(showPassword); setShowPassword(3) }}>
                      <IconEyeDefault />
                    </span>

                  }
                  {showPassword == 3 &&
                    <span  style={{ cursor: "pointer" }}  onClick={() => { console.log(showPassword); setShowPassword() }}>
                      <IconEyeSlash />
                    </span>
                  }
                </div>
 
              </Modal.Body>
              
              <Modal.Footer className="justify-content-end gap-2">
          
             <Button variant="white" className="m-0" size="" onClick={() => onClose(false)}>
               Close
             </Button>
             <Button className="m-0" variant="secondary" size="" 
              disabled={Object.keys(passwordValidation(values)).length !==0}
              onClick={() => onPasswordSubmit()}>
              Update
             </Button>
             </Modal.Footer>
              </>)
          }}
        </Formik>

        <Modal
          className=""
          size="sm"
          centered
          show={responsePopup?.show}
        >
          {responsePopup?.show && (
            <ModalResponse data={responsePopup} onClose={onResponseClose} />
          )}
        </Modal>
    </>
  );


};

export default UpdatePassword;
