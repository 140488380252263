export const questionType = {
  ShortAnswer: 1,
  Paragraph: 2,
  MultipleChoice: 3,
  Checkboxes: 4,
  Dropdown: 5,
  FileUpload: 6,
  Date: 7,
  Time: 8
};
