import React, { useState, useEffect } from 'react'
import { IconDownload, IconDownloadBlue, IconDropDown, IconPhoto, IconAudio, IconVideo, IconDocument, IconVideoPlay } from '../../../icons/Icons'
import attachmentsCard1 from "../../../assets/images/journyImg8.jpg"
import attachmentsCard2 from "../../../assets/images/journyImg.jpg"
import attachmentsCard3 from "../../../assets/images/journyImg6.jpg"
// import { IconDownload, IconLink, IconListDoc, IconPhoto } from '../../../icons/Icons'
import DownloadLink from "../../Authoring/ContentPreview/DownloadLink";
import { bulkGeneratePresignedUrl, generatePresignedUrl } from "../../../api/common-store-api";
import { getGUID, getFileExtension, getRemainingStringFileName } from "../../../common";

const Attachments = ({allDocuments}) => {
    const [openCard, setOpenCard] = useState(false);
    const [countFile, setCountFile] = useState(0);

    const iconMap = {
        IconPhoto: IconPhoto,
        IconAudio: IconAudio,
        IconVideo: IconVideo,
        IconDocument: IconDocument
    };

    const countFiles = (documents) => {
        let fileCount = 0;
        documents.forEach(doc => {
            const files = doc.split(',');
            fileCount += files.length;
        });
        return fileCount;
    };

    useEffect(() => {
        setCountFile(countFiles(allDocuments));
    }, [allDocuments]);

    const getBulkGeneratePresignedUrl = async (files) => {
        // console.log(files,'filesfilesfilesfilesfilesfiles');

        let allDocuments = [];
        files.forEach(file => {
        const documents = file?.split(',');
        documents.forEach(document => {
            allDocuments.push(document.trim());
        });
        });

        const processedFiles = allDocuments.map((file) => getGUID(file));
        let responseURLsDownloadable = await bulkGeneratePresignedUrl(processedFiles.map((value, i) => processedFiles[i]));
        // let responseURLsDownloadable = await bulkGeneratePresignedUrl(files.map((value, i) => files[i]));
        if (responseURLsDownloadable?.data) {
            return  responseURLsDownloadable?.data;
        }
    };

    const handleDownload = async () => {
        const urls = await getBulkGeneratePresignedUrl(allDocuments);
        if (urls && urls.length) {
            urls.forEach((url) => {
                const link = document.createElement('a');
                link.href = url;
                link.target = '_blank';
                link.download = ''; // Optional: Set the desired file name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            });
        }
    };

    return (
        <div className={`formAttachments ${openCard ? "active" : ""}`}>
            <div className='pb-3 d-flex align-items-center justify-content-between'>
                <div className='d-flex align-items-center'>
                    <h6>Attachments {`(${countFile})`} </h6>
                    {openCard && 
                    <div className='ps-3'>
                        <a className='d-flex align-items-center cursor-pointer' onClick={handleDownload} role="button">
                        {/* <a className='d-flex align-items-center' href={getBulkGeneratePresignedUrl(allDocuments)} target="blank" download> */}
                        {/* <button className='d-flex align-items-center' onClick={() => getBulkGeneratePresignedUrl(allDocuments)} > */}
                            <IconDownloadBlue/>
                            <span className='ms-2 text-secondary-500'>Download all</span>
                        {/* </button> */}
                        </a>
                    </div>
                    }
                </div>
                <div>
                    <button className={`px-2 openCardBtn ${openCard ? "active" : ""}`} onClick={() => setOpenCard(!openCard)}>
                        <IconDropDown/>
                    </button>
                </div>
            </div>
            <div className={`attachmentsCards ${openCard ? "active" : ""}`}>
                <div>
                    <div className='attachmentsCardsRow'>
                    {allDocuments.map((doc, index) => {
                        const documentList = doc?.split(',');
                        return documentList.map((doc, docIndex) => {
                            const fileExtension = getFileExtension(doc);
                            const fileName = getRemainingStringFileName(doc);
                            const IconComponent = iconMap[fileExtension] || IconDocument;
                            return (
                                <div className="attachmentsCard">
                                    <div className="imageBox">
                                        {fileExtension === "IconVideo" ? (
                                            <>
                                                <img src={attachmentsCard2} alt="attachmentsCard2" />
                                                <span className="playIcon"><IconVideoPlay /></span>
                                            </>
                                        ) : fileExtension === "IconAudio" ? (
                                            <>
                                                <img src={attachmentsCard2} alt="attachmentsCard2" />
                                                <span className="playIcon"><IconVideoPlay /></span>
                                            </>
                                        ) : fileExtension === "IconPhoto" ? (
                                            <img src={attachmentsCard3} alt="attachmentsCard3" />
                                        ) : (
                                            <img src={attachmentsCard1} alt="attachmentsCard1" />
                                        )}
                                    </div>
                                    <button>
                                        <IconComponent/>
                                        {/* <span>{doc}</span> */}
                                        <span>{fileName}</span>
                                        <DownloadLink url={doc} />
                                    </button>
                                </div>
                            )
                        });
                        // <div className="attachmentsCard">
                        //     <div className="imageBox">
                        //         <img src={attachmentsCard2} alt="attachmentsCard2" />
                        //         <span className="playIcon"><IconVideoPlay /></span>
                        //     </div>
                        //     <button>
                        //         <IconVideo/>
                        //         <span>{doc}</span>
                        //         <DownloadLink url={doc} />
                        //     </button>
                        // </div>
                        // <div className="attachmentsCard">
                        //     <div className="imageBox">
                        //         <img src={attachmentsCard2} alt="attachmentsCard2" />
                        //         <span className="playIcon"><IconVideoPlay /></span>
                        //     </div>
                        //     <button>
                        //         <IconPhoto/>
                        //         <span>{doc}</span>
                        //         <DownloadLink url={doc} />
                        //     </button>
                        // </div>
                        // <div className="attachmentsCard">
                        //     <div className="imageBox">
                        //         <img src={attachmentsCard3} alt="attachmentsCard3" />
                        //     </div>
                        //     <button>
                        //         <IconPhoto/>
                        //         <span>{doc}</span>
                        //         <DownloadLink url={doc} />
                        //     </button>
                        // </div>
                    })}
                        {/* <div className="attachmentsCard">
                            <img src={attachmentsCard1} alt="attachmentsCard1" />
                            <button>
                                <IconPhoto/>
                                <span>COVID vaccination.pdf</span>
                                <IconDownload className='iconDownload'/>
                            </button>
                        </div>
                        <div className="attachmentsCard">
                            <img src={attachmentsCard1} alt="attachmentsCard1" />
                            <button>
                                <IconPhoto/>
                                <span>COVID vaccination.pdf</span>
                                <IconDownload className='iconDownload'/>
                            </button>
                        </div> */}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Attachments
