import React, { useState } from 'react';
import { Row, Col, Container, Modal } from "react-bootstrap";
import AccountImg from "../../assets/images/Account.png";
import {
  IconArrow,
  IconAddEmployee,
  IconModule2,
  IconSequence2,
  IconReportIssue2,
  IconDashboardMostPopular1,
  IconDashboardMostPopular2,
  IconDashboardMostPopular3,
  IconDashboardMostPopular4,
  IconDashboardMostPopular5,
  IconDashboardMostPopular6,
} from "../../icons/Icons";
import EmptyState from "../../components/EmptyState/EmptyState";

const MostPopularContentModal = ({ handleModal, mostPopularContent }) => {
  const availableIcons = [
    <IconDashboardMostPopular1 />,
    <IconDashboardMostPopular2 />,
    <IconDashboardMostPopular3 />,
    <IconDashboardMostPopular4 />,
    <IconDashboardMostPopular5 />,
    <IconDashboardMostPopular6 />,
    <IconDashboardMostPopular1 />,
    <IconDashboardMostPopular2 />,
    <IconDashboardMostPopular3 />,
    <IconDashboardMostPopular4 />
  ];
  return (

    <>
      <Modal.Header className='ps-8' closeButton>
        <div className='w-100'>
          <Modal.Title className='ms-1'>Most Popular Content<span className="font-normal ps-2">({mostPopularContent?.length})</span></Modal.Title>
        </div>
      </Modal.Header>
      <Modal.Body className='px-8 pt-3 mt-3'>

        <Row>
          <Col lg={12} className="d-flex">
            <div className="page flex-grow-1">
              <div className="popularContantMod">


                <div className="HeadeingBar mb-0 px-20 py-3">
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex gap-3 conteHeadinghModal">


                      <p className="text-md mb-0 text-gray-300 font-bold">NAME</p>

                    </div>

                    <p className="text-md mb-0 text-gray-300 font-bold me-1">NUMBER OF VIEWS</p>
                    <p className="text-md mb-0 text-gray-300 font-bold me-5">AVERAGE DAYS TO COMPLETE</p>

                  </div>
                </div>
                <div className='bodyContent px-20'>
                  {mostPopularContent
                    ?.slice(0, 10)
                    .map((content, index) => {
                      const randomIcon = availableIcons[Math.floor(Math.random() * availableIcons.length)];
                      return (
                        <div className="py-2">
                          <div className="d-flex justify-content-between align-items-center py-1">
                            <div className="d-flex gap-3 conteHeadingModal">
                              {randomIcon}
                              <div className='w-100'>
                                <h6 className="font-medium">
                                  {content.title}
                                </h6>
                                <p className="text-gray-300 text-md mb-0">{content.type}</p>
                              </div>
                            </div>

                            <div className="mostPopularCount me-5">{content.noOfViews}</div>


                            {/* <p className="text-md mb-0">&lt; {content.avgDaysToComplete.toFixed(1)} days</p> */}
                            <p className="text-md mb-0 avgDays me-5">
                              {content.avgDaysToComplete.toFixed(1)} {content.avgDaysToComplete.toFixed(1) > 1 ? "days" : "day"}
                            </p>



                          </div>
                        </div>
                      );
                    })}
                  {mostPopularContent?.length === 0 && (
                    <EmptyState message={"No records found"} />
                  )}
                  {/* <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular2 />
                        <div>
                          <h6 className="font-medium">UI Design Tool</h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">34</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular3 />
                        <div>
                          <h6 className="font-medium">
                            Understanding React Native
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Module</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">87</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular4 />
                        <div>
                          <h6 className="font-medium">
                            Introduction to Jira 1
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">159</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular5 />
                        <div>
                          <h6 className="font-medium">
                            Introduction to Jira 1
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">159</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular6 />
                        <div>
                          <h6 className="font-medium">
                            Introduction to Jira 1
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">159</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular1 />
                        <div>
                          <h6 className="font-medium">
                            Introduction to Jira 1
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">159</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular2 />
                        <div>
                          <h6 className="font-medium">
                            Introduction to Jira 1
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">159</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular3 />
                        <div>
                          <h6 className="font-medium">
                            Introduction to Jira 1
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">159</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="d-flex justify-content-between align-items-center pb-3 ">
                      <div className="d-flex gap-3 conteHeading">
                        <IconDashboardMostPopular4 />
                        <div>
                          <h6 className="font-medium">
                            Introduction to Jira 1
                          </h6>
                          <p className="text-gray-300 text-md mb-0">Sequence</p>
                        </div>
                      </div>

                      <div className="mostPopularCount">159</div>
                      <p className="text-md mb-0">&lt; 15 days</p>
                    </div>
                  </div> */}
                </div>

              </div>
            </div>
          </Col>
        </Row>

      </Modal.Body>

    </>
  );
}

export default MostPopularContentModal;

