import React, { useState, useEffect, useRef } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import { Modal, Button } from "react-bootstrap";
import { Formik, Field, ErrorMessage, Form } from "formik";
import Select from "react-select";
import CheckBoxMd from "../../components/CheckBox/CheckBoxMd";
import cardType from "../../assets/images/master_card.png";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  getCardByCardIdAsync,
  updateCardAsync,
  addCardAsync,
} from "../../reducers/subscription-reducers";
import moment from "moment";
import TextInput from "../../components/FormikInputs/TextInput";
import { getUserProfileAsync } from "../../reducers/user-reducers";
import countryType from "../../assets/images/usa_flag.png";
import ToasterAlertSucces from "../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../helper/ErrorMessage";
import { Constants } from "../../services/Constants";
import Loader from "../../components/Loader/Loader";
import ConfirmEditCardModal from "../../pages/AccountSetting/Billing/ConfirmEditCardModal";
import { IconCalendar } from "../../icons/Icons";
import DateRangePicker from "../DateRangePicker/DateRangePicker";
const curYearMonthOpts = [];
const monthOpts = [];
const yearsOpts = [];
const noOfYear = 5;
const currentYear = parseInt(moment(new Date()).format("YYYY"));
const currentMonth = parseInt(moment(new Date()).format("MM"));
for (let i = currentMonth; i <= 12; i++) {
  curYearMonthOpts.push({ value: i, label: i.toString().padStart(2, "0") });
}
for (let i = 1; i <= 12; i++) {
  monthOpts.push({ value: i, label: i.toString().padStart(2, "0") });
}
for (let i = 0; i < noOfYear; i++) {
  yearsOpts.push({ value: currentYear + i, label: currentYear + i });
}

const EditPaymentModal = ({ handleModal, cardId, setCardId, editPayment }) => {
  const dispatch = useDispatch();
  const navigate = useHistory();
  const formRef = useRef();
  const [userProfile, setUserProfile] = useState(null);
  const entities = useSelector((state) => state.user.users);
  const cardData = useSelector((state) => state.subscription.cardData);
  const [expiryDate, setExpiryDate] = useState(new Date());
  const [redirectUrl, setRedirectUrl] = useState('');
  const [confirmPayment, setConfirmPayment] = useState(false);
  const [isLoader, setIsLoader] = useState(false);

  const stripe = useStripe();
  const elements = useElements();
  const [error, setError] = useState(null);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    if (cardId) {
      dispatch(getCardByCardIdAsync(cardId));
    }
  }, [cardId]);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    await dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
      }
    });
  };
  // const [startDate, setStartDate] = useState(new Date());

  const cardValidation = async (values) => {
    const cardElement = elements.getElement(CardElement);
    
    // Validate card details
    const { error: cardError } = await stripe.createToken(cardElement);

    // "incomplete_number"
    // "invalid_number"
    // "incomplete_expiry"
    // "invalid_expiry_year_past"
    // "incomplete_cvc"
    // "incomplete_cvc"

    let errors = {};

    // if (!values.cardHolderName || values.cardHolderName.trim() === "") {
    //   errors.cardHolderName = "Card holder name is required!";
    // }

    if (cardError?.message) {
      errors.cardElement = cardError?.message;
    }
    if (!values.cardHolderName || values.cardHolderName.trim() === "") {
      errors.cardHolderName = "Card holder name is required!";
    }
    //  else {
    //   errors = {};
    // }

    // if (!values.cardNumber || values.cardNumber.trim() === "") {
    //   errors.cardNumber = "Card number is required!";
    // }

    // if (!values.cvv || values.cvv.trim() === "") {
    //   errors.cvv = "CVV is required!";
    // } else if (values.cvv.trim().length > 3) {
    //   errors.cvv = "CVV must not be more than 3!";
    // }

    // if (!values.expiryYear || values.expiryYear.length === 0) {
    //   errors.expiryYear = "Expiry year is required!";
    // }

    // if (!values.expiryMonth || values.expiryMonth.length === 0) {
    //   errors.expiryMonth = "Expiry month is required!";
    // }
    return errors;
  };

  const cardElementOptions = {
    style: {
      base: {
        color: "#232323",
        "::placeholder": {
          color: "#888888",
          textTransform: 'capitalize',
        },
        borderColor: "#4a154b",
        borderRadius: '0.375rem',
        padding: '0.75rem 1rem',
        fontSize: '15px',
        border: '0.0625rem solid #D9D9D9',
        fontFamily: '"Inter",sans-serif'
        
      },
      invalid: {
        lineHeight: "normal",
        fontSize: '15px',
        marginTop: '0.25rem',
        color: "red",
        fontWeight: "normal",
        borderRadius: '0.375rem',
        border: '0.0625rem solid #D9D9D9',
      },
    },
    hidePostalCode: true, // Optional, hides the postal code field
    classes: {
      focus: 'focused', // Optional, CSS class to apply when the element is in focus
    }
  };

  const onhandleConfirmPlan = async () => {
    // onCloseModal();
    // alert(redirectUrl);
    window.location.href = redirectUrl;
  };

  const handleCardChange = (event) => {
    if (event.error) {
      setIsButtonDisabled(true);
    } else {
      setIsButtonDisabled(false);
    }
  };

  console.log(cardData, "cardDatasss");
  // console.log(cardId, "cardId");
  return (
    <>
      {isLoader && (
        <div className="loader-container">
          <Loader />
        </div>
      )}
      <Modal.Header closeButton>
        <Modal.Title>
          {/*{cardId ? "Edit Payment Method" : "Add Payment Method"}*/}

          Edit Payment Method
        </Modal.Title>
      </Modal.Header>
      <Formik
        enableReinitialize
        initialValues={{
          cardHolderName: "",
          // cardNumber: cardId ? cardData?.paymentSource?.card?.maskedNumber : "",
          // cvv: cardId ? "***" : "",
          // expiryYear: cardId ? cardData?.paymentSource?.card?.expiryYear : "",
          // expiryMonth: cardId ? cardData?.paymentSource?.card?.expiryMonth : "",
          // customerId: userProfile?.id || "",
          // setAsPreferredCard: false,
        }}
        validate={cardValidation}
        onSubmit={async (values) => {
          setIsLoader(true);
          const cardElement = elements.getElement(CardElement);
          // console.log(cardElement,'cardElement');
          // console.log(stripe,'stripe');
          // console.log(elements,'elements');
          // if (!stripe || !elements) {
          //   // Stripe.js has not yet loaded.
          //   // Make sure to disable form submission until Stripe.js has loaded.
          //   return;
          // }
          const { error, token } = await stripe.createToken(cardElement);
          // console.log(token,'tokentokentoken');
          if (cardId) {
            const request = {
              id: cardId || "",
              token: token?.id || "",
              // returnUrl: `${Constants?.clientRoot}/accountsetting/billingsubscription/updatecardsuccess`,
              returnUrl: `${Constants?.clientRoot}/accountsetting/billingsubscription`,
            };
            // console.log(request, "values");
            await dispatch(updateCardAsync(request)).then((res) => {
              console.log(res,'updateCardAsync');
              // setCardId("");
              localStorage.setItem(
                "updatePaymentCard",
                JSON.stringify({
                  state: {
                    paymentMethodId: res?.payload?.paymentMethodId,
                    priceId: "",
                    isNew: false,
                  },
                })
              );
              // ToasterAlertSucces(res, ErrrorMessage.UPDATE);
              setRedirectUrl(res?.payload?.result);
              setIsLoader(false);
              setConfirmPayment(true);
            });
          }
          // else {
          //   const request = {
          //     token: token?.id || "",
          //     customerId: userProfile?.id || "",
          //     amount: "1" || "",
          //     priceId: "",
          //     returnUrl: "",
          //   };
          //   await dispatch(addCardAsync(request)).then((res) => {
          //     ToasterAlertSucces(res, ErrrorMessage.CREATE);
          //   });
          // }
          // handleModal(!editPayment);
        }}
      >
        {({ errors, touched, setFieldValue, setFieldTouched, values }) => (
          <>
          {
            console.log(errors,'errorserrorserrorserrors')
          }
            <Form
              className="d-flex flex-column flex-grow-1 overfllow-auto h-100 editPaymentMethodModal"
              autoComplete="off"
              // onSubmit={handleSubmit}
            >
              <Modal.Body className="pt-0 pb-0">
                <div className="row mt-32">
                  <div className="col-12 col-md-6 col-lg-6">
                    <div className="mb-3 ps-lg-0" style={{maxWidth: "27.5rem"}}>
                      <CardElement options={cardElementOptions} onChange={handleCardChange} />
                      {errors?.cardElement !== '' && (
                        <label className="errorValidation mt-0" style={{ color: "red"}}> 
                          {/* "errorValidation" this is class in lable  */}
                          {errors?.cardElement?.replace(/\./g, '!')}
                        </label>
                      )}
                    </div>

                    <div className="input-group myInput mb-4 pe-lg-0">
                      <label className="">
                        Name on the card <span style={{ color: "red" }}>*</span>
                      </label>
                      <Field
                        name="cardHolderName"
                        placeholder="Name on the card"
                        aria-label="Name on the card"
                        type="text"
                        className="input"
                        onChange={(e) => {
                          setFieldValue("cardHolderName", e?.target?.value);
                        }}
                      />
                      {errors.cardHolderName && touched.cardHolderName ? (
                        <label className="errorValidation" style={{ color: "red"}}>
                          {errors.cardHolderName}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-start btnFooter">
                <Button variant="secondary" className="m-0" size="" type="submit" disabled={!stripe || isButtonDisabled === true || values?.cardHolderName === ""}>
                  {cardId ? "Save changes" : "Save"}
                  {/* This is a comment */}
                </Button>

                <Button
                  variant="white"
                  size=""
                  className="m-0 ms-3"
                  onClick={() => handleModal(false)}
                >
                  Cancel
                </Button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={confirmPayment}
        onHide={() => setConfirmPayment(false)}
      >
        <ConfirmEditCardModal
          handleModal={setConfirmPayment}
          confirm={onhandleConfirmPlan}
        />
      </Modal>
    </>
  );
};

export default EditPaymentModal;
