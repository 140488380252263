import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getSubScription, addSubScription, updateSubScription, downgradeSubscription, getPlan, getCustomerInvoices, getInvoicesPdf, getCardListByCustomerId, addCard, getCardByCardId, updateCard, deleteCard, deleteSubScription, saveReason, upgradeStorage, getCustomerDetails, updateCustomer, getStorage, cancelUnderReviewSubScription, payPendingInvoice, getProrationalInvoice, getDiscountOnCurrentPlan, downgradeStorage, downgradeSeats } from "../api/subscription-api";

const initialState = {
    isLoading: false,
    isUpdateLoading: false,
    isRenewalLoading: false,
    isDowngradeLoading: false,
    isgetSubscription: false,
    isError: false,
    subscriptionData: [],
    paymentData: [],
    plansData: [],
    cardListData: [],
    cardData:{},
    customerDetails: [],
    storageData:{},
    discountData:{},
  };

  export const getSubscriptionAsync = createAsyncThunk(
    "subscription/get",
    async () => {
      const response = await getSubScription();
      return response.data;
    }
  );

  export const getPlansAsync = createAsyncThunk(
    "Plan",
    async () => {
      const response = await getPlan();
      return response.data;
    }
  );
  
  export const getCustomerInvoicesAsync = createAsyncThunk(
    "GetCustomerInvoices",
    async () => {
      const response = await getCustomerInvoices();
      return response.data;
    }
  );
  
  export const getInvoicePdfAsync = createAsyncThunk(
    "invoicePdf",
    async (id) => {
      const response = await getInvoicesPdf(id);
      return response.data;
    }
  );
  
  export const addSubscriptionAsync = createAsyncThunk(
    "subscription/add",
    async (request) => {
      const response = await addSubScription(request);
      return response.data;
    }
  );

  export const saveReasonAsync = createAsyncThunk(
    "subscription/saveReason",
    async (request) => {
      const response = await saveReason(request);
      return response.data;
    }
  );

  export const upgradeStorageAsync = createAsyncThunk(
    "subscription/upgradeStorage",
    async (request) => {
      const response = await upgradeStorage(request);
      return response.data;
    }
  );

  export const downgradeStorageAsync = createAsyncThunk(
    "subscription/downgradeStorage",
    async (request) => {
      const response = await downgradeStorage(request);
      return response.data;
    }
  );

  export const downgradeSeatsAsync = createAsyncThunk(
    "subscription/downgradeSeats",
    async (request) => {
      const response = await downgradeSeats(request);
      return response.data;
    }
  );

  export const getProrationalInvoiceAsync = createAsyncThunk(
    "subscription/getProrationalInvoice",
    async (request) => {
      const response = await getProrationalInvoice(request);
      return response.data;
    }
  );
  
  export const updateSubscriptionAsync = createAsyncThunk(
    "subscription/update",
    async (request) => {
      const response = await updateSubScription(request);
      return response.data;
    }
  );

  export const payPendingInvoiceAsync = createAsyncThunk(
    "subscription/payPendingInvoice",
    async (request) => {
      const response = await payPendingInvoice(request);
      return response.data;
    }
  );

  export const downgradeSubscriptionAsync = createAsyncThunk(
    "subscription/downgrade",
    async (request) => {
      const response = await downgradeSubscription(request);
      return response.data;
    }
  );

  export const getCardListByCustomerIdAsync = createAsyncThunk(
    "card",
    async (reqBody) => {
      const response = await getCardListByCustomerId(reqBody);
      // console.log(response.data,'getCardListByCustomerIdAsync');
      return response.data;
    }
  );

  export const getCustomerDetailsAsync = createAsyncThunk(
    "getcustomerdetails",
    async (reqBody) => {
      const response = await getCustomerDetails(reqBody);
      return response.data;
    }
  );

  export const getStorageAsync = createAsyncThunk(
    "getstorage",
    async () => {
      const response = await getStorage();
      return response.data;
    }
  );

  export const getDiscountOnCurrentPlanAsync = createAsyncThunk(
    "getdiscountoncurrentplan",
    async () => {
      const response = await getDiscountOnCurrentPlan();
      return response.data;
    }
  );
  
  export const addCardAsync = createAsyncThunk(
    "cards",
    async (request) => {
      const response = await addCard(request);
      return response.data;
    }
  );

  export const updateCustomerAsync = createAsyncThunk(
    "updateCustomer",
    async (request) => {
      const response = await updateCustomer(request);
      return response.data;
    }
  );
  
  export const getCardByCardIdAsync = createAsyncThunk(
    "card",
    async (cardId) => {
      const response = await getCardByCardId(cardId);
      return response.data;
    }
  );
  
  
  export const updateCardAsync = createAsyncThunk(
    "card",
    async (request) => {
      const response = await updateCard(request);
      return response.data;
    }
  );
  
  
  export const deleteCardAsync = createAsyncThunk(
    "card/delete",
    async (id) => {
      const response = await deleteCard(id);
      return response.data;
    }
  );

  export const deleteSubScriptiondAsync = createAsyncThunk(
    "subscription/delete",
    async (id) => {
      const response = await deleteSubScription(id);
      return response.data;
    }
  );

  export const cancelUnderReviewSubScriptionAsync = createAsyncThunk(
    "subscription/delete",
    async (id) => {
      const response = await cancelUnderReviewSubScription(id);
      return response.data;
    }
  );

  const subscriptionSlice = createSlice({
    name: "subscription",
    initialState,
    reducers: {
      clearSubscriptionData(state) {
        state.subscriptionData = [];
      },
      resetIsgetSubscription(state) {
        state.isgetSubscription = false;
      }
    },
    extraReducers: {
        [getSubscriptionAsync.pending]: (state) => {
          console.log("pending");
        },
        [getSubscriptionAsync.fulfilled]: (state, { payload }) => {
          console.log("full filled", payload);
          return { ...state, subscriptionData: payload };
        },
        [getSubscriptionAsync.rejected]: (state) => {
          console.log("rejected sub");
        },

        [getPlansAsync.pending]: (state) => {
            console.log("pending");
          },
          [getPlansAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled Plan", payload);
              return { ...state, plansData: payload };
          },
          [getPlansAsync.rejected]: (state) => {
              console.log("rejected");
          },

          [updateSubscriptionAsync.pending]: (state) => {
            console.log("pending");
          },
          [updateSubscriptionAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled", payload);
              state.isUpdateLoading = false;
          },
          [updateSubscriptionAsync.rejected]: (state) => {
              state.isUpdateLoading = false;
              console.log("rejected update");
          },

          [payPendingInvoiceAsync.pending]: (state) => {
            console.log("pending");
          },
          [payPendingInvoiceAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled", payload);
              state.isRenewalLoading = false;
          },
          [payPendingInvoiceAsync.rejected]: (state) => {
              state.isRenewalLoading = false;
              console.log("rejected update");
          },
          

          [downgradeSubscriptionAsync.pending]: (state) => {
            console.log("pending");
          },
          [downgradeSubscriptionAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled", payload);
              state.isDowngradeLoading = false;
          },
          [downgradeSubscriptionAsync.rejected]: (state) => {
              state.isDowngradeLoading = false;
              console.log("rejected update");
          },

          [getCustomerInvoicesAsync.pending]: (state) => {
            console.log("pending");
          },
          [getCustomerInvoicesAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled", payload);
              return { ...state, paymentData: payload };
          },
          [getCustomerInvoicesAsync.rejected]: (state) => {
              console.log("rejected");
          },

          [getInvoicePdfAsync.pending]: (state) => {
            console.log("pending");
          },
          [getInvoicePdfAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled", payload);
              return { ...state, pdfUrl: payload?.downloadUrl };
          },
          [getInvoicePdfAsync.rejected]: (state) => {
              console.log("rejected");
          },

          [getCardListByCustomerIdAsync.pending]: (state) => {
            console.log("pending");
          },
          [getCardListByCustomerIdAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled card", payload);
              return { ...state, cardListData: payload };
          },
          [getCardListByCustomerIdAsync.rejected]: (state) => {
              console.log("rejected");
          },

          
          [getCustomerDetailsAsync.pending]: (state) => {
            console.log("pending");
          },
          [getCustomerDetailsAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled card", payload);
              return { ...state, customerDetails: payload };
          },
          [getCustomerDetailsAsync.rejected]: (state) => {
              console.log("rejected");
          },

          
          [getStorageAsync.pending]: (state) => {
            console.log("pending");
          },
          [getStorageAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled card", payload);
              return { ...state, storageData: payload };
          },
          [getStorageAsync.rejected]: (state) => {
              console.log("rejected");
          },

          [getDiscountOnCurrentPlanAsync.pending]: (state) => {
            console.log("pending");
          },
          [getDiscountOnCurrentPlanAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled card", payload);
              return { ...state, discountData: payload };
          },
          [getDiscountOnCurrentPlanAsync.rejected]: (state) => {
              console.log("rejected");
          },          

          [getCardByCardIdAsync.pending]: (state) => {
            console.log("pending");
          },
          [getCardByCardIdAsync.fulfilled]: (state, { payload }) => {
              console.log("full filled", payload);
              return { ...state, cardData: payload };
          },
          [getCardByCardIdAsync.rejected]: (state) => {
              console.log("rejected");
          },
    },
});

export const { clearSubscriptionData, resetIsgetSubscription } = subscriptionSlice.actions;
export default subscriptionSlice.reducer;