import React, { useState, useEffect } from "react";
import { Row, Col, Button } from "react-bootstrap";
import { IconPPT, IconDownload, IconReportIssue } from "../../../icons/Icons";
import { generatePresignedUrl, bulkGeneratePresignedUrl } from "../../../api/common-store-api";
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { getGUID } from "../../../common";

const ImagePreview = ({ actionReport, contentUrl, slideData, setContent }) => {
  const [contentUrlData, setContentUrlData] = useState(null);
  const location = useLocation()
  const userRole = useSelector((state) => state.user.currentUser?.roles[0].name);
  const arrayDownloadableFiles = slideData?.downloadableFile

  const getPreviewUrl = async () => {
    const getGuid = getGUID(contentUrl);
    let previewUrl = await generatePresignedUrl(getGuid);
    if (previewUrl?.data) {
      setContentUrlData(previewUrl?.data);
    }
  };

  const getBulkGeneratePresignedUrl = async () => {
    if (arrayDownloadableFiles !== undefined &&
      arrayDownloadableFiles !== null &&
      arrayDownloadableFiles.length > 0) {
      let responseURLsDownloadable = await bulkGeneratePresignedUrl(arrayDownloadableFiles.map((value) => value.filePath));
      console.log('responseURLsDownloadable 34 = ', responseURLsDownloadable)
      if (responseURLsDownloadable?.data) {
        // setDownloadableFileURLs(responseURLsDownloadable.data)
      }
    }
  };

  useEffect(() => {
    getPreviewUrl();
    getBulkGeneratePresignedUrl()
  }, []);

  return (
    <div className="pages">
      <div className="pageOuter pb-0">
        <Row className="g-0">
          <Col className="col-lg-12">
            <div className="videoPreview">
              <img src={contentUrlData} alt="content image" width="100%" />
              {/*<div className="mt-3">
                <i className="text-gray-200 h6">
                  Use arrow keys to move through the presentation
                </i>
              </div> */}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ImagePreview;
