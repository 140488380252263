import React from "react";
import EmptyState1 from "../../assets/images/EmptyState1.svg";
import EmptyState2 from "../../assets/images/EmptyState2.svg";
import EmptyState3 from "../../assets/images/EmptyState3.svg";
import emptyForm from "../../assets/images/emptyForm.svg";
const NoData = ({ msgicon, message }) => {
    return (
        <div className="EmptyDatadiv">
          <div className="mb-3">
            {msgicon === "status" && (
              <img src={EmptyState1} alt="EmptyState1" />
            )}
            {msgicon === "resume" && (
             <img src={EmptyState3} alt="EmptyState1" />
            )}
            {msgicon === "helpdesk" && (
              <img src={EmptyState2} alt="EmptyState1" />
            )}
            {msgicon === "formModal" && (
              <img src={emptyForm} alt="EmptyState1" />
            )}
            </div>
           
                <span className="h6 font-normal text-gray-300 p-0 m-0" dangerouslySetInnerHTML={{ __html: message }}>
                  {/* {message} */}
                </span> 
               
            </div>
        );
      };
      
      export default NoData;
