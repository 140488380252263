import React from 'react';
import { Modal, Button } from "react-bootstrap";
const DowngradeUnderProcessModal = ({ setDowngradeUnderProcess }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <h3 className='mt-3 pe-4'>You plan downgrade is already under process. You can not downgrade it now.!</h3>
                {/* <p className='text-gray-300 mb-0 mt-2 mb-5'>
                    Your discount coupon will be shared with you shortly !
                </p> */}
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button variant="primary" size="sm" className='border-0' onClick={() => setDowngradeUnderProcess(false)}>
                    Ok
                </Button>
            </Modal.Footer>
        </>
    );
}

export default DowngradeUnderProcessModal;
