import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import {
  IconCross,
  IconModule,
  IconHamberger,
  IconTopSearch,
  IconRestrict,
  IconPlus,
} from "../../../icons/Icons";
import ContentView from "../../../components/ContentView/ContentView";
import TextArea from "../../../components/FormikInputs/TaxtArea";
import TextInput from "../../../components/FormikInputs/TextInput";
import CheckBoxInput from "../../../components/FormikInputs/CheckBoxInput";
import OwnerImg from "../../../assets/images/Account.png";
import Select from "react-select";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { Link, useHistory, useLocation } from "react-router-dom";
import TagsInput from "../../../components/TagsInput/TagsInput";
import { optionsTime } from "../../../common";
import ContentDropdown from "../../../components/ContentDropdown/ContentDropdown";
import { Status } from "../../../common";
import Loader from "../../../components/Loader/Loader";
import {
  createSequenceAsync,
  updateSequenceAsync,
  getModuleDropdownListForSequenceAsync,
  createSequenceSaveAsDraftAsync,
} from "../../../reducers/sequence-reducers";
import {
  checkUniquenessAsync,
  checkUniquenessTempAsync,
} from "../../../reducers/module-reducers";
import { useDispatch, useSelector } from "react-redux";
import PageHeaderSection from "../../../components/PageHeader/PageHeaderSection";
import UserImageList from "../../../components/UserImageList/UserImageList";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import OwnerImgCount from "../OwnerImgCount";
import MultiSelectDropdown from "../../../components/MultiSelect/MultiSelect";
import ModalMessageAlertPopup from "../../../components/ModalPopup/ModalMessageAlertPopup";
import {
  getTeamsAsync,
  getUsersAsync,
  getRolesAsync,
  getUserUsageAsync,
} from "../../../reducers/user-reducers";
import * as moment from "moment";
import RestrictedPlan from "../../AccountSetting/Billing/RestrictedPlan";
import {
  getSubscriptionAsync,
  getPlansAsync,
} from "../../../reducers/subscription-reducers";
import RestrictedModalPopup from "../../../components/ModalPopup/RestrictedModalPopup";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const SequencesCreate = () => {
  const dragItem = useRef();
  const dragOverItem = useRef();
  const [formDataReady, setFormDataReady] = useState(false);
  const [content, addContentClick] = useState(false);
  const [dropdownList, addmoduleList] = useState([]);
  const dispatch = useDispatch();
  const formRef = useRef();
  const imageList = [{ OwnerImg: OwnerImg }, { OwnerImg: OwnerImg }];
  const [sequenceData, setSequenceData] = useState(null);
  const [isUniqueTitle, setIsUniqueTitle] = useState(true);
  const [titleFocused, setTitleFocused] = useState(false);
  const [isCheckFirstTitleDuplicate, setIsCheckFirstTitleDuplicate] =
    useState(true);
  const location = useLocation();
  const saveStatus = useSelector((state) => state.process.processesSave);
  const [optionsTeam, setOptionsTeam] = useState([]);
  const [restrictModal, setRestrictModal] = useState(false);
  const teamsList = useSelector((state) => state.user.teams);
  const [showAlertMessagePopup, setShowAlertMessagePopup] = useState(false);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const [roleId, setRoleId] = useState(null);
  const [pageSize, setPageSize] = useState(100000);
  const roleList = useSelector((state) => state.user.roles);
  const [optionsUser, setOptionsUser] = useState([]);
  const [hasUnpublishedContent, setHasUnpublishedContent] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [restrictedTeam, setRestrictedTeam] = useState([]);
  const [restrictedEmployee, setRestrictEmployee] = useState(false);
  const [recommendedTimeDurationType, setRecommendedTimeDurationType] =
    useState(optionsTime[0]);
  const [
    reminderRecommendedTimeDurationType,
    setReminderRecommendedTimeDurationType,
  ] = useState(optionsTime[0]);
  const [updatedList, setUpdatedList] = useState([]);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [expiredRequired, setExpiredRequired] = useState(false);

  const [isRestricted, setIsRestricted] = useState(false);
  const [restrictedPopUp, setRestrictedPopUp] = useState(false);
  const [userUsage, setUserUsage] = useState({});
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData } = useSelector((state) => state.subscription);
  const [flag, setFlag] = useState(false);
  const [isSubmittted, setIsSubmittted] = useState(false);
  const [loadTeamsList, setLoadTeamsList] = useState(true);

  useEffect(() => {
    if (restrictedEmployee === true) {
      // console.log(restrictedTeam,'restrictedTeamrestrictedTeamrestrictedTeam');
      // Get the last object in the array
      const lastObject =
        restrictedTeam && restrictedTeam[restrictedTeam?.length - 1];
      // console.log(lastObject,'lastObject');
      if (lastObject) {
        const selectedUser = optionsUser
          ? optionsUser.filter((user) => user.teamId === lastObject.value)
          : [];
        // console.log(selectedUser,'selectedUser');
        // console.log(selectedUsers,'selectedUsers');

        // Filter out objects from selectedUsers that already exist in selectedUser
        const filteredSelectedUsers = selectedUsers.filter(
          (user) => !selectedUser.some((u) => u.value === user.value)
        );

        // Append the filteredSelectedUsers to selectedUser
        setSelectedUsers([...selectedUser, ...filteredSelectedUsers]);
      }
    }
  }, [restrictedEmployee]);

  const getUsers = () => {
    roleList?.records?.map((m) => {
      if (m.name === "Admin") {
        setRoleId(m.id);
      }
    });

    let reqBody = {
      pageSize: pageSize,
      // filters: { roleIds: ["c6ec043a-50f9-4bd8-b360-2bfe5de477f7"] },
      filters: {},
    };
    dispatch(getUsersAsync(reqBody)).then((res) => {
      if (res.payload.users && res.payload.users.length > 0) {
        const users = res.payload.users.map((user) => ({
          value: user.id,
          label: user.name,
          teamId: user.teamId,
        }));
        console.log(users);
        setOptionsUser(users);
      }
    });
  };

  useEffect(() => {
    getUserUsage();
    if (roleList.length === 0) {
      dispatch(getRolesAsync({ name: "" }));
    }
    getUsers();
  }, [roleList, setRoleId, roleId]);

  // useEffect(() => {
  //   const teamsReqBody = {};
  //   if (teamsList.length === 0) {
  //     dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
  //       if (!!res?.payload && res?.payload?.length > 0) {
  //         const teams = res?.payload?.map((item) => {
  //           return { value: item.key, label: item.value };
  //         });
  //         setOptionsTeam(teams);
  //         //console.log(setOptionsTeam);
  //       }
  //     });
  //   } else {
  //     if (!!teamsList && teamsList?.length > 0) {
  //       const teams = teamsList?.map((item) => {
  //         return { value: item.key, label: item.value };
  //       });
  //       setOptionsTeam(teams);
  //       //console.log(setOptionsTeam);
  //     }
  //   }
  // }, []);

  const fetchData = async () => {
    const teamsReqBody = {};
    if (loadTeamsList && teamsList.length === 0) {
      setLoadTeamsList(false);
      try {
        const res = await dispatch(getTeamsAsync(teamsReqBody));
        if (res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload
            ?.filter((team) => {
              return optionsUser.some((user) => user.teamId === team.key);
            })
            .map((item) => ({ value: item.key, label: item.value }));
          setOptionsTeam(teams); // Filter out undefined values
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    } else {
      const teams = teamsList
        ?.filter((team) => {
          return optionsUser.some((user) => user.teamId === team.key);
        })
        .map((item) => ({ value: item.key, label: item.value }));
      setOptionsTeam(teams); // Filter out undefined values
    }
  };

  useEffect(() => {
    // console.log("teamsList:", teamsList);
    // console.log("optionsUser:", optionsUser);
    fetchData();
  }, [optionsUser, teamsList]);

  const prepareData = (data) => {
    var obj = {
      id: null,
      moduleId: data.id,
      title: data.title,
      type: data.type,
      status: data.status,
      statusEnum: data.statusEnum,
      expiryDate: data.expiryDate,
      index: sequenceData?.modules?.length ?? 0,
    };
    return obj;
  };
  // const addContentData = (
  //   data,
  //   setMethod,
  //   setTouchedMethod,
  //   formTouchedData
  // ) => {
  //   const currentFormData = formRef?.current;
  //   let latestList = [...(currentFormData?.values?.modules ?? [])];
  //   if (data) {
  //     if (sequenceData?.status === "Published" && data.status === "Draft" && operationType !== "Duplicate") {
  //       setShowAlertMessagePopup(true);
  //     } else {
  //     if (latestList) {
  //       latestList.push(prepareData(data));
  //     } else {
  //       latestList = [prepareData(data)];
  //     }
  //     if (setTouchedMethod) {
  //       setTouchedMethod({ ...formTouchedData, ["modules"]: true });
  //     }
  //     if (setMethod) {
  //       setMethod("modules", latestList);
  //     }
  //   }
  //   }
  //   if (!sequenceData) {
  //     setSequenceData({ ...sequenceData });
  //   }
  //   showContentDropdron(false);
  // };

  const addContentData = (
    data,
    setMethod,
    setTouchedMethod,
    formTouchedData,
    expiryDate
  ) => {
    const currentFormData = formRef?.current;
    let latestList = [...(currentFormData?.values?.modules ?? [])];

    if (data) {
      setUpdatedList((prevList) => [...prevList, data]);
      const dataWithExpiry = {
        ...data,
        expiryDate: data.expiryDate || expiryDate,
      };

      // Check if the added module's expiry date is earlier than the current date
      const moduleExpired =
        dataWithExpiry.expiryDate &&
        new Date(dataWithExpiry.expiryDate) < new Date();
      console.log(moduleExpired, "moduleExpired");
      if (
        sequenceData?.status === "Published" &&
        moduleExpired &&
        data.status === "Draft" &&
        operationType !== "Duplicate"
      ) {
        setShowAlertMessagePopup(true);
      } else {
        if (latestList) {
          latestList.push(prepareData(dataWithExpiry));
        } else {
          latestList = [prepareData(dataWithExpiry)];
        }

        if (setTouchedMethod) {
          setTouchedMethod({ ...formTouchedData, ["modules"]: true });
        }

        if (setMethod) {
          setMethod("modules", latestList);
        }
      }
    }

    if (!sequenceData) {
      setSequenceData({ ...sequenceData });
    }

    showContentDropdron(false);
  };

  useEffect(() => {
    console.log(updatedList, "updatedList");
    // Find the earliest expiry date from the modules
    const allExpiryDates = updatedList.flatMap(
      (module) => module.expiryDate || []
    );
    // console.log(allExpiryDates, "allExpiryDates");
    // Filter out null or undefined dates
    const validExpiryDates = allExpiryDates.filter((date) => date);

    setExpiredRequired(validExpiryDates.length > 0 ? true : false);
    // Find the earliest expiry date
    const earliestExpiryDate =
      validExpiryDates.length > 0
        ? new Date(Math.min(...validExpiryDates.map((date) => new Date(date))))
        : null;

    console.log(earliestExpiryDate, "earliestExpiryDate");

    // Update the minDate and maxDate state
    setMinDate((prevMinDate) =>
      earliestExpiryDate && earliestExpiryDate < prevMinDate
        ? earliestExpiryDate
        : prevMinDate
    );
    setMaxDate(earliestExpiryDate);
  }, [updatedList]);

  const showContentDropdron = (value) => {
    const existsData = formRef.current?.values?.modules ?? [];
    addmoduleList(
      dropdownList?.filter(
        (f) =>
          existsData && existsData.map((m) => m.moduleId).indexOf(f.id) == -1
      )
    );

    if (dropdownList.length > 0) {
      addContentClick(value);
    } else {
      addContentClick(false);
    }
  };

  const { operationType, redirectTo } = location.state;

  useEffect(() => {
    if (operationType === "Add") {
      getUserUsage();
      dispatch(getSubscriptionAsync());
      dispatch(getPlansAsync());
    }
  }, [operationType]);

  useEffect(() => {
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (operationType === "Add") {
      if (hasSubscriptions && hasItems) {
        const basePlan = subscriptionData?.subscriptionData?.plans.filter(
          (plan) => plan.statementDescriptor === "base_plan"
        );
        const planSequence =
          basePlan && basePlan?.length > 0
            ? parseInt(basePlan[0]?.metaData?.sequence)
            : 0;
        console.log(planSequence, "planSequence");
        console.log(userUsage, "userUsage");
        setIsRestricted(
          planSequence === -1
            ? false
            : userUsage?.sequenceCount >= planSequence
            ? true
            : false
        );
      } else {
        setIsRestricted(true);
      }
    }
  }, [subscriptionData, plansData, userUsage, operationType]);

  const getUserUsage = () => {
    dispatch(getUserUsageAsync()).then((res) => {
      if (res.payload) {
        setUserUsage(res.payload);
      }
    });
  };

  const [estimatedTimeDurationType, setEstimatedTimeDurationType] = useState(
    optionsTime[0]
  );

  const validateTempTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun,
    callback
  ) => {
    setIsCheckFirstTitleDuplicate(false);
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");
    if (title.trim() !== "") {
      setIsSubmittted(true);
      const jsonData =
        sequenceData && sequenceData["id"]
          ? {
              id: sequenceData["id"] ?? null,
              name: title,
              type: "Sequence",
            }
          : {
              name: title,
              type: "Sequence",
            };

      await dispatch(checkUniquenessTempAsync(jsonData)).then((res) => {
        setTitleFocused(false);
        setIsUniqueTitle(res?.payload ? true : false);
        setIsSubmittted(false);
        setIsCheckFirstTitleDuplicate(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["title"]: true });
          validateField("title");
        }, 1);

        if (res?.payload) {
          callback && callback();
        }
      });
    }
  };
  const debouncedValidateTempTitle = useDebounce(validateTempTitle, 1000);

  const validateTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun,
    callback
  ) => {
    setIsCheckFirstTitleDuplicate(false);
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");
    if (title.trim() !== "") {
      setIsSubmittted(true);
      const jsonData =
        sequenceData && sequenceData["id"]
          ? {
              id: sequenceData["id"] ?? null,
              name: title,
              type: "Sequence",
            }
          : {
              name: title,
              type: "Sequence",
            };

      await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
        setTitleFocused(false);
        setIsUniqueTitle(res?.payload ? true : false);
        setIsSubmittted(false);
        setIsCheckFirstTitleDuplicate(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["title"]: true });
          validateField("title");
        }, 1);

        if (res?.payload) {
          callback && callback();
        }
      });
    }
  };

  const debouncedValidateTitle = useDebounce(validateTitle, 1000);

  const navigate = useHistory();

  // const onSelectationChange = (e, colName, setMethod) => {
  //   setEstimatedTimeDurationType({
  //     value: e.value,
  //     label: e.label,
  //   });
  //   if (setMethod) {
  //     setMethod(colName, e.value);
  //   }
  // };
  const onSelectationChange = (e, colName, setMethod, localState) => {
    localState({
      value: e.value,
      label: e.label,
    });
    if (setMethod) {
      setMethod(colName, e.value);
    }
  };

  const onSequenceSubmit = (status) => {
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/sequences");
    }
    var errors = sequenceValidation(currentFormData.values);
    if (
      (Object.keys(currentFormData.errors).length === 0 &&
        Object.keys(errors).length === 0 &&
        status) ||
      status === 1
    ) {
      const modules = [];
      currentFormData?.values?.modules?.map((item) => {
        modules.push({
          id: item.id,
          moduleId: item.moduleId,
          title: item.title,
          type: item.type,
          status: item.statusEnum,
          statusEnum: item.statusEnum,
          index: item.index,
        });
      });

      if (
        currentFormData?.values?.id &&
        (operationType === undefined || operationType !== "Duplicate")
      ) {
        const reqBody = {
          // id: currentFormData?.values?.id,
          title: currentFormData?.values?.title,
          description: currentFormData?.values?.description,
          customTags: currentFormData?.values?.customTags,
          estimatedTime: parseInt(currentFormData?.values?.estimatedTime),
          estimatedTimeDurationType:
            currentFormData?.values?.estimatedTimeDurationType,
          isExpirable: currentFormData?.values?.isExpirable,
          expiryDate: currentFormData?.values?.isExpirable
            ? currentFormData?.values?.expiryDate
            : null,
          status: currentFormData?.values?.status,
          restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
          restrictedIds: currentFormData?.values?.restrictedIds || [],
          modules: modules,
          recommendedTime: currentFormData?.values?.recommendedTime
            ? currentFormData?.values?.recommendedTime
            : null,
          recommendedTimeDurationType:
            currentFormData?.values?.recommendedTimeDurationType,
          // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
          // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
          templateId: currentFormData?.values?.templateId,
        };
        if (operationType !== "Edit") {
          reqBody.id = currentFormData.values.id;
        }
        setFormDataReady(false);
        dispatch(
          status === 1
            ? createSequenceSaveAsDraftAsync(reqBody)
            : updateSequenceAsync(reqBody)
        ).then((res) => {
          // ToasterAlertSucces(res, ErrrorMessage.UPDATE);
          ToasterAlertSucces(
            res,
            status === 1
              ? "Success! Your sequence has been saved as a draft."
              : "Your sequence was updated successfully."
          );
          if (redirectTo) {
            navigate.push(redirectTo, { id: currentFormData?.values?.id });
          } else {
            navigate.push("/authoring/sequences");
          }
        });
      } else {
        const reqBody = {
          title: currentFormData?.values?.title,
          description: currentFormData?.values?.description,
          customTags: currentFormData?.values?.customTags,
          estimatedTime: parseInt(currentFormData?.values?.estimatedTime),
          estimatedTimeDurationType:
            currentFormData?.values?.estimatedTimeDurationType,
          isExpirable: currentFormData?.values?.isExpirable,
          expiryDate: currentFormData?.values?.isExpirable
            ? currentFormData?.values?.expiryDate
            : null,
          status: currentFormData?.values?.status,
          restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
          restrictedIds: currentFormData?.values?.restrictedIds || [],
          modules: modules,
          recommendedTime: currentFormData?.values?.recommendedTime
            ? currentFormData?.values?.recommendedTime
            : null,
          recommendedTimeDurationType:
            currentFormData?.values?.recommendedTimeDurationType,
          // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
          // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
        };

        dispatch(
          status === 1
            ? createSequenceSaveAsDraftAsync(reqBody)
            : createSequenceAsync(reqBody)
        ).then((res) => {
          // ToasterAlertSucces(res, ErrrorMessage.CREATE);
          // const message = status === 1 ? ErrrorMessage.DRAFT : ErrrorMessage.CREATE;
          const message =
            status === 1
              ? "Success! Your sequence has been saved as a draft."
              : operationType === "Duplicate"
              ? "Sequence duplicated successfully!"
              : "Success! Your sequence has been created and published.";
          ToasterAlertSucces(res, message);
          if (
            redirectTo &&
            (operationType === undefined || operationType !== "Duplicate")
          ) {
            navigate.push(redirectTo, {
              state: { id: currentFormData?.values?.id },
            });
          } else {
            navigate.push("/authoring/sequences");
          }
        });
      }
    }
  };

  const templatePublish = (status) => {
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    // currentFormData.values.status = status;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/modules");
    }
    var errors = sequenceValidation(currentFormData.values);

    const modules = [];
    currentFormData?.values?.modules?.map((item) => {
      modules.push({
        id: item.id,
        moduleId: item.moduleId,
        title: item.title,
        type: item.type,
        status: item.statusEnum,
        statusEnum: item.statusEnum,
        index: item.index,
      });
    });
    const reqBody = {
      title: currentFormData?.values?.title,
      description: currentFormData?.values?.description,
      customTags: currentFormData?.values?.customTags,
      estimatedTime: parseInt(currentFormData?.values?.estimatedTime),
      estimatedTimeDurationType:
        currentFormData?.values?.estimatedTimeDurationType,
      isExpirable: currentFormData?.values?.isExpirable,
      expiryDate: currentFormData?.values?.isExpirable
        ? currentFormData?.values?.expiryDate
        : null,
      status: 0,
      restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
      restrictedIds: currentFormData?.values?.restrictedIds || [],
      modules: modules,
      recommendedTime: currentFormData?.values?.recommendedTime
        ? currentFormData?.values?.recommendedTime
        : null,
      recommendedTimeDurationType:
        currentFormData?.values?.recommendedTimeDurationType,
      // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
      // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
      templateId: currentFormData?.values?.id,
    };
    dispatch(createSequenceAsync(reqBody)).then((res) => {
      // const message = ErrrorMessage.CREATE;
      // const message = "Success! Your sequence has been saved as a template.";
      const message = "Your Sequence has been created Successfully.";
      ToasterAlertSucces(res, message);
      if (
        redirectTo &&
        (operationType === undefined || operationType !== "Duplicate")
      ) {
        navigate.push(redirectTo, {
          state: { id: currentFormData?.values?.id },
        });
      } else {
        navigate.push("/authoring/sequences");
      }
    });
  };

  const templateSave = (status) => {
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    // currentFormData.values.status = status;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/modules");
    }
    var errors = sequenceValidation(currentFormData.values);

    const modules = [];
    currentFormData?.values?.modules?.map((item) => {
      modules.push({
        id: item.id,
        moduleId: item.moduleId,
        title: item.title,
        type: item.type,
        status: item.statusEnum,
        statusEnum: item.statusEnum,
        index: item.index,
      });
    });
    const reqBody = {
      title: currentFormData?.values?.title,
      description: currentFormData?.values?.description,
      customTags: currentFormData?.values?.customTags,
      estimatedTime: parseInt(currentFormData?.values?.estimatedTime),
      estimatedTimeDurationType:
        currentFormData?.values?.estimatedTimeDurationType,
      isExpirable: currentFormData?.values?.isExpirable,
      expiryDate: currentFormData?.values?.isExpirable
        ? currentFormData?.values?.expiryDate
        : null,
      status: 7,
      restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
      restrictedIds: currentFormData?.values?.restrictedIds || [],
      modules: modules,
      recommendedTime: currentFormData?.values?.recommendedTime
        ? currentFormData?.values?.recommendedTime
        : null,
      recommendedTimeDurationType:
        currentFormData?.values?.recommendedTimeDurationType,
      // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
      // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
    };
    dispatch(createSequenceAsync(reqBody)).then((res) => {
      // const message = ErrrorMessage.CREATE;
      // const message = "Success! Your sequence has been saved as a template.";
      const message = "Your Sequence has been created Successfully.";
      ToasterAlertSucces(res, message);

      navigate.push("/authoring/sequences");
    });
  };

  const onTagsUpdate = (
    updatedList,
    setFieldValue,
    setTouchedMethod,
    formTouchedData
  ) => {
    let stringdata = updatedList
      .map((m) => {
        return m.tag;
      })
      .toString();
    if (setTouchedMethod) {
      setTouchedMethod({ ...formTouchedData, ["customTags"]: true });
    }
    setFieldValue("customTags", stringdata);
  };

  const onRemoveContentClick = (id, setMethod, touchedMethod, touchedData) => {
    const currentFormData = formRef?.current;
    const newData = currentFormData.values?.modules.filter(
      (f) => f.moduleId !== id
    );

    if (touchedMethod) {
      touchedMethod({ ...touchedData, modules: true });
    }
    // console.log(id, 'id');
    // console.log(prevList, 'prevList');
    // console.log(newData, 'newData');
    setUpdatedList((prevList) =>
      prevList.filter((item) => item.moduleId !== id)
    );
    setMethod("modules", newData);
    showContentDropdron(false);
    SetDropDownList();
  };
  const SetDropDownList = async () => {
    let response = await dispatch(getModuleDropdownListForSequenceAsync());
    addmoduleList(response?.payload ?? []);
  };
  useEffect(() => {
    if (operationType == "Duplicate") {
      setIsUniqueTitle(false);
    }

    const state = location.state;
    setFormDataReady(true);
    if (state !== undefined && state.propsData !== undefined) {
      let propdata = { ...state.propsData };
      if (operationType === "Duplicate") {
        propdata.id = undefined;
      }

      const recommendedTimeDurationType = optionsTime.find(
        (f) => f.value === propdata.recommendedTimeDurationType
      );

      if (recommendedTimeDurationType) {
        setRecommendedTimeDurationType({
          value: recommendedTimeDurationType.value,
          label: recommendedTimeDurationType.label,
        });
      }

      // const reminderRecommendedTimeDurationType = optionsTime.find(
      //   (f) => f.value === propdata.reminderRecommendedTimeDurationType
      // );

      // if (reminderRecommendedTimeDurationType) {
      //   setReminderRecommendedTimeDurationType({
      //     value: reminderRecommendedTimeDurationType?.value,
      //     label: reminderRecommendedTimeDurationType?.label,
      //   });
      // }

      setSequenceData(propdata);
      const estimatedTimeDurationType = optionsTime.filter(
        (f) => f.value == propdata.estimatedTimeDurationType
      );
      setEstimatedTimeDurationType({
        value: estimatedTimeDurationType[0].value,
        label: estimatedTimeDurationType[0].label,
      });

      if (state?.propsData?.restrictedTeamIds) {
        const restrictedTeamIds = state?.propsData?.restrictedTeamIds.map(
          (value) => ({ value: value.id, label: value.name })
        );
        setSelectedTeam(restrictedTeamIds);
      }
      if (state?.propsData?.restrictedIds) {
        const restrictedIds = state?.propsData?.restrictedIds.map((value) => ({
          value: value.employeeId,
          label: value.employeeName,
          teamId: value.teamId,
        }));
        setSelectedUsers(restrictedIds);
        if (restrictedIds) {
          setShowMultiSelect(true);
        }
      }
      // console.log(location,'location');
    } else {
      setEstimatedTimeDurationType({
        value: optionsTime[0].value,
        label: optionsTime[0].label,
      });
    }
    SetDropDownList();
  }, [location]);

  const onTitleChanges = async (value, colName) => {
    const jsonData = {
      id: sequenceData["id"] ?? null,
      name: value,
      type: "Sequence",
    };
    let result = await dispatch(checkUniquenessAsync(jsonData));
  };

  const onSelectationChanges = (
    selectedValues,
    colName,
    setMethod,
    localState,
    prevSelectedValues // Add the previous selected values as a parameter
  ) => {
    // setRestrictModal(true);
    setRestrictEmployee(false);
    const selectedLocalTeamIds = selectedValues.map((value) => ({
      value: value.value,
      label: value.label,
    }));
    const selectedMethodTeamIds = selectedValues.map((value) => value.value);

    // console.log(selectedValues,'selectedValues');
    // console.log(selectedLocalTeamIds,'selectedLocalTeamIds');
    // console.log(selectedMethodTeamIds,'selectedMethodTeamIds');

    if (selectedMethodTeamIds.length > 0) {
      localState(selectedLocalTeamIds);
      setMethod(colName, selectedMethodTeamIds);

      // Check for removed items
      // console.log(selectedValues, 'selectedValues');
      // console.log(prevSelectedValues, 'prevSelectedValues');
      const removedItems = prevSelectedValues.filter(
        (prevItem) =>
          !selectedValues.some((newItem) => prevItem.value === newItem.value)
      );
      // console.log(removedItems, 'removedItems');
      if (removedItems.length > 0) {
        // Extract the teamId values from removedItem
        const removedTeamIds = removedItems.map((item) => item.value);

        // Remove objects from selectedUser where teamId matches removedTeamIds
        const updatedSelectedUser = selectedUsers.filter(
          (user) => !removedTeamIds.includes(user.teamId)
        );

        // Append the updatedSelectedUser to the state
        setSelectedUsers(updatedSelectedUser);
        setMethod(
          "restrictedIds",
          updatedSelectedUser.map((user) => user.value)
        );
        setRestrictModal(false); // Example: Close or hide the modal when an item is removed
      } else {
        setRestrictedTeam(selectedLocalTeamIds);
        setRestrictModal(true);
      }
    } else {
      // localState(selectedLocalTeamIds);
      setSelectedUsers([]);
      setSelectedTeam([]);
      setMethod(colName, []);
      setMethod("restrictedIds", []);
      setShowMultiSelect(false);
      setRestrictModal(false);
    }
  };

  const onSelectationUserChanges = (
    selectedValues,
    colName,
    setMethod,
    localState
  ) => {
    // console.log(selectedValues,'selectedValues');
    // setSelectedUsers(selectedValues);
    if (setMethod) {
      setMethod(
        colName,
        selectedValues.map((user) => user.value)
      );
    }
    if (localState) {
      localState(
        selectedValues.map((user) => ({
          value: user.value,
          label: user.label,
          teamId: user.teamId,
        }))
      );
    }
  };
  console.log(sequenceData, "sequenceData?.modules");
  const getSelectedContentViewList = (
    values,
    setFieldValue,
    touchMethod,
    tuchedData
  ) => {
    console.log(values,'valuesvaluesvalues');
    console.log(content,'contentcontentcontent');
    if (values?.modules === undefined && !Array.isArray(values.modules)) {
      return null; // or handle this case in some other way
    }
    const hasUnpublishedContent = values?.modules !== null && values.modules.some(
      (m) => m.status === "Draft"
    );
    setHasUnpublishedContent(hasUnpublishedContent);

    const dragStart = (e, position) => {
      dragItem.current = position;
    };

    const dragEnter = (e, position) => {
      dragOverItem.current = position;
    };

    const drop = (list) => {
      const copyListItems = [...list];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setFieldValue(
        "modules",
        copyListItems.map((item, index) => ({
          id: item.id,
          moduleId: item.moduleId,
          title: item.title,
          type: item.type,
          status: item.status,
          index: index,
          statusEnum: item.statusEnum,
        }))
      );
    };
    return (
      <>
        {content === false ? (
          <>
            <p>
              Content<span style={{ color: "red" }}> * </span>
            </p>
            <a
              className="text-primary-200 font-bold h6 mb-3 d-inline-block"
              onClick={() => showContentDropdron(true)}
            >
              <IconPlus /> Add content<span style={{ color: "red" }}> * </span>
            </a>
          </>
        ) : (
          ""
        )}
        {values.modules &&
          values?.modules.map((m, index) => {
            setUpdatedList(values?.modules);
            return (
              <ContentView
                key={m.moduleId + "_ContentView"}
                index={index}
                id={m.moduleId}
                type={m.type}
                statusEnum={m.statusEnum}
                status={m.status}
                title={m.title}
                setFieldValue={setFieldValue}
                onRemove={onRemoveContentClick}
                touchedMethod={touchMethod}
                touchedData={tuchedData}
                modules={values.modules}
                list={values?.modules}
                setList={setFieldValue}
                drop={drop}
                dragStart={dragStart}
                dragEnter={dragEnter}
              />
            );
          })}
        {hasUnpublishedContent && (
          <p style={{ color: "red" }}>
            Your Sequence OR Process cannot be published or saved as a template
            as the added content is in the draft state.
          </p>
        )}
      </>
    );
  };
  const getHeaderTitle = (id, opertionType) => {
    return id !== undefined && opertionType !== "Duplicate"
      ? "Edit Sequence"
      : "Create Sequence";
  };

  const getSubmitTitle = (id, opertionType) => {
    if (sequenceData?.status === "Draft") {
      return "Publish";
    } else if (sequenceData?.status === "Template") {
      return "Update and Publish";
    } else {
      return id !== undefined && id !== "" && opertionType !== "Duplicate"
        ? "Update"
        : "Publish";
    }
  };

  // const getTemplateTitle = (id, opType) => {
  //   return id !== undefined && id !== ""
  //     ? "Update the template"
  //     : "Save as template";
  // };
  const getTemplateTitle = (id, opType) => {
    if (sequenceData?.status === "Template") {
      return id !== undefined && id !== "" ? "Update the template" : "";
    } else {
      return id !== undefined && id !== ""
        ? "Save as template"
        : "Save as template";
    }
  };

  const getHeaderTitleUrl = (redirectToUrl) => {
    console.log(operationType,'operationType');
    console.log(redirectToUrl,'redirectToUrl');
    console.log(sequenceData,'sequenceDatasequenceData');
    const state = location.state;
    let propdata = { ...state?.propsData }
    return operationType === "Duplicate" 
      ? {
        pathname: redirectToUrl,
        state: { id: propdata?.id },
       }
      : redirectToUrl !== undefined
      ? {
          pathname: redirectToUrl,
          state: { id: sequenceData?.id },
        }
      : sequenceData?.status === "Template"
      ? {
          pathname: "/authoring/sequences",
          state: { backType: "Template" },
        }
      : "/authoring/sequences";
  };

  const handleActionClick = async (
    actionType,
    validateField,
    touched,
    setTouched,
    handleSubmit,
    setFieldValue,
    values
  ) => {
    console.log(actionType, "actionType");
    switch (actionType) {
      case "save_as_template":
        if (sequenceData?.id && sequenceData?.status !== "Template") {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templateSave(7);
              // handleSubmit();
            }
          );
          return;
        }
        break;
      case "update":
        // if (operationType === "Duplicate") {
        //   let validation = await validateTempTitle(
        //     values?.title,
        //     validateField,
        //     touched,
        //     setTouched,
        //     () => {
        //       templateSave(7);
        //       // handleSubmit();
        //     }
        //   );
        //   return;
        // }
        if (sequenceData?.status === "Template") {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              setFieldValue("status", 7);
              handleSubmit();
            }
          );
          return;
        } else {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templateSave(7);
              // handleSubmit();
            }
          );
          return;
        }
        break;
      case "update_publish": // case action duplicate, create, draft, edit, 
        if ((operationType === "Duplicate" || sequenceData?.status === "Draft") && getRestrictedPopUp() === true) {
          setRestrictedPopUp(true);
          return;
        } else if (sequenceData?.status !== "Template") {
          let validation = await debouncedValidateTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              setFieldValue("status", 0);
              handleSubmit();
            }
          );
          return;
        }
        break;
      case "publish": // case template publish
        if (getRestrictedPopUp() === true) {
          // console.log("Test Publish");
          setRestrictedPopUp(true);
          return;
        } else if (sequenceData?.status === "Template") {
          let validation = await debouncedValidateTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templatePublish(0);
              handleSubmit();
            }
          );
          return;
        }
        break;
    }
  };

  const sequenceValidation = (values) => {
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    }
    if (!values.customTags || values.customTags.trim() === "") {
      errors.customTags = "Please add tags for your sequence.";
    }

    if (!values.description || values.description.trim() === "") {
      errors.description = "Description is required!";
    } else {
      // Remove multiple consecutive white spaces and line breaks, and count a single space as a character
      const cleanedDescription = values.description.replace(/[\s\n]+/g, " ");

      if (cleanedDescription.length < 116) {
        errors.description =
          "The minimum requirement for the description should be 116 characters.";
      }
    }

    if (!values.modules || values.modules.length === 0) {
      errors.modules =
        "The content field cannot be empty. Please add content for your sequence.";
    }
    // if (!values.estimatedTime && values.estimatedTime !== 0) {
    //   errors.estimatedTime = "Estimated Time is required!";
    // } else if (values.estimatedTime <= 0) {
    //   errors.estimatedTime = "Estimated time should be grater then 0";
    // }
    if (
      expiredRequired === true &&
      !values.expiryDate &&
      values.isExpirable === true
    ) {
      errors.expiryDate = "Date is required and should be in future!";
    } else if (values.isExpirable === true && values.expiryDate < new Date()) {
      errors.expiryDate = "Date is required and should be in future!";
    }
    if (expiredRequired === true && !values.expiryDate) {
      errors.expiryDate = "Expiry date is required!";
    } else if (values.expiryDate && new Date(values.expiryDate) < new Date()) {
      errors.expiryDate = "Expiry date should be in the future!";
    }

    // if (!values.reminderRecommendedTime && values.reminderRecommendedTime !== 0) {
    //   errors.reminderRecommendedTime = "Reminder time is required!";
    // } else if (values.reminderRecommendedTime <= 0) {
    //   errors.reminderRecommendedTime = "Reminder time should be grater then 0";
    // }

    if (!values.recommendedTime && values.recommendedTime !== 0) {
      errors.recommendedTime = "Recommended time is required!";
    } else if (values.recommendedTime <= 0) {
      errors.recommendedTime = "Recommended time should be greater then 0";
    }
    return errors;
  };

  const sequenceDraftValidation = (values) => {
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    }

    return errors;
  };

  const onCancelClick = () => {
    const state = location.state;
    let propdata = { ...state.propsData };
    console.log(propdata, "propdata");
    if (
      state?.operationType !== undefined &&
      sequenceData?.id &&
      state?.operationType === "Duplicate"
    ) {
      navigate.push("/authoring/sequences/details", { id: propdata.id });
    } else if (
      state?.opertionType !== undefined &&
      state?.opertionType === "Edit" &&
      propdata.id
    ) {
      navigate.push("/authoring/sequences/details", { id: propdata.id });
    } else if (
      state?.operationType !== undefined &&
      state?.operationType === "Add" &&
      state?.redirectTo
    ) {
      navigate.push(redirectTo);
    } else if (
      state?.operationType !== undefined &&
      state?.operationType === "Add" &&
      !state?.redirectTo
    ) {
      navigate.push("/authoring/sequences");
    } else {
      navigate.push("/authoring/sequences");
    }
  };

  const getRestrictedPopUp = () => {
    console.log(operationType, "operationTypeoperationType");
    console.log(userUsage, "userUsage");
    
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );

      console.log(basePlan, "basePlanbasePlan");
      const planModules =
        basePlan && basePlan?.length > 0
          ? parseInt(basePlan[0]?.metaData?.sequence)
          : 0;
      return planModules === -1 ? false : userUsage?.sequenceCount >= planModules ? true : false;
    } else {
      return true;
    }
  };


  return (
    <div className="pages">
      <PageHeaderSection
        redirectTo={getHeaderTitleUrl(redirectTo)}
        title={getHeaderTitle(sequenceData?.id, operationType)}
      ></PageHeaderSection>

      <div className="pageOuter">
        <Container fluid>
          {!isRestricted && (
            <div className="page createModulePage d-flex h-100">
              <div className="w-100 d-flex flex-column">
                {isSubmittted === true && <Loader />}
                {formDataReady && (
                  <Formik
                    initialValues={{
                      id: sequenceData?.id ?? undefined,
                      title: sequenceData?.title,
                      description: sequenceData?.description,
                      customTags: sequenceData?.customTags,
                      estimatedTime: sequenceData?.estimatedTime,
                      modules: sequenceData?.modules,
                      isExpirable: sequenceData?.isExpirable ?? false,
                      expiryDate: sequenceData?.isExpirable
                        ? new Date(sequenceData?.expiryDate)
                        : null,
                      status: undefined,
                      recommendedTime: sequenceData?.recommendedTime ?? 1,
                      recommendedTimeDurationType:
                        sequenceData?.recommendedTimeDurationType ?? 0,
                      // reminderRecommendedTime: sequenceData?.reminderRecommendedTime ?? 1,
                      // reminderRecommendedTimeDurationType: sequenceData?.reminderRecommendedTimeDurationType ?? 1,
                    }}
                    onSubmit={onSequenceSubmit}
                    validate={sequenceValidation}
                    innerRef={formRef}
                  >
                    {({
                      errors,
                      touched,
                      isValidating,
                      values,
                      setFieldValue,
                      validateField,
                      setTouched,
                      handleSubmit,
                    }) => {
                      return (
                        <Form style={{ display: "contents" }}>
                          <div className="flex-grow-1 overflow-auto">
                            <Row className="g-0 d-flex h-100">
                              <Col className="col-lg-8 flex-grow-1 overflow-auto h-100">
                                <div className="p-32 pb-4">
                                  <div className="input-group myInput mb-32">
                                    <TextInput
                                      name="title"
                                      label="Title"
                                      className="form-control"
                                      placeholder="E.g HR Operations"
                                      isRequired={true}
                                      // onFocus={() => {
                                      //   setTitleFocused(true);
                                      // }}
                                      // onBlur={(event) => {
                                      //   validateTitle(
                                      //     event?.target?.value,
                                      //     validateField,
                                      //     touched,
                                      //     setTouched
                                      //   );
                                      // }}
                                      onInput={(event) => {
                                        debouncedValidateTitle(
                                          event?.target?.value,
                                          validateField,
                                          touched,
                                          setTouched
                                        );
                                      }}
                                      maxLength={40}
                                    />
                                    {operationType === "Duplicate" &&
                                      isCheckFirstTitleDuplicate === true &&
                                      errors.title && (
                                        <label
                                          className="errorValidation"
                                          style={{ color: "red" }}
                                        >
                                          {errors.title}
                                        </label>
                                      )}
                                  </div>
                                  <div className="input-group myInput mb-32 labelFor">
                                    <TextArea
                                      label="Description"
                                      name="description"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Add text..."
                                      isRequired={true}
                                      maxLength={250}
                                    />
                                  </div>
                                  <div className="mb-32">
                                    <TagsInput
                                      name="customTags"
                                      inputTags={sequenceData?.customTags ?? ""}
                                      onChange={onTagsUpdate}
                                      setMethod={setFieldValue}
                                      touchedMethod={setTouched}
                                      touchedData={touched}
                                    />
                                    {touched.customTags &&
                                      errors.customTags && (
                                        <label
                                          className="errorValidation"
                                          style={{ color: "red" }}
                                        >
                                          {errors.customTags}
                                        </label>
                                      )}
                                  </div>
                                  {content && (
                                    <ContentDropdown
                                      list={dropdownList ?? []}
                                      onAdd={addContentData}
                                      setMethod={setFieldValue}
                                      selectedData={
                                        sequenceData?.modules?.map((m) => {
                                          return {
                                            id: m.moduleId,
                                            type: m.type,
                                            status: m.status,
                                            statusEnum: m.statusEnum,
                                            title: m.Title,
                                          };
                                        }) ?? []
                                      }
                                      touchedMethod={setTouched}
                                      touchedData={touched}
                                      getSelectedContentViewList={getSelectedContentViewList(
                                        values,
                                        setFieldValue,
                                        setTouched,
                                        touched
                                      )}
                                    />
                                  )}

                                  {!content &&
                                    getSelectedContentViewList(
                                      values,
                                      setFieldValue,
                                      setTouched,
                                      touched
                                    )}

                                  {dropdownList !== undefined &&
                                    dropdownList?.length !==
                                      sequenceData?.content?.length &&
                                    !content && (
                                      <>
                                        {!flag &&
                                          operationType !== "Duplicate" &&
                                          getHeaderTitle(
                                            sequenceData?.id,
                                            operationType
                                          ) !== "Edit Sequence" && (
                                            <a
                                              className="text-primary-200 font-bold h6"
                                              onClick={() => {
                                                showContentDropdron(true);
                                                setFlag(true);
                                              }}
                                            >
                                              <IconPlus /> Add content
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                *{" "}
                                              </span>
                                            </a>
                                          )}
                                        <br />
                                        {touched.modules && errors.modules && (
                                          <label
                                            className="errorValidation"
                                            style={{ color: "red" }}
                                          >
                                            {errors.modules}
                                          </label>
                                        )}
                                      </>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-lg-4 bg-gray-600 flex-grow-1 overflow-auto h-100">
                                <div className="bg-gray-600 p-32 px-4 h-100">
                                  <div className="mb-32">
                                    <OwnerImgCount title="Sequence Owners" />
                                  </div>
                                  {/* <div className="mb-3">
                                    <div className="row">
                                      <div className="col-12">
                                        <p className="h6 font-medium mb-12">
                                          Estimate time
                                          <span className="mandatoryFields" style={{ color: "red" }}>*</span>
                                        </p>
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="input-group myInput estmateTimein">
                                          <TextInput
                                            type="number"
                                            name="estimatedTime"
                                            className="form-control"
                                            min={0}
                                            step={1}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group mySelect estmateTimein">
                                          <Select
                                            isSearchable={false}
                                            name="estimatedTimeDurationType"
                                            className="select"
                                            options={optionsTime}
                                            defaultValue={
                                              estimatedTimeDurationType
                                            }
                                            value={estimatedTimeDurationType}
                                            onChange={(e) =>
                                              onSelectationChange(
                                                e,
                                                "estimatedTimeDurationType",
                                                setFieldValue
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}

                                  {/* <div className="mb-3">
                                      <p className="h6 font-medium mb-12">
                                        Reminder time to alert
                                        <span
                                          className="mandatoryFields"
                                          style={{ color: "red" }}
                                        >
                                          *
                                        </span>
                                      </p>
                                      <div className="row">
                                        <div className="col-6">
                                          <div className="input-group myInput">
                                            <TextInput
                                              type="number"
                                              name="reminderRecommendedTime"
                                              className="form-control"
                                              min={0}
                                              step={1}
                                            />
                                          </div>
                                        </div>
                                        <div className="col-6">
                                          <div className="form-group mySelect">
                                            <Select
                                              isSearchable={false}
                                              name="reminderRecommendedTimeDurationType"
                                              className="select"
                                              classNamePrefix="selectCustom"
                                              options={optionsTime}
                                              defaultValue={
                                                reminderRecommendedTimeDurationType
                                              }
                                              value={reminderRecommendedTimeDurationType ? reminderRecommendedTimeDurationType : null}
                                              onChange={(e) =>
                                                onSelectationChange(
                                                  e,
                                                  "reminderRecommendedTimeDurationType",
                                                  setFieldValue,
                                                  setReminderRecommendedTimeDurationType
                                                )
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div> 
                                  </div> */}

                                  <div className="mb-3">
                                    <p className="h6 font-medium mb-12">
                                      Recommended time to complete
                                      <span
                                        className="mandatoryFields"
                                        style={{ color: "red" }}
                                      >
                                        *
                                      </span>
                                    </p>
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="input-group myInput">
                                          <TextInput
                                            type="number"
                                            name="recommendedTime"
                                            className="form-control"
                                            min={1}
                                            step={1}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group mySelect">
                                          <Select
                                            isSearchable={false}
                                            name="recommendedTimeDurationType"
                                            className="select"
                                            classNamePrefix="selectCustom"
                                            options={optionsTime}
                                            defaultValue={
                                              recommendedTimeDurationType
                                            }
                                            value={
                                              recommendedTimeDurationType
                                                ? recommendedTimeDurationType
                                                : null
                                            }
                                            onChange={(e) =>
                                              onSelectationChange(
                                                e,
                                                "recommendedTimeDurationType",
                                                setFieldValue,
                                                setRecommendedTimeDurationType
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-32">
                                    <p className="h6 font-medium mb-20">
                                      Other Settings
                                    </p>
                                    <p className="h6 text-gray-200 mb-20 d-flex align-items-centre">
                                      <CheckBoxInput
                                        name="isExpirable"
                                        label="Set Expiry date"
                                        value={values?.isExpirable ?? false}
                                        onChange={(isChecked) => {
                                          setFieldValue(
                                            "isExpirable",
                                            isChecked?.target?.checked
                                          );
                                          if (!isChecked?.target?.checked) {
                                            setFieldValue("expiryDate", null);
                                          }
                                        }}
                                      />
                                      {expiredRequired === true && (
                                        <span className="ms-1 text-secondary-600">
                                          {" "}
                                          *{" "}
                                        </span>
                                      )}
                                    </p>
                                    {values.isExpirable == true && (
                                      <div className="input-group myInput mb-20 ms-2 ps-4 yAxisCalendar dateCalendar iconbig dateCalendarbgico">
                                        {/* <IconCalendar /> */}
                                        <DatePicker
                                          selected={
                                            values?.expiryDate
                                              ? new Date(values?.expiryDate)
                                              : null
                                          }
                                          className="form-control"
                                          onChange={(date) =>
                                            setFieldValue(
                                              "expiryDate",
                                              date
                                                ? moment(new Date(date)).format(
                                                    "YYYY-MM-DDThh:mm:ssZ"
                                                  )
                                                : ""
                                            )
                                          }
                                          name="expiryDate"
                                          // minDate={new Date()}
                                          minDate={minDate}
                                          maxDate={maxDate}
                                          autoComplete="off"
                                        />
                                        {errors.expiryDate && (
                                          <label
                                            className="errorValidation"
                                            style={{ color: "red" }}
                                          >
                                            {errors.expiryDate}
                                          </label>
                                        )}
                                      </div>
                                    )}
                                    {/* {getHeaderTitle(
                                      sequenceData?.id,
                                      operationType
                                    ) !== "Edit Sequence" && ( */}
                                    <>
                                      <div className="mb-12 d-flex justify-content-between align-items-center">
                                        <p className="h6 font-medium ">
                                          Access Restrictions
                                        </p>
                                        {/* <p className="h6 font-bold text-primary-200">
                                      Advanced
                                    </p> */}
                                      </div>
                                      <div
                                        className="multiSelectMenuTop"
                                        style={{ marginBottom: "20px" }}
                                      >
                                        <MultiSelectDropdown
                                          name="restrictedTeamIds"
                                          isSearchable={false}
                                          className="select"
                                          classNamePrefix="selectCustom"
                                          options={optionsTeam}
                                          selectedValues={selectedTeam}
                                          placeholder="Search by Team"
                                          onSelect={(selectedTeams) =>
                                            onSelectationChanges(
                                              selectedTeams,
                                              "restrictedTeamIds",
                                              setFieldValue,
                                              setSelectedTeam,
                                              selectedTeam // Pass the current selectedTeam as prevSelectedValues
                                            )
                                          }
                                          // onSelect={(selectedTeam) =>
                                          //   onSelectationChanges(
                                          //     selectedTeam,
                                          //     "restrictedTeamIds",
                                          //     setFieldValue,
                                          //     setSelectedTeam
                                          //   )
                                          // }
                                          setMethod={setFieldValue}
                                        />
                                      </div>
                                      {showMultiSelect && (
                                        <div className="multiSelectMenuTop">
                                          <MultiSelectDropdown
                                            name="restrictedIds"
                                            isSearchable={false}
                                            className="select"
                                            classNamePrefix="selectCustom"
                                            options={
                                              optionsUser
                                                ? optionsUser.filter(
                                                    (user) =>
                                                      // selectedTeam.includes(user.teamId) || selectedUsers.includes(user.value)
                                                      selectedTeam.some(
                                                        (team) =>
                                                          team.value ===
                                                          user.teamId
                                                      ) ||
                                                      selectedUsers.some(
                                                        (team) =>
                                                          team.teamId ===
                                                          user.teamId
                                                      )
                                                  )
                                                : []
                                            }
                                            selectedValues={selectedUsers}
                                            placeholder="Search by Users"
                                            onSelect={(selectedUsers) =>
                                              onSelectationUserChanges(
                                                selectedUsers,
                                                "restrictedIds",
                                                setFieldValue,
                                                setSelectedUsers
                                              )
                                            }
                                            setMethod={setFieldValue}
                                            // onRemove={(selectedUsers) =>
                                            //   onSelectationUserChanges(
                                            //     selectedUsers,
                                            //     "restrictedIds",
                                            //     setFieldValue,
                                            //     setSelectedUsers
                                            //   )
                                            // }
                                          />
                                        </div>
                                      )}
                                    </>
                                    {/* )} */}
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="d-flex gap-20 align-items-center btnFooter pe-4">
                            <Button
                              disabled={
                                Object.keys(sequenceValidation(values))
                                  .length !== 0 || hasUnpublishedContent
                              }
                              type="button"
                              variant="secondary"
                              // onClick={() => setFieldValue("status", 0)}
                              onClick={() =>
                                handleActionClick(
                                  sequenceData?.status === "Template"
                                    ? "publish"
                                    : "update_publish",
                                  validateField,
                                  touched,
                                  setTouched,
                                  handleSubmit,
                                  setFieldValue,
                                  values
                                )
                              }
                              // onClick={() => {
                              //   if (sequenceData?.status === "Template") {
                              //     templatePublish(0);
                              //   }else{
                              //   setFieldValue("status", 0)
                              //   }
                              // }}
                            >
                              {getSubmitTitle(sequenceData?.id, operationType)}
                            </Button>
                            <Button
                              disabled={
                                Object.keys(sequenceValidation(values))
                                  .length !== 0 || hasUnpublishedContent
                              }
                              type="button"
                              variant="primary"
                              // onClick={() => setFieldValue("status", 7)}
                              onClick={() =>
                                handleActionClick(
                                  sequenceData?.id &&
                                    sequenceData?.status !== "Template"
                                    ? "save_as_template"
                                    : "update",
                                  validateField,
                                  touched,
                                  setTouched,
                                  handleSubmit,
                                  setFieldValue,
                                  values
                                )
                              }
                              // onClick={() => {
                              //     if (sequenceData?.id && sequenceData?.status !== "Template") {
                              //       templateSave(7);
                              //     }else{
                              //     setFieldValue("status", 7);
                              //     }
                              // }}
                            >
                              {getTemplateTitle(
                                sequenceData?.id,
                                operationType
                              )}
                              {/* Save as Template */}
                            </Button>

                            {(operationType === "Duplicate" || sequenceData?.status === "Template" ||
                              !sequenceData?.status ||
                              (sequenceData?.status === "Draft" &&
                                sequenceData?.status !== "Published")) && (
                              <Button
                                disabled={
                                  isButtonDisabled ||
                                  Object.keys(sequenceDraftValidation(values))
                                    .length !== 0
                                }
                                type="submit"
                                variant="noBg"
                                onClick={() => {
                                  setButtonDisabled(true);
                                  onSequenceSubmit(1);
                                }}
                                // onClick={() => setFieldValue("status", 1)}
                              >
                                Save as draft
                              </Button>
                            )}
                            <Button
                              className="ml-auro"
                              variant="white"
                              // onClick={() => onCancelClick()}
                              onClick={() => {
                                if (sequenceData?.status === "Template") {
                                  navigate.push("/authoring/sequences", {
                                    id: sequenceData?.id,
                                    backType: "Template",
                                  });
                                } else {
                                  onCancelClick();
                                }
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                          <Modal
                            className="modalDialog"
                            size="sm"
                            centered
                            show={restrictModal}
                            // onHide={() => setRestrictModal(false)}
                          >
                            <Modal.Header
                              closeButton
                              onClick={() => setRestrictModal(false)}
                            >
                              <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pb-0">
                              <span className="iconRestrict">
                                <IconRestrict />
                              </span>
                              <h3 className="pt-1 mt-2">
                                {" "}
                                Restrict Whole Team?
                              </h3>
                              <p className="text-gray-300 mb-0 mt-2 mb-5">
                                All the team members of the selected team will
                                be restricted.
                              </p>
                            </Modal.Body>

                            <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
                              <Button
                                variant="white"
                                size=""
                                className="m-0"
                                onClick={() => {
                                  setRestrictModal(false);
                                  setShowMultiSelect(true);
                                }}
                              >
                                No, Restrict Individually
                              </Button>
                              <Button
                                variant="primary"
                                size=""
                                className="border-0 m-0 ms-2"
                                onClick={() => {
                                  setRestrictModal(false);
                                  setRestrictEmployee(true);
                                }}
                              >
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </div>
          )}

          {isRestricted && (
            <RestrictedPlan
              title={"Sequence Creation Limit Reached!"}
              description={
                "You've reached the creation limit for sequences. Upgrade plan to add more."
              }
            />
          )}
        </Container>
        {formDataReady === false && <Loader />}
      </div>
      {/* <Modal className="come-from-modal right createPage" show={createPageModal} onHide={() => setCreatePageModal(false)}>
                <ModalCreatePage openCreatePageModal={setCreatePageModal} />
            </Modal>
            <Modal className="come-from-modal right createForm" show={createFormModal} onHide={() => setCreateFormModal(false)}>
                <ModalCreateForm openCreateFormModal={setCreateFormModal} />
            </Modal> */}
      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={showAlertMessagePopup}
      >
        <ModalMessageAlertPopup
          onCancel={setShowAlertMessagePopup}
          requestType=""
          headerLable="You can't add any draft content as it is in published status."
          chldLable=""
        />
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={restrictedPopUp}>
        <RestrictedModalPopup
          onCancel={setRestrictedPopUp}
          headerLable={
            "Action can't be performed since sequences limit has reached!"
          }
          chldLable={
            "Kindly purchase or upgrade your plan in order to continue with this action."
          }
        />
      </Modal>
    </div>
  );
};

export default SequencesCreate;
