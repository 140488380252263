import React from 'react';
import { Modal, Button } from "react-bootstrap";
const UpgradeModal = ({ selectedPlanData,
    handleModal,
    confirm}) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <h3 className='mt-3'>Upgrade Confirmed</h3>
                <p className='text-gray-300 mb-0 mt-2 mb-2'>
                Your plan upgrade is effective immediately. You will see a pro-rated adjustment for this billing period. The new plan rate applies from your next billing date.
                </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2 py-3">
                <Button variant="white" size="sm" onClick={() => handleModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" onClick={confirm} className='border-0'>
                    Sounds Good!
                </Button>
            </Modal.Footer>
        </>
    );
}

export default UpgradeModal;
