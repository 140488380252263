import React from 'react';
import AccountSettingNav from "../AccountSettingNav";
import { Row, Col, Container, Button } from "react-bootstrap";
import { IconHamberger, IconExclamationLg, IconMicrosoft } from "../../../icons/Icons";
import AccountImg from '../../../assets/images/Account.png';

import Search from "../../../components/Search/Search";
import OwnerImg from '../../../assets/images/Account.png';

const ProfilePhoneVerified = () => {
    return (
        <div className='pages'>
            <header className='pageHeader'>
                <Container fluid>
                    <Row className='mx-0 align-items-center'>
                        <Col className='px-0'>
                            <h3>Account Settings</h3>
                        </Col>
                        <Col className='px-0 text-end '>
                            <div className="d-flex gap-3 align-items-center justify-content-end">
                                <Search />
                                <div className='headImg'>
                                    <img src={AccountImg} alt="" />
                                </div>
                                <div>
                                    <button className="hamberger"> <IconHamberger /></button>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </header>
            <div className='pageOuter'>
                <Container fluid>
                    <AccountSettingNav />
                    <div className='page profile d-flex flex-column h-100'>

                        <div className='w-100 flex-grow-1 h-100 overflow-auto'>

                            <Row className='g-0 d-flex h-100'>

                                <Col className='col-lg-8 flex-grow-1 overflow-auto h-100'>
                                    <div className='p-32 pb-4'>
                                        <div className="input-group myInput mb-32">
                                            <label>Name</label>
                                            <input type="text" className="form-control" value="Harry Mathews" />
                                        </div>

                                        <div className="input-group myInput mb-32">
                                            <label>Title</label>
                                            <input type="text" className="form-control" value="HR lead" />
                                        </div>
                                        <div className="input-group myInput mb-12">
                                            <label>Phone Number</label>
                                            <input type="text" className="form-control" value="9988778767" />

                                        </div>


                                        <div className='bg-pastel-300 py-4 px-3 mwidth-440 rounded-6 mb-32'>


                                            <div className='d-flex gap-3'>

                                                <div className='shrink-0'><IconExclamationLg /></div>

                                                <div>

                                                    <p className='mb-0 font-medium'>Action Required</p>
                                                    <p className='mb-20'><span className='text-gray-300'>Verify your phone number </span>9988778767</p>
                                                    <Button variant="primary" size="sm">
                                                        Send Verification Link
                                                    </Button>
                                                </div>

                                            </div>



                                        </div>
                                        <div className="input-group myInput">
                                            <div className='d-flex justify-content-between w-100'>
                                                <label>Email address</label>
                                                <h6><a className='text-primary-200'>Change</a></h6>
                                            </div>
                                            <input type="email" className="form-control w-100 mb-2" value="harry@gmail.com" />
                                            <p className='text-md  mb-32 pb-32 borderBottom w-100'>Note: <span className='text-gray-200'>This email will receive notifications for password updates.</span></p>
                                        </div>



                                        <div className='mwidth-440 '>
                                            <div className='mb-32 pb-32 borderBottom'>
                                                <p className='h6 mb-20'>Linked accounts</p>
                                                <div className='d-flex justify-content-between mb-4'>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div className='tableName'>HS</div>
                                                        <p className='mb-0 text-gray-200 font-medium'>harry@gmail.com</p>
                                                    </div>

                                                    <h6><a className='text-primary-200'>Unlink</a></h6>
                                                </div>

                                                <div className='d-flex justify-content-between mb-4'>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div className='tableName'>HS</div>
                                                        <p className='mb-0 text-gray-200 font-medium'>harrymathews@gmail.com</p>
                                                    </div>

                                                    <h6><a className='text-primary-200'>Unlink</a></h6>
                                                </div>

                                                <div className='d-flex justify-content-between mb-4'>
                                                    <div className='d-flex align-items-center gap-2'>
                                                        <div className='tableName'><IconMicrosoft /></div>
                                                        <p className='mb-0'><a className='text-primary-200 font-bold'>Connect your microsoft account</a></p>
                                                    </div>


                                                </div>
                                            </div>
                                        </div>


                                        <div>
                                            <p className='h6 mb-20'>Password</p>

                                            <div className="input-group myInput mb-12 d-flex">

                                                <input type="password" className="form-control p-0 border-0" value="9988778767" />
                                                <h6><a className='text-primary-200'>Update Password</a></h6>

                                            </div>
                                        </div>


                                    </div>

                                </Col>

                                <Col className='col-lg-4 flex-grow-1 h-100'>

                                    <div className='bg-gray-600 p-32 py-40 h-100 borderLeft2 overflow-auto'>
                                        <h5 className='mb-4'>Organization</h5>
                                        <div className="input-group myInput mb-32">
                                            <label>Organization</label>
                                            <input type="text" className="form-control" value="XYZ" />
                                        </div>

                                        <div className="input-group myInput mb-32">
                                            <label>Website</label>
                                            <input type="text" className="form-control" value="www.xyz.com" />
                                        </div>

                                        <div className="input-group myInput mb-32">
                                            <label>Number of employees</label>
                                            <input type="text" className="form-control" value="123" />
                                        </div>

                                        <div className='rounded-6 p-4 text-center boxShadow mb-32 bg-base-100'>

                                            <div className='d-flex justify-content-center mb-20'>
                                                <div className='headImg moduleImg'>
                                                    <img src={OwnerImg} alt="" />
                                                </div>
                                                <div className='headImg moduleImg' style={{ zIndex: '3' }}>
                                                    <img src={OwnerImg} alt="" />
                                                </div>
                                                <div className='headImg moduleImg'>
                                                    <img src={OwnerImg} alt="" />
                                                </div>
                                            </div>

                                            <h4 className='mb-12'>Help others in your organization benefit from Circles!</h4>
                                            <p className='h6 mb-20 text-gray-200'>Share Circles to aid with employee onboarding, familiarizing new clients with your systems and processes, documenting compliance training and certifications, and more! </p>

                                            <div className="input-group myInput mb-4">

                                                <input type="text" className="form-control" placeholder="harry@gmail.com" />

                                            </div>
                                            <Button variant="primary" size="lg" className='w-100'>
                                                Share
                                            </Button>
                                        </div>




                                    </div>

                                </Col>
                            </Row>


                        </div>





                        <div className='d-flex gap-2 align-items-center btnFooter'>
                            <Button variant="secondary">
                                Save Changes
                            </Button>
                            <Button variant="white">
                                Cancel
                            </Button>

                        </div>
                    </div>


                </Container>
            </div >
        </div >
    );
}

export default ProfilePhoneVerified;
