import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { Constants } from "../services/Constants";
import {
  getEmployeeCount,
  getUnassignedEmployeeCount,
  getAssignmentsStatusCount,
  getMostPopularContent,
  getHelpdeskNumbers,
  avgOnboardingTime,
  getTeamWiseOnboarding
} from "../api/dashboard-store-api";

export const getEmployeeCountAsync = createAsyncThunk(
  "EmployeeCount",
  async (reqBody) => {
    const response = await getEmployeeCount(reqBody);
    return response.data;
  }
);

export const getUnassignedEmployeeCountAsync = createAsyncThunk(
  "UnassignedEmployeeCount",
  async (reqBody) => {
    const response = await getUnassignedEmployeeCount(reqBody);
    return response.data;
  }
);

export const getAssignmentsStatusCountAsync = createAsyncThunk(
  "AssignmentStatus",
  async (reqBody) => {
    const response = await getAssignmentsStatusCount(reqBody);
    return response.data;
  }
);

export const getMostPopularContentAsync = createAsyncThunk(
  "AssignmentStatus",
  async (reqBody) => {
    const response = await getMostPopularContent(reqBody);
    return response.data;
  }
);

export const getHelpdeskNumbersAsync = createAsyncThunk(
  "HelpDeskNumbers",
  async (reqBody) => {
    const response = await getHelpdeskNumbers(reqBody);
    return response.data;
  }
);

export const avgOnboardingTimeAsync = createAsyncThunk(
  "AvgOnboardingTime",
  async (reqBody) => {
    const response = await avgOnboardingTime(reqBody);
    return response.data;
  }
);

export const getTeamWiseOnboardingAsync = createAsyncThunk(
  "TeamWiseOnboarding",
  async (reqBody) => {
    const response = await getTeamWiseOnboarding(reqBody);
    return response.data;
  }
);
