import React, { useState } from 'react';
import { NavLink, Link } from "react-router-dom";
const TeamsNav = ({teamMember, setActiveTab, activeTab}) => {
    // const [activeTab, setActiveTab] = useState('Currently Onboarding'); 

  const handleTabClick = (tabName) => {
    setActiveTab(tabName);
  };
  
    return (
        <ul className='authoringNav'>
        <li className="me-4">
          <Link
            className={`h6 text-gray-100 ${activeTab === 'Currently Onboarding' ? 'active' : ''}`}
            to="/accountsetting/manageteams"
            onClick={() => handleTabClick('Currently Onboarding')}
          >
            Currently Onboarding ({teamMember?.currentMembers?.employees ? teamMember?.currentMembers?.employees?.length : 0})
          </Link>
        </li>
        <li className="me-4">
          <Link
            className={`h6 text-gray-100 ${activeTab === 'Pending' ? 'active' : ''}`}
            to="#"
            onClick={() => handleTabClick('Pending')}
          >
            {/* Pending ({teamMember?.pendingMembers?.employees ? teamMember?.pendingMembers?.employees?.length : 0}) */}
            Pending ({teamMember?.pendingMembers 
              ? (teamMember.pendingMembers.employees?.length || 0) + (teamMember.pendingMembers.admins?.length || 0) 
              : 0})
            
          </Link>
        </li>
        <li className="me-4">
          <Link
            className={`h6 text-gray-100 ${activeTab === 'Upcoming' ? 'active' : ''}`}
            to="#"
            onClick={() => handleTabClick('Upcoming')}
          >
            Upcoming ({teamMember?.upcomingMembers?.employees ? teamMember?.upcomingMembers?.employees?.length : 0})
          </Link>
        </li>
        <li className="me-4">
        <Link
          className={`h6 text-gray-100 ${activeTab === 'Completed' ? 'active' : ''}`}
          to="#"
          onClick={() => handleTabClick('Completed')}
        >
          Completed ({teamMember?.completedMembers?.employees?.length || 0})
        </Link>
        </li>
      </ul>
        // <ul className='authoringNav authoringNavSm'>
        //     <li className='me-4'>
        //         <button className='text-md text-gray-100 active'>Currently Onboarding (4)</button>
        //     </li>
        //     <li className='me-4'>
        //         <button className='text-md text-gray-100 '>Pending (1)</button>
        //     </li>
        //     <li className='me-4'>
        //         <button className='text-md text-gray-100'>Upcoming (2)</button>
        //     </li>
        //     <li className='me-4'>
        //         <button className='text-md text-gray-100'>Completed (24)</button>
        //     </li>
        // </ul>
       
    );
}

export default TeamsNav;
