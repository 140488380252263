import { IconsGallery, IconsVideo, IconsShortAnswer, IconsParagraph, IconsMultipleChoice, IconsCheckboxes, IconsDropdown, IconsFileUpload, IconsDate, IconsTime, IconsCross } from "./FormType/Icons";


export function QueTypeIcon({typeId,typeName}) {
    let typeLabel = <div className='dropItmIcon'> <IconsShortAnswer /> <span>{typeName}</span></div>;
console.log(typeId,typeName,'tttt')
    if (typeId) {
        switch (typeId) {
            case 1:
                typeLabel = <div className='dropItmIcon'> <IconsShortAnswer /><span>{typeName}</span></div>
                break;
            case 2:
                typeLabel = <div className='dropItmIcon'> <IconsParagraph /><span>{typeName}</span></div>
                break;
            case 3:
                typeLabel = <div className='dropItmIcon'> <IconsMultipleChoice /><span>{typeName}</span></div>
                break;
            case 4:
                typeLabel = <div className='dropItmIcon'> <IconsCheckboxes /><span>{typeName}</span></div>
                break;
            case 5:
                typeLabel = <div className='dropItmIcon'> <IconsDropdown /><span>{typeName}</span></div>
                break;
            case 6:
                typeLabel = <div className='dropItmIcon'> <IconsFileUpload /><span>{typeName}</span></div>
                break;
            case 7:
                typeLabel = <div className='dropItmIcon'> <IconsDate /><span>{typeName}</span></div>
                break;
            case 8:
                typeLabel = <div className='dropItmIcon'> <IconsTime /><span>{typeName}</span></div>
                break;
          
        }
    }

    return (
        typeLabel
    )
}

