import React from 'react';
import PageHeader from "../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button } from "react-bootstrap";

const PaymentSuccess = () => {
    return (
        <div className='pages'>
            <PageHeader title="" />
            <div className='d-flex justify-content-center  h-100 flex-grow-1 overflow-auto'>
                <div>
                    <div className='text-center mx-auto mwidth-540'>
                        <div className='mb-32'>
                            <svg width="133" height="109" viewBox="0 0 133 109" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M66 88C83.6 88 98 73.6 98 56C98 38.4 83.6 24 66 24C48.4 24 34 38.4 34 56C34 73.6 48.4 88 66 88Z" fill="#2EB67D" />
                                <path d="M52.4014 56.0003L61.4574 65.0563L79.6014 46.9443" fill="#2EB67D" />
                                <path d="M52.4014 56.0003L61.4574 65.0563L79.6014 46.9443" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                <path d="M107.403 30.1836C107.403 30.1836 105.62 21.6905 109.869 19.3341C114.205 16.9301 118.826 20.8421 123.007 18.161C127.337 15.384 125.828 4.87513 125.828 4.87513" stroke="#F15845" stroke-width="4" stroke-linecap="round" />
                                <circle cx="113.5" cy="45.5" r="5.5" fill="#0080FF" />
                                <circle cx="24.5" cy="35.5" r="5.5" fill="#ECB22E" />
                                <path d="M111 67.9998C111 67.9998 118.799 61.591 123.857 65C127.758 67.629 131 74.9998 131 74.9998" stroke="#ECB22E" stroke-width="4" stroke-linecap="round" />
                                <path d="M2 63.0012C2 63.0012 9.79858 69.4099 14.8571 66.0009C18.7583 63.3719 22 56.0012 22 56.0012" stroke="#F15845" stroke-width="4" stroke-linecap="round" />
                                <circle cx="101.528" cy="100.742" r="5.5" transform="rotate(-43.1563 101.528 100.742)" fill="#0080FF" />
                                <circle cx="25.5" cy="85.5" r="5.5" fill="#0080FF" />
                                <circle cx="71.5" cy="5.5" r="5.5" fill="#0080FF" />
                                <circle cx="85.9377" cy="92.1433" r="3" transform="rotate(-43.1563 85.9377 92.1433)" fill="#ECB22E" />
                                <circle cx="80" cy="14" r="3" fill="#ECB22E" />
                            </svg>
                        </div>


                        <h1 className='mb-3'>Payment Successful</h1>
                        <p className='mb-32 text-gray-300'>Lorem ipsum dolor sit amet. Et molestias ratione est architecto numquam aut modi odit aut harum quod est laborum.</p>

                    </div>

                    <div className='text-center mx-auto mwidth-440'>
                        <div className='text-start rounded-6 borderAll mb-4 bg-base-100'>

                            <div className='font-bold mb-20 p-20 borderBottom'>Your Subscription Details</div>

                            <Row className='p-20'>
                                <Col>

                                    <p className='h6 text-gray-200 mb-20'>Subscription Plan</p>
                                    <p className='h6 text-gray-200 mb-20'>Amount</p>
                                    <p className='h6 text-gray-200 mb-20'>Date</p>
                                    <p className='h6 text-gray-200 mb-20'>Renewal Date</p>
                                    <p className='h6 text-gray-200 mb-20'>Card Type</p>
                                    <p className='h6 text-gray-200 mb-20'>Card Holder’s Name</p>
                                </Col>

                                <Col>
                                    <p className='h6 mb-20 font-medium'>Small Business Plan</p>
                                    <p className='h6 mb-20 font-medium'>$ 699.00</p>
                                    <p className='h6 mb-20 font-medium'>11/12/2021</p>
                                    <p className='h6 mb-20 font-medium'>11/12/2022</p>
                                    <p className='h6 mb-20 font-medium'>Visa</p>
                                    <p className='h6 mb-20 font-medium'>Aaron Ronstadt</p>
                                </Col>
                            </Row>

                        </div>
                        <div className='justify-content-center d-flex gap-3 pb-5'>
                            <Button variant="secondary" >
                                Go to  Dashboard
                            </Button>
                            <Button variant="white" >
                                Download Receipt
                            </Button>
                        </div>

                    </div>

                </div>


            </div>
        </div>
    );
}

export default PaymentSuccess;
