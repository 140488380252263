import React, { useState, useRef, useEffect } from 'react';
import { Row, Col } from "react-bootstrap";
import { IconPPT, IconDownload, IconReportIssue } from "../../../icons/Icons";
import Header from './Header';
import FileViewer from 'react-file-viewer';
import file from "./Files/doc.docx";
import docSlide1 from "../../../assets/images/docSlide1.svg";
import docSlide2 from "../../../assets/images/docSlide2.svg";
import { generatePresignedUrl, bulkGeneratePresignedUrl } from '../../../api/common-store-api';
import { Link, useLocation, useHistory } from "react-router-dom";
import { useSelector } from 'react-redux';
import { getGUID, getRemainingStringFileName } from "../../../common";
import DocViewer, { DocViewerRenderers } from 'react-doc-viewer';

const Doc = ({ actionReport, contentUrl, slideData, setContent }) => {
    const [show, setShow] = useState(false);
    const target = useRef(null);
    const userRole = useSelector((state) => state.user.currentUser?.roles[0].name);
    const [contentUrlData, setContentUrlData] = useState(null);
    const location = useLocation()
    const arrayDownloadableFiles = slideData?.downloadableFile
    const [downloadableFileURLs, setDownloadableFileURLs] = useState([])


    const getPreviewUrl = async () => {
      const getGuid = getGUID(contentUrl);  
      let previewUrl = await generatePresignedUrl(getGuid);
        if (previewUrl?.data) {
            setContentUrlData(previewUrl.data);
        }
    };

    useEffect(() => {
        getPreviewUrl();
        getBulkGeneratePresignedUrl()
    }, []);

    const getBulkGeneratePresignedUrl = async () => {
        if (arrayDownloadableFiles !== undefined &&
            arrayDownloadableFiles !== null &&
            arrayDownloadableFiles.length > 0) {
            let responseURLsDownloadable = await bulkGeneratePresignedUrl(arrayDownloadableFiles.map((value) => value.filePath));
            if (responseURLsDownloadable?.data) {
                setDownloadableFileURLs(responseURLsDownloadable.data)
            }
        }
    };

    return (
      <div className="pages">
        {/* <Header />
            <div className='position-relative' style={{ top: '-1px' }}>
                <div className="progress tableProgress bg-base-100 " style={{ height: '2px' }} ref={target} onMouseEnter={() => setShow(!show)}>
                    <div className="progress-bar bg-primary-200" role="progressbar" style={{ width: '21%' }}></div>
                </div>
            </div> */}
        <div className="pageOuter pb-0">
          <Row className="g-0 h-100">
            <Col className="col-lg-9 bg-base-100 d-flex flex-grow-1 h-100">
              <div className="videoPreview docPreview flex-grow-1 d-flex flex-column">
              {contentUrlData ? (
                  <>
                    {(contentUrl.split(".").pop().toLowerCase() === "doc" || contentUrl.split(".").pop().toLowerCase() === "docx") ? (
                      <DocViewer
                        documents={[{ 
                          uri: contentUrlData, 
                          fileType: contentUrl.split(".").pop(),
                          fileName: getRemainingStringFileName(contentUrl)
                        }]}
                        pluginRenderers={DocViewerRenderers}
                        style={{ width: "100%", height: "100%" }}
                        onError={(e) => console.log('Error Documents:', e)}
                      />
                    ) : (
                      <FileViewer
                        fileType={contentUrl.split(".").pop()}
                        filePath={contentUrlData}
                        style={{ width: "100%", height: "600" }}
                        // errorComponent={CustomErrorComponent}
                        // onError={this.onError}
                        errorComponent={<div>Error loading file</div>}
                        onError={(e) => console.log('Error Documents:', e)}
                      />
                    )}
                  </>
                ) : null}

                {/* <div className='d-flex gap-3 docSlides mt-3'>

                                <img src={docSlide1} alt="" className='w-100' />
                                <img src={docSlide2} alt="" className='w-100' />
                                <img src={docSlide2} alt="" className='w-100' />
                                <img src={docSlide2} alt="" className='w-100' />
                                <img src={docSlide2} alt="" className='w-100' />
                                <img src={docSlide2} alt="" className='w-100' />



                            </div> */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    );
}

export default Doc;
