import React from "react";
import OwnerImg from "../../../../../assets/images/Account.png";
import EmptyState from "../../../../../components/EmptyState/EmptyState";
import { IconEdit2, ClickShare } from "../../../../../icons/Icons";
import UserImage from "../../../../../components/UserImage/UserImage";
import getInitialsInName from "../../../../../helper/GetNameInitials";
const Onboarding = ({ actionActivity, teamMember = [], setUserData}) => {
  return (
    teamMember?.length > 0 ?
    teamMember.map((m) => (
      <>
    
        <button
          className="userMedia d-flex align-items-center whiteNo"
          onClick={() => {
            actionActivity(true);
            setUserData(m);
          }}
        >
          <div className="imgicosize">
                                  {m.img ? (
                                    <div className="tableImg">
                                      {/* <img src={user.img} alt={user.name} /> */}
                                      <UserImage imgKey={m.img}/>
                                    </div>
                                  ) : (
                                    <div className="imgicosz">
                                      <div className="tableImg">
                                        <img src={getInitialsInName(m.name ?? "N A", 26)} alt={m.name} />
                                      </div>
                                      {/* <div class="tableName text-primary-100 bg-pastel-100">
                                        {getInitialsInName(user.name)}
                                      </div> */}
                                    </div>
                                    )}
          </div>
          <div className="userMediaBody d-flex justify-content-between">
            <div className="ps-2 flex1half">
              <h6 className="font-medium pe-5 d-flex"><span className="w18rem">{m.name}</span><span className="ps-2 ms-1"><ClickShare /></span></h6>
              {/* <p className="text-md mb-0 text-gray-300">{m.teamname}</p> */}
            </div>
            <div className="d-flex flex2half align-items-center justify-content-end"> 
              <div className="manageTeamsProgress">
                <span
                  // style={{
                  //   width:
                  //     "" +
                  //     (m.assignmentDetails.filter((f) => f.status === 0)
                  //       .length /
                  //       m.assignmentDetails.length) *
                  //       100 +
                  //     "%",
                  // }}

                  style={{ width: `${(parseInt(m.assignmentCount.completed) / parseInt(m.assignmentCount.pending + m.assignmentCount.active + m.assignmentCount.completed))* 100}%` }}
                  //       style={{
                  //   width:
                  //   {(parseInt(m.assignmentCount.completed) / parseInt(m.assignmentCount.pending + m.assignmentCount.active + m.assignmentCount.completed))* 100}
                  //   +  "%",
                  // }}

                 
                ></span>
              </div>
              <div className="text-md text-gray-300 text-end ms-4">
                {/* {m.assignmentDetails.filter((f) => f.status === 0).length}/
                {m.assignmentDetails.length} */}
{parseInt(m.assignmentCount.completed) +"/"+ parseInt(m.assignmentCount.pending + m.assignmentCount.active + m.assignmentCount.completed + m.assignmentCount.delayed)}
              </div>
            </div>
          </div>
        </button>
      </>
    ))
    :<EmptyState message={"No records found"}/>
  );
  {
    /* <button className="userMedia" onClick={() => actionActivity(true)}>
        <div className="userMediaImg">
          <img src={OwnerImg} alt="OwnerImg" />
        </div>
        <div className="userMediaBody">
          <div className="ps-2">
            <h6>John Smith</h6>
            <p className="text-md mb-0 text-gray-300">Account & Finance</p>
          </div>
          <div>
            <div className="text-md text-gray-300 text-end mb-2">8/15</div>
            <div className="manageTeamsProgress">
              <span style={{ width: "50%" }}></span>
            </div>
          </div>
        </div>
      </button>
      <button className="userMedia" onClick={() => actionActivity(true)}>
        <div className="userMediaImg">
          <img src={OwnerImg} alt="OwnerImg" />
        </div>
        <div className="userMediaBody">
          <div className="ps-2">
            <h6>John Smith</h6>
            <p className="text-md mb-0 text-gray-300">Account & Finance</p>
          </div>
          <div>
            <div className="text-md text-gray-300 text-end mb-2">8/15</div>
            <div className="manageTeamsProgress">
              <span style={{ width: "50%" }}></span>
            </div>
          </div>
        </div>
      </button>
      <button className="userMedia" onClick={() => actionActivity(true)}>
        <div className="userMediaImg">
          <img src={OwnerImg} alt="OwnerImg" />
        </div>
        <div className="userMediaBody">
          <div className="ps-2">
            <h6>John Smith</h6>
            <p className="text-md mb-0 text-gray-300">Account & Finance</p>
          </div>
          <div>
            <div className="text-md text-gray-300 text-end mb-2">8/15</div>
            <div className="manageTeamsProgress">
              <span style={{ width: "50%" }}></span>
            </div>
          </div>
        </div>
      </button>
      <button className="userMedia" onClick={() => actionActivity(true)}>
        <div className="userMediaImg">
          <img src={OwnerImg} alt="OwnerImg" />
        </div>
        <div className="userMediaBody">
          <div className="ps-2">
            <h6>John Smith</h6>
            <p className="text-md mb-0 text-gray-300">Account & Finance</p>
          </div>
          <div>
            <div className="text-md text-gray-300 text-end mb-2">8/15</div>
            <div className="manageTeamsProgress">
              <span style={{ width: "50%" }}></span>
            </div>
          </div>
        </div>
      </button> */
  }
};

export default Onboarding;
