import React, { useState, useEffect, useRef } from "react";
import { Button, Modal } from "react-bootstrap";
import {
  IconEyeDefault,
  IconEyeSlash,
  IconResetpass,
  IconResetcheck
} from "../../icons/Icons";
import { useDispatch } from "react-redux";
import { updatePasswordAsync } from "../../reducers/user-reducers";
import TextInput from "../../components/FormikInputs/TextInput";
import { Formik } from "formik";
import ModalResponse from "../../components/ModalPopup/ModalResponsePopup";

const UpdatePassword = ({ onClose, isPasswordNotSet, oldPasswords }) => {
  const dispatch = useDispatch();
  const formRef = useRef();
  const [responsePopup, setResponsePopup] = useState({});
  const [showPassword, setShowPassword] = useState();
  const [isSubmitted, setSubmitted] = useState(false);

  // console.log(isPasswordNotSet,'isPasswordNotSet');
  // console.log(oldPasswords,'oldPasswords');

  const onResponseClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
    onClose(false);

  };

  const processResponse = (res) => {
  let errorMessage = "";
  if (res.error) {
    errorMessage = res.payload.split(":")[1];
    errorMessage = errorMessage.split("\n")[0];
  }
    let prompt =!res?.error ? "Success": "Error";
    let message = !res?.error ? "Your password has been saved successfully" : errorMessage;

    setResponsePopup({
      show: true,
      propmt: prompt,
      message: message,
      records: [],
      procesFailedError: res?.errors?.message,
    });
  };
  const passwordValidation = (values) => {
    let errors = {};

    if (!values.newPassword) {
      errors.newPassword = "New password is required";
    } else if (oldPasswords === values.newPassword) {
      errors.newPassword = "Old Password and new password cannot be same";
    } else if (values.newPassword.length < 8) {
      errors.newPassword = "New password must be at least 8 characters long";
    } else if (

      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%*?&])[A-Za-z\d@#$!%*?&]/.test(
        values.newPassword
      ) && values.newPassword != "password"
    ) {
      errors.newPassword =
        "New password must contain at least one uppercase letter, one lowercase letter, one number, one special character";
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = "Confirm password is required";
    } else if (values.newPassword !== values.confirmPassword) {
      errors.confirmPassword = "Password does not match";
    }

    return errors;
  };

  const onPasswordSubmit = () => {
    const currentFormData = formRef?.current;
    var errors = passwordValidation(currentFormData.values);
    if (
      Object.keys(currentFormData.errors).length === 0 &&
      Object.keys(errors).length === 0
    ) {

      const reqBody = {
        password: null,
        newPassword:  currentFormData.values?.newPassword 
      };

      dispatch(updatePasswordAsync(reqBody)).then(res => {
        if(!res.error){
        setSubmitted(true)
        }
      });
    }
  };
// console.log(formRef?.current, "form");
  return (
    <>
      {/* <Modal.Header 
      closeButton onClick={() => onClose(false)}
      >
                <Modal.Title>Password Reset Request</Modal.Title>
            </Modal.Header> */}

        <Formik
          initialValues={{
            password: "",
            confirmPassword: "",
          }}
          onSubmit={onPasswordSubmit}
          validate={passwordValidation}
          innerRef={formRef}
        >
          {({
            values
           }) => {
            return (
              <>
              {!isSubmitted ?
              <div className=" p-4">
              <Modal.Body className="updatePassword pupdatepass">
              <div className="text-center mb-4">
                <div>
                  <IconResetpass/>
               
                </div>
              <h3>Password Reset Request</h3>
              <p className="h5 font-normal text-gray-300 py-2">You have logged with temporary password, we suggest you to reset your password before proceeding.</p>
              </div>
                <div className="input-group myInput mb-3 d-flex">
                  <TextInput
                    isRequired={true}
                    type={showPassword == 2 ? "text" : "password"}
                    name="newPassword"
                    placeholder="Enter New password"
                    className="form-control"
                   

                  />
                  {showPassword != 2 &&
                    <span  style={{ cursor: "pointer" }} onClick={() => { console.log(showPassword); setShowPassword(2) }}>
                      <IconEyeDefault />
                    </span>

                  }
                  {showPassword == 2 &&
                    <span  style={{ cursor: "pointer" }}  onClick={() => { console.log(showPassword); setShowPassword() }}>
                      <IconEyeSlash />
                    </span>
                  }

                </div>
                <div className="input-group myInput mb-3 d-flex">
                  <TextInput
                    isRequired={true}
                    type={showPassword == 3 ? "text" : "password"}
                    name="confirmPassword"
                    placeholder="Confirm New password"
                    className="form-control "
                   

                  />
                  {showPassword != 3 &&
                    <span  style={{ cursor: "pointer" }}  onClick={() => { console.log(showPassword); setShowPassword(3) }}>
                      <IconEyeDefault />
                    </span>

                  }
                  {showPassword == 3 &&
                    <span  style={{ cursor: "pointer" }}  onClick={() => { console.log(showPassword); setShowPassword() }}>
                      <IconEyeSlash />
                    </span>
                  }
                </div>
                <Button  variant="secondary2" size="lg"  className="w-100 mt-3"
                  disabled={Object.keys(passwordValidation(values)).length !==0}
                  onClick={() => onPasswordSubmit()}>
                  Done
                </Button>
              </Modal.Body>
            </div>      
:
            <div className=" p-4">
              <Modal.Body className="updatePassword pupdatepass">
              <div className="text-center mb-4">
                <div>
                  <IconResetcheck/>
                </div>
              <h3>Password has been updated</h3>
              <p className="h5 font-normal text-gray-300 py-2">Your password has been successfully updated. Click below to access your dashboard.</p>
              </div>
                <Button  variant="secondary2" size="lg"  className="w-100 mt-3" onClick={() => onClose(false)}>
                  Close
                </Button>
              </Modal.Body>
            </div>}
              </>)
          }}
        </Formik>
    </>
  );


};

export default UpdatePassword;
