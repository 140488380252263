import { http } from "../httpService";

export function getAllModule(reqBody) {
  return http.post(`/Module/All`, reqBody);
}

export function createModule(reqBody) {
  return http.post(`/Module/Create`, reqBody);
}

export function createModuleDraft(reqBody) {
  return http.post(`/Module/SaveAsDraft`, reqBody);
}

export function updateModule(reqBody) {
  return http.post(`/Module/Update`, reqBody);
}

export function addUpdateQuestion(reqBody) {
  return http.post(`/Module/AddUpdateQuestion`, reqBody);
}

export function addUpdatePage(reqBody) {
  return http.post(`/Module/AddUpdatePage`, reqBody);
}

export function addUpdateForm(reqBody) {
  return http.post(`/Module/AddUpdateForm`, reqBody);
}

export function deleteModule(request) {
  return http.delete(
    `/Module/DELETE?id=${request.id}&status=${request.status}`
  );
}

export function deleteModulePage(request) {
  return http.delete(
    `/Module/DeletePage?id=${request.id}`
  );
}

export function deleteModuleForm(request) {
  return http.delete(
    `/Module/DeleteForm?id=${request.id}`
  );
}

export function bulkDeleteModules(request) {
  return http.post(
    `/Module/BulkDelete`,request
  );
}
export function bulkArchiveModules(request) {
  return http.post(
    `/Module/BulkArchive`,request
  );
}

export function getModuleDetails(id) {
  return http.post(`/Module/Get?id=${id}`);
}

export function getModulePreview(id) {
  return http.post(`/Module/Preview/${id}`);
}

export function getModulePageDetails(id) {
  return http.post(`/Module/Get/Page?id=${id}`);
}

export function getModuleFormDetails(id) {
  return http.post(`/Module/Get/Form?id=${id}`);
}

export function getModulePageForms(id) {
  return http.post(`/Module/Get/PagesForms?id=${id}`);
}
export function checkUniqueness(reqBody) {
  return http.post(`/Module/isUniqueness`, reqBody);
}
export function checkUniquenessTemp(reqBody) {
  return http.post(`/Module/isUniqueness`, reqBody);
}

export function getModuleFeedbackDetails(id) {
  return http.get(`/EmployeeFeedbackRating/ModuleFeedback/${id}`);
}

export function getModuleTemplates(reqBody) {
  return http.post(`/Module/Templates`, reqBody);
}

export function getSortedPageForms(id) {
  return http.post(`/Module/Get/SortedPagesForms?id=${id}`);
}
