import React, { useEffect } from "react";
import PageHeader from "../../../components/PageHeader/PageHeader";
import { Row, Col, Container, Button, Link } from "react-bootstrap";
import {
  IconStarLg,
  IconStarGray,
  IconPlanUpgrade,
} from "../../../icons/Icons";

const FirstTimePlan = () => {
  return (
    // <div className="pages bg-gray-600">
    //   <PageHeader title="Account Settings" />
      <div className="d-flex align-items-center justify-content-center h-100 row g-0">
        <div className="text-center col-lg-6">
          <div className="mb-20">
            <IconPlanUpgrade />
          </div>

          <div className=" d-flex justify-content-center">
            <h1 className="mb-32">Please purchase a plan to get access to the dashboard!</h1>
          </div>

          <div className="text-center">
            <a href="/accountsetting/billingsubscription/change">
              <button class="btn-secondary">Go to Dashboard</button>
            </a>
          </div>
        </div>
      </div>
    // </div>
  );
};

export default FirstTimePlan;
