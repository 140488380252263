import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Profile from "./Profile/Profile";
import ProfileEmailVerified from "./Profile/ProfileEmailVerified";
import ProfilePhoneVerified from "./Profile/ProfilePhoneVerified";
import ManageTeams from "./ManageTeams/ManageTeams";
import Preferences from "./Preferences/Preferences";
import PreferencesEmp from "./Preferences/PreferencesEmp";
import Billing from "./Billing/Billing";
import ChangePlan from "./Billing/ChangePlan";
import PlanUpgade from "./Billing/PlanUpgade";
import PlanDowngrade from "./Billing/PlanDowngrade"
import PlanRetained from "./Billing/PlanRetained";
import MakePlanSuccess from "./Billing/MakePlanSuccess";
import RenewalPlanSuccess from "./Billing/RenewalPlanSuccess";
import ManageSubscription from "./Billing/ManageSubscription";
import UpdateCardSuccess from "./Billing/UpdateCardSuccess";
import PlanRetainedCancel from "./Billing/PlanRetainedCancel";
import Users from "./Users/Users";
import { useDispatch } from "react-redux";
import { getUserProfileAsync } from "../../reducers/user-reducers";

import { Constants } from "../../../src/services/Constants";
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import Restricted from "../Restricted/Restricted"

const AccountSettingIndex = (props) => {
  // console.log(props,'propspropspropspropspropsprops');
  const{ isRestricted } = props;
  const [userProfile, setUserProfile] = useState(null);
  const dispatch = useDispatch();
  const stripePromise = loadStripe(Constants?.loadStripe);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    await dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
      }
    });
  };

  console.log(userProfile,'check account setting userProfile');

  return (
    <Elements stripe={stripePromise}>
    <Switch>
      <Route exact path="/accountsetting">
        {isRestricted === false ? (
          <Profile />
        ) : (
          <Restricted userType={userProfile && userProfile.roleName}  />
        )}
        {/* <Redirect to="/accountsetting/profile" /> */}
      </Route>

      <Route exact path="/accountsetting/profile">
        {isRestricted === false ? (
          <Profile />
        ) : (
          <Restricted userType={userProfile && userProfile.roleName}  />
        )}
      </Route>

      <Route exact path="/accountsetting/profile/email">
        <ProfileEmailVerified />
      </Route>

      <Route exact path="/accountsetting/profile/phone">
        <ProfilePhoneVerified />
      </Route>

      {userProfile &&
        (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
          <Route path="/accountsetting/manageteams">
            {isRestricted === false ? (
              <ManageTeams />
            ) : (
              <Restricted userType={userProfile.roleName}  />
            )}
          </Route>
        )}

      
        <Route path="/accountsetting/preferences">
          {isRestricted === false ? (
            <>
              {userProfile && (userProfile.roleName === "Super Admin" || userProfile.roleName === "Admin") ? (
                <Preferences userProfile = {userProfile}/>
              ) : (
                <PreferencesEmp />
              )}
            </>
          ) : (
            <Restricted userType={userProfile && userProfile.roleName}  />
          )}
        </Route>
      
      

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/change">
          <ChangePlan />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/upgrade">
          <PlanUpgade />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/downgrade">
          <PlanDowngrade />
        </Route>
      )}      

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/makeplansuccess">
          <MakePlanSuccess />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/renewalplansuccess">
          <RenewalPlanSuccess />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/managesubscription">
          <ManageSubscription />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/planretained">
          <PlanRetained />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/planretainedcancel">
          <PlanRetainedCancel />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription/updatecardsuccess">
          <UpdateCardSuccess />
        </Route>
      )}

      {userProfile && (userProfile.roleName === "Super Admin" ||
          userProfile.roleName === "Admin") && (
        <Route path="/accountsetting/billingsubscription">
          <Billing />
        </Route>
      )}

      <Route path="/accountsetting/users">
        <Users />
      </Route>
    </Switch>
    </Elements>
  );
};

export default AccountSettingIndex;
