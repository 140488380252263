import React, { useEffect, useState, useContext } from "react";
import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getUserProfileAsync } from "../../reducers/user-reducers";

const AccountSettingNav = () => {
  // const [userProfile, setUserProfile] = useState(null);
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.user.userProfile);

  useEffect(() => {
    getUserProfile();
  }, []);

  const getUserProfile = async () => {
    await dispatch(getUserProfileAsync()).then((res) => {
      // if (res.payload) {
      //   setUserProfile(res.payload);
      // }
    });
  };

  return (
    <ul className="authoringNav">
      <li className="me-4">
        <NavLink className="h6 text-gray-100" to="/accountsetting/profile">
          Profile
        </NavLink>
      </li>

      {userProfile && ((userProfile.roleName === "Super Admin" || userProfile.switchedRoleName === "Admin") && (
          <>

      <li className="me-4">
        <NavLink className="h6 text-gray-100" to="/accountsetting/manageteams">
          Manage Teams
        </NavLink>
      </li>

      {/* {(userProfile.roleName === "Super Admin" || userProfile.switchedRoleName === "Admin") && ( */}

      <li className="me-4">
        <NavLink
          className="h6 text-gray-100"
          to="/accountsetting/billingsubscription"
        >
          Billing & Subscription
        </NavLink>
      </li>

      {/* )} */}
      </>
        )
      )}

      <li className="me-4">
        <NavLink className="h6 text-gray-100" to="/accountsetting/preferences">
          Preferences
        </NavLink>
      </li>
    </ul>

    // <ul className='authoringNav'>
    //     {/* exact */}
    //     <li className='me-4'>
    //         <NavLink className='h6 text-gray-100' to="/accountsetting/profile">Profile</NavLink>
    //     </li>
    //     {/* <li className='me-4'>
    //         <NavLink className='h6 text-gray-100' to="/accountsetting/users">Users</NavLink>
    //     </li> */}
    //     <li className='me-4'>
    //         <NavLink className='h6 text-gray-100' to="/accountsetting/manageteams">Manage Teams</NavLink>
    //     </li>

    //     <li className='me-4'>
    //         <NavLink className='h6 text-gray-100' to="/accountsetting/billingsubscription">Billing & Subscription</NavLink>
    //     </li>

    //     <li className='me-4'>
    //         <NavLink className='h6 text-gray-100' to="/accountsetting/preferences">Preferences</NavLink>
    //     </li>
    // </ul>
  );
};

export default AccountSettingNav;
