import React from "react";

const RadioButton = ({ parentName, text, value, defauluValue }) => {
  return (
    <label className="customRadio">
      <input
        type="radio"
        name={parentName}
        value={value}
        checked={defauluValue === value}
      />
      <span className="customRadioCheck"></span>
      {text && <span className="ps-2">{text}</span>}
    </label>
  );
};

export default RadioButton;
