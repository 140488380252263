import React from "react";
import { useField } from "formik";

const TextInput = ({ label, isRequired, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      {label && (
        <label htmlFor={props.id || props.name}>
          {label}
          {isRequired === true && <span style={{ color: "red" }}>*</span>}
        </label>
      )}

      <input className="text-input" {...field} {...props} />
      {meta.touched && meta.error ? (
        <label className="errorValidation" style={{ color: "red" }}>{meta.error}</label>
      ) : null}
    </>
  );
};

export default TextInput;
