import React, { useRef, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import CheckBoxMd from "../../../components/CheckBox/CheckBoxMd";
import { createReportedAsync } from "../../../reducers/reportedIssue-reducers";
import { Formik } from "formik";
import { useDispatch } from "react-redux";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import TextArea from "../../../components/FormikInputs/TaxtArea";
import ModalDelete from "../../../components/ModalPopup/ModalDeletePopup";
import TextAreaCount from "../../../components/FormikInputs/TextAreaCount";
import Loader from "../../../components/Loader/Loader";

import ReportSuccessModal from "./ReportSuccessModal";

const ReportModal = ({ handleModal, content, onClose }) => {
  const formRef = useRef();
  const [checked, setChecked] = useState([]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isSubmit, setIsSubmit] = useState(false);
  const [submitReportIssue, setSubmitReportIssue] = useState(false);
  const dispatch = useDispatch();
  const [reportSuccess, setReportSuccess] = useState(false);
  const [openReportSuccess, setOpenReportSuccess] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const issueList = [
    {
      issueTypeId: 0,
      title: "Page Content not visible",
      keyName: "pageCotentNotVisible",
      pageCotentNotVisible: false,
      issueTypeDescription:
        "Text is missing in some parts, images broken or page content not loading",
    },
    {
      issueTypeId: 1,
      title: "Sound Problem",
      keyName: "soundProblem",
      soundProblem: false,
      issueTypeDescription:
        "Hard to hear, not matching with the video or missing in some parts",
    },
    {
      issueTypeId: 2,
      title: "Video not playing",
      keyName: "videoNotPlaying",
      videoNotPlaying: false,
      issueTypeDescription: "Blurry, cuts out or is strange in some way",
    },
    {
      issueTypeId: 3,
      title: "Others",
      keyName: "captionOrSubtitle",
      captionOrSubtitle: false,
      issueTypeDescription:
        "",
    },
  ];
  //   setSelectedValues(checkboxes.join(','));
  const reportValidation = (values) => {
    let errors = {};
    if (!checked.length > 0) {
      errors.name = "Issue type  is required!";
    }
    if (!values.description || values.description.trim() === "") {
      errors.name = "Description is required!";
    }
    return errors;
  };

  const handleCheck = (event, e) => {
    const data = {
      issueTypeId: event.issueTypeId,
      issueTypeDescription: event.issueTypeDescription,
    };
    var updatedList = [...checked];
    if (e) {
      updatedList = [...checked, data];
    } else {
      updatedList.splice(checked.indexOf(data.issueTypeId), 1);
    }
    setChecked(updatedList);
  };

  const OnReportSubmit = () => {
    if (isSubmitting) {
      return; 
    }
    setIsSubmitting(true);
    const currentFormData = formRef?.current;
    // Module = 0, Page = 1, Form = 2, Sequence = 3, Process=4
    var errors = reportValidation(currentFormData.values);
    if (
      Object.keys(currentFormData.errors).length === 0 &&
      Object.keys(errors).length === 0
    ) {
      setIsSubmit(true);
      setShowLoader(true);
      const reqBody = {
        description: currentFormData.values?.description,
        issueReportedFor: content.questions
          ? 2
          : content.pageId != null
          ? 1
          : 1,
        contentId: content.id,
        issueTypeDetails: checked,
      };
      dispatch(createReportedAsync(reqBody)).then((res) => {       
        // setReportSuccess(true);
        setIsSubmit(false);
        setShowLoader(false);
        onClose(false);
        // setShowConfirmation(true);
        // ToasterAlertSucces(res, ErrrorMessage.CREATE);
        ToasterAlertSucces(res,"Issue raised successfully!");
      });
    }
  };
  

  return (
    <>
    {showConfirmation ? (
        <Modal
          className="modalDialog modalDialogReport"
          size="sm"
          centered
          show={showConfirmation}
        >
          <ReportSuccessModal handleModal={handleModal} onClose={() => setShowConfirmation(false)} />
        </Modal>
      ) : (
      <Formik
        initialValues={{
          description: "",
          issueReportedFor: "",
          issueTypeDetails: [],
        }}
        onSubmit={OnReportSubmit}
        validate={reportValidation}
        innerRef={formRef}
      >
        {({
          formik,
          errors,
          touched,
          isValidating,
          values,
          validateField,
          handleBlur,
          setFieldValue,
          setTouched,
        }) => {
          return (
            <>
              <Modal.Header
                closeButton
                onClick={() => onClose(false)}
              ></Modal.Header>
              <Modal.Body className="p-0">
                <div className="p-40 pt-4">
                  {showLoader && (
                    <div className="loader-container" style={{left: "0", top: "0"}}>
                      <Loader />
                    </div>
                  )}
                  <span className="h4 font-bold"> Report an issue</span>
                  <p className="text-gray-300 mb-20 pt-1 pb-2">
                    Select issue <span style={{ color: "red" }}>*</span>
                  </p>
                  {issueList.map((item) => (
                    <label className="mb-4 block w-100">
                      <CheckBoxMd
                        text={item.title}
                        name={item.keyName}
                        onChange={(e) => {
                          handleCheck(item, e);
                          setFieldValue(values.issueTypeId, !values.keyName);
                        }}
                      />
                      <p className="text-gray-300 ms-4 ps-2 h6">
                        {item.issueTypeDescription}
                      </p>
                    </label>
                  ))}
 
                  <div className="myTextarea myInput mw-100 pt-2">
                    {/* <label className="h5 mb-2">It is something else </label> */}
                    {touched.description && errors.description && (
                      <label className="text-danger">
                        {errors.description}
                      </label>
                    )}

                   <TextAreaCount
                      className="form-control"
                      label="Description"
                      name="description"
                      rows="4"
                      placeholder={`Describe your issue`}
                      isRequired={true}
                      maxCharCount={150}
                    />
                  </div>
                </div>
              </Modal.Body>
              <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button
                  variant="primary"
                  className="border-0"
                  disabled={Object.keys(reportValidation(values)).length !== 0 || isSubmit}
                  // onClick={() => setSubmitReportIssue(true)}
                  onClick={() => OnReportSubmit()}
                >
                  Submit
                </Button>
              </Modal.Footer>
            </>
          );
        }}
      </Formik>
      )}
      {/* <Modal
        className="modalDialog"
        size="sm"
        centered
        show={submitReportIssue}
        onHide={() => setSubmitReportIssue(false)}
      >
        <ModalDelete
          onCancel={setSubmitReportIssue}
          onConfirm={OnReportSubmit}
          headerLable="Are you sure want to submit Report an issue?"
          chldLable="The data of selected issue will be permanently submitted."
        />
      </Modal> */}
      
    </>
  );
};

export default ReportModal;