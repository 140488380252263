import React from 'react';
import { Row, Col, Button } from "react-bootstrap";
import Select from 'react-select';
import { Link } from 'react-router-dom';

import AccountImg from "../../assets/images/accountImg.jpg";
const ProfessionalDetails = () => {
    const optionsTeam = [
        { value: '1', label: 'Human Resources' },
        { value: '2', label: 'Human Resources 2' },
        { value: '3', label: 'Human Resources 3' }
    ]
    return (
        <div className='page employeeAccount d-flex flex-column'>
            <div className='w-100 flex-grow-1 overflow-auto'>
                <Row className='g-0 d-flex h-100'>
                    <Col className='col-lg-8 flex-grow-1 overflow-auto h-100'>
                        <div className='p-32 pb-4'>
                            <div className='mb-40 d-flex align-items-center gap-3'>
                                <img src={AccountImg} alt="AccountImg" className='employeeAccountImg' />
                                <label className='d-flex align-items-center gap-2'>
                                    <input type="file" className='d-none' />
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.53361 18.3334H14.0361C16.2752 18.3334 17.1677 16.9251 17.2731 15.2084L17.695 8.32508C17.8086 6.52508 16.4132 5.00008 14.6526 5.00008C14.1577 5.00008 13.7034 4.70841 13.4763 4.25841L12.8921 3.05008C12.5189 2.29175 11.5454 1.66675 10.7178 1.66675H8.85995C8.02431 1.66675 7.05074 2.29175 6.67754 3.05008L6.09341 4.25841C5.86624 4.70841 5.41191 5.00008 4.91702 5.00008C3.1565 5.00008 1.76106 6.52508 1.87464 8.32508L2.29652 15.2084C2.39387 16.9251 3.29442 18.3334 5.53361 18.3334Z" stroke="#4A154B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.75 6.66675H11.25" stroke="#4A154B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.99984 14.9999C11.4915 14.9999 12.7082 13.7833 12.7082 12.2916C12.7082 10.7999 11.4915 9.58325 9.99984 9.58325C8.50817 9.58325 7.2915 10.7999 7.2915 12.2916C7.2915 13.7833 8.50817 14.9999 9.99984 14.9999Z" stroke="#4A154B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <h6>Upload</h6>
                                </label>
                            </div>
                            <Row>
                                <Col md={6}>
                                    <div className="input-group myInput mb-32">
                                        <label>Name</label>
                                        <input type="text" className="form-control" value="Alisha Wilson" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="input-group myInput mb-32">
                                        <label>Email Address</label>
                                        <input type="email" className="form-control" value="alisha@gmail.com" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="input-group myInput mb-32">
                                        <label>Company Email Address</label>
                                        <input type="email" className="form-control" value="alisha123@cerebrent.com" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="input-group myInput mb-32">
                                        <label>Phone number</label>
                                        <input type="text" className="form-control" value="9845xxxxx1" />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="input-group mySelect mb-32">
                                        <label>Team</label>
                                        <Select isSearchable={false} className='select w-100'  classNamePrefix="selectCustom" options={optionsTeam} defaultValue={[optionsTeam[0]]} />
                                    </div>
                                </Col>
                            </Row>
                        </div>

                    </Col>

                    <Col className='col-lg-4 flex-grow-1 overflow-auto h-100'>

                        <div className='bg-gray-600 p-32 px-4 h-100 borderLeft2'>
                            <h5 className='mb-32'>Quick Links</h5>
                            <div className='d-inline-block'>
                                <Link className='d-flex gap-2 align-items-center'>
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M16.4917 12.4416C14.775 14.1499 12.3167 14.6749 10.1584 13.9999L6.23337 17.9166C5.95004 18.2083 5.39171 18.3833 4.99171 18.3249L3.17504 18.0749C2.57504 17.9916 2.01671 17.4249 1.92504 16.8249L1.67504 15.0083C1.61671 14.6083 1.80837 14.0499 2.08337 13.7666L6.00004 9.84994C5.33337 7.68327 5.85004 5.22494 7.56671 3.5166C10.025 1.05827 14.0167 1.05827 16.4834 3.5166C18.95 5.97494 18.95 9.98327 16.4917 12.4416Z" fill="#646464" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.7417 14.575L7.65837 16.4916" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M12.0835 9.16675C12.7739 9.16675 13.3335 8.6071 13.3335 7.91675C13.3335 7.22639 12.7739 6.66675 12.0835 6.66675C11.3931 6.66675 10.8335 7.22639 10.8335 7.91675C10.8335 8.6071 11.3931 9.16675 12.0835 9.16675Z" fill="#646464" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span className='h6 text-gray-200'>Reset Password</span>
                                </Link>
                                <div className='my-3 border-bottom'>
                                </div>
                                <Link className='d-flex gap-2 align-items-center'>
                                    <span>
                                        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15 15.7167H14.3667C13.7 15.7167 13.0667 15.975 12.6 16.4417L11.175 17.8501C10.525 18.4917 9.46667 18.4917 8.81667 17.8501L7.39166 16.4417C6.925 15.975 6.28333 15.7167 5.625 15.7167H5C3.61667 15.7167 2.5 14.6084 2.5 13.2417V4.15002C2.5 2.78336 3.61667 1.67505 5 1.67505H15C16.3833 1.67505 17.5 2.78336 17.5 4.15002V13.2417C17.5 14.6001 16.3833 15.7167 15 15.7167Z" fill="#646464" stroke="white" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M5.8335 7.63324C5.8335 6.85824 6.46683 6.22485 7.24183 6.22485C8.01683 6.22485 8.65017 6.85824 8.65017 7.63324C8.65017 9.19991 6.42516 9.36657 5.93349 10.8582C5.83349 11.1666 6.09183 11.4749 6.41683 11.4749H8.65017" fill="#646464" />
                                            <path d="M5.8335 7.63324C5.8335 6.85824 6.46683 6.22485 7.24183 6.22485C8.01683 6.22485 8.65017 6.85824 8.65017 7.63324C8.65017 9.19991 6.42516 9.36657 5.93349 10.8582C5.83349 11.1666 6.09183 11.4749 6.41683 11.4749H8.65017" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M13.367 11.4666V6.70826C13.367 6.49159 13.2253 6.29987 13.017 6.24154C12.8086 6.18321 12.5836 6.26654 12.467 6.44987C11.867 7.41654 11.217 8.51655 10.6503 9.48322C10.5586 9.64155 10.5586 9.84989 10.6503 10.0082C10.742 10.1666 10.917 10.2665 11.1086 10.2665H14.167" fill="#646464" />
                                            <path d="M13.367 11.4666V6.70826C13.367 6.49159 13.2253 6.29987 13.017 6.24154C12.8086 6.18321 12.5836 6.26654 12.467 6.44987C11.867 7.41654 11.217 8.51655 10.6503 9.48322C10.5586 9.64155 10.5586 9.84989 10.6503 10.0082C10.742 10.1666 10.917 10.2665 11.1086 10.2665H14.167" stroke="white" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <span className='h6 text-gray-200'>Create a module</span>
                                </Link>
                            </div>
                        </div>

                    </Col>
                </Row>


            </div>

            <div className='d-flex gap-2 align-items-center btnFooter'>
                <Button variant="secondary" size="sm">
                    Save Changes
                </Button>
                <Button variant="white" size="sm">
                    Cancel
                </Button>

            </div>
        </div>
    );
}

export default ProfessionalDetails;
