import React from "react";
import { useField } from "formik";

const CheckBoxInput = ({ label, value, ...props }) => {
  const [field, meta] = useField(props);
  return (
    <>
      <label className="checkBox md">
        {props?.isRequired === true && <span style={{ color: "red" }}>*</span>}
        <input type="checkBox" {...field} {...props} checked={value} />
        <span className="checkBoxCheck"></span>
        {label && <span className="ms-2">{label}</span>}
      </label>

      {meta.touched && meta.error ? (
        <label className="errorValidation" style={{ color: "red" }}>{meta.error}</label>
      ) : null}
    </>
  );
};

export default CheckBoxInput;
