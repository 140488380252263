import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  getAllProcesses,
  createProcesses,
  updateProcesses,
  deleteProcesses,
  getDropDownList,
  getProcessesDetails,
  bulkDeleteProcesses,
  bulkArchiveProcesses,
  getSequenceList,
  getProcessesDropDownList,
  getProcessesPreview,
  getProcessesFeedbackDetails,
  createProcessesSaveAsDraft,
  getProcessesTemplates
} from "../api/processes-store-api";

const initialState = {
  processes: { records: [] },
  loading: false,
  page: 0,
  pageSize: 10,
  searchString: "",
  orderBy: "",
  isOrderByDesc: true,
  status: [],
  tags: [],
  processesSave: null,
  processesUpdate: null,
  processesDelete: null,
  currentProcess: null,
  dropdownList: [],
  sequenceList: [],
  processesdropdownList: [],
};

export const getProcessesAsync = createAsyncThunk(
  "processes/getProcessesAll",
  async (request) => {
    const response = await getAllProcesses(request);
    // response.data.records.map(function(m,index){m.isSelectd=true; return m;})
    //console.log(response.data);
    return response.data;
  }
);

export const createProcessesAsync = createAsyncThunk(
  "processes/createProcessesAsync",
  async (request) => {
    const response = await createProcesses(request);
    //console.log(response.data);
    return response.data;
  }
);

export const createProcessesSaveAsDraftAsync = createAsyncThunk(
  "processes/createProcessesSaveAsDraftAsync",
  async (request) => {
    const response = await createProcessesSaveAsDraft(request);
    return response.data;
  }
);


export const updateProcessesAsync = createAsyncThunk(
  "processes/updateProcessesAsync",
  async (request) => {
    const response = await updateProcesses(request);
    //console.log(response.data);
    return response.data;
  }
);

export const deleteProcessesAsync = createAsyncThunk(
  "order/deleteProcessesAsync",
  async (request) => {
    const response = await deleteProcesses(request);
    return response.data;
  }
);

export const bulkDeleteProcessesAsync = createAsyncThunk(
  "Processes/bulkDeleteProcessesAsync",
  async (request) => {
    console.log(request);
    const response = await bulkDeleteProcesses(request);
    return response.data;
  }
);

export const bulkArchiveProcessesAsync = createAsyncThunk(
  "Processes/bulkArchiveProcessesAsync",
  async (request) => {
    console.log(request);
    const response = await bulkArchiveProcesses(request);
    return response.data;
  }
);

export const getDropdownListAsync = createAsyncThunk(
  "processes/getDropdownListAsync",
  async () => {
    const response = await getDropDownList();
    return response.data;
  }
);

export const getSequenceListAsync = createAsyncThunk(
  "processes/getSequenceListAsync",
  async () => {
    const response = await getSequenceList();
    return response.data;
  }
);

export const getProcessesDetailsAsync = createAsyncThunk(
  "processes/getProcessesDetailsAsync",
  async (id) => {
    const response = await getProcessesDetails(id);
    return response.data;
  }
);

export const getProcessesPreviewAsync = createAsyncThunk(
  "processes/getProcessesPreviewAsync",
  async (id) => {
    const response = await getProcessesPreview(id);
    return response.data;
  }
);

export const getProcessesFeedbackDetailsAsync = createAsyncThunk(
  "processes/getProcessesFeedbackDetailsAsync ",
  async (id) => {
    const response = await getProcessesFeedbackDetails(id);
    return response.data;
  }
);

export const getProcessesTemplatesAsync = createAsyncThunk(
  "processes/getProcessesTemplates",
  async (request) => {
    const response = await getProcessesTemplates(request)
    return response.data;
  }
);


export const getprocessesDropdownListAsync = createAsyncThunk(
  "processes/getDropdownListAsync",
  async () => {
    const response = await getProcessesDropDownList();
    return response.data;
  }
);


const processesSlice = createSlice({
  name: "processes",
  initialState,
  reducers: {},
  // reducers:{
  //     getPageData: (state,action)=>{
  //         state.notifications = action;
  //     },
  // },
  extraReducers: {
    [getProcessesAsync.pending]: () => {
      console.log("pending");
    },
    [getProcessesAsync.fulfilled]: (state, { payload }) => {
      console.log("full filled", payload);
      return { ...state, processes: payload };
    },
    [getProcessesAsync.rejected]: () => {
      console.log("rejected");
    },
    [getDropdownListAsync.pending]: () => { },
    [getDropdownListAsync.fulfilled]: (state, { payload }) => {
      return { ...state, dropdownList: payload };
    },
    [getSequenceListAsync.fulfilled]: (state, { payload }) => {
      return { ...state, sequenceList: payload };
    },
    [getProcessesTemplatesAsync.fulfilled]: (state, { payload }) => {
      return { ...state, processesTemplate: payload };
    },
    
    [getDropdownListAsync.rejected]: () => { },
    [getProcessesDetailsAsync.pending]: () => { },
    [getProcessesDetailsAsync.fulfilled]: (state, { payload }) => {
      return { ...state, currentProcess: payload };
    },
    [getprocessesDropdownListAsync.fulfilled]: (state, { payload }) => {
      return { ...state, processesdropdownList: payload };
    },
    [getProcessesDetailsAsync.rejected]: () => { },
    // [createProcessesAsync.pending] : () => {
    //   console.log("pending save processes");
    // },
    // [createProcessesAsync.fulfilled] : (state, {payload}) => {
    //   // console.log("full filled",payload);
    //   // return {...state, processesSave : payload}
    //   if (payload.status==200) {
    //     let result = [ "Processes save successfully!", new Date().toLocaleDateString(),payload.data];
    //     return {...state, processesSave : result}
    //   } else {
    //     let result = [ "Unable to save processes!", new Date().toLocaleDateString(),payload.data];
    //     return {...state, processesSave : result}
    //   }
    // },
    // [createProcessesAsync.rejected] : () => {
    //   console.log("rejected save processes");
    // },
    // [updateProcessesAsync.pending] : () => {
    //   console.log("pending update processes");
    // },
    // [updateProcessesAsync.fulfilled] : (state, {payload}) => {
    //   // console.log("full filled",payload);
    //   // return {...state, processesUpdate : payload}
    //   if (payload.status==200) {
    //     let result = [ "Processes update successfully!", new Date().toLocaleDateString(),payload.data];
    //     return {...state, processesUpdate : result}
    //   } else {
    //     let result = [ "Unable to update processes!", new Date().toLocaleDateString(),payload.data];
    //     return {...state, processesUpdate : result}
    //   }
    // },
    // [updateProcessesAsync.rejected] : () => {
    //   console.log("rejected update processes");
    // },
  },
});

//export const { processesAdded, processesUpdated, processesDeleted } = processesSlice.actions;

export default processesSlice.reducer;
