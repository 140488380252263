import React from 'react';
import { Modal, Button } from "react-bootstrap";
const DeletePersonalDataModal = ({ handleModal }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <h3 className='mt-3'>Request personal data delete</h3>
                <p className='text-gray-300 mb-0 mt-2 mb-5'>
                    Content to be added
                </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button variant="white" size="sm" onClick={() => handleModal(false)}>
                    Cancel
                </Button>
                <Button variant="primary" size="sm" className='border-0'>
                    Confirm
                </Button>
            </Modal.Footer>
        </>
    );
}

export default DeletePersonalDataModal;
