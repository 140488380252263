



import React from 'react';
import { Modal, Button } from "react-bootstrap";
import { Row, Col, Container } from "react-bootstrap";
import { Link } from "react-router-dom";
import Search from "../../../components/Search/Search";
import Select from 'react-select';
import { IconHamberger, IconStar } from "../../../icons/Icons";
import CheckBox from "../../../components/CheckBox/CheckBox";
import AccountImg from "../../../assets/images/Account.png";
const ProcessFeedbackModal = ({ openModalFeedbackProcess }) => {

    const optionsDays = [
        { value: '1', label: 'Today' },
        { value: '2', label: 'Tomorrow' },
        { value: '3', label: 'Today' }
    ]
    return (
        <>
            <Modal.Header closeButton className='py-3'>
                <div>
                    <Modal.Title>Feedback</Modal.Title>
                    <ol className="breadcrumb h6 mt-1">
                        <li><Link to="/authoring">Processes</Link></li>
                        <li>HR Onboarding</li>
                    </ol>
                </div>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4 pe-5'>


                <Row className='d-flex h-100 '>

                    <Col className='col-lg-4'>

                        <h5 className='mb-2'>Avg. Rating</h5>

                        <div className='d-flex gap-2 align-items-center mb-5 avgRat'>
                            <h1>4.7</h1>
                            <div className='d-flex gap-1'><IconStar /><IconStar /><IconStar /><IconStar /><IconStar /></div>
                            <p className='text-sm text-gray-200 mb-0'>362 reviews</p>
                        </div>

                        <div className='mainProgressBar mb-5 pb-6'>
                            <div className='d-flex align-items-center gap-2 mb-14'>

                                <p className='text-gray-300 font-medium text-md mb-0'>5</p>
                                <div className='col'>
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{ width: '88%' }}></div>
                                    </div>
                                </div>

                                <p className='font-bold text-md mb-0 percent'>88%</p>

                                <p className='text-gray-300 font-medium text-md mb-0'>203</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-14'>

                                <p className='text-gray-300 font-medium text-md mb-0'>4</p>
                                <div className='col'>
                                    <div className="progress">
                                        <div className="progress-bar" role="progressbar" style={{ width: '15%' }}></div>
                                    </div>
                                </div>

                                <p className='font-bold text-md mb-0 percent'>15%</p>

                                <p className='text-gray-300 font-medium text-md mb-0'>126</p>
                            </div>

                            <div className='d-flex align-items-center gap-2 mb-14'>

                                <p className='text-gray-300 font-medium text-md mb-0'>3</p>
                                <div className='col'>
                                    <div className="progress">
                                        <div className="progress-bar yellow" role="progressbar" style={{ width: '5%' }}></div>
                                    </div>
                                </div>

                                <p className='font-bold text-md mb-0 percent'>5%</p>

                                <p className='text-gray-300 font-medium text-md mb-0'>21</p>
                            </div>

                            <div className='d-flex align-items-center gap-2 mb-14'>

                                <p className='text-gray-300 font-medium text-md mb-0'>2</p>
                                <div className='col'>
                                    <div className="progress yellow">
                                        <div className="progress-bar" role="progressbar" style={{ width: '1%' }}></div>
                                    </div>
                                </div>

                                <p className='font-bold text-md mb-0 percent'>1%</p>

                                <p className='text-gray-300 font-medium text-md mb-0'>2</p>
                            </div>
                            <div className='d-flex align-items-center gap-2 mb-14'>

                                <p className='text-gray-300 font-medium text-md mb-0'>1</p>
                                <div className='col'>
                                    <div className="progress">
                                        <div className="progress-bar orange" role="progressbar" style={{ width: '3%' }}></div>
                                    </div>
                                </div>

                                <p className='font-bold text-md mb-0 percent'>3%</p>

                                <p className='text-gray-300 font-medium text-md mb-0'>10</p>
                            </div>
                        </div>
                        <div className='d-flex gap-4'>
                            <div>
                                <p className='text-md mb-2 text-gray-200'>Total Feedback</p>
                                <h3 className='font-medium'>362</h3>

                            </div>

                            <div>
                                <p className='text-md mb-2 text-gray-200'>Total Comments</p>
                                <h3 className='font-medium'>203</h3>

                            </div>

                            <div>
                                <p className='text-md mb-2 text-gray-200'>Reported Issues</p>
                                <h3 className='font-medium'>10</h3>

                            </div>
                        </div>


                    </Col>
                    <Col className='col-lg-8 d-flex h-100 flex-column'>
                        <div className='d-flex justify-content-between mb-20 align-items-center'>
                            <p className='font-bold mb-0'>Recent Feedback</p>
                            <div className="mySelect topSelect ">
                                <Select isSearchable={false} className="select" options={optionsDays} defaultValue={[optionsDays[0]]} />
                            </div>
                        </div>
                        <div className='feedbackTable tableOuter flex-grow-1 overflow-auto h-100'>
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th><CheckBox text="ALL" /></th>
                                        <th>NAME</th>
                                        <th>RATING</th>
                                        <th>COMMENT</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableImg'><img src={AccountImg} alt="" /></div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <IconStar />
                                                <span>4.5</span>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableName'>
                                                        RR
                                                    </div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>  <div className='d-flex gap-2 align-items-center'>
                                            <IconStar />
                                            <span>4.5</span>
                                        </div></td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableImg'><img src={AccountImg} alt="" /></div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <IconStar />
                                                <span>4.5</span>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableName'>
                                                        RR
                                                    </div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>  <div className='d-flex gap-2 align-items-center'>
                                            <IconStar />
                                            <span>4.5</span>
                                        </div></td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableImg'><img src={AccountImg} alt="" /></div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <IconStar />
                                                <span>4.5</span>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableName'>
                                                        RR
                                                    </div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>  <div className='d-flex gap-2 align-items-center'>
                                            <IconStar />
                                            <span>4.5</span>
                                        </div></td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableImg'><img src={AccountImg} alt="" /></div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <IconStar />
                                                <span>4.5</span>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableName'>
                                                        RR
                                                    </div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>  <div className='d-flex gap-2 align-items-center'>
                                            <IconStar />
                                            <span>4.5</span>
                                        </div></td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableImg'><img src={AccountImg} alt="" /></div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>
                                            <div className='d-flex gap-2 align-items-center'>
                                                <IconStar />
                                                <span>4.5</span>
                                            </div>
                                        </td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                    <tr>
                                        <td align='middle'><CheckBox /></td>
                                        <td>
                                            <Link to={`/authoring/processes/${123}`} className='text-gray-100 d-flex align-items-center gap-2'>
                                                <div className='d-flex align-items-center gap-2'>
                                                    <div className='tableName'>
                                                        RR
                                                    </div>
                                                    <div>
                                                        <p className='text-nowrap mb-0'>Ronald Richards</p>
                                                        <a className='text-primary-200 font-medium' href="mailto:mark.wilson@cerebrent.com">mark.wilson@cerebrent.com</a><br />
                                                        <a className='text-primary-200 font-medium' href="tel:+123 456 7890">+123 456 7890</a>
                                                    </div>
                                                </div>
                                            </Link>
                                        </td>
                                        <td>  <div className='d-flex gap-2 align-items-center'>
                                            <IconStar />
                                            <span>4.5</span>
                                        </div></td>
                                        <td>
                                            <p className='mb-0'>Lorem Ipsum is simply dummy text
                                                of the printing and typesetting.</p>
                                        </td>

                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </Col>
                </Row>


            </Modal.Body>

        </>
    );
}

export default ProcessFeedbackModal;

