import React, { useState, useRef } from 'react';

const OtpInput = ({ length, onComplete, setFieldValue  }) => {
  const [otp, setOtp] = useState(Array(length).fill(''));
  const inputRefs = useRef([]);

  const handleInputChange = (index, value) => {
    if (!isNaN(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);
      setFieldValue("otp", newOtp.join(''))
      if (index < length - 1) {
        inputRefs.current[index + 1].focus();
      }
      
      if (newOtp.every(entry => entry !== '')) {
        onComplete(newOtp.join(''), setFieldValue);
      }
    }
  };

  const handleKeyDown = (index, e) => {
    if (e.key === 'Backspace' && otp[index] === '' && index > 0) {
      const newOtp = [...otp];
      newOtp[index - 1] = '';
      setOtp(newOtp);
      inputRefs.current[index - 1].focus();
      if (newOtp.every(entry => entry === '')) {
        onComplete(newOtp.join(''), setFieldValue);
      }
    }
  };

  return (
    <div className="mb-12 d-flex">
      {otp.map((digit, index) => (
        <>
        <input
          key={index}
          type="text"
          maxLength="1"
          value={digit}
          onChange={(e) => handleInputChange(index, e.target.value)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(ref) => (inputRefs.current[index] = ref)}
          className='text-center'
        />
        {index === 2 && (
            <span className="sepLine mt-3 mb-3"></span>
        )}
        </>
      ))}
    </div>
  );
};

export default OtpInput;