import { http } from "../httpService";

export function getAllProcesses(reqBody) {
  return http.post(`/Processes/All`, reqBody);
}

export function createProcesses(reqBody) {
  return http.post(`/Processes/Create`, reqBody);
}

export function createProcessesSaveAsDraft(reqBody) {
  return http.post(`/Processes/SaveAsDraft`, reqBody);
}

export function updateProcesses(reqBody) {
  return http.post(`/Processes/Update`, reqBody);
}

export function deleteProcesses(request) {
  return http.delete(
    `/Processes/DELETE?id=${request.id}&status=${request.status}`
  );
}

export function bulkDeleteProcesses(request) {
  return http.post(`/Processes/BulkDelete`, request);
}

export function bulkArchiveProcesses(request) {
  return http.post(`/Processes/BulkArchive`, request);
}

export function getDropDownList() {
  return http.get(`/sequence/List`);
}
export function getProcessesDropDownList() {
  return http.get(`/Processes/List`);
}
export function getSequenceList() {
  return http.get(`/sequence/SequenceList`);
}

export function getProcessesDetails(id) {
  return http.post(`/Processes/Get?id=${id}`);
}

export function getProcessesPreview(id) {
    return http.post(`/Processes/Preview/${id}`);
}

export function getProcessesFeedbackDetails(id) {
  return http.get(`/EmployeeFeedbackRating/ProcessesFeedback/${id}`);
}

export function getProcessesTemplates(reqBody) {
  return http.post(`/Processes/Templates`, reqBody);
}



