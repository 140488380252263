import React from 'react';
import { Modal, Button } from "react-bootstrap";
const RetainSamePlanModal = ({ setRetainSamePlan }) => {
    return (
        <>
            <Modal.Header closeButton>
            </Modal.Header>
            <Modal.Body className='pt-4 pb-4'>
                <h3 className='mt-3 pe-4'>Thank you for retaining with your current plan! </h3>
                <p className='text-gray-300 mb-0 mt-2 mb-5'>
                    Your discount coupon will be shared with you shortly !
                </p>
            </Modal.Body>
            <Modal.Footer className="justify-content-end btnFooter gap-2">
                <Button variant="white" size="sm" className='' onClick={() => setRetainSamePlan(false)}>
                    Okay
                </Button>
            </Modal.Footer>
        </>
    );
}

export default RetainSamePlanModal;
