import React, { useState, useEffect, useRef } from "react";
import AccountSettingNav from "../AccountSettingNav";
import { Row, Col, Container, Button, Modal } from "react-bootstrap";
import { IconExclamationLg, IconMicrosoft, IconChecked } from "../../../icons/Icons";
import Search from "../../../components/Search/Search";
import AccountDropdown from "../../../components/AccountDropdown/AccountDropdown";
import ActivityLog from "../../../components/ActivityLog/ActivityLog";
import OwnerImg from "../../../assets/images/Account.png";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import validator from 'validator';
import {
  getUserProfileAsync,
  updateUserProfileAsync,
  getEmpTeamListAsync,
  checkUniquenessCompanyEmailAsync,
  onSendVerificationRequestAsync,
  checkUniquenessPhoneNumberAsync,
} from "../../../reducers/user-reducers";

import TextInput from "../../../components/FormikInputs/TextInput";
import { Formik, Form, Field } from "formik";
import Loader from "../../../components/Loader/Loader";
import ModalResponse from "../../../components/ModalPopup/ModalResponsePopup";
import UpdatePassword from "./UpdatePassword";
import OtpSent from "./OtpSent";
import { Constants } from "../../../services/Constants";
import { fileUpload, generatePresignedUrl } from "../../../api/common-store-api";
import UserImage from "../../../components/UserImage/UserImage";
import DefaultUserImage from "../../../assets/images/defaultUserImg.png";
import PhoneInput from "react-phone-number-input";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";

import { getStorageAsync } from "../../../reducers/subscription-reducers";
import ModalStorageLimitReachedPopup from "../../../components/ModalPopup/ModalStorageLimitReachedPopup";
import { getGUID } from "../../../common";
import RenewalHeader from "./../../Authoring/ContentPreview/RenewalHeader"
import { useDebounce } from "../../../utils/hooks/useDebounce";

const Profile = () => {
  const [userProfile, setUserProfile] = useState({});
  const [profileImage, setProfileImage] = useState(null);
  const dispatch = useDispatch();
  let imgRef = useRef();

  const entities = useSelector((state) => state.user.empTeamList);
  const [formDataReady, setFormDataReady] = useState(false);
  const [editDataReady, setEditDataReady] = useState(false);
  const [isUniqueCompanyEmail, setIsUniqueCompanyEmail] = useState(true);
  const [isUniquePhoneNumber, setIsUniquePhoneNumber] = useState(true);
  const [isCompanyEmail, setIsCompanyEmail] = useState("");
  const [isPhoneNumber, setIsPhoneNumber] = useState("");
  const [titleFocused, setTitleFocused] = useState(false);
  const [phoneFocused, setPhoneFocused] = useState(false);
  const [isUserAdmins, setIsUserAdmins] = useState(false);
  const [isUsernameEmailVerifieds, setIsUsernameEmailVerifieds] = useState(false);
  const [isPhoneNumberVerifieds, setIsPhoneNumberVerifieds] = useState(false);  
  
  const formRef = useRef();
  const saveChangesBtnRef = useRef(null);

  const [responsePopup, setResponsePopup] = useState({});

  const [updatePassword, setUpdatePassword] = useState(false);

  const [optionsTeam, setOptionsTeam] = useState(null);
  const teamIdD = "2c9bc648-3eb5-472d-a15f-28767b385b8b"
  const [name, setName] = useState('');
  const [nameError, setNameError] = useState('');
  const [otpModal, setOtpModal] = useState(false);
  const [verifyRequestType, setVerifyRequestType] = useState(null);

  const { storageData } = useSelector((state) => state.subscription);
  const [isFileUpload, setIsFileUpload] = useState(false);
  const [isStorageLimitReached, setIsStorageLimitReached] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isRequestSend, setIsRequestSend] = useState(false);
  const [teamId, setTeamId] = useState(null);

  useEffect(() => {
    dispatch(getEmpTeamListAsync()).then((res) => {
    });
  }, []);

  useEffect(() => {
    const updTeam = entities.map((obj) => ({
      label: obj.name,
      value: obj.id,
    }));
    setOptionsTeam(updTeam);
  }, [entities]);
  
  useEffect(() => {
    if (otpModal === false) {
      // setTimeout(() => {
        setFormDataReady(false);
        getUserProfile();
      // }, 1000);
    }
  }, [otpModal]);

 
  const getUserProfile = () => {
    dispatch(getUserProfileAsync()).then((res) => {
      if (res.payload) {
        setUserProfile(res.payload);
        setFormDataReady(true);
        const selectedTeam = optionsTeam && optionsTeam.find(item => item.value == res.payload.teamId);
        getPreviewUrl(res.payload.profileImagePath)
        setTeamId(selectedTeam);
      }
    });
  };

  const getPreviewUrl = async (profileImagePath) => {
    const getGuid = getGUID(profileImagePath);
    // console.log(imgRef,'imgRefimgRefimgRef');
    if (getGuid) {
      let previewUrl = await generatePresignedUrl(getGuid);
      if (previewUrl?.data) {
        if(imgRef){
          imgRef.current.src = previewUrl?.data;
        }
      }
    } else {
      if(imgRef){
        imgRef.current.src = DefaultUserImage;
      }
    }
	};

  // const handleNameChange = (event) => {
  //   const inputName = event.target.value;
  //   const pattern = /^[A-Za-z ]*$/;

  //   if (!pattern.test(inputName)) {
  //     setNameError('Employee name should only contain alphabetic characters and spaces');
  //   } else {
  //     setNameError('');
  //     setName(inputName); 
  //   }
  // };

    const isRequiredToValiate = () => {
      return !(userProfile?.roleName == "Super Admin");
    };

    const isFieldVisible = () => {
      return !(userProfile?.roleName == "Super Admin");
    };

    useEffect(() => {
      dispatch(getStorageAsync());
    }, []);
  
    useEffect(() => {
      if (storageData && storageData?.usedStorage >= storageData?.totalStorage) {
        setIsFileUpload(true);
      }
    }, [storageData]);
  
  const uploadProfileImage= async(e)=> {
    e.preventDefault();
    if (isFileUpload) {
      setIsStorageLimitReached(true);
    } else {
      const fileInput = document.createElement('input');
      fileInput.type = 'file';
      fileInput.accept = 'image/png, image/jpeg'; // You can specify accepted file types      
      fileInput.onchange = (event) => {
        const file = event.target.files[0];
        var src = URL.createObjectURL(file);
        imgRef.current.src = src;
        setProfileImage(file);
      };
      fileInput.click();
      // const file = e.target.files[0];
      // var src = URL.createObjectURL(file);
      // imgRef.current.src = src;
      // setProfileImage(file)
    }    
  }

  const sendVerificationLink = () => {
    window.open(Constants.emailVerification);
  };

  const onClose = () => {
    setResponsePopup({
      show: false,
      propmt: "",
      message: "",
      records: [],
    });
  };

  const processResponse = (res) => {
    let prompt = res?.payload ? "Success" : "Error";
    // prompt = "Success";
    let message = res?.payload
      ? "Your details have been saved successfully"
      : "Failed to update profile!";

      setTimeout(() => {
        setResponsePopup({
          show: true,
          propmt: prompt,
          message: message,
          records: [],
          procesFailedError: res?.errors?.message,
        });
      }, 5000);
  };

  const onSelectationChange = (e, colName, setMethod) => {
    setTeamId({
      value: e.value,
      label: e.label,
    });
    if (setMethod) {
      setMethod(colName, e.value);
    }
  };
  function hasNumbersOrSpecialChars(value) {
    const regex = /[!@#$%^&*(),.?":{}|<>0-9]/g;
    return regex.test(value);
  }
  const profileValidation = (values) => {
    let errors = {};
    // const currentValues = formRef?.current;
    // setFormDataReady(true);
    // if (titleFocused) {
    //   errors.title = "";
    // }
    if (!values.title || values.title.trim() === "") {
     errors.title = "Designation is required!";
    }
    // else if (values.title && !isUniqueTitle) {
    //   errors.title = "Title already exists!";
    // }
    if (!values.name || values.name.trim() === "") {
      errors.name = "Name is required!";
    } else if (hasNumbersOrSpecialChars(values.name)) {
      errors.name = "Employee name should not contain numbers or special characters!";
    } else if (values.name !== userProfile?.name) {
      setEditDataReady(true);
    } 
   
    if ((!values.teamId || values.teamId.trim() === "") && isRequiredToValiate()) {
      errors.teamId = "Team is required!";
    } else if ((values.teamId.trim() !== "" && values.teamId !== userProfile?.teamId) && isRequiredToValiate()){
      setEditDataReady(true);
    }

    if (!values.companyEmail || values.companyEmail.trim() === "") {
      errors.companyEmail = "A valid email address is required!";
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(values.companyEmail)) {
      errors.companyEmail = "Invalid email address";
    } else if (values.companyEmail && !isUniqueCompanyEmail) {
      errors.companyEmail = "Company email is already exist!";
    } else if (values.companyEmail !== userProfile?.companyEmail) {
      setEditDataReady(true);
    }

    if (!values.phoneNumber || values.phoneNumber.trim() === "") 
    { errors.phoneNumber = "A valid phone number is required!";
    } else if (!validator.isMobilePhone(values.phoneNumber, 'any', { strictMode: false })) {
      errors.phoneNumber='Invalid phone number.';
    } else if (values.phoneNumber.length < 7 || values.phoneNumber.length > 15) {
      errors.phoneNumber='Phone number should be between 7 and 15 digits.';
    }  else if (values.phoneNumber && !isUniquePhoneNumber) {
      errors.phoneNumber = "Phone number is already exist!";
    } else if (values.phoneNumber !== userProfile?.phoneNumber) {
      setEditDataReady(true);
    }

    // if (!values.organization || values.organization.trim() == "") {
    //   errors.organization = "Organization is required";
    // } else if (values.organization !== userProfile?.organization) {
    //   setEditDataReady(true);
    // }

    // if (!values.website || values.website.trim() == "") {
    //   errors.website = "A valid organization website is required.";
    // } else if (values.website !== userProfile?.website) {
    //   setEditDataReady(true);
    // }

    if (!values.password) {
      errors.password = "Passeord is required";
    } else if (values.password.length < 8) {
      errors.password = "A password is required and it must be at least 8 characters long.";
    } else if (
      !/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/.test(
        values.password
      ) &&
      values.password != "password"
    ) {
      errors.password =
        "Password must contain at least one uppercase letter, one lowercase letter, one number, one special character";
    } else if (values.password !== userProfile?.password) {
      setEditDataReady(true);
    }

    return errors;
  };

  const validateCompanyEmail = async (
    companyEmail,
    validateField,
    touchedFields,
    setTouchedFun
  ) => {
    setTouchedFun({ ...touchedFields, ["companyEmail"]: true });
    validateField("companyEmail");
    if (companyEmail.trim() !== "") {
      const jsonData = userProfile?.id
        ? {
            id: userProfile?.id ?? null,
            identityId: userProfile?.identityId ?? null,
            email: companyEmail,
          }
        : {
          email: companyEmail,
          };

      setIsCompanyEmail(companyEmail);

      await dispatch(checkUniquenessCompanyEmailAsync(jsonData)).then((res) => {
        setIsUniqueCompanyEmail(res?.payload ? true : false);  
        setTitleFocused(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["companyEmail"]: true });
          validateField("companyEmail");
        }, 1);

        if (userProfile?.isUsernameEmailVerified && userProfile?.companyEmail === companyEmail) {
          setIsUsernameEmailVerifieds(true);
          setIsDisabled(false);
        } else if (userProfile?.companyEmail !== companyEmail) {
          setIsUsernameEmailVerifieds(false);
          setIsDisabled(true);
        }

      });
    }
  };
  const debouncedValidateCompanyEmail = useDebounce(validateCompanyEmail, 1000);

  const validatePhoneNumber = async (
    phoneNumber,
    validateField,
    touchedFields,
    setTouchedFun
  ) => {
    setTouchedFun({ ...touchedFields, ["phoneNumber"]: true });
    validateField("phoneNumber");
    // console.log(phoneNumber,'phoneNumber');

    // Remove spaces from the phone number
    const phoneNumberWithoutSpaces = phoneNumber.replace(/\s/g, '');

    if (phoneNumber && phoneNumber.trim() !== "") {
      const jsonData = userProfile?.id
        ? {
          id: userProfile?.id ?? null,
          identityId: userProfile?.identityId ?? null,
          phoneNumber: phoneNumberWithoutSpaces,
        }
        : {
          phoneNumber: phoneNumberWithoutSpaces,
        };

      setIsPhoneNumber(phoneNumberWithoutSpaces);

      await dispatch(checkUniquenessPhoneNumberAsync(jsonData)).then((res) => {
        // console.log(res?.payload,'res?.payload');
        setIsUniquePhoneNumber(res?.payload ? true : false);
        setPhoneFocused(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["phoneNumber"]: true });
          validateField("phoneNumber");
        }, 1);
        
        if (userProfile?.isPhoneNumberVerified && userProfile?.phoneNumber === phoneNumberWithoutSpaces) {
          setIsPhoneNumberVerifieds(true);
          setIsDisabled(false);
        } else if (userProfile?.phoneNumber !== phoneNumberWithoutSpaces) {
          setIsPhoneNumberVerifieds(false);
          setIsDisabled(true);
        }
      });
    }
  };
 const debouncedValidatePhoneNumber = useDebounce(validatePhoneNumber, 1000);

  const onProfileSubmit = async () => {
    const currentFormData = formRef?.current;
    // if (currentFormData.values.status == undefined) {
    //   navigate.push("/authoring/sequences");
    // }
   let profileImg=''
   if (profileImage) {
      const formData = new FormData();
      formData.append("file", profileImage);
      const responseFile = await fileUpload(formData);
      if(responseFile.data){
        profileImg = `${responseFile.data?.fileKey}${responseFile.data?.fileName}`;
        setProfileImage(null)
      }
   }
    var errors = profileValidation(currentFormData.values);
    if (
      Object.keys(currentFormData.errors).length === 0 &&
      Object.keys(errors).length === 0
    ) {
      const reqBody = {
        name: currentFormData.values?.name,
        title: currentFormData.values?.title,
        phoneNumber: currentFormData.values?.phoneNumber,
        // isPhoneNumberVerified:currentFormData.values?.phoneNumber === userProfile.phoneNumber ? userProfile?.isPhoneNumberVerified : false,
        isPhoneNumberVerified:isPhoneNumberVerifieds,    
        companyEmail: currentFormData.values?.companyEmail,
        // isUsernameEmailVerified:userProfile.isUsernameEmailVerified,
        isUsernameEmailVerified:isUsernameEmailVerifieds,
        teamId:
          currentFormData.values?.teamId,
          reportTo:
          currentFormData.values?.reportsToName,
        password:
          currentFormData.values?.password != "password"
            ? currentFormData.values?.password
            : "",
        // organization: currentFormData.values?.organization,
        // website: currentFormData.values?.website,
        // profileImagePath:userProfile.profileImagePath,
        profileImagePath:profileImg ? profileImg : userProfile.profileImagePath,
        numberOfEmployees: currentFormData.values?.numberOfEmployees,
        linkedAccounts: [],
        // profilePicture:userProfile.profileImagePath
        profilePicture:profileImg ? profileImg : userProfile.profileImagePath,
      };
      // setFormDataReady(false);
      // setIsRequestSend(true);
      dispatch(updateUserProfileAsync(reqBody)).then((res) => {
        processResponse(res);
        if (res.payload) {
          setTimeout(() => {
            setFormDataReady(false);
            getUserProfile();
          }, 1000);
          // setUserProfile(res.payload);
          // setFormDataReady(true);
          // setIsRequestSend(false);
        }
      });
    }
  };

  useEffect(() => {
    getUserProfile();
  }, [optionsTeam]);

  useEffect(() => {
    if (userProfile) {
      setIsPhoneNumberVerifieds(userProfile?.isPhoneNumberVerified);
      setIsUsernameEmailVerifieds(userProfile?.isUsernameEmailVerified);
      setIsUserAdmins(userProfile?.isUserAdmin);
      setIsPhoneNumber(userProfile?.phoneNumber);
      setIsCompanyEmail(userProfile?.companyEmail);
      if (userProfile?.isPhoneNumberVerified) {
        setIsDisabled(false);
      } else if (userProfile?.isUsernameEmailVerified) {
        setIsDisabled(false);
      }
    }
  }, [userProfile]);

  const onSendVerificationRequest = async (type) => {
    setIsRequestSend(true);
    setVerifyRequestType(type)
    let email;
    if (type === "Email") {
      // email = userProfile?.companyEmail;
      email = isCompanyEmail
    } else {
      // email = userProfile?.phoneNumber;
      email = isPhoneNumber;
    }
    await dispatch(onSendVerificationRequestAsync(encodeURIComponent(email))).then((res) => {
    if (res.payload) {
      ToasterAlertSucces('res', "OTP sent successfully");
      setOtpModal(true);
      setIsRequestSend(false);
    }
    });
  };

  const onOtpSubmitProfile = async (res, type) => {
    // console.log(userProfile,'OTP userProfile');
    if (res?.payload?.value?.status === 200) {
      setIsDisabled(false);
      // console.log(saveChangesBtnRef.current,'saveChangesBtnRef');
      if (type === "Mobile") {
        setIsPhoneNumberVerifieds(true);
      } else {
        setIsUsernameEmailVerifieds(true);
      }
      if (saveChangesBtnRef.current) {
        // saveChangesBtnRef.current.click();
        setTimeout(() => {
          saveChangesBtnRef.current.click(); // Trigger click event
        }, 0);
        // console.log('Click event triggered on the button');
      }
    }
  };

  console.log(userProfile,'userProfile');
  
  return (
    <div className="pages">
      <RenewalHeader />
      <header className="pageHeader mb-4">
        <Container fluid>
          <Row className="mx-0 align-items-center">
            <Col className="px-0">
              <h3>Account Settings</h3>
            </Col>
            <Col className="px-0 text-end ">
              <div className="d-flex gap-3 align-items-center justify-content-end">
                <AccountDropdown formDataReady={formDataReady}/>
                <ActivityLog />
              </div>
            </Col>
          </Row>
        </Container>
      </header>
      <div className="pageOuter">
        <Container fluid>
          <AccountSettingNav />
          <div className="page profile  d-flex flex-column">
            {isRequestSend && (<Loader/>)}
            {!formDataReady && <Loader></Loader>}
            {formDataReady && (
              <Formik
                initialValues={{
                  id: null,
                  name: userProfile?.name,
                  title: userProfile?.title,
                  phoneNumber: userProfile?.phoneNumber,
                  isPhoneNumberVerified:userProfile?.isPhoneNumberVerified,
                  teamId:userProfile?.teamId!==null ? userProfile?.teamId : teamIdD,
                  reportTo:userProfile?.reportsToName,
                  companyEmail: userProfile?.companyEmail,
                  isUsernameEmailVerified:
                  userProfile?.isUsernameEmailVerified ?? false,
                  password: "password",
                  // organization: userProfile?.organization,
                  // website: userProfile?.website,
                  numberOfEmployees: userProfile?.numberOfEmployees,
                  linkedAccounts: userProfile?.linkedAccounts,
                }}
                onSubmit={onProfileSubmit}
                validate={profileValidation}
                innerRef={formRef}
              >
                {({ values, touched, handleBlur, errors, setFieldValue, validateField, setTouched, resetForm }) => {
                  return (
                    <Form style={{ display: "contents" }}>
                      {/* {console.log(errors.companyEmail, "errors.companyEmail")} */}
                      <div className="w-100 flex-grow-1">
                      <div className="h-100">
                      <div className="pb-0 px-32 pt-32">
                      
                      <div className='d-flex align-items-center gap-3'>
                                <img src={DefaultUserImage} alt="ProfileImg" ref={imgRef} className='employeeAccountImg' />
                                {/* <img src={DefaultUserImage} alt="ProfileImg" className='employeeAccountImg' /> */}
                                {/* <UserImage imgKey={profileImage}/> */}
                                <label className='d-flex align-items-center gap-2 cp'>
                                    <input type="file"  accept="image/png, image/jpeg" className='d-none' onClick={(e) => uploadProfileImage(e)}/>
                                    <span className='d-flex'>
                                        <svg width="1.25rem" height="1.25rem" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M5.53361 18.3334H14.0361C16.2752 18.3334 17.1677 16.9251 17.2731 15.2084L17.695 8.32508C17.8086 6.52508 16.4132 5.00008 14.6526 5.00008C14.1577 5.00008 13.7034 4.70841 13.4763 4.25841L12.8921 3.05008C12.5189 2.29175 11.5454 1.66675 10.7178 1.66675H8.85995C8.02431 1.66675 7.05074 2.29175 6.67754 3.05008L6.09341 4.25841C5.86624 4.70841 5.41191 5.00008 4.91702 5.00008C3.1565 5.00008 1.76106 6.52508 1.87464 8.32508L2.29652 15.2084C2.39387 16.9251 3.29442 18.3334 5.53361 18.3334Z" stroke="#4A154B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M8.75 6.66675H11.25" stroke="#4A154B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                            <path d="M9.99984 14.9999C11.4915 14.9999 12.7082 13.7833 12.7082 12.2916C12.7082 10.7999 11.4915 9.58325 9.99984 9.58325C8.50817 9.58325 7.2915 10.7999 7.2915 12.2916C7.2915 13.7833 8.50817 14.9999 9.99984 14.9999Z" stroke="#4A154B" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>
                                    </span>
                                    <h6>Upload</h6>
                                </label>
                                </div>
                                </div>
                        <Row className="g-0">
                      
                          <Col className="col-12 col-lg-5 flex-grow-1 h-100">
                            <div className="p-32 pb-4">
                      
                              <div className="input-group myInput mb-32">
                                <TextInput
                                  name="name"
                                  label="Name"
                                  className="form-control"
                                  placeholder="Name"
                                  isRequired={true}
                                  // onChange={handleNameChange}
                                  // value={name}
                                />

                                {/* <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        value="Harry Mathews"
                      /> */}
                              </div>
                              <div className="input-group myInput mb-32">
                                <TextInput
                                  name="title"
                                  className="form-control"
                                  label={"Designation"}
                                  placeholder="Designation"
                                  isRequired={true}
                                  disabled={userProfile?.roleName === "Employee" ? true : false}
                                />
                               </div>  
                              <div className="myInput mb-12 verifyedInput phoneCountry">
                                {/* <TextInput
                                  isRequired={true}
                                  name="phoneNumber"
                                  placeholder="Phone Number"
                                  className="form-control"
                                  label={"Phone Number"}
                                /> */}
                                {/* <div className="myInput addUser phoneCountry ee mb-32"> */}
                                {/* <TextInput
                                  name="phoneNumber"
                                  label="Phone number"
                                  className="form-control"
                                  placeholder="Enter phone number"
                                  isRequired={true}
                                /> */} 
                                <label>Phone number<span style={{ color: "red" }}>*</span></label>
                                <PhoneInput
                                  defaultCountry="IN"
                                  international
                                  addInternationalOption={false}
                                  placeholder='Phone Number'
                                  name='phoneNumber'
                                  value={values.phoneNumber}
                                  onChange={e => setFieldValue("phoneNumber", e)}
                                  // onBlur={handleBlur("phoneNumber")}
                                  // onBlur={(event) => {
                                  //   validatePhoneNumber(
                                  //     event?.target?.value,
                                  //     validateField,
                                  //     touched,
                                  //     setTouched
                                  //   );
                                  // }}
                                  onInput={(event) => {
                                    debouncedValidatePhoneNumber(
                                      event?.target?.value,
                                      validateField,
                                      touched,
                                      setTouched
                                    );
                                  }}
                                />
                                {touched.phoneNumber && errors.phoneNumber && (
                                  <label className="errorValidation" style={{ color: "red" }}>
                                    {errors.phoneNumber}
                                  </label>)}
                                {isPhoneNumberVerifieds === true && (
                                <span className="verifiedTag">
                                  <IconChecked/>
                                  Verified
                                </span>
                                )}
                                {/* <label>Phone Number</label>
                      <input
                        type="text"
                        className="form-control"
                        value="9988778767"
                      /> */}
                              </div>

                              {!isPhoneNumberVerifieds && (
                                <>
                                {/* userProfile.phoneNumber === values.phoneNumber ? */}
                                <div className="bg-pastel-300 py-4 px-3 rounded-6 myInputbox">
                                  <div className="d-flex gap-3">
                                    <div className="shrink-0">
                                      <IconExclamationLg />
                                    </div>

                                    <div>
                                      <p className="mb-0 font-medium">
                                        Action Required
                                      </p>
                                      <p className="mb-20">
                                        <span className="text-gray-300">
                                          Verify your phone number{" "}
                                        </span>
                                        {values.phoneNumber}
                                      </p>
                                      <Button
                                        variant="primary" 
                                        size="sm" 
                                        onClick={() => onSendVerificationRequest("Mobile")}
                                        disabled={errors.phoneNumber !== undefined && errors.phoneNumber !== ""}
                                        className="opecity-50"
                                      >
                                        Send verification code
                                      </Button>
                                    </div>
                                  </div>
                                </div>
                                <label className="errorValidation" style={{ color: "red" }}>Phone number verification is required!</label>
                                {/* :null */}
                                </>
                              )}
                              
                              {/* <div className="mwidth-440 ">
                                <div className="mb-32 pb-32 borderBottom">
                                  <p className="h6 mb-20">Linked accounts</p>
                                  <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="tableName">HS</div>
                                      <p className="mb-0 text-gray-200 font-medium">
                                        harry@gmail.com
                                      </p>
                                    </div>

                                    <h6>
                                      <a className="text-primary-200">Unlink</a>
                                    </h6>
                                  </div>

                                  <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="tableName">HS</div>
                                      <p className="mb-0 text-gray-200 font-medium">
                                        harrymathews@gmail.com
                                      </p>
                                    </div>

                                    <h6>
                                      <a className="text-primary-200">Unlink</a>
                                    </h6>
                                  </div>

                                  <div className="d-flex justify-content-between mb-4">
                                    <div className="d-flex align-items-center gap-2">
                                      <div className="tableName">
                                        <IconMicrosoft />
                                      </div>
                                      <p className="mb-0">
                                        <a className="text-primary-200 font-bold">
                                          Connect your microsoft account
                                        </a>
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div> */}
                              <div className="mt-32">
                                <p className="h6 mb-20 font-medium">Password</p>

                                <div className="input-group myInput mb-12 d-flex passDisable">
                                  {/* <TextInput
                                    // isRequired={true}
                                    type="password"
                                    name="password"
                                    placeholder="Password"
                                    className="form-control p-0 border-0 passInput"
                                    label={"Password"}
                                    isDisabled="true"
                                  /> */}
                                  {/* <input
                                    type="password"
                                    className="form-control p-0 border-0"
                                    value="9988778767"
                                  /> */}
                                  <h6>
                                    <a
                                      className="text-primary-200"
                                      onClick={() => {
                                        setUpdatePassword(true);
                                      }}
                                    >
                                      <u>Update Password</u>
                                    </a>
                                  </h6>
                                </div>
                              </div>
                            </div>
                          </Col>
                      
                          <Col className="col-12 col-lg-5 flex-grow-1 h-100">
                          
                            <div className="p-32 h-100 ">
                              {isFieldVisible() && (
                                <>
                                {userProfile?.reportsToName && (
                                <div className="input-group myInput mb-32">
                                                          
                              <TextInput
                                  isRequired={false}
                                  name="reportTo"
                                  placeholder="report to"
                                  className="form-control w-100 mb-1"
                                  disabled={true}
                                  label={"Reporting to"}
                                  value={userProfile?.reportsToName}
                                  
                                />
                          
                            </div>
                          )}

                            <div className="form-group mySelect mb-32">
                              <label>
                              Team
                              </label>
                              <Select
                                isSearchable={false}
                              
                                className="select"
                                options={optionsTeam}
                                // classNamePrefix="recatMySel"
                                classNamePrefix="selectCustom"
                                name="teamId"
                                isRequired={true}
                                isDisabled={
                                  userProfile?.roleName === "Employee" ?? true
                                }
                                defaultValue={teamId}
                                value={teamId}
                                onChange={(e) =>
                                  onSelectationChange(
                                    e,
                                    "teamId",
                                    setFieldValue
                                  )
                                }
                              />
                              {/* <select>
                                <option>1</option>
                              </select> */}
                          
                            </div>
                            </>
                            )}
                                  
                            <div className="input-group myInput verifyedInput">
                                 {/*<div className="d-flex justify-content-between w-100">
                                  <h6>
                                    <a className="text-primary-200">Change</a>
                                  </h6> 

                                 <label></label>
                                 <h6>
                                    <a className="text-primary-200">Change1</a>
                                  </h6>
                                </div>
                                {/* <input
                                  type="email"
                                  className="form-control w-100 mb-2"
                                  value="harry@gmail.com"
                                /> */}
                                <TextInput
                                  isRequired={true}
                                  name="companyEmail"
                                  placeholder="Email"
                                  className="form-control w-100 mb-1"
                                  disabled={!userProfile?.isUserAdmin}
                                  label={"Email address"}
                                  // onBlur={(event) => {
                                  //   validateCompanyEmail(
                                  //     event?.target?.value,
                                  //     validateField,
                                  //     touched,
                                  //     setTouched
                                  //   );
                                  // }}
                                  onInput={(event) => {
                                    debouncedValidateCompanyEmail(
                                  event?.target?.value,
                                  validateField,
                                  touched,
                                  setTouched
                                );
                              }}
                                />
                                {isUsernameEmailVerifieds === true && (
                                <span className="verifiedTag">
                                {/* <span className="verifiedTag"></span> */}
                                  <IconChecked/>
                                  Verified
                                </span>
                                )}
                                  </div>
                                  <div className="myInputbox">
                                <p className="text-md w-100 mt-2">
                                  Note:{" "}
                                  <span className="text-gray-200">
                                    This email address will be notified in case
                                    of password updates
                                  </span>
                                </p>
                                {isUserAdmins === true && isUsernameEmailVerifieds === false && (
                                  <div className="">
                                    <div className="bg-pastel-300 py-4 px-3  rounded-6 ">
                                      <div className="d-flex gap-3">
                                        <div className="shrink-0">
                                          <IconExclamationLg />
                                        </div>
                                        <div>
                                          <p className="mb-0 font-medium">
                                            Action Required
                                          </p>
                                          <p className="mb-20">
                                            <span className="text-gray-300">
                                              Verify your email address
                                            </span>{" "}
                                            {isCompanyEmail}
                                          </p>
                                          <Button
                                            variant="primary"
                                            size="sm"
                                            // onClick={sendVerificationLink}
                                            className="opecity-50"
                                            onClick={() => onSendVerificationRequest("Email")}
                                            disabled={errors.companyEmail !== undefined && errors.companyEmail !== ""}
                                          >
                                            Send verification code
                                          </Button>
                                        </div>
                                      </div>
                                    </div>
                                    <label className="errorValidation" style={{ color: "red" }}>Email address verification is required!</label>
                                  </div>
                                )}
                                </div>
                            
                              </div>
                   
                            {/* <div className="bg-gray-600 p-32 px-4 h-100 borderLeft2">
                    <h5 className="mb-4">Organization</h5>
                    <div className="mb-32">
                      <p className="h6 text-gray-300 mb-2">Name</p>

                      <div className="d-flex gap-2 align-items-center">
                        <div>
                          <img src={nlogoImg} alt="" />
                        </div>
                        <p className="font-medium mb-0">Evantiv Circles Role</p>
                      </div>
                    </div>

                    <div className="mb-32">
                      <p className="h6 text-gray-300 mb-2">
                        Company Administrator
                      </p>

                      <div className="d-flex gap-2 align-items-center">
                        <div>
                          <img src={clogoImg} alt="" />
                        </div>
                        <p className="font-medium mb-0">
                          Mckinsey &amp; Company
                        </p>
                      </div>
                    </div>
                  </div> 
                            <div className="bg-gray-600 p-32 py-40 h-100 borderLeft2 overflow-auto">
                              {/* <h5 className="mb-4">Organization</h5> 
                              <div className="input-group myInput mb-32">
                                <TextInput
                                  name="organization"
                                  className="form-control"
                                  label={"Organization"}
                                  placeholder="Organization"
                                />
                                {/* <label>Organization</label>
                                <input type="text" className="form-control" value="XYZ" /> 
                              </div>

                              <div className="input-group myInput mb-32">
                                <TextInput
                                  isRequired={true}
                                  name="website"
                                  className="form-control"
                                  label={"Website"}
                                  placeholder="Website"
                                />
                                {/* <label>Website</label>
                                <input
                                  type="text"
                                  className="form-control"
                                  value="www.xyz.com"
                                /> 
                              </div>

                              <div className="input-group myInput mb-32">
                                <TextInput
                                  isRequired={true}
                                  name="numberOfEmployees"
                                  className="form-control"
                                  label={"Number of employees"}
                                  placeholder="Number of employee"
                                />
                                {/* <label>Number of employees</label>
                                <input type="text" className="form-control" value="123" /> 
                              </div>

                              <div className="rounded-6 p-4 text-center boxShadow mb-32 bg-base-100">
                                <div className="d-flex justify-content-center mb-20">
                                  <div className="headImg moduleImg">
                                    <img src={OwnerImg} alt="" />
                                  </div>
                                  <div
                                    className="headImg moduleImg"
                                    style={{ zIndex: "3" }}
                                  >
                                    <img src={OwnerImg} alt="" />
                                  </div>
                                  <div className="headImg moduleImg">
                                    <img src={OwnerImg} alt="" />
                                  </div>
                                </div>

                                <h4 className="mb-12">
                                  Help others in your organization benefit from
                                  Circles!
                                </h4>
                                <p className="h6 mb-20 text-gray-200">
                                  Circles is great for employee onboarding,
                                  familiarizing new clients with your systems
                                  and processes, documenting compliance training
                                  and certifications, and more! share circles
                                  with others in the organization.
                                </p>

                                <div className="input-group myInput mb-4">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="harry@gmail.com"
                                  />
                                </div>
                                <Button
                                  variant="primary"
                                  size="lg"
                                  className="w-100"
                                >
                                  Share
                                </Button>
                              </div>
                            </div>*/}
                          </Col>
                        </Row>
                        </div>
                      </div>
                      {formDataReady && (
                        <div className="d-flex gap-20 align-items-center btnFooter">
                          <Button
                            variant="secondary"
                            ref={saveChangesBtnRef}
                            type="submit"
                            disabled={
                              (Object.keys(profileValidation(values)).length !==
                                0 || !formDataReady) || isDisabled
                            }
                            onClick={() => onProfileSubmit()}
                          >
                            Save Changes
                          </Button>
                          {/* <Button variant="secondary">Save Changes</Button> */}
                          <Button variant="white"
                             onClick={() =>  resetForm()} 
                          >Cancel</Button>
                        </div>
                      )}
                    </Form>
                  );
                }}
              </Formik>
            )}
          </div>
        </Container>
      </div>

      <Modal
        className="uPass"
        size="md"
        centered
        show={responsePopup?.show || updatePassword || otpModal}
      >
        {responsePopup?.show && (
          <ModalResponse data={responsePopup} onClose={onClose} />
        )}
        {updatePassword && (
          <UpdatePassword
            onClose={setUpdatePassword}
            isPasswordNotSet={userProfile?.isPasswordNotSet}
            oldPasswords={userProfile?.password}
          />
        )}
        {otpModal && (
          <OtpSent
            onClose={setOtpModal}
            // email={verifyRequestType === "Mobile" ? userProfile?.phoneNumber : userProfile?.companyEmail }
            email={verifyRequestType === "Mobile" ? isPhoneNumber : isCompanyEmail }
            type ={verifyRequestType}
            isOTP={otpModal}
            onSendVerificationRequest={onSendVerificationRequest}
            getUserProfile={getUserProfile}
            onOtpSubmitProfile={onOtpSubmitProfile}
          />
        )}
      </Modal>

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={isStorageLimitReached}
      >
        <ModalStorageLimitReachedPopup onClose={setIsStorageLimitReached} />
      </Modal>
    </div>
  );
};

export default Profile;
