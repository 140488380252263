import React from "react";
import { IconTopSearch } from "../../icons/Icons";

const Search = ({ onBlur, placeHolder, handleSearch, searchString, onKeyPress, title }) => {
  const onInputChange = (value) => {
    if (onBlur) {
      onBlur(value);
    }
  };
  return (
    <form className= {`input-group myInput hoverSerch myInputSearch ${title ?'w-100':''} `}>
      <input
        type="text"
        className="form-control"
        placeholder={placeHolder}
        // onBlur={(e) => onInputChange(e.target.value)}
        onChange={(e) => {
          onInputChange(e.target.value)
          handleSearch && handleSearch(e)
        }}
        value={searchString}
        onKeyPress={onKeyPress}
      />
      <IconTopSearch />
    </form>
  );
};

export default Search;