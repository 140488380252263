import React, { useState, useRef, useEffect } from "react";
import { Row, Col, Container, Modal, Button } from "react-bootstrap";
import ReportModal from "./ReportModal";
import { Link, useLocation, useHistory } from "react-router-dom";
import VideoCover from "../../../assets/images/videoCover.jpg";
import {
  IconExit,
  IconSkip,
  IconBreadcrumArrow,
  IconZoom,
  IconPPT,
  IconDownload,
  IconReportIssue,
  IconUploadFile,
} from "../../../icons/Icons";
import audiodesign from "../../../assets/images/audiodesign.png";
import Header from "./Header";
import ReactPlayer from "react-player";
import {
  generatePresignedUrl,
  bulkGeneratePresignedUrl,
} from "../../../api/common-store-api";
import { useSelector } from "react-redux";
import { getGUID } from "../../../common";

const Video = ({ actionReport, contentUrl, slideData, setContent }) => {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const userRole = useSelector(
    (state) => state.user.currentUser?.roles[0].name
  );
  const [contentUrlData, setContentUrlData] = useState(null);
  const arrayDownloadableFiles = slideData?.downloadableFile;
  const [downloadableFileURLs, setDownloadableFileURLs] = useState([]);
  const [isFullScreen, setIsFullScreen] = useState(false);

  const target = useRef(null);
  const playerRef = useRef();
  const [state, setState] = useState({
    isPlaying: false,
    isSeeking: true,
    played: 42.675967,
  });
  
  const [isShown, setOpen] = useState(false);
  const [videoSize, setVideoSize] = useState({
    width: 0,
    height: 0,
  });

  const ImgRef = useRef();
  const [seconds, setSeconds] = useState(0);

  const [current, setCurrent] = useState(0);
  const length = ImageData.length;

  function nextSlide() {
    setCurrent(current === length - 1 ? 0 : current + 1);
  }

  function prevSlide() {
    setCurrent(current === 0 ? length - 1 : current - 1);
  }

  const handleProgress = (secs) => {
    setSeconds(secs);
  };

  const handleIsPlaying = () => {
    setOpen(true);
    setState({ ...state, isPlaying: !state.isPlaying });
    var x = JSON.parse(localStorage.getItem("preview"));
    // alert(Number(x.duration).toFixed(2));
    playerRef.current.seekTo(parseFloat(x.duration).toFixed(2));
  };
  const handleSeekMouseDown = (e) => {
    setState({ ...state, isSeeking: true });
  };

  const handleSeekChange = (e) => {
    console.log(e.target.value);
    setState({ ...state, played: parseFloat(e.target.value) });
  };

  const handleSeekMouseUp = (e) => {
    setOpen(true);
    setState({ ...state, isPlaying: !state.isPlaying });
    var x = JSON.parse(localStorage.getItem("preview"));
    playerRef.current.seekTo(parseFloat(22.7));
  };

  var x = JSON.parse(localStorage.getItem("preview"));

  localStorage.setItem(
    "preview",
    JSON.stringify({
      duration: playerRef.current !== undefined ? seconds : x?.duration,
    })
  );

  const getPreviewUrl = async () => {
    const getGuid = getGUID(contentUrl);
    let previewUrl = await generatePresignedUrl(getGuid);
    if (previewUrl?.data) {
      setContentUrlData(previewUrl.data);
    }
  };
  const handleCloseFullScreen = () => {
    setIsFullScreen(false); // Set full-screen mode to false
  };

  const handleShow = () => {
    setShow(true);
  };

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    getPreviewUrl();
    setTimeout(() => {
      setVideoSize({
        width: ImgRef?.current?.width,
        height: ImgRef?.current?.height,
      });
    }, 300);
  }, []);
  const handleIsFullScreen = () => {
    setIsFullScreen(true);
    // setOpen(true);
    // setState({ ...state, isPlaying: !state.isPlaying });
    // var x = JSON.parse(localStorage.getItem("preview"));
    // playerRef.current.seekTo(parseFloat(x.duration).toFixed(2));
  };

  return (
    <div className="pages">
      {/* <Header nextSlide={nextSlide} prevSlide={prevSlide}/> */}
      {/* <div className="position-relative" style={{ top: "-1px" }}>
        <div
          className="progress tableProgress bg-base-100 "
          style={{ height: "2px" }}
          ref={target}
          // onClick={handleSeekMouseUp}
        >
          <div
            className="progress-bar bg-primary-200"
            role="progressbar"
            style={{ width: "21%" }}
          ></div>
        </div>
      </div> */}

      <div className="pageOuter pb-0">
        <Row className="g-0">
          <Col className="col-lg-12 bg-base-100">
            <div className="videoPreview rounded-6 overflow-hidden">
              <ReactPlayer
                ref={playerRef}
                url={contentUrlData}
                className="react-player"
                playing={state.isPlaying}
                width={videoSize.width ? videoSize.width : "100%"}
                height={videoSize.height ? videoSize.height : "100%"}
                played={state.played}
                controls={true}
                config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload nofullscreen",
                    },
                  },
                  attributes: {
                    disablePictureInPicture: true, // Disable PiP
                  },
                }}
              />
              <>
                {/* <ReactPlayer
                  ref={playerRef}
                  url="//s3.envoy.rocks/bothrs/goud-design-sprint/goud/LhgEcS_GOUD+PROTOTYPE+SHOWCASE.mp4"
                  className="react-player"
                  playing={state.isPlaying}
                  width={videoSize.width ? videoSize.width : "100%"}
                  height={videoSize.height ? videoSize.height : "100%"}
                  played={state.played}
                  controls={true}
                  onProgress={(e) => handleProgress(e.playedSeconds)}
                /> */}
              </>

              
              
                  {/* <img ref={ImgRef} src={VideoCover} alt="" className='w-100 rounded-6' /> */}
                  <div className="zoomIcon">
                    <a onClick={handleIsFullScreen}>
                      {/* <a
                      video-modal=""
                      target=""
                      href="https://youtu.be/haerYNsRhEA"
                    > */}
                      <IconZoom />
                    </a>
                  </div>
                  {/* {!state.isPlaying ? (
                 
                  <button
                    class="play-icon"
                    onClick={() => {
                      handleIsPlaying();
                      // handleShow();
                    }}
                  >
                    <svg
                      className="play-btn"
                      viewBox="0 0 74 74"
                      width="74"
                      height="74"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle
                        cx="37"
                        cy="37"
                        r="34"
                        fill="rgba(0, 0, 0, 0.7)"
                      />
                      <polygon points="30,24 30,50 54,37" fill="#fff" />
                    </svg>

                  
                    <svg class="play-btn" viewBox="0 0 74 74">
                      <path
                        d="M28 36.6911V31.1911C28 26.7711 29.67 25.9813 33.5 28.1913L38.05 30.8911L42.5 33.6912C46.33 35.9012 45.83 37.4812 42 39.6912L38.05 42.4911L33.5 45.1912C29.67 47.4012 28 46.6112 28 42.1912V36.6911Z"
                        fill="#1A1A1A"
                      ></path>
                    </svg>
                  </button>
                
              ) : null} */}
            </div>
          </Col>
        </Row>
      </div>
      {isFullScreen && (
        <Modal
          show={isFullScreen}
          // onHide={handleCloseFullScreen}
          centered
          size="lg"
          dialogClassName="full-screen-modal"
        >
          <Modal.Header closeButton onClick={handleCloseFullScreen}>
            {/* <Modal.Title>Full-Screen Video</Modal.Title> */}
          </Modal.Header>
          <Modal.Body>
            <ReactPlayer
              url={contentUrlData}
              className="react-player"
              playing={state.isPlaying}
              width="100%"
              height="100%"
              controls={true}
              config={{
                  file: {
                    attributes: {
                      controlsList: "nodownload",
                    },
                  },
                  attributes: {
                    disablePictureInPicture: true,
                  },
                }}
            />
          </Modal.Body>
        </Modal>
      )}
    </div>
  );
};

export default Video;
