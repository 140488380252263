
import React from 'react';
import { Modal, Button } from "react-bootstrap";
import {
    IconleftArrow,
    IconrightArrow,
  } from "../../icons/Icons";
const ModalFormView = ({ handleModal, onClosed }) => {
    return (
        <>
            <Modal.Header className="d-flex justify-content-start" closeButton onClick={() => onClosed(false)}>
            <Modal.Title>All Responses</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div className=''>
                    <div className='d-flex justify-content-between'>
                        <h5 className='formQus font-normal text-gray-100'><span className='count'>1.</span>
                            Enter your Full Name
                            <span className='d-block text-xs text-gray-300'>200 Responses</span>
                        </h5>
                        <ul>
                            <li className='text-sm pageCount'>
                            <button className="btn btn-sm pBtn me-2">
                     <IconleftArrow/>
                     </button>
                            Question<span>1</span>of 100
                     <button className="btn btn-sm pBtn ms-2">
                     <IconrightArrow/>
                     </button>
                            </li>
                        </ul>
                    </div>
                    <div className="responseList">
                        <ul className="p-0 m-0">
                            <li className="bg-gray-600 text-gray-200 h5">Jenny Wilson</li>
                            <li className="bg-gray-600 text-gray-200 h5">Robert Fox</li>
                            <li className="bg-gray-600 text-gray-200 h5">Jenny Wilson</li>
                            <li className="bg-gray-600 text-gray-200 h5">Robert Fox</li>
                            <li className="bg-gray-600 text-gray-200 h5">Jenny Wilson</li>
                            <li className="bg-gray-600 text-gray-200 h5">Robert Fox</li>
                            <li className="bg-gray-600 text-gray-200 h5">Jenny Wilson</li>
                            <li className="bg-gray-600 text-gray-200 h5">Robert Fox</li>
                            <li className="bg-gray-600 text-gray-200 h5">Jenny Wilson</li>
                
                        </ul>
                    </div>     
                </div>
            </Modal.Body>
            <Modal.Footer className="justify-content-start btnFooter">
        <Button
          variant="secondary"
          size="sm"
          onClick={() => onClosed(false)}
        >
          Done
        </Button>
      </Modal.Footer>
        </>
    );
}

export default ModalFormView;
