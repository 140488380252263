import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container } from "react-bootstrap";
import { IconPlus, IconCalendar, IconRestrict } from "../../../icons/Icons";
import OwnerImg from "../../../assets/images/Account.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { Modal, Button } from "react-bootstrap";
import { useHistory, useLocation } from "react-router-dom";
import TagsInput from "../../../components/TagsInput/TagsInput";
import ContentDropdown from "../../../components/ContentDropdown/ContentDropdown";
import {
  createProcessesAsync,
  updateProcessesAsync,
  getDropdownListAsync,
  createProcessesSaveAsDraftAsync,
} from "../../../reducers/processes-reducers";
import {
  checkUniquenessAsync,
  checkUniquenessTempAsync,
} from "../../../reducers/module-reducers";
import { useDispatch, useSelector } from "react-redux";
import PageHeaderSection from "../../../components/PageHeader/PageHeaderSection";
import TextArea from "../../../components/FormikInputs/TaxtArea";
import TextInput from "../../../components/FormikInputs/TextInput";
import CheckBoxInput from "../../../components/FormikInputs/CheckBoxInput";
import { Formik, Form, Field } from "formik";
import ContentView from "../../../components/ContentView/ContentView";
import UserImageList from "../../../components/UserImageList/UserImageList";
import Loader from "../../../components/Loader/Loader";
import ToasterAlertSucces from "../../../helper/ToasterAlertSucces";
import { ErrrorMessage } from "../../../helper/ErrorMessage";
import OwnerImgCount from "../OwnerImgCount";
import MultiSelectDropdown from "../../../components/MultiSelect/MultiSelect";
import {
  getTeamsAsync,
  getUsersAsync,
  getRolesAsync,
  getUserUsageAsync,
} from "../../../reducers/user-reducers";
import * as moment from "moment";
import ModalMessageAlertPopup from "../../../components/ModalPopup/ModalMessageAlertPopup";
import { optionsTime } from "../../../common";
import Select from "react-select";
import RestrictedPlan from "../../AccountSetting/Billing/RestrictedPlan";
import {
  getSubscriptionAsync,
  getPlansAsync,
} from "../../../reducers/subscription-reducers";
import RestrictedModalPopup from "../../../components/ModalPopup/RestrictedModalPopup";
import { useDebounce } from "../../../utils/hooks/useDebounce";

const CreateProcesses = () => {
  const location = useLocation();
  const dragItem = useRef();
  const dragOverItem = useRef();
  const { operationType, redirectTo } = location.state;
  const navigate = useHistory();
  const [processesData, setProcessesData] = useState(null);
  const [content, addContentClick] = useState(false);
  const [formDataReady, setFormDataReady] = useState(false);
  const [dropdownList, adddropdownList] = useState([]);
  const [isCheckFirstTitleDuplicate, setIsCheckFirstTitleDuplicate] =
    useState(true);
  const [isUniqueTitle, setIsUniqueTitle] = useState(true);
  const [titleFocused, setTitleFocused] = useState(false);
  const [isSubmittted, setIsSubmittted] = useState(false);

  const imageList = [{ OwnerImg: OwnerImg }, { OwnerImg: OwnerImg }];
  const dispatch = useDispatch();
  const formRef = useRef();
  const [optionsTeam, setOptionsTeam] = useState([]);
  const [restrictModal, setRestrictModal] = useState(false);
  const [showMultiSelect, setShowMultiSelect] = useState(false);
  const teamsList = useSelector((state) => state.user.teams);
  const [roleId, setRoleId] = useState(null);
  const [pageSize, setPageSize] = useState(100000);
  const entities = useSelector((state) => state.user.users);
  const roleList = useSelector((state) => state.user.roles);
  const [optionsUser, setOptionsUser] = useState([]);
  const [showAlertMessagePopup, setShowAlertMessagePopup] = useState(false);
  const [hasUnpublishedContent, setHasUnpublishedContent] = useState(false);
  const [isButtonDisabled, setButtonDisabled] = useState(false);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [restrictedTeam, setRestrictedTeam] = useState([]);
  const [restrictedEmployee, setRestrictEmployee] = useState(false);
  const [recommendedTimeDurationType, setRecommendedTimeDurationType] =
    useState(optionsTime[0]);
  const [
    reminderRecommendedTimeDurationType,
    setReminderRecommendedTimeDurationType,
  ] = useState(optionsTime[0]);
  const [updatedList, setUpdatedList] = useState([]);
  const [minDate, setMinDate] = useState(new Date());
  const [maxDate, setMaxDate] = useState(new Date());
  const [expiredRequired, setExpiredRequired] = useState(false);

  const [isRestricted, setIsRestricted] = useState(false);
  const [restrictedPopUp, setRestrictedPopUp] = useState(false);
  const [userUsage, setUserUsage] = useState({});
  const subscriptionData = useSelector((state) => state.subscription);
  const { plansData } = useSelector((state) => state.subscription);
  const [flag, setFlag] = useState(false);
  const [loadTeamsList, setLoadTeamsList] = useState(true);

  useEffect(() => {
    if (operationType === "Add") {
      getUserUsage();
      dispatch(getSubscriptionAsync());
      dispatch(getPlansAsync());
    }
  }, [operationType]);

  useEffect(() => {
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (operationType === "Add") {
      if (hasSubscriptions && hasItems) {
        const basePlan = subscriptionData?.subscriptionData?.plans.filter(
          (plan) => plan.statementDescriptor === "base_plan"
        );
        const planProcess =
          basePlan && basePlan?.length > 0
            ? parseInt(basePlan[0]?.metaData?.process)
            : 0;
        console.log(planProcess, "planProcess");
        console.log(userUsage, "userUsage");
        setIsRestricted(
          planProcess === -1
            ? false
            : userUsage?.processCount >= planProcess
            ? true
            : false
        );
      } else {
        setIsRestricted(true);
      }
    }
  }, [subscriptionData, plansData, userUsage, operationType]);

  const getUserUsage = () => {
    dispatch(getUserUsageAsync()).then((res) => {
      if (res.payload) {
        setUserUsage(res.payload);
      }
    });
  };

  // console.log(updatedList, 'updatedList');

  useEffect(() => {
    if (restrictedEmployee === true) {
      // console.log(restrictedTeam,'restrictedTeamrestrictedTeamrestrictedTeam');
      // Get the last object in the array
      const lastObject =
        restrictedTeam && restrictedTeam[restrictedTeam?.length - 1];
      // console.log(lastObject,'lastObject');
      if (lastObject) {
        const selectedUser = optionsUser
          ? optionsUser.filter((user) => user.teamId === lastObject.value)
          : [];
        // console.log(selectedUser,'selectedUser');
        // console.log(selectedUsers,'selectedUsers');

        // Filter out objects from selectedUsers that already exist in selectedUser
        const filteredSelectedUsers = selectedUsers.filter(
          (user) => !selectedUser.some((u) => u.value === user.value)
        );

        // Append the filteredSelectedUsers to selectedUser
        setSelectedUsers([...selectedUser, ...filteredSelectedUsers]);
      }
    }
  }, [restrictedEmployee]);

  const getUsers = () => {
    roleList?.records?.map((m) => {
      if (m.name === "Admin") {
        setRoleId(m.id);
      }
    });

    let reqBody = {
      pageSize: pageSize,
      // filters: { roleIds: ["c6ec043a-50f9-4bd8-b360-2bfe5de477f7"] },
      filters: {},
    };
    dispatch(getUsersAsync(reqBody)).then((res) => {
      if (res.payload.users && res.payload.users.length > 0) {
        const users = res.payload.users.map((user) => ({
          value: user.id,
          label: user.name,
          teamId: user.teamId,
        }));
        // console.log(users);
        setOptionsUser(users);
      }
    });
  };

  useEffect(() => {
    getUserUsage();
    if (roleList.length === 0) {
      dispatch(getRolesAsync({ name: "" }));
    }
    getUsers();
  }, [roleList, setRoleId, roleId]);

  // useEffect(() => {
  //   const teamsReqBody = {};
  //   if (teamsList.length === 0) {
  //     dispatch(getTeamsAsync(teamsReqBody)).then((res) => {
  //       if (!!res?.payload && res?.payload?.length > 0) {
  //         const teams = res?.payload?.map((item) => {
  //           const ifTeamUser = selectedUsers.filter(user => item.value === user.value);
  //           if (ifTeamUser.length > 0) {
  //             return { value: item.key, label: item.value };
  //           }
  //         });
  //         setOptionsTeam(teams);
  //         //console.log(setOptionsTeam);
  //       }
  //     });
  //   } else {
  //     if (!!teamsList && teamsList?.length > 0) {
  //       const teams = teamsList?.map((item) => {
  //         // return { value: item.key, label: item.value };
  //         const ifTeamUser = selectedUsers.filter(user => item.value === user.value);
  //         if (ifTeamUser.length > 0) {
  //           return { value: item.key, label: item.value };
  //         }
  //       });
  //       setOptionsTeam(teams);
  //       //console.log(setOptionsTeam);
  //     }
  //   }
  // }, []);

  const fetchData = async () => {
    const teamsReqBody = {};
    if (loadTeamsList && teamsList.length === 0) {
      setLoadTeamsList(false);
      try {
        const res = await dispatch(getTeamsAsync(teamsReqBody));
        if (!!res?.payload && res?.payload?.length > 0) {
          const teams = res?.payload
            ?.filter((team) => {
              return optionsUser.some((user) => user.teamId === team.key);
            })
            .map((item) => ({ value: item.key, label: item.value }));
          setOptionsTeam(teams); // Filter out undefined values
        }
      } catch (error) {
        console.error("Error fetching teams:", error);
      }
    } else {
      const teams = teamsList
        ?.filter((team) => {
          return optionsUser.some((user) => user.teamId === team.key);
        })
        .map((item) => ({ value: item.key, label: item.value }));
      setOptionsTeam(teams); // Filter out undefined values
    }
  };

  useEffect(() => {
    // console.log("teamsList:", teamsList);
    // console.log("optionsUser:", optionsUser);
    fetchData();
  }, [optionsUser, teamsList]);

  const checkDuplicateTitle = async (title) => {
    console.log(title, "title");
    if (title.trim() !== "") {
      const jsonData =
        processesData && processesData["id"]
          ? {
              id: processesData["id"] ?? null,
              name: title,
              type: "Process",
            }
          : {
              name: title,
              type: "Process",
            };

      await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
        setIsUniqueTitle(res?.payload ? true : false);
        setTitleFocused(false);
      });
    }
  };

  const validateTempTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun,
    callback
  ) => {
    setIsCheckFirstTitleDuplicate(false);
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");
    // console.log(title,'Blurtitle');
    if (title.trim() !== "") {
      setIsSubmittted(true);
      const jsonData =
        processesData && processesData["id"]
          ? {
              id: processesData["id"] ?? null,
              name: title,
              type: "Process",
            }
          : {
              name: title,
              type: "Process",
            };

      await dispatch(checkUniquenessTempAsync(jsonData)).then((res) => {
        setIsUniqueTitle(res?.payload ? true : false);
        setIsSubmittted(false);
        setTitleFocused(false);
        setIsCheckFirstTitleDuplicate(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["title"]: true });
          validateField("title");
        }, 1);

        if (res?.payload) {
          callback && callback();
        }
      });
    }
  };
  const debouncedValidateTempTitle = useDebounce(validateTempTitle, 1000);
  const validateTitle = async (
    title,
    validateField,
    touchedFields,
    setTouchedFun,
    callback
  ) => {
    setIsCheckFirstTitleDuplicate(false);
    setTouchedFun({ ...touchedFields, ["title"]: true });
    validateField("title");
    // console.log(title,'Blurtitle');
    if (title.trim() !== "") {
      setIsSubmittted(true);
      const jsonData =
        processesData && processesData["id"]
          ? {
              id: processesData["id"] ?? null,
              name: title,
              type: "Process",
            }
          : {
              name: title,
              type: "Process",
            };

      await dispatch(checkUniquenessAsync(jsonData)).then((res) => {
        setIsUniqueTitle(res?.payload ? true : false);
        setIsSubmittted(false);
        setTitleFocused(false);
        setIsCheckFirstTitleDuplicate(false);
        setTimeout(() => {
          setTouchedFun({ ...touchedFields, ["title"]: true });
          validateField("title");
        }, 1);

        if (res?.payload) {
          callback && callback();
        }
      });
    }
  };
  const debouncedValidateTitle = useDebounce(validateTitle, 1000);

  const onCancelClick = () => {
    const state = location.state;
    let propdata = { ...state.propsData };
    console.log(state, "statestate");
    if (
      state?.operationType !== undefined &&
      state?.operationType === "Duplicate" &&
      propdata.id
    ) {
      navigate.push("/authoring/processes/details", { id: propdata.id });
    } else if (
      state?.opertionType !== undefined &&
      state?.opertionType === "Edit" &&
      propdata.id
    ) {
      navigate.push("/authoring/processes/details", { id: propdata.id });
    } else if (
      state?.operationType !== undefined &&
      state?.operationType === "Add" &&
      state?.redirectTo
    ) {
      navigate.push(redirectTo);
    } else if (
      state?.operationType !== undefined &&
      state?.operationType === "Add" &&
      !state?.redirectTo
    ) {
      navigate.push("/authoring/");
    } else {
      navigate.push("/authoring/");
    }
  };
  const onProcessesSubmit = (status) => {
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    // console.log(currentFormData,'currentFormData');
    // console.log(restrictIds,'restrictIds');
    currentFormData.values.status = status;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/");
    }

    var errors = processValidation(currentFormData.values);
    if (
      (Object.keys(currentFormData.errors).length === 0 &&
        Object.keys(errors).length === 0) ||
      status === 1
    ) {
      let modules =
        currentFormData?.values?.content !== undefined &&
        currentFormData?.values?.content
          .filter((f) => f.type == "Module")
          .map((m) => {
            return {
              Id: m.id,
              moduleId: m.childId,
              index: m.index,
              status: m.statusEnum,
              statusEnum: m.statusEnum,
            };
          });
      let sequences =
        currentFormData?.values?.content !== undefined &&
        currentFormData?.values?.content
          .filter((f) => f.type == "Sequence")
          .map((m) => {
            return {
              Id: m.id,
              sequenceId: m.childId,
              index: m.index,
              status: m.statusEnum,
              statusEnum: m.statusEnum,
            };
          });

      if (
        currentFormData?.values?.id &&
        (operationType === undefined || operationType !== "Duplicate")
      ) {
        const reqBody = {
          //id: currentFormData.values.id,
          title: currentFormData.values.title,
          description: currentFormData.values.description,
          customTags: currentFormData.values.customTags,
          isCompletedTimeLine: currentFormData.values.isCompletedTimeLine,
          completedTimeLine: currentFormData.values.isCompletedTimeLine
            ? currentFormData.values.completedTimeLine
            : null,
          skipAllowed: currentFormData.values.skipAllowed,
          status: currentFormData.values.status,
          restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
          restrictedIds: currentFormData?.values?.restrictedIds || [],
          modules: modules,
          sequence: sequences,
          recommendedTime: currentFormData?.values?.recommendedTime
            ? currentFormData?.values?.recommendedTime
            : null,
          recommendedTimeDurationType:
            currentFormData?.values?.recommendedTimeDurationType,
          //reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime ? currentFormData?.values?.reminderRecommendedTime : null,
          //reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
        };
        if (operationType !== "Edit") {
          reqBody.id = currentFormData.values.id;
        }
        dispatch(
          status === 1
            ? createProcessesSaveAsDraftAsync(reqBody)
            : updateProcessesAsync(reqBody)
        ).then((res) => {
          // ToasterAlertSucces(res, ErrrorMessage.UPDATE);
          ToasterAlertSucces(
            res,
            status === 1
              ? "Success! Your process has been saved as a draft."
              : "Your process was updated successfully."
          );
          if (redirectTo) {
            navigate.push(redirectTo, { id: processesData.id });
          } else {
            navigate.push("/authoring/");
          }
        });
      } else {
        const reqBody = {
          title: currentFormData.values.title,
          description: currentFormData.values.description,
          customTags: currentFormData.values.customTags,
          isCompletedTimeLine: currentFormData.values.isCompletedTimeLine,
          completedTimeLine: currentFormData.values.isCompletedTimeLine
            ? currentFormData.values.completedTimeLine
            : null,
          skipAllowed: currentFormData.values.skipAllowed,
          status: currentFormData.values.status,
          restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
          restrictedIds: currentFormData?.values?.restrictedIds || [],
          modules: modules ? modules : null,
          sequence: sequences ? sequences : null,
          recommendedTime: currentFormData?.values?.recommendedTime
            ? currentFormData?.values?.recommendedTime
            : null,
          recommendedTimeDurationType:
            currentFormData?.values?.recommendedTimeDurationType,
          // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime ? currentFormData?.values?.reminderRecommendedTime : null,
          // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
        };

        dispatch(
          status === 1
            ? createProcessesSaveAsDraftAsync(reqBody)
            : createProcessesAsync(reqBody)
        ).then((res) => {
          // ToasterAlertSucces(res, ErrrorMessage.CREATE);
          // const message = status === 1 ? ErrrorMessage.DRAFT : ErrrorMessage.CREATE;
          const message =
            status === 1
              ? "Success! Your process has been saved as a draft."
              : operationType === "Duplicate"
              ? "Process duplicated successfully!"
              : "Success! Your process has been created and published.";
          ToasterAlertSucces(res, message);
          if (
            redirectTo &&
            (operationType === undefined || operationType !== "Duplicate")
          ) {
            navigate.push(redirectTo, { state: { id: processesData.id } });
          } else {
            navigate.push("/authoring/");
          }
        });
      }
    }
  };

  const templatePublish = (status) => {
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    // currentFormData.values.status = status;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/modules");
    }
    var errors = processValidation(currentFormData.values);

    let modules =
      currentFormData?.values?.content !== undefined &&
      currentFormData?.values?.content
        .filter((f) => f.type == "Module")
        .map((m) => {
          return {
            Id: m.id,
            moduleId: m.childId,
            index: m.index,
            status: m.statusEnum,
            statusEnum: m.statusEnum,
          };
        });
    let sequences =
      currentFormData?.values?.content !== undefined &&
      currentFormData?.values?.content
        .filter((f) => f.type == "Sequence")
        .map((m) => {
          return {
            Id: m.id,
            sequenceId: m.childId,
            index: m.index,
            status: m.statusEnum,
            statusEnum: m.statusEnum,
          };
        });
    const reqBody = {
      title: currentFormData.values.title,
      description: currentFormData.values.description,
      customTags: currentFormData.values.customTags,
      isCompletedTimeLine: currentFormData.values.isCompletedTimeLine,
      completedTimeLine: currentFormData.values.isCompletedTimeLine
        ? currentFormData.values.completedTimeLine
        : null,
      skipAllowed: currentFormData.values.skipAllowed,
      status: 0,
      restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
      restrictedIds: currentFormData?.values?.restrictedIds || [],
      modules: modules ? modules : null,
      sequence: sequences ? sequences : null,
      recommendedTime: currentFormData?.values?.recommendedTime
        ? currentFormData?.values?.recommendedTime
        : null,
      recommendedTimeDurationType:
        currentFormData?.values?.recommendedTimeDurationType,
      // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
      // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
      templateId: currentFormData?.values?.id,
    };
    dispatch(createProcessesAsync(reqBody)).then((res) => {
      // ToasterAlertSucces(res, ErrrorMessage.CREATE);
      // const message =ErrrorMessage.CREATE;
      // const message = "Success! Your process has been saved as a template.";
      const message = "Your Process has been created Successfully.";
      ToasterAlertSucces(res, message);
      if (
        redirectTo &&
        (operationType === undefined || operationType !== "Duplicate")
      ) {
        navigate.push(redirectTo, { id: currentFormData?.values?.id });
      } else {
        navigate.push("/authoring/");
      }
    });
  };

  const templateSave = (status) => {
    const currentFormData = formRef?.current;
    const restrictIds = selectedUsers.map((user) => user.value);
    currentFormData.values.restrictedIds = restrictIds;
    // currentFormData.values.status = status;
    if (currentFormData.values.status == undefined) {
      navigate.push("/authoring/modules");
    }
    var errors = processValidation(currentFormData.values);

    let modules =
      currentFormData?.values?.content !== undefined &&
      currentFormData?.values?.content
        .filter((f) => f.type == "Module")
        .map((m) => {
          return {
            Id: m.id,
            moduleId: m.childId,
            index: m.index,
            status: m.statusEnum,
            statusEnum: m.statusEnum,
          };
        });
    let sequences =
      currentFormData?.values?.content !== undefined &&
      currentFormData?.values?.content
        .filter((f) => f.type == "Sequence")
        .map((m) => {
          return {
            Id: m.id,
            sequenceId: m.childId,
            index: m.index,
            status: m.statusEnum,
            statusEnum: m.statusEnum,
          };
        });
    const reqBody = {
      title: currentFormData.values.title,
      description: currentFormData.values.description,
      customTags: currentFormData.values.customTags,
      isCompletedTimeLine: currentFormData.values.isCompletedTimeLine,
      completedTimeLine: currentFormData.values.isCompletedTimeLine
        ? currentFormData.values.completedTimeLine
        : null,
      skipAllowed: currentFormData.values.skipAllowed,
      status: 7,
      restrictedTeamIds: currentFormData?.values?.restrictedTeamIds || [],
      restrictedIds: currentFormData?.values?.restrictedIds || [],
      modules: modules ? modules : null,
      sequence: sequences ? sequences : null,
      recommendedTime: currentFormData?.values?.recommendedTime
        ? currentFormData?.values?.recommendedTime
        : null,
      recommendedTimeDurationType:
        currentFormData?.values?.recommendedTimeDurationType,
      // reminderRecommendedTime: currentFormData?.values?.reminderRecommendedTime? currentFormData?.values?.reminderRecommendedTime : null,
      // reminderRecommendedTimeDurationType: currentFormData?.values?.reminderRecommendedTimeDurationType,
      // templateId:currentFormData?.values?.id,
    };
    dispatch(createProcessesAsync(reqBody)).then((res) => {
      // ToasterAlertSucces(res, ErrrorMessage.CREATE);
      // const message = ErrrorMessage.CREATE;
      // const message = "Success! Your process has been saved as a template.";
      const message = "Your Process has been created Successfully.";
      ToasterAlertSucces(res, message);
      navigate.push("/authoring/");
      // }
    });
  };

  const onTagsUpdate = (
    updatedList,
    setFieldValue,
    setTouchedMethod,
    formTouchedData
  ) => {
    let stringdata = updatedList
      .map((m) => {
        return m.tag;
      })
      .toString();
    if (setTouchedMethod) {
      setTouchedMethod({ ...formTouchedData, ["customTags"]: true });
    }
    setFieldValue("customTags", stringdata);
  };
  const SetDropDownList = async () => {
    let response = await dispatch(getDropdownListAsync());
    adddropdownList(response?.payload ?? []);
  };

  useEffect(() => {
    if (operationType == "Duplicate") {
      setIsUniqueTitle(false);
    }

    const state = location.state;
    setFormDataReady(true);
    if (state !== undefined && state.propsData !== undefined) {
      let propdata = { ...state.propsData };
      if (operationType == "Duplicate") {
        propdata.id = undefined;
      }
      setProcessesData(propdata);

      const recommendedTimeDurationType = optionsTime.find(
        (f) => f.value === propdata.recommendedTimeDurationType
      );

      if (recommendedTimeDurationType) {
        setRecommendedTimeDurationType({
          value: recommendedTimeDurationType.value,
          label: recommendedTimeDurationType.label,
        });
      }

      // const reminderRecommendedTimeDurationType = optionsTime.find(
      //   (f) => f.value === propdata.reminderRecommendedTimeDurationType
      // );

      // if (reminderRecommendedTimeDurationType) {
      //   setReminderRecommendedTimeDurationType({
      //     value: reminderRecommendedTimeDurationType?.value,
      //     label: reminderRecommendedTimeDurationType?.label,
      //   });
      // }

      if (state?.propsData?.restrictedTeamIds) {
        const restrictedTeamIds = state?.propsData?.restrictedTeamIds.map(
          (value) => ({ value: value.id, label: value.name })
        );
        setSelectedTeam(restrictedTeamIds);
      }
      if (state?.propsData?.restrictedIds) {
        const restrictedIds = state?.propsData?.restrictedIds.map((value) => ({
          value: value.employeeId,
          label: value.employeeName,
          teamId: value.teamId,
        }));
        setSelectedUsers(restrictedIds);
        if (restrictedIds) {
          setShowMultiSelect(true);
        }
      }
    }
    SetDropDownList();

    // console.log(location,'location');
  }, [location]);

  const onRemoveContentClick = (id, setMethod, touchedMethod, touchedData) => {
    const currentFormData = formRef?.current;
    const newData = currentFormData.values?.content.filter(
      (f) => f.childId !== id
    );
    if (touchedMethod) {
      touchedMethod({ ...touchedData, ["content"]: true });
    }
    // console.log(id, 'id');
    // console.log(prevList, 'prevList');
    // console.log(newData, 'newData');
    setUpdatedList((prevList) =>
      prevList.filter((item) => item.childId !== id)
    );
    setMethod("content", newData);
    showContentDropdron(false);
    SetDropDownList();
  };
  const prepareData = (data) => {
    var obj = {
      id: null,
      childId: data.id,
      title: data.title,
      type: data.type,
      status: data.status,
      statusEnum: data.statusEnum,
      expiryDate: data.expiryDate,
      index: processesData?.content?.length ?? 0,
    };
    return obj;
  };
  const addContentData = (
    data,
    setMethod,
    setTouchedMethod,
    formTouchedData,
    expiryDate
  ) => {
    const currentFormData = formRef?.current;
    const latestList = [...(currentFormData?.values?.content ?? [])];
    if (data) {
      setUpdatedList((prevList) => [...prevList, data]);
      const dataWithExpiry = {
        ...data,
        expiryDate: data.expiryDate || expiryDate,
      };
      const moduleExpired =
        dataWithExpiry.expiryDate &&
        new Date(dataWithExpiry.expiryDate) < new Date();
      // console.log(moduleExpired,"moduleExpired")
      if (
        processesData?.status === "Published" &&
        moduleExpired &&
        data.status === "Draft" &&
        operationType !== "Duplicate"
      ) {
        setShowAlertMessagePopup(true);
      } else {
        if (latestList) {
          // latestList.push(prepareData(data));
          latestList.push(prepareData(dataWithExpiry));
        } else {
          // latestList = [prepareData(data)];
          latestList = [prepareData(dataWithExpiry)];
        }
        if (setTouchedMethod) {
          setTouchedMethod({ ...formTouchedData, ["content"]: true });
        }
        if (setMethod) {
          setMethod("content", latestList);
        }
      }
    }

    if (!processesData) {
      setProcessesData({ ...processesData });
    }
    showContentDropdron(false);
  };
  useEffect(() => {
    // console.log(updatedList, "updatedList");
    const allExpiryDates = updatedList.flatMap(
      (module) => module.expiryDate || []
    );
    const validExpiryDates = allExpiryDates.filter((date) => date);

    setExpiredRequired(validExpiryDates.length > 0 ? true : false);

    const earliestExpiryDate =
      validExpiryDates.length > 0
        ? new Date(Math.min(...validExpiryDates.map((date) => new Date(date))))
        : null;
    setMinDate((prevMinDate) =>
      earliestExpiryDate && earliestExpiryDate < prevMinDate
        ? earliestExpiryDate
        : prevMinDate
    );
    setMaxDate(earliestExpiryDate);
  }, [updatedList]);

  const showContentDropdron = (value) => {
    const existsData = formRef.current?.values?.content ?? [];
    // const data =
    adddropdownList(
      dropdownList?.filter(
        (f) =>
          existsData && existsData.map((m) => m.childId).indexOf(f.id) == -1
      )
    );

    if (dropdownList.length > 0) {
      addContentClick(value);
    } else {
      addContentClick(false);
    }
  };

  const handleActionClick = async (
    actionType,
    validateField,
    touched,
    setTouched,
    handleSubmit,
    setFieldValue,
    values
  ) => {
    console.log(actionType, "actionType");
    switch (actionType) {
      case "save_as_template":
        if (processesData?.id && processesData?.status !== "Template") {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templateSave(7);
              // handleSubmit();
            }
          );
          return;
        }
        break;
      case "update":
        // if (operationType === "Duplicate") {
        //   let validation = await validateTempTitle(
        //     values?.title,
        //     validateField,
        //     touched,
        //     setTouched,
        //     () => {
        //       templateSave(7);
        //       // handleSubmit();
        //     }
        //   );
        //   return;
        // }
        if (processesData?.status === "Template") {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              onProcessesSubmit(7);
              handleSubmit();
            }
          );
          return;
        } else {
          let validation = await debouncedValidateTempTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templateSave(7);
              // handleSubmit();
            }
          );
          return;
        }
        break;
      case "update_publish": // case action duplicate, create, draft, edit, 
        if ((operationType === "Duplicate" || processesData?.status === "Draft") && getRestrictedPopUp() === true) {
          setRestrictedPopUp(true);
          return;
        } else if (processesData?.status !== "Template") {
          let validation = await debouncedValidateTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              onProcessesSubmit(0);
              handleSubmit();
            }
          );
          return;
        }
        break;
      case "publish": // case template publish
        if (getRestrictedPopUp() === true) {
          // console.log("Test Publish");
          setRestrictedPopUp(true);
          return;
        } else if (processesData?.status === "Template") {
          let validation = await debouncedValidateTitle(
            values?.title,
            validateField,
            touched,
            setTouched,
            () => {
              templatePublish(0);
              handleSubmit();
            }
          );
          return;
        }
        break;
    }
  };

  const processValidation = (values) => {
    // console.log(values,'values');
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    }

    if (!values.description || values.description.trim() === "") {
      errors.description = "Description is required!";
    } else {
      // Remove multiple consecutive white spaces and line breaks, and count a single space as a character
      const cleanedDescription = values.description.replace(/[\s\n]+/g, " ");

      if (cleanedDescription.length < 116) {
        errors.description =
          "The minimum requirement for the description should be 116 characters.";
      }
    }

    if (!values.customTags || values.customTags.trim() === "") {
      errors.customTags = "Please add tags for your process.";
    }
    if (!values.content || values.content.length === 0) {
      errors.content =
        "The content field cannot be empty. Please add content for your process.";
    }

    if (
      expiredRequired === true &&
      !values.completedTimeLine &&
      values.isCompletedTimeLine === true
    ) {
      errors.completedTimeLine = "Date is required and should be in future!";
    } else if (
      values.isCompletedTimeLine === true &&
      values.completedTimeLine < new Date()
    ) {
      errors.completedTimeLine = "Date is required and should be in future!";
    }

    if (expiredRequired === true && !values.completedTimeLine) {
      errors.completedTimeLine = "Expiry date is required!";
    } else if (
      values.completedTimeLine &&
      new Date(values.completedTimeLine) < new Date()
    ) {
      errors.completedTimeLine = "Expiry date should be in the future!";
    }

    // if (!values.reminderRecommendedTime && values.reminderRecommendedTime !== 0) {
    //   errors.reminderRecommendedTime = "Reminder time is required!";
    // } else if (values.reminderRecommendedTime <= 0) {
    //   errors.reminderRecommendedTime = "Reminder time should be grater then 0";
    // }

    if (!values.recommendedTime && values.recommendedTime !== 0) {
      errors.recommendedTime = "Recommended time is required!";
    } else if (values.recommendedTime <= 0) {
      errors.recommendedTime = "Recommended time should be greater then 0";
    }
    return errors;
  };

  const processDraftValidation = (values) => {
    let errors = {};
    if (titleFocused) {
      errors.title = "";
    }
    if (!values.title || values.title.trim() === "") {
      errors.title = "Title is required!";
    } else if (values.title && !isUniqueTitle) {
      errors.title = "Title already exists!";
    }
    return errors;
  };

  const getHeaderTitle = (id, opertionType) => {
    return id !== undefined && opertionType !== "Duplicate"
      ? "Edit process"
      : "Create a process";
  };

  const getSubmitTitle = (id, opertionType) => {
    if (processesData?.status === "Draft") {
      return "Publish";
    } else if (processesData?.status === "Template") {
      return "Update and Publish";
    } else {
      return id !== undefined && id !== "" && opertionType !== "Duplicate"
        ? "Update"
        : "Publish";
    }
  };

  // const getTemplateTitle = (id, opType) => {
  //   return id !== undefined && id !== ""
  //     ? "Update the template"
  //     : "Save as template";
  // };
  const getTemplateTitle = (id, opType, moduleData) => {
    if (processesData?.status === "Template") {
      return id !== undefined && id !== "" ? "Update the template" : "";
    } else {
      return id !== undefined && id !== ""
        ? "Save as template"
        : "Save as template";
    }
  };

  const getHeaderTitleUrl = (redirectToUrl) => {
    console.log(operationType,'operationType');
    console.log(redirectToUrl,'redirectToUrl');
    console.log(processesData,'processesDataprocessesData');
    const state = location.state;
    let propdata = { ...state?.propsData }
    console.log(propdata,'propdata');
    return operationType === "Duplicate" 
      ? {
          pathname: redirectToUrl,
          state: { id: propdata?.id },
        }
      : redirectToUrl !== undefined
      ? {
          pathname: redirectToUrl,
          state: { id: processesData?.id },
        }
      : processesData?.status === "Template"
      ? {
          pathname: "/authoring",
          state: { backType: "Template" },
        }
      : "/authoring";
  };

  const onSelectationChange = (e, colName, setMethod, localState) => {
    localState({
      value: e.value,
      label: e.label,
    });
    if (setMethod) {
      setMethod(colName, e.value);
    }
  };
  const onSelectationChanges = (
    selectedValues,
    colName,
    setMethod,
    localState,
    prevSelectedValues // Add the previous selected values as a parameter
  ) => {
    // setRestrictModal(true);
    setRestrictEmployee(false);
    const selectedLocalTeamIds = selectedValues.map((value) => ({
      value: value.value,
      label: value.label,
    }));
    const selectedMethodTeamIds = selectedValues.map((value) => value.value);

    // console.log(selectedValues,'selectedValues');
    // console.log(selectedLocalTeamIds,'selectedLocalTeamIds');
    // console.log(setMethod,'setMethod');

    if (selectedMethodTeamIds.length > 0) {
      localState(selectedLocalTeamIds);
      setMethod(colName, selectedMethodTeamIds);

      // Check for removed items
      // console.log(selectedValues, 'selectedValues');
      // console.log(prevSelectedValues, 'prevSelectedValues');
      const removedItems = prevSelectedValues.filter(
        (prevItem) =>
          !selectedValues.some((newItem) => prevItem.value === newItem.value)
      );

      if (removedItems.length > 0) {
        // Extract the teamId values from removedItem
        const removedTeamIds = removedItems.map((item) => item.value);

        // Remove objects from selectedUser where teamId matches removedTeamIds
        const updatedSelectedUser = selectedUsers.filter(
          (user) => !removedTeamIds.includes(user.teamId)
        );

        // Append the updatedSelectedUser to the state
        setSelectedUsers(updatedSelectedUser);
        setMethod(
          "restrictedIds",
          updatedSelectedUser.map((user) => user.value)
        );
        setRestrictModal(false); // Example: Close or hide the modal when an item is removed
      } else {
        setRestrictedTeam(selectedLocalTeamIds);
        setRestrictModal(true);
      }
    } else {
      // localState(selectedLocalTeamIds);
      setSelectedUsers([]);
      setSelectedTeam([]);
      setMethod(colName, []);
      setMethod("restrictedIds", []);
      setShowMultiSelect(false);
      setRestrictModal(false);
    }
  };

  const onSelectationUserChanges = (
    selectedValues,
    colName,
    setMethod,
    localState
  ) => {
    // console.log(selectedValues,'selectedValues');
    // setSelectedUsers(selectedValues);
    if (setMethod) {
      setMethod(
        colName,
        selectedValues.map((user) => user.value)
      );
    }
    if (localState) {
      localState(
        selectedValues.map((user) => ({
          value: user.value,
          label: user.label,
          teamId: user.teamId,
        }))
      );
    }
  };
  const getSelectedContentViewList = (
    values,
    setFieldValue,
    touchMethod,
    tuchedData
  ) => {
    if (!values.content || !Array.isArray(values.content)) {
      return null;
    }
    const hasUnpublishedContent = values.content.some(
      (m) => m.status === "Draft"
    );
    setHasUnpublishedContent(hasUnpublishedContent);

    const dragStart = (e, position) => {
      dragItem.current = position;
    };

    const dragEnter = (e, position) => {
      dragOverItem.current = position;
    };

    const drop = (list) => {
      const copyListItems = [...list];
      const dragItemContent = copyListItems[dragItem.current];
      copyListItems.splice(dragItem.current, 1);
      copyListItems.splice(dragOverItem.current, 0, dragItemContent);
      dragItem.current = null;
      dragOverItem.current = null;
      setFieldValue(
        "content",
        copyListItems.map((item, index) => ({
          id: item.id,
          childId: item.childId,
          title: item.title,
          type: item.type,
          status: item.status,
          index: index,
          statusEnum: item.statusEnum,
          content: item.content,
        }))
      );
    };
    return (
      <>
        {content === false ? (
          <>
            {" "}
            <p>
              Content<span style={{ color: "red" }}> * </span>
            </p>
            <a
              className="text-primary-200 font-bold h6 mb-3 d-inline-block"
              onClick={() => showContentDropdron(true)}
            >
              <IconPlus /> Add content<span style={{ color: "red" }}> * </span>
            </a>
          </>
        ) : (
          ""
        )}
        {values.content &&
          values?.content.map((m, index) => {
            setUpdatedList(values?.content);
            return (
              <ContentView
                index={index}
                key={m.childId + "_ContentView"}
                id={m.childId}
                type={m.type}
                status={m.status}
                statusEnum={m.statusEnum}
                title={m.title}
                setFieldValue={setFieldValue}
                onRemove={onRemoveContentClick}
                touchedMethod={touchMethod}
                touchedData={tuchedData}
                list={values?.content}
                setList={setFieldValue}
                drop={drop}
                dragStart={dragStart}
                dragEnter={dragEnter}
              />
            );
          })}
        {hasUnpublishedContent && (
          <p style={{ color: "red" }}>
            Your Sequence OR Process cannot be published or saved as a template
            as the added content is in the draft state.
          </p>
        )}
      </>
    );
  };

  const getRestrictedPopUp = () => {
    console.log(operationType, "operationTypeoperationType");
    console.log(userUsage, "userUsage");
    const hasSubscriptions =
      subscriptionData?.subscriptionData?.subscriptions?.length > 0;
    const hasItems =
      hasSubscriptions &&
      subscriptionData?.subscriptionData?.subscriptions[0]?.items?.length > 0;
    if (hasSubscriptions && hasItems) {
      const basePlan = subscriptionData?.subscriptionData?.plans.filter(
        (plan) => plan.statementDescriptor === "base_plan"
      );
      console.log(basePlan, "basePlanbasePlan");
      const planModules =
        basePlan && basePlan?.length > 0
          ? parseInt(basePlan[0]?.metaData?.process)
          : 0;
      return planModules === -1 ? false : userUsage?.processCount >= planModules ? true : false;
    } else {
      return true;
    }
  };

  return (
    <div className="pages">
      <PageHeaderSection
        redirectTo={getHeaderTitleUrl(redirectTo)}
        title={getHeaderTitle(processesData?.id, operationType)}
      ></PageHeaderSection>
      {/* {console.log("opertionType 335 = ", operationType)} */}
      <div className="pageOuter">
        <Container fluid>
          {!isRestricted && (
            <div className="page createModulePage d-flex h-100">
              <div className="w-100 d-flex flex-column">
                {isSubmittted === true && <Loader />}
                {formDataReady && (
                  <Formik
                    initialValues={{
                      id: processesData?.id ?? undefined,
                      title: processesData?.title,
                      description: processesData?.description,
                      customTags: processesData?.customTags,
                      content: processesData?.content,
                      recommendedTime: processesData?.recommendedTime ?? 1,
                      recommendedTimeDurationType:
                        processesData?.recommendedTimeDurationType ?? 0,
                      // reminderRecommendedTime: processesData?.reminderRecommendedTime ?? 1,
                      // reminderRecommendedTimeDurationType: processesData?.reminderRecommendedTimeDurationType ?? 1,
                      isCompletedTimeLine:
                        processesData?.isCompletedTimeLine ?? false,
                      completedTimeLine: processesData?.completedTimeLine
                        ? new Date(processesData?.completedTimeLine)
                        : null,
                      skipAllowed: processesData?.skipAllowed ?? false,
                      status: undefined,
                    }}
                    onSubmit={onProcessesSubmit}
                    validate={processValidation}
                    innerRef={formRef}
                  >
                    {({
                      errors,
                      touched,
                      isValidating,
                      values,
                      setFieldValue,
                      validateField,
                      setTouched,
                      handleSubmit,
                    }) => {
                      return (
                        <Form style={{ display: "contents" }}>
                          <div className="flex-grow-1 overflow-auto">
                            <Row className="g-0 d-flex h-100">
                              <Col className="col-lg-8 flex-grow-1 overflow-auto h-100">
                                <div className="p-32 pb-4">
                                  <div className="input-group myInput mb-32">
                                    <TextInput
                                      name="title"
                                      label="Title"
                                      className="form-control"
                                      placeholder="E.g HR Operations"
                                      isRequired={true}
                                      // onFocus={() => {
                                      //   setTitleFocused(true);
                                      // }}
                                      // onChange={(e) => {
                                      //   checkDuplicateTitle(
                                      //     e?.target?.value,
                                      //   );
                                      // }}
                                      // onBlur={(event) => {
                                      //   validateTitle(
                                      //     event?.target?.value,
                                      //     validateField,
                                      //     touched,
                                      //     setTouched
                                      //   );
                                      // }}
                                      onInput={(event) => {
                                        debouncedValidateTitle(
                                          event?.target?.value,
                                          validateField,
                                          touched,
                                          setTouched
                                        );
                                      }}
                                      maxLength={40}
                                    />
                                    {operationType === "Duplicate" &&
                                      isCheckFirstTitleDuplicate === true &&
                                      errors.title && (
                                        <label
                                          className="errorValidation"
                                          style={{ color: "red" }}
                                        >
                                          {errors.title}
                                        </label>
                                      )}
                                  </div>
                                  <div className="input-group myInput mb-32 labelFor">
                                    <TextArea
                                      label="Description"
                                      name="description"
                                      className="form-control"
                                      rows="3"
                                      placeholder="Add text..."
                                      isRequired={true}
                                      maxLength={250}
                                    />
                                  </div>
                                  <div className="mb-32">
                                    <TagsInput
                                      name="customTags"
                                      inputTags={
                                        processesData?.customTags ?? ""
                                      }
                                      onChange={onTagsUpdate}
                                      setMethod={setFieldValue}
                                      touchedMethod={setTouched}
                                      touchedData={touched}
                                    />
                                    {touched.customTags &&
                                      errors.customTags && (
                                        <label
                                          className="errorValidation"
                                          style={{ color: "red" }}
                                        >
                                          {errors.customTags}
                                        </label>
                                      )}
                                  </div>

                                  {content && (
                                    <ContentDropdown
                                      list={dropdownList ?? []}
                                      onAdd={addContentData}
                                      setMethod={setFieldValue}
                                      selectedData={
                                        processesData?.content?.map((m) => {
                                          return {
                                            id: m.childId,
                                            type: m.type,
                                            status: m.status,
                                            statusEnum: m.statusEnum,
                                            title: m.Title,
                                          };
                                        }) ?? []
                                      }
                                      touchedMethod={setTouched}
                                      touchedData={touched}
                                      getSelectedContentViewList={getSelectedContentViewList(
                                        values,
                                        setFieldValue,
                                        setTouched,
                                        touched
                                      )}
                                    />
                                  )}

                                  {!content &&
                                    getSelectedContentViewList(
                                      values,
                                      setFieldValue,
                                      setTouched,
                                      touched
                                    )}

                                  {dropdownList != undefined &&
                                    dropdownList?.length !=
                                      processesData?.content?.length &&
                                    !content && (
                                      <>
                                        {!flag &&
                                          operationType !== "Duplicate" &&
                                          getHeaderTitle(
                                            processesData?.id,
                                            operationType
                                          ) !== "Edit process" && (
                                            <a
                                              className="text-primary-200 font-bold h6"
                                              onClick={() => {
                                                showContentDropdron(true);
                                                setFlag(true);
                                              }}
                                            >
                                              <IconPlus /> Add content
                                              <span style={{ color: "red" }}>
                                                {" "}
                                                *{" "}
                                              </span>
                                            </a>
                                          )}
                                        <br />
                                        {touched.content && errors.content && (
                                          <label
                                            className="errorValidation"
                                            style={{ color: "red" }}
                                          >
                                            {errors.content}
                                          </label>
                                        )}
                                      </>
                                    )}
                                </div>
                              </Col>
                              <Col className="col-lg-4 bg-gray-600 flex-grow-1 overflow-auto h-100">
                                <div className="bg-gray-600 p-32 px-4 h-100">
                                  <div className="mb-32">
                                    {/* <UserImageList
                                    title="Process Owners"
                                    imageList={imageList}
                                  /> */}
                                    <OwnerImgCount title="Process Owners" />
                                  </div>

                                  {/* <div className="mb-3">
                                    <p className="h6 font-medium mb-12">
                                      Reminder time to alert
                                      <span
                                        className="mandatoryFields"
                                        style={{ color: "red" }}
                                      >
                                        *
                                      </span>
                                    </p>
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="input-group myInput">
                                          <TextInput
                                            type="number"
                                            name="reminderRecommendedTime"
                                            className="form-control"
                                            min={0}
                                            step={1}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group mySelect">
                                          <Select
                                            isSearchable={false}
                                            name="reminderRecommendedTimeDurationType"
                                            className="select"
                                            classNamePrefix="selectCustom"
                                            options={optionsTime}
                                            defaultValue={
                                              reminderRecommendedTimeDurationType
                                            }
                                            value={reminderRecommendedTimeDurationType ? reminderRecommendedTimeDurationType : null}
                                            onChange={(e) =>
                                              onSelectationChange(
                                                e,
                                                "reminderRecommendedTimeDurationType",
                                                setFieldValue,
                                                setReminderRecommendedTimeDurationType
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div> */}

                                  <div className="mb-3">
                                    <p className="h6 font-medium mb-12">
                                      Recommended time to complete
                                      <span
                                        className="mandatoryFields"
                                        style={{ color: "red" }}
                                      >
                                        *
                                      </span>
                                    </p>
                                    <div className="row">
                                      <div className="col-6">
                                        <div className="input-group myInput">
                                          <TextInput
                                            type="number"
                                            name="recommendedTime"
                                            className="form-control"
                                            min={1}
                                            step={1}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-6">
                                        <div className="form-group mySelect">
                                          <Select
                                            isSearchable={false}
                                            name="recommendedTimeDurationType"
                                            className="select"
                                            classNamePrefix="selectCustom"
                                            options={optionsTime}
                                            defaultValue={
                                              recommendedTimeDurationType
                                            }
                                            value={
                                              recommendedTimeDurationType
                                                ? recommendedTimeDurationType
                                                : null
                                            }
                                            onChange={(e) =>
                                              onSelectationChange(
                                                e,
                                                "recommendedTimeDurationType",
                                                setFieldValue,
                                                setRecommendedTimeDurationType
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mb-32">
                                    <p className="h6 font-medium mb-20">
                                      Other Settings
                                    </p>
                                    <p className="h6 text-gray-200 mb-12 d-flex align-items-centre">
                                      <CheckBoxInput
                                        name="isCompletedTimeLine"
                                        label="Set Expiry date"
                                        value={
                                          values.isCompletedTimeLine ?? false
                                        }
                                        onChange={(isChecked) => {
                                          setFieldValue(
                                            "isCompletedTimeLine",
                                            isChecked?.target?.checked
                                          );
                                          if (!isChecked?.target?.checked) {
                                            setFieldValue(
                                              "completedTimeLine",
                                              null
                                            );
                                          }
                                        }}
                                      />
                                      {expiredRequired === true && (
                                        <span className="ms-1 text-secondary-600">
                                          {" "}
                                          *{" "}
                                        </span>
                                      )}
                                    </p>
                                    {values.isCompletedTimeLine == true && (
                                      <div className="mb-20">
                                        <div className="input-group myInput yAxisCalendar">
                                          <IconCalendar />
                                          <DatePicker
                                            className="form-control"
                                            onChange={(date) =>
                                              setFieldValue(
                                                "completedTimeLine",
                                                date
                                                  ? moment(
                                                      new Date(date)
                                                    ).format(
                                                      "YYYY-MM-DDThh:mm:ssZ"
                                                    )
                                                  : ""
                                              )
                                            }
                                            name="completedTimeLine"
                                            selected={
                                              values?.completedTimeLine
                                                ? new Date(
                                                    values?.completedTimeLine
                                                  )
                                                : null
                                            }
                                            // selected={
                                            //   new Date(values.completedTimeLine)
                                            // }

                                            // new Date(values.completedTimeLine)
                                            //                                             values?.completedTimeLine
                                            //                                             ? new Date(values?.completedTimeLine)
                                            //                                             : new Date()
                                            //                                           }

                                            // minDate={new Date()}
                                            minDate={minDate}
                                            maxDate={maxDate}
                                            autoComplete="off"
                                          />
                                        </div>
                                        {errors.completedTimeLine !==
                                        undefined ? (
                                          <label
                                            className="errorValidation"
                                            style={{ color: "red" }}
                                          >
                                            {errors.completedTimeLine}
                                          </label>
                                        ) : (
                                          <></>
                                        )}
                                      </div>
                                    )}

                                    {/* <p className="h6 text-gray-200">
                                      <CheckBoxInput
                                        name="skipAllowed"
                                        label="Skip allowed"
                                        value={values.skipAllowed ?? false}
                                      />
                                    </p> */}
                                  </div>
                                  {/* {getHeaderTitle(
                                    processesData?.id,
                                    operationType
                                  ) !== "Edit process" && ( */}
                                  <>
                                    <div className="mb-12 d-flex justify-content-between align-items-center">
                                      <p className="h6 font-medium ">
                                        Access Restrictions
                                      </p>
                                      {/* <p className="h6 font-bold text-primary-200">
                                      Advanced
                                    </p> */}
                                    </div>
                                    <div
                                      className="multiSelectMenuTop"
                                      style={{ marginBottom: "1.25rem" }}
                                    >
                                      <MultiSelectDropdown
                                        name="restrictedTeamIds"
                                        isSearchable={false}
                                        autofocus={true}
                                        // menuPlacement="auto"
                                        className="select"
                                        classNamePrefix="selectCustom"
                                        options={optionsTeam}
                                        selectedValues={selectedTeam}
                                        placeholder="Search by Team"
                                        onSelect={(selectedTeams) =>
                                          onSelectationChanges(
                                            selectedTeams,
                                            "restrictedTeamIds",
                                            setFieldValue,
                                            setSelectedTeam,
                                            selectedTeam // Pass the current selectedTeam as prevSelectedValues
                                          )
                                        }
                                        setMethod={setFieldValue}
                                        // onRemove={(removedItem) => {
                                        //   setShowMultiSelect(false);
                                        //   // console.log(
                                        //   //   `Item removed: ${removedItem}`
                                        //   // );
                                        // }}
                                      />
                                    </div>
                                    {showMultiSelect && (
                                      <div className="multiSelectMenuTop">
                                        <MultiSelectDropdown
                                          name="restrictedIds"
                                          isSearchable={false}
                                          className="select"
                                          classNamePrefix="selectCustom"
                                          options={
                                            optionsUser
                                              ? optionsUser.filter(
                                                  (user) =>
                                                    // selectedTeam.includes(user.teamId) || selectedUsers.includes(user.value)
                                                    selectedTeam.some(
                                                      (team) =>
                                                        team.value ===
                                                        user.teamId
                                                    ) ||
                                                    selectedUsers.some(
                                                      (team) =>
                                                        team.teamId ===
                                                        user.teamId
                                                    )
                                                )
                                              : []
                                          }
                                          selectedValues={selectedUsers}
                                          placeholder="Search by Users"
                                          onSelect={(selectedUsers) =>
                                            onSelectationUserChanges(
                                              selectedUsers,
                                              "restrictedIds",
                                              setFieldValue,
                                              setSelectedUsers
                                            )
                                          }
                                          setMethod={setFieldValue}
                                          // onRemove={(selectedUsers) =>
                                          //   onSelectationUserChanges(
                                          //     selectedUsers,
                                          //     "restrictedIds",
                                          //     setFieldValue,
                                          //     setSelectedUsers
                                          //   )
                                          // }
                                        />
                                      </div>
                                    )}
                                  </>
                                  {/* )} */}
                                  {/* <div className="input-group myInput myInputSearch lg">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Search by team name or user"
                                    />
                                  
                                    <IconTopSearch />
                                  </div> */}
                                </div>
                              </Col>
                            </Row>
                          </div>

                          <div className="d-flex gap-20 align-items-center btnFooter pe-4">
                            <Button
                              disabled={
                                Object.keys(processValidation(values))
                                  .length !== 0 || hasUnpublishedContent
                              }
                              type="button"
                              variant="secondary"
                              // onClick={() =>  onProcessesSubmit(0)}
                              onClick={() =>
                                handleActionClick(
                                  processesData?.status === "Template"
                                    ? "publish"
                                    : "update_publish",
                                  validateField,
                                  touched,
                                  setTouched,
                                  handleSubmit,
                                  setFieldValue,
                                  values
                                )
                              }
                              // onClick={() => {
                              //   if (processesData?.status === "Template") {
                              //     templatePublish(0);
                              //   } else{
                              //   onProcessesSubmit(0);
                              //   }
                              // }}
                            >
                              {getSubmitTitle(processesData?.id, operationType)}
                            </Button>
                            <Button
                              disabled={
                                Object.keys(processValidation(values))
                                  .length !== 0 || hasUnpublishedContent
                              }
                              type="button"
                              variant="primary"
                              // onClick={() => onProcessesSubmit(7)}
                              // onProcessesSubmit(7)
                              onClick={() =>
                                handleActionClick(
                                  processesData?.id &&
                                    processesData?.status !== "Template"
                                    ? "save_as_template"
                                    : "update",
                                  validateField,
                                  touched,
                                  setTouched,
                                  handleSubmit,
                                  setFieldValue,
                                  values
                                )
                              }
                              // onClick={() => {
                              //     if (processesData?.id && processesData?.status !== "Template") {
                              //       templateSave(7);
                              //     }else{
                              //       onProcessesSubmit(7);
                              //     }
                              //   }}
                            >
                              {getTemplateTitle(
                                processesData?.id,
                                operationType
                              )}
                              {/* Save as Template */}
                            </Button>

                            {(operationType === "Duplicate" || processesData?.status === "Template" ||
                              !processesData?.status ||
                              (processesData?.status === "Draft" &&
                                processesData?.status !== "Published")) && (
                              <Button
                                // disabled={
                                //   Object.keys(processValidation(values))
                                //     .length !== 0
                                // }
                                // type="submit"
                                variant="noBg"
                                // onClick={() => setFieldValue("status", 1)}
                                disabled={
                                  isButtonDisabled ||
                                  (Object.keys(processDraftValidation(values))
                                    .length !== 0 &&
                                    titleFocused)
                                }
                                onClick={() => {
                                  setButtonDisabled(true);
                                  onProcessesSubmit(1);
                                }}
                              >
                                Save as draft
                              </Button>
                            )}
                            <Button
                              className="ml-auro"
                              variant="white"
                              // onClick={() => onCancelClick()}
                              onClick={() => {
                                if (processesData?.status === "Template") {
                                  navigate.push("/authoring", {
                                    id: location?.state?.propsData?.id,
                                    backType: "Template",
                                  });
                                } else {
                                  onCancelClick();
                                }
                              }}
                            >
                              Cancel
                            </Button>
                          </div>
                          <Modal
                            className="modalDialog"
                            size="sm"
                            centered
                            show={restrictModal}
                            // onHide={() => setRestrictModal(false)}
                          >
                            <Modal.Header
                              closeButton
                              onClick={() => setRestrictModal(false)}
                            >
                              <Modal.Title></Modal.Title>
                            </Modal.Header>
                            <Modal.Body className="pb-0">
                              <span className="iconRestrict">
                                <IconRestrict />
                              </span>
                              <h3 className="pt-1 mt-2">
                                {" "}
                                Restrict Whole Team?
                              </h3>
                              <p className="text-gray-300 mb-0 mt-2 mb-5">
                                All the team members of the selected team will
                                be restricted.
                              </p>
                            </Modal.Body>

                            <Modal.Footer className="justify-content-end btnFooter py-3 noShadow">
                              <Button
                                variant="white"
                                size=""
                                className="m-0"
                                onClick={() => {
                                  setRestrictModal(false);
                                  setShowMultiSelect(true);
                                }}
                              >
                                No, Restrict Individually
                              </Button>
                              <Button
                                variant="primary"
                                size=""
                                className="border-0 m-0 ms-2"
                                onClick={() => {
                                  setRestrictModal(false);
                                  setRestrictEmployee(true);
                                }}
                              >
                                Yes
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </Form>
                      );
                    }}
                  </Formik>
                )}
              </div>
            </div>
          )}

          {isRestricted && (
            <RestrictedPlan
              title={"Process Creation Limit Reached!"}
              description={
                "You have reached the creation limit for processes.  Upgrade plan to add more."
              }
            />
          )}
        </Container>
      </div>
      {formDataReady === false && <Loader />}

      <Modal
        className="modalDialog"
        size="sm"
        centered
        show={showAlertMessagePopup}
      >
        <ModalMessageAlertPopup
          onCancel={setShowAlertMessagePopup}
          requestType=""
          headerLable="You can not add any draft content as it is in published status."
          chldLable=""
        />
      </Modal>

      <Modal className="modalDialog" size="sm" centered show={restrictedPopUp}>
        <RestrictedModalPopup
          onCancel={setRestrictedPopUp}
          headerLable={
            "Action can not be performed since processes limit has reached!"
          }
          chldLable={
            "Kindly purchase or upgrade your plan in order to continue with this action."
          }
        />
      </Modal>
    </div>
  );
};

export default CreateProcesses;
