import React, { useState, useEffect } from 'react';
import {
  Row,
  Col,
  Container,
  Button,
} from "react-bootstrap";
import {
    IconleftArrow,
    IconrightArrow
  } from "../../icons/Icons";

function Pagination({ totalItems, itemsPerPage, onPageChange, setFilterss, filterss, pageTitle }) {
  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (newPage) => {
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
      onPageChange(newPage);
    }
  };

  useEffect(() => {
    // console.log('alert current page');
    setFilterss && setFilterss(false);
    setCurrentPage(1);
  }, [totalItems, itemsPerPage, filterss]);

  // const startItem = (currentPage - 1) * itemsPerPage + totalItems > 0 ? 1 : 0;
  const startItem = (currentPage - 1) * itemsPerPage + 1;
  const endItem = Math.min(currentPage * itemsPerPage, totalItems);
  
  const renderPageNumbers = () => {
    const pageNumbers = [];
    const maxVisiblePages = 4;
  
    if (totalPages <= 1) {
      return [
        <span key={1} className="btn btn-sm pBtn active" onClick={() => handlePageChange(1)}>1</span>,
        <span key="dots" className="paginationDots px-2">...</span>,
      ];
    }

    if (totalPages <= maxVisiblePages) {
      for (let i = 1; i <= totalPages; i++) {
        pageNumbers.push(
          <span
            key={i}
            className={`btn btn-sm pBtn ${i === currentPage ? 'active' : ''}`}
            onClick={() => handlePageChange(i)}
          >
            {i}
          </span>
        );
      }
    } else {
      const leftEllipsis = currentPage > maxVisiblePages / 2;
      const rightEllipsis = totalPages - currentPage > maxVisiblePages / 2;
  
      for (let i = 1; i <= totalPages; i++) {
        // if (
        //   i === 1 ||
        //   i === totalPages ||
        //   (i >= currentPage - maxVisiblePages / 2 &&
        //     i <= currentPage + maxVisiblePages / 2) ||
        //   (leftEllipsis && i === 2) ||
        //   (rightEllipsis && i === totalPages - 1)
        // ) {
        if (
          i === 1 ||
          (i >= currentPage - 1 && i <= currentPage + 2) ||
          (i === totalPages)
        ) {
          pageNumbers.push(
            <span
              key={i}
              className={`btn btn-sm pBtn ${i === currentPage ? 'active' : ''}`}
              onClick={() => handlePageChange(i)}
            >
              {i}
            </span>
          );
        } else if ((leftEllipsis && i === 3) ||
        (rightEllipsis && i === totalPages - 2)) {
          pageNumbers.push(<span className="paginationDots px-2" key={i}>...</span>);
        }
      }
    }
  
    return pageNumbers;
  };

  return (
    <>
      <p className="h6 text-gray-200">{pageTitle ? pageTitle : "Page"} <span className="text-gray-100"> {endItem === 0 || isNaN(endItem) ? 0 : startItem} to {isNaN(endItem) ? 0 : endItem} </span>
        {/* <span className="text-gray-100">{currentPage} of {totalPages}</span> */}
        {/* <span className="text-gray-100">
          {startItem} to {endItem} - Page {currentPage} of {totalPages}
        </span> */}
      </p>
      {/* <span className="totalPagesPagination">{pageTitle ? pageTitle : "Page"} <font className="text-gray-100">{currentPage}</font> to {totalPages}</span> */}
      <div className="btnArrow ms-2">
        <Button variant="white"
          // className="btn btn-sm pBtn"
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          <IconleftArrow/>
        </Button>
        {/* {renderPageNumbers()} */}
        <Button variant="white" className="ms-3"
          // className="btn btn-sm pBtn"
          onClick={() => handlePageChange(currentPage + 1)}
          // disabled={currentPage === totalPages}
          disabled={endItem === totalItems}
        >
          <IconrightArrow/>
        </Button>
      </div>
    </>
  );
}

export default Pagination;