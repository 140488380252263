import React from 'react';
import { NavLink } from "react-router-dom";
const MyAccountNav = () => {
    return (
        <ul className='authoringNav'>
            {/* exact */}
            <li className='me-4'>
                <NavLink exact className='h6 text-gray-100' to="/employeeaccount/myaccount">Personal Details</NavLink>
            </li>
            <li className='me-4'>
                <NavLink className='h6 text-gray-100' to="/employeeaccount/myaccount/professional">Professional Details</NavLink>
            </li>
        </ul>
    );
}

export default MyAccountNav;
